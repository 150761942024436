<template>
  <v-navigation-drawer
    v-model="navigationDrawerProfile"
    :fixed="$vuetify.breakpoint.mobile"
    :style="{ 'min-width': $vuetify.breakpoint.mobile ? '320px' : '100%' }"
  >
    <v-list>
      <v-list-item class="mb-2 px-6">
        <v-list-item-content>
          <v-list-item-title class="text-h5 font-weight-bold"
            >{{ profile.firstName }} {{ profile.lastName }}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mb-6"></v-divider>
      <v-list-item
        v-for="(section) in profileSections"
        :key="section.id"
        class="px-6"
        link
        @click="selectProfileSection(section)"
      >
        <v-list-item-icon>
          <v-icon
            small
            :class="{
              'primary--text': path === `/profile/${section.key}`,
            }"
            v-text="
              path === `/profile/${section.key}`
                ? section.icon
                : 'fal ' + section.icon
            "
          ></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            :class="{
              'primary--text': path === `/profile/${section.key}`,
            }"
          >
            {{ $t(section.title) }}
            <AppNewLabel v-if="section.isNew" class="ml-2" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

import AppNewLabel from '@/common/AppNewLabel.vue'

export default {
  name: 'ProfileNavigationDrawer',
  props: ['profileSections'],
  components: { AppNewLabel },
  computed: {
    ...mapState('user', ['profile']),
    path () {
      return this.$route.path
    },
    selectedProfileSection: {
      get: function () {
        return this.$store.state.selectedProfileSection
      },
      set: function (newValue) {
        this.$store.commit('setSelectedProfileSection', newValue)
      },
    },
    navigationDrawerProfile: {
      get: function () {
        return !this.$vuetify.breakpoint.mobile
          ? true
          : this.$store.state.navigationDrawerProfile
      },
      set: function (newValue) {
        if (!this.$vuetify.breakpoint.mobile) return
        this.$store.commit('setNavigationDrawerProfile', newValue)
      },
    },
  },
  methods: {
    selectProfileSection (section) {
      this.$router.push(section.key).catch(() => {})
      this.navigationDrawerProfile = false
    },
  },
}
</script>
