<template>
  <tr class="clickable" :class="{ 'saved-checklist-table-item': isSelected }"
      @click="selectSavedChecklist">
    <!-- Name -->
    <td>
      {{ checklist.name }}
    </td>

    <!-- Rights -->
    <td>
      <AppLoader v-if="shareTypeIsCustom && getSelfContactListPending" small/>
      <Sharing v-else :share-type="checklist.shareType"
                      :with-users="sharedWithUsers"
                      @click="isCurrentUserChecklistOwner ? openSavedChecklistShareDialog(checklist) : null"
      />
    </td>

    <!-- createdOn -->
    <td>
      {{ createdOn }}
    </td>

    <!-- Menu -->
    <td>
      <SavedChecklistTableItemMenu v-if="canAccessMenu"
                                   :savedChecklist="checklist"
                                   :isCurrentUserChecklistOwner="isCurrentUserChecklistOwner"
      />
    </td>

    <!-- MODALS -->
    <SaveChecklistShareDialog v-if="savedChecklistShareDialogData.isOpen"
                              :checklist="savedChecklistShareDialogData.item"
                              @close="onSavedChecklistShareDialogClose"
    />
  </tr>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'

  import AppLoader from '@/common/AppLoader.vue'
  import { ISOToShortenedDate } from '@/common/utils/dates'
  import { RIGHT_TYPE_OPTIONS } from '@/common/utils/rightTypes.ts'
  import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes.ts'
  import SaveChecklistShareDialog from '@/project/checklist/dialogs/SaveChecklistShareDialog.vue'
  import Sharing from '@/project/signing-checklist/envelope-templates/table-columns/Sharing.vue'
  import { SET_SELECTED_SAVED_CHECKLIST } from '@/store/modules/checklist/mutation_types'

  import SavedChecklistTableItemMenu from './SavedChecklistTableItemMenu.vue'

  export default {
    name: 'SavedChecklistTableItem',
    components: {
      AppLoader,
      SaveChecklistShareDialog,
      SavedChecklistTableItemMenu,
      Sharing,
    },
    props: {
      checklist: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        savedChecklistShareDialogData: {
          item: null,
          isOpen: false,
        },
      }
    },
    computed: {
      ...mapState('checklist', ['selectedSavedChecklist']),
      ...mapState('user', ['selfContactListFromSubscription', 'getSelfContactListPending']),
      ...mapGetters('user', ['currentUserId']),
      canAccessMenu () {
        return this.isCurrentUserChecklistOwner || this.currentUserRightIsWrite
      },
      isCurrentUserChecklistOwner () {
        return this.checklist.userId === this.currentUserId
      },
      currentUserRightIsWrite () {
        const user = this.checklist.rights.find(user => user.userId === this.currentUserId)
        return user?.right === RIGHT_TYPE_OPTIONS.WRITE
      },
      isSelected () {
        return this.selectedSavedChecklist?.id === this.checklist.id
      },
      shareTypeIsCustom () {
        return this.checklist.shareType === SHARE_TYPE_OPTIONS.CUSTOM
      },
      sharedWithUsers () {
        return this.checklist.rights.map((item) => {
          const user = this.selfContactListFromSubscription.find(u => u.id === item.userId)
          if (user) {
            return user
          }
        }).filter(user => user)
      },
      createdOn () {
        return ISOToShortenedDate(this.checklist.createdAt)
      },
    },
    methods: {
      selectSavedChecklist () {
        this.$store.commit(`checklist/${SET_SELECTED_SAVED_CHECKLIST}`, this.checklist)
      },
      openSavedChecklistShareDialog (item) {
        this.savedChecklistShareDialogData = {
          item,
          isOpen: true,
        }
      },
      onSavedChecklistShareDialogClose () {
        this.savedChecklistShareDialogData = {
          isOpen: false,
          item: null,
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .saved-checklist-table-item {
    background-color: var(--v-blue-base);
  }
  tr:hover {
    background-color: var(--v-blue-base) !important;
  }
  td {
    max-width: 200px;
  }
</style>
