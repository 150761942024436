<template>
  <AppAutocomplete v-model="searchValue"
                   :items="items"
                   :search-input.sync="searchInput"
                   return-object
                   item-text="fullName"
                   :filter="memberAutocompleteFilter"
                   :placeholder="placeholder"
                   clearable
                   clear-icon="fal fa-xmark"
                   color="grey"
                   @change="onChange"
  >
    <template #prepend-inner>
      <v-icon class="text--primary mr-2" size="12">fal fa-magnifying-glass</v-icon>
    </template>

    <template #item="item">
      <v-avatar size="24" class="mr-2">
        {{ item.initials }}
      </v-avatar>

      {{ item.fullName }}
    </template>
  </AppAutocomplete>
</template>

<script>
import AppAutocomplete from '@/common/AppAutocomplete.vue'
import i18n from '@/i18n'

function memberAutocompleteFilter (member, queryText) {
  return member.fullName.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
}

export default {
  name: 'MembersAutocomplete',
  components: { AppAutocomplete },
  data () {
    return {
      memberAutocompleteFilter,
      searchInput: '',
      searchValue: null,
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: i18n.t('project.members.MembersAutocomplete.placeholder'),
    },
  },
  methods: {
    onChange (member) {
      this.$emit('select', member)

      this.$nextTick(() => {
        this.searchInput = ''
        this.searchValue = null
      })
    },
  },
}
</script>

<style scoped lang="scss">
  ::v-deep.v-avatar {
    color: var(--v-grey-darken3);
    background: var(--v-grey-lighten3);
    font-size: 12px;
  }
</style>
