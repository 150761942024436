<template>
  <div>
    <AppButton color="white" @click="filterPaneIsOpen = true">
      <v-icon :left="!$vuetify.breakpoint.xs" size="14">fa fa-sliders</v-icon>
      <span v-if="!$vuetify.breakpoint.xs">{{$t('project.signed-documents.pane.SignedDocumentsFiltersPane.filters')}}</span>
    </AppButton>
    <Pane v-model="filterPaneIsOpen"
          :tabs="tabs"
          :current-tab="currentTab"
          :right="false"
          temporary
          @closeButtonClicked="resetFilters"
    >
      <template #content-filters>
        <v-container>
          <v-row>
            <v-col class="body-1 font-weight-bold">
              {{ $t('project.signed-documents.pane.SignedDocumentsFiltersPane.dates') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <AppDatePicker
                range
                :label="$t('project.signed-documents.pane.SignedDocumentsFiltersPane.inputSignDateLabel')"
                :value="signDateModel"
                @input="(value) => handleDateRangeChange(value, 'signDate')"
              />
            </v-col>
          </v-row>
          <template v-if="signatories.length > 0">
            <v-row>
              <v-col>
                <v-divider class="my-2"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="body-1 font-weight-bold">
                {{ $t('project.signed-documents.pane.SignedDocumentsFiltersPane.signatories') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col v-for="signatory in signatories.length > 10 && !displayMoreSignatories ? shortSignatories : signatories"
                    :key="`signatory-${signatory.signerId}`"
                    class="py-1"
                    cols="12"
                    md="6"
              >
                <v-checkbox :input-value="filters.signatories"
                            :label="signatory.fullName"
                            dense
                            hide-details
                            class="SignedDocumentsFiltersPane-checkbox mt-0"
                            :value="signatory.signerId"
                            @change="setFiltersSignatories($event)"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="signatories.length > 10 && !displayMoreSignatories">
              <v-col>
                <div class="displayMoreSignatories text-h4 clickable"
                    @click="displayMoreSignatories = true"
                >
                  {{ $t('project.signed-documents.pane.SignedDocumentsFiltersPane.displayMoreSignatories') }}
                </div>
              </v-col>
            </v-row>
            <v-row v-if="signatories.length > 10 && displayMoreSignatories">
              <v-col>
                <div class="displayLessSignatories text-h4 clickable"
                    @click="displayMoreSignatories = false"
                >
                  {{ $t('project.signed-documents.pane.SignedDocumentsFiltersPane.displayLessSignatories') }}
                </div>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col>
              <v-divider class="my-2"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <AppButton
                         block
                         @click="resetFilters"
              >
                {{$t('common.clear')}}
              </AppButton>
            </v-col>
            <v-col cols="12" md="6">
              <AppButton color="primary"
                         block
                         @click="filterPaneIsOpen = false"
              >
                {{$t('common.apply')}}
              </AppButton>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </Pane>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AppDatePicker from '../../../common/AppDatePicker'
import AppButton from '../../../common/buttons/AppButton'
import Pane from '../../../common/Pane'
export default {
  name: 'SignedDocumentsFiltersPane',
  components: { Pane, AppButton, AppDatePicker },
  data () {
    return {
      filterPaneIsOpen: false,
      tabs: [
        {
          title: this.$t('project.signed-documents.pane.SignedDocumentsFiltersPane.filters'),
          id: 'filters',
        },
      ],
      currentTab: 'filters',
      displayMoreSignatories: false,
    }
  },
  computed: {
    ...mapState('signedDocuments', ['signedDocuments', 'filters', 'signDateModel']),
    signatories () {
      const array = []
      this.signedDocuments.forEach(signedDocument => {
        if (signedDocument.mySignature) {
          if (!array.find(signatory => signatory.signerId === signedDocument.mySignature.signerId)) {
            array.push(signedDocument.mySignature)
          }
        }
        if (signedDocument.otherSignatures.length > 0) {
          signedDocument.otherSignatures.forEach(otherSignature => {
            if (!array.find(signatory => signatory.signerId === otherSignature.signerId)) {
              array.push(otherSignature)
            }
          })
        }
      })
      return array
    },
    shortSignatories () {
      return this.signatories.slice(0, 10)
    },
  },
  methods: {
    ...mapActions('signedDocuments', ['setSignDateModel', 'setFiltersStartDate', 'setFiltersEndDate', 'setFiltersSignatories']),
    handleDateRangeChange (value) {
      this.setSignDateModel(value)
      this.setFiltersStartDate(value[0] || null)
      if (value.length === 2) {
        this.setFiltersEndDate(value[1])
      } else {
        this.setFiltersEndDate(null)
      }
    },
    resetFilters () {
      this.setFiltersSignatories([])
      this.setFiltersStartDate(null)
      this.setFiltersEndDate(null)
      this.setSignDateModel([])
    },
  },
}
</script>

<style scoped lang="scss">
  .displayMoreSignatories:hover,
  .displayLessSignatories:hover {
    color: var(--v-primary-base);
  }
  ::v-deep .SignedDocumentsFiltersPane-checkbox {
    i {
      font-size: 18px;
    }
    label {
      font-size: 14px;
    }
  }
</style>
