<template>
  <div>
    <AppTooltip bottom>
      <template #activator="{attrs, on}">
        <img :src="projectCategoryIcon"
             width="24px"
             v-bind="attrs"
             v-on="on"
        />
      </template>
      <span v-if="room.isDataroom">{{ $t('common.RoomTypeIcon.dataroom') }}</span>
      <span v-else>{{ $t('common.RoomTypeIcon.project') }}</span>
    </AppTooltip>
  </div>
</template>

<script>
import AppTooltip from './AppTooltip'
import { getRoomIcon } from './utils/rooms'

export default {
  name: 'RoomTypeIcon',
  components: { AppTooltip },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  computed: {
    projectCategoryIcon () {
      return getRoomIcon(this.room)
    },
  },
}
</script>
