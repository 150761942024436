<template>
    <v-row class="align-center">
      <v-col cols="auto">
        <v-avatar color="primary" size="33">
          <span class="white--text">{{initials}}</span>
        </v-avatar>
      </v-col>
      <v-col>
        <div>{{signatory.fullName}}</div>
        <div v-if="signatoryGroup">{{signatoryGroup.name}}</div>
      </v-col>
      <v-col class="text-center">
        {{time}}
      </v-col>
      <v-col class="text-center">
        <v-chip label
                color="primary"
        >
          {{ $t('project.signed-documents.SignedDocumentsSignatoriesListModalItem.signed') }}
        </v-chip>
      </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'

import { ISOToShortDate } from '../../common/utils/dates'

export default {
  name: 'SignedDocumentsSignatoriesListModalItem',
  props: {
    signatory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('groups', ['groups']),
    initials () {
      const array = this.signatory.fullName.split(' ')
      let string = ''
      array.forEach(i => {
        string += i[0]
      })
      return string
    },
    signatoryGroup () {
      return this.groups.find(group => {
        if (group.members.find(member => member.id === this.signatory.signerId)) {
          return group
        }
      })
    },
    time () {
      return ISOToShortDate(this.signatory.time)
    },
  },
}
</script>
