<template>
    <AppDialog :is-open="isDialogOpened"
               :ok-text="$t('common.office.OfficeConversionDialog.conversion')"
               @ok="validateConversionDialog"
               @cancel="closeDialog"
    >
      <template #title>
        <h3 class="conversion-title">{{ $t('common.office.OfficeConversionDialog.title') }}</h3>
      </template>
      <template #body>
        <i18n path="common.office.OfficeConversionDialog.text"
              tag="p"
              class="conversion-text"
        >
          <template v-slot:filderName>
            <b>{{ document.name }}</b>
          </template>
        </i18n>
        <v-checkbox
                v-model="officeConversionAuth"
                hide-details
                :label="$t('common.office.OfficeConversionDialog.checkbox')"
                @change="checkAuthOfficeConversion($event)"
        />
      </template>
    </AppDialog>
</template>

<script>
import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'

export default {
  name: 'OfficeConversionDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      officeConversionAuth: false,
    }
  },
  methods: {
    validateConversionDialog () {
      this.$emit('validate-conversion-dialog', this.officeConversionAuth)
    },
    checkAuthOfficeConversion ($event) {
      this.officeConversionAuth = $event
    },
  },
}
</script>

<style scope>
h3.conversion-title {
  font-size: 18px;
  color: var(--v-grey-darken4);
}
.conversion-text {
  line-height: 20px;
  color: var(--v-grey-darken3);
}
label.v-label {
  color: var(--v-grey-darken4) !important
}
</style>
