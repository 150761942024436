export const state = {
  filePendingIds: [],
  fileError: null,
  watermarkedFilePendingPages: [],
  watermarkedFile: {
    fileId: null,
    totalNbPages: 0,
    pages: [],
  },
  watermarkedFileError: null,
  watermarkedDialogData: {
    file: null,
    isOpen: false,
  },
  watermarkedRequestPending: [],
}
