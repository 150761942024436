<template>
  <v-snackbar :value="true"
              app
              light
              top
              :elevation="6"
              :timeout="-1"
  >
    <div class="text-center">
      <v-progress-circular color="primary"
                           indeterminate
                           class="mr-4"
      />
      <span>{{ $t('common.identity-checking.IdentityCheckingInProgressToast.text') }}</span>
    </div>
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IdentityCheckingInProgressToast',
})
</script>
