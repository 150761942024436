<template>
    <v-container style="display: flex; justify-content: center;">
        <v-row style="max-width: 500px">
            <v-row justify="center" class="mt-10 mb-10">
                <h2>{{$t('login.views.TwoFactor.twoFactorTitle')}}</h2>
            </v-row>
            <v-row>
                <AppCard :elevation="0"
                >
                  <v-row justify="center" class="mt-4 text-center">
                    <v-col>
                      {{ $t('login.views.TwoFactor.title') }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-center">
                      <img
                        width="205"
                        src="/img/2FaIllustration.svg"
                      />
                    </v-col>
                  </v-row>
                    <v-row justify="center" class="mt-4 mb-4 text-center">
                        {{ $t('login.views.TwoFactor.explainText1') }}<br />
                        {{ $t('login.views.TwoFactor.explainText2') }}
                    </v-row>
                    <v-row justify="center">
                      <div class="d-flex align-center">
                        <font-awesome-icon :icon="['fas', 'key']"
                                           class="mr-4"
                                           style="font-size: 24px;"
                        ></font-awesome-icon>
                        <v-otp-input v-model.trim="twoFactorCode"
                                     length="6"
                                     @keypress.enter="prepareTwoFactorSend"
                        />
                      </div>
                      <div v-if="twoFactorErrors.length > 0" class="error--text">
                        <div v-for="(error, index) in twoFactorErrors" :key="index">{{ error }}</div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="mt-7 mb-4">
                        <AppButton color="primary"
                           :loading="loading"
                           @click="prepareTwoFactorSend"
                        >
                            {{$t('login.views.TwoFactor.sendCode')}}
                        </AppButton>
                    </v-row>
                    <v-row class="mt-5">
                        <v-divider></v-divider>
                    </v-row>
                    <v-row v-if="!successResend" justify="center">
                      <AppButton color="primary"
                           class="TwoFactor-resend mt-3 mb-3"
                           :disabled="!canResend"
                           @click="prepareResend"
                        >
                            {{sendNewCodeLabel}}
                        </AppButton>
                    </v-row>
                    <v-row v-if="successResend" justify="center" align="center" class="mt-8 mb-1">
                        <v-col cols="1">
                          <font-awesome-icon :icon="['fad', 'circle-check']" style="font-size: 30px; color: green"></font-awesome-icon>
                        </v-col>
                        <v-col cols="6">
                          <p>{{$t('login.views.TwoFactor.successMsg')}}</p>
                        </v-col>
                    </v-row>
                </AppCard>
            </v-row>
            <v-row class="mt-3">
                <v-col class="d-flex justify-center TwoFactor-login">
                  <router-link :to="{ name: LOGOUT_ROUTE_NAME }">{{
                    $t("login.views.TwoFactor.backLogin")
                  }}</router-link>
                </v-col>
            </v-row>
            </v-row>
    </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import { RESEND_TWO_FACTORS_CODE, VALIDATE_TWO_FACTORS_CODE } from '@/store/modules/login/action_types'

import AppCard from '../../common/AppCard'
import AppButton from '../../common/buttons/AppButton'
import { ROOMS_ROUTE_NAME, LOGOUT_ROUTE_NAME } from '../../router'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'

export default {
  name: 'TwoFactor',
  components: { AppCard, AppButton },
  mixins: [validationMixin],
  validations: {
    twoFactorCode: { required },
  },
  props: {
    returnRoute: {
      type: Object,
    },
  },
  data () {
    return {
      LOGOUT_ROUTE_NAME,
      twoFactorCode: null,
      successResend: false,
      countdown: 30,
    }
  },
  computed: {
    ...mapState('login', ['loading']),
    twoFactorErrors () {
      const errors = []
      if (!this.$v.twoFactorCode.$dirty) return errors
      !this.$v.twoFactorCode.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('login.views.TwoFactor.inputCode') }))
      return errors
    },
    canResend () {
      return this.countdown === 0
    },
    sendNewCodeLabel () {
      let label = this.$t('login.views.TwoFactor.resendCode')
      if (!this.canResend) {
        label += ` (${this.countdown})`
      }
      return label
    },
  },
  watch: {
    countdown: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.countdown--
          }, 1000)
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('login', [RESEND_TWO_FACTORS_CODE, VALIDATE_TWO_FACTORS_CODE]),
    async prepareTwoFactorSend () {
      if (this.loading) {
        return
      }
      this.$v.twoFactorCode.$touch()
      if (!this.$v.twoFactorCode.$invalid) {
        try {
          const validate = await this.VALIDATE_TWO_FACTORS_CODE({
            code: this.twoFactorCode,
          })
          if (validate.success === true) {
            if (this.returnRoute !== undefined) {
              this.$router.push(this.returnRoute)
            } else {
              this.$router.push({ name: ROOMS_ROUTE_NAME })
            }
          } else {
            this.$store.commit(ENQUEUE_SNACKBAR, {
              color: 'error',
              message: this.$t('login.views.TwoFactor.codeError'),
            })
          }
        } catch (e) {
          console.error(e)
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('login.views.TwoFactor.submitError'),
          })
        }
      }
    },
    async prepareResend () {
      try {
        await this.RESEND_TWO_FACTORS_CODE()
        this.successResend = true
        setTimeout(() => { this.successResend = false }, 5000)
        this.countdown = 30
      } catch (e) {
        console.error(e)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('login.views.TwoFactor.resendError'),
        })
      }
    },
  },
}
</script>
<style>
.TwoFactor-login a{
    text-decoration: none;
    color: #9e9e9e !important;
}
button.TwoFactor-resend.v-btn.v-btn--has-bg.theme--light.v-size--default.primary.px-4{
  background-color: #fff !important;
  color: #333333;
}
</style>
