<template>
  <div class="header-container">
    <v-row>
      <v-col>
        <div class="text-h1">{{$t('project.documents.DocumentsHeader.documents')}}</div>

        <p class="my-3 accent--text text--lighten-2">
          {{$t('project.documents.DocumentsHeader.storeAndExchangeDocuments')}}
          <AppInfoTooltip>
            <div>{{$t('project.documents.DocumentsHeader.documentsPageInfo1')}}</div>
            <div>{{$t('project.documents.DocumentsHeader.documentsPageInfo2')}}</div>
          </AppInfoTooltip>
        </p>
      </v-col>
    </v-row>

    <div class="sticky-container">
      <v-row>
        <!--SEARCH-->
        <v-col cols="12" md="auto" lg="6">
          <v-row class="align-center">
            <v-col cols="auto">
              <SearchTextField v-model="search" :tooltipText="$t('project.documents.DocumentsHeader.searchPlaceholder')" />
            </v-col>
            <v-col cols="auto">
              <AppButton color="white"
                         @click="onOpenFiltersPane"
              >
                <font-awesome-icon :icon="['far', 'sliders']" class="mr-2"></font-awesome-icon>
                {{ $t('common.filters') }}
              </AppButton>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="auto" lg="6">
          <v-row class="justify-end align-center">
            <v-col v-if="currentRoom.isPM && !hasTodoFolderOrChild"
                   md="auto"
            >
              <AppTooltip top>
                <template #activator="{attrs, on}">
                  <div v-bind="attrs"
                       v-on="on"
                  >
                    <v-switch
                      :input-value="numberingEnabled"
                      readonly
                      class="mt-0"
                      :loading="patchNumberingPending"
                      hide-details
                      @click="!patchNumberingPending && onNumberingChange()"
                    >
                      <template #label>
                        <span class="text--primary">
                          {{ $t("project.documents.DocumentsHeader.numbering") }}
                        </span>
                      </template>
                    </v-switch>
                  </div>
                </template>
                <span>{{ $t("project.documents.DocumentsHeader.numberingTooltip") }}</span>
              </AppTooltip>
            </v-col>
            <v-col md="auto">
              <v-menu offset-y>
                <template #activator="{ attrs, on }">
                  <AppButton color="white" v-bind="attrs" v-on="on">
                    {{ $t("project.documents.DocumentsHeader.options") }}
                    <v-icon right>fal fa-chevron-down</v-icon>
                  </AppButton>
                </template>
                <v-list>
                  <AppDownloadFile
                    :link="exportIndexToExcelLink"
                    class="d-block"
                  >
                    <template #default="{ submitForm }">
                      <v-list-item @click="submitForm">
                        <v-list-item-title class="d-flex align-center">
                          <font-awesome-icon :icon="['fal', 'file-excel']" size="lg" class="mr-2"></font-awesome-icon>
                          {{ $t("project.documents.DocumentsHeader.exportIndexToExcel") }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </AppDownloadFile>
                  <AppDownloadFile :link="exportIndexToPdfLink" forceReader class="d-block">
                    <template #default="{ submitForm }">
                      <v-list-item @click="submitForm">
                        <v-list-item-title class="d-flex align-center">
                          <font-awesome-icon :icon="['fal', 'file-pdf']" size="lg" class="mr-2"></font-awesome-icon>
                          {{ $t("project.documents.DocumentsHeader.exportIndexToPdf") }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </AppDownloadFile>
                  <v-list-item v-if="currentRoom.isPM && !hasTodoFolderOrChild" @click="existingTreeDialogIsOpen = true">
                    <v-list-item-title class="d-flex align-center">
                      <font-awesome-icon :icon="['fal', 'file-excel']" size="lg" class="mr-2"></font-awesome-icon>
                      {{ $t("project.documents.DocumentsHeader.dropdownIcon.existingTree") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onSendDocumentsIndexByEmail">
                    <v-list-item-title class="d-flex align-center">
                      <font-awesome-icon :icon="['fal', 'envelope']" size="lg" class="mr-2"></font-awesome-icon>
                      {{ $t("project.documents.DocumentsHeader.sendIndexByEmail") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col v-if="isDataRoom && currentRoom.isPM"
                   md="auto"
            >
              <AppButton color="primary"
                         outlined
                         :to="{ name: ROOM_SETTINGS_ROUTE_NAME, params: { goTo: '#accessRightsContainer', roomType: computedRoomRouteType } }"
              >
                {{ $t('project.documents.DocumentsHeader.privacyAndSecurity') }}
              </AppButton>
            </v-col>
            <v-col v-if="displayAddMenu"
                   md="auto"
            >
            <AppTooltip
              v-if="currentUserRights.canUpload && hasTodoFolderOrChild"
              top
              :disabled="!showSizeExceededTooltip"
            >
              <template #activator="{attrs, on}">
                <!-- We bind the tooltip to the div, because it should be seen even with a disabled activator -->
                <!-- This makes it able to see the tooltip when disabled-->
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <AppButton
                    color="primary"
                    :disabled="showSizeExceededTooltip"
                    @click="OPEN_DOCUMENTS_UPLOAD_FILES"
                  >
                    <v-icon left>fal fa-plus</v-icon>
                    {{ $t("project.documents.DocumentsHeader.addFile") }}
                  </AppButton>
                </div>
              </template>
              {{ $t('project.documents.DocumentsHeader.sizeExceededTooltip') }}
            </AppTooltip>
              <v-menu v-else open-on-hover offset-y>
                <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
                  <!-- We bind the tooltip to the div, because it should be seen even with a disabled activator -->
                  <!-- This makes it able to see the tooltip when disabled, but not the menu -->
                  <!-- here use v-tooltip inside the v-menu because bug when use AppTooltip on v-menu -->
                  <v-tooltip top :disabled="!showSizeExceededTooltip">
                    <template #activator="{attrs: tooltipAttrs, on: tooltipOn}">
                      <div
                        v-bind="tooltipAttrs"
                        v-on="tooltipOn"
                      >
                        <AppButton
                          color="primary"
                          :disabled="showSizeExceededTooltip"
                          v-bind="menuAttrs"
                          v-on="menuOn"
                        >
                          <v-icon class="text-center" left>fal fa-plus</v-icon>
                          {{ $t("common.add") }}
                          <v-icon right>fal fa-chevron-down</v-icon>
                        </AppButton>
                      </div>
                    </template>
                    {{ $t('project.documents.DocumentsHeader.sizeExceededTooltip') }}
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item v-if="currentUserRights.canCreateFolders && !hasTodoFolderOrChild"
                               @click="OPEN_DOCUMENTS_UPLOAD_FILES"
                  >
                    <v-list-item-title>{{
                      $t("project.documents.DocumentsHeader.dropdownIcon.addFoldersOrFiles")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-else-if="currentUserRights.canUpload && !isCurrentlyInTodoFolderRoot"
                               @click="OPEN_DOCUMENTS_UPLOAD_FILES"
                  >
                    <v-list-item-title>{{
                        $t("project.documents.DocumentsHeader.dropdownIcon.addFiles")
                      }}</v-list-item-title>
                  </v-list-item>
                  <PolyOfficeListItemImport
                    v-if="isImportFromPolyMenuDisplayed"
                    @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
                  />
                  <v-list-item v-if="(currentRoom.isPM || currentUserRights.canCreateFolders) && !hasTodoFolderOrChild" @click="openDocumentsCreateNewFolder">
                    <v-list-item-title>{{
                      $t("project.documents.DocumentsHeader.dropdownIcon.newFolder")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-show="breadcrumbIsShown">
        <v-col>
          <DocumentsBreadcrumb />
        </v-col>
      </v-row>
      <v-row v-if="selectedDocuments.length > 0 && !loading" class="sticky-box">
        <v-col>
          <DocumentsActionBar />
        </v-col>
      </v-row>
    </div>

    <DocumentsHeaderFilters v-if="filtersCount > 0" />

    <DocumentsExistingTreeDialog :is-open.sync="existingTreeDialogIsOpen"
                                 :mnemo="mnemo"
    />
    <DocumentsNumberingDisableDialog
      v-if="showNumberingDisableDialog"
      :mnemo="mnemo"
      @confirm="confirmNumberingDisableDialog"
      @close="showNumberingDisableDialog = false"
    />

    <PolyOfficeDialog v-if="polyOfficeEnabled && dialogIsOpen" @callback="polyOfficeDialogCallback"/>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import AppInfoTooltip from '@/common/AppInfoTooltip'
import AppTooltip from '@/common/AppTooltip.vue'
import AppButton from '@/common/buttons/AppButton'
import { DocumentsImport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeDialog, { POLY_OFFICE_DIALOG_STATUS_SUCCESS } from '@/common/polyOffice/PolyOfficeDialog.vue'
import PolyOfficeListItemImport from '@/common/polyOffice/PolyOfficeListItemImport.vue'
import SearchTextField from '@/common/SearchTextField'
import { getRoomRouteType } from '@/common/utils/rooms'
import DocumentsExistingTreeDialog from '@/project/documents/dialogs/DocumentsExistingTreeDialog'
import DocumentsHeaderFilters from '@/project/documents/DocumentsHeaderFilters.vue'
import { ROOM_SETTINGS_ROUTE_NAME } from '@/router'
import {
  DELETE_SEARCH_RESULTS,
  GET_DOCUMENTS_BY_ID,
  GET_NUMBERING,
  GET_SEARCH_RESULTS,
  LOAD_FOLDERS_TREE,
  OPEN_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PANE,
  PATCH_NUMBERING,
} from '@/store/modules/documents/action_types'
import { SET_FILTERS_PANE_IS_OPEN, SET_NUMBERING_SNACKBAR_IS_OPEN } from '@/store/modules/documents/mutation_types'
import { OPEN_DOCUMENTS_UPLOAD_FILES } from '@/store/modules/documents-upload-files/action_types'
import { GET_IMPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import DocumentsNumberingDisableDialog from './dialogs/DocumentsNumberingDisableDialog.vue'
import DocumentsActionBar from './DocumentsActionBar'
import DocumentsBreadcrumb from './DocumentsBreadcrumb'

export default {
  name: 'DocumentsHeader',
  components: {
    AppTooltip,
    AppButton,
    AppDownloadFile,
    AppInfoTooltip,
    DocumentsBreadcrumb,
    DocumentsExistingTreeDialog,
    DocumentsHeaderFilters,
    DocumentsActionBar,
    DocumentsNumberingDisableDialog,
    PolyOfficeDialog,
    PolyOfficeListItemImport,
    SearchTextField,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      ROOM_SETTINGS_ROUTE_NAME,
      searchQuery: '',
      showNumberingDisableDialog: false,
      existingTreeDialogIsOpen: false,
    }
  },
  computed: {
    ...mapState('documents', ['selectedDocuments', 'documents', 'loading', 'filters', 'numberingEnabled', 'searchModeEnabled', 'patchNumberingPending']),
    ...mapState('documentsBreadcrumb', ['breadcrumb']),
    ...mapState('room', ['currentRoom']),
    ...mapState('polyOffice', ['dialogIsOpen', 'dialogSuccess']),
    ...mapGetters('room', ['currentUserRights', 'isDataRoom', 'isStorageFull']),
    ...mapGetters('documentsBreadcrumb', ['hasTodoFolderOrChild', 'isCurrentlyInTodoFolderRoot']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    computedRoomRouteType () {
      return getRoomRouteType(this.currentRoom)
    },
    exportIndexToExcelLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/documents-index-excel`
    },
    exportIndexToPdfLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/documents-index-pdf`
    },
    search: {
      get () {
        return this.searchQuery
      },
      set: debounce(function (val) {
        this.searchQuery = val
        this.searchDocs()
      }, 1000),
    },
    displayAddMenu () {
      return (
        this.documents.specialFolder !== 'new' &&
        !this.isCurrentlyInTodoFolderRoot &&
        (
          this.currentRoom.isPM ||
          this.currentUserRights.canUpload ||
          this.currentUserRights.canCreateFolders
        )
      )
    },
    breadcrumbIsShown () {
      return this.breadcrumb.length > 1 && !this.searchModeEnabled
    },
    filtersCount () {
      return this.filters.length
    },
    isImportFromPolyMenuDisplayed () {
      return this.polyOfficeEnabled &&
        ((this.currentRoom.isPM && !this.hasTodoFolderOrChild) ||
          (this.currentUserRights.canUpload && !this.isCurrentlyInTodoFolderRoot))
    },
    showSizeExceededTooltip () {
      return this.isStorageFull
    },
  },
  watch: {
    searchModeEnabled (searchModeIsEnabled) {
      if (!searchModeIsEnabled) {
        this.searchQuery = ''
      }
    },
  },
  mounted () {
    this.GET_NUMBERING(this.mnemo)
  },
  methods: {
    ...mapActions('documentsCreateNewFolder', ['openDocumentsCreateNewFolder']),
    ...mapActions('documentsUploadFiles', [OPEN_DOCUMENTS_UPLOAD_FILES]),
    ...mapActions('documents', [
      DELETE_SEARCH_RESULTS,
      GET_DOCUMENTS_BY_ID,
      GET_NUMBERING,
      GET_SEARCH_RESULTS,
      LOAD_FOLDERS_TREE,
      OPEN_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PANE,
      PATCH_NUMBERING,
    ]),
    ...mapActions('polyOffice', [
      GET_IMPORT_IFRAME,
    ]),
    ...mapMutations('documents', [SET_FILTERS_PANE_IS_OPEN, SET_NUMBERING_SNACKBAR_IS_OPEN]),
    searchDocs () {
      if (this.search) {
        this.GET_SEARCH_RESULTS(this.searchQuery)
      } else {
        this.DELETE_SEARCH_RESULTS()
      }
    },
    async toggleNumbering () {
      try {
        await this.PATCH_NUMBERING({ mnemo: this.mnemo, value: !this.numberingEnabled })
        await this.GET_NUMBERING(this.mnemo)

        this.LOAD_FOLDERS_TREE()
        await this.GET_DOCUMENTS_BY_ID({ mnemo: undefined, id: undefined, queryObject: { markRecent: true } })

        if (this.numberingEnabled) {
          this.SET_NUMBERING_SNACKBAR_IS_OPEN(true)
        } else {
          this.SET_NUMBERING_SNACKBAR_IS_OPEN(false)
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: this.$t('project.documents.DocumentsHeader.successNumberingDisabled'),
          })
        }
      } catch {
        this.$store.commit('enqueueSnackbar', {
          color: 'error',
          message: this.$t('project.documents.DocumentsHeader.errorNumbering'),
        })
      }
    },
    confirmNumberingDisableDialog () {
      this.showNumberingDisableDialog = false
      this.toggleNumbering()
    },
    onNumberingChange () {
      if (this.numberingEnabled) {
        this.showNumberingDisableDialog = true
      } else {
        this.toggleNumbering()
      }
    },
    onOpenFiltersPane () {
      this.SET_FILTERS_PANE_IS_OPEN(true)
    },
    onSendDocumentsIndexByEmail () {
      this.OPEN_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PANE()
    },
    async refreshDocuments () {
      try {
        await this.GET_DOCUMENTS_BY_ID({ queryObject: { markRecent: true } })
      } catch (error) {
        console.error(error)

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.DocumentsHeader.getDocumentsError'),
        })
      }
    },
    async polyOfficeOpenDialogHandler () {
      const payload = new DocumentsImport(
        this.mnemo,
        this.documents.id,
      )
      await this.GET_IMPORT_IFRAME({
        payload,
        successMessage: this.$t('project.documents.DocumentsHeader.polyOffice.importSuccessful'),
      })
    },
    polyOfficeDialogCallback (data) {
      if (data.status === POLY_OFFICE_DIALOG_STATUS_SUCCESS) {
        this.refreshDocuments()
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.dialogSuccess,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.header-container {
  display: unset;
}
.sticky-container {
  position: sticky;
  top: 0;
  z-index: 2;
}
.sticky-container .row {
  background-color: var(--v-originalGrey-lighten5);
}
</style>
