<template>
  <AppDialog :is-open="isDialogOpened"
             :cancel-text="$t('project.signing-checklist.dialogs.SigningChecklistTagsOverlapsDialog.cancelButtonLabel')"
             :ok-text="$t('project.signing-checklist.dialogs.SigningChecklistTagsOverlapsDialog.okButtonLabel')"
             size="l"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>
      <div class="d-flex column-gap-2">
        <app-icon icon-name="exclamation-triangle"
                  icon-weight="fas"
                  class="warning--text"
          />
        <span>{{ $t("project.signing-checklist.dialogs.SigningChecklistTagsOverlapsDialog.dialogTitle") }}</span>
      </div>
    </template>

    <template #body>
      <i18n path="project.signing-checklist.dialogs.SigningChecklistTagsOverlapsDialog.dialogBody"
            tag="p"
      >
        <template #documentsLabel>
          {{ $tc("project.signing-checklist.dialogs.SigningChecklistTagsOverlapsDialog.dialogBodyDocumentsLabel", documentsWithTagsOverlaps.length) }}
        </template>
        <template #documentsNames>
          <span class="font-weight-semi-bold"> {{ displayedDocumentsNames }}</span>
        </template>
      </i18n>
    </template>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'

export default defineComponent({
  name: 'SigningChecklistTagsOverlapsDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    documents: {
      type: Array,
      required: true,
    },
    tagsOverlaps: {
      type: Array,
      required: true,
    },
  },
  computed: {
    displayedDocumentsNames () {
      return this.documentsWithTagsOverlaps.map(file => `"${file.objectToPost.title}"`).join(', ')
    },
    documentsWithTagsOverlaps () {
      const documentsIds = this.tagsOverlaps.map(item => item.envelopeId)
      return this.documents.filter(file => documentsIds.includes(file.objectToPost.envelope.id))
    },
  },
  methods: {
    confirmClick () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
})
</script>
