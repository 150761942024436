<template>
 <div v-if="errorCanBeDisplayed"
      class="d-flex flex-column align-center justify-center fill-height"
 >
   <div class="text-h2 mb-4">
     <template v-if="errorMayHaveIdpName && idpName">
       {{ $t(`login.views.SamlAcs.errorWithIdp.${error}`, { idpName: idpName }) }}
     </template>
     <template v-else>
       {{ $t(`login.views.SamlAcs.error.${error}`) }}
     </template>
   </div>
   <div v-if="hasContactPoints && errorShouldDisplayContactPoints" class="body-1 mt-4 mb-4">
     {{ errorContext.contactPoints[lang] }}
   </div>
   <div class="mt-8">
     <AppButton color="primary"
                @click="goToLogin"
     >
       {{ $t('login.views.SamlAcs.goToLogin') }}
     </AppButton>
   </div>
 </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapMutations } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import { getSignInRoute } from '@/common/utils/login'
import { USER_ID_STORAGE_KEY, USER_TOKEN_STORAGE_KEY } from '@/common/utils/users'
import { ROOMS_ROUTE_NAME } from '@/router'
import { SET_USER_TOKEN_SCOPE } from '@/store/modules/login/mutation_types'
import { GET_USER } from '@/store/modules/user/action_types'

const handledError = [
  'SAML_ERROR_USER_NOT_AUTHORIZED',
  'SAML_ERROR_FORBIDDEN_FROM_EXTERNAL_NET',
  'SAML_ERROR_LOGIN_DUPLICATE_MESSAGE',
  'SAML_ERROR_USER_INVALID',
  'SAML_ERROR_LOGIN_IDP_ERROR',
  'SAML_ERROR_LOGIN_UNEXPECTED_ERROR',
]
const errorsWithIdpName = [
  'SAML_ERROR_USER_NOT_AUTHORIZED',
  'SAML_ERROR_FORBIDDEN_FROM_EXTERNAL_NET',
  'SAML_ERROR_LOGIN_IDP_ERROR',
]
const errorsWithContactPoints = [
  'SAML_ERROR_USER_INVALID',
  'SAML_ERROR_USER_NOT_AUTHORIZED',
]

export default {
  name: 'SamlAcs',
  components: { AppButton },
  data () {
    return {
      errorContext: null,
      error: null,
    }
  },
  computed: {
    errorCanBeDisplayed () {
      return handledError.includes(this.error)
    },
    errorMayHaveIdpName () {
      return errorsWithIdpName.includes(this.error)
    },
    errorShouldDisplayContactPoints () {
      return errorsWithContactPoints.includes(this.error)
    },
    idpName () {
      return this.errorContext?.idpName || null
    },
    hasContactPoints () {
      if (this.errorContext) {
        if (this.errorContext.contactPoints) {
          if (Array.isArray(this.errorContext.contactPoints)) {
            return this.errorContext.contactPoints.length > 0
          } else if (typeof this.errorContext.contactPoints === 'object') {
            return Object.keys(this.errorContext.contactPoints).length > 0
          }
        }
      }
      return false
    },
    lang () {
      if (this.$i18n.locale.includes('-')) {
        return this.$i18n.locale.split('-')[0]
      } else {
        return this.$i18n.locale
      }
    },
  },
  mounted () {
    this.$i18n.locale = navigator.language
    if (this.$route.query.token) {
      axios.defaults.headers.common.Authorization = `Bearer ${decodeURI(this.$route.query.token)}`
      this.prepareGetUser()
    } else if (this.$route.query.error) {
      this.error = this.$route.query.error
      if (this.$route.query.errorContext) {
        this.errorContext = JSON.parse(this.$route.query.errorContext)
      }
    }
    if (this.$route.query.scope) {
      const array = this.$route.query.scope.split(',')
      this.SET_USER_TOKEN_SCOPE(array)
    }
    if (this.$route.query.intendedUrl) {
      this.intendedUrl = this.$route.query.intendedUrl
    }
  },
  methods: {
    ...mapActions('user', [GET_USER]),
    ...mapMutations('login', [SET_USER_TOKEN_SCOPE]),
    async prepareGetUser () {
      try {
        await this.GET_USER({
          loginMode: true,
        })
        localStorage.setItem(USER_TOKEN_STORAGE_KEY, decodeURI(this.$route.query.token))
        localStorage.setItem(USER_ID_STORAGE_KEY, this.$store.state.user.profile.id)
        const intendedUrl = this.$route.query.intendedUrl
        if (intendedUrl) {
          window.location.replace(intendedUrl)
        } else {
          this.$router.replace({ name: ROOMS_ROUTE_NAME })
        }
      } catch (error) {
        console.error(error)
      }
    },
    goToLogin () {
      const signInRoute = getSignInRoute()
      this.$router.replace(signInRoute)
    },
  },
}
</script>
