<template>
  <v-menu offset-y left min-width="150">
    <template #activator="{ on, attrs }">
      <app-button type="icon" v-bind="attrs" v-on="on">
        <app-icon icon-name="ellipsis"/>
      </app-button>
    </template>
    <v-list class="py-0">
      <template v-for="(item, i) in itemMenu">
        <v-list-item :key="`itemMenu-${i}`"
                      @click="handleMenuAction(item.action)"
        >
          <v-list-item-icon v-if="item.icon"
                            :class="item.class"
                            class="mr-2"
          >
            <app-icon :icon-name="item.icon"/>
          </v-list-item-icon>
          <v-list-item-content :class="item.class">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'SubscriptionTeamsTableItemMenu',
    data () {
      return {
        itemMenu: [
          {
            icon: 'pen-to-square',
            text: this.$t('subscriptions.views.SubscriptionTeams.updateTeam'),
            action: 'edit',
          },
          {
            icon: 'trash-can',
            text: this.$t('common.delete'),
            class: 'error--text',
            action: 'delete',
          },
        ],
      }
    },
    methods: {
      handleMenuAction (action) {
        switch (action) {
          case 'edit':
            this.$emit('open-team-edit-pane')
            break
          case 'delete':
            this.$emit('delete-team')
            break
        }
      },
    },
  }
</script>
