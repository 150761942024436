<template>
  <draggable
    v-model="computedSteps"
    :group="group"
    handle=".step-handle"
    :move="move"
    :forceFallback="true"
    v-on="$listeners"
  >
    <transition-group
      tag="div"
      :class="[
        'd-flex',
        'flex-wrap', {
        'justify-center': value.length === 0,
        'flex-column': sidebar,
        'pa-4': !sidebar,
        },
      ]"
    >
      <template v-if="value.length !== 0">
        <div v-for="(step, index) in value" :key="index + '-'  + step.id" class="my-2 mr-2 step-handle d-flex align-center">
          <font-awesome-icon
            class="body-1 mr-2"
            :icon="['fal', 'arrows']"
          >
          </font-awesome-icon>
          <div class="step-container pa-2">
            {{ step.position || step.display }}
            {{ step.title }}
            <AppButton v-if="removable" icon @click="removeStep(step)">
              <font-awesome-icon :icon="['fas', 'circle-xmark']"></font-awesome-icon>
            </AppButton>
          </div>
        </div>
      </template>
      <p v-else-if="!sidebar" key="hint" class="text-center w-100" >{{$t('project.signing-checklist.SigningChecklistOrderSteps.dragHint')}}</p>
      <p v-else-if="sidebar" key="sidebar-empty-hint" class="text-center w-100">{{$t('project.signing-checklist.SigningChecklistOrderSteps.emptySidebar')}}</p>
    </transition-group>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

import AppButton from '../../common/buttons/AppButton.vue'

export default {
  name: 'SigningChecklistOrderSteps',
  components: {
    draggable,
    AppButton,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    move: {
      type: Function,
      default: () => {},
    },
    removable: {
      type: Boolean,
      default: false,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedSteps: {
      get () {
        return this.value
      },
      set (change) {
        this.$emit('input', change)
      },
    },
  },
  methods: {
    removeStep (step) {
      this.$emit('remove-step', step)
    },
  },
}
</script>

<style lang="scss" scoped>
.step-container {
  border: 2px solid #bdbdbd;
  border-left: 4px solid #cc453e;
  border-radius: 6px;
  word-break: break-all;
}

.step-handle {
  cursor: move;
}
</style>
