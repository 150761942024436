<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('common.continue')"
             size="dialog-xs"
             @ok="onConfirm"
             @cancel="closeDialog"
  >
    <template #title>
      {{ $t(`${TRANSLATION_KEY_PREFIX}.title`) }}
    </template>
    <template #body>
      <div class="d-flex flex-column row-gap-4">
        <p>
          {{ $t(`${TRANSLATION_KEY_PREFIX}.text.action.${currentAction}`) }}
          {{ $t(`${TRANSLATION_KEY_PREFIX}.text.warning`) }}
        </p>
        <p>{{ $t(`${TRANSLATION_KEY_PREFIX}.text.outro`) }}</p>
      </div>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'

const TRANSLATION_KEY_PREFIX = 'common.dialogs.DocumentOnGoingEditionDialog'

export default {
  name: 'DocumentOnGoingEditionDialog',
  components: { AppDialog },
  emits: ['confirm'],
  mixins: [dialogMixin],
  props: {
    currentAction: {
      type: String,
      default: 'edit',
    },
    documents: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
    }
  },
  methods: {
    onConfirm () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
