<template>
  <div v-if="selectedDocument">
    <v-row>
      <v-col class="text-h4">{{$t('project.signing-checklist.pane.SigningChecklistPaneShareAttachment.selectedDocument')}}</v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <v-chip outlined>
          <ClosdFildersIcon :document="selectedDocument" size="22"/>
          <span class="SigningChecklistAttachmentShare-filename">
            {{selectedDocument.basename}}
          </span>
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-radio-group v-model="shareType"
                       class="mt-0"
                       hide-details
        >
          <v-radio :value="SHARE_TYPE_OPTIONS.PUBLIC"
                   class="SigningChecklistAttachmentShare-radio mb-0 px-3"
                   active-class="SigningChecklistAttachmentShare-radio--active"
          >
            <template #label>
              <div class="d-flex flex-column text--primary ml-2">
                <div class="text-h3">{{$t('project.signing-checklist.pane.SigningChecklistPaneShareAttachment.publicTitle')}}</div>
                <div class="subtitle-1 text--secondary">{{$t('project.signing-checklist.pane.SigningChecklistPaneShareAttachment.publicSubtitle')}}</div>
              </div>
            </template>
          </v-radio>
          <v-radio :value="SHARE_TYPE_OPTIONS.CUSTOM"
                   class="SigningChecklistAttachmentShare-radio px-3"
                   active-class="SigningChecklistAttachmentShare-radio--active"
          >
            <template #label>
              <div class="d-flex flex-column text--primary ml-2">
                <div class="text-h3">{{$t('project.signing-checklist.pane.SigningChecklistPaneShareAttachment.customTitle')}}</div>
                <div class="subtitle-1 text--secondary">{{$t('project.signing-checklist.pane.SigningChecklistPaneShareAttachment.customSubtitle')}}</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row v-if="pane.isOpen && shareType === shareTypeOptions.CUSTOM && groups"
           class="mt-0"
    >
      <v-col>
        <DocumentSharePicker :force-pm-selection="true" />
      </v-col>
    </v-row>

    <v-divider />

    <v-row>
      <v-col>
        <v-switch v-model="sendNotification"
                  hide-details
                  :label="$t('project.signing-checklist.pane.SigningChecklistPaneShareAttachment.sendNotification')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <VisualTextEditor ref="notifyMessageInput"
                          v-model="notifyMessage"
                          counter="footer"
                          :error-messages="notifyMessageErrors"
                          :max-length="notifyMessageMaxLength"
                          :placeholder="$t('project.signing-checklist.pane.SigningChecklistPaneShareAttachment.optionalMessageWithCopy')"
        />
      </v-col>
    </v-row>

    <v-row class="mt-n2">
      <v-col>
        <AppButton color="primary"
                   outlined
                   block
                   @click="closePane"
        >
          {{$t('common.cancel')}}
        </AppButton>
      </v-col>
      <v-col>
        <AppButton color="primary"
                   block
                   :disabled="shareDisabled"
                   :loading="shareAttachmentPending"
                   @click="shareAttachmentFile"
        >
          {{$t('common.share')}}
        </AppButton>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import DocumentSharePicker from '@/common/document-share/DocumentSharePicker'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import { CLOSE_PANE, SHARE_ATTACHMENT_FILE } from '@/store/modules/signing-checklist/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import { escapeHtml } from '../../../common/utils/strings'
import {
  INIT_SELECTED_GROUPS,
  INIT_SELECTED_MEMBERS,
} from '../../../store/modules/document-share-picker/action_types'

const NOTIFY_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'SigningChecklistPaneShareAttachment',
  components: { AppButton, ClosdFildersIcon, DocumentSharePicker, VisualTextEditor },
  data () {
    return {
      notifyMessage: '',
      notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
      sendNotification: true,
      SHARE_TYPE_OPTIONS,
      shareTypeValue: '',
    }
  },
  computed: {
    ...mapState('groups', ['groups']),
    ...mapState('signingChecklist', ['shareAttachmentPending', 'pane']),
    notifyMessageLength () {
      return escapeHtml(this.notifyMessage).length
    },
    notifyMessageIsTooLong () {
      return this.notifyMessageLength > this.notifyMessageMaxLength
    },
    notifyMessageErrors () {
      const errors = []

      if (this.notifyMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    selectedDocument () {
      if (!this.pane?.fileToShare) {
        return null
      }

      const splicedFilename = this.pane.fileToShare.name.split('.')
      const fileExt = splicedFilename.pop()

      return {
        basename: splicedFilename.join('.'),
        ext: fileExt,
      }
    },
    shareDisabled () {
      return this.notifyMessageIsTooLong
    },
    shareType: {
      get () {
        if (this.shareTypeValue) {
          return this.shareTypeValue
        }

        if (this.pane.fileToShare) {
          return this.pane.fileToShare.rights.read.isPublic
            ? SHARE_TYPE_OPTIONS.PUBLIC
            : SHARE_TYPE_OPTIONS.CUSTOM
        }

        return SHARE_TYPE_OPTIONS.CUSTOM
      },
      set (val) {
        this.shareTypeValue = val
        if (val === SHARE_TYPE_OPTIONS.CUSTOM) {
          this.INIT_SELECTED_GROUPS(this.pane.fileToShare.rights.read.groups)
          this.INIT_SELECTED_MEMBERS(this.pane.fileToShare.rights.read.users)
        }
      },
    },
  },
  watch: {
    'pane.isOpen' (isOpen) {
      if (isOpen) {
        this.sendNotification = true
        this.notifyMessage = ''

        if (this.pane.fileToShare?.rights) {
          this.INIT_SELECTED_GROUPS(this.pane.fileToShare.rights.read.groups)
          this.INIT_SELECTED_MEMBERS(this.pane.fileToShare.rights.read.users)
        }

        if (this.$refs.notifyMessageInput) {
          this.$refs.notifyMessageInput.clear()
        }
      }
    },
  },
  methods: {
    ...mapActions('signingChecklist', [CLOSE_PANE, SHARE_ATTACHMENT_FILE]),
    ...mapActions('documentSharePicker', [
      INIT_SELECTED_GROUPS,
      INIT_SELECTED_MEMBERS,
    ]),
    closePane () {
      this.CLOSE_PANE()
    },
    async shareAttachmentFile () {
      try {
        await this.SHARE_ATTACHMENT_FILE({
          notifyMessage: this.notifyMessage,
          sendNotification: this.sendNotification,
          shareType: this.shareType,
        })

        this.closePane()
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.signing-checklist.pane.SigningChecklistPaneShareAttachment.shareAttachmentFileSuccess'),
        })
      } catch (error) {
        console.error(error)

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signing-checklist.pane.SigningChecklistPaneShareAttachment.shareAttachmentFileError'),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.SigningChecklistAttachmentShare {
  &-filename {
    max-width: 350px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-radio {
    border-left: 7px solid transparent;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 14px;
    padding-bottom: 14px;

    &--active {
      border-left-color: var(--v-primary-base);
      background-color: var(--v-primary-lighten4);
    }
  }
}
</style>
