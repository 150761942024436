<template>
  <div class="SignIn" :class="{ 'fill-height': subscriptionBrandingBackgroundUrl }">
    <div v-if="signingFromSubdomain" class="subscription-branding-background"
        :style="{ 'background-image': subscriptionBrandingBackgroundUrl, 'opacity': subscriptionBrandingBackgroundOpacity }">
    </div>
    <div class="SignIn__container px-4">
      <div class="SignIn__topbar">
        <LoginTopbar/>
      </div>

      <div class="SignIn__content">
        <div v-if="!signingFromSubdomain && $vuetify.breakpoint.mdAndUp">
          <ClosdTagLine/>
        </div>

        <div>
          <AppCard class="SignIn__form">
            <div v-if="signingFromSubdomain">
              <v-img :src="subscriptionBranding && subscriptionBranding.logo"
                     contain
                     width="240"
                     class="mx-auto"
              />
            </div>

            <div class="d-flex flex-column row-gap-8">
              <h2 class="text-h2 font-weight-medium text-center mt-4">{{ $t('login.views.SignIn.title') }}</h2>

              <v-alert v-if="showCredentialError"
                       dense
                       outlined
                       type="error"
              >
                {{ $t('login.views.SignIn.wrongCredential') }}
              </v-alert>

              <v-alert v-if="showExpiredError"
                       dense
                       outlined
                       type="error"
              >
                {{ $t('login.views.SignIn.expiredCredential') }}
              </v-alert>

              <v-alert v-if="showOtherSessionStartedMessage"
                       outlined
                       border="left"
                       type="warning"
              >
                {{ $t('login.views.SignIn.otherSessionStartedInfo') }}
              </v-alert>

              <v-alert v-if="showLogOutMessage && !showVoluntaryLogOutMessage"
                       outlined
                       border="left"
                       type="warning"
              >
                {{ $t('login.views.SignIn.logOutInfo') }}
              </v-alert>

              <v-alert v-if="isTokenInvalid"
                       outlined
                       border="left"
                       type="warning"
              >
                {{ $t('login.views.SignIn.InvalidTokenInfo') }}
              </v-alert>

              <AppTextField v-model.trim="login.email"
                            :label="$t('login.views.SignIn.email')"
                            type="email"
                            autofocus
                            :error-messages="emailErrors"
                            maxlength="255"
                            @blur="validateEmail"
                            @keypress.enter="prepareSignIn"
              />

              <AppTextField v-show="isPasswordFieldVisible"
                            v-model="login.password"
                            :type="displayPassword ? 'text' : 'password'"
                            :label="$t('login.views.SignIn.password')"
                            autofocus
                            :error-messages="passwordErrors"
                            @blur="validatePassword"
                            @keypress.enter="prepareSignIn"
              >
                <template #append>
                  <AppButton icon
                             @click="displayPassword = !displayPassword"
                  >
                    <font-awesome-icon v-if="displayPassword" :icon="['fas', 'eye']"></font-awesome-icon>
                    <font-awesome-icon v-else :icon="['fas', 'eye-slash']"></font-awesome-icon>
                  </AppButton>
                </template>
              </AppTextField>

              <v-checkbox
                v-model.trim="login.rememberMe"
                :label="$t('login.views.SignIn.rememberMe')"
                outlined
                color="primary"
                class="mt-n2"
                dense
                @change="setRememberMe"
              ></v-checkbox>

              <template v-if="step === 1">
                <AppButton color="primary"
                           class="align-self-center"
                           :loading="loading"
                           @click="prepareSignIn"
                >
                  {{ $t('login.views.SignIn.next') }}
                </AppButton>
              </template>
              <template v-if="step === 2">
                <AppButton color="primary"
                           class="align-self-center"
                           :loading="loading || signinPending"
                           @click="prepareSignIn"
                >
                  {{ $t('login.views.SignIn.login') }}
                </AppButton>
              </template>

              <div class="SignIn__forgot-password-link mt-n4">
                <router-link :to="{ name: FORGOT_PASSWORD_ROUTE_NAME }">
                  {{ $t('login.views.SignIn.forgotPassword') }}
                </router-link>
              </div>
            </div>

            <div v-if="signingFromSubdomain">
              <v-img src="/img/logo.svg"
                     contain
                     width="90"
                     class="mx-auto my-4"
              />
            </div>
          </AppCard>

          <BackToClosd/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import { emailFormatter } from '@/common/utils/strings'
import { navigatingOnAvailableSubdomain, urlPathJoin } from '@/common/utils/url'
import { SAML_CHECK, SIGN_IN } from '@/store/modules/login/action_types'

import AppCard from '../../common/AppCard'
import AppTextField from '../../common/AppTextField'
import AppButton from '../../common/buttons/AppButton'
import { Login } from '../../models/login.model'
import { FORGOT_PASSWORD_ROUTE_NAME } from '../../router'
import { SET_STEP } from '../../store/modules/login/mutation_types'
import BackToClosd from '../BackToClosd'
import ClosdTagLine from '../ClosdTagLine'
import LoginTopbar from '../LoginTopbar'

export default {
  name: 'SignIn',
  components: {
    AppButton,
    AppTextField,
    AppCard,
    ClosdTagLine,
    LoginTopbar,
    BackToClosd,
  },
  mixins: [validationMixin],
  validations: {
    login: {
      email: {
        required,
        email: (val) => email(emailFormatter(val)),
      },
      password: { required },
    },
  },
  props: {
    isLogOut: {
      type: [Boolean, String],
      required: false,
    },
    isVoluntaryLogOut: {
      type: [Boolean, String],
      required: false,
    },
    isTokenInvalid: {
      type: Boolean,
      default: false,
    },
    otherSessionStarted: {
      type: [Boolean, String],
      default: false,
    },
    returnRoute: {
      // May be a fullPath string instead of a route object if from query
      type: [Object, String],
      required: false,
    },
    redirectUrl: {
      type: String,
    },
  },
  data () {
    return {
      login: new Login(),
      displayPassword: false,
      showCredentialError: false,
      showExpiredError: false,
      showLogOutMessage: this.isLogOut && this.isLogOut !== 'false',
      showVoluntaryLogOutMessage: this.isVoluntaryLogOut && this.isVoluntaryLogOut !== 'false',
      showOtherSessionStartedMessage: this.otherSessionStarted && this.otherSessionStarted !== 'false',
      signingFromSubdomain: false,
    }
  },
  computed: {
    ...mapState('login', ['loading', 'signinPending', 'step']),
    ...mapState('user', ['profile']),
    ...mapState('subscription', ['subscriptionBranding']),
    subscriptionBrandingBackgroundUrl () {
      if (!this.subscriptionBranding?.background) return ''
      return `url(${this.subscriptionBranding.background})`
    },
    subscriptionBrandingBackgroundOpacity () {
      if (!this.subscriptionBranding?.backgroundOpacity) return ''
      return `${this.subscriptionBranding.backgroundOpacity}%`
    },
    isPasswordFieldVisible () {
      return !this.signinPending && this.step === 2
    },
    submitButtonStep1Disabled () {
      return !this.$v.login.email.$dirty || this.$v.login.email.$anyError
    },
    submitButtonStep2Disabled () {
      return !this.$v.login.password.$dirty || this.$v.login.password.$anyError
    },
    emailErrors () {
      const errors = []
      if (!this.$v.login.email.$dirty) return errors
      !this.$v.login.email.email && errors.push(this.$t('common.validations.email'))
      !this.$v.login.email.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('login.views.SignIn.email') }))
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.login.password.$dirty) return errors
      !this.$v.login.password.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('login.views.SignIn.password') }))
      return errors
    },
    returnRouteFullPath () {
      if (typeof this.returnRoute === 'object') {
        return this.returnRoute.fullPath
      }

      if (typeof this.returnRoute === 'string') {
        return this.returnRoute
      }

      return null
    },
  },
  mounted () {
    const rememberMeCookie = this.$cookies.get('closd_rememberMe')
    if (rememberMeCookie) {
      this.login.email = rememberMeCookie
      this.login.rememberMe = true
      this.prepareSignIn()
    }
  },
  created () {
    const natixisCookie = this.$cookies.get('closd_ipRange')
    if (natixisCookie && natixisCookie === 'Ntx') {
      var intendedUrl = ''
      if (this.redirectUrl) {
        intendedUrl = this.redirectUrl
      } else if (this.returnRouteFullPath) {
        intendedUrl = urlPathJoin(process.env.VUE_APP_CLIENT_URL, this.returnRouteFullPath)
      }
      window.location = process.env.VUE_APP_NATIXIS_SSO_URL + '?noJson&intendedUrl=' + intendedUrl
    }
    this.FORGOT_PASSWORD_ROUTE_NAME = FORGOT_PASSWORD_ROUTE_NAME
    this.signingFromSubdomain = navigatingOnAvailableSubdomain()
  },
  methods: {
    ...mapActions('login', [SAML_CHECK, SIGN_IN]),
    validateEmail () {
      this.$v.login.email.$touch()
    },
    validatePassword () {
      this.$v.login.password.$touch()
    },
    async prepareSignIn () {
      if (this.loading) {
        return
      }
      try {
        if (this.step === 1) {
          this.$v.login.email.$touch()
        } else {
          this.$v.login.password.$touch()
        }
        if (!this.$v.login.$anyError) {
          const data = {}
          data.email = this.login.email
          if (this.redirectUrl) {
            data.intendedUrl = this.redirectUrl
          } else if (this.returnRouteFullPath) {
            data.intendedUrl = urlPathJoin(process.env.VUE_APP_CLIENT_URL, this.returnRouteFullPath)
          }
          await this.SAML_CHECK(data)
          if (this.login.rememberMe) {
            this.$cookies.set('closd_rememberMe', this.login.email, '30d')
          }
          if (this.step === 2 && this.$v.login.password.$dirty) {
            await this.SIGN_IN({
              login: this.login,
              redirectRoute: this.returnRouteFullPath,
              redirectUrl: this.redirectUrl,
            })
          }
          this.$store.commit(`login/${SET_STEP}`, 2)
        }
      } catch (e) {
        if (e.response?.status === 401) {
          this.clearError()
          this.showExpiredError = true
        } else {
          this.clearError()
          this.showCredentialError = true
        }
      }
    },
    setRememberMe () {
      if (!this.login.rememberMe) {
        this.$cookies.remove('closd_rememberMe')
      }
    },
    clearError () {
      this.showCredentialError = false
      this.showExpiredError = false
      this.showLogOutMessage = false
      this.showOtherSessionStartedMessage = false
    },
  },
}
</script>

<style scoped lang="scss">
.SignIn {
  position: relative;

  &__container {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }

  &__topbar {
    padding-top: 1rem;
  }

  &__content {
    margin-top: 10%;
    display: flex;
    align-items: center;
    column-gap: 4rem;
    padding: 0;

    & > * {
      flex: 1;
    }
  }

  &__form {
    max-width: 600px;
    min-height: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__forgot-password-link {
    text-align: center;

    a {
      text-decoration: none;
      color: var(--v-grey-darken1);
    }
  }
}

.subscription-branding-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  pointer-events: none;
}
</style>
