export const GET_USER = 'GET_USER'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_NOTIFICATION_PREFERENCES = 'UPDATE_NOTIFICATION_PREFERENCES'
export const UPDATE_NOTIFICATION_MUTE_EMAILS = 'UPDATE_NOTIFICATION_MUTE_EMAILS'
export const GET_CURRENT_NOTIFICATION_PREFERENCES = 'GET_CURRENT_NOTIFICATION_PREFERENCES'
export const CREATE_NOTIFICATION_PREFERENCES = 'CREATE_NOTIFICATION_PREFERENCES'
export const DELETE_NOTIFICATION_PREFERENCES = 'DELETE_NOTIFICATION_PREFERENCES'
export const GET_SELF_CONTACT_LIST = 'GET_SELF_CONTACT_LIST'
export const POST_ID_CHECK = 'POST_ID_CHECK'
export const GET_ID_CHECK_STATE = 'GET_ID_CHECK_STATE'
export const GET_ACTIVITY_POLLING = 'GET_ACTIVITY_POLLING'
export const GET_ZENDESK_AUTH = 'GET_ZENDESK_AUTH'
export const GET_ALIASES = 'GET_ALIASES'
export const ADD_NEW_ALIAS = 'ADD_NEW_ALIAS'
export const VALIDATE_USER_ALIAS = 'VALIDATE_USER_ALIAS'
export const RESEND_ALIAS_CHECK_LINK = 'RESEND_ALIAS_CHECK_LINK'
export const DELETE_USER_ALIAS = 'DELETE_USER_ALIAS'
