<template>
  <AppVersionCheckerAlert :show="appHashChanged" @close="closeAppVersionAlert" @reload="reloadApp" />
</template>

<script>
import AppVersionCheckerAlert from '@/common/AppVersionCheckerAlert.vue'

const APP_VERSION_CHECK_INTERVAL = 1000 * 60 * 5
const APP_VERSION_FILE_PATH = '/version.json'

export default {
  name: 'AppVersionChecker',
  components: { AppVersionCheckerAlert },
  data () {
    return {
      appCurrentHash: '',
      appHashChanged: false,
      appNewHash: '',
      appVersionCheckIntervalId: 0,
    }
  },
  methods: {
    async checkAppVersion () {
      try {
        const fileResponse = await axios.create({ baseURL: '' }).get(`${APP_VERSION_FILE_PATH}?t=${new Date().getTime()}`)
        this.appNewHash = fileResponse.data.hash || ''
        this.appHashChanged = this.appCurrentHash !== this.appNewHash
        if (this.appHashChanged) clearInterval(this.appVersionCheckIntervalId)
      } catch (e) {
        console.error(e)
        console.error('App version check failed')
      }
    },
    closeAppVersionAlert () {
      this.appHashChanged = false
    },
    initAppVersionCheck (frequency) {
      return setInterval(() => {
        this.checkAppVersion()
      }, frequency)
    },
    reloadApp () {
      this.$emit('reload')
      this.appCurrentHash = this.appNewHash
      this.appHashChanged = false
      window.location.reload()
    },
  },
  created () {
    if (process.env.NODE_ENV === 'production') {
      this.appCurrentHash = process.env.VUE_APP_REVISION.replace(/\n/g, '')
      this.appVersionCheckIntervalId = this.initAppVersionCheck(APP_VERSION_CHECK_INTERVAL)
    }
  },
  beforeDestroy () {
    clearInterval(this.appVersionCheckIntervalId)
  },
}
</script>
