<template>
  <div>
    <v-chip v-if="dateFilter"
            class="mr-4"
            close
            close-icon="fal fa-xmark"
            @click:close="removeDateFilters"
    >
      <span v-html="dateFilter"></span>
    </v-chip>
    <v-chip v-if="signatoriesFilter"
            close
            close-icon="fal fa-xmark"
            @click:close="setFiltersSignatories([])"
    >
      <span v-html="signatoriesFilter"></span>
    </v-chip>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { ISOToShortenedDate } from '../../common/utils/dates'

export default {
  name: 'SignedDocumentsHeaderFilters',
  computed: {
    ...mapState('signedDocuments', ['filters']),
    dateFilter () {
      let string = null
      if (this.filters.startDate && this.filters.endDate) {
        string = this.$t('project.signed-documents.SignedDocumentsHeaderFilters.fullDate', { startDate: ISOToShortenedDate(this.filters.startDate), endDate: ISOToShortenedDate(this.filters.endDate) })
      } else if (this.filters.startDate) {
        string = this.$t('project.signed-documents.SignedDocumentsHeaderFilters.startDateOnly', { startDate: ISOToShortenedDate(this.filters.startDate) })
      }
      return string
    },
    signatoriesFilter () {
      let string = null
      if (this.filters.signatories.length > 0) {
        string = this.$tc('project.signed-documents.SignedDocumentsHeaderFilters.signatories', this.filters.signatories.length, { signatoriesCount: this.filters.signatories.length })
      }
      return string
    },
  },
  methods: {
    ...mapActions('signedDocuments', ['setFiltersStartDate', 'setFiltersEndDate', 'setSignDateModel', 'setFiltersSignatories']),
    removeDateFilters () {
      this.setFiltersStartDate(null)
      this.setFiltersEndDate(null)
      this.setSignDateModel([])
    },
  },
}
</script>
