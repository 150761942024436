<template>
  <div class="summaries-loader">
    <v-skeleton-loader type="image" />
    <v-skeleton-loader type="image" />
    <v-skeleton-loader type="image" />
    <v-skeleton-loader type="image" />
    <v-skeleton-loader type="image" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DocumentsAISummaryLoadingState',
})
</script>

<style scoped lang="scss">
.summaries-loader {
  display: grid;
  grid-template-columns: 28px 392px auto;
  grid-template-rows: 40px 88px auto 40px;
  gap: 24px;

  .v-skeleton-loader:nth-child(1),
  .v-skeleton-loader:nth-child(5) {
    grid-column-start: span 3;
  }

  .v-skeleton-loader:nth-child(2) {
    grid-column: 2 / span 1;
  }

  .v-skeleton-loader:nth-child(3) {
    grid-column-start: 1;

    ::v-deep .v-skeleton-loader__bone {
      height: 28px;
    }
  }

  .v-skeleton-loader:nth-child(4) {
    ::v-deep .v-skeleton-loader__bone {
      height: 100%;
    }
  }
}
</style>
