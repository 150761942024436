<template>
  <v-row class="ma-0">
    <v-col cols="12" :class="{ 'pl-2 pr-5': $vuetify.breakpoint.mobile }">
      <v-row
        :class="{
          'pr-14 pl-2': $vuetify.breakpoint.mobile,
          'px-14': !$vuetify.breakpoint.mobile,
        }"
      >
        <h2 class="font-weight-bold text-h2">{{ $t("profile.views.PersonalInformation.personalInfo") }}</h2>
      </v-row>
      <v-row
        :class="{
          'pr-14 pl-2': $vuetify.breakpoint.mobile,
          'px-14': !$vuetify.breakpoint.mobile,
        }"
      >
        <div class="mb-10" v-html="$t('profile.views.PersonalInformation.requiredFields')"></div>
      </v-row>
      <v-form :disabled="updateProfilePending" @submit.prevent="submit">
        <v-row :class="{ 'px-6': !$vuetify.breakpoint.mobile }">
          <v-col
            cols="12"
            sm="6"
            class="px-10 pb-0"
            :class="{ 'pl-5': $vuetify.breakpoint.mobile }"
          >
            <v-row>
              <AppTextField
                v-model.trim="model.firstName"
                autocomplete="given-name"
                :label="$t('common.firstName')"
                required
                class="required"
                :hide-details="false"
                maxlength="50"
              ></AppTextField>
            </v-row>
            <v-row>
              <AppTextField
                v-model.trim="model.birthName"
                :label="$t('common.birthName')"
                :hide-details="false"
                maxlength="50"
              ></AppTextField>
            </v-row>
            <v-row>
              <AppTextField
                disabled
                :value="model.email"
                :label="$t('common.email')"
                :hide-details="false"
              ></AppTextField>
            </v-row>
            <v-row>
              <!-- Kept as is for legacy, could be changed to AppDatePicker -->
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <AppTextField
                    :value="formattedBirthDate"
                    readonly
                    :label="$t('common.birthDate')"
                    v-bind="attrs"
                    autocomplete="bday"
                    :hide-details="false"
                    v-on="on"
                  >
                    <template v-slot:append>
                      <AppTooltip bottom max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small v-bind="attrs" v-on="on">
                            fa-circle-question
                          </v-icon>
                        </template>
                        <span>{{ $t("profile.views.PersonalInformation.infoRequiredForIdCheck") }}</span>
                      </AppTooltip>
                    </template>
                  </AppTextField>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="model.birthDate"
                  color="primary"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1920-01-01"
                  :locale="$vuetify.lang.current"
                  @change="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="px-10"
            :class="{
              'pl-5': $vuetify.breakpoint.mobile,
              'pt-0': $vuetify.breakpoint.xs,
            }"
          >
            <v-row>
              <AppTextField
                v-model.trim="model.lastName"
                autocomplete="family-name"
                :label="$t('profile.views.PersonalInformation.usageLastName')"
                :hide-details="false"
                maxlength="50"
              >
                <template v-slot:append>
                  <AppTooltip bottom max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">
                        fa-circle-question
                      </v-icon>
                    </template>
                    <span>{{ $t("profile.views.PersonalInformation.usageLastNameExample") }}</span>
                  </AppTooltip>
                </template>
              </AppTextField>
            </v-row>
            <v-row>
              <AppTextField
                disabled
                :value="initials"
                :label="$t('common.initials')"
                :hide-details="false"
              >
                <template v-slot:append>
                  <AppTooltip bottom max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">
                        fa-circle-question
                      </v-icon>
                    </template>
                    <span>{{ $t("profile.views.PersonalInformation.infoUsedForSigning") }}</span>
                  </AppTooltip>
                </template>
              </AppTextField>
            </v-row>
            <v-row>
              <AppPhoneField
                :value="model.telephone"
                class="w-100"
                :label="$t('common.phoneNumber')"
                :hide-details="false"
                @input="handlePhoneChange"
              >
                <template v-slot:append>
                  <AppTooltip bottom max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">
                        fa-circle-question
                      </v-icon>
                    </template>
                    <span>{{ $t("profile.views.PersonalInformation.infoForSigningAuth") }}</span>
                  </AppTooltip>
                </template>
              </AppPhoneField>
            </v-row>
            <v-row>
              <AppSelect
                v-model="model.gender"
                :items="genders"
                autocomplete="sexe"
                :label="$t('common.gender')"
                :hide-details="false"
              >
                <template v-slot:append>
                  <AppTooltip bottom max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">
                        fa-circle-question
                      </v-icon>
                    </template>
                    <span>{{ $t("profile.views.PersonalInformation.infoRequiredForIdCheck") }}</span>
                  </AppTooltip>
                </template>
              </AppSelect>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider class="mb-10 pb-3 mt-6"></v-divider>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="px-10 py-0"
                :class="{ 'pl-5': $vuetify.breakpoint.mobile }"
              >
                <v-row>
                  <AppTextField
                    v-model.trim="model.company"
                    autocomplete="organization"
                    :label="$t('common.company')"
                    :hide-details="false"
                    maxlength="100"
                  ></AppTextField>
                </v-row>
              </v-col>
              <v-col
                v-if="profile.subscription"
                cols="12"
                sm="6"
                class="px-10 py-0"
                :class="{ 'pl-5': $vuetify.breakpoint.mobile }"
              >
                <v-row>
                  <AppTextField
                    disabled
                    :value="profile.subscription.name"
                    :label="$t('profile.views.PersonalInformation.subscription')"
                    :hide-details="false"
                  ></AppTextField>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="mt-8 px-10">
          <v-btn
            color="primary"
            :disabled="updateProfilePending"
            :loading="updateProfilePending"
            @click="submit"
          >{{ $t("profile.views.PersonalInformation.submit") }}</v-btn>
        </div>
        <input type="submit" hidden />
      </v-form>
    </v-col>
    <AppDialog :is-open="unsavedChangesModal"
               :ok-text="$t('profile.views.PersonalInformation.unsavedChangesModalConfirm')"
               :cancel-text="$t('profile.views.PersonalInformation.unsavedChangesModalCancel')"
               @cancel="cancelUnsavedChanges"
               @ok="confirmUnsavedChanges"
    >
      <template #title>
        {{$t('profile.views.PersonalInformation.unsavedChangesModalTitle')}}
      </template>
      <template #body>
        {{$t('profile.views.PersonalInformation.unsavedChangesModalBody')}}
      </template>
    </AppDialog>
    <AppDialog :is-open="idCheckChangesModal"
               @cancel="idCheckChangesModal = false"
               @ok="confirmIdCheckChanges"
    >
      <template #title>
        {{$t('profile.views.PersonalInformation.idCheckChangesModalTitle')}}
      </template>
      <template #body>
        {{$t('profile.views.PersonalInformation.idCheckChangesModalBody')}}
      </template>
    </AppDialog>
  </v-row>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'

import { ISOToShortenedDate, ISOToYYYYMMDD } from '@/common/utils/dates'
import { LOGOUT_ROUTE_NAME } from '@/router'
import { UPDATE_PROFILE } from '@/store/modules/user/action_types'
import { SET_USER_PROFILE } from '@/store/modules/user/mutation_types'

import AppPhoneField from '../../common/AppPhoneField.vue'
import AppSelect from '../../common/AppSelect'
import AppTextField from '../../common/AppTextField.vue'
import AppTooltip from '../../common/AppTooltip'
import AppDialog from '../../common/dialogs/AppDialog'

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('user')
const PROFILE_KEYS = [
  'firstName',
  'lastName',
  'email',
  'birthDate',
  'birthName',
  'gender',
  'company',
  'telephone',
]
const IDCHECK_PROFILE_KEYS = [
  'firstName',
  'lastName',
  'birthDate',
  'birthName',
  'gender',
]
export default {
  name: 'PersonalInformation',
  components: {
    AppDialog,
    AppSelect,
    AppPhoneField,
    AppTextField,
    AppTooltip,
  },
  data () {
    return {
      unsavedChangesModal: false,
      idCheckChangesModal: false,
      genders: [
        {
          text: this.$t('common.male'),
          value: 'M',
        },
        {
          text: this.$t('common.female'),
          value: 'F',
        },
      ],
      model: {
        firstName: '',
        lastName: '',
        birthDate: '',
        birthName: '',
        gender: '',
        company: '',
        email: '',
        telephone: '',
      },
      menu: false,
    }
  },
  computed: {
    ...mapGetters('user', ['canCurrentUserSign']),
    ...mapState(['profile', 'updateProfilePending']),
    formattedBirthDate () {
      return ISOToShortenedDate(this.model.birthDate)
    },
    computedBirthDateValue: {
      get () {
        if (this.model.birthDate) {
          return ISOToYYYYMMDD(this.model.birthDate)
        }
        return ISOToYYYYMMDD(Date.now())
      },
      set (val) {
        this.model.birthDate = val
      },
    },
    initials () {
      const { firstName, lastName } = this.model
      return `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`
    },
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  created () {
    const {
      firstName,
      lastName,
      email,
      birthDate,
      birthName,
      gender,
      company,
      telephone,
    } = this.profile
    this.model = {
      firstName,
      lastName,
      birthDate,
      email,
      birthName,
      gender,
      company,
      telephone,
    }
  },
  beforeRouteLeave (to, from, next) {
    const isNavigationToLogout = to.name === LOGOUT_ROUTE_NAME
    if (isNavigationToLogout) {
      next()
    }

    const unsavedChanges = this.isModelDifferentFromSource(PROFILE_KEYS)
    if (unsavedChanges) {
      this.unsavedChangesModal = true
      this.unsavedChangesConfirmCallback = next
      next(false)
    } else {
      next()
    }
  },
  methods: {
    ...mapActions([UPDATE_PROFILE]),
    ...mapMutations([SET_USER_PROFILE]),
    handlePhoneChange (input, event) {
      this.model.telephone = event.number.e164 || input
    },
    async submit () {
      if (this.canCurrentUserSign && this.isModelDifferentFromSource(IDCHECK_PROFILE_KEYS)) {
        this.idCheckChangesModal = true
      } else {
        this.saveUser()
      }
    },
    async saveUser () {
      try {
        await this.UPDATE_PROFILE({
          ...this.model,
          initials: this.initials,
        })

        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.$t('profile.views.PersonalInformation.confirmNotification'),
        })
      } catch (e) {
        // TODO: Do something custom ?
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    /**
     * Checks if the model is different from the original source by checking the keys parameter of both the model and source
     * @param {array} keys The property keys to check for difference
     * @returns {boolean} True if any property from the keys param is different between the model and source, false otherwise
     */
    isModelDifferentFromSource (keys) {
      for (const key of keys) {
        if (this.model[key] !== this.profile[key]) {
          return true
        }
      }

      return false
    },
    confirmUnsavedChanges () {
      this.saveUser()
      this.unsavedChangesConfirmCallback()
    },
    cancelUnsavedChanges () {
      this.unsavedChangesConfirmCallback()
    },
    async confirmIdCheckChanges () {
      this.idCheckChangesModal = false
      await this.saveUser()
      if (Array.isArray(this.profile.missingInfoToSign)) {
        if (!this.profile.missingInfoToSign.includes('idCheck')) {
          this.profile.missingInfoToSign.push('idCheck')
        }
      } else {
        this.profile.missingInfoToSign = ['idCheck']
      }
      this.SET_USER_PROFILE(this.profile)
    },
  },
}
</script>
