<template>
  <v-layout class="PrepareSignatureSidebar" column fill-height>
    <div v-if="!isTemplate" class="PrepareSignatureSidebar-actions">
      <app-button :disabled="isReadOnly"
                  full-width
                  type="tonal"
                  variant="project"
                  @click="onAddSigners"
      >
        <font-awesome-icon class="mr-2" :icon="['fas', 'plus']"></font-awesome-icon>
        {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebar.addSignatories') }}
      </app-button>
      <app-button :disabled="isReadOnly"
                  full-width
                  type="tonal"
                  variant="project"
                  @click="onEditSignersOrder"
      >
        <font-awesome-icon class="mr-2" :icon="['fas', 'pen']"></font-awesome-icon>
        {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebar.editSignersOrder') }}
      </app-button>
      <app-button
       :disabled="isReadOnly"
        full-width
        type="tonal"
        variant="project"
        @click="onAddAutoTags"
      >
        <font-awesome-icon class="mr-2" :icon="['fal', 'bullseye-arrow']"></font-awesome-icon>
        {{$t('project.signing-checklist.prepare-signature.PrepareSignatureSidebar.automaticTagPlacement')}}
      </app-button>
    </div>
    <div>
      <template v-if="hasSignersGroup">
        <div>
          <div class="PrepareSignatureSidebar-section">
            <app-text as="span" variant="small-bold">{{ hasCommonSigners ? $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebar.commonSigners') : $tc('common.signer', 2) }}</app-text>
          </div>
          <template v-for="(signerGroup, groupIndex) in signersGroups">
            <app-text v-if="signersGroups.length > 1"
                      :key="`title-${groupIndex}`"
                      variant="small-bold"
                      class="pl-4 pt-4 black--text"
            >
              {{ `${$t('common.group')} #${groupIndex + 1}` }}
            </app-text>

            <v-expansion-panels :key="`panel-${groupIndex}`" :readonly="isReadOnly" :value="openedSignerPanelIndex" flat hover>
              <template v-for="(signer, index) in signerGroup" >
                <PrepareSignatureSidebarSigner :key="signer.signatureId"
                                               :delete-signer-pending-ids="deleteSignerPendingIds"
                                               :drag-active="false"
                                               :index="getSignerIndex(signer.signatureId)"
                                               :is-multiple-envelope="isMultipleEnvelope"
                                               :is-read-only="isReadOnly"
                                               :is-template="isTemplate"
                                               :order="groupIndex"
                                               :provider="provider"
                                               :signer="signer"
                                               @change-signer="onChangeSigner"
                                               @delete-signer="onDeleteSigner"
                                               @rename-signer="onRenameSigner"
                                               @update-step-signers="onUpdateStepSigners"
                />

                <template v-if="signer.hasWitness">
                  <PrepareSignatureSidebarSigner v-for="witness in getWitnessesForSigner(signer.signatureId)"
                                                 :key="witness.signatureId"
                                                 :delete-signer-pending-ids="deleteSignerPendingIds"
                                                 :drag-active="false"
                                                 :index="getSignerIndex(signer.signatureId)"
                                                 :is-multiple-envelope="isMultipleEnvelope"
                                                 :is-read-only="isReadOnly"
                                                 :is-template="isTemplate"
                                                 :order="groupIndex"
                                                 :provider="provider"
                                                 :signer="witness"
                                                 isWitness
                                                 @change-signer="onChangeSigner"
                                                 @delete-signer="onDeleteSigner"
                                                 @update-step-signers="onUpdateStepSigners"
                  />
                </template>
              </template>
            </v-expansion-panels>
          </template>
        </div>
      </template>
    </div>
    <div class="pa-4">
      <app-button :disabled="isReadOnly"
                  full-width
                  type="tonal"
                  variant="project"
                  @click="onDeleteAllTags"
      >
        {{$t('project.signing-checklist.prepare-signature.PrepareSignatureSidebar.deleteAllTagsButtonLabel')}}
      </app-button>
    </div>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'

import { getCommonAndUniqueSignerIdsFromSignersGroup } from '@/common/utils/signingChecklist'
import { ENVELOPE_TYPES, SIGNATURE_TYPE_HANDWRITTEN } from '@/project/signing-checklist/constants'
import { GET_TAGGING_INFORMATION } from '@/store/modules/prepareSignature/action_types'

import PrepareSignatureSidebarSigner from './PrepareSignatureSidebarSigner.vue'

export default {
    name: 'PrepareSignatureSidebar',
    components: { PrepareSignatureSidebarSigner },
    props: {
      deleteSignerPendingIds: {
        type: Array,
        required: true,
      },
      envelopeId: {
        type: Number,
        required: true,
      },
      envelopeType: {
        type: String,
        required: true,
      },
      isReadOnly: {
        type: Boolean,
      },
      provider: {
        type: String,
        required: true,
      },
      signatureType: {
        type: String,
        required: true,
      },
      signers: {
        type: Array,
        required: true,
      },
    },
    computed: {
      hasSignersGroup () {
        return this.signersGroups.length > 0
      },
      hasCommonSigners () {
        return this.hasSignersGroup && this.isMultipleEnvelope && this.signersGroups.flat().some((signer) => signer.signatureType === 'single')
      },
      isMultipleEnvelope () {
        return this.envelopeType === ENVELOPE_TYPES.MULTIPLE
      },
      isSignersOrderButtonDisplayed () {
        return !this.isMultipleEnvelope && this.signatureType !== SIGNATURE_TYPE_HANDWRITTEN && !this.isTemplate
      },
      isTemplate () {
        return this.$route.path.includes('envelope-template') // TO OPTIMIZE
      },
      openedSignerPanelIndex () {
        return this.isReadOnly ? undefined : 0
      },
      signersGroups () {
        return this.signers.map(signersGroup => {
          signersGroup.map((signer) => {
            if (signer.signatureType !== 'witness') {
              signer.hasWitness = signersGroup.some((s) => s.witnessFor === signer.signatureId)
            }
          })
          const commonSigners = signersGroup.filter(signer => signer.signatureType === 'single')
          const uniqueSigners = signersGroup.filter(signer => signer.signatureType === 'multiple')

          return [...commonSigners, ...uniqueSigners]
        })
      },
    },
    async mounted () {
      if (!this.signers) {
        this.getTags()
      }
    },
    methods: {
      ...mapActions('prepareSignature', [GET_TAGGING_INFORMATION]),
      getTags () {
        this.$emit('get-tags')
      },
      onAddAutoTags () {
        this.$emit('add-auto-tags')
      },
      onAddSigners () {
        this.$emit('add-signers')
      },
      onChangeSigner (signer) {
        this.$emit('change-signer', signer)
      },
      onDeleteAllTags () {
        this.$emit('delete-all-tags')
      },
      onDeleteSigner (signatureId) {
        this.$emit('delete-signer', signatureId)
      },
      onEditSignersOrder () {
        this.$emit('edit-signers-order')
      },
      onRenameSigner (signerData) {
        this.$emit('rename-signer', signerData)
      },
      scrollTo (page) {
        this.$emit('scroll-to', page)
      },
      getSignerIndex (signatureId) {
        return this.signersGroups.flat().findIndex(signer => signer.signatureId === signatureId)
      },
      async onUpdateStepSigners () {
        await this.GET_TAGGING_INFORMATION({
          mnemo: this.$route.params.mnemo,
          envId: this.envelopeDisplayInformation.envelopeId,
        })
        const signersDescription = getCommonAndUniqueSignerIdsFromSignersGroup(this.signersGroups)
        this.$set(this.step.objectToPost, 'signers', signersDescription.commonSigners)
        if (this.step.objectToPost.envelope.envelopeType === ENVELOPE_TYPES.MULTIPLE) {
          this.$set(this.step.objectToPost, 'uniqueSigners', signersDescription.uniqueSigners)
        }
      },
      getWitnessesForSigner (signerId) {
        return this.signers.flat()
          .filter(signer => signer.signatureType === 'witness' && signer.witnessFor === signerId)
      },
    },
  }
</script>

<style lang="scss" scoped>
.PrepareSignatureSidebar-actions,
.PrepareSignatureSidebar-section {
  padding: 16px;
  border-bottom: 1px solid #DEE2E6;
}
.PrepareSignatureSidebar-actions {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
</style>
