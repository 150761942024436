<template>
  <SendByEmailDialog :is-open="isDialogOpened"
                     :cancelDisabled="sendArchivesPending"
                     :okLoading="sendArchivesPending"
                     :title="$t('archives.dialogs.ArchivesListSendByEmailDialog.title')"
                     @cancel="closeDialog"
                     @send="sendArchive"
  />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import SendByEmailDialog from '@/common/dialogs/SendByEmailDialog'
import { dialogMixin } from '@/mixins/dialogMixin'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'ArchivesListSendByEmailDialog',
  components: { SendByEmailDialog },
  mixins: [dialogMixin],
  props: {
    archiveId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('archives', ['sendArchivesPending']),
  },
  methods: {
    ...mapActions('archives', ['SEND_ARCHIVES']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async sendArchive (emails) {
      if (!emails) return

      try {
        await this.SEND_ARCHIVES({
          params: {
            archivesFilesIds: [this.archiveId],
            emails: emails,
          },
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('archives.dialogs.ArchivesListSendByEmailDialog.sendSuccess'))
        this.closeDialog()
      } catch (error) {
        const errorMessage = error.response?.data?.errorCode === 'ERR_INVALID_RIGHTS' ? 'sendInvalidRightsError' : 'sendError'
        this.ENQUEUE_ERROR_SNACKBAR(this.$t(`archives.dialogs.ArchivesListSendByEmailDialog.${errorMessage}`))
      }
    },
  },
}
</script>
