<template>
    <div>
      <AppButton size="small"
                 type="tonal"
                 variant="brand"
                 @click="onOpen"
      >
        {{ $t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.sign') }}
      </AppButton>
      <AppDialog :is-open="openDialog"
                 size="xxxl"
                 :ok-text="$t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.load')"
                 :ok-loading="postSignedFilePending"
                 :ok-disabled="!signedDoc || postSignedFilePending"
                 @ok="prepareFileUpload"
                 @cancel="resetAndClose"
      >
        <template #title>{{$t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.sign')}}</template>
        <template #subTitle>{{ fileName }}</template>
        <template #body>
          <v-row>
            <v-col>
              <Alert>
                {{ $t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.alertText') }}
              </Alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col >
              <div class="SigningPdfModal-card h-100 text--primary">
                <v-col class="d-flex align-center">
                  {{ $t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.downloadPagesToSign') }}
                  <AppDownloadFile :link="downloadEnvelopePagesToSign"
                                   class="ml-auto mr-2"
                  >
                    <template #default="{submitForm}">
                      <AppButton color="#333"
                                 class="ml-5"
                                 icon
                                 @click="submitForm">
                        <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon>
                      </AppButton>
                    </template>
                  </AppDownloadFile>
                </v-col>
                <v-col class="text-center text-h3">
                  {{ $t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.or') }}
                </v-col>
                <v-col class="d-flex align-center">
                  {{ $t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.downloadAllPagesToSign') }}
                  <AppDownloadFile :link="downloadAllEnvelopesPagesToSign"
                                   class="ml-auto mr-2"
                  >
                    <template #default="{submitForm}">
                      <AppButton color="#333"
                                 class="ml-5"
                                 icon
                                 @click="submitForm">
                        <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon>
                      </AppButton>
                    </template>
                  </AppDownloadFile>
                </v-col>
              </div>
            </v-col>
            <v-col>
              <div class="SigningPdfModal-card h-100 text--primary">
                <v-col class="text-center">
                  {{$t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.uploadSignedPages')}}
                </v-col>
                <v-col>
                  <v-file-input v-model="signedDoc"
                                dense
                                outlined
                                color="blue darken-2"
                                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
          <template v-if="signatureFiles && signatureFiles.signedFiles.length > 0">
            <v-row class="text--primary">
              <v-col>
                {{$t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.signedPages')}}
              </v-col>
            </v-row>
            <v-row class="text--primary">
              <v-col>
                <ul>
                  <li v-for="signedFile in signatureFiles.signedFiles"
                      :key="`signedFile-${signedFile.id}`"
                  >
                    {{ getFileString(signedFile) }}
                    <AppDownloadFile :link="getFileDownloadUrl(signedFile.id)"
                                     class="ml-auto"
                    >
                      <template #default="{submitForm}">
                        <AppButton icon
                                   color="#333"
                                   @click="submitForm"
                        >
                          <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon>
                        </AppButton>
                      </template>
                    </AppDownloadFile>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </template>
        </template>
      </AppDialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppButton from '@/design-system/buttons/AppButton.vue'

import Alert from '../../../common/alerts/Alert'
import AppDownloadFile from '../../../common/AppDownloadFile'
import AppDialog from '../../../common/dialogs/AppDialog'
import { ISOToShortDate } from '../../../common/utils/dates'
import { GET_SIGNATURE_FILES, POST_SIGNED_FILE, RESET_SIGNATURE_FILES } from '../../../store/modules/signing-checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'PdfSigningDialogButton',
  components: { AppDialog, Alert, AppButton, AppDownloadFile },
  props: {
    step: {
      type: Object,
      required: true,
    },
    signerId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      openDialog: false,
      signedDoc: null,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['signatureFiles', 'postSignedFilePending']),
    fileName () {
      if (this.step) {
        return this.step.hasOwnProperty('envelope')
          ? this.step.envelope.file.basename
          : this.step.name || null
      }
      return null
    },
    downloadEnvelopePagesToSign () {
      return `${process.env.VUE_APP_API_URL}/room/${this.$route.params.mnemo}/downloads/envelopePdfFileToSign/${this.step.envelope.id}`
    },
    downloadAllEnvelopesPagesToSign () {
      return `${process.env.VUE_APP_API_URL}/room/${this.$route.params.mnemo}/downloads/envelopePdfFileToSign`
    },
  },
  async mounted () {
    this.GET_SIGNATURE_FILES({
      mnemo: this.$route.params.mnemo,
      envelopeId: this.step.envelope.id,
      signerId: this.signerId,
    })
  },
  methods: {
    ...mapActions('signingChecklist', [GET_SIGNATURE_FILES, POST_SIGNED_FILE, RESET_SIGNATURE_FILES]),
    onOpen () {
      this.openDialog = true
      this.GET_SIGNATURE_FILES({
        mnemo: this.$route.params.mnemo,
        envelopeId: this.step.envelope.id,
        signerId: this.signerId,
      })
    },
    getFileDownloadUrl (fileId) {
      return `${process.env.VUE_APP_API_URL}/room/${this.$route.params.mnemo}/downloads/envelopePdfSignedFile/${this.step.envelope.id}/${fileId}`
    },
    getFileString (file) {
      let str = ''
      if (file.fileName) {
        str += file.fileName
      }
      if (file.signerName) {
        str += `, ${this.$t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.signerName')} ${file.signerName}`
      }
      if (file.date) {
        str += `, ${this.$t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.signDate')} ${ISOToShortDate(file.date)}`
      }
      return str
    },
    async prepareFileUpload () {
      try {
        const formData = new FormData()
        formData.append('signed_pages', this.signedDoc, this.signedDoc.name)
        formData.append('signer_id', this.signerId)
        await this.POST_SIGNED_FILE({
          mnemo: this.$route.params.mnemo,
          envelopeId: this.step.envelope.id,
          formData,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.postSignedFileSuccess'),
        })
        this.resetAndClose()
        this.GET_SIGNATURE_FILES({
          mnemo: this.$route.params.mnemo,
          envelopeId: this.step.envelope.id,
          signerId: this.signerId,
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningDialogButton.postSignedFileError'),
        })
      }
    },
    resetAndClose () {
      this.openDialog = false
      this.signedDoc = null
      this.RESET_SIGNATURE_FILES()
    },
  },
}
</script>

<style scoped lang="scss">
.SigningPdfModal-card {
  border: 2px dashed var(--v-grey-base);
  border-radius: 8px;
}
</style>
