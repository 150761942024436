<template>
  <div>
    <AppDialog
      :is-open="isDialogOpened"
      size="xxxl"
      hideFooter
      @cancel="closeDialog"
    >
      <template #title>
        {{$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.title')}}
      </template>
      <template #body>
        <v-row no-gutters>
          <SearchTextField
            v-model="searchQuery"
            :disabled="headerActionsDisabled"
            :tooltipText="$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.searchBarPlaceholder')"
          />
          <v-spacer />
          <app-button :disabled="headerActionsDisabled" @click="downloadAllAttachedFiles">
            <app-icon iconName="arrow-down-to-line" />
            {{ $t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.downloadAllBtnLabel') }}
          </app-button>
        </v-row>
        <v-row class="my-4" no-gutters>
          <div v-if="globalTaskFolderPending" class="w-100 text-center">
            <v-progress-circular
              indeterminate
              size="64"
              color="primary"
            />
          </div>
          <div v-else-if="globalTaskFolder.length === 0" class="mx-auto text-center">
            <v-img src="/img/documentIllustration.svg" width="325" class="mx-auto my-4" />
            <app-text as="h3" variant="medium-bold" class="grey--text text--darken-2">{{ $t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.emptyTitle') }}</app-text>
            <app-text variant="small-regular" class="grey--text text--darken-2">{{ $t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.emptyText') }}</app-text>
          </div>
          <!-- We do a v-show here instead of a v-if / v-else to have a consistent table state when we refresh its state through actions -->
          <div v-show="!globalTaskFolderPending && globalTaskFolder.length !== 0" class="w-100">
            <v-row v-if="selectedItems.length > 0" class="my-4" no-gutters>
              <ChecklistTableTaskAttachedFilesDialogMultiSelectBar
                :mnemo="mnemo"
                :selectionModel="selectedItems"
                @input="selectedItems = $event"
              />
            </v-row>
            <ChecklistGlobalAttachedFilesDialogTable
              v-model="selectedItems"
              :items="tableItems"
              :mnemo="mnemo"
            />
          </div>
        </v-row>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapActions, mapMutations, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import SearchTextField from '@/common/SearchTextField'
import { dialogMixin } from '@/mixins/dialogMixin'
import { GET_GLOBAL_TASK_FOLDER } from '@/store/modules/checklist/action_types'
import { SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN } from '@/store/modules/checklist/mutation_types'
import { OPEN_DOCUMENTS_DOWNLOAD_FILES } from '@/store/modules/documents-download-files/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

import ChecklistGlobalAttachedFilesDialogTable from './ChecklistGlobalAttachedFilesDialogTable.vue'
import ChecklistTableTaskAttachedFilesDialogMultiSelectBar from './ChecklistTableTaskAttachedFilesDialogMultiSelectBar.vue'

export default {
  name: 'ChecklistGlobalAttachedFilesDialog',
  mixins: [dialogMixin],
  components: {
    AppDialog,
    SearchTextField,
    ChecklistGlobalAttachedFilesDialogTable,
    ChecklistTableTaskAttachedFilesDialogMultiSelectBar,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      selectedItems: [],
      searchQuery: '',
    }
  },
  computed: {
    ...mapState('checklist', ['globalTaskFolder', 'globalTaskFolderPending']),
    search: {
      get () {
        return this.searchQuery
      },
      set: debounce(function (val) {
        this.searchQuery = val
      }, 1000),
    },
    headerActionsDisabled () {
      return this.globalTaskFolderPending || this.globalTaskFolder.length === 0
    },
    tableItems () {
      return this.search ? this.filteredGlobalTaskFolder : this.globalTaskFolder
    },
    filteredGlobalTaskFolder () {
      if (!this.search) return []
      return this.globalTaskFolder.filter((task) => {
        const searchQuery = this.search.toLowerCase()
        return task.basename.toLowerCase().includes(searchQuery) ||
          task.updateUser.toLowerCase().includes(searchQuery)
      })
    },
  },
  async created () {
    try {
      await this.GET_GLOBAL_TASK_FOLDER(this.mnemo)
    } catch (e) {
      this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.msgFailErrorOccurred'))
    }
  },
  // Done here because when going to another route it'd stay open otherwise
  // Since the parent doesn't set it back to false anymore in that case
  beforeDestroy () {
    this.SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN(false)
  },
  methods: {
    ...mapActions('checklist', [GET_GLOBAL_TASK_FOLDER]),
    ...mapMutations('checklist', [SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    ...mapActions('documentsDownloadFiles', [OPEN_DOCUMENTS_DOWNLOAD_FILES]),
    downloadAllAttachedFiles () {
      this.OPEN_DOCUMENTS_DOWNLOAD_FILES(this.tableItems)
    },
  },
}
</script>
