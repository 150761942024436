<template>
  <AppDialog :is-open="isOpen"
             :ok-text="$t('common.delete')"
             :ok-loading="deleteUserPending"
             :ok-disabled="deleteUserPending"
             :cancel-disabled="deleteUserPending"
             @ok="prepareDeleteUser"
             @cancel="$emit('close')"
  >
    <template #title>{{ $t('subscriptions.SubscriptionSubscribersDeleteDialog.title') }}</template>
    <template #body>
      <v-row>
        <v-col>
          {{ $t('subscriptions.SubscriptionSubscribersDeleteDialog.text') }}
        </v-col>
      </v-row>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import { DELETE_USER } from '@/store/modules/subscription/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'
export default {
  name: 'SubscriptionSubscribersDeleteDialog',
  components: { AppDialog },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    userToDelete: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null,
    },
  },
  computed: {
    ...mapState('subscription', ['deleteUserPending']),
  },
  methods: {
    ...mapActions('subscription', [DELETE_USER]),
    async prepareDeleteUser () {
      try {
        await this.DELETE_USER(this.userToDelete.id)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('subscriptions.SubscriptionSubscribersDeleteDialog.deleteUserSuccess'),
        })
        this.$emit('userDeleteSuccess')
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('subscriptions.SubscriptionSubscribersDeleteDialog.deleteUserError'),
        })
      }
    },
  },
}
</script>
