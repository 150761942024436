<template>
  <div>
    <div class="body-1 font-weight-bold pb-5 ml-1">
        {{ $t('project.project-settings.access-rights.AccessRights.title') }}
    </div>
    <AppCard class="ma-1">
      <template v-if="roomRightsPending && !roomRights">
        <div class="text-center">
          <AppLoader class="mx-auto"></AppLoader>
        </div>
      </template>
      <template v-if="roomRights">
        <v-progress-linear v-if="patchRightsPending"
                           indeterminate
                           color="primary"
        />
        <div class="AccessRightsTable-grid">
          <AccessRightsHeader :grid-styles="gridStyles"
                              :displayed-columns="displayedColumns"
          />
          <AccessRightsGlobal :grid-styles="gridStyles"
                              :patch-rights-pending="patchRightsPending"
                              :displayed-columns="displayedColumns"
                              @patchRoomRightsSuccess="patchRoomRightsSuccess"
                              @patchRoomRightsError="patchRoomRightsError"
          />

          <template v-for="group in roomRights.groups">
            <AccessRightsGroup :key="`group-${group.id}-name`"
                               :patch-rights-pending="patchRightsPending"
                               :grid-styles="gridStyles"
                               :group="group"
                               :is-open="openedGroups.includes(group.id)"
                               :displayed-columns="displayedColumns"
                               @toggleGroup="toggleGroup"
                               @patchRoomRightsSuccess="patchRoomRightsSuccess"
                               @patchRoomRightsError="patchRoomRightsError"
            />
          </template>
        </div>
      </template>
    </AppCard>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppCard from '@/common/AppCard.vue'
import AppLoader from '@/common/AppLoader.vue'
import AccessRightsGlobal from '@/project/project-settings/access-rights/AccessRightsGlobal.vue'
import AccessRightsGroup from '@/project/project-settings/access-rights/AccessRightsGroup.vue'
import AccessRightsHeader from '@/project/project-settings/access-rights/AccessRightsHeader.vue'
import { RIGHTS_LIST } from '@/project/project-settings/access-rights/rights-list'
import { ROOMS_ROUTE_NAME } from '@/router'
import { GET_ROOM_RIGHTS } from '@/store/modules/room/action_types'
import { RESET_ROOM_RIGHTS } from '@/store/modules/room/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'AccessRights',
  components: { AccessRightsGroup, AccessRightsGlobal, AccessRightsHeader, AppCard, AppLoader },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      openedGroups: [],
    }
  },
  computed: {
    ...mapState('room',
      [
        'roomRights',
        'roomRightsPending',
        'patchRoomGlobalRightsPending',
        'patchRoomGroupRightsPending',
        'patchRoomUserRightsPending',
      ]),
    ...mapGetters('room', ['isDataRoom']),
    patchRightsPending () {
      return this.patchRoomGlobalRightsPending ||
        this.patchRoomGroupRightsPending ||
        this.patchRoomUserRightsPending
    },
    gridStyles () {
      let columnWidth = [150, 100]
      if (this.$vuetify.breakpoint.smAndUp) {
        columnWidth = [250, 150]
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        columnWidth = [350, 200]
      }
      return {
        display: 'grid',
        gridTemplateColumns: `${columnWidth[0]}px repeat(${this.templateVariables['--access-rights-columns-count']}, ${columnWidth[1]}px)`,
      }
    },
    displayedColumns () {
      const bibleCreationRightColumn = {
        ...RIGHTS_LIST.CAN_CREATE_BIBLES,
        hasHeaderTooltip: this.isDataRoom,
        disableCheck: {
          global: () => {
            const dontHaveDownloadRight = this.isDataRoom && !this.roomRights.global[RIGHTS_LIST.CAN_DOWNLOAD.propertyName]
            return this.patchRightsPending || dontHaveDownloadRight
          },
          group: (group) => {
            const dontHaveDownloadRight = this.isDataRoom && !group[RIGHTS_LIST.CAN_DOWNLOAD.propertyName]
            return this.patchRightsPending || Boolean(group.isPm) || dontHaveDownloadRight
          },
          member: (member, isGroupPm) => {
            const dontHaveDownloadRight = this.isDataRoom && !member[RIGHTS_LIST.CAN_DOWNLOAD.propertyName]
            return this.patchRightsPending || isGroupPm || dontHaveDownloadRight
          },
        },
      }

      if (this.isDataRoom) {
        return [
          RIGHTS_LIST.CAN_DOWNLOAD,
          RIGHTS_LIST.CAN_UPLOAD,
          RIGHTS_LIST.CAN_CREATE_FOLDERS,
          bibleCreationRightColumn,
          RIGHTS_LIST.CAN_ADD_QUESTIONS,
        ]
      }

      return [
        RIGHTS_LIST.CAN_UPLOAD,
        RIGHTS_LIST.CAN_CREATE_FOLDERS,
        bibleCreationRightColumn,
        RIGHTS_LIST.CAN_COMMENT_TASKS,
      ]
    },
    templateVariables () {
      return {
        '--access-rights-columns-count': this.displayedColumns.length,
      }
    },
  },
  created () {
    this.prepareGetRoomRights()
  },
  beforeDestroy () {
    this.RESET_ROOM_RIGHTS()
  },
  methods: {
    ...mapActions('room', [GET_ROOM_RIGHTS]),
    ...mapMutations('room', [RESET_ROOM_RIGHTS]),
    async prepareGetRoomRights () {
      try {
        await this.GET_ROOM_RIGHTS(this.mnemo)
      } catch (error) {
        if (error.response?.status === 404) {
          this.$router.replace({ name: ROOMS_ROUTE_NAME })
        }
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.project-settings.access-rights.AccessRights.getRoomRightsError'),
        })
      }
    },
    toggleGroup (groupId) {
      const groupIndex = this.openedGroups.findIndex(g => g === groupId)
      if (groupIndex >= 0) {
        this.openedGroups.splice(groupIndex, 1)
      } else {
        this.openedGroups.push(groupId)
      }
    },
    patchRoomRightsSuccess () {
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'success',
        message: this.$t('project.project-settings.access-rights.AccessRights.patchRoomRightsSuccess'),
      })
    },
    patchRoomRightsError () {
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'error',
        message: this.$t('project.project-settings.access-rights.AccessRights.patchRoomRightsError'),
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .AccessRightsTable {
  &-grid {
    overflow-x: auto;
  }
  &-gridCell,
  &-gridHeaderCell,
  &-memberGridCell {
    padding: 10px;
    justify-self: stretch;
    background-color: #fff;
    border-right: 1px solid rgba(216, 216, 216, 0.6);
  }
  &-gridHeaderCell {
    border-bottom: 1px solid rgba(216, 216, 216, 0.6);
  }
  &-gridCell {
    background-color: #eee;
  }
  &-groupChevron {
    transition: transform .5s;
    &--open {
      transform: rotate(90deg);
    }
  }
  &-checkbox {
    margin-top: 0;
    padding-top: 0;
    .v-input--selection-controls__input {
      margin-left: auto;
      margin-right: auto;
      height: 26px;
      width: 26px;
      .v-input--selection-controls__ripple {
        left: -6px !important;
      }
      .v-icon {
        font-size: 20px;
      }
    }
  }
}
::v-deep .centered-switch .v-input--selection-controls__input {
  margin-left: auto;
  margin-right: auto;
}
::v-deep .sticky-left {
  position: sticky;
  left: 0;
  z-index: 1;
}
</style>
