<template>
  <div class="my-2">
    <div class="ChecklistShareTasksDialogRolePickerGroup-row py-2 px-4">
      <div class="text-h4 text-truncated">
        {{ group.name }}
      </div>
      <v-checkbox :ripple="false"
                  class="mt-0 pt-0 mx-auto"
                  dense
                  hide-details
                  :input-value="responsibleIsChecked"
                  readonly
                  @click="onResponsibleClick"
      />
      <v-checkbox :ripple="false"
                  class="mt-0 pt-0 mx-auto"
                  dense
                  hide-details
                  :input-value="validatorIsChecked"
                  readonly
                  @click="onValidatorsClick"
      />
      <v-checkbox :ripple="false"
                  class="mt-0 pt-0 mx-auto"
                  dense
                  hide-details
                  :input-value="observersIsChecked"
                  :disabled="isObserversCheckboxDisabled"
                  readonly
                  @click="!isObserversCheckboxDisabled && onObserversClick()"
      />
    </div>
    <ChecklistShareTasksDialogRolePickerGroupMember v-for="member in group.members"
                                                    :key="`member-${member.id}`"
                                                    :mnemo="mnemo"
                                                    :member="member"
                                                    :group="group"
                                                    :taskVisibleByAll="taskVisibleByAll"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ChecklistShareTasksDialogRolePickerGroupMember from './ChecklistShareTasksDialogRolePickerGroupMember'
import {
  TOGGLE_SELECTED_RESPONSIBLE_GROUPS,
  TOGGLE_SELECTED_OBSERVERS_GROUPS,
  TOGGLE_SELECTED_VALIDATORS_GROUPS,
  REMOVE_SELECTED_RESPONSIBLE_MEMBERS,
  REMOVE_SELECTED_OBSERVERS_MEMBERS,
  REMOVE_SELECTED_VALIDATORS_MEMBERS, ADD_SELECTED_OBSERVERS_MEMBERS,
} from '../../../store/modules/checklist/mutation_types'
export default {
  name: 'ChecklistShareTasksDialogRolePickerGroup',
  components: { ChecklistShareTasksDialogRolePickerGroupMember },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    taskVisibleByAll: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('checklist', ['shareTaskRolePickerSelectedResponsible', 'shareTaskRolePickerSelectedObservers', 'shareTaskRolePickerSelectedValidators']),
    isObserversCheckboxDisabled () {
      return this.responsibleIsChecked || this.validatorIsChecked || this.group.isPm || this.taskVisibleByAll
    },
    responsibleIsChecked () {
      if (this.shareTaskRolePickerSelectedResponsible.groups.includes(this.group)) {
        return true
      } else if (this.group.members.length > 0 && this.group.members.every(child => this.shareTaskRolePickerSelectedResponsible.members.includes(child))) {
        return true
      }
      return false
    },
    observersIsChecked () {
      if (this.shareTaskRolePickerSelectedObservers.groups.includes(this.group)) {
        return true
      } else if (this.group.members.length > 0 && this.group.members.every(child => this.shareTaskRolePickerSelectedObservers.members.includes(child))) {
        return true
      }
      return false
    },
    validatorIsChecked () {
      if (this.shareTaskRolePickerSelectedValidators.groups.includes(this.group)) {
        return true
      } else if (this.group.members.length > 0 && this.group.members.every(child => this.shareTaskRolePickerSelectedValidators.members.includes(child))) {
        return true
      }
      return false
    },
  },
  methods: {
    onResponsibleClick () {
      this.$store.commit(`checklist/${TOGGLE_SELECTED_RESPONSIBLE_GROUPS}`, this.group)
      this.group.members.forEach(member => {
        this.$store.commit(`checklist/${REMOVE_SELECTED_RESPONSIBLE_MEMBERS}`, member)
      })
      if (this.responsibleIsChecked && !this.observersIsChecked) {
        this.onObserversClick()
      }
    },
    onObserversClick () {
      this.$store.commit(`checklist/${TOGGLE_SELECTED_OBSERVERS_GROUPS}`, this.group)
      this.group.members.forEach(member => {
        if (!this.shareTaskRolePickerSelectedResponsible.members.includes(member)) {
          this.$store.commit(`checklist/${REMOVE_SELECTED_OBSERVERS_MEMBERS}`, member)
        } else if (this.shareTaskRolePickerSelectedResponsible.members.includes(member)) {
          this.$store.commit(`checklist/${ADD_SELECTED_OBSERVERS_MEMBERS}`, member)
        }
      })
    },
    onValidatorsClick () {
      this.$store.commit(`checklist/${TOGGLE_SELECTED_VALIDATORS_GROUPS}`, this.group)
      this.group.members.forEach(member => {
        this.$store.commit(`checklist/${REMOVE_SELECTED_VALIDATORS_MEMBERS}`, member)
      })
      if (this.validatorIsChecked && !this.observersIsChecked) {
        this.onObserversClick()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistShareTasksDialogRolePickerGroup-row {
  display: grid;
  grid-template-columns: auto 180px 180px 180px;
  grid-gap: 10px;
  align-items: center;
  border: solid 1px #e0e0e0;
  background-color: rgba(75, 117, 181, 0.1);
}
</style>
