<template>
  <v-row align="center" justify="center" class="h-100">
    <AppLoader />
  </v-row>
</template>

<script>
import * as Sentry from '@sentry/vue'

import { AUTH_DATA_COOKIE_KEY, getSignInRoute } from '@/common/utils/login'
import { SUBDOMAIN_COOKIE_KEY, getRootDomainFromUrl } from '@/common/utils/url'
import { USER_ID_STORAGE_KEY, USER_TOKEN_STORAGE_KEY } from '@/common/utils/users'

import AppLoader from '../../common/AppLoader.vue'
import { STORE_PERSIST_KEY } from '../../store'
export default {
  name: 'Logout',
  components: { AppLoader },
  props: {
    redirectProps: {
      type: Object,
      default: () => ({
        isLogOut: true,
        isVolontaryLogOut: false,
      }),
    },
    // Overrides the basic functionality that redirects to the login
    redirectPath: {
      type: String,
      required: false,
    },
  },
  created () {
    const clientAppUrl = process.env.VUE_APP_CLIENT_URL
    const cookieDomain = getRootDomainFromUrl(clientAppUrl)
    const subdomain = this.$cookies.get(SUBDOMAIN_COOKIE_KEY)
    this.$cookies.remove(AUTH_DATA_COOKIE_KEY, null, cookieDomain)
    this.$cookies.remove(SUBDOMAIN_COOKIE_KEY, null, cookieDomain)

    localStorage.removeItem(USER_TOKEN_STORAGE_KEY)
    localStorage.removeItem(USER_ID_STORAGE_KEY)
    try {
      if (window.zE) {
        window.zE('messenger', 'logoutUser')
      }
    } catch {
      console.error('zendesk log out failed')
    }
    try {
      Sentry.setUser(null)
    } catch {
      console.error('Could not unset Sentry user ID')
    }
    // Done in an immediately queued timer so that the persist already handled the changes it needed to do
    // And we can then remove it entirely
    setTimeout(() => {
      localStorage.removeItem(STORE_PERSIST_KEY)
      let host = window.location.host
      // If we logged from a subdomain, replace the first part of the host (the subdomain part)
      // With the subdomain so that we're back where we started
      if (subdomain) {
        host = host.replace(host.split('.')[0], subdomain)
      }
      const ROUTE_PATH_PREPEND_URL = window.location.protocol + '//' + host

      if (this.redirectProps.returnRoute) {
        this.redirectProps.returnRoute = this.redirectProps.returnRoute.fullPath
      }

      if (this.redirectPath) {
        window.location.replace(
          ROUTE_PATH_PREPEND_URL +
          this.redirectPath,
        )
        return
      }
      // Force refresh into sign in. Every state is reset.
      // Put every params into query because of the refresh
      window.location.replace(
        ROUTE_PATH_PREPEND_URL +
        this.$router.resolve(getSignInRoute({}, this.redirectProps)).href,
      )
    }, 0)
  },
}
</script>
