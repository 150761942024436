<template>
  <transition name="fade" appear>
    <div class="popover-content" :style="customStyle">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'PopoverContent',
  props: {
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
<style scoped>
.popover-content {
  position: fixed;
  z-index: 9999;
  background: white;
  border: 1px solid #d4d7da;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 0px 12px 0px #444a5014;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
