<template>
  <Pane :value="paneIsOpen"
        :tabs="tabs"
        current-tab="teams"
        class="subscription-teams-pane"
        temporary
        @closeButtonClicked="closePane"
        @input="onInput"
  >
    <template #content-teams>
      <app-text variant="small-bold" class="mb-2">
        {{ $t('subscriptions.views.SubscriptionTeams.teamName') }}
      </app-text>
      <!--
        Limit is 100 only to set a reasonable limit based on past inputs with the same use-case
        there was no spec given by the backend
      -->
      <AppTextField v-model="name"
                    :placeholder="$t('subscriptions.views.SubscriptionTeams.newTeam')"
                    counter="100"
                    maxlength="100"
      />

      <app-text variant="small-bold" class="mb-2">
        {{ $t('subscriptions.views.SubscriptionTeams.addMembers') }}
      </app-text>
      <SearchTextField v-model="searchQuery"
                      :tooltipText="$t('project.checklist.dialogs.SaveChecklistDialog.searchMember')"
                      fluid
      />

      <v-row class="share-type-custom-content mt-2">
        <v-col>
          <div v-if="getSelfContactListPending" class="text-center">
            <AppLoader small/>
          </div>
          <template v-else>
            <div v-for="user in sortedComputedSubscriptionUsers"
                :key="`user-${user.id}`"
                class="ml-2"
            >
              <v-checkbox v-model="selectedUsers"
                          :value="user.id"
                          class="my-0"
                          hide-details
              >
                <template #label>
                  <AddMemberFormComboboxItem class="pl-1" :item="user" :withCompany="false" />
                </template>
              </v-checkbox>
            </div>
          </template>
        </v-col>
      </v-row>

      <div class="w-100">
        <v-divider class="my-4"></v-divider>
        <div class="d-flex flex-column flex-sm-row justify-space-between">
          <app-button v-if="isUpdating"
                      class="mb-2 mb-sm-0"
                      color="error"
                      outlined
                      :disabled="isPending"
                      @click="showDeleteTeamDialog"
          >
            {{ $t('subscriptions.views.SubscriptionTeams.deleteTeam') }}
          </app-button>
          <app-button class="ml-sm-auto mr-sm-2 mb-2 mb-sm-0"
                      :disabled="isPending"
                      @click="closePane"
          >
            {{ $t('common.cancel') }}
          </app-button>
          <app-button color="primary"
                      :loading="isPending"
                      :disabled="okDisabled || isPending"
                      @click="isUpdating ? preparePatchSubscriptionTeam() : preparePostSubscriptionTeam()"
          >
            {{ isUpdating ? $t('common.register') : $t('subscriptions.views.SubscriptionTeams.createTheTeam') }}
          </app-button>
        </div>
      </div>
    </template>
  </Pane>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

import AppLoader from '@/common/AppLoader.vue'
import AppTextField from '@/common/AppTextField.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import Pane, { PANE_FADE_TRANSITION_TIME } from '@/common/Pane.vue'
import SearchTextField from '@/common/SearchTextField.vue'
import AddMemberFormComboboxItem from '@/project/members/AddMemberFormComboboxItem.vue'
import {
  POST_SUBSCRIPTION_TEAM,
  PATCH_SUBSCRIPTION_TEAM,
  GET_SUBSCRIPTION_TEAMS,
} from '@/store/modules/subscription/action_types'
import {
  SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN,
  SET_SELECTED_SUBSCRIPTION_TEAM,
  SET_DELETE_TEAM_DIALOG,
} from '@/store/modules/subscription/mutation_types'
import { GET_SELF_CONTACT_LIST } from '@/store/modules/user/action_types'
import { ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'SubscriptionTeamsPane',
  components: {
    AppButton,
    Pane,
    AppTextField,
    AppLoader,
    SearchTextField,
    AddMemberFormComboboxItem,
  },
  data () {
    return {
      paneIsOpen: false,
      name: '',
      searchQuery: '',
      selectedUsers: [],
    }
  },
  computed: {
    ...mapState('subscription', [
      'subscriptionPaneIsOpen',
      'postSubscriptionTeamPending',
      'patchSubscriptionTeamPending',
      'selectedSubscriptionTeam',
    ]),
    ...mapState('user', ['profile', 'selfContactListFromSubscriptionPayingUsers', 'getSelfContactListPending']),
    tabs () {
      return [
        {
          id: 'teams',
          title: this.isUpdating ? `${this.$t('common.modify')} ${this.name}` : this.$t('subscriptions.views.SubscriptionTeams.createTeam'),
        },
      ]
    },
    computedSubscriptionUsers () {
      const users = this.selfContactListFromSubscriptionPayingUsers
      if (this.searchQuery && users) {
        return users.filter(user => {
          const fullName = user.firstName + ' ' + user.lastName
          return fullName.toLowerCase().includes(this.searchQuery.toLowerCase())
        })
      }
      return users
    },
    sortedComputedSubscriptionUsers () {
      const selectedUsersArray = []
      const otherUsersArray = []
      this.computedSubscriptionUsers.forEach(user => {
        if (this.selectedUsers.includes(user.id)) {
          selectedUsersArray.push(user)
        } else {
          otherUsersArray.push(user)
        }
      })
      return selectedUsersArray.concat(otherUsersArray)
    },
    isUpdating () {
      return this.selectedSubscriptionTeam !== null
    },
    okDisabled () {
      return this.name === '' || this.selectedUsers.length === 0
    },
    isPending () {
      return this.postSubscriptionTeamPending || this.patchSubscriptionTeamPending
    },
  },
  watch: {
    subscriptionPaneIsOpen: {
      handler (value) {
        if (value) {
          this.applySelectedSubscriptionTeamData()
          setTimeout(() => { this.paneIsOpen = true }, PANE_FADE_TRANSITION_TIME)
        }
      },
      immediate: true,
    },
  },
  created () {
    this.prepareGetSubscriptionUsers()
  },
  methods: {
    ...mapActions('user', [GET_SELF_CONTACT_LIST]),
    ...mapActions('subscription', [
      POST_SUBSCRIPTION_TEAM,
      PATCH_SUBSCRIPTION_TEAM,
      GET_SUBSCRIPTION_TEAMS,
    ]),
    ...mapMutations('subscription', [
      SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN,
      SET_SELECTED_SUBSCRIPTION_TEAM,
      SET_DELETE_TEAM_DIALOG,
    ]),
    ...mapMutations([ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_ERROR_SNACKBAR]),
    applySelectedSubscriptionTeamData () {
      if (this.selectedSubscriptionTeam) {
        this.name = this.selectedSubscriptionTeam.name
        this.selectedUsers = this.selectedSubscriptionTeam.members.map(item => item.id)
      }
    },
    async prepareGetSubscriptionUsers () {
      try {
        await this.GET_SELF_CONTACT_LIST({ fromUserSubscriptionOnly: true, payingUsersOnly: true })
      } catch (error) {
        console.error(error)
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.subscription.errors.getSubscriptionUsersError'))
      }
    },
    async preparePostSubscriptionTeam () {
      try {
        const data = {
          name: this.name,
          memberIds: this.selectedUsers,
        }
        await this.POST_SUBSCRIPTION_TEAM(data)
        this.getSubscriptionTeams()
        this.closePane()
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$tc('subscriptions.views.SubscriptionTeams.saveTeamSuccess', this.name, { name: this.name }))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('subscriptions.views.SubscriptionTeams.saveTeamError'))
      }
    },
    async preparePatchSubscriptionTeam () {
      try {
        const data = {
          name: this.name,
          memberIds: this.selectedUsers,
        }
        await this.PATCH_SUBSCRIPTION_TEAM({
          teamId: this.selectedSubscriptionTeam.id,
          data,
        })
        this.getSubscriptionTeams()
        this.closePane()
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$tc('subscriptions.views.SubscriptionTeams.updateTeamSuccess', this.name, { name: this.name }))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('subscriptions.views.SubscriptionTeams.updateTeamError'))
      }
    },
    async getSubscriptionTeams () {
      try {
        await this.GET_SUBSCRIPTION_TEAMS()
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('subscriptions.views.SubscriptionTeams.getTeamsError'))
      }
    },
    showDeleteTeamDialog () {
      this.SET_DELETE_TEAM_DIALOG({
        subscriptionTeam: this.selectedSubscriptionTeam,
        isOpen: true,
      })
      this.closePane()
    },
    onInput (value) {
      if (!value) {
        this.closePane()
      }
    },
    closePane () {
      this.paneIsOpen = false
      this.SET_SELECTED_SUBSCRIPTION_TEAM(null)

      setTimeout(() => { this.$emit('close') }, PANE_FADE_TRANSITION_TIME)
    },
  },
}
</script>

<style lang="scss">
  .subscription-teams-pane {
    .share-type-custom-content {
      // 320px represents the height of buttons and other margins above
      height: calc(100vh - 320px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
</style>
