export const ADD_UPLOAD_HIDDEN_FILE_PENDING = 'ADD_UPLOAD_HIDDEN_FILE_PENDING'
export const REMOVE_DOCUMENT_FILTER = 'REMOVE_DOCUMENT_FILTER'
export const REMOVE_SELECTED_DOCUMENT = 'REMOVE_SELECTED_DOCUMENT'
export const REMOVE_UPLOAD_HIDDEN_FILE_PENDING = 'REMOVE_UPLOAD_HIDDEN_FILE_PENDING'
export const REMOVE_VERSION = 'REMOVE_VERSION'
export const SET_ALL_DOCUMENTS_AS_SELECTED = 'SET_ALL_DOCUMENTS_AS_SELECTED'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const SET_DOCUMENTS_PANE = 'SET_DOCUMENTS_PANE'
export const SET_DOCUMENT_FILTERS = 'SET_DOCUMENT_FILTERS'
export const SET_DOCUMENTS_TO_MOVE = 'SET_DOCUMENTS_TO_MOVE'
export const SET_DUPLICATE_LOADING = 'SET_DUPLICATE_LOADING'
export const SET_EDIT_DOCUMENT_NUMBERING_PENDING = 'SET_EDIT_DOCUMENT_NUMBERING_PENDING'
export const SET_ERROR = 'SET_ERROR'
export const SET_FILTERS_PANE_IS_OPEN = 'SET_FILTERS_PANE_IS_OPEN'
export const SET_FOLDERS_TREE = 'SET_FOLDERS_TREE'
export const SET_FULL_TREE = 'SET_FULL_TREE'
export const SET_GET_FOLDERS_TREE_PENDING = 'SET_GET_FOLDERS_TREE_PENDING'
export const SET_GET_FOLDER_PATH_PENDING = 'SET_GET_FOLDER_PATH_PENDING'
export const SET_IMANAGE_HIDDEN_FILE_IMPORT_ERROR = 'SET_IMANAGE_HIDDEN_FILE_IMPORT_ERROR'
export const SET_IMANAGE_HIDDEN_FILE_IMPORT_PENDING = 'SET_IMANAGE_HIDDEN_FILE_IMPORT_PENDING'
export const SET_LAST_ACCESS_TIMESTAMP = 'SET_LAST_ACCESS_TIMESTAMP'
export const SET_LAST_MNEMO_ACCESSED = 'SET_LAST_MNEMO_ACCESSED'
export const SET_LOADING = 'SET_LOADING'
export const SET_MOVE_LOADING = 'SET_MOVE_LOADING'
export const SET_MOVEMENT_SNACKBAR_IS_OPEN = 'SET_MOVEMENT_SNACKBAR_IS_OPEN'
export const SET_NUMBERING = 'SET_NUMBERING'
export const SET_NUMBERING_ERROR = 'SET_NUMBERING_ERROR'
export const SET_NUMBERING_SNACKBAR_IS_OPEN = 'SET_NUMBERING_SNACKBAR_IS_OPEN'
export const SET_PATCH_NUMBERING_PENDING = 'SET_PATCH_NUMBERING_PENDING'
export const SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_ERROR = 'SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_ERROR'
export const SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_PENDING = 'SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_PENDING'
export const SET_POST_FILDERS_FROM_IMANAGE_ERROR = 'SET_POST_FILDERS_FROM_IMANAGE_ERROR'
export const SET_POST_FILDERS_FROM_IMANAGE_PENDING = 'SET_POST_FILDERS_FROM_IMANAGE_PENDING'
export const SET_POST_FILDERS_TO_IMANAGE_ERROR = 'SET_POST_FILDERS_TO_IMANAGE_ERROR'
export const SET_POST_FILDERS_TO_IMANAGE_PENDING = 'SET_POST_FILDERS_TO_IMANAGE_PENDING'
export const SET_POST_MASS_FOLDERS_ERROR = 'SET_POST_MASS_FOLDERS_ERROR'
export const SET_POST_MASS_FOLDERS_PENDING = 'SET_POST_MASS_FOLDERS_PENDING'
export const SET_POST_REQUEST_AUTHORIZATION_BIBLE_PENDING = 'SET_POST_REQUEST_AUTHORIZATION_BIBLE_PENDING'
export const SET_POST_VERSION_FROM_IMANAGE_ERROR = 'SET_POST_VERSION_FROM_IMANAGE_ERROR'
export const SET_POST_VERSION_FROM_IMANAGE_PENDING = 'SET_POST_VERSION_FROM_IMANAGE_PENDING'
export const SET_REMOVE_VERSION_PENDING = 'SET_REMOVE_VERSION_PENDING'
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING'
export const SET_SEARCH_MODE_ENABLED = 'SET_SEARCH_MODE_ENABLED'
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const SET_SELECTED_DOCUMENTS = 'SET_SELECTED_DOCUMENTS'
export const SET_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PENDING = 'SET_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PENDING'
export const SET_SHARE_LOADING = 'SET_SHARE_LOADING'
export const SET_TABLE_DOCUMENTS_PANE_TAB = 'SET_TABLE_DOCUMENTS_PANE_TAB'
export const SET_UPDATE_ACCESS_TIME_ERROR = 'SET_UPDATE_ACCESS_TIME_ERROR'
export const SET_UPDATE_ACCESS_TIME_PENDING = 'SET_UPDATE_ACCESS_TIME_PENDING'
export const SET_VERSIONS_LOADING = 'SET_VERSIONS_LOADING'
export const TOGGLE_TABLE_DOCUMENTS_PANE = 'TOGGLE_TABLE_DOCUMENTS_PANE'
export const SET_DOCUMENTS_VERSIONING_PANE_TAB = 'SET_DOCUMENTS_VERSIONING_PANE_TAB'
export const TOGGLE_DOCUMENTS_VERSIONING_PANE = 'TOGGLE_DOCUMENTS_VERSIONING_PANE'
export const SET_CHECK_WOPI_LOCKED_FILES_PENDING = 'SET_CHECK_WOPI_LOCKED_FILES_PENDING'
export const SET_WOPI_LOCKED_FILES = 'SET_WOPI_LOCKED_FILES'
export const RESET_WOPI_LOCKED_FILES = 'RESET_WOPI_LOCKED_FILES'
