<template>
  <div>
    <template v-if="isPublic">
      <AppTooltip top :disabled="!isClickable">
        <template #activator="{ on, attrs }">
          <div v-bind="attrs"
               style="width: fit-content"
               v-on="on"
          >
            <AppButton class="RightsDisplayButton"
                       outlined
                       :block="block"
                       :disabled="hoverOnly"
                       rounded
                       @click="$emit($event.type)"
            >
              <font-awesome-icon :icon="['fas', 'users']"></font-awesome-icon>
            </AppButton>
          </div>
        </template>
        <div>
          {{$t('common.users.RightsDisplayButton.everyone')}}
        </div>
      </AppTooltip>
    </template>
    <template v-else>
      <AppTooltip top :disabled="!isClickable">
        <template #activator="{ on, attrs }">
          <div v-bind="attrs"
               style="width: fit-content"
               v-on="on"
          >
            <AppButton class="RightsDisplayButton"
                       outlined
                       :block="block"
                       :disabled="hoverOnly"
                       rounded
                       @click="isClickable ? $emit($event.type) : null"
            >
              {{ numberOfGroupsAndMembers }}
              <font-awesome-icon :icon="['fad', 'user-lock']" class="ml-2"></font-awesome-icon>
            </AppButton>
          </div>
        </template>
        <div>
          <template v-if="groups.length > 0">
            <div>{{$t('common.users.RightsDisplayButton.sharedWith')}}</div>
            <ul>
              <li v-for="group in groups" :key="`group-${group.id}`">
                <font-awesome-icon :icon="['fas', 'users']" class="mr-2"></font-awesome-icon>
                {{group.name}}
              </li>
              <li v-for="member in members" :key="`member-${member.id}`">
                <font-awesome-icon :icon="['fas', 'user']" class="mr-2"></font-awesome-icon>
                {{member.fullName}}
              </li>
            </ul>
          </template>
        </div>
      </AppTooltip>
    </template>
  </div>
</template>

<script>
import AppTooltip from '../AppTooltip'
import AppButton from '../buttons/AppButton'
export default {
  name: 'RightsDisplayButton',
  components: {
    AppButton,
    AppTooltip,
  },
  props: {
    rights: {
      type: Object,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
    // Disables the btn but keeps the hover tooltip animation
    hoverOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // If we're using the signature with read rights, read is always an object
    // Otherwise it'll be undefined
    hasReadRights () {
      return this.rights.read
    },
    isPublic () {
      if (this.hasReadRights) {
        return this.rights.read.isPublic
      } else {
        return this.rights.isPublic
      }
    },
    numberOfGroupsAndMembers () {
      return this.groups.length + this.members.length
    },
    groups () {
      if (this.hasReadRights) {
        if (this.rights.read.groups.length > 0) {
          return this.rights.read.groups
        }
      } else {
        return this.rights.groups
      }
      return []
    },
    members () {
      if (this.hasReadRights) {
        if (this.rights.read.users.length > 0) {
          return this.rights.read.users
        }
      } else {
        return this.rights.users
      }
      return []
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .RightsDisplayButton {
  height: 28px !important;
  &.v-btn--outlined {
    border-color: var(--v-grey-lighten2);
    border-radius: 14px;
  }
}
</style>
