<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             :ok-disabled="selectedDocuments.length === 0"
             @cancel="closeDialog"
             @ok="validateSelection"
  >
    <template #title>{{ $t("project.question-answer.QuestionAnswerReferenceDocumentDialog.title") }}</template>

    <template #body>
      <template v-if="documentsFolder">
        <template v-if="documentsFolder.children.length !== 0">
          <AppClosdFildersTree v-model="selectedDocuments"
                               :root-folder="documentsFolder"
                               :multiple="false"
                               :disable-root-folder="true"
          />
        </template>
        <template v-else>
          <div class="caption text-center">
            {{$t('project.question-answer.QuestionAnswerReferenceDocumentDialog.noDocuments')}}
          </div>
        </template>
      </template>
      <template v-else>
        <div class="text-center">
          <v-progress-circular
            style="width: 48px; margin: 100px 0"
            color="primary"
            size="48"
            indeterminate
          />
        </div>
      </template>
    </template>
  </AppDialog>
</template>

<script>
import { orderBy } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppClosdFildersTree from '@/common/app-closd-filders-tree/AppClosdFildersTree'
import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'
import { GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'QuestionAnswerReferenceDocumentDialog',
  components: { AppDialog, AppClosdFildersTree },
  mixins: [dialogMixin],
  props: {
    selectedDocument: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      documentsFolder: null,
      selectedDocuments: this.selectedDocument ? [this.selectedDocument] : [],
    }
  },
  computed: {
    ...mapState('documents', ['documents']),
    ...mapGetters('room', ['roomMnemo']),
  },
  created () {
    this.getDocumentsFolder()
  },
  methods: {
    ...mapActions('documents', [GET_DOCUMENTS_BY_ID]),
    cancel () {
      this.$emit('hidden')
    },
    async getDocumentsFolder () {
      try {
        await this.GET_DOCUMENTS_BY_ID({
          mnemo: this.roomMnemo,
          queryObject: { ignoreTodoFolder: true, lazyMode: true },
          id: 0,
        })

        this.documentsFolder = {
          ...this.documents,
          children: orderBy(this.documents.children, ['type', 'basename'], ['desc', 'asc']),
        }
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.question-answer.QuestionAnswerReferenceDocumentDialog.getDocumentsError'),
        })
      }
    },
    validateSelection () {
      if (this.selectedDocuments.length > 0) {
        const selection = this.selectedDocuments[0]
        this.$emit('validate-selection', selection)
      }

      this.closeDialog()
    },
  },
}
</script>
