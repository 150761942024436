<template>
  <Pane :value="filtersPaneIsOpen"
        :tabs="tabs"
        :right="false"
        current-tab="filters"
        temporary
        class="SigningChecklistFiltersPane"
        @input="onPaneValueChanged"
  >
    <template #content-filters>
      <div v-if="filtersPaneIsOpen" class="d-flex flex-column justify-space-between">
        <div>
          <div v-show="checklistEnvelopeTypes.length > 0">
            <div class="font-weight-bold mb-2">{{ $t('project.signing-checklist.SigningChecklistFiltersPane.envelopeTypesFilterTitle') }}</div>
            <div>
              <v-checkbox v-for="(type) in checklistEnvelopeTypes"
                          :key="`envelope-type-${type}`"
                          v-model="localFilters.envelopeTypes"
                          :label="$t(`common.envelopeType.${type}`)"
                          :ripple="false"
                          :value="type"
                          hide-details
                          class="my-1"
              />
            </div>
            <v-divider class="my-4"/>
          </div>

          <div v-show="checklistSigningTypes.length > 0">
            <div class="font-weight-bold mb-2">{{ $t('project.signing-checklist.SigningChecklistFiltersPane.signingTypesFilterTitle') }}</div>
            <div>
              <v-checkbox v-for="(type) in checklistSigningTypes"
                          :key="`signing-type-${type}`"
                          v-model="localFilters.signingTypes"
                          :label="$t(`project.signing-checklist.SigningChecklistFiltersPane.signingTypesLabels`)[type]"
                          :ripple="false"
                          :value="type"
                          hide-details
                          class="my-1"
              />
            </div>
            <v-divider class="my-4"/>
          </div>

          <div v-show="checklistStatuses.length > 0">
            <div class="font-weight-bold mb-2">{{ $t('project.signing-checklist.SigningChecklistFiltersPane.statusesFilterTitle') }}</div>
            <div>
              <v-checkbox v-for="(status) in checklistStatuses"
                          :key="`status-${status}`"
                          v-model="localFilters.statuses"
                          :label="$t(`common.envelopeStatus.${status}`)"
                          :ripple="false"
                          :value="status"
                          hide-details
                          class="my-1"
              />
            </div>
          </div>
        </div>

        <v-row class="SigningChecklistFiltersPane-stickyButton-bar mt-4">
          <v-col>
            <AppButton
                       block
                       @click="onClearAllFilters"
            >
              {{ $t('project.signing-checklist.SigningChecklistFiltersPane.resetFiltersButton') }}
            </AppButton>
          </v-col>
          <v-col>
            <AppButton color="primary"
                       block
                       @click="onApplyFilters"
            >
              {{ $t('project.signing-checklist.SigningChecklistFiltersPane.applyFiltersButton') }}
            </AppButton>
          </v-col>
        </v-row>
      </div>

      <AppDialog :is-open="openCloseDialog"
                 :ok-text="$t('common.yes')"
                 :cancel-text="$t('common.no')"
                 @ok="onOkCloseDialog"
                 @cancel="onCancelCloseDialog"
      >
        <template #title>{{ $t('project.signing-checklist.SigningChecklistFiltersPane.closeDialogTitle') }}</template>
        <template #body>
          <v-row>
            <v-col>
              {{ $t('project.signing-checklist.SigningChecklistFiltersPane.closeDialogText') }}
            </v-col>
          </v-row>
        </template>
      </AppDialog>
    </template>
  </Pane>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import Pane from '@/common/Pane'
import { Filter } from '@/models/filter.model'
import {
  SET_FILTERS_PANE_IS_OPEN,
  SET_SELECTED_STEPS,
  SET_SIGNING_CHECKLIST_FILTERS,
} from '@/store/modules/signing-checklist/mutation_types'

const EMPTY_FILTERS = {
  envelopeTypes: [],
  signingTypes: [],
  statuses: [],
}

export default {
  name: 'SigningChecklistFiltersPane',
  components: { AppDialog, AppButton, Pane },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      localFilters: { ...EMPTY_FILTERS },
      openCloseDialog: false,
      tabs: [{ id: 'filters', title: this.$t('common.filters') }],
    }
  },
  computed: {
    ...mapState('signingChecklist', ['filters', 'filtersPaneIsOpen']),
    ...mapGetters('signingChecklist', ['flatSigningChecklist', 'filteredSigningChecklist']),
    filtersHasChange () {
      return this.localFilters?.envelopeTypes?.length !== this.filters.filter(filter => filter.category.key === 'envelopeTypes').length ||
      this.localFilters?.signingTypes?.length !== this.filters.filter(filter => filter.category.key === 'signingTypes').length ||
      this.localFilters?.statuses?.length !== this.filters.filter(filter => filter.category.key === 'statuses').length
    },
    checklistEnvelopeTypes () {
      const envelopeTypes = []
      this.filteredSigningChecklist.forEach(item => {
        // add fake envelopeType 'fromBulk' to target 'single' envelopes with multienvelope parent
        if (item.envelope?.parentMultienvelopeId && !envelopeTypes.includes('fromBulk')) {
          envelopeTypes.push('fromBulk')
        }
        if (item?.envelope?.envelopeType && !envelopeTypes.includes(item.envelope.envelopeType) && !item.envelope?.parentMultienvelopeId) {
          envelopeTypes.push(item.envelope.envelopeType)
        }
      })
      return envelopeTypes
    },
    checklistSigningTypes () {
      const signingTypes = []
      this.filteredSigningChecklist.forEach(item => {
        const signatureType = `${item.envelope?.provider}.${item.envelope?.signatureType}`
        if (item?.envelope?.provider && !signingTypes.includes(signatureType)) {
          signingTypes.push(signatureType)
        }
      })
      return signingTypes
    },
    checklistStatuses () {
      const statuses = []
      this.filteredSigningChecklist.forEach(item => {
        if (item?.envelope?.status && !statuses.includes(item.envelope.status)) {
          statuses.push(item.envelope.status)
        }
      })
      return statuses
    },
  },
  watch: {
    filtersPaneIsOpen (value) {
      if (value) {
        this.syncAppliedFiltersWithLocals()
      }
    },
  },
  methods: {
    ...mapMutations('signingChecklist', [
      SET_FILTERS_PANE_IS_OPEN,
      SET_SELECTED_STEPS,
      SET_SIGNING_CHECKLIST_FILTERS,
    ]),
    applyFilters () {
      const envelopeTypesFilters = this.localFilters.envelopeTypes.map(envelopeType =>
        new Filter('envelopeTypes', this.$t('project.signing-checklist.SigningChecklistFiltersPane.envelopeTypesFilterTitle'), envelopeType, this.$t(`common.envelopeType.${envelopeType}`)),
      )
      const signingTypesFilters = this.localFilters.signingTypes.map(signingType =>
        new Filter('signingTypes', this.$t('project.signing-checklist.SigningChecklistFiltersPane.signingTypesFilterTitle'), signingType, this.$t('project.signing-checklist.SigningChecklistFiltersPane.signingTypesLabels')[signingType]),
      )
      const statusesFilters = this.localFilters.statuses.map(status =>
        new Filter('statuses', this.$t('project.signing-checklist.SigningChecklistFiltersPane.statusesFilterTitle'), status, this.$t('common.envelopeStatus')[status]),
      )

      const filters = [
        ...envelopeTypesFilters,
        ...signingTypesFilters,
        ...statusesFilters,
      ]

      this.SET_SIGNING_CHECKLIST_FILTERS(filters)
      this.SET_SELECTED_STEPS([])
    },
    closePane () {
      this.SET_FILTERS_PANE_IS_OPEN(false)
    },
    onApplyFilters () {
      this.applyFilters()
      this.closePane()
    },
    onCancelCloseDialog () {
      this.openCloseDialog = false
      this.closePane()
    },
    onClearAllFilters () {
      this.localFilters = { ...EMPTY_FILTERS }
      this.applyFilters()
    },
    onOkCloseDialog () {
      this.openCloseDialog = false
      this.applyFilters()
      this.closePane()
    },
    onPaneValueChanged (isOpen) {
      if (!isOpen && this.filtersPaneIsOpen && this.filtersHasChange) {
        this.openCloseDialog = true
      } else if (!isOpen && this.filtersPaneIsOpen) {
        this.closePane()
      }
    },
    syncAppliedFiltersWithLocals () {
      this.localFilters.envelopeTypes = this.filters
        .filter(filter => filter.category.key === 'envelopeTypes')
        .map(filter => filter.value.key)
      this.localFilters.signingTypes = this.filters
        .filter(filter => filter.category.key === 'signingTypes')
        .map(filter => filter.value.key)
      this.localFilters.statuses = this.filters
        .filter(filter => filter.category.key === 'statuses')
        .map(filter => filter.value.key)
    },
  },
}
</script>

<style scoped lang="scss">
  .SigningChecklistFiltersPane-stickyButton-bar {
    position: sticky;
    bottom: 0;
    background-color: #fff;
  }
  ::v-deep.SigningChecklistFiltersPane .pane-content {
    padding-bottom: 0;
    overflow-y: auto;
  }
</style>
