<template>
  <div>
    <Alert v-if="isPartialFilesAlertDisplayed"
           type="warning"
           icon="fal fa-circle-exclamation"
           class="mb-2 mt-4"
    >
      <p>{{$t('project.signing-checklist.add.step3.SigningChecklistAddStep3.partialFiles')}}</p>
    </Alert>
    <PrepareSignature v-if="stepToPrepare"
                      :key="envelopeToPrepareIndex"
                      :step="stepToPrepare"
    />
    <p v-if="filteredImportedFiles.length === 0"
       class="text-center mt-4"
    >
      {{$t('project.signing-checklist.add.step3.SigningChecklistAddStep3.noValidFiles')}}
    </p>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Alert from '@/common/alerts/Alert.vue'

import PrepareSignature from '../../prepare-signature/PrepareSignature.vue'

export default {
  name: 'SigningChecklistAddStep3',
  components: {
    PrepareSignature,
    Alert,
  },
  props: {
    envelopeToPrepareIndex: {
      type: Number,
      required: true,
    },
  },
  metaInfo () {
    return {
      title: this.$t('project.signing-checklist.add.step3.SigningChecklistAddStep3.metaTitle'),
    }
  },
  computed: {
    ...mapState('signingChecklist', ['importedFiles']),
    ...mapGetters('signingChecklist', ['importedFilesInDraft']),
    filteredImportedFiles () {
      return this.importedFilesInDraft
    },
    stepToPrepare () {
      if (this.filteredImportedFiles.length > 0) {
        return this.filteredImportedFiles[this.envelopeToPrepareIndex]
      }
      return null
    },
    isPartialFilesAlertDisplayed () {
      return this.filteredImportedFiles.length !== 0 && (this.importedFiles.length !== this.filteredImportedFiles.length)
    },
  },
}
</script>
