<template>
<div class="d-flex flex-column align-center">
  <div class="EnvelopeTemplatesDialogNoData-icon mb-6">
    <font-awesome-icon :icon="['far', 'file-alt']" />
  </div>
  <div class="mb-6">{{$t('project.signing-checklist.envelope-templates.EnvelopeTemplatesNoData.noEnvelopeTemplates')}}</div>
  <AppButton v-if="isSubPayingUser"
             type="primary"
             @click="navigateToEnvelopeTemplateCreation"
  >
    <template #left-icon>
      <font-awesome-icon :icon="['far', 'plus']" class="mr-2"/>
    </template>
    {{$t('project.signing-checklist.envelope-templates.EnvelopeTemplatesNoData.createEnvelopeTemplate')}}
  </AppButton>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppButton from '@/design-system/buttons/AppButton.vue'
import { ENVELOPE_TEMPLATE_ADD_ROUTE_NAME } from '@/router'

export default {
  name: 'EnvelopeTemplatesNoData',
  components: { AppButton },
  computed: {
    ...mapGetters('user', ['isSubPayingUser']),
  },
  methods: {
    navigateToEnvelopeTemplateCreation () {
      this.$router.push({ name: ENVELOPE_TEMPLATE_ADD_ROUTE_NAME })
    },
  },
}
</script>

<style scoped lang="scss">
.EnvelopeTemplatesDialogNoData {
  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: var(--v-primary-lighten5);
    border-radius: 50%;

    & svg {
      font-size: 25px;
      color: var(--v-primary-base);
    }
  }
}
</style>
