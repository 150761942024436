<template>
    <v-row class="PrepareSignatureTopbar" align="center" no-gutters>
      <v-col class="text-h3 pl-5 py-4">
        <AppTooltip top>
          <template #activator="{attrs, on}">
            <div class="PrepareSignatureTopbar-envelopeName" v-bind="attrs"
                  v-on="on"
            >
              {{ envelopeName }}
            </div>
          </template>
          <span>{{ envelopeName }}</span>
        </AppTooltip>
      </v-col>
      <v-col v-if="pagesCount > 0"
             class="subtitle-2 text-right pr-5 py-1"
      >
        <span>{{$t('common.page')}}</span>
        <input v-model="newPage"
               type="number"
               class="PrepareSignatureTopbar-pageInput"
               :class="{ 'input-error': newPage < 1 || newPage > pagesCount }"
               :min="1"
               :max="pagesCount"
               v-on:input="newPage = $event.target.value"
               v-on:keydown.enter="scrollToPageNumber"
               v-on:change="onPageNumberChange"
        >
        <span>{{ `/ ${pagesCount}` }}</span>
      </v-col>
    </v-row>
</template>

<script>
import { debounce } from 'lodash-es'

import AppTooltip from '@/common/AppTooltip'

export default {
  name: 'PrepareSignatureTopbar',
  components: {
    AppTooltip,
  },
  props: {
    envelopeName: {
      type: String,
      default: '',
    },
    pagesCount: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: [Number, String],
      required: true,
    },
  },
  data () {
    return {
      newPage: this.currentPage,
    }
  },
  watch: {
    currentPage: function (newValue) {
      this.newPage = newValue
    },
  },
  created () {
    this.onPageNumberChange = debounce(this.onPageNumberChange, 800)
  },
  methods: {
    scrollToPageNumber () {
      if (this.newPage >= 1 && this.newPage <= this.pagesCount) {
        this.$emit('scroll-to', this.newPage)
      }
    },
    onPageNumberChange () {
      this.scrollToPageNumber()
    },
  },
}
</script>

<style scoped lang="scss">
.PrepareSignatureTopbar {
  background-color: var(--v-grey-lighten5);
  border: 1px solid #DEE2E6;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.PrepareSignatureTopbar-envelopeName {
  max-width: 1000px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PrepareSignatureTopbar-pageInput {
  background: white;
  border: 1px solid var(--v-tertiary-darken1);
  border-radius: 3px;
  padding: 3px 10px;
  margin: 0 4px;
  max-width: 80px;
  outline:none;

  &.input-error {
    border: 1px solid var(--v-error-darken1);
  }
}
</style>
