<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             :hide-footer="step === STEPS_NAMES.CHOSE_FORMULA"
             @cancel="closeDialog"
  >
    <template #title>
      <div class="d-block">
        <div class="text-h2 font-weight-medium">{{ $t(`projects.dialogs.ProjectsNewDataroomDialog.dialogTitle.${step}`) }}</div>
      </div>
    </template>
    <template v-if="step === STEPS_NAMES.SETUP_DATA_ROOM" #subTitle>
      {{ $t('projects.dialogs.ProjectsNewDataroomDialog.dialogSubtitle') }}
    </template>
    <template #body>
      <ProjectsNewDataroomDialogChooseFormula v-show="step === STEPS_NAMES.CHOSE_FORMULA"
                                              @configureClicked="step = STEPS_NAMES.SETUP_DATA_ROOM"
                                              @requestActivation="onRequestActivation"
      />
      <ProjectsNewDataroomDialogSetupDataRoom v-show="step === STEPS_NAMES.SETUP_DATA_ROOM"
                                              :new-data-room="newDataRoom"
                                              :validator="$v.termsChecked"
      />
      <ProjectsNewDataroomDialogCreateDataRoom v-show="step === STEPS_NAMES.CREATE_DATA_ROOM"
                                               :new-data-room="newDataRoom"
                                               :validator="$v.newDataRoom"
      />
    </template>
    <template v-if="step !== STEPS_NAMES.CHOSE_FORMULA" #footer>
      <div class="d-flex justify-space-between w-100">
        <div>
          <AppButton v-if="step === STEPS_NAMES.CREATE_DATA_ROOM && !isDataroomUnlimited"
                     color="white"
                     :disabled="postRoomProcessing"
                     @click="step = STEPS_NAMES.SETUP_DATA_ROOM"
          >
            {{ $t('common.previous') }}
          </AppButton>
        </div>
        <div>
          <AppButton color="white"
                     class="mr-2"
                     :disabled="postRoomProcessing"
                     @click="closeDialog"
          >
            {{ $t('common.cancel') }}
          </AppButton>
          <AppButton color="primary"
                     :loading="postRoomProcessing"
                     @click="onOkClick"
          >
            {{ okText }}
          </AppButton>
        </div>
      </div>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { maxLength, required, sameAs } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'
import { DataRoomToPost } from '@/models/room.model'
import ProjectsNewDataroomDialogChooseFormula from '@/projects/dialogs/ProjectsNewDataroomDialogChooseFormula.vue'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import ProjectsNewDataroomDialogCreateDataRoom from './ProjectsNewDataroomDialogCreateDataRoom.vue'
import ProjectsNewDataroomDialogSetupDataRoom from './ProjectsNewDataroomDialogSetupDataRoom.vue'

const STEPS_NAMES = Object.freeze({
  CHOSE_FORMULA: 'CHOSE_FORMULA',
  SETUP_DATA_ROOM: 'SETUP_DATA_ROOM',
  CREATE_DATA_ROOM: 'CREATE_DATA_ROOM',
})

export default {
  name: 'ProjectsNewDataroomDialog',
  components: { ProjectsNewDataroomDialogChooseFormula, ProjectsNewDataroomDialogSetupDataRoom, ProjectsNewDataroomDialogCreateDataRoom, AppDialog, AppButton },
  mixins: [dialogMixin, validationMixin],
  validations: {
    newDataRoom: {
      name: {
        required,
        maxLength: maxLength(100),
      },
    },
    termsChecked: { sameAs: sameAs(() => true) },
  },
  data () {
    return {
      STEPS_NAMES,
      step: STEPS_NAMES.CHOSE_FORMULA,
      newDataRoom: new DataRoomToPost(),
      termsChecked: false,
    }
  },
  computed: {
    ...mapGetters('rooms', ['dataRoomTypeId']),
    ...mapState('room', ['postRoomProcessing']),
    ...mapGetters('user', ['isDataroomUnlimited']),
    okText () {
      return this.step === STEPS_NAMES.SETUP_DATA_ROOM ? this.$t('common.next') : this.$t('common.validate')
    },
  },
  created () {
    if (this.isDataroomUnlimited) {
      this.step = STEPS_NAMES.CREATE_DATA_ROOM
    }
  },
  methods: {
    ...mapActions('room', ['postRoom']),
    async onOkClick () {
      if (this.step === STEPS_NAMES.SETUP_DATA_ROOM) {
        this.$v.termsChecked.$touch()
        if (!this.$v.termsChecked.$invalid) {
          this.step = STEPS_NAMES.CREATE_DATA_ROOM
        }
      } else if (this.step === STEPS_NAMES.CREATE_DATA_ROOM) {
        this.$v.newDataRoom.$touch()
        if (!this.$v.newDataRoom.$invalid) {
          try {
            if (!this.newDataRoom.type) {
              this.newDataRoom.type = this.dataRoomTypeId
            }
            await this.postRoom(this.newDataRoom)
            this.$store.commit(ENQUEUE_SNACKBAR, {
              color: 'success',
              message: this.$t('projects.dialogs.ProjectsNewDataroomDialog.postDataRoomSuccess'),
            })
          } catch (error) {
            console.error(error)
            this.$store.commit(ENQUEUE_SNACKBAR, {
              color: 'error',
              message: this.$t('projects.dialogs.ProjectsNewDataroomDialog.postDataRoomError'),
            })
          }
        }
      }
    },
    onRequestActivation () {
      this.closeDialog()
      this.$emit('requestActivation')
    },
  },
}
</script>
