<template>
  <v-app-bar app color="#fafbfc">
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile && (homeLayout || projectLayout) && fullAccessScope"
                        @click="setToggleProjectDrawer(true)"
    >
      <v-icon class="text--primary">fas fa-bars</v-icon>
    </v-app-bar-nav-icon>
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile && isInProfileLayout && fullAccessScope"
      @click="openProfileDrawer"
    >
      <v-icon class="text--primary">fas fa-bars</v-icon>
    </v-app-bar-nav-icon>

    <v-toolbar-title class="text-h2 px-0">
      <div v-if="projectLayout && !$vuetify.breakpoint.mobile && roomName"
           class="ml-3 d-flex"
      >
        <img class="mr-2"
             alt="room-category-icon"
             :src="projectCategoryIcon"
             width="24"
        />
        <AppTooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="AppBar-project-name text-truncate"
                  v-bind="attrs"
                  v-on="on">{{roomName}}</span>
          </template>
          {{roomName}}
        </AppTooltip>
      </div>
      <router-link v-if="$vuetify.breakpoint.mobile && fullAccessScope" :to="{ name: routes.ROOMS_ROUTE_NAME }"
                   class="pa-2 d-block"
      >
        <v-img
          alt="Closd Logo"
          contain
          src="/img/logo-small.svg"
          transition="scale-transition"
          width="40"
        />
      </router-link>

      <template v-if="!$vuetify.breakpoint.mobile && !projectLayout && fullAccessScope">
        <div v-if="homeLayout"
             class="pa-2 home-title"
        >
          <DocumentsBreadcrumb :items="getProjectsFoldersPath"></DocumentsBreadcrumb>
        </div>
        <router-link v-else :to="{ name: routes.ROOMS_ROUTE_NAME }"
                     class="pa-2 d-flex flex-column align-center text-decoration-none"
        >
          <v-img
            alt="Closd Name"
            class="shrink AppBar-closd-logo"
            contain
            min-width="100"
            src="/img/logo.svg"
            width="100"
          />

          <p class="body-2 mb-0 mt-1 grey--text text--darken-3">{{ $t('common.app-bar.AppBar.backToHome') }}</p>
        </router-link>
      </template>

      <v-img
        v-if="!fullAccessScope && $vuetify.breakpoint.mobile"
        alt="Closd Logo"
        contain
        src="/img/logo-small.svg"
        transition="scale-transition"
        width="40"
      />
      <v-img
        v-if="!fullAccessScope && !$vuetify.breakpoint.mobile && !projectLayout"
        alt="Closd Name"
        class="shrink mt-1"
        contain
        min-width="100"
        src="/img/logo.svg"
        width="100"
      />
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <ProjectsMenuItem v-if="!$vuetify.breakpoint.mobile && fullAccessScope" />

    <AppBarMenuItem v-if="fullAccessScope && profile.subscription && !profile.subscription?.hideArchiveOption"
                    v-slot="{active}"
                    :label="$t('common.app-bar.AppBar.archiving')"
                    :active="$route.name.includes(ARCHIVES_ROUTE_NAME)"
                    @click="onArchivesClick"
    >
      <ArchiveAddAccessIcon :isArchivingEnabled="isArchivingEnabled" :solid="active" />
    </AppBarMenuItem>

    <HelpMenuItem/>

    <div class="mx-2" style="height: 40px">
      <v-divider vertical></v-divider>
    </div>

    <ProfileMenuItem :fullAccessScope="fullAccessScope"/>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

import AppBarMenuItem from '@/common/app-bar/AppBarMenuItem.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import DocumentsBreadcrumb from '@/project/documents/DocumentsBreadcrumb.vue'
import {
  ROOMS_ROUTE_NAME,
  PROFILE_ROUTE_NAME,
  ARCHIVES_ROUTE_NAME,
} from '@/router'
import { SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN } from '@/store/modules/archives/mutation_types'
import { SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG } from '@/store/modules/subscription/mutation_types'

import HelpMenuItem from './HelpMenuItem'
import ProfileMenuItem from './ProfileMenuItem'
import ProjectsMenuItem from './ProjectsMenuItem'
import ArchiveAddAccessIcon from '../archives/ArchiveAddAccessIcon.vue'
import { getRoomIcon } from '../utils/rooms'

export default {
  name: 'AppBar',
  components: {
    AppBarMenuItem,
    AppTooltip,
    HelpMenuItem,
    ProfileMenuItem,
    ProjectsMenuItem,
    ArchiveAddAccessIcon,
    DocumentsBreadcrumb,
  },
  props: {
    fullAccessScope: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    tab: 1,
    item: 0,
    discoverDialogIsOpen: false,
  }),
  computed: {
    ...mapState(['homeLayout', 'projectLayout']),
    ...mapState('projectsFolders', ['projectsFoldersTree', 'currentProjectsFolder']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['roomName']),
    ...mapGetters('user', ['isArchivingEnabled']),
    ...mapState('user', ['profile']),
    ...mapState('documentsBreadcrumb', ['breadcrumb']),
    getProjectsFoldersPath () {
      const path = this.findProjectsFolderPath(this.projectsFoldersTree, this.currentProjectsFolder.parentId)
      let allPath = []
      if (this.$route.path.includes('recent')) {
        allPath = [{
          id: 'recent',
          name: this.$t('projects.ProjectsHeader.recent'),
        }]
      } else {
        allPath = [
          {
            id: undefined,
            name: this.$t('projects.ProjectsHeader.myProjects'),
          },
          ...path,
        ]
        if (this.currentProjectsFolder.id) {
          allPath.push(this.currentProjectsFolder)
        }
      }
      return allPath
    },
    routes () {
      return {
        ROOMS_ROUTE_NAME,
      }
    },
    onBoardingId () {
      return 'onboarding-' + this.$route.name
    },
    isInProfileLayout () {
      return this.$route.name.includes(PROFILE_ROUTE_NAME)
    },
    projectCategoryIcon () {
      let src = ''
      if (this.projectLayout && this.currentRoom) {
        src = getRoomIcon(this.currentRoom)
      }
      return src
    },
  },
  created () {
    this.ROOMS_ROUTE_NAME = ROOMS_ROUTE_NAME
    this.PROFILE_ROUTE_NAME = PROFILE_ROUTE_NAME
    this.ARCHIVES_ROUTE_NAME = ARCHIVES_ROUTE_NAME
  },
  methods: {
    ...mapActions(['setToggleProjectDrawer']),
    ...mapMutations('subscription', [SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG]),
    ...mapMutations('archives', [SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN]),
    findProjectsFolderPath (dossiers, parentId, path = []) {
      const parent = dossiers.find(dossier => dossier.id === parentId)
      if (!parent) {
        // If the parent folder is not found, returns an empty list
        return path
      }
      path.unshift(parent)

      // Otherwise, recursively searches for the parent folder
      return this.findProjectsFolderPath(dossiers, parent.parentId, path)
    },
    openProfileDrawer () {
      this.$store.commit('setNavigationDrawerProfile', true)
    },
    onArchivesClick () {
      if (this.isArchivingEnabled) {
        this.$router.push({ name: ARCHIVES_ROUTE_NAME })
      } else {
        this.SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN(true)
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .small-tab {
    min-width: 60px !important;
    padding: 0 !important;
  }
  .AppBar-project-name {
    max-width: 550px
  }

  ::v-deep .v-toolbar__content {
    padding: 0 16px;
  }

  .home-title {
    font-size: 16px;
    font-weight: 500;
    color: var(--v-grey-darken3);
  }
</style>
