<template>
  <v-container class="ForgotPassword-container mx-auto">
    <LoginTopbar />
    <v-row class="ForgotPassword-content">
      <v-col class="d-flex justify-center">
         <AppCard :elevation="0"
                  class="ForgotPassword-card "
        >
          <v-row class="mb-3">
            <v-col class="text-h2">{{ $t('login.views.ForgotPassword.title') }}</v-col>
          </v-row>
           <v-row>
            <v-col>
              <AppTextField v-model.trim="login.email"
                            :label="$t('login.views.ForgotPassword.email')"
                            type="email"
                            class="ForgotPassword-input"
                            :error-messages="emailErrors"
                            maxlength="255"
                            @blur="validateEmail"
                            @keypress.enter="prepareForgotPassword"
              />
            </v-col>
          </v-row>
           <v-row>
            <v-col class="text-center mt-3">
              <AppButton  color="primary"
                          :loading="loading"
                          @click="prepareForgotPassword"
              >
                {{$t('login.views.ForgotPassword.resetPassword')}}
              </AppButton>
            </v-col>
          </v-row>
          <v-row v-if="success === true && !submitButtonDisabled" class="ForgotPassword-divider mt-5">
              <v-divider></v-divider>
          </v-row>
          <v-row v-if="success === true && !submitButtonDisabled" align="center" class="mt-4">
                <v-col cols="1">
                <i class="success--text fad fa-circle-check text-success ForgotPassword-validation-check"></i>
              </v-col>
              <v-col cols="11">
                <p>{{$t('login.views.ForgotPassword.successMsg')}}</p>
            </v-col>
          </v-row>

        </AppCard>
      </v-col>
    </v-row>
    <BackToClosd />
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { createNamespacedHelpers } from 'vuex'

import { FORGOT_PASSWORD } from '@/store/modules/login/action_types'

import AppCard from '../../common/AppCard'
import AppTextField from '../../common/AppTextField'
import AppButton from '../../common/buttons/AppButton'
import { Login } from '../../models/login.model'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'
import BackToClosd from '../BackToClosd'
import LoginTopbar from '../LoginTopbar.vue'

const { mapState, mapActions } = createNamespacedHelpers('login')

export default {
  name: 'ForgotPassword',
  components: { LoginTopbar, AppCard, AppTextField, AppButton, BackToClosd },
  mixins: [validationMixin],
  validations: {
    login: {
      email: { required, email },
    },
  },
  data () {
    return {
      login: new Login(),
      show: false,
      success: false,
    }
  },
  computed: {
    ...mapState(['loading']),
    submitButtonDisabled () {
      return !this.$v.login.email.$dirty || this.$v.login.email.$anyError
    },
    emailErrors () {
      const errors = []
      if (!this.$v.login.email.$dirty) return errors
      !this.$v.login.email.email && errors.push(this.$t('common.validations.email'))
      !this.$v.login.email.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('login.views.ForgotPassword.email') }))
      return errors
    },
  },
  methods: {
    ...mapActions([FORGOT_PASSWORD]),
    validateEmail () {
      this.$v.login.email.$touch()
    },
    prepareForgotPassword () {
      if (this.loading) {
        return
      }
      this.$v.login.email.$touch()
      if (!this.$v.login.email.$invalid) {
        this.FORGOT_PASSWORD(this.login)
          .then(() => { this.success = true })
          .catch(error => {
            this.$store.commit(ENQUEUE_SNACKBAR, {
              color: 'error',
              message: this.$t('login.views.ForgotPassword.submitError'),
            })
            throw error
          })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .ForgotPassword-container {
    max-width: 1100px;
    max-height: 840px;
  }
  .ForgotPassword-divider{
    margin-left: -36px;
    margin-right: -36px
  }
  .ForgotPassword-content{
    margin-top: 10%;
  }
  .ForgotPassword-card {
    box-shadow: 0 0 10px 0 rgba(94, 86, 105, 0.1) !important;
    border: 1px solid #bdbdbd;
    padding: 25px 35px;
    max-height: 279px;
  }
  .ForgotPassword-validation-check{
    font-size: 30px;
  }
  ::v-deep .ForgotPassword-input .v-input__slot {
    background-color: #fff;
  }
  ::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #ffe3e1 !important;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')}{
    .ForgotPassword-content{
      margin-top: 0;
    }
  }
</style>
