<template>
<div>
  <AppButton block color="#e0e0e0"
             @click="isOpen = true"
  >
          <span class="text-h4">
            {{ $t('project.members.dialogs.MembersSeeAllDialog.seeAllMembers') }}
          </span>
  </AppButton>
  <AppDialog :is-open.sync="isOpen"
             ok-only
             size="xl"
             :persistent="false"
             :ok-text="$t('project.members.dialogs.MembersSeeAllDialog.close')"
             @ok="isOpen = false"
             @cancel="isOpen = false"
  >
    <template #title>
      {{ $t('project.members.dialogs.MembersSeeAllDialog.title', {groupName: group.name}) }}
    </template>
    <template #body>
      <v-row>
        <v-col cols="auto" class="text-h4 text--primary align-self-end">
          {{ $tc('project.members.dialogs.MembersSeeAllDialog.membersCount', group.members.length, {nbMembers: group.members.length}) }}
        </v-col>
        <v-col>
          <SearchTextField v-model="search" class="ml-auto" :tooltipText="$t('project.members.dialogs.MembersSeeAllDialog.searchPlaceholder')" />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col style="padding-top: 14px;">
          <v-data-table :headers="headers"
                        :items="searchResults"
                        :footer-props="{
                          'items-per-page-options': [50, 100, 250],
                        }"
                        :items-per-page="tableItemsPerPage"
                        :options.sync="options"
                        :hide-default-footer="(searchResults && searchResults.length <= 50)">
            <template v-if="isCurrentUserPm" #item.status="{item}">
              <div v-if="item.isRedactedForInactivity" class="d-flex align-center">
                <div class="text-caption ignore-draggable-elements quaternary lighten-3 px-2 py-1 mr-1 rounded-sm mt-1 w-100">
                  {{$t('project.members.Member.isRedactedForInactivity')}}
                </div>
              </div>
              <template v-else>
                <template v-if="!item.missingInfoToSign && item.isActive">
                  <div class="d-flex align-center">
                    <font-awesome-icon :icon="['fas', 'badge-check']"
                                      class="mx-1 tertiary--text text--lighten-1"
                    ></font-awesome-icon>
                    <span class="caption">{{$t('project.members.dialogs.MembersSeeAllDialog.identityVerified')}}</span>
                  </div>
                </template>
                <template v-if="!item.isActive">
                  <AppTooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        depressed
                        height="30"
                        width="100%"
                        class="text-caption quaternary lighten-3 px-2 py-1 rounded-sm"
                        v-on="on"
                        @click="sendEmail(item.id)"
                      >
                        <v-icon class="mr-2" small color="quaternary">fa-envelope</v-icon>
                        <v-spacer></v-spacer>
                        {{$t('project.members.dialogs.MembersSeeAllDialog.accountNotActivated')}}
                      </v-btn>
                    </template>
                    <span>{{$t('project.members.dialogs.MembersSeeAllDialog.sendFollowUpEmail')}}</span>
                  </AppTooltip>
                </template>
                <template v-if="item.isActive && isUserMissingIdCheck(item)">
                  <AppTooltip top>
                    <template #activator="{attrs, on}">
                      <div class="px-2 py-1 rounded-sm text-caption d-flex"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <font-awesome-icon :icon="['fas', 'address-card']"
                                          class="text-body-2 mr-1"
                                          color="grey"
                        ></font-awesome-icon>
                        {{$t('project.members.dialogs.MembersSeeAllDialog.identityVerificationNotPerformed')}}
                      </div>
                    </template>
                    <span> {{$t('project.members.dialogs.MembersSeeAllDialog.tooltipIdentityVerificationNotPerformed', {name: item.fullName})}} </span>
                  </AppTooltip>
                </template>
              </template>
            </template>
            <template v-if="isCurrentUserPm" #item.remove="{item}">
              <MemberDeleteDialog v-if="item.id !== currentUserId" :member="item" :withTrashIcon="true" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </AppDialog>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import AppButton from '@/common/buttons/AppButton'
import SearchTextField from '@/common/SearchTextField'
import { isUserMissingIdCheck } from '@/common/utils/users'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'

import MemberDeleteDialog from './MemberDeleteDialog'
import AppDialog from '../../../common/dialogs/AppDialog'

export default {
  name: 'SeeAllMembers',
  components: {
    AppButton,
    AppDialog,
    AppTooltip,
    MemberDeleteDialog,
    SearchTextField,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isOpen: false,
      search: '',
      options: {},
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapGetters('user', ['currentUserId']),
    searchResults () {
      if (this.search) {
        return this.group.members.filter(member => {
          let includesName; let includesCompany; let includesEmail
          if (member.fullName) {
            includesName = member.fullName.toLowerCase().includes(this.search.toLowerCase())
          }
          if (member.company) {
            includesCompany = member.company.toLowerCase().includes(this.search.toLowerCase())
          }
          if (member.email) {
            includesEmail = member.email.toLowerCase().includes(this.search.toLowerCase())
          }
          return includesName || includesCompany || includesEmail
        })
      }
      return this.group.members
    },
    headers () {
      const h = [
        {
          text: this.$t('project.members.dialogs.MembersSeeAllDialog.headers.name'),
          value: 'fullName',
          cellClass: 'subtitle-1',
        },
        {
          text: this.$t('project.members.dialogs.MembersSeeAllDialog.headers.mail'),
          value: 'email',
          cellClass: 'subtitle-1',
        },
        {
          text: this.$t('project.members.dialogs.MembersSeeAllDialog.headers.company'),
          value: 'company',
          cellClass: 'subtitle-1',
        },
      ]
      if (this.isCurrentUserPm) {
        h.push(
          {
            text: this.$t('project.members.dialogs.MembersSeeAllDialog.headers.status'),
            value: 'status',
          },
          {
            text: this.$t('project.members.dialogs.MembersSeeAllDialog.headers.remove'),
            value: 'remove',
            sortable: false,
          },
        )
      }
      return h
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, 'see-all-members-items-per-page')
      return itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : 50
    },
  },
  watch: {
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, 'see-all-members-items-per-page'))) {
          setUserSetting(this.currentUserId, 'see-all-members-items-per-page', newValue.itemsPerPage)
        }
      },
    },
  },
  methods: {
    ...mapActions('groups', ['CHASE_MEMBER']),
    isUserMissingIdCheck (user) {
      return isUserMissingIdCheck(user)
    },
    sendEmail (memberId) {
      this.CHASE_MEMBER(memberId)
        .then(() => {
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: this.$t('project.members.dialogs.MembersSeeAllDialog.messages.successChaseUser'),
          })
        })
        .catch(error => {
          if (error.response?.status === 403 && error.response?.data?.errorCode === 'ERR_WAIT_BEFORE_CHASING') {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.dialogs.MembersSeeAllDialog.messages.errorChaseUserWait'),
            })
          } else if (error.response?.status === 409 && error.response?.data?.errorCode === 'ERR_USER_ALREADY_ACTIVE') {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.dialogs.MembersSeeAllDialog.messages.errorChaseUserAlreadyActive'),
            })
          } else {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.dialogs.MembersSeeAllDialog.messages.errorChaseUser'),
            })
          }
        })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-data-table {
  .v-data-table-header {
    tr {
      background-color: #eee;
    }
    th {
      font-size: 14px;
      span {
        margin-right: 6px;
      }
    }
  }
  tr td:last-child {
    padding-right: 0;
  }
}
</style>
