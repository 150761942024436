<template>
  <AppDialog :is-open="renameAttachedFilesDialog.isOpen"
             :ok-text="$t('project.checklist.dialogs.ChecklistRenameAttachedFileDialog.title')"
             :ok-loading="renameAttachedFilePending"
             :ok-disabled="renameAttachedFilePending"
             :cancel-disabled="renameAttachedFilePending"
             @ok="prepareRenameAttachedFile"
             @cancel="resetAndClose"
  >
    <template #title>{{ $t('project.checklist.dialogs.ChecklistRenameAttachedFileDialog.title') }}</template>
    <template #body>
      <form @submit.stop.prevent="prepareRenameAttachedFile">
        <AppTextField v-model.trim="newName"
                      autofocus
                      :label="$t('project.checklist.dialogs.ChecklistRenameAttachedFileDialog.renameFile')"
                      :error-messages="newNameErrors"
                      counter="100"
                      maxlength="100"
        />
      </form>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import AppTextField from '../../../common/AppTextField'
import AppDialog from '../../../common/dialogs/AppDialog'
import { RENAME_ATTACHED_FILE, GET_TASK_FOLDER, GET_GLOBAL_TASK_FOLDER } from '../../../store/modules/checklist/action_types'
import { SET_RENAME_ATTACHED_FILE_DIALOG } from '../../../store/modules/checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'
export default {
  name: 'ChecklistRenameAttachedFileDialog',
  components: { AppDialog, AppTextField },
  mixins: [validationMixin],
  validations: {
    newName: {
      required,
      maxLength: maxLength(100),
    },
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      newName: '',
    }
  },
  computed: {
    ...mapState('checklist', ['renameAttachedFilesDialog', 'renameAttachedFilePending']),
    isOpen () { return this.renameAttachedFilesDialog.isOpen },
    fileToRename () { return this.renameAttachedFilesDialog.fileToRename },
    newNameErrors () {
      const errors = []
      if (!this.$v.newName.$dirty) return errors
      !this.$v.newName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.checklist.dialogs.ChecklistRenameAttachedFileDialog.renameFile') }))
      !this.$v.newName.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.checklist.dialogs.ChecklistRenameAttachedFileDialog.renameFile') }))
      return errors
    },
  },
  watch: {
    isOpen (val) {
      if (val) this.newName = this.fileToRename.basename
    },
  },
  methods: {
    ...mapActions('checklist', [RENAME_ATTACHED_FILE, GET_TASK_FOLDER, GET_GLOBAL_TASK_FOLDER]),
    resetAndClose () {
      this.newName = ''
      this.$store.commit(`checklist/${SET_RENAME_ATTACHED_FILE_DIALOG}`, {
        isOpen: false,
        fileToRename: null,
      })
    },
    async prepareRenameAttachedFile () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.RENAME_ATTACHED_FILE({
            mnemo: this.mnemo,
            fileId: this.fileToRename.id,
            data: {
              basename: this.newName,
            },
          })
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.checklist.dialogs.ChecklistRenameAttachedFileDialog.renameAttachedFileSuccess'),
          })
          // Global rename operation
          if (this.$store.state.checklist.globalAttachedFilesDialogIsOpen) {
            this.GET_GLOBAL_TASK_FOLDER(this.mnemo)
          } else {
            this.GET_TASK_FOLDER({
              mnemo: this.mnemo,
              folderId: this.fileToRename.parent.id,
            })
          }
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.dialogs.ChecklistRenameAttachedFileDialog.renameAttachedFileError'),
          })
        } finally {
          this.resetAndClose()
        }
      }
    },
  },
}
</script>
