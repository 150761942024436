<template>
<div>
  <template v-if="closingSummaryPending">
    <div class="text-center">
      <AppLoader/>
    </div>
  </template>
  <template v-else>
    <SigningChecklistSummary :allow-envelope-grouping="currentRoom.allowEnvelopeGrouping"
                             :data="data"
                             :is-loading="closingPredictPending"
    />
    <template v-if="isEnvelopeGroupingVisible">
      <SigningChecklistDocumentsBundleSection @change-toggle="onChangeEnvelopeGroupingToggle" />
    </template>
  </template>
</div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex'

import AppLoader from '@/common/AppLoader'
import SigningChecklistDocumentsBundleSection from '@/project/signing-checklist/dialogs/SigningChecklistDocumentsBundleSection'
import SigningChecklistSummary from '@/project/signing-checklist/SigningChecklistSummary'
import {
  GET_CLOSING_PREDICT,
} from '@/store/modules/signing-checklist/action_types'

const { mapState: mapRoomState } = createNamespacedHelpers('room')
const { mapActions } = createNamespacedHelpers('signingChecklist')

export default {
  name: 'SigningChecklistStartSigningDialogSummaryStep',
  components: { AppLoader, SigningChecklistDocumentsBundleSection, SigningChecklistSummary },
  inject: ['onChangeEnvelopeGrouping'],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      data: null,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['closingPredictPending', 'closingSummaryPending', 'envelopeStats', 'envelopeStatsPending']),
    ...mapRoomState(['currentRoom']),
    isEnvelopeGroupingVisible () {
      return process.env.VUE_APP_ENABLE_ENVELOPE_GROUPING &&
        (this.currentRoom.canAllowEnvelopeGrouping === true ||
          this.currentRoom.allowEnvelopeGrouping === true
        )
    },
  },
  methods: {
    ...mapActions([GET_CLOSING_PREDICT]),
    async getData () {
      this.data = await this.GET_CLOSING_PREDICT({ mnemo: this.mnemo })
    },
    async onChangeEnvelopeGroupingToggle () {
      this.data = null
      await this.onChangeEnvelopeGrouping().then(() => {
        this.getData()
      })
    },
  },
  async created () {
    this.getData()
  },
}
</script>
