<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             @cancel="closeDialog"
  >
    <template #title>
      <ProjectTypeFeatureHeader :shadow-color="PROJECT_TYPE_COLORS.PROJECT"
                                illustration="/img/contact_closd.svg"
      >
        <template #title>
          <h3 class="text-h3' light-blue--text">{{ $t('projects.dialogs.ProjectTypeQuoteRequestDialog.contactUs') }}</h3>
          <h1 class="text-h1">{{ $t('projects.dialogs.ProjectTypeQuoteRequestDialog.quoteRequest') }}</h1>
        </template>
      </ProjectTypeFeatureHeader>
    </template>

    <template #body>
      <div class="body">
        <div class="copyright">
          <CopyrightLink author="pch.vector"
                         link="https://fr.freepik.com/vecteurs-libre/document-signature-partenaires-commerciaux-petits-personnages-stylo-papier-signature-sceau-plat-illustration_11235250.htm"
          />
        </div>

        <i18n path="projects.dialogs.ProjectTypeQuoteRequestDialog.text" tag="p" >
          <template #phoneNumber>
            <a :href="`tel:${SALES_PHONE_NUMBER_WITH_COUNTRY_CODE}`" class="phone-number">{{ SALES_PHONE_NUMBER }}</a>
          </template>
        </i18n>

        <p class="mt-4 font-weight-bold required-asterisk">{{ $t('projects.dialogs.ProjectTypeQuoteRequestDialog.phoneNumberLabel') }}</p>
        <AppPhoneField :value="telephone"
                       class="my-6"
                       label=""
                       :hideDetails="false"
                       :error-messages="phoneNumberErrors"
                       @input="handlePhoneChange"
        />

        <p class="mt-4 font-weight-bold">{{$t('projects.dialogs.ProjectTypeQuoteRequestDialog.whichProjectTypeQuestion')}}</p>

        <AppSelect v-model="$v.quoteRelatedProjectType.$model"
                   :items="projectTypes"
                   class="projet-type-select my-6"
                   no-label
        >
          <template #selection="{item}">
            <div class="d-flex align-center">
              <i18n path="projects.dialogs.ProjectTypeQuoteRequestDialog.projectTypeSelection" tag="p" class="mb-0">
                <template #projectTypeName>
                  <span class="font-weight-bold">{{item.text}}</span>
                </template>
              </i18n>
            </div>
          </template>
          <template #item="{item}">
            <div class="d-flex align-center">
              <i18n path="projects.dialogs.ProjectTypeQuoteRequestDialog.projectTypeSelection" tag="p" class="mb-0">
                <template #projectTypeName>
                  <span class="font-weight-bold">{{item.text}}</span>
                </template>
              </i18n>
            </div>
          </template>
        </AppSelect>

        <v-textarea v-model="$v.requestDetails.$model"
                    hide-details
                    color="blue darken-2"
                    no-resize
                    outlined
                    :placeholder="$t('projects.dialogs.ProjectTypeQuoteRequestDialog.requestMessagePlaceholder')"
                    rows="4"
        />

        <v-checkbox v-model="$v.isPrivacyPolicyCheck.$model" hide-details class="privacy-policy-check">
          <template #label>
            <i18n path="projects.dialogs.ProjectTypeQuoteRequestDialog.privacyPolicyConsentText" tag="p" class="mb-0">
              <template #privacyPolicyUrl>
                <a :href="$t('projects.dialogs.ProjectTypeQuoteRequestDialog.privacyPolicyUrl')"
                   target="_blank"
                   class="font-weight-bold d-inline-block"
                   @click.stop
                >
                  {{$t('projects.dialogs.ProjectTypeQuoteRequestDialog.privacyPolicy')}}
                </a>
              </template>
            </i18n>
          </template>
        </v-checkbox>
        <p class="caption mt-4">* {{$t('projects.dialogs.ProjectTypeQuoteRequestDialog.mandatoryFields')}}</p>
      </div>
    </template>

    <template #footer>
      <v-spacer />

      <AppButton
        color="white"
        @click="closeDialog"
      >
        {{$t('common.cancel')}}
      </AppButton>

      <AppButton
        color="primary"
        :disabled="$v.$invalid"
        :loading="quoteRequestPending"
        @click="sendRequest"
      >
        {{ $t("projects.dialogs.ProjectTypeQuoteRequestDialog.sendRequest") }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppPhoneField from '@/common/AppPhoneField.vue'
import AppSelect from '@/common/AppSelect'
import AppButton from '@/common/buttons/AppButton'
import CopyrightLink from '@/common/CopyrightLink.vue'
import AppDialog from '@/common/dialogs/AppDialog'
import { SALES_PHONE_NUMBER, SALES_PHONE_NUMBER_WITH_COUNTRY_CODE } from '@/common/utils/closdInfo'
import { QUOTE_REQUEST_TOPICS } from '@/common/utils/quoteRequestTopics'
import { dialogMixin } from '@/mixins/dialogMixin'
import { PROJECT_TYPE_COLORS } from '@/projects/dialogs/projectTypeColors'
import ProjectTypeFeatureHeader from '@/projects/project-types/ProjectTypeFeatureHeader'
import { REQUEST_FEATURE_QUOTE } from '@/store/modules/subscription/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

const AVAILABLE_TOPICS_FOR_QUOTE = Object.values(QUOTE_REQUEST_TOPICS)

export default {
  name: 'ProjectTypeQuoteRequestDialog',
  components: { AppPhoneField, AppButton, AppSelect, AppDialog, CopyrightLink, ProjectTypeFeatureHeader },
  mixins: [dialogMixin, validationMixin],
  validations () {
    return {
      isPrivacyPolicyCheck: {
        required,
        sameAs: sameAs(() => true),
      },
      quoteRelatedProjectType: { required },
      requestDetails: { required },
      telephone: { required },
    }
  },
  props: {
    defaultProjectType: {
      type: String,
      required: true,
      validator (value) {
        return AVAILABLE_TOPICS_FOR_QUOTE.includes(value)
      },
    },
  },
  data () {
    return {
      SALES_PHONE_NUMBER,
      SALES_PHONE_NUMBER_WITH_COUNTRY_CODE,
      PROJECT_TYPE_COLORS,
      isPrivacyPolicyCheck: false,
      projectTypes: [],
      quoteRelatedProjectType: null,
      requestDetails: null,
      telephone: '',
    }
  },
  computed: {
    ...mapState('subscription', ['quoteRequestPending']),
    ...mapState('user', ['profile']),
    ...mapGetters('user', ['canCurrentUserCreateDataroom', 'canCurrentUserCreateProject', 'isArchivingEnabled', 'isDataroomUnlimited']),
    phoneNumberErrors () {
      const errors = []
      if (!this.$v.telephone.$dirty) return errors
      !this.$v.telephone.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.phoneNumber') }))
      return errors
    },
  },
  created () {
    if (!this.canCurrentUserCreateProject) {
      this.projectTypes.push({ text: this.$t('projects.dialogs.ProjectTypeQuoteRequestDialog.projectTypes.project'), value: QUOTE_REQUEST_TOPICS.STD_PROJECT })
    }

    if (!this.canCurrentUserCreateDataroom || (this.canCurrentUserCreateDataroom && !this.isDataroomUnlimited)) {
      this.projectTypes.push({ text: this.$t('projects.dialogs.ProjectTypeQuoteRequestDialog.projectTypes.dataroom'), value: QUOTE_REQUEST_TOPICS.DATAROOM })
    }

    if (!this.isArchivingEnabled) {
      this.projectTypes.push({ text: this.$t('projects.dialogs.ProjectTypeQuoteRequestDialog.projectTypes.archives'), value: QUOTE_REQUEST_TOPICS.ELECTRONIC_SAFE })
    }

    this.quoteRelatedProjectType = this.defaultProjectType
    this.telephone = this.profile.telephone
  },
  methods: {
    ...mapActions('subscription', [REQUEST_FEATURE_QUOTE]),
    async sendRequest () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const consentText = this.$t('projects.dialogs.ProjectTypeQuoteRequestDialog.privacyPolicyConsentText', { privacyPolicyUrl: this.$t('projects.dialogs.ProjectTypeQuoteRequestDialog.privacyPolicy') })

        const quoteRequestData = {
          topic: this.quoteRelatedProjectType,
          message: this.requestDetails,
          consentText: consentText, // Consent text sent as-is for HubSpot processing
          gdprOptIn: this.isPrivacyPolicyCheck,
          telephone: this.telephone,
        }

        try {
          await this.REQUEST_FEATURE_QUOTE(quoteRequestData)

          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('projects.dialogs.ProjectTypeQuoteRequestDialog.quoteRequestSuccessMessage'),
          })
        } catch (error) {
          console.error(error)
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('projects.dialogs.ProjectTypeQuoteRequestDialog.quoteRequestErrorMessage'),
          })
        } finally {
          this.closeDialog()
        }
      }
    },
    handlePhoneChange (input, event) {
      this.telephone = event.number.e164 || input
    },
  },
}
</script>

<style scoped lang="scss">
.phone-number {
  color: #4C75B5;
  font-weight: bold;
  text-decoration: none;
}

.projet-type-select {
  max-width: 390px;
}

.body {
  margin: 0 45px;
}
@media (max-width: 768px) {
  .body {
    margin: 0 20px;
  }
}

::v-deep .privacy-policy-check .v-input__slot {
  display: flex;
  align-items: flex-start;
}

.copyright {
  position: absolute;
  right: -20px;
  transform: rotate(-90deg);
}
</style>
