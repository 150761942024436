export const SET_FILE_PENDING = 'SET_FILE_PENDING'
export const REMOVE_FILE_PENDING = 'REMOVE_FILE_PENDING'
export const SET_FILE_ERROR = 'SET_FILE_ERROR'
export const SET_WATERMARKED_FILE_PENDING = 'SET_WATERMARKED_FILE_PENDING'
export const REMOVE_WATERMARKED_FILE_PENDING = 'REMOVE_WATERMARKED_FILE_PENDING'
export const SET_WATERMARKED_FILE = 'SET_WATERMARKED_FILE'
export const SET_WATERMARKED_FILE_ERROR = 'SET_WATERMARKED_FILE_ERROR'
export const RESET_WATERMARKED_FILE_WITH_FILE_ID = 'RESET_WATERMARKED_FILE_WITH_FILE_ID'
export const RESET_WATERMARKED_FILE = 'RESET_WATERMARKED_FILE'
export const SET_WATERMARKED_DIALOG_DATA = 'SET_WATERMARKED_DIALOG_DATA'
export const RESET_WATERMARKED_DIALOG_DATA = 'RESET_WATERMARKED_DIALOG_DATA'
export const SET_WATERMARKED_REQUEST_PENDING = 'SET_WATERMARKED_REQUEST_PENDING'
export const REMOVE_WATERMARKED_REQUEST_PENDING = 'REMOVE_WATERMARKED_REQUEST_PENDING'
