<template>
  <div class="save-checklist-dialog-template-type">
    <v-radio-group v-model="templateType"
                   class="template-type-container"
    >
      <div v-for="option in templateTypeOptionsValues"
           :key="`option-${option}`"
           :class="`templateType__card-${option}`"
           class="d-flex flex-column"
      >
        <div :value="option"
             class="template-type-card clickable mb-2"
             :class="{'template-type-card--active': option === templateType}"
             @click="templateType = option"
        >
          <div class="d-flex align-center">
            <v-img :src="`/img/checklist/checklist-template-${option}.svg`"
                    max-height="40"
                    max-width="40"
                    class="mr-2"
            />
            <div v-if="option === TEMPLATE_TYPE_OPTIONS.NEW">
              <app-text variant="small-bold">
                {{ $t('project.checklist.dialogs.SaveChecklistDialog.createNewModel') }}
              </app-text>
              <app-text variant="small-regular">
                {{ $t('project.checklist.dialogs.SaveChecklistDialog.createNewModelSubtitle') }}
              </app-text>
            </div>
            <div v-else>
              <app-text variant="small-bold">
                {{ $t('project.checklist.dialogs.SaveChecklistDialog.updateExistingModel') }}
              </app-text>
              <app-text variant="small-regular">
                {{ $t('project.checklist.dialogs.SaveChecklistDialog.updateExistingModelSubtitle') }}
              </app-text>
            </div>
          </div>
        </div>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
  import { TEMPLATE_TYPE_OPTIONS } from '@/common/utils/templateTypes.ts'

  export default {
    name: 'SaveChecklistTemplateType',
    props: {
      defaultTemplateType: {
        required: true,
        validator: (value) => {
          return value === null || typeof value === 'string'
        },
      },
    },
    data () {
      return {
        TEMPLATE_TYPE_OPTIONS,
        templateType: this.defaultTemplateType,
      }
    },
    computed: {
      templateTypeOptionsValues () {
        return Object.values(TEMPLATE_TYPE_OPTIONS)
      },
    },
    watch: {
      templateType (value) {
        this.$emit('change-template-type', value)
      },
    },
  }
</script>

<style lang="scss">
  .save-checklist-dialog-template-type {
    .template-type-card {
      padding: $spacer * 4 $spacer * 6;
    }

    .template-type-container {
      .v-input--radio-group__input {
        gap: 16px;
      }

      .template-type-card {
        border: 1px solid #DEE2E6;
        border-radius: 4px;

        &--active {
          outline: 2px solid var(--v-primary-base);
          outline-offset: -2px;
          background-color: var(--v-primary-lighten4);
        }

        .caption {
          color: #717B85;
          font-size: 12px;
        }
      }
    }
  }
</style>
