<template>
  <div class="top-bar d-flex justify-space-between">
    <div class="d-flex align-center">
      <v-img v-if="!isNatixisAccount && !signingFromSubdomain"
             src="/img/logo.svg"
             width="150"
      ></v-img>
      <v-img v-if="isNatixisAccount && !signingFromSubdomain"
             src="/img/logo_xNatixis.png"
             width="111"
             height="63"
      ></v-img>
    </div>

    <ClosdLangSelector />
  </div>
</template>

<script>
import { navigatingOnAvailableSubdomain } from '@/common/utils/url'

import ClosdLangSelector from '../common/ClosdLangSelector'

export default {
  name: 'LoginTopbar',
  components: { ClosdLangSelector },
  data () {
    return {
      isNatixisAccount: false,
      signingFromSubdomain: false,
    }
  },
  mounted () {
    this.isNatixisAccount = process.env.VUE_APP_ENABLE_NATIXIS_PASSWORD === 'true'
    this.signingFromSubdomain = navigatingOnAvailableSubdomain()
  },
}
</script>

<style scoped lang="scss">
.top-bar {
  height: 65px;
}
</style>
