import axios from 'axios'

export default {
  getFileLogs (mnemo, fileId) {
    return axios.get(`room/${mnemo}/file/${fileId}/logs`)
  },
  getFolderLogs (mnemo, folderId) {
    return axios.get(`room/${mnemo}/folder/${folderId}/logs`)
  },
  getRoomLogs (mnemo, config) {
    return axios.get(`/room/${mnemo}/logs`, config)
  },
  getLogsTypes (mnemo) {
    return axios.get(`/room/${mnemo}/logs/types`)
  },
}
