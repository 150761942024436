<template>
  <div>
    <template v-if="dateAfterToday">
      <div>{{ relativeFormattedDate }}</div>
      <AppTooltip top>
        <template #activator="{attrs, on}">
          <AppButton
            v-bind="attrs"
            v-on="on"
            @click="openRestoreRoomDialog"
          >
            {{$t('subscriptions.SubscriptionClosedProjectsCellDeletedAt.restore')}}
          </AppButton>
        </template>
        <span>{{$t('subscriptions.SubscriptionClosedProjectsCellDeletedAt.restoreRoomTooltip')}}</span>
      </AppTooltip>
    </template>
    <template v-else>
      <DateDisplay :value="deletedAt" />
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapMutations } from 'vuex'

import AppTooltip from '../common/AppTooltip.vue'
import AppButton from '../common/buttons/AppButton.vue'
import DateDisplay from '../common/DateDisplay.vue'
import { ISOToRelativeTime } from '../common/utils/dates'
import { SET_RESTORE_ROOM_DIALOG } from '../store/modules/subscription/mutation_types'
export default {
  name: 'SubscriptionClosedProjectsCellDeletedAt',
  components: {
    AppTooltip,
    AppButton,
    DateDisplay,
  },
  props: {
    deletedAt: {
      type: String,
      required: true,
    },
    roomId: {
      type: Number,
      required: true,
    },
    /**
     * A dayjs object that stores the date of today.
     * This is meant to be created out of the cell to avoid recalculating it too many times
     */
    todayCache: {
      default: null,
    },
  },
  computed: {
    dateAfterToday () {
      const daysJsValue = dayjs(this.deletedAt)
      return daysJsValue.isAfter(this.todayCache)
    },
    relativeFormattedDate () {
      if (this.dateAfterToday) {
        return ISOToRelativeTime(this.deletedAt)
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapMutations('subscription', [SET_RESTORE_ROOM_DIALOG]),
    openRestoreRoomDialog () {
      this.SET_RESTORE_ROOM_DIALOG(this.roomId)
    },
  },
}
</script>
