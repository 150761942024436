<template>
  <v-navigation-drawer
    v-model="isPaneOpened"
    app
    temporary
    :permanent="isPaneOpened"
    right
    :width="560"
  >
    <div
      v-if="documentToSummarize"
      class="pane-wrapper"
      data-locator="summary-pane"
    >
      <div class="pane-header">
        <v-img
          src="/img/ai_assistant_ico.svg"
          :width="24"
          :height="24"
        />

        <div>
          <app-text variant="large-bold">
            {{ t('paneTitle') }}
          </app-text>
          <app-text as="span"  variant="small-regular">
            {{ t('subTitle') }}

            <AppInfoTooltip bottom>
              {{ t('subTitleTooltip') }}
            </AppInfoTooltip>
          </app-text>
        </div>

        <AppFeatureTag tag="beta" />

        <app-button type="icon" @click="closePane">
          <app-icon icon-name="xmark" icon-weight="far" />
        </app-button>
      </div>

      <v-divider />

      <div class="selected-document">
        <DocumentSelection :selected-documents="[documentToSummarize]" />
      </div>

      <template v-if="shouldDisplayLoadingState">
        <DocumentsAISummaryLoadingState class="loading-state" />
      </template>
      <template v-else-if="shouldDisplayLoadingError">
        <div class="loading-error">
          <app-text variant="small-regular">
            {{ t('loadingErrorText') }}
          </app-text>
          <app-button variant="project" @click="loadSummariesForCurrentDocument">
            <template #left-icon>
              <app-icon icon-name="rotate-right" icon-weight="fas" />
            </template>
            {{ $t('common.retry') }}
          </app-button>
        </div>
      </template>
      <template v-else>
        <div class="summaries">
          <template v-if="shouldDisplayEmptyState">
            <AppChatMessageSeparator class="mb-8" />

            <div class="empty-state">
              <v-img
                src="/img/ai_assistant_logo.svg"
                :width="94"
                :height="94"
              />

              <app-text variant="large-bold">
                {{ t('emptyStateText') }}
              </app-text>
            </div>
          </template>
          <template v-else>
            <template v-for="(groupedSummaries, date) in groupedByDateSummaries">
              <AppChatMessageSeparator :key="date" :date="date" class="mb-8" />

              <DocumentsAISummaryChatMessage
                v-for="summary in groupedSummaries"
                :key="summary.id"
                class="summary"
                :message-date="summary.createdAt"
                :summary-id="summary.id"
                :summary-feedback="summary.feedback"
              >
                {{ summary.summary }}
              </DocumentsAISummaryChatMessage>
            </template>

            <DocumentsAISummaryRequestChatMessage
              v-for="requestDate in summaryRequestsDates"
              :key="requestDate"
              :request-date="requestDate"
              :document-id="documentToSummarize.id"
              class="summary"
              @error="onSummaryRequestError"
              @success="onSummaryRequestSuccess"
            />
          </template>
        </div>

        <div v-if="shouldDisplayActionButton" class="action-buttons">
          <AppTooltip top :disabled="isRequestButtonTooltipDisabled">
            <template #activator="{ attrs, on }">
              <div v-bind="attrs" v-on="on">
                <DocumentsAISummaryRequestButton
                  v-if="summaryRequest === 'error'"
                  :disabled="isRequestButtonDisabled"
                  @click="retryDocumentSummaryRequest"
                >
                  <template #left-icon>
                    <app-icon icon-name="rotate-right" icon-weight="fas" />
                  </template>
                  {{ t('retryResumingDocument') }}
                </DocumentsAISummaryRequestButton>
                <DocumentsAISummaryRequestButton
                  v-else
                  :disabled="isRequestButtonDisabled"
                  @click="requestDocumentSummary"
                >
                  <template #left-icon>
                    <app-icon icon-name="book" icon-weight="far" />
                  </template>
                  {{ isThereAtLeastOneSummary ? t('resumeDocumentAgain') : t('resumeDocument') }}
                </DocumentsAISummaryRequestButton>
              </div>
            </template>
            <span>{{ t('noMoreCreditsRemaining') }}</span>
          </AppTooltip>
        </div>
      </template>

      <v-divider />

      <div class="pane-footer">
        <DocumentsAISummaryRemainingCredits />

        <app-button
          type="outlined"
          variant="neutral"
          :disabled="shouldBlockPaneBeingClosed"
          @click="closePane"
        >
          {{ $t('common.close') }}
        </app-button>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import dayjs from 'dayjs'
import { groupBy, sortBy } from 'lodash-es'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppChatMessageSeparator from '@/common/app-chat/AppChatMessageSeparator.vue'
import AppFeatureTag from '@/common/AppFeatureTag.vue'
import AppInfoTooltip from '@/common/AppInfoTooltip.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import DocumentSelection from '@/common/document-selection/DocumentSelection.vue'
import { track } from '@/common/pendo/agent'
import { extractOnlyDateFromDateTime } from '@/common/utils/dates'
import { useTranslation } from '@/common/utils/translation'
import DocumentsAISummaryChatMessage from '@/project/documents/ai-summary/DocumentsAISummaryChatMessage.vue'
import DocumentsAISummaryLoadingState from '@/project/documents/ai-summary/DocumentsAISummaryLoadingState.vue'
import DocumentsAISummaryRemainingCredits from '@/project/documents/ai-summary/DocumentsAISummaryRemainingCredits.vue'
import DocumentsAISummaryRequestButton from '@/project/documents/ai-summary/DocumentsAISummaryRequestButton.vue'
import DocumentsAISummaryRequestChatMessage from '@/project/documents/ai-summary/DocumentsAISummaryRequestChatMessage.vue'
import aiSummariesService from '@/services/ai-summaries.service'

export default defineComponent({
  name: 'DocumentsAISummaryPane.vue',
  emits: ['close'],
  components: {
    AppFeatureTag,
    AppChatMessageSeparator,
    AppInfoTooltip,
    AppTooltip,
    DocumentSelection,
    DocumentsAISummaryChatMessage,
    DocumentsAISummaryLoadingState,
    DocumentsAISummaryRemainingCredits,
    DocumentsAISummaryRequestButton,
    DocumentsAISummaryRequestChatMessage,
  },
  setup () {
    return useTranslation({ keyPrefix: 'project.documents.pane.DocumentsAISummaryPane' })
  },
  data () {
    return {
      // TODO : introduce type for query state ('idle' | 'pending' | 'error') on Vue 3 migration
      summariesLoading: 'idle',
      summaryRequest: 'idle',
      summaryRequestsDates: [],
      summaries: [],
    }
  },
  watch: {
    async documentToSummarize (value) {
      if (value) {
        await this.loadSummariesForCurrentDocument()
      }
    },
    async summaryRequest () {
      if (this.isPaneOpened) {
        await this.scrollToLastSummary({ behavior: 'smooth' })
      }
    },
  },
  computed: {
    ...mapState('documentsAISummary', [
      'documentToSummarize',
      'requestSummaryPending',
      'summariesCredits',
      'summariesCreditsPending',
    ]),
    ...mapGetters('room', ['roomMnemo']),
    groupedByDateSummaries () {
      return groupBy(
        sortBy(this.summaries, ['createdAt']),
        ({ createdAt }) => extractOnlyDateFromDateTime(createdAt),
      )
    },
    isRequestButtonDisabled () {
      return this.summaryRequest === 'pending' ||
        this.summariesCreditsPending ||
        !this.summariesCredits ||
        this.summariesCredits?.remaining === 0
    },
    isRequestButtonTooltipDisabled () {
      return this.summariesCreditsPending || this.summariesCredits?.remaining > 0
    },
    isThereAtLeastOneSummary () {
      return this.summaries.length > 0 ||
        this.summaryRequestsDates.length > 1 ||
        (this.summaryRequestsDates.length === 1 && this.summaryRequest === 'idle')
    },
    isPaneOpened: {
      get () {
        return !!this.documentToSummarize
      },
      set (isOpened) {
        if (isOpened) {
          this.loadSummariesCreditsForCurrentUser()
        } else {
          this.closePane()
        }
      },
    },
    shouldBlockPaneBeingClosed () {
      return this.isPaneOpened && this.requestSummaryPending
    },
    shouldDisplayActionButton () {
      return this.summariesLoading === 'idle'
    },
    shouldDisplayEmptyState () {
      return this.summaries.length === 0 && this.summaryRequestsDates.length === 0
    },
    shouldDisplayLoadingError () {
      return this.summariesLoading === 'error'
    },
    shouldDisplayLoadingState () {
      return this.summariesLoading === 'pending'
    },
  },
  methods: {
    ...mapActions('documentsAISummary', ['LOAD_SUMMARIES_CREDITS']),
    ...mapMutations('documentsAISummary', ['SET_DOCUMENT_TO_SUMMARIZE_WITH_AI']),
    closePane () {
      if (this.shouldBlockPaneBeingClosed) {
        return
      }

      this.SET_DOCUMENT_TO_SUMMARIZE_WITH_AI(null)
      this.setInitialState()
    },
    async loadSummariesCreditsForCurrentUser () {
      try {
        await this.LOAD_SUMMARIES_CREDITS()
      } catch (error) {
        console.error(error)
      }
    },
    async loadSummariesForCurrentDocument () {
      this.summariesLoading = 'pending'

      try {
        const { data } = await aiSummariesService.getDocumentAISummaries(this.roomMnemo, this.documentToSummarize.id)
        this.summaries.push(...data)
        this.summariesLoading = 'idle'
        await this.scrollToLastSummary({ behavior: 'instant', block: 'end' })
      } catch (error) {
        console.error(error)
        this.summariesLoading = 'error'
      }
    },
    onSummaryRequestError () {
      this.summaryRequest = 'error'
    },
    async onSummaryRequestSuccess () {
      this.summaryRequest = 'idle'
      await this.loadSummariesCreditsForCurrentUser()
    },
    requestDocumentSummary () {
      track('request_document_ai_summary')
      this.summaryRequestsDates.push(dayjs().toISOString())
      this.summaryRequest = 'pending'
    },
    retryDocumentSummaryRequest () {
      this.summaryRequestsDates.pop()
      this.requestDocumentSummary()
    },
    async scrollToLastSummary (scrollOptions) {
      await this.$nextTick()
      const summariesHtmlElements = document.getElementsByClassName('summary')

      if (summariesHtmlElements.length > 0) {
        const lastSummary = summariesHtmlElements[summariesHtmlElements.length - 1]
        lastSummary.scrollIntoView(scrollOptions)
      }
    },
    setInitialState () {
      this.summariesLoading = 'idle'
      this.summaryRequest = 'idle'
      this.summaryRequestsDates = []
      this.summaries = []
    },
  },
})
</script>

<style scoped>
.pane-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pane-header,
.selected-document,
.loading-state,
.summaries,
.action-buttons,
.pane-footer {
  padding: 24px;
}

.pane-header {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  column-gap: 16px;
  align-items: center;
  padding-block: 12px;
  padding-inline: 24px;
}

.loading-state,
.loading-error,
.summaries {
  flex: 1;
}

.summaries {
  padding-block: 4px;
  overflow-y: auto;
}

.loading-error {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.loading-state {
  padding-top: 0;
}

.summary:not(:last-child) {
  margin-bottom: 24px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.pane-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
