import {
  SET_IS_OPEN,
  SET_SELECTED_DOCUMENTS,
  REMOVE_DOCUMENT,
  RESET_SELECTED_DOCUMENTS,
  SET_LOADING,
  SET_DOWNLOAD_PENDING,
  SET_DOWNLOAD_ERROR,
} from './mutation_types'

export const mutations = {
  [SET_IS_OPEN]: function (state, value) {
    state.isOpen = value
  },
  [SET_SELECTED_DOCUMENTS]: function (state, selectedDocuments) {
    state.selectedDocuments = selectedDocuments
  },
  [REMOVE_DOCUMENT]: function (state, index) {
    state.selectedDocuments.splice(index, 1)
  },
  [RESET_SELECTED_DOCUMENTS]: function (state) {
    state.selectedDocuments = []
  },
  [SET_LOADING]: function (state, value) {
    state.loading = value
  },
  [SET_DOWNLOAD_PENDING]: function (state, value) {
    state.downloadPending = value
  },
  [SET_DOWNLOAD_ERROR]: function (state, value) {
    state.downloadError = value
  },
}
