<template>
  <v-card>
    <v-card-title>
        {{ $t('project.members.dialogs.GroupImportDialog.title') }}
        <AppButton
          class="ml-auto mr-n5 mt-n6"
          icon
          @click="cancel"
        >
          <font-awesome-icon :icon="['fal', 'xmark']" size="lg" />
        </AppButton>
      </v-card-title>
      <v-card-text>
        <p class="text--primary mb-4">
          {{ $t('project.members.dialogs.GroupImportDialog.textInfo') }}
        </p>
        <v-row>
          <v-col>
            <AppDownloadFile :link="templateLink"
                             class="w-100 h-100"
            >
              <template #default="{submitForm}">
                <AppCard class="GroupImportDialog-card text-center clickable h-100"
                         hover
                         @click.native="submitForm">
                  <div class="d-inline-flex align-center ml-n3">
                    <div class="mr-3 step-chip">1</div>
                    <div class="text-h3">{{ $t('project.members.dialogs.GroupImportDialog.downloadModel') }}</div>
                  </div>
                  <div class="my-5">
                    <v-img src="/img/icons/excel_import.svg"
                           class="mx-auto"
                           width="70"
                    />
                  </div>
                </AppCard>
              </template>
            </AppDownloadFile>
          </v-col>
          <v-col>
            <AppCard class="GroupImportDialog-card text-center clickable h-100"
                     hover
                     @click.native="onFileInputClick">
              <div class="d-inline-flex align-center ml-n3">
                <div class="mr-3 step-chip">2</div>
                <div class="text-h3">{{ $t('project.members.dialogs.GroupImportDialog.uploadModel') }}</div>
              </div>
              <div class="my-5">
                <v-img src="/img/icons/excel_closd.svg"
                       class="mx-auto"
                       width="70"
                />
              </div>
              <div v-if="groupImportFormModel.selectedFile">{{ groupImportFormModel.selectedFile.name }}</div>
              <input ref="fileInput" type="file" class="d-none" name="fileInput" @input="selectFile"/>
            </AppCard>
          </v-col>
        </v-row>
        <v-row v-if="errorCode">
          <v-col>
            <div class="error--text text-center">
              {{ $t('project.members.dialogs.GroupImportDialog.error.massImport_' + errorCode) }}
              <template v-if="errorCode === 'ERR_INCORRECT_DATA'">
                <ul style="list-style-position: inside;" class="text-left">
                  <li v-for="(error, i) in errorDetails" :key="`errorLine-${i}`" class="py-1">
                    <!--
                      Number is incremented by 2 because the errors from the back-end are indexed from 0 and
                      there's also the header that's ignored by the parser but needs to be included to be accurate
                    -->
                    {{ $t('project.members.dialogs.GroupImportDialog.error.massImport_atLine')}} {{Number(i) + 2 }}
                    <ul style="list-style-position: inside;" class="ml-5">
                      <li v-for="(errorDetail, j) in error" :key="`error-${j}`">
                        {{ $t("project.members.dialogs.GroupImportDialog.error.massImport_" + errorDetail.errorCode) }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox v-model="groupImportFormModel.sendInvitationNow"
                        hide-details
                        class="pt-0 my-2"
                        @change="onSendInvitationNowChange"
            >
              <template #label>
                {{ $t('project.members.dialogs.GroupImportDialog.sendInvitationNow') }}
                <AppTooltip top>
                  <template #activator="{attrs, on}">
                    <font-awesome-icon :icon="['fas', 'circle-question']"
                                       class="ml-2"
                                       style="color: #ababab"
                                       v-bind="attrs"
                                       v-on="on"
                    ></font-awesome-icon>
                  </template>
                  <span>{{ $t('project.members.dialogs.GroupImportDialog.sendInvitationNowTooltip') }}</span>
                </AppTooltip>
              </template>
            </v-checkbox>

            <v-checkbox v-if="!isDataRoom"
                        v-model="groupImportFormModel.idVerification"
                        hide-details
                        class="pt-0 my-2"
            >
              <template #label>
                {{ $t('project.members.dialogs.GroupImportDialog.idVerification') }}
                <AppTooltip top>
                  <template #activator="{attrs, on}">
                    <font-awesome-icon :icon="['fas', 'circle-question']"
                                       class="ml-2"
                                       style="color: #ababab"
                                       v-bind="attrs"
                                       v-on="on"
                    ></font-awesome-icon>
                  </template>
                  <span>{{ $t('project.members.dialogs.GroupImportDialog.idVerificationTooltip') }}</span>
                </AppTooltip>
              </template>
            </v-checkbox>

            <v-checkbox v-model="groupImportFormModel.hidePm"
                        hide-details
                        class="pt-0 my-2"
            >
              <template #label>
                {{ $t('project.members.dialogs.GroupImportDialog.hidePm') }}
                <AppTooltip top>
                  <template #activator="{attrs, on}">
                    <font-awesome-icon :icon="['fas', 'circle-question']"
                                       class="ml-2"
                                       style="color: #ababab"
                                       v-bind="attrs"
                                       v-on="on"
                    ></font-awesome-icon>
                  </template>
                  <span>{{ $t('project.members.dialogs.GroupImportDialog.hidePmTooltip') }}</span>
                </AppTooltip>
              </template>
            </v-checkbox>

            <v-checkbox v-model="groupImportFormModel.customMessageIsActive"
                        :disabled="!groupImportFormModel.sendInvitationNow"
                        hide-details
                        class="pt-0 my-2"
            >
              <template #label>
                <span>{{ $t('project.members.dialogs.GroupImportDialog.customMessageIsActive') }}</span>
              </template>
            </v-checkbox>
            <template v-if="groupImportFormModel.customMessageIsActive">
              <VisualTextEditor v-model="groupImportFormModel.customMessage"
                                counter="footer"
                                :error-messages="customMessageErrors"
                                :max-length="customMessageMaxLength"
              />
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <AppButton @click="cancel">
          {{$t('common.cancel')}}
        </AppButton>
        <AppButton color="primary"
                  :disabled="okDisabled"
                  :loading="okLoading"
                  @click="validate"
        >
          {{$t('project.members.dialogs.GroupImportDialog.import')}}
        </AppButton>
      </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import AppCard from '@/common/AppCard.vue'
import AppDownloadFile from '@/common/AppDownloadFile.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import { escapeHtml } from '@/common/utils/strings'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor.vue'

const CUSTOM_MESSAGE_MAX_LENGTH = 400
export default {
  name: 'GroupImportForm',
  components: {
    VisualTextEditor,
    AppButton,
    AppDownloadFile,
    AppCard,
    AppTooltip,
  },
  props: {
    groupImportFormModel: {
      type: Object,
      required: true,
    },
    errorCode: {
      type: String,
      default: '',
    },
    errorDetails: {
      type: Array,
      default: () => ([]),
    },
    okLoading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      customMessageMaxLength: CUSTOM_MESSAGE_MAX_LENGTH,
    }
  },
  computed: {
    ...mapGetters('room', ['isDataRoom']),

    customMessageLength () {
    return escapeHtml(this.groupImportFormModel.customMessage).length
    },
    customMessageIsTooLong () {
      return this.groupImportFormModel.customMessageIsActive && this.customMessageLength > this.customMessageMaxLength
    },
    customMessageErrors () {
      const errors = []

      if (this.customMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    okDisabled () {
      return !this.groupImportFormModel.selectedFile || this.customMessageIsTooLong
    },
    templateLink () {
      return `${process.env.VUE_APP_API_URL}/resources/${this.isDataRoom ? 'massImportTemplateDataroom' : 'massImportTemplate'}`
    },
  },
  methods: {
    selectFile (e) {
      this.errorCode = null
      if (e.target.files.length > 0) {
        this.groupImportFormModel.selectedFile = e.target.files[0]
        // Check for file size
        if (this.groupImportFormModel.selectedFile.size > 10 * 1024 * 1024) {
          this.errorCode = 'FILE_TOO_BIG'
          return
        }

        // Check for file type
        const ext = this.groupImportFormModel.selectedFile.name.split('.').pop()
        if (ext !== 'csv' && ext !== 'xls' && ext !== 'xlsx') {
          this.errorCode = 'WRONG_FILE_TYPE'
        }
      }
    },
    onFileInputClick () {
      this.$refs.fileInput.click()
    },
    onSendInvitationNowChange () {
      this.groupImportFormModel.customMessageIsActive = false
      this.groupImportFormModel.customMessage = ''
    },
    // Public use function. Make the file input empty again.
    resetFileInput () {
      this.$refs.fileInput.value = ''
    },
    cancel () {
      this.$emit('onCancel')
    },
    validate () {
      this.$emit('onValidate')
    },
  },
}
</script>

<style scoped lang="scss">
.GroupImportDialog-card {
  border-radius: 7px;
}

.step-chip {
  background: var(--v-tertiary-base);
  color: white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
