export const SUBDOMAIN_COOKIE_KEY = 'subdomain-cookie-key'

/**
 * Return the domain of the given url
 * @param {string} url
 * @returns {string} Domain of the given url
 */
export function getDomainFromUrl (url) {
  return (new URL(url)).hostname
}

/**
 * Return the root domain of the given url
 * @param {string} url
 * @returns {string} Root domain of the given url
 */
export function getRootDomainFromUrl (url) {
  const fullDomain = getDomainFromUrl(url)
  const domainSegments = fullDomain.split('.')

  if (domainSegments.length > 1) {
    domainSegments.shift()
    return `.${domainSegments.join('.')}`
  }

  return fullDomain
}

/**
 * Return the subdomain domain of the current url
 * @returns {string} Sub domain of the given url
 */
export function getSubDomainFromCurrentUrl () {
  const currentDomain = getDomainFromUrl(window.location)
  return currentDomain.split('.')[0]
}

/**
 * Concat baseUrl and path into a valid url
 * @param {string} baseUrl
 * @param {string} path
 * @returns {string} Valid url
 */
export function urlPathJoin (baseUrl, path) {
  let baseUrlWithoutSlash = baseUrl
  while (baseUrlWithoutSlash.endsWith('/')) {
    baseUrlWithoutSlash = baseUrlWithoutSlash.slice(0, -1)
  }

  let pathWithoutSlash = path
  while (pathWithoutSlash.startsWith('/')) {
    pathWithoutSlash = pathWithoutSlash.slice(1)
  }

  return `${baseUrlWithoutSlash}/${pathWithoutSlash}`
}

/**
 * Indicate if we are currently navigating on a Closd subdomain URL
 * @returns {boolean} Return true if navigating on a subdomain, false otherwise
 */
export function navigatingOnAvailableSubdomain () {
  if (!process.env.VUE_APP_SUBDOMAINS_AVAILABLE) {
    return false
  }

  const availableSubdomains = process.env.VUE_APP_SUBDOMAINS_AVAILABLE.split(',')
  const currentSubdomain = getSubDomainFromCurrentUrl()

  return availableSubdomains.includes(currentSubdomain)
}
