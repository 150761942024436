<template>
  <v-row no-gutters>
    <v-col>
      <slot />
    </v-col>
    <v-col class="pl-5">
      <ProjectSettingsSignaturesCount :price="price"
                                      :count="count"
                                      :is-billing-by-envelope="isBillingByEnvelope"
                                      :documents-count="documentsCount"
      />
    </v-col>
  </v-row>
</template>

<script>
  import ProjectSettingsSignaturesCount from '@/project/project-settings/signatures/ProjectSettingsSignaturesCount.vue'

  export default {
    name: 'ProjectSettingsInformationSignatures',
    components: {
      ProjectSettingsSignaturesCount,
    },
    props: {
      price: {
        type: Number,
        required: true,
      },
      count: {
        type: Number,
        required: true,
      },
      isBillingByEnvelope: {
        type: Boolean,
        default: false,
      },
      documentsCount: {
        type: Number,
        default: () => 0,
      },
    },
  }
</script>
