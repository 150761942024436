<template>
  <div>
    <v-data-table v-model="selectedItems"
                  :items="filteredQuestions"
                  :headers="headers"
                  :must-sort="!searchQuery"
                  :show-select="isCurrentUserPm"
                  :page="dataTablePage"
                  sort-by="id"
                  :custom-sort="customSort"
                  class="questionAnswerDataTable"
                  :footer-props="{
                    'items-per-page-options': [20, 30, 40, 50],
                  }"
                  :items-per-page.sync="tableItemsPerPage"
                  :options.sync="options"
                  @click:row="onRowClick"
    >
      <template #no-data>
        {{ $t('project.question-answer.QuestionAnswerDataTable.noResults') }}
      </template>
      <template #item.data-table-select="{isSelected, select}">
        <v-simple-checkbox :ripple="false"
                           :value="isSelected"
                           color="primary"
                           @input="select($event)"
        >
        </v-simple-checkbox>
      </template>
      <template #item.id="{item}">
        {{ `#${item.idInRoom}` }}
      </template>
      <template #item.question="{item}">
        <div class="questionAnswerDataTable-description d-inline-block"
             v-html="item.description"
        ></div>
        <div class="d-flex align-center">
          <div class="mr-5">
            <v-chip v-for="category in item.categories"
                    :key="category.id"
                    small
                    class="mr-1"
            >
              {{ category.name }}
            </v-chip>
          </div>
        </div>
      </template>
      <template #item.reference="{item}">
        <AppTooltip
                    v-if="item.file"
                    top
                    :max-width="300"
        >
          <template #activator="{attrs, on}">
            <div class="questionAnswerDataTable-reference"
                 v-bind="attrs"
                 v-on="on"
            >
              <div class="questionAnswerDataTable-reference--text">
                <span v-if="item.file.numbering">{{ item.file.numbering }}</span>
                {{ item.file.basename }}
              </div>
            </div>
          </template>
          <span>
            <span v-if="item.file.numbering">{{ item.file.numbering }}</span>
            {{ item.file.basename }}
          </span>
        </AppTooltip>

        <AppTooltip
          v-if="item.directory"
          top
          :max-width="300"
        >
          <template #activator="{attrs, on}">
            <div class="questionAnswerDataTable-reference"
                 v-bind="attrs"
                 v-on="on"
            >
              <div class="questionAnswerDataTable-reference--text">
                <span v-if="item.directory.numbering">{{ item.directory.numbering }}</span>
                {{ item.directory.name }}
              </div>
            </div>
          </template>
          <span>
            <span v-if="item.directory.numbering">{{ item.directory.numbering }}</span>
            {{ item.directory.name }}
          </span>
        </AppTooltip>
      </template>
      <template #item.author="{item}">
        {{ item.userName }}
      </template>
      <template #item.createdAt ="{item}">
        <DateDisplay :value="item.createdAt"/>
      </template>
      <template #item.lastUpdate ="{item}">
        <DateDisplay v-if="Date.parse(item.lastUpdate)" :value="item.lastUpdate" />
        <app-text v-else>{{ item.lastUpdate }}</app-text>
      </template>
      <template #item.status="{item}">
        <div class="pl-4">
          <QuestionAnswerStatusIcon :status="item.status"/>
        </div>
      </template>
      <template #item.share="{item}">
        <div style="max-width: 120px">
          <RightsDisplayButton :rights="item.rights" @click="onShareButtonClick(item)" />
        </div>
      </template>
      <template #item.actions="{item}">
        <div class="text-right">
          <AppButton v-if="item.status !== QA_STATUS.VALIDATED"
                     color="primary"
                     @click="onRowClick(item)"
          >
            {{ $t('project.question-answer.QuestionAnswerDataTable.answer') }}
          </AppButton>
        </div>
      </template>
      <template #item.actionsDelete="{item}">
        <AppTooltip v-if="isCurrentUserPm || item.rights.canWrite" top>
          <template #activator="{attrs, on}">
            <AppButton icon
                       class="body-1"
                       v-bind="attrs"
                       v-on="on"
                       @click.stop="setDeleteQuestionDialogData(item.id)"
            >
              <font-awesome-icon :icon="['fal', 'trash-can']"></font-awesome-icon>
            </AppButton>
          </template>
          <span>{{ $t('common.delete') }}</span>
        </AppTooltip>
      </template>
      <template #footer.prepend>
        <AppPagination
          v-model="dataTablePage"
          :total="filteredQuestions.length"
          :perPage="options.itemsPerPage"
          @input="dataTablePage = $event"
        >
        </AppPagination>
      </template>
    </v-data-table>

    <QuestionAnswerQuestionDeleteDialog :mnemo="mnemo"
                                        :question-id.sync="deleteQuestionDialogData.questionId"
                                        :is-open="deleteQuestionDialogData.isOpen"
                                        @onClose="onQuestionDeleteDialogClose"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { mapGetters, mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import AppButton from '@/common/buttons/AppButton'
import DateDisplay from '@/common/DateDisplay'
import { QA_STATUS } from '@/common/utils/qa-status'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import QuestionAnswerQuestionDeleteDialog from '@/project/question-answer/QuestionAnswerQuestionDeleteDialog'
import QuestionAnswerStatusIcon from '@/project/question-answer/QuestionAnswerStatusIcon'
import { QUESTION_ANSWER_ITEM_ROUTE_NAME } from '@/router'

import AppPagination from '../../common/AppPagination.vue'
import RightsDisplayButton from '../../common/users/RightsDisplayButton.vue'

const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

export default {
  name: 'QuestionAnswerDataTable',
  components: {
    AppTooltip,
    QuestionAnswerQuestionDeleteDialog,
    QuestionAnswerStatusIcon,
    DateDisplay,
    AppButton,
    AppPagination,
    RightsDisplayButton,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    selectedQuestions: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      options: {},
      QA_STATUS,
      headers: [
        {
          text: this.$t('project.question-answer.QuestionAnswerDataTable.headers.id'),
          value: 'id',
        },
        {
          text: this.$t('project.question-answer.QuestionAnswerDataTable.headers.question'),
          value: 'question',
        },
        {
          text: this.$t('project.question-answer.QuestionAnswerDataTable.headers.reference'),
          value: 'reference',
        },
        {
          text: this.$t('project.question-answer.QuestionAnswerDataTable.headers.author'),
          value: 'author',
        },
        {
          text: this.$t('project.question-answer.QuestionAnswerDataTable.headers.sharing'),
          value: 'share',
        },
        {
          text: this.$t('project.question-answer.QuestionAnswerDataTable.headers.createdAt'),
          value: 'createdAt',
        },
        {
          text: this.$t('project.question-answer.QuestionAnswerDataTable.headers.latestAddition'),
          value: 'lastUpdate',
        },
        {
          text: this.$t('project.question-answer.QuestionAnswerDataTable.headers.status'),
          value: 'status',
        },
        {
          value: 'actions',
          sortable: false,
        },
        {
          value: 'actionsDelete',
          sortable: false,
        },
      ],
      deleteQuestionDialogData: {
        isOpen: false,
        questionId: null,
      },
      dataTablePage: 1,
    }
  },
  computed: {
    ...mapState('questionAnswer', ['filters']),
    ...mapGetters('questionAnswer', ['questions']),
    ...mapGetters('user', ['currentUserId']),
    ...mapGetters('room', ['isCurrentUserPm']),
    selectedItems: {
      get () {
        return this.selectedQuestions
      },
      set (value) {
        this.$emit('update:selected-questions', value)
      },
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, 'qa-items-per-page')
      return itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : 20
    },
    filteredQuestions () {
      let filteredQuestions = cloneDeep(this.questions)
      const questionIdMatchSearchQueryFn = question => question.idInRoom.toString() === this.searchQuery
      if (this.searchQuery) {
        filteredQuestions = filteredQuestions.filter(q => {
          return questionIdMatchSearchQueryFn(q) ||
            q.description.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            q.userName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            (q.file && q.file.basename.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
            q.categories.some(category => category.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
        })

        if (filteredQuestions.find(elem => elem.id.toString().includes(this.searchQuery))) {
          filteredQuestions = filteredQuestions.sort((a, b) => {
            if (!a.id.toString().includes(this.searchQuery) && b.id.toString().includes(this.searchQuery)) return 1
            else if (a.id.toString().includes(this.searchQuery) && !b.id.toString().includes(this.searchQuery)) return -1
            else return 0
          })
        }
      }

      const categoriesFilters = this.filters.filter(filter => filter.category.key === 'categories')
      if (categoriesFilters.length > 0) {
        const categoryMatchFilter = category => categoriesFilters.some(categoryFilter => categoryFilter.value.key === category.id)
        filteredQuestions = filteredQuestions.filter(q => q.categories.some(categoryMatchFilter))
      }

      const questionersFilters = this.filters.filter(filter => filter.category.key === 'questioners')
      if (questionersFilters.length > 0) {
        filteredQuestions = filteredQuestions.filter(q => questionersFilters.some(filter => filter.value.key === q.userName))
      }

      const createdAtFilter = this.filters.find(filter => filter.category.key === 'createdAt')
      if (createdAtFilter) {
        const filterFrom = createdAtFilter.value.key[0]
        const filterTo = createdAtFilter.value.key[1]

        if (filterFrom && !filterTo) {
          filteredQuestions = filteredQuestions.filter(q => {
            const qDate = q.createdAt
            return dayjs(qDate).isSameOrAfter(dayjs(filterFrom))
          })
        } else if (filterFrom && filterTo) {
          filteredQuestions = filteredQuestions.filter(q => {
            const qDate = q.createdAt
            return dayjs(qDate).isBetween(dayjs(filterFrom), dayjs(filterTo), 'day', '[]')
          })
        }
      }

      const lastUpdateFilter = this.filters.find(filter => filter.category.key === 'lastUpdate')
      if (lastUpdateFilter) {
        const filterFrom = lastUpdateFilter.value.key[0]
        const filterTo = lastUpdateFilter.value.key[1]

        if (filterFrom && !filterTo) {
          filteredQuestions = filteredQuestions.filter(q => {
            const qDate = q.lastUpdate
            return dayjs(qDate).isSameOrAfter(dayjs(filterFrom))
          })
        } else if (filterFrom && filterTo) {
          filteredQuestions = filteredQuestions.filter(q => {
            const qDate = q.lastUpdate
            return dayjs(qDate).isBetween(dayjs(filterFrom), dayjs(filterTo), 'day', '[]')
          })
        }
      }

      const referencesFilters = this.filters.filter(filter => filter.category.key === 'references')
      if (referencesFilters.length > 0) {
        filteredQuestions = filteredQuestions.filter(q => referencesFilters.some(filter => filter.value.key === q.file?.id))
      }

      const statusFilters = this.filters.filter(filter => filter.category.key === 'status')
      if (statusFilters.length > 0) {
        filteredQuestions = filteredQuestions.filter(q => statusFilters.some(filter => filter.value.key === q.status))
      }

      return filteredQuestions
    },
  },
  watch: {
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, 'qa-items-per-page'))) {
          setUserSetting(this.currentUserId, 'qa-items-per-page', newValue.itemsPerPage)
        }
      },
    },
  },
  methods: {
    setDeleteQuestionDialogData (questionId) {
      this.deleteQuestionDialogData = {
        questionId,
        isOpen: true,
      }
    },
    onQuestionDeleteDialogClose () {
      this.deleteQuestionDialogData = {
        questionId: null,
        isOpen: false,
      }
    },
    onRowClick (item) {
      this.$router.push({
        name: QUESTION_ANSWER_ITEM_ROUTE_NAME,
        params: {
          questionId: item.id,
          questionIdInRoom: item.idInRoom,
        },
      })
    },
    onShareButtonClick (item) {
      this.$router.push({
        name: QUESTION_ANSWER_ITEM_ROUTE_NAME,
        params: {
          questionId: item.id,
          questionIdInRoom: item.idInRoom,
          openSharePane: true,
        },
      })
    },
    customSort (items, sortBy, sortDesc) {
      const exactIdIndex = items.findIndex(elem => elem.idInRoom.toString() === this.searchQuery)
      const exactIdItem = items[exactIdIndex]
      if (exactIdIndex !== -1) items.splice(exactIdIndex, 1)
      items.sort((a, b) => {
        if (sortBy[0] === 'id') {
          if (sortDesc[0]) {
            return b.idInRoom - a.idInRoom
          } else {
            return a.idInRoom - b.idInRoom
          }
        }
        if (sortBy[0] === 'question') {
          if (sortDesc[0]) {
            return b.description.localeCompare(a.description)
          } else {
            return a.description.localeCompare(b.description)
          }
        }
        if (sortBy[0] === 'reference') {
          if (sortDesc[0]) {
            return b.file?.basename?.localeCompare(a.file?.basename)
          } else {
            return a.file?.basename?.localeCompare(b.file?.basename)
          }
        }
        if (sortBy[0] === 'author') {
          if (sortDesc[0]) {
            return b.userName.localeCompare(a.userName)
          } else {
            return a.userName.localeCompare(b.userName)
          }
        }
        if (sortBy[0] === 'createdAt') {
          if (sortDesc[0]) {
            return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1
          } else {
            return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1
          }
        }
        if (sortBy[0] === 'lastUpdate') {
          if (sortDesc[0]) {
            return dayjs(a.lastUpdate).isBefore(dayjs(b.lastUpdate)) ? 1 : -1
          } else {
            return dayjs(a.lastUpdate).isBefore(dayjs(b.lastUpdate)) ? -1 : 1
          }
        }
        if (sortBy[0] === 'status') {
          if (sortDesc[0]) {
            return this.$t(`common.qaStatus.${b.status}`).localeCompare(this.$t(`common.qaStatus.${a.status}`))
          } else {
            return this.$t(`common.qaStatus.${a.status}`).localeCompare(this.$t(`common.qaStatus.${b.status}`))
          }
        }
      })
      if (exactIdIndex !== -1) items.unshift(exactIdItem)
      return items
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep.questionAnswerDataTable {
  background-color: transparent;

  &-description {
    max-width: 350px;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  &-reference {
    max-width: 250px;
    &--text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  table {
    border-spacing: 0 6px;
  }

  .v-data-table-header {
    th {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      color: var(--v-accent-base) !important;
      border: none !important;
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      background-color: #fff;
      cursor: pointer;
      transition: box-shadow .5s;
      &:hover {
        background-color: #fff !important;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
      }
    }

    td {
      border: none !important;
      padding-top: 16px !important;
      padding-bottom: 16px !important;

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  .v-data-footer {
    border: none;
  }
}

::v-deep .v-data-footer__icons-before {
  display: none;
}

::v-deep .v-data-footer__icons-after {
  display: none;
}

::v-deep .v-data-footer {
  flex-direction: row-reverse;
}
</style>
