<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             @cancel="closeDialog"
  >
    <template #title>
      <ProjectTypeFeatureHeader :shadow-color="PROJECT_TYPE_COLORS.PROJECT"
                                illustration="/img/discover_project.svg"
      >
        <template #title>
          <h3 class="text-h3' light-blue--text">{{ $t('common.discover') }}</h3>
          <h1 class="text-h1">{{ $t('projects.dialogs.DiscoverProjectDialog.moduleTitle') }}</h1>
        </template>
      </ProjectTypeFeatureHeader>
    </template>

    <template #body>
      <div class="copyright">
        <CopyrightLink author="fullvector"
                       link="https://fr.freepik.com/vecteurs-libre/achat-paiement-ligne-boutique-ligne-isometrique-reception-du-paiement-notification-achat_4102305.htm"
        />
      </div>
      <div class="features-list">
        <ProjectTypeFeaturesList :features="features" icon-class="light-blue--text" />
      </div>
    </template>

    <template #footer>
      <v-spacer />
      <AppButton
        color="white"
        @click="closeDialog"
      >
        {{$t('common.cancel')}}
      </AppButton>

      <AppButton
        v-if="canCurrentUserCreateProject"
        color="primary"
        @click="createProject"
      >
        {{ $t("projects.dialogs.DiscoverProjectDialog.createProject") }}
      </AppButton>
      <AppButton
        v-else
        color="primary"
        @click="requestActivation"
      >
        {{ $t("projects.dialogs.DiscoverProjectDialog.activate") }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { mapGetters } from 'vuex'

import CopyrightLink from '@/common/CopyrightLink.vue'
import { PROJECT_TYPE_COLORS } from '@/projects/dialogs/projectTypeColors'

import AppButton from '../../common/buttons/AppButton'
import AppDialog from '../../common/dialogs/AppDialog'
import { dialogMixin } from '../../mixins/dialogMixin'
import ProjectTypeFeatureHeader from '../project-types/ProjectTypeFeatureHeader'
import ProjectTypeFeaturesList from '../project-types/ProjectTypeFeaturesList'

const FEATURES_ICONS = {
  1: 'file-signature',
  2: 'alarm-clock',
  3: 'lock-alt',
}

export default {
  name: 'DiscoverProjectDialog',
  components: { AppButton, AppDialog, CopyrightLink, ProjectTypeFeatureHeader, ProjectTypeFeaturesList },
  mixins: [dialogMixin],
  data () {
    return {
      PROJECT_TYPE_COLORS,
      features: Object.keys(FEATURES_ICONS).map(key => ({
        icon: FEATURES_ICONS[key],
        title: this.$t(`projects.dialogs.DiscoverProjectDialog.features.${key}.title`),
        description: this.$t(`projects.dialogs.DiscoverProjectDialog.features.${key}.description`),
      })),
    }
  },
  computed: {
    ...mapGetters('user', ['canCurrentUserCreateProject']),
  },
  methods: {
    createProject () {
      this.$emit('createProject')
    },
    requestActivation () {
      this.closeDialog()
      this.$emit('requestActivation')
    },
  },
}
</script>

<style scoped lang="scss">
.features-list {
  margin: 0 45px;
  max-width: 600px;
}

@media (max-width: 768px) {
  .features-list {
    margin: 0 20px;
  }
}

.copyright {
  position: absolute;
  right: -20px;
  transform: rotate(-90deg);
}
</style>
