
import { defineComponent, PropType } from 'vue'

const __default__ = defineComponent({
  name: 'SignerColorChip',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "309c3eb8": (_vm.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__