<template>
  <div>
    <AppTextField v-model.trim="model.$model"
                  :label="$t('common.birthName')"
                  :error-messages="birthNameErrors"
                  :error="isErrored"
    >
      <template v-slot:append>
        <AppTooltip bottom max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon :icon="['far', 'circle-question']"
                               class="clickable mr-2"
                               v-bind="attrs"
                               v-on="on"
            />
          </template>
          <v-img src="/img/profile/id_example_last_name.png" />
        </AppTooltip>
      </template>
    </AppTextField>

    <AppTextFieldSuggestion v-if="displaySuggestedValue"
                            :suggestedValue="suggestedValue"
                            @validate="model.$model = suggestedValue"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AppTextField from '@/common/AppTextField'
import AppTooltip from '@/common/AppTooltip'
import AppTextFieldSuggestion from '@/common/identity-checking/AppTextFieldSuggestion'
import { ID_CHECK_ERRORS } from '@/common/utils/idCheckErrors'

export default {
  name: 'BirthName',
  components: { AppTextField, AppTextFieldSuggestion, AppTooltip },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['idCheckError']),
    ...mapGetters('user', ['hasIdCheckErrorInfo']),
    birthNameErrors () {
      const errors = []
      if (!this.model.$dirty) return errors
      !this.model.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.birthName') }))
      return errors
    },
    isErrored () {
      return this.hasIdCheckErrorInfo &&
        this.idCheckError.errors.includes(ID_CHECK_ERRORS.ERROR_BIRTHNAME_MISMATCH) &&
        this.displaySuggestedValue
    },
    suggestedValue () {
      return this.idCheckError?.suggestedValues?.birthName
    },
    displaySuggestedValue () {
      return this.suggestedValue && this.suggestedValue !== this.model.$model
    },
  },
}
</script>
