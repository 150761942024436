<template>
  <AppDialog :is-open="isDialogOpened"
             stepper-dialog
             :stepper-model="currentStep"
             :cancel-disabled="idCheckPending"
             :no-click-animation="idCheckPending"
             content-class="IdentityCheckingDialog"
             size="xxl"
             @cancel="resetAndClose"
  >
    <template #stepper-header>
      <v-stepper-step :step="1">{{$t('common.identity-checking.IdentityCheckingDialog.personalInformation')}}</v-stepper-step>
      <v-divider/>
      <v-stepper-step :step="2">{{$t('common.identity-checking.IdentityCheckingDialog.id')}}</v-stepper-step>
      <v-divider/>
      <v-stepper-step :step="3">{{$t('common.identity-checking.IdentityCheckingDialog.import')}}</v-stepper-step>
    </template>
    <template #stepper-items>
      <v-stepper-items>
        <IdentityCheckingInformation :information="$v.information"
                                     :current-step="currentStep"
                                     :tries-left="triesLeft"
                                     @phone-validation-change="isValidPhoneNumber = $event"
        />
        <IdentityDocuments :current-step="currentStep" />
        <IdentityCheckingID :id="$v.documents"
                            :tries-left="triesLeft"
                            :display-no-tries-left-template="displayNoTriesLeftTemplate"
                            :current-step="currentStep"
        />

        <IdentityCheckingInProgressToast v-if="idCheckPending" />
      </v-stepper-items>
    </template>
    <template #footer>
      <div class="w-100">
        <v-divider></v-divider>
        <div class="d-flex flex-column flex-sm-row justify-space-between mt-4 mx-8">
          <template v-if="displayNoTriesLeftTemplate">
            <AppButton color="primary"
                       class="ml-sm-auto mb-2 mb-sm-0"
                       @click="resetAndClose">
              {{ $t('common.close') }}
            </AppButton>
          </template>
          <template v-else>
            <AppButton v-if="currentStep > 1"
                      class="mb-2 mb-sm-0"
                      outlined
                      :disabled="idCheckPending"
                      @click="currentStep--"
            >
              <font-awesome-icon :icon="['far', 'arrow-left-long']" class="mr-2" />
              {{ $t('common.previous') }}
            </AppButton>
            <div class="d-flex flex-column flex-sm-row ml-sm-auto">
              <AppButton class="mb-2 mb-sm-0"
                         outlined
                         :disabled="idCheckPending"
                         @click="resetAndClose"
              >
                {{ $t('common.cancel') }}
              </AppButton>
              <AppButton class="ml-sm-4"
                         color="primary"
                         :loading="updateProfilePending || idCheckPending"
                         @click="onOk"
              >
                {{ okText }}
              </AppButton>
            </div>
          </template>
        </div>
      </div>
    </template>
  </AppDialog>
</template>

<script>
import dayjs from 'dayjs'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import IdentityCheckingID from '@/common/identity-checking/IdentityCheckingID'
import IdentityCheckingInformation from '@/common/identity-checking/IdentityCheckingInformation'
import IdentityCheckingInProgressToast from '@/common/identity-checking/IdentityCheckingInProgressToast.vue'
import IdentityDocuments from '@/common/identity-checking/IdentityDocuments'
import { ID_CHECK_ERROR_TYPES } from '@/common/utils/idCheckErrors'
import { ID_TYPE_OPTIONS } from '@/common/utils/IDTypes.ts'
import { dialogMixin } from '@/mixins/dialogMixin'
import { POST_ID_CHECK, UPDATE_PROFILE } from '@/store/modules/user/action_types'
import { SET_ID_CHECK_ERROR, SET_SELECTED_ID_DOCUMENT_TYPE } from '@/store/modules/user/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

const MIN_BIRTHDATE = '1900-01-01'
const MAX_BIRTHDATE = '2099-12-31'

export default {
  name: 'IdentityCheckingDialog',
  components: {
    AppButton,
    AppDialog,
    IdentityCheckingID,
    IdentityCheckingInProgressToast,
    IdentityCheckingInformation,
    IdentityDocuments,
  },
  mixins: [dialogMixin, validationMixin],
  validations: {
    information: {
      firstName: { required },
      lastName: { required },
      birthName: { required },
      birthDate: {
        required,
        minValue (value) {
          return dayjs(value).isAfter(dayjs(MIN_BIRTHDATE))
        },
        maxValue (value) {
          return dayjs(value).isBefore(dayjs(MAX_BIRTHDATE))
        },
      },
      gender: { required },
      telephone: {
        required,
        isValidPhoneNumber () {
          return this.isValidPhoneNumber
        },
      },
    },
    documents: {
      documentFront: { required },
      documentBack: {},
    },
  },
  data () {
    return {
      currentStep: 1,
      information: {
        firstName: '',
        lastName: '',
        birthName: '',
        birthDate: '',
        gender: null,
        telephone: '',
      },
      isValidPhoneNumber: false,
      idDocumentType: ID_TYPE_OPTIONS.IDENTITY_CARD,
      documents: {
        documentFront: null,
        documentBack: null,
      },
      triesLeft: 0,
      displayNoTriesLeftTemplate: false,
      ID_TYPE_OPTIONS,
    }
  },
  computed: {
    ...mapState('user', ['profile', 'updateProfilePending', 'selectedIdDocumentType', 'idCheckPending']),
    ...mapGetters('user', ['failedIdChecks', 'isIdCheckPending']),
    okText () {
      return this.currentStep < 3 ? this.$t('common.next') : this.$t('common.identity-checking.IdentityCheckingDialog.verify')
    },
  },
  created () {
    if (this.isIdCheckPending) {
      this.currentStep = 3
      this.displayNoTriesLeftTemplate = true
    }
    this.information.firstName = this.profile.firstName
    this.information.lastName = this.profile.lastName
    this.information.birthName = this.profile.birthName
    this.information.birthDate = this.profile.birthDate
    this.information.gender = this.profile.gender
    this.information.telephone = this.profile.telephone
  },
  beforeDestroy () {
    this.SET_ID_CHECK_ERROR(null)
  },
  watch: {
    selectedIdDocumentType () {
      this.documents.documentFront = null
      this.documents.documentBack = null
      this.SET_ID_CHECK_ERROR(null)
    },
  },
  methods: {
    ...mapActions('user', [UPDATE_PROFILE, POST_ID_CHECK]),
    ...mapMutations('user', [SET_ID_CHECK_ERROR]),
    onOk () {
      if (this.currentStep === 1) {
        this.prepareUpdateProfile()
      } else if (this.currentStep === 2) {
        this.currentStep++
      } else if (this.currentStep === 3) {
        this.preparePostIdCheck()
      }
    },
    async prepareUpdateProfile () {
      this.$v.information.$touch()
      if (!this.$v.information.$invalid) {
        try {
          await this.UPDATE_PROFILE({
            ...this.information,
          })
          this.currentStep++
        } catch (error) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('common.identity-checking.IdentityCheckingDialog.updateProfileError'),
          })
        }
      }
    },
    async preparePostIdCheck () {
      this.$v.documents.$touch()
      if (!this.$v.documents.$invalid) {
        try {
          await this.POST_ID_CHECK({
            idDoc: this.documents.documentFront,
            idDocBack: this.selectedIdDocumentType === ID_TYPE_OPTIONS.PASSPORT ? null : this.documents.documentBack,
          })
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('common.identity-checking.IdentityCheckingDialog.postIdCheckSuccess'),
          })
          this.resetAndClose()
        } catch (error) {
          this.triesLeft = error.triesLeft
          if (error.triesLeft === 0) {
            this.displayNoTriesLeftTemplate = true
            return
          }

          // Errors about user information
          if (error.errorType === ID_CHECK_ERROR_TYPES.ERROR_TYPE_INFO) {
            // If we have information that don't match with the id
            // We set them back to the previous step
            // Suggested values will be added based on the mismatching information
            this.currentStep = 1
            return
          }

          // Errors about provided documents
          // Display error with remaining tries counter in alert
          if (error.errorType === ID_CHECK_ERROR_TYPES.ERROR_TYPE_DOCUMENT) {
            return
          }

          // If an unexpected error happened
          // Show the standard error happened snackbar
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('common.msgFailErrorOccurred'),
          })
        }
      }
    },
    resetAndClose () {
      this.$store.commit(`user/${SET_SELECTED_ID_DOCUMENT_TYPE}`, null)
      this.closeDialog()
    },
  },
}
</script>

<style lang="scss">
  // For this dialog in particular, we have a "flashing scroller" if we don't do this
  // But we also have to keep being able to scroll, since the overflow _has_ to come from the dialog
  // To keep a usable behavior
  // Reference: https://gitlab.com/closd/closd-front/-/merge_requests/841#note_1170612153
  .IdentityCheckingDialog.dialog-transition-enter-active {
    overflow-y: hidden;
  }

  // Remove the padding to the left and right of the footer
  // so that the divider in the footer takes up the full width
  .IdentityCheckingDialog.v-dialog > .v-card > .v-card__actions {
    padding: 8px 0px 24px;
  }
</style>
