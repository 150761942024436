<template>
  <div>
    <AppTooltip top>
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="d-flex align-center clickable"
          @click="handleOpenFolderClick"
          v-on="on"
        >
          <font-awesome-icon :icon="['fas', 'folder']" class="mr-2 grey--text"></font-awesome-icon>

          <span v-if="document.parent">
            <span v-if="numberingEnabled && document.parent.numbering" class="mr-1">{{ `${document.parent.numbering}` }}</span>
            {{document.parent.name}}
          </span>
          <span v-else>
            {{$t("project.documents.DocumentLocationLink.rootFolder")}}
          </span>
        </div>
      </template>
      {{ $t('project.documents.DocumentLocationLink.tooltip') }}
    </AppTooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppTooltip from '../../common/AppTooltip.vue'

export default {
  name: 'DocumentLocationLink',
  components: { AppTooltip },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('documents', ['numberingEnabled']),
  },
  methods: {
    handleOpenFolderClick () {
      this.$emit('openInFolder', this.document)
    },
  },
}
</script>
