import {
  SET_STRIPE_INFO_PENDING,
  SET_STRIPE_INFO,
  SET_STRIPE_INFO_ERROR,
} from './mutation_types'

export const mutations = {
  [SET_STRIPE_INFO_PENDING] (state, value) {
    state.stripeInfoPending = value
  },

  [SET_STRIPE_INFO] (state, value) {
    state.stripeInfo = value
  },
  [SET_STRIPE_INFO_ERROR] (state, value) {
    state.stripeInfoError = value
  },
}
