export const CHASE_USERS = 'CHASE_USERS'
export const CHASE_VALIDATOR = 'CHASE_VALIDATOR'
export const CLOSE_ROOM = 'CLOSE_ROOM'
export const GET_MORE_SIGNATURES = 'GET_MORE_SIGNATURES'
export const GET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT = 'GET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT'
export const GET_ROOM_PROVIDERS = 'GET_ROOM_PROVIDERS'
export const GET_ROOM_RIGHTS = 'GET_ROOM_RIGHTS'
export const GET_ROOM_STATS = 'GET_ROOM_STATS'
export const GET_SIGNATURES = 'GET_SIGNATURES'
export const GET_ROOM_NOTIFICATIONS = 'GET_ROOM_NOTIFICATIONS'
export const PATCH_ROOM = 'PATCH_ROOM'
export const PATCH_ROOM_GLOBAL_RIGHTS = 'PATCH_ROOM_GLOBAL_RIGHTS'
export const PATCH_ROOM_GROUP_RIGHTS = 'PATCH_ROOM_GROUP_RIGHTS'
export const PATCH_ROOM_USER_RIGHTS = 'PATCH_ROOM_USER_RIGHTS'
export const POST_ROOM_STORAGE_ADDON = 'POST_ROOM_STORAGE_ADDON'
export const UPDATE_ACCESS_TIME = 'UPDATE_ACCESS_TIME'
export const NOTIFY_PM_NO_MORE_SPACE = 'NOTIFY_PM_NO_MORE_SPACE'
export const NOTIFY_PM_PLAN_DATAROOM_FULL = 'NOTIFY_PM_PLAN_DATAROOM_FULL'
export const MOVE_ROOM = 'MOVE_ROOM'
export const GET_ROOM_NDA_STATUS = 'GET_ROOM_NDA_STATUS'
export const GET_ROOM_NDA_SIGNING_VIEW = 'GET_ROOM_NDA_SIGNING_VIEW'
