<template>
  <AppButton
    color="tertiary"
    large
    :dark="true"
    class="polyOfficeIconParent"
    @click="handleClick"
  >
    <PolyOfficeIcon color="#FFF"/>
    {{ $t('common.polyOffice.PolyOfficeButtonImport.importFiles') }}
  </AppButton>
</template>

<script>
import AppButton from '@/common/buttons/AppButton.vue'
import PolyOfficeIcon from '@/common/polyOffice/PolyOfficeIcon.vue'

export default {
  name: 'PolyOfficeButtonImport',
  components: { PolyOfficeIcon, AppButton },
  methods: {
    handleClick () {
      this.$emit('polyOfficeOpenDialog')
    },
  },
}
</script>
