import { ROOM_TYPES } from '../../../common/utils/roomTypes'

export const getters = {
  processing: state => { return state.processing },
  rooms: state => { return state.rooms },
  error: state => { return state.error },
  hasRecentRooms: state => state.recentRooms.length !== 0,
  dataRoomTypeId: (_, getters, rootState, rootGetters) => {
    const dataRoomType = rootGetters['user/roomTypes'].find(
      roomType => roomType.type === ROOM_TYPES.DATAROOM || roomType.type === ROOM_TYPES.DATAROOM_UNLIMITED,
    )
    return dataRoomType?.id ?? null
  },
}
