import jwtDecode from 'jwt-decode'

const FAILED_ID_CHECKS_LIMIT = 3
const USER_ID_STORAGE_KEY = 'user-id'
const USER_TOKEN_STORAGE_KEY = 'user-token'

function isUserMissingIdCheck (user) {
  return Array.isArray(user.missingInfoToSign) &&
    user.missingInfoToSign.includes('idCheck')
}

function canUserSign (user) {
  return user.missingInfoToSign === false
}

/**
 * Mutates a user to remove id check if it's a missingInfoToSign.
 * Does nothing if not an array or idCheck is not missing
 * @param {*} user - The user to mutate
 */
function removeIdCheckFromMissingInfo (user) {
  if (Array.isArray(user.missingInfoToSign)) {
    user.missingInfoToSign.splice(user.missingInfoToSign.indexOf('idCheck'), 1)
    if (user.missingInfoToSign.length === 0) {
      user.missingInfoToSign = false
    }
    // Reset failed id checks. We passed it !
    user.failedIdChecks = 0
  }
}

/**
 * Decode user token from local storage to get it scopes
 * @returns {Array|null} User scopes if token is present, null otherwise
 */
function getUserScopesFromAuthToken () {
  const userToken = localStorage.getItem(USER_TOKEN_STORAGE_KEY)

  if (userToken) {
    try {
      const decodedToken = jwtDecode(userToken)
      return decodedToken.scopes ?? []
    } catch {
      console.error('An error occurred while decoding the user token')
      return null
    }
  }

  return null
}

export {
  canUserSign,
  FAILED_ID_CHECKS_LIMIT,
  getUserScopesFromAuthToken,
  isUserMissingIdCheck,
  removeIdCheckFromMissingInfo,
  USER_ID_STORAGE_KEY,
  USER_TOKEN_STORAGE_KEY,
}
