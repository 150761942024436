import axios from 'axios'

export default {
  getChecklist (mnemo, params = {}, isPolling = false) {
    const config = { params }

    if (isPolling) {
      config.headers = {
        'X-Poll': 'x-poll',
      }
    }
    return axios.get(`/room/${mnemo}/todo`, config)
  },
  postTask (mnemo, data) {
    return axios.post(`/room/${mnemo}/todo/todoTask`, data)
  },
  patchTask (mnemo, taskId, data) {
    return axios.patch(`/room/${mnemo}/todo/todoTask/${taskId}`, data)
  },
  deleteTask (mnemo, id) {
    return axios.delete(`room/${mnemo}/todo/todoTask/${id}`)
  },
  deleteChecklistItems (mnemo, { taskIds, titleIds }) {
    return axios.post(`room/${mnemo}/todo/bulkdelete`, {
      tasks: taskIds,
      titles: titleIds,
    })
  },
  deleteChecklistDoubleValidation (mnemo, data) {
    return axios.post(`room/${mnemo}/todo/validate/deletion`, data)
  },
  patchTitle (mnemo, titleId, data) {
    return axios.patch(`/room/${mnemo}/todo/todoTitle/${titleId}`, data)
  },
  deleteTitle (mnemo, titleId) {
    return axios.delete(`/room/${mnemo}/todo/todoTitle/${titleId}`)
  },
  incrementTitleIndentation (mnemo, titleId) {
    return axios.post(`/room/${mnemo}/todo/todoTitle/${titleId}/indent/increment`)
  },
  decrementTitleIndentation (mnemo, titleId) {
    return axios.post(`/room/${mnemo}/todo/todoTitle/${titleId}/indent/decrement`)
  },
  postTitle (mnemo, data) {
    return axios.post(`/room/${mnemo}/todo/todoTitle`, data)
  },
  contactResponsible (mnemo, taskId, data) {
    return axios.post(`/room/${mnemo}/todo/todoTask/${taskId}/chase`, data)
  },
  getTaskComments (mnemo, taskId, params) {
    return axios.get(`/room/${mnemo}/todo/todoTask/${taskId}/comments`, { params })
  },
  postTaskComment (mnemo, taskId, data) {
    return axios.post(`/room/${mnemo}/todo/todoTask/${taskId}/comment`, data)
  },
  deleteTaskComment (mnemo, taskId, commentId) {
    return axios.delete(`/room/${mnemo}/todo/todoTask/${taskId}/comment/${commentId}`)
  },
  getTaskStatuses (mnemo) {
    return axios.get(`/room/${mnemo}/todo/todoTask/statuses`)
  },
  shareAttachedFiles (mnemo, data) {
    return axios.patch(`/room/${mnemo}/filders/rights`, data)
  },
  moveFilders (mnemo, data) {
    return axios.patch(`/room/${mnemo}/filders`, data)
  },
  deleteFilders (mnemo, data) {
    return axios.delete(`/room/${mnemo}/filders`, { data: data })
  },
  renameAttachedFile (mnemo, fileId, data) {
    return axios.patch(`/room/${mnemo}/file/${fileId}`, data)
  },
  getAttachedFileVersions (mnemo, fileId) {
    return axios.get(`/room/${mnemo}/file/${fileId}/version`)
  },
  postAttachedFileVersion (mnemo, documentId, data) {
    return axios.post(
      `/room/${mnemo}/file/${documentId}/version`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
  },
  shareTasks (mnemo, data) {
    return axios.post(`/room/${mnemo}/todo/bulkrights`, data)
  },
  saveChecklist (mnemo, data) {
    return axios.post(`/room/${mnemo}/todo/exportToClosd`, data)
  },
  updateChecklist (mnemo, checklistId, data) {
    return axios.patch(`/room/${mnemo}/todolist/${checklistId}`, data)
  },
  getSavedChecklists () {
    return axios.get('/todolists')
  },
  getSelfSavedChecklists () {
    return axios.get('/todolists/self')
  },
  getSavedChecklistPreview (checklistId) {
    return axios.get(`/todolist/${checklistId}/preview`)
  },
  getLexisNexisChecklists () {
    return axios.get('/todolists/lexis')
  },
  getLexisNexisChecklistPreview (checklistId) {
    return axios.get(`/todolist/${checklistId}/preview?lexis=true`)
  },
  importChecklistFromClosd (mnemo, data) {
    return axios.post(`/room/${mnemo}/todo/importFromClosd`, data)
  },
  importChecklistFromExcel (mnemo, data) {
    return axios.post(`/room/${mnemo}/todo/import`, data)
  },
  deleteSavedChecklist (todoListId) {
    return axios.delete(`/todolists/${todoListId}`)
  },
  shareSavedChecklist (todoListId, data) {
    return axios.patch(`/todolists/${todoListId}/rights`, data)
  },
  renameSavedChecklist (todoListId, name) {
    return axios.patch(`/todolists/${todoListId}/name`, { name })
  },
  getTask (mnemo, taskId) {
    return axios.get(`/room/${mnemo}/todo/todoTask/${taskId}`)
  },
  postMoveTask (mnemo, taskId, data) {
    return axios.post(`/room/${mnemo}/todo/todoTask/${taskId}/move`, data)
  },
  postMoveTitle (mnemo, titleId, data) {
    return axios.post(`/room/${mnemo}/todo/todoTitle/${titleId}/move`, data)
  },
  duplicateTask (mnemo, taskId) {
    return axios.post(`/room/${mnemo}/todo/todoTask/${taskId}/duplicate`)
  },
  shareChecklistByEmail (mnemo, data) {
    return axios.post(`/room/${mnemo}/share-export`, data)
  },
  postStatus (mnemo, data) {
    return axios.post(`/room/${mnemo}/todo/todoTask/statuses`, data)
  },
  getAllTodoFiles (mnemo) {
    return axios.get(`/room/${mnemo}/todo/files`)
  },
  downloadTodoFiles (mnemo, data) {
    return axios.post(`/room/${mnemo}/downloads/todo/files`, data, { responseType: 'blob' })
  },
}
