<template>
  <v-row>
    <v-col>
      <AppCard :class="{ 'pt-0': $vuetify.breakpoint.mdAndDown }">
        <template v-if="!dataReady">
          <v-skeleton-loader type="table-thead, table-tbody"/>
        </template>
        <template v-else>
          <!-- There is custom logic on this table so we don't need "must sort" here to have its behavior -->
          <v-data-table id="DocumentsTable"
                        ref="DocumentsTable"
                        v-model="selectedItems"
                        :headers="headers"
                        class="data-table-simple-checkbox table-header"
                        data-locator="documents-table"
                        :items="tableItems"
                        :show-select="true"
                        :footer-props="{
                          'items-per-page-options': ITEM_PER_PAGE_DEFAULT,
                        }"
                        :items-per-page="tableItemsPerPage"
                        :options.sync="options"
                        item-key="selectableKey"
                        selectable-key="isSelectable"
          >
            <template #header.data-table-select="{props, on}">
              <v-simple-checkbox v-bind="props" color="primary" :ripple="false" v-on="on"></v-simple-checkbox>
            </template>
            <template #header.name="{header}">
              <div class="clickable" @click="handleSorting('basename')">
                <span class="mr-2">{{ header.text }}</span>
                <font-awesome-icon v-if="sorting.name !== 'basename'"
                                   :icon="['fad', 'sort']"
                ></font-awesome-icon>
                <font-awesome-icon v-if="sorting.name === 'basename' && sorting.order === 'asc'"
                                   :icon="['fad', 'sort-up']"
                ></font-awesome-icon>
                <font-awesome-icon v-if="sorting.name === 'basename' && sorting.order === 'desc'"
                                   :icon="['fad', 'sort-down']"
                ></font-awesome-icon>
              </div>
            </template>
            <template #header.size="{header}">
              <div class="clickable" @click="handleSorting('size')">
                <span class="mr-2">{{ header.text }}</span>
                <font-awesome-icon v-if="sorting.name !== 'size'"
                                   :icon="['fad', 'sort']"
                ></font-awesome-icon>
                <font-awesome-icon v-if="sorting.name === 'size' && sorting.order === 'asc'"
                                   :icon="['fad', 'sort-up']"
                ></font-awesome-icon>
                <font-awesome-icon v-if="sorting.name === 'size' && sorting.order === 'desc'"
                                   :icon="['fad', 'sort-down']"
                ></font-awesome-icon>
              </div>
            </template>
            <template #header.updatedBy="{header}">
              <div class="clickable" @click="handleSorting('updatedBy')">
                <span class="mr-2">{{ header.text }}</span>
                <font-awesome-icon v-if="sorting.name !== 'updatedBy'"
                                   :icon="['fad', 'sort']"
                ></font-awesome-icon>
                <font-awesome-icon v-if="sorting.name === 'updatedBy' && sorting.order === 'asc'"
                                   :icon="['fad', 'sort-up']"
                ></font-awesome-icon>
                <font-awesome-icon v-if="sorting.name === 'updatedBy' && sorting.order === 'desc'"
                                   :icon="['fad', 'sort-down']"
                ></font-awesome-icon>
              </div>
            </template>
            <template #header.updatedAt="{header}">
              <div class="clickable" @click="handleSorting('updatedAt')">
                <span class="mr-2">{{ header.text }}</span>
                <font-awesome-icon v-if="sorting.name !== 'updatedAt'"
                                   :icon="['fad', 'sort']"
                ></font-awesome-icon>
                <font-awesome-icon v-if="sorting.name === 'updatedAt' && sorting.order === 'asc'"
                                   :icon="['fad', 'sort-up']"
                ></font-awesome-icon>
                <font-awesome-icon v-if="sorting.name === 'updatedAt' && sorting.order === 'desc'"
                                   :icon="['fad', 'sort-down']"
                ></font-awesome-icon>
              </div>
            </template>

            <template #body="{items, isSelected, select, headers}">
              <DocumentsTableBody :current-folder="documents"
                                  :columns-count="headers.length"
                                  :documents="items"
                                  :isDocumentSelected="isSelected"
                                  :selectDocument="select"
              />
            </template>

            <template #footer.prepend>
              <v-pagination
                v-model="dataTablePage"
                :length="dataTablePageCount"
                :total-visible="7"
                :disabled="disabledPagination"
                @input="onPageChange"
              ></v-pagination>
            </template>
          </v-data-table>
        </template>
      </AppCard>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { mapState, mapGetters, mapActions } from 'vuex'

import AppCard from '@/common/AppCard'
import { ITEM_PER_PAGE_DEFAULT } from '@/common/utils/dataTablePagination'
import { handleElementDimensionResize } from '@/common/utils/dimensionObserver'
import { roomFileDownloadUrl } from '@/common/utils/files'
import { sortNumbering } from '@/common/utils/sorting'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import DocumentsTableBody from '@/project/documents/table/DocumentsTableBody.vue'
import {
  GET_DOCUMENTS_BY_ID,
  SELECT_ALL_DOCUMENTS,
  SELECT_DOCUMENTS,
} from '@/store/modules/documents/action_types'
export default {
  name: 'DocumentsTableTemplate',
  components: { AppCard, DocumentsTableBody },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      ITEM_PER_PAGE_DEFAULT,
      sorting: {
        name: 'basename',
        order: 'asc',
      },
      options: {},
      dataTablePage: 1,
      dataTablePageCount: 1,
      disabledPagination: false,
      resizeObserverDestroyCallback: null,
    }
  },
  computed: {
    ...mapState('documents', [
      'documents',
      'filters',
      'getFolderPathPending',
      'loading',
      'searchLoading',
      'searchModeEnabled',
      'searchResults',
      'selectedDocuments',
    ]),
    ...mapGetters('documents', [
      'allDocumentsSelected',
      'documentsTableItems',
      'isRecentFolder',
    ]),
    ...mapGetters('room', ['isCurrentUserPm', 'roomMnemo']),
    ...mapGetters('user', ['currentUserId']),
    headers () {
      const locationColumnIsEnabled = this.searchModeEnabled || this.isRecentFolder
      const columnHeaders = [
        {
          text: this.$t('project.documents.table.DocumentsTableTemplate.name'),
          sortable: false,
          value: 'name',
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
        },
        {
          text: this.$t('project.documents.table.DocumentsTableTemplate.size'),
          sortable: false,
          value: 'size',
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
        },
        {
          text: this.$t('project.documents.table.DocumentsTableTemplate.updatedAt'),
          sortable: false,
          value: 'updatedAt',
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
        },
        {
          text: this.$t('project.documents.table.DocumentsTableTemplate.updatedBy'),
          sortable: false,
          value: 'updatedBy',
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
        },
        {
          text: this.$t('project.documents.table.DocumentsTableTemplate.sharing'),
          sortable: false,
          value: 'share',
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
        },
        {
          text: this.$t('project.documents.table.DocumentsTableTemplate.location'),
          sortable: false,
          value: 'location',
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
          hidden: !locationColumnIsEnabled,
        },
        {
          text: this.$t('project.documents.table.DocumentsTableTemplate.options'),
          sortable: false,
          value: 'options',
          align: 'end',
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
        },
      ]

      return columnHeaders.filter(columnHeader => !columnHeader.hidden)
    },
    filteredDocuments () {
      let arr = cloneDeep(this.documentsTableItems)

      if (this.documents.id === 0) {
        arr.splice(0, 0, {
          basename: this.$t('project.documents.table.DocumentsTableTemplate.recentFolderName'),
          children: [],
          id: 'recentFolder',
          name: this.$t('project.documents.table.DocumentsTableTemplate.recentFolderName'),
          type: 'folder',
          specialFolder: 'new',
        })
      }

      arr.forEach(child => {
        child.selectableKey = `${child.type}-${child.id}`
        child.isSelectable = !child.specialFolder
      })

      arr.sort((a, b) => {
        if (Object.prototype.hasOwnProperty.call(a, 'specialFolder') && Object.prototype.hasOwnProperty.call(b, 'specialFolder')) {
          return a.specialFolder === 'new' ? -1 : 1
        } else if (!Object.prototype.hasOwnProperty.call(a, 'specialFolder') && Object.prototype.hasOwnProperty.call(b, 'specialFolder')) {
          return 1
        } else if (Object.prototype.hasOwnProperty.call(a, 'specialFolder') && !Object.prototype.hasOwnProperty.call(b, 'specialFolder')) {
          return -1
        } else {
          if (a.type === 'folder' && b.type === 'file') {
            return 0
          } else if (a.type === 'file' && b.type === 'folder') {
            return 1
          } else {
            if (this.sorting.name === 'basename') {
              if (a.numbering || b.numbering) {
                return sortNumbering(
                  a.numbering ?? 9999999999,
                  b.numbering ?? 9999999999,
                  this.sorting.order === 'desc',
                )
              } else {
                return this.sorting.order === 'asc'
                  ? a.basename.localeCompare(b.basename, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                  })
                  : b.basename.localeCompare(a.basename, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                  })
              }
            } else if (this.sorting.name === 'updatedAt') {
              return this.sorting.order === 'asc'
                ? new Date(a.updateTime) < new Date(b.updateTime) ? -1 : 1
                : new Date(a.updateTime) < new Date(b.updateTime) ? 1 : -1
            } else if (this.sorting.name === 'size') {
              return this.sorting.order === 'asc'
                ? a.size - b.size
                : b.size - a.size
            } else if (this.sorting.name === 'updatedBy') {
              const aValue = a.updateUser || ''
              const bValue = b.updateUser || ''
              return this.sorting.order === 'asc'
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue)
            } else {
              return this.sorting.order === 'asc'
                ? a[this.sorting.name].localeCompare(b[this.sorting.name])
                : b[this.sorting.name].localeCompare(a[this.sorting.name])
            }
          }
        }
      })

      const membersFilters = this.filters.filter(filter => filter.category.key === 'members')
      if (membersFilters.length > 0) {
        arr = arr.filter(
          document =>
            membersFilters.some(filter => filter.value.key === document.updateUser) ||
            document.type === 'folder',
        )
      }

      const dateFilter = this.filters.find(filter => filter.category.key === 'dates')
      if (dateFilter) {
        const filterFrom = dateFilter.value.key[0]
        const filterTo = dateFilter.value.key[1]

        if (filterFrom && !filterTo) {
          arr = arr.filter(document =>
            dayjs(document.updateTime).isSameOrAfter(dayjs(filterFrom)),
          )
        } else if (filterFrom && filterTo) {
          arr = arr.filter(document =>
            dayjs(document.updateTime).isBetween(dayjs(filterFrom), dayjs(filterTo), 'day', '[]'),
          )
        }
      }

      return arr
    },
    filteredSearchResult () {
      const arr = cloneDeep(this.searchResults)
      arr.forEach(child => {
        child.selectableKey = `${child.type}-${child.id}`
        child.isSelectable = !child.specialFolder
      })
      return arr
    },
    isSelected: {
      get () {
        return this.allDocumentsSelected
      },
      set () {
        this.SELECT_ALL_DOCUMENTS()
      },
    },
    selectedItems: {
      get () {
        return this.selectedDocuments
      },
      set (val) {
        this.SELECT_DOCUMENTS(val)
      },
    },
    dataReady () {
      return !(Object.keys(this.documents).length === 0 || this.loading || this.searchLoading || this.getFolderPathPending)
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, 'document-items-per-page')
      const itemsPerPage = itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : 50
      this.setPagination(itemsPerPage)
      return itemsPerPage
    },
    tableItems () {
      return this.searchModeEnabled ? this.filteredSearchResult : this.filteredDocuments
    },
  },
  watch: {
    async dataReady (newDataReady) {
      await this.$nextTick()
      if (newDataReady) {
        this.resizeObserverDestroyCallback =
          handleElementDimensionResize('.sticky-container', 'height', (newHeight) => {
          // The headerElement (.v-data-table-header) becomes accessible only from this point onward.
          const headerElement = document.querySelector('.table-header .v-data-table-header')
            if (headerElement) {
              headerElement.style.top = `${newHeight}px`
            }
          })
      }
    },
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, 'document-items-per-page'))) {
          this.setPagination(newValue.itemsPerPage)
          setUserSetting(this.currentUserId, 'document-items-per-page', newValue.itemsPerPage)
        }
      },
    },
  },
  methods: {
    ...mapActions('documents', [
      GET_DOCUMENTS_BY_ID,
      SELECT_ALL_DOCUMENTS,
      SELECT_DOCUMENTS,
    ]),
    handleSorting (name) {
      if (this.sorting.name === name) {
        this.sorting.order === 'asc' ? this.sorting.order = 'desc' : this.sorting.order = 'asc'
      } else {
        this.sorting.name = name
        this.sorting.order = 'asc'
      }
    },
    fileLink (fileId) {
      return roomFileDownloadUrl(fileId, this.roomMnemo)
    },
    prepareShowPdf (documentId) {
      return {
        url: this.fileLink(documentId),
        withCredentials: true,
        httpHeaders: {
          Authorization: axios.defaults.headers.common.Authorization,
          'X-Requested-With': 'XMLHttpRequest',
        },
      }
    },
    setPagination (nbItemsPerPage) {
      const files = this.searchModeEnabled ? this.searchResults : this.filteredDocuments
      const nbPages = Math.ceil(files.length / nbItemsPerPage)
      this.dataTablePage = 1
      this.dataTablePageCount = nbPages > 0 ? nbPages : 1
      this.disabledPagination = this.dataTablePageCount === 1
    },
    onPageChange (page) {
      this.$refs.DocumentsTable.$options.propsData.options.page = page
    },
  },
  beforeDestroy () {
    if (this.resizeObserverDestroyCallback) {
      this.resizeObserverDestroyCallback()
    }
  },
}
</script>

<style scoped lang="scss">
/*Toggle class to fit the top with the multiple selection menu*/
.table-header ::v-deep .v-data-table-header {
  position: sticky;
  background-color: #fff;
  z-index: 2;
}
::v-deep .v-data-table .v-data-table__wrapper {
  overflow: unset;
}

::v-deep #DocumentsTable {
  position: relative;

  .v-input--selection-controls__input {
    margin-right: 0;
  }

  table {
    border-collapse: collapse;
  }

  td,
  th {
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

::v-deep .v-data-footer__icons-before {
  display: none;
}

::v-deep .v-data-footer__icons-after {
  display: none;
}

::v-deep .v-data-footer {
  flex-direction: row-reverse;
}

::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}

.data-table-simple-checkbox i {
  font-size: 16px;
}

.filderName {
  word-break: break-word;
}
</style>
