<template>
<div class="Signer-container mb-2 white">
  <div>
    <font-awesome-icon :icon="['fas', 'grip-vertical']"
                       class="Signer-grabIcon mr-2 originalGrey--text text--lighten-1"
    />
    {{signer.fullName}}
  </div>
  {{index}}

</div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'SignersOrderQualifiedSigner',
  props: {
    signerId: {
      type: Number,
      required: true,
    },
    index: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters('groups', ['allRoomMembers']),
    signer () {
      return this.allRoomMembers.find(m => m.id === this.signerId)
    },
  },
})
</script>

<style scoped lang="scss">
.Signer {
  &-container {
    border: 1px solid #E9ECEF;
    border-radius: 4px;
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-grabIcon {
    cursor: grab;
  }
}
</style>
