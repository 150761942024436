<template>
  <div>
    <div v-if="item.validations?.length === 1" class="d-flex align-center">
      <ProjectSettingsSignaturesValidator :validation="item.validations[0]" :currentDate="currentDate" :item="item"/>
    </div>
    <span v-else-if="item.validations?.length" class="d-flex align-center">
      <app-icon v-if="allValidatorsValidated" icon-name="clock" icon-weight="far" size="small" class="tertiary--text mr-1"/>
      <app-icon v-else icon-name="circle-check" icon-weight="fas" class="success--text" size="small mr-1"/>
      {{ item.validations[0].validator.fullName }}
      {{ $tc('project.project-settings.signatures.ProjectSettingsSignaturesValidators.otherValidators', item.validations.length - 1, { count: item.validations.length - 1 }) }}
    </span>
    <CollapseTransition v-if="item.validations?.length > 1">
      <div v-if="expanded">
        <div v-if="item.validations?.length > 1" class="mt-2 caption font-weight-bold" style="color: #616161">{{$t('project.project-settings.signatures.ProjectSettingsSignaturesValidators.validatorsList')}}</div>
        <div v-for="(validation, i) in item.validations"
             :key="`validation-${i}`"
        >
          <ProjectSettingsSignaturesValidator :validation="validation" :currentDate="currentDate" :item="item"/>
        </div>
      </div>
    </CollapseTransition>

  </div>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import { defineComponent } from 'vue'

import { ISOToShortDate } from '@/common/utils/dates'

import ProjectSettingsSignaturesValidator from './ProjectSettingsSignaturesValidator.vue'

export default defineComponent({
  name: 'ProjectSettingsSignaturesValidators',
  components: { CollapseTransition, ProjectSettingsSignaturesValidator },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    currentDate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    allValidatorsValidated () {
      return this.item.validations.filter(validator => !validator.validated).length > 0
    },
  },
  methods: {
    ISOToShortDate,
  },
})
</script>
