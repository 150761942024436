// This file contains all the actions which was in index.ts before module structure creation
// TODO: Clean this file

import BIBLES from '@/fixtures/bibles.json'
import NOTIFICATIONS from '@/fixtures/notifications.json'
import TASKS from '@/fixtures/tasks.json'

const setHomeLayout = ({ commit }, value) => {
  commit('SET_HOME_LAYOUT', value)
}
const setProjectLayout = ({ commit }, value) => {
  commit('SET_PROJECT_LAYOUT', value)
}
const setToggleProjectDrawer = ({ commit }, value) => {
  commit('SET_TOGGLE_PROJECT_DRAWER', value)
}
const getNotifications = ({ state, commit }) => {
  setTimeout(() => {
    commit('setNotifications', NOTIFICATIONS)
  }, 3000)
}
const getTasks = ({ state, commit }) => {
  setTimeout(() => {
    commit('setTasks', TASKS)
  }, 1000)
}
const getBibles = ({ state, commit }) => {
  setTimeout(() => {
    commit('setBibles', BIBLES)
  }, 500)
}
const saveUser = ({ state, commit }, payload) => {
  commit('enqueueSnackbar', {
    color: 'success',
    timeout: 3000,
    text: 'Modifications sauvegardées',
  })
}

export default {
  getNotifications,
  getTasks,
  getBibles,
  saveUser,
  setHomeLayout,
  setProjectLayout,
  setToggleProjectDrawer,
}
