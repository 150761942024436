<template>
  <AppDialog :is-open="isDialogOpened"
             :stepper-model="step"
             stepper-dialog
             size="l"
             content-class="save-checklist-dialog"
             @cancel="closeDialog"
  >
    <template #stepper-header>
      <template v-if="step === 1">
        {{ $t('project.checklist.dialogs.SaveChecklistDialog.newModel') }}
      </template>
      <template v-if="step === 2">
        <template v-if="isTemplateTypeNew">
          {{ $t('project.checklist.dialogs.SaveChecklistDialog.createNewModel') }}
        </template>
        <template v-else>
          {{ $t('project.checklist.dialogs.SaveChecklistDialog.updateExistingModel') }}
        </template>
      </template>
      <template v-if="step === 3">
        {{ $t('project.checklist.dialogs.SaveChecklistDialog.shareModel') }}
      </template>
    </template>
    <template #stepper-items>
      <v-stepper-items>
        <!-- STEP 1 -->
        <template v-if="step === 1">
          <app-text variant="medium-bold">
            {{ $t('project.checklist.dialogs.SaveChecklistDialog.step1Title') }}
          </app-text>
          <SaveChecklistTemplateType :default-template-type="templateType"
                                     @change-template-type="templateType = $event"
          />
        </template>
        <!-- STEP 2 -->
        <template v-if="step === 2">
          <template v-if="isTemplateTypeNew">
            <h3 class="text-h3">
              {{ $t('project.checklist.dialogs.SaveChecklistDialog.saveChecklist') }}
            </h3>

            <div class="my-6">{{ $t('project.checklist.dialogs.SaveChecklistDialog.text1') }}</div>

            <v-row>
              <v-col>
                <form @submit.prevent="onOk"></form>
                <AppTextField ref="nameInput"
                              v-model="$v.name.$model"
                              class="required"
                              autofocus
                              :label="$t('project.checklist.dialogs.SaveChecklistDialog.saveName')"
                              :error-messages="nameErrors"
                              counter="100"
                              maxlength="100"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="mb-4">
                  {{ $t('project.checklist.dialogs.SaveChecklistDialog.whereFindSavedChecklistText') }}
                </div>
                <app-alert-in-page variant="info" :dismissible="false">
                  <div class="mb-1"><b>{{ $t('project.checklist.dialogs.SaveChecklistDialog.alertText1') }}</b></div>
                  <div>{{ $t('project.checklist.dialogs.SaveChecklistDialog.alertText2') }}</div>
                  <div>{{ $t('project.checklist.dialogs.SaveChecklistDialog.alertText3') }}</div>
                  <div>{{ $t('project.checklist.dialogs.SaveChecklistDialog.alertText4') }}</div>
                </app-alert-in-page>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <!-- MODELS LISTS -->
            <SavedChecklistTemplatesList @go-to-new-template="goToNewTemplate" />

            <template v-if="selectedSelfSavedChecklist">
              <form @submit.prevent="onOk"></form>

              <app-text variant="x-small-bold" class="mt-4 mb-2">
                {{ $t('project.checklist.dialogs.SaveChecklistDialog.modelName') }}
              </app-text>

              <AppTextField ref="nameInput"
                            v-model="$v.name.$model"
                            class="required"
                            autofocus
                            :error-messages="nameErrors"
                            counter="100"
                            maxlength="100"
              />
              <app-alert-in-page variant="info" :dismissible="false">
                <div class="mb-1"><b>{{ $t('project.checklist.dialogs.SaveChecklistDialog.alertText1') }}</b></div>
                <div>{{ $t('project.checklist.dialogs.SaveChecklistDialog.alertText2') }}</div>
                <div>{{ $t('project.checklist.dialogs.SaveChecklistDialog.alertText3') }}</div>
                <div>{{ $t('project.checklist.dialogs.SaveChecklistDialog.alertText4') }}</div>
              </app-alert-in-page>
            </template>
          </template>
        </template>

        <!-- STEP 3 -->
        <template v-if="step === 3">
          <SaveChecklistShare :defaultShareType="shareType"
                              :defaultSelectedUsers="selectedUsers"
                              :defaultSendNotif="sendNotif"
                              @change-share-type="shareType = $event"
                              @set-selected-users="selectedUsers = $event"
                              @set-send-notif="sendNotif = $event"
          />
        </template>
      </v-stepper-items>
    </template>

    <template #footer>
      <AppButton v-if="step > 1"
                color="white"
                @click="step -= 1"
      >
        <app-icon icon-name="arrow-left-long" size="medium" class="mr-2" />
        {{ $t('common.previous') }}
      </AppButton>
      <AppButton color="white"
                class="ml-auto"
                :disabled="isPending"
                @click="closeDialog"
      >
        {{ $t('common.cancel') }}
      </AppButton>
      <AppButton v-if="step < maxStepsNumber"
                color="primary"
                :disabled="!canGoToNext"
                @click="onOk"
      >
        {{ $t('common.next') }}
        <app-icon icon-name="arrow-right-long" size="medium" class="ml-2" />
      </AppButton>
      <AppButton v-if="step === maxStepsNumber"
                color="primary"
                :loading="isPending"
                :disabled="disableSaveModelBtn"
                @click="onOk"
      >
        {{ $t('project.checklist.dialogs.SaveChecklistDialog.saveModel') }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import AppTextField from '@/common/AppTextField.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes.ts'
import { TEMPLATE_TYPE_OPTIONS } from '@/common/utils/templateTypes.ts'
import { dialogMixin } from '@/mixins/dialogMixin'
import SaveChecklistShare from '@/project/checklist/dialogs/SaveChecklistShare.vue'
import SaveChecklistTemplateType from '@/project/checklist/dialogs/SaveChecklistTemplateType.vue'
import SavedChecklistTemplatesList from '@/project/checklist/dialogs/SavedChecklistTemplatesList.vue'
import { SAVE_CHECKLIST, UPDATE_CHECKLIST } from '@/store/modules/checklist/action_types'
import { SET_SELF_SAVED_CHECKLISTS, SET_SELECTED_SELF_SAVED_CHECKLIST } from '@/store/modules/checklist/mutation_types'
import { ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

const STEPS = Object.freeze({
  RENAME: 2,
  SHARE: 3,
})

export default {
  name: 'SaveChecklistDialog',
  components: {
    AppDialog,
    AppButton,
    AppTextField,
    SaveChecklistShare,
    SaveChecklistTemplateType,
    SavedChecklistTemplatesList,
  },
  mixins: [dialogMixin, validationMixin],
  validations: {
    name: { required, maxLength: maxLength(100) },
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      name: '',
      step: 1,
      SHARE_TYPE_OPTIONS,
      shareType: SHARE_TYPE_OPTIONS.PUBLIC,
      TEMPLATE_TYPE_OPTIONS,
      templateType: null,
      selectedUsers: [],
      sendNotif: false,
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', ['currentUserId']),
    ...mapState('checklist', [
      'saveChecklistPending',
      'updateChecklistPending',
      'selectedSelfSavedChecklist',
    ]),
    ...mapGetters('room', ['roomName']),
    maxStepsNumber  () {
      return this.canShareChecklistTemplate ? STEPS.SHARE : STEPS.RENAME
    },
    canShareChecklistTemplate () {
      if (!this.selectedSelfSavedChecklist) return true
      return this.isTemplateTypeNew || this.isCurrentUserChecklistOwner
    },
    isCurrentUserChecklistOwner () {
      return this.selectedSelfSavedChecklist?.userId === this.currentUserId
    },
    isPending () {
      return this.saveChecklistPending || this.updateChecklistPending
    },
    disableSaveModelBtn () {
      return this.nameErrors.length !== 0 || this.isPending
    },
    defaultName () {
      return `Checklist-${this.roomName}`
    },
    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.checklist.dialogs.SaveChecklistDialog.saveName'), maxLength: '100' }))
      !this.$v.name.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.checklist.dialogs.SaveChecklistDialog.saveName') }))
      return errors
    },
    canGoToNext () {
      if (this.step === 1 && this.templateType) return true
      if (this.step === 2) {
        if (this.isTemplateTypeUpdate) {
          if (this.selectedSelfSavedChecklist && this.nameErrors.length === 0) return true
        } else if (this.nameErrors.length === 0) {
          return true
        }
      }
      return false
    },
    isTemplateTypeNew () {
      return this.templateType === TEMPLATE_TYPE_OPTIONS.NEW
    },
    isTemplateTypeUpdate () {
      return this.templateType === TEMPLATE_TYPE_OPTIONS.UPDATE
    },
    shareTypeIsPublic () {
      return this.shareType === SHARE_TYPE_OPTIONS.PUBLIC
    },
    shareTypeIsPrivate () {
      return this.shareType === SHARE_TYPE_OPTIONS.PRIVATE
    },
    shareTypeIsCustom () {
      return this.shareType === SHARE_TYPE_OPTIONS.CUSTOM
    },
  },
  watch: {
    'selectedSelfSavedChecklist.id' (newValue) {
      if (newValue) {
        this.name = this.selectedSelfSavedChecklist.name
        this.shareType = this.selectedSelfSavedChecklist.shareType
        if (this.selectedSelfSavedChecklist.rights?.length) {
          this.selectedUsers = this.selectedSelfSavedChecklist.rights.filter(user => user.userId !== this.profile.id)
        } else {
          this.selectedUsers = []
        }
      }
    },
    'templateType' () {
      if (this.isTemplateTypeNew) {
        this.name = this.defaultName
      } else if (this.selectedSelfSavedChecklist?.name) {
        this.name = this.selectedSelfSavedChecklist.name
      }
    },
  },
  beforeDestroy () {
    this.SET_SELF_SAVED_CHECKLISTS([])
    this.SET_SELECTED_SELF_SAVED_CHECKLIST(null)
  },
  methods: {
    ...mapActions('checklist', [SAVE_CHECKLIST, UPDATE_CHECKLIST]),
    ...mapMutations('checklist', [SET_SELF_SAVED_CHECKLISTS, SET_SELECTED_SELF_SAVED_CHECKLIST]),
    ...mapMutations([ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_ERROR_SNACKBAR]),
    goToNewTemplate () {
      this.templateType = TEMPLATE_TYPE_OPTIONS.NEW
      this.step = 2
    },
    onOk () {
      if (this.step === 1) {
        this.step = this.step + 1
      } else if (this.step === 2) {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          if (this.isTemplateTypeUpdate && !this.isCurrentUserChecklistOwner) {
            this.prepareSaveChecklist()
          } else {
            this.step = this.step + 1
          }
        }
      } else if (this.step === 3) {
        this.prepareSaveChecklist()
      }
    },
    async prepareSaveChecklist () {
      try {
        if (this.shareTypeIsPublic || this.shareTypeIsPrivate) {
          this.selectedUsers = []
        }

        if (this.shareTypeIsCustom && this.selectedUsers.length === 0) {
          this.shareType = SHARE_TYPE_OPTIONS.PRIVATE
        }

        const data = {
          name: this.name,
          shareType: this.shareType,
          withUsers: this.selectedUsers,
          sendNotif: !this.shareTypeIsPrivate && this.sendNotif,
        }

        if (this.isTemplateTypeNew) {
          await this.SAVE_CHECKLIST({
            mnemo: this.mnemo,
            data,
          })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$tc('project.checklist.dialogs.SaveChecklistDialog.saveChecklistSuccess', this.name, { checklistName: this.name }))
        } else {
          await this.UPDATE_CHECKLIST({
            mnemo: this.mnemo,
            taskId: this.selectedSelfSavedChecklist.id,
            data,
          })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$tc('project.checklist.dialogs.SaveChecklistDialog.updateChecklistSuccess', this.name, { checklistName: this.name }))
        }
      } catch (e) {
        if (this.isTemplateTypeNew) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.checklist.dialogs.SaveChecklistDialog.saveChecklistError'))
        } else {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.checklist.dialogs.SaveChecklistDialog.updateChecklistError'))
        }
      } finally {
        this.closeDialog()
      }
    },
  },
}
</script>

<style lang="scss">
  .save-checklist-dialog {
    .AppDialogStepper__stepperHeader {
      margin-left: initial;
    }

    .v-card {
      // Set the same size for the entire stepper
      &__text.v-card__text {
        min-height: 480px;
      }
    }
  }
</style>
