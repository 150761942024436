<template>
  <div class="ChecklistTableTaskStatus"
       :class="{'clickable': statusIsClickable}"
       @click="onStatusClick"
  >
    <div v-if="$vuetify.breakpoint.smAndDown" class="ChecklistTableTaskStatus__row-label pa-2">
      {{ $t('project.checklist.task.ChecklistTableTaskStatus.status') }}
    </div>

    <div class="ChecklistTableTaskStatus__chip-container pa-4"
         :style="statusContainerStyles"
    >
      <AppTooltip top>
        <template #activator="{attrs, on}">
          <v-chip :color="chipColor"
                  class="px-4"
                  :class="{'clickable': statusIsClickable}"
                  v-bind="attrs"
                  v-on="on"
          >
            <template v-if="taskStatusCategoriesPending && !statusLabel">
              <v-progress-circular
                color="white"
                indeterminate
                size="14"
                width="2"
              />
            </template>
            <template v-else>
              {{ statusLabel }}
            </template>
          </v-chip>
        </template>
        <span>{{ statusLabel }}</span>
      </AppTooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { statusCategoryColors } from './taskStatus'
import AppTooltip from '../../../common/AppTooltip.vue'
import { getStatusDetailsByTaskInformation } from '../../../common/utils/checklist'

const AWAITING_DELETE_VALIDATION_COLOR = '#F39C12'
const CONTRAST_SUFFIX = '4d'

export default {
  name: 'ChecklistTableTaskStatus',
  components: { AppTooltip },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('checklist', ['taskStatusCategories', 'taskStatusCategoriesPending']),
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapGetters('user', ['currentUserId']),
    isAwaitingDeleteValidation () {
      return this.item.isAwaitingValidation
    },
    chipColor () {
      if (this.isAwaitingDeleteValidation) return AWAITING_DELETE_VALIDATION_COLOR
      return this.item.statusColor || this.statusCategoryColor
    },
    statusCategory () {
      if (this.taskStatusCategories[this.item.statusCategory]) {
        return this.item.statusCategory
      }

      return null
    },
    statusLabel () {
      if (this.isAwaitingDeleteValidation) {
        return this.$t('project.project-settings.ProjectSettingsFeatures.checklist.pendingDeletion')
      }
      if (this.statusCategory) {
        const status = getStatusDetailsByTaskInformation(this.item.statusId, this.statusCategory, this.taskStatusCategories)
        if (status) {
          return status.label
        }
      }
      return null
    },
    statusCategoryColor () {
      if (this.statusCategory) {
        return statusCategoryColors[this.statusCategory]
      }

      return statusCategoryColors.draft
    },
    statusContainerStyles () {
      if (this.isAwaitingDeleteValidation) {
        return {
          backgroundColor: `${AWAITING_DELETE_VALIDATION_COLOR}${CONTRAST_SUFFIX}`,
          clickable: false,
        }
      }
      return {
        backgroundColor: `${this.item.statusColor || this.statusCategoryColor}${CONTRAST_SUFFIX}`,
        clickable: this.item.canWriteStatus,
      }
    },
    currentUserIsResponsibleOfTheTask () {
      return !!this.item.rights?.tagged?.users?.find(user => user.id === this.currentUserId)
    },
    currentUserIsValidatorOfTheTask () {
      return !!this.item.rights?.validators?.users?.find(user => user.id === this.currentUserId)
    },
    currentUserCanChangeTaskStatus () {
      return !this.isAwaitingDeleteValidation && (this.isCurrentUserPm ||
        this.currentUserIsValidatorOfTheTask ||
        this.currentUserIsResponsibleOfTheTask)
    },
    statusIsClickable () {
      return this.item.canWriteStatus && this.currentUserCanChangeTaskStatus
    },
  },
  methods: {
    onStatusClick () {
      if (this.currentUserCanChangeTaskStatus) {
        this.$emit('edit-status')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableTaskStatus {
  pointer-events: visible;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 200px;
  }

  &__row-label {
    flex-grow: 1;
  }

  &__chip-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 2;

    .v-chip {
      color: white;
      font-weight: bold;

      ::v-deep &__content {
        height: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }
  }
}
</style>
