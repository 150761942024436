<template>
  <div class="qa-item-thread-question">
    <div class="qa-item-thread-question__header">
      <div class="qa-item-thread-question__question">
        <v-row justify="space-between" align="center" no-gutters class="mb-4">
          <span class="qa-item-thread-question__question--id">{{ `#${question.idInRoom}` }}</span>
          <QuestionAnswerStatusChip :status="question.status" />
        </v-row>
        <div class="qa-item-thread-question__question--description">
          <span v-html="question.description"></span>
        </div>
      </div>

      <div class="d-flex justify-space-between align-center">
        <div>
          <v-avatar v-if="$vuetify.breakpoint.mdAndUp"
                    size="26"
                    class="qa-item-thread-question__initials mr-2"
          >
            {{ question.userShortInitials }}
          </v-avatar>
          <span class="qa-item-thread-question__username">{{ question.userName }}</span>
        </div>

        <div v-if="question.file">
          <span class="font-weight-medium">{{ $t('project.question-answer.QuestionAnswerItemThreadQuestion.reference') }} :</span>
          <span v-if="question.file.numbering">{{ question.file.numbering }}</span>
          {{ question.file.basename }}
        </div>
        <div v-else-if="question.directory">
          <span class="font-weight-medium">{{ $t('project.question-answer.QuestionAnswerItemThreadQuestion.reference') }} :</span>
          <span v-if="question.directory.numbering">{{ question.directory.numbering }}</span>
          {{ question.directory.name }}
        </div>
      </div>

      <div class="d-flex justify-space-between align-center mt-2">
        <div class="caption qa-item-thread-question__creation-date">
          {{ $t('project.question-answer.QuestionAnswerItemThreadQuestion.publishedOn') }}
          <DateDisplay :value="question.createdAt" with-hours/>
        </div>

        <div>
          <v-chip v-for="category in question.categories" :key="category.id" small>
            {{ category.name }}
          </v-chip>
        </div>
      </div>
    <div v-if="question.status !== 'validated' &&
          (currentRoom.isPM || question.rights.canWrite)"
         class="qa-item-thread__actions"
    >
      <QuestionAnswerItemCloseQuestionDialog
        v-if="showValidateAnswerDialog"
        @close="showValidateAnswerDialog = false"
        @confirm="markQuestionAsAnswered"
      />
      <AppButton color="primary"
                 outlined
                 :disabled="question.answers.length === 0"
                 :loading="markQuestionAsResolvedPending"
                 @click="showValidateAnswerDialog = true"
      >
        {{ $t("project.question-answer.QuestionAnswerItemThreadQuestion.validateAnswer")}}
      </AppButton>
    </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import DateDisplay from '@/common/DateDisplay'

import QuestionAnswerItemCloseQuestionDialog from './QuestionAnswerItemCloseQuestionDialog.vue'
import QuestionAnswerStatusChip from './QuestionAnswerStatusChip.vue'
import AppButton from '../../common/buttons/AppButton.vue'
import { QuestionAnswerActionTypes } from '../../store/modules/question-answer/action_types'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'

export default {
  name: 'QuestionAnswerItemThreadQuestion',
  components: {
    DateDisplay,
    QuestionAnswerStatusChip,
    AppButton,
    QuestionAnswerItemCloseQuestionDialog,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showValidateAnswerDialog: false,
    }
  },
  computed: {
    ...mapState('questionAnswer', ['question', 'markQuestionAsResolvedPending']),
    ...mapState('room', ['currentRoom']),
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.MARK_QUESTION_AS_VALIDATED]),
    async markQuestionAsAnswered () {
      try {
        await this.MARK_QUESTION_AS_VALIDATED({
          mnemo: this.mnemo,
          questionId: this.question.id,
        })

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.question-answer.QuestionAnswerItemThreadQuestion.markQuestionAsAnsweredSuccess', { idInRoom: this.question.idInRoom }),
        })
      } catch (error) {
        console.error(error)

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.question-answer.QuestionAnswerItemThreadQuestion.markQuestionAsAnsweredError'),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.qa-item-thread-question {
  padding-bottom: 28px;
  color: #333333;

  &__question--id {
    font-size: 16px;
    color: #626262;
  }

  &__initials {
    color: white;
    background-color: var(--v-primary-lighten1);
    font-size: 12px;
  }

  &__username {
    font-size: 16px;
    font-weight: 500;
    /* Position fixes the vertical alignment with the initials */
    position: relative;
    top: 2px;
  }

  &__creation-date {
    color: #626262;
  }

  &__question {
    margin: 20px 0;
    font-size: 24px;
    font-weight: 500;

    ::v-deep &--description p {
      margin-bottom: 0;
      overflow-wrap: anywhere;
      // Magic value that slightly increases the line-height.
      // Most UA have 1.2 as default and 1.4 seems like a good visual help
      // Especially because of the bold title-like styling
      line-height: 1.4;
    }
  }
}
</style>
