import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'

export const getters = {
  filteredSignedDocuments (state) {
    let filteredSignedDocuments = cloneDeep(state.signedDocuments)

    if (state.searchQuery) {
      filteredSignedDocuments = filteredSignedDocuments.filter(el => el.name.toLowerCase().includes(state.searchQuery.toLowerCase()))
    }
    if (state.filters.signatories.length > 0) {
      filteredSignedDocuments = filteredSignedDocuments.filter(filteredSignedDocument => {
        if (state.filters.signatories.find(signatory => signatory === filteredSignedDocument.mySignature.signerId)) {
          return true
        }
        if (filteredSignedDocument.otherSignatures.length > 0) {
          for (const otherSignature of filteredSignedDocument.otherSignatures) {
            if (state.filters.signatories.find(signatory => signatory === otherSignature.signerId)) {
              return true
            }
          }
        }
        return false
      })
    }

    if (state.filters.startDate) {
      filteredSignedDocuments = filteredSignedDocuments.filter(filteredSignedDocument => dayjs(filteredSignedDocument.time).isSameOrAfter(dayjs(state.filters.startDate), 'day'))
    }
    if (state.filters.endDate) {
      filteredSignedDocuments = filteredSignedDocuments.filter(filteredSignedDocument => dayjs(filteredSignedDocument.time).isSameOrBefore(dayjs(state.filters.endDate), 'day'))
    }
    return filteredSignedDocuments.filter(filteredSignedDocument => filteredSignedDocument.status === 'signed')
  },
  hasActiveFilters (state) {
    return state.filters.signatories.length > 0 ||
      !!state.filters.startDate ||
      !!state.filters.endDate
  },
  signedDocumentsToDisplay (state) {
    return state.signedDocuments.filter(filteredSignedDocument => filteredSignedDocument.status === 'signed')
  },
}
