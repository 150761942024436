import { GroupsState } from '@/store/modules/groups/state'

export const getters = {
  allRoomMembers: (state: GroupsState) => state.groups.map(group => group.members).flat(),
  currentUserGroupId: (state: GroupsState, getters, rootState, rootGetters) => {
    const group = state.groups.find(g => {
      return g.members.some(m => {
        return m.id === rootGetters['user/currentUserId']
      })
    })
    return group?.id || null
  },
  members: (state: GroupsState) => state.groups.map(group => group.members).flat(),
  projectManagers: (state: GroupsState) => {
    const pmGroup = state.groups.find(group => group.isPm)
    if (pmGroup) {
      return pmGroup.members
    }

    return []
  },
}
