<template>
  <AppDialog :is-open="isDialogOpened"
             :close-button="false"
             size="l"
             hide-footer
  >
    <template v-if="!showDoNotRemindMeStep" #title>
      <h2 class="primary--text mx-auto">{{ $t('common.identity-checking.IdentityCheckingLandingDialog.title', { userFirstName }) }}</h2>
    </template>

    <template #body>
      <div v-if="showDoNotRemindMeStep" class="text-center pt-8">
        <div>
          <v-icon size="60" color="accent" left style="opacity: 0.4">far fa-id-card</v-icon>
        </div>
        <p class="my-2 text-h2 font-weight-bold" >
          {{$t('common.identity-checking.IdentityCheckingLandingDialog.doNotRemindMeStepTitle') }}
        </p>
        <p v-html="$t('common.identity-checking.IdentityCheckingLandingDialog.doNotRemindMeStepText')"></p>
      </div>
      <template v-else>
        <p class="text-center">{{ $t("common.identity-checking.IdentityCheckingLandingDialog.description") }}</p>
        <v-row>
          <v-col cols="12" md="6">
            <AppCard
              hover
              class="h-100"
              @click.native="wannaSign"
            >
              <v-row>
                <v-col
                  order="1"
                  order-md="2"
                  md="12"
                  :class="{'text-center': $vuetify.breakpoint.mdAndUp}"
                >
                  <p class="mb-2" style="font-size: 18px; font-weight: 600;">
                    {{$t('common.identity-checking.IdentityCheckingLandingDialog.signOptionTitle') }}
                  </p>
                  <p>{{ $t('common.identity-checking.IdentityCheckingLandingDialog.signOptionDescription') }}</p>
                </v-col>
                <v-col
                  cols="auto"
                  md="12"
                  order="2"
                  order-md="1"
                  :class="{'text-center mb-3': $vuetify.breakpoint.mdAndUp}"
                >
                  <img src="/img/file_signature_on.svg" width="95" />
                </v-col>
              </v-row>
            </AppCard>
          </v-col>
          <v-col cols="12" md="6">
            <AppCard
              hover
              class="h-100"
              @click.native="dontRemindMe"
            >
              <v-row>
                <v-col
                  order="1"
                  order-md="2"
                  md="12"
                  :class="{'text-center': $vuetify.breakpoint.mdAndUp}"
                >
                  <p class="mb-2" style="font-size: 18px; font-weight: 600;">
                    {{$t('common.identity-checking.IdentityCheckingLandingDialog.doNotRemindMeOptionTitle') }}
                  </p>
                  <p>{{ $t('common.identity-checking.IdentityCheckingLandingDialog.doNotRemindMeOptionDescription') }}</p>
                </v-col>
                <v-col
                  cols="auto"
                  md="12"
                  order="2"
                  order-md="1"
                  :class="{'text-center mb-3': $vuetify.breakpoint.mdAndUp}"
                >
                  <img src="/img/file_signature_off.svg" width="95" />
                </v-col>
              </v-row>
            </AppCard>
          </v-col>
        </v-row>
        <v-row>
          <AppButton
            class="mx-auto"
            color="primary"
            text
            @click="remindLater"
          >
            {{$t('common.identity-checking.IdentityCheckingLandingDialog.remindMeLater')}}
          </AppButton>
        </v-row>
      </template>
    </template>

    <template v-if="showDoNotRemindMeStep" #footer>
      <AppButton @click="showDoNotRemindMeStep = false">
        <i
          v-if="$vuetify.breakpoint.mdAndUp"
          class="fal mr-2 fa-arrow-left-long"
        ></i>
        {{ $t('common.previous') }}
      </AppButton>
      <v-spacer />
      <AppButton
        color="primary"
        @click="closeDialog"
      >
        {{ $t('common.close') }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import { dialogMixin } from '../../mixins/dialogMixin'
import { UPDATE_PROFILE } from '../../store/modules/user/action_types'
import { SET_SHOW_IDENTITY_CHECK_MODAL } from '../../store/modules/user/mutation_types'
import AppCard from '../AppCard.vue'
import AppButton from '../buttons/AppButton.vue'
import AppDialog from '../dialogs/AppDialog'

const { mapMutations, mapActions } = createNamespacedHelpers('user')

export default {
  name: 'IdentityCheckingLandingDialog',
  components: { AppDialog, AppButton, AppCard },
  mixins: [dialogMixin],
  data () {
    return {
      showDoNotRemindMeStep: false,
    }
  },
  computed: {
    userFirstName () {
      return this.$store.state.user.profile.firstName
    },
  },
  watch: {
    showDoNotRemindMeStep (value) {
      // If we get to a falsy value again, that means we got back from
      // The do not remind me step. So we should put the hereToSign back to true
      if (!value) {
        this.UPDATE_PROFILE({ hereToSign: 1 })
      }
    },
  },
  methods: {
    ...mapActions([UPDATE_PROFILE]),
    ...mapMutations([SET_SHOW_IDENTITY_CHECK_MODAL]),
    wannaSign () {
      this.closeDialog()
      this.SET_SHOW_IDENTITY_CHECK_MODAL(true)
    },
    remindLater () {
      this.closeDialog()
    },
    dontRemindMe () {
      this.UPDATE_PROFILE({ hereToSign: 0 })
      this.showDoNotRemindMeStep = true
    },
  },
}
</script>
