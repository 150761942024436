export const RIGHTS_LIST = Object.freeze({
  CAN_ADD_QUESTIONS: { propertyName: 'canAddQuestions' },
  CAN_CREATE_BIBLES: {
    propertyName: 'canCreateBibles',
  },
  CAN_CREATE_FOLDERS: { propertyName: 'canCreateFolders' },
  CAN_DOWNLOAD: {
    propertyName: 'canDownload',
    hasHeaderTooltip: true,
  },
  CAN_UPLOAD: { propertyName: 'canUpload' },
  CAN_UPLOAD_VERSIONS: { propertyName: 'canUploadVersions' },
  CAN_COMMENT_TASKS: { propertyName: 'canCommentTasks' },
})
