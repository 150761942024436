<template>
<div class="h-100 d-flex justify-center align-center">
  <template v-if="stripeInfo">
    <StripeCheckout ref="checkoutRef"
                    :pk="publishableKey"
                    :session-id="stripeInfo.session"
    >
    </StripeCheckout>
    <AppCard class="w-50">
      <v-row>
        <v-col class="text-h1 text-center">
          {{ $t('payment.Payment.title') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <font-awesome-icon :icon="['far', 'file-invoice']"
                             class="text--primary"
                             style="font-size: 100px;"
          ></font-awesome-icon>
        </v-col>
      </v-row>
      <v-row class="body-1">
        <v-col>
          <v-row>
            <v-col class="text-h2">{{ $t('payment.Payment.details') }}</v-col>
          </v-row>
          <v-row>
            <v-col>
              {{$t('payment.Payment.extRef')}}
              {{ stripeInfo.invoice.ref }}
            </v-col>
            <v-col>
              {{$t('payment.Payment.subscriptionName')}}
              {{ stripeInfo.invoice.subscriptionName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              {{$t('payment.Payment.period')}}
              <DateDisplay :value="stripeInfo.invoice.fromDate"/>
              -
              <DateDisplay :value="stripeInfo.invoice.toDate"/>
            </v-col>
            <v-col>
              {{$t('payment.Payment.dueDate')}}
              <DateDisplay :value="stripeInfo.invoice.dueDate"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              {{$t('payment.Payment.date')}}
              <DateDisplay :value="stripeInfo.invoice.invoiceDate"/>
            </v-col>
            <v-col>
              {{$t('payment.Payment.amountGross')}}
              {{ $t('payment.Payment.amount', { amount: stripeInfo.invoice.amountGross }) }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <AppButton color="primary"
                     @click="redirectToCheckout"
          >
            {{ $t('payment.Payment.pay') }}
          </AppButton>
        </v-col>
      </v-row>
    </AppCard>
  </template>
</div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { mapActions, mapState } from 'vuex'

import AppCard from '@/common/AppCard'
import AppButton from '@/common/buttons/AppButton'
import DateDisplay from '@/common/DateDisplay'
import { GET_STRIPE_INFO } from '@/store/modules/payment/action_types'

export default {
  name: 'Payment',
  components: { DateDisplay, AppCard, AppButton, StripeCheckout },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      publishableKey: null,
    }
  },
  computed: {
    ...mapState('payment', ['stripeInfo']),
  },
  created () {
    this.prepareGetStripeInfo()
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
  },
  methods: {
    ...mapActions('payment', [GET_STRIPE_INFO]),
    async prepareGetStripeInfo () {
      try {
        await this.GET_STRIPE_INFO(this.token)
      } catch (error) {
        console.error(error.response)
      }
    },
    redirectToCheckout () {
      this.$refs.checkoutRef.redirectToCheckout()
    },
  },
}
</script>
