import {
  SET_CONFIG,
  SET_CONFIG_PENDING,
  SET_CURRENT_ARCHIVE_RIGHTS,
  SET_GET_ARCHIVE_RIGHTS_PENDING,
  SET_LIST_SEARCH_DATA,
  SET_LIST_SEARCH_DATA_COUNT,
  SET_LIST_SEARCH_PENDING,
  SET_SEND_ARCHIVES_PENDING,
  SET_SHARE_ARCHIVES_PENDING,
  SET_MUTE_ARCHIVING_WARNING,
  ADD_POST_ARCHIVE_PENDING,
  REMOVE_POST_ARCHIVE_PENDING,
  ADD_POST_ARCHIVE_ERROR,
  REMOVE_POST_ARCHIVE_ERROR,
  ADD_UPLOAD_ARCHIVE_PENDING,
  REMOVE_UPLOAD_ARCHIVE_PENDING,
  ADD_UPLOAD_ARCHIVE_ERROR,
  REMOVE_UPLOAD_ARCHIVE_ERROR,
  SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN,
  SET_ALL_DOCUMENTS_AS_SELECTED,
  SET_SELECTED_DOCUMENTS,
  SET_ARCHIVES_DOWNLOAD_PENDING,
  REMOVE_DOCUMENT,
} from './mutation_types'

export const mutations = {
  [SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN] (state, value) {
    state.discoverElectronicSafeDialogIsOpen = value
  },
  [SET_LIST_SEARCH_PENDING]: function (state, value) {
    state.listSearchPending = value
  },
  [SET_LIST_SEARCH_DATA]: function (state, value) {
    state.listSearchData = value
  },
  [SET_LIST_SEARCH_DATA_COUNT]: function (state, value) {
    state.listSearchDataCount = value
  },
  [SET_CONFIG]: function (state, value) {
    state.config = value
  },
  [SET_CONFIG_PENDING]: function (state, value) {
    state.configPending = value
  },
  [SET_GET_ARCHIVE_RIGHTS_PENDING]: function (state, value) {
    state.getArchiveRightsPending = value
  },
  [SET_CURRENT_ARCHIVE_RIGHTS]: function (state, value) {
    state.currentArchiveRights = value
  },
  [SET_SEND_ARCHIVES_PENDING]: function (state, value) {
    state.sendArchiveSPending = value
  },
  [SET_SHARE_ARCHIVES_PENDING]: function (state, value) {
    state.shareArchiveSPending = value
  },
  [SET_MUTE_ARCHIVING_WARNING] (state, value) {
    state.muteArchivingWarning = value
  },
  [ADD_UPLOAD_ARCHIVE_PENDING] (state, value) {
    state.uploadArchivePendingIds.push(value)
  },
  [REMOVE_UPLOAD_ARCHIVE_PENDING] (state, index) {
    state.uploadArchivePendingIds.splice(index, 1)
  },
  [ADD_UPLOAD_ARCHIVE_ERROR] (state, value) {
    state.uploadArchiveErrors.push(value)
  },
  [REMOVE_UPLOAD_ARCHIVE_ERROR] (state, index) {
    state.uploadArchiveErrors.splice(index, 1)
  },
  [ADD_POST_ARCHIVE_PENDING] (state, value) {
    state.postArchivePendingIds.push(value)
  },
  [REMOVE_POST_ARCHIVE_PENDING] (state, index) {
    state.postArchivePendingIds.splice(index, 1)
  },
  [ADD_POST_ARCHIVE_ERROR] (state, value) {
    state.postArchiveErrors.push(value)
  },
  [REMOVE_POST_ARCHIVE_ERROR] (state, index) {
    state.postArchiveErrors.splice(index, 1)
  },
  [SET_SELECTED_DOCUMENTS] (state, selectedDocuments) {
    state.selectedDocuments = selectedDocuments
  },
  [SET_ALL_DOCUMENTS_AS_SELECTED] (state) {
    state.selectedDocuments.length === state.documents.children.filter(item => !item.specialFolder).length
      ? state.selectedDocuments = []
      : state.selectedDocuments = state.documents.children.filter(item => !item.specialFolder)
  },
  [SET_ARCHIVES_DOWNLOAD_PENDING] (state, value) {
    state.archivesDownloadPending = value
  },
  [REMOVE_DOCUMENT] (state, index) {
    state.selectedDocuments.splice(index, 1)
  },
}
