<template>
  <component :is="as" :class="computedClasses">
    <!-- @slot text to display -->
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import AppHeadline from '@/design-system/typography/AppHeadline.vue'

type RenderedAs = 'li' | 'p' | 'span'

export type TextVariant =
  | 'large-bold'
  | 'large-regular'
  | 'medium-bold'
  | 'medium-regular'
  | 'small-bold'
  | 'small-regular'
  | 'x-small-bold'
  | 'x-small-regular'
  | 'caption'
  | 'overline'
  | 'button-medium'
  | 'button-small'

/**
 * @displayName Body
 */
export default defineComponent({
  name: 'AppText',
  inheritAttrs: false,
  components: { AppHeadline },
  props: {
    /**
     * Indicate how the text should be rendered
     * Possible values are : p (rendered as \<p\>) and span (rendered as \<span\>)
     */
    as: {
      type: String as PropType<RenderedAs>,
      default: 'p',
    },
    /**
     * Text variant
     */
    variant: {
      type: String as PropType<TextVariant>,
      default: 'medium-regular',
    },
  },
  computed: {
    computedClasses (): string {
      switch (this.variant) {
        case 'large-bold': return 'text--large text--bold'
        case 'large-regular': return 'text--large text--regular'
        case 'medium-bold': return 'text--medium text--bold'
        case 'medium-regular': return 'text--medium text--regular'
        case 'small-bold': return 'text--small text--bold'
        case 'small-regular': return 'text--small text--regular'
        case 'x-small-bold': return 'text--x-small text--bold'
        case 'x-small-regular': return 'text--x-small text--regular'
        case 'caption': return 'text--caption'
        case 'overline': return 'text--overline'
        case 'button-medium': return 'text--button-medium'
        case 'button-small': return 'text--button-small'
        default: return 'text--medium text--regular'
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.text {
  &--bold {
    font-weight: 500;
  }
  &--regular {
    font-weight: 400;
  }

  &--large {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.15px;
  }
  &--medium {
    font-size: 16px;
    line-height: 22px;
  }
  &--small {
    font-size: 14px;
    line-height: 20px;
  }
  &--x-small {
    font-size: 12px;
    line-height: 17px;
  }

  &--caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
  }

  &--overline {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.5px;
  }

  &--button {
    &-medium {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    &-small {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
    }
  }
}
</style>

<docs>
```vue
<template>
  <div>
    <div v-for="text in texts" class="typo-presenter">
      <AppText class="typo-presenter__variant" :variant="text.variant">{{ text.text }}</AppText>

      <div class="typo-presenter__characteristics">
        <div class="typo-presenter__characteristic">
          <span class="typo-presenter__characteristic-name">Font</span>
          <span class="typo-presenter__characteristic-value">{{ text.font }}</span>
        </div>

        <div class="typo-presenter__characteristic">
          <span class="typo-presenter__characteristic-name">Weight</span>
          <span class="typo-presenter__characteristic-value">{{ text.weight }}</span>
        </div>

        <div class="typo-presenter__characteristic">
          <span class="typo-presenter__characteristic-name">Size</span>
          <span class="typo-presenter__characteristic-value">{{ text.size }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AppText from './AppText'

  export default {
    components: { AppText },
    data () {
      return {
        texts: [
          {
            variant: 'large-bold',
            text: 'Body Text Large Bold',
            font: 'Roboto',
            weight: 'Medium 500',
            size: '18px (1,125rem)'
          },
          {
            variant: 'large-regular',
            text: 'Body Text Large Regular',
            font: 'Roboto',
            weight: 'Regular 400',
            size: '18px (1,125rem)'
          },
          {
            variant: 'medium-bold',
            text: 'Body Text Medium Bold',
            font: 'Roboto',
            weight: 'Medium 500',
            size: '16px (1rem)'
          },
          {
            variant: 'medium-regular',
            text: 'Body Text Medium Regular',
            font: 'Roboto',
            weight: 'Regular 400',
            size: '16px (1rem)'
          },
          {
            variant: 'small-bold',
            text: 'Body Text Small Bold',
            font: 'Roboto',
            weight: 'Medium 500',
            size: '14px (0,875rem)'
          },
          {
            variant: 'small-regular',
            text: 'Body Text Small Regular',
            font: 'Roboto',
            weight: 'Regular 400',
            size: '14px (0,875rem)'
          },
          {
            variant: 'x-small-bold',
            text: 'Body Text X-Small Bold',
            font: 'Roboto',
            weight: 'Medium 500',
            size: '12px (0,75rem)'
          },
          {
            variant: 'x-small-regular',
            text: 'Body Text X-Small Regular',
            font: 'Roboto',
            weight: 'Regular 400',
            size: '12px (0,75rem)'
          },
          {
            variant: 'caption',
            text: 'Caption',
            font: 'Roboto',
            weight: 'Regular 400',
            size: '12px (0,75rem)'
          },
          {
            variant: 'overline',
            text: 'OVERLINE',
            font: 'Roboto',
            weight: 'Medium 500',
            size: '12px (0,75rem)'
          },
          {
            variant: 'button-medium',
            text: 'Button Medium',
            font: 'Roboto',
            weight: 'Medium 500',
            size: '14px (0,875rem)'
          },
          {
            variant: 'button-small',
            text: 'Button Small',
            font: 'Roboto',
            weight: 'Medium 500',
            size: '12px (0,75rem)'
          },
        ],
      }
    },
  }
</script>

<style lang="scss">
  .typo-presenter {
    width: 450px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .typo-presenter__variant {
    border-bottom: 1px solid #000;
    margin-bottom: 8px;
  }

  .typo-presenter__characteristics {
    display: flex;
  }

  .typo-presenter__characteristic {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-name {
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      color: var(--v-grey-lighten1);
    }
  }

  .typo-presenter__characteristic-name {
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    color: var(--v-grey-lighten1);
  }

  .typo-presenter__characteristic-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: var(--v-grey-base);
  }
</style>
```
</docs>
