import {
  SET_MINI,
  SET_IS_PENDO_LOADED,
  SET_APP_STATE,
} from './mutation_types'
import { AppStateEnum } from '../../../enums'

export const mutations = {
  [SET_APP_STATE] (state, value: AppStateEnum) {
    state.appState = value
  },
  [SET_IS_PENDO_LOADED] (state, value) {
    state.isPendoLoaded = value
  },
  [SET_MINI] (state, value) {
    state.mini = value
  },
}
