import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'

export function formatDraftEnvelopeTemplate (templateData): DraftEnvelopeTemplate {
  return {
    templateId: templateData.id,
    document: {
      id: templateData.file.id,
      objectToPost: {
        title: templateData.title,
        envelopeType: templateData.envelopeType,
        signatureType: `${templateData.provider}.${templateData.signatureType}`,
        shareType: templateData.shareType,
        withUsers: templateData.shareType === SHARE_TYPE_OPTIONS.CUSTOM
          ? templateData.rights.users.map(user => user.id)
          : [],
        signers: templateData.signatures.filter(s => s.signatureType === 'single').length,
      },
      name: templateData.file.name,
      extension: templateData.file.ext,
      size: templateData.file.size,
    },
  }
}
