<template>
  <ArchiveAddAccessBase :routeOptions="routeOptions">
    <template #default="{ isArchivingEnabled }">
      <v-list-item
        class="v-list-item--link"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <v-list-item-title :class="{ 'archive-add-access-list-item-title-badge-fix': !isArchivingEnabled }">
          <ArchiveAddAccess :disableTooltip="true" :showIcon="false" :routeOptions="routeOptions"></ArchiveAddAccess>
        </v-list-item-title>
      </v-list-item>
    </template>
  </ArchiveAddAccessBase>
</template>

<script>
import ArchiveAddAccess from './ArchiveAddAccess.vue'
import ArchiveAddAccessBase from './ArchiveAddAccessBase.vue'
export default {
  name: 'ArchiveAddAccessListItem',
  components: { ArchiveAddAccessBase, ArchiveAddAccess },
  inheritAttrs: false,
  props: {
    routeOptions: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss" scoped>
// We need to get some space for the badge that overlaps and gets out of the list-item bounds
// Those are pretty much magic numbers however...
.archive-add-access-list-item-title-badge-fix > ::v-deep* {
  margin-left: 9px;
  margin-top: 5px;
}
</style>
