<template>
  <div class="wopi-page">
    <iframe
      id="office_frame"
      name="office_frame"
      width="100%"
      height="100%"
      frameborder="0"
      allowfullscreen
    ></iframe>
    <form id="office_form" name="office_form" target="office_frame" :action="officeUrl" method="post">
      <input name="access_token" :value="accessToken" type="hidden" />
      <input name="access_token_ttl" :value="accessTokenTTL" type="hidden"/>
    </form>
  </div>
</template>

<script>
import { nextTick } from 'vue'

import WopiService from '../../services/wopi.service'

  export default {
    name: 'WopiPage',
    props: {
      mnemo: {
      type: String,
      required: true,
      },
      fileId: {
        type: String,
        required: true,
      },
      mode: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        officeUrl: '',
        accessToken: '',
        accessTokenTTL: '',
      }
    },
    async mounted () {
      const result = await WopiService.getWopiAccess(this.mnemo, this.fileId, this.mode)

      this.officeUrl = result.data.url
      this.accessToken = result.data.accessToken
      this.accessTokenTTL = result.data.accessTokenTTL
      nextTick().then(() => {
        document.querySelector('#office_form').submit()

        if (window.zE) {
          try {
            window.zE('messenger', 'hide')
          } catch (e) {
            console.error(e)
            console.info('zendesk hide fail')
          }
        }
      })
    },
  }
</script>
<style scoped>
.wopi-page {
    background:#FAFAFA;
    height:100vh;
}
</style>
