const VERSIONING_SOURCE_OPTIONS = {
  FROM_CLOSD: 'fromClosd',
  FROM_LOCAL: 'fromLocal',
  FROM_EXTERNAL: 'fromExternal',
} as const

type VersioningSourceOptions = typeof VERSIONING_SOURCE_OPTIONS[keyof typeof VERSIONING_SOURCE_OPTIONS]

const VERSIONING_EXTERNAL_PROVIDERS = {
  IMANAGE: 'imanage',
  POLY: 'poly',
} as const

type VersioningExternalProviders = typeof VERSIONING_EXTERNAL_PROVIDERS[keyof typeof VERSIONING_EXTERNAL_PROVIDERS]

export { VERSIONING_EXTERNAL_PROVIDERS, VERSIONING_SOURCE_OPTIONS, VersioningSourceOptions, VersioningExternalProviders }
