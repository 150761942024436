<template>
  <div class="qa-item-thread">
    <QuestionAnswerItemThreadNavigation :mnemo="mnemo" :question="question" />

    <AppCard elevation="0">
      <v-card-text class="qa-item-thread__content">
        <QuestionAnswerItemThreadQuestion :mnemo="mnemo" />

        <div v-if="question.answers.length > 0"
             class="qa-item-thread__answers"
        >
          <div class="qa-item-thread__answers-title">
            {{ $t("project.question-answer.QuestionAnswerItemThread.answers", { nbAnswers: question.answers.length }) }}
          </div>

          <v-timeline v-if="question.answers.length > 0" align-top dense>
              <QuestionAnswerItemThreadAnswer v-for="answer in question.answers"
                                              :key="`answer-${answer.id}`"
                                              :answer="answer"
                                              :mnemo="mnemo"
              />
          </v-timeline>
        </div>

        <div class="qa-item-thread__add-answer">
          <QuestionAnswerItemThreadAnswerAdd v-if="question.status !== 'validated'"
                                             ref="answerAddForm"
                                             :mnemo="mnemo"
          />
        </div>
      </v-card-text>
    </AppCard>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppCard from '@/common/AppCard'

import QuestionAnswerItemThreadAnswer from './QuestionAnswerItemThreadAnswer'
import QuestionAnswerItemThreadAnswerAdd from './QuestionAnswerItemThreadAnswerAdd'
import QuestionAnswerItemThreadNavigation from './QuestionAnswerItemThreadNavigation'
import QuestionAnswerItemThreadQuestion from './QuestionAnswerItemThreadQuestion'

export default {
  name: 'QuestionAnswerItemThread',
  components: {
    QuestionAnswerItemThreadNavigation,
    AppCard,
    QuestionAnswerItemThreadAnswerAdd,
    QuestionAnswerItemThreadAnswer,
    QuestionAnswerItemThreadQuestion,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('questionAnswer', ['question', 'markQuestionAsResolvedPending']),
    ...mapState('room', ['currentRoom']),
  },
}
</script>

<style scoped lang="scss">
.qa-item-thread {
  &__content {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-left: 90px;
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }

  &__answers {
    color: #333333;
    margin-top: 16px;

    &-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 24px;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--v-grey-lighten3);
    }
  }

  &__actions {
    margin-top: 20px;
    text-align: right;

    button:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__add-answer {
    margin-top: 24px;
  }
  .v-timeline:before {
    display: none;
  }
}
</style>
