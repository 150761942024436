import {
  SET_PROJECTS_FOLDER_MOVE_DIALOG_IS_OPEN,
  SET_PROJECTS_FOLDER_MOVE_PENDING,
  SET_PROJECTS_FOLDER_TO_MOVE,
} from './mutation_types'

export const mutations = {
  [SET_PROJECTS_FOLDER_MOVE_DIALOG_IS_OPEN] (state, value) {
    state.projectsFolderMoveDialogIsOpen = value
  },
  [SET_PROJECTS_FOLDER_MOVE_PENDING] (state, value) {
    state.projectsFolderMovePending = value
  },
  [SET_PROJECTS_FOLDER_TO_MOVE] (state, projectsFolderToMove) {
    state.projectsFolderToMove = projectsFolderToMove
  },
}
