<template>
    <div class="ChecklistTableTaskChat">
      <template v-if="taskCommentsPending">
        <div class="ChecklistTableTaskChat-bordered text-center">
          <AppLoader/>
        </div>
      </template>
      <template v-else>
        <div v-if="taskComments && taskComments.length > 0"
             class="ChecklistTableTaskChat-bordered px-2"
        >
          <div class="d-flex align-center justify-space-between">
            <div class="clickable app-link font-weight-bold grey--text text--darken-2"
                 @click="expandedToggle"
            >
              <span :class="{ 'is-awaiting-delete-validation': isAwaitingDeleteValidation }">
                {{ expanded ? $t('project.checklist.task.ChecklistTableTaskChat.displayLessComments') : $t('project.checklist.task.ChecklistTableTaskChat.displayMoreComments') }}
              </span>
              <font-awesome-icon class="expanded-icon ml-1"
                                 :class="{'expanded-icon-rotate': expanded}"
                                 :icon="['fas', 'caret-down']"
              ></font-awesome-icon>
            </div>
            <div class="d-flex align-center">
              <transition name="fade">
                <AppTextField v-show="searchModeEnabled"
                              ref="searchInput"
                              v-model.trim="searchQuery"
                              class="text--primary mr-2 overflow-x-hidden"
                              @blur="onSearchInputBlur"
                />
              </transition>
              <AppTooltip top>
                <template #activator="{on, attrs}">
                  <AppButton icon
                             :input-value="searchModeEnabled"
                             class="ChecklistTableTaskChat-searchButton"
                             v-bind="attrs"
                             v-on="on"
                             @click.stop="toggleSearch"
                  >
                    <font-awesome-icon :icon="['fal', 'search']"></font-awesome-icon>
                  </AppButton>
                </template>
                <span>{{ $t('project.checklist.task.ChecklistTableTaskChat.searchTooltip') }}</span>
              </AppTooltip>
              <AppDownloadFile :link="exportToPdfLink">
                <template #default="{submitForm}">
                  <AppTooltip top>
                    <template #activator="{on, attrs}">
                      <AppButton icon
                                 v-bind="attrs"
                                 v-on="on"
                                 @click="submitForm"
                      >
                        <font-awesome-icon :icon="['fal', 'file-pdf']"></font-awesome-icon>
                      </AppButton>
                    </template>
                    <span>{{ $t('project.checklist.task.ChecklistTableTaskChat.exportToPdfTooltip') }}</span>
                  </AppTooltip>
                </template>
              </AppDownloadFile>
            </div>
          </div>
        </div>
        <div v-if="taskComments && taskComments.length > 0"
             ref="commentsContainer"
             class="ChecklistTableTaskChat-commentsContainer ChecklistTableTaskChat-bordered d-flex flex-column overflow-y-auto px-2"
             :class="{'ChecklistTableTaskChat-commentsContainer--guest': !currentRoom.userRights.canCommentTasks}"
             style="transition: max-height .8s"
             :style="{'max-height': expanded ? '350px' : '62px'}"
        >
          <div class="text-center">
            <div v-if="taskComments && taskComments.length === commentsParams.nbMessages"
                 class="loadMoreComments app-link"
                 @click="loadMoreComments"
            >
              {{ $t('project.checklist.task.ChecklistTableTaskChat.loadMoreComments') }}
            </div>
          </div>
          <template v-for="comment in searchModeEnabled ? searchResults : taskComments">
            <div :key="`comment-${comment.id}`"
                 class="d-flex align-center">
              <div class="ChecklistTableTaskChat-comment"
                   :class="{'ChecklistTableTaskChat-comment--self': comment.userId === profile.id}"
              >
                <div class="ChecklistTableTaskChat-comment-userName"
                     v-html="comment.user ? (searchModeEnabled ? highlight(comment.user.fullName, searchQuery) : comment.user.fullName) : ''"
                ></div>
                <div v-html="searchModeEnabled ? highlight(comment.message, searchQuery) : comment.message"></div>
              </div>
              <div class="caption ml-2">{{getCommentDate(comment.createdAt)}}</div>
              <AppButton v-if="comment.userId === profile.id"
                         icon
                         class="ml-1"
                         @click="openDeleteTaskCommentDialog(comment)"
              >
                <font-awesome-icon :icon="['far', 'trash-can']"
                ></font-awesome-icon>
              </AppButton>
            </div>

          </template>
        </div>
      </template>
      <div>
        <VisualTextEditor v-if="currentRoom.userRights.canCommentTasks"
                          v-model="newComment"
                          :clean-content-on-blur="false"
                          clean-content-on-validation
                          counter="menubar"
                          :error-messages="newCommentErrors"
                          hide-menubar
                          keep-toolbar-on-blur
                          :max-length="NEW_COMMENT_MAX_LENGTH"
                          :one-line="$vuetify.breakpoint.mdAndUp"
                          :placeholder="$t('project.checklist.task.ChecklistTableTaskChat.newCommentPlaceholder')"
                          send-button
                          :taggable-groups="taggableGroups"
                          :taggable-members="taggableMembers"
                          @focus="expand"
                          @sendClicked="preparePostTaskComment"
        />
      </div>
      <AppDialog :is-open="deleteTaskCommentDialogIsOpen"
                 :ok-text="$t('common.delete')"
                 :ok-loading="deleteTaskCommentPending"
                 :ok-disabled="deleteTaskCommentPending"
                 :cancel-disabled="deleteTaskCommentPending"
                 @cancel="closeDeleteTaskCommentDialog"
                 @ok="prepareDeleteComment"
      >
        <template #title>{{$t('project.checklist.task.ChecklistTableTaskChat.deleteTaskCommentDialogTitle')}}</template>
        <template #body>{{$t('project.checklist.task.ChecklistTableTaskChat.deleteTaskCommentDialogText')}}</template>
      </AppDialog>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import AppLoader from '@/common/AppLoader'
import AppTextField from '@/common/AppTextField'
import AppTooltip from '@/common/AppTooltip'
import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import { ISOToShortDate } from '@/common/utils/dates'
import { escapeHtml } from '@/common/utils/strings'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import { GET_TASK_COMMENTS, POST_TASK_COMMENT, DELETE_TASK_COMMENT } from '@/store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

const NEW_COMMENT_MAX_LENGTH = 5000

export default {
  name: 'ChecklistTableTaskChat',
  components: {
    AppDialog,
    AppDownloadFile,
    AppTextField,
    VisualTextEditor,
    AppLoader,
    AppButton,
    AppTooltip,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      taskComments: null,
      newComment: '',
      expanded: false,
      commentsParams: {
        numPage: 1,
        nbMessages: 5,
      },
      deleteTaskCommentDialogIsOpen: false,
      commentToDelete: null,
      searchModeEnabled: false,
      searchQuery: '',
      NEW_COMMENT_MAX_LENGTH,
    }
  },
  computed: {
    ...mapState('checklist', ['taskCommentsPendingIds', 'deleteTaskCommentPendingIds']),
    ...mapState('user', ['profile']),
    ...mapState('groups', ['groups']),
    ...mapState('room', ['currentRoom']),
    exportToPdfLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/todoTask/${this.item.id}/comments-pdf`
    },
    taskCommentsPending () {
      return this.taskCommentsPendingIds.includes(this.item.id)
    },
    deleteTaskCommentPending () {
      return this.deleteTaskCommentPendingIds.includes(this.item.id)
    },
    searchResults () {
      return this.taskComments.filter(comment => {
        if (comment.message.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          return comment
        } else if (comment.user && comment.user.fullName.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          return comment
        }
      })
    },
    taggableMembers () {
      if (Array.isArray(this.item.taggableUsers)) {
        const taggableMembers = []
        for (const group of this.groups) {
          for (const member of group.members) {
            if (this.item.taggableUsers.includes(member.id)) {
              const memberCopy = cloneDeep(member)
              memberCopy.value = memberCopy.fullName
              taggableMembers.push(memberCopy)
            }
          }
        }
        return taggableMembers
      }
      return null
    },
    taggableGroups () {
      if (Array.isArray(this.item.taggableGroups)) {
        const taggableGroups = []
        for (const group of this.groups) {
          if (this.item.taggableGroups.includes(group.id)) {
            const groupCopy = cloneDeep(group)
            groupCopy.value = groupCopy.name
            taggableGroups.push(groupCopy)
          }
        }
        return taggableGroups
      }
      return null
    },
    newCommentLength () {
      return escapeHtml(this.newComment).length
    },
    newCommentIsTooLong () {
      return this.newCommentLength > this.NEW_COMMENT_MAX_LENGTH
    },
    newCommentErrors () {
      const errors = []

      if (this.newCommentIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    isAwaitingDeleteValidation () {
      return this.item.isAwaitingValidation
    },
  },
  mounted () {
    this.prepareGetTaskComments()
  },
  methods: {
    ...mapActions('checklist', [GET_TASK_COMMENTS, POST_TASK_COMMENT, DELETE_TASK_COMMENT]),
    async prepareGetTaskComments (scroll = true) {
      try {
        this.taskComments = await this.GET_TASK_COMMENTS({
          mnemo: this.mnemo,
          taskId: this.item.id,
          params: this.commentsParams,
        })
        if (scroll) {
          this.$nextTick(() => {
            this.scrollToCommentsContainerEnd()
          })
        }
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.task.ChecklistTableTaskChat.getTaskCommentsError'),
        })
      }
    },
    async preparePostTaskComment () {
      if (this.newComment) {
        try {
          await this.POST_TASK_COMMENT({
            mnemo: this.mnemo,
            taskId: this.item.id,
            data: {
              comment: this.newComment,
            },
          })
          this.newComment = ''
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.checklist.task.ChecklistTableTaskChat.postTaskCommentSuccess'),
          })
          this.prepareGetTaskComments()
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.task.ChecklistTableTaskChat.postTaskCommentError'),
          })
        }
      }
    },
    getCommentDate (date) {
      return ISOToShortDate(date)
    },
    loadMoreComments () {
      this.commentsParams.nbMessages += 5
      this.prepareGetTaskComments(false)
    },
    scrollToCommentsContainerEnd () {
      if (this.$refs.commentsContainer) {
        setTimeout(() => {
          this.$refs.commentsContainer && this.$refs.commentsContainer.scrollTo(0, this.$refs.commentsContainer.scrollHeight)
        }, 850)
      }
    },
    expandedToggle () {
      this.expanded = !this.expanded
      this.scrollToCommentsContainerEnd()
    },
    expand () {
      this.expanded = true
      this.scrollToCommentsContainerEnd()
    },
    async prepareDeleteComment () {
      try {
        await this.DELETE_TASK_COMMENT({
          mnemo: this.mnemo,
          taskId: this.item.id,
          commentId: this.commentToDelete.id,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.task.ChecklistTableTaskChat.deleteTaskCommentSuccess'),
        })
        this.closeDeleteTaskCommentDialog()
        this.prepareGetTaskComments()
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.task.ChecklistTableTaskChat.deleteTaskCommentError'),
        })
      }
    },
    openDeleteTaskCommentDialog (comment) {
      this.commentToDelete = comment
      this.deleteTaskCommentDialogIsOpen = true
    },
    closeDeleteTaskCommentDialog () {
      this.commentToDelete = null
      this.deleteTaskCommentDialogIsOpen = false
    },
    toggleSearch () {
      this.searchModeEnabled = !this.searchModeEnabled
      this.searchQuery = ''
      if (this.searchModeEnabled) {
        this.$nextTick(() => {
          this.$refs.searchInput.$refs.input.focus()
        })
      }
    },
    onSearchInputBlur (e) {
      if (e.relatedTarget) {
        if (!e.relatedTarget.classList.contains('ChecklistTableTaskChat-searchButton')) {
          this.searchModeEnabled = false
          this.searchQuery = ''
        }
      } else {
        this.searchModeEnabled = false
        this.searchQuery = ''
      }
    },
    highlight (string, substring) {
      if (!substring) {
        return string
      } else {
        const reg = new RegExp('(' + substring + ')', 'gi')
        return string.replace(reg, '<span style="background-color:#e5ff00; color: #333;">$1</span>')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableTaskChat-comment {
  background-color: #2196f3;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 14px;
  word-break: break-word;
  &--self {
    background-color: var(--v-primary-base);
  }
  ::v-deep p {
    margin-bottom: 0 !important;
  }
  ::v-deep span[data-mention] {
    color: #333;
    font-weight: bold;
  }
}
.ChecklistTableTaskChat-comment-userName {
  font-weight: bold;
}
.expanded-icon {
  transition: transform .5s;
}
.expanded-icon-rotate {
  transform: rotate(180deg);
}
::v-deep .ChecklistTableTaskChat-searchButton.v-btn--active {
  color: var(--v-primary-base);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .8s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.ChecklistTableTaskChat-bordered {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  &:first-child {
    border-top: 1px solid #e0e0e0;
  }
}
.loadMoreComments {
  color: var(--v-grey-darken2);
  &:hover {
    color: var(--v-primary-base) !important;
  }
}
.ChecklistTableTaskChat-commentsContainer--guest {
  border-bottom: 1px solid #ddd;
}

.is-awaiting-delete-validation {
  color: var(--v-grey-lighten3) !important;

  .expanded-icon {
    color: var(--v-grey-lighten3) !important;
  }
}
</style>
