<template>
  <div class="mb-2 group-container">
    <div class="py-2 pl-4 group-row"
         :style="gridStyles"
    >
      <span class="font-weight-bold group-name">{{ group.name }}</span>
      <div v-if="hasSignersOrder"></div>
      <div v-if="hasWitnesses"></div>
      <template v-if="isForMultiEnvelope">
        <v-checkbox
          dense
          hide-details
          readonly
          class="mx-auto pt-0 mt-0 group-signer-checkbox"
          :input-value="allMembersChecked('signers')"
          :indeterminate="someMembersChecked('signers')"
          :disabled="isMultiEnvelopeSignerGroupDisabled"
          @click="toggleAll('signers')"
        >
        </v-checkbox>
        <v-checkbox
          dense
          hide-details
          readonly
          class="mx-auto pt-0 mt-0 group-unique-signer-checkbox"
          :input-value="allMembersChecked('uniqueSigners')"
          :indeterminate="someMembersChecked('uniqueSigners')"
          :disabled="isMultiEnvelopeUniqueSignerGroupDisabled"
          @click="toggleAll('uniqueSigners')"
        >
        </v-checkbox>
      </template>
      <template v-else>
        <v-checkbox
          dense
          hide-details
          class="mx-auto pt-0 mt-0 group-signer-checkbox"
          :input-value="allMembersChecked('signers')"
          :indeterminate="someMembersChecked('signers')"
          :disabled="!enableSigners || recipientsAndObserversOnly || observersOnly || disabled"
          @change="toggleAll('signers')"
        >
        </v-checkbox>
      </template>
      <v-checkbox
        dense
        hide-details
        class="mx-auto pt-0 mt-0 group-validator-checkbox"
        :input-value="allMembersChecked('validators')"
        :indeterminate="someMembersChecked('validators')"
        :disabled="!enableValidators && (recipientsAndObserversOnly || observersOnly) || disabled"
        @change="toggleAll('validators')"
      >
      </v-checkbox>
      <v-checkbox
        dense
        hide-details
        class="mx-auto pt-0 mt-0 group-recipient-checkbox"
        :input-value="allMembersChecked('recipients')"
        :indeterminate="someMembersChecked('recipients')"
        :disabled="observersOnly || disabled"
        @change="toggleAll('recipients')"
        >
      </v-checkbox>
      <v-checkbox
        dense
        hide-details
        class="mx-auto pt-0 mt-0 group-observer-checkbox"
        :input-value="allMembersChecked('observers')"
        :indeterminate="someMembersChecked('observers')"
        :disabled="group.isPm || disabled"
        @change="toggleAll('observers')"
      >
      </v-checkbox>
    </div>
      <EnvelopeUsersSelectorUser v-for="user in group.members"
                                 :key="user.id"
                                 :user="user"
                                 :step="step"
                                 :has-witnesses="hasWitnesses"
                                 :recipients-and-observers-only="recipientsAndObserversOnly"
                                 :enable-signers="enableSigners"
                                 :observers-only="observersOnly"
                                 :enable-validators="enableValidators"
                                 :is-pm-group="group.isPm"
                                 :has-signers-order="hasSignersOrder"
                                 :grid-styles="gridStyles"
                                 :is-for-multi-envelope="isForMultiEnvelope"
                                 @toggle="toggle($event.id, $event.list)"
      />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import EnvelopeUsersSelectorUser from '@/common/users/envelopeUsersSelector/EnvelopeUsersSelectorUser.vue'
import { SIGNATURE_TYPE_DOCUSIGN_QUALIFIED } from '@/project/signing-checklist/constants'
import { UNSELECT_SIGNER_WITNESSES } from '@/store/modules/signing-checklist/action_types'

const SIGNERS_LISTS = Object.freeze(['signers', 'uniqueSigners'])

export default {
  name: 'EnvelopeUsersSelectorGroup',
  components: { EnvelopeUsersSelectorUser },
  props: {
    step: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    hasSignersOrder: {
      type: Boolean,
      required: true,
    },
    gridStyles: {
      type: Object,
      default: null,
      validator: (value) => { return typeof value === 'object' || value === null },
    },
    isForMultiEnvelope: {
      type: Boolean,
      required: true,
    },
    hasWitnesses: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    recipientsAndObserversOnly () {
      if (this.step.hasOwnProperty('envelope')) {
        return this.step.envelope.status === 'sent' ||
          this.step.envelope.status === 'queued' ||
          this.step.envelope.status === 'unvalidated'
      }
      return false
    },
    observersOnly () {
      if (this.step.hasOwnProperty('envelope')) {
        return this.step.envelope.status === 'signed' ||
          this.step.envelope.status === 'voided'
      }
      return false
    },
    enableValidators () {
      if (this.step.hasOwnProperty('envelope')) {
        return this.step.envelope.status !== 'signed' &&
          this.step.envelope.status !== 'voided' &&
          this.step.envelope.status !== 'sent' &&
          this.step.envelope.status !== 'unvalidated'
      }
      return false
    },
    enableSigners () {
      if (this.step.hasOwnProperty('envelope')) {
        return this.step.envelope.status === 'draft'
      }
      return true
    },
    flattenedSigners () {
      return this.step?.signers?.flat()
    },
    isQualifiedSignature () {
      return this.step.signatureType === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED
    },
    isMultiEnvelopeSignerGroupDisabled () {
      return !!this.allMembersChecked('uniqueSigners') || (!this.enableSigners || this.recipientsAndObserversOnly || this.observersOnly || this.disabled)
    },
    isMultiEnvelopeUniqueSignerGroupDisabled () {
      return !!this.allMembersChecked('signers') || (!this.enableSigners || this.recipientsAndObserversOnly || this.observersOnly || this.disabled)
    },
    allCurrentWitnesses () {
      if (this.step.witnesses) {
        return this.step.witnesses.flatMap(signerWithWitnesses => signerWithWitnesses.witness)
      }

      return []
    },
  },
  methods: {
    ...mapActions('signingChecklist', [UNSELECT_SIGNER_WITNESSES]),
    isSelected (id, list) {
      if (list === 'signers') {
        return this.step?.signers?.some(group => {
          return group.some(userId => userId === id)
        })
      }
      return this.step[list]?.includes(id)
    },
    allMembersChecked (list) {
      if (this.group.members.length === 0) {
        return false
      }
      if (list === 'signers') {
        return this.group.members.every((user) => this.flattenedSigners?.includes(user.id))
      }
      return this.group.members.every((user) => this.step[list]?.includes(user.id))
    },
    someMembersChecked (list) {
      if (list === 'signers') {
        return this.group.members.some((user) => this.flattenedSigners?.includes(user.id)) && !this.allMembersChecked(list)
      }
      return this.group.members.some((user) => this.step[list]?.includes(user.id)) && !this.allMembersChecked(list)
    },
    toggle (id, list) {
      if (!this.isSelected(id, list)) {
        if (list === 'signers') {
          if (this.allCurrentWitnesses.includes(id)) {
            // disallow user selection as signer if user is already witness
            return
          }
        }

        if (list === 'signers' && Array.isArray(this.step.signers[0])) {
          if (this.isQualifiedSignature && this.step.signers.length > 1) {
            this.step.signers.push([id])
          } else {
            this.step.signers[this.step.signers.length - 1].push(id)
          }
          if (this.step?.witnesses) {
            this.toggleWitness(id)
          }
        } else {
          this.step[list].push(id)
        }

        if (SIGNERS_LISTS.includes(list)) {
          if (!this.isSelected(id, 'recipients')) {
            this.toggle(id, 'recipients')
          }
          if (!this.isSelected(id, 'observers')) {
            this.toggle(id, 'observers')
          }
        }

        if (list === 'validators') {
          if (!this.isSelected(id, 'observers')) {
            this.toggle(id, 'observers')
          }
        }
      } else {
        if (list === 'observers' && (this.isSelected(id, 'validators') || this.isSelected(id, 'signers'))) {
          // Nothing to do here
          return
        }

        if (list === 'signers' && this.step.witnesses?.some(signerAndWitnesses => signerAndWitnesses.signer === id)) {
          this.UNSELECT_SIGNER_WITNESSES({
            importedFileId: this.step.fileId,
            signerId: id,
          })
        }

        if (list === 'signers' && Array.isArray(this.step.signers[0])) {
          const groupIndex = this.step.signers.findIndex(group => group.some(signer => signer === id))
          const group = this.step.signers[groupIndex]
          group.splice(group.findIndex(signer => signer === id), 1)
          if (!group.length && this.step.signers.length > 1) {
            this.step.signers.splice(groupIndex, 1)
          }

          if (this.step?.witnesses) {
            this.toggleWitness(id)
          }
        } else {
          this.step[list].splice(this.step[list].indexOf(id), 1)
        }

        if (SIGNERS_LISTS.includes(list)) {
          if (this.isSelected(id, 'recipients')) {
            this.toggle(id, 'recipients')
          }
          // We never remove PMs from the observers
          if (this.isSelected(id, 'observers') && !this.group.isPm) {
            this.toggle(id, 'observers')
          }
        }

        if (list === 'validators') {
          // We never remove PMs from the observers
          if (this.isSelected(id, 'observers') && !this.group.isPm) {
            this.toggle(id, 'observers')
          }
        }
      }
    },
    toggleAll (list) {
      if (this.isForMultiEnvelope) {
        this.toggleAllForMultiEnvelope(list)
        return
      }
      if (this.allMembersChecked(list)) {
        for (const user of this.group.members) {
          // Because everything is selected, we can toggle them all to deselect them
          this.toggle(user.id, list)
        }
      } else {
        for (const user of this.group.members) {
          // We only want to touch those that are not selected so that they are
          if (!this.isSelected(user.id, list)) {
            this.toggle(user.id, list)
          }
        }
      }
    },
    toggleAllForMultiEnvelope (list) {
      const isSignersList = SIGNERS_LISTS.includes(list)
      const allChecked = isSignersList ? this.allMembersCheckedForSignersList(list) : this.allMembersChecked(list)
      if (allChecked) {
        for (const user of this.group.members) {
          // Because everything is selected, we can toggle them all to deselect them
          if (this.isSelected(user.id, list)) {
            this.toggle(user.id, list)
          }
        }
      } else {
        if (isSignersList) {
          const otherList = list === 'signers' ? 'uniqueSigners' : 'signers'
          for (const user of this.group.members) {
            // We only want to touch those that are not selected so that they are
            if (!this.isSelected(user.id, list) && !this.isSelected(user.id, otherList)) {
              this.toggle(user.id, list)
            }
          }
        } else {
          for (const user of this.group.members) {
            // We only want to touch those that are not selected so that they are
            if (!this.isSelected(user.id, list)) {
              this.toggle(user.id, list)
            }
          }
        }
      }
    },
    allMembersCheckedForSignersList () {
      return this.group.members.every((user) => this.step.uniqueSigners?.includes(user.id) || this.flattenedSigners?.includes(user.id))
    },
    toggleWitness (id) {
      if (this.step?.witnesses?.flatMap(w => w.witness)?.find(w => w !== undefined)) {
        this.step.witnesses = this.step.witnesses.filter(w => w.signer !== id)
      } else {
        this.step.witnesses = this.step.witnesses.filter(w => w.witnessOfSignerId !== id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-row,
.group-row {
  display: grid;
  grid-gap: 10px;
}

.group-row {
  background-color: #F5F5F5;
}

.user-row {
  border-top: none;
}

.group-name, .user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
