import { StoreActions } from '@/types/store'

import { groupsActionTypes } from './action_types'
import { GroupsMutationTypes } from './mutation_types'
import groupsService from '../../../services/groups.service'

export const actions: StoreActions<groupsActionTypes, GroupsMutationTypes> = {
  PATCH_IS_HIDDEN_PM: async ({ commit }, { mnemo, userId, data }) => {
    commit('SET_PATCH_IS_HIDDEN_PM_PENDING', true)
    try {
      await groupsService.patchIsHiddenPm(mnemo, userId, data)
    } catch (error) {
      commit('SET_PATCH_IS_HIDDEN_PM_ERROR', error)
      throw error
    } finally {
      commit('SET_PATCH_IS_HIDDEN_PM_PENDING', false)
    }
  },
  GET_GROUPS: async ({ commit }, mnemo) => {
    commit('SET_LOADING', true)
    commit('SET_GROUPS', [])
    try {
      const response = await groupsService.getGroups(mnemo)
      commit('SET_GROUPS', response.data.sort((a, b) => {
        if (a.isPm) {
          return -1
        } else if (b.isPm) {
          return 1
        }
        return 0
      }))
      return response
    } catch (e) {
      commit('SET_ERROR', e)
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },
  POST_GROUP: async ({ commit, dispatch }, { mnemo, groupName }) => {
    commit('SET_POST_GROUP_LOADING', true)
    try {
      const response = await groupsService.postGroup(mnemo, groupName)
      dispatch('GET_GROUPS', mnemo)
      return response
    } finally {
      commit('SET_POST_GROUP_LOADING', false)
    }
  },
  DELETE_GROUP: async ({ commit, dispatch }, { mnemo, groupId }) => {
    commit('SET_DELETE_GROUP_LOADING', true)
    try {
      const response = await groupsService.deleteGroup(mnemo, groupId)
      dispatch('GET_GROUPS', mnemo)
      return response
    } finally {
      commit('SET_DELETE_GROUP_LOADING', false)
    }
  },
  PATCH_HIDE_GROUPS: async ({ commit, rootGetters }, { hideGroupsStatus }) => {
    commit('SET_PATCH_HIDE_GROUPS_LOADING', true)
    return groupsService.patchHideGroups(rootGetters['room/roomMnemo'], hideGroupsStatus)
      .then(() => {
        commit('SET_PATCH_HIDE_GROUPS_LOADING', false)
      })
  },
  PATCH_GROUP: async ({ rootGetters }, { groupId, data }) => {
    return groupsService.patchGroup(rootGetters['room/roomMnemo'], groupId, data)
  },
  POST_MEMBER: async ({ rootGetters }, { data, params }) => {
    return groupsService.postMember(rootGetters['room/roomMnemo'], data, params)
  },
  DELETE_MEMBER: async ({ rootGetters }, { memberId, params }) => {
    return groupsService.deleteMember(rootGetters['room/roomMnemo'], memberId, params)
  },
  CHASE_MEMBER: async ({ rootGetters }, memberId) => {
    return groupsService.chaseMember(rootGetters['room/roomMnemo'], memberId)
  },
  POST_MEMBER_FROM_TEAM: async ({ commit }, { mnemo, data }) => {
    commit('SET_POST_MEMBER_FROM_TEAM_LOADING', true)
    try {
      return await groupsService.postMemberFromTeam(mnemo, data)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit('SET_POST_MEMBER_FROM_TEAM_LOADING', false)
    }
  },
  PATCH_USER_ACCESS: async ({ rootGetters }, { memberId, data }) => {
    return groupsService.patchUserAccess(rootGetters['room/roomMnemo'], memberId, data)
  },
  POST_MASS_IMPORT: async ({ commit, rootGetters }, data) => {
    commit('SET_POST_MASS_LOADING', true)
    try {
      return await groupsService.postMassImport(rootGetters['room/roomMnemo'], data)
    } finally {
      commit('SET_POST_MASS_LOADING', false)
    }
  },
  SEND_GLOBAL_REMINDER: async ({ commit }, { mnemo }) => {
    commit('SEND_GLOBAL_REMINDER_LOADING', true)
    try {
      await groupsService.sendGlobalReminder(mnemo)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit('SEND_GLOBAL_REMINDER_LOADING', false)
    }
  },
  POST_USER_MISSED_ACCESS: async (store, { mnemo, data }) => {
    return groupsService.postUserMissedAccess(mnemo, data)
  },
  GET_USER_PROFILE: async (store, { mnemo, userId }) => {
    return groupsService.getUserProfile(mnemo, userId)
  },
}
