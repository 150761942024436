<template>
  <v-combobox ref="input"
              v-model="computedValue"
              outlined
              :hide-details="hideDetails"
              dense
              clear-icon="fal fa-xmark"
              :color="color"
              v-bind="$attrs"
              autocomplete="no-autocomplete-please-chrome"
              v-on="$listeners"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <slot :name="slot" />
    </template>
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
  </v-combobox>
</template>

<script>
/**
 * Default Combobox
 * @displayName AppCombobox
 */
/*
 * A wrapper around the combobox with default styling and custom default behavior
 * All Vuetify props for the combobox component are usable.
 * See doc : https://vuetifyjs.com/en/api/v-combobox/#props
 */
export default {
  name: 'AppCombobox',
  props: {
    /**
     * @model
     * Value of combobox
     */
    value: {
      required: true,
    },
    /**
     * Color variant of the combobox
     * @default blue darken-2
     */
    color: {
      default: 'blue darken-2',
    },
    /**
     * Hide space under combobox for detailed message
     * @default auto
     */
    hideDetails: {
      default: 'auto',
    },
  },
  computed: {
    computedValue: {
      get () {
        return this.value
      },
      /**
       * Gets called when the user type in combobox
       * @event input
       * @type String
       */
      set (newValue) {
        this.$emit('input', newValue)
      },
    },
  },
}
</script>

<docs>
```vue
<template>
  <AppCombobox :value="value"
               :items="['item 1', 'item 2', 'item 3']"
               label="Combobox"
  >
  </AppCombobox>
</template>
<script>
  export default {
    data () {
      return {
        value: ''
      }
    },
  }
</script>
```
</docs>
