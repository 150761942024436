<template>
  <ul class="documents-selection-list">
    <li v-for="document in selectedDocuments"
        :key="`document-${document.type}-${document.id}`"
        class="documents-selection-list-item"
    >
      <slot>
        <DocumentSelectionListItem :document="document"
                                   :removable="removable"
                                   @remove="onRemove"
        />
      </slot>
    </li>
  </ul>
</template>

<script>
import DocumentSelectionListItem from '@/common/document-selection/DocumentSelectionListItem'

export default {
  name: 'DocumentSelectionList',
  components: {
    DocumentSelectionListItem,
  },
  emits: ['remove'],
  props: {
    removable: {
      type: Boolean,
    },
    selectedDocuments: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onRemove (documentId) {
      this.$emit('remove', documentId)
    },
  },
}
</script>

<style lang="scss" scoped>
.documents-selection {
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;

    &-item {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
