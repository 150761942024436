<template>
  <div>
    <div v-if="selfSavedChecklistsPending" class="text-center">
      <AppLoader/>
    </div>
    <template v-else>
      {{ $t('project.checklist.dialogs.SaveChecklistDialog.chooseModel') }}

      <app-text variant="small-bold" class="mt-6 mb-2">
        {{ $t('project.checklist.dialogs.SaveChecklistDialog.modelLists') }}
      </app-text>

      <AppSelect v-if="selfSavedChecklists.length"
                  ref="checklistsSelect"
                  v-model="selectedSavedChecklist"
                  :items="selfSavedChecklists"
                  :placeholder="$t('project.checklist.dialogs.SaveChecklistDialog.searchModel')"
                  item-text="name"
                  item-value="id"
                  return-object
                  noLabel
      />
      <div v-else>
        <i18n path="project.checklist.dialogs.ImportChecklistDialog.noSavedChecklists" tag="span">
          <template #action>
            <a href="#" @click="goToNewTemplate">
              {{ $t('project.checklist.dialogs.ImportChecklistDialog.noSavedChecklistsAction')}}
            </a>
          </template>
        </i18n>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import AppLoader from '@/common/AppLoader.vue'
import AppSelect from '@/common/AppSelect.vue'
import { GET_SELF_SAVED_CHECKLISTS } from '@/store/modules/checklist/action_types'
import { SET_SELECTED_SELF_SAVED_CHECKLIST } from '@/store/modules/checklist/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

  export default {
    name: 'SavedChecklistTemplatesList',
    components: {
      AppLoader,
      AppSelect,
    },
    data () {
      return {
        selectedSavedChecklist: null,
      }
    },
    computed: {
      ...mapState('checklist', [
        'selfSavedChecklists',
        'selfSavedChecklistsPending',
        'selectedSelfSavedChecklist',
      ]),
    },
    watch: {
      selectedSavedChecklist (value) {
        this.SET_SELECTED_SELF_SAVED_CHECKLIST(value)
      },
    },
    created () {
      if (!this.selfSavedChecklists.length) {
        this.loadData()
      }
      if (this.selectedSelfSavedChecklist) {
        this.selectedSavedChecklist = this.selectedSelfSavedChecklist
      }
    },
    methods: {
      ...mapActions('checklist', [GET_SELF_SAVED_CHECKLISTS]),
      ...mapMutations('checklist', [SET_SELECTED_SELF_SAVED_CHECKLIST]),
      ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
      async loadData () {
        try {
          await this.GET_SELF_SAVED_CHECKLISTS()
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.checklist.dialogs.ImportChecklistDialog.getSavedChecklistsError'))
        }
      },
      goToNewTemplate () {
        this.$emit('go-to-new-template')
      },
    },
  }
</script>
