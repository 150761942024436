<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center">
        <SearchTextField :value="searchQuery"
                         :tooltipText="$t('project.question-answer.QuestionAnswerTopBar.search')"
                         style="min-width: 250px;"
                         @input="$emit('update:searchQuery', $event)"
        />
        <AppButton color="white"
                   :height="40"
                   @click="openFiltersPane"
        >
          <template v-if="filtersCount === 0">
            <font-awesome-icon :icon="['far', 'sliders']" class="mr-2"></font-awesome-icon>
          </template>
          <template v-else-if="filtersCount > 0">
            <v-avatar color="primary"
                      class="white--text caption mr-2"
                      size="19"
            >
              {{ filtersCount }}
            </v-avatar>
          </template>
          {{ $t('project.question-answer.QuestionAnswerTopBar.filters') }}
        </AppButton>
      </div>

      <div class="d-flex align-center">
        <AppDownloadFile :link="exportToExcelLink" class="mr-4">
          <template #default="{ submitForm }">
            <AppTooltip top>
              <template #activator="{attrs, on}">
                <AppButton :height="40"
                           color="white"
                           :attrs="attrs"
                           v-on="on"
                           @click="submitForm">
                  {{$t("project.question-answer.QuestionAnswerTopBar.exportToExcel") }}
                </AppButton>
              </template>
              <span>{{ $t('project.question-answer.QuestionAnswerTopBar.exportToExcelTooltip') }}</span>
            </AppTooltip>
          </template>
        </AppDownloadFile>

        <QuestionAnswerTopBarAdd @open-import-excel-dialog="$emit('open-import-excel-dialog', $event)"/>
      </div>
    </div>

    <DataTableFiltersSummary v-if="filtersCount > 0"
                             :active-filters="filters"
                             class="mt-4"
                             @clear-filter="clearFilter"
                             @clear-all-filters="clearAllFilters"
    />

    <QuestionAnswerImportDialog v-if="importDialogIsOpen"
                                :mnemo="mnemo"
                                v-on="$listeners"
                                @close="importDialogIsOpen = false"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import DataTableFiltersSummary from '@/common/filters/DataTableFiltersSummary.vue'
import SearchTextField from '@/common/SearchTextField.vue'
import { QuestionAnswerMutationTypes } from '@/store/modules/question-answer/mutation_types'

import QuestionAnswerImportDialog from './QuestionAnswerImportDialog.vue'
import QuestionAnswerTopBarAdd from './QuestionAnswerTopBarAdd.vue'

export default {
  name: 'QuestionAnswerTopBar',
  components: {
    AppButton,
    AppDownloadFile,
    AppTooltip,
    DataTableFiltersSummary,
    QuestionAnswerImportDialog,
    SearchTextField,
    QuestionAnswerTopBarAdd,
  },
  props: {
    searchQuery: {
      type: String,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      importDialogIsOpen: false,
    }
  },
  computed: {
    ...mapState('questionAnswer', ['filters']),
    filtersCount () {
      return this.filters.length
    },
    exportToExcelLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.$route.params.mnemo}/qa/export-excel`
    },
  },
  methods: {
    ...mapMutations('questionAnswer', [
      QuestionAnswerMutationTypes.REMOVE_FILTER,
      QuestionAnswerMutationTypes.SET_FILTERS,
      QuestionAnswerMutationTypes.SET_FILTERS_PANE_IS_OPEN,
    ]),
    openFiltersPane () {
      this.SET_FILTERS_PANE_IS_OPEN(true)
    },
    clearFilter (filter) {
      this.REMOVE_FILTER(filter)
    },
    clearAllFilters () {
      this.SET_FILTERS([])
      this.$emit('update:searchQuery', '')
    },
  },
}
</script>
