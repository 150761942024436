<template>
  <v-container fluid class="py-4 px-0">
    <template v-if="rooms && rooms.asPM.length > 0">
      <ProjectsTable :projects="rooms.asPM" />
    </template>
    <template v-if="rooms && rooms.asGuest.length > 0">
      <v-row v-if="rooms.asPM.length > 0">
        <v-col>
          <!-- We want to share the same style and margins as the pm subtitle from the projectheader component   -->
          <div class="ProjectsHeader-subtitle my-7">{{$t('projects.ProjectsTableTemplate.guest', { projectsCount: rooms.asGuest.length })}}</div>
        </v-col>
      </v-row>
      <ProjectsTable :projects="rooms.asGuest" hide-members />
    </template>
  </v-container>
</template>

<script>
import ProjectsTable from './table/ProjectsTable'

export default {
  name: 'ProjectsTableTemplate',
  components: { ProjectsTable },
  props: {
    rooms: {
      required: true,
      validator: (item) => typeof item === 'object' || item === null,
    },
  },
}
</script>

<style scoped>
  .ProjectsHeader-subtitle {
    font-size: 18px;
    font-weight: 600;
  }
</style>
