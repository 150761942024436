<template>
 <AppDialog :is-open="isOpen"
            :ok-title="$t('common.delete')"
            :ok-loading="deleteQuestionPending"
            :ok-disabled="deleteQuestionPending"
            :cancel-disabled="deleteQuestionPending"
            @ok="prepareDeleteQuestion"
            @cancel="closeDialog"
 >
   <template #title>{{$t('project.question-answer.QuestionAnswerQuestionDeleteDialog.title')}}</template>
   <template #body>
     <div class="text--primary" v-html="$t('project.question-answer.QuestionAnswerQuestionDeleteDialog.text')"></div>
   </template>
 </AppDialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import { QuestionAnswerActionTypes } from '@/store/modules/question-answer/action_types'
import { QuestionAnswerMutationTypes } from '@/store/modules/question-answer/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'QuestionAnswerQuestionDeleteDialog',
  components: { AppDialog },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    questionId: {
      required: true,
      validator: (value) => {
        return value === null || typeof value === 'number'
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('questionAnswer', ['deleteQuestionPending', 'questions']),
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.DELETE_QUESTION]),
    ...mapMutations('questionAnswer', [QuestionAnswerMutationTypes.REMOVE_QUESTION_FROM_LIST]),
    async prepareDeleteQuestion () {
      try {
        await this.DELETE_QUESTION({
          mnemo: this.mnemo,
          questionId: this.questionId,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.question-answer.QuestionAnswerQuestionDeleteDialog.deleteQuestionSuccess'),
        })
        this.closeDialog()
        this.REMOVE_QUESTION_FROM_LIST(this.questionId)
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.question-answer.QuestionAnswerQuestionDeleteDialog.deleteQuestionError'),
        })
      }
    },
    closeDialog () {
      this.$emit('onClose')
    },
  },
}
</script>
