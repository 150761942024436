<template>
<div>
  <template v-if="getRoomLogsError">
    <Alert type="error"
           no-icon
           :dismissible="false"
    >
      {{ $t('project.Logs.LogsDataTable.getRoomLogsError') }}
    </Alert>
  </template>
  <template v-else>
    <template v-if="roomLogsPending">
      <AppDataTableSkeletonLoader/>
    </template>
    <template v-else>
      <v-data-table :items="logs"
                    must-sort
                    sort-by="id"
                    :sort-desc="true"
                    :headers="headers"
                    :server-items-length="1"
                    hide-default-footer
                    disable-pagination
                    :options.sync="computedOptions"
      >
        <template #item.date="{item}">
          <DateDisplay :value="item.date"
                       with-hours
          />
        </template>
        <template #item.userFullname="{item}">
          <AppTooltip top>
            <template #activator="{on}">
              <div class="text-truncated"
                   style="max-width: 250px"
                   v-on="on"
              >
                {{ item.userFullname }}
              </div>
            </template>
            <span>{{ item.userFullname }}</span>
          </AppTooltip>
        </template>
        <template #item.name="{item}">
          <AppTooltip top>
            <template #activator="{on}">
              <div class="d-flex align-center"
                   v-on="on"
              >
                <ClosdFildersIcon :document="item" />
                {{ getFileName(item.name) }}
              </div>
            </template>
            <span>{{ item.name }}</span>
          </AppTooltip>
        </template>
        <template #item.id="{item}">
          {{ item.fileId || item.directoryId || item.bibleId }}
        </template>
        <template #item.description="{item}">
          <AppTooltip top>
            <template #activator="{attrs, on}">
              <div class="text-truncated"
                   style="max-width: 400px"
                   v-bind="attrs"
                   v-on="on"
              >{{ item.description }}</div>
            </template>
            <span>{{item.description}}</span>
          </AppTooltip>
        </template>
      </v-data-table>
      <div class="d-flex align-center justify-end mt-4">
        <div class="d-flex align-center mr-4">
          <div class="mr-4">{{ $t('project.Logs.LogsDataTable.linesPerPage') }}</div>
          <AppSelect v-model="computedTableItemsPerPage"
                     :items="ITEM_PER_PAGE_DEFAULT"
                     dense
          />
        </div>
        <AppPagination v-if="pagination && computedTableItemsPerPage"
                       :total="pagination.itemCount"
                       :value="pagination.page"
                       :per-page="computedTableItemsPerPage"
                       @next="onPaginationNext"
                       @previous="onPaginationPrevious"
                       @input="onPaginationInput"
        />
      </div>
    </template>
  </template>
</div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon.vue'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'

import Alert from '../../common/alerts/Alert'
import AppDataTableSkeletonLoader from '../../common/AppDataTableSkeletonLoader'
import AppPagination from '../../common/AppPagination'
import AppSelect from '../../common/AppSelect'
import DateDisplay from '../../common/DateDisplay'
import { ITEM_PER_PAGE_DEFAULT } from '../../common/utils/dataTablePagination'
import { GET_ROOM_LOGS } from '../../store/modules/logs/action_types'
import { SET_LOGS_SORT_BY, SET_LOGS_SORT_DESC, RESET_LOGS_SORT } from '../../store/modules/logs/mutation_types'

export default {
  name: 'LogsDataTable',
  components: { AppTooltip, AppSelect, DateDisplay, AppDataTableSkeletonLoader, Alert, AppPagination, ClosdFildersIcon },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    const headersClass = 'text--primary body-2 font-weight-medium white-space-nowrap'
    return {
      ITEM_PER_PAGE_DEFAULT,
      getRoomLogsError: false,
      headers: [
        { text: this.$t('project.Logs.LogsDataTable.headers.date'), value: 'date', class: headersClass },
        { text: this.$t('project.Logs.LogsDataTable.headers.user'), value: 'userFullname', class: headersClass },
        { text: this.$t('project.Logs.LogsDataTable.headers.action'), value: 'type', class: headersClass },
        { text: this.$t('project.Logs.LogsDataTable.headers.id'), value: 'id', class: headersClass },
        { text: this.$t('project.Logs.LogsDataTable.headers.name'), value: 'name', class: headersClass },
        { text: this.$t('project.Logs.LogsDataTable.headers.description'), value: 'description', class: headersClass, sortable: false },
      ],
      tableItemsPerPage: null,
      options: {
        sortBy: ['date'],
        sortDesc: [true],
      },
    }
  },
  computed: {
    ...mapState('logs', ['roomLogs', 'roomLogsPending', 'logsSort', 'logsFilters', 'roomLogsSearchQuery']),
    ...mapGetters('logs', ['getLogsParams']),
    ...mapGetters('user', ['currentUserId']),
    logs () {
      return this.roomLogs?.data
    },
    pagination () {
      return this.roomLogs?.pagination
    },
    computedTableItemsPerPage: {
      get () {
        return this.tableItemsPerPage
      },
      set (value) {
        this.tableItemsPerPage = value
        setUserSetting(this.currentUserId, 'logs-items-per-page', value)
        this.prepareGetRoomLogsDebounced(1)
      },
    },
    computedOptions: {
      get () { return this.options },
      set (value) {
        this.options = value
        if (this.logsSort.sortBy !== value.sortBy[0]) {
          this.onSortByUpdate(value.sortBy[0])
        }
        if (this.logsSort.sortDesc !== value.sortDesc[0]) {
          this.onSortDescUpdate(value.sortDesc[0])
        }
      },
    },
  },
  watch: {
    logsSort: {
      handler () {
        this.prepareGetRoomLogsImmediate(1)
      },
      deep: true,
    },
    logsFilters: {
      handler () {
        this.prepareGetRoomLogsImmediate(1)
      },
      deep: true,
    },
    roomLogsSearchQuery: {
      handler () {
        this.prepareGetRoomLogsImmediate(1)
      },
      deep: true,
    },
  },
  created () {
    this.prepareGetRoomLogsDebounced = debounce(this.prepareGetRoomLogs, 1000)
    this.prepareGetRoomLogsImmediate = this.prepareGetRoomLogs
  },
  mounted () {
    const storedPagination = getUserSetting(this.currentUserId, 'logs-items-per-page')
    this.tableItemsPerPage = storedPagination !== null ? parseInt(storedPagination) : 50
    this.prepareGetRoomLogsImmediate(1)
  },
  beforeDestroy () {
    this.RESET_LOGS_SORT()
  },
  methods: {
    ...mapActions('logs', [GET_ROOM_LOGS]),
    ...mapMutations('logs', [
      RESET_LOGS_SORT,
      SET_LOGS_SORT_BY,
      SET_LOGS_SORT_DESC,
    ]),
    async prepareGetRoomLogs (page) {
      try {
        await this.GET_ROOM_LOGS(
          {
            mnemo: this.mnemo,
            params: {
              page,
              pageSize: this.tableItemsPerPage,
              ...this.getLogsParams,
            },
          })
      } catch (e) {
        this.getRoomLogsError = true
      }
    },
    onPaginationNext () {
      this.prepareGetRoomLogsImmediate(this.pagination.page + 1)
    },
    onPaginationPrevious () {
      this.prepareGetRoomLogsImmediate(this.pagination.page - 1)
    },
    onPaginationInput (page) {
      this.prepareGetRoomLogsImmediate(page)
    },
    onSortByUpdate (value) {
      this.SET_LOGS_SORT_BY(value)
    },
    onSortDescUpdate (value) {
      this.SET_LOGS_SORT_DESC(value)
    },
    getFileName (fileName) {
      if (fileName.length > 40) {
        const splitedFileName = fileName.split('/')
        return `.../${splitedFileName[splitedFileName.length - 1]}`
      }
      return fileName
    },
  },
}
</script>
