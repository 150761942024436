<template>
  <div>
    <h1 class="text-h1">{{ $t('project.views.QuestionAnswerList.title') }}</h1>

    <v-container
      fluid
      class="pa-0"
      :class="{ 'px-1': $vuetify.breakpoint.mdAndDown, 'fill-height': noData }"
    >
      <template v-if="noData">
        <QuestionAnswerNoData @open-import-excel-dialog="importDialogIsOpen = true" />
      </template>
      <template v-else-if="initialDataLoading">
        <AppDataTableSkeletonLoader class="mt-8"/>
      </template>
      <template v-else-if="dataReady">
        <QuestionAnswerListHeader />
        <QuestionAnswerTopBar :mnemo="mnemo"
                              :search-query.sync="searchQuery"
                              class="my-8"
                              @open-import-excel-dialog="importDialogIsOpen = true"
        />
        <QuestionAnswerMultiSelectBar v-if="shouldMultiSelectBarBeVisible"
                                      class="mb-8"
                                      :selection="selectedQuestions"
                                      @deselectAll="selectedQuestions = []"
        />
        <QuestionAnswerDataTable :mnemo="mnemo"
                                 :search-query.sync="searchQuery"
                                 :selected-questions.sync="selectedQuestions"
                                 @update:selected-questions="selectedQuestions = $event"
        />
      </template>
      <QuestionAnswerFiltersPane :mnemo="mnemo"/>
    </v-container>

    <QuestionAnswerImportDialog v-if="importDialogIsOpen"
                                :mnemo="mnemo"
                                @close="importDialogIsOpen = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import AppDataTableSkeletonLoader from '@/common/AppDataTableSkeletonLoader'
import { getRoomRouteType } from '@/common/utils/rooms'
import QuestionAnswerDataTable from '@/project/question-answer/QuestionAnswerDataTable'
import QuestionAnswerFiltersPane from '@/project/question-answer/QuestionAnswerFiltersPane'
import QuestionAnswerListHeader from '@/project/question-answer/QuestionAnswerListHeader'
import QuestionAnswerMultiSelectBar from '@/project/question-answer/QuestionAnswerMultiSelectBar.vue'
import QuestionAnswerNoData from '@/project/question-answer/QuestionAnswerNoData'
import QuestionAnswerTopBar from '@/project/question-answer/QuestionAnswerTopBar'
import { ROOM_MEMBERS_ROUTE_NAME } from '@/router'
import { QuestionAnswerActionTypes } from '@/store/modules/question-answer/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import QuestionAnswerImportDialog from '../question-answer/QuestionAnswerImportDialog'

export default {
  name: 'QuestionAnswerList',
  components: {
    QuestionAnswerFiltersPane,
    QuestionAnswerTopBar,
    AppDataTableSkeletonLoader,
    QuestionAnswerDataTable,
    QuestionAnswerNoData,
    QuestionAnswerListHeader,
    QuestionAnswerMultiSelectBar,
    QuestionAnswerImportDialog,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      searchQuery: '',
      selectedQuestions: [],
      importDialogIsOpen: false,
    }
  },
  metaInfo () {
    return {
      title: this.$t('project.views.QuestionAnswerList.title'),
    }
  },
  computed: {
    ...mapState('questionAnswer', ['questions', 'questionsPending']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['isCurrentUserPm']),
    noData () {
      return this.questions && this.questions.questions.length === 0
    },
    initialDataLoading () {
      return this.questionsPending && !this.questions
    },
    dataReady () {
      return this.questions && !this.initialDataLoading
    },
    shouldMultiSelectBarBeVisible () {
      return this.isCurrentUserPm && this.selectedQuestions.length
    },
  },
  created () {
    if (!this.currentRoom.isDataroom) {
      this.$router.replace({ name: ROOM_MEMBERS_ROUTE_NAME, params: { roomType: getRoomRouteType(this.currentRoom) } })
    }
    this.prepareGetQuestions()
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.GET_QUESTIONS]),
    async prepareGetQuestions () {
      try {
        await this.GET_QUESTIONS(this.mnemo)
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.views.QuestionAnswerList.getQuestionsError'),
        })
      }
    },
  },
}
</script>
