/**
 * Returns a flattened array.
 * Given array must have objects with 'children' property
 * @param {Array} into - array to fill
 * @param {Array} node - array to flatten
 * @returns {Array} flattened array
 * */
function flatten (into, node) {
  if (node == null) return into
  if (Array.isArray(node)) return node.reduce(flatten, into)
  into.push(node)
  return flatten(into, node.children)
}

export { flatten }
