import { cloneDeep } from 'lodash-es'

import documentsService from '@/services/documents.service'

import {
  CLOSE_DOCUMENTS_DOWNLOAD_FILES,
  GET_SELECTED_DOCUMENTS_IN_DEEP,
  OPEN_DOCUMENTS_DOWNLOAD_FILES,
  POST_DOWNLOAD,
  REMOVE_DOCUMENTS_DOWNLOAD_FILES,
} from './action_types'
import {
  SET_IS_OPEN,
  SET_SELECTED_DOCUMENTS,
  REMOVE_DOCUMENT,
  RESET_SELECTED_DOCUMENTS,
  SET_LOADING,
  SET_ERROR,
  SET_DOWNLOAD_ERROR,
  SET_DOWNLOAD_PENDING,
} from './mutation_types'

export const actions = {
  [OPEN_DOCUMENTS_DOWNLOAD_FILES]: function ({ commit }, selectedDocuments) {
    commit(SET_SELECTED_DOCUMENTS, selectedDocuments)
    commit(SET_IS_OPEN, true)
    commit(SET_LOADING, true)
  },
  [CLOSE_DOCUMENTS_DOWNLOAD_FILES]: function ({ commit }) {
    commit(SET_IS_OPEN, false)
    commit(RESET_SELECTED_DOCUMENTS)
  },
  [REMOVE_DOCUMENTS_DOWNLOAD_FILES]: function ({ commit }, index) {
    commit(REMOVE_DOCUMENT, index)
  },
  [GET_SELECTED_DOCUMENTS_IN_DEEP]: async function ({ commit, state }, mnemo) {
    const selectedDocumentsInDeep = cloneDeep(state.selectedDocuments)

    try {
      // Call API in order to get all childrens
      const newSelectedDocuments = await Promise.all(selectedDocumentsInDeep.map(async (document) => {
        if (document.type === 'folder' && document.childrenCount > 0) {
          const response = await documentsService.getDocumentsById(mnemo, document.id, { lazyMode: false })
          document.children = response.data.children
        }
        return document
      }))

      commit(SET_SELECTED_DOCUMENTS, newSelectedDocuments)
      commit(SET_LOADING, false)
    } catch (e) {
      commit(SET_ERROR, e)
      throw e
    }
  },
  async [POST_DOWNLOAD] ({ commit }, { mnemo, data }) {
    commit(SET_DOWNLOAD_PENDING, true)
    try {
      const response = await documentsService.postDownload(mnemo, data)
      return response.data
    } catch (e) {
      commit(SET_DOWNLOAD_ERROR, e)
      throw e
    } finally {
      commit(SET_DOWNLOAD_PENDING, false)
    }
  },
}
