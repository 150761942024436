<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-disabled="isConfirmUpgradeDisabled"
             :ok-loading="roomStorageAddonPending"
             :ok-text="$t('common.confirm')"
             size="l"
             @cancel="closeDialog"
             @ok="onConfirmUpgrade"
  >
    <template #title>{{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.title') }}</template>
    <template #body>
      <template v-if="dataRoomCustomPricingPending">
        <v-skeleton-loader type="article"/>
      </template>
      <template v-else>
        <app-text variant="small-bold">{{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.currentOfferTitle') }}</app-text>

        <div class="offer">
          <div class="offer__details">
            <div>
              <app-text>
                {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.currentOfferDataroomLabel')}} {{ getDisplaySize(currentRoom.dataroomInfo.initialStorageGb) }} - {{ $tc('project.project-settings.ProjectSettingsDataroomUpgradeDialog.monthsCount', currentRoom.dataroomInfo.initialDuration, { months: currentRoom.dataroomInfo.initialDuration }) }}
              </app-text>
              <app-text variant="x-small-regular" class="grey--text">
                {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.subscriptionDateLabel', { date: ISOToDDMMYYYY(currentRoom.createdAt)}) }}
              </app-text>
            </div>
            <app-text as="span" variant="medium-bold" class="ml-auto">{{ getDisplayPrice(currentRoom.dataroomInfo.monthlyPrice) }}</app-text>
            <app-text as="span" variant="small-regular" class="grey--text">{{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.perMonth') }}</app-text>
          </div>
          <div v-for="(addon, index) in currentRoom.dataroomInfo.addOns" :key="index" class="offer__details">
            <div>
              <app-text>
                {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.currentOfferAddonLabel')}} - {{ getDisplaySize(addon.sizeGb) }}
              </app-text>
              <app-text variant="x-small-regular" class="grey--text">
                {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.subscriptionDateLabel', { date: ISOToDDMMYYYY(addon.date)}) }}
              </app-text>
            </div>
            <app-text as="span" variant="medium-bold" class="ml-auto">{{ getDisplayPrice(addon.monthlyPrice) }}</app-text>
            <app-text as="span" variant="small-regular" class="grey--text">{{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.perMonth') }}</app-text>
          </div>

          <!-- Total Monthly Price -->
          <div class="offer__details">
            <app-text variant="small-regular" class="grey--text">
              {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.total') }}
            </app-text>
            <app-text as="span" variant="small-regular" class="grey--text ml-auto">
              {{ getDisplayPrice(totalMonthlyPrice) }}
            </app-text>
            <app-text as="span" variant="x-small-regular" class="grey--text">
              {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.perMonth') }}
            </app-text>
          </div>
        </div>

        <div class="d-flex justify-space-between align-center mb-2">
          <app-text as="span" variant="small-bold">{{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.addStorageTitle') }}</app-text>
          <v-chip v-if="dataRoomCustomPricing.customerDiscountPercent"
                  color="dataroom lighten-5"
                  class="font-weight-medium px-2 rounded-xl"
                  label
                  small
                  text-color="dataroom"
          >
            {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.customerDiscount', { percent: dataRoomCustomPricing.customerDiscountPercent }) }}
          </v-chip>
        </div>

        <app-text variant="small-regular" class="flex-grow grey--text">
          {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.addStorageSubTitle') }}
        </app-text>

        <v-radio-group v-model="$v.selectedStorageQuantity.$model"
                       hide-details
        >
          <v-radio v-for="nb in addonsDisplayedNumber"
                   :key="nb"
                   :value="nb"
                   active-class="addon--active"
                   class="addon"
          >
            <template #label>
                <app-text as="span" class="addon__size">{{ getDisplaySize(dataRoomCustomPricing.additionalStorageSize * nb) }}</app-text>
                <div class="ml-auto">
                  <template v-if="dataRoomCustomPricing.customerDiscountPercent">
                    <app-text as="span" variant="small-regular" class="grey--text text-decoration-line-through mr-2">
                      {{ getDisplayPrice(dataRoomCustomPricing.additionalStoragePrice * nb) }}{{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.perMonth') }}
                    </app-text>
                  </template>
                  <app-text as="span" variant="medium-bold">
                    {{ getDisplayPrice(dataRoomCustomPricing.additionalStoragePrice * nb, dataRoomCustomPricing.customerDiscountPercent) }}
                  </app-text>
                  <app-text as="span" variant="small-regular" class="grey--text">
                    {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.perMonth') }}
                  </app-text>
                </div>
            </template>
          </v-radio>
          <v-radio :value="selectedStorageWiderQuantity"
                   active-class="addon--active"
                   class="addon"
          >
            <template #label>
              <app-text as="span" class="addon__size pa-0">
                <AppSelect
                  v-model="selectedStorageWiderQuantity"
                  :items="computedStorageWiderItems"
                  :placeholder="$t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.addStoragePlaceholder')"
                  class="addon__select"
                  noLabel
                  @change="onStorageWiderQuantityChange"
                ></AppSelect>
              </app-text>
              <div class="ml-auto">
                <template v-if="dataRoomCustomPricing.customerDiscountPercent && selectedStorageWiderQuantity">
                  <app-text as="span" variant="small-regular" class="grey--text text-decoration-line-through mr-2">
                    {{ getDisplayPrice(dataRoomCustomPricing.additionalStoragePrice * selectedStorageWiderQuantity) }}{{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.perMonth') }}
                  </app-text>
                </template>
                <app-text as="span" variant="medium-bold" class="ml-auto">{{ computedStorageWiderDisplayPrice }}</app-text>
                <app-text as="span" variant="small-regular" class="grey--text">
                  {{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.perMonth') }}
                </app-text>
              </div>
            </template>
          </v-radio>
        </v-radio-group>

        <app-text v-if="selectedStorageQuantity"
                  class="mt-4"
                  variant="small-regular"
                  v-html="$t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.addStorageSumUpText', { price: totalAdditionalStorageDisplayPrice })"
        />

        <v-checkbox v-model="$v.termsChecked.$model"
                    :error-messages="termsCheckedError"
                    class="terms__checkbox"
        >
          <template #label>
            <i18n path="project.project-settings.ProjectSettingsDataroomUpgradeDialog.termsLabel">
              <template v-slot:link>
                  <AppDownloadFile :link="termsLink">
                    <template #default="{ submitForm }">
                      <span class="blue--text text--darken-2 text-decoration-underline" @click="submitForm">{{ $t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.termsLabelLink') }}</span>
                    </template>
                  </AppDownloadFile>
              </template>
            </i18n>
          </template>
        </v-checkbox>
      </template>

    </template>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
import { mapActions, mapMutations, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile.vue'
import AppSelect from '@/common/AppSelect.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import { ISOToDDMMYYYY } from '@/common/utils/dates'
import { dialogMixin } from '@/mixins/dialogMixin'
import { POST_ROOM_STORAGE_ADDON } from '@/store/modules/room/action_types'
import { GET_DATA_ROOM_CUSTOM_PRICING } from '@/store/modules/rooms/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'ProjectSettingsDataroomUpgradeDialog',
  components: { AppDialog, AppDownloadFile, AppSelect },
  mixins: [dialogMixin, validationMixin],
  data () {
    return {
      ISOToDDMMYYYY,
      addonsDisplayedNumber: 3,
      addonsMaxNumber: 9,
      selectedStorageQuantity: null,
      selectedStorageWiderQuantity: 0,
      termsChecked: false,
    }
  },
  computed: {
    ...mapState('room', ['currentRoom', 'roomStorageAddonPending']),
    ...mapState('rooms', ['dataRoomCustomPricing', 'dataRoomCustomPricingPending']),
    totalMonthlyPrice () {
      let total = parseFloat(this.currentRoom.dataroomInfo.monthlyPrice)

      this.currentRoom.dataroomInfo.addOns.forEach((addon) => {
        total += parseFloat(addon.monthlyPrice)
      })

      return total
    },
    computedStorageWiderItems () {
      const items = []

      for (let i = this.addonsDisplayedNumber; i < this.addonsMaxNumber; i++) {
        const item = {
          text: `${this.getDisplaySize(this.dataRoomCustomPricing.additionalStorageSize * (i + 1))}`,
          value: i + 1,
        }
        items.push(item)
      }

      return items
    },
    computedStorageWiderDisplayPrice () {
      if (this.selectedStorageWiderQuantity) {
        return this.getDisplayPrice(this.dataRoomCustomPricing.additionalStoragePrice * this.selectedStorageWiderQuantity, this.dataRoomCustomPricing.customerDiscountPercent)
      }

      return this.$t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.displayedPrice', { price: '- ' })
    },
    isConfirmUpgradeDisabled () {
      return this.$v.$invalid
    },
    termsCheckedError () {
      const errors = []
      if (!this.$v.$dirty) return errors
      !this.$v.termsChecked.sameAs && errors.push(this.$t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.termsValidationError'))
      return errors
    },
    termsLink () {
      return `${process.env.VUE_APP_API_URL}/assets/pdf?file=dataroom-price-list-${this.$i18n.locale.toUpperCase()}`
    },
    totalAdditionalStorageSize () {
      return (this.selectedStorageQuantity * this.dataRoomCustomPricing.additionalStorageSize).toFixed(1)
    },
    totalAdditionalStorageDisplayPrice () {
      return this.getDisplayPrice(this.selectedStorageQuantity * this.dataRoomCustomPricing.additionalStoragePrice, this.dataRoomCustomPricing.customerDiscountPercent)
    },
  },
  created () {
    try {
      this.GET_DATA_ROOM_CUSTOM_PRICING({
        duration: this.currentRoom.dataroomInfo.initialDuration,
        storage: this.currentRoom.dataroomInfo.initialStorageGb,
        unit: 'Gb',
      })
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    ...mapActions('room', [POST_ROOM_STORAGE_ADDON]),
    ...mapActions('rooms', [GET_DATA_ROOM_CUSTOM_PRICING]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    getDiscountPrice (price, discountPercent) {
      const discount = (price * discountPercent) / 100
      return price - discount
    },
    getDisplayPrice (price, discountPercent) {
      const finalPrice = discountPercent ? this.getDiscountPrice(price, discountPercent) : price
      return this.$t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.displayedPrice', { price: this.$n(finalPrice, 'decimal') })
    },
    getDisplaySize (size) {
      const formattedSize = parseFloat(Number(size).toFixed(1))

      if (formattedSize < 1) {
        return `${formattedSize * 1000} ${this.$t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.sizeUnit.Mb')}`
      } else {
        return `${formattedSize} ${this.$t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.sizeUnit.Gb')}`
      }
    },
    async onConfirmUpgrade () {
       try {
          await this.POST_ROOM_STORAGE_ADDON({
            mnemo: this.currentRoom.mnemo,
            data: {
              storage: this.totalAdditionalStorageSize,
              unit: 'Gb',
            },
          })

          this.$emit('refresh-stats')
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.addStorageSuccessText'))
          this.closeDialog()
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.project-settings.ProjectSettingsDataroomUpgradeDialog.addStorageErrorText'))
        }
    },
    onStorageWiderQuantityChange () {
      this.selectedStorageQuantity = this.selectedStorageWiderQuantity
    },
  },
  validations: {
    selectedStorageQuantity: { required },
    termsChecked: { sameAs: sameAs(() => true) },
  },
})
</script>

<style scoped lang="scss">
.offer {
  background-color: var(--v-grey-lighten5);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
  margin-top: 16px;

  &__details {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid var(--v-grey-lighten3);
      margin-bottom: 16px;
      padding-bottom: 8px;
    }
  }
}
.addon {
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline: 1px solid var(--v-grey-lighten3);
  outline-offset: -1px;
  padding: 12px 16px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &--active {
    outline-color: #4C75B5;
    outline-width: 2px;
  }

  &__size {
    background-color: var(--v-grey-lighten5);
    border-radius: 4px;
    padding: 8px;
    min-width: 110px;
    text-align: center;
  }

  ::v-deep .v-input--selection-controls__input {
    display: none;
  }

  ::v-deep &__select {
    fieldset {
      border: none !important;
    }

    input {
      cursor: pointer;

      &::placeholder {
        color: var(--v-accent-base);
      }
    }

    &.v-input {
      font-size: 16px;
    }

    .v-input__control {
      max-width: 160px;

      & .v-input__slot {
        padding: 0;
        background-color: transparent;
        box-shadow: none !important;

        & .v-input__append-inner {
          padding-left: 0;

          & .v-icon {
            font-size: 16px;
          }
        }

        & .v-select__selection--coma {
          text-overflow: unset;
        }
      }
    }

    &.v-input--is-focused {
      .v-input__append-inner .v-icon {
        color: #fff !important;
        caret-color: #fff !important;
      }
    }

    &.v-input--is-dirty {
      input { display: none;}
    }
  }
}
.terms__checkbox {
   margin-top: 24px;
   padding-top: 0;

  ::v-deep .v-input__slot {
    align-items: start;
  }
}
</style>
