import dayjs from 'dayjs'

import {
  SET_AUTH_STATUS_PENDING,
  SET_AUTH_STATUS,
  SET_AUTH_STATUS_ERROR,
  SET_USER_WANT_IMANAGE,
  SET_AUTH_URL_PENDING,
  SET_AUTH_URL,
  SET_AUTH_URL_ERROR,
  SET_AUTH_CODE_PENDING,
  SET_AUTH_CODE,
  SET_AUTH_CODE_ERROR,
  SET_IMANAGE_FILE_PICKER_PENDING,
  SET_IMANAGE_FILE_PICKER_ERROR,
  SET_CURRENT_TIME,
  SET_CURRENT_TIME_TIMEOUT,
  SET_IMANAGE_FOLDER_PICKER_PENDING,
  SET_IMANAGE_FOLDER_PICKER_ERROR,
} from './mutation_types'

export const mutations = {
  [SET_AUTH_STATUS_PENDING] (state, value) {
    state.authStatusPending = value
  },
  [SET_AUTH_STATUS] (state, value) {
    state.authStatus = value
  },
  [SET_AUTH_STATUS_ERROR] (state, value) {
    state.authStatusError = value
  },
  [SET_USER_WANT_IMANAGE] (state, value) {
    state.userWantIManage = value
  },
  [SET_AUTH_URL_PENDING] (state, value) {
    state.authUrlPending = value
  },
  [SET_AUTH_URL] (state, value) {
    state.authUrl = value
  },
  [SET_AUTH_URL_ERROR] (state, value) {
    state.authUrlError = value
  },
  [SET_AUTH_CODE_PENDING] (state, value) {
    state.authCodePending = value
  },
  [SET_AUTH_CODE] (state, value) {
    state.authCode = value
  },
  [SET_AUTH_CODE_ERROR] (state, value) {
    state.authCodeError = value
  },
  [SET_IMANAGE_FILE_PICKER_PENDING] (state, value) {
    state.imanageFilePickerPending = value
  },
  [SET_IMANAGE_FILE_PICKER_ERROR] (state, value) {
    state.imanageFilePickerError = value
  },
  [SET_CURRENT_TIME] (state) {
    state.currentTime = dayjs()
  },
  [SET_CURRENT_TIME_TIMEOUT] (state, value) {
    state.currentTimeTimeout = value
  },
  [SET_IMANAGE_FOLDER_PICKER_PENDING] (state, value) {
    state.imanageFolderPickerPending = value
  },

  [SET_IMANAGE_FOLDER_PICKER_ERROR] (state, value) {
    state.imanageFolderPickerError = value
  },
}
