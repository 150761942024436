<template>
    <div class="d-flex align-center">
      <div class="body-1 mr-2">
        {{ $t('common.app-sort-select.AppSortSelect.sortBy') }}
      </div>
      <AppSelect v-if="options.length"
                 :value="value"
                 :items="options"
                 class="AppSortSelect-input white"
                 @change="$emit('input', $event)"
      />
    </div>
</template>

<script>
import { SORT_OPTIONS } from './sortOptions'
import AppSelect from '../AppSelect'
/**
 * Default Sort select
 * @displayName AppSortSelect
 */
/**
 * Display a select for sorting options.
 */
export default {
  name: 'AppSortSelect',
  components: { AppSelect },
  props: {
    /**
     * Options.
     * Default options are :
     * CREATION_DATE: 'CREATION_DATE',
     * PLANNED_ACHIEVEMENT: 'PLANNED_ACHIEVEMENT',
     * SIGNATURE_ORDER: 'SIGNATURE_ORDER',
     * NAME: 'NAME',
     * */
    selectedOptions: {
      type: Object,
      default: () => SORT_OPTIONS,
    },
    /**
     * @model
     * Selected option
     * */
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    options () {
      const options = []
      for (const option in this.selectedOptions) {
        options.push({
          text: this.$t(`common.app-sort-select.AppSortSelect.options.${option}`),
          value: option,
        })
      }
      return options
    },
  },
}
</script>

<style scoped>
.AppSortSelect-input {
  display: inline-flex;
}
</style>

<docs>
```vue
<template>
  <AppSortSelect v-model="selectedOption"/>
</template>
<script>
  export default {
    data () {
      return {
        selectedOption: '',
      }
    },
  }
</script>
```
</docs>
