<template>
    <div>
      <v-row class="mb-2">
        <v-col class="text--primary clickable"
               style="background-color:#f5f5f5;"
               @click="isOpen = !isOpen"
        >
          <font-awesome-icon :icon="['fas', 'caret-down']"
                             class="expand-btn mr-4"
                             :class="{'expand-btn--closed': !isOpen}"
          ></font-awesome-icon>
          {{step.display}} - {{step.title}}
        </v-col>
      </v-row>
      <CollapseTransition>
        <v-row v-show="isOpen">
          <v-col>
            <div class="PdfSigningPmDialogEnvelope-envelope h-100 py-4 pr-4 text--primary d-flex flex-column">
              <template v-if="!signatures">
                <v-col class="text-center py-5">
                  <font-awesome-icon :icon="['far', 'spinner-third']" spin size="3x" class="text--primary"></font-awesome-icon>
                </v-col>
              </template>
              <template v-else>
                <v-col class="text-center text-h3 font-weight-bold pt-0">
                  {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.signaturePages', { signaturePagesCount }) }}
                </v-col>
                <v-col v-if="!hasSignedPages" class="font-italic text-center">
                  {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.noSignaturePages')}}
                </v-col>
                <v-col v-if="hasSignedPages && signatures"
                       class="PdfSigningPmDialogEnvelope-list"
                       style="flex: auto;"
                >
                  <template v-for="(signature, i) in signatures">
                    <ul :key="`signature-${i}`">
                      <li v-for="file in signature.signedFiles"
                          :key="`signedFile-${file.id}`"
                          class="mb-3"
                      >
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="d-flex align-items-center">
                              <img
                                class="ClosdFildersIcon mr-2"
                                style="width: 20px"
                                :src="`/img/icons/pdf.svg`"
                                alt="File icon"
                              />
                              {{getFileName(file)}}
                            </div>
                            <div class="caption"
                                 v-html="getFileInfo(file)"
                            >
                            </div>
                          </div>
                          <template v-if="file.fileDownloadUrl">
                            <AppDownloadFile :link="getFileDownloadUrl(file.id)"
                                             class="ml-auto"
                            >
                              <template #default="{submitForm}">
                                <AppButton icon
                                           color="#333"
                                           @click="submitForm"
                                >
                                  <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon>
                                </AppButton>
                              </template>
                            </AppDownloadFile>
                          </template>
                        </div>
                      </li>
                    </ul>
                  </template>
                </v-col>
                <v-col class="d-flex justify-space-between pb-0">
                  <PdfSigningPmDialogEnvelopeUploadForUserDialog :envelope="step.envelope"
                                                                 @newSignatures="signatures = $event"
                  />
                  <AppButton color="primary"
                             :disabled="signatures.length === 0"
                             :loading="compileFinalFilePending"
                             @click="prepareCompileFinalFile"
                  >
                    {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.compile') }}
                  </AppButton>
                </v-col>
              </template>
            </div>
          </v-col>
          <v-col>
            <div class="PdfSigningPmDialogEnvelope-envelope h-100 pa-4 text--primary">
              <v-col class="text-center text-h3 font-weight-bold pt-0">
                {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.compiledDocument') }}
              </v-col>
              <template v-if="step.envelope.status === 'sent' && !isRefreshing">
                <v-row v-if="compiledDocName">
                  <v-col class="d-flex align-center justify-space-between">
                    <div class="d-flex align-center">
                      <img
                        class="ClosdFildersIcon mr-2"
                        style="width: 20px"
                        :src="`/img/icons/pdf.svg`"
                        alt="File icon"
                      />
                      <template v-if="compiledDocNameEdit">
                        <AppTextField v-model="compiledDocName"
                                      @blur="onCompiledDocNameEdit"
                        />
                      </template>
                      <template v-else>
                        {{ compiledDocName }}
                      </template>
                    </div>
                    <div>
                      <AppDownloadFile :link="compiledDocumentLink">
                        <template #default="{submitForm}">
                          <AppButton icon
                                     width="28"
                                     height="28"
                                     :x-padding="0"
                                     class="text--primary"
                                     @click="submitForm"
                          >
                            <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon>
                          </AppButton>
                        </template>
                      </AppDownloadFile>
                      <AppButton icon
                                 width="28"
                                 height="28"
                                 :x-padding="0"
                                 class="text--primary"
                                 @click="compiledDocNameEdit = !compiledDocNameEdit"
                      >
                        <font-awesome-icon :icon="['fas', 'pen']"></font-awesome-icon>
                      </AppButton>
                      <AppButton icon
                                 width="28"
                                 height="28"
                                 :x-padding="0"
                                 class="text--primary"
                                 @click="onCompiledFileDelete"
                      >
                        <font-awesome-icon :icon="['fas', 'trash-can']"></font-awesome-icon>
                      </AppButton>
                      <AppButton icon
                                 width="28"
                                 height="28"
                                 :x-padding="0"
                                 class="text--primary"
                                 @click="preparePostLastCompiledFile"
                      >
                        <font-awesome-icon :icon="['fas', 'file-check']"></font-awesome-icon>
                      </AppButton>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="my-2">
                  <v-col>
                    <p>
                      {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.uploadFinalDocument')}}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-file-input v-model="signedDoc"
                                  dense
                                  outlined
                                  color="blue darken-2"
                                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <AppButton color="primary"
                               :disabled="!signedDoc"
                               :loading="postFinalFilePending"
                               @click="preparePostFinalFile"
                    >
                      {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.finalize') }}
                    </AppButton>
                  </v-col>
                </v-row>
              </template>
              <template v-if="isRefreshing">
                <v-row>
                  <v-col class="text-center py-5">
                    <font-awesome-icon :icon="['far', 'spinner-third']" spin size="3x" class="text--primary"></font-awesome-icon>
                  </v-col>
                </v-row>
              </template>
              <template v-if="step.envelope.status === 'signed'">
                <v-row v-if="envelopeSignedDocument"
                       class="align-items-center"
                       no-gutters
                >
                  <v-col>
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="d-flex align-items-center">
                          <img
                            class="ClosdFildersIcon mr-2"
                            style="width: 20px"
                            :src="`/img/icons/pdf.svg`"
                            alt="File icon"
                          />
                          {{envelopeSignedDocument.name}}
                        </div>
                        <div class="caption"
                             v-html="getSignedFileDate(envelopeSignedDocument.time)"
                        >
                        </div>
                      </div>
                      <template v-if="envelopeSignedDocument.signedDocumentURL">
                        <AppDownloadFile :link="envelopeSignedDocument.signedDocumentURL"
                                         class="ml-auto"
                        >
                          <template #default="{submitForm}">
                            <AppButton icon
                                       color="#333"
                                       @click="submitForm"
                            >
                              <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon>
                            </AppButton>
                          </template>
                        </AppDownloadFile>
                      </template>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <font-awesome-icon :icon="['fad', 'circle-check']"
                                       class="success--text mb-3"
                                       style="font-size: 60px;"
                    ></font-awesome-icon>
                    <div>{{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.documentSendToRecipients') }}</div>
                  </v-col>
                </v-row>
              </template>
            </div>
          </v-col>
        </v-row>
      </CollapseTransition>
    </div>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import { mapActions, mapState } from 'vuex'

import AppTextField from '@/common/AppTextField'
import signingChecklistService from '@/services/signingChecklist.service'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import PdfSigningPmDialogEnvelopeUploadForUserDialog from './PdfSigningPmDialogEnvelopeUploadForUserDialog'
import AppDownloadFile from '../../../common/AppDownloadFile'
import AppButton from '../../../common/buttons/AppButton'
import { ISOToShortDate } from '../../../common/utils/dates'
import { findStepParent } from '../../../common/utils/signingChecklist'
import {
  POST_FINAL_FILE,
  COMPILE_FINAL_FILE,
  REFRESH_STEP,
  POST_LAST_COMPILED_FILE, DELETE_COMPILED_FILE, PATCH_COMPILED_FILE,
} from '../../../store/modules/signing-checklist/action_types'

export default {
  name: 'PdfSigningPmDialogEnvelope',
  components: { AppTextField, PdfSigningPmDialogEnvelopeUploadForUserDialog, AppButton, AppDownloadFile, CollapseTransition },
  props: {
    step: {
      type: Object,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      signedDoc: null,
      signatures: null,
      isOpen: true,
      compiledDocName: '',
      compiledDocNameEdit: false,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['compileFinalFilePending', 'refreshStepPendingIds', 'steps', 'postFinalFilePending', 'signingChecklist']),
    ...mapState('signedDocuments', ['signedDocuments']),
    envelopeSignedDocument () {
      return this.signedDocuments.find(signedDocument => signedDocument.id === this.step.envelope.id)
    },
    hasSignedPages () {
      if (this.signatures) {
        for (const signature of this.signatures) {
          if (signature.signedFiles.length > 0) {
            return true
          }
        }
      }
      return false
    },
    signaturePagesCount () {
      let counter = 0
      if (this.signatures) {
        for (const signature of this.signatures) {
          if (signature.signedFiles.length > 0) {
            counter += signature.signedFiles.length
          }
        }
      }
      return counter
    },
    isRefreshing () {
      return this.refreshStepPendingIds.includes(this.step.id)
    },
    compiledDocumentLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/envelopePdfCompiled/${this.step.envelope.id}`
    },
  },
  async mounted () {
    const response = await signingChecklistService.getEnvelopeSignatures(this.$route.params.mnemo, this.step.envelope.id)
    this.signatures = response.data.signatures
    if (response.data.hasCompiledFile && response.data.compiledFileName) {
      this.compiledDocName = response.data.compiledFileName
    }
  },
  methods: {
    ...mapActions('signingChecklist', [POST_FINAL_FILE, COMPILE_FINAL_FILE, REFRESH_STEP, POST_LAST_COMPILED_FILE, DELETE_COMPILED_FILE, PATCH_COMPILED_FILE]),
    getFileName (file) {
      let str = ''
      if (file.fileName) {
        str += file.fileName
      }
      return str
    },
    getFileInfo (file) {
      let str = ''
      if (file.signerName) {
        str += `${this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.signedBy')} <b>${file.signerName}</b>`
      }
      if (file.date) {
        str += `, ${this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.signDate')} ${ISOToShortDate(file.date)}`
      }
      return str
    },
    getSignedFileDate (date) {
      return ` ${this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.signedFileDate')} ${ISOToShortDate(date)}`
    },
    async preparePostFinalFile () {
      try {
        const formData = new FormData()
        formData.append('file', this.signedDoc, this.signedDoc.name)
        await this.POST_FINAL_FILE({
          mnemo: this.$route.params.mnemo,
          envelopeId: this.step.envelope.id,
          formData,
        })
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.postFinalFileSuccess'),
        })
        this.$emit('refreshSignedDocuments')
      } catch (e) {
        this.$store.commit('enqueueSnackbar', {
          color: 'error',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.postFinalFileError'),
        })
      }
      try {
        await this.REFRESH_STEP({
          mnemo: this.$route.params.mnemo,
          stepId: this.step.id,
          parent: findStepParent(this.step, this.signingChecklist),
        })
      } catch (e) {
        this.$store.commit('enqueueSnackbar', {
          color: 'error',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.refreshStepError'),
        })
      }
    },
    async prepareCompileFinalFile () {
      try {
        await this.COMPILE_FINAL_FILE({
          mnemo: this.$route.params.mnemo,
          envelopeId: this.step.envelope.id,
        })
        this.compiledDocName = this.step.envelope.name
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.compileFinalFileSuccess'),
        })
      } catch (e) {
        this.$store.commit('enqueueSnackbar', {
          color: 'error',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.compileFinalFileError'),
        })
      }
    },
    getFileDownloadUrl (fileId) {
      return `${process.env.VUE_APP_API_URL}/room/${this.$route.params.mnemo}/downloads/envelopePdfSignedFile/${this.step.envelope.id}/${fileId}`
    },
    async preparePostLastCompiledFile () {
      try {
        await this.POST_LAST_COMPILED_FILE({
          mnemo: this.$route.params.mnemo,
          envelopeId: this.step.envelope.id,
          data: {
            fileName: this.compiledDocName,
          },
        })
        try {
          await this.REFRESH_STEP({
            mnemo: this.$route.params.mnemo,
            stepId: this.step.id,
            parent: findStepParent(this.step, this.signingChecklist),
          })
        } catch (e) {
          console.error(e)
          this.$store.commit('enqueueSnackbar', {
            color: 'error',
            message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.refreshStepError'),
          })
        }
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.postLastCompiledFileError'),
        })
      }
    },
    async onCompiledFileDelete () {
      try {
        await this.DELETE_COMPILED_FILE({
          mnemo: this.mnemo,
          envelopeId: this.step.envelope.id,
        })
        this.compiledDocName = ''
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.deleteCompiledFileError'),
        })
      }
    },
    async getCompiledFileName () {
      const response = await signingChecklistService.getEnvelopeSignatures(this.$route.params.mnemo, this.step.envelope.id)
      if (response.data.hasCompiledFile && response.data.compiledFileName) {
        this.compiledDocName = response.data.compiledFileName
      }
    },
    async onCompiledDocNameEdit () {
      try {
        await this.PATCH_COMPILED_FILE({
          mnemo: this.mnemo,
          envelopeId: this.step.envelope.id,
          data: {
            fileName: this.compiledDocName,
          },
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelope.patchCompiledFileError'),
        })
      } finally {
        this.getCompiledFileName()
        this.compiledDocNameEdit = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.PdfSigningPmDialogEnvelope-envelope {
  border: 2px dashed var(--v-grey-base);
  border-radius: 8px;
  overflow-y: auto;
}
.PdfSigningPmDialogEnvelope-list {
  max-height: 200px;
  overflow-y: auto;
}
.expand-btn {
  transition: transform .3s;
}
.expand-btn--closed {
  transform: rotate(-90deg);
}
</style>
