import { buildGetPagesQuery } from '@/common/utils/prepareSignature'

import {
  GET_SIGNING_VIEW,
  GET_PAGES,
  SIGN_TAG,
  UNSIGN_TAG,
  SEND_VALIDATION_SMS,
  SIGN_DOCUMENT,
  RESEND_VALIDATION_SMS,
  CONFIRM_SIGNATURE,
} from './action_types'
import {
  SET_SIGNING_VIEW,
  SET_SIGNING_VIEW_PENDING,
  SET_SIGNING_VIEW_ERROR,
  SET_PAGES,
  ADD_PAGES_NUMBER_PENDING,
  REMOVE_PAGES_NUMBER_PENDING,
  SET_PAGES_ERROR,
  SET_SIGN_TAG_PENDING,
  REMOVE_SIGN_TAG_PENDING,
  SET_SIGN_TAG_ERROR,
  SET_UNSIGN_TAG_PENDING,
  REMOVE_UNSIGN_TAG_PENDING,
  SET_UNSIGN_TAG_ERROR,
  REFRESH_TAG,
  SET_SEND_VALIDATION_SMS_PENDING,
  SET_SEND_VALIDATION_SMS_ERROR,
  SET_SIGN_DOCUMENT_PENDING,
  SET_SIGN_DOCUMENT_ERROR,
  SET_RESEND_VALIDATION_SMS_PENDING,
  SET_RESEND_VALIDATION_SMS_ERROR,
  SET_CONFIRM_SIGNATURE_PENDING,
  SET_CONFIRM_SIGNATURE_ERROR,
} from './mutation_types'
import service from '../../../services/certeurope-sign.service'
import prepareSignatureService from '../../../services/prepareSignature.service'

export const actions = {
  async [CONFIRM_SIGNATURE] ({ commit }, { mnemo, envelopeId }) {
    commit(SET_CONFIRM_SIGNATURE_PENDING, true)
    try {
      await service.confirmSignature(mnemo, envelopeId)
    } catch (error) {
      commit(SET_CONFIRM_SIGNATURE_ERROR, error)
      throw error
    } finally {
      commit(SET_CONFIRM_SIGNATURE_PENDING, false)
    }
  },
  async [RESEND_VALIDATION_SMS] ({ commit }, { mnemo, envelopeId }) {
    commit(SET_RESEND_VALIDATION_SMS_PENDING, true)
    try {
      await service.resentValidationSms(mnemo, envelopeId)
    } catch (error) {
      commit(SET_RESEND_VALIDATION_SMS_ERROR, error)
      throw error
    } finally {
      commit(SET_RESEND_VALIDATION_SMS_PENDING, false)
    }
  },
  async [SIGN_DOCUMENT] ({ commit }, { mnemo, envelopeId, data }) {
    commit(SET_SIGN_DOCUMENT_PENDING, true)
    try {
      await service.signDocument(mnemo, envelopeId, data)
    } catch (error) {
      commit(SET_SIGN_DOCUMENT_ERROR, error)
      throw error
    } finally {
      commit(SET_SIGN_DOCUMENT_PENDING, false)
    }
  },
  async [SEND_VALIDATION_SMS] ({ commit }, { mnemo, envelopeId, data }) {
    commit(SET_SEND_VALIDATION_SMS_PENDING, true)
    try {
      await service.sentValidationSms(mnemo, envelopeId, data)
    } catch (error) {
      commit(SET_SEND_VALIDATION_SMS_ERROR, error)
      throw error
    } finally {
      commit(SET_SEND_VALIDATION_SMS_PENDING, false)
    }
  },
  async [UNSIGN_TAG] ({ commit, state }, { mnemo, envelopeId, tagId, page }) {
    commit(SET_UNSIGN_TAG_PENDING, tagId)
    try {
      const response = await service.unsignTag(mnemo, envelopeId, tagId)
      commit(REFRESH_TAG, { page, tag: response.data.tag })
    } catch (error) {
      commit(SET_UNSIGN_TAG_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_UNSIGN_TAG_PENDING,
        state.unsignTagPendingIds.indexOf(tagId))
    }
  },
  async [SIGN_TAG] ({ commit, state }, { mnemo, envelopeId, tagId, data, page }) {
    commit(SET_SIGN_TAG_PENDING, tagId)
    try {
      const response = await service.signTag(mnemo, envelopeId, tagId, data)
      commit(REFRESH_TAG, { page, tag: response.data.tag })
    } catch (error) {
      commit(SET_SIGN_TAG_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_SIGN_TAG_PENDING,
        state.signTagPendingIds.indexOf(tagId))
    }
  },
  async [GET_PAGES] ({ commit, state }, { mnemo, envId, fromPage, toPage }) {
    for (let i = fromPage; i <= toPage; i++) {
      if (!state.pagesNumberPending.includes(i)) {
        commit(ADD_PAGES_NUMBER_PENDING, { fromPage: i, toPage: i })
        try {
          const response = await prepareSignatureService.getPages(mnemo, envId, buildGetPagesQuery({ fromPage: i, toPage: i }))
          commit(SET_PAGES, response.data)
        } catch (error) {
          commit(SET_PAGES_ERROR, error)
          throw error
        } finally {
          commit(REMOVE_PAGES_NUMBER_PENDING, { fromPage: i, toPage: i })
        }
      }
    }
  },
  async [GET_SIGNING_VIEW] ({ commit }, { mnemo, envelopeId }) {
    commit(SET_SIGNING_VIEW_PENDING, true)
    commit(SET_SIGNING_VIEW_ERROR, null)
    try {
      const response = await service.getSigningView(mnemo, envelopeId)
      commit(SET_SIGNING_VIEW, response.data)
    } catch (error) {
      commit(SET_SIGNING_VIEW_ERROR, error)
      throw error
    } finally {
      commit(SET_SIGNING_VIEW_PENDING, false)
    }
  },
}
