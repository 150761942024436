<template>
  <v-container ref="ProjectsGridTemplate" fluid class="py-4 px-0">
    <template>
      <v-row>
        <template v-if="rooms && rooms.asPM.length > 0">
          <v-col v-for="(project) in rooms.asPM"
                 :key="project.id"
                 cols="12"
                 sm="6"
                 md="3"
                 lg="3"
                 xl="2"
          >
            <span :draggable="projectsFoldersTree.length"
                  @dragstart="dragRoomStart($event, project)"
                  @dragend="dragRoomEnd($event, project)"
            >
              <ProjectsGrid :ref="`projectGrid${project.id}`" :project="project"/>
              <ProjectsGridGhost :ref="`projectGhost${project.id}`" :item="project"/>
            </span>
          </v-col>
        </template>
        <template v-if="rooms && rooms.asPM.length === 0 && rooms.asGuest.length > 0">
          <v-col v-for="(project) in rooms.asGuest"
                 :key="project.id"
                 cols="12"
                 sm="6"
                 md="3"
                 lg="3"
                 xl="2"
          >
            <span :draggable="projectsFoldersTree.length"
                  @dragstart="dragRoomStart($event, project)"
                  @dragend="dragRoomEnd($event, project)"
            >
              <ProjectsGrid :ref="`projectGrid${project.id}`" :project="project"/>
              <ProjectsGridGhost :ref="`projectGhost${project.id}`" :item="project"/>
            </span>
          </v-col>
        </template>
      </v-row>
    </template>

    <template v-if="rooms && rooms.asPM.length > 0 && rooms.asGuest.length > 0">
      <v-row v-if="rooms.asPM.length > 0">
        <v-col>
          <div class="ProjectsGridTemplate-title">{{$t('projects.ProjectsGridTemplate.guest', { projectsCount: rooms.asGuest.length })}}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(project) in rooms.asGuest"
               :key="project.id"
               cols="12"
               sm="6"
               md="3"
               lg="3"
               xl="2"
        >
          <span :draggable="projectsFoldersTree.length"
                @dragstart="dragRoomStart($event, project)"
                @dragend="dragRoomEnd($event, project)"
          >
            <ProjectsGrid :ref="`projectGrid${project.id}`" :project="project"/>
            <ProjectsGridGhost :ref="`projectGhost${project.id}`" :item="project"/>
          </span>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import { DARK_BLUE_50, DARK_BLUE_500 } from '@/common/utils/colors'
import { SET_ROOM_TO_MOVE } from '@/store/modules/room/mutation_types'

import ProjectsGrid from './ProjectsGrid.vue'
import ProjectsGridGhost from './ProjectsGridGhost.vue'
export default {
  name: 'ProjectsGridTemplate',
  components: { ProjectsGrid, ProjectsGridGhost },
  props: {
    rooms: {
      required: true,
      validator: (item) => typeof item === 'object' || item === null,
    },
  },
  data () {
    return {
      currentGhost: null,
      DARK_BLUE_50,
      DARK_BLUE_500,
    }
  },
  computed: {
    ...mapState('projectsFolders', ['projectsFoldersTree']),
  },
  methods: {
    ...mapMutations('room', [SET_ROOM_TO_MOVE]),
    dragRoomStart (event, room) {
      if (!this.projectsFoldersTree.length) return
      const projectGrid = this.$refs[`projectGrid${room.id}`][0].$el
      if (projectGrid) {
        projectGrid.classList.add('roomIsDragging')
      }
      const ghost = this.$refs[`projectGhost${room.id}`][0].$el.cloneNode(true)
      this.currentGhost = ghost
      if (this.currentGhost) {
        ghost.style.display = 'inline-flex'
        this.$refs.ProjectsGridTemplate.appendChild(ghost)
        event.dataTransfer.setDragImage(ghost, 0, 0)
      }
      this.SET_ROOM_TO_MOVE(room)
    },
    dragRoomEnd (event, room) {
      if (!this.projectsFoldersTree.length) return
      const projectGrid = this.$refs[`projectGrid${room.id}`][0].$el
      if (projectGrid) {
        projectGrid.classList.remove('roomIsDragging')
      }
      if (this.currentGhost) {
        this.$refs.ProjectsGridTemplate.removeChild(this.currentGhost)
      }
      this.SET_ROOM_TO_MOVE({})
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ProjectsGridTemplate-card {
  width: 100%;
}
.ProjectsGridTemplate-title {
  font-size: 18px;
  font-weight: 600;
}

.roomIsDragging {
  background-color: v-bind(DARK_BLUE_50);
  border: 2px solid v-bind(DARK_BLUE_500);
}
</style>
