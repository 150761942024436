<template>
  <AppTooltip top :disabled="!fileTooLarge">
    <template #activator="{ on, attrs }">
      <!-- We use a wrapper div to retain the disable styling _and_ the tooltip hover event -->
      <div v-bind="attrs" v-on="on">
        <v-list-item
          :disabled="fileTooLarge"
          @click="handleClick"
        >
          <v-list-item-title>
            <div class="d-flex align-center">
              {{ $t('common.polyOffice.PolyOfficeListItemExport.exportFiles') }}
              <v-img src="/img/LexisPoly.svg"
                    max-height="18"
                    max-width="18"
                    class="ml-2"
              />
            </div>
          </v-list-item-title>
        </v-list-item>
      </div>
    </template>
    <span>{{ $tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1) }}</span>
  </AppTooltip>
</template>

<script>
import { POLYOFFICE_MAX_EXPORT_SIZE } from './constants'
import AppTooltip from '../AppTooltip.vue'

export default {
  name: 'PolyOfficeListItemExport',
  components: { AppTooltip },
  props: {
    fileSize: {
      type: Number,
      required: true,
    },
  },
  computed: {
    fileTooLarge () {
      return POLYOFFICE_MAX_EXPORT_SIZE <= this.fileSize
    },
  },
  methods: {
    handleClick () {
      this.$emit('polyOfficeOpenDialog')
    },
  },
}
</script>
