<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             :ok-text="$t('common.yes')"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>{{ $tc('project.signing-checklist.list.SigningChecklistVoidedToDraftStepDialog.title', count) }}</template>

    <template #body>{{ $tc('project.signing-checklist.list.SigningChecklistVoidedToDraftStepDialog.body', count) }}</template>
  </AppDialog>
</template>

<script>
import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'

export default {
  name: 'SigningChecklistVoidedToDraftStepDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
  methods: {
    confirmClick () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
