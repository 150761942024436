<template>
  <div>
    <BiblesHeader/>
    <BiblesButtonBar v-if="!noData"
                     :mnemo="mnemo"
                     :sort="sort"
                     @input="sort = $event"
                     @generateBible="openBibleGenerateDialog = true"
    />
    <div v-if="noData"
         class="noData"
    >
      <div class="text-center">
        <v-row>
          <v-col>
            <v-img src="/img/bibleIllustration.svg" width="325" class="mx-auto"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-if="currentRoom.userRights.canCreateBibles" class="noDataText">{{ $t('project.bibles.Bibles.noData.text') }}</div>
            <div v-else class="noDataText">{{ $t('project.bibles.Bibles.noData.guestText') }}</div>
          </v-col>
        </v-row>
        <v-row v-if="currentRoom.userRights.canCreateBibles">
          <v-col>
            <AppButton color="primary"
                       @click="openBibleGenerateDialog = true"
            >
              {{ $t('project.bibles.Bibles.addBible') }}
            </AppButton>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <template v-if="biblesPending">
        <v-row>
          <v-col cols="12"
                 sm="6"
                 md="4"
                 lg="3"
                 xl="2"
          >
            <v-skeleton-loader type="article, actions"/>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col v-for="bible in filteredBibles"
                 :key="`bible-${bible.id}`"
                 cols="12"
                 sm="6"
                 md="4"
                 lg="3"
                 xl="2"
          >
            <Bible :bible="bible"
                   :autoDownload="isAutoDownload(bible)"
                   :mnemo="mnemo"
                   @share-success="getBibles"
                   @export-to-imanage="onExportToImanage"
            />
          </v-col>
        </v-row>
      </template>
    </div>
    <BibleGenerateDialog
      v-if="openBibleGenerateDialog"
      :mnemo="mnemo"
      display-tree-structure
      @pollBibles="pollBibles"
      @close="openBibleGenerateDialog = false"
    />
    <IManageFolderPickerDialog :is-open.sync="openImanageFolderPicker" @folders="prepareExportToImanage" />
    <PolyOfficeDialog v-if="polyOfficeEnabled && dialogIsOpen" @callback="polyOfficeDialogCallback"/>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'

import { SORT_OPTIONS } from '@/common/app-sort-select/sortOptions'
import IManageFolderPickerDialog from '@/common/imanage/IManageFolderPickerDialog'
import PolyOfficeDialog, { POLY_OFFICE_DIALOG_STATUS_SUCCESS } from '@/common/polyOffice/PolyOfficeDialog.vue'
import BiblesButtonBar from '@/project/bibles/BiblesButtonBar'
import { EXPORT_BIBLE_TO_IMANAGE } from '@/store/modules/bibles/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import Bible from './Bible'
import BiblesHeader from './BiblesHeader'
import BibleGenerateDialog from './dialogs/BibleGenerateDialog'
import AppButton from '../../common/buttons/AppButton'

const POLL_BIBLES_POLLING_DELAY = 5000

export default {
  name: 'Bibles',
  components: { AppButton, Bible, BibleGenerateDialog, BiblesButtonBar, BiblesHeader, IManageFolderPickerDialog, PolyOfficeDialog },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      bibleToExportToImanage: null,
      pollBiblesTimeout: null,
      openBibleGenerateDialog: false,
      openImanageFolderPicker: false,
      sort: SORT_OPTIONS.NAME,
    }
  },
  metaInfo () {
    return {
      title: this.$t('project.bibles.Bibles.metaTitle'),
    }
  },
  computed: {
    ...mapState('bibles', ['bibles', 'biblesPending']),
    ...mapState('room', ['currentRoom']),
    ...mapState('polyOffice', ['dialogIsOpen', 'dialogSuccess']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    noData () {
      return this.bibles && this.bibles.length === 0 && !this.biblesPending
    },
    filteredBibles () {
      let bibles = cloneDeep(this.bibles)
      if (this.sort === SORT_OPTIONS.NAME) {
        bibles = bibles.sort((a, b) => {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        })
      } else if (this.sort === SORT_OPTIONS.CREATION_DATE) {
        bibles = bibles.sort((a, b) => {
          return new Date(a.generatedAt) < new Date(b.generatedAt) ? -1 : 1
        })
      }
      return bibles
    },
  },
  mounted () {
    this.GET_BIBLES({ mnemo: this.mnemo })
    if (this.$route.params.poll) {
      this.pollBiblesTimeout = setTimeout(() => { this.pollBibles() }, POLL_BIBLES_POLLING_DELAY)
    }
  },
  beforeDestroy () {
    clearTimeout(this.pollBiblesTimeout)
    // Set to a recognizable value so that we know if we should stop refreshing if already fired
    this.pollBiblesTimeout = -1
  },
  methods: {
    ...mapActions('bibles', [EXPORT_BIBLE_TO_IMANAGE, 'GET_BIBLES']),
    getBibles () {
      return this.GET_BIBLES({ mnemo: this.mnemo, params: { ifRecent: true }, isPolling: true })
    },
    onExportToImanage (bibleId) {
      this.bibleToExportToImanage = bibleId
      this.openImanageFolderPicker = true
    },
    async pollBibles () {
      if (this.pollBiblesTimeout) {
        clearTimeout(this.pollBiblesTimeout)
        this.pollBiblesTimeout = null
      }
      try {
        await this.getBibles()
      } finally {
        if (this.bibles.filter(x => x.status === 'processing').length > 0) {
          if (this.pollBiblesTimeout !== -1) {
            this.pollBiblesTimeout = setTimeout(() => { this.pollBibles() }, POLL_BIBLES_POLLING_DELAY)
          }
        }
      }
    },
    isAutoDownload (bible) {
      return bible.id.toString() === this.$route.query.idBible
    },
    polyOfficeDialogCallback (data) {
      if (data.status === POLY_OFFICE_DIALOG_STATUS_SUCCESS) {
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.dialogSuccess,
        })
      }
    },
    async prepareExportToImanage (target) {
      const data = {
        targetId: target.selected,
      }
      try {
        await this.EXPORT_BIBLE_TO_IMANAGE({
          mnemo: this.mnemo,
          bibleId: this.bibleToExportToImanage,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.bibles.Bibles.exportBibleToImanageSuccess'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.bibles.Bibles.exportBibleToImanageError'),
        })
      }
    },
  },
}
</script>

<style scoped>
.noData {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}
</style>
