<template>
  <v-stepper-content step="1"
                     class="text--primary"
  >
    <template v-if="currentStep === 1">
      <v-row>
        <v-col>
          <div class="IdentityCheckingInformation__title mb-2">{{ $t('common.identity-checking.IdentityCheckingInformation.stepTitle') }}</div>
          <div>{{ $t('common.identity-checking.IdentityCheckingInformation.stepText') }}</div>
        </v-col>
      </v-row>

      <!-- Information Errors -->
      <div v-if="informationErrors.length > 0" class="mt-8">
        <app-alert-in-page v-for="documentError in informationErrors"
                           :key="documentError"
                           class="mt-2"
                           :dismissible="false"
                           variant="error"
        >
          <div v-html="$t('common.idCheckErrors.' + documentError)"/>
        </app-alert-in-page>

        <!-- Tries left -->
        <app-alert-in-page class="mt-2" :dismissible="false" variant="error">
          <template #title>{{ $t('common.identity-checking.IdentityCheckingDialog.wrongInfo') }}</template>
          <div class="mt-2">{{ this.$tc('common.identity-checking.IdentityCheckingDialog.triesLeft', triesLeft, { triesLeft: triesLeft }) }}</div>
        </app-alert-in-page>
      </div>

      <v-row class="mt-6">
        <v-col cols="12" sm="6">
          <Gender :model="information.gender"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <FirstName :model="information.firstName"/>
        </v-col>
        <v-col cols="12" sm="6">
          <LastName :model="information.lastName"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <BirthName :model="information.birthName"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <BirthDate :model="information.birthDate"/>
        </v-col>
        <v-col cols="12" sm="6">
          <Telephone :model="information.telephone"
                     :label="$t('common.cellPhoneNumber')"
                     @phone-validation-change="$emit('phone-validation-change', $event)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="caption">
            {{ $t('common.identity-checking.IdentityCheckingInformation.requiredFieldsInfo') }}
          </div>
        </v-col>
      </v-row>
    </template>
  </v-stepper-content>
</template>

<script>
import { intersection } from 'lodash-es'
import { mapState } from 'vuex'

import BirthDate from '@/common/identity-checking/form-fields/BirthDate'
import BirthName from '@/common/identity-checking/form-fields/BirthName'
import FirstName from '@/common/identity-checking/form-fields/FirstName'
import Gender from '@/common/identity-checking/form-fields/Gender'
import LastName from '@/common/identity-checking/form-fields/LastName'
import Telephone from '@/common/identity-checking/form-fields/Telephone'
import { ID_CHECK_INFORMATION_ERRORS } from '@/common/utils/idCheckErrors'

export default {
  name: 'IdentityCheckingInformation',
  components: { Telephone, BirthDate, BirthName, LastName, FirstName, Gender },
  props: {
    information: {
      type: Object,
      required: true,
    },
    triesLeft: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['idCheckError']),
    informationErrors () {
      return intersection(this.idCheckError?.errors, ID_CHECK_INFORMATION_ERRORS)
    },
  },
}
</script>

<style scoped lang="scss">
.IdentityCheckingInformation {
  &__title {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
