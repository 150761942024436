export enum AppAlertIcon {
  'info' = 'circle-info',
  'success' = 'circle-check',
  'error' = 'circle-xmark',
  'warning' = 'triangle-exclamation',
}

export enum AppAlertBackgroundColor {
  'info' = '#f2fbff',
  'success' = '#f2fff2',
  'error' = '#fff5f2',
  'warning' = '#fffaf2',
}

export enum AppAlertColor {
  'info' = '#0095da',
  'success' = '#389b3c',
  'error' = '#e71e31',
  'warning' = '#eb8916',
}
