<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             :persistent="true"
             :ok-text="$t('project.documents.dialogs.DocumentsFileUploadDialog.startUpload')"
             :ok-disabled="selectedDocuments.length <= 0"
             @ok="startSelectedDocumentsUpload"
             @cancel="closeDialog"
  >
    <template #title>
      <span>{{ dialogTitle }}</span>
    </template>
    <template #body>
      <v-row>
        <v-col>
          <div
            class="DocumentsFileUpload-dropzone text-center pa-12"
            @dragover.prevent
            @dragenter.prevent="dragInProgress = true"
            @dragleave.prevent="dragInProgress = false"
            @drop.prevent="dropFileUpload"
          >
            <v-row>
              <v-col>
                <img
                  width="145"
                  class="mx-auto"
                  src="/img/icons/uploaderIllustration.svg"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="body-1 font-weight-semi-bold text--primary">
                {{ canUploadFolders ? $t("project.documents.dialogs.DocumentsFileUploadDialog.dropZoneText1") : $t("project.documents.dialogs.DocumentsFileUploadDialog.dropZoneText3") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text--secondary">
                <template v-if="roomProvidersPending">
                  <v-skeleton-loader type="text"/>
                </template>
                <div v-else-if="!isDataRoom"
                     v-html="$tc('project.documents.dialogs.DocumentsFileUploadDialog.dropZoneText2', null, { maxSize: maxSignableSize, maxPage: maxSignablePages })"
                ></div>
                <div> {{ $t('project.documents.dialogs.DocumentsFileUploadDialog.maxFileSize', { maxSize: maxUploadFileSize }) }} </div>
              </v-col>
            </v-row>
            <v-row v-if="imanageEnabledAndLogged">
              <v-col>
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <AppButton
                      color="tertiary"
                      class="white--text"
                      :loading="postFildersFromImanagePending"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-img src="/img/iManage_logo.png" width="25" class="mr-2" />
                      {{ $t("project.documents.dialogs.DocumentsFileUploadDialog.importFromImanage") }}
                      <font-awesome-icon
                        :icon="['fal', 'chevron-down']"
                        class="ml-2 body-1"
                      ></font-awesome-icon>
                    </AppButton>
                  </template>
                  <v-list>
                    <v-list-item @click="openIManageFilesPicker">
                      <v-list-item-title>{{
                          $t("project.documents.dialogs.DocumentsFileUploadDialog.importFilesFromImanage")
                        }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openIManageFoldersPicker">
                      <v-list-item-title>{{
                          $t("project.documents.dialogs.DocumentsFileUploadDialog.importFoldersFromImanage")
                        }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <IManageFilePickerDialog
              :is-open.sync="iManageFilesPickerDialogIsOpen"
              @files="onImanageFiles"
            />
            <IManageFolderPickerDialog
              :is-open.sync="iManageFoldersPickerDialogIsOpen"
              @folders="onImanageFolders"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="canUploadFolders" class="text-right">
          <AppButton
            color="tertiary"
            dark
            @click="onFolderInputClick"
          >
            <font-awesome-icon :icon="['fal', 'folder-upload']" class="body-1 mr-2"></font-awesome-icon>
            {{ $t("project.documents.dialogs.DocumentsFileUploadDialog.importFoldersBtn") }}
          </AppButton>
        </v-col>
        <v-col :class="{ 'text-center': !(canUploadFolders) }">
          <AppButton
            color="tertiary"
            dark
            @click="onFileInputClick"
          >
            <font-awesome-icon :icon="['fal', 'file-upload']" class="body-1 mr-2"></font-awesome-icon>
            {{ $t("project.documents.dialogs.DocumentsFileUploadDialog.importFilesBtn") }}
          </AppButton>
        </v-col>
      </v-row>
      <v-row v-if="selectedDocuments.length > 0">
        <v-col>
          <v-list>
            <template v-for="(document, i) in selectedDocuments">
              <v-list-item :key="`doc-${i}`" class="px-0">
                <v-list-item-icon>
                  <template v-if="document.type === 'file'">
                    <ClosdFildersIcon :document="document.fileObject" />
                  </template>
                  <template v-if="document.type === 'folder'">
                    <ClosdFildersIcon :document="document" />
                  </template>
                </v-list-item-icon>
                <v-list-item-content>
                  <template v-if="document.type === 'file'">
                    {{ document.fileObject.name }}
                  </template>
                  <template v-if="document.type === 'folder'">
                    {{ document.name }}
                  </template>
                </v-list-item-content>
                <v-list-item-action>
                  <AppButton
                    icon
                    class="delete-button"
                    @click="() => onDocumentDelete(i)"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'trash']"
                    ></font-awesome-icon>
                  </AppButton>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="i !== selectedDocuments.length - 1"
                :key="`divider-${i}`"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
      <input
        ref="fileInput"
        class="d-none"
        type="file"
        :multiple="true"
        @change="buildAddFiles($event.target.files)"
      />
      <input
        ref="folderInput"
        class="d-none"
        type="file"
        :multiple="true"
        :webkitdirectory="true"
        :allowdirs="true"
        :directory="true"
        @change="buildSelectedFolder($event.target.files)"
      />
    </template>
  </AppDialog>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import IManageFilePickerDialog from '@/common/imanage/IManageFilePickerDialog'
import IManageFolderPickerDialog from '@/common/imanage/IManageFolderPickerDialog'
import { BtoMB } from '@/common/utils/sizes'
import { dialogMixin } from '@/mixins/dialogMixin'
import { FileToUpload, FolderToUpload } from '@/models/documentToUpload.model'
import { GET_DOCUMENTS_BY_ID, POST_FILDERS_FROM_IMANAGE } from '@/store/modules/documents/action_types'
import { START_UPLOAD } from '@/store/modules/documents-upload-files/action_types'
import { ADD_FILES, ADD_FOLDER, REMOVE_DOCUMENT } from '@/store/modules/documents-upload-files/mutation_types'
import { GET_ROOM_PROVIDERS } from '@/store/modules/room/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import AppButton from '../../../common/buttons/AppButton'
import AppDialog from '../../../common/dialogs/AppDialog'
import ClosdFildersIcon from '../../../common/filders/ClosdFildersIcon'

export default {
  name: 'DocumentsFileUploadDialog',
  components: { AppDialog, IManageFolderPickerDialog, IManageFilePickerDialog, ClosdFildersIcon, AppButton },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      dragInProgress: false,
      folderKey: 1,
      iManageFilesPickerDialogIsOpen: false,
      iManageFoldersPickerDialogIsOpen: false,
      providersMaxSizes: [],
      trashIconColor: -1,
    }
  },
  computed: {
    ...mapState('documentsUploadFiles', ['selectedDocuments']),
    ...mapState('documents', ['documents', 'postFildersFromImanagePending']),
    ...mapState('room', ['currentRoom', 'roomProvidersPending']),
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('room', ['currentUserRights', 'isDataRoom', 'isCurrentUserPm']),
    ...mapGetters('documentsBreadcrumb', ['hasTodoFolderOrChild']),
    canUploadFolders () {
      return this.currentUserRights.canCreateFolders && !this.hasTodoFolderOrChild
    },
    dialogTitle () {
      return this.canUploadFolders && !this.hasTodoFolderOrChild ? this.$t('project.documents.dialogs.DocumentsFileUploadDialog.dialogTitlePm') : this.$t('project.documents.dialogs.DocumentsFileUploadDialog.dialogTitleGuests')
    },
    maxSignableSize () {
      if (!this.isDataRoom) {
        const availableSize = this.providersMaxSizes.length !== 0 ? BtoMB(Math.max(...this.providersMaxSizes)) : this.maxUploadFileSize
        return Number(availableSize).toFixed(1)
      } else {
        // We won't sign, so let's not compute anything, there's no size
        return 0
      }
    },
    maxSignablePages () {
      return process.env.VUE_APP_MAX_SIGNABLE_PAGES
    },
    maxUploadFileSize () {
      return this.isDataRoom
        ? process.env.VUE_APP_MAX_FILE_SIZE_DR
        : process.env.VUE_APP_MAX_FILE_SIZE
    },
  },
  async created () {
    if (!this.isDataRoom && this.isCurrentUserPm) {
      if (!this.roomProvidersPending && !this.currentRoom.providers) {
        await this.GET_ROOM_PROVIDERS(this.mnemo)
      }
      this.providersMaxSizes = this.currentRoom.providers?.map(o => o.maxSize) || []
    }
  },
  methods: {
    ...mapActions('documentsUploadFiles', [START_UPLOAD]),
    ...mapActions('documents', [POST_FILDERS_FROM_IMANAGE, GET_DOCUMENTS_BY_ID]),
    ...mapActions('room', [GET_ROOM_PROVIDERS]),
    ...mapMutations('documentsUploadFiles', [ADD_FILES, ADD_FOLDER, REMOVE_DOCUMENT]),
    onFileInputClick () {
      this.$refs.fileInput.click()
    },
    onFolderInputClick () {
      this.$refs.folderInput.click()
    },
    buildAddFiles (files) {
      const array = []
      for (const file of files) {
        array.push(new FileToUpload(file, 'document', this.documents.id))
      }
      this.ADD_FILES(array)
      this.$refs.fileInput.value = null
    },
    buildSelectedFolder (files) {
      const folder = new FolderToUpload(files[0].webkitRelativePath.split('/')[0], [], this.documents.id)
      for (const file of files) {
        const path = file.webkitRelativePath.split('/')
        path.pop()
        if (path.length === 1) {
          folder.children.push(new FileToUpload(file, 'document'))
        } else {
          path.splice(0, 1)
          this.pushInFolder(file, path, folder)
        }
      }
      this.ADD_FOLDER(folder)
    },
    pushInFolder (file, path, parent) {
      const pathExist = parent.children.find(child => child.name === path[0])
      if (pathExist) {
        if (path.length === 1) {
          pathExist.children.push(new FileToUpload(file, 'document'))
        } else {
          const newPath = cloneDeep(path)
          newPath.splice(0, 1)
          this.pushInFolder(file, newPath, pathExist)
        }
      } else {
        if (path.length === 1) {
          parent.children.push(new FolderToUpload(path[0], [new FileToUpload(file, 'document')]))
        } else {
          parent.children.push(new FolderToUpload(path[0], []))
          const newParent = parent.children.find(child => child.name === path[0])
          const newPath = cloneDeep(path)
          newPath.splice(0, 1)
          this.pushInFolder(file, newPath, newParent)
        }
      }
    },
    async dropFileUpload (e) {
      const dataTransferItemList = e.dataTransfer ? e.dataTransfer.items : 'null'
      const fileEntries = []
      const queue = []
      for (let i = 0; i < dataTransferItemList.length; i++) {
        queue.push(dataTransferItemList[i].webkitGetAsEntry())
      }
      while (queue.length > 0) {
        const entry = queue.shift()
        if (entry.isFile) {
          fileEntries.push(await this.getFileFromFileEntry(entry, this.documents.id))
        } else if (entry.isDirectory && this.canUploadFolders) {
          fileEntries.push(await this.getFolderFromFileEntry(entry, this.documents.id))
        } else if (entry.isDirectory && !this.canUploadFolders) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.documents.dialogs.DocumentsFileUploadDialog.noFolderAsGuest'),
          })
        }
      }
      this.ADD_FILES(fileEntries)
    },
    async getFolderFromFileEntry (fileEntry, parentId) {
      const folder = parentId !== undefined
        ? new FolderToUpload(fileEntry.name, [], parentId)
        : new FolderToUpload(fileEntry.name, [])
      const readEntries = await this.readEntriesPromise(fileEntry.createReader())
      while (readEntries.length > 0) {
        const entry = readEntries.shift()
        if (entry.isFile) {
          folder.children.push(await this.getFileFromFileEntry(entry))
        } else if (entry.isDirectory) {
          folder.children.push(await this.populateFolder(entry))
        }
      }
      return folder
    },
    async readEntriesPromise (directoryReader) {
      try {
        return await new Promise((resolve, reject) => {
          directoryReader.readEntries(resolve, reject)
        })
      } catch (err) {
        console.error(err)
      }
    },
    async getFileFromFileEntry (fileEntry, parentId) {
      try {
        return parentId !== undefined
          ? new FileToUpload(await new Promise((resolve, reject) => fileEntry.file(resolve, reject)), 'document', parentId)
          : new FileToUpload(await new Promise((resolve, reject) => fileEntry.file(resolve, reject)), 'document')
      } catch (err) {
        console.error(err)
      }
    },
    async populateFolder (fileEntry) {
      const folder = new FolderToUpload(fileEntry.name, [])
      const readEntries = await this.readEntriesPromise(fileEntry.createReader())
      while (readEntries.length > 0) {
        const entry = readEntries.shift()
        if (entry.isFile) {
          folder.children.push(await this.getFileFromFileEntry(entry))
        } else if (entry.isDirectory) {
          folder.children.push(await this.populateFolder(entry))
        }
      }
      return folder
    },
    openIManageFilesPicker () {
      this.iManageFilesPickerDialogIsOpen = true
    },
    openIManageFoldersPicker () {
      this.iManageFoldersPickerDialogIsOpen = true
    },
    onImanageFiles (files) {
      const data = {}
      data.parentId = this.documents.id
      data.files = files.selected.split(',')
      this.preparePostFildersFromIManage(data)
    },
    onImanageFolders (folders) {
      const data = {}
      data.parentId = this.documents.id
      data.folders = folders.selected.split(',')
      this.preparePostFildersFromIManage(data)
    },
    async preparePostFildersFromIManage (data) {
      try {
        await this.POST_FILDERS_FROM_IMANAGE({
          mnemo: this.mnemo,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.documents.dialogs.DocumentsFileUploadDialog.postFildersFromIManageSuccess'),
        })
        this.GET_DOCUMENTS_BY_ID({
          mnemo: this.mnemo,
          id: this.documents.id,
          queryObject: { markRecent: true },
        })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.dialogs.DocumentsFileUploadDialog.postFildersFromIManageError'),
        })
      } finally {
        this.closeDialog()
      }
    },
    startSelectedDocumentsUpload () {
      this.START_UPLOAD()
    },
    onDocumentDelete (documentIndex) {
      this.REMOVE_DOCUMENT(documentIndex)
    },
  },
}
</script>

<style scoped lang="scss">
.DocumentsFileUpload-dropzone {
  background-color: #efeff1;
  border: 2px dashed #e0e0e0;
  border-radius: 7px;
  z-index: 1;
  top: 5px;
  position: sticky;
}
.delete-button.v-btn--icon {
  color: var(--v-grey-lighten1);

&:hover {
   color: var(--v-error-base);
 }
}

</style>
