import { GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'

import documentsService from '../../../services/documents.service'

export const actions = {
  openDocumentsCreateNewFolder: function ({ commit }) {
    commit('SET_IS_OPEN', true)
  },
  closeDocumentsCreateNewFolder: function ({ commit }) {
    commit('SET_LOADING', false)
    commit('SET_IS_OPEN', false)
  },
  createNewFolder: function ({ commit, rootGetters, rootState, state, dispatch }, newFolderName) {
    commit('SET_LOADING', true)
    const data = {
      name: newFolderName,
      parentId: rootState.documents.documents.id,
    }
    return documentsService.createNewFolder(rootGetters['room/roomMnemo'], data)
      .then(() => {
        dispatch(`documents/${GET_DOCUMENTS_BY_ID}`, {
          mnemo: undefined,
          id: undefined,
          queryObject: {
            markRecent: true,
          },
        }, { root: true })
        commit('SET_IS_OPEN', false)
        commit('SET_LOADING', false)
      })
      .catch(() => {
        commit('SET_LOADING', false)
      })
  },
}
