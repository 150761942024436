<template>
  <app-dropdown-button v-if="isCurrentUserPm || currentUserRights.canAddQuestions"
                       :menu-items="menuItems"
                       variant="brand"
  >
    {{ $t('project.question-answer.QuestionAnswerTopBar.add') }}
  </app-dropdown-button>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { QUESTION_ANSWER_ADD_ROUTE_NAME } from '@/router'

  export default {
    name: 'QuestionAnswerTopBarAdd',
    data () {
      return {
        QUESTION_ANSWER_ADD_ROUTE_NAME,
        menuItems: [
          {
            title: this.$t('project.question-answer.QuestionAnswerTopBar.newQuestion'),
            action: () => { this.addNewQuestion() },
            icon: 'plus',
          },
          {
            title: this.$t('project.question-answer.QuestionAnswerTopBar.importQuestionsAndAnswers'),
            action: () => { this.openImportDialog() },
            img: '/img/icons/excel_custom.svg',
          },
        ],
      }
    },
    computed: {
      ...mapGetters('room', ['isCurrentUserPm', 'currentUserRights']),
    },
    methods: {
      addNewQuestion () {
        this.$router.push({ name: QUESTION_ANSWER_ADD_ROUTE_NAME })
      },
      openImportDialog () {
        this.$emit('open-import-excel-dialog')
      },
    },
  }
</script>
