function sortNumbering (numberingA, numberingB, desc = false) {
  const a1 = numberingA.toString().split('.')
  const b1 = numberingB.toString().split('.')
  const len = Math.max(a1.length, b1.length)

  for (let i = 0; i < len; i++) {
    const _a = +a1[i] || 0
    const _b = +b1[i] || 0

    if (_a !== _b) {
      return (_a < _b ? -1 : 1) * (desc ? -1 : 1)
    }
  }

  return 0
}

function numericSort (a, b, desc = false) {
  if (!a) {
    return desc ? -1 : 1
  }

  if (!b) {
    return desc ? 1 : -1
  }

  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
  }) * (desc ? -1 : 1)
}

function dateSort (a, b, desc = false) {
  const sortValue = new Date(a) < new Date(b) ? -1 : 1

  return desc ? sortValue * -1 : sortValue
}

export { dateSort, numericSort, sortNumbering }
