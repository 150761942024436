<template>
    <div class="px-2">
        <h3>{{$t('archives.steps.ArchivesAddStep3.title')}}</h3>
        <ArchivesShareModule v-model="shareConfig" />
    </div>
</template>

<script>
import ArchivesShareModule from '../ArchivesShareModule'
export default {
  name: 'ArchivesAddStep3',
  components: {
    ArchivesShareModule,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      shareConfig: {
        fileRights: [],
        notify: false,
        notifyMessage: '',
      },
    }
  },
  watch: {
    shareConfig: {
      handler (newConfig) {
        this.$emit('input',
          this.value.map((file) => ({ ...file, objectToPost: { ...file.objectToPost, ...newConfig } })))
      },
      deep: true,
    },
  },
  created () {
    // Use value defaults, they may have been changed by the user previously
    // We consider that the first element is the reference
    this.shareConfig = {
      fileRights: this.value[0].objectToPost.fileRights,
      notify: !!this.value[0].objectToPost.notify,
      notifyMessage: this.value[0].objectToPost.notifyMessage,
    }
  },

}
</script>
