import {
  DELETE_USER,
  DOWNLOAD_INVOICES,
  GET_ACTIVE_ROOMS,
  GET_CLOSED_ROOMS,
  GET_SEATS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_BRANDING,
  GET_SUBSCRIPTION_INVOICES,
  GET_SUBSCRIPTION_USERS,
  PATCH_SUB_ADMIN,
  PATCH_SUB_ADMIN_BILLING,
  PATCH_SUB_ADMIN_USER,
  POST_NEW_SUBSCRIBER,
  REQUEST_FEATURE_QUOTE,
  RESTORE_ROOM,
  POST_SUBSCRIPTION_TEAM,
  PATCH_SUBSCRIPTION_TEAM,
  GET_SUBSCRIPTION_TEAMS,
  DELETE_SUBSCRIPTION_TEAM,
} from './action_types'
import {
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_PENDING,
  SET_SUBSCRIPTION_BRANDING,
  SET_SUBSCRIPTION_BRANDING_PENDING,
  SET_SUBSCRIPTION_ERROR,
  SET_SUBSCRIPTION_USERS,
  SET_SUBSCRIPTION_USERS_PENDING,
  SET_SUBSCRIPTION_USERS_ERROR,
  SET_PATCH_SUB_ADMIN_PENDING,
  REMOVE_PATCH_SUB_ADMIN_PENDING,
  SET_PATCH_SUB_ADMIN_ERROR,
  SET_USER_SUB_ADMIN,
  SET_PATCH_SUB_ADMIN_BILLING_PENDING,
  REMOVE_PATCH_SUB_ADMIN_BILLING_PENDING,
  SET_PATCH_SUB_ADMIN_BILLING_ERROR,
  SET_USER_SUB_ADMIN_BILLING,
  SET_PATCH_SUB_ADMIN_USER_PENDING,
  REMOVE_PATCH_SUB_ADMIN_USER_PENDING,
  SET_PATCH_SUB_ADMIN_USER_ERROR,
  SET_USER_SUB_ADMIN_USER,
  SET_DELETE_USER_PENDING,
  SET_DELETE_USER_ERROR,
  SET_POST_NEW_SUBSCRIBER_PENDING,
  SET_POST_NEW_SUBSCRIBER_ERROR,
  SET_ACTIVE_ROOMS,
  SET_ACTIVE_ROOMS_PENDING,
  SET_ACTIVE_ROOMS_ERROR,
  SET_CLOSED_ROOMS,
  SET_CLOSED_ROOMS_PENDING,
  SET_CLOSED_ROOMS_ERROR,
  SET_POST_SUBSCRIPTION_TEAM_PENDING,
  SET_PATCH_SUBSCRIPTION_TEAM_PENDING,
  SET_SUBSCRIPTION_TEAMS_PENDING,
  SET_SUBSCRIPTION_TEAMS,
  SET_SUBSCRIPTION_TEAMS_ERROR,
  SET_SUBSCRIPTION_INVOICES,
  SET_SUBSCRIPTION_INVOICES_PENDING,
  SET_SUBSCRIPTION_INVOICES_ERROR,
  SET_DOWNLOAD_INVOICES_PENDING,
  SET_DOWNLOAD_INVOICES_ERROR,
  SET_RESTORE_ROOM_PENDING,
  SET_RESTORE_ROOM_ERROR,
  SET_SEATS,
  SET_SEATS_PENDING,
  SET_SEATS_ERROR,
  SET_QUOTE_REQUEST_PENDING,
  SET_DELETE_SUBSCRIPTION_TEAM_PENDING,
} from './mutation_types'
import service from '../../../services/subscription.service'

export const actions = {
  async [GET_SEATS] ({ commit }) {
    commit(SET_SEATS_PENDING, true)
    commit(SET_SEATS_ERROR, null)
    try {
      const { data } = await service.getSeats()
      commit(SET_SEATS, data.seats)
    } catch (error) {
      commit(SET_SEATS_ERROR, error)
      throw error
    } finally {
      commit(SET_SEATS_PENDING, false)
    }
  },
  async [RESTORE_ROOM] ({ commit }, params) {
    commit(SET_RESTORE_ROOM_PENDING, true)
    try {
      await service.restoreRoom(params.roomId, params.data)
    } catch (error) {
      commit(SET_RESTORE_ROOM_ERROR, error)
      throw error
    } finally {
      commit(SET_RESTORE_ROOM_PENDING, false)
    }
  },
  async [POST_NEW_SUBSCRIBER] ({ commit }, data) {
    commit(SET_POST_NEW_SUBSCRIBER_PENDING, true)
    try {
      await service.postNewSubscriber(data)
    } catch (error) {
      commit(SET_POST_NEW_SUBSCRIBER_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_NEW_SUBSCRIBER_PENDING, false)
    }
  },
  async [DELETE_USER] ({ commit }, userId) {
    commit(SET_DELETE_USER_PENDING, true)
    try {
      await service.deleteUser(userId)
    } catch (error) {
      commit(SET_DELETE_USER_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_USER_PENDING, false)
    }
  },
  async [PATCH_SUB_ADMIN_USER] ({ commit, state }, { userId, data }) {
    commit(SET_PATCH_SUB_ADMIN_USER_PENDING, userId)
    try {
      const response = await service.patchSubAdminUser(userId, data)
      if (response.status === 200) {
        commit(SET_USER_SUB_ADMIN_USER, userId)
      }
    } catch (error) {
      commit(SET_PATCH_SUB_ADMIN_USER_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_PATCH_SUB_ADMIN_USER_PENDING,
        state.patchSubAdminUserPendingIds.indexOf(userId))
    }
  },
  async [PATCH_SUB_ADMIN_BILLING] ({ commit, state }, { userId, data }) {
    commit(SET_PATCH_SUB_ADMIN_BILLING_PENDING, userId)
    try {
      const response = await service.patchSubAdminBilling(userId, data)
      if (response.status === 200) {
        commit(SET_USER_SUB_ADMIN_BILLING, userId)
      }
    } catch (error) {
      commit(SET_PATCH_SUB_ADMIN_BILLING_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_PATCH_SUB_ADMIN_BILLING_PENDING,
        state.patchSubAdminBillingPendingIds.indexOf(userId))
    }
  },
  async [PATCH_SUB_ADMIN] ({ commit, state }, { userId, data }) {
    commit(SET_PATCH_SUB_ADMIN_PENDING, userId)
    try {
      const response = await service.patchSubAdmin(userId, data)
      if (response.status === 200) {
        commit(SET_USER_SUB_ADMIN, userId)
      }
    } catch (error) {
      commit(SET_PATCH_SUB_ADMIN_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_PATCH_SUB_ADMIN_PENDING,
        state.patchSubAdminPendingIds.indexOf(userId))
    }
  },
  async [GET_SUBSCRIPTION_USERS] ({ commit }) {
    commit(SET_SUBSCRIPTION_USERS_PENDING, true)
    try {
      const response = await service.getSubscriptionUsers()
      commit(SET_SUBSCRIPTION_USERS, response.data)
    } catch (error) {
      commit(SET_SUBSCRIPTION_USERS_ERROR, error)
      throw error
    } finally {
      commit(SET_SUBSCRIPTION_USERS_PENDING, false)
    }
  },
  async [GET_ACTIVE_ROOMS] ({ commit }) {
    commit(SET_ACTIVE_ROOMS_PENDING, true)
    try {
      const response = await service.getActiveRooms()
      commit(SET_ACTIVE_ROOMS, response.data)
    } catch (error) {
      commit(SET_ACTIVE_ROOMS_ERROR, error)
      throw error
    } finally {
      commit(SET_ACTIVE_ROOMS_PENDING, false)
    }
  },
  async [GET_CLOSED_ROOMS] ({ commit }) {
    commit(SET_CLOSED_ROOMS_PENDING, true)
    try {
      const response = await service.getClosedRooms()
      commit(SET_CLOSED_ROOMS, response.data)
    } catch (error) {
      commit(SET_CLOSED_ROOMS_ERROR, error)
      throw error
    } finally {
      commit(SET_CLOSED_ROOMS_PENDING, false)
    }
  },
  async [POST_SUBSCRIPTION_TEAM] ({ commit }, data) {
    commit(SET_POST_SUBSCRIPTION_TEAM_PENDING, true)
    try {
      await service.postSubscriptionTeam(data)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_POST_SUBSCRIPTION_TEAM_PENDING, false)
    }
  },
  async [PATCH_SUBSCRIPTION_TEAM] ({ commit }, { teamId, data }) {
    commit(SET_PATCH_SUBSCRIPTION_TEAM_PENDING, true)
    try {
      await service.patchSubscriptionTeam(teamId, data)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_PATCH_SUBSCRIPTION_TEAM_PENDING, false)
    }
  },
  async [DELETE_SUBSCRIPTION_TEAM] ({ commit }, teamId) {
    commit(SET_DELETE_SUBSCRIPTION_TEAM_PENDING, true)
    try {
      await service.deleteSubscriptionTeam(teamId)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_DELETE_SUBSCRIPTION_TEAM_PENDING, false)
    }
  },
  async [GET_SUBSCRIPTION_TEAMS] ({ commit }) {
    commit(SET_SUBSCRIPTION_TEAMS_PENDING, true)
    commit(SET_SUBSCRIPTION_TEAMS_ERROR, null)
    try {
      const response = await service.getSubscriptionTeams()
      commit(SET_SUBSCRIPTION_TEAMS, response.data.data)
    } catch (error) {
      console.error(error)
      commit(SET_SUBSCRIPTION_TEAMS_ERROR, error)
      throw error
    } finally {
      commit(SET_SUBSCRIPTION_TEAMS_PENDING, false)
    }
  },
  async [DOWNLOAD_INVOICES] ({ commit }, data) {
    commit(SET_DOWNLOAD_INVOICES_PENDING, true)
    try {
      await service.downloadInvoices(data)
    } catch (error) {
      commit(SET_DOWNLOAD_INVOICES_ERROR, error)
      throw error
    } finally {
      commit(SET_DOWNLOAD_INVOICES_PENDING, false)
    }
  },
  async [GET_SUBSCRIPTION_INVOICES] ({ commit }) {
    commit(SET_SUBSCRIPTION_INVOICES_PENDING, true)
    try {
      const response = await service.getSubscriptionInvoices()
      commit(SET_SUBSCRIPTION_INVOICES, response.data)
    } catch (error) {
      commit(SET_SUBSCRIPTION_INVOICES_ERROR, error)
      throw error
    } finally {
      commit(SET_SUBSCRIPTION_INVOICES_PENDING, false)
    }
  },
  async [GET_SUBSCRIPTION] ({ commit }, subId) {
    commit(SET_SUBSCRIPTION_PENDING, true)
    try {
      const response = await service.getSubscription(subId)
      commit(SET_SUBSCRIPTION, response.data.subscription)
    } catch (error) {
      commit(SET_SUBSCRIPTION_ERROR, error)
      throw error
    } finally {
      commit(SET_SUBSCRIPTION_PENDING, false)
    }
  },
  async [GET_SUBSCRIPTION_BRANDING] ({ commit }, subdomain) {
    commit(SET_SUBSCRIPTION_BRANDING_PENDING, true)
    try {
      const response = await service.getSubscriptionBranding(subdomain)
      commit(SET_SUBSCRIPTION_BRANDING, response.data)
    } catch (error) {
      commit(SET_SUBSCRIPTION_BRANDING, null)
      console.error(error)
    } finally {
      commit(SET_SUBSCRIPTION_BRANDING_PENDING, false)
    }
  },
  async [REQUEST_FEATURE_QUOTE] ({ commit }, data) {
    commit(SET_QUOTE_REQUEST_PENDING, true)

    try {
      await service.requestFeatureQuote(data)
    } finally {
      commit(SET_QUOTE_REQUEST_PENDING, false)
    }
  },
}
