/**
 * @typedef LastTitleChildResult An object representing the last child `item` of the rootTitle and
 * the child's parent `parent`
 * @property {Object} parent - The parent of the item currently being traversed
 * @property {?Object} item - The current last child as the titles are being traversed
 */

/**
 * @callback lastTitleChildTraversalCallback
 * @param {LastTitleChildResult} - The current param and last child found
 */
/**
 * Search the informations of the last child of a title rootTitle while continuing the search
 * as long as the last child is a title with children
 * @param {Object} rootTitle - The title to begin the search for
 * @param {lastTitleChildTraversalCallback} [traversalCallback] - A function given the current parent and last child found for each traversal
 * @returns {?LastTitleChildResult}
 */
function getLastChild (rootTitle, traversalCallback = () => {}) {
  let currentLastChild = rootTitle.children[rootTitle.children.length - 1]
  let currentLastChildParent = rootTitle
  traversalCallback({ parent: currentLastChildParent, item: currentLastChild })
  while (currentLastChild?.type === 'title' && currentLastChild.children.length !== 0) {
    currentLastChildParent = currentLastChild
    currentLastChild = currentLastChild.children[currentLastChild.children.length - 1]
    traversalCallback({ parent: currentLastChildParent, item: currentLastChild })
  }
  if (currentLastChild) {
    return {
      parent: currentLastChildParent,
      item: currentLastChild,
    }
  } else {
    return null
  }
}

export {
  getLastChild,
}
