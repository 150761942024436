<template>
  <v-expansion-panel class="EnvelopeTemplateAddSettingsDocument-expansionPanel">
    <v-expansion-panel-header>
      <SigningChecklistDocumentHeader :file="file" :validator="validator" @deleteFile="isDeleteFileDialogOpened = true" />
    </v-expansion-panel-header>
    <v-expansion-panel-content class="py-8">

      <template v-if="validator.document.objectToPost.$anyError">
        <AppAlertInPage variant="error">{{$t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettingsDocument.validatorAlertContent')}}</AppAlertInPage>
      </template>

      <SigningChecklistSection>
        <template #title>
          1. {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettingsDocument.sectionTitles.documentTitle') }} <span class="indigo--text">*</span>
        </template>
        <template #content>
          <SigningChecklistSectionDocumentTitle
            :file-name="file.objectToPost.title"
            :is-editing-limited="false"
            :title-validator="validator.document.objectToPost.title"
            @title-change="setTemplateDocumentTitle"
          />
        </template>
      </SigningChecklistSection>

      <SigningChecklistSection>
        <template #title>
          2. {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettingsDocument.sectionTitles.signatureType') }} <span class="indigo--text">*</span>
        </template>
        <template #content>
          <SigningChecklistSectionDocumentSignatureType
            :file-size="file.size"
            :has-mercury-option-activated="false"
            :has-signers-order="false"
            :is-editing-limited="false"
            :signature-type-validator="validator.document.objectToPost.signatureType"
            :signature-type="file.objectToPost.signatureType"
            is-handwritten-signature-disabled
            @setQualifiedDSWarningDialog="isQualifiedDSWarningDialogOpened = $event"
            @signature-type-change="setTemplateDocumentSignatureType"
          />
        </template>
      </SigningChecklistSection>

      <SigningChecklistSection>
        <template #title>
          3. {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettingsDocument.sectionTitles.envelopeType') }} <span class="indigo--text">*</span>
        </template>
        <template #content>
          <SigningChecklistSectionDocumentEnvelopeType
            :envelope-type-validator="validator.document.objectToPost.envelopeType"
            :envelope-type="file.objectToPost.envelopeType"
            :is-editing-limited="false"
            :is-multi-envelope-child="false"
            @envelope-type-change="setTemplateDocumentEnvelopeType"
          />
        </template>
      </SigningChecklistSection>

      <SigningChecklistSection>
        <template #title>
          4. {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettingsDocument.sectionTitles.signersCount') }}
          <span v-if="file.objectToPost.envelopeType === ENVELOPE_TYPES.SINGLE"
                class="indigo--text"
          >
            *
          </span>
        </template>
        <template #content>
          <SigningChecklistSectionDocumentSignersCount
            :file-envelope-type="file.objectToPost.envelopeType"
            :file-signers="file.objectToPost.signers"
            :is-editing-limited="false"
            :signers-validator="validator.document.objectToPost.signers"
            @signers-change="setTemplateDocumentSigners"
          />
        </template>
      </SigningChecklistSection>

      <SigningChecklistSection>
        <template #title>
          5. {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettingsDocument.sectionTitles.sharingOptions') }}
        </template>
        <template #content>
          <SigningChecklistSectionDocumentSharingOptions
            :share-type="file.objectToPost.shareType"
            :file-rights="file.objectToPost.rights || { isCustom : true }"
            :file-with-users="file.objectToPost.withUsers"
            :is-editing-limited="false"
            @send-notif-change="setTemplateDocumentShareSendNotif"
            @share-type-change="setTemplateDocumentShareType"
            @with-users-change="setTemplateDocumentShareWithUsers"
          />
        </template>
      </SigningChecklistSection>
    </v-expansion-panel-content>

    <DeleteFileDialog v-if="isDeleteFileDialogOpened"
                      @close="isDeleteFileDialogOpened = false"
                      @confirm="onConfirmDeleteFile"
    />

    <QualifiedDSSignatureWarningDialog v-if="isQualifiedDSWarningDialogOpened"
                                       @close="isQualifiedDSWarningDialogOpened = false"
                                       @confirm="onConfirmQualifiedSignatureSelection"
    />
  </v-expansion-panel>
</template>

<script>
import { defineComponent } from 'vue'

import { typedMapMutations } from '@/common/utils/store'
import AppAlertInPage from '@/design-system/alert/AppAlertInPage.vue'
import SigningChecklistDocumentHeader from '@/project/signing-checklist/add/SigningChecklistDocumentHeader.vue'
import SigningChecklistSection from '@/project/signing-checklist/add/SigningChecklistSection.vue'
import SigningChecklistSectionDocumentEnvelopeType from '@/project/signing-checklist/add/SigningChecklistSectionDocumentEnvelopeType.vue'
import SigningChecklistSectionDocumentSharingOptions from '@/project/signing-checklist/add/SigningChecklistSectionDocumentSharingOptions.vue'
import SigningChecklistSectionDocumentSignatureType from '@/project/signing-checklist/add/SigningChecklistSectionDocumentSignatureType.vue'
import SigningChecklistSectionDocumentSignersCount from '@/project/signing-checklist/add/SigningChecklistSectionDocumentSignersCount.vue'
import SigningChecklistSectionDocumentTitle from '@/project/signing-checklist/add/SigningChecklistSectionDocumentTitle.vue'
import { ENVELOPE_TYPES } from '@/project/signing-checklist/constants'
import QualifiedDSSignatureWarningDialog from '@/project/signing-checklist/dialogs/QualifiedDSSignatureWarningDialog.vue'
import DeleteFileDialog from '@/project/signing-checklist/envelope-templates/dialogs/DeleteFileDialog.vue'

export default defineComponent({
  name: 'EnvelopeTemplateAddSettingsDocument',
  components: {
    AppAlertInPage,
    DeleteFileDialog,
    QualifiedDSSignatureWarningDialog,
    SigningChecklistDocumentHeader,
    SigningChecklistSection,
    SigningChecklistSectionDocumentEnvelopeType,
    SigningChecklistSectionDocumentSharingOptions,
    SigningChecklistSectionDocumentSignatureType,
    SigningChecklistSectionDocumentSignersCount,
    SigningChecklistSectionDocumentTitle,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isDeleteFileDialogOpened: false,
      isQualifiedDSWarningDialogOpened: false,
      ENVELOPE_TYPES,
    }
  },
  methods: {
    ...typedMapMutations('envelopeTemplates', [
      'REMOVE_DOCUMENT_FROM_TEMPLATE_DRAFT',
      'SET_TEMPLATE_DOCUMENT_ENVELOPE_TYPE',
      'SET_TEMPLATE_DOCUMENT_SHARE_SEND_NOTIF',
      'SET_TEMPLATE_DOCUMENT_SHARE_TYPE',
      'SET_TEMPLATE_DOCUMENT_SHARE_WITH_USERS',
      'SET_TEMPLATE_DOCUMENT_SIGNATURE_TYPE',
      'SET_TEMPLATE_DOCUMENT_SIGNERS',
      'SET_TEMPLATE_DOCUMENT_TITLE',
    ]),
    onConfirmDeleteFile (file) {
      this.isDeleteFileDialogOpened = false
      this.REMOVE_DOCUMENT_FROM_TEMPLATE_DRAFT(file)
    },
    onConfirmQualifiedSignatureSelection () {
      this.isQualifiedDSWarningDialogOpened = false
      this.setTemplateDocumentSignatureType('ds.qualified')
    },
    setTemplateDocumentEnvelopeType (envelopeType) {
      this.SET_TEMPLATE_DOCUMENT_ENVELOPE_TYPE({
        fileId: this.file.id,
        envelopeType: envelopeType,
      })
    },
    setTemplateDocumentShareSendNotif (sendNotif) {
      this.SET_TEMPLATE_DOCUMENT_SHARE_SEND_NOTIF({
        fileId: this.file.id,
        sendNotif: sendNotif,
      })
    },
    setTemplateDocumentShareType (shareType) {
      this.SET_TEMPLATE_DOCUMENT_SHARE_TYPE({
        fileId: this.file.id,
        shareType: shareType,
      })
    },
    setTemplateDocumentShareWithUsers (withUsers) {
      this.SET_TEMPLATE_DOCUMENT_SHARE_WITH_USERS({
        fileId: this.file.id,
        withUsers: withUsers,
      })
    },
    setTemplateDocumentSignatureType (signatureType) {
      this.SET_TEMPLATE_DOCUMENT_SIGNATURE_TYPE({
        fileId: this.file.id,
        signatureType: signatureType,
      })

      this.$emit('signatureTypeChange', this.file, signatureType)
    },
    setTemplateDocumentSigners (signers) {
      this.SET_TEMPLATE_DOCUMENT_SIGNERS({
        fileId: this.file.id,
        signers: Number(signers),
      })
    },
    setTemplateDocumentTitle (title) {
      this.SET_TEMPLATE_DOCUMENT_TITLE({
        fileId: this.file.id,
        title: title.trim(),
      })
    },
  },
})
</script>

<style scoped lang="scss">
.EnvelopeTemplateAddSettingsDocument-expansionPanel {
  &:after {
    border-top: none !important;
  }
  ::v-deep .v-expansion-panel-header {
    border-bottom: 1px solid #DEE2E6;
    border-radius: 6px !important;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  ::v-deep .v-messages {
    min-height: 0;
  }
}
</style>
