<template>
   <v-container class="ResetPassword-container mx-auto">
    <LoginTopbar />
    <v-row class="ResetPassword-content">
      <v-col class="d-flex justify-center">
         <AppCard :elevation="0"
                  class="ResetPassword-card"
        >
          <v-row class="mb-3">
            <v-col class="text-h2">{{ $t('login.views.ResetPassword.title') }}</v-col>
          </v-row>
           <v-row>
            <v-col>
              <AppTextField v-model.trim="login.email"
                            :label="$t('login.views.ResetPassword.email')"
                            type="email"
                            class="ResetPassword-input"
                            :error-messages="emailErrors"
                            maxlength="255"
                            @blur="validateEmail"
                            @keypress.enter="prepareResetPassword"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <AppTextField v-model="login.password"
                            :type="displayPassword ? 'text' : 'password'"
                            :label="$t('login.views.ResetPassword.password')"
                            class="ResetPassword-input"
                            :error-messages="passwordErrors"
                            maxlength="255"
                            @blur="validatePassword"
                            @keypress.enter="prepareResetPassword"
              >
                <template #append>
                    <AppButton icon
                               @click="displayPassword = !displayPassword"
                    >
                        <font-awesome-icon v-if="displayPassword" :icon="['fas', 'eye']"></font-awesome-icon>
                        <font-awesome-icon v-else :icon="['fas', 'eye-slash']"></font-awesome-icon>
                    </AppButton>
                </template>
              </AppTextField>
            </v-col>
          </v-row>
          <v-row v-if="isNatixisAccount" style="margin-top:-10px">
            <v-col>
              <v-row class="mt-2">
                  <v-col>
                      <p>{{$t('login.views.ResetPassword.natixisRule')}}</p>
                  </v-col>
              </v-row>
              <v-row v-for="(rule, i) in natixisValidator" :key="`rule-${i}`" :class="rule.ruleIsValid() ? 'success--text' : 'error--text'" style="margin-top:-20px;">
                <v-col cols="1">
                      <i class="fal" :class="rule.ruleIsValid() ? 'fa-check' : 'fa-xmark'"></i>
                </v-col>
                <v-col cols="11" style="margin-left:-10px">
                      <span>{{$t(rule.ruleName)}}</span>
                </v-col>
              </v-row>
              <v-row style="margin-top:-20px; color : #6c757d">
                <v-col cols="1">
                      <i class="fal fa-info"></i>
                </v-col>
                <v-col cols="11" style="margin-left:-10px">
                      <span>{{$t('login.views.ResetPassword.natixisRules.userInfo')}}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col>
              <AppTextField v-model="login.passwordConfirmation"
                            :type="displayConfirmPassword ? 'text' : 'password'"
                            :label="$t('login.views.ResetPassword.confirmPassword')"
                            class="ResetPassword-input"
                            :error-messages="passwordConfirmErrors"
                            maxlength="255"
                            @blur="validatePasswordConfirmation"
                            @keypress.enter="prepareResetPassword"
              >
                <template #append>
                    <AppButton icon
                               @click="displayConfirmPassword = !displayConfirmPassword"
                    >
                        <font-awesome-icon v-if="displayConfirmPassword" :icon="['fas', 'eye']"></font-awesome-icon>
                        <font-awesome-icon v-else :icon="['fas', 'eye-slash']"></font-awesome-icon>
                    </AppButton>
                </template>
              </AppTextField>
            </v-col>
          </v-row>
          <v-col class="text-center mt-3">
              <template>
                <AppButton color="primary"
                           :loading="loading"
                           @click="prepareResetPassword"
                >
                  {{$t('login.views.ResetPassword.resetPassword')}}
                </AppButton>
              </template>
            </v-col>
          </AppCard>
          </v-col>
          </v-row>
          <BackToClosd />
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { createNamespacedHelpers } from 'vuex'

import { RESET_PASSWORD } from '@/store/modules/login/action_types'

import AppCard from '../../common/AppCard'
import AppTextField from '../../common/AppTextField'
import AppButton from '../../common/buttons/AppButton.vue'
import { Login } from '../../models/login.model'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'
import BackToClosd from '../BackToClosd'
import LoginTopbar from '../LoginTopbar.vue'

const { mapState, mapActions } = createNamespacedHelpers('login')

export default {
  name: 'ResetPassword',
  components: { AppButton, LoginTopbar, AppCard, AppTextField, BackToClosd },
  mixins: [validationMixin],
  validations: {
    login: {
      email: { required, email },
      password: { required, minLength: minLength(process.env.VUE_APP_ENABLE_NATIXIS_PASSWORD === 'true' ? 12 : 8) },
      passwordConfirmation: { required, sameAs: sameAs(function () { return this.login.password }) },
    },
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      login: new Login('', '', '', '', '', '', this.token),
      displayPassword: false,
      isNatixisAccount: false,
      displayConfirmPassword: false,
    }
  },
  computed: {
    ...mapState(['loading']),
    submitButtonDisabled () {
      return !this.$v.login.email.$dirty ||
        this.$v.login.email.$anyError ||
        this.$v.login.password.$anyError ||
        this.$v.login.passwordConfirmation.$anyError ||
        !this.$v.login.passwordConfirmation.$dirty ||
        (this.isNatixisAccount ? !this.natixisValidatorIsValid : false)
    },
    natixisValidator () {
      return [
        {
          ruleName: this.$t('login.views.ResetPassword.natixisRules.minLength'),
          ruleIsValid: () => { return this.login.password ? this.login.password.length >= 12 : false },
        },
        {
          ruleName: this.$t('login.views.ResetPassword.natixisRules.maj'),
          ruleIsValid: () => { return this.login.password ? /(?=[a-z])/.test(this.login.password) : false },
        },
        {
          ruleName: this.$t('login.views.ResetPassword.natixisRules.min'),
          ruleIsValid: () => { return this.login.password ? /(?=[A-Z])/.test(this.login.password) : false },
        },
        {
          ruleName: this.$t('login.views.ResetPassword.natixisRules.number'),
          ruleIsValid: () => { return this.login.password ? /(?=[^a-zA-Z\s\u00C0-\u00FF])/.test(this.login.password) : false },
        },
      ]
    },
    natixisValidatorIsValid () {
      return this.natixisValidator.every(rule => rule.ruleIsValid())
    },
    emailErrors () {
      const errors = []
      if (!this.$v.login.email.$dirty) return errors
      !this.$v.login.email.email && errors.push(this.$t('login.views.ResetPassword.rules.email.valid'))
      !this.$v.login.email.required && errors.push(this.$t('login.views.ResetPassword.rules.email.required', { fieldName: this.$t('login.views.ResetPassword.email') }))
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.login.password.$dirty) return errors
      !this.$v.login.password.minLength && errors.push(this.$t(process.env.VUE_APP_ENABLE_NATIXIS_PASSWORD === 'true' ? 'login.views.ResetPassword.rules.password.minNatixis' : 'login.views.ResetPassword.rules.password.min', { fieldName: this.$t('login.views.ResetPassword.password') }))
      !this.$v.login.password.required && errors.push(this.$t('login.views.ResetPassword.rules.password.required', { fieldName: this.$t('login.views.ResetPassword.password') }))
      return errors
    },
    passwordConfirmErrors () {
      const errors = []
      if (!this.$v.login.passwordConfirmation.$dirty) return errors
      !this.$v.login.passwordConfirmation.sameAs && errors.push(this.$t('login.views.ResetPassword.rules.confirmPassword.equals', { fieldName: this.$t('login.views.ResetPassword.confirmPassword') }))
      !this.$v.login.passwordConfirmation.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('login.views.ResetPassword.confirmPassword') }))
      return errors
    },
  },
  mounted () {
    this.isNatixisAccount = process.env.VUE_APP_ENABLE_NATIXIS_PASSWORD === 'true'
  },
  methods: {
    ...mapActions([RESET_PASSWORD]),
    validateEmail () {
      this.$v.login.email.$touch()
    },
    validatePassword () {
      this.$v.login.password.$touch()
    },
    validatePasswordConfirmation () {
      this.$v.login.passwordConfirmation.$touch()
    },
    async prepareResetPassword () {
      if (this.loading) {
        return
      }
      this.$v.login.$touch()
      if (!this.$v.login.$invalid) {
        try {
          await this.RESET_PASSWORD(this.login)
        } catch (error) {
          const errorCode = error?.response?.data?.errorCode
          let msgError = ''
          if (errorCode === 'ERR_INCORRECT_CREDENTIALS') {
            msgError = this.$t('login.views.ResetPassword.resetPasswordError.incorrectCredentials')
          } else if (errorCode === 'ERR_EXPIRED_RESET_TOKEN') {
            msgError = this.$t('login.views.ResetPassword.resetPasswordError.expiredToken')
          } else if (errorCode === 'ERR_PASSWORD_NOT_FRESH') {
            msgError = this.$t('login.views.ResetPassword.resetPasswordError.passwordNotFresh')
          } else if (errorCode === 'ERR_PASSWORD_NOT_MATCHING') {
            msgError = this.$t('login.views.ResetPassword.resetPasswordError.passwordNotMatching')
          } else if (errorCode === 'ERR_PASSWORD_NOT_VALID') {
            msgError = !this.isNatixisAccount
            ? this.$t('login.views.ResetPassword.resetPasswordError.passwordNotValidGeneric')
            : this.$t('login.views.ResetPassword.resetPasswordError.passwordNotValidCustom')
          } else {
            msgError = this.$t('login.views.ResetPassword.resetPasswordError.generic')
          }
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: msgError,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .ResetPassword-container {
    max-width: 1100px;
    max-height: 840px;
  }
  .ResetPassword-divider{
    margin-left: -36px;
    margin-right: -36px
  }
  .ResetPassword-content{
    margin-top: 10%;
  }
  .ResetPassword-card {
    box-shadow: 0 0 10px 0 rgba(94, 86, 105, 0.1) !important;
    border: 1px solid #bdbdbd;
    padding: 25px 35px;
    width: 538px;
  }
  .ResetPassword-validation-check{
    font-size: 30px;
  }
  ::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #ffe3e1 !important;
  }
  ::v-deep .ResetPassword-input .v-input__slot {
    background-color: #fff;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')}{
    .ResetPassword-content{
      margin-top: 0;
    }
  }
</style>
