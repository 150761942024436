import { render, staticRenderFns } from "./AppHeadline.vue?vue&type=template&id=0a4f135e&scoped=true"
import script from "./AppHeadline.vue?vue&type=script&lang=ts"
export * from "./AppHeadline.vue?vue&type=script&lang=ts"
import style0 from "./AppHeadline.vue?vue&type=style&index=0&id=0a4f135e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a4f135e",
  null
  
)

/* custom blocks */
import block0 from "./AppHeadline.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports