import biblesService from '@/services/bibles.service'

import {
  DELETE_BIBLE,
  EXPORT_BIBLE_TO_IMANAGE,
  GET_BIBLE,
  GET_BIBLES,
  POST_BIBLE,
  POST_BIBLE_RIGHTS,
} from './action_types'
import {
  ADD_BIBLE,
  REMOVE_BIBLE_PENDING,
  SET_BIBLES,
  SET_BIBLES_ERROR,
  SET_BIBLES_PENDING,
  SET_BIBLE_ERROR,
  SET_BIBLE_PENDING,
  SET_EXPORT_BIBLE_TO_IMANAGE_ERROR,
  SET_EXPORT_BIBLE_TO_IMANAGE_PENDING,
  SET_POST_BIBLE_PENDING,
} from './mutation_types'

export const actions = {
  async [DELETE_BIBLE] ({ commit }, { mnemo, bibleId }) {
    return biblesService.deleteBible(mnemo, bibleId)
  },
  async [EXPORT_BIBLE_TO_IMANAGE] ({ commit, rootState }, { mnemo, bibleId, data }) {
    commit(SET_EXPORT_BIBLE_TO_IMANAGE_PENDING, true)
    try {
      await biblesService.exportBibleToImanage(mnemo, bibleId, data, rootState.imanage.authCode?.imConfig)
    } catch (error) {
      commit(SET_EXPORT_BIBLE_TO_IMANAGE_ERROR, error)
      throw error
    } finally {
      commit(SET_EXPORT_BIBLE_TO_IMANAGE_PENDING, false)
    }
  },
  async [GET_BIBLE] ({ commit, state }, { mnemo, bibleId }) {
    commit(SET_BIBLE_PENDING, bibleId)
    try {
      return await biblesService.getBible(mnemo, bibleId)
    } catch (error) {
      commit(SET_BIBLE_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_BIBLE_PENDING, state.biblePendingIds.indexOf(bibleId))
    }
  },
  async [GET_BIBLES] ({ commit, state }, { mnemo, params, isPolling = false }) {
    if (!params) {
      commit(SET_BIBLES_PENDING, true)
    }
    try {
      const data = await biblesService.getBibles(mnemo, params, isPolling)
      if (Array.isArray(data.data)) {
        commit(SET_BIBLES, data.data)
      }
    } catch (error) {
      commit(SET_BIBLES_ERROR, error)
    } finally {
      commit(SET_BIBLES_PENDING, false)
    }
  },
  async [POST_BIBLE] ({ commit }, { mnemo, data }) {
    commit(SET_POST_BIBLE_PENDING, true)
    try {
      const postData = await biblesService.postBible(mnemo, data)
      commit(ADD_BIBLE, postData.data)
    } finally {
      commit(SET_POST_BIBLE_PENDING, false)
    }
  },
  async [POST_BIBLE_RIGHTS] ({ commit }, { mnemo, bibleId, data }) {
    return biblesService.postBibleRights(mnemo, bibleId, data)
  },
}
