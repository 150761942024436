<template>
  <div class="summary-message-actions">
    <div class="thumbs-button">
      <Popover :autoCloseDelay="3000" :disabled="feedbackUpvote">
        <template #trigger>
          <AppTooltip top :disabled="feedbackUpvote">
            <template #activator="{attrs, on}">
              <app-button
                :class="{'thumb-up-button': true, 'active': feedbackUpvote}"
                type="ghost"
                v-bind="attrs"
                data-locator="positive-feedback-button"
                v-on="on"
                @click="feedbackUpvote ? null : upVote()"
              >
                <template #left-icon>
                  <app-icon icon-name="thumbs-up" :icon-weight="feedbackUpvote ? 'fas' : 'far'" />
                </template>
              </app-button>
            </template>
            {{ $t('project.documents.ai-summary.DocumentsAISummaryMessageActions.feedbackThumbsUpTooltip') }}
          </AppTooltip>
        </template>
        <template #content>
          <span>{{ $t('project.documents.ai-summary.DocumentsAISummaryMessageActions.feedbackThumbsUpPopover') }}</span>
        </template>
      </Popover>
      <AppTooltip top :disabled="feedbackDownvote">
        <template #activator="{attrs, on}">
          <app-button
            :class="{'thumb-down-button': true, 'active': feedbackDownvote}"
            type="ghost"
            v-bind="attrs"
            data-locator="negative-feedback-button"
            v-on="on"
            @click="feedbackDownvote ? null : downVote()"
          >
            <template #left-icon>
              <app-icon icon-name="thumbs-down" :icon-weight="feedbackDownvote ? 'fas' : 'far'" />
            </template>
          </app-button>
        </template>
        {{ $t('project.documents.ai-summary.DocumentsAISummaryMessageActions.feedbackThumbsDownTooltip') }}
      </AppTooltip>
    </div>

    <app-button
      v-if="canProgrammaticallyCopySummary"
      data-locator="copy-summary-button"
      class="copy-button"
      type="ghost"
      @click="copySummary"
    >
      <template #left-icon>
        <app-icon icon-name="copy" icon-weight="far" />
      </template>
      {{ copyButtonText }}
    </app-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import Popover from '@/common/popover/Popover.vue'
import aiSummariesService from '@/services/ai-summaries.service'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

enum SummaryFeedback {
  FEEDBACK_POSITIVE = 1,
  FEEDBACK_NONE = 0,
  FEEDBACK_NEGATIVE = -1,
}

export default defineComponent({
  name: 'DocumentsAISummaryMessageActions',
  components: { AppTooltip, Popover },
  emits: ['copy'],
  props: {
    summaryId: {
      type: Number,
      required: true,
    },
    summaryFeedback: {
      type: Number,
      default: SummaryFeedback.FEEDBACK_NONE,
    },
  },
  data () {
    return {
      copyButtonText: this.$t('common.copy'),
      feedbackUpvote: this.summaryFeedback === SummaryFeedback.FEEDBACK_POSITIVE,
      feedbackDownvote: this.summaryFeedback === SummaryFeedback.FEEDBACK_NEGATIVE,
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    canProgrammaticallyCopySummary () {
      return navigator.clipboard
    },
  },
  methods: {
    async copySummary () {
      this.$emit('copy')
      this.copyButtonText = `${this.$t('common.copied')} !`

      setTimeout(() => {
        this.copyButtonText = this.$t('common.copy')
      }, 3000)
    },
    async upVote () {
      try {
        await aiSummariesService.patchAISummaryUpvote(this.roomMnemo, this.summaryId)
        this.feedbackUpvote = true
        this.feedbackDownvote = false
      } catch {
        this.feedbackUpvote = false
        this.feedbackDownvote = this.summaryFeedback === SummaryFeedback.FEEDBACK_NEGATIVE
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('common.msgFailErrorOccurred'),
        })
      }
    },
    async downVote () {
      try {
        await aiSummariesService.patchAISummaryDownvote(this.roomMnemo, this.summaryId)
        this.feedbackDownvote = true
        this.feedbackUpvote = false
      } catch {
        this.feedbackUpvote = this.summaryFeedback === SummaryFeedback.FEEDBACK_POSITIVE
        this.feedbackDownvote = false
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('common.msgFailErrorOccurred'),
        })
      }
    },
  },
})
</script>

<style scoped lang="scss">
.summary-message-actions {
  display: flex;
  padding-top: 16px;
  justify-content: space-between;

  .copy-button {
    & ::v-deep span:first-of-type {
      column-gap: 4px;
    }
  }
  .thumbs-button {
    display: flex;
    column-gap: 4px;

    .thumb-up-button, .thumb-down-button {
      padding-inline: 8px;

      &.active {
        background-color: var(--v-grey-lighten5);
        cursor: default;
      }
    }
  }
}
</style>
