import { render, staticRenderFns } from "./PrepareSignatureDeleteInitialsTagDialog.vue?vue&type=template&id=33f89450&scoped=true"
import script from "./PrepareSignatureDeleteInitialsTagDialog.vue?vue&type=script&lang=js"
export * from "./PrepareSignatureDeleteInitialsTagDialog.vue?vue&type=script&lang=js"
import style0 from "./PrepareSignatureDeleteInitialsTagDialog.vue?vue&type=style&index=0&id=33f89450&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f89450",
  null
  
)

export default component.exports