<template>
  <v-menu
    offset-y
    rounded="lg"
    :open-on-hover="!$vuetify.breakpoint.xs"
    transition="slide-y-transition"
    bottom
    left
  >
    <template v-slot:activator="{ on, attrs, value }">
      <AppBarMenuItem :label="$t('common.app-bar.HelpMenuItem.help')"
                      :hover="value"
                      v-bind="attrs"
                      v-on="on"
      >
        <AppBarMenuItemIcon icon="circle-question" />
      </AppBarMenuItem>
    </template>

    <v-list dense>
      <v-list-item-group>
        <v-list-item :href="urlFaq" target="_blank">
          <v-list-item-title>
              {{ $t("common.app-bar.HelpMenuItem.helpCenter") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="webinarLinkShouldBeDisplayed"
                     :href="webinarUrl"
                     target="_blank"
        >
          <v-list-item-title>
              {{ $t("common.app-bar.HelpMenuItem.webinar") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import AppBarMenuItem from '@/common/app-bar/AppBarMenuItem.vue'
import AppBarMenuItemIcon from '@/common/app-bar/AppBarMenuItemIcon.vue'

import i18n from '../../i18n'

export default {
  name: 'HelpMenuItem',
  components: { AppBarMenuItemIcon, AppBarMenuItem },
  data () {
    return {
      webinarUrl: process.env.VUE_APP_WEBINAR_URL,
    }
  },
  computed: {
    urlFaq () {
      return `${process.env.VUE_APP_KB_URL}/${this.$i18n.locale}`
    },
    webinarLinkShouldBeDisplayed () {
      return i18n.locale === 'fr' && this.webinarUrl
    },
  },
}
</script>
