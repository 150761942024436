<template>
  <div>
    <app-text variant="large-bold" class="grey--text text--darken-3">
      {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSignatureTags.title') }}
    </app-text>
    <app-text variant="small-regular" class="grey--text text--darken-2 mb-8">
      {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSignatureTags.subTitle') }}
    </app-text>

    <PrepareSignatureTemplate v-if="envelopeTemplateToPrepare"
                              :key="envelopeTemplateToPrepare.id"
                              :envelope-to-prepare="envelopeTemplateToPrepare"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import PrepareSignatureTemplate from '@/project/signing-checklist/prepare-signature/PrepareSignatureTemplate.vue'
import type { EnvelopeTemplateToPrepare } from '@/project/views/EnvelopeTemplateAdd.vue'

export default defineComponent({
  name: 'EnvelopeTemplateAddSignatureTags',
  components: { PrepareSignatureTemplate },
  props: {
    envelopeTemplateToPrepare: {
      type: Object as PropType<EnvelopeTemplateToPrepare>,
      required: true,
    },
  },
})
</script>
