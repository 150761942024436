import { EXPORT_SIGNED_DOCUMENT_TO_IMANAGE, GET_SIGNED_DOCUMENT, GET_SIGNED_DOCUMENTS } from '@/store/modules/signed-documents/action_types'
import {
  SET_EXPORT_SIGNED_DOCUMENT_TO_IMANAGE_PENDING,
  SET_EXPORT_SIGNED_DOCUMENT_TO_IMANAGE_ERROR,
  SET_SIGNED_DOCUMENT_PENDING,
  SET_SIGNED_DOCUMENT_ERROR,
  REMOVE_SIGNED_DOCUMENT_PENDING,
} from '@/store/modules/signed-documents/mutation_types'

import signedDocumentsService from '../../../services/signedDocuments.service'

export const actions = {
  async [GET_SIGNED_DOCUMENT] ({ commit, state }, { mnemo, envelopeId }) {
    commit(SET_SIGNED_DOCUMENT_PENDING, envelopeId)
    try {
      return await signedDocumentsService.getSignedDocument(mnemo, envelopeId)
    } catch (error) {
      commit(SET_SIGNED_DOCUMENT_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_SIGNED_DOCUMENT_PENDING,
        state.signedDocumentPendingIds.indexOf(envelopeId))
    }
  },
  async [EXPORT_SIGNED_DOCUMENT_TO_IMANAGE] ({ commit, rootState }, { mnemo, envelopeId, data }) {
    commit(SET_EXPORT_SIGNED_DOCUMENT_TO_IMANAGE_PENDING, true)
    try {
      await signedDocumentsService.exportSignedDocumentToImanage(mnemo, envelopeId, data, rootState.imanage.authCode?.imConfig)
    } catch (error) {
      commit(SET_EXPORT_SIGNED_DOCUMENT_TO_IMANAGE_ERROR, error)
      throw error
    } finally {
      commit(SET_EXPORT_SIGNED_DOCUMENT_TO_IMANAGE_PENDING, false)
    }
  },
  async [GET_SIGNED_DOCUMENTS] ({ commit }, { mnemo, params }) {
    commit('SET_LOADING', true)
    try {
      const response = await signedDocumentsService.getSignedDocuments(mnemo, params)
      commit('SET_SIGNED_DOCUMENTS', response.data)
      return response.data
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      commit('SET_LOADING', false)
    }
  },
  deleteOriginal (store, { mnemo, data }) {
    return signedDocumentsService.deleteOriginal(mnemo, data)
  },
  async shareOriginal (store, { mnemo, data }) {
    try {
      return await signedDocumentsService.shareOriginal(mnemo, data)
    } catch (e) {
      console.error(e)
      throw e
    }
  },
  setSearchQuery ({ commit }, searchQuery) {
    commit('SET_SEARCH_QUERY', searchQuery)
  },
  setSignDateModel ({ commit }, signDateModel) {
    commit('SET_SIGN_DATE_MODEL', signDateModel)
  },
  setFiltersStartDate ({ commit }, startDate) {
    commit('SET_FILTERS_START_DATE', startDate)
  },
  setFiltersEndDate ({ commit }, endDate) {
    commit('SET_FILTERS_END_DATE', endDate)
  },
  setFiltersSignatories ({ commit }, signatories) {
    commit('SET_FILTERS_SIGNATORIES', signatories)
  },
}
