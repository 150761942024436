<template>
  <AppDialog :is-open="true"
             :ok-text="$t('common.confirm')"
             size="l"
             @ok="confirm"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('project.signing-checklist.dialogs.SignerOrderSignatureTypeIncompatibilityWarningDialog.title') }}</template>

    <template #body>
      <p>{{ $t('project.signing-checklist.dialogs.SignerOrderSignatureTypeIncompatibilityWarningDialog.warning') }}</p>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'
export default {
  name: 'SignerOrderSignatureTypeIncompatibilityWarningDialog',
  mixins: [dialogMixin],
  components: { AppDialog },
  methods: {
    confirm () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
