<template>
  <AppTooltip v-if="isSubscriber && !isArchivingHidden" top :disabled="disableTooltip">
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" @click.prevent="handleClick">
        <slot :isArchivingEnabled="isArchivingEnabled"></slot>
      </div>
    </template>
    <span>{{ tooltipContent }}</span>
  </AppTooltip>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import { ARCHIVES_ADD_ROUTE_NAME } from '@/router'
import { SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN } from '@/store/modules/archives/mutation_types'

export default {
  name: 'ArchiveAddAccessBase',
  components: {
    AppTooltip,
  },
  props: {
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    routeOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('user', ['isSubscriber', 'isArchivingEnabled', 'isArchivingHidden']),
    tooltipContent () {
      return this.isArchivingEnabled
        ? this.$t('common.archives.ArchiveAddAccessBase.archiveTooltip')
        : this.$t('common.archives.ArchiveAddAccessBase.inactiveTooltip')
    },
  },
  methods: {
    ...mapMutations('archives', [SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN]),
    handleClick () {
      if (!this.isArchivingEnabled) {
        this.SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN(true)
      } else {
        this.$router.push({
          name: ARCHIVES_ADD_ROUTE_NAME,
          ...this.routeOptions,
        })
      }
    },
  },
}
</script>
