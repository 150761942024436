<template>
  <div class="signing-checklist-selected-swap-file">
    <div class="signing-checklist-selected-swap-file__file-data">
      <ClosdFildersIcon
        :document="displayedFile"
        :signed-file="displayedFile.signedOnClosd"
      />
      <div>
        <div class="d-flex align-center column-gap-2">
          <app-text as="span" variant="small-regular" :class="{ 'grey--text text-decoration-line-through' : selectedFile }">
            {{ swapFile.title }}
          </app-text>
          <template v-if="selectedFile">
            <app-text as="span" variant="small-bold">
              {{ selectedFile.objectToPost.fileName }}
            </app-text>
            <font-awesome-icon v-if="selectedFile.status === 'success' || !selectedFile.objectToPost.fileRaw"
                          class="signing-checklist-selected-swap-file__status-success"
                          :icon="['fad', 'check-circle']"
            />
          </template>
        </div>
        <app-text v-if="displayedFileSize" as="span" variant="small-regular">
          <app-filesize-display :value="displayedFileSize" />
        </app-text>
      </div>
      <div class="ml-auto align-self-start d-flex column-gap-2">
        <slot></slot>
      </div>
    </div>
    <transition name="app-fade">
      <v-progress-linear v-if="isSelectedFileFromLocal && selectedFile.status !== 'success'" :value="selectedFile.progress"
                         background-color="grey lighten-4"
                         class="mt-4"
                         color="primary"
                         height="6"
                         rounded
      />
    </transition>
    <div>
      <v-switch v-model="retainTags"
                :label="$t('project.signing-checklist.SwapFilesSelectorItem.retainTagsLabel')"
                :disabled="!selectedFile"
                @change="onchangeRetainsTags"
      />
    </div>
  </div>
</template>

<script>
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon'

export default {
  name: 'SwapFilesSelectorItem',
  components: {
    ClosdFildersIcon,
  },
  props: {
    selectedFile: {
      type: Object,
      default: () => {},
    },
    swapFile: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      retainTags: this.selectedFile?.objectToPost?.retainTags || true,
    }
  },
  computed: {
    displayedFile () {
      return this.selectedFile ? this.selectedFile : this.swapFile.envelope.file
    },
    displayedFileSize () {
      return this.selectedFile ? this.selectedFile.size : this.swapFile.envelope.file.size
    },
    isSelectedFileFromLocal () {
      return this.selectedFile && this.selectedFile.objectToPost.fileRaw
    },
  },
  methods: {
    onchangeRetainsTags () {
      this.selectedFile.objectToPost.retainTags = this.retainTags
      this.$emit('update-retain-tags', this.selectedFile)
    },
  },
  watch: {
    selectedFile () {
      if (this.selectedFile && this.selectedFile?.objectToPost?.retainTags !== this.retainTags) {
        this.onchangeRetainsTags()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.signing-checklist-selected-swap-file {
  &__file-data {
    display: flex;
    align-items: center;
  }

  &__status {
    display: flex;
    column-gap: 12px;

    svg {
      width: 18px;
      height: 18px;
    }

    &-success,
    &-error {
      --fa-primary-opacity: 1;
      --fa-secondary-opacity: 1;
    }
    &-success {
      --fa-primary-color: #f0f0f0;
      --fa-secondary-color: #14B88F;
    }
    &-error {
      --fa-primary-color: #b99c6b;
      --fa-secondary-color: rgb(245, 230, 178);
    }
  }
}
</style>
