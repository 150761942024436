<template>
  <div class="selected-items-bar">
    <span class="mr-2 selected-items-count">
      {{ $tc("common.selectedItems", allSelectedChecklistItems.length) }}
    </span>

    <AppButton
      class="mr-10"
      text
      color="primary"
      @click="unselectAll"
    >
      {{ $t("common.cancelSelection") }}
    </AppButton>

    <MultiSelectBarButton :disabled="noTaskSelected" @click="openShareSelectionDialog">
      <font-awesome-icon
        class="mr-2"
        :icon="['fas', 'share']"
      ></font-awesome-icon>
      {{ $t("common.share") }}
    </MultiSelectBarButton>

    <MultiSelectBarButton @click="deleteSelectionDialogIsOpen = true">
      <font-awesome-icon
        class="mr-2"
        :icon="['fas', 'trash']"
      ></font-awesome-icon>
      {{ $t("common.delete") }}
    </MultiSelectBarButton>

    <ChecklistTableDeleteSelectionDialog v-if="deleteSelectionDialogIsOpen"
                                         @close="deleteSelectionDialogIsOpen = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import ChecklistTableDeleteSelectionDialog from './dialogs/ChecklistTableDeleteSelectionDialog'
import AppButton from '../../common/buttons/AppButton'
import MultiSelectBarButton from '../../common/buttons/MultiSelectBarButton'
import { CLEAR_ITEMS_SELECTION } from '../../store/modules/checklist/action_types'
import { SET_SHARE_TASKS_DIALOG } from '../../store/modules/checklist/mutation_types'

export default {
  name: 'ChecklistTableBulkActions',
  components: {
    AppButton,
    ChecklistTableDeleteSelectionDialog,
    MultiSelectBarButton,
  },
  data () {
    return {
      deleteSelectionDialogIsOpen: false,
    }
  },
  computed: {
    ...mapGetters('checklist', ['allSelectedChecklistItems', 'selectedTasks']),
    ...mapGetters('room', ['roomMnemo']),
    noTaskSelected () {
      return this.selectedTasks.length === 0
    },
  },
  beforeDestroy () {
    this.CLEAR_ITEMS_SELECTION()
  },
  methods: {
    ...mapActions('checklist', [CLEAR_ITEMS_SELECTION]),
    ...mapMutations('checklist', [SET_SHARE_TASKS_DIALOG]),
    unselectAll () {
      this.CLEAR_ITEMS_SELECTION()
    },
    openShareSelectionDialog () {
      this.SET_SHARE_TASKS_DIALOG({
        isOpen: true,
        tasksToShare: this.selectedTasks,
      })
    },
  },
}
</script>
