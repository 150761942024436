<template>
  <div class="my-signatures">
    <SignedDocumentsHeader :selected-signed-documents.sync="selectedSignedDocuments"
                           :no-data="noData"
                           :no-results="noResults"
                           :display.sync="display"
                           @shareOriginal="onShareOriginal"
                           @selectedSignature="selectedSignedDocuments = $event"
    />
    <template v-if="loading && filteredSignedDocuments.length === 0">
      <v-row>
        <v-col cols="12"
               md="4"
               lg="3"
        >
          <v-skeleton-loader type="article, actions"/>
        </v-col>
      </v-row>

    </template>
    <template v-if="noData">
      <v-row style="margin-top: 100px;">
        <v-col>
          <div class="d-flex flex-column justify-center align-center">
            <img src="/img/projet_signature.svg" alt="" width="325" class="mb-8">
            <div class="text-center noDataText" style="width: 300px">
              {{$t('project.signed-documents.SignedDocuments.noData')}}
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="noResults">
      <v-row class="mt-16">
        <v-col>
          <div class="d-flex flex-column justify-center align-center">
            <img src="/img/projet_signature.svg" alt="" width="300">
            <div class="mt-5 text-center" style="width: 300px">
              {{$t('project.signed-documents.SignedDocuments.noResults')}}
            </div>
            <AppButton color="primary" @click="deleteSearchAndFilters">{{$t('project.signed-documents.SignedDocuments.noResultsButton')}}</AppButton>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <template v-if="display === DISPLAY.grid">
        <v-row class="mt-3">
          <v-col v-for="signedDocument in filteredSignedDocuments"
                 :key="signedDocument.id"
                 cols="12"
                 md="3"
                 lg="3"
          >
            <SignedDocumentsItemGrid :signedDocument="signedDocument"
                                     :selected-signed-documents.sync="selectedSignedDocuments"
                                     @deleteOriginal="onDeleteOriginal"
                                     @shareOriginal="onShareOriginal"
                                     @openSignatoriesListModal="openSignatoriesListModal"
                                     @exportToImanage="onExportToImanage"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="display === DISPLAY.table">
        <v-row>
          <v-col>
            <SignedDocumentsTable :signedDocuments="filteredSignedDocuments"
                                  :selected-signed-documents.sync="selectedSignedDocuments"
                                  @deleteOriginal="onDeleteOriginal"
                                  @shareOriginal="onShareOriginal"
                                  @openSignatoriesListModal="openSignatoriesListModal"
                                  @exportToImanage="onExportToImanage"
            />
          </v-col>
        </v-row>
      </template>
    </template>
    <DeleteSignedDocument v-if="deleteOriginal.document.length > 0"
                          :is-open.sync="deleteOriginal.isOpen"
                          :documents.sync="deleteOriginal.document"
    />
    <SharePane v-model="shareOriginal.isOpen"
               :documents.sync="shareOriginal.originals"
               @removeDocumentToShare="removeDocumentToShare"
    ></SharePane>
    <SignedDocumentsSignatoriesListDialog v-if="signatoriesListModalSignedDocument"
                                         v-model="signatoriesListModalIsOpen"
                                         :mnemo="mnemo"
                                         :signed-document.sync="signatoriesListModalSignedDocument"
    />
    <IManageFolderPickerDialog :is-open.sync="imanageFolderPickerIsOpen"
                               @folders="prepareExportSignedDocumentsToImanage"
    />

    <PolyOfficeDialog v-if="polyOfficeEnabled && dialogIsOpen" @callback="polyOfficeDialogCallback"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import IManageFolderPickerDialog from '@/common/imanage/IManageFolderPickerDialog'
import PolyOfficeDialog, { POLY_OFFICE_DIALOG_STATUS_SUCCESS } from '@/common/polyOffice/PolyOfficeDialog.vue'
import { EXPORT_SIGNED_DOCUMENT_TO_IMANAGE, GET_SIGNED_DOCUMENTS } from '@/store/modules/signed-documents/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import DeleteSignedDocument from './dialogs/DeleteSignedDocument'
import SharePane from './pane/SharePane'
import SignedDocumentsHeader from './SignedDocumentsHeader'
import SignedDocumentsItemGrid from './SignedDocumentsItemGrid'
import SignedDocumentsSignatoriesListDialog from './SignedDocumentsSignatoriesListDialog'
import SignedDocumentsTable from './SignedDocumentsTable'
import AppButton from '../../common/buttons/AppButton'
import { DISPLAY } from '../../projects/display'
import { DISABLE_MEMBERS } from '../../store/modules/document-share-picker/action_types'

export default {
  name: 'SignedDocuments',
  components: {
    PolyOfficeDialog,
    IManageFolderPickerDialog,
    SignedDocumentsSignatoriesListDialog,
    AppButton,
    SharePane,
    DeleteSignedDocument,
    SignedDocumentsTable,
    SignedDocumentsItemGrid,
    SignedDocumentsHeader,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      DISPLAY,
      fetched: false,
      display: 'grid',
      selectedSignedDocuments: [],
      deleteOriginal: {
        document: [],
        isOpen: false,
      },
      shareOriginal: {
        originals: [],
        isOpen: false,
      },
      signatoriesListModalIsOpen: false,
      signatoriesListModalSignedDocument: null,
      imanageFolderPickerIsOpen: false,
      filesToExportToImanage: null,
    }
  },
  metaInfo () {
    return {
      title: this.$t('project.signed-documents.SignedDocuments.metaTitle'),
    }
  },
  computed: {
    ...mapState('signedDocuments', ['loading']),
    ...mapGetters('room', ['roomMnemo']),
    ...mapState('groups', ['groups']),
    ...mapGetters('signedDocuments', ['filteredSignedDocuments', 'signedDocumentsToDisplay']),
    ...mapState('polyOffice', ['dialogIsOpen', 'dialogSuccess']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    noData () {
      return this.signedDocumentsToDisplay.length === 0 && this.fetched
    },
    noResults () {
      return this.filteredSignedDocuments.length === 0 && this.signedDocumentsToDisplay.length > 0
    },
  },
  async mounted () {
    await this.GET_SIGNED_DOCUMENTS({ mnemo: this.roomMnemo, params: { withRights: true } })
    this.fetched = true
    this.GET_GROUPS(this.mnemo)
  },
  methods: {
    ...mapActions('signedDocuments', [GET_SIGNED_DOCUMENTS, 'setSignDateModel', 'setFiltersStartDate', 'setFiltersEndDate', 'setFiltersSignatories', 'setSearchQuery', EXPORT_SIGNED_DOCUMENT_TO_IMANAGE]),
    ...mapActions('documentSharePicker', [DISABLE_MEMBERS]),
    ...mapActions('groups', ['GET_GROUPS']),
    onDeleteOriginal (original) {
      this.deleteOriginal.document = [original]
      this.deleteOriginal.isOpen = true
    },
    onShareOriginal (original) {
      if (Array.isArray(original)) {
        this.shareOriginal.originals = original
      } else {
        this.shareOriginal.originals = [original]
        if (original.rights) {
          if (original.rights.readSigned.groups.length > 0) {
            const membersToDisable = this.groups.filter(group => group.id === original.id)
              .flatMap(group => group.members)

            if (membersToDisable.length) {
              this.DISABLE_MEMBERS(membersToDisable)
            }
          }

          if (original.rights.readSigned.users.length > 0) {
            const membersToDisable = this.groups.flatMap(group => group.members)
              .filter(member => original.rights.readSigned.users.find(signer => signer === member.id))

            if (membersToDisable.length) {
              this.DISABLE_MEMBERS(membersToDisable)
            }
          }
        }
      }
      this.shareOriginal.isOpen = true
    },
    deleteSearchAndFilters () {
      this.setFiltersSignatories([])
      this.setFiltersStartDate(null)
      this.setFiltersEndDate(null)
      this.setSignDateModel([])
      this.setSearchQuery('')
    },
    openSignatoriesListModal (signedDocument) {
      this.signatoriesListModalIsOpen = true
      this.signatoriesListModalSignedDocument = signedDocument
    },
    removeDocumentToShare (document) {
      this.shareOriginal.originals.splice(this.shareOriginal.originals.findIndex(o => o.id === document.id), 1)
    },
    async prepareExportSignedDocumentsToImanage (target) {
      try {
        await this.EXPORT_SIGNED_DOCUMENT_TO_IMANAGE({
          mnemo: this.mnemo,
          envelopeId: this.filesToExportToImanage,
          data: {
            targetId: target.selected,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.signed-documents.SignedDocuments.exportSignedDocumentToImanageSuccess'),
        })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signed-documents.SignedDocuments.exportSignedDocumentToImanageError'),
        })
      }
    },
    onExportToImanage (signedDocuments) {
      this.filesToExportToImanage = signedDocuments
      this.imanageFolderPickerIsOpen = true
    },
    polyOfficeDialogCallback (data) {
      if (data.status === POLY_OFFICE_DIALOG_STATUS_SUCCESS) {
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.dialogSuccess,
        })
      }
    },
  },
}
</script>

<style scoped>
/* transition list */
.list-complete-item {
  transition: all 0.5s;
}
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.list-complete-leave-active {
  position: absolute;
  z-index: -1;
}
</style>
