<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             @cancel="closeDialog"
  >
    <template #title>{{ $t("project.signing-checklist.prepare-signature.PrepareSignatureAutoTagsDialog.title") }}</template>

    <template #subTitle>{{ $t("project.signing-checklist.prepare-signature.PrepareSignatureAutoTagsDialog.subTitle") }}</template>

    <template #body>
      <Alert type="info" class="my-2">
        <p v-html="$t('project.signing-checklist.prepare-signature.PrepareSignatureAutoTagsDialog.info')"></p>
        <div class="text-right">
          <AppDownloadFile :link="autoTagsInfosLink">
            <template #default="{ submitForm }">
              <AppButton light target="_blank" text @click="submitForm">
                {{$t('project.signing-checklist.prepare-signature.PrepareSignatureAutoTagsDialog.moreInfos')}}
              </AppButton>
            </template>
          </AppDownloadFile>
        </div>
      </Alert>
      <template v-if="!loading && wordsFound.length !== 0">
        <Alert
          v-show="!isFormValid"
          icon="fal fa-circle-exclamation"
          type="warning"
          class="my-1"
        >
          <p>{{ $t("project.signing-checklist.prepare-signature.PrepareSignatureAutoTagsDialog.warning") }}</p>
        </Alert>
        <p class="my-4">{{ $t("project.signing-checklist.prepare-signature.PrepareSignatureAutoTagsDialog.text") }}</p>
        <div class="tags-found-container px-4">
          <v-row
            v-for="tag in wordsFound"
            :key="tag.text"
            align-v="center"
            class="mb-2"
          >
            <AppSelect
              :id="tag.text"
              v-model="tag.user"
              :label="tag.text"
              :items="knownUsersOptions"
            >
            </AppSelect>
          </v-row>
        </div>
      </template>
      <template v-if="!loading && wordsFound.length === 0">
        <p class="mt-4 text-center">{{ $t("project.signing-checklist.prepare-signature.PrepareSignatureAutoTagsDialog.emptyText") }}</p>
      </template>
      <template v-if="loading">
        <div class="text-center">
          <v-progress-circular
            color="primary"
            indeterminate
            size="32"
            style="width: 3rem; height: 3rem; margin: 100px auto"
          />
        </div>
      </template>
    </template>

    <template #footer>
      <v-spacer />
      <AppButton class="mr-2" color="white" @click="closeDialog">
        {{ $t("common.cancel") }}
      </AppButton>
      <AppTooltip :disabled="isFormValid" bottom>
        <template v-slot:activator="{on, attrs}">
            <span v-bind="attrs" v-on="on">
              <AppButton
                :disabled="!isFormValid || loading"
                color="primary"
                @click="
                  wordsFound.length === 0 ? closeDialog() : confirmAutoTags()
                "
              >
                {{ $t("common.confirm") }}
              </AppButton>
            </span>
        </template>
        <p>{{$t('project.signing-checklist.prepare-signature.PrepareSignatureAutoTagsDialog.confirmTooltip')}}</p>
      </AppTooltip>
    </template>
  </AppDialog>
</template>

<script>
import { uniqBy, sortBy } from 'lodash-es'
import { mapGetters, mapActions, mapState } from 'vuex'

import Alert from '../../../common/alerts/Alert.vue'
import AppDownloadFile from '../../../common/AppDownloadFile.vue'
import AppSelect from '../../../common/AppSelect.vue'
import AppTooltip from '../../../common/AppTooltip'
import AppButton from '../../../common/buttons/AppButton.vue'
import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { GET_AUTO_TAGS, GET_TAGGING_INFORMATION, POST_AUTO_TAGS } from '../../../store/modules/prepareSignature/action_types'

export default {
  name: 'PrepareSignatureAutoTagsDialog',
  components: {
    Alert,
    AppButton,
    AppDialog,
    AppSelect,
    AppTooltip,
    AppDownloadFile,
  },
  mixins: [dialogMixin],
  data () {
    return {
      // Words found are based on the tags found with a unicity based on the word.
      // This is shown to the user to confirm the related users for each unique word
      wordsFound: [],
      // This is the raw tags found from the back-end response
      tagsFound: [],
      loading: true,
      knownUsers: [],
      knownUsersOptions: [],
    }
  },
  computed: {
    ...mapState('prepareSignature', ['envelopeDisplayInformation']),
    ...mapGetters('room', ['roomMnemo']),
    isFormValid () {
      return this.wordsFound.every((tag) => Number.isInteger(tag.user))
    },
    autoTagsInfosLink () {
      return `${process.env.VUE_APP_API_URL}/assets/pdf?file=auto-tags-${this.$i18n.locale.toUpperCase()}`
    },
  },
  async created () {
    try {
      const taggingInfo = await this.GET_TAGGING_INFORMATION({
        mnemo: this.roomMnemo,
        envId: this.envelopeDisplayInformation.envelopeId,
      })
      this.knownUsers = taggingInfo.signers.flat()
      this.knownUsersOptions = this.formatKnownUsers(this.knownUsers)

      this.tagsFound = await this.GET_AUTO_TAGS({
        mnemo: this.roomMnemo,
        envId: this.envelopeDisplayInformation.envelopeId,
      })
      this.wordsFound = uniqBy(this.tagsFound, 'word').map((tag) => {
        const extractedWord = tag.word.substring(7, tag.word.length - 2)
        const user = this.findMatchingUser(extractedWord, true)
        const userId = (user && user.signerId) || ''
        return {
          text: tag.word,
          user: userId,
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('prepareSignature', [GET_TAGGING_INFORMATION, GET_AUTO_TAGS, POST_AUTO_TAGS]),
    async confirmAutoTags () {
      this.loading = true
      const formattedData = {}
      this.wordsFound.forEach((tag) => {
        formattedData[tag.text] = tag.user
      })
      try {
        await this.POST_AUTO_TAGS({
          mnemo: this.roomMnemo,
          envId: this.envelopeDisplayInformation.envelopeId,
          data: formattedData,
        })
        this.$emit('get-tags')
        // We may get pages out of order here, so we re-sort them because getpage needs them in order.
        // We need to do that to scroll to the first one later anyway so that's all good :)
        const sortedUniqueTagPages = sortBy(uniqBy(this.tagsFound, 'page'), ['page']).map((tagPage) => tagPage.page)
        this.$emit('get-pages', { pages: sortedUniqueTagPages })
        this.closeDialog()
        this.$emit('scroll-to', sortedUniqueTagPages[0])
      } finally {
        this.loading = false
      }
    },
    formatKnownUsers (knownUsers) {
      return knownUsers.map((user) => (
        {
          text: user.fullName,
          value: user.signerId,
        }
      ))
    },

    /**
     * Find a user from the known users. If there are multiple matches, they are ignored and this returns nothing.
     * @param {string} word The string to use to find the user
     * @param {boolean} shallow Determines if the search should be done with an exact match of the fullname or not.
     *                          If it's not, it will match any word that starts the same as
     *                          either the name or lastName of a user.
     * @returns {object|undefined}
     */
    findMatchingUser (word, shallow = true) {
      let userMatch = []
      if (shallow) {
        userMatch = this.knownUsers.filter(
          (user) => (
            this.normalizeWord(user.firstName).startsWith(this.normalizeWord(word)) ||
            this.normalizeWord(user.lastName).startsWith(this.normalizeWord(word))
          ),
        )
      } else {
        userMatch = this.knownUsers.filter(
          (user) => (
            user.fullName.toLowerCase() === word.toLowerCase()
          ),
        )
      }
      if (userMatch.length === 0 || userMatch.length > 1) {
        return undefined
      } else {
        return userMatch[0]
      }
    },
    /**
     * Returns a normalized version of a word, without diacritics and accents, in all lowercase
     * @param {string} word The word source
     * @returns {string} The same word, normalized
     */
    normalizeWord (word) {
      return word.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
  },
}
</script>

<style scoped lang="scss">
.subtitle {
  color: #a3a3a3;
  font-weight: 600;
}

.tags-found-container {
  max-height: 250px;
  overflow-y: auto;
  // Those paddings are there to prevent the focus styles to overflow clip
  // Otherwise the focus border of the input would get clipped outside the container
  padding-top: 18px;
  padding-right: 2px;
}
</style>
