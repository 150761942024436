<template>
  <span class="text-caption">
    <span :class="{ 'red--text': textIsTooLong }">{{currentLength}}</span>
    <template v-if="maxLength">
      /{{maxLength}}
    </template>
  </span>
</template>

<script>
/**
 * Default Text counter
 * @displayName TextCounter
 */
/**
 * Display a text counter. Used under richtext textarea to match the vuetify input counter
 */
export default {
  name: 'TextCounter',
  props: {
    /**
     * The text length
     */
    currentLength: {
      type: Number,
      required: true,
    },
    /**
     * The text max length
     */
    maxLength: {
      type: Number,
    },
  },
  computed: {
    textIsTooLong () {
      return this.currentLength > this.maxLength
    },
  },
}
</script>

<docs>
```vue
<template>
  <div>
    <AppTextField v-model="text"
                  placeholder="Type text here"
                  label="Input"
    />
    <TextCounter :currentLength="text.length"
                 :max-length="100"
    />
  </div>
</template>
<script>
  import AppTextField from '@/common/AppTextField'
  export default {
    components: {AppTextField},
    data () {
      return {
        text: '',
      }
    },
  }
</script>
```
</docs>
