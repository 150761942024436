<template>
    <div class="ChecklistTableTaskAttachedFilesDialog">
      <v-chip v-if="isButtonDisplayed"
              class="ChecklistTableTaskAttachedFilesDialog-button clickable"
              color="#9e9e9e"
              outlined
              text-color="#333"
              @click="onOpen"
      >
        <font-awesome-icon :icon="['fal', 'paperclip']"
                           class="mr-2"
        ></font-awesome-icon>
        {{ $tc('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.buttonText', task.nbAttachments, { attachmentCount: task.nbAttachments }) }}
      </v-chip>
      <AppDialog :is-open="dialogIsOpen"
                 size="xxxl"
                 hideFooter
                 @ok="onCancel"
                 @cancel="onCancel"
      >
        <template #title>
          {{$t('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.title', { taskDisplay: task.display, taskTitle: task.title })}}
        </template>
        <template #body>
          <template v-if="currentRoom.isPM || currentUserRights.canUpload">
            <v-row>
              <v-col>
                <div class="ChecklistTableTaskAttachedFilesDialog-dropzone text-center pa-5"
                     :class="{'ChecklistTableTaskAttachedFilesDialog-dropzone--hovered': dragInProgress}"
                     @dragover.prevent="dragInProgress = true"
                     @dragenter.prevent="dragInProgress = true"
                     @dragleave.prevent="dragInProgress = false"
                     @drop.prevent="getAllFileEntries"
                     @click="onFileInputClick"
                >
                  <v-row>
                    <v-col>
                      <img width="145" class="mx-auto" src="/img/icons/uploaderIllustration.svg"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-h4 text--primary"
                    >
                      <input ref="fileInput"
                             class="d-none"
                             type="file"
                             :multiple="true"
                             @change="buildAddFiles($event.target.files)"
                      >
                      {{ $t('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.dropZoneText1') }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text--secondary d-flex" style="justify-content: center">
                      <div style="max-width: 360px" v-html="$tc('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.dropZoneText2', null, { maxSignableSize, maxPage: maxSignablePages })"></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text--secondary d-flex" style="justify-content: center">
                      <template v-if="roomProvidersPending">
                        <!-- Min-width necessary here to avoid 0 width -->
                        <v-skeleton-loader min-width="250" type="text"/>
                      </template>
                      <div v-else style="max-width: 360px" v-html="$tc('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.dropZoneText3', null, { maxSize: maxFilesSize })"></div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="my-4 d-flex" style="justify-content: center">
                <AppButton
                  color="tertiary"
                  large
                  class="mx-2"
                  :dark="true"
                  @click="onFileInputClick"
                >
                  {{ $t("project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.btnImportFromMyComputer") }}
                </AppButton>
                <PolyOfficeButtonImport v-if="polyOfficeEnabled"
                                        class="mx-2"
                                        @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
                />
                <PolyOfficeDialog v-if="polyOfficeEnabled && polyOfficeDialogIsOpen" @callback="polyOfficeDialogCallback"/>
              </v-col>
            </v-row>
            <v-row v-if="imanageEnabledAndLogged">
              <v-col>
                <AppButton color="tertiary"
                           dark
                           small
                           @click.stop="iManageFilePickerDialogIsOpen = true"
                >
                  <v-img src="/img/iManage_logo.png"
                         width="25"
                         class="mr-2"
                  />
                  {{ $t('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.importFromImanage') }}
                </AppButton>
                <IManageFilePickerDialog :is-open.sync="iManageFilePickerDialogIsOpen"
                                         @files="onImanageFiles"
                />
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col>
              <ChecklistTableTaskAttachedFilesDialogFileTree :mnemo="mnemo"
                                                             :folder-id="task.attachmentsFolderId"
              />
            </v-col>
          </v-row>
        </template>
      </AppDialog>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import IManageFilePickerDialog from '@/common/imanage/IManageFilePickerDialog'
import { ChecklistImport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeButtonImport from '@/common/polyOffice/PolyOfficeButtonImport.vue'
import PolyOfficeDialog, { POLY_OFFICE_DIALOG_STATUS_SUCCESS } from '@/common/polyOffice/PolyOfficeDialog.vue'
import { BtoMB } from '@/common/utils/sizes'
import { FileToUpload } from '@/models/documentToUpload.model'
import { GET_TASK_FOLDER } from '@/store/modules/checklist/action_types'
import { RESET_SELECTED_ATTACHED_FILE, RESET_TASK_FOLDER } from '@/store/modules/checklist/mutation_types'
import { POST_FILDERS_FROM_IMANAGE } from '@/store/modules/documents/action_types'
import { GET_ACTION_INFO, GET_IMPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { GET_ROOM_PROVIDERS } from '@/store/modules/room/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import ChecklistTableTaskAttachedFilesDialogFileTree from './ChecklistTableTaskAttachedFilesDialogFileTree'

export default {
  name: 'ChecklistTableTaskAttachedFilesDialog',
  components: {
    PolyOfficeButtonImport,
    PolyOfficeDialog,
    AppDialog,
    IManageFilePickerDialog,
    AppButton,
    ChecklistTableTaskAttachedFilesDialogFileTree,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    noButton: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      dialogIsOpen: false,
      dragInProgress: false,
      addedFiles: [],
      selectedDocuments: [],
      mustRefreshTask: false,
      iManageFilePickerDialogIsOpen: false,
      providersMaxSizes: [],
    }
  },
  computed: {
    ...mapState('checklist', ['currentTaskFolder']),
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('room', ['currentUserRights', 'isCurrentUserPm']),
    ...mapState('room', ['currentRoom', 'roomProvidersPending']),
    ...mapState('polyOffice', {
      polyOfficeDialogIsOpen: 'dialogIsOpen',
    }),
    ...mapGetters('user', ['polyOfficeEnabled']),
    maxSignableSize () {
      const availableSize = this.providersMaxSizes.length !== 0 ? BtoMB(Math.max(...this.providersMaxSizes)) : this.maxFilesSize
      return Number(availableSize).toFixed(1)
    },
    maxFilesSize () {
      return process.env.VUE_APP_MAX_FILE_SIZE
    },
    maxSignablePages () {
      return process.env.VUE_APP_MAX_SIGNABLE_PAGES
    },
    isButtonDisplayed () {
      if (this.noButton) {
        return false
      } else if (this.currentRoom.isPM || this.currentUserRights.canUpload) {
        return true
      }
      return this.task.nbAttachments > 0
    },
  },
  watch: {
    open (value) {
      if (this.noButton && value) {
        this.onOpen()
      }
    },
  },
  methods: {
    ...mapActions('checklist', [GET_TASK_FOLDER]),
    ...mapActions('appUploadHandler', ['addFilesToUpload']),
    ...mapActions('documents', [POST_FILDERS_FROM_IMANAGE]),
    ...mapActions('polyOffice', [
      GET_IMPORT_IFRAME,
      GET_ACTION_INFO,
    ]),
    ...mapActions('room', [
      GET_ROOM_PROVIDERS,
    ]),
    async getAllFileEntries (e) {
      const dataTransferItemList = e.dataTransfer ? e.dataTransfer.items : 'null'
      const fileEntries = []
      const queue = []
      for (let i = 0; i < dataTransferItemList.length; i++) {
        queue.push(dataTransferItemList[i].webkitGetAsEntry())
      }
      while (queue.length > 0) {
        const entry = queue.shift()
        if (entry.isFile) {
          fileEntries.push(await this.getFileFromFileEntry(entry, this.currentTaskFolder.id))
        } else if (entry.isDirectory) {
          queue.push(...await this.readAllDirectoryEntries(entry.createReader()))
        }
      }
      this.addFilesToUpload(fileEntries)
      this.mustRefreshTask = true
    },
    async readAllDirectoryEntries (directoryReader) {
      const entries = []
      let readEntries = await this.readEntriesPromise(directoryReader)
      while (readEntries.length > 0) {
        entries.push(...readEntries)
        readEntries = await this.readEntriesPromise(directoryReader)
      }
      return entries
    },
    async readEntriesPromise (directoryReader) {
      try {
        return await new Promise((resolve, reject) => {
          directoryReader.readEntries(resolve, reject)
        })
      } catch (err) {
        console.error(err)
      }
    },
    async getFileFromFileEntry (fileEntry, parentId) {
      try {
        return new FileToUpload(await new Promise((resolve, reject) => fileEntry.file(resolve, reject)), 'checklist', parentId)
      } catch (err) {
        console.error(err)
      }
    },
    buildAddFiles (files) {
      const array = []
      Object.values(files).forEach(file => {
        array.push(new FileToUpload(file, 'checklist', this.currentTaskFolder.id))
      })
      this.addFilesToUpload(array)
      this.$refs.fileInput.value = null
      this.mustRefreshTask = true
    },
    onCancel () {
      this.$store.commit(`checklist/${RESET_TASK_FOLDER}`)
      this.$store.commit(`checklist/${RESET_SELECTED_ATTACHED_FILE}`)
      this.dialogIsOpen = false
      this.dragInProgress = false
      this.addedFiles = []
      this.selectedDocuments = []
      if (this.mustRefreshTask) {
        this.mustRefreshTask = false
        this.$emit('refreshTask')
      }
    },
    async onOpen () {
      this.GET_TASK_FOLDER({
        mnemo: this.mnemo,
        folderId: this.task.attachmentsFolderId,
      })
      this.dialogIsOpen = true
      if (this.isCurrentUserPm && !this.roomProvidersPending && !this.currentRoom.providers) {
        await this.GET_ROOM_PROVIDERS(this.mnemo)
      }
      this.providersMaxSizes = this.currentRoom.providers?.map(o => o.maxSize) || []
    },
    onFileInputClick () {
      this.$refs.fileInput.click()
    },
    async onImanageFiles (files) {
      const data = {
        parentId: this.task.attachmentsFolderId,
        files: [],
      }
      if (files.hasOwnProperty('output_data')) {
        if (files.output_data.hasOwnProperty('selected')) {
          for (const item of files.output_data.selected) {
            if (item.wstype === 'document') {
              data.files.push(item.id)
            }
          }
        }
      }
      try {
        await this.POST_FILDERS_FROM_IMANAGE({
          mnemo: this.mnemo,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$tc('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.postFildersFromIManageSuccess', data.files.length),
        })
        this.GET_TASK_FOLDER({
          mnemo: this.mnemo,
          folderId: this.task.attachmentsFolderId,
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.postFildersFromIManageError', data.files.length),
        })
      }
    },
    async polyOfficeOpenDialogHandler () {
      const payload = new ChecklistImport(
        this.mnemo,
        this.task.attachmentsFolderId,
      )
      await this.GET_IMPORT_IFRAME({
        payload,
        successMessage: this.$t('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.polyOffice.importSuccessful'),
      })
    },
    async polyOfficeDialogCallback (data) {
      // TODO remove after test with the real poly office's iframe
      console.info('CALLBACK STATUS', data.status)
      console.info('CALLBACK EVENT', data.event)

      if (data.status === POLY_OFFICE_DIALOG_STATUS_SUCCESS) {
        try {
          // Call get info and check receive status is DONE
          const response = await this.GET_ACTION_INFO()

          if (response.receiveStatus === 'DONE') {
            // Refresh task folder
            this.GET_TASK_FOLDER({
              mnemo: this.mnemo,
              folderId: this.task.attachmentsFolderId,
            })

            this.mustRefreshTask = true
          } else {
            this.$store.commit(ENQUEUE_SNACKBAR, {
              color: 'error',
              message: this.$t('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.polyOffice.importFailure'),
            })
          }
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialog.polyOffice.importFailure'),
          })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableTaskAttachedFilesDialog-button {
  height: 28px;
}
.ChecklistTableTaskAttachedFilesDialog-dropzone {
  background-color: #efeff1;
  border: 2px dashed #e0e0e0;
  border-radius: 7px;
  z-index: 1;
  top: 5px;
  position: sticky;
  cursor: pointer;
  &--hovered {
    background-color: rgba(131, 169, 237, 0.15);
    border-color: #83a9ed;
  }
}
</style>
