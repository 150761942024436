<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             @cancel="closeDialog"
  >
    <template #title>
      {{ $t('common.imanage.IManageAuthDialog.title') }}
    </template>

    <template #body>
      <v-row>
        <v-col>
          <v-img src="/img/iManage_work_logo.png"
                 width="150"
                 class="mx-auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h4 text-center text--primary">{{ $t('common.imanage.IManageAuthDialog.connectSession') }}</v-col>
      </v-row>
      <v-row>
        <v-col class="text-center text--primary">{{ $t('common.imanage.IManageAuthDialog.connectSessionInfo') }}</v-col>
      </v-row>
    </template>

    <template #footer>
      <div class="buttons-container">
        <AppButton color="white"
                   @click="onNoClick"
        >
          {{$t('common.imanage.IManageAuthDialog.no')}}
        </AppButton>
        <AppButton color="primary"
                   :loading="authUrlPending"
                   :disabled="!authUrl"
                   @click="onYesClick"
        >
          {{$t('common.imanage.IManageAuthDialog.yes')}}
        </AppButton>
      </div>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import { removeUserSetting, setUserSetting } from '@/common/utils/userSettings'
import { dialogMixin } from '@/mixins/dialogMixin'
import { GET_AUTH_URL } from '@/store/modules/imanage/action_types'
import { SET_USER_WANT_IMANAGE } from '@/store/modules/imanage/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export const IMANAGE_REFUSAL_USER_SETTINGS_KEY = 'refused-imanage-auth-at'

export default {
  name: 'IManageAuthDialog',
  components: { AppDialog, AppButton },
  mixins: [dialogMixin],
  computed: {
    ...mapState('imanage', ['authUrl', 'authUrlPending']),
    ...mapGetters('user', ['currentUserId']),
  },
  mounted () {
    this.prepareGetAuthURL()
  },
  methods: {
    ...mapActions('imanage', [GET_AUTH_URL]),
    onNoClick () {
      this.$store.commit(`imanage/${SET_USER_WANT_IMANAGE}`, false)
      setUserSetting(this.currentUserId, IMANAGE_REFUSAL_USER_SETTINGS_KEY, Date.now())
      this.closeDialog()
    },
    onYesClick () {
      this.$cookies.set('imanage_auth_last_route', this.$route.fullPath)
      removeUserSetting(this.currentUserId, IMANAGE_REFUSAL_USER_SETTINGS_KEY)
      window.location.replace(this.authUrl)
    },
    async prepareGetAuthURL () {
      try {
        await this.GET_AUTH_URL()
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('common.imanage.IManageAuthDialog.getAuthURLError'),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .buttons-container {
    margin-left: auto;
    margin-right: auto;
    right: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
</style>
