<template>
  <li class="treeview-item" :class="{ 'treeview-item--selected': selected }">
    <slot />
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppTreeviewItem',
  inheritAttrs: true,
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped lang="scss">
.treeview-item {
  padding: 8px 12px;
  border-radius: 8px;

  &:hover {
    background: var(--v-grey-lighten5);
  }

  &--selected {
    background: var(--v-originalGrey-lighten2);

    &:hover {
      background: #D4D7DA;
    }
  }
}
</style>
