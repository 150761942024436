<template>
  <v-expansion-panels flat>
    <v-expansion-panel class="flat-expansion-panel">
      <v-expansion-panel-header class="pl-0">
        <a class="learnMoreLink" href="#"><slot name="title"></slot></a>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="content"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
  export default {
    name: 'AppFlatExpansionPanel',
  }
</script>

<style scoped lang="scss">
  .flat-expansion-panel {
    background-color: transparent !important;
    &::v-deep .v-expansion-panel-header {
      width: auto;
    }
    &::v-deep .v-expansion-panel-header__icon {
      margin-left: $spacer * 2;
    }
    &::v-deep .v-expansion-panel-header__icon .v-icon {
      color: #4C75B5 !important;
    }
    &::v-deep .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
</style>
