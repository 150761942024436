<template>
  <AppDroppableArea as="tbody"
                    :disabled="!dropInCurrentFolderIsEnabled"
                    @dragenter="dragEnterCurrentFolder"
                    @dragleave="dragLeaveCurrentFolder"
                    @drop="dropDocumentsInCurrentFolder"
  >
    <AppDroppableArea v-for="folder in folderItems"
                      :key="folder.selectableKey"
                      :as="DocumentsTableItem"
                      :document="folder"
                      :selected="isDocumentSelected(folder)"
                      :draggable="childrenDocumentCanBeDragged(folder)"
                      :disabled="!dropInChildFolderIsEnabled || !!folder.specialFolder "
                      @select="selectDocument(folder, !isDocumentSelected(folder))"
                      @dragenter="dragEnterChildFolder(folder)"
                      @dragleave="dragLeaveChildFolder(folder)"
                      @dragstart="dragClosdDocumentStart($event, folder)"
                      @drop="dropDocumentsInChildFolder($event, folder)"
    />

    <DocumentsTableItem v-for="file in fileItems"
                        :key="file.selectableKey"
                        :document="file"
                        :draggable="childrenDocumentCanBeDragged(file)"
                        :selected="isDocumentSelected(file)"
                        @dragstart="dragClosdDocumentStart($event, file)"
                        @select="selectDocument(file, !isDocumentSelected(file))"
    />

    <tr v-if="emptyDroppableRowIsPresent">
      <td :colspan="columnsCount"
          class="text-center"
      >
        {{ $t('project.documents.table.DocumentsTableBody.noFile') }}
      </td>
    </tr>
  </AppDroppableArea>
</template>

<script>
import { last } from 'lodash-es'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDroppableArea from '@/common/AppDroppableArea.vue'
import { DRAG_AND_DROP_MESSAGE_TYPE } from '@/common/utils/dragAndDropMessageType'
import { flatten } from '@/common/utils/flatArray'
import { FileToUpload, FolderToUpload } from '@/models/documentToUpload.model'
import DocumentsTableItem from '@/project/documents/table/DocumentsTableItem.vue'
import { SET_UPLOAD_HINT } from '@/store/modules/app-upload-handler/mutation_types'
import { DESELECT_ALL_DOCUMENTS, LOAD_FOLDERS_TREE, MOVE_FILDERS } from '@/store/modules/documents/action_types'
import { SET_DOCUMENTS_TO_MOVE, SET_MOVEMENT_SNACKBAR_IS_OPEN, SET_NUMBERING_SNACKBAR_IS_OPEN } from '@/store/modules/documents/mutation_types'
import { START_UPLOAD } from '@/store/modules/documents-upload-files/action_types'
import { ADD_FILES } from '@/store/modules/documents-upload-files/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

const ROOT_FOLDER_ID = 0

export default {
  name: 'DocumentsTableBody',
  components: { AppDroppableArea, DocumentsTableItem },
  inheritAttrs: false,
  props: {
    currentFolder: {
      type: Object,
      required: true,
    },
    columnsCount: {
      type: Number,
      default: 0,
    },
    documents: {
      type: Array,
      default: () => [],
    },
    isDocumentSelected: {
      type: Function,
      required: true,
    },
    selectDocument: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      documentsHaveBeenDroppedInChild: false,
      enteredDroppableFolders: [],
      DocumentsTableItem,
    }
  },
  computed: {
    ...mapState('documents', ['documentsToMove', 'foldersTree', 'numberingEnabled', 'selectedDocuments']),
    ...mapGetters('room', ['currentUserRights', 'roomMnemo']),
    ...mapGetters('documents', ['isRecentFolder']),
    ...mapGetters('documentsBreadcrumb', ['hasTodoFolderOrChild', 'isCurrentlyInTodoFolderRoot']),
    dropInCurrentFolderIsAllowed () {
      return !this.isCurrentlyInTodoFolderRoot &&
        !this.isRecentFolder &&
        this.userHasUploadRight && !this.dropToSameFolder
    },
    dropInCurrentFolderIsEnabled () {
      if (this.dropFromFoldersTreeDisabled) {
        return false
      }
      const currentlyDraggingOverChildFolder = this.enteredDroppableFolders.length > 1
      return this.dropInCurrentFolderIsAllowed && !currentlyDraggingOverChildFolder
    },
    dropToSameFolder () {
      return this.documentsToMove.documents?.some(document =>
        document.id === this.focusedDroppableFolder?.id && document.type === this.focusedDroppableFolder?.type,
      )
    },
    dragFromFoldersTree () {
      return this.documentsToMove.isDragFromFoldersTree
    },
    dropFromFoldersTreeDisabled () {
      return this.dragFromFoldersTree && !this.canDropFromFolderTree(this.focusedDroppableFolder)
    },
    dropInChildFolderIsEnabled () {
      if (this.dropFromFoldersTreeDisabled) {
        return false
      }
      return this.userHasUploadRight && !this.dropToSameFolder
    },
    emptyDroppableRowIsPresent () {
      return this.fileItems.length === 0 && this.dropInCurrentFolderIsAllowed
    },
    folderItems () {
      return this.documents.filter(document => document.type === 'folder')
    },
    fileItems () {
      return this.documents.filter(document => document.type === 'file')
    },
    userHasUploadRight () {
      return this.currentUserRights.canUpload
    },
    focusedDroppableFolder () {
      if (this.enteredDroppableFolders.length > 0) {
        return last(this.enteredDroppableFolders)
      }

      return null
    },
  },
  watch: {
    focusedDroppableFolder (folder) {
      if (folder) {
        let messageType = DRAG_AND_DROP_MESSAGE_TYPE.MOVABLE

        if (folder.specialFolder) {
          messageType = DRAG_AND_DROP_MESSAGE_TYPE.ERROR_NOT_ALLOWED_FOLDER
        }
        if (this.dropToSameFolder && this.documentsToMove.isDragElementSelected) {
          messageType = DRAG_AND_DROP_MESSAGE_TYPE.ERROR_DROP_IN_SELECTED_FOLDER
        }
        this.openUploadHint(folder.name, messageType)
        if (this.dropFromFoldersTreeDisabled) {
          this.closeUploadHint()
        }
      } else {
        this.closeUploadHint()
      }
    },
  },
  methods: {
    ...mapActions('documents', [DESELECT_ALL_DOCUMENTS, LOAD_FOLDERS_TREE, MOVE_FILDERS]),
    ...mapActions('documentsUploadFiles', [START_UPLOAD]),
    ...mapMutations('documentsUploadFiles', [ADD_FILES]),
    ...mapMutations('appUploadHandler', [SET_UPLOAD_HINT]),
    ...mapMutations('documents', [SET_DOCUMENTS_TO_MOVE, SET_MOVEMENT_SNACKBAR_IS_OPEN, SET_NUMBERING_SNACKBAR_IS_OPEN]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async generateFileToUpload (file, parentFolderId) {
      let entryFile
      try {
        entryFile = await new Promise((resolve, reject) => {
          file.file(resolve, reject)
        })
      } catch (err) {
        console.error(err)
      }

      return new FileToUpload(entryFile, 'document', parentFolderId)
    },
    async generateFolderToUpload (folder, parentFolderId) {
      const folderToUpload = new FolderToUpload(folder.name, [], parentFolderId)

      let readEntries
      try {
        readEntries = await new Promise((resolve, reject) => {
          folder.createReader().readEntries(resolve, reject)
        })
      } catch (err) {
        console.error(err)
      }

      for (const entry of readEntries) {
        if (entry.isFile) {
          folderToUpload.children.push(await this.generateFileToUpload(entry))
          continue
        }

        if (entry.isDirectory) {
          folderToUpload.children.push(await this.generateFolderToUpload(entry))
        }
      }

      return folderToUpload
    },
    async dropLocalDocuments ($event, targetFolder) {
      const dataTransferItemList = $event.dataTransfer ? $event.dataTransfer.items : []
      const documentsToUpload = []
      const queue = []
      for (let i = 0; i < dataTransferItemList.length; i++) {
        queue.push(dataTransferItemList[i].webkitGetAsEntry())
      }

      for (const entry of queue) {
        if (entry.isFile) {
          const fileToUpload = await this.generateFileToUpload(entry, targetFolder.id)
          documentsToUpload.push(fileToUpload)
        }

        if (entry.isDirectory) {
          if (!this.currentUserRights.canCreateFolders) {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.table.DocumentsTableBody.noFolderAsGuest'))
            return
          }

          if (this.hasTodoFolderOrChild) {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.table.DocumentsTableBody.noFolderInChecklist'))
            return
          }

          const folderToUpload = await this.generateFolderToUpload(entry, targetFolder.id)
          documentsToUpload.push(folderToUpload)
        }
      }

      this.ADD_FILES(documentsToUpload)
      this.START_UPLOAD()
    },
    dropDocumentsInCurrentFolder ($event) {
      if (this.documentsHaveBeenDroppedInChild) {
        return
      }

      const documentFromClosd = $event.dataTransfer.getData('fromClosd') === 'true'
      const draggedFromCurrentFolder = documentFromClosd &&
      Number($event.dataTransfer.getData('documentParentId')) === this.currentFolder.id
      if (documentFromClosd && !this.dropFromFoldersTreeDisabled) {
        this.moveClosdDocument(this.currentFolder)
        return
      }
      if (draggedFromCurrentFolder) {
        return
      }
      if (!this.documentsToMove.documents && !draggedFromCurrentFolder) {
        this.dropLocalDocuments($event, this.currentFolder)
      }
    },
    dropDocumentsInChildFolder ($event, childFolder) {
      if (!childFolder.specialFolder) {
        this.documentsHaveBeenDroppedInChild = true

        const documentFromClosd = $event.dataTransfer.getData('fromClosd') === 'true'
        if (documentFromClosd) {
          const documentId = Number($event.dataTransfer.getData('documentId'))
          const documentType = $event.dataTransfer.getData('documentType')
          const dropFolderInItself = documentId === childFolder.id && documentType === childFolder.type

          if (!dropFolderInItself || !this.dropFromFoldersTreeDisabled) {
            this.moveClosdDocument(childFolder)
          }
        } else {
          this.dropLocalDocuments($event, childFolder)
        }
      }
    },
    canDropFromFolderTree (targetFolder) {
      if (!targetFolder || !this.documentsToMove.isDragFromFoldersTree) {
        return false
      }
      const flattenFolders = flatten([], this.foldersTree?.folders)
      const matchingFolder = flattenFolders.find(flattenFolder => flattenFolder.id === this.documentsToMove.documents[0].id)
      const childrenOfMatchingFolder = flatten([], matchingFolder?.children)
      const dropInSameParent = this.documentsToMove.originalFolderId === targetFolder.id
      const dropToSameFolder = this.documentsToMove.documents[0].id === targetFolder.id
      const dropInChildrenFolder = childrenOfMatchingFolder.some(childFolder => childFolder.id === targetFolder.id)
      return !dropInSameParent && !dropToSameFolder && !dropInChildrenFolder
    },
    dragEnterCurrentFolder () {
      this.documentsHaveBeenDroppedInChild = false
      this.enteredDroppableFolders.unshift(this.currentFolder)
    },
    dragLeaveCurrentFolder () {
      this.closeUploadHint()
      this.enteredDroppableFolders = []
    },
    dragEnterChildFolder (folder) {
      this.enteredDroppableFolders.push(folder)
    },
    dragLeaveChildFolder (folder) {
      this.enteredDroppableFolders = this.enteredDroppableFolders.filter(
        droppableFolderEntered => droppableFolderEntered.id !== folder.id,
      )
    },
    dragClosdDocumentStart ($event, draggedDocument) {
      if (!this.childrenDocumentCanBeDragged(draggedDocument)) {
        $event.preventDefault()
        return
      }
      this.SET_MOVEMENT_SNACKBAR_IS_OPEN(false)
      let documentsToMoveArray = [{ id: draggedDocument.id, type: draggedDocument.type }]
      const isDragElementSelected = this.isDocumentSelected(draggedDocument)
      if (isDragElementSelected) {
        documentsToMoveArray = this.selectedDocuments.map(doc => {
          return {
            id: doc.id,
            type: doc.type,
          }
        })
      }
      this.SET_DOCUMENTS_TO_MOVE({
          isDragElementSelected,
          isDragFromFoldersTree: false,
          documents: documentsToMoveArray,
          originalFolderId: this.currentFolder?.id || ROOT_FOLDER_ID,
        })
      $event.dataTransfer.setData('fromClosd', 'true')
      $event.dataTransfer.setData('documentId', draggedDocument.id)
      $event.dataTransfer.setData('documentType', draggedDocument.type)
      $event.dataTransfer.setData('documentParentId', draggedDocument.parentFolderId ?? ROOT_FOLDER_ID)
    },
    async moveClosdDocument (targetFolder) {
      if (this.hasTodoFolderOrChild) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.table.DocumentsTableBody.noFolderInChecklist'))
        return
      }
      try {
        await this.MOVE_FILDERS({
          documents: this.documentsToMove.documents,
          targetFolderId: targetFolder.id,
        })
        this.SET_MOVEMENT_SNACKBAR_IS_OPEN(true)
      } catch {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.table.DocumentsTableBody.moveError'))
        return
      }

      if (this.numberingEnabled) {
        this.SET_NUMBERING_SNACKBAR_IS_OPEN(true)
      }

      if (this.documentsToMove.documents.some(doc => doc.type === 'folder')) {
        await this.LOAD_FOLDERS_TREE()
      }
      if (this.documentsToMove.isDragElementSelected) {
        this.DESELECT_ALL_DOCUMENTS()
      }
    },
    openUploadHint (destinationFolder, messageType) {
      this.SET_UPLOAD_HINT({
        visible: true,
        destinationFolder: destinationFolder,
        messageType: messageType,
      })
    },
    closeUploadHint () {
      this.SET_UPLOAD_HINT({ visible: false })
    },
    childrenDocumentCanBeDragged (document) {
      if (document.type === 'folder') {
        return !document.specialFolder && !this.hasTodoFolderOrChild
      }

      return !this.isRecentFolder
    },
  },
}
</script>
