<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>{{ $t('project.signing-checklist.add.step2.SigningChecklistAddStep2RecipientWarningDialog.title') }}</template>

    <template #body>
      <p>{{ $t('project.signing-checklist.add.step2.SigningChecklistAddStep2RecipientWarningDialog.warning') }}</p>
      <p class="mb-0">{{ $t('project.signing-checklist.add.step2.SigningChecklistAddStep2RecipientWarningDialog.confirmText') }}</p>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from '../../../../common/dialogs/AppDialog.vue'
import { dialogMixin } from '../../../../mixins/dialogMixin'

export default {
  name: 'SigningChecklistAddStep2RecipientWarningDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  methods: {
    confirmClick () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
