<template>
<v-container fluid
             class="question-add pa-0"
             :class="{ 'px-1': $vuetify.breakpoint.mdAndDown }"
>
  <h1 class="text-h1">{{ $t('project.views.QuestionAnswerAdd.title') }}</h1>

  <AppCard class="my-4 px-12 py-6 question-add__form">
    <fieldset>
      <legend>1. {{ $t('project.views.QuestionAnswerAdd.steps.1') }}</legend>
      <div :style="{'max-width': '1000px'}">
        <VisualTextEditor v-model="newQuestion.description"
                          :placeholder="$t('project.views.QuestionAnswerAdd.description.placeholder')"
                          qa-variant
                          :errorMessages="descriptionErrors"
                          counter="footer"
                          :max-length="DESCRIPTION_MAX_LENGTH"
                          @input="$v.newQuestion.description.$touch"
        />
      </div>
    </fieldset>

    <fieldset>
      <legend>2. {{ $t('project.views.QuestionAnswerAdd.steps.2') }}</legend>
      <div>
        <div v-if="newQuestion.file" class="d-flex align-center">
          <div class="d-flex align-center">
            <ClosdFildersIcon :document="newQuestion.file"/>
            <span v-if="newQuestion.file.numbering">{{ newQuestion.file.numbering }}</span>
            {{ newQuestion.file.basename }}
          </div>
          <AppTooltip top>
            <template #activator="{ on, attrs }">
              <AppButton class="ml-2"
                         icon
                         v-bind="attrs"
                         @click="referenceDocumentDialogIsOpen = true"
                         v-on="on"
              >
                <font-awesome-icon :icon="['fal', 'pencil']"></font-awesome-icon>
              </AppButton>
            </template>
            <span>{{ $t('common.modify') }}</span>
          </AppTooltip>
          <AppTooltip top>
            <template #activator="{ on, attrs }">
              <AppButton icon
                         v-bind="attrs"
                         @click="newQuestion.file = null"
                         v-on="on"
              >
                <font-awesome-icon :icon="['far', 'xmark']"></font-awesome-icon>
              </AppButton>
            </template>
            <span>{{ $t('common.delete') }}</span>
          </AppTooltip>
        </div>
        <div v-else :style="{'max-width': '280px'}">
          <AppButton
                     outlined
                     block
                     color="primary"
                     @click="referenceDocumentDialogIsOpen = true"
          >
            <v-icon small class="mr-2">far fa-plus</v-icon>
            {{ $t('common.select') }}
          </AppButton>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>3. {{ $t('project.views.QuestionAnswerAdd.steps.3') }}</legend>
      <div :style="{'max-width': '590px'}">
        <QuestionAnswerCategoriesSelect :categories-form="$v.newQuestion.categories"
                                        @change="selectedCategoriesChanged"
        />
      </div>
    </fieldset>

    <fieldset>
      <legend>4. {{ $t('project.views.QuestionAnswerAdd.steps.4') }}</legend>
      <div :style="{'max-width': '590px'}">
        <QuestionAnswerAddShare :custom-message.sync="newQuestion.customShareMessage"
                                :share-type.sync="newQuestion.shareType"
                                :custom-message-validation="$v.newQuestion.customShareMessage"
        />
      </div>
    </fieldset>

    <div class="text-right mt-4">
      <AppButton color="white"
                 exact-path
                 :to="{ name: QUESTION_ANSWER_LIST_ROUTE_NAME }"
                 class="mr-4"
      >
        {{ $t('common.cancel') }}
      </AppButton>
      <AppButton color="primary"
                 :loading="postQuestionPending"
                 @click="publishQuestion"
      >
        {{ $t('project.views.QuestionAnswerAdd.publish') }}
      </AppButton>
    </div>
  </AppCard>

  <QuestionAnswerReferenceDocumentDialog v-if="referenceDocumentDialogIsOpen"
                                         :selected-document="newQuestion.file"
                                         @close="referenceDocumentDialogIsOpen = false"
                                         @validate-selection="document => newQuestion.file = document"
  />
</v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppCard from '@/common/AppCard'
import AppTooltip from '@/common/AppTooltip.vue'
import AppButton from '@/common/buttons/AppButton'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon'
import { getRoomRouteType } from '@/common/utils/rooms'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'
import { maxEscapedLength } from '@/common/utils/validators'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import QuestionAnswerAddShare, { validations as addShareValidation } from '@/project/question-answer/QuestionAnswerAddShare'
import QuestionAnswerCategoriesSelect, { validations as categoriesSelectValidation } from '@/project/question-answer/QuestionAnswerCategoriesSelect'
import QuestionAnswerReferenceDocumentDialog from '@/project/question-answer/QuestionAnswerReferenceDocumentDialog'
import { QUESTION_ANSWER_LIST_ROUTE_NAME, ROOM_MEMBERS_ROUTE_NAME } from '@/router'
import { QuestionAnswerActionTypes } from '@/store/modules/question-answer/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

const DESCRIPTION_MAX_LENGTH = 32000

export default {
  name: 'QuestionAnswerAdd',
  components: {
    AppButton,
    AppCard,
    AppTooltip,
    ClosdFildersIcon,
    QuestionAnswerAddShare,
    QuestionAnswerCategoriesSelect,
    QuestionAnswerReferenceDocumentDialog,
    VisualTextEditor,
  },
  mixins: [validationMixin],
  validations: {
    newQuestion: {
      description: {
        required,
        maxLength: maxEscapedLength(DESCRIPTION_MAX_LENGTH),
      },
      ...categoriesSelectValidation,
      ...addShareValidation,
    },
  },
  data () {
    return {
      DESCRIPTION_MAX_LENGTH,
      QUESTION_ANSWER_LIST_ROUTE_NAME,
      newQuestion: {
        description: '',
        file: null,
        status: 'draft',
        categories: [],
        customShareMessage: '',
        shareType: SHARE_TYPE_OPTIONS.CUSTOM,
      },
      referenceDocumentDialogIsOpen: false,
    }
  },
  metaInfo () {
    return {
      title: this.$t('project.views.QuestionAnswerAdd.metaTitle'),
    }
  },
  computed: {
    ...mapState('documentSharePicker', ['selectedGroups', 'selectedMembers']),
    ...mapState('questionAnswer', ['postQuestionPending']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['roomMnemo']),
    questionForm () {
      return this.$v.newQuestion
    },
    descriptionErrors () {
      const errors = []

      if (!this.questionForm.description.$dirty) return errors
      if (!this.questionForm.description.maxLength) {
        errors.push(this.$t('common.validations.maxLength', {
          fieldName: this.$t('project.views.QuestionAnswerAdd.description.label'),
          maxLength: DESCRIPTION_MAX_LENGTH,
        }))
      }
      if (!this.questionForm.description.required) {
        errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.views.QuestionAnswerAdd.description.label') }))
      }

      return errors
    },
  },
  created () {
    if (!this.currentRoom.isDataroom) {
      this.$router.replace({ name: ROOM_MEMBERS_ROUTE_NAME, params: { roomType: getRoomRouteType(this.currentRoom) } })
    }
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.POST_QUESTION]),
    async publishQuestion () {
      try {
        await this.$v.$touch()
        if (!this.$v.$invalid) {
          const { file, customShareMessage, ...questionRest } = this.newQuestion
          const questionObject = {
            ...questionRest,
            customMessage: customShareMessage,
            withGroups: this.selectedGroups.map(g => g.id),
            withUsers: this.selectedMembers.map(u => u.id),
          }

          if (file) {
            if (file.type === 'file') {
              questionObject.fileId = file.id
            } else if (file.type === 'folder') {
              questionObject.folderId = file.id
            }
          }

          await this.POST_QUESTION({
            mnemo: this.roomMnemo,
            data: questionObject,
          })
          this.$router.push({ name: QUESTION_ANSWER_LIST_ROUTE_NAME })
        }
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.views.QuestionAnswerAdd.postQuestionError'),
        })
      }
    },
    selectedCategoriesChanged (categories) {
      this.newQuestion.categories = categories
    },
  },
}
</script>

<style scoped lang="scss">
.question-add {
  &__form {
    fieldset {
      border: none;

      &:not(:first-child) {
        margin-top: 32px;
      }

      legend {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
