const WORD_EXTENSIONS = ['doc', 'docx', 'docm', 'dotx', 'dotm', 'wopitest', 'wopitestx']
const EXCEL_EXTENSIONS = ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm']
const POWERPOINT_EXTENSIONS = ['ppt', 'pptx', 'pps']

export function isOfficeDocumentExtension (fileExtension: string): boolean {
  return [
    ...WORD_EXTENSIONS,
    ...EXCEL_EXTENSIONS,
    ...POWERPOINT_EXTENSIONS,
  ].includes(fileExtension.toLowerCase())
}

export type SupportedOfficeDocumentTypes = 'word' | 'excel' | 'powerpoint'

export function getOfficeDocumentType (fileExtension: string): SupportedOfficeDocumentTypes | null {
  const loweredFileExtension = fileExtension.toLowerCase()

  if (WORD_EXTENSIONS.includes(loweredFileExtension)) {
    return 'word'
  }

  if (EXCEL_EXTENSIONS.includes(loweredFileExtension)) {
    return 'excel'
  }

  if (POWERPOINT_EXTENSIONS.includes(loweredFileExtension)) {
    return 'powerpoint'
  }

  return null
}

export const OFFICE_EDITION_MAX_FILE_SIZE: Record<SupportedOfficeDocumentTypes, number> = {
  word: 100,
  excel: 25,
  powerpoint: 200,
}
