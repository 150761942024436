import axios from 'axios'

export default {
  getBibles (mnemo, params, isPolling = false) {
    let config = {}
    if (isPolling) {
      config = {
        headers: {
          'X-Poll': 'x-poll',
        },
      }
    }
    return axios.get(`/room/${mnemo}/bibles`, { params: params }, config)
  },
  postBible (mnemo, data) {
    return axios.post(`/room/${mnemo}/bible`, data)
  },
  deleteBible (mnemo, bibleId) {
    return axios.delete(`/room/${mnemo}/bible/${bibleId}/rights/read/self`)
  },
  postBibleRights (mnemo, bibleId, data) {
    return axios.post(`/room/${mnemo}/bible/${bibleId}/rights`, data)
  },
  exportBibleToImanage (mnemo, bibleId, data, token) {
    return axios.post(`/room/${mnemo}/imanageExport/bible/${bibleId}`, data, { headers: { 'X-CLOSD-IMCONFIG': token } })
  },
  getBible (mnemo, bibleId) {
    return axios.get(`/room/${mnemo}/bible/${bibleId}`)
  },
}
