<template>
  <div ref="member" class="py-1 member" :class="{'member--highlighted': isHighlighted}">
    <v-checkbox v-model="isSelected"
                :label="member.fullName"
                :disabled="disabled"
                hide-details
                class="DocumentSharePickerMember-label mt-0 pt-0 text--secondary"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { SELECT_MEMBER, UNSELECT_MEMBER } from '@/store/modules/document-share-picker/action_types'

export default {
  name: 'DocumentSharePickerMember',
  props: {
    member: {
      type: Object,
      required: true,
    },
    groupIsSelected: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    usersOnly: {
      type: Boolean,
      required: true,
    },
    multiSelect: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      isHighlighted: false,
    }
  },
  computed: {
    ...mapState('documentSharePicker', ['selectedMembers', 'disabledMembers', 'highlightedMember']),
    isSelected: {
      get () {
        return this.selectedMembers.some(member => member.id === this.member.id) ||
          this.groupIsSelected ||
          this.disabledMembers.some(member => member.id === this.member.id)
      },
      set (memberIsSelected) {
        if (memberIsSelected) {
          this.SELECT_MEMBER({
            member: this.member,
            multiselect: this.multiSelect,
          })
        } else {
          if (this.groupIsSelected) {
            this.$emit('removeMemberFromSelectedGroup', this.member.id)
          } else {
            this.UNSELECT_MEMBER(this.member)
          }
        }
      },
    },
  },
  watch: {
    highlightedMember (value) {
      if (value?.id === this.member.id) {
        this.isHighlighted = true
        this.$refs.member.scrollIntoView({ behavior: 'smooth', block: 'center' })
      } else {
        this.isHighlighted = false
      }
    },
  },
  methods: {
    ...mapActions('documentSharePicker', [SELECT_MEMBER, UNSELECT_MEMBER]),
  },
}
</script>

<style scoped lang="scss">
.member {
  transition: background-color .4s ease-in-out;

  &--highlighted {
    background-color: var(--v-grey-lighten3);
  }
}
</style>
