export const SET_GET_ROOM_LABELS_PENDING = 'SET_GET_ROOM_LABELS_PENDING'
export const SET_ROOMS_LABELS = 'SET_ROOMS_LABELS'
export const SET_GET_ROOM_LABELS_ERROR = 'SET_GET_ROOM_LABELS_ERROR'
export const SET_DISPLAY_TYPE = 'SET_DISPLAY_TYPE'
export const SET_SUBSCRIPTION_LABELS_PENDING = 'SET_SUBSCRIPTION_LABELS_PENDING'
export const SET_SUBSCRIPTION_LABELS = 'SET_SUBSCRIPTION_LABELS'
export const SET_SUBSCRIPTION_LABELS_ERROR = 'SET_SUBSCRIPTION_LABELS_ERROR'
export const SET_DATA_ROOM_PRICING_PENDING = 'SET_DATA_ROOM_PRICING_PENDING'
export const SET_DATA_ROOM_PRICING = 'SET_DATA_ROOM_PRICING'
export const SET_DATA_ROOM_PRICING_ERROR = 'SET_DATA_ROOM_PRICING_ERROR'
export const SET_DATA_ROOM_CUSTOM_PRICING_PENDING = 'SET_DATA_ROOM_CUSTOM_PRICING_PENDING'
export const SET_DATA_ROOM_CUSTOM_PRICING = 'SET_DATA_ROOM_CUSTOM_PRICING'
export const SET_DATA_ROOM_CUSTOM_PRICING_ERROR = 'SET_DATA_ROOM_CUSTOM_PRICING_ERROR'
export const SET_LAST_VISITED_ROOMS = 'SET_LAST_VISITED_ROOMS'
export const SET_LAST_VISITED_ROOMS_PENDING = 'SET_LAST_VISITED_ROOMS_PENDING'
export const SET_RECENT_ROOMS_PENDING = 'SET_RECENT_ROOMS_PENDING'
export const SET_RECENT_ROOMS = 'SET_RECENT_ROOMS'
export const SET_RECENT_ROOMS_ERROR = 'SET_RECENT_ROOMS_ERROR'
export const SET_ROOM_SEARCH_RESULTS_PENDING = 'SET_ROOM_SEARCH_RESULTS_PENDING'
export const SET_ROOM_SEARCH_RESULTS = 'SET_ROOM_SEARCH_RESULTS'
export const SET_ROOM_SEARCH_RESULTS_ERROR = 'SET_ROOM_SEARCH_RESULTS_ERROR'
export const SET_SEARCH_MODE_ENABLED = 'SET_SEARCH_MODE_ENABLED'
