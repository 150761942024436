<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             :ok-text="$t('common.yes')"
             :ok-disabled="!canCancel"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>{{ $tc(getTitleDialog, ids.length) }}</template>

    <template #body>
      <div v-if="deletionTypePending" class="text-center">
        <v-progress-circular
          size="48"
          indeterminate
          color="primary"
        />
      </div>
      <template v-else>
        <div v-if="!isMulti && !canCancel">
          {{ $t('project.signing-checklist.list.SigningChecklistCancelStepDialog.textCantCancel', {'status': $t('common.envelopeStatus.' + deletionType.status)}) }}
        </div>
        <div v-else>
          <template v-if="!isMulti && inClosing && deletionType.partOfClosing">
            <p>{{ $tc(getTextDialog) }}</p>
            <p>{{ $t('project.signing-checklist.list.SigningChecklistCancelStepDialog.textWithinClosingDesc1') }}</p>
          </template>
          <template v-else>
            <p>
              {{ $tc(getTextDialog, ids.length) }}
            </p>
            <p v-if="inClosing">{{ $t('project.signing-checklist.list.SigningChecklistCancelStepDialog.textClosingContinues') }}</p>
          </template>
        </div>
      </template>
    </template>
  </AppDialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { GET_DELETION_TYPE } from '../../../store/modules/signing-checklist/action_types'

const TRANSLATION_KEY_PREFIX = 'project.signing-checklist.list.SigningChecklistCancelStepDialog.'
const { mapState, mapActions } = createNamespacedHelpers('signingChecklist')

export default {
  name: 'SigningChecklistCancelStepDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    ids: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(['deletionType', 'deletionTypePending']),
    canCancel () {
      return this.isMulti || (!this.deletionTypePending &&
        (this.deletionType?.status === 'queued' || this.deletionType?.status === 'sent' || this.deletionType?.status === 'unvalidated')
      )
    },
    inClosing () {
      return this.isMulti
        ? this.$store.state.room.currentRoom.inClosing
        : this.deletionType && this.deletionType.inClosing
    },
    isMulti () {
      return this.ids.length > 1
    },
    getTextDialog () {
      let key = ''
      if (this.inClosing) {
        key = this.deletionType?.status !== 'unvalidated'
          ? 'textWithinClosing'
          : 'textSignaturesCancelledUnvalidated'
      } else {
        key = this.deletionType?.status !== 'unvalidated'
          ? 'textSignaturesCancelled'
          : 'textSignaturesCancelledUnvalidated'
      }
      return TRANSLATION_KEY_PREFIX + key
    },
    getTitleDialog () {
      const key = this.deletionType?.status !== 'unvalidated'
        ? 'title'
        : 'titleUnvalidated'
      return TRANSLATION_KEY_PREFIX + key
    },
  },
  created () {
    if (!this.isMulti) {
      this.GET_DELETION_TYPE({ mnemo: this.mnemo, stepId: this.ids[0] })
    }
  },
  methods: {
    ...mapActions([GET_DELETION_TYPE]),
    confirmClick () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
