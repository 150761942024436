/**
 * Represents a Room.
 * @constructor
 * @param {number} type - Room type.
 * @param {string} name - Room name.
 * @param {boolean} optHideGroups
 * @param {boolean} optAllowGuestBibles
 * @param {date} expClosingDate
 * @param {string} cmRef
 * @param {number} labelId
 */
export const RoomToPost = function (
  name = '',
  type = null,
  optHideGroups = false,
  optAllowGuestBibles = false,
  labelId = null,
  expClosingDate = null,
  cmRef = '',
) {
  this.name = name
  this.type = type
  this.optHideGroups = optHideGroups
  this.optAllowGuestBibles = optAllowGuestBibles
  this.labelId = labelId
  this.expClosingDate = expClosingDate
  this.cmRef = cmRef
}

/**
 * Represents a Data Room.
 * @constructor
 * @param {number} type - Room type.
 * @param {string} name - Room name.
 * @param {date} expClosingDate
 * @param {string} cmRef
 * @param {number} labelId
 * @param {number} duration
 * @param {number} storage
 * @param {string} unit
 */
export const DataRoomToPost = function (
  type = null,
  name = '',
  expClosingDate = null,
  cmRef = '',
  labelId = null,
  duration = null,
  storage = null,
  unit = null,
) {
  this.type = type
  this.name = name
  this.expClosingDate = expClosingDate
  this.cmRef = cmRef
  this.labelId = labelId
  this.duration = duration
  this.storage = storage
  this.unit = unit
}
