<template>
  <div class="d-flex flex-column row-gap-4">
    <template v-if="polyOfficeEnabled">
      <app-button :disabled="disabled"
                  class="align-self-center"
                  type="outlined"
                  variant="neutral"
                  @click="openPolyOfficeDialog"
      >
        <template #left-icon>
          <PolyOfficeIcon />
        </template>
        {{ $t('common.filders.ExternalFildersSelector.importFromPoly') }}
      </app-button>
      <PolyOfficeDialog v-if="polyOfficeDialogIsOpen"
                        @callback="polyOfficeDialogCallback"
      />
    </template>

    <template v-if="imanageEnabledAndLogged">
      <app-button :disabled="disabled"
                  class="align-self-center"
                  type="outlined"
                  variant="neutral"
                  @click="imanageDialogIsOpen = true"
      >
        <template #left-icon>
          <v-img src="/img/iManage_logo.png" width="25" />
        </template>
        {{ $t('common.filders.ExternalFildersSelector.importFromImanage') }}
      </app-button>
      <IManageFilePickerDialog :is-open.sync="imanageDialogIsOpen"
                               @files="onSelectFilesFromImanage"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import { VERSIONING_EXTERNAL_PROVIDERS } from '@/common/document-versioning/types.ts'
import IManageFilePickerDialog from '@/common/imanage/IManageFilePickerDialog.vue'
import { SigningChecklistImport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeDialog, { POLY_OFFICE_DIALOG_STATUS_SUCCESS } from '@/common/polyOffice/PolyOfficeDialog.vue'
import PolyOfficeIcon from '@/common/polyOffice/PolyOfficeIcon.vue'
import { IMANAGE_HIDDEN_FILE_IMPORT } from '@/store/modules/documents/action_types'
import { GET_ACTION_INFO, GET_IMPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'ExternalFildersSelector',
  components: {
    IManageFilePickerDialog,
    PolyOfficeDialog,
    PolyOfficeIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selectOnly: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      imanageDialogIsOpen: false,
    }
  },
  computed: {
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('room', ['roomMnemo']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    ...mapState('polyOffice', {
      polyOfficeDialogIsOpen: 'dialogIsOpen',
    }),
  },
  methods: {
    ...mapActions('documents', [IMANAGE_HIDDEN_FILE_IMPORT]),
    ...mapActions('polyOffice', [GET_IMPORT_IFRAME, GET_ACTION_INFO]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async importFromImanage (files) {
      const imanageFileImportPromises = files.output_data.selected.map(
        file => this.IMANAGE_HIDDEN_FILE_IMPORT({
          mnemo: this.roomMnemo,
          data: {
            file: file.id,
          },
        }),
      )

      const responses = await Promise.all(imanageFileImportPromises)
      const iManageFiles = responses.map(response => response.data)
      this.$emit('select', { files: iManageFiles, provider: VERSIONING_EXTERNAL_PROVIDERS.IMANAGE })
    },
    onSelectFilesFromImanage (files) {
      if (this.selectOnly) {
        this.$emit('select', { files: files, provider: VERSIONING_EXTERNAL_PROVIDERS.IMANAGE })
      } else {
        this.importFromImanage(files)
      }
    },
    async openPolyOfficeDialog () {
      const payload = new SigningChecklistImport(this.roomMnemo)
      await this.GET_IMPORT_IFRAME({
        payload,
        is_hidden: this.selectOnly,
        successMessage: this.$t('common.filders.ExternalFildersSelector.polyOffice.importSuccessful'),
      })
    },
    async polyOfficeDialogCallback (data) {
      if (data.status === POLY_OFFICE_DIALOG_STATUS_SUCCESS) {
        try {
          const response = await this.GET_ACTION_INFO()
          if (response.receiveStatus === 'DONE') {
            this.$emit('select', { files: response.receivedFilesData, provider: VERSIONING_EXTERNAL_PROVIDERS.POLY })
          } else {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.filders.ExternalFildersSelector.polyOffice.importFailure'))
          }
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.filders.ExternalFildersSelector.polyOffice.importFailure'))
        }
      }
    },
  },
})
</script>
