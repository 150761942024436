<template>
  <div
    v-if="permanentOverlay"
    class="smooth-overlay"
  ></div>
</template>

<script>
// TODO: Check if this component is really needed when Overlay already exists and is super similar
import { mapGetters } from 'vuex'
/**
 * Default permanent overlay
 * @displayName PermanentOverlay
 * @deprecated
 */
export default {
  name: 'PermanentOverlay',
  computed: {
    ...mapGetters(['permanentOverlay']),
  },
}
</script>
<style scoped>
.smooth-overlay {
  position: fixed;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 9;
  animation: 1s smooth-overlay;
  height: 100%;
}
@keyframes smooth-overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
