<template>
  <div
    v-if="overlay"
    class="smooth-overlay"
  ></div>
</template>

<script>
import { mapState } from 'vuex'
/**
 * Default Overlay
 * @displayName Overlay
 * @deprecated
 */
export default {
  name: 'Overlay',
  computed: {
    ...mapState(['overlay']),
  },
}
</script>
<style scoped>
.smooth-overlay {
  position: fixed;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 10;
  animation: 1s smooth-overlay;
  height: 100%;
}
@keyframes smooth-overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
