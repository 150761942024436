<template>
<v-row v-if="hasActiveFilter">
  <v-col>
    <div class="d-flex align-center">
      <div class="caption mr-4">{{$t('projects.ProjectsActiveFilters.activeFilters')}}</div>
      <v-chip v-if="filtersPane.filters.creationDate.length > 0"
              class="mr-4"
              close
              close-icon="fal fa-xmark"
              @click:close="removeCreationDateFilter"
      >
        {{ $t('projects.ProjectsActiveFilters.creationDateFilter') }}
      </v-chip>
      <v-chip v-if="filtersPane.filters.dueDate.length > 0"
              class="mr-4"
              close
              close-icon="fal fa-xmark"
              @click:close="removeDueDateFilter"
      >
        {{ $t('projects.ProjectsActiveFilters.dueDateFilter') }}
      </v-chip>
      <v-chip v-if="filtersPane.filters.selectedLabels.length > 0"
              class="mr-4"
              close
              close-icon="fal fa-xmark"
              @click:close="removeSelectedLabelsFilter"
      >
        {{ $t('projects.ProjectsActiveFilters.selectedLabelsFilter') }}
      </v-chip>
      <v-chip v-if="filtersPane.filters.projectType.length > 0"
              class="mr-4"
              close
              close-icon="fal fa-xmark"
              @click:close="removeProjectTypeFilter"
      >
        {{ $t('projects.ProjectsActiveFilters.projectTypeFilter') }}
      </v-chip>
      <v-chip v-if="filtersPane.filters.role.length > 0"
              class="mr-4"
              close
              close-icon="fal fa-xmark"
              @click:close="removeRoleFilter"
      >
        {{ $t('projects.ProjectsActiveFilters.roleFilter') }}
      </v-chip>
    </div>
  </v-col>
</v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProjectsActiveFilters',
  computed: {
    ...mapState('rooms', ['filtersPane']),
    hasActiveFilter () {
      return this.filtersPane.filters.creationDate.length > 0 ||
        this.filtersPane.filters.dueDate.length > 0 ||
        this.filtersPane.filters.selectedLabels.length > 0 ||
        this.filtersPane.filters.projectType.length > 0 ||
        this.filtersPane.filters.role.length > 0
    },
  },
  methods: {
    removeCreationDateFilter () {
      this.$store.commit('rooms/DELETE_CREATION_DATE_FILTER')
    },
    removeDueDateFilter () {
      this.$store.commit('rooms/DELETE_DUE_DATE_FILTER')
    },
    removeSelectedLabelsFilter () {
      this.$store.commit('rooms/DELETE_SELECTED_LABELS_FILTER')
    },
    removeProjectTypeFilter () {
      this.$store.commit('rooms/DELETE_PROJECT_TYPE_FILTER')
    },
    removeRoleFilter () {
      this.$store.commit('rooms/DELETE_ROLE_FILTER')
    },
  },
}
</script>
