import Vue from 'vue'

import { findStepParent } from '@/common/utils/signingChecklist'
import {
  ENVELOPE_TYPES,
  SIGNATURE_TYPE_DOCUSIGN_SIMPLE,
} from '@/project/signing-checklist/constants'

import {
  ADD_CREATE_SIGNING_CHECKLIST_STEP_PENDING,
  ADD_ENVELOPE_DOCUMENTS_TO_UPLOAD_QUEUE,
  ADD_IMPORTED_FILE,
  ADD_OBSERVERS,
  ADD_RECIPIENTS,
  ADD_REFRESH_STEP_PENDING,
  ADD_SIGNER_WITNESSES,
  ADD_UPDATE_SIGNING_CHECKLIST_STEP_PENDING,
  ASSIGN_SIGNER_ON_GENERIC_SIGNATURE,
  MARK_QUEUED_DOCUMENT_AS_UPLOADING,
  REMOVE_CANCEL_STEP_MULTIPLE_PENDING,
  REMOVE_CANCEL_STEP_PENDING,
  REMOVE_CREATE_SIGNING_CHECKLIST_STEP_PENDING,
  REMOVE_DELETE_GROUP_PENDING,
  REMOVE_DELETE_STEP_MULTIPLE_PENDING,
  REMOVE_DELETE_STEP_PENDING,
  REMOVE_DELETE_TITLE_PENDING,
  REMOVE_ENVELOPE_DOCUMENTS_FROM_UPLOAD_QUEUE,
  REMOVE_FROM_EXPAND_TITLE_AFTER_DELETE,
  REMOVE_IMPORTED_FILE,
  REMOVE_MOVE_GROUP_PENDING,
  REMOVE_MOVE_STEP_PENDING,
  REMOVE_OBSERVERS,
  REMOVE_RECIPIENTS,
  REMOVE_REFRESH_STEP_PENDING,
  REMOVE_RESET_STEP_MULTIPLE_PENDING,
  REMOVE_RESET_STEP_PENDING,
  REMOVE_SEND_SIGN_NOW_MULTIPLE_PENDING,
  REMOVE_SEND_SIGN_NOW_PENDING,
  REMOVE_SIGNERS,
  REMOVE_SIGNER_WITNESSES,
  REMOVE_SIGNING_CHECKLIST_FILTER,
  REMOVE_STEP_PENDING,
  REMOVE_TITLE_INDENTATION_PENDING,
  REMOVE_UPDATE_SIGNING_CHECKLIST_STEP_PENDING,
  REMOVE_UPDATE_TITLE_PENDING,
  REMOVE_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING,
  REMOVE_VALIDATE_THEN_SEND_NOW_PENDING,
  REPLACE_IMPORTED_FILE,
  SET_ADD_SELECTED_STEPS,
  SET_ATTACHMENT_FILE_TO_SHARE,
  SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_ERROR,
  SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_PENDING,
  SET_CANCEL_CLOSING_ERROR,
  SET_CANCEL_CLOSING_PENDING,
  SET_CANCEL_SCHEDULED_CLOSING_ERROR,
  SET_CANCEL_SCHEDULED_CLOSING_PENDING,
  SET_CANCEL_STEP_ERROR,
  SET_CANCEL_STEP_MODAL,
  SET_CANCEL_STEP_MULTIPLE_ERROR,
  SET_CANCEL_STEP_MULTIPLE_PENDING,
  SET_CANCEL_STEP_PENDING,
  SET_CLOSING_STARTED,
  SET_CLOSING_SUMMARY,
  SET_CLOSING_SUMMARY_ERROR,
  SET_CLOSING_SUMMARY_PENDING,
  SET_COMPILE_FINAL_FILE_ERROR,
  SET_COMPILE_FINAL_FILE_PENDING,
  SET_CREATE_GROUP_ERROR,
  SET_CREATE_SIGNING_CHECKLIST_STEP_ERROR,
  SET_CREATE_TITLE_ERROR,
  SET_CREATE_TITLE_PENDING,
  SET_CURRENT_TITLE_EDIT,
  SET_DELETE_COMPILED_FILE_ERROR,
  SET_DELETE_COMPILED_FILE_PENDING,
  SET_DELETE_GROUP_ERROR,
  SET_DELETE_GROUP_PENDING,
  SET_DELETE_STEP_ERROR,
  SET_DELETE_STEP_MODAL,
  SET_SWAP_FILES,
  SET_DELETE_STEP_MULTIPLE_ERROR,
  SET_DELETE_STEP_MULTIPLE_PENDING,
  SET_DELETE_STEP_PENDING,
  SET_DELETE_TITLE_ERROR,
  SET_DELETE_TITLE_MODAL,
  SET_DELETE_TITLE_PENDING,
  SET_DELETE_VALIDATION_ERROR,
  SET_DELETE_VALIDATION_PENDING,
  SET_DELETION_TYPE,
  SET_DELETION_TYPE_ERROR,
  SET_DELETION_TYPE_PENDING,
  SET_EDIT_TITLE,
  SET_EXPAND_ALL_SIGNING_CHECKLIST,
  SET_EXPAND_SIGNING_CHECKLIST,
  SET_FILTERS_PANE_IS_OPEN,
  SET_IMPORTED_FILES,
  SET_IMPORTED_FILE_DOCUMENT_NAME,
  SET_IMPORTED_FILE_ENVELOPE_TYPE,
  SET_IMPORTED_FILE_MERCURY_OPTION,
  SET_IMPORTED_FILE_SIGNATURE_DATE,
  SET_IMPORTED_FILE_SIGNATURE_TYPE,
  SET_IS_PANE_OPENED,
  SET_IS_SENDING_DOCUMENT,
  SET_MOVE_GROUP_ERROR,
  SET_MOVE_GROUP_PENDING,
  SET_MOVE_STEP_ERROR,
  SET_MOVE_STEP_PENDING,
  SET_PANE_TAB,
  SET_PARENT_CHILDREN,
  SET_PATCH_COMPILED_FILE_ERROR,
  SET_PATCH_COMPILED_FILE_PENDING,
  SET_POST_FINAL_FILE_ERROR,
  SET_POST_FINAL_FILE_PENDING,
  SET_POST_LAST_COMPILED_FILE_ERROR,
  SET_POST_LAST_COMPILED_FILE_PENDING,
  SET_POST_MOVE_STEP_ERROR,
  SET_POST_MOVE_STEP_PENDING,
  SET_POST_SIGNED_FILE_ERROR,
  SET_POST_SIGNED_FILE_PENDING,
  SET_POST_VALIDATION_ERROR,
  SET_POST_VALIDATION_PENDING,
  SET_QUALIFIED_DS_WARNING_IS_MUTED,
  SET_REFRESH_CLOSING_STATUS_ERROR,
  SET_REFRESH_CLOSING_STATUS_PENDING,
  SET_REFRESH_SIGNING_CHECKLIST,
  SET_REFRESH_SIGNING_CHECKLIST_ERROR,
  SET_REFRESH_SIGNING_CHECKLIST_PENDING,
  SET_CLOSING_PREDICT,
  SET_CLOSING_PREDICT_ERROR,
  SET_CLOSING_PREDICT_PENDING,
  SET_REFRESH_STATUS_ERROR,
  SET_REFRESH_STATUS_PENDING,
  SET_REFRESH_STEP,
  SET_REFRESH_STEP_ERROR,
  SET_REFRESH_STEP_PENDING_IDS,
  SET_REMOVE_SELECTED_STEPS,
  SET_RESET_SIGNATURE_FILES,
  SET_RESET_SIGNATURE_FILES_ERROR,
  SET_RESET_SIGNATURE_FILES_PENDING,
  SET_RESET_STEP_ERROR,
  SET_RESET_STEP_MULTIPLE_ERROR,
  SET_RESET_STEP_MULTIPLE_PENDING,
  SET_RESET_STEP_PENDING,
  SET_SEARCH_QUERY,
  SET_SEARCH_QUERY_PENDING,
  SET_SELECTED_STEPS,
  SET_SEND_SIGN_NOW_ERROR,
  SET_SEND_SIGN_NOW_MULTIPLE_ERROR,
  SET_SEND_SIGN_NOW_MULTIPLE_PENDING,
  SET_SEND_SIGN_NOW_PENDING,
  SET_SEND_SIGN_STEP_MODAL,
  SET_SHARE_ATTACHMENT_PENDING,
  SET_SIGNATURE_FILES,
  SET_SIGNATURE_FILES_ERROR,
  SET_SIGNATURE_FILES_PENDING,
  SET_SIGNERS_GROUP_ORDER,
  SET_SIGNING_CHECKLIST,
  SET_SIGNING_CHECKLIST_ERROR,
  SET_SIGNING_CHECKLIST_FILTERS,
  SET_SIGNING_CHECKLIST_GROUP,
  SET_SIGNING_CHECKLIST_GROUP_ERROR,
  SET_SIGNING_CHECKLIST_GROUP_PENDING,
  SET_SIGNING_CHECKLIST_PENDING,
  SET_SIGNING_CHECKLIST_STEP,
  SET_SIGNING_VIEW,
  SET_SIGNING_VIEW_ERROR,
  SET_SIGNING_VIEW_PENDING,
  SET_START_CLOSING_ERROR,
  SET_START_CLOSING_PENDING,
  SET_START_SCHEDULED_CLOSING_ERROR,
  SET_START_SCHEDULED_CLOSING_PENDING,
  SET_STEP_ERROR,
  SET_STEP_PENDING,
  SET_SWAP_FILES_PENDING,
  SET_TITLE_INDENTATION_ERROR,
  SET_TITLE_INDENTATION_PENDING,
  SET_TOTAL_DOCUMENTS,
  SET_UPDATE_CLOSING_END_DATE_ERROR,
  SET_UPDATE_CLOSING_END_DATE_PENDING,
  SET_UPDATE_SIGNING_CHECKLIST_STEP_ERROR,
  SET_UPDATE_TITLE_ERROR,
  SET_UPDATE_TITLE_PENDING,
  SET_VALIDATE_THEN_SEND_NOW_ERROR,
  SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_ERROR,
  SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING,
  SET_VALIDATE_THEN_SEND_NOW_PENDING,
  SET_VOIDED_TO_DRAFT_STEP_MODAL,
  TOGGLE_SELECTED_STEP,
} from './mutation_types'

export const mutations = {
  [SET_IMPORTED_FILES] (state, value) {
    state.importedFiles = value
  },
  [ADD_IMPORTED_FILE] (state, value) {
    state.importedFiles.push(value)
  },
  [REMOVE_IMPORTED_FILE] (state, index) {
    state.importedFiles.splice(index, 1)
  },
  [REPLACE_IMPORTED_FILE] (state, { index, value }) {
    state.importedFiles.splice(index, 1, value)
  },
  [REMOVE_FROM_EXPAND_TITLE_AFTER_DELETE] (state, titleId) {
    const item = state.isExpandSigningChecklist.findIndex(item => item.id === titleId)
    if (item >= 0) {
      state.isExpandSigningChecklist.splice(item, 1)
    }
  },
  [SET_CLOSING_STARTED] (state, value) {
    state.closingStarted = value
  },
  [SET_DELETE_TITLE_MODAL] (state, value) {
    state.deleteTitleModal = value
  },
  [SET_SEND_SIGN_STEP_MODAL] (state, value) {
    state.sendSignStepModal = value
  },
  [SET_VOIDED_TO_DRAFT_STEP_MODAL] (state, value) {
    state.voidedToDraftStepModal = value
  },
  [SET_EDIT_TITLE] (state, payload) {
    if (Array.isArray(payload.parent)) {
      Vue.set(payload.parent, payload.parent.findIndex(el => el.id === payload.item.id && el.type === 'title'), payload.item)
    } else {
      Vue.set(payload.parent.children, payload.parent.children.findIndex(el => el.id === payload.item.id && el.type === 'title'), payload.item)
    }
  },
  [SET_RESET_STEP_MULTIPLE_PENDING] (state, value) {
    state.resetStepMultiplePendingIds =
      state.resetStepMultiplePendingIds.concat(value)
  },
  [REMOVE_RESET_STEP_MULTIPLE_PENDING] (state, ids) {
    state.resetStepMultiplePendingIds =
      state.resetStepMultiplePendingIds.filter((id) => ids.includes(id))
  },
  [SET_RESET_STEP_MULTIPLE_ERROR] (state, value) {
    state.resetStepMultipleError = value
  },
  [SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING] (state, value) {
    state.validateThenSendNowMultiplePendingIds =
    state.validateThenSendNowMultiplePendingIds.concat(value)
  },
  [REMOVE_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING] (state, ids) {
    state.validateThenSendNowMultiplePendingIds =
      state.validateThenSendNowMultiplePendingIds.filter((id) => ids.includes(id))
  },
  [SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_ERROR] (state, value) {
    state.validateThenSendNowMultipleError = value
  },
  [SET_START_CLOSING_PENDING] (state, value) {
    state.startClosingPending = value
  },
  [SET_START_CLOSING_ERROR] (state, value) {
    state.startClosingError = value
  },
  [SET_START_SCHEDULED_CLOSING_PENDING] (state, value) {
    state.startScheduledClosingPending = value
  },
  [SET_START_SCHEDULED_CLOSING_ERROR] (state, value) {
    state.startScheduledClosingError = value
  },
  [SET_CANCEL_SCHEDULED_CLOSING_PENDING] (state, value) {
    state.cancelScheduledClosingPending = value
  },
  [SET_CANCEL_SCHEDULED_CLOSING_ERROR] (state, value) {
    state.cancelScheduledClosingError = value
  },
  [SET_CANCEL_CLOSING_PENDING] (state, value) {
    state.cancelClosingPending = value
  },
  [SET_CANCEL_CLOSING_ERROR] (state, value) {
    state.cancelClosingError = value
  },
  [SET_CLOSING_SUMMARY_PENDING] (state, value) {
    state.closingSummaryPending = value
  },
  [SET_CLOSING_SUMMARY] (state, value) {
    state.closingSummary = value
  },
  [SET_CLOSING_SUMMARY_ERROR] (state, value) {
    state.closingSummaryError = value
  },
  [SET_TITLE_INDENTATION_PENDING] (state, value) {
    state.titleIndentationPendingIds.push(value)
  },
  [REMOVE_TITLE_INDENTATION_PENDING] (state, index) {
    state.titleIndentationPendingIds.splice(index, 1)
  },
  [SET_TITLE_INDENTATION_ERROR] (state, value) {
    state.titleIndentationError = value
  },
  [SET_DELETE_TITLE_PENDING] (state, value) {
    state.deleteTitlePendingIds.push(value)
  },
  [REMOVE_DELETE_TITLE_PENDING] (state, index) {
    state.deleteTitlePendingIds.splice(index, 1)
  },
  [SET_DELETE_TITLE_ERROR] (state, value) {
    state.deleteTitleError = value
  },
  [SET_SEND_SIGN_NOW_MULTIPLE_PENDING] (state, value) {
    state.sendSignNowMultiplePendingIds = state.sendSignNowMultiplePendingIds.concat(value)
  },
  [REMOVE_SEND_SIGN_NOW_MULTIPLE_PENDING] (state, ids) {
    state.sendSignNowMultiplePendingIds =
      state.sendSignNowMultiplePendingIds.filter((id) => !ids.includes(id))
  },
  [SET_SEND_SIGN_NOW_MULTIPLE_ERROR] (state, value) {
    state.sendSignNowMultipleError = value
  },
  [SET_CANCEL_STEP_MULTIPLE_PENDING] (state, value) {
    state.cancelStepMultiplePendingIds =
      state.cancelStepMultiplePendingIds.concat(value)
  },
  [REMOVE_CANCEL_STEP_MULTIPLE_PENDING] (state, ids) {
    state.cancelStepMultiplePendingIds =
      state.cancelStepMultiplePendingIds.filter((id) => !ids.includes(id))
  },
  [SET_CANCEL_STEP_MULTIPLE_ERROR] (state, value) {
    state.cancelStepMultipleError = value
  },
  [SET_DELETE_STEP_MULTIPLE_PENDING] (state, value) {
    state.deleteStepMultiplePendingIds =
      state.deleteStepMultiplePendingIds.concat(value)
  },
  [REMOVE_DELETE_STEP_MULTIPLE_PENDING] (state, ids) {
    state.deleteStepMultiplePendingIds =
      state.deleteStepMultiplePendingIds.filter((id) => !ids.includes(id))
  },
  [SET_DELETE_STEP_MULTIPLE_ERROR] (state, value) {
    state.deleteStepMultipleError = value
  },
  [SET_REFRESH_STATUS_PENDING] (state, value) {
    state.refreshStatusPending = value
  },
  [SET_REFRESH_STATUS_ERROR] (state, value) {
    state.refreshStatusError = value
  },
  [SET_CLOSING_PREDICT] (state, value) {
    state.closingPredict = value
  },
  [SET_CLOSING_PREDICT_PENDING] (state, value) {
    state.closingPredictPending = value
  },
  [SET_CLOSING_PREDICT_ERROR] (state, value) {
    state.closingPredictError = value
  },
  [SET_REFRESH_CLOSING_STATUS_PENDING] (state, value) {
    state.refreshClosingStatusPending = value
  },
  [SET_REFRESH_CLOSING_STATUS_ERROR] (state, value) {
    state.refreshClosingStatusError = value
  },
  [SET_CURRENT_TITLE_EDIT] (state, value) {
    state.currentTitleEdit = value
  },
  [SET_CANCEL_STEP_MODAL] (state, value) {
    state.cancelStepModal = value
  },
  [SET_DELETE_STEP_MODAL] (state, value) {
    state.deleteStepModal = value
  },
  [SET_SWAP_FILES] (state, value) {
    state.swapFiles = value
  },
  [SET_SWAP_FILES_PENDING] (state, value) {
    state.swapFilesPending = value
  },
  [SET_PARENT_CHILDREN] (state, payload) {
    Vue.set(payload.parent, 'children', payload.children)
  },
  [SET_SIGNING_CHECKLIST_PENDING] (state, value) {
    state.signingChecklistPending = value
  },
  [SET_SIGNING_CHECKLIST] (state, value) {
    state.signingChecklist = value
  },
  [SET_SIGNING_CHECKLIST_ERROR] (state, value) {
    state.signingChecklistError = value
  },
  [SET_SIGNING_CHECKLIST_GROUP_PENDING] (state, value) {
    state.signingChecklistGroupsPending = value
  },
  [SET_SIGNING_CHECKLIST_GROUP] (state, value) {
    state.signingChecklistGroups = value
  },
  [SET_SIGNING_CHECKLIST_GROUP_ERROR] (state, value) {
    state.signingChecklistGroupsError = value
  },
  [SET_CREATE_GROUP_ERROR] (state, value) {
    state.createGroupError = value
  },
  [SET_DELETE_GROUP_PENDING] (state, value) {
    state.deleteGroupPendingIds.push(value)
  },
  [REMOVE_DELETE_GROUP_PENDING] (state, index) {
    state.deleteGroupPendingIds.splice(index, 1)
  },
  [SET_DELETE_GROUP_ERROR] (state, value) {
    state.deleteGroupError = value
  },
  [SET_MOVE_STEP_PENDING] (state, value) {
    state.moveStepPendingIds.push(value)
  },
  [REMOVE_MOVE_STEP_PENDING] (state, index) {
    state.moveStepPendingIds.splice(index, 1)
  },
  [SET_MOVE_STEP_ERROR] (state, value) {
    state.moveStepError = value
  },
  [SET_MOVE_GROUP_PENDING] (state, value) {
    state.moveGroupPendingIds.push(value)
  },
  [REMOVE_MOVE_GROUP_PENDING] (state, index) {
    state.moveGroupPendingIds.splice(index, 1)
  },
  [SET_MOVE_GROUP_ERROR] (state, value) {
    state.moveGroupError = value
  },
  [SET_SIGNING_VIEW_PENDING] (state, value) {
    state.signingViewPending = value
  },
  [SET_SIGNING_VIEW] (state, value) {
    state.signingView = value
  },
  [SET_SIGNING_VIEW_ERROR] (state, value) {
    state.signingViewError = value
  },
  [ADD_CREATE_SIGNING_CHECKLIST_STEP_PENDING] (state, value) {
    state.createSigningChecklistStepPendingIds.push(value)
  },
  [REMOVE_CREATE_SIGNING_CHECKLIST_STEP_PENDING] (state, index) {
    state.createSigningChecklistStepPendingIds.splice(index, 1)
  },
  [SET_CREATE_SIGNING_CHECKLIST_STEP_ERROR] (state, value) {
    state.createSigningCheckListStepError = value
  },
  [ADD_UPDATE_SIGNING_CHECKLIST_STEP_PENDING] (state, value) {
    state.updateSigningChecklistStepPendingIds.push(value)
  },
  [REMOVE_UPDATE_SIGNING_CHECKLIST_STEP_PENDING] (state, index) {
    state.updateSigningChecklistStepPendingIds.splice(index, 1)
  },
  [SET_UPDATE_SIGNING_CHECKLIST_STEP_ERROR] (state, value) {
    state.updateSigningCheckListStepError = value
  },
  [SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_PENDING] (state, value) {
    state.bulkUpsertSigningChecklistStepPending = value
  },
  [SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_ERROR] (state, value) {
    state.bulkUpsertSigningChecklistStepError = value
  },
  [SET_POST_SIGNED_FILE_PENDING] (state, value) {
    state.postSignedFilePending = value
  },

  [SET_POST_SIGNED_FILE_ERROR] (state, value) {
    state.postSignedFileError = value
  },
  [SET_POST_FINAL_FILE_PENDING] (state, value) {
    state.postFinalFilePending = value
  },
  [SET_POST_FINAL_FILE_ERROR] (state, value) {
    state.postFinalFileError = value
  },
  [SET_COMPILE_FINAL_FILE_PENDING] (state, value) {
    state.compileFinalFilePending = value
  },
  [SET_COMPILE_FINAL_FILE_ERROR] (state, value) {
    state.compileFinalFileError = value
  },
  [SET_SIGNATURE_FILES_PENDING] (state, value) {
    state.signatureFilesPending = value
  },
  [SET_SIGNATURE_FILES] (state, value) {
    state.signatureFiles = value
  },
  [SET_SIGNATURE_FILES_ERROR] (state, value) {
    state.signatureFilesError = value
  },
  [SET_RESET_SIGNATURE_FILES_PENDING] (state, value) {
    state.resetSignatureFilesPending = value
  },
  [SET_RESET_SIGNATURE_FILES_ERROR] (state, value) {
    state.resetSignatureFilesError = value
  },
  [SET_RESET_SIGNATURE_FILES] (state) {
    state.signatureFiles = null
  },
  [SET_DELETE_VALIDATION_PENDING] (state, value) {
    state.deleteValidationPending = value
  },
  [SET_DELETE_VALIDATION_ERROR] (state, value) {
    state.deleteValidationError = value
  },
  [SET_REFRESH_STEP_PENDING_IDS] (state, value) {
    state.refreshStepPendingIds = value
  },
  [ADD_REFRESH_STEP_PENDING] (state, value) {
    state.refreshStepPendingIds.push(value)
  },
  [REMOVE_REFRESH_STEP_PENDING] (state, index) {
    state.refreshStepPendingIds.splice(index, 1)
  },
  [SET_REFRESH_STEP_ERROR] (state, value) {
    state.refreshStepError = value
  },
  [SET_REFRESH_STEP] (state, payload) {
    Vue.set(payload.parent.children, payload.parent.children.findIndex(el => el.id === payload.newStep.id), payload.newStep)
  },
  [SET_POST_VALIDATION_PENDING] (state, value) {
    state.postValidationPending = value
  },
  [SET_POST_VALIDATION_ERROR] (state, value) {
    state.postValidationError = value
  },
  [SET_POST_MOVE_STEP_PENDING] (state, value) {
    state.postMoveStepPending = value
  },
  [SET_POST_MOVE_STEP_ERROR] (state, value) {
    state.postMoveStepError = value
  },
  [SET_REFRESH_SIGNING_CHECKLIST_PENDING] (state, value) {
    state.refreshSigningChecklistPending = value
  },
  [SET_REFRESH_SIGNING_CHECKLIST] (state, value) {
    state.signingChecklist = value
  },
  [SET_REFRESH_SIGNING_CHECKLIST_ERROR] (state, value) {
    state.refreshSigningChecklistError = value
  },
  [SET_SELECTED_STEPS] (state, value) {
    state.selectedSteps = value
  },
  [TOGGLE_SELECTED_STEP] (state, value) {
    const itemIndex = state.selectedSteps.findIndex(item => item.id === value.id && item.type === value.type)
    if (itemIndex >= 0) {
      state.selectedSteps.splice(itemIndex, 1)
    } else {
      state.selectedSteps.push(value)
    }
  },
  [SET_CANCEL_STEP_PENDING] (state, value) {
    state.cancelStepPendingIds.push(value)
  },
  [REMOVE_CANCEL_STEP_PENDING] (state, index) {
    state.cancelStepPendingIds.splice(index, 1)
  },
  [SET_CANCEL_STEP_ERROR] (state, value) {
    state.cancelStepError = value
  },
  [SET_VALIDATE_THEN_SEND_NOW_PENDING] (state, value) {
    state.validateThenSendNowPendingIds.push(value)
  },
  [REMOVE_VALIDATE_THEN_SEND_NOW_PENDING] (state, index) {
    state.validateThenSendNowPendingIds.splice(index, 1)
  },
  [SET_VALIDATE_THEN_SEND_NOW_ERROR] (state, value) {
    state.validateThenSendNowError = value
  },
  [SET_RESET_STEP_PENDING] (state, value) {
    state.resetStepPendingIds.push(value)
  },
  [REMOVE_RESET_STEP_PENDING] (state, index) {
    state.resetStepPendingIds.splice(index, 1)
  },
  [SET_RESET_STEP_ERROR] (state, value) {
    state.resetStepError = value
  },
  [SET_UPDATE_TITLE_PENDING] (state, value) {
    state.updateTitlePendingIds.push(value)
  },
  [REMOVE_UPDATE_TITLE_PENDING] (state, index) {
    state.updateTitlePendingIds.splice(index, 1)
  },
  [SET_UPDATE_TITLE_ERROR] (state, value) {
    state.updateTitleError = value
  },
  [SET_CREATE_TITLE_PENDING] (state, value) {
    state.createTitlePending = value
  },
  [SET_CREATE_TITLE_ERROR] (state, value) {
    state.createTitleError = value
  },
  [SET_DELETE_STEP_PENDING] (state, value) {
    state.deleteStepPendingIds.push(value)
  },
  [REMOVE_DELETE_STEP_PENDING] (state, index) {
    state.deleteStepPendingIds.splice(index, 1)
  },
  [SET_DELETE_STEP_ERROR] (state, value) {
    state.deleteStepError = value
  },
  [SET_SEND_SIGN_NOW_PENDING] (state, value) {
    state.sendSignNowPendingIds.push(value)
  },
  [REMOVE_SEND_SIGN_NOW_PENDING] (state, index) {
    state.sendSignNowPendingIds.splice(index, 1)
  },
  [SET_SEND_SIGN_NOW_ERROR] (state, value) {
    state.sendSignNowError = value
  },
  [SET_DELETION_TYPE_PENDING] (state, value) {
    state.deletionTypePending = value
  },
  [SET_DELETION_TYPE] (state, value) {
    state.deletionType = value
  },
  [SET_DELETION_TYPE_ERROR] (state, value) {
    state.deletionTypeError = value
  },
  [SET_ADD_SELECTED_STEPS] (state, value) {
    const itemIndex = state.selectedSteps.findIndex(item => item.id === value.id && item.type === value.type)
    if (itemIndex < 0) {
      state.selectedSteps.push(value)
    }
  },
  [SET_REMOVE_SELECTED_STEPS] (state, value) {
    const itemIndex = state.selectedSteps.findIndex(item => item.id === value.id && item.type === value.type)
    if (itemIndex >= 0) {
      state.selectedSteps.splice(itemIndex, 1)
    }
  },
  [SET_STEP_PENDING] (state, value) {
    state.stepPendingIds.push(value)
  },
  [REMOVE_STEP_PENDING] (state, index) {
    state.stepPendingIds.splice(index, 1)
  },
  [SET_STEP_ERROR] (state, value) {
    state.stepError = value
  },
  [SET_POST_LAST_COMPILED_FILE_PENDING] (state, value) {
    state.postLastCompiledFilePending = value
  },
  [SET_POST_LAST_COMPILED_FILE_ERROR] (state, value) {
    state.postLastCompiledFileError = value
  },
  [SET_DELETE_COMPILED_FILE_PENDING] (state, value) {
    state.deleteCompiledFilePending = value
  },
  [SET_DELETE_COMPILED_FILE_ERROR] (state, value) {
    state.deleteCompiledFileError = value
  },
  [SET_PATCH_COMPILED_FILE_PENDING] (state, value) {
    state.patchCompiledFilePending = value
  },
  [SET_PATCH_COMPILED_FILE_ERROR] (state, value) {
    state.patchCompiledFileError = value
  },
  [SET_IS_PANE_OPENED] (state, isOpen) {
    state.pane.isOpen = isOpen
  },
  [SET_PANE_TAB] (state, value) {
    state.pane.tab = value
  },
  [SET_ATTACHMENT_FILE_TO_SHARE] (state, file) {
    state.pane.fileToShare = file
  },
  [SET_SHARE_ATTACHMENT_PENDING] (state, value) {
    state.shareAttachmentPending = value
  },
  [SET_QUALIFIED_DS_WARNING_IS_MUTED] (state, value) {
    state.qualifiedDSWarningIsMuted = value
  },
  [SET_EXPAND_SIGNING_CHECKLIST] (state, value) {
    const item = state.isExpandSigningChecklist.findIndex(item => item.id === value.id)
    if (item >= 0) {
      state.isExpandSigningChecklist.splice(item, 1)
    }
    state.isExpandSigningChecklist.push(value)
  },
  [SET_EXPAND_ALL_SIGNING_CHECKLIST] (state, value) {
    for (let index = 0; index < value.items.length; index++) {
      const item = state.isExpandSigningChecklist.findIndex(item => item.id === value.items[index].id)
      if (item < 0) {
        state.isExpandSigningChecklist.push({ id: value.items[index].id, value: value.value })
      } else {
        state.isExpandSigningChecklist[index].value = value.value
      }
    }
  },
  [SET_IMPORTED_FILE_SIGNATURE_TYPE] (state, { fileId, signatureType }) {
    const fileToUpdateIndex = state.importedFiles.findIndex(file => file.id === fileId)

    if (fileToUpdateIndex > -1) {
      state.importedFiles[fileToUpdateIndex].objectToPost.signatureType = signatureType

      if (signatureType === SIGNATURE_TYPE_DOCUSIGN_SIMPLE) {
        Vue.set(state.importedFiles[fileToUpdateIndex].objectToPost, 'witnesses', [])
      } else {
        if (state.importedFiles[fileToUpdateIndex].objectToPost.witnesses?.length) {
          const currentObservers = [...state.importedFiles[fileToUpdateIndex].objectToPost.observers]
          const currentWitness = state.importedFiles[fileToUpdateIndex].objectToPost.witnesses.flatMap(x => x.witness)
          state.importedFiles[fileToUpdateIndex].objectToPost.observers = currentObservers.filter(
            currentObserver => !currentWitness.includes(currentObserver),
          )

          const currentRecipients = [...state.importedFiles[fileToUpdateIndex].objectToPost.recipients]
          state.importedFiles[fileToUpdateIndex].objectToPost.recipients = currentRecipients.filter(
            currentRecipient => !currentWitness.includes(currentRecipient),
          )
        }
        Vue.delete(state.importedFiles[fileToUpdateIndex].objectToPost, 'witnesses')
      }
    }
  },
  [SET_IMPORTED_FILE_SIGNATURE_DATE] (state, { fileId, signatureDate }) {
    const fileToUpdateIndex = state.importedFiles.findIndex(file => file.id === fileId)

    if (fileToUpdateIndex > -1) {
      state.importedFiles[fileToUpdateIndex].objectToPost.date = signatureDate
    }
  },
  [SET_IMPORTED_FILE_DOCUMENT_NAME] (state, { fileId, documentName }) {
    const fileToUpdateIndex = state.importedFiles.findIndex(file => file.id === fileId)

    if (fileToUpdateIndex > -1) {
      state.importedFiles[fileToUpdateIndex].objectToPost.title = documentName
    }
  },
  [SET_IMPORTED_FILE_MERCURY_OPTION] (state, { fileId, value }) {
    const fileToUpdateIndex = state.importedFiles.findIndex(file => file.id === fileId)

    if (fileToUpdateIndex > -1) {
      state.importedFiles[fileToUpdateIndex].providerOptions.mercury = value
    }
  },
  [SET_IMPORTED_FILE_ENVELOPE_TYPE] (state, { fileId, value }) {
    const fileToUpdateIndex = state.importedFiles.findIndex(file => file.id === fileId)
    const importedFileObjectToPost = state.importedFiles[fileToUpdateIndex].objectToPost

    if (fileToUpdateIndex > -1) {
      importedFileObjectToPost.envelopeType = value
      if (value === ENVELOPE_TYPES.SINGLE) {
        const previousUniqueSigners = importedFileObjectToPost.uniqueSigners
        const lastGroupSigners = importedFileObjectToPost.signers[importedFileObjectToPost.signers.length - 1]
        importedFileObjectToPost.signers.splice(importedFileObjectToPost.signers.length - 1, 1, [...lastGroupSigners, ...previousUniqueSigners])
        Vue.delete(importedFileObjectToPost, 'uniqueSigners')
      } else if (value === ENVELOPE_TYPES.MULTIPLE) {
        Vue.set(importedFileObjectToPost, 'signers', [importedFileObjectToPost.signers.flat()])
        Vue.set(importedFileObjectToPost, 'uniqueSigners', [])
        Vue.delete(importedFileObjectToPost, 'witnesses')
      }
    }
  },
  [SET_UPDATE_CLOSING_END_DATE_PENDING] (state, value) {
    state.updateClosingEndDatePending = value
  },
  [SET_UPDATE_CLOSING_END_DATE_ERROR] (state, value) {
    state.updateClosingEndDateError = value
  },
  [SET_SIGNERS_GROUP_ORDER] (state, { groups, file }) {
    Vue.set(file.objectToPost, 'signers', groups)
  },
  [SET_IS_SENDING_DOCUMENT] (state, value) {
    state.isSendingDocument = value
  },
  [SET_SIGNING_CHECKLIST_STEP] (state, step) {
    const stepParent = findStepParent(step, state.signingChecklist)
    Vue.set(stepParent.children, stepParent.children.findIndex(checklistItem => checklistItem.id === step.id), step)
  },
  // Filters
  [REMOVE_SIGNING_CHECKLIST_FILTER] (state, filterToRemove) {
    state.filters = state.filters.filter(
      filter => filter.category.key !== filterToRemove.category.key || filter.value.key !== filterToRemove.value.key,
    )
  },
  [SET_SIGNING_CHECKLIST_FILTERS] (state, value) {
    state.filters = value
  },
  [SET_FILTERS_PANE_IS_OPEN] (state, isOpen) {
    state.filtersPaneIsOpen = isOpen
  },
  // Search
  [SET_SEARCH_QUERY] (state, searchQuery) {
    state.searchQuery = searchQuery
  },
  [SET_SEARCH_QUERY_PENDING] (state, value) {
    state.searchQueryPending = value
  },
  [ADD_ENVELOPE_DOCUMENTS_TO_UPLOAD_QUEUE] (state, documents) {
    const documentsToQueued = documents.reduce(
      (result, document) => ({ ...result, [document.queueId]: document }),
      {},
    )

    state.envelopeDocumentsUploadQueue = {
      ...state.envelopeDocumentsUploadQueue,
      ...documentsToQueued,
    }
  },
  [REMOVE_ENVELOPE_DOCUMENTS_FROM_UPLOAD_QUEUE] (state, queueId) {
    if (state.envelopeDocumentsUploadQueue[queueId]) {
      const { [queueId]: removedDocument, ...queue } = state.envelopeDocumentsUploadQueue
      state.envelopeDocumentsUploadQueue = queue
    }
  },
  [MARK_QUEUED_DOCUMENT_AS_UPLOADING] (state, queueId) {
    if (state.envelopeDocumentsUploadQueue[queueId]) {
      state.envelopeDocumentsUploadQueue[queueId].status = 'uploading'
    }
  },
  [ASSIGN_SIGNER_ON_GENERIC_SIGNATURE] (state, { fileId, signatureId, signer }) {
    const fileToUpdateIndex = state.importedFiles.findIndex(file => file.id === fileId)

    if (fileToUpdateIndex > -1) {
      state.importedFiles[fileToUpdateIndex].templateSignatures.forEach((templateSignature, index) => {
        if (templateSignature.assignedSigner?.id === signer.id) {
          state.importedFiles[fileToUpdateIndex].templateSignatures[index].assignedSigner = null
        }

        if (templateSignature.id === signatureId) {
          state.importedFiles[fileToUpdateIndex].templateSignatures[index].assignedSigner = signer
        }
      })
    }
  },
  [ADD_SIGNER_WITNESSES] (state, { importedFileId, signersWithWitnesses }) {
    const importedFile = state.importedFiles.find(importedFile => importedFile.id === importedFileId)
    if (importedFile) {
      Vue.set(importedFile.objectToPost, 'witnesses', signersWithWitnesses)
    }
  },
  [REMOVE_SIGNER_WITNESSES] (state, { importedFileId, signerId }) {
    const importedFileIndex = state.importedFiles.findIndex(importedFile => importedFile.id === importedFileId)

    if (!state.importedFiles[importedFileIndex]) {
      return
    }

    const currentWitnesses = [...state.importedFiles[importedFileIndex].objectToPost.witnesses]
    state.importedFiles[importedFileIndex].objectToPost.witnesses = currentWitnesses.filter(
      currentSignerAndWitnesses => currentSignerAndWitnesses.signer !== signerId,
    )
    const currentRecipients = [...state.importedFiles[importedFileIndex].objectToPost.recipients]
    const indexRecipient = currentRecipients.findIndex(id => id === signerId)
    state.importedFiles[importedFileIndex].objectToPost.recipients = currentRecipients.splice(indexRecipient, 1)

    const currentObservers = [...state.importedFiles[importedFileIndex].objectToPost.observers]
    const indexObserver = currentObservers.findIndex(id => id === signerId)
    state.importedFiles[importedFileIndex].objectToPost.observers = currentObservers.splice(indexObserver, 1)
  },
  [ADD_OBSERVERS] (state, { importedFileId, observersIds }) {
    const importedFileIndex = state.importedFiles.findIndex(importedFile => importedFile.id === importedFileId)

    if (!state.importedFiles[importedFileIndex]) {
      return
    }

    const currentObservers = [...state.importedFiles[importedFileIndex].objectToPost.observers]
    const observersToAdd = observersIds.filter(observerId => !currentObservers.includes(observerId))
    state.importedFiles[importedFileIndex].objectToPost.observers.push(...observersToAdd)
  },
  [ADD_RECIPIENTS] (state, { importedFileId, recipientsIds }) {
    const importedFileIndex = state.importedFiles.findIndex(importedFile => importedFile.id === importedFileId)

    if (!state.importedFiles[importedFileIndex]) {
      return
    }

    const currentRecipients = [...state.importedFiles[importedFileIndex].objectToPost.recipients]
    const recipitentsToAdd = recipientsIds.filter(recipientId => !currentRecipients.includes(recipientId))
    state.importedFiles[importedFileIndex].objectToPost.recipients.push(...recipitentsToAdd)
  },
  [REMOVE_OBSERVERS] (state, { importedFileId, observersIds }) {
    const importedFileIndex = state.importedFiles.findIndex(importedFile => importedFile.id === importedFileId)

    if (!state.importedFiles[importedFileIndex]) {
      return
    }

    const currentObservers = [...state.importedFiles[importedFileIndex].objectToPost.observers]
    state.importedFiles[importedFileIndex].objectToPost.observers = currentObservers.filter(
      currentObserver => !observersIds.includes(currentObserver),
    )
  },
  [REMOVE_SIGNERS] (state, { importedFileId, signersIds }) {
    const importedFileIndex = state.importedFiles.findIndex(importedFile => importedFile.id === importedFileId)

    if (!state.importedFiles[importedFileIndex]) {
      return
    }

    const currentSignerGroups = [...state.importedFiles[importedFileIndex].objectToPost.signers]
    state.importedFiles[importedFileIndex].objectToPost.signers = currentSignerGroups.map(
      signerGroup => signerGroup.filter(signer => !signersIds.includes(signer)),
    )
  },
  [REMOVE_RECIPIENTS] (state, { importedFileId, recipientsIds }) {
    const importedFileIndex = state.importedFiles.findIndex(importedFile => importedFile.id === importedFileId)

    if (!state.importedFiles[importedFileIndex]) {
      return
    }

    const currentRecipients = [...state.importedFiles[importedFileIndex].objectToPost.recipients]
    state.importedFiles[importedFileIndex].objectToPost.recipients = currentRecipients.filter(
      currentRecipient => !recipientsIds.includes(currentRecipient),
    )
  },
  [SET_TOTAL_DOCUMENTS] (state, value) {
    state.totalDocuments = value
  },
}
