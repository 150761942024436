<template>
  <ul class="features">
    <ProjectTypeFeature v-for="(feature, index) in features"
                        :key="index"
                        :title="feature.title"
                        :icon="feature.icon"
                        :description="feature.description"
                        :icon-class="iconClass"
    />
  </ul>
</template>

<script>
import ProjectTypeFeature from './ProjectTypeFeature'

export default {
  name: 'ProjectTypeFeaturesList',
  components: {
    ProjectTypeFeature,
  },
  props: {
    features: {
      type: Array,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.features {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li:not(:last-of-type) {
    margin-bottom: 32px;
  }
}
</style>
