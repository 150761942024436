import axios from 'axios'

export default {
  getFile (mnemo, id) {
    return axios.get(`room/${mnemo}/file/${id}`)
  },
  getWatermarkedFile (mnemo, fileId, data, request) {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    request.cancelToken = source
    return axios.post(`room/${mnemo}/file/${fileId}/watermark`, data, { cancelToken: source.token })
  },
}
