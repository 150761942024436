<template>
  <v-container fluid>
    <div class="d-flex flex-column row-gap-6">
      <div class="d-flex justify-space-between align-center">
        <app-headline :level="2">{{ $t("archives.views.Archives.header") }}</app-headline>
        <AppButton :to="{ name: ARCHIVES_ADD_ROUTE_NAME }" color="primary">
          <i class="fal fa-plus mr-2"></i>{{ $t("archives.views.Archives.btnAdd") }}
        </AppButton>
      </div>
      <ArchivesSearchArea :additionalFields="metadata"
                          :loading="listSearchPending"
                          @submit="handleSubmit"
      />

      <ArchivesListBulkActions @download-selection="isArchivesDownloadDialogOpened = true"
                               @send-selection="isArchivesSendDialogOpened = true"
                               @share-selection="isArchivesShareDialogOpened = true"
      />

      <ArchivesList v-if="listSearchData"
                    :additionalFields="metadata"
                    :items="listSearchData"
                    :itemCount="listSearchDataCount"
                    :loading="listSearchPending"
                    :page="page"
                    @page-change="onPageChange"
                    @sort-change="onSortChange"
      />
      <div v-else class="text-center mb-8">
        <AppLoader></AppLoader>
      </div>
    </div>

    <ArchivesDownloadDialog v-if="isArchivesDownloadDialogOpened"
                            @close="isArchivesDownloadDialogOpened = false"
    />

    <ArchivesShareDialog v-if="isArchivesShareDialogOpened"
                         @close="isArchivesShareDialogOpened = false"
    />

    <ArchivesSendDialog v-if="isArchivesSendDialogOpened"
                        @close="isArchivesSendDialogOpened = null"
    />
  </v-container>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'

import ArchivesList from '@/archives/ArchivesList.vue'
import ArchivesListBulkActions from '@/archives/ArchivesListBulkActions.vue'
import ArchivesSearchArea from '@/archives/ArchivesSearchArea.vue'
import ArchivesDownloadDialog from '@/archives/dialogs/ArchivesDownloadDialog'
import ArchivesSendDialog from '@/archives/dialogs/ArchivesSendDialog.vue'
import ArchivesShareDialog from '@/archives/dialogs/ArchivesShareDialog.vue'
import AppLoader from '@/common/AppLoader'
import AppButton from '@/common/buttons/AppButton.vue'
import { ARCHIVES_ADD_ROUTE_NAME } from '@/router'
import { GET_SEARCH_ARCHIVES } from '@/store/modules/archives/action_types'

export default {
  name: 'Archives',
  components: {
    AppButton,
    AppLoader,
    ArchivesDownloadDialog,
    ArchivesList,
    ArchivesListBulkActions,
    ArchivesSearchArea,
    ArchivesSendDialog,
    ArchivesShareDialog,
  },
  data () {
    return {
      isArchivesDownloadDialogOpened: false,
      isArchivesSendDialogOpened: false,
      isArchivesShareDialogOpened: false,
      page: 1,
      search: {},
      sortBy: 'archiveDate',
      sortDir: 'DESC',
    }
  },
  metaInfo () {
    return {
      titleTemplate: this.$t('archives.views.Archives.header') + ' - Closd',
    }
  },
  computed: {
    ...mapState('archives', ['listSearchPending', 'listSearchData', 'listSearchDataCount']),
    ...mapGetters('archives', ['metadata']),
  },
  created () {
    this.ARCHIVES_ADD_ROUTE_NAME = ARCHIVES_ADD_ROUTE_NAME
    this.handleSearchRequest = debounce(this.handleSearchRequest, 200, { leading: true })
  },
  methods: {
    ...mapActions('archives', [GET_SEARCH_ARCHIVES]),
    handleSearchRequest (search = this.search, params = {}) {
      this.GET_SEARCH_ARCHIVES(
        {
          search: {
            docName: search.docName,
            cmRef: search.folderRef,
            signerName: search.signerName,
            signedDocuments: search.signedDocuments,
            archiveDateStart: search.archiveDate?.begin,
            archiveDateEnd: search.archiveDate?.end,
            signingDateStart: search.signDate?.begin,
            signingDateEnd: search.signDate?.end,
            archiverName: search.archiverName,
            ...search.additionalFields,
          },
          params,
        },
      )
    },
    handleSubmit (search) {
      this.page = 1
      this.sortBy = undefined
      this.search = search
      this.handleSearchRequest()
    },
    onPageChange (newPage) {
      this.page = newPage
      this.handleSearchRequest(this.search, {
        page: this.page,
        sortBy: this.sortBy,
        sortDir: this.sortDir,
      })
    },
    onSortChange (newSort) {
      if (this.sortBy !== newSort.sortBy || this.sortDir !== newSort.sortDir) {
        this.sortBy = newSort.sortBy
        this.sortDir = newSort.sortDir

        this.handleSearchRequest(this.search, {
          page: this.page,
          sortBy: this.sortBy,
          sortDir: this.sortDir,
        })
      }
    },
  },
}
</script>
