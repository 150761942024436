<template>
  <div class="identity-documents-types">
    <v-radio-group
      v-model="idDocumentType"
      hide-details
      active-class="id-type-card--active"
      class="id-type-container id-type-container-col"
      required
      row
    >
      <div v-for="option in idTypeOptionsValues"
            :key="`option-${option}`"
            :class="`idDocumentType__card-${option}`"
            class="d-flex flex-column"
      >
        <v-radio :value="option"
                  class="id-type-card mb-2"
        >
          <template v-slot:label>
            <h4>
              {{ $t(`common.identity-checking.IdentityCheckingID.identityCard.IDTypeOptions.${option}`) }}
            </h4>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import { ID_TYPE_OPTIONS } from '@/common/utils/IDTypes.ts'
  import { SET_SELECTED_ID_DOCUMENT_TYPE } from '@/store/modules/user/mutation_types'

  export default {
    name: 'IdentityDocumentsTypes',
    data () {
      return {
        ID_TYPE_OPTIONS,
        idDocumentType: null,
      }
    },
    computed: {
      ...mapState('user', ['selectedIdDocumentType']),
      idTypeOptionsValues () {
        return Object.values(ID_TYPE_OPTIONS)
      },
    },
    created () {
      this.idDocumentType = this.selectedIdDocumentType || ID_TYPE_OPTIONS.IDENTITY_CARD
    },
    watch: {
      idDocumentType (value) {
        this.$store.commit(`user/${SET_SELECTED_ID_DOCUMENT_TYPE}`, value)
      },
    },
  }
</script>

<style lang="scss">
  .identity-documents-types {
    .id-type-container {
      margin-top: $spacer * 4;
      margin-bottom: 0;

      .v-input--radio-group__input {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(1, 1fr);
      }

      .id-type-card {
        padding: $spacer * 4 $spacer * 6;
        border: 1px solid #DEE2E6;
        border-radius: 4px;
        margin: 0;

        &--active {
          outline: 2px solid var(--v-primary-base);
          outline-offset: -2px;
          background-color: var(--v-primary-lighten4);
        }

        .caption {
          color: #717B85;
          font-size: 12px;
        }
      }
    }

    .id-type-container-col {
      @media #{map-get($display-breakpoints, 'md-and-up')}{
        .v-input--radio-group__input {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
</style>
