<template>
  <v-container fluid
               class="pa-0"
               :class="{ 'px-1': $vuetify.breakpoint.mdAndDown, 'h-100': questionPending }"
  >
    <template v-if="questionPending">
      <div class="d-flex align-center justify-center h-100">
        <AppLoader/>
      </div>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12" :lg="showSharePanel ? 8 : 12">
          <QuestionAnswerItemThread :mnemo="mnemo"/>
        </v-col>
        <v-col v-if="showSharePanel" :class="$vuetify.breakpoint.lgAndUp && 'share-module-margin-fix'" cols="12" lg="4">
          <QuestionAnswerItemShared :mnemo="mnemo"/>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppLoader from '@/common/AppLoader'
import QuestionAnswerItemShared from '@/project/question-answer/QuestionAnswerItemShared'
import QuestionAnswerItemThread from '@/project/question-answer/QuestionAnswerItemThread'
import { QUESTION_ANSWER_LIST_ROUTE_NAME, ROOM_MEMBERS_ROUTE_NAME } from '@/router'
import { QuestionAnswerActionTypes } from '@/store/modules/question-answer/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import { QuestionAnswerMutationTypes } from '../../store/modules/question-answer/mutation_types'
export default {
  name: 'QuestionAnswerItem',
  components: { QuestionAnswerItemShared, QuestionAnswerItemThread, AppLoader },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    questionId: {
      type: [Number, String],
      required: true,
    },
    // If we come from the router, we already have this information
    questionIdInRoom: {
      type: [Number, String],
    },
  },
  metaInfo () {
    const idInRoom = this.questionIdInRoom || this.question?.idInRoom
    const title = idInRoom
      ? this.$t('project.views.QuestionAnswerItem.metaTitle', { questionId: idInRoom })
      : this.$t('project.views.QuestionAnswerItem.metaTitleQuestionPending')
    return {
      title,
    }
  },
  computed: {
    ...mapState('questionAnswer', ['question', 'questionPending']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['isCurrentUserPm']),
    showSharePanel () {
      return this.isCurrentUserPm || this.question.rights.canWrite
    },
  },
  watch: {
    questionId: {
      handler () {
        this.prepareGetQuestion()
      },
      immediate: true,
    },
  },
  created () {
    if (!this.currentRoom.isDataroom) {
      this.$router.replace({ name: ROOM_MEMBERS_ROUTE_NAME, params: { roomType: getRoomRouteType(this.currentRoom) } })
    }
    this.prepareGetQuestion()
  },
  beforeDestroy () {
    this.SET_QUESTION(null)
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.GET_QUESTION]),
    ...mapMutations('questionAnswer', [QuestionAnswerMutationTypes.SET_QUESTION]),
    async prepareGetQuestion () {
      try {
        await this.GET_QUESTION({
          mnemo: this.mnemo,
          questionId: this.questionId,
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.views.QuestionAnswerItem.getQuestionError'),
        })
        this.$router.push({ name: QUESTION_ANSWER_LIST_ROUTE_NAME })
      }
    },
  },
}
</script>

<style scoped>
/* Used to align the share module with the question block when they're on the same line
   This is because the navigation is in the same column but the share shouldn't be aligned to it
*/
.share-module-margin-fix {
  margin-top: 55px;
}
</style>
