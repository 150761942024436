<template>
  <v-switch :input-value="value"
            v-bind="$attrs"
            inset
            :ripple="false"
            :disabled="disabled || loading"
            :loading="loading"
            class="AppToggle"
            @change="$emit('input', !!$event)"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

/**
 * It's used to switch the state of a single option. The inline label of "Switch" should be displayed clearly, e.g. Disable/Enable, Disallow/Allow etc.
 * All vuetify props are available
 * @see https://v2.vuetifyjs.com/en/api/v-switch/#props
 * @displayName Toggle
 */

const TRUE_CLASS_LIST = ['fa-check', 'brand--text']
const FALSE_CLASS_LIST = ['fa-xmark', 'grey--text', 'text--lighten-1']
const LOADING_TRANSITION_DURATION = 300
type dataType = {
  toggleIconContainer: Element | null,
  toggleIconElement: Element | null,
}
export default defineComponent({
  name: 'AppToggle',
  inheritAttrs: false,
  props: {
    /**
     * The v-model bound value
     * */
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /**
     * Displays circular progress bar.
     * */
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /**
     * Disable the input
     * */
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data (): dataType {
    return {
      toggleIconContainer: null,
      toggleIconElement: null,
    }
  },
  computed: {},
  mounted () {
    this.toggleIconContainer = this.$el.querySelector('.v-input--switch__thumb')
    if (this.toggleIconContainer) {
      this.toggleIconElement = this.toggleIconContainer.querySelector('.AppToggle-innerIcon')
    }
    this.setToggleIcon()
  },
  watch: {
    value: {
      handler: function () {
        this.setToggleIcon()
      },
    },
    loading: function (value) {
      this.handleLoading(value)
    },
  },
  methods: {
    setToggleIcon () {
      if (this.toggleIconContainer && !this.loading) {
        if (this.toggleIconElement) {
          this.toggleIconElement.classList.add(...this.value ? TRUE_CLASS_LIST : FALSE_CLASS_LIST)
          this.toggleIconElement.classList.remove(...this.value ? FALSE_CLASS_LIST : TRUE_CLASS_LIST)
        } else {
          const iconHTMLElement = document.createElement('i')
          iconHTMLElement.classList.add('AppToggle-innerIcon', 'far', ...this.value ? TRUE_CLASS_LIST : FALSE_CLASS_LIST)
          setTimeout(() => {
            if (this.toggleIconContainer) {
              this.toggleIconContainer.appendChild(iconHTMLElement)
              this.toggleIconElement = iconHTMLElement
            }
          }, LOADING_TRANSITION_DURATION)
        }
      }
    },
    handleLoading (value) {
      if (value) {
        if (this.toggleIconElement) {
          this.toggleIconElement.remove()
          // We remove the icon element to make room for the loading icon. It will be restored at the end of the loading state.
          this.toggleIconElement = null
        }
      }
    },
  },
})

</script>

<style scoped lang="scss">
.AppToggle {
  &.v-input ::v-deep .v-input__slot {
      margin-left: 4px;
  }

  &.v-input ::v-deep .v-input__slot:hover {
    .v-input--switch__track {
      color: var(--v-grey-lighten2);
      opacity: .6;
    }
    .AppToggle-innerIcon {
      color: var(--v-grey-lighten2);
      opacity: .6;
    }
    .v-progress-circular {
      color: var(--v-grey-lighten2);
      opacity: .6;
    }
  }

  // Reuse the hover style, but only when the input inside is focused by tabbing, not by clicks
  // Progress shouldn't be able to be focused onto so we don't reuse its style change
  &::v-deep input:focus-visible {
    +.v-input--switch__track, ~.v-input--switch__thumb .AppToggle-innerIcon {
      color: var(--v-grey-lighten2);
      opacity: .6;
    }
  }

  &.v-input--is-label-active ::v-deep .v-input__slot:hover {
    .v-input--switch__track {
      color: var(--v-brand-lighten2);
    }
    .AppToggle-innerIcon {
      color: var(--v-brand-lighten2);
    }
    .v-progress-circular {
      color: var(--v-brand-lighten2) !important;
    }
  }

  & ::v-deep .AppToggle-innerIcon {
    font-size: 12px !important;
  }
  // Falsy state
  &.v-input {
    ::v-deep .v-input--switch__track {
      color: var(--v-grey-lighten1);
      opacity: .6;
    }
    // Loading
    &.v-input--is-loading ::v-deep {
      .v-input--switch__track {
        color: var(--v-grey-lighten1);
        opacity: .6;
      }
      .v-progress-circular {
        color: var(--v-grey-lighten1) !important;
      }
    }
    // Disabled
    &.v-input--is-disabled ::v-deep {
      label {
        color: var(--v-grey-lighten1) !important;
      }
      .v-input--switch__track {
        color: var(--v-grey-lighten3);
        opacity: .6;
      }
      .AppToggle-innerIcon {
        color: var(--v-grey-lighten3);
        opacity: .6;
      }
    }
  }
  // Truthy state
  &.v-input--is-label-active {
    ::v-deep .v-input--switch__track {
      opacity: 1;
    }
    ::v-deep .v-input--switch__thumb {
      color: white !important;
    }
    // Loading
    &.v-input--is-loading ::v-deep {
      .v-input--switch__track {
        color: var(--v-brand-base);
        opacity: 1;
      }
      .v-progress-circular {
        color: var(--v-brand-base) !important;
      }
    }
    // Disabled
    &.v-input--is-disabled ::v-deep {
      label {
        color: var(--v-grey-lighten1) !important;
      }
      .v-input--switch__track {
        color: var(--v-brand-lighten3);
        opacity: 1;
      }
      .AppToggle-innerIcon {
        color: var(--v-brand-lighten3);
      }
    }
  }
}
</style>

<docs>
```vue
<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-checkbox v-model="isLoading"
                    label="Loading"
        />
        <v-checkbox v-model="isDisabled"
                    label="Disabled"
        />
        <AppToggle v-model="toggle"
                   label="My toggle"
                   :loading="isLoading"
                   :disabled="isDisabled"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    data () {
      return {
        isLoading: false,
        isDisabled: false,
        toggle: false,
      }
    },
  }
</script>
```
</docs>
