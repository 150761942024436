<template>
  <div class="signing-checklist-selected-documents-list-item py-2">
    <ClosdFildersIcon
      :document="file"
      :signed-file="file.signedOnClosd"
    />

    <div class="signing-checklist-selected-documents-list-item__file-data ml-2 mr-4">
      <template v-if="hasToBeUploaded && canEdit">
        <div class="d-flex justify-space-between mb-1">
          <app-text variant="small-regular" class="grey--text text--darken-4 mb-0">
            {{ file.objectToPost.fileName }}
          </app-text>
          <app-text v-if="file.size"
                    variant="x-small-regular"
                    class="grey--text text--lighten-2 mb-0"
          >
            <app-filesize-display :value="file.size" />
          </app-text>
        </div>
        <v-progress-linear :value="file.progress"
                           height="6"
                           color="primary"
                           background-color="grey lighten-4"
                           rounded
        />
      </template>
      <template v-else>
        <app-text variant="small-regular" class="grey--text text--darken-4 mb-0">
          {{ file.objectToPost.fileName }}
        </app-text>
        <app-text v-if="file.size"
                  variant="x-small-regular"
                  class="grey--text text--lighten-2 mb-0"
        >
          <app-filesize-display :value="file.size" />
        </app-text>
      </template>
    </div>

    <div class="signing-checklist-selected-documents-list-item__actions">
      <AppTooltip v-if="canDelete" top>
        <template v-slot:activator="{ attrs, on }">
          <font-awesome-icon v-bind="attrs"
                             :icon="['fas', 'times-circle']"
                             class="grey--text clickable"
                             v-on="on"
                             @click="$emit('deleteFile', file)"
          />
        </template>
        {{$t('common.delete')}}
      </AppTooltip>

      <template v-if="hasToBeUploaded">
        <font-awesome-icon v-if="file.status === 'success'"
                           class="signing-checklist-selected-documents-list-item__actions-success"
                           :icon="['fad', 'check-circle']"
        />
        <font-awesome-icon v-if="file.status === 'error'"
                           class="signing-checklist-selected-documents-list-item__actions-error"
                           :icon="['fad', 'exclamation-circle']"
        />
      </template>
    </div>
  </div>
</template>

<script>
import AppTooltip from '@/common/AppTooltip.vue'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon'

export default {
  name: 'SigningChecklistSelectedDocumentsListItem',
  components: {
    AppTooltip,
    ClosdFildersIcon,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasToBeUploaded () {
      return !!this.file.objectToPost.fileRaw
    },
  },
}
</script>

<style scoped lang="scss">
.signing-checklist-selected-documents-list-item {
  display: flex;
  align-items: center;

  &__file-data {
    flex: 1;
  }

  &__actions {
    display: flex;
    column-gap: 12px;

    svg {
      width: 18px;
      height: 18px;
    }

    &-success,
    &-error {
      --fa-primary-opacity: 1;
      --fa-secondary-opacity: 1;
    }
    &-success {
      --fa-primary-color: #f0f0f0;
      --fa-secondary-color: #4e985f;
    }
    &-error {
      --fa-primary-color: #b99c6b;
      --fa-secondary-color: rgb(245, 230, 178);
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--v-grey-lighten3);
  }
}
</style>
