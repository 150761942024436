import axios from 'axios'

export default {
  getEnvelopeTemplateById (envelopeTemplateId: number) {
    return axios.get(`/envelope/template/${envelopeTemplateId}`)
  },
  deleteAllTags (envelopeTemplateId: number, data: object) {
    return axios.post(`/envelope/template/${envelopeTemplateId}/tags/delete`, data)
  },
  deleteEnvelopeTemplate (envelopeTemplateIds) {
    return axios.delete('/envelope/templates', { data: { ids: envelopeTemplateIds } })
  },
  deleteSigner (envelopeTemplateId: number, signatureId: number) {
    return axios.delete(`/envelope/template/${envelopeTemplateId}/signature/${signatureId}`)
  },
  deleteTag (envelopeTemplateId: number, tagId: number) {
    return axios.delete(`/envelope/template/${envelopeTemplateId}/tags/${tagId}`)
  },
  duplicateEnvelopeTemplate (envelopeTemplateId: number, data) {
    return axios.post(`/envelope/template/${envelopeTemplateId}/duplicate`, data)
  },
  duplicateTag (envelopeTemplateId: number, tagId: number, data: object): Promise<{ data: object }> {
    return axios.post(`/envelope/template/${envelopeTemplateId}/tags/${tagId}/autofill`, data)
  },
  getEnvelopeTemplate (envelopeTemplateId: string) {
    return axios.get(`/envelope/template/${envelopeTemplateId}`)
  },
  getEnvelopeTemplateDisplayInformations (envelopeTemplateId: number): Promise<{ data: object }> {
    return axios.get(`/envelope/template/${envelopeTemplateId}/displayInfo`)
  },
  getEnvelopeTemplatePages (envelopeTemplateId: string, pageQuery) {
    return axios.get(`/envelope/template/${envelopeTemplateId}/pages?pages=${pageQuery}`)
  },
  getEnvelopeTemplateTaggingInformations (envelopeTemplateId: number): Promise<{ data: object }> {
    return axios.get(`/envelope/template/${envelopeTemplateId}/taggingInfo`)
  },
  getPageThumb (envelopeTemplateId: number, pageId: number): Promise<{ data: object }> {
    return axios.get(`/envelope/template/${envelopeTemplateId}/thumb/${pageId}`)
  },
  getUserEnvelopeTemplates () {
    return axios.get('/envelope/templates')
  },
  patchTag (envelopeTemplateId: number, tagId: number, data: object) {
    return axios.patch(`/envelope/template/${envelopeTemplateId}/tags/${tagId}`, data)
  },
  postEnvelopeTemplate (data: object) {
    return axios.post('/envelope/template', data)
  },
  patchEnvelopeTemplate (envelopeTemplateId: number, data: object) {
    return axios.patch(`/envelope/template/${envelopeTemplateId}`, data)
  },
  postTag (envelopeTemplateId: number, data: object) {
    return axios.patch(`/envelope/template/${envelopeTemplateId}/tags/0`, data)
  },
  renameEnvelopeTemplate (envelopeTemplateId, data) {
    return axios.patch(`/envelope/template/${envelopeTemplateId}/title`, data)
  },
  renameEnvelopeTemplateSigner (envelopeTemplateId: number, signatureId: number, data: object) {
    return axios.patch(`/envelope/template/${envelopeTemplateId}/signature/${signatureId}/name`, data)
  },
  saveEnvelopeAsTemplate (mnemo: string, envelopeId: number, data: object) {
    return axios.post(`/room/${mnemo}/envelope/${envelopeId}/saveAsTemplate`, data)
  },
}
