<template>
  <div class="h-100 d-flex justify-center align-center">
    <AppCard class="w-50">
      <v-row>
        <v-col class="text-h1 text-center">
          {{ $t('payment.PaymentCancel.title') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <font-awesome-icon :icon="['fal', 'circle-xmark']"
                             class="error--text"
                             style="font-size: 100px;"
          ></font-awesome-icon>
        </v-col>
      </v-row>
    </AppCard>
  </div>
</template>

<script>
import AppCard from '@/common/AppCard'

export default {
  name: 'PaymentCancel',
  components: { AppCard },
}
</script>
