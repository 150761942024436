import { flatten } from '@/common/utils/flatArray'

export const getters = {
  flatSelectedDocuments: function (state) {
    return flatten([], state.selectedDocuments)
  },
  selectedDocumentsSize: function (state, getters) {
    return getters.flatSelectedDocuments.reduce(
      function (accSum, item) {
        return item.type === 'file' ? accSum + item.size : accSum
      },
      0)
  },
  selectedDocumentsFileIds: function (state) {
    return state.selectedDocuments.reduce(
      (fileIds, item) => item.type === 'file' ? [...fileIds, item.id] : fileIds,
      [],
    )
  },
  selectedDocumentsFolderIds: function (state) {
    return state.selectedDocuments.reduce(
      (folderIds, item) => item.type === 'folder' ? [...folderIds, item.id] : folderIds,
      [],
    )
  },
  hasAtLeastOneEmptyFolder: function (state) {
    return state.selectedDocuments.some((item) => {
      return item.type === 'folder' && item.childrenCount === 0
    })
  },
}
