import { DocumentsAISummaryMutationType } from '@/store/modules/documents-ai-summary/mutation_type'
import { StoreMutations } from '@/types/store'

export const mutations: StoreMutations<DocumentsAISummaryMutationType> = {
  SET_DOCUMENT_TO_SUMMARIZE_WITH_AI (state, document) {
    state.documentToSummarize = document
  },
  SET_REQUEST_SUMMARY_PENDING (state, pending) {
    state.requestSummaryPending = pending
  },
  SET_SUMMARIES_CREDITS (state, summariesCredits) {
    state.summariesCredits = summariesCredits
  },
  SET_SUMMARIES_CREDITS_PENDING (state, pending) {
    state.summariesCreditsPending = pending
  },
}
