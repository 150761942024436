import { defineComponent } from 'vue'

const MODAL_FADE_TRANSITION_TIME = 300

export const dialogMixin = defineComponent({
  name: 'dialogMixin',
  data () {
    return {
      isDialogOpened: true,
    }
  },
  watch: {
    isDialogOpened (currentlyOpened): void {
      if (!currentlyOpened) {
        setTimeout(() => {
          this.$emit('close')
        }, MODAL_FADE_TRANSITION_TIME)
      }
    },
  },
  methods: {
    closeDialog (): void {
      this.isDialogOpened = false
    },
  },
})
