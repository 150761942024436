import {
  DISABLE_MEMBERS,
  HIGHLIGHT_MEMBER,
  INIT_SELECTED_GROUPS,
  INIT_SELECTED_MEMBERS,
  RESET_DISABLED_MEMBERS,
  RESET_SELECTED_GROUPS_AND_MEMBERS,
  SELECT_GROUP,
  SELECT_MEMBER,
  UNSELECT_GROUP,
  UNSELECT_MEMBER,
} from './action_types'
import {
  ADD_GROUP_TO_SELECTED,
  ADD_MEMBERS_TO_SELECTED,
  REMOVE_GROUP_FROM_SELECTED,
  REMOVE_MEMBERS_FROM_SELECTED,
  ADD_MEMBERS_TO_DISABLED,
  SET_SELECTED_GROUPS,
  SET_SELECTED_MEMBERS,
  SET_DISABLED_MEMBERS,
  SET_HIGHLIGHTED_MEMBER,
} from './mutation_types'

export const actions = {
  [DISABLE_MEMBERS]: function ({ commit }, members) {
    commit(ADD_MEMBERS_TO_DISABLED, members)
  },
  [RESET_DISABLED_MEMBERS]: function ({ commit }) {
    commit(SET_DISABLED_MEMBERS, [])
  },
  [SELECT_GROUP]: function ({ commit }, { group, userOnly }) {
    if (!userOnly) {
      commit(ADD_GROUP_TO_SELECTED, group)
      commit(REMOVE_MEMBERS_FROM_SELECTED, group.members)
    } else {
      commit(ADD_MEMBERS_TO_SELECTED, group.members)
    }
  },
  [UNSELECT_GROUP]: function ({ commit }, { group, userOnly }) {
    if (!userOnly) {
      commit(REMOVE_GROUP_FROM_SELECTED, group)
    }

    commit(REMOVE_MEMBERS_FROM_SELECTED, group.members)
  },
  [INIT_SELECTED_GROUPS]: function ({ commit }, groups) {
    commit(SET_SELECTED_GROUPS, groups)
  },
  [SELECT_MEMBER]: function ({ commit }, { member, multiselect }) {
    if (multiselect) {
      commit(ADD_MEMBERS_TO_SELECTED, [member])
    } else {
      commit(SET_SELECTED_MEMBERS, [member])
    }
  },
  [UNSELECT_MEMBER]: function ({ commit }, member) {
    commit(REMOVE_MEMBERS_FROM_SELECTED, [member])
  },
  [INIT_SELECTED_MEMBERS]: function ({ commit }, members) {
    commit(SET_SELECTED_MEMBERS, members)
  },
  [RESET_SELECTED_GROUPS_AND_MEMBERS]: function ({ commit }) {
    commit(SET_SELECTED_GROUPS, [])
    commit(SET_SELECTED_MEMBERS, [])
    commit(SET_HIGHLIGHTED_MEMBER, null)
  },
  [HIGHLIGHT_MEMBER]: function ({ commit }, member) {
    // When selected from the search field, member should be highlighted for 3s
    const HIGHLIGHT_DURATION = 3000

    commit(SET_HIGHLIGHTED_MEMBER, member)

    setTimeout(() => {
      commit(SET_HIGHLIGHTED_MEMBER, null)
    }, HIGHLIGHT_DURATION)
  },
}
