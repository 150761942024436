<template>
  <div class="fill-height">
    <template v-if="isArchivingEnabled">
      <transition name="app-fade">
        <router-view v-if="!configPending"></router-view>
        <div v-else class="d-flex align-center justify-center h-100">
          <v-progress-circular
            style="width: 64px; height: 64px"
            indeterminate
            color="primary"
            size="64"
          />
        </div>
      </transition>
    </template>
    <template v-else>
      <ArchivesPaywallDialog />
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import { GET_ARCHIVE_CONFIG } from '../../store/modules/archives/action_types'
import ArchivesPaywallDialog from '../views/ArchivesPaywallDialog'

const { mapActions, mapState } = createNamespacedHelpers('archives')
const { mapGetters: mapUserGetters } = createNamespacedHelpers('user')
export default {
  name: 'ArchivesLayout',
  components: {
    ArchivesPaywallDialog,
  },
  computed: {
    ...mapState(['configPending']),
    ...mapUserGetters(['isArchivingEnabled']),
  },
  created () {
    if (this.isArchivingEnabled) {
      // This is always used, so we fetch it here
      this.GET_ARCHIVE_CONFIG()
    }
  },
  methods: {
    ...mapActions([GET_ARCHIVE_CONFIG]),
  },
}
</script>
