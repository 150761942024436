<template>
  <div class="fill-height d-flex flex-column">
    <div v-show="creationLoading">
      <div class="d-flex align-center justify-center">
        <v-progress-circular
          style="width: 64px; height: 64px"
          color="primary"
          indeterminate
          size="64"
        />
      </div>
    </div>

    <div v-show="!creationLoading"
         class="flex-grow-1 d-flex flex-column"
    >
      <v-stepper
        v-model="stepActive"
        alt-labels
        :class="`mx-auto w-100 flex-grow-1 overflow-y-auto originalGrey lighten-5 ${currentStepActiveClass}`"
        :non-linear="hasPassedStep3"
      >
        <v-stepper-header v-if="!hasOnlyReadOnlyFiles"
                          class="mx-auto"
                          style="max-width: 600px"
        >
          <template v-for="i in isStep3Disabled ? 2 : 3">
            <v-divider v-if="i !== 1" :key="i" class="mt-6"></v-divider>
            <v-stepper-step :key="'step' + i"
                            :step="i"
                            :editable="hasPassedStep3"
                            :complete="hasPassedStep3 && i < stepActive"
                            edit-icon="$complete"
                            color="tertiary"
                            class="pa-3"
            >
              {{ $t(`project.views.SigningChecklistAdd.step${i}Title`) }}
            </v-stepper-step>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1"
                             class="white"
          >
            <SigningChecklistAddStep1
              v-if="stepActive === 1 && !creationLoading"
              :mnemo="mnemo"
              :prevId="prevId"
              :prevType="prevType"
              :cannotAdd="cannotAdd"
              @updateUploadedFiles="updateUploadedFiles"
            />
          </v-stepper-content>
          <v-stepper-content step="2">
            <SigningChecklistAddStep2
              v-if="stepActive === 2"
              :mnemo="mnemo"
              :validator="$v"
            />
          </v-stepper-content>
          <v-stepper-content v-if="!isStep3Disabled"
                             step="3"
                             class="py-0 px-3"
          >
            <SigningChecklistAddStep3 v-if="stepActive === 3"
                                      :envelopeToPrepareIndex="envelopeToPrepareIndex"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-row v-if="!hasOnlyReadOnlyFiles"
             class="stepper__actionsBar SigningChecklistAdd-actionsBar"
             no-gutters
            :class="{
              'justify-space-between': stepActive > 1,
              'justify-end': stepActive === 1,
             }"
      >
        <v-col class="d-flex column-gap-2">
          <AppButton
            v-if="stepActive === 2"
            color="white"
            @click="stepActive--"
          >
            <i
              v-if="$vuetify.breakpoint.mdAndUp"
              class="fal mr-2 fa-arrow-left-long"
            ></i>
            {{ $t(`common.previous`) }}
          </AppButton>
          <AppButton
            v-if="envelopeToPrepareIndex === 0 && stepActive === 3"
            color="white"
            @click="stepActive--"
          >
            <i
              v-if="$vuetify.breakpoint.mdAndUp"
              class="fal mr-2 fa-arrow-left-long"
            ></i>
            {{ $t(`common.previous`) }}
          </AppButton>
          <AppButton v-if="envelopeToPrepareIndex > 0 && stepActive === 3"
                     color="white"
                     @click="envelopeToPrepareIndex--"
          >
            <font-awesome-icon :icon="['far', 'arrow-left-long']"
                               class="mr-2"
            ></font-awesome-icon>
            {{ $t('project.views.SigningChecklistAdd.previousDocument') }}
          </AppButton>
          <AppButton v-if="stepActive < 3"
            color="white"
            @click="onCancel"
          >
            {{ $t(`common.cancel`) }}
          </AppButton>
        </v-col>
        <v-col class="text-center">
          <div v-if="stepActive > 1" class="caption">
            <p v-if="lastSave">{{ $t('project.views.SigningChecklistAdd.autoSave', { hour: dayjs(lastSave).format('HH:mm:ss') }) }}</p>
            <p>{{ $t('project.views.SigningChecklistAdd.leaveInfo') }}</p>
          </div>
        </v-col>
        <v-col class="d-flex justify-end column-gap-2">
          <AppButton
            v-if="stepActive === 2"
            color="white"
            :loading="actionLoading"
            @click="onSaveAndExit"
          >
            {{ $t(`common.saveAndExit`) }}
          </AppButton>
          <AppButton
            v-if="stepActive !== 3 && !(stepActive === 2 && isStep3Disabled)"
            color="primary"
            :disabled="!anyImportedFiles || (filesToUpload && !allFilesToUploadSuccess)"
            :loading="actionLoading"
            @click="checkStepValidation"
          >
            {{ $t(`common.next`) }}
            <i
              v-if="stepActive < 3 && $vuetify.breakpoint.mdAndUp"
              class="ml-2 fal fa-arrow-right-long"
            ></i>
          </AppButton>
          <AppButton
            v-if="stepActive === 2 && isStep3Disabled"
            color="primary"
            :disabled="!anyImportedFiles || (filesToUpload && !allFilesToUploadSuccess)"
            :loading="actionLoading"
            @click="checkStepValidation"
          >
            {{ $t(`common.validate`) }}
          </AppButton>
          <template v-if="stepActive === 3">
            <AppButton
              color="white"
              @click="onSaveAndExit"
            >
              {{ $t(`common.saveAndExit`) }}
            </AppButton>
            <AppButton v-if="envelopeToPrepareIndex === draftedDocuments.length - 1"
                       color="primary"
                       @click="onFinish"
            >
              {{ $t('common.finish')}}
            </AppButton>
            <AppButton v-if="envelopeToPrepareIndex < draftedDocuments.length - 1"
                       color="white"
                       @click="envelopeToPrepareIndex++"
            >
              {{ $t('project.views.SigningChecklistAdd.nextDocument') }}
              <font-awesome-icon :icon="['far', 'arrow-right-long']"
                                 class="ml-2"
              ></font-awesome-icon>
            </AppButton>
          </template>
        </v-col>
      </v-row>
      <v-row v-else
             class="stepper__actionsBar SigningChecklistAdd-actionsBar justify-end"
             no-gutters
      >
        <AppButton
          color="white"
          class="mr-2"
          @click="onSaveAndExit"
        >
          {{ $t(`common.saveAndExit`) }}
        </AppButton>
        <AppButton color="primary"
                   @click="onFinish"
        >
          {{ $t('project.views.SigningChecklistAdd.backBtnLabel')}}
        </AppButton>
      </v-row>
    </div>
    <SigningChecklistAddStep2RecipientWarningDialog
      v-if="showStep2RecipientWarningDialog"
      @confirm="confirmWarningStep2Dialog"
      @close="showStep2RecipientWarningDialog = false"
    />
    <SigningChecklistAddStep2SignatoryWarningDialog
      v-if="showStep2SignatoryWarningDialog"
      :files="importedFiles"
      @confirm="confirmWarningStep2Dialog"
      @close="showStep2SignatoryWarningDialog = false"
    />
    <SigningChecklistAddStep2MissingRecipientWarningDialog
      v-if="showStep2MissingRecipientWarningDialog"
      :imported-files="importedFiles"
      @close="showStep2MissingRecipientWarningDialog = false"
    />
    <SigningChecklistAddBeforeLeaveDialog
      v-if="showBeforeLeaveDialog"
      @confirm="upsertAllImportedFilesToSteps"
      @close="$router.push(leaveLocation)"
    />
    <SigningChecklistPrepareCancelDialog
      v-if="showCancelDialog"
      @confirm="returnToSigningChecklist"
      @close="showCancelDialog = false"
    />
    <SigningChecklistTagsOverlapsDialog
      v-if="showTagsOverlapsDialog"
      :documents="importedFilesInDraft"
      :tags-overlaps="tagsOverlaps"
      @confirm="returnToSigningChecklist"
      @close="showTagsOverlapsDialog = false"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { createNamespacedHelpers, mapActions, mapGetters, mapState } from 'vuex'

import { canShowReadOnlyStep } from '@/common/utils/signingChecklist'
import SigningChecklistAddStep2MissingRecipientWarningDialog from '@/project/signing-checklist/add/step2/SigningChecklistAddStep2MissingRecipientWarningDialog.vue'
import { ENVELOPE_STATUS, ENVELOPE_TYPES, SIGNATURE_TYPE_HANDWRITTEN, SIGNATURE_TYPES } from '@/project/signing-checklist/constants'
import SigningChecklistAddBeforeLeaveDialog from '@/project/signing-checklist/dialogs/SigningChecklistAddBeforeLeaveDialog'
import SigningChecklistPrepareCancelDialog from '@/project/signing-checklist/dialogs/SigningChecklistPrepareCancelDialog'
import SigningChecklistTagsOverlapsDialog from '@/project/signing-checklist/dialogs/SigningChecklistTagsOverlapsDialog'
import { LOGOUT_ROUTE_NAME, ROOM_DOCUMENTS_ROUTE_NAME, SIGNING_CHECKLIST_ROUTE_NAME, ROOM_ROUTE_TYPES } from '@/router'

import AppButton from '../../common/buttons/AppButton.vue'
import {
  formatImportedFile,
  formatStep,
  formatStepFromEnvelopeTemplate,
} from '../../common/utils/signingChecklist'
import { UPLOAD_HIDDEN_FILE } from '../../store/modules/documents/action_types'
import { GET_FILE } from '../../store/modules/files/action_types'
import { GET_ROOM_PROVIDERS } from '../../store/modules/room/action_types'
import { BULK_UPSERT_SIGNING_CHECKLIST_STEP, GET_STEP } from '../../store/modules/signing-checklist/action_types'
import { SET_IMPORTED_FILES } from '../../store/modules/signing-checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'
import SigningChecklistAddStep1 from '../signing-checklist/add/step1/SigningChecklistAddStep1.vue'
import SigningChecklistAddStep2 from '../signing-checklist/add/step2/SigningChecklistAddStep2.vue'
import SigningChecklistAddStep2RecipientWarningDialog from '../signing-checklist/add/step2/SigningChecklistAddStep2RecipientWarningDialog.vue'
import SigningChecklistAddStep2SignatoryWarningDialog from '../signing-checklist/add/step2/SigningChecklistAddStep2SignatoryWarningDialog.vue'
import SigningChecklistAddStep3 from '../signing-checklist/add/step3/SigningChecklistAddStep3.vue'

const { mapActions: mapDocumentsActions } = createNamespacedHelpers('documents')
const {
  mapActions: mapSigningChecklistActions,
  mapMutations: mapSigningChecklistMutations,
  mapState: mapSigningChecklistState,
  mapGetters: mapSigningChecklistGetters,
} = createNamespacedHelpers('signingChecklist')
const { mapState: mapUserState } = createNamespacedHelpers('user')
const { mapActions: mapFilesActions } = createNamespacedHelpers('files')
const { mapState: mapRoomState, mapActions: mapRoomActions } = createNamespacedHelpers('room')
const { mapActions: mapGroupsActions } = createNamespacedHelpers('groups')

export default {
  name: 'SigningChecklistAdd',
  components: {
    SigningChecklistAddStep1,
    SigningChecklistAddStep2,
    SigningChecklistAddStep3,
    AppButton,
    SigningChecklistAddStep2MissingRecipientWarningDialog,
    SigningChecklistAddStep2RecipientWarningDialog,
    SigningChecklistAddStep2SignatoryWarningDialog,
    SigningChecklistAddBeforeLeaveDialog,
    SigningChecklistPrepareCancelDialog,
    SigningChecklistTagsOverlapsDialog,
  },
  mixins: [validationMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      default: () => [],
    },
    steps: {
      type: Array,
      default: () => [],
    },
    prevId: {
      type: Number,
      default: -1,
    },
    prevType: {
      type: String,
      default: '',
    },
  },
  validations () {
    const objectToPostValidations = {
      title: {
        required,
      },
      signatureType: {
        required,
      },
      envelopeType: {
        required,
      },
    }
    const importedFileValidations = {
      objectToPost: objectToPostValidations,
    }

    if (this.addFromTemplate) {
      importedFileValidations.templateSignatures = {
        $each: {
          assignedSigner: {
            required: requiredIf(templateSignature => templateSignature.signerType === SIGNATURE_TYPES.SINGLE),
          },
        },
      }
    }

    return {
      importedFiles: {
        $each: importedFileValidations,
      },
    }
  },
  data () {
    return {
      actionLoading: false, // Loading that shows after an action by the user
      cannotAdd: false,
      creationLoading: true, // Blocking loading only done on created lifecycle
      envelopeToPrepareIndex: 0,
      hasPassedStep3: false,
      leaveLocation: null,
      showBeforeLeaveDialog: false,
      showCancelDialog: false,
      showStep2MissingRecipientWarningDialog: false,
      showStep2RecipientWarningDialog: false,
      showStep2SignatoryWarningDialog: false,
      showTagsOverlapsDialog: false,
      stepActive: 1,
    }
  },
  provide () {
    return {
      addFromTemplate: this.addFromTemplate,
    }
  },
  computed: {
    ...mapUserState(['profile']),
    ...mapSigningChecklistState(['importedFiles']),
    ...mapSigningChecklistGetters(['importedFilesInDraft']),
    ...mapRoomState(['currentRoom', 'roomProvidersPending']),
    ...mapState('prepareSignature', ['lastSave', 'tagsOverlaps']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['isCurrentUserPm']),
    addFromTemplate () {
      return !!this.$route.query.envelopeTemplatesIds
    },
    dayjs () {
      return dayjs
    },
    anyImportedFiles () {
      return this.importedFiles.length > 0
    },
    queuedFilesInProgress () {
      return this.$store.state.documents.uploadHiddenFilePendingIds.length
    },
    filesToUpload () {
      return this.importedFiles.filter(f => f.objectToPost.hasOwnProperty('fileRaw') && !f.objectToPost.fileId)
    },
    allFilesToUploadSuccess () {
      return this.filesToUpload.every((f) => f.status === 'success')
    },
    draftedDocuments () {
      return this.importedFilesInDraft
    },
    atLeastOneRecipientForEachFile () {
      return this.importedFiles.every((file) => file.objectToPost.recipients.length > 0)
    },
    isStep3Disabled () {
      return this.steps.length && this.steps.every(step => { return step.envelope.status === ENVELOPE_STATUS.SIGNED })
    },
    hasEverySingleEnvelopeSignatoriesInEveryFiles () {
      const singleFiles = this.importedFiles.filter(file => file.objectToPost.envelopeType === ENVELOPE_TYPES.SINGLE)
      return singleFiles.every(file => {
        return !file.objectToPost.signers.every(signersGroup => signersGroup.length === 0)
      })
    },
    hasEveryMultipleEnvelopeTwoUniqueSigners () {
      const multipleFiles = this.importedFiles.filter(file => file.objectToPost.envelopeType === ENVELOPE_TYPES.MULTIPLE)
      return multipleFiles.every(file => {
        return file.objectToPost.uniqueSigners.length >= 2
      })
    },
    currentStepActiveClass () {
      const baseActiveClass = 'stepper-step-'
      let stepActiveClassFragment = ''
      if (this.stepActive === 1) {
        stepActiveClassFragment = 'files'
      } else if (this.stepActive === 2) {
        stepActiveClassFragment = 'settings'
      } else if (this.stepActive === 3) {
        stepActiveClassFragment = 'tags'
      }
      return baseActiveClass + stepActiveClassFragment
    },
    hasDocumentsWithTagsOverlaps () {
       return this.importedFilesInDraft.some(file => this.tagsOverlaps.find(item => item.envelopeId === file.objectToPost.envelope.id && item.tagIds))
    },
    hasOnlyReadOnlyFiles () {
      return this.importedFiles.length && this.importedFiles.every((file) => canShowReadOnlyStep(file.objectToPost))
    },
  },
  async created () {
    if (this.currentRoom.isDataroom) {
      this.$router.replace({ name: ROOM_DOCUMENTS_ROUTE_NAME, params: { roomType: ROOM_ROUTE_TYPES.DATAROOM } })
    }
    if (this.isCurrentUserPm && !this.roomProvidersPending && !this.currentRoom.providers) {
      this.GET_ROOM_PROVIDERS(this.mnemo)
    }
    if (!this.$store.state.groups.actionLoading) {
      // We await because it's used afterwards for formatting the files
      await this.GET_GROUPS(this.mnemo)
    }

    try {
      if (this.$route.query.envelopeTemplatesIds) {
        await this.prepareEnvelopeTemplatesAsFormattedSteps()
      } else {
        const { query } = this.$route
        const stepPromises = []
        const filePromises = []
        let newFiles = this.files
        let newSteps = this.steps

        // If we navigated here through the application's runtime,
        // the steps are passed with their information already through the router
        // Otherwise, we only have the step ids in the URL left and have to fetch
        // their information.
        if (this.steps.length === 0 && query.stepIds) {
          const stepIdsArray = query.stepIds.split(',')
          for (const stepId of stepIdsArray) {
            stepPromises.push(this.GET_STEP({
              mnemo: this.mnemo,
              id: stepId,
            }))
          }
          const promisedSteps = await Promise.allSettled(stepPromises)
          newFiles = []
          promisedSteps.forEach((promisedStep) => {
            if (promisedStep.status === 'fulfilled') {
              newSteps.push(promisedStep.value)
            }
          })
        } else if (this.files.length === 0 && query.fileIds) {
          const fileIdsArray = query.fileIds.split(',')
          for (const fileId of fileIdsArray) {
            filePromises.push(this.GET_FILE({
              mnemo: this.mnemo,
              id: fileId,
            }))
          }
          const promisedFiles = await Promise.allSettled(filePromises)
          newFiles = []
          promisedFiles.forEach((promisedFile) => {
            if (promisedFile.status === 'fulfilled') {
              newFiles.push(promisedFile.value)
            }
          })
        }

        newSteps = newSteps.map((step) => formatStep(step))
        newFiles = newFiles.map((file) => formatImportedFile(file))

        this.SET_IMPORTED_FILES(newSteps.concat(newFiles))
      }
    } catch (e) {
      console.error(e)
    } finally {
      const { query } = this.$route
      if (query.step) {
        query.step = Number(query.step)
        if (query.step >= 0 && query.step < 4) {
          if (query.step > 1) {
            this.cannotAdd = true
          }
          this.stepActive = query.step
        }
      }
      this.creationLoading = false
    }
  },
  beforeDestroy () {
    this.SET_IMPORTED_FILES([])
  },
  beforeRouteLeave (to, from, next) {
    const isNavigationToLogout = to.name === LOGOUT_ROUTE_NAME
    if (isNavigationToLogout) {
      next()
    }

    if (this.stepActive === 2 && this.leaveLocation === null) {
      this.leaveLocation = to

      if (!this.hasOnlyReadOnlyFiles) {
        this.showBeforeLeaveDialog = true
      }

      next(false)
    } else {
      next()
    }
  },
  methods: {
    ...mapDocumentsActions([UPLOAD_HIDDEN_FILE]),
    ...mapSigningChecklistActions([BULK_UPSERT_SIGNING_CHECKLIST_STEP, GET_STEP]),
    ...mapSigningChecklistMutations([SET_IMPORTED_FILES]),
    ...mapFilesActions([GET_FILE]),
    ...mapRoomActions([GET_ROOM_PROVIDERS]),
    ...mapGroupsActions(['GET_GROUPS']),
    ...mapActions('envelopeTemplates', ['GET_ENVELOPE_TEMPLATE']),
    async checkStepValidation () {
      if (this.stepActive === 1) {
        if (this.anyImportedFiles) {
          this.stepActive++
        }
      } else if (this.stepActive === 2) {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          if (!this.atLeastOneRecipientForEachFile) {
            this.showStep2MissingRecipientWarningDialog = true
            return
          }

          if (!this.isCurrentUserRecipientInEveryFiles()) {
            this.showStep2RecipientWarningDialog = true
            return
          }

          if (!this.hasEverySingleEnvelopeSignatoriesInEveryFiles || !this.hasEveryMultipleEnvelopeTwoUniqueSigners) {
            this.showStep2SignatoryWarningDialog = true
            return
          }

          await this.upsertAllImportedFilesToSteps()
          if (!this.isStep3Disabled) {
            this.stepActive++
            this.hasPassedStep3 = true
          } else {
            this.returnToSigningChecklist()
          }
        } else {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'warning',
            message: this.$t('common.validations.fillOutAll'),
          })
        }
      }
    },
    async confirmWarningStep2Dialog () {
      await this.upsertAllImportedFilesToSteps()
      this.stepActive++
    },
    async upsertAllImportedFilesToSteps () {
      this.actionLoading = true
      const importedFilesBeforeUpdate = cloneDeep(this.importedFiles)
      const objectsToPost = []
      this.importedFiles.forEach((file, index) => {
        if (file.objectToPost.signatureType === SIGNATURE_TYPE_HANDWRITTEN) {
          file.objectToPost.providerOptions.mercury = file.providerOptions.mercury || false
        }
        file.objectToPost.uid = index
        file.status = 'pending'

        if (file.objectToPost.witnesses?.flatMap(w => w.witness)?.includes(undefined)) {
          const signerWitnesses = []
          file.objectToPost.signers.flat().map(signer => {
            const witnesses = file.objectToPost.witnesses?.filter(w => w.witnessOfSignerId === signer).map(w => w.signerId)
            if (witnesses.length) {
              signerWitnesses.push({ signer: signer, witness: witnesses })
            }
          })
          file.objectToPost.witnesses = signerWitnesses
        }

        if (this.addFromTemplate) {
          file.objectToPost.template = {
            id: file.templateId,
            signers: file.templateSignatures
              .filter(signature => signature.signerType === SIGNATURE_TYPES.SINGLE)
              .map(signature => ({
                templateSignerId: signature.id,
                assignedSignerId: signature.assignedSigner.id,
              })),
          }
        }

        objectsToPost.push(file.objectToPost)
      })
      if (objectsToPost.length !== 0) {
        try {
          const data = await this.BULK_UPSERT_SIGNING_CHECKLIST_STEP({
            mnemo: this.mnemo,
            steps: objectsToPost,
            })

          for (const backendStep of data.steps) {
            const frontEndStep = this.importedFiles.find((step) => step.objectToPost.uid === backendStep.uid)
            frontEndStep.objectToPost = formatStep(backendStep).objectToPost
          }

          if (data.errorSteps.length !== 0) {
            for (const errorUid of data.errorSteps) {
              const frontEndStep = this.importedFiles.find((step) => step.objectToPost.uid === errorUid)
              frontEndStep.status = 'error'
            }
            return Promise.reject(new Error('Some steps errored'))
          }
          if (data.errorSteps.length === 0 && this.steps.length > 0) {
            const observersHaveChanged = function (importedFile) {
              if (importedFile.objectToPost?.observers?.length > 0) {
                if (importedFile.objectToPost?.observers?.length !== importedFile.objectToPost?.envelope?.observers?.length) {
                  return true
                } else {
                  return !importedFile.objectToPost?.observers.every(observer => { return importedFile.objectToPost?.envelope?.observers.some(o => o.id === observer) })
                }
              }
              return false
            }
            const validatorsHaveChanged = function (importedFile) {
              if (importedFile.objectToPost?.validators?.length > 0) {
                if (importedFile.objectToPost?.validators?.length !== importedFile.objectToPost?.envelope?.validators?.length) {
                  return true
                } else {
                  return !importedFile.objectToPost?.validators.every(validators => { return importedFile.objectToPost?.envelope?.validators.some(v => v.id === validators) })
                }
              }
              return false
            }
            const modifiedObservers = importedFilesBeforeUpdate.some(importedFile => observersHaveChanged(importedFile))
            const modifiedValidators = importedFilesBeforeUpdate.some(importedFile => validatorsHaveChanged(importedFile))
            let message
            if (modifiedObservers || modifiedValidators) {
              if (modifiedObservers && modifiedValidators) {
                message = this.$t('project.views.SigningChecklistAdd.addingObserversAndValidatorsSuccess')
              } else if (modifiedObservers) {
                message = this.$t('project.views.SigningChecklistAdd.addingObserversSuccess')
              } else if (modifiedValidators) {
                message = this.$t('project.views.SigningChecklistAdd.addingValidatorsSuccess')
              }
              this.$store.commit(ENQUEUE_SNACKBAR, {
                color: 'success',
                message,
              })
            }
          }
        } catch (error) {
          console.error(error)
          if (error.request?.status === 403) {
            this.$store.commit(ENQUEUE_SNACKBAR, {
              color: 'error',
              message: error.response?.data?.description,
            })
          } else {
            this.$store.commit(ENQUEUE_SNACKBAR, {
              color: 'error',
              message: this.$t('common.msgFailErrorOccurred'),
            })
          }
          return Promise.reject(error)
        } finally {
          this.actionLoading = false
        }
      }
    },
    isCurrentUserRecipientInEveryFiles () {
      return this.importedFiles.every((file) => file.objectToPost.recipients.includes(this.profile.id))
    },
    updateUploadedFiles (files) {
      if (files) this.SET_IMPORTED_FILES(files)
      this.launchQueue()
    },
    launchQueue () {
      for (const f of this.filesToUpload) {
        if (f.status === 'queued') {
          if (this.queuedFilesInProgress < (process.env.VUE_APP_SIMULTANEOUS_FILES_UPLOAD || 2)) {
            f.status = 'uploading'
            this.UPLOAD_HIDDEN_FILE({ file: f, mnemo: this.mnemo })
              .then(() => {
                f.objectToPost.fileId = f.id
                f.objectToPost.title = f.basename
              })
              .finally(() => {
                if (this.queuedFilesInProgress < (process.env.VUE_APP_SIMULTANEOUS_FILES_UPLOAD || 2)) {
                  this.launchQueue()
                }
              })
          }
        }
      }
    },
    onCancel () {
      if (this.stepActive > 1) {
        this.showCancelDialog = true
      } else {
        this.returnToSigningChecklist()
      }
    },
    onFinish () {
      if (this.hasDocumentsWithTagsOverlaps && !this.hasOnlyReadOnlyFiles) {
        this.showTagsOverlapsDialog = true
      } else {
        this.returnToSigningChecklist()
      }
    },
    async onSaveAndExit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        await this.upsertAllImportedFilesToSteps()
        this.returnToSigningChecklist()
      } else {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'warning',
          message: this.$t('common.validations.fillOutAll'),
        })
      }
    },
    returnToSigningChecklist () {
      this.leaveLocation = { name: SIGNING_CHECKLIST_ROUTE_NAME }
      this.$router.push({ name: SIGNING_CHECKLIST_ROUTE_NAME })
    },
    async prepareEnvelopeTemplatesAsFormattedSteps () {
      const envelopeTemplatesPromises = []
      const envelopeTemplatesIds = this.$route.query.envelopeTemplatesIds.split(',')
      for (const envelopeTemplateId of envelopeTemplatesIds) {
        envelopeTemplatesPromises.push(this.GET_ENVELOPE_TEMPLATE({ envelopeTemplateId }))
      }
      const promisedEnvelopeTemplates = await Promise.allSettled(envelopeTemplatesPromises)
      const formattedSteps = []
      promisedEnvelopeTemplates.forEach(p => {
        if (p.status === 'fulfilled') {
          formattedSteps.push(formatStepFromEnvelopeTemplate(p.value.envelopeTemplate))
        }
      })
      this.SET_IMPORTED_FILES(formattedSteps)
    },
  },
}
</script>

<style scoped lang="scss">
.signing-checklist-title {
  font-size: 1.3rem;
  font-weight: bold;
}
.SigningChecklistAdd-actionsBar {
  align-items: center;
  background-color: var(--v-originalGrey-lighten5);
  bottom: 0;
  display: flex;
  flex-grow: 0;
  padding: 16px;
  position: sticky;
  width: 100%;
  z-index: 1;
}
</style>
