<template>
  <v-container fluid class="pa-0">
    <!-- MOBILE -->
    <template v-if="$vuetify.breakpoint.mobile">
      <v-card flat outlined class="my-4 mx-2">
        <v-tabs vertical class="tabs-profile" :value="path">
          <template v-for="section in PROFILE_SECTIONS">
            <div :key="section.key">
              <v-tab
                :href="`#/profile/${section.key}`"
                @click="$router.push('/profile/' + section.key).catch(() => {})"
              >
                <v-icon
                  small
                  left
                  class="profile-icon"
                  v-text="
                    path === `/profile/${section.key}`
                      ? section.icon
                      : 'fal ' + section.icon
                  "
                ></v-icon>
                <span>{{ $t(section.title) }}</span>
                <v-badge
                        v-if="section.key === 'identity-checking' && !canCurrentUserSign"
                        color="error"
                        class="mt-1"
                        :value="true"
                        dot
                        :offset-x="-5"
                        :offset-y="-5"
                      />
              </v-tab>
              <v-divider />
            </div>
          </template>
        </v-tabs>
      </v-card>
      <v-card flat class="py-5 profile rounded-lg" outlined>
        <v-tabs-items v-model="path">
          <v-tab-item :value="$route.path">
            <transition name="app-fade" tag="div">
              <router-view></router-view>
            </transition>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
    <!-- DESKTOP -->
    <v-row v-else style="height: 100%" class="my-4">
      <v-col cols="12" sm="3" class="py-0 offset-sm-1">
        <v-row justify="end" align="center" style="height: 100%">
          <v-card
            flat
            class="ml-2 mt-3 pb-2 rounded-lg"
            style="width: 100%; height: 100%"
            outlined
          >
            <v-toolbar flat>
              <v-toolbar-title
                >{{ profile.firstName }} {{ profile.lastName }}</v-toolbar-title
              >
            </v-toolbar>
            <v-divider></v-divider>
            <v-tabs vertical class="tabs-profile" :value="path">
              <template v-for="section in PROFILE_SECTIONS">
                <div :key="section.key">
                  <v-tab
                    :href="`#/profile/${section.key}`"
                    @click="
                      $router.push('/profile/' + section.key).catch(() => {})
                    "
                  >
                    <v-icon
                      small
                      left
                      class="profile-icon"
                      v-text="
                        path === `/profile/${section.key}`
                          ? section.icon
                          : 'fal ' + section.icon
                      "
                    ></v-icon>
                    <span>{{ $t(section.title) }}</span>
                    <AppNewLabel v-if="section.isNew" class="ml-2" />
                    <v-badge
                            v-if="section.key === 'identity-checking' && !canCurrentUserSign"
                            color="error"
                            class="mt-1"
                            :value="true"
                            dot
                            :offset-x="-5"
                            :offset-y="-5"
                          />
                  </v-tab>
                  <v-divider />
                </div>
              </template>
            </v-tabs>
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="12" sm="7" class="py-0">
        <v-card flat class="py-5 profile rounded-lg" outlined>
          <v-tabs-items v-model="path">
            <v-tab-item :value="path">
              <transition name="app-fade" tag="div">
                <router-view></router-view>
              </transition>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <ProfileNavigationDrawer
      v-if="$vuetify.breakpoint.mobile"
      :profileSections="PROFILE_SECTIONS"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppNewLabel from '@/common/AppNewLabel.vue'

import ProfileNavigationDrawer from '../ProfileNavigationDrawer'
import profileSections from '../profileSections'

export default {
  name: 'Profile',
  components: { ProfileNavigationDrawer, AppNewLabel },
  computed: {
    ...mapGetters('user', ['canCurrentUserSign']),
    ...mapState('user', ['profile']),
    path () {
      const matchedSection = this.PROFILE_SECTIONS.find(section =>
        this.$route.path.startsWith(`/profile/${section.key}`), // handles dynamic urls
      )
      return matchedSection ? `/profile/${matchedSection.key}` : ''
    },
  },
  created () {
    this.$store.commit('setBadgeProfileVisible', !this.canCurrentUserSign)
    this.PROFILE_SECTIONS = profileSections
  },
}
</script>

<style scoped lang="scss">
.tabs-profile .v-tab {
  justify-content: start !important;
  max-width: 100% !important;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.profile-icon {
  /* This helps put consistent margins between icons and their text */
  /* Some icons have less natural width than others so normal margins would leave them unaligned */
  width: 30px;
}
a.v-tab.v-tab--active {
  background-color: var(--v-primary-lighten4);
}
</style>
