<template>
  <v-col cols="12" class="px-10">
    <v-row no-gutters class="mb-5">
      <h2 class="font-weight-bold text-h2">{{$t('profile.views.Password.password')}}</h2>
    </v-row>
    <v-form
      ref="form"
      v-model="isFormValid"
      lazy-validation
      @submit.prevent="changePassword"
    >
      <v-row>
        <v-col cols="12" sm="6">
          <PasswordTextField
            v-model="passwordOld"
            :label="$t('profile.views.Password.currentPassword')"
            :hint="$t('profile.views.Password.atLeast8Chars')"
          />
        </v-col>
      </v-row>
      <v-row class="mt-n3">
        <v-col cols="12" sm="6">
        <PasswordTextField
          v-model="password"
          :label="$t('profile.views.Password.newPassword')"
          :hint="$t('profile.views.Password.atLeast8Chars')"
        />
        </v-col>
        <v-col cols="12" sm="6">
        <PasswordTextField
          v-model="passwordConfirm"
          :label="$t('profile.views.Password.confirmPassword')"
          :hint="$t('profile.views.Password.atLeast8Chars')"
          :rules="[
            (v) => !!v || $t('common.validations.fieldRequired', { fieldName: '' }),
            (v) => (v && v.length >= 8) || $t('profile.views.Password.atLeast8Chars'),
            (v) => (v === password) || $t('profile.views.Password.confirmPasswordError'),
          ]"
        />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-btn
          color="primary"
          class="my-4 "
          depressed
          height="40"
          :loading="loading"
          :disabled="(!isFormValid || (!password || !passwordOld || !passwordConfirm))"
          type="submit"
          >{{$t('profile.views.Password.changePassword')}}</v-btn
        >
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import PasswordTextField from '@/common/PasswordTextField'

import { UPDATE_PASSWORD } from '../../store/modules/user/action_types'
const { mapActions, mapState } = createNamespacedHelpers('user')

export default {
  name: 'Password',
  components: {
    PasswordTextField,
  },
  data () {
    return {
      isFormValid: false,
      loading: false,
      passwordOld: '',
      password: '',
      passwordConfirm: '',
    }
  },
  computed: {
    ...mapState(['updatePasswordPending']),
  },
  methods: {
    ...mapActions([UPDATE_PASSWORD]),
    async changePassword () {
      if (this.$refs.form.validate()) {
        try {
          await this.UPDATE_PASSWORD({
            passwordOld: this.passwordOld,
            password: this.password,
          })
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            timeout: 3000,
            message: this.$t('profile.views.Password.newPasswordSaved'),
          })
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
        } catch (error) {
          let message = this.$t('common.msgFailErrorOccurred')

          if (error.response?.data?.errorCode === 'ERR_PASSWORD_WRONG') {
            message = this.$t('profile.views.Password.currentPasswordError')
          }

          if (error.response?.data?.errorCode === 'ERR_PASSWORD_NOT_FRESH') {
            message = this.$t('profile.views.Password.newPasswordNotFresh')
          }

          this.$store.commit('enqueueSnackbar', {
            color: 'error',
            timeout: 3000,
            message: message,
          })
        }
      }
    },
  },
}
</script>
