import axios from 'axios'

export default {
  getAuthStatus (token) {
    return axios.get('/imanage/authStatus', { headers: { 'X-CLOSD-IMCONFIG': token } })
  },
  getAuthURL () {
    return axios.get('/imanage/authUrl')
  },
  getAuthCode (data) {
    return axios.post('/imanage/authCode', data)
  },
  getIManageFilePicker (token, params = {}) {
    return axios.get('/imanage/dialog/filePicker', { params, headers: { 'X-CLOSD-IMCONFIG': token } })
  },
  getIManageFolderPicker (token) {
    return axios.get('/imanage/dialog/folderPicker', { headers: { 'X-CLOSD-IMCONFIG': token } })
  },
}
