import axios from 'axios'

export default {
  getSigningChecklist: async function (mnemo, queryObject) {
    return axios.get(`room/${mnemo}/signingGuide`, { params: queryObject })
  },
  getsigningChecklistGroups: async function (mnemo) {
    return axios.get(`room/${mnemo}/signingGuideGroup`)
  },
  moveStep: async function (mnemo, stepId, groupId) {
    return axios.post(`/room/${mnemo}/signingGuideStep/${stepId}/signingGuideGroup`, {
      groupId,
    })
  },
  moveGroup: async function (mnemo, position, blockId) {
    return axios.patch(`room/${mnemo}/signingGuideGroup/${blockId}`, {
      position,
    })
  },
  getStep (mnemo, stepId) {
    return axios.get(`/room/${mnemo}/signingGuide/signingGuideStep/${stepId}`)
  },
  getDeletionType (mnemo, stepId) {
    return axios.get(`/room/${mnemo}/signingGuide/signingGuideStep/${stepId}/deletionType`)
  },
  getClosingSummary (mnemo) {
    return axios.get(`room/${mnemo}/signingGuide/closing`)
  },
  cancelClosing (mnemo) {
    return axios.delete(`room/${mnemo}/signingGuide/closing`)
  },
  cancelScheduledClosing (mnemo) {
    return axios.delete(`room/${mnemo}/signingGuide/schedule`)
  },
  // Does creation and update for a room
  updateScheduledClosing (mnemo, data) {
    return axios.post(`room/${mnemo}/signingGuide/schedule`, data)
  },
  startClosing (mnemo, data) {
    return axios.post(`room/${mnemo}/signingGuide/closing`, data)
  },
  deleteStep (mnemo, stepId) {
    return axios.delete(`/room/${mnemo}/signingGuide/signingGuideStep/${stepId}`)
  },
  deleteStepMultiple (mnemo, stepIds) {
    return axios.patch(`/room/${mnemo}/signingGuideStep/multiple/delete`, {
      signingGuideStepId: stepIds,
    })
  },
  resetStep (mnemo, envId) {
    return axios.post(`/room/${mnemo}/envelope/${envId}/reset`)
  },
  resetStepMultiple (mnemo, envelopeIds) {
    return axios.patch(`/room/${mnemo}/envelope/multiple/reset`, {
      envelopesIds: envelopeIds,
    })
  },
  cancelStep (mnemo, stepId) {
    return axios.delete(`/room/${mnemo}/signingGuide/signingGuideStep/${stepId}/delivery`, {
      data: { closingAction: 'keepClosing' },
    })
  },
  cancelStepMultiple (mnemo, stepIds) {
    return axios.patch(`/room/${mnemo}/signingGuideStep/multiple/void`, {
      signingGuideStepId: stepIds,
    })
  },
  validateThenSendNow (mnemo, envId) {
    return axios.post(`/room/${mnemo}/envelope/${envId}/delivery`)
  },
  validateThenSendNowMultiple (mnemo, envelopeIds) {
    return axios.patch(`/room/${mnemo}/envelope/multiple/delivery`, {
      envelopesIds: envelopeIds,
    })
  },
  sendSignNow (mnemo, stepId) {
    return axios.post(`/room/${mnemo}/signingGuide/signingGuideStep/${stepId}/delivery`)
  },
  sendSignNowMultiple (mnemo, stepIds) {
    return axios.post(`/room/${mnemo}/signingGuideStep/multiple/delivery`, {
      signingGuideStepId: stepIds,
    })
  },
  createGroup: async function (mnemo, position) {
    return axios.post(`room/${mnemo}/signingGuideGroup`, {
      position,
    })
  },
  deleteGroup: async function (mnemo, groupId) {
    return axios.delete(`room/${mnemo}/signingGuideGroup/${groupId}`)
  },
  bulkUpsertSigningChecklistStep (mnemo, steps) {
    return axios.post(`room/${mnemo}/signingGuideStep/multiple`, {
      steps,
    })
  },
  createSigningChecklistStep (mnemo, data) {
    return axios.post(`room/${mnemo}/signingGuide/signingGuideStep/`, data)
  },
  createTitle (mnemo, data) {
    return axios.post(`room/${mnemo}/signingGuide/signingGuideTitle/`, data)
  },
  updateTitle (mnemo, data, id) {
    return axios.patch(`room/${mnemo}/signingGuide/signingGuideTitle/${id}`, data)
  },
  deleteTitle (mnemo, id) {
    return axios.delete(`room/${mnemo}/signingGuide/signingGuideTitle/${id}`)
  },
  incrementTitleIndentation (mnemo, id) {
    return axios.post(`room/${mnemo}/signingGuide/signingGuideTitle/${id}/indent/increment`)
  },
  decrementTitleIndentation (mnemo, id) {
    return axios.post(`room/${mnemo}/signingGuide/signingGuideTitle/${id}/indent/decrement`)
  },
  updateSigningChecklistStep (mnemo, data) {
    return axios.patch(`room/${mnemo}/signingGuide/signingGuideStep/${data.stepId}`, data)
  },
  getSigningView (mnemo, envelopeId) {
    return axios.get(`/room/${mnemo}/envelope/${envelopeId}/signingView`)
  },
  getEnvelopeSignatures (mnemo, envelopeId) {
    return axios.get(`/room/${mnemo}/envelope/${envelopeId}/pdfSign/signatures`)
  },
  postSignedFile (mnemo, envelopeId, formData) {
    return axios.post(`/room/${mnemo}/envelope/${envelopeId}/pdfSign/signedfile`, formData)
  },
  postFinalFile (mnemo, envelopeId, formData) {
    return axios.post(`/room/${mnemo}/envelope/${envelopeId}/pdfSign/final`, formData)
  },
  compileFinalFile (mnemo, envelopeId) {
    return axios.post(`/room/${mnemo}/envelope/${envelopeId}/pdfSign/compile`)
  },
  postLastCompiledFile (mnemo, envelopeId, data) {
    return axios.post(`/room/${mnemo}/envelope/${envelopeId}/pdfSign/compile/validate`, data)
  },
  getSignatureFiles (mnemo, envelopeId) {
    return axios.get(`/room/${mnemo}/envelope/${envelopeId}/pdfSign/signatures`)
  },
  deleteValidation (mnemo, envelopeId, validationId) {
    return axios.delete(`room/${mnemo}/envelope/${envelopeId}/validation/${validationId}/status`)
  },
  postValidation (mnemo, envelopeId, validationId, data) {
    return axios.post(`room/${mnemo}/envelope/${envelopeId}/validation/${validationId}/status`, data)
  },
  postMoveStep (mnemo, stepId, stepType, data) {
    return axios.post(`/room/${mnemo}/signingGuide/signingGuide${stepType === 'title' ? 'Title' : 'Step'}/${stepId}/move`, data)
  },
  refreshStatus (mnemo) {
    return axios.get(`room/${mnemo}/signingGuide/status`, {
      headers: {
        'X-Poll': 'x-poll',
      },
    })
  },
  refreshClosingStatus (mnemo, isPolling = false) {
    let config = {}
    if (isPolling) {
      config = {
        headers: {
          'X-Poll': 'x-poll',
        },
      }
    }
    return axios.get(`room/${mnemo}/signingGuide/closing/status`, config)
  },
  getClosingPredict (mnemo, params) {
    return axios.get(`room/${mnemo}/signingGuide/closing/predict`, params)
  },
  deleteCompiledFile (mnemo, envelopeId) {
    return axios.delete(`/room/${mnemo}/envelope/${envelopeId}/pdfSign/compile`)
  },
  patchCompiledFile (mnemo, envelopeId, data) {
    return axios.patch(`/room/${mnemo}/envelope/${envelopeId}/pdfSign/compile`, data)
  },
  updateClosingEndDate (mnemo, data) {
    return axios.patch(`/room/${mnemo}/signingGuide/closing`, data)
  },
  swapStepFiles (mnemo, data) {
    return axios.patch(`/room/${mnemo}/envelope/multiple/swapFile`, data)
  },
}
