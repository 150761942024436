<template>
  <div>
    <template v-if="status === SIGNATURE_STATUS.SIGNED">
      <div class="d-flex align-center">
        <font-awesome-icon :icon="['fad', 'circle-check']"
                           style="font-size: 33px; --fa-primary-color: #7bb287; --fa-secondary-color: #7bb287"
                           class="mr-2"
        ></font-awesome-icon>
        {{ $t('project.signed-documents.SignedDocumentsTableItemOptions.youSignedTheDocument') }}
      </div>
    </template>
    <template v-if="status === SIGNATURE_STATUS.SIGNED_BY_ALL">
      <div class="d-flex align-center" style="justify-content: end">
        <font-awesome-icon :icon="['fad', 'circle-check']"
                           style="font-size: 24px; --fa-primary-color: #7bb287; --fa-secondary-color: #7bb287"
                           class="mr-2"
        ></font-awesome-icon>
        <template v-if="hasOriginal">
          <app-button size="small"
                      type="outlined"
                      variant="neutral"
                      width="110"
                      @click.stop="openSingleFileDownloadDialog = true"
          >
            <font-awesome-icon :icon="['fal','file-download']"
                               style="font-size: 16px;"
                               class="mr-2"
            ></font-awesome-icon>
            {{$t('project.signed-documents.SignedDocumentsTableItemOptions.documentDownload')}}
          </app-button>
          <SignedDocumentsDownloadDialog v-if="openSingleFileDownloadDialog"
                                         :signed-document="item"
                                         @close="openSingleFileDownloadDialog = false"
          />
        <AppTooltip top>
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-menu>
                <template #activator="{ on, attrs }">
                  <app-button class="ml-2"
                              type="icon"
                              v-bind="attrs"
                              v-on="on"
                  >
                    <font-awesome-icon :icon="['far','ellipsis-vertical']"
                                       style="font-size: 16px;"
                    ></font-awesome-icon>
                  </app-button>
                </template>
                <v-list>
                  <v-list-item link @click="$emit('shareOriginal', item)">
                    <v-list-item-title>{{ $t('project.signed-documents.SignedDocumentsTableItemOptions.menu.share') }}</v-list-item-title>
                  </v-list-item>
                  <ArchiveAddAccessListItem :routeOptions="archiveAddRouteOptions" :icon="false" />
                  <v-list-item link @click="$emit('deleteOriginal', item)">
                    <v-list-item-title>{{ $t('project.signed-documents.SignedDocumentsTableItemOptions.menu.delete') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="imanageEnabledAndLogged" link @click="$emit('exportToImanage', item.id)">
                    <v-list-item-title>{{ $t('project.signed-documents.SignedDocumentsTableItemOptions.menu.exportToImanage') }}</v-list-item-title>
                  </v-list-item>
                  <PolyOfficeListItemExport v-if="polyOfficeEnabled"
                                            :fileSize="combinedSignedDocumentSize"
                                            @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
                  />
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>{{ $t('common.additionalActions') }}</span>
        </AppTooltip>
        </template>
        <template v-else>
          <app-button disabled
                      @click.stop
          >
            {{$t('project.signed-documents.SignedDocumentsTableItemOptions.noAccessToOriginal')}}
          </app-button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import ArchiveAddAccessListItem from '@/common/archives/ArchiveAddAccessListItem.vue'
import { SignedEnvExport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeListItemExport from '@/common/polyOffice/PolyOfficeListItemExport.vue'
import { SIGNATURE_STATUS } from '@/common/utils/signatureStatus'
import { GET_EXPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import SignedDocumentsDownloadDialog from './dialogs/SignedDocumentsDownloadDialog'

export default {
  name: 'SignedDocumentsTableItemOptions',
  components: {
    AppTooltip,
    ArchiveAddAccessListItem,
    PolyOfficeListItemExport,
    SignedDocumentsDownloadDialog,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      SIGNATURE_STATUS,
      openSingleFileDownloadDialog: false,
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    status () {
      if (this.item.status === 'sent' && this.item.mySignature.status === 'sent') return this.SIGNATURE_STATUS.SENT
      else if (this.item.status === 'sent' && this.item.mySignature.status === 'signed') return this.SIGNATURE_STATUS.SIGNED
      else if (this.item.status === 'signed' && !this.item.mySignature && this.item.otherSignatures.every(o => o.status === 'signed')) return this.SIGNATURE_STATUS.SIGNED_BY_ALL
      else if (this.item.status === 'signed' && this.item.mySignature.status === 'signed') return this.SIGNATURE_STATUS.SIGNED_BY_ALL
      else if (this.item.status === 'voided') return this.SIGNATURE_STATUS.VOIDED
      return null
    },
    hasOriginal () {
      return this.status === this.SIGNATURE_STATUS.SIGNED_BY_ALL && (this.item.proofFileURL || this.item.signedDocumentURL)
    },
    combinedSignedDocumentSize () {
      return this.item.signSize + this.item.proofSize
    },
    archiveAddRouteOptions () {
      return {
        params: {
          signedDocuments: [this.item],
        },
        query: {
          signedDocumentIds: this.item.id,
          fromMnemo: this.roomMnemo,
        },
      }
    },
  },
  methods: {
    ...mapActions('polyOffice', [
      GET_EXPORT_IFRAME,
    ]),
    async polyOfficeOpenDialogHandler () {
      const payload = new SignedEnvExport(
        this.roomMnemo,
        [this.item.id],
      )
      try {
        await this.GET_EXPORT_IFRAME({
          payload,
          successMessage: this.$t('project.signed-documents.SignedDocumentsTableItemOptions.polyOffice.exportSuccessful'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1),
        })
      }
    },
  },
}
</script>
