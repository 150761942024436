<template>
  <v-card>
    <v-card-title>
      {{ $t('project.members.MemberSubscription.title') }}
      <AppButton
        class="ml-auto mr-n5 mt-n6"
        icon
        @click="cancel"
      >
        <font-awesome-icon :icon="['fal', 'xmark']" size="lg" />
      </AppButton>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <p v-if="userFullNameAndMail && !multi"
             v-html="$t('project.members.MemberSubscription.info', { user: userFullNameAndMail, subName: errorData.name})"
          ></p>
          <p v-if="multi"
             v-html="$tc('project.members.MemberSubscription.infoMulti', errorData.nbUsers, {nbUsers: errorData.nbUsers, subName: errorData.name})"
          ></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="errorData && errorData.yearlyPrice" cols="12" md="6">
          <AppCard hover
                   class="card-option h-100"
                   :class="{'card-option--selected': selectedOption === options.yearlyLicense}"
                   @click.native="selectedOption = options.yearlyLicense"
          >
            {{$t('project.members.MemberSubscription.annualSub', {price: errorData.yearlyPrice})}}
          </AppCard>
        </v-col>
        <v-col v-if="errorData && errorData.monthlyPrice" cols="12" md="6">
          <AppCard hover
                   class="card-option h-100"
                   :class="{'card-option--selected': selectedOption === options.monthlyLicense}"
                   @click.native="selectedOption = options.monthlyLicense"
          >
            <template v-if="errorData.monthlyPrice === -1">
              {{$t('project.members.MemberSubscription.freeTrial')}}
            </template>
            <template v-else>
              {{$t('project.members.MemberSubscription.monthlySub', {price: errorData.monthlyPrice})}}
            </template>
          </AppCard>
        </v-col>
        <v-col cols="12" md="6">
          <AppCard hover
                   class="card-option h-100"
                   :class="{'card-option--selected': selectedOption === options.noLicense}"
                   @click.native="selectedOption = options.noLicense"
          >
            {{$t('project.members.MemberSubscription.noSub')}}
          </AppCard>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <AppButton @click="cancel">
        {{$t('common.cancel')}}
      </AppButton>
      <AppButton color="primary"
                 :disabled="okDisabled"
                 :loading="okLoading"
                 @click="validate"
      >
        {{$t('common.ok')}}
      </AppButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppCard from '@/common/AppCard'
import AppButton from '@/common/buttons/AppButton'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'MemberSubscription',
  components: { AppButton, AppCard },
  props: {
    errorData: {
      type: Object,
      required: true,
    },
    memberForm: {
      type: Object,
      default: () => ({}),
    },
    okLoading: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selectedOption: null,
      options: {
        yearlyLicense: 1,
        monthlyLicense: 2,
        noLicense: 3,
      },
    }
  },
  computed: {
    userFullNameAndMail () {
      return `${this.memberForm.firstName} ${this.memberForm.lastName} (${this.memberForm.email})`
    },
    userFullName () {
      return `${this.memberForm.firstName} ${this.memberForm.lastName}`
    },
    okDisabled () {
      return !this.selectedOption
    },
  },
  methods: {
    cancel () {
      this.$emit('onCancel')
    },
    validate () {
      switch (this.selectedOption) {
        case 1 :
          this.$emit('onSubscriptionValidate', { confirmYearly: true })
          break
        case 2 :
          this.$emit('onSubscriptionValidate', { confirmMonthly: true })
          break
        case 3 :
          if (this.multi) {
            this.$store.commit(
              ENQUEUE_ERROR_SNACKBAR,
              this.$tc('project.members.MemberSubscription.noSubErrorMulti', this.errorData.nbUsers),
            )
          } else {
            this.$store.commit(
              ENQUEUE_ERROR_SNACKBAR,
              this.$t('project.members.MemberSubscription.noSubError', { member: this.userFullName }),
            )
          }

          this.$emit('onSubscriptionDecline')
          break
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .card-option {
    border: 3px solid transparent;
  }
  .card-option--selected {
    border-color: var(--v-primary-base);
  }
</style>
