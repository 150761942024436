<template>
  <div>
    <v-radio-group
      :disabled="isEditingLimited"
      :error-messages="envelopeTypeErrorMessages"
      :value="envelopeType"
      active-class="envelopeType__card--active"
      class="envelopeType__container"
      required
      row
      @change="onEnvelopeTypeChange"
    >
      <div v-for="option in envelopeTypeOptions"
            :key="`option-${option}`"
            class="d-flex flex-column"
      >
        <v-radio :value="option"
                  class="envelopeType__card"
                  style="pointer-events: all"
        >
          <template v-slot:label>
            <div class="w-100 d-flex align-center justify-space-between">
              <h4>{{ $t(`project.signing-checklist.add.SigningChecklistSectionDocumentEnvelopeType.envelopeTypeOptions.${option}`) }}</h4>
              <span v-if="option === ENVELOPE_TYPES.SINGLE && isMultiEnvelopeChild">
                ({{ $t('common.envelopeType.fromBulk') }})
              </span>
              <v-chip v-if="option === ENVELOPE_TYPES.MULTIPLE"
                      outlined
                      color="primary"
                      small
              >
                {{ $t('common.new') }}
              </v-chip>
            </div>
          </template>
        </v-radio>
        <span class="mt-2 originalGrey--text text--darken-2">{{ $t(`project.signing-checklist.add.SigningChecklistSectionDocumentEnvelopeType.envelopeTypeOptions.${option}Info`) }}</span>
      </div>
    </v-radio-group>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'

import { ENVELOPE_TYPES } from '@/project/signing-checklist/constants'

export default defineComponent({
  name: 'SigningChecklistSectionDocumentEnvelopeType',
  props: {
    envelopeType: {
      type: String,
      required: true,
    },
    isEditingLimited: {
      type: Boolean,
      required: true,
    },
    isMultiEnvelopeChild: {
      type: Boolean,
      required: true,
    },
    envelopeTypeValidator: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      ENVELOPE_TYPES,
    }
  },
  computed: {
    envelopeTypeOptions (): string[] {
      return Object.values(ENVELOPE_TYPES)
    },
    envelopeTypeErrorMessages (): TranslateResult[] {
      if (this.envelopeTypeValidator.$dirty &&
        !this.envelopeTypeValidator.required) {
        return [this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.signing-checklist.add.SigningChecklistSectionDocumentEnvelopeType.envelopeTypeLabel') })]
      }
      return []
    },
  },
  methods: {
    onEnvelopeTypeChange (value) {
      this.$emit('envelope-type-change', value)
    },
  },
})
</script>

<style scoped lang="scss">
::v-deep .v-input--selection-controls {
  margin-top: 0;
}
::v-deep .v-input--radio-group__input {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
}
.envelopeType__container {
  .envelopeType__card {
    justify-self: stretch;
    padding: 16px 24px;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    margin: 0;

    &--active {
      outline: 2px solid var(--v-primary-base);
      outline-offset: -2px;
      background-color: var(--v-primary-lighten4);
    }

    .caption {
      color: #717B85;
      font-size: 12px;
    }
  }
}
</style>
