<template>
  <li class="DocumentsActionBar-List-Item" :name="name">
    <slot>
       <MultiSelectBarButton v-if="icon && title" @click="onClick">
        <font-awesome-icon :icon="icon" class="mr-2"></font-awesome-icon>
        {{ title }}
      </MultiSelectBarButton>
    </slot>
  </li>
</template>

<script>
import MultiSelectBarButton from '@/common/buttons/MultiSelectBarButton'

export default {
  name: 'DocumentsActionBarListItem',
  components: {
    MultiSelectBarButton,
  },
  props: {
    icon: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick () {
      this.$emit('click')
    },
  },
}
</script>
