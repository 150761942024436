import axios from 'axios'
import Vue from 'vue'

import { AUTH_DATA_COOKIE_KEY, backToSignIn } from '@/common/utils/login'
import {
  SUBDOMAIN_COOKIE_KEY,
  getRootDomainFromUrl,
  getSubDomainFromCurrentUrl,
  navigatingOnAvailableSubdomain,
} from '@/common/utils/url'
import { USER_ID_STORAGE_KEY, USER_TOKEN_STORAGE_KEY } from '@/common/utils/users'
import router, { ROOMS_ROUTE_NAME } from '@/router'
import {
  ACTIVATE_ACCOUNT,
  CHECK_TOKEN,
  FORGOT_PASSWORD,
  GET_AUTH_FROM_TOKEN,
  RESEND_TWO_FACTORS_CODE,
  RESET_PASSWORD,
  SAML_CHECK,
  SIGN_IN,
  VALIDATE_TWO_FACTORS_CODE,
} from '@/store/modules/login/action_types'

import {
  SET_AUTH_FROM_TOKEN_ERROR,
  SET_AUTH_FROM_TOKEN_PENDING,
  SET_LOADING_PAGE,
  SET_SIGNIN_PENDING,
  SET_USER_TOKEN_SCOPE,
} from './mutation_types'
import { Login } from '../../../models/login.model'
import loginService from '../../../services/login.service'
import { SET_USER_PROFILE } from '../user/mutation_types'

/**
 * Sets up the store and storage when a new user gets logged in
 * @param {object} data The data received from a successful login endpoint
 * @param {function} commit The module-bound commit function from a login action
 */
function setNewUser (data, commit) {
  localStorage.setItem(USER_TOKEN_STORAGE_KEY, data.access_token)
  // Used to detect a new user and inform other instances of the app through localStorage event
  localStorage.setItem(USER_ID_STORAGE_KEY, data.user.id)
  commit(SET_USER_TOKEN_SCOPE, data.scopes)
  axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`
  // We have everything we need in the data
  commit('user/' + SET_USER_PROFILE, data.user, { root: true })
}

function setAuthDataCookie (accessToken, userId, redirectPath = '') {
  const clientAppUrl = process.env.VUE_APP_CLIENT_URL
  const cookieDomain = getRootDomainFromUrl(clientAppUrl)
  const authData = { token: accessToken, userId }

  Vue.$cookies.set(AUTH_DATA_COOKIE_KEY, authData, '1d', null, cookieDomain)
}

function setSubdomainCookie () {
  const subdomain = getSubDomainFromCurrentUrl()
  if (!subdomain) return
  const clientAppUrl = process.env.VUE_APP_CLIENT_URL
  const cookieDomain = getRootDomainFromUrl(clientAppUrl)

  Vue.$cookies.set(SUBDOMAIN_COOKIE_KEY, subdomain, '1d', null, cookieDomain)
}

export const actions = {
  async [GET_AUTH_FROM_TOKEN] ({ commit }, { params, withoutApiToken = false }) {
    commit(SET_AUTH_FROM_TOKEN_PENDING, true)
    try {
      return loginService.getAuthFromToken(params, withoutApiToken)
    } catch (error) {
      commit(SET_AUTH_FROM_TOKEN_ERROR, error)
      throw error
    } finally {
      commit(SET_AUTH_FROM_TOKEN_PENDING, false)
    }
  },
  /**
  * @function Saml checking
  * @param {store} store
  * @param {object} queryParams
  */
  async [SAML_CHECK] ({ commit }, queryParams) {
    commit('SET_LOADING', true)
    try {
      const data = await loginService.samlCheck(queryParams)
      if (data.data.samlEnabled && data.data.redirTo) {
        commit(SET_SIGNIN_PENDING, true)
        window.location = data.data.redirTo
      }
    } catch (error) {
      commit('SET_SAMLCHECK_ERROR', error)
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  /**
     * @function Login function
     * @param {store} store
     * @param {Login} login
     * @param {string} redirectRoute
     * @param {string} redirectUrl
     */
  async [SIGN_IN] ({ commit, rootGetters }, { login, redirectRoute, redirectUrl }) {
    if (login instanceof Login) {
      commit('SET_LOADING', true)

      try {
        const { data } = await loginService.signIn(login)
        const { access_token: accessToken, user } = data
        const signingFromAvailableSubdomain = navigatingOnAvailableSubdomain()
        if (signingFromAvailableSubdomain) {
          setAuthDataCookie(accessToken, user.id)
          setSubdomainCookie()
        } else {
          if (user.isClosdAdmin) {
            setAuthDataCookie(accessToken, user.id)
          }
          setNewUser(data, commit)

          if (rootGetters['user/customizationEnabled']) {
            rebootApp()
          }
        }
        if (redirectUrl) {
          window.location = redirectUrl
        } else if (redirectRoute) {
          router.push(redirectRoute)
        } else {
          router.push({ name: ROOMS_ROUTE_NAME })
        }
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 422) {
          commit('SET_SIGNIN_ERROR', error.response.statusText)
          throw error
        }
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
  /**
     * @function Login function
     * @param {store} store
     * @param {Login} login
     */
  async [FORGOT_PASSWORD] ({ commit }, login) {
    if (login instanceof Login) {
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        loginService.forgotPassword(login)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            commit('SET_FORGOT_PASSWORD_ERROR', error)
            reject(error)
            throw error
          })
          .then(() => {
            commit('SET_LOADING', false)
          })
      })
    }
  },
  /**
     * @function Login function
     * @param {store} store
     * @param {Login} login
     */
  async [RESET_PASSWORD] ({ commit, rootGetters }, login) {
    if (login instanceof Login) {
      commit('SET_LOADING', true)
      try {
        const { data } = await loginService.resetPassword(login)
        const { access_token: accessToken, user } = data

        const resettingPasswordFromAvailableSubdomain = navigatingOnAvailableSubdomain()
        if (resettingPasswordFromAvailableSubdomain) {
          setAuthDataCookie(accessToken, user.id)
          setSubdomainCookie()
        } else {
          setNewUser(data, commit)
          if (rootGetters['user/customizationEnabled']) {
            rebootApp()
          }
        }
        router.push({ name: ROOMS_ROUTE_NAME })
      } catch (error) {
        commit('SET_FORGOT_PASSWORD_ERROR', error)
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
  /**
     * @function Login function
     * @param {store} store
     * @param {Login} login
     * @param {object} redirectRoute A route to go to if successful. Undefined if default should be used
     */
  async [ACTIVATE_ACCOUNT] ({ commit, rootGetters }, { login, redirectRoute }) {
    if (login instanceof Login) {
      commit('SET_LOADING', true)
      try {
        const { data } = await loginService.activateAccount(login)
        const { access_token: accessToken, user } = data

        const activatingFromAvailableSubdomain = navigatingOnAvailableSubdomain()
        if (activatingFromAvailableSubdomain) {
          setAuthDataCookie(accessToken, user.id)
          setSubdomainCookie()
        } else {
          setNewUser(data, commit)
          if (rootGetters['user/customizationEnabled']) {
            rebootApp()
          }
        }

        if (redirectRoute) {
          router.push(redirectRoute)
        } else {
          router.push({ name: ROOMS_ROUTE_NAME })
        }
      } catch (error) {
        commit('SET_FORGOT_PASSWORD_ERROR', error)
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
  /**
     * @function Login Check token validity
     * @param {store} store
     * @param {String} token
     */
  async [CHECK_TOKEN] ({ commit }, token) {
    try {
      await loginService.checkToken(token)
      commit(SET_LOADING_PAGE, false)
    } catch (error) {
      backToSignIn({ isTokenInvalid: true })
    }
  },

  /**
     * @function Login Send code to valid 2FA
     * @param {store} store
     * @param {Object} {code}
     */
  async [VALIDATE_TWO_FACTORS_CODE] ({ commit }, { code }) {
    commit('SET_LOADING', true)
    try {
      const { data } = await loginService.sendTwoFactorCode(code)
      return data
    } catch (error) {
      commit('SET_TWO_FACTOR_ERROR', error)
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  /**
     * @function Login resend code to valid 2FA
     * @param {store} store
     */
  async [RESEND_TWO_FACTORS_CODE] ({ commit }) {
    commit('SET_LOADING', true)
    try {
      await loginService.resendTwoFactorCode()
    } catch (error) {
      commit('SET_TWO_FACTOR_ERROR', error)
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },
}

function rebootApp () {
  // Boot the app again to correctly reset Vuetify themes
  const locationUrl = new URL(window.location.href)
  window.location = locationUrl.toString()
}
