import { track } from '@/common/pendo/agent'
import projectsService from '@/services/projectsFolders.service.js'

import {
  CREATE_PROJECTS_NEW_FOLDER,
} from './action_types'
import {
  SET_LOADING,
} from './mutation_types'

export const actions = {
  async [CREATE_PROJECTS_NEW_FOLDER] ({ commit }, data) {
    commit(SET_LOADING, true)
    try {
      await projectsService.createNewFolder(data)
      track('create_home_folder')
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
}
