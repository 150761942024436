<template>
  <div>
    <div :style="gridStyles">
      <div class="AccessRightsTable-gridCell sticky-left font-weight-medium d-flex align-center"
           style="align-self: center"
      >
        <AppButton icon
                   small
                   :x-padding="0"
                   @click="$emit('toggleGroup', group.id)"
        >
          <font-awesome-icon :icon="['far', 'chevron-right']"
                             class="AccessRightsTable-groupChevron"
                             :class="{'AccessRightsTable-groupChevron--open': isOpen}"
          ></font-awesome-icon>
        </AppButton>
        {{ group.name }}
      </div>
      <div v-for="column in displayedColumns"
           :key="`group-${group.id}-column-${column.propertyName}`"
           class="AccessRightsTable-gridCell"
      >
        <v-checkbox :input-value="group[column.propertyName]"
                    :indeterminate="isGroupPartiallyChecked(column.propertyName)"
                    :disabled="isCheckboxDisabled(column)"
                    dense
                    hide-details
                    class="AccessRightsTable-checkbox"
                    @change="patchGroupRights(column.propertyName, $event)"
        />
      </div>
    </div>
    <template v-if="isOpen">
      <AccessRightsMember v-for="member in group.members"
                          :key="`group-${group.id}-member-${member.id}`"
                          :grid-styles="gridStyles"
                          :patch-rights-pending="patchRightsPending"
                          :member="member"
                          :is-group-pm="Boolean(group.isPm)"
                          :displayed-columns="displayedColumns"
                          v-on="$listeners"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppButton from '@/common/buttons/AppButton.vue'
import AccessRightsMember from '@/project/project-settings/access-rights/AccessRightsMember.vue'
import { PATCH_ROOM_GROUP_RIGHTS } from '@/store/modules/room/action_types'

export default {
  name: 'AccessRightsGroup',
  components: { AccessRightsMember, AppButton },
  props: {
    gridStyles: {
      type: Object,
      required: true,
    },
    patchRightsPending: {
      type: Boolean,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    displayedColumns: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('room', ['isDataRoom', 'roomMnemo']),
  },
  methods: {
    ...mapActions('room', [PATCH_ROOM_GROUP_RIGHTS]),
    isGroupPartiallyChecked (property) {
      return !this.group[property] && (this.group.members.length > 0 && this.group.members.some(g => g[property]))
    },
    async patchGroupRights (property, value) {
      try {
        await this.PATCH_ROOM_GROUP_RIGHTS({
          mnemo: this.roomMnemo,
          groupId: this.group.id,
          data: {
            [property]: value,
          },
        })
        this.$emit('patchRoomRightsSuccess')
      } catch (error) {
        this.$emit('patchRoomRightsError')
      }
    },
    isCheckboxDisabled (column) {
      if (column.disableCheck?.group) {
        return column.disableCheck.group(this.group)
      }

      return Boolean(this.group.isPm) || this.patchRightsPending
    },
  },
}
</script>
