export const ADD_DELETE_SIGNER_PENDING_ID = 'ADD_DELETE_SIGNER_PENDING_ID'
export const REMOVE_DELETE_SIGNER_PENDING_ID = 'REMOVE_DELETE_SIGNER_PENDING_ID'
export const SET_AUTO_TAGS = 'SET_AUTO_TAGS'
export const SET_AUTO_TAGS_ERROR = 'SET_AUTO_TAGS_ERROR'
export const SET_AUTO_TAGS_PENDING = 'SET_AUTO_TAGS_PENDING'
export const SET_CHANGE_SIGNER_ERROR = 'SET_CHANGE_SIGNER_ERROR'
export const SET_CHANGE_SIGNER_PENDING = 'SET_CHANGE_SIGNER_PENDING'
export const SET_DELETE_SIGNER_ERROR = 'SET_DELETE_SIGNER_ERROR'
export const SET_DELETE_TAG_ERROR = 'SET_DELETE_TAG_ERROR'
export const SET_DELETE_TAG_PENDING = 'SET_DELETE_TAG_PENDING'
export const SET_DRAG_TAG_ACTIVE = 'SET_DRAG_TAG_ACTIVE'
export const SET_DRAG_TAG_ACTIVE_ERROR = 'SET_DRAG_TAG_ACTIVE_ERROR'
export const SET_DRAG_TAG_ACTIVE_PENDING = 'SET_DRAG_TAG_ACTIVE_PENDING'
export const SET_DUPLICATE_TAG_ERROR = 'SET_DUPLICATE_TAG_ERROR'
export const SET_DUPLICATE_TAG_PENDING = 'SET_DUPLICATE_TAG_PENDING'
export const SET_ENVELOPE_DISPLAY_INFORMATION = 'SET_ENVELOPE_DISPLAY_INFORMATION'
export const SET_ENVELOPE_DISPLAY_INFORMATION_ERROR = 'SET_ENVELOPE_DISPLAY_INFORMATION_ERROR'
export const SET_ENVELOPE_DISPLAY_INFORMATION_PENDING = 'SET_ENVELOPE_DISPLAY_INFORMATION_PENDING'
export const SET_LAST_SAVE = 'SET_LAST_SAVE'
export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT'
export const SET_PAGE_CONTENT_IS_LOADING = 'SET_PAGE_CONTENT_IS_LOADING'
export const SET_PAGE_THUMB = 'SET_PAGE_THUMB'
export const SET_PAGE_THUMB_IS_LOADING = 'SET_PAGE_THUMB_IS_LOADING'
export const SET_PATCH_SIGNERS_ORDER_PENDING = 'SET_PATCH_SIGNERS_ORDER_PENDING'
export const SET_PATCH_TAG_ERROR = 'SET_PATCH_TAG_ERROR'
export const SET_PATCH_TAG_PENDING = 'SET_PATCH_TAG_PENDING'
export const SET_POST_AUTO_TAGS_ERROR = 'SET_POST_AUTO_TAGS_ERROR'
export const SET_POST_AUTO_TAGS_PENDING = 'SET_POST_AUTO_TAGS_PENDING'
export const SET_POST_SIGNERS_ERROR = 'SET_POST_SIGNERS_ERROR'
export const SET_POST_SIGNERS_PENDING = 'SET_POST_SIGNERS_PENDING'
export const SET_RESET_DOCUMENT_TO_PREPARE = 'SET_RESET_DOCUMENT_TO_PREPARE'
export const SET_SIGNATURE_ORDER_ERROR = 'SET_SIGNATURE_ORDER_ERROR'
export const SET_TAGGING_INFORMATION = 'SET_TAGGING_INFORMATION'
export const SET_TAGGING_INFORMATION_ERROR = 'SET_TAGGING_INFORMATION_ERROR'
export const SET_TAGGING_INFORMATION_PENDING = 'SET_TAGGING_INFORMATION_PENDING'
export const SET_TAG_ERROR = 'SET_TAG_ERROR'
export const SET_TAG_PENDING = 'SET_TAG_PENDING'
export const ADD_TAG_OVERLAP = 'ADD_TAG_OVERLAP'
export const REMOVE_TAG_OVERLAP_BY_ENVELOPE_ID = 'REMOVE_TAG_OVERLAP_BY_ENVELOPE_ID'
export const REMOVE_TAG_OVERLAP_BY_TAG_ID = 'REMOVE_TAG_OVERLAP_BY_TAG_ID'
