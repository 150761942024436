import { GO_TO_FOLDER } from '../documents/action_types'

export const actions = {
  addBreadcrumbItem: function ({ commit }, item) {
    commit('ADD_ITEM', item)
  },
  setBreadcrumbItems: function ({ commit }, items) {
    commit('SET_ITEMS', items)
  },
  goTo: function ({ commit, dispatch }, item) {
    dispatch(`documents/${GO_TO_FOLDER}`, item.id, { root: true })
    commit('REMOVE_ALL_AFTER', item)
  },
  resetBreadcrumb: function ({ commit }) {
    commit('RESET_BREADCRUMB')
  },
}
