import { GroupsMutationTypes } from '@/store/modules/groups/mutation_types'
import { StoreMutations } from '@/types/store'

export const mutations: StoreMutations<GroupsMutationTypes> = {
  SET_LOADING (state, value) {
    state.loading = value
  },
  SET_POST_GROUP_LOADING (state, value) {
    state.postGroupLoading = value
  },
  SET_POST_MASS_LOADING (state, value) {
    state.postMassLoading = value
  },
  SET_DELETE_GROUP_LOADING (state, value) {
    state.deleteGroupLoading = value
  },
  SET_PATCH_HIDE_GROUPS_LOADING (state, value) {
    state.patchHideGroupsLoading = value
  },
  SEND_GLOBAL_REMINDER_LOADING (state, value) {
    state.sendGlobalReminderLoading = value
  },
  SET_GROUPS (state, groups) {
    state.groups = groups
  },
  SET_ERROR (state, error) {
    state.error = error
  },
  SET_PATCH_IS_HIDDEN_PM_PENDING (state, value) {
    state.patchIsHiddenPmPending = value
  },
  SET_PATCH_IS_HIDDEN_PM_ERROR (state, value) {
    state.patchIsHiddenPmError = value
  },
  SET_POST_MEMBER_FROM_TEAM_LOADING (state, value) {
    state.postMemberFromTeamLoading = value
  },
}
