<template>
  <v-menu
    v-if="profile"
    offset-y
    rounded="lg"
    :open-on-hover="!$vuetify.breakpoint.xs"
    transition="slide-y-transition"
    bottom
    left
  >
    <template v-slot:activator="{ on, attrs, value }">
      <div class="profile-menu-item" :class="{'profile-menu-item--opened': value}">
        <AppBarMenuItem v-slot="{active}"
                        class="profile-menu-item__button"
                        :label="profile.firstName"
                        :active="isInProfileRoute"
                        :hover="value"
                        v-bind="attrs"
                        v-on="on"
                        @click="onProfileItemClick"
        >
          <v-avatar class="profile-menu-item__initials caption"
                    :class="{'profile-menu-item__initials--active': active}"
                    size="24"
          >
            {{ profile.initials }}
          </v-avatar>
        </AppBarMenuItem>
      </div>
    </template>

    <div class="profile-menu-item__menu white">
      <div class="px-4 pt-4">
        <p class="text-h6">{{profile.fullName}}</p>
        <p class="text-body-2">{{profile.email}}</p>
      </div>
      <v-list dense>
      <v-list-item-group v-model="path" color="primary">
        <v-list-item
          v-for="(path, i) in paths"
          :key="i"
          :to="path.to"
        >
          <v-list-item-icon class="mr-2">
            <v-badge
              v-if="isInProfileRoute && path.icon === 'fa-user'"
              color="error"
              class="mt-1"
              :value="!canCurrentUserSign"
              dot
            >
              <div class="d-flex flex-column">
                <v-icon
                  small
                  v-text="
                    isInProfileRoute ? 'fa-user' : 'fal fa-user'
                  "
                ></v-icon>
              </div>
            </v-badge>
            <v-icon
              v-else
              small
              :class="{ 'primary--text': path.to === '/' && $route.path === '/' }"
              v-text="path.to !== $route.path ? 'fal ' + path.icon : path.icon"
            ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              :class="{ 'primary--text': path.to === '/' && $route.path === '/' }"
              v-text="(path.text)"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    </div>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppBarMenuItem from '@/common/app-bar/AppBarMenuItem.vue'
import {
  LOGOUT_ROUTE_NAME,
  PROFILE_ROUTE_NAME,
  SUBSCRIPTION_ROUTE_NAME,
} from '@/router'

export default {
  name: 'AppBarProfile',
  components: { AppBarMenuItem },
  props: {
    fullAccessScope: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      path: 0,
    }
  },
  computed: {
    paths () {
      const paths = []
      if (this.fullAccessScope) {
        paths.push({ text: this.$t('common.app-bar.ProfileMenuItem.profile'), icon: 'fa-user', to: { name: PROFILE_ROUTE_NAME } })
        if (this.canAccessSubscription) {
          paths.push({ text: this.$t('common.app-bar.ProfileMenuItem.subscription'), icon: 'fa-credit-card', to: { name: SUBSCRIPTION_ROUTE_NAME } })
        }
      }
      paths.push({ text: this.$t('common.app-bar.ProfileMenuItem.logout'), icon: 'fa-power-off', to: { name: LOGOUT_ROUTE_NAME, params: { redirectProps: { isVolontaryLogOut: true, isLogOut: true } } } })
      return paths
    },
    ...mapState('user', ['profile']),
    ...mapGetters('user', ['canCurrentUserSign', 'canAccessSubscription']),
    isInProfileRoute () {
      return this.$route.name.includes(PROFILE_ROUTE_NAME)
    },
  },
  methods: {
    onProfileItemClick () {
      if (!this.isInProfileRoute) {
        this.$router.push({ name: PROFILE_ROUTE_NAME })
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .profile-menu-item {
    height: 100%;

    &__button:hover {
      .profile-menu-item__initials {
        color: white;
        background: var(--v-primary-base);
      }
    }

    &__initials {
      color: var(--v-grey-darken3);
      background: var(--v-grey-lighten2);

      &--active {
        color: white;
        background: var(--v-primary-base);
      }
    }

    &--opened {
      .profile-menu-item__initials {
        color: white;
        background: var(--v-primary-base);
      }
    }
  }
</style>
