<template>
  <v-container v-if="subscription"
               fluid
               class="py-0 px-2"
  >
    <v-row>
      <v-col class="text-h1">
        {{ $t('subscriptions.views.SubscriptionSubscribers.subscription') }} - {{ subscription.name }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="SubscriptionSubscribers-card">
          <template v-if="subscriptionPending">
            <v-skeleton-loader type="text" class="mb-1"/>
            <v-skeleton-loader type="text"/>
          </template>
          <template v-else>
            <div class="mb-1"
                 v-html="$t('subscriptions.views.SubscriptionSubscribers.commitmentDate', { date: ISOToDDMMYYYY(subscription.startDate) })"></div>
            <div v-if="subscription.billingSeatsAvailable === 999"
                 v-html="$t('subscriptions.views.SubscriptionSubscribers.unlimitedSeats')"></div>
            <div v-else v-html="$t('subscriptions.views.SubscriptionSubscribers.seats', { count: subscription.billingSeats })"></div>
          </template>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SearchTextField v-model="searchQuery"/>
      </v-col>
      <v-col class="text-right">
        <AddSubscriberDialog class="d-inline-block"
                             :subscription="subscription"
                             @postNewSubscriberSuccess="onPostNewSubscriberSuccess"
        />
        <AppDownloadFile :link="exportLink">
          <template #default="{submitForm}">
            <AppButton color="secondary"
                       @click="submitForm"
            >
              <font-awesome-icon class="mr-2" :icon="['fal', 'file-export']"></font-awesome-icon>
              {{ $t('subscriptions.views.SubscriptionSubscribers.export') }}
            </AppButton>
          </template>
        </AppDownloadFile>
      </v-col>
    </v-row>
    <template v-if="subscriptionUsersPending && !subscriptionUsers">
      <v-row>
        <v-col>
          <AppDataTableSkeletonLoader class="w-100"/>
        </v-col>
      </v-row>
    </template>
    <template v-if="subscriptionUsers && !subscriptionUsersPending">
      <v-row>
        <v-col>
          <v-data-table ref="SubscriptionSubscribersTable"
                        :items="filteredSubscriptionUsers"
                        :headers="headers"
                        class="SubscriptionSubscribers-table"
                        :footer-props="{
                        'items-per-page-options': ITEM_PER_PAGE_DEFAULT,
                      }"
                        :items-per-page="tableItemsPerPage"
                        :options.sync="options"
          >
            <template #item.firstName="{item}">
              <div class="d-flex align-center">
                <AppButton icon
                           color="primary"
                           @click="onExpandClick(item)"
                >
                  <font-awesome-icon :icon="['far', 'chevron-right']"
                                     class="SubscriptionSubscribers-expandIcon"
                                     :class="{'SubscriptionSubscribers-expandIcon--open': expanded.length > 0 && expanded[0].id === item.id}"
                  ></font-awesome-icon>
                </AppButton>
                <AppTooltip top>
                  <template #activator="{attrs, on}">
                    <div class="SubscriptionSubscribers-table-firstName"
                         v-bind="attrs"
                         v-on="on"
                    >
                      {{ item.firstName }}
                    </div>
                  </template>
                  <span>{{ item.firstName }}</span>
                </AppTooltip>
              </div>
              <CollapseTransition>
                <div v-if="expanded.includes(item) && item.lastLogin" class="ml-9">
                  <div class="caption font-weight-bold accent--text text--lighten-2">{{ $t('subscriptions.views.SubscriptionSubscribers.lastLogin') }}</div>
                  <div>{{ ISOToShortenedDate(item.lastLogin) }}</div>
                </div>
              </CollapseTransition>
            </template>
            <template #item.lastName="{item}">
              <AppTooltip top>
                <template #activator="{attrs, on}">
                  <div class="SubscriptionSubscribers-table-lastName"
                       style="margin-bottom: 5px;"
                       v-bind="attrs"
                       v-on="on"
                  >
                    {{ item.lastName }}
                  </div>
                </template>
                <span>{{ item.lastName }}</span>
              </AppTooltip>
              <CollapseTransition>
                <div v-if="expanded.includes(item)">
                  <div class="caption font-weight-bold accent--text text--lighten-2">{{ $t('subscriptions.views.SubscriptionSubscribers.activeRooms') }}</div>
                  <div>{{ item.activeRooms }}</div>
                </div>
              </CollapseTransition>
            </template>
            <template #item.email="{item}">
              <AppTooltip top>
                <template #activator="{attrs, on}">
                  <div class="SubscriptionSubscribers-table-email"
                       v-bind="attrs"
                       v-on="on"
                  >
                    {{ item.email }}
                  </div>
                </template>
                <span>{{ item.email }}</span>
              </AppTooltip>
            </template>
            <template #item.participant="{item}">
              <div class="d-flex justify-center">
                <v-switch :input-value="item.isSubUser"
                          class="mt-0"
                          :disabled="item.isSubUser && profile.id === item.id"
                          readonly
                          hide-details
                          :loading="patchSubAdminUserPendingIds.includes(item.id)"
                          @click="!(item.isSubUser && profile.id === item.id) && onSubAdminUserChange(item)"
                />
              </div>
            </template>
            <template #item.administrator="{item}">
              <div class="d-flex justify-center">
                <v-switch :input-value="item.isSubAdmin"
                          class="mt-0"
                          readonly
                          :disabled="item.isSubUser && profile.id === item.id"
                          hide-details
                          :loading="patchSubAdminPendingIds.includes(item.id)"
                          @click="!(item.isSubUser && profile.id === item.id) && onSubAdminChange(item)"
                />
              </div>
            </template>
            <template #item.accounting="{item}">
              <div class="d-flex justify-center">
                <v-switch :input-value="item.isSubBillingAdmin"
                          class="mt-0"
                          hide-details
                          :loading="patchSubAdminBillingPendingIds.includes(item.id)"
                          @click="onSubAdminBillingChange(item)"
                />
              </div>
            </template>
            <template #item.archivingResponsible="{item}">
              <div class="d-flex justify-center">
                <AppTooltip top>
                  <template #activator="{attrs, on}">
                    <div v-bind="attrs"
                         v-on="on"
                    >
                      <v-switch :input-value="item.isSubArchivingAdmin"
                                disabled
                                class="mt-0"
                                hide-details
                      />
                    </div>
                  </template>
                  <span>{{ $t('subscriptions.views.SubscriptionSubscribers.archivingResponsibleTooltip') }}</span>
                </AppTooltip>
              </div>
            </template>
            <template #item.options="{item}">
              <AppButton icon
                         @click="openDeleteUserDialog(item)"
              >
                <font-awesome-icon :icon="['fal', 'trash-can']"></font-awesome-icon>
              </AppButton>
            </template>
            <template #footer.prepend>
              <v-pagination
                v-model="dataTablePage"
                :length="dataTablePageCount"
                :total-visible="7"
                :disabled="disabledPagination"
                @input="onPageChange"
              ></v-pagination>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="subscriptionUsersPending">
      <AppDataTableSkeletonLoader/>
    </template>
    <SubscriptionSubscribersDeleteDialog :is-open="deleteUserDialogIsOpen"
                                         :user-to-delete="userToDelete"
                                         @close="closeDeleteUserDialog"
                                         @userDeleteSuccess="userDeleteSuccess"
    />
    <AppDialog :is-open="!!subAddConfirmationDialogInfo"
               size="m"
               :ok-disabled="seatsPending || seatsError"
               :cancel-disabled="seatsPending"
               @ok="confirmSubAddConfirmationDialog"
               @cancel="cancelSubAddConfirmationDialog"
      >
        <template #title>{{ $t('subscriptions.views.SubscriptionSubscribers.confirmationDialogTitle') }}</template>
        <template #body>
          <div v-if="seatsPending" class="text-center">
            <v-progress-circular indeterminate color="primary" size="48" />
          </div>
          <p v-else>{{subAddConfirmationDialogText}}</p>
        </template>
      </AppDialog>
    <AppDialog :is-open="!!subRemoveConfirmationDialogInfo"
               size="m"
               :ok-text="$t('common.ok')"
               @ok="confirmSubRemoveConfirmationDialog"
               @cancel="cancelSubRemoveConfirmationDialog"
      >
        <template #title>{{ $t('subscriptions.views.SubscriptionSubscribers.confirmationDialogTitle') }}</template>
        <template #body>
          <p>{{$t('subscriptions.views.SubscriptionSubscribers.confirmationDialogSubRemoveText', { subName: subscription.name })}}</p>
        </template>
      </AppDialog>
  </v-container>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppDataTableSkeletonLoader from '@/common/AppDataTableSkeletonLoader'
import AppDownloadFile from '@/common/AppDownloadFile'
import AppButton from '@/common/buttons/AppButton'
import SearchTextField from '@/common/SearchTextField'
import { ITEM_PER_PAGE_DEFAULT } from '@/common/utils/dataTablePagination'
import { ISOToDDMMYYYY, ISOToShortenedDate } from '@/common/utils/dates'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import {
  GET_SUBSCRIPTION_USERS,
  PATCH_SUB_ADMIN,
  PATCH_SUB_ADMIN_BILLING, PATCH_SUB_ADMIN_USER,
  GET_SUBSCRIPTION,
  GET_SEATS,
} from '@/store/modules/subscription/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'
import AddSubscriberDialog from '@/subscriptions/AddSubscriberDialog'
import SubscriptionSubscribersDeleteDialog from '@/subscriptions/SubscriptionSubscribersDeleteDialog'

import AppTooltip from '../../common/AppTooltip'
import AppDialog from '../../common/dialogs/AppDialog.vue'
import { SUBSCRIPTION_SUBSCRIBERS_ITEMS_PER_PAGE } from '../constants.js'

export default {
  name: 'SubscriptionSubscribers',
  components: {
    AppDataTableSkeletonLoader,
    AppDownloadFile,
    AddSubscriberDialog,
    SearchTextField,
    SubscriptionSubscribersDeleteDialog,
    AppButton,
    CollapseTransition,
    AppTooltip,
    AppDialog,
  },
  data () {
    return {
      ITEM_PER_PAGE_DEFAULT,
      ISOToDDMMYYYY,
      ISOToShortenedDate,
      headers: [
        { value: 'firstName', text: this.$t('subscriptions.views.SubscriptionSubscribers.headers.firstName') },
        { value: 'lastName', text: this.$t('subscriptions.views.SubscriptionSubscribers.headers.lastName') },
        { value: 'email', text: this.$t('subscriptions.views.SubscriptionSubscribers.headers.email') },
        { value: 'participant', text: this.$t('subscriptions.views.SubscriptionSubscribers.headers.participant'), align: 'center', cellClass: 'va-inherit' },
        { value: 'administrator', text: this.$t('subscriptions.views.SubscriptionSubscribers.headers.administrator'), align: 'center', cellClass: 'va-inherit' },
        { value: 'accounting', text: this.$t('subscriptions.views.SubscriptionSubscribers.headers.accounting'), align: 'center', cellClass: 'va-inherit' },
        { value: 'archivingResponsible', text: this.$t('subscriptions.views.SubscriptionSubscribers.headers.archivingResponsible'), align: 'center', cellClass: 'va-inherit' },
        { value: 'options', text: this.$t('subscriptions.views.SubscriptionSubscribers.headers.options') },
      ],
      expanded: [],
      deleteUserDialogIsOpen: false,
      subAddConfirmationDialogInfo: null,
      subRemoveConfirmationDialogInfo: null,
      userToDelete: null,
      searchQuery: '',
      options: {},
      subAddConfirmationDialogText: '',
      dataTablePage: 1,
      dataTablePageCount: 1,
      disabledPagination: false,
      SUBSCRIPTION_SUBSCRIBERS_ITEMS_PER_PAGE,
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapState('subscription', [
      'subscription',
      'subscriptionPending',
      'subscriptionUsers',
      'patchSubAdminPendingIds',
      'patchSubAdminBillingPendingIds',
      'patchSubAdminUserPendingIds',
      'seatsPending',
      'seats',
      'seatsError',
      'subscriptionUsersPending',
    ]),
    ...mapGetters('user', ['currentUserId']),
    filteredSubscriptionUsers () {
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase()
        return this.subscriptionUsers.data.filter(user => {
          return user.firstName.toLowerCase().includes(query) ||
            user.lastName.toLowerCase().includes(query)
        })
      }
      return this.subscriptionUsers.data
    },
    exportLink () {
      return `${process.env.VUE_APP_API_URL}/subscription/download/subscribers-excel`
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, SUBSCRIPTION_SUBSCRIBERS_ITEMS_PER_PAGE)
      const itemsPerPage = itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : 50
      this.setPagination(itemsPerPage)
      return itemsPerPage
    },
  },
  watch: {
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, SUBSCRIPTION_SUBSCRIBERS_ITEMS_PER_PAGE))) {
          this.setPagination(newValue.itemsPerPage)
          setUserSetting(this.currentUserId, SUBSCRIPTION_SUBSCRIBERS_ITEMS_PER_PAGE, newValue.itemsPerPage)
        }
      },
    },
  },
  created () {
    this.prepareGetSubscriptionUsers()
  },
  methods: {
    ...mapActions('subscription', [GET_SUBSCRIPTION_USERS, PATCH_SUB_ADMIN, PATCH_SUB_ADMIN_BILLING, PATCH_SUB_ADMIN_USER, GET_SUBSCRIPTION, GET_SEATS]),
    async prepareGetSubscriptionUsers () {
      try {
        await this.GET_SUBSCRIPTION_USERS()
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('common.subscription.errors.getSubscriptionUsersError'),
        })
      }
    },
    onExpandClick (user) {
      if (this.expanded.length === 0) {
        this.expanded.push(user)
      } else if (this.expanded[0].id === user.id) {
        this.expanded.splice(0, 1)
      } else {
        this.expanded.splice(0, 1)
        this.expanded.push(user)
      }
    },
    async onSubAdminChange (item) {
      try {
        await this.PATCH_SUB_ADMIN({
          userId: item.id,
          data: {
            userIsAdmin: !item.isSubAdmin,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('subscriptions.views.SubscriptionSubscribers.patchSubAdminSuccess'),
        })
      } catch (error) {
        if (error.response?.data?.errorCode === 'ERR_INCORRECT_DATA') {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('patchSubAdminERR_INCORRECT_DATA'),
          })
        } else {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('subscriptions.views.SubscriptionSubscribers.patchSubAdminError'),
          })
        }
      }
    },
    async onSubAdminBillingChange (item) {
      try {
        await this.PATCH_SUB_ADMIN_BILLING({
          userId: item.id,
          data: {
            userIsBillingAdmin: !item.isSubBillingAdmin,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('subscriptions.views.SubscriptionSubscribers.patchSubAdminSuccess'),
        })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('subscriptions.views.SubscriptionSubscribers.patchSubAdminError'),
        })
      }
    },
    onSubAdminUserChange (item) {
      if (!item.isSubUser) {
        this.GET_SEATS()
          .then(() => { this.initSeatsData(this.seats) })
          .catch((error) => {
            console.error(error)
            this.subAddConfirmationDialogText = this.$t('common.subscription.errors.getSeatsError')
          })
        this.subAddConfirmationDialogInfo = item
      } else {
        this.subRemoveConfirmationDialogInfo = item
      }
    },
    confirmSubAddConfirmationDialog () {
      const infos = this.subAddConfirmationDialogInfo
      this.subAddConfirmationDialogInfo = null
      this.prepareSubAdminUserChange(infos)
    },
    confirmSubRemoveConfirmationDialog () {
      const infos = this.subRemoveConfirmationDialogInfo
      this.subRemoveConfirmationDialogInfo = null
      this.prepareSubAdminUserChange(infos)
    },
    async prepareSubAdminUserChange (item) {
      try {
        // We invert the state here because it's the current state and not the new one
        // This is because of the dialog in-between state changes
        await this.PATCH_SUB_ADMIN_USER({
          userId: item.id,
          data: {
            userIsSubUser: !item.isSubUser,
            confirmMonthly: item.confirmMonthly,
            confirmYearly: item.confirmYearly,
          },
        })
        this.GET_SUBSCRIPTION(this.profile.subscription.id)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('subscriptions.views.SubscriptionSubscribers.patchSubAdminSuccess'),
        })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('subscriptions.views.SubscriptionSubscribers.patchSubAdminError'),
        })
      }
    },
    initSeatsData (seats) {
      if (seats.billingSeatsAvailable <= 0 && (seats.nextYearlyPrice > 0 || seats.nextMonthlyPrice > 0)) {
        let price
        if (seats.nextYearlyPrice) {
          price = seats.nextYearlyPrice
          this.subAddConfirmationDialogInfo.confirmYearly = true
          this.subAddConfirmationDialogInfo.confirmMonthly = false
        } else {
          price = seats.nextMonthlyPrice
          this.subAddConfirmationDialogInfo.confirmMonthly = true
          this.subAddConfirmationDialogInfo.confirmYearly = false
        }
        this.subAddConfirmationDialogText = this.$t('subscriptions.views.SubscriptionSubscribers.licensePrice', { price })
      } else {
        this.subAddConfirmationDialogText = this.$t('subscriptions.views.SubscriptionSubscribers.licenseIncluded')
      }
    },
    cancelSubAddConfirmationDialog () {
      this.subAddConfirmationDialogInfo = null
    },
    cancelSubRemoveConfirmationDialog () {
      this.subRemoveConfirmationDialogInfo = null
    },
    closeDeleteUserDialog () {
      this.userToDelete = null
      this.deleteUserDialogIsOpen = false
    },
    openDeleteUserDialog (user) {
      this.userToDelete = user
      this.deleteUserDialogIsOpen = true
    },
    onPostNewSubscriberSuccess () {
      this.GET_SUBSCRIPTION(this.profile.subscription.id)
      this.prepareGetSubscriptionUsers()
    },
    userDeleteSuccess () {
      this.prepareGetSubscriptionUsers()
      this.closeDeleteUserDialog()
    },
    setPagination (nbItemsPerPage) {
      const files = this.filteredSubscriptionUsers
      const nbPages = Math.ceil(files.length / nbItemsPerPage)
      this.dataTablePage = 1
      this.dataTablePageCount = nbPages > 0 ? nbPages : 1
      if (this.dataTablePageCount === 1) {
        this.disabledPagination = true
      } else {
        this.disabledPagination = false
      }
    },
    onPageChange (page) {
      this.$refs.SubscriptionSubscribersTable.$options.propsData.options.page = page
    },
  },
}
</script>

<style scoped lang="scss">
.SubscriptionSubscribers-card {
  background-color: #fff;
  padding: 14px 16px;
}
::v-deep .SubscriptionSubscribers-table {
  background-color: transparent;
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }
  th {
    color: var(--v-accent-base) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    border-bottom: none !important;
  }
  td {
    color: var(--v-accent-base) !important;
    font-size: 14px !important;
    border-bottom: none !important;
    vertical-align: baseline;
    height: 48px !important;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  tbody {
    tr {
      background-color: #fff;
      &.errorBorder {
        td {
          border-top: 1px solid var(--v-error-base) !important;
          border-bottom: 1px solid var(--v-error-base) !important;
          &:first-child {
            border-left: 1px solid var(--v-error-base) !important;
          }
          &:last-child {
            border-right: 1px solid var(--v-error-base) !important;
          }
        }
      }
      &.selectedInvoice {
        td {
          background-color: var(--v-primary-lighten4);
        }
      }
    }
    tr.v-data-table__expanded__content {
      box-shadow: none;
    }
  }
  .va-inherit {
    vertical-align: inherit;
  }
}
.SubscriptionSubscribers-expandIcon {
  transition-duration: 400ms;
  transition-property: transform;
}
.SubscriptionSubscribers-expandIcon--open {
  transform: rotate(90deg);
}
.SubscriptionSubscribers-table-firstName {
  max-width: 100px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.SubscriptionSubscribers-table-lastName {
  max-width: 110px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.SubscriptionSubscribers-table-email {
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .v-data-footer__icons-before {
  display: none;
}

::v-deep .v-data-footer__icons-after {
  display: none;
}
::v-deep .v-data-footer {
  flex-direction: row-reverse;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}
</style>
