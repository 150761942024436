<template>
  <div class="filters-row">
    <app-filter v-for="(filter, i) in filters"
            :key="i"
            :filter="filter"
            class="px-4"
            @clear="clearFilter(filter)"
    >
    </app-filter>

    <app-button
      v-if="hasMultipleActiveFilters"
      size="medium"
      type="ghost"
      variant="neutral"
      @click="clearAllFilters"
    >
      {{ $t('clearFilters') }}
    </app-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { FilterType } from '@/models/filter.model'

export default defineComponent({
  name: 'AppFilterRow',
  computed: {
    hasMultipleActiveFilters (): boolean {
      return !!(this.filters.find((filter) => filter.value.key.length !== 0))
    },
  },
  props: {
    /**
     * Filters to display
     * @type {Filter[]}
     * @example { category: { key: 'author', label: 'Auteur' }, value: { key: 1, label: 'Bruno Thomas' }, options: [], type: 'checkbox' }
     * @default []
     */
    filters: {
      type: Array as PropType<Array<FilterType>>,
      default: () => [],
    },
  },
  methods: {
    clearFilter (filter): void {
      this.$emit('clear-filter', filter)
    },
    clearAllFilters (): void {
      this.$emit('clear-all-filters')
    },
  },
})
</script>

<style scoped lang="scss">
.filters-row {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;

  & ::v-deep> *:not(:last-child) {
    margin-right: 1rem;
  }
}
</style>

<i18n>
{
  "de": {
    "clearFilters": "Zurücksetzen"
  },
  "en": {
    "clearFilters": "Clear"
  },
  "fr": {
    "clearFilters": "Réinitialiser"
  }
}
</i18n>

<docs>
  ```vue
  <template>
    <div>
      <AppFilterRow :filters="filters" @clear-all-filters="clearAll" />
    </div>
  </template>
  <script>
    import { Filter, FilterTypes } from '../../models/filter.model'
    export default {
      data () {
        return {
          filters: [
            new Filter(
              'authorTest',
              'Auteur',
              [1],
              'Bruno Thomas',
              FilterTypes.CHECKBOX,
              [
                { label: 'Bruno Thomas', value: 1 },
                { label: 'Thomas Bruno', value: 2 }
              ]
            ),
            new Filter(
              'dateTest',
              'Date début',
              [],
              '',
              FilterTypes.DATE,
            ),
          ],
        }
      },
      methods: {
        clearAll () {
          this.filters = this.filters.map((filter) => { return { ...filter, value: { key: [], label: '' } } })
        },
      }
    }
  </script>
  ```
</docs>
