import Vue from 'vue'

import AppAlertInPage from '@/design-system/alert/AppAlertInPage.vue'
import AppBanner from '@/design-system/alert/AppBanner.vue'
import AppSegmentedControl from '@/design-system/AppSegmentedControl.vue'
import AppBadge from '@/design-system/badge/AppBadge.vue'
import AppButton from '@/design-system/buttons/AppButton.vue'
import AppButtonAlert from '@/design-system/buttons/AppButtonAlert.vue'
import AppDropdownButton from '@/design-system/buttons/AppDropdownButton.vue'
import FileSizeDisplay from '@/design-system/display/FileSizeDisplay.vue'
import AppFilter from '@/design-system/filter/AppFilter.vue'
import AppFilterRow from '@/design-system/filter/AppFilterRow.vue'
import AppIcon from '@/design-system/icons/AppIcon.vue'
import AppIconBadge from '@/design-system/icons/AppIconBadge.vue'
import AppToggle from '@/design-system/inputs/AppToggle.vue'
import AppTreeviewItem from '@/design-system/treeview/AppTreeviewItem.vue'
import AppHeadline from '@/design-system/typography/AppHeadline.vue'
import AppText from '@/design-system/typography/AppText.vue'

export function globallyRegisterDesignSystemComponents () {
  // alert
  Vue.component('app-alert-in-page', AppAlertInPage)
  Vue.component('app-banner', AppBanner)

  // buttons
  Vue.component('app-button', AppButton)
  Vue.component('app-button-alert', AppButtonAlert)
  Vue.component('app-dropdown-button', AppDropdownButton)
  Vue.component('app-segmented-control', AppSegmentedControl)

  // badge
  Vue.component('app-badge', AppBadge)

  // display
  Vue.component('app-filesize-display', FileSizeDisplay)

  // icons
  Vue.component('app-icon', AppIcon)
  Vue.component('app-icon-badge', AppIconBadge)

  // inputs
  Vue.component('app-toggle', AppToggle)

  // treeview
  Vue.component('app-treeview-item', AppTreeviewItem)

  // typography
  Vue.component('app-text', AppText)
  Vue.component('app-headline', AppHeadline)

  // filters
  Vue.component('app-filter-row', AppFilterRow)
  Vue.component('app-filter', AppFilter)
}
