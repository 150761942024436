export const DISABLE_MEMBERS = 'DISABLE_MEMBERS'
export const HIGHLIGHT_MEMBER = 'HIGHLIGHT_MEMBER'
export const INIT_SELECTED_GROUPS = 'INIT_SELECTED_GROUPS'
export const INIT_SELECTED_MEMBERS = 'INIT_SELECTED_MEMBERS'
export const RESET_DISABLED_MEMBERS = 'RESET_DISABLED_MEMBERS'
export const RESET_SELECTED_GROUPS_AND_MEMBERS = 'RESET_SELECTED_GROUPS_AND_MEMBERS'
export const SELECT_GROUP = 'SELECT_GROUP'
export const SELECT_MEMBER = 'SELECT_MEMBER'
export const UNSELECT_GROUP = 'UNSELECT_GROUP'
export const UNSELECT_MEMBER = 'UNSELECT_MEMBER'
