<template>
    <div v-intersect.quiet="{ handler: onIntersect }"
         class="PrepareSignatureThumb"
         @mouseenter="displayPageNumber = true"
         @mouseleave="displayPageNumber = false"
    >
      <template v-if="thumbData">
        <v-img :src="`data:image/png;base64,${thumbData}`"
               :width="100"
               :height="142"
               class="PrepareSignatureThumb-thumb mx-auto"
               :class="{'PrepareSignatureThumb-thumb--active': isActivePage}"
               @click="$emit('scroll-to', pageNumber)"
        />
        <font-awesome-icon v-if="hasTag"
                           class="PrepareSignatureThumb-hasTag"
                           :icon="['fas', 'bookmark']"
        ></font-awesome-icon>
        <div v-if="displayPageNumber"
             class="PrepareSignatureThumb-pageNumber"
             @click="$emit('scroll-to', pageNumber)"
        >
          {{ pageNumber }}
        </div>
      </template>
      <template v-else>
        <div class="PrepareSignatureThumb-placeholder mx-auto"
             :class="{'PrepareSignatureThumb-placeholder--active': isActivePage}"
             @click="$emit('scroll-to', pageNumber)"
        >
          <div class="PrepareSignatureThumb-placeholderContent">
            <font-awesome-icon :icon="['far', 'ellipsis']"  class="text--primary"></font-awesome-icon>
          </div>
        </div>
      </template>
    </div>
</template>

<script>
export default {
  name: 'PrepareSignatureThumb',
  props: {
    isActivePage: {
      type: Boolean,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    pageThumb: {
      type: Object,
      default: () => {},
    },
    pagesTags: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      displayPageNumber: false,
    }
  },
  computed: {
    thumbData () {
      return this.pageThumb?.data
    },
    hasTag () {
      if (this.pagesTags.length > 0) {
        return this.pagesTags.some(tag => tag.pageNumber === this.pageNumber)
      }
      return false
    },
  },
  methods: {
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$emit('thumb-visible')
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .PrepareSignatureThumb {
    position: relative;
  }
  .PrepareSignatureThumb-placeholder {
    background-color: #fff;
    position: relative;
    width: 100px;
    height: 142px;
    border: 1px solid #979797;
    cursor: pointer;
    &--active {
      border-color: var(--v-primary-base);
      border-width: 2px;
    }
  }
  .PrepareSignatureThumb-placeholderContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .PrepareSignatureThumb-thumb {
    border: 1px solid #979797;
    cursor: pointer;
    &--active {
      border-color: var(--v-primary-base);
      border-width: 2px;
    }
  }
  .PrepareSignatureThumb-hasTag {
    position: absolute;
    font-size: 32px;
    top: 10px;
    left: -11px;
    color: #ffc700;
  }
  .PrepareSignatureThumb-pageNumber {
    color: var(--v-grey-base);
    cursor: pointer;
    font-size: 60px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
