export const SET_SIGNING_VIEW_PENDING = 'SET_SIGNING_VIEW_PENDING'
export const SET_SIGNING_VIEW = 'SET_SIGNING_VIEW'
export const SET_SIGNING_VIEW_ERROR = 'SET_SIGNING_VIEW_ERROR'
export const ADD_PAGES_NUMBER_PENDING = 'ADD_PAGES_NUMBER_PENDING'
export const REMOVE_PAGES_NUMBER_PENDING = 'REMOVE_PAGES_NUMBER_PENDING'
export const SET_PAGES = 'SET_PAGES'
export const SET_PAGES_ERROR = 'SET_PAGES_ERROR'
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE'
export const SET_SCROLL_TO = 'SET_SCROLL_TO'
export const SET_SIGN_TAG_PENDING = 'SET_SIGN_TAG_PENDING'
export const REMOVE_SIGN_TAG_PENDING = 'REMOVE_SIGN_TAG_PENDING'
export const SET_SIGN_TAG_ERROR = 'SET_SIGN_TAG_ERROR'
export const SET_UNSIGN_TAG_PENDING = 'SET_UNSIGN_TAG_PENDING'
export const REMOVE_UNSIGN_TAG_PENDING = 'REMOVE_UNSIGN_TAG_PENDING'
export const SET_UNSIGN_TAG_ERROR = 'SET_UNSIGN_TAG_ERROR'
export const REFRESH_TAG = 'REFRESH_TAG'
export const SET_VALIDATION_MODAL_IS_OPEN = 'SET_VALIDATION_MODAL_IS_OPEN'
export const SET_SEND_VALIDATION_SMS_PENDING = 'SET_SEND_VALIDATION_SMS_PENDING'
export const SET_SEND_VALIDATION_SMS_ERROR = 'SET_SEND_VALIDATION_SMS_ERROR'
export const SET_SIGN_DOCUMENT_PENDING = 'SET_SIGN_DOCUMENT_PENDING'
export const SET_SIGN_DOCUMENT_ERROR = 'SET_SIGN_DOCUMENT_ERROR'
export const SET_RESEND_VALIDATION_SMS_PENDING = 'SET_RESEND_VALIDATION_SMS_PENDING'
export const SET_RESEND_VALIDATION_SMS_ERROR = 'SET_RESEND_VALIDATION_SMS_ERROR'
export const SET_CONFIRM_SIGNATURE_PENDING = 'SET_CONFIRM_SIGNATURE_PENDING'
export const SET_CONFIRM_SIGNATURE_ERROR = 'SET_CONFIRM_SIGNATURE_ERROR'
export const RESET = 'RESET'
