<template>
  <v-row ref="ProjectsFoldersTemplate">
    <v-col v-for="folder in folders"
           :key="folder.id"
           cols="12"
           sm="6"
           md="3"
           lg="3"
           xl="2"
    >
      <span :draggable="folder.maxDepth < FOLDER_MAX_DEPTH"
            @dragstart="dragProjectsFolderStart($event, folder)"
            @dragend="dragProjectsFolderEnd($event, folder)"
      >
        <ProjectsFoldersGrid :ref="`projectFolderGrid${folder.id}`" :folder="folder" />
        <ProjectsGridGhost :ref="`projectFolderGhost${folder.id}`" :item="folder" isFolder/>
      </span>
    </v-col>
  </v-row>
</template>

<script>
  import { mapMutations } from 'vuex'

  import { FOLDER_MAX_DEPTH } from '@/common/constants'
  import { DARK_BLUE_50, DARK_BLUE_500 } from '@/common/utils/colors'
  import { SET_PROJECTS_FOLDER_TO_MOVE } from '@/store/modules/projects-folders-move/mutation_types'

  import ProjectsFoldersGrid from './ProjectsFoldersGrid.vue'
  import ProjectsGridGhost from './ProjectsGridGhost.vue'
  export default {
    name: 'ProjectsFoldersTemplate',
    components: {
      ProjectsFoldersGrid,
      ProjectsGridGhost,
    },
    props: {
      folders: {
        type: Array,
        validator: (item) => typeof item === 'object' || item === null,
      },
    },
    data () {
      return {
        currentGhost: null,
        DARK_BLUE_50,
        DARK_BLUE_500,
        FOLDER_MAX_DEPTH,
      }
    },
    methods: {
      ...mapMutations('projectsFoldersMove', [SET_PROJECTS_FOLDER_TO_MOVE]),
      dragProjectsFolderStart (event, folder) {
        if (folder.maxDepth === FOLDER_MAX_DEPTH) return
        const projectFolderGrid = this.$refs[`projectFolderGrid${folder.id}`][0].$el
        if (projectFolderGrid) {
          projectFolderGrid.classList.add('folderIsDragging')
        }
        const ghost = this.$refs[`projectFolderGhost${folder.id}`][0].$el.cloneNode(true)
        this.currentGhost = ghost
        if (this.currentGhost) {
          ghost.style.display = 'inline-flex'
          this.$refs.ProjectsFoldersTemplate.appendChild(ghost)
          event.dataTransfer.setDragImage(ghost, 0, 0)
        }
        this.SET_PROJECTS_FOLDER_TO_MOVE(folder)
      },
      dragProjectsFolderEnd (event, folder) {
        if (folder.maxDepth === FOLDER_MAX_DEPTH) return
        const projectFolderGrid = this.$refs[`projectFolderGrid${folder.id}`][0].$el
        if (projectFolderGrid) {
          projectFolderGrid.classList.remove('folderIsDragging')
        }
        if (this.currentGhost) {
          this.$refs.ProjectsFoldersTemplate.removeChild(this.currentGhost)
        }
        this.SET_PROJECTS_FOLDER_TO_MOVE({})
      },
    },
  }
</script>

<style scoped>
  ::v-deep .folderIsDragging div.AppCard {
    background-color: v-bind(DARK_BLUE_50);
    border: 2px solid v-bind(DARK_BLUE_500);
  }
</style>
