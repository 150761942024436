<template>
<v-menu v-model="menu"
        :close-on-content-click="false"
        v-bind="$attrs"
>
  <template v-slot:activator="{ on, attrs }">
    <slot name="activator"
          :attrs="attrs"
          :on="on"
    />
  </template>
  <AppCard :min-width="cardMinWidth"
           :max-width="cardMaxWidth"
           class="pa-0"
  >
    <slot name="default"
          :closeMenu="closeMenu"
    ></slot>
  </AppCard>
</v-menu>
</template>

<script>
import AppCard from './AppCard'
export default {
  name: 'AppMenuAsPopover',
  components: { AppCard },
  props: {
    cardMinWidth: {
      type: [Number, String],
      default: 200,
    },
    cardMaxWidth: {
      type: [Number, String],
      default: '100%',
    },
  },
  data () {
    return {
      menu: false,
    }
  },
  watch: {
    menu (newValue) {
      if (newValue) {
        this.$emit('open')
      }
      this.$emit('menu-state-change', newValue)
    },
  },
  methods: {
    closeMenu () {
      this.menu = false
    },
  },
}
</script>
