<template>
  <v-alert class="document-versioning-duplicate-box" style="background-image: url('/img/m365-background.png')"
            close-icon="far fa-xmark"
            dismissible
  >
    <app-text variant="medium-bold">{{ $t(`${TRANSLATION_KEY_PREFIX}.title`) }} ⚡</app-text>
    <app-text variant="small-regular" class="mt-3">{{ $t(`${TRANSLATION_KEY_PREFIX}.text`) }}</app-text>
    <v-checkbox v-model="hideBox"
                :label="$t(`${TRANSLATION_KEY_PREFIX}.checkboxLabel`)"
                hide-details
                @change="onHideBoxChange"
    ></v-checkbox>
  </v-alert>
</template>

<script>
const TRANSLATION_KEY_PREFIX = 'common.document-versioning.DocumentVersioningWopiDuplicationBox'

export default {
  name: 'DocumentVersioningWopiDuplicationBox',
  emits: ['hide'],
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
      hideBox: false,
    }
  },
  methods: {
    onHideBoxChange (value) {
      this.$emit('hide', value)
    },
  },
}
</script>

<style scoped lang="scss">
.document-versioning-duplicate-box {
  background-size: cover;
  background-position: center right;
  border: 1px solid var(--v-grey-lighten4);
  border-radius: 12px;
  box-shadow: 0px 0px 12px 0px #444A5014 !important;
  margin-bottom: 0;
  padding: 16px;
  }

::v-deep .v-alert__dismissible {
  margin: -4px -4px auto 0;

  i.v-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
}
</style>
