<template>
  <v-progress-circular color="primary"
                       :size="size"
                       :width="5"
                       :indeterminate="indeterminate"
  />
</template>

<script>
/**
 * Default Loader
 * @displayName AppLoader
 */
export default {
  name: 'AppLoader',
  props: {
    /**
     * Set the indeterminate prop of v-progress-circular component
     * @see https://vuetifyjs.com/en/api/v-progress-circular/#props-indeterminate
     */
    indeterminate: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    size () {
      return this.small ? 50 : 80
    },
  },
}
</script>

<docs>
```vue
<template>
  <AppLoader indeterminate/>
</template>
```
</docs>
