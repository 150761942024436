<template>
  <SendByEmailDialog :is-open="isDialogOpened"
                     :cancelDisabled="sendArchivesPending"
                     :okLoading="sendArchivesPending"
                     :title="$tc('archives.dialogs.ArchivesSendDialog.title', selectedDocuments.length)"
                     @cancel="closeDialog"
                     @send="sendArchives"
  />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import SendByEmailDialog from '@/common/dialogs/SendByEmailDialog'
import { dialogMixin } from '@/mixins/dialogMixin'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'ArchivesSendDialog',
  components: { SendByEmailDialog },
  mixins: [dialogMixin],
  computed: {
    ...mapState('archives', ['selectedDocuments', 'sendArchivesPending']),
    selectedArchivesFilesIds () {
      return this.selectedDocuments.map(doc => doc.id)
    },
  },
  methods: {
    ...mapActions('archives', ['SEND_ARCHIVES']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async sendArchives (emails) {
      if (!emails) return

      try {
        await this.SEND_ARCHIVES({
          params: {
            archivesFilesIds: this.selectedArchivesFilesIds,
            emails: emails,
          },
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('archives.dialogs.ArchivesSendDialog.sendSuccess'))
        this.closeDialog()
      } catch (error) {
        const errorMessage = error.response?.data?.errorCode === 'ERR_INVALID_RIGHTS' ? 'sendInvalidRightsError' : 'sendError'
        this.ENQUEUE_ERROR_SNACKBAR(this.$t(`archives.dialogs.ArchivesSendDialog.${errorMessage}`))
      }
    },
  },
}
</script>
