<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             :persistent="true"
             :ok-text="$t('common.download')"
             :ok-disabled="!canDownload"
             :ok-loading="downloadPendingState"
             @ok="preparePostDownload"
             @cancel="closeDialog"
  >
    <template #title>
      <span>{{ $t('project.documents.dialogs.DocumentsDownloadFilesDialog.dialogTitle') }}</span>
    </template>
    <template #body>
      <v-row v-if="!loading && hasAlerts"
        class="mb-4"
      >
        <v-col>
          <template v-if="selectedDocumentsSize > SIZE_LIMIT">
            <app-alert-in-page class="pa-5 mb-2" :dismissible="false">
              <i18n path="project.documents.dialogs.DocumentsDownloadFilesDialog.caption" tag="span"><app-filesize-display :value="SIZE_LIMIT"/></i18n>
            </app-alert-in-page>
            <app-alert-in-page class="pa-5" :class="{ 'mb-2': hasAtLeastOneEmptyFolder }" :dismissible="false">
              <i18n v-if="currentRoom.isPM || currentRoom.userRights.canCreateBibles"
                    path="project.documents.dialogs.DocumentsDownloadFilesDialog.captionActionBible"
                    tag="label"
                    for="action"
              >
                <template #action>
                  <a
                    href="#"
                    class="text-decoration-underline"
                    @click="openBibleGenerateDialog = true"
                  >{{ $t('project.documents.dialogs.DocumentsDownloadFilesDialog.createBible') }}</a>

                </template>
                <template #fileSize><app-filesize-display :value="SIZE_LIMIT"/></template>
              </i18n>
              <i18n v-else
                    path="project.documents.dialogs.DocumentsDownloadFilesDialog.captionActionRequestAuthorization"
                    tag="label"
                    for="action"
              >
                <template #action>
                  <a
                    href="#"
                    class="text-decoration-underline"
                    @click="requestAuthorizationBible"
                  >{{ $t('project.documents.dialogs.DocumentsDownloadFilesDialog.requestAuthorization') }}</a>

                </template>
                <template #fileSize><app-filesize-display :value="SIZE_LIMIT"/></template>
              </i18n>
            </app-alert-in-page>
          </template>
          <app-alert-in-page v-if="hasAtLeastOneEmptyFolder" class="pa-5" :dismissible="false">
            {{ $t('project.documents.dialogs.DocumentsDownloadFilesDialog.warningSelectEmptyFolders') }}
          </app-alert-in-page>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h4">
          {{ $t('project.documents.dialogs.DocumentsDownloadFilesDialog.yourSelection') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-list class="listContent">
            <template v-for="(document, i) in selectedDocuments">
              <v-list-item :key="`doc-${i}`" class="px-0">
                <v-list-item-icon>
                  <ClosdFildersIcon :document="document"/>
                </v-list-item-icon>
                <v-list-item-content>
                  <div class="d-flex justify-space-between">
                    <span>{{ getDisplayName(document) }}</span>
                    <template v-if="loading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="ml-4"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      <app-filesize-display :value="getDocumentSize(i)" :default-value="0"/>
                    </template>
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <AppButton
                    icon
                    class="delete-button"
                    @click="removeDocument(i)"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'trash']"
                    ></font-awesome-icon>
                  </AppButton>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="i !== selectedDocuments.length"
                :key="`divider-${i}`"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
      <v-row class="my-4">
        <v-col>
          <div class="d-flex justify-space-between">
          <span class="text-h4">{{
              $tc('project.documents.dialogs.DocumentsDownloadFilesDialog.selectedItems', selectedDocuments.length, { selectedItems: selectedDocuments.length })
            }}</span>
            <div>
              <span>{{ $tc('project.documents.dialogs.DocumentsDownloadFilesDialog.selectionSize') }}</span>
              <template v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-4"
                ></v-progress-circular>
              </template>
              <template v-else>
                <span class="ml-4 text-h4"><app-filesize-display :value="selectedDocumentsSize" :default-value="0"/></span>
              </template>
            </div>
          </div>
        </v-col>
      </v-row>

    </template>

    <BibleGenerateDialog v-if="openBibleGenerateDialog"
                         :mnemo="mnemo"
                         button-icon
                         :initialTab="initialTab"
                         :initialSelectedDocuments="initialSelectedDocuments"
                         :initialSelectedTodos="initialSelectedTodos"
                         v-on="$listeners"
                         @close="openBibleGenerateDialog = false"
    />
  </AppDialog>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon'
import { downloadFileObject } from '@/common/utils/files'
import { flatten } from '@/common/utils/flatArray'
import { dialogMixin } from '@/mixins/dialogMixin'
import BibleGenerateDialog from '@/project/bibles/dialogs/BibleGenerateDialog'
import { BIBLE_GENERATE_DIALOG_TABS } from '@/project/bibles/dialogs/BibleGenerateDialog.vue'
import { DOWNLOAD_TODO_FILES } from '@/store/modules/checklist/action_types'
import { POST_REQUEST_AUTHORIZATION_BIBLE } from '@/store/modules/documents/action_types'
import {
  GET_SELECTED_DOCUMENTS_IN_DEEP,
  POST_DOWNLOAD,
  REMOVE_DOCUMENTS_DOWNLOAD_FILES,
} from '@/store/modules/documents-download-files/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

/**
 * Size limit in byte
 * @type {number}
 */
const SIZE_LIMIT = 209715200

export const FILE_TYPES = {
  DOCUMENT: 'document',
  TODO: 'todo',
  SIGNED: 'signed',
}

const VALID_FILE_TYPES = Object.values(FILE_TYPES)

export default {
  name: 'DocumentsDownloadFilesDialog',
  components: {
    AppButton,
    AppDialog,
    ClosdFildersIcon,
    BibleGenerateDialog,
  },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    // The type of files downloaded
    fileType: {
      type: String,
      default: FILE_TYPES.DOCUMENT,
      validator (value) {
        return VALID_FILE_TYPES.includes(value)
      },
    },
  },
  data () {
    return {
      openBibleGenerateDialog: false,
      SIZE_LIMIT,
    }
  },
  computed: {
    ...mapState('documents', ['documents']),
    ...mapState('documentsDownloadFiles', ['loading', 'selectedDocuments', 'downloadPending']),
    ...mapState('checklist', ['downloadTodoFilesPending']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('documentsDownloadFiles', ['selectedDocumentsSize', 'selectedDocumentsFileIds', 'selectedDocumentsFolderIds', 'hasAtLeastOneEmptyFolder']),
    ...mapState('documentsBreadcrumb', ['breadcrumb']),
    bearerToken () {
      return axios.defaults.headers.common.Authorization
    },
    downloadAction () {
      return this.fileType === FILE_TYPES.TODO
        ? this.DOWNLOAD_TODO_FILES
        : this.POST_DOWNLOAD
    },
    downloadPendingState () {
      return this.fileType === FILE_TYPES.TODO
        ? this.downloadTodoFilesPending
        : this.downloadPending
    },
    canDownload () {
      return !this.loading &&
        this.selectedDocuments.length !== 0 &&
        this.selectedDocumentsSize < SIZE_LIMIT
    },
    initialTab () {
      if (this.fileType === FILE_TYPES.TODO) {
        return BIBLE_GENERATE_DIALOG_TABS.TODOS
      } else if (this.fileType === FILE_TYPES.SIGNED) {
        return BIBLE_GENERATE_DIALOG_TABS.SIGNATURES
      } else {
        return BIBLE_GENERATE_DIALOG_TABS.DOCUMENTS
      }
    },
    initialSelectedDocuments () {
      return this.fileType === FILE_TYPES.DOCUMENT
        ? this.selectedDocuments
        : []
    },
    initialSelectedTodos () {
      return this.fileType === FILE_TYPES.TODO
        ? this.selectedDocuments
        : []
    },
    hasAlerts () {
      return this.selectedDocumentsSize > SIZE_LIMIT || this.hasAtLeastOneEmptyFolder
    },
  },
  mounted () {
    this.prepareSelectedDocuments()
  },
  methods: {
    ...mapActions('documentsDownloadFiles', [
      REMOVE_DOCUMENTS_DOWNLOAD_FILES,
      GET_SELECTED_DOCUMENTS_IN_DEEP,
      POST_DOWNLOAD,
    ]),
    ...mapActions('documents', [POST_REQUEST_AUTHORIZATION_BIBLE]),
    ...mapActions('checklist', [DOWNLOAD_TODO_FILES]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async prepareSelectedDocuments () {
      try {
        // call API in order to get childrens data
        await this.GET_SELECTED_DOCUMENTS_IN_DEEP(this.mnemo)
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsDownloadFilesDialog.getSelectedDocumentsInDeepError'))
      }
    },
    getDocumentSize (index) {
      const document = this.selectedDocuments[index]

      if (document.type === 'file') {
        return document.size
      }

      // For folders, first flatten children array and sum only files sizes
      const folderDocuments = flatten([], document.children)
      return folderDocuments.reduce(
        function (accSum, item) {
          return item.type === 'file' ? accSum + item.size : accSum
        },
        0)
    },
    async preparePostDownload () {
      try {
        const response = await this.downloadAction({
          mnemo: this.mnemo,
          data: {
            fileIds: this.selectedDocumentsFileIds,
            folderIds: this.selectedDocumentsFolderIds,
          },
        })
        downloadFileObject(new File(
          [response],
          this.getFilename(),
          { type: 'application/zip' },
        ))
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.documents.dialogs.DocumentsDownloadFilesDialog.postDownloadSuccess'))
      } catch (error) {
        let message = 'errorUnknown'

        const data = await this.getJsonFromBlobResponse(error.response)
        if (data?.errorCode && (
          error.request.status === 400 ||
          error.request.status === 403 ||
          error.request.status === 404
        )) {
          message = data.errorCode
        }

        this.ENQUEUE_ERROR_SNACKBAR(this.$t(message))
      } finally {
        this.closeDialog()
      }
    },
    getFilename () {
      const datetime = dayjs().format('YYYY-MM-DD_HH-mm')

      return `${this.$t('project.documents.dialogs.DocumentsDownloadFilesDialog.filename')}-${datetime}.zip`
    },
    async getJsonFromBlobResponse (response) {
      const isJsonBlob = (data) => data instanceof Blob && data.type === 'application/json'
      const responseData = isJsonBlob(response?.data) ? await (response?.data)?.text() : response?.data || {}
      return (typeof responseData === 'string') ? JSON.parse(responseData) : responseData
    },
    removeDocument (index) {
      this.REMOVE_DOCUMENTS_DOWNLOAD_FILES(index)
      if (this.selectedDocuments.length === 0) {
        this.closeDialog()
      }
    },
    async requestAuthorizationBible () {
      try {
        await this.POST_REQUEST_AUTHORIZATION_BIBLE({
          mnemo: this.mnemo,
        })
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.documents.dialogs.DocumentsDownloadFilesDialog.requestAuthorizationSuccess'))
      } catch (error) {
        console.error(error)
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsDownloadFilesDialog.requestAuthorizationError'))
      }
    },
    getDisplayName (document) {
      return document.numbering ? `${document.numbering} ${document.name}` : document.name
    },
  },
}
</script>

<style scoped lang="scss">
.listContent {
  max-height: 300px;
  overflow-y: auto;
}
</style>
