  <template>
  <AppDialog
    :is-open="isDialogOpened"
    size="l"
    :ok-text="$t('common.confirm')"
    :ok-loading="patchNumberingPending"
    :cancel-disabled="patchNumberingPending"
    @ok="onConfirm"
    @cancel="closeDialog"
  >
    <template #title>{{ $t('project.documents.dialogs.DocumentsNumberingDisableDialog.title') }}</template>
    <template #body>
      <p>{{ $t('project.documents.dialogs.DocumentsNumberingDisableDialog.text') }}</p>
    </template>
  </AppDialog>
</template>

<script>
import { mapState } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'

export default {
  name: 'DocumentsNumberingDisableDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('documents', ['patchNumberingPending']),
  },
  methods: {
    async onConfirm () {
      this.$emit('confirm')
    },
  },
}
</script>
