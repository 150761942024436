<template>
  <div>
    <v-row>
      <v-col>
        <AppTextField
          :value="fileName"
          required
          class="required document-name-input"
          :disabled="isEditingLimited"
          :label="$t('project.signing-checklist.add.SigningChecklistSectionDocumentTitle.titleLabel')"
          counter="70"
          maxlength="70"
          :error-messages="titleErrorMessages"
          @change="onTitleChange"
        >
        </AppTextField>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'

import AppTextField from '@/common/AppTextField.vue'

export default defineComponent({
  name: 'SigningChecklistSectionDocumentTitle',
  components: {
    AppTextField,
  },
  props: {
    fileName: {
      type: String,
      required: true,
    },
    isEditingLimited: {
      type: Boolean,
      required: true,
    },
    titleValidator: {
      type: Object,
      required: true,
    },
  },
  computed: {
    titleErrorMessages (): TranslateResult[] {
      if (this.titleValidator.$dirty && !this.titleValidator.required) {
        return [this.$t('common.validations.fieldRequired', { fieldName: this.$t('titleLabel') })]
      }
      return []
    },
  },
  methods: {
    onTitleChange (title: string) {
      this.$emit('title-change', title.trim())
    },
  },
})
</script>
