import { ROOM_ROUTE_TYPES } from '@/router'

/**
 * Get the rooms as an object with properties by role
 * @param {array} rooms The original room object from the back-end
 * @returns {Object} an object with asPM and asGuest properties corresponding to the roles attached to the rooms
 */
function getRoomsByRole (rooms) {
  return rooms.reduce((acc, room) => {
    if (room.isPM) {
      acc.asPM.push(room)
    } else {
      acc.asGuest.push(room)
    }
    return acc
  }, { asPM: [], asGuest: [] })
}

function getRoomColorTheme (room) {
  return room?.isDataroom
    ? 'deep-purple'
    : 'light-blue'
}

function getRoomCount (room, countName) {
  const NO_COUNTER_VALUE = '-'
  const { countsForRoom, countsForUser } = room

  if (countsForRoom?.[countName] >= 0) {
    return countsForRoom[countName]
  }

  if (countsForUser?.[countName] >= 0) {
    return countsForUser[countName]
  }

  return NO_COUNTER_VALUE
}

function getRoomIcon (room) {
  return room?.isDataroom
    ? '/img/project/dataroom-project-category.svg'
    : '/img/project/project-category.svg'
}

/**
 * Get roomType route param
 * @param {Object} room The room object to build route path for
 * @returns {ROOM_ROUTE_TYPES} A string to use as roomType param in the room route path
 */
function getRoomRouteType (room) {
  return room?.isDataroom
    ? ROOM_ROUTE_TYPES.DATAROOM
    : ROOM_ROUTE_TYPES.PROJECT
}

export {
  getRoomsByRole,
  getRoomColorTheme,
  getRoomCount,
  getRoomIcon,
  getRoomRouteType,
}
