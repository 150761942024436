export const ADD_CREATE_SIGNING_CHECKLIST_STEP_PENDING = 'ADD_CREATE_SIGNING_CHECKLIST_STEP_PENDING'
export const ADD_ENVELOPE_DOCUMENTS_TO_UPLOAD_QUEUE = 'ADD_ENVELOPE_DOCUMENTS_TO_UPLOAD_QUEUE'
export const ADD_IMPORTED_FILE = 'ADD_IMPORTED_FILE'
export const ADD_OBSERVERS = 'ADD_OBSERVERS'
export const ADD_RECIPIENTS = 'ADD_RECIPIENTS'
export const ADD_REFRESH_STEP_PENDING = 'ADD_REFRESH_STEP_PENDING'
export const ADD_SIGNER_WITNESSES = 'ADD_SIGNER_WITNESSES'
export const ADD_UPDATE_SIGNING_CHECKLIST_STEP_PENDING = 'ADD_UPDATE_SIGNING_CHECKLIST_STEP_PENDING'
export const ASSIGN_SIGNER_ON_GENERIC_SIGNATURE = 'ASSIGN_SIGNER_ON_GENERIC_SIGNATURE'
export const MARK_QUEUED_DOCUMENT_AS_UPLOADING = 'MARK_QUEUED_DOCUMENT_AS_UPLOADING'
export const REMOVE_CANCEL_STEP_MULTIPLE_PENDING = 'REMOVE_CANCEL_STEP_MULTIPLE_PENDING'
export const REMOVE_CANCEL_STEP_PENDING = 'REMOVE_CANCEL_STEP_PENDING'
export const REMOVE_CREATE_SIGNING_CHECKLIST_STEP_PENDING = 'REMOVE_CREATE_SIGNING_CHECKLIST_STEP_PENDING'
export const REMOVE_DELETE_GROUP_PENDING = 'REMOVE_DELETE_GROUP_PENDING'
export const REMOVE_DELETE_STEP_MULTIPLE_PENDING = 'REMOVE_DELETE_STEP_MULTIPLE_PENDING'
export const REMOVE_DELETE_STEP_PENDING = 'REMOVE_DELETE_STEP_PENDING'
export const REMOVE_DELETE_TITLE_PENDING = 'REMOVE_DELETE_TITLE_PENDING'
export const REMOVE_ENVELOPE_DOCUMENTS_FROM_UPLOAD_QUEUE = 'REMOVE_ENVELOPE_DOCUMENTS_FROM_UPLOAD_QUEUE'
export const REMOVE_FROM_EXPAND_TITLE_AFTER_DELETE = 'REMOVE_FROM_EXPAND_TITLE_AFTER_DELETE'
export const REMOVE_IMPORTED_FILE = 'REMOVE_IMPORTED_FILE'
export const REMOVE_MOVE_GROUP_PENDING = 'REMOVE_MOVE_GROUP_PENDING'
export const REMOVE_MOVE_STEP_PENDING = 'REMOVE_MOVE_STEP_PENDING'
export const REMOVE_OBSERVERS = 'REMOVE_OBSERVERS'
export const REMOVE_RECIPIENTS = 'REMOVE_RECIPIENTS'
export const REMOVE_REFRESH_STEP_PENDING = 'REMOVE_REFRESH_STEP_PENDING'
export const REMOVE_RESET_STEP_MULTIPLE_PENDING = 'REMOVE_RESET_STEP_MULTIPLE_PENDING'
export const REMOVE_RESET_STEP_PENDING = 'REMOVE_RESET_STEP_PENDING'
export const REMOVE_SEND_SIGN_NOW_MULTIPLE_PENDING = 'REMOVE_SEND_SIGN_NOW_MULTIPLE_PENDING'
export const REMOVE_SEND_SIGN_NOW_PENDING = 'REMOVE_SEND_SIGN_NOW_PENDING'
export const REMOVE_SIGNERS = 'REMOVE_SIGNERS'
export const REMOVE_SIGNER_WITNESSES = 'REMOVE_SIGNER_WITNESSES'
export const REMOVE_SIGNING_CHECKLIST_FILTER = 'REMOVE_SIGNING_CHECKLIST_FILTER'
export const REMOVE_STEP_PENDING = 'REMOVE_STEP_PENDING'
export const REMOVE_TITLE_INDENTATION_PENDING = 'REMOVE_TITLE_INDENTATION_PENDING'
export const REMOVE_UPDATE_SIGNING_CHECKLIST_STEP_PENDING = 'REMOVE_UPDATE_SIGNING_CHECKLIST_STEP_PENDING'
export const REMOVE_UPDATE_TITLE_PENDING = 'REMOVE_UPDATE_TITLE_PENDING'
export const REMOVE_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING = 'REMOVE_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING'
export const REMOVE_VALIDATE_THEN_SEND_NOW_PENDING = 'REMOVE_VALIDATE_THEN_SEND_NOW_PENDING'
export const REPLACE_IMPORTED_FILE = 'REPLACE_IMPORTED_FILE'
export const SET_ADD_SELECTED_STEPS = 'SET_ADD_SELECTED_STEPS'
export const SET_ATTACHMENT_FILE_TO_SHARE = 'SET_ATTACHMENT_FILE_TO_SHARE'
export const SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_ERROR = 'SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_ERROR'
export const SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_PENDING = 'SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_PENDING'
export const SET_CANCEL_CLOSING_ERROR = 'SET_CANCEL_CLOSING_ERROR'
export const SET_CANCEL_CLOSING_PENDING = 'SET_CANCEL_CLOSING_PENDING'
export const SET_CANCEL_SCHEDULED_CLOSING_ERROR = 'SET_CANCEL_SCHEDULED_CLOSING_ERROR'
export const SET_CANCEL_SCHEDULED_CLOSING_PENDING = 'SET_CANCEL_SCHEDULED_CLOSING_PENDING'
export const SET_CANCEL_STEP_ERROR = 'SET_CANCEL_STEP_ERROR'
export const SET_CANCEL_STEP_MODAL = 'SET_CANCEL_STEP_MODAL'
export const SET_CANCEL_STEP_MULTIPLE_ERROR = 'SET_CANCEL_STEP_MULTIPLE_ERROR'
export const SET_CANCEL_STEP_MULTIPLE_PENDING = 'SET_CANCEL_STEP_MULTIPLE_PENDING'
export const SET_CANCEL_STEP_PENDING = 'SET_CANCEL_STEP_PENDING'
export const SET_CLOSING_PREDICT = 'SET_CLOSING_PREDICT'
export const SET_CLOSING_PREDICT_ERROR = 'SET_CLOSING_PREDICT_ERROR'
export const SET_CLOSING_PREDICT_PENDING = 'SET_CLOSING_PREDICT_PENDING'
export const SET_CLOSING_STARTED = 'SET_CLOSING_STARTED'
export const SET_CLOSING_SUMMARY = 'SET_CLOSING_SUMMARY'
export const SET_CLOSING_SUMMARY_ERROR = 'SET_CLOSING_SUMMARY_ERROR'
export const SET_CLOSING_SUMMARY_PENDING = 'SET_CLOSING_SUMMARY_PENDING'
export const SET_COMPILE_FINAL_FILE_ERROR = 'SET_COMPILE_FINAL_FILE_ERROR'
export const SET_COMPILE_FINAL_FILE_PENDING = 'SET_COMPILE_FINAL_FILE_PENDING'
export const SET_CREATE_GROUP_ERROR = 'SET_CREATE_GROUP_ERROR'
export const SET_CREATE_SIGNING_CHECKLIST_STEP_ERROR = 'SET_CREATE_SIGNING_CHECKLIST_STEP_ERROR'
export const SET_CREATE_TITLE_ERROR = 'SET_CREATE_TITLE_ERROR'
export const SET_CREATE_TITLE_PENDING = 'SET_CREATE_TITLE_PENDING'
export const SET_CURRENT_TITLE_EDIT = 'SET_CURRENT_TITLE_EDIT'
export const SET_DELETE_COMPILED_FILE_ERROR = 'SET_DELETE_COMPILED_FILE_ERROR'
export const SET_DELETE_COMPILED_FILE_PENDING = 'SET_DELETE_COMPILED_FILE_PENDING'
export const SET_DELETE_GROUP_ERROR = 'SET_DELETE_GROUP_ERROR'
export const SET_DELETE_GROUP_PENDING = 'SET_DELETE_GROUP_PENDING'
export const SET_DELETE_STEP_ERROR = 'SET_DELETE_STEP_ERROR'
export const SET_DELETE_STEP_MODAL = 'SET_DELETE_STEP_MODAL'
export const SET_SWAP_FILES = 'SET_SWAP_FILES'
export const SET_SWAP_FILES_PENDING = 'SET_SWAP_FILES_PENDING'
export const SET_DELETE_STEP_MULTIPLE_ERROR = 'SET_DELETE_STEP_MULTIPLE_ERROR'
export const SET_DELETE_STEP_MULTIPLE_PENDING = 'SET_DELETE_STEP_MULTIPLE_PENDING'
export const SET_DELETE_STEP_PENDING = 'SET_DELETE_STEP_PENDING'
export const SET_DELETE_TITLE_ERROR = 'SET_DELETE_TITLE_ERROR'
export const SET_DELETE_TITLE_MODAL = 'SET_DELETE_TITLE_MODAL'
export const SET_DELETE_TITLE_PENDING = 'SET_DELETE_TITLE_PENDING'
export const SET_DELETE_VALIDATION_ERROR = 'SET_DELETE_VALIDATION_ERROR'
export const SET_DELETE_VALIDATION_PENDING = 'SET_DELETE_VALIDATION_PENDING'
export const SET_DELETION_TYPE = 'SET_DELETION_TYPE'
export const SET_DELETION_TYPE_ERROR = 'SET_DELETION_TYPE_ERROR'
export const SET_DELETION_TYPE_PENDING = 'SET_DELETION_TYPE_PENDING'
export const SET_EDIT_TITLE = 'SET_EDIT_TITLE'
export const SET_EXPAND_ALL_SIGNING_CHECKLIST = 'SET_EXPAND_ALL_SIGNING_CHECKLIST'
export const SET_EXPAND_SIGNING_CHECKLIST = 'SET_EXPAND_SIGNING_CHECKLIST'
export const SET_FILTERS_PANE_IS_OPEN = 'SET_FILTERS_PANE_IS_OPEN'
export const SET_IMPORTED_FILES = 'SET_IMPORTED_FILES'
export const SET_IMPORTED_FILE_DOCUMENT_NAME = 'SET_IMPORTED_FILE_DOCUMENT_NAME'
export const SET_IMPORTED_FILE_ENVELOPE_TYPE = 'SET_IMPORTED_FILE_ENVELOPE_TYPE'
export const SET_IMPORTED_FILE_MERCURY_OPTION = 'SET_IMPORTED_FILE_MERCURY_OPTION'
export const SET_IMPORTED_FILE_SIGNATURE_DATE = 'SET_IMPORTED_FILE_SIGNATURE_DATE'
export const SET_IMPORTED_FILE_SIGNATURE_TYPE = 'SET_IMPORTED_FILE_SIGNATURE_TYPE'
export const SET_IS_PANE_OPENED = 'SET_IS_PANE_OPENED'
export const SET_IS_SENDING_DOCUMENT = 'SET_IS_SENDING_DOCUMENT'
export const SET_MOVE_GROUP_ERROR = 'SET_MOVE_GROUP_ERROR'
export const SET_MOVE_GROUP_PENDING = 'SET_MOVE_GROUP_PENDING'
export const SET_MOVE_STEP_ERROR = 'SET_MOVE_STEP_ERROR'
export const SET_MOVE_STEP_PENDING = 'SET_MOVE_STEP_PENDING'
export const SET_PANE_TAB = 'SET_PANE_TAB'
export const SET_PARENT_CHILDREN = 'SET_PARENT_CHILDREN'
export const SET_PATCH_COMPILED_FILE_ERROR = 'SET_PATCH_COMPILED_FILE_ERROR'
export const SET_PATCH_COMPILED_FILE_PENDING = 'SET_PATCH_COMPILED_FILE_PENDING'
export const SET_POST_FINAL_FILE_ERROR = 'SET_POST_FINAL_FILE_ERROR'
export const SET_POST_FINAL_FILE_PENDING = 'SET_POST_FINAL_FILE_PENDING'
export const SET_POST_LAST_COMPILED_FILE_ERROR = 'SET_POST_LAST_COMPILED_FILE_ERROR'
export const SET_POST_LAST_COMPILED_FILE_PENDING = 'SET_POST_LAST_COMPILED_FILE_PENDING'
export const SET_POST_MOVE_STEP_ERROR = 'SET_POST_MOVE_STEP_ERROR'
export const SET_POST_MOVE_STEP_PENDING = 'SET_POST_MOVE_STEP_PENDING'
export const SET_POST_SIGNED_FILE_ERROR = 'SET_POST_SIGNED_FILE_ERROR'
export const SET_POST_SIGNED_FILE_PENDING = 'SET_POST_SIGNED_FILE_PENDING'
export const SET_POST_VALIDATION_ERROR = 'SET_POST_VALIDATION_ERROR'
export const SET_POST_VALIDATION_PENDING = 'SET_POST_VALIDATION_PENDING'
export const SET_QUALIFIED_DS_WARNING_IS_MUTED = 'SET_QUALIFIED_DS_WARNING_IS_MUTED'
export const SET_REFRESH_CLOSING_STATUS_ERROR = 'SET_REFRESH_CLOSING_STATUS_ERROR'
export const SET_REFRESH_CLOSING_STATUS_PENDING = 'SET_REFRESH_CLOSING_STATUS_PENDING'
export const SET_REFRESH_SIGNING_CHECKLIST = 'SET_REFRESH_SIGNING_CHECKLIST'
export const SET_REFRESH_SIGNING_CHECKLIST_ERROR = 'SET_REFRESH_SIGNING_CHECKLIST_ERROR'
export const SET_REFRESH_SIGNING_CHECKLIST_PENDING = 'SET_REFRESH_SIGNING_CHECKLIST_PENDING'
export const SET_REFRESH_STATUS_ERROR = 'SET_REFRESH_STATUS_ERROR'
export const SET_REFRESH_STATUS_PENDING = 'SET_REFRESH_STATUS_PENDING'
export const SET_REFRESH_STEP = 'SET_REFRESH_STEP'
export const SET_REFRESH_STEP_ERROR = 'SET_REFRESH_STEP_ERROR'
export const SET_REFRESH_STEP_PENDING_IDS = 'SET_REFRESH_STEP_PENDING_IDS'
export const SET_REMOVE_SELECTED_STEPS = 'SET_REMOVE_SELECTED_STEPS'
export const SET_RESET_SIGNATURE_FILES = 'SET_RESET_SIGNATURE_FILES'
export const SET_RESET_SIGNATURE_FILES_ERROR = 'SET_RESET_SIGNATURE_FILES_ERROR'
export const SET_RESET_SIGNATURE_FILES_PENDING = 'SET_RESET_SIGNATURE_FILES_PENDING'
export const SET_RESET_STEP_ERROR = 'SET_RESET_STEP_ERROR'
export const SET_RESET_STEP_MULTIPLE_ERROR = 'SET_RESET_STEP_MULTIPLE_ERROR'
export const SET_RESET_STEP_MULTIPLE_PENDING = 'SET_RESET_STEP_MULTIPLE_PENDING'
export const SET_RESET_STEP_PENDING = 'SET_RESET_STEP_PENDING'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SET_SEARCH_QUERY_PENDING = 'SET_SEARCH_QUERY_PENDING'
export const SET_SELECTED_STEPS = 'SET_SELECTED_STEPS'
export const SET_SEND_SIGN_NOW_ERROR = 'SET_SEND_SIGN_NOW_ERROR'
export const SET_SEND_SIGN_NOW_MULTIPLE_ERROR = 'SET_SEND_SIGN_NOW_MULTIPLE_ERROR'
export const SET_SEND_SIGN_NOW_MULTIPLE_PENDING = 'SET_SEND_SIGN_NOW_MULTIPLE_PENDING'
export const SET_SEND_SIGN_NOW_PENDING = 'SET_SEND_SIGN_NOW_PENDING'
export const SET_SEND_SIGN_STEP_MODAL = 'SET_SEND_SIGN_STEP_MODAL'
export const SET_SHARE_ATTACHMENT_PENDING = 'SET_SHARE_ATTACHMENT_PENDING'
export const SET_SIGNATURE_FILES = 'SET_SIGNATURE_FILES'
export const SET_SIGNATURE_FILES_ERROR = 'SET_SIGNATURE_FILES_ERROR'
export const SET_SIGNATURE_FILES_PENDING = 'SET_SIGNATURE_FILES_PENDING'
export const SET_SIGNERS_GROUP_ORDER = 'SET_SIGNERS_GROUP_ORDER'
export const SET_SIGNING_CHECKLIST = 'SET_SIGNING_CHECKLIST'
export const SET_SIGNING_CHECKLIST_ERROR = 'SET_SIGNING_CHECKLIST_ERROR'
export const SET_SIGNING_CHECKLIST_FILTERS = 'SET_SIGNING_CHECKLIST_FILTERS'
export const SET_SIGNING_CHECKLIST_GROUP = 'SET_SIGNING_CHECKLIST_GROUP'
export const SET_SIGNING_CHECKLIST_GROUP_ERROR = 'SET_SIGNING_CHECKLIST_GROUP_ERROR'
export const SET_SIGNING_CHECKLIST_GROUP_PENDING = 'SET_SIGNING_CHECKLIST_GROUP_PENDING'
export const SET_SIGNING_CHECKLIST_PENDING = 'SET_SIGNING_CHECKLIST_PENDING'
export const SET_SIGNING_CHECKLIST_STEP = 'SET_SIGNING_CHECKLIST_STEP'
export const SET_SIGNING_VIEW = 'SET_SIGNING_VIEW'
export const SET_SIGNING_VIEW_ERROR = 'SET_SIGNING_VIEW_ERROR'
export const SET_SIGNING_VIEW_PENDING = 'SET_SIGNING_VIEW_PENDING'
export const SET_START_CLOSING_ERROR = 'SET_START_CLOSING_ERROR'
export const SET_START_CLOSING_PENDING = 'SET_START_CLOSING_PENDING'
export const SET_START_SCHEDULED_CLOSING_ERROR = 'SET_START_SCHEDULED_CLOSING_ERROR'
export const SET_START_SCHEDULED_CLOSING_PENDING = 'SET_START_SCHEDULED_CLOSING_PENDING'
export const SET_STEP_ERROR = 'SET_STEP_ERROR'
export const SET_STEP_PENDING = 'SET_STEP_PENDING'
export const SET_TITLE_INDENTATION_ERROR = 'SET_TITLE_INDENTATION_ERROR'
export const SET_TITLE_INDENTATION_PENDING = 'SET_TITLE_INDENTATION_PENDING'
export const SET_TOTAL_DOCUMENTS = 'SET_TOTAL_DOCUMENTS'
export const SET_UPDATE_CLOSING_END_DATE_ERROR = 'SET_UPDATE_CLOSING_END_DATE_ERROR'
export const SET_UPDATE_CLOSING_END_DATE_PENDING = 'SET_UPDATE_CLOSING_END_DATE_PENDING'
export const SET_UPDATE_SIGNING_CHECKLIST_STEP_ERROR = 'SET_UPDATE_SIGNING_CHECKLIST_STEP_ERROR'
export const SET_UPDATE_TITLE_ERROR = 'SET_UPDATE_TITLE_ERROR'
export const SET_UPDATE_TITLE_PENDING = 'SET_UPDATE_TITLE_PENDING'
export const SET_VALIDATE_THEN_SEND_NOW_ERROR = 'SET_VALIDATE_THEN_SEND_NOW_ERROR'
export const SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_ERROR = 'SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_ERROR'
export const SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING = 'SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING'
export const SET_VALIDATE_THEN_SEND_NOW_PENDING = 'SET_VALIDATE_THEN_SEND_NOW_PENDING'
export const SET_VOIDED_TO_DRAFT_STEP_MODAL = 'SET_VOIDED_TO_DRAFT_STEP_MODAL'
export const TOGGLE_SELECTED_STEP = 'TOGGLE_SELECTED_STEP'
