<template>
  <v-row v-if="hasActiveFilter">
    <v-col>
      <DataTableFiltersSummary :active-filters="filters"
                              @clear-filter="onClearAllFilter"
                              @clear-all-filters="onClearAllFilters"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import DataTableFiltersSummary from '@/common/filters/DataTableFiltersSummary.vue'
import { REMOVE_CHECKLIST_FILTER, SET_CHECKLIST_FILTERS } from '@/store/modules/checklist/mutation_types'

export default {
  name: 'ChecklistFiltersActive',
  components: { DataTableFiltersSummary },
  computed: {
    ...mapState('checklist', ['filters']),
    ...mapGetters('checklist', ['hasActiveFilter']),
  },
  methods: {
    ...mapMutations('checklist', [REMOVE_CHECKLIST_FILTER, SET_CHECKLIST_FILTERS]),
    onClearAllFilter (filter) {
      this.REMOVE_CHECKLIST_FILTER(filter)
    },
    onClearAllFilters () {
      this.SET_CHECKLIST_FILTERS([])
    },
  },
}
</script>
