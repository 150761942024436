<template>
    <div :class="['ChecklistTableHeader', 'text--primary', 'checklist-header', noItemsSelected ? 'checklist-header-tab-without-multiple': 'checklist-header-tab-with-multiple']">
      <div class="ChecklistTableHeader-item pa-2">
        <v-row align="center" no-gutters>
          <v-col v-if="true" cols="auto">
            <v-checkbox
              v-show="isPm"
              :value="allTableItemsAreSelected"
              readonly
              hide-details
              class="mt-0 pt-0 mr-2"
              :ripple="false"
              @click.stop="toggleSelectAll"
            />
          </v-col>
          <v-col cols="auto"
                 class="mr-3"
          >
            {{ $t('project.checklist.ChecklistTableHeader.task') }}
          </v-col>
          <v-col>
          <AppTooltip top>
            <template #activator="{ on }">
              <AppButton icon
                       small
                       :x-padding="0"
                       v-on="on"
                       @click="expandAllTitles"
            >
              <font-awesome-icon :icon="['fas', 'up-right-and-down-left-from-center']"
                                 class="caption text--primary"
                                 :transform="{ rotate: -45 }"
              ></font-awesome-icon>
            </AppButton>
            </template>
            {{ $t("project.checklist.ChecklistTableHeader.expandAllTooltip") }}
          </AppTooltip>
          <AppTooltip top>
            <template #activator="{ on }">
            <AppButton icon
                       small
                       :x-padding="0"
                       v-on="on"
                       @click="collapseAllTitles"
            >
              <font-awesome-icon :icon="['fas', 'down-left-and-up-right-to-center']"
                                 class="caption text--primary"
                                 :transform="{ rotate: -45 }"
              ></font-awesome-icon>
            </AppButton>
            </template>
            {{ $t("project.checklist.ChecklistTableHeader.collapseAllTooltip") }}
          </AppTooltip>
          </v-col>

        </v-row>
      </div>
      <div class="ChecklistTableHeader-item">
        {{ $t('project.checklist.ChecklistTableHeader.responsible') }}
      </div>
      <div class="ChecklistTableHeader-item">
        {{ $t('project.checklist.ChecklistTableHeader.validator') }}
      </div>
      <div class="ChecklistTableHeader-item">
        {{ $t('project.checklist.ChecklistTableHeader.status') }}
      </div>
      <div class="ChecklistTableHeader-item">
        {{ $t('project.checklist.ChecklistTableHeader.date') }}
      </div>
      <div class="ChecklistTableHeader-item text-right">
        <!--{{ $t('project.checklist.ChecklistTableHeader.options') }}-->
      </div>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex'

import {
  ADD_ALL_ITEMS_TO_SELECTION,
  CLEAR_ITEMS_SELECTION,
  COLLAPSE_ALL_TITLES,
  EXPAND_ALL_TITLES,
} from '@/store/modules/checklist/action_types'

import AppTooltip from '../../common/AppTooltip'
import AppButton from '../../common/buttons/AppButton'

const { mapGetters } = createNamespacedHelpers('checklist')

export default {
  name: 'ChecklistTableHeader',
  components: { AppButton, AppTooltip },
  computed: {
    ...mapGetters(['flatChecklist', 'selectableChecklistItems', 'selectedTasks', 'selectedTitles', 'allSelectedChecklistItems', 'isChecklistFiltered']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
    noItemsSelected () {
      return this.selectedTitles.length === 0 && this.selectedTasks.length === 0
    },
    displayedSelection () {
      return this.isChecklistFiltered ? this.selectedTasks : this.allSelectedChecklistItems
    },
    allTableItemsAreSelected: {
      get () {
        return this.selectableChecklistItems.length > 0 && this.displayedSelection.length === this.selectableChecklistItems.length
      },
      set (value) {
        if (value) {
          this.ADD_ALL_ITEMS_TO_SELECTION()
        } else {
          this.CLEAR_ITEMS_SELECTION()
        }
      },
    },
  },
  methods: {
    ...mapActions('checklist', [
      ADD_ALL_ITEMS_TO_SELECTION,
      CLEAR_ITEMS_SELECTION,
      COLLAPSE_ALL_TITLES,
      EXPAND_ALL_TITLES,
    ]),
    expandAllTitles () {
      this.EXPAND_ALL_TITLES()
    },
    collapseAllTitles () {
      this.COLLAPSE_ALL_TITLES()
    },
    toggleSelectAll () {
      this.allTableItemsAreSelected = !this.allTableItemsAreSelected
    },
  },
}
</script>

<style scoped lang="scss">
/*Toggle class to fit the top with the multiple selection menu
* Need to be updated if the layout change
*/
.checklist-header {
  position: sticky;
  background: #fff;
  z-index: 2;
}
.checklist-header-tab-with-multiple {
  top: 189px;
}
.checklist-header-tab-without-multiple {
  top: 128px;
}
@media #{map-get($display-breakpoints, 'md-only')}{
  .checklist-header-tab-with-multiple {
    top: 173px;
  }
  .checklist-header-tab-without-multiple {
    top: 120px;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')}{
  .checklist-header-tab-with-multiple {
    top: 204px;
  }
  .checklist-header-tab-without-multiple {
    top: 151px;
  }
}
.ChecklistTableHeader {
  display: grid;
  grid-template-columns: 10fr 4fr 4fr 4fr 3fr 1fr;
  align-items: center;
  justify-items: center;
}
.ChecklistTableHeader-item {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    font-size: 14px;
  }
  &:first-child {
    justify-self: start;
  }
}
</style>
