import axios from 'axios'

export default {
  getQuestions (mnemo) {
    return axios.get(`/room/${mnemo}/qa/questions`)
  },
  deleteQuestion (mnemo, questionId) {
    return axios.delete(`/room/${mnemo}/qa/question/${questionId}`)
  },
  getCategories (mnemo) {
    return axios.get(`/room/${mnemo}/qa/categories`)
  },
  patchQuestion (mnemo, questionId, data) {
    return axios.patch(`/room/${mnemo}/qa/question/${questionId}`, data)
  },
  getQuestion (mnemo, questionId) {
    return axios.get(`/room/${mnemo}/qa/question/${questionId}`)
  },
  postQuestion (mnemo, data) {
    return axios.post(`/room/${mnemo}/qa/question`, data)
  },
  postAnswer (mnemo, questionId, data) {
    return axios.post(`/room/${mnemo}/qa/question/${questionId}/answer`, data)
  },
  patchQuestionRights (mnemo, questionId, data) {
    return axios.patch(`/room/${mnemo}/qa/question/${questionId}/rights`, data)
  },
  questionsBulkRights (mnemo, data) {
    return axios.patch(`/room/${mnemo}/qa/questions/bulkrights`, data)
  },
  importQuestions (mnemo, data) {
    return axios.post(`/room/${mnemo}/qa/import-excel`, data)
  },
}
