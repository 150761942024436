<template>
  <div>
    <AppTooltip :disabled="!iconOnly" right>
      <template #activator="{attrs, on}">
        <v-list-item
          class="px-4 py-0"
          :color="colorTheme"
          :value="value"
          :to="to ? { name: to } : null"
          v-bind="attrs"
          v-on="on"
        >
          <div class="d-flex align-center" :class="textColorClass">
            <slot name="content">
              <app-icon
                :icon-name="icon"
                :icon-weight="iconWeight"
                :class="textColorClass"
              />
              <span v-if="!iconOnly" class="ml-2">{{ title }}</span>
            </slot>
          </div>
        </v-list-item>
      </template>
      <span>{{ title }}</span>
    </AppTooltip>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import { getRoomColorTheme } from '@/common/utils/rooms'
import AppIcon from '@/design-system/icons/AppIcon.vue'

export default defineComponent({
  name: 'ProjectNavigationSection',
  components: { AppIcon, AppTooltip },
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState('global', ['mini']),
    ...mapState('room', ['currentRoom']),
    ...mapState('subscription', ['subscriptionBranding']),
    active () {
      if (!this.currentRoom) {
        return false
      }

      return this.$route.name === this.to
    },
    colorTheme () {
      if (this.currentRoom?.subscription?.color &&
        this.currentRoom?.subscription?.customizationEnabled) {
        return this.currentRoom.subscription.color
      } else if (this.subscriptionBranding?.color) {
        return this.subscriptionBranding.color
      }
      return getRoomColorTheme(this.currentRoom)
    },
    iconOnly () {
      return this.mini
    },
    iconWeight () {
      return this.active ? 'fas' : 'fal'
    },
    textColorClass () {
      return {
        [`text-${this.colorTheme}`]: this.active,
      }
    },
  },
})
</script>
