<template>
  <v-row>
    <v-col cols="12">
      <v-row class="border-bottom align-center">
        <v-col cols="1">
          <ClosdFildersIcon
            :document="file"
            :signed-file="file.signedOnClosd"
          />
        </v-col>
        <v-col cols="11">
          <template v-if="upload">
            <div class="d-flex align-center justify-space-between">
              <div class="fileName">
                {{ file.objectToPost.fileName }}
              </div>
              <app-filesize-display
                v-if="file.size"
                class="fileSize"
                :value="file.size"
              />
            </div>
            <div class="loadingBar-container pt-2">
              <div class="loadingBar-background"></div>
              <div
                class="loadingBar-bar"
                :style="{ width: `${file.progress}%` }"
              ></div>
            </div>
          </template>
          <template v-else>
            <div class="fileName">{{ file.objectToPost.fileName }}</div>
            <div class="fileSize">
              <app-filesize-display
                v-if="file.size"
                class="fileSize"
                :value="file.size"
              />
            </div>
          </template>
        </v-col>
        <v-col v-if="upload" cols="1" class="fileAction">
          <i v-if="file.status === 'success'" class="fad fa-circle-check"></i>
          <i v-if="file.status === 'error'" class="fad fa-circle-exclamation"></i>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ClosdFildersIcon from '../../common/filders/ClosdFildersIcon'

export default {
  name: 'ArchivesAddStep4File',
  components: { ClosdFildersIcon },
  props: {
    file: {
      type: Object,
      required: true,
    },
    upload: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.loadingBar-container {
  width: 100%;
  position: relative;

  .loadingBar-background {
    position: absolute;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: #ededed;
    border: 1px solid #e5e5e5;
  }

  .loadingBar-bar {
    position: absolute;
    height: 6px;
    border-radius: 3px;
    background-color: var(--v-primary-base);
  }
}

.fileAction {
  .fad {
    --fa-primary-opacity: 1;
    --fa-secondary-opacity: 1;
    font-size: 1.1rem;
  }
  i.fa-circle-exclamation {
    --fa-primary-color: #b99c6b;
    --fa-secondary-color: rgb(245, 230, 178);
  }

  i.fa-circle-check {
    --fa-primary-color: #f0f0f0;
    --fa-secondary-color: #4e985f;
  }
}

.fileName {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  font-size: 12px;
}

.fileSize {
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: $textGreyLight;
  white-space: nowrap;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}
</style>
