<template>
  <svg
    :width="width"
    :height="height"
    class="polyOfficeIcon icon mr-1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <g clip-path="url(#c)">
        <mask id="d" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="18" height="18">
          <path d="M1 1h18v18H1V1z" fill="#FF7276"/>
        </mask>
        <g id="polyOfficeIconGroup" mask="url(#d)" :fill="color ?? opts.theme.themes.light.primary.base">
          <path
            d="M4.921 9.149a8.594 8.594 0 0 1 3.915-3.893c.193-.094.391-.18.589-.256.017-.008.01-.035-.01-.03-5.894.997-8.423 8.375-4.486 11.532.014.013.034-.007.024-.022-.574-.79-1.92-3.512-.032-7.331z"/>
          <path
            d="M6.273 14.069a9.28 9.28 0 0 1 .002-2.919 9.02 9.02 0 0 1 2.547-4.966 8.12 8.12 0 0 1 .777-.677c.014-.012 0-.034-.017-.027-3.082 1.386-4.858 4.848-4.631 8 .125 1.443.687 4.096 3.43 4.62.018.002.028-.02.013-.03-1.129-.785-1.92-2.613-2.121-4.001zM2.466 6.961C3.844 5.003 6.671 3.68 9.433 4.023c.02.003.024-.025.007-.032-2.109-.62-4.3-.204-5.64.886-.576.468-1.021 1.275-1.36 2.067-.008.017.016.032.026.017z"/>
          <path
            d="M18.021 9.405c-.364-4.484-4.376-7.882-8.823-7.471-1.547.14-3.201.785-4.435 1.835-.015.013.002.037.02.028 1.055-.608 2.506-.594 3.364-.493a7.39 7.39 0 0 1 2.58.805c.208.11.409.229.604.36a8.557 8.557 0 0 1 2.268-.653h.002c.056-.007.112.022.13.074a.116.116 0 0 1-.086.153c-.415.088-.826.209-1.222.359-.017.007-.012.032.008.032a8.212 8.212 0 0 1 1.669.177h.002a.116.116 0 0 1-.04.229 8.158 8.158 0 0 0-1.448-.086c-.02 0-.022.027-.005.034.227.07.45.15.67.239.412.17.808.374 1.185.608l.005.002a.11.11 0 0 1 .053.106.116.116 0 0 1-.17.094 7.732 7.732 0 0 0-1.687-.694c-.02-.005-.03.02-.012.032.283.18.555.376.809.593.34.288.655.603.94.942l.003.003a.115.115 0 0 1 .02.118.115.115 0 0 1-.191.034 7.593 7.593 0 0 0-.94-.878 6.757 6.757 0 0 0-.624-.436c-.017-.01-.034.012-.022.027a7.24 7.24 0 0 1 .968 1.285c.227.384.42.787.574 1.206v.002a.116.116 0 0 1-.022.118.116.116 0 0 1-.193-.034 6.954 6.954 0 0 0-.584-1.15 7.065 7.065 0 0 0-2.036-2.1c-6.695 3.762-4.371 13.635.46 13.123 3.841-.925 6.539-4.55 6.207-8.623z"/>
          <path
            d="M3.482 8.174c1.545-2.466 4.235-3.526 5.884-3.686a.016.016 0 0 0 0-.032c-.681-.06-1.312-.015-2.138.16a7.27 7.27 0 0 0-4.23 2.746c-.004.008-.01.015-.014.02-.995 1.341-1.315 3.066-.92 4.693.045.187.172.57.258.817.007.017.034.012.031-.007-.207-1.927.347-3.465 1.13-4.71z"/>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z"/>
      </clipPath>
      <clipPath id="c">
        <path fill="#fff" transform="translate(1 1)" d="M0 0h18v18H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { opts } from '@/plugins/vuetify'

export default {
  name: 'PolyOfficeIcon',
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      opts,
    }
  },
}
</script>

<docs>
```vue
<template>
  <PolyOfficeIcon width="18" height="18" color="#FFF"/>
</template>
```
</docs>
