export const state = {
  discoverElectronicSafeDialogIsOpen: false,
  listSearchPending: false,
  listSearchData: [],
  listSearchDataCount: 0,
  config: null,
  configPending: false,
  getArchiveRightsPending: false,
  currentArchiveRights: {},
  sendArchiveSPending: false,
  shareArchivesPending: false,
  muteArchivingWarning: false,
  uploadArchivePendingIds: [],
  uploadArchiveErrors: [],
  postArchivePendingIds: [],
  postArchiveErrors: [],
  selectedDocuments: [],
  archivesDownloadPending: false,
}
