<template>
  <div>
    <AppSelect v-model="model.$model"
               :items="genders"
               :label="$t('common.identity-checking.form-fields.Gender.civilityLabel')"
               :placeholder="$t('common.identity-checking.form-fields.Gender.placeholder')"
               :error-messages="genderErrors"
               :error="isErrored"
               persistent-placeholder
    />

    <AppTextFieldSuggestion v-if="displaySuggestedValue"
                            :suggested-value="suggestedValue.text"
                            @validate="model.$model = suggestedValue.value"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AppSelect from '@/common/AppSelect'
import AppTextFieldSuggestion from '@/common/identity-checking/AppTextFieldSuggestion'
import { ID_CHECK_ERRORS } from '@/common/utils/idCheckErrors'

export default {
  name: 'Gender',
  components: { AppTextFieldSuggestion, AppSelect },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      genders: [
        {
          text: this.$t('common.identity-checking.form-fields.Gender.civility.mister'),
          value: 'M',
        },
        {
          text: this.$t('common.identity-checking.form-fields.Gender.civility.madame'),
          value: 'F',
        },
      ],
    }
  },
  computed: {
    ...mapState('user', ['idCheckError']),
    ...mapGetters('user', ['hasIdCheckErrorInfo']),
    genderErrors () {
      const errors = []
      if (!this.model.$dirty) return errors
      !this.model.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.identity-checking.form-fields.Gender.civilityLabel') }))
      return errors
    },
    isErrored () {
      return this.hasIdCheckErrorInfo &&
        this.idCheckError.errors.includes(ID_CHECK_ERRORS.ERROR_GENDER_MISMATCH) &&
        this.displaySuggestedValue
    },
    suggestedValue () {
      return this.genders.find(g => g.value === this.idCheckError?.suggestedValues?.gender)
    },
    displaySuggestedValue () {
      return this.suggestedValue && this.suggestedValue.value !== this.model.$model
    },
  },
}
</script>
