<template>
 <div class="fill-height d-flex align-center justify-center">
   <div class="text-center">
     <div class="mb-8">{{ $t('imanage.views.ImAuth.auth') }}</div>
     <AppLoader class="mx-auto"/>
   </div>
 </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppLoader from '@/common/AppLoader'
import { ROOMS_ROUTE_NAME } from '@/router'
import { GET_AUTH_CODE, GET_AUTH_STATUS } from '@/store/modules/imanage/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'ImAuth',
  components: { AppLoader },
  data () {
    return {
      code: null,
      state: null,
      route: null,
    }
  },
  computed: {
    ...mapState('imanage', ['authCode']),
  },
  async mounted () {
    this.code = this.$route.query.code
    this.state = this.$route.query.state
    this.route = this.$cookies.get('imanage_auth_last_route')

    try {
      if (this.authCode) {
        await this.GET_AUTH_STATUS(this.authCode.imConfig)
      } else {
        await this.GET_AUTH_CODE({
          authCode: this.code,
          state: this.state,
        })
      }
    } catch (error) {
      console.error(error)
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'error',
        message: this.$t('imanage.views.ImAuth.getAuthCodeError'),
      })
    } finally {
      if (this.route) {
        this.$router.push(this.route)
      } else {
        this.$router.push({ name: ROOMS_ROUTE_NAME })
      }
    }
  },
  methods: {
    ...mapActions('imanage', [GET_AUTH_CODE, GET_AUTH_STATUS]),
  },
}
</script>
