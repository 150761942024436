import {
  GET_STRIPE_INFO,
} from './action_types'
import {
  SET_STRIPE_INFO,
  SET_STRIPE_INFO_PENDING,
  SET_STRIPE_INFO_ERROR,
} from './mutation_types'
import service from '../../../services/payment.service'

export const actions = {
  async [GET_STRIPE_INFO] ({ commit }, token) {
    commit(SET_STRIPE_INFO_PENDING, true)
    try {
      const response = await service.getStripeInfo(token)
      commit(SET_STRIPE_INFO, response.data)
    } catch (error) {
      commit(SET_STRIPE_INFO_ERROR, error)
      throw error
    } finally {
      commit(SET_STRIPE_INFO_PENDING, false)
    }
  },
}
