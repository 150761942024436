<template>
  <div
    :style="{ 'min-width': containerSize }"
    class="d-flex justify-center"
  >
    <template v-if="isSpecialFolder === 'todo'">
      <img
        class="ClosdFildersIcon ClosdFildersIcon-specialFolder"
        src="/img/icons/folder_calendar.svg"
        alt="todo folder icon"
      />
    </template>
    <template v-else-if="isFolder">
      <v-badge
        color="error"
        :offset-x="8"
        :offset-y="10"
        :value="isSpecialFolder === 'new'"
        dot
      >
        <img
          class="ClosdFildersIcon ClosdFildersIcon-folder"
          :style="{ width: specificSize }"
          src="/img/icons/folder.svg"
          alt="folder icon"
        />
      </v-badge>
    </template>
    <template v-else-if="extension">
      <span v-if="signedFile">
        <span class="fa-stack" style="font-size: 0.75rem">
          <span style="position: relative">
            <OfficeIcon
              v-if="isOfficeDocument"
              :document-type="officeDocumentType"
              icon-type="document"
              :width="25"
              :height="25"
            />
            <img
              v-else
              class="ClosdFildersIcon"
              :style="{ width: specificSize }"
              :src="`/img/icons/${extension.icon}.svg`"
              alt="File icon"
            />
            <span v-if="document.isNew" class="ClosdFildersIcon-new"></span>
          </span>
          <span
            class="fa-stack fa-stack-2x"
            style="font-size: 0.45rem; bottom: -1.2rem; left: 0.45rem"
          >
            <i class="fas fa-badge fa-stack-2x" style="color: #31b45f"></i>
            <i class="fal fa-signature fa-stack-1x" style="color: #fff"></i>
          </span>
        </span>
      </span>
      <span v-else style="position: relative">
        <OfficeIcon
          v-if="isOfficeDocument"
          :document-type="officeDocumentType"
          icon-type="document"
          :width="25"
          :height="25"
        />
        <img
          v-else
          class="ClosdFildersIcon"
          :style="{ width: specificSize }"
          :src="`/img/icons/${extension.icon}.svg`"
          alt="File icon"
        />
        <span v-if="document.isNew" class="ClosdFildersIcon-new"></span>
      </span>
    </template>
  </div>
</template>

<script>
import OfficeIcon from '@/common/office/OfficeIcon.vue'
import { getFileExtension } from '@/common/utils/files'
import { getOfficeDocumentType, isOfficeDocumentExtension } from '@/common/utils/office'

export default {
  name: 'ClosdFildersIcon',
  components: { OfficeIcon },
  props: {
    document: {
      type: [Object, File],
      required: true,
    },
    size: {
      type: [String, Number],
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    signedFile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSpecialFolder () {
      return this.document.specialFolder
    },
    isFolder () {
      return this.document.type === 'folder' || this.document.directoryId
    },
    specificSize () {
      if (this.size) {
        return `${this.size}px`
      }
      return false
    },
    containerSize () {
      return this.small ? '35px' : '40px'
    },
    fileExtension () {
      return getFileExtension(this.document)
    },
    isOfficeDocument () {
      return this.fileExtension && isOfficeDocumentExtension(this.fileExtension)
    },
    officeDocumentType () {
      return this.fileExtension ? getOfficeDocumentType(this.fileExtension) : null
    },
    extension () {
      if (this.fileExtension) {
        switch (this.fileExtension.toLowerCase()) {
          case 'doc':
          case 'docx':
          case 'docm':
          case 'dotx':
          case 'dotm':
            return {
              icon: 'doc',
            }
          case 'xls':
          case 'xlt':
          case 'xlm':
          case 'xlsx':
          case 'xlsm':
          case 'xltx':
          case 'xltm':
            return {
              icon: 'excel',
            }
          case 'ppt':
          case 'pptx':
          case 'pps':
            return {
              icon: 'ppt',
            }
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
            return {
              icon: 'img',
            }
          case 'zip':
          case 'rar':
          case '7z':
          case 'tar':
          case 'gz':
          case 'bz':
            return {
              icon: 'zip',
            }
          case 'pdf':
            return {
              icon: 'pdf',
            }
        }
      }
      return {
        icon: 'file',
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ClosdFildersIcon {
  position: relative;
  width: 25px;
  vertical-align: middle;
  &.ClosdFildersIcon-specialFolder {
    width: 35px !important;
  }
  &--small {
    font-size: 20px;
    &.ClosdFildersIcon-specialFolder {
      font-size: 27px;
    }
  }
  &-folder {
    width: 30px;
  }
  &-new {
    position: absolute;
    width: 10px;
    height: 10px;
    top: -3px;
    right: -3px;
    background-color: var(--v-error-base);
    border-radius: 50%;
  }
  &-folderNew {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 3px;
    right: 0;
    background-color: var(--v-error-base);
    border-radius: 50%;
  }
}
</style>
