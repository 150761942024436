<template>
  <div class="d-flex flex-column row-gap-6" :class="{'mt-4' : !canSeeAllVersions }">
    <template v-if="versionsLoading">
      <div class="text-center">
        <AppLoader></AppLoader>
      </div>
    </template>
    <template v-else>
      <template v-if="documentVersions && previousVersions.length === 0">
        <div>
          <app-text variant="large-bold">{{ $t(`${TRANSLATION_KEY_PREFIX}.noVersionTitle`) }}</app-text>
          <app-text variant="small-regular">{{ $t(`${TRANSLATION_KEY_PREFIX}.noVersionText`) }}</app-text>
        </div>
        <div class="version-placeholder">
          <app-icon icon-name="minus"></app-icon>
        </div>
      </template>
      <template v-else-if="canSeeAllVersions">
        <app-text variant="large-bold">{{ $t(`${TRANSLATION_KEY_PREFIX}.currentVersionTitle`) }}</app-text>
        <DocumentVersioningHistoryItem :item="currentVersion"
                                       :no-share="noShare"
                                       @share-version="onShareVersion"
        />
        <v-divider></v-divider>
        <app-text variant="large-bold">{{ $t(`${TRANSLATION_KEY_PREFIX}.previousVersionsTitle`) }}</app-text>
        <DocumentVersioningHistoryItem v-for="item in previousVersions"
                                       :key="item.id"
                                       :item="item"
                                       deletable
                                       :no-share="noShare"
                                       @delete-version="onDeleteVersion"
                                       @share-version="onShareVersion"
        />
      </template>
      <template v-else>
        <app-text variant="small-regular">{{ $t(`${TRANSLATION_KEY_PREFIX}.guestText`) }}</app-text>
        <DocumentVersioningHistoryItem v-for="item in documentVersions"
                                       :key="item.id"
                                       :item="item"
                                       :no-share="noShare"
                                       @delete-version="onDeleteVersion"
                                       @share-version="onShareVersion"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppLoader from '@/common/AppLoader.vue'
import { DELETE_VERSION, GET_DOCUMENT_VERSIONS } from '@/store/modules/documents/action_types'

import DocumentVersioningHistoryItem from './DocumentVersioningHistoryItem.vue'

const TRANSLATION_KEY_PREFIX = 'common.document-versioning.DocumentVersioningHistory'

export default {
  name: 'DocumentVersioningHistory',
  components: {
    AppLoader,
    DocumentVersioningHistoryItem,
  },
  emits: ['share-version'],
  props: {
    noShare: {
      type: Boolean,
      default: false,
    },
    selectedDocuments: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
      documentVersions: [],
    }
  },
  methods: {
    ...mapActions('documents', [DELETE_VERSION, GET_DOCUMENT_VERSIONS]),
    async prepareGetDocumentVersions () {
      try {
        const response = await this.GET_DOCUMENT_VERSIONS({
          mnemo: this.roomMnemo,
          documentId: this.selectedDocuments[0].id,
        })
        this.documentVersions = response.data
      } catch (error) {
        console.error(error)
      }
    },
    async onDeleteVersion (data) {
      try {
        await this.DELETE_VERSION(data)
        this.prepareGetDocumentVersions()
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t(`${TRANSLATION_KEY_PREFIX}.deleteVersionError`))
      }
    },
    onShareVersion (data) {
      this.$emit('share-version', data)
    },
  },
  computed: {
    ...mapState('documents', ['versionsLoading']),
    ...mapGetters('room', ['isCurrentUserPm', 'currentUserRights', 'roomMnemo', 'isDataRoom']),
    canSeeAllVersions () {
      return this.isCurrentUserPm || this.currentUserRights?.canUploadVersions
    },
    currentVersion () {
      return this.documentVersions[0]
    },
    previousVersions () {
      return this.documentVersions.slice(1)
    },
  },
  created () {
    this.prepareGetDocumentVersions()
  },
}
</script>

<style lang="scss" scoped>
.version-placeholder {
  background-color: var(--v-grey-lighten6);
  border-radius: 8px;
  border: 1px solid var(--v-grey-lighten5);
  display: grid;
  min-height: 72px;
  padding: 8px 16px;
  place-items: center;
}
</style>
