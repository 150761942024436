<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('project.signing-checklist.signer-order.SignersOrderQualifiedDialog.title') }}</template>
    <template #body>
      <v-row>
        <v-col>
          {{ $t('project.signing-checklist.signer-order.SignersOrderQualifiedDialog.defineOrder') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="SignersList-container pa-4">
            <div class="font-weight-medium mb-4">{{ $t('project.signing-checklist.signer-order.SignersOrderQualifiedDialog.allSigners', { signersCount: flattenSigners.length }) }}</div>
            <div class="SignersList-list">
              <draggable v-model="flattenSigners"
                         group="signers"
                         handle=".Signer-grabIcon"
                         :forceFallback="true"
                         :emptyInsertThreshold="100"
                         :delay="500"
                         :delayOnTouchOnly="true"
              >
                <SignersOrderQualifiedSigner v-for="(signerId, index) in flattenSigners"
                                              :key="signerId"
                                              :signer-id="signerId"
                                              :index="index + 1"
                />
              </draggable>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <AppTooltip top>
        <template #activator="{attrs, on}">
          <div v-bind="attrs"
                class="d-inline-block"
                v-on="on"
          >
            <AppButton color="white"
                        @click="onResetSignersOrder"
            >
              {{ $t('common.reset') }}
            </AppButton>
          </div>
        </template>
        <span>{{$t('project.signing-checklist.signer-order.SignersOrderQualifiedDialog.resetOrderTooltip')}}</span>
      </AppTooltip>
      <div class="d-flex ml-auto column-gap-2">
        <AppButton type="outlined"
                   variant="neutral"
                   @click="closeDialog"
        >
          {{ $t('common.cancel') }}
        </AppButton>
        <AppButton type="primary"
                   @click="confirmGroups"
        >
          {{ $t('common.confirm') }}
        </AppButton>
      </div>
    </template>
  </AppDialog>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import draggable from 'vuedraggable'

import AppTooltip from '@/common/AppTooltip.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import AppButton from '@/design-system/buttons/AppButton.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import SignersOrderQualifiedSigner from '@/project/signing-checklist/signer-order/SignersOrderQualifiedSigner.vue'

type SignersGroup = Array<EnvelopeSigner['id']>

export default defineComponent({
  name: 'SignersOrderQualifiedDialog',
  components: { AppTooltip, AppButton, SignersOrderQualifiedSigner, AppDialog, draggable },
  mixins: [dialogMixin],
  props: {
    signers: {
      type: Array as PropType<Array<SignersGroup>>,
      default: () => [],
    },
  },
  data (): {
    groupsInternal: Array<SignersGroup>
  } {
    return {
      groupsInternal: [],
    }
  },
  computed: {
    flattenSigners: {
      get (): SignersGroup {
        return this.groupsInternal.flat()
      },
      set (signersArray: SignersGroup): void {
        this.groupsInternal = signersArray.map(signer => [signer])
      },
    },
  },
  created () {
    this.groupsInternal = cloneDeep(this.signers)
  },
  methods: {
    confirmGroups (): void {
      const groups: number[][] = []
      this.flattenSigners.forEach(signer => {
        groups.push([signer])
      })
      this.$emit('patch-signers-order', groups)
      this.closeDialog()
    },
    onResetSignersOrder () {
      this.$emit('reset-signers-order')
      this.closeDialog()
    },
  },
})
</script>

<style scoped lang="scss">
.SignersList {
  &-container {
    background-color: var(--v-originalGrey-lighten4);
    border-radius: 4px;
  }
  &-list {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>
