<template>
  <div v-if="!isSelectionEmpty" class="selected-items-bar">
    <span class="mr-2 selected-items-count">
      {{ $tc("common.selectedItems", selectedDocuments.length) }}
    </span>

    <AppButton
      class="mr-10"
      text
      color="primary"
      @click="onClearSelection"
    >
      {{ $t("common.cancelSelection") }}
    </AppButton>

    <MultiSelectBarButton v-if="selectionCanBeDownloaded" @click="onDownloadSelection">
      <font-awesome-icon :icon="['fas', 'download']" class="mr-2"></font-awesome-icon>
      {{$t('common.download')}}
    </MultiSelectBarButton>

    <MultiSelectBarButton v-if="isArchivingAdmin" @click="onShareSelection">
      <i class="fa fa-share mr-2"></i>
      {{$t('archives.ArchivesList.shareSelection')}}
    </MultiSelectBarButton>

    <MultiSelectBarButton @click="onSendSelection">
      <i class="fa fa-envelope mr-2"></i>
      {{$t('archives.ArchivesList.sendByEmail')}}
    </MultiSelectBarButton>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import MultiSelectBarButton from '@/common/buttons/MultiSelectBarButton'
import { DESELECT_ALL_DOCUMENTS } from '@/store/modules/archives/action_types'

export default {
  name: 'ArchivesBulkActions',
  components: {
    AppButton,
    MultiSelectBarButton,
  },
  computed: {
    ...mapGetters('user', ['isArchivingAdmin', 'currentUserId']),
    ...mapState('archives', ['selectedDocuments']),
    isSelectionEmpty () {
      return this.selectedDocuments.length === 0
    },
    selectionCanBeDownloaded () {
      return this.isArchivingAdmin || this.selectedDocuments.every(document => document.canDownload)
    },
  },
  beforeDestroy () {
    this.DESELECT_ALL_DOCUMENTS()
  },
  methods: {
    ...mapActions('archives', [DESELECT_ALL_DOCUMENTS]),
    onClearSelection () {
      this.DESELECT_ALL_DOCUMENTS()
    },
     onDownloadSelection () {
      this.$emit('download-selection')
    },
     onShareSelection () {
      this.$emit('share-selection')
    },
     onSendSelection () {
      this.$emit('send-selection')
    },
  },
}
</script>
