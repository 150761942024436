<template>
<AppDialog :is-open="isDialogOpened"
           size="xl"
           @cancel="closeDialog"
>
  <template #title>{{ dialogTitle }}</template>
  <template #body>
    <v-stepper v-model="currentStep"
               alt-labels
    >
      <v-stepper-items class="text--primary">
        <v-stepper-content v-if="!isEditMode"
                           :step="1"
        >
          <SigningChecklistStartSigningDialogSummaryStep :mnemo="mnemo"/>
        </v-stepper-content>
        <v-stepper-content :step="2">
          <SigningChecklistStartSigningDialogSettingsStep :action="action"
                                                   :start-date.sync="$v.startDate"
                                                   :end-date.sync="$v.endDate"
                                                   :custom-message.sync="customMessage"
                                                   :custom-message-errors="customMessageErrors"
                                                   :custom-message-max-characters="NB_CHARACTER_MAX"
                                                   :is-edit-mode="isEditMode"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </template>
  <template #footer>
    <AppButton v-if="currentStep === 2 && !isEditMode"
               color="white"
               :disabled="hasActionPending"
               @click="currentStep--"
    >
      {{ $t('common.previous') }}
    </AppButton>
    <AppButton color="white"
               class="ml-auto"
               :disabled="hasActionPending"
               @click="closeDialog"
    >
      {{ $t('common.cancel') }}
    </AppButton>
    <AppButton color="primary"
               class="ml-4"
               :disabled="okDisabled"
               :loading="hasActionPending"
               @click="onOk"
    >
      {{ okText }}
    </AppButton>
  </template>
</AppDialog>
</template>

<script>
import dayjs from 'dayjs'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'
import { mapActions, mapMutations, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import { ISOToShortDate } from '@/common/utils/dates'
import { escapeHtml } from '@/common/utils/strings'
import { dialogMixin } from '@/mixins/dialogMixin'
import { DATE_AND_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from '@/project/signing-checklist/list/dateAndTimeFormat'
import { START_SIGNING_ACTIONS } from '@/project/signing-checklist/list/startSigningActions'
import {
  GET_CLOSING_SUMMARY,
  START_CLOSING,
  START_SCHEDULED_CLOSING, UPDATE_CLOSING_END_DATE,
} from '@/store/modules/signing-checklist/action_types'
import { SET_SELECTED_STEPS } from '@/store/modules/signing-checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import SigningChecklistStartSigningDialogSettingsStep from './SigningChecklistStartSigningDialogSettingsStep'
import SigningChecklistStartSigningDialogSummaryStep from './SigningChecklistStartSigningDialogSummaryStep'

const NB_CHARACTER_MAX = 3000

export default {
  name: 'SigningChecklistStartSigningDialog',
  components: {
    AppButton,
    AppDialog,
    SigningChecklistStartSigningDialogSettingsStep,
    SigningChecklistStartSigningDialogSummaryStep,
  },
  mixins: [dialogMixin, validationMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
      validator: (value) => { return Object.values(START_SIGNING_ACTIONS).includes(value) },
    },
  },
  validations () {
    return {
      startDate: {
        date: {
          required: requiredIf(function () {
            return this.action === START_SIGNING_ACTIONS.SCHEDULED || this.action === START_SIGNING_ACTIONS.EDIT
          }),
          afterCurrentDate: () => this.startDateIsAfterCurrentDateValidator,
        },
        time: {
          required: requiredIf(function () {
            return this.action === START_SIGNING_ACTIONS.SCHEDULED || this.action === START_SIGNING_ACTIONS.EDIT
          }),
        },
      },
      endDate: {
        date: {
          required: requiredIf((endDateModel) => { return !!endDateModel.time }),
          afterCurrentDate: () => this.endDateIsAfterCurrentDateValidator,
          afterStartDate: () => this.endDateIsAfterStartDateValidator,
        },
        time: { required: requiredIf((endDateModel) => { return !!endDateModel.date }) },
      },
    }
  },
  data () {
    return {
      NB_CHARACTER_MAX,
      currentStep: 1,
      startDate: {
        date: '',
        time: '',
      },
      endDate: {
        date: '',
        time: '',
      },
      customMessage: '',
    }
  },
  computed: {
    ...mapState('signingChecklist', ['closingSummary', 'closingSummaryPending', 'startClosingPending', 'startScheduledClosingPending', 'updateClosingEndDatePending']),
    ...mapState('room', ['currentRoom']),
    // eslint-disable-next-line vue/return-in-computed-property
    dialogTitle () {
      switch (this.action) {
        case START_SIGNING_ACTIONS.NOW:
          return this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.startSigningNowTitle')
        case START_SIGNING_ACTIONS.SCHEDULED:
          return this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.startScheduledSigningTitle')
        case START_SIGNING_ACTIONS.EDIT:
          if (this.isEditClosing) {
            return this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.editClosingTitle')
          } else if (this.isEditScheduledClosing) {
            return this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.editScheduledClosingTitle')
          }
          break
        default:
          return this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.startSigningNowTitle')
      }
    },
    okText () {
      return this.currentStep === 1 ? this.$t('common.next') : this.$t('common.confirm')
    },
    okDisabled () {
      if (this.currentStep === 1) {
        return !this.closingSummary?.allReady
      }
      return false
    },
    customMessageErrors () {
      const errors = []
      if (this.hasTooManyCharacters) {
        errors.push(this.$t('common.validations.textTooLong'))
      }
      return errors
    },
    hasTooManyCharacters () {
      return this.nbCharacter > NB_CHARACTER_MAX
    },
    nbCharacter () {
      return escapeHtml(this.customMessage).length
    },
    isEditMode () {
      return this.action === START_SIGNING_ACTIONS.EDIT
    },
    isEditClosing () {
      return this.action === START_SIGNING_ACTIONS.EDIT && this.currentRoom.inClosing
    },
    isEditScheduledClosing () {
      return this.action === START_SIGNING_ACTIONS.EDIT && !this.currentRoom.inClosing
    },
    hasActionPending () {
      return this.startScheduledClosingPending || this.startClosingPending || this.updateClosingEndDatePending
    },
    computedCompleteStartDate () {
      if (this.startDate.date && this.startDate.time) {
        return dayjs(`${this.startDate.date} ${this.startDate.time}`, DATE_AND_TIME_FORMAT).format()
      }
      return null
    },
    computedCompleteEndDate () {
      if (this.endDate.date && this.endDate.time) {
        return dayjs(`${this.endDate.date} ${this.endDate.time}`, DATE_AND_TIME_FORMAT).format()
      }
      return null
    },
    startDateIsAfterCurrentDateValidator () {
      if (this.computedCompleteStartDate && !this.isEditClosing) {
        return dayjs(this.computedCompleteStartDate).isAfter(dayjs())
      }
      return true
    },
    endDateIsAfterCurrentDateValidator () {
      if (this.computedCompleteEndDate) {
        return dayjs(this.computedCompleteEndDate).isAfter(dayjs())
      }
      return true
    },
    endDateIsAfterStartDateValidator () {
      if (this.computedCompleteStartDate && this.computedCompleteEndDate) {
        return dayjs(this.computedCompleteEndDate).isAfter(this.computedCompleteStartDate)
      }
      return true
    },
    canPrepareStartClosing () {
      if (this.computedCompleteEndDate) {
        return this.action === START_SIGNING_ACTIONS.NOW &&
          !this.customMessageErrors.length &&
          this.endDateIsAfterCurrentDateValidator &&
          this.endDateIsAfterStartDateValidator
      }
      return this.action === START_SIGNING_ACTIONS.NOW && !this.customMessageErrors.length
    },
    canPrepareStartScheduledClosing () {
      if (this.computedCompleteEndDate) {
        return this.action === START_SIGNING_ACTIONS.SCHEDULED &&
          !this.customMessageErrors.length &&
          this.startDateIsAfterCurrentDateValidator &&
          this.endDateIsAfterCurrentDateValidator &&
          this.endDateIsAfterStartDateValidator
      }
      return this.action === START_SIGNING_ACTIONS.SCHEDULED && !this.customMessageErrors.length && this.startDateIsAfterCurrentDateValidator
    },
    canPrepareUpdateClosingEndDate () {
      return this.action === START_SIGNING_ACTIONS.EDIT &&
        this.endDateIsAfterStartDateValidator &&
        this.endDateIsAfterCurrentDateValidator &&
        this.currentRoom.inClosing
    },
  },
  created () {
    this.GET_CLOSING_SUMMARY(this.mnemo)
    if (this.action === START_SIGNING_ACTIONS.EDIT) {
      this.currentStep = 2
    }
    if (this.currentRoom.closingScheduledAt) {
      this.startDate.date = dayjs(this.currentRoom.closingScheduledAt).format(DATE_FORMAT)
      this.startDate.time = dayjs(this.currentRoom.closingScheduledAt).format(TIME_FORMAT)
    } else if (this.currentRoom.closingStartedAt) {
      this.startDate.date = dayjs(this.currentRoom.closingStartedAt).format(DATE_FORMAT)
      this.startDate.time = dayjs(this.currentRoom.closingStartedAt).format(TIME_FORMAT)
    }
    if (this.currentRoom.closingScheduledEndAt) {
      this.endDate.date = dayjs(this.currentRoom.closingScheduledEndAt).format(DATE_FORMAT)
      this.endDate.time = dayjs(this.currentRoom.closingScheduledEndAt).format(TIME_FORMAT)
    }
  },
  methods: {
    ...mapActions('signingChecklist', [GET_CLOSING_SUMMARY, START_CLOSING, START_SCHEDULED_CLOSING, UPDATE_CLOSING_END_DATE]),
    ...mapMutations('signingChecklist', [SET_SELECTED_STEPS]),
    onOk () {
      if (this.currentStep === 1 && this.closingSummary.allReady) {
        this.currentStep++
      } else if (this.currentStep === 2) {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          if (this.canPrepareStartClosing) {
            const data = {}
            if (this.endDate.date && this.endDate.time) {
              data.scheduledEndAt = dayjs(`${this.endDate.date} ${this.endDate.time}`, DATE_AND_TIME_FORMAT).toISOString()
            }
            if (this.customMessage) {
              data.message = this.customMessage
            }
            this.prepareStartClosing(data)
          }
          if (this.canPrepareStartScheduledClosing) {
            const data = {}
            if (this.startDate.date && this.startDate.time) {
              data.scheduledAt = dayjs(`${this.startDate.date} ${this.startDate.time}`, DATE_AND_TIME_FORMAT).toISOString()
            }
            if (this.endDate.date && this.endDate.time) {
              data.scheduledEndAt = dayjs(`${this.endDate.date} ${this.endDate.time}`, DATE_AND_TIME_FORMAT).toISOString()
            }
            if (this.customMessage) {
              data.message = this.customMessage
            }
            this.prepareStartScheduledClosing(data)
          }
          if (this.canPrepareUpdateClosingEndDate) {
            const data = {}
            if (this.endDate.date && this.endDate.time) {
              data.scheduledEndAt = dayjs(`${this.endDate.date} ${this.endDate.time}`, DATE_AND_TIME_FORMAT).toISOString()
            }
            this.prepareUpdateClosingEndDate(data)
          }
          if (!this.canPrepareUpdateClosingEndDate && this.action === START_SIGNING_ACTIONS.EDIT) {
            const data = {}
            if (this.startDate.date && this.startDate.time) {
              data.scheduledAt = dayjs(`${this.startDate.date} ${this.startDate.time}`, DATE_AND_TIME_FORMAT).toISOString()
            }
            if (this.endDate.date && this.endDate.time) {
              data.scheduledEndAt = dayjs(`${this.endDate.date} ${this.endDate.time}`, DATE_AND_TIME_FORMAT).toISOString()
            }
            this.prepareStartScheduledClosing(data)
          }
        }
      }
    },
    async prepareStartClosing (data) {
      try {
        await this.START_CLOSING({
            mnemo: this.mnemo,
            data,
          })
          let message
          if (data.scheduledEndAt) {
              message = this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.startNowWithEndDateSuccess', { endDate: ISOToShortDate(data.scheduledEndAt) })
            } else {
            message = this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.startNowSuccess')
          }
          this.SET_SELECTED_STEPS([])
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message,
          })
        } catch (error) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.setClosingError'),
          })
        } finally {
        this.closeDialog()
      }
    },
    async prepareStartScheduledClosing (data) {
      try {
        await this.START_SCHEDULED_CLOSING({
          mnemo: this.mnemo,
          data,
        })
        let message
        if (data.scheduledEndAt) {
          message = this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.scheduleWithEndDateSuccess', { startDate: ISOToShortDate(dayjs(data.scheduledAt)), endDate: ISOToShortDate(dayjs(data.scheduledEndAt)) })
        } else {
          message = this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.scheduleSuccess', { startDate: ISOToShortDate(dayjs(data.scheduledAt)) })
        }
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message,
        })
      } catch (error) {
        if (error.response?.data?.errorCode === 'ERR_CLOSING_INVALID_START_DATE') {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.setClosingStartDateError'),
          })
        } else {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.setClosingError'),
          })
        }
      } finally {
        this.closeDialog()
      }
    },
    async prepareUpdateClosingEndDate (data) {
      try {
        await this.UPDATE_CLOSING_END_DATE({
          mnemo: this.mnemo,
          data,
        })
        let message
        if (data.scheduledEndAt) {
          message = this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.updateClosingEndDateSuccess', { endDate: ISOToShortDate(dayjs(data.scheduledEndAt)) })
        } else {
          message = this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.updateClosingSuccess')
        }
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message,
        })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.setClosingError'),
        })
      } finally {
        this.closeDialog()
      }
    },
  },
}
</script>

<style scoped lang="scss">

.v-stepper__content {
  padding: 0;
}
::v-deep .StartSigningDialog {
  &__step {
    .v-stepper__label {
      text-align: center;
      font-weight: 500;
    }
    &.v-stepper__step--active {
      .v-stepper__label {
        color: var(--v-accent-base);
        text-shadow: none;
      }
    }
  }
}
</style>
