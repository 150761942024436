<template>
  <div class="d-flex align-center justify-space-between">
    <div class="d-flex align-center">
      <SearchTextField v-model="searchQuery"
                       :disabled="roomLogsPending"
                       class="mr-4"
                       :tooltipText="$t('project.Logs.LogsTopBar.searchPlaceholder')"
                       @keydown.enter.native="onEnterDown"
                       @click:clear="onClearClick"
      />
      <AppButton color="white"
                 height="40"
                 @click="onFiltersButtonClick"
      >
        <template v-if="logsFilters.length === 0">
          <font-awesome-icon :icon="['far', 'sliders']" class="mr-2"></font-awesome-icon>
        </template>
        <template v-else-if="logsFilters.length > 0">
          <v-avatar color="primary"
                    class="white--text caption mr-2"
                    size="19"
          >
            {{ logsFilters.length }}
          </v-avatar>
        </template>
        {{ $t('project.Logs.LogsTopBar.filters') }}
      </AppButton>
    </div>
      <AppDownloadFile :link="exportLogsLink">
        <template #default="{submitForm}">
          <AppButton color="primary"
                     @click="exportLogs(submitForm)"
          >
            <font-awesome-icon :icon="['far', 'file-upload']" class="mr-2"></font-awesome-icon>
            {{ exportButtonText }}
          </AppButton>
        </template>
      </AppDownloadFile>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapGetters, mapMutations, mapState } from 'vuex'

import { surveyCanBeTriggered, SurveyId, triggerSurvey } from '@/plugins/satismeter'
import { SET_ROOM_LOGS_SEARCH_QUERY, SET_LOGS_FILTERS_PANE_IS_OPEN } from '@/store/modules/logs/mutation_types'

import AppDownloadFile from '../../common/AppDownloadFile'
import AppButton from '../../common/buttons/AppButton'
import SearchTextField from '../../common/SearchTextField'

const SEARCH_QUERY_DEBOUNCE_TIMER = 1500

export default {
  name: 'LogsTopBar',
  components: { AppDownloadFile, SearchTextField, AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      logExportDialogIsOpen: false,
    }
  },
  computed: {
    ...mapState('logs', ['logsFilters', 'roomLogsSearchQuery', 'roomLogsPending']),
    ...mapState('user', ['profile']),
    ...mapGetters('logs', ['getLogsParams', 'hasActiveFilters']),
    searchQuery: {
      get () { return this.roomLogsSearchQuery },
      set: debounce(function (value) {
        this.SET_ROOM_LOGS_SEARCH_QUERY(value)
      }, SEARCH_QUERY_DEBOUNCE_TIMER),
    },
    exportLogsLink () {
      return this.axios.getUri({
        url: `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/logs`,
        params: this.getLogsParams,
      }) || null
    },
    exportButtonText () {
      if (this.hasActiveFilters || this.roomLogsSearchQuery) {
        return this.$t('project.Logs.LogsTopBar.exportResults')
      }
      return this.$t('project.Logs.LogsTopBar.exportAllActivity')
    },
  },
  methods: {
    ...mapMutations('logs', [SET_ROOM_LOGS_SEARCH_QUERY, SET_LOGS_FILTERS_PANE_IS_OPEN]),
    onFiltersButtonClick () {
      this.SET_LOGS_FILTERS_PANE_IS_OPEN(true)
    },
    onEnterDown (event) {
      this.SET_ROOM_LOGS_SEARCH_QUERY(event.target.value)
    },
    onClearClick () {
      this.SET_ROOM_LOGS_SEARCH_QUERY('')
    },
    exportLogs (submitFormCallback) {
      const { id: userId, fullName, email, isSubUser } = this.profile
      if (surveyCanBeTriggered(SurveyId.NPS, userId)) {
        triggerSurvey(SurveyId.NPS, userId, fullName, email, isSubUser)
      }
      submitFormCallback()
    },
  },
}
</script>
