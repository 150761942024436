<template>
  <div>
    <AppDialog :is-open="isOpen"
               @cancel="close"
               @ok="onOk"
    >
      <template #title>
        {{ $tc('project.signed-documents.dialogs.DeleteSignedDocument.title', documents.length) }}
      </template>
      <template #body>
        <p>{{$tc('project.signed-documents.dialogs.DeleteSignedDocument.text1', documents.length)}}</p>
        <p>{{$tc('project.signed-documents.dialogs.DeleteSignedDocument.text2', documents.length)}}</p>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { GET_SIGNED_DOCUMENTS } from '@/store/modules/signed-documents/action_types'

import AppDialog from '../../../common/dialogs/AppDialog'

export default {
  name: 'DeleteSignedDocument',
  components: { AppDialog },
  props: {
    documents: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
  },
  methods: {
    ...mapActions('signedDocuments', ['deleteOriginal', 'getMySignatures', GET_SIGNED_DOCUMENTS]),
    async onOk () {
      try {
        await this.deleteOriginal({ mnemo: this.roomMnemo, data: { envelopesIds: this.documents.map(o => o.id) } })
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.$tc('project.signed-documents.dialogs.DeleteSignedDocument.deleteOriginalSuccess', this.documents.length),
        })
        this.GET_SIGNED_DOCUMENTS({ mnemo: this.roomMnemo })
      } catch (e) {
        this.$store.commit('enqueueSnackbar', {
          color: 'error',
          message: this.$tc('project.signed-documents.dialogs.DeleteSignedDocument.deleteOriginalError', this.documents.length),
        })
      } finally {
        this.close()
      }
    },
    close () {
      this.$emit('update:isOpen', false)
      this.$emit('update:documents', [])
    },
  },
}
</script>
