<template>
  <div>
    <template v-if="loading">
      <div class="d-flex justify-center my-5">
        <font-awesome-icon :icon="['far', 'spinner-third']"
                           class="text--primary"
                           spin
                           style="font-size: 100px;"
        ></font-awesome-icon>
      </div>
    </template>
    <template v-else>
      <DocumentSharePickerGroup v-for="group in groups"
                                :key="`group-${group.id}`"
                                :forcePmSelection="forcePmSelection"
                                :forceCurrentUserSelection="forceCurrentUserSelection"
                                :group="group"
                                :multi-select="multiSelect"
                                :users-only="usersOnly"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import DocumentSharePickerGroup from './DocumentSharePickerGroup'
import { RESET_DISABLED_MEMBERS, RESET_SELECTED_GROUPS_AND_MEMBERS } from '../../store/modules/document-share-picker/action_types'
export default {
  name: 'DocumentSharePicker',
  components: { DocumentSharePickerGroup },
  props: {
    forcePmSelection: {
      type: Boolean,
      required: true,
    },
    forceCurrentUserSelection: {
      type: Boolean,
      default: false,
    },
    usersOnly: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: true,
    },
    /**
     * Resets members and groups in the parent component
     * In the case of a modal with steppers, for example,
     * where you don't want to reset the data when you switch from one stepper to another
     */
    resetGroupsAndMembersInParent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('groups', ['groups', 'loading']),
  },
  mounted () {
    // We don't refetch in case the parent or someone else higher in the tree is also trying to load them
    // This avoids spamming _concurrent requests_ for no reason.
    // We will still refetch if the request is done, though.
    // There's no special error handling or retries so it's assumed this doesn't happen enough to be a problem
    if (!this.loading) {
      this.GET_GROUPS(this.$route.params.mnemo)
    }
  },
  beforeDestroy () {
    if (!this.resetGroupsAndMembersInParent) {
      this.RESET_DISABLED_MEMBERS()
      this.RESET_SELECTED_GROUPS_AND_MEMBERS()
    }
  },
  methods: {
    ...mapActions('documentSharePicker', [RESET_DISABLED_MEMBERS, RESET_SELECTED_GROUPS_AND_MEMBERS]),
    ...mapActions('groups', ['GET_GROUPS']),
  },
}
</script>
