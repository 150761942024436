<template>
  <div>
    <v-row>
      <v-col>
        <app-text as="span" variant="small-bold">{{ $t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.title') }}</app-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Alert variant="info">
          <p>{{ endSessionAlertText }}</p>
        </Alert>
      </v-col>
    </v-row>
    <v-row v-if="isStartDateDisplayed">
      <v-col>
        <div class="font-weight-medium mb-2">{{ $t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.startDateLabel') }}</div>
        <v-row>
          <v-col>
          <AppTextField v-model="startDate.date.$model"
                        type="date"
                        :disabled="currentRoom.inClosing"
                        :min="minStartDate"
                        :error="startDateErrors.length > 0"
                        clearable
          />
          </v-col>
          <v-col>
          <AppTextField v-model="startDate.time.$model"
                        :disabled="currentRoom.inClosing"
                        type="time"
                        :error="startDateErrors.length > 0"
                        clearable
          />
          </v-col>
        </v-row>
        <div v-if="startDateErrors.length"
             class="error--text caption mt-1"
        >
          {{ startDateErrors[0] }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="font-weight-medium mb-2">{{ endLabel }}</div>
        <v-row>
          <v-col>
          <AppTextField v-model="endDate.date.$model"
                        type="date"
                        :min="minEndDate"
                        :error="endDateErrors.length > 0"
                        clearable
          />
          </v-col>
          <v-col>
          <AppTextField v-model="endDate.time.$model"
                        type="time"
                        :error="endDateErrors.length > 0"
                        clearable
          />
          </v-col>
        </v-row>
        <div v-if="endDateErrors.length"
             class="error--text caption mt-1"
        >
          {{ endDateErrors[0] }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!isEditMode"
           class="mt-10"
    >
      <v-col>
        <div class="font-weight-medium mb-2">
          {{ $t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.customMessageLabel') }}
          <AppHelpTooltip :text="$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.customMessageTooltip')" />
        </div>
        <VisualTextEditor :value="customMessage"
                          :placeholder="$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.customMessagePlaceholder')"
                          counter="footer"
                          :error-messages="customMessageErrors"
                          :max-length="customMessageMaxCharacters"
                          @input="$emit('update:customMessage', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { mapState } from 'vuex'

import Alert from '@/common/alerts/Alert'
import AppHelpTooltip from '@/common/AppHelpTooltip'
import AppTextField from '@/common/AppTextField'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import { DATE_FORMAT, DATE_AND_TIME_FORMAT } from '@/project/signing-checklist/list/dateAndTimeFormat'
import { START_SIGNING_ACTIONS } from '@/project/signing-checklist/list/startSigningActions'

dayjs.extend(dayOfYear)
dayjs.extend(isSameOrBefore)

export default {
  name: 'SigningChecklistStartSigningDialogSettingsStep',
  components: { Alert, AppHelpTooltip, AppTextField, VisualTextEditor },
  props: {
    action: {
      type: String,
      required: true,
    },
    startDate: {
      type: Object,
      required: true,
    },
    endDate: {
      type: Object,
      required: true,
    },
    customMessage: {
      type: String,
      required: true,
    },
    customMessageErrors: {
      type: Array,
      required: true,
    },
    customMessageMaxCharacters: {
      type: Number,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      START_SIGNING_ACTIONS,
      minStartDate: null,
    }
  },
  computed: {
    ...mapState('room', ['currentRoom']),
    computedCompleteStartDate () {
      if (this.startDate.date.$model && this.startDate.time.$model) {
        return dayjs(`${this.startDate.date.$model} ${this.startDate.time.$model}`, DATE_AND_TIME_FORMAT).format()
      }
      return null
    },
    computedCompleteEndDate () {
      if (this.endDate.date.$model && this.endDate.time.$model) {
        return dayjs(`${this.endDate.date.$model} ${this.endDate.time.$model}`, DATE_AND_TIME_FORMAT).format()
      }
      return null
    },
    endDateErrors () {
      const errors = []
      if (!this.endDate.date.$dirty && !this.endDate.time.$dirty) return errors
      if (!this.endDate.date.required && this.endDate.date.$dirty) { errors.push(this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.errors.endDateRequired')) }
      if (!this.endDate.date.afterCurrentDate && this.endDate.date.$dirty) { errors.push(this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.errors.dateTooOld')) }
      if (!this.endDate.date.afterStartDate && this.endDate.date.$dirty) { errors.push(this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.errors.endDateBeforeStartDate')) }
      if (!this.endDate.time.required && this.endDate.time.$dirty) { errors.push(this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.errors.endTimeRequired')) }
      return errors
    },
    endLabel () {
      if (this.action === START_SIGNING_ACTIONS.NOW) {
        return this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.endDateOnlyLabel')
      }
      return this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.endDateLabel')
    },
    endSessionAlertText () {
      return this.action === START_SIGNING_ACTIONS.EDIT && this.currentRoom.inClosing ? this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.endSessionEditAlertText') : this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.endSessionNewAlertText')
    },
    isStartDateDisplayed () {
      return (this.action === START_SIGNING_ACTIONS.SCHEDULED) || this.isEditMode
    },
    minEndDate () {
      if (this.computedCompleteStartDate) {
        return dayjs(this.computedCompleteStartDate).format(DATE_FORMAT)
      }
      return dayjs().format(DATE_FORMAT)
    },
    startDateErrors () {
      const errors = []
      if (!this.startDate.date.$dirty && !this.startDate.time.$dirty) return errors
      if (!this.startDate.date.required && this.startDate.date.$dirty) { errors.push(this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.errors.startDateRequired')) }
      if (!this.startDate.date.afterCurrentDate && this.startDate.date.$dirty) { errors.push(this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.errors.dateTooOld')) }
      if (!this.startDate.time.required && this.startDate.time.$dirty) { errors.push(this.$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialogSettingsStep.errors.startTimeRequired')) }
      return errors
    },
  },
  created () {
    this.minStartDate = dayjs().format(DATE_FORMAT)
  },
  methods: {
    dayjs,
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-text-field .v-input__icon--clear {
  opacity: 1;
}
</style>
