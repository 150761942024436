<template>
  <Pane :value="value"
        :tabs="tabs"
        :current-tab="currentTab"
        temporary
        @input="closePane"
  >
    <template #content-share>
      <v-row>
        <v-col class="text-h3">
          {{ $t('project.question-answer.QuestionAnswerItemSharePane.shareQuestion') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group v-model="shareType"
                         class="mt-0"
                         hide-details
          >
            <v-radio
              v-if="isCurrentUserPm"
              :value="SHARE_TYPE_OPTIONS.PUBLIC"
              class="DocumentShare-radio mb-0 px-3"
              active-class="DocumentShare-radio--active"
            >
              <template #label>
                <div class="d-flex flex-column text--primary ml-2">
                  <p class="custom-label-title">{{$t('project.question-answer.QuestionAnswerItemSharePane.publicTitle')}}</p>
                  <div class="subtitle-1 text--secondary">{{$t('project.question-answer.QuestionAnswerItemSharePane.publicSubtitle')}}</div>
                </div>
              </template>
            </v-radio>
            <v-radio :value="SHARE_TYPE_OPTIONS.CUSTOM"
                     class="DocumentShare-radio px-3"
                     active-class="DocumentShare-radio--active"
            >
              <template #label>
                <div class="d-flex flex-column text--primary ml-2">
                  <p class="custom-label-title">{{$t('project.question-answer.QuestionAnswerItemSharePane.customTitle')}}</p>
                  <div class="subtitle-1 text--secondary">{{$t('project.question-answer.QuestionAnswerItemSharePane.customSubtitle')}}</div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="shareType === SHARE_TYPE_OPTIONS.CUSTOM"
             class="mt-0"
      >
        <v-col>
          <DocumentSharePicker :force-pm-selection="true" :force-current-user-selection="true"/>
        </v-col>
      </v-row>
      <v-row v-if="value">
        <v-col>
          <VisualTextEditor v-model="customMessage"
                            qa-variant
                            :placeholder="$t('project.question-answer.QuestionAnswerItemSharePane.customMessagePlaceholder')"
          />
          <div class="text-right mt-1">
            <TextCounter :current-length="notifyMessageLength" :max-length="NOTIFY_MESSAGE_MAX_LENGTH" />
          </div>
          <span v-if="notifyMessageErrors.length > 0" class="error--text caption">{{ notifyMessageErrors[0] }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <AppButton block
                     color="primary"
                     :disabled="patchQuestionRightsPending"
                     outlined
                     @click="closePane(false)"
          >
            {{ $t('common.cancel') }}
          </AppButton>
        </v-col>
        <v-col>
          <AppButton color="primary"
                     block
                     :loading="patchQuestionRightsPending"
                     @click="preparePatchQuestionRights"
          >
            {{ $t('project.question-answer.QuestionAnswerItemSharePane.update') }}
          </AppButton>
        </v-col>
      </v-row>
    </template>
  </Pane>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import DocumentSharePicker from '@/common/document-share/DocumentSharePicker'
import Pane from '@/common/Pane'
import TextCounter from '@/common/TextCounter'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import { QUESTION_ANSWER_LIST_ROUTE_NAME } from '@/router'
import { QuestionAnswerActionTypes } from '@/store/modules/question-answer/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import { INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS, RESET_SELECTED_GROUPS_AND_MEMBERS } from '../../store/modules/document-share-picker/action_types'

const NOTIFY_MESSAGE_MAX_LENGTH = 3000

export default {
  name: 'QuestionAnswerItemSharePane',
  components: { AppButton, Pane, DocumentSharePicker, VisualTextEditor, TextCounter },
  mixins: [validationMixin],
  validations: {
    customMessage: { maxLength: maxLength(NOTIFY_MESSAGE_MAX_LENGTH) },
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      NOTIFY_MESSAGE_MAX_LENGTH,
      tabs: [{ title: this.$t('project.question-answer.QuestionAnswerItemSharePane.tabTitle'), id: 'share' }],
      currentTab: 'share',
      SHARE_TYPE_OPTIONS,
      shareTypeValue: '',
      customMessage: '',
    }
  },
  computed: {
    ...mapState('questionAnswer', ['question', 'patchQuestionRightsPending']),
    ...mapState('documentSharePicker', ['selectedMembers', 'selectedGroups']),
    ...mapGetters('room', ['isCurrentUserPm']),
    shareType: {
      get () {
        if (this.shareTypeValue) {
          return this.shareTypeValue
        } else {
          return this.question.rights.isPublic
            ? SHARE_TYPE_OPTIONS.PUBLIC
            : SHARE_TYPE_OPTIONS.CUSTOM
        }
      },
      set (val) {
        this.shareTypeValue = val
        if (val === SHARE_TYPE_OPTIONS.CUSTOM) {
          this.initSelectedGroupsAndMembers()
        }
      },
    },
    notifyMessageLength () {
      return this.customMessage.replace(/(<([^>]+)>)/gi, '').length
    },
    notifyMessageErrors () {
      const errors = []
      this.notifyMessageLength > NOTIFY_MESSAGE_MAX_LENGTH && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.question-answer.QuestionAnswerItemSharePane.customMessage'), maxLength: NOTIFY_MESSAGE_MAX_LENGTH }))
      return errors
    },
  },
  watch: {
    value (value) {
      if (value) {
        this.initSelectedGroupsAndMembers()
      }
    },
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.PATCH_QUESTION_RIGHTS, QuestionAnswerActionTypes.GET_QUESTION]),
    ...mapActions('documentSharePicker', [RESET_SELECTED_GROUPS_AND_MEMBERS, INIT_SELECTED_MEMBERS, INIT_SELECTED_GROUPS]),
    closePane (value) {
      if (!value) {
        this.resetData()
        this.$emit('input', false)
      }
    },
    resetData () {
      this.shareTypeValue = ''
      this.customMessage = ''
      this.RESET_SELECTED_GROUPS_AND_MEMBERS()
    },
    async preparePatchQuestionRights () {
      try {
        const data = {
          shareType: this.shareType,
          withUsers: this.selectedMembers.map(m => m.id),
          withGroups: this.selectedGroups.map(g => g.id),
        }
        if (this.customMessage) {
          data.customMessage = this.customMessage
        }
        await this.PATCH_QUESTION_RIGHTS({
          mnemo: this.mnemo,
          questionId: this.question.id,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.question-answer.QuestionAnswerItemSharePane.patchQuestionRightsSuccess'),
        })
        this.closePane(false)
        this.prepareGetQuestion()
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.question-answer.QuestionAnswerItemSharePane.patchQuestionRightsError'),
        })
      }
    },
    initSelectedGroupsAndMembers () {
      this.INIT_SELECTED_GROUPS(this.question.rights.groups)
      this.INIT_SELECTED_MEMBERS(this.question.rights.users)
    },
    async prepareGetQuestion () {
      try {
        await this.GET_QUESTION({
          mnemo: this.mnemo,
          questionId: this.question.id,
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.question-answer.QuestionAnswerItemSharePane.getQuestionError'),
        })
        this.$router.push({ name: QUESTION_ANSWER_LIST_ROUTE_NAME })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.DocumentShare-radio {
  border-left: 7px solid transparent;
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 14px;
  padding-bottom: 14px;

  &--active {
    border-left-color: var(--v-primary-base);
    background-color: var(--v-primary-lighten4);
  }
}
::v-deep .v-input--selection-controls__input .v-icon {
  font-size: 18px;
}

.custom-label-title {
  font-size: 16px;
  font-weight: 500;
}
</style>
