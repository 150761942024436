<template>
  <span>
    {{$t('project.project-settings.signatures.ProjectSettingsSignaturesCount.countWithPrice', { count, price })}}

    <span v-if="isBillingByEnvelope">
      {{$tc('project.project-settings.signatures.ProjectSettingsSignaturesCount.documentsCount', documentsCount)}}
    </span>
  </span>
</template>

<script>
export default {
  name: 'ProjectSettingsSignaturesCount',
  props: {
    count: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    isBillingByEnvelope: {
      type: Boolean,
      default: false,
    },
    documentsCount: {
      type: Number,
      default: () => 0,
    },
  },
}
</script>
