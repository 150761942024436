export const hasActiveFilters = state => state.logsFilters.length > 0
export const getLogsParams = state => {
  const params = {}
  // Search
  if (state.roomLogsSearchQuery) {
    params.search = state.roomLogsSearchQuery
  }

  // Filters
  const dateFilter = state.logsFilters.find(filter => filter.category.key === 'date')
  if (dateFilter) {
    const beginDate = dateFilter.value.key[0]
    const endDate = dateFilter.value.key[1]

    if (beginDate) {
      params.beginDate = beginDate
    }
    if (endDate) {
      params.endDate = endDate
    }
  }

  const userFilters = state.logsFilters.filter(filter => filter.category.key === 'users')
  if (userFilters.length > 0) {
    params.userIds = userFilters.map(filter => filter.value.key)
  }

  const groupFilters = state.logsFilters.filter(filter => filter.category.key === 'groups')
  if (groupFilters.length > 0) {
    params.groupIds = groupFilters.map(filter => filter.value.key)
  }

  const typesFilters = state.logsFilters.filter(filter => filter.category.key === 'types')
  if (typesFilters.length > 0) {
    params.types = typesFilters.map(filter => filter.value.key)
  }

  // Sort
  if (state.logsSort.sortBy) {
    params.sortBy = state.logsSort.sortBy
    params.sortDesc = state.logsSort.sortDesc
  }

  return params
}
