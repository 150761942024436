<template>
  <Pane :value="isOpen"
        :tabs="tabs"
        current-tab="filter"
        temporary
        @input="closePane"
  >
    <template #content-filter>
      <div v-if="isOpen"
           class="mt-6"
      >
        <v-row>
          <v-col>
            <div class="body-1 font-weight-bold mb-4">{{ $t('projects.ProjectsFiltersPane.projectType') }}</div>
            <v-row no-gutters>
              <v-col v-for="(projectType, index) in projectTypes"
                     :key="`projectType-${index}`"
                     cols="12"
                     md="6"
              >
                <v-checkbox v-model="filters.projectType"
                            :label="$t(`common.projectTypes.${projectType}`)"
                            :ripple="false"
                            :value="projectType"
                            hide-details
                            multiple
                            class="my-1 py-0"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider class="my-2"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="body-1 font-weight-bold mb-4">{{ $t('projects.ProjectsFiltersPane.role') }}</div>
            <v-row no-gutters>
              <v-col v-for="(role, index) in roles"
                     :key="`role-${index}`"
                     cols="12"
                     md="6"
              >
                <v-checkbox v-model="filters.role"
                            :label="$t(`projects.ProjectsFiltersPane.roles.${role}`)"
                            :ripple="false"
                            :value="role"
                            hide-details
                            multiple
                            class="my-1 py-0"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider class="my-2"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="body-1 font-weight-bold mb-4">{{ $t('projects.ProjectsFiltersPane.creationDate') }}</div>
            <AppDatePicker range
                           :label="$t('projects.ProjectsFiltersPane.creationDate')"
                           :value="filters.creationDate"
                           @input="(value) => handleDateRangeChange(value, 'creationDate')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider class="my-2"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="body-1 font-weight-bold mb-4">{{ $t('projects.ProjectsFiltersPane.dueDate') }}</div>
            <AppDatePicker range
                           :label="$t('projects.ProjectsFiltersPane.dueDate')"
                           :value="filters.dueDate"
                           @input="(value) => handleDateRangeChange(value, 'dueDate')"
            />
          </v-col>
        </v-row>
        <template v-if="roomLabels.length">
        <v-row>
          <v-col>
            <v-divider class="my-2"/>
          </v-col>
        </v-row>
          <v-row>
            <v-col>
              <div class="body-1 font-weight-bold mb-4">{{ $t('projects.ProjectsFiltersPane.labels') }}</div>
              <div class="d-flex align-center flex-wrap">
                <v-checkbox v-for="(label) in roomLabels"
                            :key="`label-${label.id}`"
                            v-model="filters.selectedLabels"
                            :label="label.label[$i18n.locale]"
                            :ripple="false"
                            multiple
                            :value="label"
                            hide-details
                            class="w-50 my-1 py-0"
                />
              </div>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col>
            <v-divider class="my-2"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <AppButton
                       block
                       class="mr-2"
                       @click="deleteAllFilters"
            >
              {{ $t('common.clear') }}
            </AppButton>
          </v-col>
          <v-col>
            <AppButton color="primary"
                       block
                       class="ml-2"
                       @click="applyFilters"
            >
              {{ $t('common.apply') }}
            </AppButton>
          </v-col>
        </v-row>
      </div>
    </template>
  </Pane>
</template>

<script>
import { mapState } from 'vuex'

import AppDatePicker from '../common/AppDatePicker'
import AppButton from '../common/buttons/AppButton'
import Pane from '../common/Pane'
import { initialRoomFiltersPaneState } from '../store/modules/rooms/state.js'

export default {
  name: 'ProjectsFiltersPane',
  components: { AppDatePicker, Pane, AppButton },
  data () {
    return {
      tabs: [{ id: 'filter', title: this.$t('projects.ProjectsFiltersPane.filters') }],
      filters: null,
      projectTypes: ['project', 'dataroom'],
      roles: ['projectManager', 'guest'],
    }
  },
  computed: {
    ...mapState('rooms', ['filtersPane', 'rooms']),
    isOpen () {
      return this.filtersPane.isOpen
    },
    roomLabels () {
      const labels = []
      if (this.rooms) {
        this.rooms.forEach(room => {
          if (room.label && !labels.includes(room.label) && !labels.find(lbl => lbl.id === room.label.id)) {
            labels.push(room.label)
          }
        })
      }
      return labels
    },
  },
  watch: {
    isOpen (value) {
      if (value) {
        this.filters = { ...this.filtersPane.filters }
      }
    },
  },

  methods: {
    closePane (value) {
      if (!value) {
        this.$store.commit('rooms/CLOSE_FILTER_PANE')
      }
    },
    handleDateRangeChange (value, property) {
      this.filters[property] = value
    },
    deleteAllFilters () {
      this.filters = { ...initialRoomFiltersPaneState }
      this.$store.commit('rooms/DELETE_FILTERS')
    },
    applyFilters () {
      this.$store.commit('rooms/SET_FILTERS', this.filters)
      this.closePane(false)
    },

  },
}
</script>
