<template>
  <v-img
    :src="iconUrl"
    :width="width"
    :height="height"
    :style="{ opacity: iconOpacity }"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { type SupportedOfficeDocumentTypes } from '@/common/utils/office'

type OfficeIconType = 'document' | 'product'

export default defineComponent({
  name: 'OfficeIcon',
  props: {
    /**
     * Specify the document's type to display or open
     * Possible values are : word, excel or powerpoint
     */
    documentType: {
      type: String as PropType<SupportedOfficeDocumentTypes>,
      required: true,
    },
    /**
     * Specify if we want to display a document
     * Possible values are : document (for document type)
     * or product (for application icon to open the document with)
     */
    iconType: {
      type: String as PropType<OfficeIconType>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    iconOpacity (): number {
      return this.disabled ? 0.5 : 1
    },
    iconUrl (): string {
      const officeIconsFolder = '/img/icons/office'

      if (this.iconType === 'document') {
        return `${officeIconsFolder}/documents/${this.documentType}.svg`
      }

      return `${officeIconsFolder}/products/${this.documentType}${this.disabled ? '-monochromatic' : ''}.svg`
    },
  },
})
</script>
