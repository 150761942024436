<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('common.add')"
             size="l"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('project.signing-checklist.dialogs.SigningChecklistAddSignersDialog.addSignatories') }}</template>

    <template #body>
      <div v-show="currentStep === ADD_SIGNER_STEPS.MEMBERS_SELECTION">
        <p class="subtitle-2 mb-6">{{ $t("project.signing-checklist.dialogs.SigningChecklistAddSignersDialog.selectMembersStepText") }}</p>
        <DocumentSharePicker :force-pm-selection="false" users-only />
      </div>

      <div v-show="currentStep === ADD_SIGNER_STEPS.SIGNER_TYPE_SELECTION">
        <p class="subtitle-2">{{ $t("project.signing-checklist.dialogs.SigningChecklistAddSignersDialog.selectSignersTypeStepText") }}</p>

        <div v-for="signer in signers" :key="signer.id" class="d-flex align-center mt-6">
          <span class="signer__name body-1 mr-2">
            {{ signer.name }}
          </span>
          <div class="signer__type">
            <AppSelect v-model="signer.type"
                       :items="signerTypeSelectOptions"
                       item-text="label"
                       item-value="value"
                       no-label
                       :placeholder="$t('project.signing-checklist.dialogs.SigningChecklistAddSignersDialog.signerTypeSelectPlaceholder')"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <template v-if="currentStep === ADD_SIGNER_STEPS.MEMBERS_SELECTION">
        <v-spacer />

        <AppButton color="white" @click="closeDialog">
          {{$t('common.cancel')}}
        </AppButton>

        <AppButton color="primary"
                   :disabled="!canValidateMembersSelection"
                   :loading="postSignersPending"
                   @click="validateMembersSelection"
        >
          <template v-if="bulkSend">
            {{$t('common.next')}}
          </template>
          <template v-else>
            {{$t('common.validate')}}
          </template>
        </AppButton>
      </template>

      <template v-else-if="currentStep === ADD_SIGNER_STEPS.SIGNER_TYPE_SELECTION">
        <AppButton color="white" @click="goBackToMembersSelection">
          {{$t('common.previous')}}
        </AppButton>

        <v-spacer />

        <AppButton color="white" @click="closeDialog">
          {{$t('common.cancel')}}
        </AppButton>

        <AppButton color="primary"
                   :disabled="!canValidateTypesSelection"
                   :loading="postSignersPending"
                   @click="validateSigners"
        >
          {{$t('common.validate')}}
        </AppButton>
      </template>
    </template>
  </AppDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppSelect from '@/common/AppSelect.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import DocumentSharePicker from '@/common/document-share/DocumentSharePicker.vue'
import { getCommonAndUniqueSignerIdsFromSignersGroup } from '@/common/utils/signingChecklist'
import { dialogMixin } from '@/mixins/dialogMixin'
import { ENVELOPE_TYPES } from '@/project/signing-checklist/constants'
import { DISABLE_MEMBERS } from '@/store/modules/document-share-picker/action_types'
import { POST_SIGNERS } from '@/store/modules/prepareSignature/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

enum ADD_SIGNER_STEPS {
  MEMBERS_SELECTION,
  SIGNER_TYPE_SELECTION,
}

type Signer = {
  id: number
  name: string
  type?: ENVELOPE_TYPES
}
type SignerTypeSelectOption = {
  value: ENVELOPE_TYPES
  label: string
}

type DataType = {
  currentStep: ADD_SIGNER_STEPS
  signers: Array<Signer>
}

export default defineComponent({
  name: 'SigningChecklistAddSignersDialog',
  components: { AppButton, AppDialog, AppSelect, DocumentSharePicker },
  mixins: [dialogMixin],
  props: {
    bulkSend: {
      type: Boolean,
      default: false,
    },
    witnesses: {
      type: Array,
      required: true,
    },
  },
  data (): DataType {
    return {
      currentStep: ADD_SIGNER_STEPS.MEMBERS_SELECTION,
      signers: [],
    }
  },
  watch: {
    groups: {
      handler (groups) {
        const alreadySelectedMembers = groups.flatMap(group => group.members)
          .filter(member => this.isMemberSelected(member.id) || this.isWitness(member.id))
        this.DISABLE_MEMBERS(alreadySelectedMembers)
      },
      immediate: true,
    },
    selectedMembers (selectedMembers) {
      // we remove the signers not currently selected
      this.signers = this.signers.filter(signer => !!this.selectedMembers.find(member => member.id === signer.id))

      // we add members who were not previously selected as signers
      const newlySelectedMembers = selectedMembers
        .filter(member =>
          !this.signers.find(signer => signer.id === member.id) &&
          !this.currentEnvelopeSignersIds.includes(member.id),
        )
        .map(member => ({
          id: member.id,
          name: member.fullName,
        }))
      this.signers.push(...newlySelectedMembers)
    },
  },
  computed: {
    ...mapState('groups', ['groups']),
    ...mapState('prepareSignature', ['envelopeDisplayInformation', 'postSignersPending', 'taggingInformation']),
    ...mapState('documentSharePicker', ['selectedMembers']),
    ...mapGetters('room', ['roomMnemo']),
    ADD_SIGNER_STEPS: () => ADD_SIGNER_STEPS,
    canValidateMembersSelection (): boolean {
      return this.selectedMembers.filter(member => !this.isMemberSelected(member.id)).length > 0
    },
    canValidateTypesSelection (): boolean {
      return this.signers.every(signer => !!signer.type)
    },
    currentEnvelopeSignersIds (): Array<number> {
      const { commonSigners, uniqueSigners } = getCommonAndUniqueSignerIdsFromSignersGroup(this.taggingInformation.signers)

      return [
        ...commonSigners.flat(),
        ...uniqueSigners,
      ]
    },
    signerTypeSelectOptions (): Array<SignerTypeSelectOption> {
      return [
        {
          value: ENVELOPE_TYPES.SINGLE,
          label: this.$t('project.signing-checklist.dialogs.SigningChecklistAddSignersDialog.commonSigner') as string,
        },
        {
          value: ENVELOPE_TYPES.MULTIPLE,
          label: this.$t('project.signing-checklist.dialogs.SigningChecklistAddSignersDialog.uniqueSigner') as string,
        },
      ]
    },
  },
  methods: {
    ...mapActions('documentSharePicker', [DISABLE_MEMBERS]),
    ...mapActions('prepareSignature', [POST_SIGNERS]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    goBackToMembersSelection () {
      this.currentStep = ADD_SIGNER_STEPS.MEMBERS_SELECTION
    },
    validateMembersSelection () {
      if (this.bulkSend) {
        this.currentStep = ADD_SIGNER_STEPS.SIGNER_TYPE_SELECTION
      } else {
        this.validateSigners()
      }
    },
    async validateSigners () {
      try {
        await this.postSigners()
      } catch {
        return
      }

      this.$emit('update-signers-list')
      this.closeDialog()
    },
    async postSigners () {
      try {
        await this.POST_SIGNERS({
          mnemo: this.roomMnemo,
          envId: this.envelopeDisplayInformation.envelopeId,
          signers: {
            newSigners: this.signers,
          },
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$tc('project.signing-checklist.dialogs.SigningChecklistAddSignersDialog.postSignersMessageSuccess', this.signers.length))
      } catch (error) {
        console.error(error)
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.dialogs.SigningChecklistAddSignersDialog.postSignersMessageError'))

        throw error
      }
    },
    isMemberSelected (memberId: number): boolean {
      return this.currentEnvelopeSignersIds.some(signerId => signerId === memberId)
    },
    isWitness (memberId: number): boolean {
      return this.witnesses.includes(memberId)
    },
  },
})
</script>

<style lang="scss">
.signer__name {
  width: 154px
}

.signer__type {
  width: 224px;
}
</style>
