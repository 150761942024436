import { render, staticRenderFns } from "./AppPagination.vue?vue&type=template&id=1b7a5af8"
import script from "./AppPagination.vue?vue&type=script&lang=js"
export * from "./AppPagination.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AppPagination.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports