<template>
  <v-container fluid class="h-100 overflow-y-hidden">
    <v-row :class="{'h-100': $vuetify.breakpoint.mdAndUp}">
      <v-col cols="12" md="3" xl="2" class="white">
        <SubscriptionMenu/>
      </v-col>
      <v-col md="9" xl="10">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { GET_SUBSCRIPTION } from '@/store/modules/subscription/action_types'
import SubscriptionMenu from '@/subscriptions/SubscriptionMenu'
export default {
  name: 'Subscription',
  components: { SubscriptionMenu },
  computed: {
    ...mapState('user', ['profile']),
    ...mapState('subscription', ['subscription']),
  },
  created () {
    if (!this.profile.isSubAdmin) {
      this.$router.replace('/')
    }
    this.prepareGetSubscription()
  },
  methods: {
    ...mapActions('subscription', [GET_SUBSCRIPTION]),
    prepareGetSubscription () {
      this.GET_SUBSCRIPTION(this.profile.subscription.id)
    },
  },
}
</script>
