<template>
  <transition name="fade">
    <v-footer v-if="!$vuetify.breakpoint.xs && $route.path === '/'" absolute class="transparent">
      <v-col class="pa-1 text-center" cols="6" offset="3">
          <AppButton
            rounded
            text
            small
          >
            <AppDownloadFile :link="urlCGU" forceReader>
              <template #default="{ submitForm }">
                <span @click="submitForm">
                  {{ $t('common.Footer.termsOfUse') }}
                </span>
              </template>
            </AppDownloadFile>
          </AppButton>
          <AppButton rounded text small :href="urlFaq">{{$t('common.Footer.help')}}</AppButton>
          <AppButton
            rounded
            text
            small
            :href="$t('common.Footer.sponsorshipOfferLink')"
            target="blank"
          >
            {{$t('common.Footer.sponsorshipOffer')}}
          </AppButton>
      </v-col>
      <v-col class="text-right">
        <span class="caption ml-auto">{{ closdVersion }}</span>
      </v-col>
    </v-footer>
  </transition>
</template>

<script>
import AppDownloadFile from './AppDownloadFile'
import AppButton from './buttons/AppButton.vue'
/**
 * Default App footer
 * @displayName Footer
 */
/**
 * Default App footer
 */
export default {
  name: 'Footer',
  components: { AppButton, AppDownloadFile },
  computed: {
    urlFaq () {
      return `${process.env.VUE_APP_KB_URL}/${this.$i18n.locale}`
    },
    urlCGU () {
      return `${process.env.VUE_APP_API_URL}/assets/pdf?file=terms-of-service-${this.$i18n.locale.toUpperCase()}`
    },
    closdVersion () {
      return process.env.VUE_APP_VERSION
    },
  },
}
</script>
