<template>
  <div class="d-flex align-center clickable"
       @click="navigateToFolder"
  >
    <ClosdFildersIcon :document="folder" class="mr-2" />
    <template v-if="folder.numbering">
      <span class="mr-1">{{ `${folder.numbering}` }}</span>
    </template>
    <div class="filderName">{{ folder.basename || folder.name }}</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon.vue'
import { GO_TO_FOLDER } from '@/store/modules/documents/action_types'

export default {
  name: 'DocumentsTableItemFolderName',
  components: { ClosdFildersIcon },
  props: {
    folder: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('documents', [GO_TO_FOLDER]),
    navigateToFolder () {
      this.GO_TO_FOLDER(this.folder.id)
    },
  },
}
</script>
