<template>
  <v-row class="mt-0"
         justify="end"
         align="center"
  >
    <v-col cols="auto">
      <AppSortSelect :value="sort"
                     :selected-options="selectedOptions"
                     v-on="$listeners"
      />
    </v-col>
    <v-col v-if="currentRoom.userRights.canCreateBibles"
           cols="auto"
    >
      <AppButton color="primary"
                 @click="$emit('generateBible')"
      >
        {{ $t('project.bibles.BiblesButtonBar.addBible') }}
      </AppButton>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

import AppSortSelect from '@/common/app-sort-select/AppSortSelect'

import { SORT_OPTIONS } from '../../common/app-sort-select/sortOptions'
import AppButton from '../../common/buttons/AppButton'

export default {
  name: 'BiblesButtonBar',
  components: { AppSortSelect, AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      selectedOptions: {
        [SORT_OPTIONS.NAME]: SORT_OPTIONS.NAME,
        [SORT_OPTIONS.CREATION_DATE]: SORT_OPTIONS.CREATION_DATE,
      },
      openBibleGenerateDialog: false,
    }
  },
  computed: {
    ...mapState('room', ['currentRoom']),
  },
}
</script>
