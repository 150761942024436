<template>
  <span>{{ $t(`common.AppSignatureTypeText.signatureType.${signatureType}`) }}</span>
</template>

<script>
export default {
  name: 'AppSignatureTypeText',
  props: {
    signatureType: {
      type: String,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
  },
  computed: {
    computedSignatureType () {
      if (this.provider === 'pdf') {
        return this.provider
      }
      return this.signatureType
    },
  },
}
</script>
