import { render, staticRenderFns } from "./ChecklistTableStatusColorPicker.vue?vue&type=template&id=5f9c617e&scoped=true"
import script from "./ChecklistTableStatusColorPicker.vue?vue&type=script&lang=js"
export * from "./ChecklistTableStatusColorPicker.vue?vue&type=script&lang=js"
import style0 from "./ChecklistTableStatusColorPicker.vue?vue&type=style&index=0&id=5f9c617e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f9c617e",
  null
  
)

export default component.exports