<template>
  <div>
    <template v-if="edit">
      <div class="d-flex align-center white">
        <AppTextField
          v-model.trim="item.objectToPost[field]"
          dense
          autofocus
          maxlength="100"
          @keydown.enter="edit = false"
          @blur="edit = false"
        />
      </div>
    </template>
    <template v-if="!edit">
      <template v-if="item.objectToPost[field]">
        <div class="m-1">
          <div
            class="Closd-hoverArea"
            style="box-sizing: border-box"
            @click="edit = true"
          >
            {{ item.objectToPost[field] }}
          </div>
        </div>
      </template>
      <template v-if="!item.objectToPost[field]">
        <div class="Closd-hoverArea" @click="edit = true">
          <span class="font-italic text--secondary">
            {{$t('archives.steps.ArchivesAddStep2TableCellAdditionalField.lblClickToAddRef')}}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AppTextField from '../../common/AppTextField.vue'
export default {
  name: 'ArchivesAddStep2TableCellAdditionalField',
  components: { AppTextField },
  props: {
    item: {
      type: Object,
      required: true,
    },
    /** Name of the field prop to edit on the item */
    field: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      edit: false,
    }
  },
}
</script>
