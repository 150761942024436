// This file contains all the states which was in index.ts before module structure creation
// TODO: Clean this file
const homeLayout = false
const projectLayout = false
const toggleProjectDrawer = false
const dialogAddMember = { active: false, newMember: {}, group: '' }
const dialogDisplaySigners = { active: false, signers: [] }
const dialogGenerateBible = { active: false, newBible: { includeIndex: true } }
const navigationDrawerBibles = { active: false, bible: {}, tabl: null }
const overlay = false
const permanentOverlay = false
const snackbars = []
const badgeProfileVisible = true
const chat = false
const projectDrawerPinned = false
const tasks = null
const bibles = null
const navigationDrawerDocuments = { active: false, tab: null }
const dialogDeleteMember = {
  groupId: null,
  memberId: null,
  active: false,
}
const dialogMembersOfGroup = {
  active: false,
  group: {},
}
const navigationDrawerTasks = { active: false, tab: null }
const notifications = []
const navigationDrawerProfile = false
const routerHistory = []

export default {
  homeLayout,
  projectLayout,
  toggleProjectDrawer,
  dialogAddMember,
  dialogDisplaySigners,
  dialogGenerateBible,
  navigationDrawerBibles,
  overlay,
  permanentOverlay,
  snackbars,
  badgeProfileVisible,
  chat,
  projectDrawerPinned,
  tasks,
  bibles,
  navigationDrawerDocuments,
  dialogDeleteMember,
  dialogMembersOfGroup,
  navigationDrawerTasks,
  notifications,
  navigationDrawerProfile,
  routerHistory,
}
