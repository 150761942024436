export const state = {
  authStatusPending: false,
  authStatus: null,
  authStatusError: null,
  userWantIManage: true,

  authUrlPending: false,
  authUrl: '',
  authUrlError: null,
  authCodePending: false,
  authCode: null,
  authCodeError: null,
  imanageFilePickerPending: false,
  imanageFilePickerError: null,
  currentTime: null,
  currentTimeTimeout: null,
  imanageFolderPickerPending: false,
  imanageFolderPickerError: null,
}
