<template>
  <tr class="clickable" :class="{ 'saved-checklist-table-item': isSelected }"
      @click="selectLexisNexisChecklist">
    <!-- Name -->
    <td>
      {{ checklist.name }}
    </td>

    <!-- Categorie -->
    <td>
      {{ lexisNexisChecklistCategories[checklist.category] }}
    </td>

    <!-- Author -->
    <td>
      {{ checklist.author }}
    </td>

    <!-- Freshness Date -->
    <td>
      {{ freshnessDate }}
    </td>
  </tr>
</template>

<script>
  import { mapState } from 'vuex'

  import { ISOToShortenedDate } from '@/common/utils/dates'
  import { SET_SELECTED_LEXISNEXIS_CHECKLIST } from '@/store/modules/checklist/mutation_types'

  export default {
    name: 'LexisNexisChecklistTableItem',
    props: {
      checklist: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapState('checklist', ['selectedLexisNexisChecklist']),
      isSelected () {
        return this.selectedLexisNexisChecklist?.id === this.checklist.id
      },
      lexisNexisChecklistCategories () {
        const categoriesObject = this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.categories')
        return Object.values(categoriesObject)
      },
      freshnessDate () {
        return ISOToShortenedDate(this.checklist.date)
      },
    },
    methods: {
      selectLexisNexisChecklist () {
        this.$store.commit(`checklist/${SET_SELECTED_LEXISNEXIS_CHECKLIST}`, this.checklist)
      },
    },
  }
</script>

<style scoped lang="scss">
  .saved-checklist-table-item {
    background-color: var(--v-blue-base);
  }
  tr:hover {
    background-color: var(--v-blue-base) !important;
  }
  td {
    max-width: 200px;
  }
</style>
