<template>
  <div>
    <Alert v-if="!roomProvidersPending" :dismissible="false" class="mb-4">
      {{$t('project.signing-checklist.add.step1.SigningChecklistAddStep1.acceptedFilesAlert' + (allowWordSigning ? 'WithWord' : ''), { maxSize: maxSignableSize, maxPages: maxSignablePages } )}}
    </Alert>
    <div v-if="loading" class="text-center mb-4">
      <v-progress-circular
        style="width: 64px; height: 64px"
        size="64"
        color="primary"
        indeterminate
      />
    </div>
    <v-row v-if="!cannotAdd">
      <v-col cols="12" md="6">
        <div class="SigningChecklistsAddStep1-card h-100">
          <h3
            class="text-center mt-0 mb-8 SigningChecklistsAddStep1-card-title"
          >
            {{ $t("project.signing-checklist.add.step1.SigningChecklistAddStep1.lblClosdFiles") }}
          </h3>
          <div class="px-2">
            <SigningChecklistClosdFileBrowser
              :mnemo="mnemo"
              :maxSignableSize="maxSignableSize"
              @selectedFiles="onSelectedFiles"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="SigningChecklistsAddStep1-card text-center h-100"
          @dragover.prevent
          @drop.prevent="onDrop"
        >
          <h3
            class="text-center mt-0 mb-8 SigningChecklistsAddStep1-card-title"
          >
            {{ $t("project.signing-checklist.add.step1.SigningChecklistAddStep1.lblExternalFiles") }}
          </h3>
          <v-row justify-md="center" align="center" class="h-100" style="flex-direction: column">
            <img src="/img/icons/2fichiers.svg" width="105" />
            <p class="mt-2 mb-0 px-4 font-weight-medium">
              {{ $t("project.signing-checklist.add.step1.SigningChecklistAddStep1.lblExternalFilesDrop") }}
            </p>
            <p class="my-0 px-4 font-weight-semi-bold text-uppercase">
              {{ $t("project.signing-checklist.add.step1.SigningChecklistAddStep1.lblExternalFilesDropOr") }}
            </p>
            <input
              id="signing-checklist-file-loader"
              ref="signingchecklistFileLoader"
              type="file"
              accept=".pdf, .doc, .docx"
              multiple
              class="d-none"
              @change="importFiles($event)"
            />
            <input
              id="signing-checklist-folder-loader"
              ref="signingChecklistFolderLoader"
              type="file"
              accept=".pdf, .doc, .docx"
              webkitdirectory
              multiple
              style="display: none"
              @change="importFolderContent($event)"
            />
            <!-- mb-16 is there to provide a minimum space for the accepted files caption -->
            <div class="my-6 mb-16">
              <div class="py-0">
                <AppButton
                  color="tertiary"
                  :dark="!loading"
                  large
                  class="my-1"
                  :disabled="loading"
                  @click="
                    () => {
                      this.$refs.signingchecklistFileLoader.click();
                    }
                  "
                >
                  <i class="fa fa-plus mr-2"></i>
                  {{ $t("project.signing-checklist.add.step1.SigningChecklistAddStep1.btnExternalFiles") }}
                </AppButton>
              </div>
              <div class="py-0">
                <AppButton
                  color="tertiary"
                  :dark="!loading"
                  large
                  class="my-1"
                  :disabled="loading"
                  @click="
                    () => {
                      this.$refs.signingChecklistFolderLoader.click();
                    }
                  "
                >
                  <i class="fa fa-plus mr-2"></i>
                  {{ $t("project.signing-checklist.add.step1.SigningChecklistAddStep1.btnExternalFolder") }}
                </AppButton>
              </div>
              <div v-if="imanageEnabledAndLogged"
                   class="py-0"
              >
                <AppButton color="tertiary"
                           large
                           dark
                           class="my-1"
                           @click="imanageDialogIsOpen = true"
                >
                  <i class="fa fa-plus mr-2"></i>
                  {{ $t('project.signing-checklist.add.step1.SigningChecklistAddStep1.importFromImanage') }}
                </AppButton>
                <IManageFilePickerDialog :is-open.sync="imanageDialogIsOpen"
                                         @files="prepareImportFromImanage"
                />
              </div>
              <PolyOfficeButtonImport v-if="polyOfficeEnabled"
                                      class="my-1"
                                      @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
              />
            </div>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <template v-if="importedFiles.length > 0">
      <h3 class="ml-4 my-1 imported-files-title">
        {{ $t("project.signing-checklist.add.step1.SigningChecklistAddStep1.importedFilesTitle", { count: importedFiles.length }) }}
      </h3>
      <SigningChecklistSelectedDocumentsListItem
        v-for="(file, i) in importedFiles"
        :key="`file-${i}`"
        :file="file"
        :canDelete="!file.objectToPost.envelope || (file.objectToPost.envelope && file.objectToPost.envelope.status === 'draft')"
        @deleteFile="deleteFile"
      />
    </template>

    <PolyOfficeDialog v-if="polyOfficeEnabled && polyOfficeDialogIsOpen" @callback="polyOfficeDialogCallback"/>
  </div>
</template>

<script>
import { getFilesFromDataTransferItems } from 'datatransfer-files-promise'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Alert from '@/common/alerts/Alert.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import IManageFilePickerDialog from '@/common/imanage/IManageFilePickerDialog.vue'
import { SigningChecklistImport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeButtonImport from '@/common/polyOffice/PolyOfficeButtonImport.vue'
import PolyOfficeDialog, { POLY_OFFICE_DIALOG_STATUS_SUCCESS } from '@/common/polyOffice/PolyOfficeDialog.vue'
import { isFileSignable } from '@/common/utils/files'
import { UPLOAD_SESSION_LIMIT, formatImportedFile } from '@/common/utils/signingChecklist'
import { BtoMB } from '@/common/utils/sizes'
import { IMANAGE_HIDDEN_FILE_IMPORT } from '@/store/modules/documents/action_types'
import { GET_IMPORT_IFRAME, GET_ACTION_INFO } from '@/store/modules/poly-office/action_types'
import { GET_ROOM_PROVIDERS } from '@/store/modules/room/action_types'
import { ADD_IMPORTED_FILE, REMOVE_IMPORTED_FILE } from '@/store/modules/signing-checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import SigningChecklistClosdFileBrowser from '../../SigningChecklistClosdFileBrowser.vue'
import SigningChecklistSelectedDocumentsListItem from '../../SigningChecklistSelectedDocumentsListItem.vue'

export default {
  name: 'SigningChecklistAddStep1',
  components: {
    PolyOfficeButtonImport,
    PolyOfficeDialog,
    IManageFilePickerDialog,
    SigningChecklistSelectedDocumentsListItem,
    AppButton,
    SigningChecklistClosdFileBrowser,
    Alert,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    prevId: {
      type: Number,
      // By default, add to the end
      default: -1,
    },
    prevType: {
      type: String,
      default: '',
    },
    cannotAdd: {
      type: Boolean,
      default: false,
    },
  },
  metaInfo () {
    return {
      title: this.$t('project.signing-checklist.add.step1.SigningChecklistAddStep1.metaTitle'),
    }
  },
  data () {
    return {
      loading: false,
      imanageDialogIsOpen: false,
      providersMaxSizes: [],
    }
  },
  computed: {
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapState('room', ['currentRoom', 'roomProvidersPending']),
    ...mapState('signingChecklist', ['importedFiles']),
    ...mapState('polyOffice', {
      polyOfficeDialogIsOpen: 'dialogIsOpen',
    }),
    ...mapGetters('user', ['polyOfficeEnabled']),
    ...mapGetters('room', ['isCurrentUserPm']),
    allowWordSigning: function () {
      return process.env.VUE_APP_ENABLE_WORD_SIGNING
    },
    maxSignableSize () {
      const availableSize = this.providersMaxSizes.length !== 0 ? BtoMB(Math.max(...this.providersMaxSizes)) : process.env.VUE_APP_MAX_FILE_SIZE
      return Number(availableSize).toFixed(1)
    },
    maxSignablePages () {
      return process.env.VUE_APP_MAX_SIGNABLE_PAGES
    },
  },
  async created () {
    if (this.isCurrentUserPm && !this.roomProvidersPending && !this.currentRoom.providers) {
      await this.GET_ROOM_PROVIDERS(this.mnemo)
    }
    this.providersMaxSizes = this.currentRoom.providers?.map(o => o.maxSize) || []
  },
  methods: {
    ...mapMutations([ENQUEUE_SNACKBAR]),
    ...mapMutations('signingChecklist', [ADD_IMPORTED_FILE, REMOVE_IMPORTED_FILE]),
    ...mapActions('documents', [IMANAGE_HIDDEN_FILE_IMPORT]),
    ...mapActions('polyOffice', [
      GET_IMPORT_IFRAME,
      GET_ACTION_INFO,
    ]),
    ...mapActions('room', [GET_ROOM_PROVIDERS]),
    limitReachCheck (length) {
      if (length >= UPLOAD_SESSION_LIMIT) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'warning',
          message: this.$t('project.signing-checklist.add.step1.SigningChecklistAddStep1.limitReachedMessage'),
        })
        return true
      }
      return false
    },
    importFromPickerRef (ref) {
      this.loading = true
      const correctFiles = this.handleInvalidFiles(Array.from(ref.files))
      if (correctFiles.length !== 0) {
        const queue = [...this.importedFiles]
        for (const f of correctFiles) {
          if (!this.limitReachCheck(queue.length)) {
            queue.push(formatImportedFile(f, { id: this.prevId, type: this.prevType }))
          } else {
            break
          }
        }
        this.$emit('updateUploadedFiles', queue)
      }
      this.loading = false
      ref.value = ''
    },
    importFiles () {
      this.importFromPickerRef(this.$refs.signingchecklistFileLoader)
    },
    importFolderContent () {
      this.importFromPickerRef(this.$refs.signingChecklistFolderLoader)
    },
    importClosdFiles (e) {
      this.loading = true
      for (const f of e) {
        if (!this.limitReachCheck(this.importedFiles.length)) {
          this.ADD_IMPORTED_FILE(formatImportedFile(f, { id: this.prevId, type: this.prevType }))
        } else {
          break
        }
      }
      this.loading = false
    },
    async onDrop (e) {
      let files = e.dataTransfer ? e.dataTransfer.items : null
      if (files) {
        this.loading = true
        const droppedFiles = await getFilesFromDataTransferItems(files)
        const correctFiles = this.handleInvalidFiles(Array.from(droppedFiles))
        if (correctFiles.length !== 0) {
          const filesToAdd = []
          for (const droppedFile of correctFiles) {
            if (!this.limitReachCheck(filesToAdd.length + this.importedFiles.length)) {
              filesToAdd.push(formatImportedFile(droppedFile, { id: this.prevId, type: this.prevType }))
            } else {
              break
            }
          }
          if (filesToAdd.length > 0) {
            files = [...this.importedFiles, ...filesToAdd]
            this.$emit('updateUploadedFiles', files)
          }
        }
        this.loading = false
      }
    },
    /**
     * Checks if there are invalid files and if so, dispatch actions to notify the end-user while returning the valid files
     * @param {array} files The files as an array
     * @returns {array} The valid files
     */
    handleInvalidFiles (files) {
      const invalidFiles = files.filter((f) => !isFileSignable(f, this.maxSignableSize))
      if (invalidFiles.length !== 0) {
        this[ENQUEUE_SNACKBAR]({
          color: 'error',
          message: this.$tc('project.signing-checklist.add.step1.SigningChecklistAddStep1.invalidFiles', invalidFiles.length, {
            name: invalidFiles[0].name,
            remainingCount: invalidFiles.length - 1,
          }),
        })
        for (const invalidFile of invalidFiles) {
          files.splice(files.indexOf(invalidFile), 1)
        }
      }
      return files
    },
    onSelectedFiles (e) {
      this.importClosdFiles(e)
    },
    deleteFile (file) {
      try {
        const fileIndexToDelete = this.importedFiles.findIndex(f => f === file)
        if (file.cancelToken) {
          file.cancelToken.cancel()
        }
        this.REMOVE_IMPORTED_FILE(fileIndexToDelete)
      } catch (e) {
        console.error(e)
      }
    },
    async prepareImportFromImanage (files) {
      for (const file of files.output_data.selected) {
        if (!this.limitReachCheck(this.importedFiles.length)) {
          const response = await this.IMANAGE_HIDDEN_FILE_IMPORT({
            mnemo: this.mnemo,
            data: {
              file: file.id,
            },
          })

          this.ADD_IMPORTED_FILE(formatImportedFile(response.data, { id: this.prevId, type: this.prevType }))
        } else {
          break
        }
      }
    },
    async polyOfficeOpenDialogHandler () {
      const payload = new SigningChecklistImport(this.mnemo)
      await this.GET_IMPORT_IFRAME({
        payload,
        successMessage: this.$t('project.signing-checklist.add.step1.SigningChecklistAddStep1.polyOffice.importSuccessful'),
      })
    },
    async polyOfficeDialogCallback (data) {
      if (data.status === POLY_OFFICE_DIALOG_STATUS_SUCCESS) {
        try {
          // Call get info and add files data to the store with ADD_IMPORTED_FILE
          const response = await this.GET_ACTION_INFO()

          if (response.receiveStatus === 'DONE') {
            for (const file of response.receivedFilesData) {
              if (!this.limitReachCheck(this.importedFiles.length)) {
                this.ADD_IMPORTED_FILE(formatImportedFile(file, { id: this.prevId, type: this.prevType }))
              } else {
                break
              }
            }
          } else {
            this[ENQUEUE_SNACKBAR]({
              color: 'error',
              message: this.$t('project.signing-checklist.add.step1.SigningChecklistAddStep1.polyOffice.importFailure'),
            })
          }
        } catch (e) {
          this[ENQUEUE_SNACKBAR]({
            color: 'error',
            message: this.$t('project.signing-checklist.add.step1.SigningChecklistAddStep1.polyOffice.importFailure'),
          })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.SigningChecklistsAddStep1-card {
  border: 2px dashed #d8d8d8;
  border-radius: 0.35rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  position: relative;
}

.SigningChecklistsAddStep1-card-title,
.imported-files-title {
  font-size: 17px;
  font-weight: 600;
}
</style>
