<template>
  <AppDialog :is-open="isOpen"
              :ok-text="okText"
              :ok-loading="loading"
              :ok-disabled="homeLayout && !name"
              @cancel="close"
              @ok="prepareCreateNewFolder"
  >
    <template #title>
      <template v-if="homeLayout">
        {{ $t('project.documents.dialogs.DocumentsNewFolderDialog.newFolder') }}
      </template>
      <template v-else>
        {{ $t('project.documents.dialogs.DocumentsNewFolderDialog.title') }}
      </template>
    </template>
    <template #body>
      <Alert v-if="!homeLayout && displayPathLengthWarning"
              type="warning"
              class="mb-4"
              :dismissible="false"
              icon="fal fa-triangle-exclamation"
      >
        {{ $t('project.documents.dialogs.DocumentsNewFolderDialog.pathLengthWarning') }}
      </Alert>
      <form @submit.stop.prevent="prepareCreateNewFolder">
        <AppTextField v-model.trim="name"
                      autofocus
                      :label="$t('project.documents.dialogs.DocumentsNewFolderDialog.lblCreateFolder')"
                      :error-messages="nameErrors"
                      :counter="DIRECTORY_NAME_MAX_LENGTH"
                      :maxlength="DIRECTORY_NAME_MAX_LENGTH"
        />
      </form>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapState, mapActions, mapMutations } from 'vuex'

import Alert from '@/common/alerts/Alert'
import AppTextField from '@/common/AppTextField'
import AppDialog from '@/common/dialogs/AppDialog'
import { DIRECTORY_NAME_MAX_LENGTH } from '@/common/utils/directory'
import { LOAD_FOLDERS_TREE } from '@/store/modules/documents/action_types'
import { CREATE_PROJECTS_NEW_FOLDER } from '@/store/modules/projects-create-new-folder/action_types'
import { SET_IS_OPEN } from '@/store/modules/projects-create-new-folder/mutation_types'
import { LOAD_PROJECTS_FOLDERS_TREE, GET_PROJECTS_FOLDER_BY_ID } from '@/store/modules/projects-folders/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'DocumentsNewFolderDialog',
  components: { AppDialog, Alert, AppTextField },
  mixins: [validationMixin],
  validations: {
    name: { required, maxLength: maxLength(DIRECTORY_NAME_MAX_LENGTH) },
  },
  data () {
    return {
      DIRECTORY_NAME_MAX_LENGTH,
      name: '',
    }
  },
  computed: {
    ...mapState('projectsFolders', ['currentProjectsFolder']),
    ...mapState(['homeLayout']),
    ...mapState('documentsBreadcrumb', ['breadcrumb']),
    ...mapState('documentsCreateNewFolder', {
        documentLoading: 'loading',
        documentIsOpen: 'isOpen',
    }),
    ...mapState('projectsCreateNewFolder', {
        projectLoading: 'loading',
        projectIsOpen: 'isOpen',
    }),
    loading () {
      return this.homeLayout ? this.projectLoading : this.documentLoading
    },
    isOpen () {
      return this.homeLayout ? this.projectIsOpen : this.documentIsOpen
    },
    okText () {
      if (this.homeLayout) {
        return this.$t('project.documents.dialogs.DocumentsNewFolderDialog.createTheFolder')
      } else {
        return this.$t('project.documents.dialogs.DocumentsNewFolderDialog.create')
      }
    },
    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.documents.dialogs.DocumentsNewFolderDialog.lblCreateFolder'), maxLength: DIRECTORY_NAME_MAX_LENGTH }))
      !this.$v.name.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.documents.dialogs.DocumentsNewFolderDialog.lblCreateFolder') }))
      return errors
    },
    displayPathLengthWarning () {
      return this.breadcrumb.length >= 5
    },
  },
  methods: {
    ...mapActions('documents', [LOAD_FOLDERS_TREE]),
    ...mapActions('documentsCreateNewFolder', ['createNewFolder', 'closeDocumentsCreateNewFolder']),
    ...mapMutations('projectsCreateNewFolder', [SET_IS_OPEN]),
    ...mapActions('projectsCreateNewFolder', [CREATE_PROJECTS_NEW_FOLDER]),
    ...mapActions('projectsFolders', [LOAD_PROJECTS_FOLDERS_TREE, GET_PROJECTS_FOLDER_BY_ID]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async prepareCreateNewFolder () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.homeLayout) {
          try {
            await this.CREATE_PROJECTS_NEW_FOLDER({
              name: this.name,
              parentId: this.currentProjectsFolder.id || undefined,
            })

            if (this.currentProjectsFolder.id) {
              try {
                this.GET_PROJECTS_FOLDER_BY_ID({
                  folderId: this.currentProjectsFolder.id,
                })
              } catch (error) {
                this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsNewFolderDialog.getFolderError', { name: this.currentProjectsFolder.name }))
              }
            } else {
              try {
                this.LOAD_PROJECTS_FOLDERS_TREE()
              } catch (error) {
                this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.ProjectsHeader.getProjectsFoldersTreeError'))
              }
            }
          } catch (error) {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsNewFolderDialog.createFolderError'))
          }
        } else {
          await this.createNewFolder(this.name)
          this.LOAD_FOLDERS_TREE()
        }
      }
      this.close()
    },
    close () {
      this.name = ''
      this.$v.$reset()
      if (this.homeLayout) {
        this.SET_IS_OPEN(false)
      } else {
        this.closeDocumentsCreateNewFolder()
      }
    },
  },
}
</script>
