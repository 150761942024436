<template>
  <div class="folder-item__inner-content"
       :class="{ 'folder-item__special': isFolderItemSpecial }"
       :style="{ paddingLeft: leftOffset }"
  >
    <div v-if="!isFolderItemSpecial" style="width: 18px">
      <font-awesome-icon v-if="loadingChildren"
                         class="text--primary"
                         :icon="['far', 'spinner-third']"
                         spin
      />
      <template v-if="hasChildren">
        <app-icon v-if="!loadingChildren"
                  size="regular"
                  icon-weight="fas"
                  :icon-name="areChildrenExpanded ? 'caret-down' : 'caret-right'"
                  class="expand-icon"
                  @click.native.stop="$emit('load-children')"
        />
      </template>
    </div>

    <AppTooltip :disabled="!iconOnly || (iconOnly && homeLayout)" right>
      <template #activator="{attrs, on}">
        <app-icon v-bind="attrs"
                  size="regular"
                  :icon-weight="homeLayout ? 'far' : 'fas'"
                  :icon-name="computedIconName"
                  class="folder-icon mr-2"
                  :class="homeLayout ? '' : 'folder-icon-blue'"
                  v-on="on"
        />
      </template>
      <span v-if="iconOnly">{{ folderName }}</span>
    </AppTooltip>

    <AppTooltip v-if="!iconOnly" top :open-delay="500" :disabled="!hasTooltip">
      <template #activator="{ attrs, on }">
        <div v-bind="attrs"
             ref="folderName"
             class="folder-name text-truncate"
             v-on="on"
        >
          <app-text as="span"
                    :variant="isCurrentlyViewedFolder ? 'small-bold' : 'small-regular'"
          >
            {{ folderName }}
          </app-text>
        </div>
      </template>
      {{ folderName }}
    </AppTooltip>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { mapState } from 'vuex'

  import AppTooltip from '@/common/AppTooltip.vue'
  import { FOLDER_ICON_COLOR } from '@/common/constants'

  import { RECENT_FOLDER, ROOT_FOLDER } from '../constants/special-folders'

  export type DocumentsSidebarFolder = {
    id: number | string;
    name: string;
    parentId?: number | string | null;
    children?: Array<DocumentsSidebarFolder>;
    numbering?: number;
    icon?: string;
  }

  export default defineComponent({
    name: 'DocumentsSidebarFolderItemContent',
    components: { AppTooltip },
    props: {
      folder: {
        type: Object as PropType<DocumentsSidebarFolder>,
        required: true,
      },
      depth: {
        type: Number,
        default: 0,
      },
      isCurrentlyViewedFolder: {
        type: Boolean,
        default: false,
      },
      areChildrenExpanded: {
        type: Boolean,
        default: false,
      },
      loadingChildren: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        hasTooltip: false,
        FOLDER_ICON_COLOR,
      }
    },
    async mounted () {
      await this.$nextTick()
      const folderNameDivElement = this.$refs.folderName as HTMLDivElement
      // Current usage has the folderName sometimes undefined. So we guard against it.
      this.hasTooltip = !!(folderNameDivElement?.offsetWidth < folderNameDivElement?.scrollWidth)
    },
    computed: {
      ...mapState(['homeLayout']),
      ...mapState('global', ['mini']),
      iconOnly (): boolean {
        return this.homeLayout && this.mini
      },
      computedIconName (): string {
        if (!this.folder.icon) {
          return this.homeLayout && this.hasChildren && this.areChildrenExpanded ? 'folder-open' : 'folder'
        } else {
          return this.folder.icon
        }
      },
      leftOffset (): string {
        const LEVEL_OFFSET = 8
        return `${this.depth * LEVEL_OFFSET}px`
      },
      hasChildren (): boolean {
        return !!this.folder.children && this.folder.children.length > 0
      },
      isFolderItemSpecial (): boolean {
        return this.folder.id === RECENT_FOLDER.id || this.folder.id === ROOT_FOLDER.id
      },
      folderName (): string {
        if (this.folder.numbering) {
          return `${this.folder.numbering} ${this.folder.name}`
        }

        return this.folder.name
      },
    },
  })
</script>

<style lang="scss" scoped>
  .folder-item {
    &__inner-content {
      display: grid;
      align-items: end;
      grid-template-columns: 24px auto 1fr;
      grid-template-areas: "expand-icon folder-icon folder-name";
      cursor: pointer;
    }
    // Special folders can never be expanded.
    &__special {
      grid-template-columns: auto 1fr !important;
      grid-template-areas: "folder-icon folder-name" !important;
    }

    .expand-icon {
      grid-area: expand-icon;
      color: var(--v-grey-darken1);
      border-radius: 4px;
      width: 24px;

      &:hover {
        background: var(--v-grey-lighten4);
      }
    }

    .folder-icon {
      grid-area: folder-icon;
    }
    .folder-icon-blue {
      color: v-bind(FOLDER_ICON_COLOR);
    }

    .folder-name {
      grid-area: folder-name;
    }
  }
</style>
