<template>
  <AppMenuAsPopover
    offset-y
    bottom
    :card-max-width="320"
    :card-min-width="240"
    @menu-state-change="onMenuStateChange"
  >
    <template #activator="{attrs: menuAttrs, on: menuOn}">
      <v-tooltip
        top
        transition="fade-transition"
      >
        <template #activator="{on: tooltipOn, attrs: tooltipAttrs}">
          <!-- Use AppChip when available -->
          <v-chip
            v-bind="{ ...menuAttrs, ...tooltipAttrs }"
            :key="filter.category.key"
            class="px-4 filter-chip grey--text"
            :class="{
              'completed': isFilterActive,
              'active': menuOpen,
              'text--darken-3': isFilterActive || menuOpen,
            }"
            v-on="{ ...menuOn, ...tooltipOn }"
          >
            <span v-if="filter.category.label" class="mr-1">{{filter.category.label}}</span>
            <span v-if="filter.value.label" class="font-weight-medium">: {{ filterLabel }}</span>
            <span class="ml-1 grey--text text--darken-3">
              <span v-if="isFilterActive" @click.stop.prevent="clearFilterValue">
                <app-icon iconName="xmark" size="medium" />
              </span>
              <app-icon v-else :iconName="noFilterMenuIcon" size="medium" />
            </span>
          </v-chip>
        </template>
        <span>
          {{filter.category.label}}
          <template v-if="filter.value.label">: {{ filterLabel }}</template>
        </span>
      </v-tooltip>
    </template>
    <template #default="{closeMenu}">
      <v-card-text>
        <template v-if="filter.type === FilterTypes.CHECKBOX">
          <v-checkbox
            v-for="(option, i) in filter.options"
            :key="i + '-' + option.value"
            v-model="filter.value.key"
            :label="option.label"
            :value="option.value"
            dense
            hide-details
            class="mb-2 mt-0 pt-0"
            @change="updateValueLabel"
          >
        </v-checkbox>
        </template>
        <template v-if="filter.type === 'date'">
          <AppDatePicker v-model="filter.value.key" range :menuPicker="false" @change="updateValueLabel" >
          </AppDatePicker>
        </template>
      </v-card-text>
    </template>
  </AppMenuAsPopover>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import AppDatePicker from '@/common/AppDatePicker.vue'
import AppMenuAsPopover from '@/common/AppMenuAsPopover.vue'
import { ISOToShortenedDate } from '@/common/utils/dates'
import { FilterType, FilterTypes } from '@/models/filter.model'

export default defineComponent({
  components: { AppMenuAsPopover, AppDatePicker },
  name: 'AppFilter',
  props: {
    /**
     * Filter on display
     * @type {Filter}
     * @example { category: { key: 'author', label: 'Auteur' }, value: { key: 1, label: 'Bruno Thomas' }, options: [], type: 'checkbox' }
     */
    filter: {
      type: Object as PropType<FilterType>,
      required: true,
    },
  },
  data () {
    return {
      FilterTypes,
      menuOpen: false,
    }
  },
  computed: {
    isFilterActive (): boolean {
      return this.filter.value.key.length !== 0
    },
    filterLabel (): string {
      let computedValueLabel = this.filter.value.label
      if (this.filter.type === FilterTypes.CHECKBOX && this.filter.value.key.length > 1) {
        computedValueLabel += `, +${this.filter.value.key.length - 1}`
      }
      return computedValueLabel
    },
    noFilterMenuIcon (): string {
      return this.menuOpen
        ? 'chevron-up'
        : 'chevron-down'
    },
  },
  methods: {
    updateValueLabel (value): void {
      let valueLabel = this.filter.value.label
      if (this.filter.type === FilterTypes.CHECKBOX) {
        if (value.length !== 0 && this.filter.options) {
          valueLabel = this.filter.options.find((option) => option.value === value[0])?.label || ''
        } else {
          valueLabel = ''
        }
      } else if (this.filter.type === FilterTypes.DATE) {
        const dates = this.filter.value.key as Array<string>
        valueLabel = `${ISOToShortenedDate(dates[0])} - ${ISOToShortenedDate(dates[1])}`
      }
      this.filter.value.label = valueLabel
      this.$emit('change', value)
    },
    clearFilterValue (): void {
      this.filter.value = {
        key: [],
        label: '',
      }
      this.$emit('clear')
    },
    onMenuStateChange (value): void {
      this.menuOpen = value
    },
  },
})
</script>

<style scoped lang="scss">
.filter-chip {
  background: transparent !important;
  border: 1px var(--v-grey-lighten3) solid;

  &:hover, &.active, &.completed {
    background-color: var(--v-grey-lighten4) !important;
  }

  &:hover {
    border-color: var(--v-grey-lighten1);
  }

  &.completed {
    border: 1px var(--v-grey-lighten1) solid;
  }

  &.active {
    border: 2px var(--v-grey-lighten1) solid;
  }
}

</style>

<docs>
  ```vue
  <template>
    <div>
      <div>
        <span>Checkboxes:</span>
        <AppFilter :filter="checkboxFilter">
        </AppFilter>
      </div>
      <div>
        <span>Dates:</span>
        <AppFilter :filter="dateFilter">
        </AppFilter>
      </div>
    </div>
  </template>
  <script>
    import { Filter, FilterTypes } from '../../models/filter.model'
    export default {
      data () {
        return {
          checkboxFilter: new Filter(
            'authorTest',
            'Auteur',
            [1],
            'Bruno Thomas',
            FilterTypes.CHECKBOX,
            [
              { label: 'Bruno Thomas', value: 1 },
              { label: 'Thomas Bruno', value: 2 }
            ]),
          dateFilter: new Filter(
            'dateTest',
            'Date début',
            [],
            '',
            FilterTypes.DATE,
          )
        }
      }
    }
  </script>
  ```
</docs>
