export const CHANGE_SIGNER = 'CHANGE_SIGNER'
export const DELETE_ALL_TAG_TYPE = 'DELETE_ALL_TAG_TYPE'
export const DELETE_SIGNER = 'DELETE_SIGNER'
export const DELETE_TAG = 'DELETE_TAG'
export const DRAG_TAG_ACTIVE = 'DRAG_TAG_ACTIVE'
export const DUPLICATE_CHECKBOX = 'DUPLICATE_CHECKBOX'
export const DUPLICATE_TAG = 'DUPLICATE_TAG'
export const GET_AUTO_TAGS = 'GET_AUTO_TAGS'
export const GET_ENVELOPE_DISPLAY_INFORMATION = 'GET_ENVELOPE_DISPLAY_INFORMATION'
export const GET_PAGES_CONTENT = 'GET_PAGES_CONTENT'
export const GET_PAGE_THUMB = 'GET_PAGE_THUMB'
export const GET_TAGGING_INFORMATION = 'GET_TAGGING_INFORMATION'
export const PATCH_SIGNERS_ORDER = 'PATCH_SIGNERS_ORDER'
export const PATCH_TAG = 'PATCH_TAG'
export const POST_AUTO_TAGS = 'POST_AUTO_TAGS'
export const POST_SIGNERS = 'POST_SIGNERS'
export const POST_TAG = 'POST_TAG'
export const RESET_DOCUMENT_TO_PREPARE = 'RESET_DOCUMENT_TO_PREPARE'
export const UPDATE_TAGS_OVERLAPS = 'UPDATE_TAGS_OVERLAPS'
