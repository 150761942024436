import archivesService from '@/services/archives.service'

import {
  DESELECT_ALL_DOCUMENTS,
  GET_ARCHIVE_CONFIG,
  GET_ARCHIVE_RIGHTS,
  GET_SEARCH_ARCHIVES,
  SHARE_ARCHIVES,
  UPLOAD_ARCHIVE,
  POST_ARCHIVE,
  SELECT_DOCUMENTS,
  SELECT_ALL_DOCUMENTS,
  GET_ARCHIVES_DOWNLOAD,
  SEND_ARCHIVES,
} from './action_types'
import {
  SET_CONFIG,
  SET_CONFIG_PENDING,
  SET_CURRENT_ARCHIVE_RIGHTS,
  SET_GET_ARCHIVE_RIGHTS_PENDING,
  SET_LIST_SEARCH_DATA,
  SET_LIST_SEARCH_DATA_COUNT,
  SET_LIST_SEARCH_PENDING,
  SET_SEND_ARCHIVES_PENDING,
  SET_SHARE_ARCHIVES_PENDING,
  ADD_UPLOAD_ARCHIVE_PENDING,
  REMOVE_UPLOAD_ARCHIVE_PENDING,
  ADD_UPLOAD_ARCHIVE_ERROR,
  ADD_POST_ARCHIVE_PENDING,
  REMOVE_POST_ARCHIVE_PENDING,
  ADD_POST_ARCHIVE_ERROR,
  SET_ALL_DOCUMENTS_AS_SELECTED,
  SET_SELECTED_DOCUMENTS,
  SET_ARCHIVES_DOWNLOAD_PENDING,
} from './mutation_types'

export const actions = {
  async [GET_SEARCH_ARCHIVES] ({ commit }, { search, params }) {
    commit(SET_LIST_SEARCH_PENDING, true)
    try {
      const { data } = await archivesService.searchArchives(search, params)
      commit(SET_LIST_SEARCH_DATA, data.data)
      commit(SET_LIST_SEARCH_DATA_COUNT, data.pagination.itemCount)
    } finally {
      commit(SET_LIST_SEARCH_PENDING, false)
    }
  },
  async [GET_ARCHIVE_CONFIG] ({ commit, state }) {
    let config = state.config
    if (!config) {
      commit(SET_CONFIG_PENDING, true)
      const { data } = await archivesService.getConfig()
      config = data
      commit(SET_CONFIG_PENDING, false)
    }
    commit(SET_CONFIG, config)
  },
  async [GET_ARCHIVE_RIGHTS] ({ commit }, archiveId) {
    commit(SET_GET_ARCHIVE_RIGHTS_PENDING, true)
    const { data } = await archivesService.getArchiveRights(archiveId)
    commit(SET_GET_ARCHIVE_RIGHTS_PENDING, false)
    commit(SET_CURRENT_ARCHIVE_RIGHTS, data)
  },
  async [SHARE_ARCHIVES] ({ commit }, file) {
    commit(SET_SHARE_ARCHIVES_PENDING, true)
    await archivesService.shareArchives(file)
    commit(SET_SHARE_ARCHIVES_PENDING, false)
  },
  async [UPLOAD_ARCHIVE] ({ commit, state }, file) {
    // We don't actually have any id for files not yet uploaded
    // so we generate a unique one from the currently pending array
    const id = state.uploadArchivePendingIds.length + 1
    commit(ADD_UPLOAD_ARCHIVE_PENDING, id)
    try {
      await archivesService.postArchive(file)
      file.status = 'success'
    } catch (error) {
      commit(ADD_UPLOAD_ARCHIVE_ERROR, error)
      file.status = 'error'
    } finally {
      commit(REMOVE_UPLOAD_ARCHIVE_PENDING,
        state.uploadArchivePendingIds.indexOf(id),
      )
    }
  },
  async [POST_ARCHIVE] ({ commit, state }, file) {
    commit(ADD_POST_ARCHIVE_PENDING, file.id)
    try {
      await archivesService.postArchive(file)
      file.status = 'success'
    } catch (error) {
      file.status = 'error'
      commit(ADD_POST_ARCHIVE_ERROR, error)
    } finally {
      commit(REMOVE_POST_ARCHIVE_PENDING,
        state.postArchivePendingIds.indexOf(file.id),
      )
    }
  },
  [SELECT_DOCUMENTS] ({ commit }, selectedDocuments) {
    commit(SET_SELECTED_DOCUMENTS, selectedDocuments)
  },
  [SELECT_ALL_DOCUMENTS] ({ commit }) {
    commit(SET_ALL_DOCUMENTS_AS_SELECTED)
  },
  [DESELECT_ALL_DOCUMENTS] ({ commit }) {
    commit(SET_SELECTED_DOCUMENTS, [])
  },
  async [GET_ARCHIVES_DOWNLOAD] ({ commit }, { params }) {
    commit(SET_ARCHIVES_DOWNLOAD_PENDING, true)
    try {
      const { data } = await archivesService.getArchivesDownload({ params: params })
      return data
    } finally {
      commit(SET_ARCHIVES_DOWNLOAD_PENDING, false)
    }
  },
  async [SEND_ARCHIVES] ({ commit }, { params }) {
    commit(SET_SEND_ARCHIVES_PENDING, true)
    try {
      await archivesService.sendArchives(params)
    } finally {
      commit(SET_SEND_ARCHIVES_PENDING, false)
    }
  },
}
