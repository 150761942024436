import dayjs from 'dayjs'

import {
  GET_AUTH_STATUS,
  GET_AUTH_URL,
  GET_AUTH_CODE,
  GET_IMANAGE_FILE_PICKER,
  START_AUTH_TIMER,
  GET_IMANAGE_FOLDER_PICKER,
} from './action_types'
import {
  SET_AUTH_STATUS,
  SET_AUTH_STATUS_PENDING,
  SET_AUTH_STATUS_ERROR,
  SET_AUTH_URL,
  SET_AUTH_URL_PENDING,
  SET_AUTH_URL_ERROR,
  SET_AUTH_CODE,
  SET_AUTH_CODE_PENDING,
  SET_AUTH_CODE_ERROR,
  SET_IMANAGE_FILE_PICKER_PENDING,
  SET_IMANAGE_FILE_PICKER_ERROR,
  SET_CURRENT_TIME,
  SET_CURRENT_TIME_TIMEOUT,
  SET_IMANAGE_FOLDER_PICKER_PENDING,
  SET_IMANAGE_FOLDER_PICKER_ERROR,
} from './mutation_types'
import service from '../../../services/iManage.service'

export const actions = {
  async [GET_IMANAGE_FOLDER_PICKER] ({ commit, state }) {
    commit(SET_IMANAGE_FOLDER_PICKER_PENDING, true)
    try {
      const response = await service.getIManageFolderPicker(state.authCode.imConfig)
      return response.data
    } catch (error) {
      commit(SET_IMANAGE_FOLDER_PICKER_ERROR, error)
      throw error
    } finally {
      commit(SET_IMANAGE_FOLDER_PICKER_PENDING, false)
    }
  },
  async [GET_IMANAGE_FILE_PICKER] ({ commit, state }, params) {
    commit(SET_IMANAGE_FILE_PICKER_PENDING, true)
    try {
      const response = await service.getIManageFilePicker(state.authCode.imConfig, params)
      return response.data
    } catch (error) {
      commit(SET_IMANAGE_FILE_PICKER_ERROR, error)
      throw error
    } finally {
      commit(SET_IMANAGE_FILE_PICKER_PENDING, false)
    }
  },
  async [GET_AUTH_CODE] ({ commit, dispatch, state }, data) {
    commit(SET_AUTH_CODE_PENDING, true)
    clearTimeout(state.currentTimeTimeout)
    try {
      const response = await service.getAuthCode(data)
      if (response.data.expireIn) {
        response.data.expireAt = dayjs().add(response.data.expireIn, 's')
        dispatch(START_AUTH_TIMER)
      }
      commit(SET_AUTH_STATUS, true)
      commit(SET_AUTH_CODE, response.data)
    } catch (error) {
      commit(SET_AUTH_CODE_ERROR, error)
      throw error
    } finally {
      commit(SET_AUTH_CODE_PENDING, false)
    }
  },
  async [GET_AUTH_STATUS] ({ commit }, token) {
    commit(SET_AUTH_STATUS_PENDING, true)
    try {
      const response = await service.getAuthStatus(token)

      if (response.data.expireIn) {
        response.data.expireAt = dayjs().add(response.data.expireIn, 's')
      }
      commit(SET_AUTH_STATUS, true)
      commit(SET_AUTH_CODE, response.data)
    } catch (error) {
      if (error.response?.data?.errorCode === 'ERR_REQUIRE_IMANAGE_AUTH') {
        commit(SET_AUTH_STATUS, false)
        commit(SET_AUTH_CODE, null)
      } else {
        commit(SET_AUTH_STATUS_ERROR, error)
      }
      throw error
    } finally {
      commit(SET_AUTH_STATUS_PENDING, false)
    }
  },
  async [GET_AUTH_URL] ({ commit }) {
    commit(SET_AUTH_URL_PENDING, true)
    try {
      const response = await service.getAuthURL()
      commit(SET_AUTH_URL, response.data.authUrl)
    } catch (error) {
      commit(SET_AUTH_URL_ERROR, error)
      throw error
    } finally {
      commit(SET_AUTH_URL_PENDING, false)
    }
  },
  [START_AUTH_TIMER] ({ commit, dispatch }) {
    commit(SET_CURRENT_TIME)
    commit(SET_CURRENT_TIME_TIMEOUT, setTimeout(() => {
      dispatch(START_AUTH_TIMER)
    }, 1000 * 60))
  },
}
