export const state = {
  signingViewPending: false,
  signingView: null,
  signingViewError: null,
  pagesNumberPending: [],
  pages: [],
  pagesError: null,
  activePage: null,
  scrollTo: null,
  signTagPendingIds: [],
  signTagError: null,
  unsignTagPendingIds: [],
  unsignTagError: null,
  validationModalIsOpen: false,
  sendValidationSmsPending: false,
  sendValidationSmsError: null,
  signDocumentPending: false,
  signDocumentError: null,
  resendValidationSmsPending: false,
  resendValidationSmsError: null,
  confirmSignaturePending: false,
  confirmSignatureError: null,
}
