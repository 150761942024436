<template>
  <v-container fluid
               class="py-0 px-2"
  >
    <v-row>
      <v-col class="text-h1">{{ $t('subscriptions.views.SubscriptionProjects.activeRooms') }}</v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <SearchTextField v-model="searchQuery"/>
          <AppButton
            color="white"
            class="ml-4"
            :height="40"
            @click="openFiltersPane"
          >
            <template v-if="filtersCount === 0">
              <font-awesome-icon :icon="['far', 'sliders']" class="mr-2"></font-awesome-icon>
            </template>
            <template v-else-if="filtersCount > 0">
              <v-avatar
                color="primary"
                class="white--text caption mr-2"
                size="19"
              >
                {{ filtersCount }}
              </v-avatar>
            </template>
            {{ $t('subscriptions.views.SubscriptionProjects.filters') }}
          </AppButton>
        </div>
      </v-col>
      <v-col class="text-right">
        <AppDownloadFile :link="exportLink">
          <template #default="{submitForm}">
            <AppButton color="secondary"
                       @click="submitForm"
            >
              <font-awesome-icon class="mr-2" :icon="['fal', 'file-export']"></font-awesome-icon>
              {{ $t('subscriptions.views.SubscriptionProjects.export') }}
            </AppButton>
          </template>
        </AppDownloadFile>
      </v-col>
    </v-row>
    <v-row>
      <DataTableFiltersSummary
        v-if="filtersCount > 0"
        :active-filters="activeRoomsFilters"
        class="mt-4"
        @clear-filter="clearFilter"
        @clear-all-filters="clearAllFilters"
      />
    </v-row>
    <template v-if="activeRoomsPending && !activeRooms ">
      <v-row>
        <v-col class="d-flex justify-center">
          <AppDataTableSkeletonLoader class="w-100"/>
        </v-col>
      </v-row>
    </template>
    <v-row v-if="activeRooms && !activeRoomsPending">
      <v-col>
        <v-data-table ref="SubscriptionProjectsTable"
                      class="SubscriptionProjects-table"
                      :items="filteredActiveRooms"
                      :headers="headers"
                      :footer-props="{
                        'items-per-page-options': ITEM_PER_PAGE_DEFAULT,
                      }"
                      :items-per-page="tableItemsPerPage"
                      :options.sync="options"
        >
          <template #item.type="{item}">
            <RoomTypeIcon :room="item" />
          </template>
          <template #item.cmRef="{item}">
            {{ item.cmRef || '-' }}
          </template>
          <template #item.label="{item}">
            <v-chip v-if="item.roomLabel"
                    label
                    class="white--text"
                    :color="item.roomLabel.color"
            >
              {{ item.roomLabel.label[$i18n.locale] }}
            </v-chip>
          </template>
          <template #item.creator="{item}">
            <div>{{ item.creator }}</div>
            <a :href="`mailto:${item.creatorEmail}`"
               class="SubscriptionProjects-table-creatorMail text--primary app-link"
            >
              {{ item.creatorEmail }}
            </a>
          </template>
          <template #item.createdAt="{item}">
            <DateDisplay :value="item.createdAt"/>
          </template>
          <template #item.users="{item}">
            <template v-if="item.usersCount !== -1">{{ item.usersCount }}</template>
            <template v-else>-</template>
          </template>
          <template #item.storage="{item}">
            <app-filesize-display v-if="item.storage" :value="item.storage"></app-filesize-display>
            <div v-else>-</div>
          </template>
          <template #item.signedDocuments="{item}">
            <template v-if="item.signedDocuments !== -1">{{ item.signedDocuments }}</template>
            <template v-else>-</template>
          </template>
          <template #footer.prepend>
            <v-pagination
              v-model="dataTablePage"
              :length="dataTablePageCount"
              :total-visible="7"
              :disabled="disabledPagination"
              @input="onPageChange"
            ></v-pagination>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <SubscriptionActiveProjectsFiltersPane />
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDataTableSkeletonLoader from '@/common/AppDataTableSkeletonLoader'
import AppDownloadFile from '@/common/AppDownloadFile'
import AppButton from '@/common/buttons/AppButton'
import DateDisplay from '@/common/DateDisplay'
import SearchTextField from '@/common/SearchTextField'
import { ITEM_PER_PAGE_DEFAULT } from '@/common/utils/dataTablePagination'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import { GET_ACTIVE_ROOMS } from '@/store/modules/subscription/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import DataTableFiltersSummary from '../../common/filters/DataTableFiltersSummary.vue'
import RoomTypeIcon from '../../common/RoomTypeIcon'
import { REMOVE_ACTIVE_ROOMS_FILTER, SET_ACTIVE_ROOMS_FILTERS, SET_ACTIVE_ROOMS_FILTERS_PANE_IS_OPEN } from '../../store/modules/subscription/mutation_types'
import { SUBSCRIPTION_PROJECTS_ITEMS_PER_PAGE } from '../constants.js'
import SubscriptionActiveProjectsFiltersPane from '../SubscriptionActiveProjectsFiltersPane.vue'

export default {
  name: 'SubscriptionProjects',
  components: {
    DateDisplay,
    AppDownloadFile,
    AppButton,
    SearchTextField,
    AppDataTableSkeletonLoader,
    RoomTypeIcon,
    SubscriptionActiveProjectsFiltersPane,
    DataTableFiltersSummary,
  },
  data () {
    return {
      ITEM_PER_PAGE_DEFAULT,
      headers: [
        { value: 'type', text: this.$t('subscriptions.views.SubscriptionProjects.headers.type') },
        { value: 'name', text: this.$t('subscriptions.views.SubscriptionProjects.headers.name') },
        { value: 'cmRef', text: this.$t('subscriptions.views.SubscriptionProjects.headers.cmRef') },
        { value: 'label', text: this.$t('subscriptions.views.SubscriptionProjects.headers.label') },
        { value: 'creator', text: this.$t('subscriptions.views.SubscriptionProjects.headers.creator') },
        { value: 'createdAt', text: this.$t('subscriptions.views.SubscriptionProjects.headers.creationDate'), align: 'center' },
        { value: 'users', text: this.$t('subscriptions.views.SubscriptionProjects.headers.users'), align: 'center' },
        { value: 'storage', text: this.$t('subscriptions.views.SubscriptionProjects.headers.size'), align: 'center' },
        { value: 'signedDocuments', text: this.$t('subscriptions.views.SubscriptionProjects.headers.signedDocuments'), align: 'center' },
      ],
      options: {},
      dataTablePage: 1,
      dataTablePageCount: 1,
      disabledPagination: false,
      searchQuery: '',
      SUBSCRIPTION_PROJECTS_ITEMS_PER_PAGE,
    }
  },
  computed: {
    ...mapState('subscription', ['activeRooms', 'activeRoomsPending', 'activeRoomsFilters']),
    ...mapGetters('user', ['currentUserId']),
    filtersCount () {
      return this.activeRoomsFilters.length
    },
    filteredActiveRooms () {
      let filteredActiveRooms = cloneDeep(this.activeRooms.data)
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase()
        filteredActiveRooms = filteredActiveRooms.filter(activeRoom => {
          return activeRoom.name.toLowerCase().includes(query) ||
            activeRoom.cmRef?.toLowerCase().includes(query) ||
            activeRoom.creator.toLowerCase().includes(query) ||
            activeRoom.creatorEmail.toLowerCase().includes(query)
        })
      }

      const memberFilters = this.activeRoomsFilters.filter(filter => filter.category.key === 'members')
      if (memberFilters.length > 0) {
        filteredActiveRooms = filteredActiveRooms.filter(room => {
          return memberFilters.some((filterMember) => room.users.some((roomUser) => filterMember.value.key === roomUser.fullName))
        })
      }

      const labelFilters = this.activeRoomsFilters.filter(filter => filter.category.key === 'labels')
      if (labelFilters.length > 0) {
        filteredActiveRooms = filteredActiveRooms.filter(room => {
          return labelFilters.some((filterLabel) => room.label && (room.roomLabel.id === filterLabel.value.key.id))
        })
      }

      const projectTypeFilters = this.activeRoomsFilters.filter(filter => filter.category.key === 'projectType')
      if (projectTypeFilters.length > 0 && projectTypeFilters.length !== 2) {
        if (projectTypeFilters[0].value.key === 'project') {
          filteredActiveRooms = filteredActiveRooms.filter(room => { return !room.isDataroom })
        }
        if (projectTypeFilters[0].value.key === 'dataroom') {
          filteredActiveRooms = filteredActiveRooms.filter(room => { return room.isDataroom })
        }
      }

      const createdAtFilter = this.activeRoomsFilters.find(filter => filter.category.key === 'createdAt')
      if (createdAtFilter) {
        const filterFrom = createdAtFilter.value.key[0]
        const filterTo = createdAtFilter.value.key[1]

        if (filterFrom && !filterTo) {
          filteredActiveRooms = filteredActiveRooms.filter(room => {
            return dayjs(room.createdAt).isSameOrAfter(dayjs(filterFrom))
          })
        } else if (filterFrom && filterTo) {
          filteredActiveRooms = filteredActiveRooms.filter(room => {
            return dayjs(room.createdAt).isBetween(dayjs(filterFrom), dayjs(filterTo), 'day', '[]')
          })
        }
      }

      return filteredActiveRooms
    },
    exportLink () {
      return `${process.env.VUE_APP_API_URL}/subscription/download/rooms-excel`
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, SUBSCRIPTION_PROJECTS_ITEMS_PER_PAGE)
      const itemsPerPage = itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : 50
      this.setPagination(itemsPerPage)
      return itemsPerPage
    },
  },
  watch: {
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, SUBSCRIPTION_PROJECTS_ITEMS_PER_PAGE))) {
          this.setPagination(newValue.itemsPerPage)
          setUserSetting(this.currentUserId, SUBSCRIPTION_PROJECTS_ITEMS_PER_PAGE, newValue.itemsPerPage)
        }
      },
    },
  },
  created () {
    this.prepareGetActiveRooms()
  },
  methods: {
    ...mapMutations('subscription', [SET_ACTIVE_ROOMS_FILTERS_PANE_IS_OPEN, SET_ACTIVE_ROOMS_FILTERS, REMOVE_ACTIVE_ROOMS_FILTER]),
    ...mapActions('subscription', [GET_ACTIVE_ROOMS]),
    async prepareGetActiveRooms () {
      try {
        await this.GET_ACTIVE_ROOMS()
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('subscriptions.views.SubscriptionProjects.getActiveRoomsError'),
        })
      }
    },
    setPagination (nbItemsPerPage) {
      const files = this.activeRooms.data
      const nbPages = Math.ceil(files.length / nbItemsPerPage)
      this.dataTablePage = 1
      this.dataTablePageCount = nbPages > 0 ? nbPages : 1
      if (this.dataTablePageCount === 1) {
        this.disabledPagination = true
      } else {
        this.disabledPagination = false
      }
    },
    onPageChange (page) {
      this.$refs.SubscriptionProjectsTable.$options.propsData.options.page = page
    },
    openFiltersPane () {
      this.SET_ACTIVE_ROOMS_FILTERS_PANE_IS_OPEN(true)
    },
    clearFilter (filter) {
      this.REMOVE_ACTIVE_ROOMS_FILTER(filter)
    },
    clearAllFilters () {
      this.SET_ACTIVE_ROOMS_FILTERS([])
      this.$emit('update:searchQuery', '')
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .SubscriptionProjects-table {
  background-color: transparent;
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }
  th {
    color: var(--v-accent-base) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    border-bottom: none !important;
  }
  td {
    color: var(--v-accent-base) !important;
    font-size: 14px !important;
    border-bottom: none !important;
    vertical-align: center;
    height: 48px !important;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  tbody {
    tr {
      background-color: #fff;
    }
    tr.v-data-table__expanded__content {
      box-shadow: none;
    }
  }
  .va-inherit {
    vertical-align: inherit;
  }
}
.SubscriptionProjects-table-creatorMail {
  max-width: 200px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}
::v-deep .v-data-footer {
  flex-direction: row-reverse;
}
::v-deep .v-data-footer__icons-before {
  display: none;
}

::v-deep .v-data-footer__icons-after {
  display: none;
}

::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}
</style>
