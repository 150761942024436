<template>
  <AppDialog :is-open="isDialogOpened"
             size="m"
             :ok-text="$t('common.confirm')"
             :ok-loading="isPending"
             :cancel-loading="isPending"
             @ok="onConfirm"
             @cancel="onCancel"
  >
    <template #title>{{ $t('project.signing-checklist.dialogs.PrepareSignatureDeleteInitialsTagDialog.title') }}</template>
    <template #body>
      <app-text class="mb-4" variant="small-regular">{{ $t('project.signing-checklist.dialogs.PrepareSignatureDeleteInitialsTagDialog.body') }}</app-text>
      <v-checkbox
        v-model="deleteAllTags"
        class="mt-0 pt-0 text--secondary align-top"
        :label="$t('project.signing-checklist.dialogs.PrepareSignatureDeleteInitialsTagDialog.deleteAllTagsLabel')"
        hide-details
      />
    </template>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'

export default defineComponent({
  name: 'PrepareSignatureDeleteInitialsTagDialog',
  components: {
    AppDialog,
  },
  mixins: [dialogMixin],
  data () {
    return {
      deleteAllTags: false,
    }
  },
  props: {
    isPending: {
      type: Boolean,
    },
  },
  methods: {
    onCancel () {
      this.closeDialog()
    },
    onConfirm () {
      if (this.deleteAllTags) {
        this.$emit('delete-all-tags')
      } else {
        this.$emit('delete-tag')
      }

      this.closeDialog()
    },
  },
})
</script>

<style scoped>
.v-dialog > .v-card > .v-card__subtitle {
  padding: 0;
  margin-top: 0;
}
</style>
