import {
  GET_STATUS,
} from './action_types'
import {
  SET_STATUS_PENDING,
  SET_STATUS_ERROR,
} from './mutation_types'
import service from '../../../services/maintenance.service'

export const actions = {
  async [GET_STATUS] ({ commit }) {
    commit(SET_STATUS_PENDING, true)
    try {
      return await service.getStatus()
    } catch (error) {
      if (error.response.status !== 503) {
        commit(SET_STATUS_ERROR, error)
      }
      throw error
    } finally {
      commit(SET_STATUS_PENDING, false)
    }
  },
}
