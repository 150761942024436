<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('common.delete')"
             :ok-loading="deleteTaskPending"
             :ok-disabled="deleteTaskPending"
             :cancel-disabled="deleteTaskPending"
             @cancel="closeDialog"
             @ok="prepareDeleteTask"
  >
    <template #title>
      {{ $t('project.checklist.dialogs.ChecklistTableTaskDeleteDialog.title') }}
    </template>
    <template #body>
      <div v-if="enableValidationChecklistItemDeletion"
           v-html="$t('project.checklist.dialogs.ChecklistTableTaskDeleteDialog.doubleValidationText')">
      </div>
      <template v-else>
        {{ $t('project.checklist.dialogs.ChecklistTableTaskDeleteDialog.text') }}
      </template>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { DELETE_TASK, REMOVE_TASK_FROM_SELECTION } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'ChecklistTableTaskDeleteDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('room', ['currentRoom']),
    ...mapState('checklist', ['deleteTaskPending']),
    ...mapGetters('checklist', ['selectedTasks']),
    enableValidationChecklistItemDeletion () {
      return this.currentRoom?.enableValidationChecklistItemDeletion
    },
    taskIsSelected () {
      return this.selectedTasks.some(task => task.id === this.task.id)
    },
    deleteTaskSuccess () {
      return this.enableValidationChecklistItemDeletion
             ? this.$t('project.project-settings.ProjectSettingsFeatures.checklist.pendingDeletion')
             : this.$t('project.checklist.dialogs.ChecklistTableTaskDeleteDialog.deleteTaskSuccess')
    },
  },
  methods: {
    ...mapActions('checklist', [DELETE_TASK, REMOVE_TASK_FROM_SELECTION]),
    async prepareDeleteTask () {
      try {
        await this.DELETE_TASK({
          mnemo: this.mnemo,
          taskId: this.task.id,
        })

        if (this.taskIsSelected) {
          this.REMOVE_TASK_FROM_SELECTION(this.task)
        }

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.deleteTaskSuccess,
        })

        this.$emit('onDelete')
        this.closeDialog()
      } catch (e) {
        console.error(e)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.dialogs.ChecklistTableTaskDeleteDialog.deleteTaskError'),
        })
      }
    },
  },
}
</script>
