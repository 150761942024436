<template>
  <div class="px-4 pb-2">
    <template v-if="importedFiles.length !== 0">
      <div v-if="addFromTemplate" class="mb-4">
        <app-text variant="large-bold" class="grey--text text--darken-4">
          {{$t('project.signing-checklist.add.step2.SigningChecklistAddStep2.fromTemplateTitle')}}
        </app-text>
        <app-text variant="small-regular" class="grey--text text--darken-2">
          {{$t('project.signing-checklist.add.step2.SigningChecklistAddStep2.fromTemplateSubtitle')}}
        </app-text>
      </div>
      <p v-else class="body-1 mb-4">
        {{$tc('project.signing-checklist.add.step2.SigningChecklistAddStep2.infoHint', importedFiles.length)}}
      </p>

      <v-expansion-panels hover>
        <template>
          <SigningChecklistAddStep2File
            v-for="(file, i) in importedFiles"
            :key="i"
            :validator="validator.importedFiles.$each[i]"
            :file="file"
            @signatureTypeChange="onsignatureTypeChange"
          >
          </SigningChecklistAddStep2File>
        </template>
      </v-expansion-panels>
    </template>
    <template v-else>
      <p class="text-center">{{$t('project.signing-checklist.add.step2.SigningChecklistAddStep2.noValidFiles')}}</p>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import { GET_ROOM_PROVIDERS } from '@/store/modules/room/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import SigningChecklistAddStep2File from './SigningChecklistAddStep2File.vue'
const { mapActions: mapRoomActions, mapState: mapRoomState } = createNamespacedHelpers('room')
const { mapState: mapSigningChecklistState } = createNamespacedHelpers('signingChecklist')
export default {
  name: 'SigningChecklistAddStep2',
  components: {
    SigningChecklistAddStep2File,
  },
  props: {
    validator: {
      type: Object,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
  metaInfo () {
    return {
      title: this.$t('project.signing-checklist.add.step2.SigningChecklistAddStep2.metaTitle'),
    }
  },
  inject: ['addFromTemplate'],
  computed: {
    ...mapRoomState(['currentRoom']),
    ...mapSigningChecklistState(['importedFiles']),
  },
  methods: {
    ...mapRoomActions([GET_ROOM_PROVIDERS]),
    onsignatureTypeChange (originalFile, signatureType) {
      if (this.importedFiles.length > 1 && this.importedFiles.every((file) => file.objectToPost.signatureType === '' || file === originalFile)) {
        let updatedSomethingElse = false
        for (const file of this.importedFiles) {
          if (file === originalFile) {
            continue
          }
          const provider = signatureType.split('.')[0]
          const maxSize = this.currentRoom.providers.find((roomProvider) => roomProvider.provider === provider).maxSize
          if (maxSize > file.size) {
            file.objectToPost.signatureType = signatureType
            updatedSomethingElse = true
          }
        }
        if (updatedSomethingElse) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.signing-checklist.add.step2.SigningChecklistAddStep2.groupsignatureTypeChange'),
          })
        }
      }
    },
  },
}
</script>
