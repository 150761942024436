<template>
  <div class="AppMultipleSelectionBar">
    <AppText class="AppMultipleSelectionBar-counter mr-1"
             variant="x-small-bold"
             as="span"
    >
      {{items.length}}
    </AppText>
    {{ $tc('common.selectedItems', items.length, { count: items.length }) }}
    <v-divider class="mx-4" vertical/>
    <!--
    Triggered on click. Bind this event to a reset selected items method
    @event deselectAll
  -->
    <div class="app-link"
         @click="$emit('deselectAll')"
    >
      {{ $t('common.deselectAll') }}
    </div>
    <div class="ml-auto">
      <!-- @slot Use this slot to add mas action buttons -->
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import AppText from '@/design-system/typography/AppText.vue'
export default defineComponent({
  name: 'AppMultipleSelectionBar',
  components: { AppText },
  props: {
    /**
     * Array of selected items
     */
    items: {
      type: Array,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.AppMultipleSelectionBar {
  display: flex;
  align-items: center;
  padding: 6px 24px;
  border: 2px solid var(--v-primary-base);
  border-radius: 8px;
  &-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-base);
  }
}
</style>

<docs>
```vue
<template>
  <AppMultipleSelectionBar :items="items">
    <template #buttons>
      <AppMultipleSelectionBarButton type="delete" />
      <AppMultipleSelectionBarButton type="delete" />
    </template>
  </AppMultipleSelectionBar>
</template>
<script>
  import AppMultipleSelectionBarButton from "@/design-system/multiple-selection-bar/AppMultipleSelectionBarButton.vue";
  export default {
    components: {
      AppMultipleSelectionBarButton,
    },
    data () {
      return {
        items: ['a', 'b', 'c']
      }
    },
  }
</script>
```
</docs>
