export const state = {
  loading: false,
  loadingPage: false,
  step: 1,
  samlCheckError: null,
  signInError: null,
  forgotPasswordPending: false,
  forgotPassword: null,
  forgotPasswordError: null,
  activateAccountPending: false,
  activateAccount: null,
  activateAccountError: null,
  resetPasswordPending: false,
  resetPassword: null,
  resetPasswordError: null,
  signinPending: false,
  signin: null,
  signinError: null,
  twoFactorError: null,
  authFromTokenPending: false,
  authFromTokenError: null,
  userTokenScope: [],
}
