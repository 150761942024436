
  import { defineComponent, PropType } from 'vue'
  import { mapState } from 'vuex'

  import AppTooltip from '@/common/AppTooltip.vue'
  import { FOLDER_ICON_COLOR } from '@/common/constants'

  import { RECENT_FOLDER, ROOT_FOLDER } from '../constants/special-folders'

  export type DocumentsSidebarFolder = {
    id: number | string;
    name: string;
    parentId?: number | string | null;
    children?: Array<DocumentsSidebarFolder>;
    numbering?: number;
    icon?: string;
  }

  const __default__ = defineComponent({
    name: 'DocumentsSidebarFolderItemContent',
    components: { AppTooltip },
    props: {
      folder: {
        type: Object as PropType<DocumentsSidebarFolder>,
        required: true,
      },
      depth: {
        type: Number,
        default: 0,
      },
      isCurrentlyViewedFolder: {
        type: Boolean,
        default: false,
      },
      areChildrenExpanded: {
        type: Boolean,
        default: false,
      },
      loadingChildren: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        hasTooltip: false,
        FOLDER_ICON_COLOR,
      }
    },
    async mounted () {
      await this.$nextTick()
      const folderNameDivElement = this.$refs.folderName as HTMLDivElement
      // Current usage has the folderName sometimes undefined. So we guard against it.
      this.hasTooltip = !!(folderNameDivElement?.offsetWidth < folderNameDivElement?.scrollWidth)
    },
    computed: {
      ...mapState(['homeLayout']),
      ...mapState('global', ['mini']),
      iconOnly (): boolean {
        return this.homeLayout && this.mini
      },
      computedIconName (): string {
        if (!this.folder.icon) {
          return this.homeLayout && this.hasChildren && this.areChildrenExpanded ? 'folder-open' : 'folder'
        } else {
          return this.folder.icon
        }
      },
      leftOffset (): string {
        const LEVEL_OFFSET = 8
        return `${this.depth * LEVEL_OFFSET}px`
      },
      hasChildren (): boolean {
        return !!this.folder.children && this.folder.children.length > 0
      },
      isFolderItemSpecial (): boolean {
        return this.folder.id === RECENT_FOLDER.id || this.folder.id === ROOT_FOLDER.id
      },
      folderName (): string {
        if (this.folder.numbering) {
          return `${this.folder.numbering} ${this.folder.name}`
        }

        return this.folder.name
      },
    },
  })

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "4332d9fd": (_vm.FOLDER_ICON_COLOR)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__