<template>
  <v-skeleton-loader type="table-thead, table-row-divider@5"/>
</template>

<script>
/**
 * Skeleton loader for data tables.
 * @displayName AppDataTableSkeletonLoader
 */
export default {
  name: 'AppDataTableSkeletonLoader',
}
</script>

<docs>
```vue
<template>
  <AppDataTableSkeletonLoader/>
</template>
```
</docs>
