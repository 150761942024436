<template>
    <div v-if="signingView" class="CerteuropeSignPages">
      <CerteuropeSignPage v-for="(page) in signingView.pages"
                          :key="`page-${page.number}`"
                          class="my-2"
                          :selected-tag="selectedTag"
                          :mnemo="mnemo"
                          :page="page"
                          v-on="$listeners"
      />
      <v-row>
        <v-col class="text-center mt-2">
          <AppButton v-if="tagsCount === 0"
                     :disabled="signTagPending"
                     class="CerteuropeSignPages-finishButton"
                     :loading="confirmSignaturePending"
                     @click="onFinishClick"
          >
            {{ $t('common.finish') }}
          </AppButton>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import CerteuropeSignPage from './CerteuropeSignPage'
import AppButton from '../common/buttons/AppButton'
import { SET_VALIDATION_MODAL_IS_OPEN } from '../store/modules/certeurope-sign/mutation_types'

export default {
  name: 'CerteuropeSignPages',
  components: { AppButton, CerteuropeSignPage },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    selectedTag: {
      required: true,
    },
  },
  computed: {
    ...mapState('certeuropeSign', ['signingView', 'confirmSignaturePending', 'signTagPendingIds', 'unsignTagPendingIds']),
    tagsCount () {
      if (this.signingView && this.signingView.pages.length > 0) {
        let counter = 0
        this.signingView.pages.forEach(page => {
          counter += page.tags.filter(item => !item.isSigned && item.type !== 'optionalCheckbox').length
        })
        return counter
      }
      return null
    },
    signTagPending () {
      return this.signTagPendingIds.length > 0 || this.unsignTagPendingIds.length > 0
    },
  },
  methods: {
    onFinishClick () {
      if (this.signingView.signatureType === 'advanced') {
        this.$store.commit(`certeuropeSign/${SET_VALIDATION_MODAL_IS_OPEN}`, true)
      } else if (this.signingView.signatureType === 'simple') {
        this.$emit('confirmSimpleSignature')
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .CerteuropeSignPages-finishButton {
    animation: blink 1s infinite;
    background-color: var(--v-primary-base);
    color: white;
  }
  @keyframes blink {
    0% {
      background-color: var(--v-primary-base);
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
    }
    50% {
      background-color: #e7666a;
      box-shadow: none;
    }
    100% {
      background-color: var(--v-primary-base);
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
    }
  }
</style>
