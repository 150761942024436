export const MemberFormModel = function () {
  this.firstName = ''
  this.lastName = ''
  this.email = ''
  this.company = ''
  this.locale = ''
  this.sendInvitationNow = true
  this.withCustomInvitationMessage = false
  this.customInvitationMessage = ''
  this.askForIdCheck = true
  this.projectManagersAreHidden = false
  this.team = null
}
