<template>
<AppDialog :is-open="isDialogOpened"
           size="l"
           :ok-text="$t('common.confirm')"
           @ok="confirm"
           @cancel="closeDialog"
>
  <template #title>{{ $t('project.checklist.task.ChecklistTableStatusCloseDialog.dialogTitle') }}</template>
  <template #body>
    <v-row>
      <v-col class="text--primary">
        {{ $t('project.checklist.task.ChecklistTableStatusCloseDialog.dialogText') }}
      </v-col>
    </v-row>
  </template>
</AppDialog>
</template>

<script>
import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'

export default {
  name: 'ChecklistTableStatusCloseDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  methods: {
    confirm () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
