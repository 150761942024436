<template>
  <div class="items text--primary">
    <template v-for="(item, index) in items">
      <div v-if="item.fullName"
           :key="`MentionListItem-${index}`"
           class="item item-member no-click-outside"
           :class="{ 'is-selected': index === selectedIndex}"
           @mouseenter="selectedIndex = index"
           @click="selectItem(index, 'user', item.id)"
      >
        {{ item.fullName }}
      </div>
      <div v-if="item.name && item.name !== 'separator'"
           :key="`MentionListItem-${index}`"
           class="item item-group no-click-outside"
           :class="{ 'is-selected': index === selectedIndex}"
           @mouseenter="selectedIndex = index"
           @click="selectItem(index, 'group', item.id)"
      >
        {{ item.name }}
      </div>
      <v-divider v-if="item.name === 'separator'"
                 :key="`MentionListItem-${index}`"
                 class="separator"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'MentionList',
  props: {
    items: {
      type: Array,
      required: true,
    },
    command: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      selectedIndex: 0,
    }
  },
  watch: {
    items () {
      this.selectedIndex = 0
    },
  },
  methods: {
    onKeyDown ({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }
      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }
      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }
      return false
    },
    upHandler () {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
      if (this.items[this.selectedIndex].name === 'separator') {
        this.upHandler()
      }
    },
    downHandler () {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
      if (this.items[this.selectedIndex].name === 'separator') {
        this.downHandler()
      }
    },
    enterHandler () {
      const item = this.items[this.selectedIndex]
      if (item.hasOwnProperty('fullName')) {
        this.selectItem(this.selectedIndex, 'user', item.id)
      } else {
        this.selectItem(this.selectedIndex, 'group', item.id)
      }
    },
    selectItem (index, type, id) {
      const item = this.items[index]
      if (item && item.name !== 'separator') {
        this.command({
          id: `${type}-${id}`,
          label: item.fullName || item.name,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .items {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid var(--v-grey-lighten2);
    border-radius: 6px;
  }

  .item {
    border: none;
    padding: 4px 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    cursor: pointer;
    &.is-selected {
      background: var(--v-primary-base);
      color: white;
    }
  }
</style>
