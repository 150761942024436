<template>
  <v-col cols="12" class="px-10">
    <v-row no-gutters>
      <h2 class="text-h2 font-weight-bold">
        {{$t('profile.views.IdentityChecking.title')}}
      </h2>
    </v-row>
    <div v-if="idCheckStatePending" class="text-center">
      <v-progress-circular size="24" indeterminate color="primary" />
    </div>
    <template v-else>
      <v-row no-gutters>
        <template v-if="canCurrentUserSign">
          <p>
            <font-awesome-icon class="success--text" :icon="['fas', 'circle-check']"></font-awesome-icon>
            {{$t('profile.views.IdentityChecking.verified', { untilDate: validUntilDate })}}
          </p>
        </template>
        <template v-else>
          <v-row no-gutters class="mt-3">
            <p>{{$t('profile.views.IdentityChecking.notVerified')}}</p>
          </v-row>
          <AppButton
            class="mt-4 mx-auto"
            color="primary"
            @click="SET_SHOW_IDENTITY_CHECK_MODAL(true)"
          >
            {{$t('profile.views.IdentityChecking.verifyBtn')}}
          </AppButton>
        </template>
      </v-row>
    </template>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import AppButton from '../../common/buttons/AppButton.vue'
import { ISOToDDMMYYYY } from '../../common/utils/dates'
import { GET_ID_CHECK_STATE } from '../../store/modules/user/action_types'
import { SET_SHOW_IDENTITY_CHECK_MODAL } from '../../store/modules/user/mutation_types'
const { mapMutations, mapActions, mapState } = createNamespacedHelpers('user')

export default {
  name: 'IdentityChecking',
  components: { AppButton },
  computed: {
    ...mapState(['idCheckState', 'idCheckStatePending']),
    canCurrentUserSign () {
      return this.idCheckState && this.idCheckState.hasValidIdCheck
    },
    validUntilDate () {
      return this.canCurrentUserSign && ISOToDDMMYYYY(this.idCheckState.idCheckValidUntil)
    },
  },
  created () {
    this.GET_ID_CHECK_STATE()
  },
  methods: {
    ...mapActions([GET_ID_CHECK_STATE]),
    ...mapMutations([SET_SHOW_IDENTITY_CHECK_MODAL]),
  },
}
</script>
