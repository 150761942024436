import dayjs from 'dayjs'

import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import i18n from '../../i18n'

const localizedFormat = require('dayjs/plugin/localizedFormat')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

const DATE_FORMAT = 'YYYY-MM-DD'

/**
 * Returns a DD/MM/YYYY date
 * @param {string} date
 * @returns {string} DD/MM/YYYY date. Sample Output: "18/05/2020"
 * */
function ISOToDDMMYYYY (date) {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY')
  } else {
    return ''
  }
}
/**
 * Returns a DD-MM-YYYY date
 * @param {string} date
 * @returns {string} YYYY-MM-DD date. Sample Output: "2020-05-18"
 * */
function ISOToYYYYMMDD (date) {
  if (date) {
    return dayjs(date).format('YYYY-MM-DD')
  } else {
    return ''
  }
}

function extractOnlyDateFromDateTime (date) {
  return dayjs(date).format('YYYY-MM-DD')
}

function extractOnlyTimeFromDateTime (date) {
  return dayjs(date).format('HH:mm')
}

/**
 * Returns a duration from now
 * @param {string} date
 * @param {boolean} withoutSuffix
 * @returns {string} Duration from now. Sample Output: "4 days ago"
 * */
function ISOToRelativeTime (date, withoutSuffix = false) {
  if (date) {
    return dayjs(date).fromNow(withoutSuffix)
  } else {
    return ''
  }
}

/**
 * Returns a shortened date
 * @param {string} date
 * @returns {string} Shortened date. Sample Output: "16 Nov 2020"
 * */
function ISOToShortenedDate (date) {
  if (date) {
    return dayjs(date).format('ll')
  } else {
    return ''
  }
}

function ISOToShortDate (date) {
  if (date) {
    return `${dayjs(date).format('ll')} ${i18n.t('common.dateUtils.preposition')} ${dayjs(date).format('LT')}`
  } else {
    return ''
  }
}

function dateTag (date) {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY')
  } else {
    return ''
  }
}

function dateToStringDateTime (date) {
  if (!date) {
    return null
  }

  const dayjsDate = dayjs(date)
  return dayjsDate.isValid()
    ? dayjsDate.format('YYYY-MM-DD HH:mm:ss')
    : null
}

function formatDateValue (dateValue) {
  if (!Array.isArray(dateValue)) { return ISOToShortenedDate(dateValue) }

  if (dateValue.length === 1) {
    return ISOToShortenedDate(dateValue[0])
  } else {
    const dateRange = dateValue.map(date => ISOToShortenedDate(date)).join(' - ')
    return dateRange
  }
}

function getRelativePastDateRange (number, timeUnit) {
  const today = dayjs()
  const dayOfXTimeAgo = today.subtract(number, timeUnit)
  const endDate = ISOToYYYYMMDD(today)
  const startDate = ISOToYYYYMMDD(dayOfXTimeAgo)
  return { begin: startDate, end: endDate }
}

export {
  DATE_FORMAT,
  ISOToDDMMYYYY,
  ISOToRelativeTime,
  ISOToShortDate,
  ISOToShortenedDate,
  ISOToYYYYMMDD,
  dateTag,
  dateToStringDateTime,
  extractOnlyDateFromDateTime,
  extractOnlyTimeFromDateTime,
  formatDateValue,
  getRelativePastDateRange,
}
