<template>
  <div>
    <div class="d-flex align-center my-2">
      <ClosdFildersIcon
        style="height: 23px"
        :document="item"
        :signed-file="item.objectToPost.fileIsSigned"
      />
      <span style="min-width: 0" class="font-weight-bold ml-2">
        <template v-if="edit">
          <div class="d-flex align-center white">
            <app-text-field
              ref="nameInput"
              v-model="item.objectToPost.fileName"
              dense
              autofocus
              maxlength="100"
              @keydown.enter="edit = false"
              @blur="edit = false"
            />
          </div>
        </template>
        <template v-if="!edit">
          <AppTooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <template v-if="!item.signedOnClosd">
                <div class="m-1" v-bind="attrs" v-on="on">
                  <div
                    class="Closd-hoverArea step2-cell-name"
                    style="box-sizing: border-box;"
                    @click="edit = true"
                  >
                    {{ item.objectToPost.fileName }}
                  </div>
                </div>
              </template>
              <div
                v-if="item.signedOnClosd"
                class="step2-cell-name"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.objectToPost.fileName }}
              </div>
            </template>
            <span>{{ item.objectToPost.fileName }}</span>
          </AppTooltip>
        </template>
      </span>
    </div>
    <div class="my-2">
      <v-switch
        :input-value="item.objectToPost.fileIsSigned"
        :label="$t('archives.steps.ArchivesAddStep2TableCellName.lblIsSigned')"
        :disabled="item.signedOnClosd"
        class="cell-switch"
        @change="handleFileIsSignedChange"
      ></v-switch>
    </div>
  </div>
</template>

<script>
import AppTextField from '../../common/AppTextField.vue'
import AppTooltip from '../../common/AppTooltip'
import ClosdFildersIcon from '../../common/filders/ClosdFildersIcon'
export default {
  name: 'ArchivesAddStep2TableCellName',
  components: {
    ClosdFildersIcon,
    AppTextField,
    AppTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      edit: false,
    }
  },
  methods: {
    handleFileIsSignedChange (value) {
      this.item.objectToPost.fileIsSigned = value
      if (!value) {
        this.item.objectToPost.fileSigningDate = ''
        this.item.objectToPost.fileSigners = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cell-switch ::v-deep .v-label {
  font-size: 13px;
  white-space: nowrap;
}

.step2-cell-name {
  display: -webkit-box;
  // Seems like it got reluctantly supported everywhere as of 2019
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
