export const state = {
  projectTypeQuoteRequestDialog: {
    isOpen: false,
  },
  restoreRoomDialog: null,
  subscriptionPending: false,
  subscriptionBrandingPending: false,
  subscription: null,
  subscriptionBranding: null,
  subscriptionError: null,
  subscriptionInvoices: null,
  subscriptionInvoicesError: null,
  subscriptionInvoicesPending: false,
  subscriptionUsersPending: false,
  subscriptionUsers: null,
  subscriptionUsersError: null,
  patchSubAdminPendingIds: [],
  patchSubAdminError: null,
  patchSubAdminBillingPendingIds: [],
  patchSubAdminBillingError: null,
  patchSubAdminUserPendingIds: [],
  patchSubAdminUserError: null,
  deleteUserPending: false,
  deleteUserError: null,
  postNewSubscriberPending: false,
  postNewSubscriberError: null,
  activeRoomsPending: false,
  activeRoomsFiltersPaneIsOpen: false,
  activeRoomsFilters: [],
  closedRoomsFiltersPaneIsOpen: false,
  subscriptionPaneIsOpen: false,
  deleteTeamDialog: {
    isOpen: false,
    subscriptionTeam: null,
  },
  closedRoomsFilters: [],
  activeRooms: null,
  activeRoomsError: null,
  closedRooms: null,
  closedRoomsError: null,
  closedRoomsPending: false,
  postSubscriptionTeamPending: false,
  patchSubscriptionTeamPending: false,
  selectedSubscriptionTeam: null,
  subscriptionTeamsPending: false,
  subscriptionTeams: null,
  subscriptionTeamsError: null,
  downloadInvoicesError: null,
  downloadInvoicesPending: false,
  quoteRequestPending: false,
  restoreRoomError: null,
  restoreRoomPending: false,
  seats: null,
  seatsError: null,
  seatsPending: false,
  deleteSubscriptionTeamPending: false,
}
