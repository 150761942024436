<template>
  <v-text-field
    ref="input"
    v-model="computedValue"
    outlined
    :hide-details="hideDetails"
    clear-icon="fal fa-xmark"
    dense
    :color="color"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <slot :name="slot" />
    </template>
  </v-text-field>
</template>

<script>
/**
 * A wrapper around the text field with default styling and custom default behavior
 * All Vuetify props for the text field component are usable.
 * @see https://vuetifyjs.com/en/api/v-text-field/#props
 */
export default {
  name: 'AppTextField',
  inheritAttrs: false,
  props: {
    /**
     * @model
     * The input’s value
     */
    value: {
      required: true,
    },
    /**
     * Applies specified color to the control
     * @default blue darken-2
     */
    color: {
      default: 'blue darken-2',
    },
    /**
     * Hides hint and validation errors
     */
    hideDetails: {
      default: 'auto',
    },
  },
  computed: {
    computedValue: {
      get () {
        return this.value
      },
      set (newValue) {
        /**
         * The updated bound model
         *
         * @property {string} newValue new value set
         */
        this.$emit('input', newValue)
      },
    },
  },
}
</script>

<docs>
```vue
<template>
  <AppTextField v-model="value"
                placeholder="Type something"
                label="My text input"
  />
</template>
<script>
  export default {
    data () {
      return {
        value: ''
      }
    },
  }
</script>
```
</docs>
