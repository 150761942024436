<template>
  <div>
    <div>
        <v-radio-group v-model="selectedShareType"
                       class="my-0"
                       hide-details
        >
          <v-radio
            v-if="isCurrentUserPm"
            :value="SHARE_TYPE_OPTIONS.PUBLIC"
            class="qa-share-radio"
            active-class="qa-share-radio--active"
          >
            <template #label>
              <div class="d-flex flex-column text--primary ml-2">
                <p class="custom-label-title">{{$t('project.question-answer.QuestionAnswerAddShare.publicTitle')}}</p>
                <div class="caption text--secondary">{{$t('project.question-answer.QuestionAnswerAddShare.publicSubtitle')}}</div>
              </div>
            </template>
          </v-radio>
          <v-radio :value="SHARE_TYPE_OPTIONS.CUSTOM"
                   class="qa-share-radio"
                   active-class="qa-share-radio--active"
          >
            <template #label>
              <div class="d-flex flex-column text--primary ml-2">
                <p class="custom-label-title">{{$t('project.question-answer.QuestionAnswerAddShare.customTitle')}}</p>
                <div class="caption text--secondary">{{$t('project.question-answer.QuestionAnswerAddShare.customSubtitle')}}</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
    </div>

    <div v-if="selectedShareType === SHARE_TYPE_OPTIONS.CUSTOM"
         class="mt-4"
    >
      <DocumentSharePicker :force-pm-selection="true" :force-current-user-selection="true"/>
    </div>

    <v-checkbox v-model="customMessageIsEnabled"
                hide-details
                class="mt-4 pt-0"
                :style="{'margin-left': '26px'}"
                :label="$t('project.question-answer.QuestionAnswerAddShare.sendCustomMessage')"
    />

    <div v-if="customMessageIsEnabled" class="mt-4">
      <VisualTextEditor v-model="notifyMessage"
                        qa-variant
                        counter="footer"
                        :errorMessages="notifyMessageErrors"
                        :placeholder="$t('project.question-answer.QuestionAnswerAddShare.customMessagePlaceholder')"
                        :max-length="NOTIFY_MESSAGE_MAX_LENGTH"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import DocumentSharePicker from '@/common/document-share/DocumentSharePicker'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'
import { maxEscapedLength } from '@/common/utils/validators'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import {
  INIT_SELECTED_GROUPS,
  INIT_SELECTED_MEMBERS,
  RESET_SELECTED_GROUPS_AND_MEMBERS,
} from '@/store/modules/document-share-picker/action_types'

const NOTIFY_MESSAGE_MAX_LENGTH = 3000

export const validations = {
  customShareMessage: {
    maxLength: maxEscapedLength(NOTIFY_MESSAGE_MAX_LENGTH),
  },
}

export default {
  name: 'QuestionAnswerAddShare',
  components: { DocumentSharePicker, VisualTextEditor },
  props: {
    customMessage: {
      type: String,
      required: true,
    },
    customMessageValidation: {
      type: Object,
      required: true,
    },
    shareType: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      NOTIFY_MESSAGE_MAX_LENGTH,
      SHARE_TYPE_OPTIONS,
      customMessageIsEnabled: false,
    }
  },
  computed: {
    ...mapState('groups', ['groups']),
    ...mapGetters('room', ['roomMnemo', 'isCurrentUserPm']),
    ...mapGetters('user', ['currentUserId']),
    selectedShareType: {
      get () { return this.shareType },
      set (value) { this.$emit('update:shareType', value) },
    },
    notifyMessage: {
      get () { return this.customMessage },
      set (value) { this.$emit('update:customMessage', value) },
    },
    notifyMessageLength () {
      return this.customMessage.replace(/(<([^>]+)>)/gi, '').length
    },
    notifyMessageErrors () {
      const errors = []
      !this.customMessageValidation.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.question-answer.QuestionAnswerAddShare.customMessage'), maxLength: NOTIFY_MESSAGE_MAX_LENGTH }))
      return errors
    },
  },
  watch: {
    customMessageIsEnabled (value) {
      if (!value) {
        this.notifyMessage = ''
      }
    },
  },
  beforeDestroy () {
    this.RESET_SELECTED_GROUPS_AND_MEMBERS()
  },
  async created () {
    await this.GET_GROUPS(this.roomMnemo)
    const pmGroup = this.groups.find(g => g.isPm)
    if (pmGroup) {
      this.INIT_SELECTED_GROUPS([pmGroup])
    }
    // Add current user to selected members
    this.INIT_SELECTED_MEMBERS([{ id: this.currentUserId }])
  },
  methods: {
    ...mapActions('documentSharePicker', [RESET_SELECTED_GROUPS_AND_MEMBERS, INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS]),
    ...mapActions('groups', ['GET_GROUPS']),
  },
}
</script>

<style scoped lang="scss">
.qa-share-radio {
  padding: 16px 24px;

  &--active {
    background-color: var(--v-primary-lighten4);
  }
}

.custom-label-title {
  font-size: 16px;
  font-weight: 500;
}
</style>
