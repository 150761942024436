<template>
  <v-chip :color="isInvalid ? 'error' : 'grey-lighten-3'"
          close
          small
          truncate-length="24"
          @click:close="removeCategory"
  >
    <div :style="{'max-width': '300px'}" class="text-truncated">
      {{category.name}}
    </div>
  </v-chip>
</template>

<script>
export default {
  name: 'QuestionAnswerCategoryChip',
  props: {
    categoryForm: {
      type: Object,
      required: true,
    },
  },
  computed: {
    category () {
      return this.categoryForm.$model
    },
    isInvalid () {
      return this.categoryForm.$invalid
    },
  },
  methods: {
    removeCategory () {
      this.$emit('remove-category')
    },
  },
}
</script>
