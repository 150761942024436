import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import {
  GET_FILE,
  GET_WATERMARKED_FILE,
  CANCEL_ALL_WATERMARKED_REQUEST_PENDING,
} from './action_types'
import {
  SET_FILE_PENDING,
  REMOVE_FILE_PENDING,
  SET_FILE_ERROR,
  SET_WATERMARKED_FILE,
  SET_WATERMARKED_FILE_PENDING,
  REMOVE_WATERMARKED_FILE_PENDING,
  SET_WATERMARKED_FILE_ERROR,
  RESET_WATERMARKED_FILE_WITH_FILE_ID,
  SET_WATERMARKED_REQUEST_PENDING,
  REMOVE_WATERMARKED_REQUEST_PENDING, RESET_WATERMARKED_FILE,
} from './mutation_types'
import filesService from '../../../services/files.service'

export const actions = {
  [CANCEL_ALL_WATERMARKED_REQUEST_PENDING] ({ state, commit }) {
    state.watermarkedRequestPending.forEach(req => {
      req.cancelToken.cancel()
      commit(RESET_WATERMARKED_FILE)
      commit(REMOVE_WATERMARKED_REQUEST_PENDING, req.identifier)
    })
  },
  async [GET_WATERMARKED_FILE] ({ state, commit }, { mnemo, fileId, data }) {
    const identifier = uuidv4()
    commit(SET_WATERMARKED_FILE_PENDING, { fileId, data, identifier })
    try {
      const identifiedRequest = {
        identifier,
        cancelToken: null,
      }
      commit(SET_WATERMARKED_REQUEST_PENDING, identifiedRequest)
      const response = await filesService.getWatermarkedFile(mnemo, fileId, data, identifiedRequest)
      if (state.watermarkedFile.fileId === fileId && state.watermarkedDialogData.isOpen) {
        commit(SET_WATERMARKED_FILE, response.data)
      } else if (!state.watermarkedFile.fileId) {
        commit(RESET_WATERMARKED_FILE_WITH_FILE_ID, fileId)
        commit(SET_WATERMARKED_FILE, response.data)
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        commit(SET_WATERMARKED_FILE_ERROR, error)
        throw error
      }
    } finally {
      commit(REMOVE_WATERMARKED_FILE_PENDING, identifier)
      commit(REMOVE_WATERMARKED_REQUEST_PENDING, identifier)
    }
  },
  async [GET_FILE] ({ commit, state }, { mnemo, id }) {
    commit(SET_FILE_PENDING, id)
    try {
      const { data } = await filesService.getFile(mnemo, id)
      return data
    } catch (error) {
      commit(SET_FILE_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_FILE_PENDING,
        state.filePendingIds.indexOf(id))
    }
  },
}
