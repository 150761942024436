<template>
    <div>
      <AppTooltip top :disabled="isMainTooltipDisabled">
        <template #activator="{attrs, on}">
          <div :id="`tag-${tag.id}`"
               ref="PrepareSignatureTag"
               class="PrepareSignatureTag"
               :class="[`PrepareSignatureTag--${tag.type}`, {'PrepareSignatureTag--pointerEventDisabled': pointerEventDisabled}]"
               draggable="true"
               :style="{...computedTagPosition, ...computedTagStyles}"
               v-bind="attrs"
               v-on="on"
               @dragstart="dragStart"
               @dragend="dragEnd"
          >
            <template v-if="tag.type === tagsTypes.SIGN">
              <font-awesome-icon v-if="isWitness" :icon="['far', 'link']" :style="computedWitnessTagStyles"></font-awesome-icon>
              {{ computedSignerName }}
              <font-awesome-icon class="PrepareSignatureTag-icon mx-1" :icon="['far', 'signature']"></font-awesome-icon>
            </template>
            <template v-if="tag.type === tagsTypes.INITIAL">
              {{ computedSignerInitials }}
              <AppTooltip top>
                <template #activator="{attrs, on}">
                  <AppButton class="PrepareSignatureTag-duplicate white"
                             icon
                             :disabled="isPending"
                             v-bind="attrs"
                             v-on="on"
                             @click="onDuplicateTag"
                  >
                    <font-awesome-icon :icon="['far', 'clone']"></font-awesome-icon>
                  </AppButton>
                </template>
                <span>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.duplicateTagTooltip') }}</span>
              </AppTooltip>
            </template>
            <template v-if="tag.type === tagsTypes.DATE">
              {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.signatureDate') }}
            </template>
            <template v-if="tag.type === tagsTypes.CERTIFIED">
              {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.certified', { signer: computedSignerName }) }}
            </template>
            <template v-if="tag.type === tagsTypes.NAME">
              {{ computedSignerName }}
            </template>
            <template v-if="tag.type === tagsTypes.TEXT">
              <template v-if="customInstructions">
                <VueResizable :min-width="tagMinWidth"
                              :width="tag.width || tagMinWidth"
                              :height="tag.height"
                              :max-width="tagMaxWidth"
                              :max-height="tagMaxHeight"
                              class="d-flex flex-column justify-space-between"
                              :active="['r']"
                              @resize:end="onResizeEnd(true)"
                >
                  <AppTooltip top>
                    <template #activator="{on, attrs}">
                      <div v-bind="attrs"
                           v-on="on"
                      >
                        <v-textarea ref="textFieldMessage"
                                    v-model="computedTextFieldMessage"
                                    hide-details
                                    :row-height="19"
                                    :rows="1"
                                    color="blue darken-2"
                                    no-resize
                                    @input="onInput"
                        />
                      </div>
                    </template>
                    <div>
                      <div>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.signer', { name: computedSignerName }) }}</div>
                      <div>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.resizeTooltip') }}</div>
                    </div>
                  </AppTooltip>
                  <AppTooltip top>
                    <template #activator="{on, attrs}">
                      <div ref="textFieldSwitch"
                           class="textFieldLabelWrapper"
                           v-bind="attrs"
                           v-on="on"
                      >
                        <v-switch v-model="customInstructions"
                                  :label="$t('project.signing-checklist.prepare-signature.PrepareSignatureTag.freeTextSwitchLabel')"
                                  class="PrepareSignatureTag--freeText-switch mt-0 mb-0 pl-2 pb-2 caption"
                                  hide-details
                                  :ripple="false"
                        />
                      </div>
                    </template>
                    <span>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.customInstructionsTooltip') }}</span>
                  </AppTooltip>
                </VueResizable>
              </template>
              <template v-if="!customInstructions">
                <VueResizable :min-width="tagMinWidth"
                              :min-height="75"
                              :width="tag.width || tagMinWidth"
                              :height="tag.height || 75"
                              :max-width="tagMaxWidth"
                              :max-height="tagMaxHeight"
                              class="d-flex flex-column justify-space-between"
                              :active="['r', 'rb', 'b']"
                              @resize:end="onResizeEnd(false)"
                >
                  <AppTooltip top>
                    <template #activator="{on, attrs}">
                      <div v-bind="attrs"
                           style="flex: 1;"
                           v-on="on"
                      >
                        <v-textarea ref="textFieldTextArea"
                                    v-model="computedTextFieldMessage"
                                    hide-details
                                    height="100%"
                                    class="PrepareSignatureTag--textarea-resizable h-100"
                                    disabled
                                    :row-height="19"
                                    color="blue darken-2"
                                    :rows="2"
                                    no-resize
                        />
                      </div>
                    </template>
                    <div>
                      <div>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.signer', { name: computedSignerName }) }}</div>
                      <div>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.resizeTooltip') }}</div>
                    </div>
                  </AppTooltip>
                  <AppTooltip top>
                    <template #activator="{on, attrs}">
                      <div ref="textFieldSwitch"
                           class="textFieldLabelWrapper"
                           v-bind="attrs"
                           v-on="on"
                      >
                        <v-switch v-model="customInstructions"
                                  :label="$t('project.signing-checklist.prepare-signature.PrepareSignatureTag.freeTextSwitchLabel')"
                                  class="PrepareSignatureTag--freeText-switch mt-0 mb-0 pl-2 pb-2 caption"
                                  hide-details
                                  :ripple="false"
                        />
                      </div>
                    </template>
                    <span>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.customInstructionsTooltip') }}</span>
                  </AppTooltip>
                </VueResizable>
              </template>
            </template>
            <template v-if="tag.type === tagsTypes.TEXT_READ_ONLY">
                <VueResizable :min-width="tagMinWidth"
                              :width="tag.width || tagMinWidth"
                              :height="tag.height"
                              :max-width="tagMaxWidth"
                              :max-height="tagMaxHeight"
                              class="d-flex flex-column justify-space-between"
                              :active="['r']"
                              @resize:end="onResizeEnd(true)"
                >
                  <AppTooltip top>
                    <template #activator="{on, attrs}">
                      <div v-bind="attrs"
                          style="flex: 1;"
                          v-on="on"
                      >
                        <v-textarea ref="textFieldMessage"
                                    v-model="computedTextFieldReadOnlyMessage"
                                    hide-details
                                    :placeholder="$t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSignerTag.textFieldReadOnlyPlaceholder')"
                                    :row-height="19"
                                    :rows="1"
                                    color="blue darken-2"
                                    no-resize
                                    @input="onInput"
                        />
                      </div>
                    </template>
                    <div>
                      <div>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.signer', { name: computedSignerName }) }}</div>
                      <div>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.resizeTooltip') }}</div>
                    </div>
                  </AppTooltip>
                  <div ref="textFieldSwitch"
                        class="textFieldLabelWrapper"
                  >
                    <span class="pa-1">{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.textFieldReadOnlyLabel') }}</span>
                  </div>
                </VueResizable>
            </template>
            <AppButton v-if="tag.type === tagsTypes.INITIAL" class="PrepareSignatureTag-closeButton white"
                       icon
                       :disabled="isPending"
                       @click="onDeleteInitialsTags"
            >
              <font-awesome-icon :icon="['fal', 'xmark']"></font-awesome-icon>
            </AppButton>
            <AppButton v-else class="PrepareSignatureTag-closeButton white"
                       icon
                       :disabled="isPending"
                       @click="onDeleteTag"
            >
              <font-awesome-icon :icon="['fal', 'xmark']"></font-awesome-icon>
            </AppButton>
            <template v-if="tag.type === tagsTypes.CHECKBOX">
              <AppTooltip top>
                <template #activator="{attrs, on}">
                  <font-awesome-layers v-bind="attrs"
                                       v-on="on"
                  >
                    <font-awesome-icon :icon="['far', 'square']"></font-awesome-icon>
                    <font-awesome-icon :icon="['fas', 'asterisk']"
                                       transform="up-8 right-8"
                                       style="font-size: 10px;"
                                       class="error--text"
                    ></font-awesome-icon>
                  </font-awesome-layers>
                </template>
                <span>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.signer', { name: computedSignerName }) }}</span>
              </AppTooltip>
              <AppTooltip v-if="!isDuplicationDisabled"
                          top
              >
                <template #activator="{attrs, on}">
                  <AppButton class="PrepareSignatureTag-duplicateCheckbox white"
                             icon
                             v-bind="attrs"
                             v-on="on"
                             @click="onDuplicateCheckbox"
                  >
                    <font-awesome-icon :icon="['far', 'clone']"></font-awesome-icon>
                  </AppButton>
                </template>
                <span>{{ $t('common.duplicate') }}</span>
              </AppTooltip>
            </template>
            <template v-if="tag.type === tagsTypes.OPTIONAL_CHECKBOX">
              <AppTooltip top>
                <template #activator="{attrs, on}">
                  <font-awesome-icon :icon="['far', 'square']"
                                     class="text--primary"
                                     v-bind="attrs"
                                     v-on="on"
                  ></font-awesome-icon>
                </template>
                <span>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureTag.signer', { name: computedSignerName }) }}</span>
              </AppTooltip>
              <AppTooltip v-if="!isDuplicationDisabled"
                          top
              >
                <template #activator="{attrs, on}">
                  <AppButton class="PrepareSignatureTag-duplicateCheckbox white"
                             icon
                             v-bind="attrs"
                             v-on="on"
                             @click="onDuplicateCheckbox"
                  >
                    <font-awesome-icon :icon="['far', 'clone']"></font-awesome-icon>
                  </AppButton>
                </template>
                <span>{{ $t('common.duplicate') }}</span>
              </AppTooltip>
            </template>
            <PrepareSignatureTagAlert v-if="isTagOverlapping"
                                      :message="$t('project.signing-checklist.prepare-signature.PrepareSignatureTag.tagOverlapAlertText')"
                                      variant="warning"
            />
          </div>
        </template>
        <span>
          {{
            $t(isWitness
                ? 'project.signing-checklist.prepare-signature.PrepareSignatureTag.witness'
                : 'project.signing-checklist.prepare-signature.PrepareSignatureTag.signer', { name: computedSignerName })
          }}
        </span>
      </AppTooltip>
    </div>
</template>

<script>
import { debounce } from 'lodash-es'
import VueResizable from 'vue-resizable'
import { mapGetters } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import AppButton from '@/common/buttons/AppButton'
import { tagsTypes } from '@/common/prepare-signature/tagsTypes'
import { hexToRGB } from '@/common/utils/colors'
import PrepareSignatureTagAlert from '@/project/signing-checklist/prepare-signature/PrepareSignatureTagAlert.vue'
import { SignersColors } from '@/project/signing-checklist/prepare-signature/signers-colors'

export default {
  name: 'PrepareSignatureTag',
  components: {
    AppButton,
    AppTooltip,
    PrepareSignatureTagAlert,
    VueResizable,
  },
  props: {
    envelopeId: {
      type: Number,
      required: true,
    },
    envelopeType: {
      type: String,
      required: true,
    },
    isPending: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
    pointerEventDisabled: {
      type: Boolean,
      required: true,
    },
    signers: {
      type: Array,
      required: true,
    },
    tag: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isDuplicated: false,
      textFieldMessage: '',
      tagsTypes,
      customInstructionsValue: false,
      cursorOnDragStart: {
        x: null,
        y: null,
      },
    }
  },
  computed: {
    ...mapGetters('prepareSignature', ['envelopeTagsOverlaps']),
    sortedSigners () {
      const flattenSigners = this.signers.flat()

      const commonSigners = flattenSigners.filter(signer => signer.signatureType === 'single')
      const uniqueSigners = flattenSigners.filter(signer => signer.signatureType === 'multiple')
      const witnessSigners = flattenSigners.filter(signer => signer.signatureType === 'witness')

      return [...commonSigners, ...uniqueSigners, ...witnessSigners]
    },
    computedSignerName () {
      if (this.signer.fullName) {
        return this.signer.fullName
      }

      const signerIndex = this.sortedSigners.findIndex(signer => signer.signatureId === this.signer.signatureId)
      const numbering = `#${signerIndex + 1}`

      if (this.envelopeType === 'multiple') {
        return this.signer.signatureType === 'multiple'
          ? this.$tc('common.signerType.uniqueSigner', 1)
          : `${this.$tc('common.signerType.commonSigner', 1)} ${numbering}`
      }

      return `${this.$tc('common.signer', 1)} ${numbering}`
    },
    computedSignerInitials () {
      if (this.signer.initials) {
        return this.signer.initials
      }

      const array = this.computedSignerName.split(' ')
      let string = ''
      array.forEach(i => {
        const initial = i[0]
        string += initial === '#' ? i : i[0]
      })

      return string.toUpperCase()
    },
    computedTagPosition () {
      return {
        top: `${this.tag.top}px`,
        left: `${this.tag.left}px`,
      }
    },
    computedTextFieldMessage: {
      get () {
        return this.customInstructions
          ? this.textFieldMessage
          : this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTag.textFieldDefaultMessage', { name: this.computedSignerName })
      },
      set (value) {
        this.textFieldMessage = value
        this.tag.data = value
      },
    },
    computedTextFieldReadOnlyMessage: {
      get () {
        return this.textFieldMessage
      },
      set (value) {
        this.textFieldMessage = value
        this.tag.data = value
        this.$nextTick(() => {
          this.setTextFieldReadOnlyAutoHeight()
        })
        this.prepareTagPatch()
      },
    },
    customInstructions: {
      get () { return this.customInstructionsValue },
      set (value) {
        this.customInstructionsValue = value
        if (!value) {
          this.tag.data = null
          this.tag.height = 75
          this.$nextTick(() => {
            this.$refs.textFieldTextArea.$el.querySelector('textarea').style.height = 'auto'
            this.tag.height = this.$refs.textFieldTextArea.$el.scrollHeight + this.$refs.textFieldSwitch.getBoundingClientRect().height
          })
        } else {
          this.tag.data = this.textFieldMessage
          this.$nextTick(() => {
            this.$refs.textFieldMessage.$el.querySelector('textarea').style.height = `${this.$refs.textFieldMessage.$el.querySelector('textarea').scrollHeight}px`
            this.tag.height = this.$refs.textFieldMessage.$el.querySelector('textarea').scrollHeight + this.$refs.textFieldSwitch.getBoundingClientRect().height
            this.$refs.textFieldMessage.focus()
          })
        }
        this.prepareTagPatch()
      },
    },
    isDuplicationDisabled () {
      if (this.isPending) return true
      return this.isDuplicated
    },
    isMainTooltipDisabled () {
      return this.tag.type === tagsTypes.TEXT ||
        this.tag.type === tagsTypes.TEXT_READ_ONLY ||
        this.tag.type === tagsTypes.CHECKBOX ||
        this.tag.type === tagsTypes.OPTIONAL_CHECKBOX
    },
    signer () {
      return this.sortedSigners.find(signer => signer.signatureId === this.tag.signatureId)
    },
    computedTagColor () {
      let signerIndex
      const taggingInfo = this.signers.flat()
      const indexMultiple = taggingInfo.findIndex(s => s.signatureType === 'multiple')
      // We make sure that if there's a signatureType value of "multiple", it's put at the end of the array.
      // This ensures the list is handled the same way as the sidebar and keeps the same color order.
      // Doing it this way also avoids a sort problem encountered in V2-2535
      if (indexMultiple !== -1 && indexMultiple !== taggingInfo.length) {
        taggingInfo.push(taggingInfo.splice(indexMultiple, 1)[0])
      }
      if (this.signer.witnessFor) {
        signerIndex = taggingInfo.filter(s => !s.witnessFor).findIndex(s => s.signatureId === this.signer.witnessFor)
      } else {
        signerIndex = taggingInfo.filter(s => !s.witnessFor).findIndex(s => s === this.signer)
      }
      signerIndex = signerIndex % (Object.entries(SignersColors).length / 2)
      return SignersColors[signerIndex]
    },
    computedTagStyles () {
      if (this.computedTagColor) {
        const rgb = hexToRGB(this.computedTagColor)
        return {
          backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, .13)`,
          borderColor: this.computedTagColor,
        }
      }
      return null
    },
    computedWitnessTagStyles () {
      return { color: this.computedTagColor }
    },
    isWitness () {
      return this.signer.signatureType === 'witness'
    },
    isTagOverlapping () {
      return !!this.tagsOverlaps.find(item => item.includes(this.tag.id) && Math.max(...item) === this.tag.id && !item.includes(0))
    },
    tagsOverlaps () {
      return this.envelopeTagsOverlaps(this.envelopeId) || []
    },
    tagMaxHeight () {
      return this.page.height - this.tag.top - 4
    },
    tagMaxWidth () {
      return this.page.width - this.tag.left - 4
    },
    tagMinWidth () {
      return this.tag.type === tagsTypes.TEXT_READ_ONLY ? 240 : 220
    },
  },
  mounted () {
    if (this.tag.type === tagsTypes.TEXT && this.tag.data) {
      this.textFieldMessage = this.tag.data
      this.customInstructionsValue = true
      this.$nextTick(() => {
        if (this.$refs?.textFieldMessage?.$el) {
          this.$refs.textFieldMessage.$el.querySelector('textarea').style.height = `${this.$refs.textFieldMessage.$el.querySelector('textarea').scrollHeight}px`
        }
      })
    }
    if (this.tag.type === tagsTypes.TEXT_READ_ONLY) {
      this.textFieldMessage = this.tag.data || ''
      this.$nextTick(() => {
        this.setTextFieldReadOnlyAutoHeight()
        if (this.textFieldMessage === '' && !this.pointerEventDisabled) {
          this.$refs.textFieldMessage.focus()
        }
      })
    }
    this.$emit('tag-ready', this.tag.id)
  },
  created () {
    this.prepareTagPatch = debounce(this.prepareTagPatch, 1000)
  },
  methods: {
    dragStart (event) {
      this.$emit('drag-tag-active', { value: true, tag: this.tag })

      this.cursorOnDragStart.x = event.clientX - event.target.getBoundingClientRect().x
      this.cursorOnDragStart.y = event.clientY - event.target.getBoundingClientRect().y
      event.dataTransfer.setData('cursorOffsetX', this.cursorOnDragStart.x)
      event.dataTransfer.setData('cursorOffsetY', this.cursorOnDragStart.y)
      event.dataTransfer.setData('action', 'patch')
      event.dataTransfer.setData('tagId', this.tag.id)
    },
    dragEnd (event) {
      this.tag.top += Math.ceil(event.layerY - this.cursorOnDragStart.y)
      this.tag.left += Math.ceil(event.layerX - this.cursorOnDragStart.x)
      if (this.tag.top < 0) {
        this.tag.top = 0
      }
      if (this.tag.left < 0) {
        this.tag.left = 0
      }
      if (this.tag.left + this.$refs.PrepareSignatureTag.offsetWidth > this.page.width) {
        this.tag.left = this.page.width - this.$refs.PrepareSignatureTag.offsetWidth
      }
      if (this.tag.top + this.$refs.PrepareSignatureTag.offsetHeight > this.page.height) {
        this.tag.top = this.page.height - this.$refs.PrepareSignatureTag.offsetHeight
      }
    },
    onDeleteInitialsTags () {
      this.$emit('delete-initials-tag', {
          id: this.tag.id,
          signatureIds: [this.tag.signatureId],
          type: this.tag.type,
        },
      )
    },
    onDeleteTag () {
      this.$emit('delete-tag', this.tag.id)
    },
    onDuplicateCheckbox () {
      const tagData = {
        tagSigId: this.tag.signatureId,
        tagX: this.tag.left,
        tagY: this.tag.top + 25,
        tagWidth: this.tag.width || 17,
        tagHeight: this.tag.height || 17,
        tagType: this.tag.type,
        tagPage: this.tag.pageNumber,
        tagData: this.tag.data,
      }

      this.isDuplicated = true
      this.$emit('duplicate-checkbox', tagData)
    },
    onDuplicateTag () {
      this.$emit('duplicate-tag', this.tag.id)
    },
    onInput () {
      const textAreaElement = this.$refs.textFieldMessage.$el.querySelector('textarea')
      textAreaElement.style.height = '0px'
      textAreaElement.style.height = `${this.$refs.textFieldMessage.$el.querySelector('textarea').scrollHeight}px`
      this.tag.width = Math.max(textAreaElement.scrollWidth, this.tagMinWidth)
      this.tag.height = textAreaElement.scrollHeight + this.$refs.textFieldSwitch.getBoundingClientRect().height
      this.prepareTagPatch()
    },
    onResizeEnd (custom) {
      if (custom) {
        this.tag.width = this.$refs.textFieldMessage.$el.scrollWidth
        this.tag.height = Math.ceil((this.$refs.textFieldMessage.$el.scrollHeight + 21) / 19) * 19
      } else {
        this.tag.width = this.$refs.textFieldTextArea.$el.scrollWidth
        this.tag.height = Math.ceil((this.$refs.textFieldTextArea.$el.scrollHeight + 21) / 19) * 19
      }
      this.prepareTagPatch()
    },
    prepareTagPatch () {
      const tagData = {
        id: this.tag.id,
        tagSigId: this.tag.signatureId,
        tagX: this.tag.left,
        tagY: this.tag.top,
        tagWidth: this.tag.width,
        tagHeight: this.tag.height,
        tagType: this.tag.type,
        tagPage: this.tag.pageNumber,
        tagData: this.tag.data,
      }

      this.$emit('patch-tag', tagData)
    },
    setTextFieldReadOnlyAutoHeight () {
      if (this.$refs?.textFieldMessage?.$el) {
        const textAreaElement = this.$refs.textFieldMessage.$el.querySelector('textarea')
        textAreaElement.style.height = `${textAreaElement.scrollHeight}px`
        this.tag.height = textAreaElement.scrollHeight + this.$refs.textFieldSwitch.getBoundingClientRect().height
      }
    },
  },
}
</script>

<style scoped lang="scss">
.PrepareSignatureTag {
  position: absolute;
  background-color: rgba(150, 150, 150, .3);
  border: 2px solid var(--v-primary-base);
  border-radius: 3px;
  cursor: grab;
  &--signHere {
    width: 125px;
    height: 49px;
    font-size: 11px;
    line-height: 13.2px;
    padding: 4px;
  }
  &--initialHere {
    width: 60px;
    height: 49px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--dateSigned {
    font-size: 11px;
    padding: 5px;
  }
  &--certifiedField {
    padding: 3px;
    font-style: italic;
    font-size: 11pt;
    line-height: 18px;
  }
  &--fullName {
    padding: 0 3px;
    text-align: center;
    font-size: 12pt;
  }
  &--textField, &--textFieldReadOnly {
    & .v-textarea {
      padding-top: 0;
      margin-top: 0;
      &.v-input--is-disabled {
        color: var(--v-accent-base) !important;
      }
    }
    & ::v-deep textarea {
      font-size: 11.5pt !important;
      font-style: italic;
      line-height: 17px;
      min-height: auto;
      color: var(--v-accent-base) !important;
      padding: 2px 5px;
      &::placeholder {
        color: var(--v-accent-base);
      }
    }
    & ::v-deep .v-input__slot:before,
    ::v-deep .v-input__slot:after {
      display: none;
    }
  }
  &--checkbox,
  &--optionalCheckbox{
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    .PrepareSignatureTag-closeButton,
    .PrepareSignatureTag-duplicate,
    .PrepareSignatureTag-duplicateCheckbox {
      opacity: 1;
    }
  }
  &--pointerEventDisabled {
    pointer-events: none;
  }
}
.PrepareSignatureTag-icon {
  font-size: 18px;
}
.PrepareSignatureTag-closeButton {
  opacity: 0;
  position: absolute;
  top: 0;
  right: -25px;
  width: 22px;
  height: 22px;
  padding: 5px !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    opacity: 1;
  }
}
.PrepareSignatureTag-duplicate {
  opacity: 0;
  position: absolute;
  bottom: -10px;
  right: -25px;
  width: 22px;
  height: 22px;
  padding: 5px !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    opacity: 1;
  }
}
.PrepareSignatureTag-duplicateCheckbox {
  opacity: 0;
  position: absolute;
  top: 0;
  right: -55px;
  width: 22px;
  height: 22px;
  padding: 5px !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    opacity: 1;
  }
}
::v-deep .PrepareSignatureTag--textarea-resizable {
  * {
    height: 100%
  }
}
::v-deep .AppToast {
  margin-left: -12px;
  margin-top: -52px;

  .v-snack__content {
    padding: 4px 8px;
  }
}
.textFieldLabelWrapper {
  border-top: 1px solid var(--v-accent-base);
  display:flex;
  align-items:center;
}
</style>
