import { GetterTree } from 'vuex'

import { QA_STATUS } from '@/common/utils/qa-status'

import { QuestionAnswerState } from './state'

export const getters: GetterTree<QuestionAnswerState, QuestionAnswerState> = {
  questions: state => state.questions?.questions ?? [],
  questioners: (_, getters) => [...new Set(getters.questions.map(question => question.userName))],
  awaitingQuestions: (_, getters) => getters.questions.filter(question => question.status === QA_STATUS.TO_ANSWER),
  awaitingAnswers: (_, getters) => getters.questions.filter(question => question.status === QA_STATUS.TO_VALIDATE),
  validatedQuestions: (_, getters) => getters.questions.filter(question => question.status === QA_STATUS.VALIDATED),
  counterAwaitingQuestions: (_, getters) => getters.awaitingQuestions.length,
  counterAwaitingAnswers: (_, getters) => getters.awaitingAnswers.length,
  counterValidatedQuestions: (_, getters) => getters.validatedQuestions.length,
  counterAllQuestions: (_, getters) => getters.questions.length,
  categoriesAsObject: state => state.categories?.reduce((result, current :{id:number, name:string}) => ({ ...result, [current.id]: current.name }), {}),
}
