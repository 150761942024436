<template>
  <AppMenu v-if="menu.length" open-on-hover offset-y :menu="menu">
    <template v-slot:activator="{ on, attrs }">
      <MultiSelectBarButton v-bind="attrs"
                            v-on="on"
      >
        <span>{{ $t("project.documents.DocumentsActionBar.moreActionsButtonLabel") }}</span>
        <font-awesome-icon :icon="['fal', 'chevron-down']" size="lg" class="ml-2"></font-awesome-icon>
      </MultiSelectBarButton>
    </template>
  </AppMenu>
</template>

<script>
import AppMenu from '@/common/AppMenu.vue'
import MultiSelectBarButton from '@/common/buttons/MultiSelectBarButton'

export default {
  name: 'DocumentsActionBarMoreActionsMenu',
  components: {
    MultiSelectBarButton,
    AppMenu,
  },
  props: {
   menu: {
     type: Array,
     required: true,
   },
  },
}
</script>
