<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <div class="text-h1">{{$t('project.signed-documents.SignedDocumentsHeader.signedDocuments')}}</div>
        <p class="mt-3 mb-0 body-1 accent--text text--lighten-2">{{$t('project.signed-documents.SignedDocumentsHeader.findSignedDocuments')}}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SignedDocumentsNotarityBanner v-if="isNotarityEnabled"/>
      </v-col>
    </v-row>
    <v-row v-if="!noData"
           align="center"
    >
      <v-col cols="auto">
        <SearchTextField v-model="computedSearchQuery" :tooltipText="$t('project.signed-documents.SignedDocumentsHeader.searchPlaceholder')" />
      </v-col>
      <v-col cols="auto">
        <SignedDocumentsFiltersPane />
      </v-col>
      <v-col v-if="!noResults" class="text-right">
        <AppTooltip v-if="!$vuetify.breakpoint.mobile" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="display === DISPLAY.grid ? 'primary' : '#333'"
              icon
              v-bind="attrs"
              @click="$emit('update:display', DISPLAY.grid)"
              v-on="on"
            >
              <v-icon size="24">fal fa-border-all</v-icon>
            </v-btn>
          </template>
          <span>{{$t('project.signed-documents.SignedDocumentsHeader.gridDisplay')}}</span>
        </AppTooltip>
        <AppTooltip v-if="!$vuetify.breakpoint.mobile" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="display === DISPLAY.table ? 'primary' : '#333'"
              icon
              class="mx-1"
              v-bind="attrs"
              @click="$emit('update:display', DISPLAY.table)"
              v-on="on"
            >
              <v-icon size="24">fal fa-list</v-icon>
            </v-btn>
          </template>
          <span>{{$t('project.signed-documents.SignedDocumentsHeader.tableDisplay')}}</span>
        </AppTooltip>
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col v-if="display === DISPLAY.grid && filteredSignedDocuments.length > 0" cols="12" lg="auto">
        <v-checkbox v-model="computedSelectAll"
                    :label="$t('project.signed-documents.SignedDocumentsHeader.selectAll')"
                    color="primary"
                    hide-details
                    class="SignedDocumentsHeader-checkbox mt-0"
                    :indeterminate="selectedSignedDocuments.length > 0 && selectedSignedDocuments.length < filteredSignedDocuments.filter(signedDocument => signedDocument.signedDocumentURL).length"
        />
      </v-col>
      <v-col cols="12" lg>
        <SignedDocumentsMultiSelectBar v-if="selectedSignedDocuments.length > 0"
                                       :selection="selectedSignedDocuments"
                                       @deselectAll="computedSelectAll = false"
                                       @shareOriginal="$emit('shareOriginal', $event)"
                                       @selectedSignature="selectedSignatures = $event"
                                       v-on="$listeners"
        />
      </v-col>
    </v-row>
    <v-row v-if="hasFilters">
      <v-col>
        <SignedDocumentsHeaderFilters/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash-es'
import { mapState, mapActions, mapGetters } from 'vuex'

import SignedDocumentsNotarityBanner from '@/project/signed-documents/SignedDocumentsNotarityBanner.vue'

import SignedDocumentsFiltersPane from './pane/SignedDocumentsFiltersPane'
import SignedDocumentsHeaderFilters from './SignedDocumentsHeaderFilters'
import SignedDocumentsMultiSelectBar from './SignedDocumentsMultiSelectBar'
import AppTooltip from '../../common/AppTooltip'
import SearchTextField from '../../common/SearchTextField'
import { DISPLAY } from '../../projects/display'

export default {
  name: 'SignedDocumentsHeader',
  components: {
    SignedDocumentsNotarityBanner,
    SignedDocumentsHeaderFilters,
    SearchTextField,
    SignedDocumentsFiltersPane,
    SignedDocumentsMultiSelectBar,
    AppTooltip,
  },
  props: {
    display: {
      type: String,
      required: true,
    },
    selectedSignedDocuments: {
      type: Array,
      required: true,
    },
    noData: {
      type: Boolean,
      required: true,
    },
    noResults: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      DISPLAY,
      selectAll: false,
    }
  },
  computed: {
    ...mapState('signedDocuments', ['signedDocuments', 'searchQuery', 'filters']),
    ...mapGetters('signedDocuments', ['filteredSignedDocuments']),
    ...mapGetters('room', ['isNotarityEnabled']),
    computedSelectAll: {
      get () {
        return this.selectedSignedDocuments.length === this.filteredSignedDocuments.filter(signedDocument => signedDocument.signedDocumentURL).length && this.filteredSignedDocuments.length !== 0
      },
      set (value) {
        if (value) {
          this.$emit('update:selectedSignedDocuments', cloneDeep(this.filteredSignedDocuments.filter(signedDocument => signedDocument.signedDocumentURL)))
          this.selectAll = true
        } else {
          this.$emit('update:selectedSignedDocuments', [])
          this.selectAll = false
        }
      },
      selectedSignatures: {
        get () {
          return this.selectedSignedDocuments
        },
        set (val) {
          this.$emit('selectedSignature', val)
        },
      },
    },
    computedSearchQuery: {
      get () { return this.searchQuery },
      set: debounce(function (val) {
        this.setSearchQuery(val)
      }, 1000),
    },
    hasFilters () {
      return this.filters.startDate || this.filters.endDate || this.filters.signatories.length > 0
    },
  },
  methods: {
    ...mapActions('signedDocuments', ['setSearchQuery']),
  },
}
</script>

<style scoped lang="scss">
::v-deep.SignedDocumentsHeader-checkbox {
  i {
    font-size: 18px;
  }
  label {
    font-size: 14px;
  }
}
</style>
