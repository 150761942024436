import { range } from 'lodash-es'

import { formatDraftEnvelopeTemplate } from '@/common/utils/envelopeTemplate'
import { buildGetPagesQuery } from '@/common/utils/prepareSignature'
import service from '@/services/envelopeTemplate.service'
import { StoreActions } from '@/types/store'

import { EnvelopeTemplatesActionType } from './action_types'
import { EnvelopeTemplatesMutationType } from './mutation_types'

const GET_PAGES_RETRY_DELAY = 5000

export const actions: StoreActions<EnvelopeTemplatesActionType, EnvelopeTemplatesMutationType> = {
  GET_ENVELOPE_TEMPLATE: async ({ commit }, { envelopeTemplateId }) => {
    const { data } = await service.getEnvelopeTemplateById(envelopeTemplateId)
    return data
  },
  POST_ENVELOPE_TEMPLATE: async ({ commit }, templatePostData) => {
    commit('SET_POST_ENVELOPE_TEMPLATE_PENDING', true)
    try {
      const { data } = await service.postEnvelopeTemplate(templatePostData)
      return data
    } finally {
      commit('SET_POST_ENVELOPE_TEMPLATE_PENDING', false)
    }
  },
  PATCH_ENVELOPE_TEMPLATE: async ({ commit }, { templateId, ...templatePatchData }) => {
    commit('SET_PATCH_ENVELOPE_TEMPLATE_PENDING', true)
    try {
      const { data } = await service.patchEnvelopeTemplate(templateId, templatePatchData)
      return data
    } finally {
      commit('SET_PATCH_ENVELOPE_TEMPLATE_PENDING', false)
    }
  },
  SAVE_ENVELOPE_AS_TEMPLATE: async ({ commit }, { mnemo, envelopeId, data }) => {
    commit('SET_SAVE_ENVELOPE_AS_TEMPLATE_PENDING', true)
    try {
      await service.saveEnvelopeAsTemplate(mnemo, envelopeId, data)
    } finally {
      commit('SET_SAVE_ENVELOPE_AS_TEMPLATE_PENDING', false)
    }
  },
  GET_ENVELOPE_TEMPLATES: async ({ commit }) => {
    commit('SET_GET_ENVELOPE_TEMPLATES_PENDING', true)
    try {
      const response = await service.getUserEnvelopeTemplates()
      commit('SET_GET_ENVELOPE_TEMPLATES', response.data?.envelopeTemplates)
    } finally {
      commit('SET_GET_ENVELOPE_TEMPLATES_PENDING', false)
    }
  },
  DUPLICATE_ENVELOPE_TEMPLATE: async ({ commit }, { envelopeTemplateId, data }) => {
    commit('SET_DUPLICATE_ENVELOPE_TEMPLATE_PENDING', true)
    try {
      const response = await service.duplicateEnvelopeTemplate(envelopeTemplateId, data)
      commit('ADD_ENVELOPE_TEMPLATE', response.data.envelopeTemplate)
    } finally {
      commit('SET_DUPLICATE_ENVELOPE_TEMPLATE_PENDING', false)
    }
  },
  DELETE_ENVELOPE_TEMPLATES: async ({ commit }, { ids }) => {
    commit('SET_DELETE_ENVELOPE_TEMPLATES_PENDING', true)
    try {
      await service.deleteEnvelopeTemplate(ids)
      commit('REMOVE_ENVELOPE_TEMPLATES', ids)
    } finally {
      commit('SET_DELETE_ENVELOPE_TEMPLATES_PENDING', false)
    }
  },
  RENAME_ENVELOPE_TEMPLATE: async ({ commit }, { envelopeTemplateId, data }) => {
    commit('SET_RENAME_ENVELOPE_TEMPLATE_PENDING', true)
    try {
      const response = await service.renameEnvelopeTemplate(envelopeTemplateId, data)
      commit('UPDATE_ENVELOPE_TEMPLATE', response.data.envelopeTemplate)
    } finally {
      commit('SET_RENAME_ENVELOPE_TEMPLATE_PENDING', false)
    }
  },
  RENAME_ENVELOPE_TEMPLATE_SIGNER: async ({ commit }, { envelopeTemplateId, signatureId, data }) => {
    commit('SET_RENAME_ENVELOPE_TEMPLATE_SIGNER_PENDING', true)
    try {
      await service.renameEnvelopeTemplateSigner(envelopeTemplateId, signatureId, data)
    } finally {
      commit('SET_RENAME_ENVELOPE_TEMPLATE_SIGNER_PENDING', false)
    }
  },
  GET_ENVELOPE_TEMPLATE_DISPLAY_INFORMATIONS: async ({ commit }, envelopeTemplateId) => {
    commit('SET_ENVELOPE_TEMPLATE_DISPLAY_INFORMATIONS_PENDING', true)
    try {
      const { data } = await service.getEnvelopeTemplateDisplayInformations(envelopeTemplateId)
      commit('SET_ENVELOPE_TEMPLATE_DISPLAY_INFORMATIONS', data)
      return data
    } finally {
      commit('SET_ENVELOPE_TEMPLATE_DISPLAY_INFORMATIONS_PENDING', false)
    }
  },
  GET_ENVELOPE_TEMPLATE_TAGGING_INFORMATIONS: async ({ commit }, envelopeTemplateId) => {
    commit('SET_ENVELOPE_TEMPLATE_TAGGING_INFORMATIONS_PENDING', true)
    try {
      const { data } = await service.getEnvelopeTemplateTaggingInformations(envelopeTemplateId)
      commit('SET_ENVELOPE_TEMPLATE_TAGGING_INFORMATIONS', data)
    } finally {
      commit('SET_ENVELOPE_TEMPLATE_TAGGING_INFORMATIONS_PENDING', false)
    }
  },
  DRAG_TAG_ACTIVE: async ({ commit }, { value, tag }) => {
    commit('SET_DRAG_TAG_ACTIVE_PENDING', true)
    try {
      commit('SET_DRAG_TAG_ACTIVE', { value, tag })
    } finally {
      commit('SET_DRAG_TAG_ACTIVE_PENDING', false)
    }
  },
  DELETE_ALL_TAGS: async ({ commit }, { envelopeTemplateId, data }) => {
    commit('SET_DELETE_ALL_TAGS_PENDING', true)
    try {
      await service.deleteAllTags(envelopeTemplateId, data)
    } finally {
      commit('SET_DELETE_ALL_TAGS_PENDING', false)
    }
  },
  DELETE_TAG: async ({ commit }, { envelopeTemplateId, tagId }) => {
    commit('SET_DELETE_TAG_PENDING', true)
    try {
      await service.deleteTag(envelopeTemplateId, tagId)
    } finally {
      commit('SET_DELETE_TAG_PENDING', false)
    }
  },
  DELETE_SIGNER: async ({ commit }, { envelopeTemplateId, signatureId }) => {
    commit('ADD_DELETE_SIGNER_PENDING_ID', signatureId)
    try {
      await service.deleteSigner(envelopeTemplateId, signatureId)
    } finally {
      commit('REMOVE_DELETE_SIGNER_PENDING_ID', signatureId)
    }
  },
  DUPLICATE_CHECKBOX: async ({ commit }, { envelopeTemplateId, data }) => {
    commit('SET_DUPLICATE_CHECKBOX_PENDING', true)
    try {
      return await service.postTag(envelopeTemplateId, data)
    } finally {
      setTimeout(() => {
        commit('SET_DUPLICATE_CHECKBOX_PENDING', false)
        commit('SET_LAST_SAVE', Date.now())
      }, 500)
    }
  },
  DUPLICATE_TAG: async ({ commit }, { envelopeTemplateId, tagId, data }) => {
    commit('SET_DUPLICATE_TAG_PENDING', true)
    try {
      await service.duplicateTag(envelopeTemplateId, tagId, data)
    } finally {
      commit('SET_DUPLICATE_TAG_PENDING', false)
    }
  },
  GET_PAGES_CONTENT: async ({ commit, dispatch, state }, { envelopeTemplateId, fromPage, toPage, pages }) => {
    // TODO : fix typescript error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (envelopeTemplateId !== state.envelopeTemplateDisplayInformations.envelopeId || fromPage > toPage) {
      return
    }

    const handledPageNumbers = pages ?? range(fromPage, toPage + 1)

    for (const page of handledPageNumbers) {
      commit('SET_PAGE_CONTENT_IS_LOADING', {
        pageNumber: page,
        isLoading: true,
      })
    }

    const pagesQuery = buildGetPagesQuery({ fromPage, toPage, pages })

    try {
      const response = await service.getEnvelopeTemplatePages(envelopeTemplateId, pagesQuery)

      // TODO : fix typescript error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if ((typeof response.data !== 'object' || (Array.isArray(response.data) && response.data.length === 0)) && envelopeTemplateId === state.envelopeTemplateDisplayInformations.envelopeId) {
        setTimeout(() => {
          dispatch('GET_PAGES_CONTENT', { fromPage, toPage })
        }, GET_PAGES_RETRY_DELAY)
      }

      // TODO : fix typescript error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (envelopeTemplateId === state.envelopeTemplateDisplayInformations.envelopeId) {
        const pagesToRetry = response.data.filter(page => !page.content).map((page) => page.pageNumber)

        if (pagesToRetry.length !== 0) {
          setTimeout(() => {
            dispatch('GET_PAGES_CONTENT', { pages: pagesToRetry })
          }, GET_PAGES_RETRY_DELAY)
        }

        for (const { pageNumber, content } of response.data) {
          commit('SET_PAGE_CONTENT', { pageNumber, content })
        }
      }
    } finally {
      for (const page of handledPageNumbers) {
        commit('SET_PAGE_CONTENT_IS_LOADING', {
          pageNumber: page,
          isLoading: false,
        })
      }
    }
  },
  GET_PAGE_THUMB: async ({ commit, state }, { envelopeTemplateId, pageId, pageNumber }) => {
    commit('SET_PAGE_THUMB_IS_LOADING', {
      pageNumber: pageNumber,
      isLoading: true,
    })

    try {
      const { data } = await service.getPageThumb(envelopeTemplateId, pageId)
      // TODO : fix typescript error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const thumb = data.thumb
      // TODO : fix typescript error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (thumb && envelopeTemplateId === state.envelopeTemplateDisplayInformations.envelopeId) {
        commit('SET_PAGE_THUMB', {
          pageNumber: pageNumber,
          thumb: thumb,
        })
      }
    } finally {
      commit('SET_PAGE_THUMB_IS_LOADING', {
        pageNumber: pageNumber,
        isLoading: false,
      })
    }
  },
  PATCH_TAG: async ({ commit }, { envelopeTemplateId, data }) => {
    commit('SET_PATCH_TAG_PENDING', true)
    try {
      await service.patchTag(envelopeTemplateId, data.tag.id, data)
    } finally {
      commit('SET_PATCH_TAG_PENDING', false)
      commit('SET_LAST_SAVE', Date.now())
    }
  },
  POST_TAG: async ({ commit }, { envelopeTemplateId, data }) => {
    commit('SET_POST_TAG_PENDING', true)
    try {
      return await service.postTag(envelopeTemplateId, data)
    } finally {
      commit('SET_POST_TAG_PENDING', false)
      commit('SET_LAST_SAVE', Date.now())
    }
  },
  UPDATE_DRAFT_TEMPLATE_DATA: async ({ commit }, templateId) => {
    const { data } = await service.getEnvelopeTemplate(templateId)
    const draftTemplate = formatDraftEnvelopeTemplate(data.envelopeTemplate)
    commit('SET_DRAFT_ENVELOPE_TEMPLATE', draftTemplate)
  },
}
