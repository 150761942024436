<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             :ok-text="$t('common.send')"
             :ok-disabled="!canSend"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>
      {{ $tc('project.signing-checklist.list.SigningChecklistSendDocumentsDialog.title', envelopeIds.length) }}
    </template>

    <template #body>
      <template v-if="closingSummaryPending">
        <div class="text-center">
          <AppLoader/>
        </div>
      </template>
      <template v-else>
        <SigningChecklistSummary :allow-envelope-grouping="currentRoom.allowEnvelopeGrouping"
                                 :data="data"
                                 :is-loading="closingPredictPending"
                                 is-sending-documents
        />
        <template v-if="isEnvelopeGroupingVisible">
          <SigningChecklistDocumentsBundleSection @change-toggle="onChangeEnvelopeGroupingToggle" />
        </template>
      </template>
    </template>
  </AppDialog>
</template>

<script>

import { mapMutations, mapState, createNamespacedHelpers } from 'vuex'

import AppLoader from '@/common/AppLoader'
import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'
import SigningChecklistDocumentsBundleSection from '@/project/signing-checklist/dialogs/SigningChecklistDocumentsBundleSection'
import SigningChecklistSummary from '@/project/signing-checklist/SigningChecklistSummary'
import { GET_CLOSING_PREDICT } from '@/store/modules/signing-checklist/action_types'
import { SET_SELECTED_STEPS } from '@/store/modules/signing-checklist/mutation_types'

const { mapState: mapRoomState } = createNamespacedHelpers('room')
const { mapActions } = createNamespacedHelpers('signingChecklist')

export default {
  name: 'SigningChecklistSendDocumentsDialog',
  components: { AppDialog, AppLoader, SigningChecklistDocumentsBundleSection, SigningChecklistSummary },
  inject: ['onChangeEnvelopeGrouping'],
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      data: null,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['closingPredictPending', 'closingSummaryPending', 'envelopeStats', 'envelopeStatsPending', 'sendSignStepModal']),
    ...mapRoomState(['currentRoom']),
    canSend () {
      return this.data?.documentsCount > this.totalDocumentsInError // TODO with (V2-3026): change with correct param
    },
    envelopeIds () {
      return this.sendSignStepModal.steps.map(step => step.envelope.id)
    },
    isEnvelopeGroupingVisible () {
      return process.env.VUE_APP_ENABLE_ENVELOPE_GROUPING &&
        (this.currentRoom.canAllowEnvelopeGrouping === true ||
          this.currentRoom.allowEnvelopeGrouping === true
        )
    },
    totalDocumentsInError () {
      let tot = 0
      this.data?.groups.forEach(groups => {
        if (groups.waitingCorrection) { tot += groups.waitingCorrection.length }
      })
      return tot
    },
  },
  methods: {
    ...mapActions([GET_CLOSING_PREDICT]),
    ...mapMutations('signingChecklist', [SET_SELECTED_STEPS]),
    async getData () {
      this.data = await this.GET_CLOSING_PREDICT({
        mnemo: this.mnemo,
        params: {
          envelopeIds: this.envelopeIds.join(','),
        },
      })
    },
    async onChangeEnvelopeGroupingToggle () {
      this.data = null
      await this.onChangeEnvelopeGrouping().then(() => {
        this.getData()
      })
    },
    confirmClick () {
      this.$emit('confirm')
      this.SET_SELECTED_STEPS([])
      this.closeDialog()
    },
  },
  async created () {
    this.getData()
  },
}
</script>
