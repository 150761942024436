<template>
    <div>
      <AppButton color="primary"
                 @click="openDialog = true"
      >
        {{ $t('common.upload') }}
      </AppButton>
      <AppDialog :is-open="openDialog"
                 size="l"
                 :ok-disabled="okDisabled"
                 :ok-loading="postSignedFilePending"
                 :cancel-disabled="postSignedFilePending"
                 @ok="preparePostSignedFile"
                 @cancel="resetAndClose"
      >
        <template #title>
          {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelopeUploadForUserDialog.title') }}
        </template>
        <template #body>
              <form @submit.stop.prevent="preparePostSignedFile">
                <v-row>
                  <v-col>
                    <div class="text-h4 my-2">{{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelopeUploadForUserDialog.forUser') }}</div>
                    <AppSelect v-model="selectedUser"
                               name="user"
                               :items="signatories"
                               item-text="fullName"
                               item-value="id"
                               :label="$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelopeUploadForUserDialog.forUserPlaceholder')"
                    ></AppSelect>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="text-h4">{{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelopeUploadForUserDialog.file') }}</div>
                    <v-file-input v-model="file"
                                  name="file"
                                  color="blue darken-2"
                                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </form>
        </template>
      </AppDialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import signingChecklistService from '@/services/signingChecklist.service'

import AppSelect from '../../../common/AppSelect'
import AppButton from '../../../common/buttons/AppButton'
import AppDialog from '../../../common/dialogs/AppDialog'
import { POST_SIGNED_FILE } from '../../../store/modules/signing-checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'PdfSigningPmDialogEnvelopeUploadForUserDialog',
  components: { AppDialog, AppSelect, AppButton },
  props: {
    envelope: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      openDialog: false,
      selectedUser: null,
      file: null,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['postSignedFilePending']),
    okDisabled () {
      return this.postSignedFilePending || (!this.selectedUser || !this.file)
    },
    signatories () {
      return this.envelope.signatories.flat()
    },
  },
  methods: {
    ...mapActions('signingChecklist', [POST_SIGNED_FILE]),
    async preparePostSignedFile () {
      try {
        const formData = new FormData()
        formData.append('signed_pages', this.file, this.file.name)
        formData.append('signer_id', this.selectedUser)
        await this.POST_SIGNED_FILE({
          mnemo: this.$route.params.mnemo,
          envelopeId: this.envelope.id,
          formData,
        })
        const response = await signingChecklistService.getEnvelopeSignatures(this.$route.params.mnemo, this.envelope.id)
        this.$emit('newSignatures', response.data.signatures)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelopeUploadForUserDialog.postSignedFileSuccess'),
        })
        this.resetAndClose()
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signing-checklist.pdf-signing.PdfSigningPmDialogEnvelopeUploadForUserDialog.postSignedFileError'),
        })
      }
    },
    resetAndClose () {
      this.file = null
      this.selectedUser = null
      this.openDialog = false
    },
  },
}
</script>
