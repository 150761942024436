import projectsFoldersService from '@/services/projectsFolders.service'

import {
  RENAME_PROJECTS_FOLDER,
} from './action_types'
import {
  SET_PROJECTS_FOLDER_RENAME_PENDING,
  SET_PROJECTS_FOLDER_TO_RENAME,
} from './mutation_types'

export const actions = {
  async [RENAME_PROJECTS_FOLDER] ({ commit, state }, newProjectsFolderName) {
    commit(SET_PROJECTS_FOLDER_RENAME_PENDING, true)
    try {
      await projectsFoldersService.renameProjectsFolder(state.projectsFolderToRename.id, newProjectsFolderName)
      commit(SET_PROJECTS_FOLDER_TO_RENAME, {})
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_PROJECTS_FOLDER_RENAME_PENDING, false)
    }
  },
}
