export enum SignersColors {
  '#03a9f4',
  '#8d8d8d',
  '#ff5252',
  '#b388ff',
  '#0288d1',
  '#ff4081',
  '#ff9e80',
  '#00bcd4',
  '#0097a7',
  '#8c9eff',
  '#8bc34a',
}
