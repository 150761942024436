<template>
<div>
  <AppTextField v-model.trim="model.$model"
                :label="$t('common.firstName')"
                :error-messages="firstNameErrors"
                :error="isErrored"
  />
  <AppTextFieldSuggestion v-if="displaySuggestedValue"
                          :suggestedValue="suggestedValue"
                          @validate="model.$model = suggestedValue"
  />
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AppTextField from '@/common/AppTextField'
import AppTextFieldSuggestion from '@/common/identity-checking/AppTextFieldSuggestion'
import { ID_CHECK_ERRORS } from '@/common/utils/idCheckErrors'

export default {
  name: 'FirstName',
  components: { AppTextField, AppTextFieldSuggestion },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['idCheckError']),
    ...mapGetters('user', ['hasIdCheckErrorInfo']),
    firstNameErrors () {
      const errors = []
      if (!this.model.$dirty) return errors
      !this.model.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.firstName') }))
      return errors
    },
    isErrored () {
      return this.hasIdCheckErrorInfo &&
        this.idCheckError.errors.includes(ID_CHECK_ERRORS.ERROR_FIRSTNAME_MISMATCH) &&
        this.displaySuggestedValue
    },
    suggestedValue () {
      return this.idCheckError?.suggestedValues?.firstName
    },
    displaySuggestedValue () {
      return this.suggestedValue && this.suggestedValue !== this.model.$model
    },
  },
}
</script>
