<template>
  <AppTooltip top>
    <template #activator="{attrs, on}">
      <div v-bind="attrs"
           class="d-flex align-center"
           v-on="on"
      >
        <div class="d-flex align-center pa-1" @click.stop="$emit('click')">
          <template v-if="templateIsShared">
            <app-icon icon-name="building"
                      icon-weight="far"
                      class="mr-2"
            />
            <span>{{ usersCounter }}</span>
          </template>
          <template v-else>
            <app-icon icon-name="lock-alt"
                      icon-weight="far"
                      class="mr-2"
            />
            <span>{{ $t('common.private') }}</span>
          </template>
        </div>
      </div>
    </template>
    <div>
      <span class="font-weight-medium">{{ tooltipTitle }}</span>
      <ul v-if="usersList.length">
        <li v-for="user in usersList" :key="`user-${user.id}`">
          {{ user.firstName }} {{ user.lastName }}
        </li>
      </ul>
    </div>
  </AppTooltip>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { mapGetters } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import { type ShareType } from '@/common/utils/shareTypes'

const TRANSLATION_KEY_PREFIX = 'project.signing-checklist.envelope-templates.table-columns.Sharing'

export default defineComponent({
  name: 'Sharing',
  components: { AppTooltip },
  props: {
    shareType: {
      type: String as PropType<ShareType>,
      required: true,
    },
    withUsers: {
      type: Array as PropType<Array<User>>,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('user', ['currentUserId']),
    templateIsShared (): boolean {
      return this.shareType === 'public' || this.shareType === 'custom'
    },
    tooltipTitle (): TranslateResult {
      return this.$t(`${TRANSLATION_KEY_PREFIX}.tooltipTitle.${this.shareType}`)
    },
    usersCounter (): TranslateResult | number {
      return this.shareType === 'public' ? this.$t('common.all') : this.usersList.length
    },
    usersList (): Array<User> {
      return this.withUsers.filter(user => user.id !== this.currentUserId)
    },
  },
})
</script>
