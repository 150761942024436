import Vue from 'vue'

import {
  ADD_SELECTED_ATTACHED_FILE,
  ADD_SELECTED_OBSERVERS_GROUPS,
  ADD_SELECTED_OBSERVERS_MEMBERS,
  ADD_SELECTED_RESPONSIBLE_GROUPS,
  ADD_SELECTED_RESPONSIBLE_MEMBERS,
  ADD_SELECTED_VALIDATORS_GROUPS,
  ADD_SELECTED_VALIDATORS_MEMBERS,
  REMOVE_DELETE_TASK_COMMENT_PENDING,
  REMOVE_POST_TASK_COMMENT_PENDING,
  REMOVE_REFRESH_TASK_PENDING,
  REMOVE_SELECTED_ATTACHED_FILE,
  REMOVE_SELECTED_OBSERVERS_GROUPS,
  REMOVE_SELECTED_OBSERVERS_MEMBERS,
  REMOVE_SELECTED_RESPONSIBLE_GROUPS,
  REMOVE_SELECTED_RESPONSIBLE_MEMBERS,
  REMOVE_SELECTED_VALIDATORS_GROUPS,
  REMOVE_SELECTED_VALIDATORS_MEMBERS,
  REMOVE_TASK_COMMENTS_PENDING,
  REMOVE_TASK_FOLDER_PENDING,
  RESET_ATTACHED_FILE_VERSIONS,
  RESET_CHECKLIST,
  RESET_ROLE_PICKER,
  RESET_SELECTED_ATTACHED_FILE,
  RESET_TASK_FOLDER,
  SET_ATTACHED_FILE_VERSIONS,
  SET_ATTACHED_FILE_VERSIONS_ERROR,
  SET_ATTACHED_FILE_VERSIONS_PENDING,
  SET_CHECKLIST,
  SET_CHECKLIST_ERROR,
  SET_CHECKLIST_PENDING,
  SET_CONTACT_RESPONSIBLE_ERROR,
  SET_CONTACT_RESPONSIBLE_PENDING,
  SET_CREATE_TASK_ERROR,
  SET_CREATE_TASK_PENDING,
  SET_CREATE_TITLE_ERROR,
  SET_CREATE_TITLE_PENDING,
  SET_CURRENT_TITLE_EDIT,
  SET_CURRENT_TITLE_EDIT_ERROR,
  SET_CURRENT_TITLE_EDIT_PENDING,
  SET_DELETE_FILDERS_ERROR,
  SET_DELETE_FILDERS_PENDING,
  SET_DELETE_SAVED_CHECKLIST_PENDING,
  SET_DELETE_SAVED_CHECKLIST_ERROR,
  SET_SHARE_SAVED_CHECKLIST_PENDING,
  SET_SHARE_SAVED_CHECKLIST_ERROR,
  SET_RENAME_SAVED_CHECKLIST_PENDING,
  SET_RENAME_SAVED_CHECKLIST_ERROR,
  SET_DELETE_TABLE_SELECTION_ERROR,
  SET_DELETE_CHECKLIST_DOUBLE_VALIDATION_PENDING,
  SET_DELETE_TABLE_SELECTION_PENDING,
  SET_DELETE_TASK_COMMENT_ERROR,
  SET_DELETE_TASK_COMMENT_PENDING,
  SET_DELETE_TASK_ERROR,
  SET_DELETE_TASK_MODAL,
  SET_DELETE_TASK_PENDING,
  SET_DELETE_TITLE_ERROR,
  SET_DELETE_TITLE_PENDING,
  SET_DUPLICATE_TASK_ERROR,
  SET_DUPLICATE_TASK_PENDING,
  SET_EDIT_TASK,
  SET_EDIT_TASK_ERROR,
  SET_EDIT_TASK_PENDING,
  SET_EDIT_TITLE,
  SET_EDIT_TITLE_ERROR,
  SET_EDIT_TITLE_PENDING,
  SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN,
  SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN,
  SET_CHECKLISTS_TEMPLATES_SELECTED_TAB,
  SET_IMPORT_CHECKLIST_FROM_CLOSD_ERROR,
  SET_IMPORT_CHECKLIST_FROM_CLOSD_PENDING,
  SET_IMPORT_CHECKLIST_FROM_EXCEL_PENDING,
  SET_LAST_TASK_ADDED,
  SET_LAST_TITLE_ADDED,
  SET_MOVE_FILDERS_ERROR,
  SET_MOVE_FILDERS_PENDING,
  SET_PANE,
  SET_PANE_TAB,
  SET_PARENT_CHILDREN,
  SET_POST_ATTACHED_FILE_VERSIONS_ERROR,
  SET_POST_ATTACHED_FILE_VERSIONS_PENDING,
  SET_POST_MOVE_TASK_ERROR,
  SET_POST_MOVE_TASK_PENDING,
  SET_POST_STATUS_ERROR,
  SET_POST_STATUS_PENDING,
  SET_POST_TASK_COMMENT_ERROR,
  SET_POST_TASK_COMMENT_PENDING,
  SET_REFRESH_CHECKLIST,
  SET_REFRESH_CHECKLIST_ERROR,
  SET_REFRESH_CHECKLIST_PENDING,
  SET_REFRESH_TASK,
  SET_REFRESH_TASK_ERROR,
  SET_REFRESH_TASK_PENDING,
  SET_RENAME_ATTACHED_FILE_DIALOG,
  SET_RENAME_ATTACHED_FILE_ERROR,
  SET_RENAME_ATTACHED_FILE_PENDING,
  SET_SAVED_CHECKLISTS,
  SET_SAVED_CHECKLISTS_ERROR,
  SET_SAVED_CHECKLISTS_PENDING,
  SET_SELF_SAVED_CHECKLISTS_PENDING,
  SET_SELF_SAVED_CHECKLISTS,
  SET_SELECTED_SELF_SAVED_CHECKLIST,
  SET_SELECTED_SAVED_CHECKLIST,
  SET_SAVED_CHECKLIST_PREVIEW,
  SET_SAVED_CHECKLIST_PREVIEW_PENDING,
  SET_SAVED_CHECKLIST_PREVIEW_ERROR,
  SET_LEXISNEXIS_CHECKLISTS,
  SET_LEXISNEXIS_CHECKLISTS_ERROR,
  SET_LEXISNEXIS_CHECKLISTS_PENDING,
  SET_SELECTED_LEXISNEXIS_CHECKLIST,
  SET_LEXISNEXIS_CHECKLIST_PREVIEW,
  SET_LEXISNEXIS_CHECKLIST_PREVIEW_PENDING,
  SET_LEXISNEXIS_CHECKLIST_PREVIEW_ERROR,
  SET_SAVE_CHECKLIST_DIALOG_IS_OPEN,
  SET_SAVE_CHECKLIST_ERROR,
  SET_SAVE_CHECKLIST_PENDING,
  SET_UPDATE_CHECKLIST_PENDING,
  SET_SELECTED_ATTACHED_FILE,
  SET_SELECTED_TASKS,
  SET_SELECTED_TITLES,
  SET_SHARE_ATTACHED_FILES_ERROR,
  SET_SHARE_ATTACHED_FILES_PENDING,
  SET_SHARE_CHECKLIST_BY_EMAIL_PENDING,
  SET_SHARE_TASKS_DIALOG,
  SET_SHARE_TASKS_ERROR,
  SET_SHARE_TASKS_PENDING,
  SET_TABLE_TASK_PANE,
  SET_TABLE_TASK_PANE_TAB,
  SET_TASK_COMMENTS_ERROR,
  SET_TASK_COMMENTS_PENDING,
  SET_TASK_FOLDER,
  SET_TASK_FOLDER_ERROR,
  SET_TASK_FOLDER_PENDING,
  SET_TASK_STATUS_CATEGORIES,
  SET_TASK_STATUS_CATEGORIES_ERROR,
  SET_TASK_STATUS_CATEGORIES_PENDING,
  SET_TITLE_INDENTATION_ERROR,
  SET_TITLE_INDENTATION_PENDING,
  TOGGLE_SELECTED_OBSERVERS_GROUPS,
  TOGGLE_SELECTED_OBSERVERS_MEMBERS,
  TOGGLE_SELECTED_RESPONSIBLE_GROUPS,
  TOGGLE_SELECTED_RESPONSIBLE_MEMBERS,
  TOGGLE_SELECTED_VALIDATORS_GROUPS,
  TOGGLE_SELECTED_VALIDATORS_MEMBERS,
  SET_TITLES_EXPAND_STATE,
  REMOVE_FROM_EXPAND_TASK_AFTER_DELETE,
  REMOVE_CHECKLIST_FILTER,
  SET_CHECKLIST_FILTERS,
  SET_FILTERS_PANE_IS_OPEN,
  SET_SEARCH_QUERY,
  SET_SEARCH_QUERY_PENDING,
  SET_GLOBAL_TASK_FOLDER_PENDING,
  SET_GLOBAL_TASK_FOLDER,
  SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN,
  SET_DOWNLOAD_TODO_FILES_PENDING,
  SET_CHECKLIST_VERSIONING_PANE_TAB,
  TOGGLE_CHECKLIST_VERSIONING_PANE,
} from './mutation_types'

export const mutations = {
  [SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN] (state, value) {
    state.globalAttachedFilesDialogIsOpen = value
  },
  [SET_DELETE_TASK_MODAL] (state, value) {
    state.deleteTaskModal = value
  },
  [SET_SELECTED_TASKS] (state, tasks) {
    state.selectedChecklistItems.tasks = tasks
  },
  [SET_SELECTED_TITLES] (state, titles) {
    state.selectedChecklistItems.titles = titles
  },
  [SET_CURRENT_TITLE_EDIT_PENDING] (state, value) {
    state.currentTitleEditPending = value
  },
  [SET_CURRENT_TITLE_EDIT] (state, value) {
    state.currentTitleEdit = value
  },
  [SET_CURRENT_TITLE_EDIT_ERROR] (state, value) {
    state.currentTitleEditError = value
  },
  [SET_REFRESH_CHECKLIST_PENDING] (state, value) {
    state.refreshChecklistPending = value
  },
  [SET_REFRESH_CHECKLIST] (state, value) {
    state.checklist = value
  },
  [SET_REFRESH_CHECKLIST_ERROR] (state, value) {
    state.refreshChecklistError = value
  },
  [SET_DELETE_CHECKLIST_DOUBLE_VALIDATION_PENDING] (state, value) {
    state.deleteChecklistDoubleValidationPending = value
  },
  [SET_DELETE_TABLE_SELECTION_PENDING] (state, value) {
    state.deleteTableSelectionPending = value
  },
  [SET_DELETE_TABLE_SELECTION_ERROR] (state, value) {
    state.deleteTableSelectionError = value
  },
  [SET_CHECKLIST_PENDING] (state, value) {
    state.checklistPending = value
  },
  [SET_CHECKLIST] (state, value) {
    state.checklist = value
  },
  [SET_CHECKLIST_ERROR] (state, value) {
    state.checklistError = value
  },
  [RESET_CHECKLIST] (state) {
    state.checklist = null
    state.importChecklistDialogIsOpen = null
    state.importChecklistFromTemplateDialogIsOpen = false
    state.lastTaskAdded = null
    state.lastTitleAdded = null
    state.saveChecklistDialogIsOpen = null
    state.savedChecklists = []
    state.lexisNexisChecklists = []
    state.searchQuery = ''
  },
  [SET_CREATE_TASK_PENDING] (state, value) {
    state.createTaskPending = value
  },
  [SET_CREATE_TASK_ERROR] (state, value) {
    state.createTaskError = value
  },
  [SET_EDIT_TASK_PENDING] (state, value) {
    state.editTaskPending = value
  },
  [SET_EDIT_TASK_ERROR] (state, value) {
    state.editTaskError = value
  },
  [SET_EDIT_TASK] (state, payload) {
    Vue.set(payload.parent.children, payload.parent.children.findIndex(el => el.id === payload.item.id && el.type === 'task'), payload.item)
  },
  [SET_EDIT_TITLE] (state, payload) {
    if (Array.isArray(payload.parent)) {
      Vue.set(payload.parent, payload.parent.findIndex(el => el.id === payload.item.id && el.type === 'title'), payload.item)
    } else {
      Vue.set(payload.parent.children, payload.parent.children.findIndex(el => el.id === payload.item.id && el.type === 'title'), payload.item)
    }
  },
  [SET_DOWNLOAD_TODO_FILES_PENDING] (state, value) {
    state.downloadTodoFilesPending = value
  },
  [SET_GLOBAL_TASK_FOLDER_PENDING] (state, value) {
    state.globalTaskFolderPending = value
  },
  [SET_GLOBAL_TASK_FOLDER] (state, value) {
    state.globalTaskFolder = value
  },
  [SET_EDIT_TITLE_PENDING] (state, value) {
    state.editTitlePending = value
  },
  [SET_EDIT_TITLE_ERROR] (state, value) {
    state.editTitleError = value
  },
  [SET_DELETE_TITLE_PENDING] (state, value) {
    state.deleteTitlePending = value
  },
  [SET_DELETE_TITLE_ERROR] (state, value) {
    state.deleteTitleError = value
  },
  [SET_TITLE_INDENTATION_PENDING] (state, value) {
    state.titleIndentationPending = value
  },
  [SET_TITLE_INDENTATION_ERROR] (state, value) {
    state.titleIndentationError = value
  },
  [SET_CREATE_TITLE_PENDING] (state, value) {
    state.createTitlePending = value
  },
  [SET_CREATE_TITLE_ERROR] (state, value) {
    state.createTitleError = value
  },
  [SET_SHARE_TASKS_DIALOG] (state, value) {
    state.shareTasksDialog = value
  },
  [TOGGLE_SELECTED_RESPONSIBLE_MEMBERS] (state, member) {
    const index = state.shareTaskRolePickerSelectedResponsible.members.findIndex(item => item.id === member.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedResponsible.members.splice(index, 1)
    } else {
      state.shareTaskRolePickerSelectedResponsible.members.push(member)
    }
  },
  [ADD_SELECTED_RESPONSIBLE_MEMBERS] (state, member) {
    const index = state.shareTaskRolePickerSelectedResponsible.members.findIndex(item => item.id === member.id)
    if (index < 0) {
      state.shareTaskRolePickerSelectedResponsible.members.push(member)
    }
  },
  [REMOVE_SELECTED_RESPONSIBLE_MEMBERS] (state, member) {
    const index = state.shareTaskRolePickerSelectedResponsible.members.findIndex(item => item.id === member.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedResponsible.members.splice(index, 1)
    }
  },
  [TOGGLE_SELECTED_OBSERVERS_MEMBERS] (state, member) {
    const index = state.shareTaskRolePickerSelectedObservers.members.findIndex(item => item.id === member.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedObservers.members.splice(index, 1)
    } else {
      state.shareTaskRolePickerSelectedObservers.members.push(member)
    }
  },
  [ADD_SELECTED_OBSERVERS_MEMBERS] (state, member) {
    const index = state.shareTaskRolePickerSelectedObservers.members.findIndex(item => item.id === member.id)
    if (index < 0) {
      state.shareTaskRolePickerSelectedObservers.members.push(member)
    }
  },
  [REMOVE_SELECTED_OBSERVERS_MEMBERS] (state, member) {
    const index = state.shareTaskRolePickerSelectedObservers.members.findIndex(item => item.id === member.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedObservers.members.splice(index, 1)
    }
  },
  [TOGGLE_SELECTED_VALIDATORS_MEMBERS] (state, member) {
    const index = state.shareTaskRolePickerSelectedValidators.members.findIndex(item => item.id === member.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedValidators.members.splice(index, 1)
    } else {
      state.shareTaskRolePickerSelectedValidators.members.push(member)
    }
  },
  [ADD_SELECTED_VALIDATORS_MEMBERS] (state, member) {
    const index = state.shareTaskRolePickerSelectedValidators.members.findIndex(item => item.id === member.id)
    if (index < 0) {
      state.shareTaskRolePickerSelectedValidators.members.push(member)
    }
  },
  [REMOVE_SELECTED_VALIDATORS_MEMBERS] (state, member) {
    const index = state.shareTaskRolePickerSelectedValidators.members.findIndex(item => item.id === member.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedValidators.members.splice(index, 1)
    }
  },
  [TOGGLE_SELECTED_RESPONSIBLE_GROUPS] (state, group) {
    const index = state.shareTaskRolePickerSelectedResponsible.groups.findIndex(item => item.id === group.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedResponsible.groups.splice(index, 1)
    } else {
      state.shareTaskRolePickerSelectedResponsible.groups.push(group)
    }
  },
  [ADD_SELECTED_RESPONSIBLE_GROUPS] (state, group) {
    const index = state.shareTaskRolePickerSelectedResponsible.groups.findIndex(item => item.id === group.id)
    if (index < 0) {
      state.shareTaskRolePickerSelectedResponsible.groups.push(group)
    }
  },
  [REMOVE_SELECTED_RESPONSIBLE_GROUPS] (state, group) {
    const index = state.shareTaskRolePickerSelectedResponsible.groups.findIndex(item => item.id === group.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedResponsible.groups.splice(index, 1)
    }
  },
  [TOGGLE_SELECTED_OBSERVERS_GROUPS] (state, group) {
    const index = state.shareTaskRolePickerSelectedObservers.groups.findIndex(item => item.id === group.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedObservers.groups.splice(index, 1)
    } else {
      state.shareTaskRolePickerSelectedObservers.groups.push(group)
    }
  },
  [ADD_SELECTED_OBSERVERS_GROUPS] (state, group) {
    const index = state.shareTaskRolePickerSelectedObservers.groups.findIndex(item => item.id === group.id)
    if (index < 0) {
      state.shareTaskRolePickerSelectedObservers.groups.push(group)
    }
  },
  [REMOVE_SELECTED_OBSERVERS_GROUPS] (state, group) {
    const index = state.shareTaskRolePickerSelectedObservers.groups.findIndex(item => item.id === group.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedObservers.groups.splice(index, 1)
    }
  },
  [TOGGLE_SELECTED_VALIDATORS_GROUPS] (state, group) {
    const index = state.shareTaskRolePickerSelectedValidators.groups.findIndex(item => item.id === group.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedValidators.groups.splice(index, 1)
    } else {
      state.shareTaskRolePickerSelectedValidators.groups.push(group)
    }
  },
  [ADD_SELECTED_VALIDATORS_GROUPS] (state, group) {
    const index = state.shareTaskRolePickerSelectedValidators.groups.findIndex(item => item.id === group.id)
    if (index < 0) {
      state.shareTaskRolePickerSelectedValidators.groups.push(group)
    }
  },
  [REMOVE_SELECTED_VALIDATORS_GROUPS] (state, group) {
    const index = state.shareTaskRolePickerSelectedValidators.groups.findIndex(item => item.id === group.id)
    if (index >= 0) {
      state.shareTaskRolePickerSelectedValidators.groups.splice(index, 1)
    }
  },
  [SET_SHARE_TASKS_PENDING] (state, value) {
    state.shareTasksPending = value
  },
  [SET_SHARE_TASKS_ERROR] (state, value) {
    state.shareTasksError = value
  },
  [RESET_ROLE_PICKER] (state) {
    state.shareTaskRolePickerGroups = null
    state.shareTaskRolePickerSelectedResponsible = {
      groups: [],
      members: [],
    }
    state.shareTaskRolePickerSelectedObservers = {
      groups: [],
      members: [],
    }
    state.shareTaskRolePickerSelectedValidators = {
      groups: [],
      members: [],
    }
  },
  [SET_SAVE_CHECKLIST_DIALOG_IS_OPEN] (state, value) {
    state.saveChecklistDialogIsOpen = value
  },
  [SET_SAVE_CHECKLIST_PENDING] (state, value) {
    state.saveChecklistPending = value
  },
  [SET_UPDATE_CHECKLIST_PENDING] (state, value) {
    state.updateChecklistPending = value
  },
  [SET_SAVE_CHECKLIST_ERROR] (state, value) {
    state.saveChecklistError = value
  },
  [SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN] (state, value) {
    state.importChecklistDialogIsOpen = value
  },
  [SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN] (state, value) {
    state.importChecklistFromTemplateDialogIsOpen = value
  },
  [SET_CHECKLISTS_TEMPLATES_SELECTED_TAB] (state, value) {
    state.checklistTemplatesSelectedTab = value
  },
  [SET_SAVED_CHECKLISTS_PENDING] (state, value) {
    state.savedChecklistsPending = value
  },
  [SET_SAVED_CHECKLISTS] (state, value) {
    state.savedChecklists = value
  },
  [SET_SELF_SAVED_CHECKLISTS_PENDING] (state, value) {
    state.selfSavedChecklistsPending = value
  },
  [SET_SELF_SAVED_CHECKLISTS] (state, value) {
    state.selfSavedChecklists = value
  },
  [SET_SELECTED_SELF_SAVED_CHECKLIST] (state, value) {
    state.selectedSelfSavedChecklist = value
  },
  [SET_SAVED_CHECKLISTS_ERROR] (state, value) {
    state.savedChecklistsError = value
  },
  [SET_SELECTED_SAVED_CHECKLIST] (state, value) {
    state.selectedSavedChecklist = value
  },
  [SET_SAVED_CHECKLIST_PREVIEW_PENDING] (state, value) {
    state.savedChecklistPreviewPending = value
  },
  [SET_SAVED_CHECKLIST_PREVIEW] (state, value) {
    state.savedChecklistPreview = value
  },
  [SET_SAVED_CHECKLIST_PREVIEW_ERROR] (state, value) {
    state.savedChecklistPreviewError = value
  },
  [SET_LEXISNEXIS_CHECKLISTS_PENDING] (state, value) {
    state.lexisNexisChecklistsPending = value
  },
  [SET_LEXISNEXIS_CHECKLISTS] (state, value) {
    state.lexisNexisChecklists = value
  },
  [SET_LEXISNEXIS_CHECKLISTS_ERROR] (state, value) {
    state.lexisNexisChecklistsError = value
  },
  [SET_SELECTED_LEXISNEXIS_CHECKLIST] (state, value) {
    state.selectedLexisNexisChecklist = value
  },
  [SET_LEXISNEXIS_CHECKLIST_PREVIEW_PENDING] (state, value) {
    state.lexisNexisChecklistPreviewPending = value
  },
  [SET_LEXISNEXIS_CHECKLIST_PREVIEW] (state, value) {
    state.lexisNexisChecklistPreview = value
  },
  [SET_LEXISNEXIS_CHECKLIST_PREVIEW_ERROR] (state, value) {
    state.lexisNexisChecklistPreviewError = value
  },
  [SET_IMPORT_CHECKLIST_FROM_CLOSD_PENDING] (state, value) {
    state.importChecklistFromClosdPending = value
  },
  [SET_IMPORT_CHECKLIST_FROM_EXCEL_PENDING] (state, value) {
    state.importChecklistFromExcelPending = value
  },
  [SET_IMPORT_CHECKLIST_FROM_CLOSD_ERROR] (state, value) {
    state.importChecklistFromClosdError = value
  },
  [SET_DELETE_SAVED_CHECKLIST_PENDING] (state, value) {
    state.deleteSavedChecklistPending = value
  },
  [SET_DELETE_SAVED_CHECKLIST_ERROR] (state, value) {
    state.deleteSavedChecklistError = value
  },
  [SET_SHARE_SAVED_CHECKLIST_PENDING] (state, value) {
    state.shareSavedChecklistPending = value
  },
  [SET_SHARE_SAVED_CHECKLIST_ERROR] (state, value) {
    state.shareSavedChecklistError = value
  },
  [SET_RENAME_SAVED_CHECKLIST_PENDING] (state, value) {
    state.renameSavedChecklistPending = value
  },
  [SET_RENAME_SAVED_CHECKLIST_ERROR] (state, value) {
    state.renameSavedChecklistError = value
  },
  [SET_LAST_TASK_ADDED] (state, value) {
    state.lastTaskAdded = value
  },
  [SET_LAST_TITLE_ADDED] (state, value) {
    state.lastTitleAdded = value
  },
  [SET_TASK_COMMENTS_PENDING] (state, value) {
    state.taskCommentsPendingIds.push(value)
  },
  [REMOVE_TASK_COMMENTS_PENDING] (state, index) {
    state.taskCommentsPendingIds.splice(index, 1)
  },
  [SET_TASK_COMMENTS_ERROR] (state, value) {
    state.taskCommentsError = value
  },
  [SET_POST_TASK_COMMENT_PENDING] (state, value) {
    state.postTaskCommentPendingIds.push(value)
  },
  [REMOVE_POST_TASK_COMMENT_PENDING] (state, index) {
    state.postTaskCommentPendingIds.splice(index, 1)
  },
  [SET_POST_TASK_COMMENT_ERROR] (state, value) {
    state.postTaskCommentError = value
  },
  [SET_DELETE_TASK_COMMENT_PENDING] (state, value) {
    state.deleteTaskCommentPendingIds.push(value)
  },
  [REMOVE_DELETE_TASK_COMMENT_PENDING] (state, index) {
    state.deleteTaskCommentPendingIds.splice(index, 1)
  },
  [SET_DELETE_TASK_COMMENT_ERROR] (state, value) {
    state.deleteTaskCommentError = value
  },
  [SET_DELETE_TASK_PENDING] (state, value) {
    state.deleteTaskPending = value
  },
  [SET_DELETE_TASK_ERROR] (state, value) {
    state.deleteTaskError = value
  },
  [SET_CONTACT_RESPONSIBLE_PENDING] (state, value) {
    state.contactResponsiblePending = value
  },
  [SET_CONTACT_RESPONSIBLE_ERROR] (state, value) {
    state.contactResponsibleError = value
  },
  [SET_TASK_STATUS_CATEGORIES_PENDING] (state, value) {
    state.taskStatusCategoriesPending = value
  },
  [SET_TASK_STATUS_CATEGORIES] (state, statusCategories) {
    state.taskStatusCategories = statusCategories.reduce(
      (result, category) => {
        result[category.name] = category.statuses
        return result
      },
      {},
    )
  },
  [SET_TASK_STATUS_CATEGORIES_ERROR] (state, value) {
    state.taskStatusCategoriesError = value
  },
  [SET_POST_MOVE_TASK_PENDING] (state, value) {
    state.postMoveTaskPending = value
  },
  [SET_POST_MOVE_TASK_ERROR] (state, value) {
    state.postMoveTaskError = value
  },
  [SET_PARENT_CHILDREN] (state, payload) {
    Vue.set(payload.parent, 'children', payload.children)
  },
  [SET_TASK_FOLDER_PENDING] (state, value) {
    state.taskFolderPendingIds.push(value)
  },
  [SET_TASK_FOLDER] (state, value) {
    state.currentTaskFolder = value
  },
  [RESET_TASK_FOLDER] (state) {
    state.currentTaskFolder = null
  },
  [REMOVE_TASK_FOLDER_PENDING] (state, index) {
    state.taskFolderPendingIds.splice(index, 1)
  },
  [SET_TASK_FOLDER_ERROR] (state, value) {
    state.taskFolderError = value
  },
  [SET_PANE] (state, value) {
    state.pane = value
  },
  [SET_PANE_TAB] (state, value) {
    Vue.set(state.pane, 'tab', value)
  },
  [SET_SHARE_ATTACHED_FILES_PENDING] (state, value) {
    state.shareAttachedFilesPending = value
  },
  [SET_SHARE_ATTACHED_FILES_ERROR] (state, value) {
    state.shareAttachedFilesError = value
  },
  [SET_MOVE_FILDERS_PENDING] (state, value) {
    state.moveFildersPending = value
  },
  [SET_MOVE_FILDERS_ERROR] (state, value) {
    state.moveFildersError = value
  },
  [SET_DELETE_FILDERS_PENDING] (state, value) {
    state.deleteFildersPending = value
  },
  [SET_DELETE_FILDERS_ERROR] (state, value) {
    state.deleteFildersError = value
  },
  [SET_RENAME_ATTACHED_FILE_DIALOG] (state, value) {
    state.renameAttachedFilesDialog = value
  },
  [SET_RENAME_ATTACHED_FILE_PENDING] (state, value) {
    state.renameAttachedFilePending = value
  },
  [SET_RENAME_ATTACHED_FILE_ERROR] (state, value) {
    state.renameAttachedFileError = value
  },
  [SET_ATTACHED_FILE_VERSIONS_PENDING] (state, value) {
    state.attachedFileVersionsPending = value
  },
  [SET_ATTACHED_FILE_VERSIONS] (state, value) {
    state.attachedFileVersions = value
  },
  [RESET_ATTACHED_FILE_VERSIONS] (state) {
    state.attachedFileVersions = null
  },
  [SET_ATTACHED_FILE_VERSIONS_ERROR] (state, value) {
    state.attachedFileVersionsError = value
  },
  [SET_POST_ATTACHED_FILE_VERSIONS_PENDING] (state, value) {
    state.postAttachedFileVersionsPending = value
  },
  [SET_POST_ATTACHED_FILE_VERSIONS_ERROR] (state, value) {
    state.postAttachedFileVersionsError = value
  },
  [SET_SELECTED_ATTACHED_FILE] (state, value) {
    const itemIndex = state.selectedAttachedFiles.findIndex(item => item.id === value.id)
    itemIndex >= 0
      ? state.selectedAttachedFiles.splice(itemIndex, 1)
      : state.selectedAttachedFiles.push(value)
  },
  [ADD_SELECTED_ATTACHED_FILE] (state, value) {
    const itemIndex = state.selectedAttachedFiles.findIndex(item => item.id === value.id)
    if (itemIndex < 0) {
      state.selectedAttachedFiles.push(value)
    }
  },
  [REMOVE_SELECTED_ATTACHED_FILE] (state, value) {
    const itemIndex = state.selectedAttachedFiles.findIndex(item => item.id === value.id)
    if (itemIndex >= 0) {
      state.selectedAttachedFiles.splice(itemIndex, 1)
    }
  },
  [RESET_SELECTED_ATTACHED_FILE] (state) {
    state.selectedAttachedFiles = []
  },
  [SET_REFRESH_TASK_PENDING] (state, value) {
    state.refreshTaskPendingIds.push(value)
  },
  [REMOVE_REFRESH_TASK_PENDING] (state, index) {
    state.refreshTaskPendingIds.splice(index, 1)
  },
  [SET_REFRESH_TASK_ERROR] (state, value) {
    state.refreshTaskError = value
  },
  [SET_REFRESH_TASK] (state, payload) {
    Vue.set(payload.parent.children, payload.parent.children.findIndex(el => el.id === payload.newTask.id), payload.newTask)
  },
  [SET_DUPLICATE_TASK_PENDING] (state, value) {
    state.duplicateTaskPending = value
  },
  [SET_DUPLICATE_TASK_ERROR] (state, value) {
    state.duplicateTaskError = value
  },
  [SET_POST_STATUS_PENDING] (state, value) {
    state.postStatusPending = value
  },
  [SET_POST_STATUS_ERROR] (state, value) {
    state.postStatusError = value
  },
  [SET_TABLE_TASK_PANE] (state, value) {
    state.tableTaskPane = value
  },
  [SET_TABLE_TASK_PANE_TAB] (state, value) {
    Vue.set(state.tableTaskPane, 'tab', value)
  },
  [REMOVE_FROM_EXPAND_TASK_AFTER_DELETE] (state, titleId) {
    const item = state.titlesExpandState.findIndex(item => item.id === titleId)
    if (item >= 0) {
      state.titlesExpandState.splice(item, 1)
    }
  },
  [SET_SHARE_CHECKLIST_BY_EMAIL_PENDING] (state, value) {
    state.shareChecklistByEmailPending = value
  },
  [SET_TITLES_EXPAND_STATE] (state, titlesExpandState) {
    state.titlesExpandState = titlesExpandState
  },
  // Filters
  [REMOVE_CHECKLIST_FILTER] (state, filterToRemove) {
    state.filters = state.filters.filter(
      filter => filter.category.key !== filterToRemove.category.key || filter.value.key !== filterToRemove.value.key,
    )
  },
  [SET_CHECKLIST_FILTERS] (state, value) {
    state.filters = value
  },
  [SET_FILTERS_PANE_IS_OPEN] (state, isOpen) {
    state.filtersPaneIsOpen = isOpen
  },
  // Search
  [SET_SEARCH_QUERY] (state, searchQuery) {
    state.searchQuery = searchQuery
  },
  [SET_SEARCH_QUERY_PENDING] (state, value) {
    state.searchQueryPending = value
  },
  [SET_CHECKLIST_VERSIONING_PANE_TAB] (state, tab) {
    state.checklistVersioningPane.tab = tab
  },
  [TOGGLE_CHECKLIST_VERSIONING_PANE] (state, payload) {
    state.checklistVersioningPane.documentVersions = payload.documentVersions || []
    state.checklistVersioningPane.documents = payload.documents || []
    state.checklistVersioningPane.isOpen = payload.isOpen || false
    state.checklistVersioningPane.tab = payload.tab || ''
  },
}
