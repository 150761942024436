<template>
  <v-row class="justify-space-between">
    <v-col cols="12" md="auto" lg="6">
       <v-row class="align-center">
          <v-col cols="auto">
            <SearchTextField v-model="search" :tooltipText="$t('project.checklist.ChecklistHeader.searchPlaceholder')" />
          </v-col>
          <v-col cols="auto">
            <AppButton color="white"
                      @click="onOpenFiltersPane"
            >
              <font-awesome-icon :icon="['far', 'sliders']" class="mr-2"></font-awesome-icon>
              {{ $t('common.filters') }}
            </AppButton>
          </v-col>
       </v-row>
    </v-col>
    <v-col cols="12" md="auto" lg="6">
      <v-row class="justify-end align-center">
        <v-col md="auto" class="d-flex align-center">
          <app-dropdown-button type="outlined"
                               variant="neutral"
                               button-classes="mr-4"
          >
            {{ $t('project.checklist.ChecklistHeader.options') }}
            <template #list>
              <v-list>
                <v-list-item-group>
                  <AppDownloadFile :link="`${baseURL}/room/${mnemo}/downloads/todo-pdf`"
                                   class="d-block"
                                   forceReader
                  >
                    <template #default="{submitForm}">
                      <v-list-item  @click="submitForm">
                        <v-list-item-title>
                          <div class="d-flex align-center">
                            <app-icon icon-name="file-pdf" class="mr-2"/>
                            {{ $t('project.checklist.ChecklistHeader.exportToPDF') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </AppDownloadFile>
                  <AppDownloadFile :link="`${baseURL}/room/${mnemo}/downloads/todo-word`"
                                   class="d-block"
                  >
                    <template #default="{submitForm}">
                      <v-list-item  @click="submitForm">
                        <v-list-item-title>
                          <div class="d-flex align-center">
                            <app-icon icon-name="file-word" class="mr-2"/>
                            {{ $t('project.checklist.ChecklistHeader.exportToWord') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </AppDownloadFile>
                  <v-list-item v-if="isCurrentUserPm && isSubscriber"
                               @click="onSaveChecklistClick"
                  >
                    <v-list-item-title>
                      <div class="d-flex align-center">
                        <app-icon icon-name="save" class="mr-2"/>
                        {{ $t('project.checklist.ChecklistHeader.saveChecklist') }}
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onShowAttachmentsClick">
                    <v-list-item-title>
                      <div class="d-flex align-center">
                        <app-icon icon-name="paperclip" class="mr-2" />
                        {{ $t('project.checklist.ChecklistHeader.showAttachments') }}
                        <app-new-label class="ml-2" />
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onSendChecklistByEmailClick">
                    <v-list-item-title>
                      <div class="d-flex align-center">
                        <app-icon icon-name="envelope" class="mr-2"/>
                        {{ $t('project.checklist.ChecklistHeader.shareByEmail') }}
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
          </app-dropdown-button>
          <app-dropdown-button v-if="isCurrentUserPm && isSubscriber"
                               type="outlined"
                               variant="brand"
                               button-classes="mr-4"
                               :menu-items="importChecklistMenu"
          >
            {{ $t('project.checklist.ChecklistHeader.importChecklist') }}
          </app-dropdown-button>
          <app-dropdown-button v-if="isCurrentUserPm"
                               :menu-items="addMenu"
          >
            <template #left-icon>
              <font-awesome-icon :icon="['fas', 'plus-circle']" />
            </template>
            {{ $t('common.add') }}
          </app-dropdown-button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import AppNewLabel from '@/common/AppNewLabel.vue'
import SearchTextField from '@/common/SearchTextField'
import AppButton from '@/design-system/buttons/AppButton'
import {
  SET_FILTERS_PANE_IS_OPEN,
  SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN,
  SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN,
  SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN,
} from '@/store/modules/checklist/mutation_types'

import {
  CREATE_TITLE,
  CREATE_TASK,
  DELETE_SEARCH_RESULTS,
  GET_CHECKLIST,
  GET_SEARCH_RESULTS,
  OPEN_SAVE_CHECKLIST_DIALOG,
  OPEN_SHARE_CHECKLIST_BY_EMAIL_PANE,
} from '../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'

export default {
  name: 'ChecklistHeader',
  components: {
    AppDownloadFile,
    AppButton,
    SearchTextField,
    AppNewLabel,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      searchQuery: '',
      importChecklistMenu: [
        {
          title: this.$t('project.checklist.ChecklistTable.importChecklistFromTemplate'),
          action: () => { this.onImportChecklistFromTemplate() },
          icon: 'file-invoice',
        },
        {
          title: this.$t('project.checklist.ChecklistHeader.importChecklistFromExcel'),
          action: () => { this.onImportChecklistClick() },
          icon: 'file-excel',
        },
      ],
      addMenu: [
        {
          title: this.$t('project.checklist.ChecklistHeader.newTask'),
          action: () => { this.prepareCreateTask() },
          loading: this.createTaskPending,
          icon: 'tasks',
        },
        {
          title: this.$t('project.checklist.ChecklistHeader.newTitle'),
          action: () => { this.prepareCreateTitle() },
          loading: this.createTitlePending,
          icon: 'heading',
        },
      ],
    }
  },
  computed: {
    ...mapState('checklist', ['createTitlePending', 'createTaskPending']),
    ...mapGetters('checklist', ['flatChecklist']),
    ...mapGetters('user', ['isSubscriber']),
    ...mapGetters('room', ['isCurrentUserPm']),
    baseURL () {
      return process.env.VUE_APP_API_URL
    },
    search: {
      get () {
        return this.searchQuery
      },
      set: debounce(function (val) {
        this.searchQuery = val
        this.searchChecklist()
      }, 1000),
    },
  },
  methods: {
    ...mapActions('checklist', [
      CREATE_TITLE,
      CREATE_TASK,
      DELETE_SEARCH_RESULTS,
      GET_CHECKLIST,
      GET_SEARCH_RESULTS,
      OPEN_SAVE_CHECKLIST_DIALOG,
      OPEN_SHARE_CHECKLIST_BY_EMAIL_PANE,
    ]),
    ...mapMutations('checklist', [SET_FILTERS_PANE_IS_OPEN, SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN, SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN]),
    async prepareCreateTitle () {
      try {
        const data = {
          title: '',
          depth: 0,
          prevId: null,
          prevType: null,
        }
        if (this.flatChecklist.length > 0) {
          const lastElement = this.flatChecklist[this.flatChecklist.length - 1]
          data.prevId = lastElement.id
          data.prevType = lastElement.type
        }
        await this.CREATE_TITLE({
          mnemo: this.mnemo,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.ChecklistHeader.createTitleSuccess'),
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.ChecklistHeader.createTitleError'),
        })
      }
    },
    async prepareCreateTask () {
      try {
        const data = {
          prevId: null,
          prevType: null,
        }
        if (this.flatChecklist.length > 0) {
          const lastElement = this.flatChecklist[this.flatChecklist.length - 1]
          data.prevId = lastElement.id
          data.prevType = lastElement.type
        }
        await this.CREATE_TASK({
          mnemo: this.mnemo,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.ChecklistHeader.createTaskSuccess'),
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.ChecklistHeader.createTaskError'),
        })
      }
    },
    onSaveChecklistClick () {
      this.OPEN_SAVE_CHECKLIST_DIALOG()
    },
    onImportChecklistClick () {
      this.SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN(true)
    },
    onImportChecklistFromTemplate () {
      this.$store.commit(`checklist/${SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN}`, true)
    },
    onShowAttachmentsClick () {
      this.SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN(true)
    },
    onSendChecklistByEmailClick () {
      this.OPEN_SHARE_CHECKLIST_BY_EMAIL_PANE()
    },
    onOpenFiltersPane () {
      this.SET_FILTERS_PANE_IS_OPEN(true)
    },
    searchChecklist () {
      this.search ? this.GET_SEARCH_RESULTS(this.searchQuery) : this.DELETE_SEARCH_RESULTS()
    },
  },
}
</script>
