/**
 *
 * @param {string} mnemo
 * @param {number} folderId
 * @constructor
 */
export const DocumentsImport = function (
  mnemo = null,
  folderId = null,
) {
  this.mnemo = mnemo
  this.folderId = folderId
}

/**
 *
 * @param {string} mnemo
 * @param {Array} fileIds
 * @constructor
 */
export const DocumentsExport = function (
  mnemo = null,
  fileIds = [],
) {
  this.mnemo = mnemo
  this.fileIds = fileIds
}

/**
 *
 * @param {string} mnemo
 * @constructor
 */
export const SigningChecklistImport = function (
  mnemo = null,
) {
  this.mnemo = mnemo
  // Should be a string, ask by backend devs !
  this.asHidden = 'true'
}

/**
 *
 * @param {string} mnemo
 * @param {string} attachmentsFolderId
 * @constructor
 */
export const ChecklistImport = function (
  mnemo = null,
  attachmentsFolderId = null,
) {
  this.mnemo = mnemo
  // Should be a string, ask by backend devs !
  this.folderId = attachmentsFolderId
}

/**
 *
 * @param {string} mnemo
 * @param {Array} bibleIds
 * @constructor
 */
export const BiblesExport = function (
  mnemo = null,
  bibleIds = [],
) {
  this.mnemo = mnemo
  this.bibleIds = bibleIds
}
/**
 *
 * @param {string} mnemo
 * @param {Array} signedEnvIds
 * @constructor
 */
export const SignedEnvExport = function (
  mnemo = null,
  signedEnvIds = [],
) {
  this.mnemo = mnemo
  this.signedEnvIds = signedEnvIds
}
