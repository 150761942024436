<template>
  <div>
    <AppDialog :is-open="isDialogOpened"
               :ok-text="$t('subscriptions.RestoreRoomDialog.restore')"
               :ok-loading="restoreRoomPending"
               :ok-disabled="restoreRoomPending"
               :cancel-disabled="restoreRoomPending"
               @ok="prepareRestoreRoom"
               @cancel="closeDialog"
    >
      <template #title>{{ $t('subscriptions.RestoreRoomDialog.title') }}</template>
      <template #body>
        <v-row>
          <v-col class="text--primary body-2">
            <div class="mb-4">{{ $t('subscriptions.RestoreRoomDialog.text1') }}</div>
            <div>{{ $t('subscriptions.RestoreRoomDialog.text2') }}</div>
            <p class="mt-4">{{ $t('subscriptions.RestoreRoomDialog.notify') }}</p>
            <v-checkbox v-model="notifyPms"
                      :label="$t('subscriptions.RestoreRoomDialog.notifyPms')"
                      hide-details
                      class="mt-3"
            />
            <v-checkbox v-model="notifyGuests"
                      :label="$t('subscriptions.RestoreRoomDialog.notifyGuests')"
                      hide-details
                      class="mt-3"
            />
          </v-col>
        </v-row>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import { RESTORE_ROOM } from '@/store/modules/subscription/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import { dialogMixin } from '../mixins/dialogMixin'
export default {
  name: 'RestoreRoomDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    roomId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      notifyPms: true,
      notifyGuests: true,
    }
  },
  computed: {
    ...mapState('subscription', ['restoreRoomPending']),
  },
  methods: {
    ...mapActions('subscription', [RESTORE_ROOM]),
    async prepareRestoreRoom () {
      try {
        await this.RESTORE_ROOM({
          roomId: this.roomId,
          data: {
            notifyPms: this.notifyPms,
            notifyGuests: this.notifyGuests,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('subscriptions.RestoreRoomDialog.restoreRoomSuccess'),
        })
        this.$emit('confirm')
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('subscriptions.RestoreRoomDialog.restoreRoomError'),
        })
      }
    },
  },
}
</script>
