<template>
  <div>
    <template v-if="loading || Object.keys(documents).length === 0">
      <v-row>
        <v-col class="text-center mt-5">
          <AppLoader/>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row no-gutters>
        <div class="text-subtitle-1">
          <b>{{$t('project.checklist.pane.ChecklistTableTaskPaneMove.moveTo')}}</b>
        </div>
      </v-row>
      <v-row no-gutters class="mt-4">
        <AppFildersTree v-model="selectedFolder"
                        :root-folder="documents"
                        open-first-node
                        folders-only
                        style="width: 100%;"
        />
      </v-row>
      <v-row>
        <v-col>
          <AppButton color="primary"
                     outlined
                     block
                     :disabled="moveFildersPending"
                     @click="resetAndClose"
          >
            {{$t('common.cancel')}}
          </AppButton>
        </v-col>
        <v-col>
          <AppButton color="primary"
                     block
                     :disabled="isMoveDisabled"
                     :loading="moveFildersPending"
                     @click="move"
          >
            {{$t('project.checklist.pane.ChecklistTableTaskPaneMove.move')}}
          </AppButton>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'

import AppLoader from '../../../common/AppLoader'
import AppFildersTree from '../../../common/AppVuetifyFildersTree'
import AppButton from '../../../common/buttons/AppButton'
import { GET_GLOBAL_TASK_FOLDER, GET_TASK_FOLDER, MOVE_FILDERS } from '../../../store/modules/checklist/action_types'
import { RESET_SELECTED_ATTACHED_FILE, SET_TABLE_TASK_PANE } from '../../../store/modules/checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'ChecklistTableTaskPaneMove',
  components: { AppLoader, AppFildersTree, AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      selectedFolder: [],
    }
  },
  computed: {
    ...mapState('documents', ['documents', 'loading']),
    ...mapState('checklist', ['tableTaskPane', 'moveFildersPending']),
    isMoveDisabled () {
      return this.tableTaskPane.documents.length === 0 || !this.isSelectedFolderValid || this.moveFildersPending
    },
    isSelectedFolderValid () {
      return this.selectedFolder.length !== 0 && this.selectedFolder[0]?.specialFolder !== 'todo'
    },
    paneTab () {
      return this.tableTaskPane.tab
    },
    paneIsOpen () {
      return this.tableTaskPane.isOpen
    },
  },
  watch: {
    paneTab (val) {
      if (val === 'move') {
        this.loadData()
      }
    },
    paneIsOpen (val) {
      if (!val) {
        this.resetAndClose()
      }
    },
  },
  methods: {
    ...mapActions('documents', [GET_DOCUMENTS_BY_ID]),
    ...mapActions('checklist', [GET_TASK_FOLDER, MOVE_FILDERS, GET_GLOBAL_TASK_FOLDER]),
    async loadData () {
      try {
        await this.GET_DOCUMENTS_BY_ID({
          mnemo: this.mnemo,
          id: 0,
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.pane.ChecklistTableTaskPaneMove.getDocumentsError'),
        })
      }
    },
    async move () {
      try {
        await this.MOVE_FILDERS({
          mnemo: this.mnemo,
          data: {
            folderIds: [],
            fileIds: this.tableTaskPane.documents.map(item => { return item.id }),
            parentId: this.selectedFolder[0].id,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.pane.ChecklistTableTaskPaneMove.moveSuccess'),
        })
        // Global move operation
        if (this.$store.state.checklist.globalAttachedFilesDialogIsOpen) {
          this.GET_GLOBAL_TASK_FOLDER(this.mnemo)
        } else {
          this.GET_TASK_FOLDER({
            mnemo: this.mnemo,
            folderId: this.tableTaskPane.parent.id,
          })
        }
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.pane.ChecklistTableTaskPaneMove.moveError'),
        })
      } finally {
        this.resetAndClose()
      }
    },
    resetAndClose () {
      this.selectedFolder = []
      this.$store.commit(`checklist/${RESET_SELECTED_ATTACHED_FILE}`)
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: false,
        tab: '',
        documents: [],
        parent: null,
      })
    },
  },
}
</script>
