<template>
  <div>
    <AppDatePicker v-model.trim="model.$model"
                  :class="{'invalid': isErrored || birthDateErrors.length > 0}"
                  :label="$t('common.birthDate')"
                  :clearable="false"
                  :error-messages="birthDateErrors"
                  :error="isErrored"
    />
    <AppTextFieldSuggestion v-if="displaySuggestedValue"
                            :suggestedValue="suggestedValue"
                            @validate="model.$model = suggestedValue"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AppDatePicker from '@/common/AppDatePicker'
import AppTextFieldSuggestion from '@/common/identity-checking/AppTextFieldSuggestion'
import { ID_CHECK_ERRORS } from '@/common/utils/idCheckErrors'

export default {
  name: 'BirthDate',
  components: { AppDatePicker, AppTextFieldSuggestion },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['idCheckError']),
    ...mapGetters('user', ['hasIdCheckErrorInfo']),
    birthDateErrors () {
      const errors = []
      if (!this.model.$dirty) return errors
      !this.model.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.birthDate') }))
      !this.model.minValue && errors.push(this.$t('common.validations.invalid', { fieldName: this.$t('common.birthDate') }))
      !this.model.maxValue && errors.push(this.$t('common.validations.invalid', { fieldName: this.$t('common.birthDate') }))
      return errors
    },
    isErrored () {
      return this.hasIdCheckErrorInfo &&
        this.idCheckError.errors.includes(ID_CHECK_ERRORS.ERROR_BIRTHDATE_MISMATCH) &&
        this.displaySuggestedValue
    },
    suggestedValue () {
      return this.idCheckError?.suggestedValues?.birthDate
    },
    displaySuggestedValue () {
      return this.suggestedValue && this.suggestedValue !== this.model.$model
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .invalid input {
  color: var(--v-error-base) !important;
}
</style>
