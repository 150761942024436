/**
 * Mixin for projects folders components that modify the folders list or navigate within folders
 */
import { mapState, mapActions } from 'vuex'

import { FOLDER_ROUTE_NAME } from '@/router/index'
import { LOAD_PROJECTS_FOLDERS_TREE, GET_PROJECTS_FOLDER_BY_ID } from '@/store/modules/projects-folders/action_types'
import { GET_ROOMS_PROGRESSIVELY } from '@/store/modules/rooms/action_types'

export default {
  name: 'projectsFoldersMixin',
  data () {
    return {
      FOLDER_ROUTE_NAME,
    }
  },
  computed: {
    ...mapState('projectsFolders', ['currentProjectsFolder']),
  },
  methods: {
    ...mapActions('projectsFolders', [LOAD_PROJECTS_FOLDERS_TREE, GET_PROJECTS_FOLDER_BY_ID]),
    ...mapActions('rooms', [GET_ROOMS_PROGRESSIVELY]),
    navigateToProjectsFolder () {
      let params = {}
      if (this.folder.parentId) {
        params = {
          folderId: this.folder.parentId,
          childId: this.folder.id,
        }
      } else {
        params = {
          folderId: this.folder.id,
        }
      }
      this.$router.push({ name: FOLDER_ROUTE_NAME, params })
    },
    updateCurrentAndDestinationFolders (isRoom) {
      // Update current folder
      if (this.currentProjectsFolder.id) {
        try {
          this.GET_PROJECTS_FOLDER_BY_ID({
            folderId: this.currentProjectsFolder.id,
          })
        } catch (error) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsNewFolderDialog.getFolderError', { name: this.currentProjectsFolder.name }))
        }
      } else if (isRoom) {
        this.GET_ROOMS_PROGRESSIVELY()
      } else {
        this.LOAD_PROJECTS_FOLDERS_TREE()
      }

      // Update destination folder
      let destinationFolder = {}
      if (this.folder) { // Drag&drop Folder
        destinationFolder = this.folder
      } else if (this.selectedFolder?.[0]) { // Move Folder Dialog
        destinationFolder = this.selectedFolder[0]
      }

      if (destinationFolder.id) {
        this.GET_PROJECTS_FOLDER_BY_ID({
          folderId: destinationFolder.id,
          silent: true,
        })
      } else if (isRoom) {
        this.GET_ROOMS_PROGRESSIVELY()
      } else {
        this.LOAD_PROJECTS_FOLDERS_TREE()
      }
    },
  },
}
