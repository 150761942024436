export const REMOVE_LOG_FILTER = 'REMOVE_LOG_FILTER'
export const RESET_LOGS_SORT = 'RESET_LOGS_SORT'
export const SET_CLOSE_LOGS_DIALOG = 'SET_CLOSE_LOGS_DIALOG'
export const SET_FILE_LOGS_ERROR = 'SET_FILE_LOGS_ERROR'
export const SET_FILE_LOGS_PENDING = 'SET_FILE_LOGS_PENDING'
export const SET_FOLDER_LOGS_ERROR = 'SET_FOLDER_LOGS_ERROR'
export const SET_FOLDER_LOGS_PENDING = 'SET_FOLDER_LOGS_PENDING'
export const SET_LOGS_FILTERS = 'SET_LOGS_FILTERS'
export const SET_LOGS_FILTERS_PANE_IS_OPEN = 'SET_LOGS_FILTERS_PANE_IS_OPEN'
export const SET_LOGS_SORT_BY = 'SET_LOGS_SORT_BY'
export const SET_LOGS_SORT_DESC = 'SET_LOGS_SORT_DESC'
export const SET_OPEN_LOGS_DIALOG = 'SET_OPEN_LOGS_DIALOG'
export const SET_ROOM_LOGS = 'SET_ROOM_LOGS'
export const SET_ROOM_LOGS_ERROR = 'SET_ROOM_LOGS_ERROR'
export const SET_ROOM_LOGS_PENDING = 'SET_ROOM_LOGS_PENDING'
export const SET_ROOM_LOGS_SEARCH_QUERY = 'SET_ROOM_LOGS_SEARCH_QUERY'
export const SET_LOGS_TYPES_PENDING = 'SET_LOGS_TYPES_PENDING'
export const SET_LOGS_TYPES = 'SET_LOGS_TYPES'
export const SET_LOGS_TYPES_ERROR = 'SET_LOGS_TYPES_ERROR'
