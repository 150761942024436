<template>
  <div style="width:inherit">
    <div>
      <v-carousel
        height="auto"
        interval="8000"
        cycle
        :show-arrows="false"
        hide-delimiters
      >
        <v-carousel-item v-for="(lawyer, i) in lawyers" :key="`lawyer-${i}`">
          <div class="d-flex fill-height justify-center align-center">
              <LawyerCardItem :lawyer="lawyer"></LawyerCardItem>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="progress-bar">
       <div class="progress-fill"></div>
    </div>
  </div>
</template>

<script>
import LawyerCardItem from './LawyerCardItem.vue'

export default {
  name: 'LawyerCarousel',
  components: { LawyerCardItem },
  data () {
    return {
      lawyersName: ['David', 'Lea', 'Heloise'],
      lawyers: [],
    }
  },
  mounted () {
    this.lawyers = this.lawyersName.map(name => ({
      avatar: `/img/landing-page/avatar-${name.toLowerCase()}.png`,
      name: this.$t(`sign.views.LandingPage.discover.lawyer${name}.name`),
      profession: this.$t(`sign.views.LandingPage.discover.lawyer${name}.profession`),
      office: this.$t(`sign.views.LandingPage.discover.lawyer${name}.office`),
      quote: this.$t(`sign.views.LandingPage.discover.lawyer${name}.quote`),
    }))
  },
}
</script>

<style lang="scss" scoped>
.progress-bar {
  width: 140px;
  height: 4px;
  background-color: lightgray;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 16px;
}
.progress-fill {
  height: 100%;
  width: 0;
  border-radius: 10px;
  background-color: #2997FF;
  position: absolute;
  animation: fillAnimation 8s linear infinite;
}
@keyframes fillAnimation {
  to {
    width: 100%;
  }
}
.v-window { &-x-transition, &-x-reverse-transition, &-y-transition, &-y-reverse-transition { &-enter-active, &-leave-active { transition: .8s cubic-bezier(.25, .8, .50, 1) !important; } } }
</style>
