<template>
  <div>
    <form @submit.prevent="preparePatchGroup">
      <AppTextField ref="inputText"
                    v-model.trim="$v.newGroupName.$model"
                    outlined
                    autofocus
                    dense
                    counter="100"
                    maxlength="100"
                    @keydown.esc="$emit('cancel')"
      >
        <template #append>
          <AppButton icon
                     small
                     :disabled="loading"
                     class="GroupNameEdit-button"
                     @click="$emit('cancel')"
          >
            <font-awesome-icon :icon="['fad', 'circle-xmark']" style="font-size: 20px;"></font-awesome-icon>
          </AppButton>
          <AppButton color="success"
                     icon
                     small
                     :loading="loading"
                     :disabled="loading"
                     class="GroupNameEdit-button"
                     @click="preparePatchGroup"
          >
            <font-awesome-icon :icon="['fad', 'circle-check']" style="font-size: 20px;"></font-awesome-icon>
          </AppButton>
        </template>
      </AppTextField>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

import AppTextField from '@/common/AppTextField'
import AppButton from '@/common/buttons/AppButton'

export default {
  name: 'GroupNameEdit',
  components: { AppTextField, AppButton },
  mixins: [validationMixin],
  validations: {
    newGroupName: {
      required,
      maxLength: maxLength(100),
    },
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      newGroupName: '',
      loading: false,
    }
  },
  mounted () {
    this.newGroupName = this.group.name
    this.$nextTick(() => {
      this.$refs.inputText.$el.querySelector('input').select()
    })
  },
  methods: {
    ...mapActions('groups', ['PATCH_GROUP', 'GET_GROUPS']),
    async preparePatchGroup () {
      if (this.newGroupName !== this.group.name && this.newGroupName && !this.loading) {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.loading = true
          try {
            await this.PATCH_GROUP({
              groupId: this.group.id,
              data: {
                name: this.newGroupName,
              },
            })
            this.GET_GROUPS(this.$route.params.mnemo)
            this.$store.commit('enqueueSnackbar', {
              color: 'success',
              message: this.$t('project.members.GroupNameEdit.patchGroupSuccess'),
            })
          } catch (e) {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.GroupNameEdit.patchGroupError'),
            })
          } finally {
            this.loading = false
            this.$emit('cancel')
          }
        }
      } else {
        this.$emit('cancel')
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .GroupNameEdit-button {
  &:hover .v-btn__content {
    transform: scale(1.3);
   }
}
</style>
