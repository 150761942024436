<template>
<!-- For simplicity with back-end possible values, we only check for "truthy" values -->
<!-- eslint-disable vue/eqeqeq  -->
<!-- eslint-disable vue/valid-v-slot -->
  <div>
    <div v-show="!loading">
      <v-card body-class="p-0">
        <p v-if="itemCount !== 0" class="pt-3 mb-0 ml-4">{{$tc('archives.ArchivesList.itemCount', itemCount)}}</p>
        <!-- Update options is effectively used for sort changes only because everything else is external -->
        <v-data-table
          v-model="selectedItems"
          :items="items"
          :headers="fields"
          mobile-breakpoint="960"
          must-sort
          sort-by="archiveDate"
          :sort-desc="true"
          hide-default-footer
          :page="page"
          :item-class="getRowClass"
          selectable-key="_selected"
          show-select
          item-key="id"
          :no-results-text="$t('archives.ArchivesList.emptyText')"
          :no-data-text="$t('archives.ArchivesList.emptyText')"
          :server-items-length="itemCount"
          :footer-props="{
                  'items-per-page-options': [50, 100, 250],
                }"
          :items-per-page="tableItemsPerPage"
          :options.sync="options"
          @update:options="onSortByChange"
        >
          <template v-slot:item.docName="{ item }">
             <AppTooltip bottom max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex" v-bind="attrs" v-on="on">
                  <ClosdFildersIcon
                    class="mr-2"
                    :document="item"
                    :signedFile="item.isSigned == true"
                  />
                  <span class="text-ellipsis">{{item.docName}}</span>
                </div>
              </template>
              <span>{{ item.docName }}</span>
            </AppTooltip>
          </template>
          <template v-slot:item.archiveDate="{ item }">
            <DateDisplay :value="item.archiveDate" />
          </template>
          <template v-slot:item.archiverName="{ item }">
            <ArchivesListArchiverName :name="item.archiverName" />
          </template>
          <template v-slot:item.signedDocument="{ item }">
            <BooleanDisplay :value="item.isSigned == true" />
          </template>
          <template v-slot:item.signedDate="{ item }">
            <DateDisplay :value="item.signedDate" />
          </template>
          <template v-slot:item._options="{ item, index }">
            <div style="min-width: 75px">
              <AppButton
                v-if="isArchivingAdmin || item.canDownload"
                icon
                @click="downloadFile(item)"
              >
                <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon>
              </AppButton>
              <AppMenu
                :menu="getMenuForItem(item)"
                min-width="250px"
                @update:return-value="indexMenuOpen = -1"
              >
                <template v-slot:activator="{ on, attrs }">
                <AppButton
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="toggleMenu(index)"
                >
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                </AppButton>
                </template>
              </AppMenu>
            </div>
          </template>
        </v-data-table>
      </v-card>
      <ArchivesListDetailsDialog
        v-if="currentItemDetailsOpen"
        :data="currentItemDetails"
        :fields="modalFields"
        @action="handleDetailsModalAction"
        @close="currentItemDetailsOpen = false"
      />
      <ArchivesListShareDialog
        v-if="shareDialogOpen"
        :data="currentShareItems"
        @close="shareDialogOpen = false"
      />
      <ArchivesListSendByEmailDialog
        v-if="archiveToBeSendByEmail"
        :archive-id="archiveToBeSendByEmail.id"
        @close="archiveToBeSendByEmail = null"
      />
      <AppPagination
        class="mt-4"
        :value="page"
        :total="itemCount"
        @input="onPageChange"
      />
    </div>
    <AppDownloadFile ref="appDownloadFileRef" :link="downloadLink" />
    <div v-show="loading" class="text-center">
      <v-progress-circular style="width: 64px;height:64px;" indeterminate size="64" color="primary" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import { SELECT_DOCUMENTS } from '@/store/modules/archives/action_types'

import ArchivesListArchiverName from './ArchivesListArchiverName'
import ArchivesListDetailsDialog from './dialogs/ArchivesListDetailsDialog'
import ArchivesListSendByEmailDialog from './dialogs/ArchivesListSendByEmailDialog'
import ArchivesListShareDialog from './dialogs/ArchivesListShareDialog'
import AppDownloadFile from '../common/AppDownloadFile'
import AppMenu from '../common/AppMenu.vue'
import AppPagination from '../common/AppPagination'
import AppTooltip from '../common/AppTooltip'
import BooleanDisplay from '../common/BooleanDisplay'
import AppButton from '../common/buttons/AppButton.vue'
import DateDisplay from '../common/DateDisplay.vue'
import ClosdFildersIcon from '../common/filders/ClosdFildersIcon'

export default {
  name: 'ArchivesList',
  components: {
    ArchivesListArchiverName,
    BooleanDisplay,
    DateDisplay,
    ClosdFildersIcon,
    ArchivesListDetailsDialog,
    ArchivesListShareDialog,
    ArchivesListSendByEmailDialog,
    AppPagination,
    AppMenu,
    AppButton,
    AppDownloadFile,
    AppTooltip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      required: true,
    },
    itemCount: {
      type: Number,
      required: true,
    },
    additionalFields: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      fields: [],
      modalFields: [],
      menuItems: [],
      indexMenuOpen: -1,
      currentItemDetails: null,
      currentItemDetailsOpen: false,
      currentShareItems: [],
      shareDialogOpen: false,
      archiveToBeSendByEmail: null,
      downloadLink: '',
      options: {},
    }
  },
  computed: {
    ...mapState('archives', ['selectedDocuments']),
    ...mapGetters('user', ['isArchivingAdmin', 'currentUserId']),
    allItemsSelected () {
      return this.selectedItems.length !== 0 && this.selectedItems.every((item) => item._selected)
    },
    selectedItems: {
      get () {
        return this.selectedDocuments
      },
      set (val) {
        this.SELECT_DOCUMENTS(val)
      },
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, 'archive-list-items-per-page')
      return itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : 50
    },
  },
  watch: {
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, 'archive-list-items-per-page'))) {
          setUserSetting(this.currentUserId, 'archive-list-items-per-page', newValue.itemsPerPage)
        }
      },
    },
  },
  created () {
    let additionalFields = []
    if (this.additionalFields) {
      additionalFields = this.additionalFields.map((metadata) => ({
        value: metadata.field,
        text: metadata[this.$i18n.locale],
      }))
    }
    const fields = [
      {
        value: 'docName',
        text: this.$t('archives.ArchivesList.nameField'),
        sortable: true,
      },
      {
        value: 'cmRef',
        text: this.$t('archives.ArchivesList.folderRefField'),
        sortable: true,
      },
      {
        value: 'archiveDate',
        text: this.$t('archives.ArchivesList.archiveDateField'),
        sortable: true,
      },
      {
        value: 'archiverName',
        text: this.$t('archives.ArchivesList.archiverNameField'),
        sortable: true,
      },
      {
        value: 'signedDocument',
        text: this.$t('archives.ArchivesList.signedDocumentField'),
      },
      {
        value: 'signedDate',
        text: this.$t('archives.ArchivesList.signDateField'),
        sortable: true,
      },
    ]

    if (additionalFields.length !== 0) {
      additionalFields.forEach((field) => {
        fields.push({
          ...field,
          sortable: true,
        })
      })
    }

    fields.push(
      {
        value: '_options',
        text: this.$t('archives.ArchivesList.optionsField'),
        sortable: false,
      },
    )

    this.modalFields = [
      {
        value: 'cmRef',
        text: this.$t('archives.ArchivesList.folderRefField'),
      },
      {
        value: 'signersNames',
        text: this.$t('archives.ArchivesList.signersNamesField'),
      },
      {
        value: 'archiverName',
        text: this.$t('archives.ArchivesList.archiverNameField'),
      },
      {
        value: 'signedDate',
        text: this.$t('archives.ArchivesList.signDateField'),
      },
      {
        value: 'docExt',
        text: this.$t('archives.ArchivesList.documentFormatField'),
      },
      {
        value: 'docSize',
        text: this.$t('archives.ArchivesList.documentSizeField'),
      },
      {
        value: 'signedDocument',
        text: this.$t('archives.ArchivesList.signedDocumentField'),
      },
      {
        value: 'archiveDate',
        text: this.$t('archives.ArchivesList.archiveDateField'),
      },
      {
        value: 'archiveEndDate',
        text: this.$t('archives.ArchivesList.archiveEndDateField'),
      },
      ...additionalFields,
    ]

    this.fields = fields
  },
  methods: {
    ...mapActions('archives', [SELECT_DOCUMENTS]),
    toggleSelectAllItems () {
      // If we already checked everything, we should deselect them
      // otherwise, select them all
      const toggleValue = !this.allItemsSelected
      this.items.forEach((item) => {
        this.$set(item, '_selected', toggleValue)
      })
    },
    deselectAllItems () {
      this.items.forEach((item) => {
        this.$set(item, '_selected', false)
      })
    },
    getRowClass (item) {
      let classes = ''
      if (item && item._selected) { classes += '--selected-row' }
      if (this.$vuetify.breakpoint.mdAndUp) { classes += ' column-limit' }
      return classes
    },
    toggleMenu (index) {
      if (this.indexMenuOpen !== index) {
        this.indexMenuOpen = index
      } else {
        // Reset
        this.indexMenuOpen = -1
      }
    },
    getMenuForItem (item) {
      const menuItems = [
        {
          title: this.$t('archives.ArchivesList.propertiesOption'),
          action: this.showCurrentDetails,
        },
      ]

      if (this.isArchivingAdmin) {
        menuItems.unshift(
          {
            title: this.$t('archives.ArchivesList.shareOption'),
            action: () => this.shareCurrent(item),
          },
        )
      }

      if (item.hasCertificate) {
        menuItems.push({
          title: this.$t('archives.ArchivesList.certificateOption'),
          action: () => this.downloadCertificate(item),
        })
      }

      menuItems.push({
        title: this.$t('archives.ArchivesList.sendByEmail'),
        action: () => { this.archiveToBeSendByEmail = item },
      })

      return menuItems
    },
    async downloadFile (item) {
      this.downloadLink = `${this.axios.defaults.baseURL}/downloads/downloadArchiveDoc/${item.id}`
      await this.$nextTick()
      this.$refs.appDownloadFileRef.submitForm()
    },
    shareCurrent (item) {
      this.currentShareItems = [item]
      this.shareDialogOpen = true
    },
    showCurrentDetails () {
      this.currentItemDetails = this.items[this.indexMenuOpen]
      this.currentItemDetailsOpen = true
    },
    async downloadCertificate (item) {
      this.downloadLink = `${this.axios.defaults.baseURL}/downloads/downloadArchiveCert/${item.id}`
      await this.$nextTick()
      this.$refs.appDownloadFileRef.submitForm()
    },
    handleDetailsModalAction (actionName) {
      switch (actionName) {
        case 'download':
          this.downloadFile(this.currentItemDetails)
          break
        case 'share':
          this.shareCurrent(this.currentItemDetails)
          break
        default:
          break
      }
    },
    onSortByChange ({ sortBy, sortDesc }) {
      this.$emit('sort-change', {
        sortBy: sortBy[0],
        sortDir: sortDesc[0] ? 'DESC' : 'ASC',
      })
    },
    onPageChange (page) {
      this.$emit('page-change', page)
    },
  },
}
</script>

<style lang="scss" scoped>
.selected-items-bar {
  border: 1px solid var(--v-primary-base);
  flex-wrap: wrap;
}

::v-deep .--selected-row, ::v-deep .--selected-row .v-data-table__mobile-row {
  background-color: var(--v-primary-lighten4);
}

::v-deep .column-limit td {
  max-width: 300px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
