<template>
  <v-container class="mx-auto notFound-container">
    <div v-if="!isWrongUser && !isWrongUserToAdvancedSign" class="text-center notFound-title">{{ $t('Error.views.NotFound.errorTitle') }}</div>
    <div v-if="customMessage" class="text-h3 text-center mt-2">{{ $tc(customMessage, currentUserFullName, { currentUserFullName }) }}</div>
    <div v-else class="text-h3 text-center mt-2">{{ $t('Error.views.NotFound.title2') }}</div>
    <div v-if="isWrongUser || isWrongUserToAdvancedSign" class="text-center mt-11">
      <v-row>
        <v-col class="d-flex justify-end">
          <AppButton v-if="isWrongUser" color="primary" :to="{ name: SIMPLE_SIGNATURE_ROUTE_NAME, params: paramsRouteSigningView }">
            {{ $t('Error.views.NotFound.btnOk') }}
          </AppButton>
          <AppButton v-else color="primary" :to="{ name: LOGOUT_ROUTE_NAME, params: {redirectProps: {returnRoute: paramsRouteSigningAdvancedView } } }">
            {{ $t('Error.views.NotFound.btnOk') }}
          </AppButton>
        </v-col>
        <v-col class="d-flex justify-start">
          <AppButton color="secondary" :to="{name: isAuthent ? ROOMS_ROUTE_NAME :  SIGN_IN_ROUTE_NAME, params: { locale: localeLanguage } }">
            {{ $t('Error.views.NotFound.btnKo') }}
          </AppButton>
        </v-col>
      </v-row>
    </div>
    <div v-else class="text-center mt-11">
      <AppButton color="primary" :to="{name: isAuthent ? ROOMS_ROUTE_NAME :  SIGN_IN_ROUTE_NAME, params: { locale: localeLanguage } }">
        {{ $t('Error.views.NotFound.btn') }}
      </AppButton>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'

import { ROOMS_ROUTE_NAME, SIGN_IN_ROUTE_NAME, SIMPLE_SIGNATURE_ROUTE_NAME, LOGOUT_ROUTE_NAME, SIGNING_VIEW_ROUTE_NAME } from '@/router'

import AppButton from '../../common/buttons/AppButton'
import i18n from '../../i18n'
import store from '../../store/'

export default {
  name: 'NotFound',
  components: { AppButton },
  props: {
    customMessage: {
      type: String,
      required: false,
    },
    paramRoute: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      ROOMS_ROUTE_NAME,
      SIGN_IN_ROUTE_NAME,
      SIMPLE_SIGNATURE_ROUTE_NAME,
      LOGOUT_ROUTE_NAME,
    }
  },
  computed: {
    isAuthent () {
      return axios.defaults.headers.common.Authorization !== undefined
    },
    currentUserFullName () {
      return store.getters['user/profileFullName']
    },
    localeLanguage () {
      return i18n.locale
    },
    isWrongUser () {
      return this.customMessage === 'ERR_WRONG_USER_LOGGED_IN'
    },
    isWrongUserToAdvancedSign () {
      return this.customMessage === 'ERR_WRONG_SIGNER'
    },
    paramsRouteSigningView () {
      const params = this.paramRoute
      params.withoutApiToken = true
      return params
    },
    paramsRouteSigningAdvancedView () {
      const route = {}
      route.name = SIGNING_VIEW_ROUTE_NAME
      route.params = this.paramRoute
      return route
    },
  },
}
</script>

<style scoped>
  .notFound-container {
    max-width: 1100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .notFound-title {
    font-size: 48px;
    font-weight: bold;
  }
</style>
