<template>
  <div :class="{'px-0': $vuetify.breakpoint.smAndDown}">
    <template v-if="isEdited">
      <div>
        <v-skeleton-loader type="text@2"/>
      </div>
    </template>
    <template v-else>
      <!--   v-show doesn't work here   -->
      <div v-if="isDisplayed"
           class="ChecklistTableTitle text--primary position-relative d-flex align-center"
           :class="{ 'is-awaiting-delete-validation': isAwaitingDeleteValidation }"
           :style="{paddingLeft: $vuetify.breakpoint.mdAndUp ? item.depth > 0 ? `${28 * item.depth}px` : '8px' : 0}"
           @click="toggleTitleExpansion"
           @mouseenter="onMouseEnter(`${item.type}-${item.id}`, true)"
           @mouseleave="onMouseLeave"
      >
        <div>
          <AppTooltip top>
            <template #activator="{ on }">
              <font-awesome-icon :icon="['far', 'chevron-right']"
                                class="ChecklistTableTitle-chevron mr-2 body-1"
                                :class="{'ChecklistTableTitle-chevron--open': isTitleExpanded}"
                                v-on="on"
              ></font-awesome-icon>
            </template>
            {{ isTitleExpanded ? $t("project.checklist.title.ChecklistTableTitle.collapseTooltip") : $t("project.checklist.title.ChecklistTableTitle.expandTooltip") }}
          </AppTooltip>
        </div>
        <div v-if="isPm"
             class="px-1 text-center"
        >
          <AppTooltip v-if="!isFakeTitle&& !isChecklistFiltered"
                     top
          >
            <template #activator="{on, attrs}">
              <font-awesome-icon :icon="['far', 'arrows-alt']"
                                 class="dragIcon"
                                 style="cursor: grab"
                                 v-bind="attrs"
                                 v-on="on"
                                 @click.stop
              ></font-awesome-icon>
            </template>
            <span>{{ $t('project.checklist.title.ChecklistTableTitle.dragTooltip') }}</span>
          </AppTooltip>
        </div>
        <div v-if="isPm && !isFakeTitle"
             class="px-1"
        >
          <v-checkbox hide-details
                      :input-value="isTitleSelected"
                      readonly
                      :ripple="false"
                      class="checkboxIcon mt-0 pt-0"
                      @click.stop="onSelectTitle"
          />
        </div>
        <div class="checklist-title py-1 px-0">
          <template v-if="isFakeTitle">
            <div class="text-h3">
              {{ computedTitle }}
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-center">
              <div class="text-h3">{{ item.display }}.</div>
              <template v-if="editIsActive">
                <AppTextField v-model.trim="editableTitle"
                              autofocus
                              maxlength="100"
                              counter="100"
                              @blur="prepareEditTitle"
                              @keypress.enter="prepareEditTitle"
                              @click.stop
                />
              </template>
              <template v-else-if="isPm">
                <span class="ChecklistTableTitle-title d-flex align-center"
                     @click.stop="activeEditMode"
                >
                  <span v-if="computedTitle">{{ computedTitle }}</span>
                  <span v-else class="ChecklistTableTitle-titleTooltip">{{ $t('project.checklist.title.ChecklistTableTitle.titleTooltip') }}</span>
                </span>
              </template>
              <template v-else>
                <div class="text-h3">
                  {{ computedTitle }}
                </div>
              </template>
            </div>
          </template>
        </div>
        <div v-if="!isFakeTitle && isPm"
             v-show="mouseInRow"
             class="py-0 px-1"
        >
          <AppTooltip top>
            <template #activator="{on, attrs}">
              <AppButton icon
                         small
                         :disabled="!canOutdent"
                         :x-padding="1"
                         v-bind="attrs"
                         v-on="on"
                         @click.stop="outdent"
              >
                <font-awesome-icon :icon="['far', 'outdent']"
                >
                </font-awesome-icon>
              </AppButton>
            </template>
            <span>{{ $t('project.checklist.title.ChecklistTableTitle.outdentTooltip') }}</span>
          </AppTooltip>
          <AppTooltip top>
            <template #activator="{on, attrs}">
              <AppButton icon
                         small
                         :disabled="!canIndent"
                         :x-padding="1"
                         v-bind="attrs"
                         v-on="on"
                         @click.stop="indent"
              >
                <font-awesome-icon :icon="['far', 'indent']"
                >
                </font-awesome-icon>
              </AppButton>
            </template>
            <span>{{ $t('project.checklist.title.ChecklistTableTitle.indentTooltip') }}</span>
          </AppTooltip>
          <AppTooltip top>
            <template #activator="{on, attrs}">
              <AppButton icon
                         small
                         :disabled="editIsActive"
                         :x-padding="1"
                         v-bind="attrs"
                         v-on="on"
                         @click.stop="activeEditMode"
              >
                <font-awesome-icon :icon="['far', 'edit']"
                >
                </font-awesome-icon>
              </AppButton>
            </template>
            <span>{{ $t('project.checklist.title.ChecklistTableTitle.editTooltip') }}</span>
          </AppTooltip>
          <AppTooltip top>
            <template #activator="{on, attrs}">
              <AppButton icon
                         small
                         :disabled="editIsActive"
                         :x-padding="1"
                         v-bind="attrs"
                         v-on="on"
                         @click.stop="deleteTitleDialogIsOpen = true"
              >
                <font-awesome-icon :icon="['far', 'trash-can']"
                >
                </font-awesome-icon>
              </AppButton>
            </template>
            <span>{{ $t('project.checklist.title.ChecklistTableTitle.deleteTooltip') }}</span>
          </AppTooltip>
        </div>
        <div v-show="floatingAdd && isPm" class="floating-add-button">
          <AppMenu :menu="floatingMenu">
            <template v-slot:activator="{ on, attrs }">
              <v-tooltip transition="fade-transition" top>
                <template #activator="{on: onTooltip}">
                  <font-awesome-icon
                    v-bind="{...attrs}"
                    :icon="['fas', 'map-marker-plus']"
                    class="fa-rotate-270 marker"
                    v-on="{...onTooltip, ...on}"
                  ></font-awesome-icon>
                </template>
                <span>{{$t('project.checklist.title.ChecklistTableTitle.floatingAddTooltip')}}</span>
              </v-tooltip>
            </template>
          </AppMenu>
        </div>
      </div>
      <CollapseTransition>
        <div v-if="item.children && isTitleExpanded">
          <Draggable v-model="childrenModel"
                     :forceFallback="true"
                     :group="dragGroup"
                     :emptyInsertThreshold="100"
                     :delay="500"
                     :delayOnTouchOnly="true"
                     :handle="`.dragIcon`"
                     :disabled="isChecklistFiltered"
                     @change="preparePostMoveTask"
                     @start="dragging = true"
                     @end="dragging = false"
          >
            <template v-for="child in item.children">
              <component v-bind:is="child.type === 'title' ? 'ChecklistTableTitle' : 'ChecklistTableTask'"
                         :key="`checklist-${child.type}-${child.id}`"
                         :parent="item"
                         :item="child"
                         :mnemo="mnemo"
                         :isMatchingFilters="childIsMatchingFilters(child)"
                         :floatingAdd="elementEntered === `${child.type}-${child.id}`"
                         :elementEntered="child.type === 'title' ? elementEntered : null"
                         @mouseenter.native="child.type === 'task' && onMouseEnter(`${child.type}-${child.id}`, false)"
                         @newElementEntered="onNewElementEntered"
              />
            </template>
          </Draggable>
        </div>
      </CollapseTransition>
    </template>

    <ChecklistTableTitleDeleteDialog v-if="deleteTitleDialogIsOpen"
                                     :mnemo="mnemo"
                                     :title="item"
                                     @close="deleteTitleDialogIsOpen = false"
    />
  </div>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import Draggable from 'vuedraggable'
import { mapActions, mapState, mapGetters } from 'vuex'

import { getLastChild } from '@/common/utils/titles'
import {
  ADD_TITLE_CHILDREN_TO_SELECTION,
  ADD_TITLE_TO_SELECTION,
  COLLAPSE_TITLE,
  CREATE_TASK,
  CREATE_TITLE,
  EDIT_TITLE,
  EXPAND_TITLE,
  GET_CHECKLIST,
  POST_MOVE_TASK,
  REMOVE_TITLE_CHILDREN_FROM_SELECTION,
  REMOVE_TITLE_FROM_SELECTION,
  TITLE_INDENTATION,
} from '@/store/modules/checklist/action_types'
import { SET_PARENT_CHILDREN } from '@/store/modules/checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import AppMenu from '../../../common/AppMenu.vue'
import AppTextField from '../../../common/AppTextField'
import AppTooltip from '../../../common/AppTooltip'
import AppButton from '../../../common/buttons/AppButton'
import { filterChecklistTasks } from '../../../common/utils/checklist'
import ChecklistTableTitleDeleteDialog from '../dialogs/ChecklistTableTitleDeleteDialog'
import ChecklistTableTask from '../task/ChecklistTableTask'

// If a title is less or equal than this, it'll be expanded by default
const AUTO_EXPAND_COUNT_LIMIT = 5

export default {
  name: 'ChecklistTableTitle',
  components: {
    ChecklistTableTitleDeleteDialog,
    AppTextField,
    AppButton,
    ChecklistTableTask,
    CollapseTransition,
    Draggable,
    AppMenu,
    AppTooltip,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: [Object, Array],
      required: true,
    },
    floatingAdd: {
      type: Boolean,
      required: true,
    },
    elementEntered: {
      type: String,
    },
  },
  data () {
    return {
      editIsActive: false,
      editableTitle: null,
      dragging: false,
      mouseInRow: false,
      lastSavedTitle: null,
      deleteTitleDialogIsOpen: false,
    }
  },
  computed: {
    ...mapState('checklist', ['filters', 'searchQuery', 'editTitlePending', 'lastTitleAdded', 'filterPane', 'createTitlePending', 'titlesExpandState']),
    ...mapGetters('checklist', ['flatChecklist', 'isChecklistFiltered', 'selectedTasks', 'selectedTitles']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
    isTitleExpanded () {
      const storedExpand = this.titlesExpandState.find(({ id }) => id === this.item.id)
      if (storedExpand) {
        return storedExpand.value
      } else {
        return this.item.children.length <= AUTO_EXPAND_COUNT_LIMIT
      }
    },
    isTitleSelected () {
      return this.selectedTitles.some(title => title.id === this.item.id)
    },
    isFakeTitle () {
      return this.item.id === 0 && this.item.type === 'title'
    },
    isLastTitleAdded () {
      if (this.lastTitleAdded) {
        return this.lastTitleAdded.id === this.item.id && this.lastTitleAdded.type === this.item.type
      }
      return false
    },
    computedTitle () {
      if (this.isFakeTitle) {
        return this.$t('project.checklist.title.ChecklistTableTitle.fakeTitle')
      }
      if (this.lastSavedTitle) {
        return this.lastSavedTitle
      }
      return this.item.title || ''
    },
    isEdited () {
      return this.editTitlePending === this.item.id
    },
    canOutdent () {
      return this.item.depth > 0
    },
    canIndent () {
      return this.item.depth <= 4 && this.item.display !== '1'
    },
    childrenModel: {
      get () {
        return this.item.children
      },
      set (value) {
        this.$store.commit(`checklist/${SET_PARENT_CHILDREN}`, {
          parent: this.item,
          children: value,
        })
      },
    },
    dragGroup () {
      if (this.isFakeTitle) {
        return {
          name: 'fakeTitle',
          pull: ['children'],
          put: false,
        }
      }
      return {
        name: 'children',
        pull: ['children', 'topLevel'],
        put: this.item.depth < 4
          ? ['children', 'topLevel', 'fakeTitle']
          : false,
      }
    },
    floatingMenu () {
      return [
        {
          title: this.$t('project.checklist.title.ChecklistTableTitle.addTask'),
          action: async () => {
            try {
              const payload = this.prepareFloatingButtonAction('task')
              await this.CREATE_TASK({
                mnemo: this.mnemo,
                data: payload,
              })
              this.$store.commit(ENQUEUE_SNACKBAR, {
                color: 'success',
                message: this.$t('project.checklist.title.ChecklistTableTitle.createTaskSuccess'),
              })
            } catch (e) {
              console.error(e)
              this.$store.commit(ENQUEUE_SNACKBAR, {
                color: 'error',
                message: this.$t('project.checklist.title.ChecklistTableTitle.createTaskError'),
              })
            }
          },
          loading: this.createTaskPending,
        },
        {
          title: this.$t('project.checklist.title.ChecklistTableTitle.addTitle'),
          action: async () => {
            try {
              const payload = this.prepareFloatingButtonAction('title')
              payload.title = ''

              await this.CREATE_TITLE({
                mnemo: this.mnemo,
                data: payload,
              })
              this.$store.commit(ENQUEUE_SNACKBAR, {
                color: 'success',
                message: this.$t('project.checklist.title.ChecklistTableTitle.createTitleSuccess'),
              })
            } catch (e) {
              console.error(e)
              this.$store.commit(ENQUEUE_SNACKBAR, {
                color: 'error',
                message: this.$t('project.checklist.title.ChecklistTableTitle.createTitleError'),
              })
            }
          },
          loading: this.createTitlePending,
        },
      ]
    },
    matchingFilterChildren () {
      const onlyTaskChildren = this.childrenModel.filter((checklistElement) => checklistElement.type === 'task')
      return filterChecklistTasks(onlyTaskChildren, this.filters, this.searchQuery)
    },
    isDisplayed () {
      return !this.isChecklistFiltered || (this.isChecklistFiltered && this.matchingFilterChildren.length > 0)
    },
    isAwaitingDeleteValidation () {
      return this.item.isAwaitingValidation
    },
  },
  watch: {
    isLastTitleAdded (value) {
      if (value) {
        this.onLastTitleAdded()
      }
    },
    'item.title' (value) {
      if (value === this.lastSavedTitle) {
        this.lastSavedTitle = null
      }
    },
  },
  mounted () {
    this.editableTitle = this.item.title
    if (this.isLastTitleAdded) {
      this.onLastTitleAdded()
    }
  },
  methods: {
    ...mapActions('checklist', [
      ADD_TITLE_CHILDREN_TO_SELECTION,
      ADD_TITLE_TO_SELECTION,
      COLLAPSE_TITLE,
      CREATE_TASK,
      CREATE_TITLE,
      EDIT_TITLE,
      EXPAND_TITLE,
      GET_CHECKLIST,
      POST_MOVE_TASK,
      REMOVE_TITLE_CHILDREN_FROM_SELECTION,
      REMOVE_TITLE_FROM_SELECTION,
      TITLE_INDENTATION,
    ]),
    activeEditMode () {
      this.editIsActive = true
    },
    outdent () {
      this.prepareEditIndentation('decrement')
    },
    indent () {
      this.prepareEditIndentation('increment')
    },
    /**
     * An object representing the floating button common data payload for any action using it
     * @typedef {Object} FloatingButtonBasePayload
     * @property {number} prevId - The id of the element that is before the one represented by the payload
     * @property {string} prevType - The type of the element that is before the one represented by the payload
     * @property {number} depth - The 0-based depth of the item we're represented by the payload
     */
    /**
     * Get the floating button action base position payload
     * @param {object} [cachedLastChild] - The last child of the item if already computed
     * @param {boolean} isTitleExpanded - If the current title is expanded or not
     * @returns {FloatingButtonBasePayload}
     */
    getFloatingButtonBasePayload (cachedLastChild, isTitleExpanded) {
      const payload = {
        prevId: this.item.id,
        prevType: this.item.type,
        depth: this.item.depth,
      }
      // If the title is not expanded and has children
      // prevId should be the id of its last child, to align with the title's floating button's position
      if (!isTitleExpanded && this.item?.children.length > 0) {
        if (!cachedLastChild) {
          cachedLastChild = getLastChild(this.item).item
        }
        payload.prevId = cachedLastChild.id
        payload.prevType = cachedLastChild.type
        payload.depth = cachedLastChild.depth
      }

      return payload
    },
    /**
     * Prepare any floating button action and get the base payload for one
     * @param {string} action - The action being prepared. Either `title` or `task`
     * @returns {FloatingButtonBasePayload}
     */
    prepareFloatingButtonAction (action) {
      // We'll expand the titles traversed including ourselves, so we need to store the _current state_
      const wasExpanded = this.isTitleExpanded
      const lastChild = getLastChild(this.item, (lastChildTitleResult) => {
        if (action === 'title') {
          if (lastChildTitleResult.parent.type === 'task') {
            return
          }
        }
        this.EXPAND_TITLE(lastChildTitleResult.parent.id)
      }) ?? { item: this.item }
      return this.getFloatingButtonBasePayload(lastChild.item, wasExpanded)
    },
    async prepareEditTitle () {
      if (this.item.title === this.editableTitle) {
        this.editIsActive = false
      } else {
        try {
          this.lastSavedTitle = this.editableTitle
          await this.EDIT_TITLE({
            parent: this.parent,
            mnemo: this.mnemo,
            item: this.item,
            data: {
              title: this.editableTitle,
            },
          })

          this.editIsActive = false

          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.checklist.title.ChecklistTableTitle.editTitleSuccess'),
          })
        } catch (e) {
          console.error(e)
          this.lastSavedTitle = null
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.title.ChecklistTableTitle.editTitleError'),
          })
        }
      }
    },
    async refreshChecklist () {
      try {
        await this.GET_CHECKLIST(this.mnemo)
      } catch (e) {
        console.error(e)
      }
    },
    async prepareEditIndentation (action) {
      try {
        await this.TITLE_INDENTATION({
          mnemo: this.mnemo,
          titleId: this.item.id,
          action,
        })
        this.refreshChecklist()
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.title.ChecklistTableTitle.editTitleSuccess'),
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.title.ChecklistTableTitle.editTitleError'),
        })
      }
    },
    onLastTitleAdded () {
      this.$el.scrollIntoView({ block: 'center' })
      this.activeEditMode()
    },
    async preparePostMoveTask (e) {
      if (e.moved || e.added) {
        let itemToMove = null
        if (e.moved) {
          itemToMove = e.moved.element
        } else if (e.added) {
          itemToMove = e.added.element
        }
        const itemToMoveIndexInFlattenChecklist = this.flatChecklist.findIndex(el => el.id === itemToMove.id && el.type === itemToMove.type)
        const data = itemToMoveIndexInFlattenChecklist === 0
          ? { newPosition: 0 }
          : {
            prevId: this.flatChecklist[itemToMoveIndexInFlattenChecklist - 1].id,
            prevType: this.flatChecklist[itemToMoveIndexInFlattenChecklist - 1].type,
          }
        const trailItem = this.getTrailItem(itemToMove)
        if (itemToMove.type === 'title') {
          data.newTrailId = trailItem.id
          data.newTrailType = trailItem.type
        }
        try {
          await this.POST_MOVE_TASK({
            mnemo: this.mnemo,
            item: itemToMove,
            data,
          })
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: itemToMove.type === 'title' ? this.$t('project.checklist.title.ChecklistTableTitle.postMoveTitleSuccess') : this.$t('project.checklist.title.ChecklistTableTitle.postMoveTaskSuccess'),
          })
        } catch (e) {
          this.$store.commit('enqueueSnackbar', {
            color: 'error',
            message: itemToMove.type === 'title' ? this.$t('project.checklist.title.ChecklistTableTitle.postMoveTitleError') : this.$t('project.checklist.title.ChecklistTableTitle.postMoveTaskError'),
          })
        }
        this.GET_CHECKLIST(this.mnemo)
      }
    },
    getTrailItem (item) {
      if (item.type === 'task') {
        return item
      } else {
        if (item.children.length === 0) {
          return item
        } else {
          return this.getTrailItem(item.children[item.children.length - 1])
        }
      }
    },
    onSelectTitle () {
      if (this.isTitleSelected) {
        this.REMOVE_TITLE_FROM_SELECTION(this.item)
        this.REMOVE_TITLE_CHILDREN_FROM_SELECTION(this.item)
      } else {
        this.ADD_TITLE_TO_SELECTION(this.item)
        this.ADD_TITLE_CHILDREN_TO_SELECTION(this.item)
      }
    },
    childIsMatchingFilters (child) {
      return child.type === 'task' && this.matchingFilterChildren.some(task => task.id === child.id && task.fullName === child.fullName)
    },
    onMouseEnter (item, isThisTitle) {
      // Contrary to signing checklist, children titles also need to check drag state
      // Otherwise, the fake title will not let its children move
      if (!this.dragging) {
        this.$emit('newElementEntered', item)
      }
      if (isThisTitle) {
        this.mouseInRow = true
      }
    },
    onMouseLeave () {
      this.mouseInRow = false
    },
    // We emit it further up the chain if we got a child title emitting it
    onNewElementEntered (item) {
      this.$emit('newElementEntered', item)
    },
    toggleTitleExpansion () {
      if (this.isTitleExpanded) {
        this.COLLAPSE_TITLE(this.item.id)
      } else {
        this.EXPAND_TITLE(this.item.id)
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .ChecklistTableTitle {
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .ChecklistTableTitle-title {
    user-select: none;
    font-size: 16px;
    font-weight: 600;
    border: 1px dashed transparent;
    border-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;
    min-height: 24px;
    min-width: 100px;
    &:hover {
      border-color: #bdbddb;
    }
  }

  .ChecklistTableTitle-chevron {
    transition: transform .3s;

    &--open {
      transform: rotate(90deg);
    }
  }

  .sortable-ghost .ChecklistTableTitle,
  .sortable-chosen .ChecklistTableTitle,
  .sortable-drag .ChecklistTableTitle {
    background-color: var(--v-primary-lighten4) !important;
    border: 2px dashed var(--v-primary-base) !important;
  }

  ::v-deep .dragIcon:focus {
    outline: none;
  }

  .marker {
    position: absolute;
    bottom: -17px;
    left: -20px;
    cursor: pointer;
    opacity: 0.5;
    font-size: 26px;
    color: var(--v-primary-base);
    z-index: 1;
  }

  .marker:hover {
    opacity: 1;
  }
  .ChecklistTableTitle-titleTooltip {
    font-style: italic;
    color: rgba(51, 51, 51, .51);
    font-size: 14px;
    font-weight: 400;
  }

  .is-awaiting-delete-validation {
    ::v-deep {
      .ChecklistTableTitle-chevron,
      .dragIcon,
      .checkboxIcon,
      .checklist-title,
      .v-btn {
        pointer-events: none !important;
        color: var(--v-grey-lighten3) !important;
      }
    }
  }
</style>
