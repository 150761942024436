export const state = {
  lastAccessTimestamp: 0,
  lastMnemoAccessed: '',
  currentRoom: null,
  processing: false,
  error: null,
  postRoomProcessing: false,
  newRoomModalIsOpen: false,
  roomEnvelopesDownloadPreflight: [],
  roomEnvelopesDownloadPreflightPending: false,
  roomProvidersPending: false,
  roomProvidersError: null,
  roomStats: null,
  roomStatsError: null,
  roomStatsPending: false,
  roomStorageAddonPending: null,
  roomStorageExceededDialogIsOpen: false,
  patchRoomPending: false,
  patchRoomError: null,
  closeRoomPending: false,
  closeRoomError: null,
  signaturesPending: false,
  signatures: null,
  signaturesError: null,
  moreSignaturesPending: false,
  moreSignaturesError: null,
  roomNotificationsPending: false,
  roomNotifications: null,
  roomNotificationsSearchQuery: '',
  roomNotificationsSort: {
    sortBy: '',
    sortDir: '',
  },
  roomNotificationsError: null,
  chaseUsersPending: false,
  chaseUsersError: null,
  chaseTimers: {
    global: {},
    envelope: {},
    signature: {},
    validation: {},
  },
  updateAccessTimePending: false,
  updateAccessTimeError: null,
  roomRightsPending: false,
  roomRights: null,
  roomRightsError: null,
  patchRoomGlobalRightsPending: false,
  patchRoomGlobalRightsError: null,
  patchRoomGroupRightsPending: false,
  patchRoomGroupRightsError: null,
  patchRoomUserRightsPending: false,
  patchRoomUserRightsError: null,
  chaseValidatorPendingIds: [],
  chaseValidatorError: null,
  notifyPmNoMoreSpacePending: false,
  notifyPmPlanDRFullPending: false,
  moveRoomDialogIsOpen: false,
  moveRoomPending: false,
  roomToMove: {},
  roomUnderNdaDialog: {},
  signRoomNdaPending: false,
}
