<template>
  <v-combobox :value="categoriesForm.$model"
              :error-messages="categoriesErrors"
              :items="categories"
              :placeholder="$t('project.question-answer.QuestionAnswerCategoriesSelect.placeholder')"
              :persistent-placeholder="true"
              :search-input.sync="categorySearch"
              :rules="[checkMaxLength]"
              append-icon=""
              clearable
              color="blue darken-2"
              dense
              hide-selected
              item-text="name"
              multiple
              outlined
              return-object
              class="categories-select"
              @blur="createTemporaryCategory"
              @input="selectCategory"
              @keydown.enter.prevent="createTemporaryCategory"
  >
    <template #selection="{item, index}">
      <QuestionAnswerCategoryChip :category-form="categoriesForm.$each[index]"
                                  @remove-category="() => removeCategoryAtIndex(index)"
      />
    </template>

    <template v-if="categorySearch && categorySearch.trim().length > 0" #no-data>
      <div class="px-5 py-2 d-flex align-center" @click="createTemporaryCategory">
        <font-awesome-icon :icon="['fas', 'plus-circle']" class="mr-3 grey--text text--darken-1"></font-awesome-icon>
        <div class="grey--text text--darken-3">
          {{ $t('common.add') }} : <span class="font-weight-bold">{{ categorySearch }}</span>
        </div>
      </div>
    </template>

    <template v-if="optionToCreateCategoryIsDisplayed" #append-item>
      <div class="px-5 py-2 d-flex align-center" @click="createTemporaryCategory">
        <font-awesome-icon :icon="['fas', 'plus-circle']" class="mr-3 grey--text text--darken-1"></font-awesome-icon>
        <div class="grey--text text--darken-3">
          {{ $t('common.add') }} : <span class="font-weight-bold">{{ categorySearch }}</span>
        </div>
      </div>
    </template>
  </v-combobox>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import QuestionAnswerCategoryChip from '@/project/question-answer/QuestionAnswerCategoryChip.vue'
import { QuestionAnswerActionTypes } from '@/store/modules/question-answer/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

const CATEGORY_MAX_LENGTH = 100

export const validations = {
  categories: {
    required,
    $each: {
      name: {
        required,
        maxLength: maxLength(CATEGORY_MAX_LENGTH),
      },
    },
  },
}

export default {
  name: 'QuestionAnswerCategoriesSelect',
  components: { QuestionAnswerCategoryChip },
  props: {
    categoriesForm: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      categorySearch: '',
    }
  },
  computed: {
    ...mapState('questionAnswer', ['categories']),
    ...mapGetters('room', ['roomMnemo']),
    optionToCreateCategoryIsDisplayed () {
      return this.categorySearch?.trim().length > 0 &&
        this.categories.find(category => category.name.toLocaleLowerCase() === this.categorySearch.toLocaleLowerCase()) === undefined &&
        this.categories.some(category => category.name.toLocaleLowerCase().indexOf(this.categorySearch.toLocaleLowerCase()) > -1)
    },
    categoriesErrors () {
      const errors = []
      if (!this.categoriesForm.$dirty) return errors

      if (!this.categoriesForm.required) {
        errors.push(this.$t('common.validations.fieldRequired', {
          fieldName: this.$t('project.question-answer.QuestionAnswerCategoriesSelect.categories'),
        }))
      }

      if (this.categoriesForm.$each.$invalid) {
        errors.push(this.$t('project.question-answer.QuestionAnswerCategoriesSelect.categoryTooLong', {
          maxLength: CATEGORY_MAX_LENGTH,
        }))
      }

      return errors
    },
  },
  created () {
    this.getCategories()
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.GET_CATEGORIES]),
    async getCategories () {
      try {
        this.GET_CATEGORIES(this.roomMnemo)
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.question-answer.QuestionAnswerCategoriesSelect.getCategoriesError'),
        })
      }
    },
    createTemporaryCategory () {
      if (!this.categorySearch) {
        return
      }

      const tempCategory = {
        id: 0,
        name: this.categorySearch,
      }

      this.$emit('change', [...this.categoriesForm.$model, tempCategory])
      this.categorySearch = ''
    },
    selectCategory (categories) {
      this.$emit('change', categories.map(({ id, name }) => ({ id, name })))
    },
    removeCategoryAtIndex (index) {
      const currentCategories = [...this.categoriesForm.$model]
      currentCategories.splice(index, 1)

      this.$emit('change', currentCategories)
    },
    checkMaxLength (values) {
      for (let i = 0; i < values.length; i++) {
        if (values[i].name.length > CATEGORY_MAX_LENGTH) {
          this.removeCategoryAtIndex(i)
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.question-answer.QuestionAnswerCategoriesSelect.categoryTooLong', {
              maxLength: CATEGORY_MAX_LENGTH,
            }),
          })
          return false
        }
      }
      return true
    },
  },
}
</script>

<style scoped lang="scss">
.categories-select {
  // used to display placeholder when input do not have the focus
  // known issue when using v-combobox and chips https://github.com/vuetifyjs/vuetify/issues/11553
  ::v-deep input {
    max-height: inherit !important;
    padding: 0;
  }
}
</style>
