<template>
  <component :is="to ? 'router-link' : 'button'" class="app-button"
          :class="{
             'app-button--small': size === 'small',
             'app-button--medium': size === 'medium',
             'app-button--primary': type === 'primary',
             'app-button--outlined': type === 'outlined',
             'app-button--tonal': type === 'tonal',
             'app-button--ghost': type === 'ghost',
             'app-button--icon': type === 'icon',
             'app-button--counter': type === 'counter',
             'app-button--full-width': fullWidth,
             'app-button--brand': variant === 'brand',
             'app-button--project': variant === 'project',
             'app-button--dataroom': variant === 'dataroom',
             'app-button--archive': variant === 'archive',
             'app-button--neutral': variant === 'neutral',
             'app-button--danger': variant === 'danger',
             'app-button--is-loading': loading,
          }"
          type="button"
          v-bind="$attrs"
          :to="to"
          v-on="$listeners"
  >
    <span class="d-flex align-center column-gap-2" :style="{ visibility: contentVisibility }">
      <!-- @slot for left icon -->
      <slot name="left-icon"></slot>
      <!-- @slot for button text -->
      <slot></slot>
      <!-- @slot for right icon -->
      <slot name="right-icon"></slot>
    </span>
    <v-progress-circular v-show="loading" indeterminate width="3" />
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

export type AppButtonSize = 'small' | 'medium'
export type AppButtonType = 'primary' | 'outlined' | 'tonal' | 'ghost' | 'icon' | 'counter'
export type AppButtonVariant = 'brand' | 'project' | 'dataroom' | 'archive' | 'neutral' | 'danger'

/**
 * @displayName Principal Button
 */
export default Vue.extend({
  name: 'AppButton',
  inheritAttrs: false,
  props: {
    /**
     * Size of the button
     * Possible values are : small, medium
     */
    size: {
      type: String as PropType<AppButtonSize>,
      default: 'medium',
    },
    /**
     * Hierarchy of the button
     * Possible values are : primary, outlined, tonal, icon, counter
     */
    type: {
      type: String as PropType<AppButtonType>,
      default: 'primary',
    },
    /**
     * Color variant
     * Possible values are : brand, project, dataroom, archive, neutral, danger
     */
    variant: {
      type: String as PropType<AppButtonVariant>,
      default: 'brand',
    },
    /**
     * Specifies whether the button should fill parent's width
     */
    fullWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, replace button text with a loader and disable pointer events
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Link object
     */
    to: {
      type: Object as PropType<object | null>,
      default: null,
    },
  },
  computed: {
    contentVisibility () {
      return this.loading ? 'hidden' : 'visible'
    },
  },
})
</script>

<style scoped lang="scss">
.app-button {
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:disabled {
    cursor: default;
  }

  &.app-button--is-loading {
    pointer-events: none;
  }

  & .v-progress-circular {
    height: 22px !important;
    width: 22px !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.app-button--full-width {
    width: 100%;
  }

  &.app-button--small {
    min-height: 24px;
    padding: 5px 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    column-gap: 4px;

    & .v-progress-circular {
      height: 12px !important;
      width: 12px !important;
    }
  }

  &.app-button--medium {
    min-height: 40px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    column-gap: 8px;
  }

  &.app-button--primary {
    &.app-button--brand {
      background-color: var(--v-primary-base);
      color: var(--v-white-base);

      &:hover:enabled {
        background-color: var(--v-primary-darken1);
      }

      &:active:enabled {
        background-color: var(--v-primary-lighten2);
      }
    }
    &.app-button--project {
      background-color: var(--v-project-base);
      color: var(--v-white-base);

      &:hover:enabled {
        background-color: var(--v-project-darken1);
      }

      &:active:enabled {
        background-color: var(--v-project-lighten2);
      }
    }
    &.app-button--dataroom {
      background-color: var(--v-dataroom-base);
      color: var(--v-white-base);

      &:hover:enabled {
        background-color: var(--v-dataroom-darken1);
      }

      &:active:enabled {
        background-color: var(--v-dataroom-lighten2);
      }
    }
    &.app-button--archive {
      background-color: var(--v-archive-base);
      color: var(--v-white-base);

      &:hover:enabled {
        background-color: var(--v-archive-darken1);
      }

      &:active:enabled {
        background-color: var(--v-archive-lighten2);
      }
    }
    &.app-button--danger {
      color: var(--v-white-base);
      outline-color: var(--v-error-base);
      background-color: var(--v-error-base);

      &:hover:enabled {
        background-color: var(--v-error-darken1);
      }

      &:active:enabled {
        background-color: var(--v-error-darken2);
      }
    }
    &:disabled {
      background-color: var(--v-grey-lighten5);
      color: var(--v-grey-lighten3);
    }
  }

  &.app-button--outlined {
    background-color: var(--v-white-base);
    color: var(--v-primary-base);
    outline: 1px solid transparent;
    outline-offset: -1px;

    &.app-button--brand {
      color: var(--v-primary-base);
      outline-color: var(--v-primary-base);

      &:hover:enabled {
        background-color: var(--v-primary-lighten5);
      }

      &:active:enabled {
        background-color: var(--v-primary-lighten5);
        color: var(--v-primary-lighten2);
      }
    }
    &.app-button--project {
      color: var(--v-project-base);
      outline-color: var(--v-project-base);

      &:hover:enabled {
        background-color: var(--v-project-lighten5);
      }

      &:active:enabled {
        background-color: var(--v-project-lighten5);
        color: var(--v-project-lighten2);
      }
    }
    &.app-button--dataroom {
      color: var(--v-dataroom-base);
      outline-color: var(--v-dataroom-base);

      &:hover:enabled {
        background-color: var(--v-dataroom-lighten5);
      }

      &:active:enabled {
        background-color: var(--v-dataroom-lighten5);
        color: var(--v-dataroom-lighten2);
      }
    }
    &.app-button--archive {
      color: var(--v-archive-base);
      outline-color: var(--v-archive-base);

      &:hover:enabled {
        background-color: var(--v-archive-lighten5);
      }

      &:active:enabled {
        background-color: var(--v-archive-lighten5);
        color: var(--v-archive-lighten2);
      }
    }
    &.app-button--neutral {
      color: var(--v-grey-darken2);
      outline-color: var(--v-grey-lighten3);

      &:hover:enabled {
        background-color: var(--v-grey-lighten5);
      }

      &:active:enabled {
        background-color: var(--v-grey-lighten5);
        color: var(--v-grey-lighten1);
      }
    }
    &.app-button--danger {
      color: var(--v-error-base);
      outline-color: var(--v-error-base);

      &:hover:enabled {
        background-color: var(--v-error-lighten1);
      }

      &:active:enabled {
        background-color: var(--v-white-base);
        color: var(--v-error-base);
      }
    }

    &:disabled {
      color: var(--v-grey-lighten3);
      outline-color: var(--v-grey-lighten4);
    }
  }

  &.app-button--tonal {
    border: 1px solid transparent;
    &.app-button--brand {
      color: var(--v-primary-base);
      background-color: var(--v-primary-lighten5);
      &:hover:enabled {
        background-color: var(--v-primary-lighten4);
      }
      &:active:enabled {
        color: var(--v-primary-lighten2);
        background-color: var(--v-primary-lighten4);
      }
    }
    &.app-button--project {
      color: var(--v-project-base);
      background-color: var(--v-project-lighten5);
      &:hover:enabled {
        background-color: var(--v-project-lighten4);
      }
      &:active:enabled {
        color: var(--v-project-lighten2);
        background-color: var(--v-project-lighten5);
      }
    }
    &.app-button--dataroom {
      color: var(--v-dataroom-base);
      background-color: var(--v-dataroom-lighten5);
      &:hover:enabled {
        background-color: var(--v-dataroom-lighten4);
      }
      &:active:enabled {
        color: var(--v-dataroom-lighten2);
        background-color: var(--v-dataroom-lighten5);
      }
    }
    &.app-button--archive {
      color: var(--v-archive-base);
      background-color: var(--v-archive-lighten5);
      &:hover:enabled {
        background-color: var(--v-archive-lighten4);
      }
      &:active:enabled {
        color: var(--v-archive-lighten2);
        background-color: var(--v-archive-lighten5);
      }
    }
    &.app-button--neutral {
      color: var(--v-grey-darken2);
      background-color: var(--v-grey-lighten5);
      border-color: var(--v-grey-lighten3);
      &:hover:enabled {
        border-color: transparent;
        background-color: var(--v-grey-lighten4);
      }
      &:active:enabled {
        border-color: transparent;
        color: var(--v-grey-lighten1);
        background-color: var(--v-grey-lighten4);
      }
    }

    &:disabled {
      color: var(--v-grey-lighten3);
      background-color: var(--v-grey-lighten5);
    }
  }

  &.app-button--ghost {
    background-color: transparent;
    color: var(--v-grey-darken2);
    border-radius: 4px;

    &:hover:enabled {
      background-color: var(--v-grey-lighten5);
    }

    &:active:enabled {
      background-color: var(--v-grey-lighten5);
      color: var(--v-grey-lighten1);
    }

    &:disabled {
      color: var(--v-grey-lighten3);
    }
  }

  &.app-button--counter,  &.app-button--icon {
    background-color: transparent;
    color: var(--v-grey-darken2);
    height: 32px;
    min-width: 32px;

    &:hover:enabled {
      background-color: var(--v-grey-lighten5);
    }

    &:active:enabled {
      color: var(--v-grey-base);
    }

    &:disabled {
      color: var(--v-grey-lighten3);
    }

    &.app-button--small {
      padding: 8px;
    }

    &.app-button--medium {
      padding: 4px;
    }

    &.app-button--counter {
      height: 28px;
      padding: 4px 8px;
    }
  }
}
</style>

<docs>
```vue
<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div>
          <label>
            Loading
            <input type="checkbox" v-model="isLoading">
          </label>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h2">
        Main Buttons
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h3">
        Type Primary
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <table>
          <thead>
          <tr>
            <th />
            <th>Variant Brand</th>
            <th>Variant Project</th>
            <th>Variant Dataroom</th>
            <th>Variant Archive</th>
            <th>Variant Neutral</th>
            <th>Disabled</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Medium</td>
            <td>
              <AppButton size="medium"
                         type="primary"
                         variant="brand"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="primary"
                         variant="project"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="primary"
                         variant="dataroom"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="primary"
                         variant="archive"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              Shouldn't be used
            </td>
            <td>
              <AppButton size="medium"
                         type="primary"
                         variant="brand"
                         disabled
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
          </tr>
          <tr>
            <td>Small</td>
            <td>
              <AppButton size="small"
                         type="primary"
                         variant="brand"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="primary"
                         variant="project"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="primary"
                         variant="dataroom"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="primary"
                         variant="archive"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              Shouldn't be used
            </td>
            <td>
              <AppButton size="small"
                         type="primary"
                         variant="brand"
                         disabled
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
          </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h3">
        Type Outlined
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <table>
          <thead>
          <tr>
            <th />
            <th>Variant Brand</th>
            <th>Variant Project</th>
            <th>Variant Dataroom</th>
            <th>Variant Archive</th>
            <th>Variant Neutral</th>
            <th>Disabled</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Medium</td>
            <td>
              <AppButton size="medium"
                         type="outlined"
                         variant="brand"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="outlined"
                         variant="project"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="outlined"
                         variant="dataroom"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="outlined"
                         variant="archive"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="outlined"
                         variant="neutral"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="outlined"
                         variant="brand"
                         disabled
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
          </tr>
          <tr>
            <td>Small</td>
            <td>
              <AppButton size="small"
                         type="outlined"
                         variant="brand"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="outlined"
                         variant="project"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="outlined"
                         variant="dataroom"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="outlined"
                         variant="archive"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="outlined"
                         variant="neutral"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="outlined"
                         variant="brand"
                         disabled
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
          </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h3">
        Type Tonal
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <table>
          <thead>
          <tr>
            <th />
            <th>Variant Brand</th>
            <th>Variant Project</th>
            <th>Variant Dataroom</th>
            <th>Variant Archive</th>
            <th>Variant Neutral</th>
            <th>Disabled</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Medium</td>
            <td>
              <AppButton size="medium"
                         type="tonal"
                         variant="brand"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="tonal"
                         variant="project"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="tonal"
                         variant="dataroom"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="tonal"
                         variant="archive"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="tonal"
                         variant="neutral"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="tonal"
                         variant="brand"
                         disabled
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
          </tr>
          <tr>
            <td>Small</td>
            <td>
              <AppButton size="small"
                         type="tonal"
                         variant="brand"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="tonal"
                         variant="project"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="tonal"
                         variant="dataroom"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="tonal"
                         variant="archive"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="tonal"
                         variant="neutral"
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
            <td>
              <AppButton size="small"
                         type="tonal"
                         variant="brand"
                         disabled
                         :loading="isLoading"
              >
                Button
              </AppButton>
            </td>
          </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h2">
        Other Buttons
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <table>
          <thead>
          <tr>
            <th />
            <th>small</th>
            <th>medium</th>
            <th>disabled</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td class="align-right">Ghost</td>
              <td>
                <AppButton size="small"
                           type="ghost"
                           :loading="isLoading"
                >
                  Ghost
                </AppButton>
              </td>
              <td>
                <AppButton size="medium"
                           type="ghost"
                           :loading="isLoading"
                >
                  Ghost
                </AppButton>
              </td>
              <td>
                <AppButton size="medium"
                           type="ghost"
                           :disabled="true"
                           :loading="isLoading"
                >
                  Ghost
                </AppButton>
              </td>
            </tr>
            <tr>
            <td class="align-right">
              icon
            </td>
            <td>
              <AppButton size="medium"
                         type="icon"
                         :loading="isLoading"
              >
                <template #left-icon>
                  <font-awesome-icon :icon="leftIcon" />
                </template>
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="icon"
                         :loading="isLoading"
              >
                <template #left-icon>
                  <font-awesome-icon :icon="leftIcon" size="lg" />
                </template>
              </AppButton>
            </td>
            <td>
              <AppButton size="medium"
                         type="icon"
                         :disabled="true"
                         :loading="isLoading"
              >
                <template #left-icon>
                  <font-awesome-icon :icon="leftIcon" size="lg" />
                </template>
              </AppButton>
            </td>
          </tr>
            <tr>
              <td class="align-right">
                counter
              </td>
              <td>
              </td>
              <td>
                <AppButton type="counter"
                           :loading="isLoading"
                >
                  <template #left-icon>
                    <font-awesome-icon :icon="counterIcon" />
                  </template>
                  3
                </AppButton>
              </td>
              <td>
                <AppButton type="counter"
                           :disabled="true"
                           :loading="isLoading"
                >
                  <template #left-icon>
                    <font-awesome-icon :icon="counterIcon" />
                  </template>
                  3
                </AppButton>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <AppButton :full-width="true" :loading="isLoading">Full width button</AppButton>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import AppButton from './AppButton'
  import AppText from '@/design-system/typography/AppText.vue'
  import { duotoneLibrary } from '@/plugins/font-awesome-libraries/duotone-library'
  import { solidLibrary } from '@/plugins/font-awesome-libraries/solid-library'

  export default {
    components: { AppButton, AppText },
    data () {
      return {
        counterIcon: duotoneLibrary.faUserLock,
        isLoading: false,
        leftIcon: solidLibrary.faPlus,
      }
    },
  }
</script>

<style lang="scss">
  label {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  table {
    border-collapse: separate;
    border-spacing: 12px;
    width: 100%;
    margin-bottom: 50px;
  }

  td {
    text-align: center;

    & > * {
      display: inline-block;
    }
  }

  .align-right {
    text-align: right;
  }
</style>
```
</docs>
