import {
  REMOVE_LOG_FILTER,
  RESET_LOGS_SORT,
  SET_CLOSE_LOGS_DIALOG,
  SET_FILE_LOGS_ERROR,
  SET_FILE_LOGS_PENDING,
  SET_FOLDER_LOGS_ERROR,
  SET_FOLDER_LOGS_PENDING,
  SET_LOGS_FILTERS,
  SET_LOGS_FILTERS_PANE_IS_OPEN,
  SET_LOGS_SORT_BY,
  SET_LOGS_SORT_DESC,
  SET_OPEN_LOGS_DIALOG,
  SET_ROOM_LOGS,
  SET_ROOM_LOGS_ERROR,
  SET_ROOM_LOGS_PENDING,
  SET_ROOM_LOGS_SEARCH_QUERY,
  SET_LOGS_TYPES_PENDING,
  SET_LOGS_TYPES,
  SET_LOGS_TYPES_ERROR,
} from './mutation_types'

export const mutations = {
  [SET_LOGS_FILTERS_PANE_IS_OPEN] (state, value) {
    state.logsFiltersPaneIsOpen = value
  },
  [SET_ROOM_LOGS_SEARCH_QUERY] (state, value) {
    state.roomLogsSearchQuery = value
  },
  [SET_CLOSE_LOGS_DIALOG] (state) {
    state.logsDialog = {
      isOpen: false,
      document: null,
    }
  },
  [SET_OPEN_LOGS_DIALOG] (state, document) {
    state.logsDialog = {
      isOpen: true,
      document,
    }
  },
  [SET_FILE_LOGS_PENDING] (state, value) {
    state.fileLogsPending = value
  },
  [SET_FILE_LOGS_ERROR] (state, value) {
    state.fileLogsError = value
  },
  [SET_FOLDER_LOGS_PENDING] (state, value) {
    state.folderLogsPending = value
  },
  [SET_FOLDER_LOGS_ERROR] (state, value) {
    state.folderLogsError = value
  },
  [SET_ROOM_LOGS_PENDING] (state, value) {
    state.roomLogsPending = value
  },
  [SET_ROOM_LOGS] (state, value) {
    state.roomLogs = value
  },
  [SET_ROOM_LOGS_ERROR] (state, value) {
    state.roomLogsError = value
  },
  [SET_LOGS_FILTERS] (state, value) {
    state.logsFilters = value
  },
  [REMOVE_LOG_FILTER] (state, filterToRemove) {
    state.logsFilters = state.logsFilters.filter(
      filter => filter.category.key !== filterToRemove.category.key || filter.value.key !== filterToRemove.value.key,
    )
  },
  [SET_LOGS_SORT_BY] (state, value) {
    state.logsSort.sortBy = value
  },
  [SET_LOGS_SORT_DESC] (state, value) {
    state.logsSort.sortDesc = value
  },
  [RESET_LOGS_SORT] (state) {
    state.logsSort = {
      sortBy: '',
      sortDesc: false,
    }
  },
  [SET_LOGS_TYPES_PENDING] (state, value) {
    state.logsTypesPending = value
  },
  [SET_LOGS_TYPES] (state, value) {
    state.logsTypes = value
  },
  [SET_LOGS_TYPES_ERROR] (state, value) {
    state.logsTypesError = value
  },
}
