<template>
  <v-chip
    :color="computedBackgroundColor"
    dark
    class="question-answer-status-chip"
  >
  <QuestionAnswerStatusIcon :status="status" :useTooltip="false" />
  <span class="ml-2">{{ $t(`common.qaStatus.${status}`) }}</span>
  </v-chip>
</template>

<script>
import colors from 'vuetify/lib/util/colors'

import { QA_STATUS } from '@/common/utils/qa-status'

import QuestionAnswerStatusIcon from './QuestionAnswerStatusIcon.vue'

export default {
  name: 'QuestionAnswerStatusChip',
  components: { QuestionAnswerStatusIcon },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      QA_STATUS,
    }
  },
  computed: {
    computedBackgroundColor () {
      let color
      if (this.status === QA_STATUS.TO_ANSWER) {
        color = colors.orange.base
      }

      if (this.status === QA_STATUS.TO_VALIDATE) {
        color = colors.blue.base
      }

      if (this.status === QA_STATUS.VALIDATED) {
        color = colors.green.base
      }

      return color
    },
  },
}
</script>

<style scoped>
  .question-answer-status-chip ::v-deep svg {
    color: white;
  }
  /* We don't want to have the usual slight light from an hover here
     This change is meant to show the user they can't interact with the chip
     Since it's used purely for styling purposes
  */
  .question-answer-status-chip::v-deep.v-chip:hover::before {
    opacity: 0;
  }
</style>
