<template>
  <div>
    <AppTooltip v-if="envelopeCanBeChase" top>
      <template #activator="{attrs, on}">
        <app-button color="white"
                    class="ml-1"
                    :loading="isLoading"
                    :disabled="isEnvelopeChaseButtonDisabled"
                    size="small"
                    type="tonal"
                    variant="brand"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="prepareChaseEnvelope"
        >
          <span>{{ textChaseButton }}</span>
        </app-button>
      </template>
      <span>{{ chaseEnvelopeTooltip }}</span>
    </AppTooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import { CHASE_USERS, CHASE_VALIDATOR } from '@/store/modules/room/action_types'
import { ADD_CHASE_TIMER_ENVELOPE } from '@/store/modules/room/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'ProjectSettingsSignaturesEnvelopeChaseButton',
  components: { AppTooltip },
  props: {
    item: {
      type: Object,
      required: true,
    },
    currentDate: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState('room', ['chaseTimers']),
    ...mapGetters('room', ['roomMnemo']),
    hasValidatorsToChase (): boolean {
      return !this.item.validated && (this.item.status === 'unvalidated' || this.item.status === 'sent')
    },
    hasSignatoriesToChase (): boolean {
      return this.item.signatories.some(signatory => signatory.status === 'sent')
    },
    envelopeCanBeChase (): boolean {
      return this.hasValidatorsToChase || this.hasSignatoriesToChase
    },
    isEnvelopeChaseButtonDisabled (): boolean {
      const envelopeChaseTimer = this.chaseTimers.envelope?.[this.item.id]
      return envelopeChaseTimer && this.currentDate.isBefore(envelopeChaseTimer)
    },
    chaseEnvelopeTooltip ():TranslateResult | null {
      if (this.hasValidatorsToChase && this.hasSignatoriesToChase) {
        return this.$t('project.project-settings.signatures.ProjectSettingsSignaturesEnvelopeChaseButton.chaseValidatorsAndSignatoriesTooltip')
      } else if (this.hasValidatorsToChase) {
        return this.$t('project.project-settings.signatures.ProjectSettingsSignaturesEnvelopeChaseButton.chaseValidatorsTooltip')
      } else if (this.hasSignatoriesToChase) {
        return this.$t('project.project-settings.signatures.ProjectSettingsSignaturesEnvelopeChaseButton.chaseSignatoriesTooltip')
      }
      return null
    },
    textChaseButton ():TranslateResult | null {
      if (this.hasValidatorsToChase && this.hasSignatoriesToChase) {
        return this.$t('project.project-settings.signatures.ProjectSettingsSignaturesEnvelopeChaseButton.chaseValidatorsAndSignatories')
      } else if (this.hasValidatorsToChase) {
        return this.$t('project.project-settings.signatures.ProjectSettingsSignaturesEnvelopeChaseButton.chaseValidators')
      } else if (this.hasSignatoriesToChase) {
        return this.$t('project.project-settings.signatures.ProjectSettingsSignaturesEnvelopeChaseButton.chaseSignatories')
      }
      return null
    },
  },
  methods: {
    ...mapActions('room', [CHASE_USERS, CHASE_VALIDATOR]),
    ...mapMutations('room', [ADD_CHASE_TIMER_ENVELOPE]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async prepareChaseEnvelope (): Promise<void> {
      // TODO : Handle pendingIDS with vuex-action
      this.isLoading = true
      try {
        if (this.hasValidatorsToChase) {
          await this.CHASE_VALIDATOR({
            mnemo: this.roomMnemo,
            envelopeId: this.item.id,
            data: [],
          })
        }
        if (this.hasSignatoriesToChase) {
          await this.CHASE_USERS({
            mnemo: this.roomMnemo,
            envelopeId: this.item.id,
          })
        }
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('common.chaseSuccess'))
        this.ADD_CHASE_TIMER_ENVELOPE(this.item.id)
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.project-settings.signatures.ProjectSettingsSignaturesEnvelopeChaseButton.chaseEnvelopeError'))
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
