<template>
  <AppButton
    v-bind="$attrs"
    text
    color="primary"
    class="MultiSelectBarButton"
    v-on="$listeners"
  >
    <slot></slot>
  </AppButton>
</template>

<script>
import AppButton from './AppButton.vue'
/**
 * Default button in multi select bar
 * @displayName MultiSelectBarButton
 */
/**
 * Display a button in multi select bar
 */
export default {
  name: 'MultiSelectBarButton',
  components: { AppButton },
}
</script>

<style scoped lang="scss">
.MultiSelectBarButton {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 40px;
  &:hover {
    background-color: var(--v-primary-base);
  }
  &:hover ::v-deep .v-btn__content {
    color: white;
  }
}

</style>
