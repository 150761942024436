import dayjs from 'dayjs'

export const getters = {
  imanageEnabledAndLogged (state, getters, rootState) {
    return rootState.user.profile.subscription?.iManageEnabled && getters.iManageLogged
  },
  iManageLogExpired (state) {
    return !!state.authCode && dayjs(state.currentTime).isAfter(state.authCode.expireAt)
  },
  iManageLogged (state, getters) {
    return !!state.authStatus && !getters.iManageLogExpired
  },
}
