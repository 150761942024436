import {
  SET_TODOS_FOLDER_PENDING,
  SET_TODOS_FOLDER,
  SET_TODOS_FOLDER_ERROR,
  SET_TODOS_FOLDER_STRUCTURE_PENDING,
  SET_TODOS_FOLDER_STRUCTURE,
  SET_TODOS_FOLDER_STRUCTURE_ERROR,
} from './mutation_types'

export const mutations = {
  [SET_TODOS_FOLDER_PENDING] (state, value) {
    state.todosFolderPending = value
  },
  [SET_TODOS_FOLDER] (state, value) {
    state.todosFolder = value
  },
  [SET_TODOS_FOLDER_ERROR] (state, value) {
    state.todosFolderError = value
  },
  [SET_TODOS_FOLDER_STRUCTURE_PENDING] (state, value) {
    state.todosFolderPending = value
  },
  [SET_TODOS_FOLDER_STRUCTURE] (state, value) {
    state.todosFolderStructure = value
  },
  [SET_TODOS_FOLDER_STRUCTURE_ERROR] (state, value) {
    state.todosFolderStructureError = value
  },
}
