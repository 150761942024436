<template>
  <Alert color="light-blue"
         :icon="false"
         no-border
  >
    <v-row>
      <v-col class="d-flex">
        <img src="/img/notarity/notarity-logo.svg" width="120"/>
        <div class="ml-10">
          <p>
            <a :href="$t('project.signed-documents.SignedDocumentsNotarityBanner.authenticateSignatureLink')"
               target="_blank"
               class="app-link light-blue--text"
            >{{ $t('project.signed-documents.SignedDocumentsNotarityBanner.authenticateSignatureLinkText') }}</a>
            {{ $t('project.signed-documents.SignedDocumentsNotarityBanner.authenticateSignatureText') }}
          </p>
          <p>
            <a :href="$t('project.signed-documents.SignedDocumentsNotarityBanner.moreInfoLink')"
               target="_blank"
               class="app-link light-blue--text"
            >
              {{ $t('project.signed-documents.SignedDocumentsNotarityBanner.moreInfo') }}
              <font-awesome-icon :icon="['far', 'external-link']" class="ml-2"></font-awesome-icon>
            </a>
          </p>
        </div>
      </v-col>
    </v-row>
  </Alert>
</template>

<script>
import Alert from '@/common/alerts/Alert.vue'

export default {
  name: 'SignedDocumentsNotarityBanner',
  components: { Alert },
}
</script>
