import { render, staticRenderFns } from "./ProjectSettingsNotificationsTable.vue?vue&type=template&id=41bcf2f9&scoped=true"
import script from "./ProjectSettingsNotificationsTable.vue?vue&type=script&lang=js"
export * from "./ProjectSettingsNotificationsTable.vue?vue&type=script&lang=js"
import style0 from "./ProjectSettingsNotificationsTable.vue?vue&type=style&index=0&id=41bcf2f9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41bcf2f9",
  null
  
)

export default component.exports