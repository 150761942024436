<template>
  <div>
    {{ date }}
  </div>
</template>

<script>
import { SIGNATURE_STATUS } from '@/common/utils/signatureStatus'

import { ISOToShortDate } from '../../common/utils/dates'

export default {
  name: 'SignedDocumentsTableItemDate',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      SIGNATURE_STATUS,
    }
  },
  computed: {
    status () {
      if (this.item.status === 'sent' && this.item.mySignature.status === 'sent') return this.SIGNATURE_STATUS.SENT
      else if (this.item.status === 'sent' && this.item.mySignature.status === 'signed') return this.SIGNATURE_STATUS.SIGNED
      else if (this.item.status === 'signed' && !this.item.mySignature && this.item.otherSignatures.every(o => o.status === 'signed')) return this.SIGNATURE_STATUS.SIGNED_BY_ALL
      else if (this.item.status === 'signed' && this.item.mySignature.status === 'signed') return this.SIGNATURE_STATUS.SIGNED_BY_ALL
      else if (this.item.status === 'voided') return this.SIGNATURE_STATUS.VOIDED
      return null
    },
    date () {
      if (this.status === this.SIGNATURE_STATUS.SENT) {
        return ISOToShortDate(this.item.mySignature.time)
      } else if (this.status === this.SIGNATURE_STATUS.SIGNED && this.item.mySignature.time) {
        return ISOToShortDate(this.item.mySignature.time)
      } else if (this.status === this.SIGNATURE_STATUS.SIGNED_BY_ALL) {
        return ISOToShortDate(this.item.time)
      } else if (this.status === this.SIGNATURE_STATUS.VOIDED) {
        return ISOToShortDate(this.item.time)
      }
      return null
    },
  },
}
</script>
