<template>
  <div>
    <template v-if="!edit">
      <template v-if="date">
        <div class="m-1">
          <template
            v-if="item.objectToPost.fileIsSigned && !item.signedOnClosd"
          >
            <div class="Closd-hoverArea" @click="openDatePicker">
              <DateDisplay :value="date" />
            </div>
          </template>
          <template v-else>
            <DateDisplay :value="date" />
          </template>
        </div>
      </template>
      <template
        v-else-if="item.objectToPost.fileIsSigned && !item.signedOnClosd"
      >
        <div class="Closd-hoverArea" @click="openDatePicker">
          <span class="font-italic text--secondary">
            {{$t("archives.steps.ArchivesAddStep2TableCellDate.lblClickToAddDate")}}
          </span>
        </div>
      </template>
    </template>
    <div v-show="edit" class="align-center flex">
      <AppDatePicker ref="datePicker" v-model="date" class="white" @hidden="edit = false" />
    </div>
  </div>
</template>

<script>
import AppDatePicker from '../../common/AppDatePicker'
import DateDisplay from '../../common/DateDisplay'
export default {
  name: 'ArchivesAddStep2TableCellDate',
  components: {
    AppDatePicker,
    DateDisplay,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      edit: false,
    }
  },
  computed: {
    date: {
      get () {
        return this.item.objectToPost.fileSigningDate
      },
      set (val) {
        this.$emit('newDate', val)
        this.edit = false
      },
    },
  },
  methods: {
    openDatePicker () {
      this.edit = true
      this.$refs.datePicker.menu = true
    },
  },
}
</script>

<style scoped>
  .flex {
    /*
      This exists because we have to be able to toggle to display none
      And if we use d-flex, it uses !important, making v-show unable to
    */
    display: flex;
  }
</style>
