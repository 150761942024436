<template>
  <v-alert class="PrepareSignatureTagAlert"
          :color="backgroundColor"
          :style="`borderColor:${color}33`"
  >
    <div class="d-flex align-center">
      <app-icon :icon-name="computedIcon"
                icon-weight="fas"
                :style="{color: color}"
                class="mr-1"
      />
      <span class="PrepareSignatureTagAlert-text">{{ message }}</span>
    </div>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { AppAlertBackgroundColor, AppAlertColor, AppAlertIcon } from '@/design-system/alert/enums'
import { AppAlertVariant } from '@/design-system/alert/types'

export default defineComponent({
  name: 'PrepareSignatureTagAlert',
  props: {
    message: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<AppAlertVariant>,
      default: 'info',
    },
  },
  computed: {
    backgroundColor (): string {
      return AppAlertBackgroundColor[this.variant].valueOf()
    },
    color (): string {
      return AppAlertColor[this.variant].valueOf()
    },
    computedIcon (): string {
      return AppAlertIcon[this.variant].valueOf()
    },
  },
})
</script>

<style scoped lang="scss">
.PrepareSignatureTagAlert {
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 8px 24px rgba(121, 123, 142, 0.12) !important;
  left: 0;
  margin-top: -42px;
  padding: 4px 8px;
  position: absolute;
  top: 0;
  width: max-content;
  z-index: 2;

  &-text {
    color: var(--v-accent-base);
    font-size: 13px;
    font-style: normal;
  }
}
</style>
