<template>
  <v-pagination
    :total-visible="7"
    v-bind="$attrs"
    :length="computedLength"
    v-on="$listeners"
  >
  </v-pagination>
</template>

<script>
/**
 * Default Pagination
 * @displayName AppPagination
 */
/**
 * A wrapper around the pagination component with default styling and custom default behavior
 * All Vuetify props for the pagination component are usable.
 * @see https://vuetifyjs.com/en/api/v-pagination/#props
 */
export default {
  name: 'AppPagination',
  inheritAttrs: false,
  props: {
    /**
     * Items per page
     * @default 25
     */
    perPage: {
      type: Number,
      default: 25,
    },
    /**
     * Total items count
     */
    total: {
      type: Number,
      required: true,
    },
  },
  computed: {
    computedLength () {
      if (this.total !== 0) {
        return this.$attrs.length || Math.ceil(this.total / this.perPage)
      } else {
        return 0
      }
    },
  },
}
</script>

<docs>
```vue
<template>
  <AppPagination :per-page="25"
                 :total="300"
  />
</template>
```
</docs>
