<template>
  <div>
    <template v-for="(signerName, index) in signersNamesToDisplay">
      {{ signerName}}<template v-if="index !== signersNamesToDisplay.length - 1">,
      </template>
    </template>
    <div v-if="shouldDisplayShowMore">
      <AppButton text color="primary" @click="collapsedList = !collapsedList">
        {{ showMoreBtnText }}
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from '../common/buttons/AppButton.vue'
const SIGNERS_LIMIT_BEFORE_COLLAPSE = 3
export default {
  name: 'ArchivesListSignersNames',
  components: { AppButton },
  props: {
    signersNames: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      collapsedList: true,
    }
  },
  computed: {
    shouldDisplayShowMore () {
      return this.signersNames.length > SIGNERS_LIMIT_BEFORE_COLLAPSE
    },
    signersNamesLeftToDisplay () {
      return this.signersNames.length - SIGNERS_LIMIT_BEFORE_COLLAPSE
    },
    signersNamesToDisplay () {
      if (this.shouldDisplayShowMore && this.collapsedList) {
        return this.signersNames.slice(0, SIGNERS_LIMIT_BEFORE_COLLAPSE)
      } else {
        return this.signersNames
      }
    },
    showMoreBtnText () {
      return this.collapsedList
        ? this.$tc('archives.ArchivesListSignersNames.signersNamesShowMore', this.signersNamesLeftToDisplay,
          { count: this.signersNamesLeftToDisplay })
        : this.$t('archives.ArchivesListSignersNames.signersNamesShowLess')
    },
  },
}
</script>
