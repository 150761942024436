<template>
  <v-tooltip
    :disabled="disabled"
    :transition="transition"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <slot :name="slot" />
    </template>
    <template v-slot:activator="attrs">
      <slot name="activator" v-bind="attrs"></slot>
    </template>
  </v-tooltip>
</template>

<script>
/**
 * A wrapper around the tooltip with a different default transition
 * All Vuetify props for the tooltip component are usable.
 * @see https://vuetifyjs.com/en/api/v-tooltip/#props
 */
export default {
  name: 'AppTooltip',
  inheritAttrs: false,
  props: {
    transition: {
      type: String,
      default: 'fade-transition',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<docs>
```vue
<template>
  <div class="d-flex">
    <AppTooltip top>
      <template #activator="{attrs, on}">
      <span v-bind="attrs"
            v-on="on"
      >
        Some text
      </span>
      </template>
      <span>My tooltip</span>
    </AppTooltip>
  </div>
</template>
```
</docs>
