/**
 * Returns a capitalized string
 * @param {string} string
 * @returns {string} Capitalized string. Sample Output: "My string". Or an empty string if param isn't a string
 * */
function capitalize (string) {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function emailFormatter (value) {
  if (!value) return value
  const parts = value.split('@')
  if (parts.length > 1) {
    parts[1] = parts[1].toLowerCase()
    return parts.join('@')
  } else {
    return value
  }
}

function escapeHtml (value) {
  return value.replace(/(<([^>]+)>)/gi, '')
}

export { capitalize, emailFormatter, escapeHtml }
