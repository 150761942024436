export enum QuestionAnswerActionTypes {
    GET_QUESTIONS = 'GET_QUESTIONS',
    DELETE_QUESTION = 'DELETE_QUESTION',
    GET_CATEGORIES = 'GET_CATEGORIES',
    PATCH_QUESTION = 'PATCH_QUESTION',
    UPDATE_QUESTION_IN_LIST = 'UPDATE_QUESTION_IN_LIST',
    POST_QUESTION = 'POST_QUESTION',
    GET_QUESTION = 'GET_QUESTION',
    POST_ANSWER = 'POST_ANSWER',
    PATCH_QUESTION_RIGHTS = 'PATCH_QUESTION_RIGHTS',
    QUESTIONS_BULK_RIGHTS = 'QUESTIONS_BULK_RIGHTS',
    MARK_QUESTION_AS_VALIDATED = 'MARK_QUESTION_AS_VALIDATED',
    IMPORT_QUESTIONS = 'IMPORT_QUESTIONS',
}
