<template>
  <v-card
    flat
    outlined
    class="pb-4 preview-default-content"
  >
    <v-row v-if="checklistPreviewPending"
           class="d-flex align-center h-100">
      <v-col>
        <div class="text-center">
          <AppLoader/>
        </div>
      </v-col>
    </v-row>
    <template v-else>
      <template v-if="selectedChecklist">
        <v-toolbar class="preview-header" flat dense>
          <v-toolbar-title class="grey--text text--darken-1 subtitle-2 mb-2">
            <h5 class="text-truncated">{{ selectedChecklist.name }}</h5>
          </v-toolbar-title>
        </v-toolbar>

        <v-divider></v-divider>

        <!-- Display the checklist for all levels -->
        <v-card-text class="overflow-y-auto"
                     :style="{ height: `${500 - 42}px` }"> <!-- preview default height - preview header height -->
          <template v-if="!checklistPreviewError">
            <SavedChecklistPreviewTitle v-for="checklist in checklistPreviewTitles"
                                        :key="checklist.id"
                                        :item="checklist"
                                        :depth="1"
                                        class="grey--text text--darken-2"
            >
              <span class="grey--text text--darken-4 font-weight-medium">{{computedTitle(checklist)}}</span>
            </SavedChecklistPreviewTitle>
          </template>
          <div v-else class="text-center">
            {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.checklistPreview.error') }}
          </div>
        </v-card-text>
      </template>
      <v-row v-else class="d-flex align-center h-100">
        <v-col>
          <div class="d-flex flex-column justify-center align-center">
            <!--
              Stack of two images together because of the design
            -->
            <v-img src="/img/checklist/checklist-preview-illustration-1.svg"/>
            <v-img src="/img/checklist/checklist-preview-illustration-1.svg"
                   style="margin-top: -60px; margin-right: -45px;"
            />
            <div class="text-center">
              {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.checklistPreview.home') }}
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import AppLoader from '@/common/AppLoader.vue'

  import SavedChecklistPreviewTitle from './SavedChecklistPreviewTitle.vue'
  import {
    GET_SAVED_CHECKLIST_PREVIEW,
    GET_LEXISNEXIS_CHECKLIST_PREVIEW,
  } from '../../../store/modules/checklist/action_types'
  import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

  const INDENT_MARGIN = 3

  export default {
    name: 'SavedChecklistPreview',
    components: {
      AppLoader,
      SavedChecklistPreviewTitle,
    },
    props: {
      isLexisNexisChecklist: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        INDENT_MARGIN,
      }
    },
    computed: {
      ...mapState('checklist', [
        // SavedChecklists
        'selectedSavedChecklist',
        'savedChecklistPreviewPending',
        'savedChecklistPreview',
        'savedChecklistPreviewError',
        // LexisNexisChecklists
        'selectedLexisNexisChecklist',
        'lexisNexisChecklistPreviewPending',
        'lexisNexisChecklistPreview',
        'lexisNexisChecklistPreviewError',
      ]),
      selectedChecklist () {
        return this.isLexisNexisChecklist
        ? this.selectedLexisNexisChecklist
        : this.selectedSavedChecklist
      },
      checklistPreviewPending () {
        return this.isLexisNexisChecklist
        ? this.lexisNexisChecklistPreviewPending
        : this.savedChecklistPreviewPending
      },
      checklistPreviewTitles () {
        const savedChecklistPreview = this.isLexisNexisChecklist
          ? this.lexisNexisChecklistPreview
          : this.savedChecklistPreview

        const fakeTitleIndex = savedChecklistPreview.findIndex(item => item.display === null)
        if (fakeTitleIndex > -1) {
            const [nullDisplayItem] = savedChecklistPreview.splice(fakeTitleIndex, 1)
            savedChecklistPreview.unshift(nullDisplayItem)
        }

        return savedChecklistPreview
      },
      checklistPreviewError () {
        return this.isLexisNexisChecklist
        ? this.lexisNexisChecklistPreviewError
        : this.savedChecklistPreviewError
      },
    },
    watch: {
      'selectedChecklist.id': {
        handler (value) {
          if (value) {
            this.getChecklistPreview()
          }
        },
      },
    },
    methods: {
      ...mapActions('checklist', [
        GET_SAVED_CHECKLIST_PREVIEW,
        GET_LEXISNEXIS_CHECKLIST_PREVIEW,
      ]),
      async getChecklistPreview () {
        try {
          if (this.isLexisNexisChecklist) {
            await this.GET_LEXISNEXIS_CHECKLIST_PREVIEW({
              todoListId: this.selectedChecklist.id,
            })
          } else {
            await this.GET_SAVED_CHECKLIST_PREVIEW({
              todoListId: this.selectedChecklist.id,
            })
          }
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.checklistPreview.error'),
          })
        }
      },
      isFakeTitle (checklist) {
        return checklist.display === null && checklist.type === 'title'
      },
      computedTitle (checklist) {
        if (this.isFakeTitle(checklist)) {
          return this.$t('project.checklist.title.ChecklistTableTitle.fakeTitle')
        }
        return `${checklist.display}. ${(checklist.title || '')}`
      },
    },
  }
</script>

<style scoped lang="scss">
  .preview-header {
    background-color: var(--v-blue-base) !important;
    height: 42px !important; // 42 to align with table header height
  }
  .preview-default-content {
    // Basic container height set to 500 as
    // "arbitrary height that leaves enough room for the contents"
    // while being mostly visible in most viewports
    height: 500px;
    position: sticky;
    top: 110px;
  }
</style>
