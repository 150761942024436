import { reduce } from 'lodash-es'

import documentsService from '@/services/documents.service'
import { DESELECT_ALL_DOCUMENTS, GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'
import { GET_ROOM_STATS } from '@/store/modules/room/action_types'

import {
  CLOSE_DOCUMENTS_DELETION_DIALOG,
  DELETE_FILDERS,
  OPEN_DOCUMENTS_DELETION_DIALOG,
} from './action_types'
import {
  SET_DOCUMENTS,
  SET_IS_OPEN,
  SET_LOADING,
} from './mutation_types'

export const actions = {
  [OPEN_DOCUMENTS_DELETION_DIALOG] ({ commit }, documents) {
    commit(SET_IS_OPEN, true)
    commit(SET_DOCUMENTS, documents)
  },
  [CLOSE_DOCUMENTS_DELETION_DIALOG] ({ commit }) {
    commit(SET_LOADING, false)
    commit(SET_IS_OPEN, false)
    commit(SET_DOCUMENTS, [])
  },
  async [DELETE_FILDERS] ({ commit, state, getters, rootState, rootGetters, dispatch }) {
    commit(SET_LOADING, true)

    const data = reduce(state.documents, (result, document) => {
      if (document.type === 'folder') {
        result.folderIds.push(document.id)
      } else if (document.type === 'file') {
        result.fileIds.push(document.id)
      }

      return result
    }, { folderIds: [], fileIds: [] })
    const { currentRoom } = rootState.room

    await documentsService.deleteFilders(currentRoom.mnemo, data)

    dispatch(`documents/${GET_DOCUMENTS_BY_ID}`, {
      mnemo: undefined,
      id: undefined,
      queryObject: {
        markRecent: true,
      },
    }, { root: true })
    dispatch(`documents/${DESELECT_ALL_DOCUMENTS}`, null, { root: true })
    dispatch(CLOSE_DOCUMENTS_DELETION_DIALOG)
    if (rootGetters['room/isDataRoom']) {
      dispatch(`room/${GET_ROOM_STATS}`, currentRoom.mnemo, { root: true })
    }
  },
}
