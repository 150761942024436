import dayjs from 'dayjs'

import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'

export enum SurveyId {
  'CSAT' = 'SURVEY',
  'NPS' = 'NPS_SURVEY',
}
// Assumes a frequency quantified in months
// NOTE: if you want to support arbitrary durations here, refactor to support durations
// https://day.js.org/docs/en/durations/durations
export const SURVEY_FREQUENCY = {
  [SurveyId.CSAT]: 1,
  [SurveyId.NPS]: 2,
}
declare global {
  interface Window {
    // We disable it because we don't manage that type and they don't provide a type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    satismeter: any;
  }
}

function initSatismeter () {
  window.satismeter = window.satismeter || function () {
    (window.satismeter.q = window.satismeter.q || []).push(arguments)
  }
  window.satismeter.l = Date.now()
  const script = document.createElement('script')
  const parent = document.getElementsByTagName('script')[0].parentNode
  script.async = true
  script.src = 'https://app.satismeter.com/js'
  parent?.appendChild(script)
}

const LAST_SURVEY_KEY_PREFIX = 'last-survey'

function getLastSurveyKey (surveyId: SurveyId) {
  return `${LAST_SURVEY_KEY_PREFIX}-${surveyId}`
}

function getLastSurvey (surveyId: SurveyId, userId) {
  return getUserSetting(userId, getLastSurveyKey(surveyId))
}

function setLastSurvey (surveyId: SurveyId, userId, surveyDate) {
  setUserSetting(userId, getLastSurveyKey(surveyId), surveyDate)
}

export function surveyCanBeTriggered (surveyId: SurveyId, userId) {
  if (!process.env.VUE_APP_SATISMETER_WRITE_KEY) {
    return false
  }

  const today = dayjs()
  let lastSurvey = getLastSurvey(surveyId, userId)

  if (!lastSurvey) {
    // If the survey is the legacy one, there's a possibility it uses the old storage key
    if (surveyId === SurveyId.CSAT) {
      const legacySetting = getUserSetting(userId, LAST_SURVEY_KEY_PREFIX)
      if (legacySetting) {
        lastSurvey = legacySetting
      } else {
        return true
      }
    } else {
      return true
    }
  }

  return dayjs(lastSurvey).add(SURVEY_FREQUENCY[surveyId], 'M').isBefore(today)
}

export function triggerSurvey (surveyId: SurveyId, userId, userName, userEmail, isPayingUser) {
  initSatismeter()

  window.satismeter({
    writeKey: process.env.VUE_APP_SATISMETER_WRITE_KEY,
    userId: userId,
    traits: {
      name: userName,
      email: userEmail,
    },
  })
  const eventTrigger = `TRIGGER_${isPayingUser ? '' : 'GUEST_'}${surveyId}`
  window.satismeter('track', { event: eventTrigger })

  const today = dayjs().format('YYYY-MM-DD')

  setLastSurvey(surveyId, userId, today)
}
