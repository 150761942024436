<template>
  <div class="signer-item"
       :class="{ 'signer-item--icon': removable }"
       @dragstart="dragstart"
  >
    <span class="signer-item__fullname">
      <font-awesome-icon :icon="['fas', 'grip-vertical']"
                         class="mr-1 grey--text"
      ></font-awesome-icon>
      {{ signer.fullName }}
    </span>

    <AppButton v-if="removable"
               small
               icon
               @click="remove"
    >
      <font-awesome-icon :icon="['far', 'xmark']"
                         style="width: 18px; height: 18px;"
      ></font-awesome-icon>
    </AppButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppButton from '../../../common/buttons/AppButton'

export default {
  name: 'SignerOrderItem',
  components: { AppButton },
  props: {
    signerId: {
      type: Number,
      required: true,
    },
    removable: {
      type: Boolean,
    },
    groupIndex: {
      required: true,
      validator: (value) => { return typeof value === 'number' || value === null },
    },
  },
  computed: {
    ...mapGetters('groups', ['allRoomMembers']),
    signer () {
      return this.allRoomMembers.find(member => member.id === this.signerId)
    },
  },
  methods: {
    dragstart (event) {
      event.dataTransfer.setData('fromGroup', this.groupIndex)
      this.$emit('dragstart', event)
    },
    remove () {
      this.$emit('remove', this.signerId)
    },
  },
}
</script>

<style scoped lang="scss">
.signer-item {
  max-width: 200px;
  display: flex;
  align-items: center;
  background: #ffffff;
  color: #424242;
  height: 50px;
  padding: 0 16px;
  border-radius: 4px;
  cursor: move;

  &__fullname {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &--icon {
    padding: 0 10px 0 16px;
    justify-content: space-between;
  }
}
</style>
