export type EnvelopeTemplatesState = {
  deleteAllTagsPending: boolean
  deleteEnvelopeTemplatesPending: boolean
  deleteSignerPendingIds: number[]
  deleteTagPending: boolean
  dragTagActive: object
  dragTagActivePending: boolean
  duplicateEnvelopeTemplatePending: boolean
  duplicateTagPending: boolean
  envelopeTemplateDisplayInformations: EnvelopeDisplayInformations | null
  envelopeTemplateDisplayInformationsPending: boolean
  envelopeTemplateDrafts: Array<DraftEnvelopeTemplate>
  envelopeTemplateTaggingInformations: object | null
  envelopeTemplateTaggingInformationsPending: boolean
  envelopeTemplates: object | null
  getEnvelopeTemplatesPending: boolean
  lastSave: Date | null
  pageContents: Record<number, { data: string, isLoading: boolean }>
  pageThumbs: Record<number, { data: string, isLoading: boolean }>
  patchTagPending: boolean
  patchEnvelopeTemplatePending: boolean
  postEnvelopeTemplatePending: boolean
  postTagPending: boolean
  renameEnvelopeTemplatePending: boolean
  renameEnvelopeTemplateSignerPending: boolean
  saveEnvelopeAsTemplatePending: boolean
}

export const state: EnvelopeTemplatesState = {
  deleteAllTagsPending: false,
  deleteEnvelopeTemplatesPending: false,
  deleteSignerPendingIds: [],
  deleteTagPending: false,
  dragTagActive: {
    value: false,
    tag: null,
  },
  dragTagActivePending: false,
  duplicateEnvelopeTemplatePending: false,
  duplicateTagPending: false,
  envelopeTemplateDisplayInformations: null,
  envelopeTemplateDisplayInformationsPending: false,
  envelopeTemplateDrafts: [],
  envelopeTemplateTaggingInformations: null,
  envelopeTemplateTaggingInformationsPending: false,
  envelopeTemplates: null,
  getEnvelopeTemplatesPending: false,
  lastSave: null,
  pageContents: {},
  pageThumbs: {},
  patchTagPending: false,
  patchEnvelopeTemplatePending: false,
  postEnvelopeTemplatePending: false,
  postTagPending: false,
  renameEnvelopeTemplatePending: false,
  renameEnvelopeTemplateSignerPending: false,
  saveEnvelopeAsTemplatePending: false,
}
