<template>
  <div>
    <AppDialog is-open
               eager
               size="xxl"
               transition="scroll-y-transition"
               hide-footer
               :close-button="false"
    >
      <template #title>
        <div class="paywall-modal-header-container">
          <img
            v-if="$vuetify.breakpoint.smAndUp"
            src="/img/closd-symbol.svg"
            width="25"
          />
          <h2 class="px-6 text-center paywall-modal-header-title">
            {{ $t('archives.views.ArchivesPaywallDialog.title') }}
          </h2>
          <button
            type="button"
            aria-label="Close"
            class="close positioned-close-button"
            @click="close"
          >
            ×
          </button>
        </div>
      </template>

      <template #body>
        <v-row align="center" class="mt-10 mt-sm-5">
          <v-col cols="12" sm="8" class="archiving-description overflow-auto">
            <!-- This has one link -->
            <p class="mb-4" v-html="$t('archives.views.ArchivesPaywallDialog.intro')"></p>
            <ul class="custom-bulletpoint-list">
              <li class="custom-bulletpoint">
                <h3 class="custom-bulletpoint-title">
                  {{ $t('archives.views.ArchivesPaywallDialog.bullet1.title') }}
                </h3>
                <p>{{ $t('archives.views.ArchivesPaywallDialog.bullet1.content') }}</p>
              </li>
              <li class="custom-bulletpoint">
                <h3 class="custom-bulletpoint-title">
                  {{ $t('archives.views.ArchivesPaywallDialog.bullet2.title') }}
                </h3>
                <p>{{ $t('archives.views.ArchivesPaywallDialog.bullet2.content') }}</p>
              </li>
              <li class="custom-bulletpoint">
                <h3 class="custom-bulletpoint-title">
                  {{ $t('archives.views.ArchivesPaywallDialog.bullet3.title') }}
                </h3>
                <p>{{ $t('archives.views.ArchivesPaywallDialog.bullet3.content') }}</p>
              </li>
              <li class="custom-bulletpoint">
                <h3 class="custom-bulletpoint-title">
                  {{ $t('archives.views.ArchivesPaywallDialog.bullet4.title') }}
                </h3>
                <p>{{ $t('archives.views.ArchivesPaywallDialog.bullet4.content') }}</p>
              </li>
              <li class="custom-bulletpoint">
                <h3 class="custom-bulletpoint-title">
                  {{ $t('archives.views.ArchivesPaywallDialog.bullet5.title') }}
                </h3>
                <!-- This has list items -->
                <p
                  class="ml-4"
                  v-html="$t('archives.views.ArchivesPaywallDialog.bullet5.content')"
                ></p>
              </li>
            </ul>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="text-center"
          >
            <img
              v-if="$vuetify.breakpoint.smAndUp"
              src="/img/archives/archiving.svg"
              class="archiving-illustration mb-4"
            />
            <AppButton
              variant="primary"
              href="https://www.closd.com/contact/"
              target="_blank"
            >{{ $t('archives.views.ArchivesPaywallDialog.contactBtnLabel') }}
            </AppButton
            >
            <p class="text-muted mt-2">
              {{ $t('archives.views.ArchivesPaywallDialog.contactPhoneText') }}
            </p>
          </v-col>
        </v-row>
      </template>
    </AppDialog>
    <!-- This is not meant to be interactable at all, because of the persistent modal -->
    <div class="archives-preview-container">
      <Archives/>
    </div>
  </div>
</template>

<script>
import Archives from './Archives.vue'
import AppButton from '../../common/buttons/AppButton.vue'
import AppDialog from '../../common/dialogs/AppDialog'

export default {
  name: 'ArchivesPaywallDialog',
  components: {
    AppButton,
    AppDialog,
    Archives,
  },
  methods: {
    close () {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
.archives-preview-container {
  filter: blur(2px);
}

.paywall-modal-header-title {
  font-size: 18px;
}

@media (min-width: 768px) {
  .paywall-modal-header-title {
    font-size: 22px;
  }
}

.paywall-modal-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  word-break: break-word;
  background-color: #ffe3e1;
}

.positioned-close-button {
  position: absolute;
  right: 20px;
  bottom: 17px;
}

.archiving-illustration {
  max-width: 350px;
}

.archiving-description {
  max-height: 500px;
}

.custom-bulletpoint p {
  margin-top: 8px;
  margin-bottom: 8px;
}

.custom-bulletpoint-list {
  list-style: none;
}

.custom-bulletpoint::before {
  content: "";
  font-size: 10px;
  color: var(--v-primary-base);
  margin-right: 8px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.custom-bulletpoint-title {
  // Otherwise the ::before element might get wrapped badly
  display: inline;
}

.sticky-contact {
  position: absolute;
  bottom: 0;
  // If we don't, there's a sort of overflow...
  width: calc(100% - 20px);
}
</style>
