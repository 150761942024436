<template>
  <div>
    <AppMenu :menu="menu">
      <template #activator="{ on, attrs }">
        <v-btn icon
               v-bind="attrs"
               v-on="on"
        >
          <font-awesome-icon :icon="['far', 'ellipsis-v']" class="body-1 grey--text"/>
        </v-btn>
      </template>
    </AppMenu>
    <ConfirmDeleteDialog v-if="isConfirmDeleteDialogOpen"
                         :envelope-templates="[envelopeTemplate]"
                         @close="isConfirmDeleteDialogOpen = false"
    />
    <RenameDialog v-if="isRenameDialogOpen"
                  :envelope-template="envelopeTemplate"
                  @close="isRenameDialogOpen = false"
    />
    <DuplicateDialog v-if="isDuplicateDialogOpen"
                     :envelope-template="envelopeTemplate"
                     @close="isDuplicateDialogOpen = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { mapMutations, mapState } from 'vuex'

import AppMenu from '@/common/AppMenu.vue'
import ConfirmDeleteDialog from '@/project/signing-checklist/envelope-templates/dialogs/ConfirmDeleteDialog.vue'
import DuplicateDialog from '@/project/signing-checklist/envelope-templates/dialogs/DuplicateDialog.vue'
import RenameDialog from '@/project/signing-checklist/envelope-templates/dialogs/RenameDialog.vue'
import { ENVELOPE_TEMPLATE_UPDATE_ROUTE_NAME } from '@/router'

export default defineComponent({
  name: 'Options',
  components: {
    AppMenu,
    ConfirmDeleteDialog,
    DuplicateDialog,
    RenameDialog,
  },
  props: {
    envelopeTemplate: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isConfirmDeleteDialogOpen: false,
      isDuplicateDialogOpen: false,
      isRenameDialogOpen: false,
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    templateWasCreatedByCurrentUser (): boolean {
      return this.envelopeTemplate.createdBy.id === this.profile.id
    },
    menu () {
      const menu: {
        title: TranslateResult,
        action: () => void
      }[] = []

      if (this.templateWasCreatedByCurrentUser) {
        menu.push(
          {
            title: this.$t('project.signing-checklist.envelope-templates.table-columns.Options.modifyTemplate'),
            action: () => {
              this.$router.push({ name: ENVELOPE_TEMPLATE_UPDATE_ROUTE_NAME, query: { envelopeTemplateId: this.envelopeTemplate.id } })
            },
          },
          {
            title: this.$t('common.rename'),
            action: () => { this.isRenameDialogOpen = true },
          },
        )
      }

      menu.push({
        title: this.$t('common.duplicate'),
        action: () => { this.isDuplicateDialogOpen = true },
      })

      if (this.templateWasCreatedByCurrentUser) {
        menu.push({
          title: this.$t('common.delete'),
          action: () => { this.isConfirmDeleteDialogOpen = true },
        })
      }

      return menu
    },
  },
})
</script>
