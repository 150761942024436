<template>
  <AppTooltip top>
    <template #activator="{ on, attrs }">
      <!-- We use a wrapper div to retain the disable styling _and_ the tooltip hover event -->
      <div v-bind="attrs" v-on="on">
        <v-btn
          icon
          :disabled="fileTooLarge"
          @click="handleClick"
        >
          <PolyOfficeIcon
            color="#FC5068"
            class="mr-0"
          />
        </v-btn>
      </div>
    </template>
    <span>{{ fileTooLarge ? $tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1) : $t('common.polyOffice.PolyOfficeIconButtonExport.exportFiles') }}</span>
  </AppTooltip>
</template>

<script>
import AppTooltip from '@/common/AppTooltip.vue'
import PolyOfficeIcon from '@/common/polyOffice/PolyOfficeIcon.vue'

import { POLYOFFICE_MAX_EXPORT_SIZE } from './constants'

export default {
  name: 'PolyOfficeIconButtonExport',
  components: {
    AppTooltip,
    PolyOfficeIcon,
  },
  props: {
    fileSize: {
      type: Number,
      required: true,
    },
  },
  computed: {
    fileTooLarge () {
      return POLYOFFICE_MAX_EXPORT_SIZE <= this.fileSize
    },
  },
  methods: {
    handleClick () {
      this.$emit('polyOfficeOpenDialog')
    },
  },
}
</script>
