<template>
  <v-row class="mb-3">
    <v-col>
      <DataTableFiltersSummary :active-filters="filters"
                              @clear-filter="clearFilter"
                              @clear-all-filters="clearAllFilters"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import DataTableFiltersSummary from '@/common/filters/DataTableFiltersSummary.vue'
import { REMOVE_DOCUMENT_FILTER, SET_DOCUMENT_FILTERS } from '@/store/modules/documents/mutation_types'

export default {
  name: 'DocumentsHeaderFilters',
  components: { DataTableFiltersSummary },
  computed: {
    ...mapState('documents', ['filters']),
  },
  methods: {
    ...mapMutations('documents', [REMOVE_DOCUMENT_FILTER, SET_DOCUMENT_FILTERS]),
    clearFilter (filter) {
      this.REMOVE_DOCUMENT_FILTER(filter)
    },
    clearAllFilters () {
      this.SET_DOCUMENT_FILTERS([])
    },
  },
}
</script>
