<template>
  <div class="document-selection-list-item"
      :class="{
          'item--info': variant === 'info',
          'item--neutral': variant === 'neutral',
      }"
  >

    <div class="item-content">
      <slot name="prepend">
        <app-badge v-if="document.version" :variant="variant">{{ `v${document.version}` }}</app-badge>
      </slot>
      <ClosdFildersIcon :document="document" :size="document.type === 'folder' ? 24 : 22"/>
      <template v-if="downloadable">
        <AppDownloadFile :link="documentDownloadLink" class="text-truncated">
          <template #default="{ submitForm }">
            <div class="clickable" @click="submitForm">
              <AppTooltip top>
                <template #activator="{ on, attrs }">
                  <div class="document-name"
                      v-bind="attrs"
                      v-on="on"
                  >
                    {{ displayedDocumentName }}
                  </div>
                </template>
                {{ displayedDocumentName }}
              </AppTooltip>
            </div>
          </template>
        </AppDownloadFile>
      </template>
      <template v-else>
        <AppTooltip top>
          <template #activator="{ on, attrs }">
            <div class="document-name"
                v-bind="attrs"
                v-on="on"
            >
              {{ displayedDocumentName }}
            </div>
          </template>
          {{ displayedDocumentName }}
        </AppTooltip>
      </template>
      <div class="document-name-append">
        <slot name="append"></slot>
        <app-icon v-if="removable" icon-name="xmark" class="clickable ml-auto grey--text text--darken-1" @click="onRemoveDocument(document.id)" />
      </div>
    </div>
    <slot name="under"></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import AppTooltip from '@/common/AppTooltip'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon'

const TRANSLATION_KEY_PREFIX = 'common.document-selection.DocumentSelectionListItem'

export default {
  name: 'DocumentSelectionListItem',
  components: {
    AppDownloadFile,
    AppTooltip,
    ClosdFildersIcon,
  },
  emits: ['remove'],
  props: {
    document: {
      type: [Object, File],
      required: true,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
    },
    variant: {
      type: String,
      default: 'neutral',
    },
  },
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
    }
  },
  computed: {
    ...mapState('documents', ['numberingEnabled']),
    displayedDocumentName () {
      const documentName = this.document.basename || this.document.name
      return this.numberingEnabled && this.document.numbering ? this.document.numbering + ' ' + documentName : documentName
    },
    documentDownloadLink () {
      return `${this.axios.defaults.baseURL}/room/${this.$route.params.mnemo}/downloads/file/${this.document.id}`
    },
  },
  methods: {
    onRemoveDocument (documentId) {
      this.$emit('remove', documentId)
    },
  },
}
</script>

<style lang="scss" scoped>
.document-selection-list-item {
  border-radius: 8px;
  min-height: 40px;
  padding: 8px 16px;

  .item-content {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: start;
  }

  &.item--neutral {
    border: 1px solid var(--v-grey-lighten5);
    background-color: var(--v-grey-lighten6);
  }

  &.item--info {
    border: 2px solid var(--v-info-darken1);
    background-color: var(--v-info-lighten5);
  }

  .document-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .document-name-append {
    align-items: center;
    column-gap: 12px;
    display: flex;
    flex-grow: 1;
    justify-content: end;
    margin-left: 12px;
  }
}
::v-deep .v-skeleton-loader__text {
  margin-bottom: 0;
}
</style>
