<template>
  <div class="d-flex align-center justify-space-between">
    <SearchTextField v-model="searchQuery"
                    :disabled="roomNotificationsPending"
                    :tooltipText="$t('project.project-settings.ProjectSettingsNotifications.searchPlaceholder')"
                    @keydown.enter.native="onEnterDown"
                    @click:clear="onClearClick"
    />

    <AppDownloadFile :link="exportNotificationsLink">
      <template #default="{submitForm}">
        <AppButton color="primary"
                   @click="exportNotifications(submitForm)"
        >
          <app-icon icon-name="file-upload" size="small" class="mr-2" />
          {{ $t('project.project-settings.ProjectSettingsNotifications.export') }}
        </AppButton>
      </template>
    </AppDownloadFile>
  </div>
</template>

<script>
  import { debounce } from 'lodash-es'
  import { mapMutations, mapState, mapGetters } from 'vuex'

  import AppDownloadFile from '@/common/AppDownloadFile.vue'
  import AppButton from '@/common/buttons/AppButton.vue'
  import SearchTextField from '@/common/SearchTextField.vue'
  import { SET_ROOM_NOTIFICATIONS_SEARCH_QUERY } from '@/store/modules/room/mutation_types'

  const SEARCH_QUERY_DEBOUNCE_TIMER = 2000

  export default {
    name: 'ProjectSettingsNotificationsTopBar',
    components: {
      AppDownloadFile,
      AppButton,
      SearchTextField,
    },
    props: {
      mnemo: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapState('room', ['roomNotificationsSearchQuery', 'roomNotificationsPending']),
      ...mapGetters('room', ['getNotificationsParams']),
      searchQuery: {
        get () {
          return this.roomNotificationsSearchQuery
        },
        set: debounce(function (value) {
          this.SET_ROOM_NOTIFICATIONS_SEARCH_QUERY(value)
        }, SEARCH_QUERY_DEBOUNCE_TIMER),
      },
      exportNotificationsLink () {
        return this.axios.getUri({
          url: `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/notifications-excel`,
          params: this.getNotificationsParams,
        }) || null
      },
    },
    methods: {
      ...mapMutations('room', [SET_ROOM_NOTIFICATIONS_SEARCH_QUERY]),
      onEnterDown (event) {
        this.SET_ROOM_NOTIFICATIONS_SEARCH_QUERY(event.target.value)
      },
      onClearClick () {
        this.SET_ROOM_NOTIFICATIONS_SEARCH_QUERY('')
      },
      exportNotifications (submitFormCallback) {
        submitFormCallback()
      },
    },
  }
</script>
