<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             :ok-text="$t('common.confirm')"
             @ok="emitNewSignersArray"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('archives.dialogs.ArchivesAddStep2AddSignersDialog.title') }}</template>

    <template #body>
      <template v-if="newSignersArray.length > 0">
        <v-row
          v-for="(signer, i) in newSignersArray"
          :key="`signer-${i}`"
          class="align-center"
        >
          <v-col cols="11">
            <AppTextField v-model.trim="newSignersArray[i]"/>
          </v-col>
          <v-col cols="1"
          ><i
            class="fas fa-trash-can trash-icon"
            style="font-size: 1rem; cursor: pointer"
            @click="deleteSigner(i)"
          ></i
          ></v-col>
        </v-row>
      </template>
      <v-row class="mt-3">
        <v-col class="text-center">
          <AppButton color="white" @click="pushNewSigner">
            <i class="fa fa-plus mr-2"></i>
            {{ $t('archives.dialogs.ArchivesAddStep2AddSignersDialog.btnAddSigner') }}
          </AppButton>
        </v-col>
      </v-row>
    </template>
  </AppDialog>
</template>

<script>
import { cloneDeep } from 'lodash-es'

import AppTextField from '../../common/AppTextField.vue'
import AppButton from '../../common/buttons/AppButton.vue'
import AppDialog from '../../common/dialogs/AppDialog'
import { dialogMixin } from '../../mixins/dialogMixin'

export default {
  name: 'ArchivesAddStep2AddSignersDialog',
  components: {
    AppDialog,
    AppTextField,
    AppButton,
  },
  mixins: [dialogMixin],
  props: {
    signers: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      newSignersArray: cloneDeep(this.signers),
    }
  },
  watch: {
    signers (newValue) {
      this.newSignersArray = cloneDeep(newValue)
    },
  },
  methods: {
    emitNewSignersArray (e) {
      if (e) e.preventDefault()
      const arrayToEmit = this.newSignersArray.filter(Boolean)
      this.$emit('newSignersArray', arrayToEmit)
      this.closeDialog()
    },
    pushNewSigner () {
      this.newSignersArray.push('')
    },
    deleteSigner (index) {
      this.newSignersArray.splice(index, 1)
    },
  },
}
</script>

<style scoped>
.trash-icon:hover {
  color: #E02020;
}
</style>
