<template>
  <div>
    <AppDialog :is-open="isDialogOpened"
             size="l"
             @cancel="explicitCloseDialog"
    >
      <template #title>{{ $t('project.signing-checklist.witness.WitnessDialog.title') }}</template>
      <template #body>
        <WitnessSelectionCard v-for="(witnessSelection, i) in $v.localWitnessArray.$each.$iter"
                              :key="`witness-${i}`"
                              :witness-selection="witnessSelection"
                              :signers="getSignersList(witnessSelection.signer.$model, i)"
                              :selected-signers="selectedSigners"
                              :witnesses="getWitnessesList(witnessSelection.signer.$model)"
                              :can-delete="canDeleteGroup"
                              :index="Number(i)"
                              @add-signer="addSigner(witnessSelection, $event)"
                              @add-witness="addWitness(witnessSelection, $event)"
                              @delete="onDelete(i)"
        />
        <AppButton v-show="areEveryGroupsValid && thereAreRemainingSigners" type="outlined" variant="neutral" @click="onAddSignatoryClick">
          <template #left-icon>
            <font-awesome-icon :icon="['far', 'plus']"/>
            {{ $t('project.signing-checklist.witness.WitnessDialog.addSignatory') }}
          </template>
        </AppButton>
      </template>
      <template #footer>
        <div>
          <AppTooltip top>
            <template #activator="{attrs, on}">
              <div v-bind="attrs"
                   class="d-inline-block"
                   v-on="on"
              >
                <AppButton type="ghost"
                           class="mr-2"
                           @click="onResetWitnessClick"
                >
                  {{ $t('project.signing-checklist.witness.WitnessDialog.resetWitnesses') }}
                </AppButton>
              </div>
            </template>
            <span>{{$t('project.signing-checklist.witness.WitnessDialog.resetWitnessesTooltip')}}</span>
          </AppTooltip>
        </div>
        <div class="d-flex justify-end w-100">
          <AppButton type="ghost"
                     class="mr-2"
                     @click="explicitCloseDialog"
          >
            {{ $t('common.cancel') }}
          </AppButton>
          <AppButton type="primary"
                     :disabled="shouldDisabledValidateButton"
                     @click="validateWitnessesSelection"
          >
            {{ $t('common.validate') }}
          </AppButton>
        </div>
      </template>
    </AppDialog>
    <AppUnsavedChangesDialog v-if="isUnsavedChangesDialogOpen"
                             @save="onSave"
                             @discard="closeDialog"
                             @close="isUnsavedChangesDialogOpen = false"
    >
      <template #header-text>{{$t('project.signing-checklist.witness.WitnessDialog.unsavedChanges.header')}}</template>
      <template #body-text>{{$t('project.signing-checklist.witness.WitnessDialog.unsavedChanges.body')}}</template>
    </AppUnsavedChangesDialog>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import AppUnsavedChangesDialog from '@/common/dialogs/AppUnsavedChangesDialog.vue'
import AppButton from '@/design-system/buttons/AppButton.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import { SignerWitnesses } from '@/project/signing-checklist/witness/types'
import WitnessSelectionCard from '@/project/signing-checklist/witness/WitnessSelectionCard.vue'
import { VALIDATE_WITNESSES_SELECTIONS } from '@/store/modules/signing-checklist/action_types'
import { REMOVE_SIGNER_WITNESSES } from '@/store/modules/signing-checklist/mutation_types'
import { ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'
import { Member } from '@/types/member'

export default defineComponent({
  name: 'WitnessDialog',
  components: { AppButton, WitnessSelectionCard, AppDialog, AppUnsavedChangesDialog, AppTooltip },
  mixins: [dialogMixin, validationMixin],
  props: {
    signers: {
      type: Array as PropType<number[][]>,
      required: true,
    },
    fileId: {
      type: Number,
      required: true,
    },
    witnesses: {
      type: Array as PropType<SignerWitnesses[]>,
    },
  },
  validations: {
    localWitnessArray: {
      $each: {
        signer: { required },
        witness: { required },
      },
    },
  },
  data (): {localWitnessArray: SignerWitnesses[], isUnsavedChangesDialogOpen:boolean, hasUnsavedChanges:boolean, hasReset:boolean} {
    return {
      localWitnessArray: [{ signer: null, witness: [] }],
      isUnsavedChangesDialogOpen: false,
      hasUnsavedChanges: false,
      hasReset: false,
    }
  },
  computed: {
    ...mapGetters('groups', ['allRoomMembers']),
    areEveryGroupsValid (): boolean {
      return this.localWitnessArray.every(i => {
        return i.signer !== null && i.witness.length
      })
    },
    selectedSigners (): number[] {
      const selectedSigners: number[] = []
      this.localWitnessArray.forEach(o => {
        if (o.signer) {
          selectedSigners.push(o.signer)
        }
      })
      return selectedSigners
    },
    thereAreRemainingSigners (): boolean {
      return this.signers.flat().some(signer => {
        return this.localWitnessArray.every(witness => witness.signer !== signer && !witness.witness.includes(signer))
      })
    },
    canDeleteGroup (): boolean {
      return this.localWitnessArray.length >= 2
    },
    shouldDisabledValidateButton () {
      if (this.$v.$anyError) return true
      if (this.$v.localWitnessArray.$anyDirty) return false
      if (this.hasUnsavedChanges) return false
      return true
    },
  },
  created () {
    if (this.witnesses && this.witnesses.length) {
      this.localWitnessArray = cloneDeep(this.witnesses)
    }
  },
  methods: {
    ...mapActions('signingChecklist', [VALIDATE_WITNESSES_SELECTIONS]),
    ...mapMutations('signingChecklist', [REMOVE_SIGNER_WITNESSES]),
    ...mapMutations([ENQUEUE_SUCCESS_SNACKBAR]),
    onAddSignatoryClick (): void {
      this.localWitnessArray.push({ signer: null, witness: [] })
    },
    addSigner (witnessSelection, event): void {
      witnessSelection.signer.$model = event
      this.hasReset = false
    },
    addWitness (witnessSelection, event): void {
      witnessSelection.witness.$model = event
      this.hasReset = false
    },
    validateWitnessesSelection (): void {
      this.$v.$touch()
      if (!this.$v.$invalid || this.hasReset) {
        this.VALIDATE_WITNESSES_SELECTIONS({
          importedFileId: this.fileId,
          signersWithWitnesses: this.localWitnessArray,
        })

        if (this.hasReset) {
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.witness.WitnessDialog.confirmResetWitnesses'))
        }
        (this as unknown as typeof dialogMixin).closeDialog()
      }
    },
    onDelete (index) {
      this.localWitnessArray.splice(index, 1)
      this.hasUnsavedChanges = true
    },
    getSignersList (signerId, index): Member[] {
      const signersList = member => {
        return this.allRoomMembers.every(s => this.signers.flat().includes(member.id))
      }
      const memberAlreadyWitnessForOtherSigner = member => {
        return this.localWitnessArray
          .filter(signerWithWitnesses => signerWithWitnesses.signer !== signerId)
          .flatMap(signerWithWitnesses => signerWithWitnesses.witness)
          .every(witness => witness !== member.id)
      }

      return this.allRoomMembers
        .filter(signersList)
        .filter(memberAlreadyWitnessForOtherSigner)
    },
    getWitnessesList (signerId): Member[] {
      const memberAlreadySignerWithWitnesses = member => {
        return this.localWitnessArray.every(signerWithWitnesses => signerWithWitnesses.signer !== member.id)
      }
      const memberAlreadyWitnessForOtherSigner = member => {
        return this.localWitnessArray
          .filter(signerWithWitnesses => signerWithWitnesses.signer !== signerId)
          .flatMap(signerWithWitnesses => signerWithWitnesses.witness)
          .every(witness => witness !== member.id)
      }

      return this.allRoomMembers
        .filter(memberAlreadySignerWithWitnesses)
        .filter(memberAlreadyWitnessForOtherSigner)
    },
    explicitCloseDialog () {
      if (!this.shouldDisabledValidateButton || this.hasReset) {
        this.isUnsavedChangesDialogOpen = true
      } else {
        (this as unknown as typeof dialogMixin).closeDialog()
      }
    },
    onSave () {
      this.validateWitnessesSelection()
      this.isUnsavedChangesDialogOpen = false
    },
    onResetWitnessClick () {
      this.localWitnessArray = [{ signer: null, witness: [] }]
      this.hasReset = true
    },
  },
})
</script>
