<template>
  <v-navigation-drawer
    v-model="computedValue"
    touchless
    fixed
    :right="right"
    :mobile-breakpoint="0"
    class="elevation-1"
    color="white"
    style="z-index: 10"
    v-bind="$attrs"
    :width="responsiveWidth"
  >
    <!--TABS-->
    <v-toolbar height="0" flat dense color="grey lighten-4">
      <template v-slot:extension>
        <div v-if="hasSingleTab" class="text-h3">{{ tabs[0].title }}</div>
        <v-tabs
          v-else
          v-model="computedCurrentTab"
          center-active
          :show-arrows="false"
          next-icon="fal fa-chevron-right"
          prev-icon="fal fa-chevron-left"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.id"
            :href="'#' + tab.id"
            class="text-h3"
          >
            {{tab.title}}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <div style="position: relative">
      <slot name="close">
        <AppButton
          :color="closeButton.bgc"
        class="Pane-close-button"
        fab
        small
        absolute
        right
        top
        @click="onCloseButtonClick"
        @mouseenter="closeButton = { bgc: 'primary', icon: 'text--white' }"
        @mouseleave="closeButton = { bgc: 'white', icon: 'text--primary' }"
      >
        <font-awesome-icon :icon="['fal', 'xmark']"
                           style="width: 18px; height: 18px;"
                           :class="closeButton.icon"></font-awesome-icon>
        </AppButton>
      </slot>
    </div>
    <div class="pane-content">
      <!-- @slot Fixed content above the current tab content -->
      <slot name="fixed-header"></slot>
      <v-tabs-items v-model="computedCurrentTab" touchless>
        <v-tab-item
          v-for="tab in tabs"
          :key="tab.id"
          :value="tab.id"
          :eager="eager"
        >
        <!-- @slot Dynamic slots for the content of the tabs.
        Starts with "content-" with each tab id suffix for each tab content -->
          <slot :name="`content-${tab.id}`"></slot>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-navigation-drawer>
</template>

<script>
import AppButton from './buttons/AppButton'
// Shared HTML element node used for toggling overflow
let $HTML_ELEMENT

export const PANE_FADE_TRANSITION_TIME = 300

/**
 * Default Pane
 * @displayName Pane
 */
/**
 * A reusable component used for displaying rich contextual information for an item with its associated list still in the viewport
 */
export default {
  name: 'Pane',
  components: { AppButton },
  inheritAttrs: false,
  props: {
    /**
     * @model
     * Determines if the pane is currently opened or not
     */
    value: {
      type: Boolean,
      required: true,
    },
    /**
     * The tab currently opened in the pane. Can be set at any time to change the view
     */
    currentTab: {
      type: String,
      required: true,
    },
    /**
     * An array of objects with the definition { title: String, id: String } describing a tab entry
     */
    tabs: {
      type: Array,
      required: true,
    },
    /**
     * Determines if the pane is on the right side
     * @default true
     */
    right: {
      type: Boolean,
      default: true,
    },
    /**
     * Apply eager prop to v-tab-item
     * @default false
     */
    eager: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      closeButton: {
        bgc: 'white',
        icon: 'text--primary',
      },
    }
  },
  computed: {
    hasSingleTab () {
      return this.tabs.length === 1
    },
    responsiveWidth () {
      if (this.$vuetify.breakpoint.xs) {
        return '100vw'
      }
      if (this.$vuetify.breakpoint.mobile) {
        return '50%'
      } else {
        return '30%'
      }
    },
    computedValue: {
      get () {
        return this.value
      },
      set (value) {
        this.handleHtmlElementOverflow(value)
        this.$emit('input', value)
      },
    },
    computedCurrentTab: {
      get () {
        return this.currentTab
      },
      set (value) {
        this.$emit('update:currentTab', value)
      },
    },
  },
  beforeDestroy () {
    if (!$HTML_ELEMENT) {
      // It will never change. It's the HTML root node afterall
      $HTML_ELEMENT = document.querySelector('html')
    }
    $HTML_ELEMENT.classList.remove('overflow-hidden')
  },
  methods: {
    handleHtmlElementOverflow (value) {
      // If it's the first Pane in this App's lifecycle
      if (!$HTML_ELEMENT) {
        // It will never change. It's the HTML root node afterall
        $HTML_ELEMENT = document.querySelector('html')
      }
      if (value) {
        if (!$HTML_ELEMENT.classList.contains('overflow-hidden')) {
          $HTML_ELEMENT.classList.add('overflow-hidden')
        }
      } else {
        $HTML_ELEMENT.classList.remove('overflow-hidden')
      }
    },
    onCloseButtonClick () {
      /**
       * Close event.
       *
       * @event closeButtonClicked
       */
      this.$emit('closeButtonClicked')
      this.computedValue = false
    },
  },
}
</script>

<style lang="scss" scoped>
  .pane-content {
    // 48px is the space used by the toolbar containing the tabs
    // By substracting it we are effectively delimiting the scroll space and
    // making only the content scrollable, thus the toolbar becomes sticky
    height: calc(100% - 48px);
    overflow-y: auto;
    padding: 20px;
  }
  .Pane-close-button {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  }
  ::v-deep .v-window {
    overflow: visible;
  }
</style>
<docs>
    ```vue
    <template>
      <div>
        <v-btn @click="value = !value">Toggle Pane</v-btn>
        <Pane v-model="value" :currentTab.sync="currentTab" :tabs="tabs">
          <template #fixed-header>
            <div>Always here !</div>
          </template>
          <template #content-test>
            Test content
          </template>
          <template #content-dos>
            Dos content
          </template>
        </Pane>
      </div>
    </template>
    <script>
        export default {
            data() {
                return {
                    value: false,
                    tabs: [
                      {
                        title: 'test',
                        id: 'test',
                      },
                      {
                        title: 'dos',
                        id: 'dos',
                      },
                    ],
                    currentTab: null,
                }
            }
        }
    </script>
    ```
</docs>
