<template>
  <div>
    <v-row class="mt-2">
      <v-col>
        <Alert>
          {{ $t('project.checklist.pane.ChecklistTableTaskPaneShare.alertText') }}
        </Alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h3">
        {{ $tc('project.checklist.pane.ChecklistTableTaskPaneShare.shareFiles', tableTaskPane.documents.length) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-radio-group v-model="shareType"
                       class="mt-0"
                       hide-details
        >
          <v-radio :value="SHARE_TYPE_OPTIONS.PUBLIC"
                   class="DocumentShare-radio mb-0 px-3"
                   active-class="DocumentShare-radio--active"
          >
            <template #label>
              <div class="d-flex flex-column text--primary ml-2">
                <div class="text-h3">{{$t('project.checklist.pane.ChecklistTableTaskPaneShare.publicTitle')}}</div>
                <div class="subtitle-1 text--secondary">{{$t('project.checklist.pane.ChecklistTableTaskPaneShare.publicSubtitle')}}</div>
              </div>
            </template>
          </v-radio>
          <v-radio :value="SHARE_TYPE_OPTIONS.CUSTOM"
                   class="DocumentShare-radio px-3"
                   active-class="DocumentShare-radio--active"
          >
            <template #label>
              <div class="d-flex flex-column text--primary ml-2">
                <div class="text-h3">{{$t('project.checklist.pane.ChecklistTableTaskPaneShare.customTitle')}}</div>
                <div class="subtitle-1 text--secondary">{{$t('project.checklist.pane.ChecklistTableTaskPaneShare.customSubtitle')}}</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="shareType === SHARE_TYPE_OPTIONS.CUSTOM"
           class="mt-0"
    >
      <v-col>
        <DocumentSharePicker v-if="paneIsOpen" :force-pm-selection="true" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox v-model="sendNotif"
                    class="my-0"
                    hide-details
                    :label="$t('project.checklist.pane.ChecklistTableTaskPaneShare.sendNotif')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <VisualTextEditor v-model="notifyMessage"
                          counter="footer"
                          :error-messages="notifyMessageErrors"
                          :max-length="notifyMessageMaxLength"
                          :placeholder="$t('project.checklist.pane.ChecklistTableTaskPaneShare.optionalMessageWithCopy')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <AppButton color="primary"
                   outlined
                   :disabled="shareAttachedFilesPending"
                   block
                   @click="resetAndClose"
        >
          {{$t('common.cancel')}}
        </AppButton>
      </v-col>
      <v-col>
        <AppButton color="primary"
                   block
                   :disabled="shareDisabled"
                   :loading="shareAttachedFilesPending"
                   @click="prepareShareAttachedFiles"
        >
          {{$t('common.share')}}
        </AppButton>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'

import Alert from '../../../common/alerts/Alert'
import AppButton from '../../../common/buttons/AppButton'
import DocumentSharePicker from '../../../common/document-share/DocumentSharePicker'
import { escapeHtml } from '../../../common/utils/strings'
import VisualTextEditor from '../../../common/visual-text-editor/VisualTextEditor'
import { GET_GLOBAL_TASK_FOLDER, GET_TASK_FOLDER, SHARE_ATTACHED_FILES } from '../../../store/modules/checklist/action_types'
import { SET_TABLE_TASK_PANE } from '../../../store/modules/checklist/mutation_types'
import { RESET_SELECTED_GROUPS_AND_MEMBERS } from '../../../store/modules/document-share-picker/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

const NOTIFY_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'ChecklistTableTaskPaneShare',
  components: { Alert, AppButton, DocumentSharePicker, VisualTextEditor },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      SHARE_TYPE_OPTIONS,
      shareTypeValue: '',
      sendNotif: true,
      notifyMessage: '',
      notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
    }
  },
  computed: {
    ...mapState('checklist', ['tableTaskPane', 'shareAttachedFilesPending']),
    ...mapState('documentSharePicker', ['selectedGroups', 'selectedMembers']),
    singleFile () {
      return this.tableTaskPane.documents.length === 1 && this.tableTaskPane.documents[0].type === 'file'
    },
    shareType: {
      get () {
        if (this.shareTypeValue) {
          return this.shareTypeValue
        } else if (this.tableTaskPane.documents[0]?.rights) {
          return this.tableTaskPane.documents[0].rights.read.isPublic
            ? SHARE_TYPE_OPTIONS.PUBLIC
            : SHARE_TYPE_OPTIONS.CUSTOM
        }
        return SHARE_TYPE_OPTIONS.CUSTOM
      },
      set (val) {
        this.shareTypeValue = val
      },
    },
    paneIsOpen () {
      return this.tableTaskPane.isOpen
    },
    notifyMessageLength () {
      return escapeHtml(this.notifyMessage).length
    },
    notifyMessageIsTooLong () {
      return this.notifyMessageLength > this.notifyMessageMaxLength
    },
    notifyMessageErrors () {
      const errors = []

      if (this.notifyMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    shareDisabled () {
      return this.notifyMessageIsTooLong || this.shareAttachedFilesPending
    },
  },
  watch: {
    paneIsOpen (val) {
      if (!val) {
        this.resetAndClose()
      }
    },
  },
  methods: {
    ...mapActions('documentSharePicker', [RESET_SELECTED_GROUPS_AND_MEMBERS]),
    ...mapActions('checklist', [SHARE_ATTACHED_FILES, GET_TASK_FOLDER, GET_GLOBAL_TASK_FOLDER]),
    async prepareShareAttachedFiles () {
      try {
        const folderIds = []
        const fileIds = []
        for (const item of this.tableTaskPane.documents) {
          if (item.type === 'folder') {
            folderIds.push(item.id)
          } else if (item.type === 'file') {
            fileIds.push(item.id)
          }
        }
        await this.SHARE_ATTACHED_FILES({
          mnemo: this.mnemo,
          data: {
            folderIds,
            fileIds,
            shareType: this.shareType,
            withUsers: this.selectedMembers.map(i => { return i.id }),
            withGroups: this.selectedGroups.map(i => { return i.id }),
            sendNotif: this.sendNotif,
            notifyMessage: this.notifyMessage,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.pane.ChecklistTableTaskPaneShare.shareAttachedFilesSuccess'),
        })
        // Global share operation
        if (this.$store.state.checklist.globalAttachedFilesDialogIsOpen) {
          this.GET_GLOBAL_TASK_FOLDER(this.mnemo)
        } else {
          this.GET_TASK_FOLDER({
            mnemo: this.mnemo,
            folderId: this.tableTaskPane.parent,
          })
        }
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.pane.ChecklistTableTaskPaneShare.shareAttachedFilesError'),
        })
      } finally {
        this.resetAndClose()
      }
    },
    resetAndClose () {
      this.shareTypeValue = ''
      this.sendNotif = true
      this.notifyMessage = ''
      this.RESET_SELECTED_GROUPS_AND_MEMBERS()
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: false,
        tab: '',
        documents: [],
        parent: null,
      })
    },
  },
}
</script>

<style scoped lang="scss">
  .DocumentShare-radio {
    border-left: 7px solid transparent;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 14px;
    padding-bottom: 14px;

  &--active {
     border-left-color: var(--v-primary-base);
     background-color: var(--v-primary-lighten4);
   }
  }
  ::v-deep .v-input--selection-controls__input .v-icon {
    font-size: 18px;
  }
</style>
