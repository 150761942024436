<template>
  <span>
    <template v-if="value">
      {{formattedDate}}
    </template>
  </span>
</template>

<script>
import { ISOToShortDate, ISOToShortenedDate } from './utils/dates'
/**
 * Default date display
 * @displayName DateDisplay
 */
/**
 * Display a date with format ll
 * @see https://day.js.org/docs/en/display/format
 */
export default {
  name: 'DateDisplay',
  props: {
    /**
     * @model
     * Date given
     */
    value: {
      required: true,
    },
    withHours: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedDate () {
      if (this.withHours) {
        return ISOToShortDate(this.value)
      }
      return ISOToShortenedDate(this.value)
    },
  },
}
</script>

<docs>
```vue
<template>
  <DateDisplay :value="Date.now()"/>
</template>
```
</docs>
