<template>
  <AppButton v-bind="$attrs"
             class="AppBarMenuItem"
             :class="{
                'AppBarMenuItem--active': active,
                'AppBarMenuItem--hover': hover,
              }"
             text
             tile
             v-on="$listeners"
  >
    <slot v-bind:active="active" />
    <span v-if="!$vuetify.breakpoint.mobile" class="ml-2">{{label}}</span>
  </AppButton>
</template>

<script>
import AppButton from '@/common/buttons/AppButton.vue'

export default {
  name: 'AppBarMenuItem',
  components: { AppButton },
  inheritAttrs: false,
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
  .v-btn.AppBarMenuItem {
    height: 100%;
    min-width: 0;

    &--active {
      color: var(--v-primary-base);
      border-bottom: 4px solid var(--v-primary-base);
    }

    &:hover {
      background: var(--v-primary-lighten4);
    }

    &:hover,
    &--hover {
      &::before {
        opacity: 0;
      }

      color: var(--v-primary-base);
    }
  }
</style>
