<template>
  <div>
    <v-row>
      <v-col>
        <template v-if="singleFile">
          {{ $t('common.document-share.DocumentShare.infoSingleFile') }}
        </template>
        <template v-if="singleFolder">
          <p>{{ $t('common.document-share.DocumentShare.infoSingleFolder') }}</p>
          <p>{{ $t('common.document-share.DocumentShare.infoSingleFile') }}</p>
        </template>
        <template v-if="multipleDocuments">
          <p>{{ $t('common.document-share.DocumentShare.infoSingleFolder') }}</p>
          <p>{{ $t('common.document-share.DocumentShare.infoSingleFile') }}</p>
        </template>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <v-radio-group v-model="shareType"
                       class="mt-0"
                       hide-details
        >
          <v-radio :value="SHARE_TYPE_OPTIONS.PUBLIC"
                   class="DocumentShare-radio mb-0 px-3"
                   active-class="DocumentShare-radio--active"
          >
            <template #label>
              <div class="d-flex flex-column text--primary ml-2">
                <div class="text-h3">{{$t('common.document-share.DocumentShare.publicTitle')}}</div>
                <div class="subtitle-1 text--secondary">{{$t('common.document-share.DocumentShare.publicSubtitle')}}</div>
              </div>
            </template>
          </v-radio>
          <v-radio :value="SHARE_TYPE_OPTIONS.CUSTOM"
                   class="DocumentShare-radio px-3"
                   active-class="DocumentShare-radio--active"
          >
            <template #label>
              <div class="d-flex flex-column text--primary ml-2">
                <div class="text-h3">{{$t('common.document-share.DocumentShare.customTitle')}}</div>
                <div class="subtitle-1 text--secondary">{{$t('common.document-share.DocumentShare.customSubtitle')}}</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="shareType === SHARE_TYPE_OPTIONS.CUSTOM && groups"
           class="mt-4"
    >
      <v-col v-if="documentsTablePane.isOpen">
        <MembersAutocomplete :items="allRoomMembers" @select="onMemberSelect" />
        <DocumentSharePicker :force-pm-selection="forcePmSelection" />
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row v-if="singleFile && documents[0].nbVersions > 1">
      <v-col>
        <v-checkbox v-model="applyToOldVersions"
                    hide-details
                    :label="$t('common.document-share.DocumentShare.applyToOldVersions')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="sendNotif"
                  hide-details
                  :label="$t('common.document-share.DocumentShare.sendNotif')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <VisualTextEditor v-model="notifyMessage"
                          counter="footer"
                          :error-messages="notifyMessageErrors"
                          :max-length="notifyMessageMaxLength"
                          :placeholder="$t('common.document-share.DocumentShare.optionalMessageWithCopy')"
        />
      </v-col>
    </v-row>
    <v-row class="mt-n2">
      <v-col>
        <AppButton color="primary"
                   outlined
                   block
                   @click="CLOSE_TABLE_DOCUMENTS_PANE"
        >
          {{$t('common.cancel')}}
        </AppButton>
      </v-col>
      <v-col>
        <AppButton color="primary"
                   block
                   :disabled="shareDisabled"
                   :loading="shareLoading"
                   @click="share"
        >
          {{$t('common.share')}}
        </AppButton>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'
import MembersAutocomplete from '@/project/members/MembersAutocomplete.vue'
import {
  HIGHLIGHT_MEMBER,
  INIT_SELECTED_GROUPS,
  INIT_SELECTED_MEMBERS,
  SELECT_MEMBER,
} from '@/store/modules/document-share-picker/action_types'
import { CLOSE_TABLE_DOCUMENTS_PANE, SHARE_FILDERS } from '@/store/modules/documents/action_types'

import DocumentSharePicker from './DocumentSharePicker'
import AppButton from '../buttons/AppButton'
import { escapeHtml } from '../utils/strings'
import VisualTextEditor from '../visual-text-editor/VisualTextEditor'

const NOTIFY_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'DocumentShare',
  components: { MembersAutocomplete, AppButton, VisualTextEditor, DocumentSharePicker },
  props: {
    documents: {
      type: Array,
      required: true,
    },
    forcePmSelection: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      SHARE_TYPE_OPTIONS,
      shareTypeValue: '',
      sendNotif: true,
      applyToOldVersions: false,
      selectedGroupsAndUsers: [],
      notifyMessage: '',
      notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
    }
  },
  computed: {
    ...mapGetters('groups', ['allRoomMembers']),
    ...mapState('groups', ['groups']),
    ...mapState('documents', ['shareLoading', 'documentsTablePane']),
    singleFile () {
      return this.documents.length === 1 && this.documents[0].type === 'file'
    },
    singleFolder () {
      return this.documents.length === 1 && this.documents[0].type === 'folder'
    },
    multipleDocuments () {
      return this.documents.length > 1
    },
    shareType: {
      get () {
        if (this.shareTypeValue) {
          return this.shareTypeValue
        } else if (this.documents[0]?.rights) {
          return this.documents[0].rights.read.isPublic
            ? SHARE_TYPE_OPTIONS.PUBLIC
            : SHARE_TYPE_OPTIONS.CUSTOM
        }
        return SHARE_TYPE_OPTIONS.CUSTOM
      },
      set (val) {
        this.shareTypeValue = val
        if (val === SHARE_TYPE_OPTIONS.CUSTOM) {
          this.INIT_SELECTED_GROUPS(this.documents[0].rights.read.groups)
          this.INIT_SELECTED_MEMBERS(this.documents[0].rights.read.users)
        }
      },
    },
    notifyMessageLength () {
      return escapeHtml(this.notifyMessage).length
    },
    notifyMessageIsTooLong () {
      return this.notifyMessageLength > this.notifyMessageMaxLength
    },
    notifyMessageErrors () {
      const errors = []

      if (this.notifyMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    shareDisabled () {
      return this.notifyMessageIsTooLong
    },
  },
  watch: {
    'documentsTablePane.isOpen' (value) {
      if (!value) {
        this.shareTypeValue = ''
      }
    },
  },
  methods: {
    ...mapActions('documents', [CLOSE_TABLE_DOCUMENTS_PANE, SHARE_FILDERS]),
    ...mapActions('documentSharePicker', [
      HIGHLIGHT_MEMBER,
      INIT_SELECTED_GROUPS,
      INIT_SELECTED_MEMBERS,
      SELECT_MEMBER,
    ]),
    share () {
      this.SHARE_FILDERS({
        shareType: this.shareType,
        sendNotif: this.sendNotif,
        notifyMessage: this.notifyMessage,
        applyToOldVersions: this.applyToOldVersions,
      })
    },
    onMemberSelect (member) {
      if (member) {
        this.SELECT_MEMBER({ member, multiselect: true })
        this.HIGHLIGHT_MEMBER(member)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.DocumentShare-radio {
  border-left: 7px solid transparent;
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 14px;
  padding-bottom: 14px;

  &--active {
    border-left-color: var(--v-primary-base);
    background-color: var(--v-primary-lighten4);
  }
}
::v-deep .v-input--selection-controls__input .v-icon {
  font-size: 18px;
}
</style>
