<template>
  <div>
    <v-chip v-if="computedSignatureType"
            color="#F3F5FF"
            class="signature-chip font-weight-medium px-2 rounded-xl"
            :class="{'mr-2':isMultiEnvelopeOrChild || computedProviderType }"
            label
            small
            text-color="#3F51B5"
    >
      {{ $t(`project.signing-checklist.list.SigningChecklistTableItemSignatureChips.signatureType.${computedSignatureType}`) }}
    </v-chip>
    <v-chip
      v-if="computedProviderType"
      color="#FFECF2"
      class="signature-chip caption font-weight-medium px-2 rounded-xl"
      :class="{'mr-2':isMultiEnvelopeOrChild}"
      small
      text-color="#FD6095"
      label
    >
      {{ computedProviderType }}
    </v-chip>
    <v-chip v-if="isMultiEnvelopeOrChild"
            color="#E5F5F6"
            class="signature-chip font-weight-medium px-2 rounded-xl"
            label
            small
            text-color="#0097A7"
    >
      <!-- If we're both a child and a multi envelope, multi envelope wins for display purposes -->
      {{ isMultiEnvelope ? $t('project.signing-checklist.list.SigningChecklistTableItemSignatureChips.envelopeType.multiple') : $t('common.envelopeType.fromBulk')}}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'SigningChecklistTableItemSignatureChips',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    computedSignatureType () {
      if (this.item.hasOwnProperty('envelope')) {
        if (this.item.envelope.provider === 'pdf') {
          return 'pdf'
        } else if (this.item.envelope.signatureType === 'advanced') {
          return 'advanced'
        } else if (this.item.envelope.signatureType === 'simple') {
          return 'simple'
        } else if (this.item.envelope.signatureType === 'qualified') {
          return 'qualified'
        }
      }
      return null
    },
    computedProviderType () {
      if (this.item.hasOwnProperty('envelope')) {
        if (this.item.envelope.provider === 'oo') {
          return 'CertEurope'
        } else if (this.item.envelope.provider === 'ds') {
          return 'DocuSign'
        } else if (this.item.envelope.provider === 'ys') {
          return 'Yousign'
        }
      }
      return null
    },
    isMultiEnvelopeOrChild () {
      return this.isMultiEnvelope || this.isMultiEnvelopeChild
    },
    isMultiEnvelope () {
      return this.item.envelope.envelopeType === 'multiple'
    },
    isMultiEnvelopeChild () {
      return this.item.envelope?.parentMultienvelopeId
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .signature-chip:hover::before {
  opacity: 0;
}
</style>
