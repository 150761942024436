<template>
    <div ref="container"
         v-resize="onResize"
         v-intersect.quiet="{
                  handler: onIntersect,
                }"
         class="CerteuropeSignPage"
    >
      <template v-if="loadedPage">
        <v-img :src="`data:image/png;base64,${loadedPage.content}`"
               class="mx-auto"
               style="position: relative"
               :width="pageSize.width"
               :height="pageSize.height"
        >
          <CerteuropeSignTag v-for="tag in page.tags"
                             :key="`tag-${tag.id}`"
                             :ratio="ratio"
                             :mnemo="mnemo"
                             :selected-tag="selectedTag"
                             :tag="tag"
                             :page="page"
                             v-on="$listeners"
          />
        </v-img>
      </template>
      <template v-else>
        <div v-if="pageSize"
             class="CerteuropeSignPage-placeholder mx-auto"
             :style="{'width': pageSize.width, 'height': pageSize.height}"
        >
          <CerteuropeSignTag v-for="tag in page.tags"
                             :key="`tag-${tag.id}`"
                             :ratio="ratio"
                             :mnemo="mnemo"
                             :selected-tag="selectedTag"
                             :tag="tag"
                             :page="page"
                             v-on="$listeners"
          />
        </div>
      </template>
    </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapState, mapActions, mapMutations } from 'vuex'

import { INTERSECT_DEBOUNCE_TIMER } from '@/common/constants'
import { GET_PAGES } from '@/store/modules/certeurope-sign/action_types'
import { SET_ACTIVE_PAGE } from '@/store/modules/certeurope-sign/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import CerteuropeSignTag from './CerteuropeSignTag'

export default {
  name: 'CerteuropeSignPage',
  components: { CerteuropeSignTag },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
    selectedTag: {
      required: true,
    },
  },
  data () {
    return {
      numberOfPagesToRequest: 3,
      containerWidth: null,
    }
  },
  computed: {
    ...mapState('certeuropeSign', ['signingView', 'pages', 'scrollTo']),
    loadedPage () {
      return this.pages.find(page => page.pageNumber === this.page.number)
    },
    ratio () {
      if (this.containerWidth < this.page.width) {
        return Math.min((this.containerWidth / this.page.width) * 10 / 10, 1)
      }
      return 1
    },
    pageSize () {
      if (this.containerWidth >= this.page.width) {
        return {
          width: `${this.page.width}px`,
          height: `${this.page.height}px`,
        }
      } else {
        const ratio = Math.min((this.containerWidth / this.page.width) * 10 / 10, 1)
        return {
          width: `${this.containerWidth}px`,
          height: `${this.page.height * ratio}px`,
        }
      }
    },
  },
  watch: {
    scrollTo (value) {
      if (value === this.page.number) {
        this.onScrollTo()
      }
    },
  },
  async mounted () {
    this.containerWidth = this.$refs.container.clientWidth
    if (this.page.number === 1 && !this.loadedPage) {
      try {
        await this.GET_PAGES({
          mnemo: this.mnemo,
          envId: this.signingView.id,
          fromPage: 1,
          toPage: this.signingView.nbPages <= 3 ? this.signingView.nbPages : 3,
        })
        this.$store.commit(`certeuropeSign/${SET_ACTIVE_PAGE}`, this.page.number)
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('certeurope-sign.CerteuropeSignPage.getPagesError'),
        })
      }
    }
  },
  created () {
    this.onIntersect = debounce(this.onIntersect, INTERSECT_DEBOUNCE_TIMER)
  },
  methods: {
    ...mapActions('certeuropeSign', [GET_PAGES]),
    ...mapMutations('certeuropeSign', [SET_ACTIVE_PAGE]),
    onResize () {
      this.containerWidth = this.$refs.container.clientWidth
    },
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$store.commit(`certeuropeSign/${SET_ACTIVE_PAGE}`, this.page.number)
        if (!this.loadedPage) {
          this.prepareGetPages(this.page.number, this.page.number)
        }
        for (let i = 1; i < this.numberOfPagesToRequest; i++) {
          if (!this.pages.some(el => el.pageNumber === this.page.number + i) && this.page.number + i <= this.signingView.nbPages) {
            this.prepareGetPages(this.page.number + i, this.page.number + i)
          }
        }
      }
    },
    prepareGetPages (fromPage, toPage) {
      this.GET_PAGES({
        mnemo: this.mnemo,
        envId: this.signingView.id,
        fromPage: fromPage,
        toPage: toPage,
      })
    },
    onScrollTo () {
      this.SET_ACTIVE_PAGE(this.page.number)
      setTimeout(() => {
        this.$refs.container.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    },
  },
}
</script>

<style scoped>
.CerteuropeSignPage-placeholder {
  background-color: #fff;
  position: relative;
}
</style>
