<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.documentDownload')"
             size="l"
             @ok="submitForm"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.downloadSignedFile') }}</template>
    <template #body>
      <form ref="envelopeDownloadForm"
            method="post"
            target="_blank"
      >
        <input type="hidden"
               :value="bearerToken"
               name="api_token"
        >

        <div class="mb-4">{{ signedDocument.name }}</div>

        <v-checkbox :input-value="true"
                    disabled
                    :label="$t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.selectSignedDocument')"
                    name="withSignedDoc"
                    dense
                    hide-details
        />

        <template v-if="signedDocument.hasCertificateLocale">
          <div>
            <AppTooltip top :disabled="selectLocaleAuthenticationCertificate || !selectAuthenticationCertificate">
              <template #activator="{attrs, on}">
                <div class="d-inline-block" v-bind="attrs" v-on="on">
                  <v-checkbox v-model="selectLocaleAuthenticationCertificate"
                              :disabled="selectAuthenticationCertificate || selectMergeDocuments"
                              :label="$t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.selectAuthenticationCertificateWithLanguage', { language: signedDocument.certificateLocale.toUpperCase() })"
                              name="withLocaleCertificate"
                              dense
                              hide-details
                  />
                </div>
              </template>
              <span>{{ $t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.singleOnlyCertificateSelectionTooltip') }}</span>
            </AppTooltip>
          </div>

          <div>
            <AppTooltip top :disabled="selectAuthenticationCertificate || !selectLocaleAuthenticationCertificate">
              <template #activator="{attrs, on}">
                <div class="d-inline-block" v-bind="attrs" v-on="on">
                  <v-checkbox v-if="signedDocument.proofFileURL"
                              v-model="selectAuthenticationCertificate"
                              :disabled="selectLocaleAuthenticationCertificate || selectMergeDocuments"
                              :label="$t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.selectAuthenticationCertificateWithLanguage', { language: DEFAULT_CERTIFICATE_LANGUAGE })"
                              name="withDefaultCertificate"
                              dense
                              hide-details
                  />
                </div>
              </template>
              <span>{{ $t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.singleOnlyCertificateSelectionTooltip') }}</span>
            </AppTooltip>
          </div>
        </template>
        <v-checkbox v-else-if="signedDocument.proofFileURL"
                    v-model="selectAuthenticationCertificate"
                    :disabled="selectMergeDocuments"
                    :label="$t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.selectAuthenticationCertificate')"
                    name="withDefaultCertificate"
                    dense
                    hide-details
        />

        <template v-if="isDocumentsMergingOptionDisplayed">
          <v-divider class="my-8" />

          <AppTooltip top :disabled="isDocumentsMergingEnabled" :max-width="440">
            <template #activator="{attrs, on}">
              <div class="d-flex align-center" v-bind="attrs" v-on="on">
                <v-checkbox v-model="selectMergeDocuments"
                            :label="$t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.selectAuthenticationCertificate')"
                            :disabled="!isDocumentsMergingEnabled"
                            name="mergeDocuments"
                            class="mt-0 pt-0 d-flex align-center"
                            dense
                            hide-details
                >
                  <template #label>
                    <span :class="{'grey--text': !isDocumentsMergingEnabled}" >
                      {{ $t("project.signed-documents.dialogs.SignedDocumentsDownloadDialog.mergeDocuments") }}
                    </span>
                  </template>
                </v-checkbox>

                <AppNewLabel class="ml-2" />
              </div>
            </template>
            <span>{{ disabledDocumentsMergeTooltip }}</span>
          </AppTooltip>
        </template>
      </form>
    </template>
  </AppDialog>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import AppNewLabel from '@/common/AppNewLabel.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'

const DEFAULT_CERTIFICATE_LANGUAGE = 'EN'

export default {
  name: 'SignedDocumentsDownloadDialog',
  components: { AppTooltip, AppNewLabel, AppDialog },
  mixins: [dialogMixin],
  props: {
    signedDocument: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      dialogIsOpen: false,
      selectAuthenticationCertificate: !!this.signedDocument.proofFileURL,
      selectLocaleAuthenticationCertificate: false,
      selectMergeDocuments: false,
      DEFAULT_CERTIFICATE_LANGUAGE,
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    bearerToken () {
      return axios.defaults.headers.common.Authorization
    },
    disabledDocumentsMergeTooltip () {
      if (!this.isAnyCertificateSelected) {
        return this.signedDocument.hasCertificateLocale
          ? this.$t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.atLeastOneCertificateIsRequiredForDocumentsMergeTooltip')
          : this.$t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.certificateIsRequiredForDocumentsMergeTooltip')
      }

      if (
        (this.selectAuthenticationCertificate && !this.signedDocument.hasCombinedCertificate) ||
        (this.selectLocaleAuthenticationCertificate && !this.signedDocument.hasCombinedCertificateLocale)
      ) {
        return this.$t('project.signed-documents.dialogs.SignedDocumentsDownloadDialog.mergedDocumentsNotAvailableForSelectedLanguage')
      }

      return null
    },
    isAnyCertificateSelected () {
      return this.selectAuthenticationCertificate || this.selectLocaleAuthenticationCertificate
    },
    isDocumentsMergingEnabled () {
      return (this.selectAuthenticationCertificate && this.signedDocument.hasCombinedCertificate) ||
        (this.selectLocaleAuthenticationCertificate && this.signedDocument.hasCombinedCertificateLocale)
    },
    isDocumentsMergingOptionDisplayed () {
      return this.signedDocument.hasCombinedCertificate
    },
  },
  methods: {
    setFormActionUrl () {
      if (this.isAnyCertificateSelected) {
        this.$refs.envelopeDownloadForm.action = this.selectMergeDocuments
          ? `${process.env.VUE_APP_API_URL}/room/${this.roomMnemo}/downloads/combined/${this.signedDocument.id}?locale=${this.selectLocaleAuthenticationCertificate}`
          : `${process.env.VUE_APP_API_URL}/room/${this.roomMnemo}/downloads/envelopeFinalZip/${this.signedDocument.id}?locale=${this.selectLocaleAuthenticationCertificate}`
      } else {
        this.$refs.envelopeDownloadForm.action = this.signedDocument.signedDocumentURL
      }
    },
    submitForm () {
      this.setFormActionUrl()
      this.$refs.envelopeDownloadForm.submit()

      this.closeDialog()
    },
  },
}
</script>

<style scoped lang="scss">
  ::v-deep .SignedDocumentsMultiSelectBar-button {
    span.v-btn__content {
      opacity: 1 !important;
    }
  }
</style>
