<template>
  <v-container fluid class="fill-height">
    <CerteuropeSignHeader :mnemo="mnemo"
                          :selectedTag.sync="selectedTag"
                          @headerHeight="headerHeight = $event"
                          @confirmSimpleSignature="confirmSimpleSignature"
    />
    <v-row v-if="!signingViewError && !signingViewPending">
      <v-col cols="12" md="10"
             class="overflow-y-auto"
             :style="{height: `calc(100vh - ${headerHeight})`}"
      >
        <CerteuropeSignPages :mnemo="mnemo"
                             :selected-tag="selectedTag"
                             @updateSelectedTag="selectedTag = $event"
                             @confirmSimpleSignature="confirmSimpleSignature"
        />
      </v-col>
      <v-col class="white overflow-y-auto"
             :style="{height: `calc(100vh - ${headerHeight})`}"
      >
        <CerteuropeSignThumbs :mnemo="mnemo"/>
      </v-col>
      <CerteuropeSignValidationDialog :mnemo="mnemo"/>
    </v-row>
    <v-row v-else align-content="center" justify="center" class="fill-height">
      <AppLoader v-if="signingViewPending" />
      <p v-else>{{$t('certeurope-sign.CerteuropeSign.getSigningViewError')}}</p>
    </v-row>
  </v-container>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

import { SIGN_LANDING_PAGE_ROUTE_NAME } from '@/router'

import CerteuropeSignHeader from './CerteuropeSignHeader'
import CerteuropeSignPages from './CerteuropeSignPages'
import CerteuropeSignThumbs from './CerteuropeSignThumbs'
import CerteuropeSignValidationDialog from './CerteuropeSignValidationDialog'
import AppLoader from '../common/AppLoader.vue'
import { CONFIRM_SIGNATURE, GET_SIGNING_VIEW } from '../store/modules/certeurope-sign/action_types'
import { RESET } from '../store/modules/certeurope-sign/mutation_types'
import { ENQUEUE_SNACKBAR } from '../store/mutation_types'

export default {
  name: 'CerteuropeSign',
  components: {
    CerteuropeSignValidationDialog,
    CerteuropeSignThumbs,
    CerteuropeSignPages,
    CerteuropeSignHeader,
    AppLoader,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    envelopeId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      headerHeight: 0,
      selectedTag: null,
    }
  },
  computed: {
    ...mapState('certeuropeSign', ['signingView', 'signingViewPending', 'signingViewError']),
    ...mapGetters('user', ['isSubscriber']),
  },
  mounted () {
    this.refreshDocument()
  },
  beforeDestroy () {
    this.RESET()
  },
  methods: {
    ...mapActions('certeuropeSign', [GET_SIGNING_VIEW, CONFIRM_SIGNATURE]),
    ...mapMutations('certeuropeSign', [RESET]),
    async refreshDocument () {
      try {
        await this.GET_SIGNING_VIEW({
          mnemo: this.mnemo,
          envelopeId: this.envelopeId,
        })
      } catch (e) {
        console.error(e)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('certeurope-sign.CerteuropeSign.getSigningViewError'),
        })
      }
    },
    async confirmSimpleSignature () {
      try {
        await this.CONFIRM_SIGNATURE({
          mnemo: this.mnemo,
          envelopeId: this.envelopeId,
        })
        this.$router.push({
          name: SIGN_LANDING_PAGE_ROUTE_NAME,
          params: {
            mnemo: this.mnemo,
          },
          query: {
            event: 'signing_complete',
            usesSimpleSignature: 'true',
            isSubUser: this.isSubscriber ? 'true' : 'false',
          },
        })
      } catch (error) {
        // Debug info for V2-677
        let tagsInfo = ''
        this.signingView.pages.forEach((page) => {
          if (page.tags.length > 0) {
            tagsInfo += `--\nPage ${page.number} (id: ${page.id}) tags: \n`
            page.tags.forEach((tag) => {
              tagsInfo += `  - id: ${tag.id} type: ${tag.type} isSigned: ${tag.isSigned}\n`
            })
            tagsInfo += '--\n'
          }
        })
        console.info(tagsInfo)
        Sentry.captureException(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('certeurope-sign.CerteuropeSign.confirmSimpleSignatureError'),
        })
      }
    },
  },
}
</script>
