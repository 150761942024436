export const tagsTypes = Object.freeze({
  SIGN: 'signHere',
  DATE: 'dateSigned',
  INITIAL: 'initialHere',
  NAME: 'fullName',
  TEXT: 'textField',
  TEXT_READ_ONLY: 'textFieldReadOnly',
  CERTIFIED: 'certifiedField',
  CHECKBOX: 'checkbox',
  OPTIONAL_CHECKBOX: 'optionalCheckbox',
})
