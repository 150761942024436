<template>
  <AppFileUploadFilder :filder="file"
                       :filder-name="fileName"
                       :filder-progress="file.progress"
                       :filder-status="file.status"
                       @cancelPendingFilder="onCancelPendingFile"
                       @cancelInProgressFilder="onCancelInProgressFile"
                       @redoFilder="onRedoFile"
  />
</template>

<script>
import { mapActions } from 'vuex'

import AppFileUploadFilder from '@/common/app-file-upload/AppFileUploadFilder'
import { fileNameWithoutExtension } from '@/common/utils/files'
export default {
  name: 'AppFileUploadFile',
  components: { AppFileUploadFilder },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fileName () {
      return fileNameWithoutExtension(this.file.fileObject.name)
    },
  },
  methods: {
    ...mapActions('appUploadHandler', ['cancelFile', 'redoFileUpload']),
    onCancelPendingFile () {
      this.cancelFile(this.file.key)
    },
    onCancelInProgressFile () {
      this.file.cancelToken.cancel()
    },
    onRedoFile () {
      this.redoFileUpload(this.file.key)
    },
  },
}
</script>
