export const ID_CHECK_ERROR_TYPES = Object.freeze({
  ERROR_TYPE_INFO: 'ERR_INFO',
  ERROR_TYPE_DOCUMENT: 'ERR_DOCUMENT',
})

export const ID_CHECK_ERRORS = Object.freeze({
  // FILE CONTROL ERRORS
  // CONTROLS PERFORMED WITHIN CLOSD BACKEND
  ERR_BACK_DOCUMENT_IMG_TOO_BIG: 'ERR_BACK_DOCUMENT_IMG_TOO_BIG',
  ERR_BACK_DOCUMENT_PDF_TOO_BIG: 'ERR_BACK_DOCUMENT_PDF_TOO_BIG',
  ERR_BACK_DOCUMENT_WRONG_FORMAT: 'ERR_BACK_DOCUMENT_WRONG_FORMAT',
  ERR_FRONT_DOCUMENT_IMG_TOO_BIG: 'ERR_FRONT_DOCUMENT_IMG_TOO_BIG',
  ERR_FRONT_DOCUMENT_PDF_TOO_BIG: 'ERR_FRONT_DOCUMENT_PDF_TOO_BIG',
  ERR_FRONT_DOCUMENT_WRONG_FORMAT: 'ERR_FRONT_DOCUMENT_WRONG_FORMAT',

  // CONTROL ERRORS ABOUT DOCUMENT FILES
  // CONTROLS PERFORMED FROM EXTERNAL SERVICE
  ERROR_DOCUMENT: 'ERR_DOCUMENT',
  ERROR_EXPIRED: 'ERR_EXPIRED',
  ERROR_FILE_TOO_LARGE: 'FILE_TOO_LARGE',
  ERROR_IMAGE_WITHOUT_DOCUMENT: 'IMAGE_WITHOUT_DOCUMENT',
  ERROR_IMG_BW: 'ERR_IMG_BW',
  ERROR_IMG_TOO_BIG: 'ERR_IMG_TOO_BIG',
  ERROR_IMG_TOO_SMALL: 'ERR_IMG_TOO_SMALL',
  ERROR_NO_BACKSIDE: 'ERR_NO_BACKSIDE',
  ERROR_NO_FRONTSIDE: 'ERR_NO_FRONTSIDE',
  ERROR_OCR_BIRTHDATE: 'ERR_OCR_BIRTHDATE',
  ERROR_OCR_EXPIRATIONDATE: 'ERR_OCR_EXPIRATIONDATE',
  ERROR_OCR_FIRSTNAMES: 'ERR_OCR_FIRSTNAMES',
  ERROR_OCR_LASTNAME: 'ERR_OCR_LASTNAME',
  ERROR_OTHER: 'ERR_OTHER',
  ERROR_QUALITY: 'ERR_QUALITY',
  ERROR_UNHANDLED: 'ERR_GENERIC',

  // CONTROL_ERRORS_ABOUT_INFORMATION
  // CONTROLS PERFORMED FROM EXTERNAL SERVICE
  ERROR_BIRTHDATE_MISMATCH: 'ERR_BIRTHDATE_MISMATCH',
  ERROR_BIRTHNAME_MISMATCH: 'ERR_BIRTHNAME_MISMATCH',
  ERROR_FIRSTNAME_MISMATCH: 'ERR_FIRSTNAME_MISMATCH',
  ERROR_GENDER_MISMATCH: 'ERR_GENDER_MISMATCH',
  ERROR_INVALID_CHAR: 'ERR_INVALID_CHAR',
  ERROR_LAST_NAMES_INVERTED: 'ERR_LAST_NAMES_INVERTED',
  ERROR_MISSING_PROFILE_INFO: 'ERR_MISSING_PROFILE_INFO',
  ERROR_NAMES_INVERTED: 'ERR_NAMES_INVERTED',
})

export const ID_CHECK_FILE_CONTROL_ERRORS = Object.freeze([
  ID_CHECK_ERRORS.ERR_BACK_DOCUMENT_IMG_TOO_BIG,
  ID_CHECK_ERRORS.ERR_BACK_DOCUMENT_PDF_TOO_BIG,
  ID_CHECK_ERRORS.ERR_BACK_DOCUMENT_WRONG_FORMAT,
  ID_CHECK_ERRORS.ERR_FRONT_DOCUMENT_IMG_TOO_BIG,
  ID_CHECK_ERRORS.ERR_FRONT_DOCUMENT_PDF_TOO_BIG,
  ID_CHECK_ERRORS.ERR_FRONT_DOCUMENT_WRONG_FORMAT,
])

export const ID_CHECK_DOCUMENT_ERRORS = Object.freeze([
  ID_CHECK_ERRORS.ERROR_DOCUMENT,
  ID_CHECK_ERRORS.ERROR_EXPIRED,
  ID_CHECK_ERRORS.ERROR_FILE_TOO_LARGE,
  ID_CHECK_ERRORS.ERROR_IMAGE_WITHOUT_DOCUMENT,
  ID_CHECK_ERRORS.ERROR_IMG_BW,
  ID_CHECK_ERRORS.ERROR_IMG_TOO_BIG,
  ID_CHECK_ERRORS.ERROR_IMG_TOO_SMALL,
  ID_CHECK_ERRORS.ERROR_NO_BACKSIDE,
  ID_CHECK_ERRORS.ERROR_NO_FRONTSIDE,
  ID_CHECK_ERRORS.ERROR_OCR_BIRTHDATE,
  ID_CHECK_ERRORS.ERROR_OCR_EXPIRATIONDATE,
  ID_CHECK_ERRORS.ERROR_OCR_FIRSTNAMES,
  ID_CHECK_ERRORS.ERROR_OCR_LASTNAME,
  ID_CHECK_ERRORS.ERROR_OTHER,
  ID_CHECK_ERRORS.ERROR_QUALITY,
  ID_CHECK_ERRORS.ERROR_UNHANDLED,
])

export const ID_CHECK_INFORMATION_ERRORS = Object.freeze([
  ID_CHECK_ERRORS.ERROR_BIRTHDATE_MISMATCH,
  ID_CHECK_ERRORS.ERROR_BIRTHNAME_MISMATCH,
  ID_CHECK_ERRORS.ERROR_FIRSTNAME_MISMATCH,
  ID_CHECK_ERRORS.ERROR_GENDER_MISMATCH,
  ID_CHECK_ERRORS.ERROR_INVALID_CHAR,
  ID_CHECK_ERRORS.ERROR_LAST_NAMES_INVERTED,
  ID_CHECK_ERRORS.ERROR_MISSING_PROFILE_INFO,
  ID_CHECK_ERRORS.ERROR_NAMES_INVERTED,
])
