<template>
  <div>
    <v-row>
      <v-col cols="6">
        <p class="mb-6">{{ $t(`project.signing-checklist.add.SigningChecklistSectionDocumentSignersCount.${sectionCaption}`) }}</p>
        <AppTextField
          v-model="signersCount"
          :disabled="isEditingLimited"
          :error-messages="signersErrorMessages"
          :placeholder="$t(`project.signing-checklist.add.SigningChecklistSectionDocumentSignersCount.${fieldPlaceholder}`)"
          class="required"
          required
          type="number"
          :min="0"
          @change="onSignersCountChange"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'

import AppTextField from '@/common/AppTextField.vue'
import { ENVELOPE_TYPES } from '@/project/signing-checklist/constants'

export default defineComponent({
  name: 'SigningChecklistSectionDocumentSignersCount',
  components: {
    AppTextField,
  },
  props: {
    fileSigners: {
      type: [Array, Number],
      required: true,
    },
    fileEnvelopeType: {
      type: String,
      required: true,
    },
    isEditingLimited: {
      type: Boolean,
      required: true,
    },
    signersValidator: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      signersCountValue: null,
    }
  },
  computed: {
    fieldPlaceholder (): string {
      return this.fileEnvelopeType === ENVELOPE_TYPES.SINGLE ? 'signersFieldPlaceholder' : 'signersFieldCommonPlaceholder'
    },
    sectionCaption (): string {
      return this.fileEnvelopeType === ENVELOPE_TYPES.SINGLE ? 'signersCaption' : 'signersCommonCaption'
    },
    signersCount: {
      get () : number | null {
        if (this.signersCountValue) {
          return this.signersCountValue
        } else {
          const count = Array.isArray(this.fileSigners) ? this.fileSigners.flat().length : this.fileSigners
          return count || null
        }
      },
      set (val) {
        this.signersCountValue = val
      },
    },
    signersErrorMessages (): TranslateResult[] {
      if (this.signersValidator.$dirty) {
        if (!this.signersValidator.maxValue) {
          return [this.$t('project.signing-checklist.add.SigningChecklistSectionDocumentSignersCount.signersCountMaxValueError', { max: this.signersValidator.$params.maxValue.max })]
        }
        if (!this.signersValidator.minValue || !this.signersValidator.required) {
          return [this.$t('project.signing-checklist.add.SigningChecklistSectionDocumentSignersCount.signersCountMinValueError')]
        }
        if (!this.signersValidator.numeric) {
          return [this.$t('common.validations.numeric', { fieldName: this.$t('project.signing-checklist.add.SigningChecklistSectionDocumentSignersCount.signersFieldLabel') })]
        }
      }

      return []
    },
  },
  methods: {
    onSignersCountChange (value: number) {
      this.$emit('signers-change', value)
    },
  },
})
</script>
