<template>
  <div>
    <AppDialog :is-open="isDialogOpened"
               size="xl"
               @cancel="closeDialog"
    >
      <template #title>{{ $t('project.bibles.dialogs.BibleGenerateDialog.generateNewBible') }}</template>

      <template #body>
        <template v-if="formStep === 1">
          <AppTextField v-model.trim="$v.newBibleName.$model"
                        :label="$t('project.bibles.dialogs.BibleGenerateDialog.newBibleNameLabel')"
                        class="required"
                        counter="100"
                        :error-messages="hasNewBibleNameErrors"
                        maxLength="100"
          />

          <div class="mt-8">
            <p class="mb-4">{{ $t("project.bibles.dialogs.BibleGenerateDialog.documentsSelectionTitle") }}</p>

            <v-tabs v-model="currentTab" fixed-tabs>
              <v-tab
                :href="`#${BIBLE_GENERATE_DIALOG_TABS.DOCUMENTS}`"
              >
              {{ $t("project.bibles.dialogs.BibleGenerateDialog.header1") }}
              </v-tab>
              <v-tab
                v-if="!noTodoFolder"
                :href="`#${BIBLE_GENERATE_DIALOG_TABS.TODOS}`"
              >
                {{ $t("project.bibles.dialogs.BibleGenerateDialog.header2") }}
              </v-tab>
              <v-tab
                v-if="!currentRoom.isDataroom"
                :href="`#${BIBLE_GENERATE_DIALOG_TABS.SIGNATURES}`"
              >
                {{ $t('project.bibles.dialogs.BibleGenerateDialog.header3') }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentTab">
              <v-tab-item :value="BIBLE_GENERATE_DIALOG_TABS.DOCUMENTS">
                <v-card-text style="max-height: 300px; overflow-y: auto">
                  <template v-if="documentsFolder">
                    <template v-if="documentsFolder.children.length !== 0">
                      <AppClosdFildersTree
                        v-model="selectedDocuments"
                        :root-folder="documentsFolder"
                      />
                    </template>
                    <template v-else>
                      <div class="caption text-center">
                        {{$t('project.bibles.dialogs.BibleGenerateDialog.noDocuments')}}
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div class="text-center">
                      <v-progress-circular
                        style="width: 48px; margin: 100px 0"
                        color="primary"
                        size="48"
                        indeterminate
                      />
                    </div>
                  </template>
                </v-card-text>
              </v-tab-item>
              <v-tab-item v-if="!noTodoFolder" :value="BIBLE_GENERATE_DIALOG_TABS.TODOS">
                <v-card-text style="max-height: 300px; overflow-y: auto">
                  <template v-if="!todosFolderStructureError">
                    <template v-if="sortedTodosFolder">
                      <template v-if="sortedTodosFolder.children.length !== 0">
                        <AppClosdFildersTree v-model="selectedTodos"
                                            :root-folder="todosFolderStructure"
                                            :display-tree-structure="displayTreeStructure"
                        />
                      </template>
                      <template v-else>
                        <div class="caption text-center">
                          {{$t('project.bibles.dialogs.BibleGenerateDialog.noTasks')}}
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="text-center">
                        <v-progress-circular
                          style="width: 48px; margin: 100px 0"
                          color="primary"
                          size="48"
                          indeterminate
                        />
                      </div>
                    </template>
                  </template>
                  <div v-else class="caption text-center">
                    {{ $t('project.bibles.dialogs.BibleGenerateDialog.checklistTodosFolderError') }}
                  </div>
                </v-card-text>
              </v-tab-item>
              <v-tab-item v-if="!currentRoom.isDataroom" :value="BIBLE_GENERATE_DIALOG_TABS.SIGNATURES">
                <v-card-text style="max-height: 300px; overflow-y: auto">
                  <template v-if="mySignaturesFolder">
                    <template v-if="mySignaturesFolder.children.length !== 0">
                      <AppFildersTree id="signaturesPicker"
                                      v-model="selectedSignatureIntern"
                                      :rootFolder="mySignaturesFolder"
                                      isSignature
                                      multiSelect
                                      openFirstNode
                      >
                        <template v-slot:append="{ item }">
                          <span v-if="item.time && $vuetify.breakpoint.smAndUp">{{ $t("project.bibles.dialogs.BibleGenerateDialog.myOrigSignedOn") + " " + formatSignTime(item.time) }}</span>
                        </template>
                      </AppFildersTree>
                    </template>
                    <template v-else>
                      <div class="caption text-center">
                        {{ $t('project.bibles.dialogs.BibleGenerateDialog.noSignedDocuments') }}
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div class="text-center">
                      <v-progress-circular
                        style="width: 48px; margin: 100px 0"
                        color="primary"
                        size="48"
                        indeterminate
                      />
                    </div>
                  </template>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </template>

        <template v-else-if="formStep === 2">
          <p>{{ $t("project.bibles.dialogs.BibleGenerateDialog.optionsTitle") }}</p>

          <div class="d-flex flex-column row-gap-2 mt-6">
            <template v-if="areCertificateOptionsAvailable">
              <template v-if="roomEnvelopesDownloadPreflight.canIncludeCertificateLocale">
                <v-checkbox v-model="certificateOptions.selectLocaleAuthenticationCertificate"
                            :label="$t('project.bibles.dialogs.BibleGenerateDialog.selectAuthenticationCertificateWithLanguage', { language: displayedCertificateLocale })"
                            :disabled="certificateOptions.selectAuthenticationCertificate || certificateOptions.selectMergeDocuments"
                            name="withLocaleCertificate"
                            dense
                            hide-details
                />

                <v-checkbox v-model="certificateOptions.selectAuthenticationCertificate"
                            :label="$t('project.bibles.dialogs.BibleGenerateDialog.selectAuthenticationCertificateWithLanguage', { language: DEFAULT_CERTIFICATE_LANGUAGE })"
                            :disabled="certificateOptions.selectLocaleAuthenticationCertificate || certificateOptions.selectMergeDocuments"
                            name="withDefaultCertificate"
                            dense
                            hide-details
                />
              </template>
              <template v-else>
                <v-checkbox v-model="certificateOptions.selectAuthenticationCertificate"
                            :label="$t('project.bibles.dialogs.BibleGenerateDialog.selectAuthenticationCertificate')"
                            :disabled="certificateOptions.selectMergeDocuments"
                            name="withDefaultCertificate"
                            dense
                            hide-details
                />
              </template>

              <AppTooltip v-if="isDocumentsMergingAvailable"
                          top
                          :disabled="canEveryDocumentsBeMerged"
                          :max-width="410"
              >
                <template #activator="{attrs, on}">
                  <div class="d-flex align-center mt-1" v-bind="attrs" v-on="on">
                    <v-checkbox v-model="certificateOptions.selectMergeDocuments"
                                :label="$t('project.bibles.dialogs.BibleGenerateDialog.selectAuthenticationCertificate')"
                                :disabled="!isAnyCertificateSelected || !canEveryDocumentsBeMerged"
                                name="mergeDocuments"
                                class="mt-0 pt-0 d-flex align-center"
                                dense
                                hide-details
                    >
                      <template #label>
                        <span>
                          {{ $t("project.bibles.dialogs.BibleGenerateDialog.mergeDocuments") }}
                        </span>
                      </template>
                    </v-checkbox>

                    <AppNewLabel class="ml-2" />
                  </div>
                </template>
                <span>{{ $t("project.bibles.dialogs.BibleGenerateDialog.documentsMergeNotAvailableForEveryDocumentsTooltip") }}</span>
              </AppTooltip>
            </template>

            <v-checkbox v-model="includeFilesIndex"
                        :label="$t('project.bibles.dialogs.BibleGenerateDialog.includeFilesIndexLabel')"
                        dense
                        hide-details
            />

            <v-checkbox v-if="displayHistoryOption"
                        v-model="includeHistory"
                        :label="$t('project.bibles.dialogs.BibleGenerateDialog.includeHistoryLabel')"
                        dense
                        hide-details
            />

            <v-checkbox v-if="!currentRoom.isDataroom"
                        v-model="hasChecklist"
                        :label="$t('project.bibles.dialogs.BibleGenerateDialog.hasChecklist')"
                        dense
                        hide-details
            />

            <v-checkbox v-if="currentRoom.isDataroom"
                        v-model="hasQA"
                        :label="$t('project.bibles.dialogs.BibleGenerateDialog.hasQA')"
                        dense
                        hide-details
            />
          </div>
        </template>
      </template>

      <template #footer-infos>
        <div v-if="postBiblePending" class="mr-5 d-flex align-center">
          <font-awesome-icon :icon="['far', 'spinner-third']" spin class="text--primary mr-2" size="2x"></font-awesome-icon>
          {{ $t('project.bibles.dialogs.BibleGenerateDialog.calculatingInProgress') }}
        </div>
      </template>

      <template #footer>
        <app-button v-if="formStep === 2"
                    type="tonal"
                    @click="formStep = 1"
        >
          <font-awesome-icon :icon="['far', 'arrow-left-long']" class="mr-2" />
          {{$t('common.previous')}}
        </app-button>

        <v-spacer />

        <app-button type="tonal"
                    class="mr-2"
                    :disabled="postBiblePending"
                    @click="closeDialog"
        >
          {{$t('common.cancel')}}
        </app-button>

        <app-button v-if="formStep === 1"
                    type="primary"
                    :loading="roomEnvelopesDownloadPreflightPending"
                    :disabled="!canValidateSelectionStep"
                    @click="nextStep"
        >
          {{ $t("common.next") }}
        </app-button>
        <app-button v-else
                    type="primary"
                    :loading="postBiblePending"
                    :disabled="!canPostBible"
                    @click="preparePostBible"
        >
          {{ $t("project.bibles.dialogs.BibleGenerateDialog.validateBibleCreation") }}
        </app-button>
      </template>
    </AppDialog>
    <BibleTooBigDialog :isOpen.sync="bibleTooBigModalIsOpen"
                       :bibleTooBigData="bibleTooBigData"
    />
  </div>
</template>

<script>

import dayjs from 'dayjs'
import { orderBy } from 'lodash-es'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppClosdFildersTree from '@/common/app-closd-filders-tree/AppClosdFildersTree'
import AppNewLabel from '@/common/AppNewLabel.vue'
import AppTextField from '@/common/AppTextField'
import AppTooltip from '@/common/AppTooltip.vue'
import AppFildersTree from '@/common/AppVuetifyFildersTree'
import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'
import BibleTooBigDialog from '@/project/bibles/BibleTooBigDialog'
import { POST_BIBLE } from '@/store/modules/bibles/action_types'
import { GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'
import { GET_MY_ENVELOPES } from '@/store/modules/envelopes/action_types'
import { GET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT } from '@/store/modules/room/action_types'
import { GET_TODOS_FOLDER_STRUCTURE } from '@/store/modules/todo/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

const DEFAULT_CERTIFICATE_LANGUAGE = 'EN'

export const BIBLE_GENERATE_DIALOG_TABS = {
  DOCUMENTS: 'documents',
  TODOS: 'todos',
  SIGNATURES: 'signatures',
}

const VALID_BIBLE_GENERATE_DIALOG_TABS = Object.values(BIBLE_GENERATE_DIALOG_TABS)

export default {
  name: 'BibleGenerateDialog',
  components: {
    AppClosdFildersTree,
    AppDialog,
    AppFildersTree,
    AppNewLabel,
    AppTextField,
    AppTooltip,
    BibleTooBigDialog,
  },
  mixins: [validationMixin, dialogMixin],
  validations: {
    newBibleName: {
      required,
      maxLength: maxLength(100),
    },
  },
  props: {
    initialSelectedDocuments: {
      type: Array,
      required: false,
      default: () => [],
    },
    initialSelectedTodos: {
      type: Array,
      required: false,
      default: () => [],
    },
    initialTab: {
      type: String,
      default: BIBLE_GENERATE_DIALOG_TABS.DOCUMENTS,
      validator (value) {
        return VALID_BIBLE_GENERATE_DIALOG_TABS.includes(value)
      },
    },
    /**
     * Displays tree titles and subtitles
     */
     displayTreeStructure: {
      type: Boolean,
      default: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
    pollAfterPost: {
      type: Boolean,
      default: true,
    },
    selectedSignatures: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data () {
    return {
      DEFAULT_CERTIFICATE_LANGUAGE,
      bibleTooBigData: {
        currentSize: null,
        maxSize: null,
      },
      bibleTooBigModalIsOpen: false,
      certificateOptions: {
        selectAuthenticationCertificate: false,
        selectLocaleAuthenticationCertificate: false,
        selectMergeDocuments: false,
      },
      currentTab: null,
      dialogIsOpen: false,
      documentsFolder: null,
      formStep: 1,
      hasChecklist: false,
      hasQA: false,
      includeFilesIndex: true,
      includeHistory: false,
      mySignaturesFolder: null,
      newBibleName: '',
      noSignatureSelected: false,
      noTodoFolder: false,
      selectedDocuments: [],
      selectedSignatureIntern: [],
      selectedTodos: [],
      sortedTodosFolder: null,
      BIBLE_GENERATE_DIALOG_TABS,
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapState('bibles', ['postBiblePending']),
    ...mapState('documents', ['documents']),
    ...mapState('envelopes', ['myEnvelopes']),
    ...mapState('todo', ['todosFolderStructure', 'todosFolderStructureError']),
    ...mapState('room', ['currentRoom', 'roomEnvelopesDownloadPreflight', 'roomEnvelopesDownloadPreflightPending']),
    allSelectedFiles () {
      return [...this.selectedDocuments, ...this.selectedTodos.flatMap(todo => todo?.children || todo), ...this.selectedSignatureIntern]
    },
    areCertificateOptionsAvailable () {
      return this.selectedSignatureIntern.length > 0 && (this.roomEnvelopesDownloadPreflight?.canIncludeCertificate || this.roomEnvelopesDownloadPreflight?.canIncludeCertificateLocale)
    },
    canEveryDocumentsBeMerged () {
      if (this.certificateOptions.selectAuthenticationCertificate) {
        return this.roomEnvelopesDownloadPreflight.canIncludeCombinedCertificate
      } else if (this.certificateOptions.selectLocaleAuthenticationCertificate) {
        return this.roomEnvelopesDownloadPreflight.canIncludeCombinedCertificateLocale
      }

      return true
    },
    canValidateSelectionStep () {
      return this.newBibleName.length > 0 && this.isAnyFileSelected
    },
    canPostBible () {
      return !this.postBiblePending && this.isAnyFileSelected
    },
    displayedCertificateLocale () {
      return this.roomEnvelopesDownloadPreflight?.certificateLocale.toUpperCase() || null
    },
    displayHistoryOption () {
      return this.allSelectedFiles.some(document => document.type !== 'file' || document?.nbVersions > 1 || document?.version > 1)
    },
    hasNewBibleNameErrors () {
      const errors = []
      if (!this.$v.newBibleName.$dirty) return errors
      !this.$v.newBibleName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.bibles.dialogs.BibleGenerateDialog.newBibleNameLabel') }))
      !this.$v.newBibleName.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.bibles.dialogs.BibleGenerateDialog.newBibleNameLabel') }))
      return errors
    },
    isAnyFileSelected () {
      return this.selectedDocuments.length > 0 || this.selectedTodos.length > 0 || this.selectedSignatureIntern.length > 0
    },
    isAnyCertificateSelected () {
      return this.certificateOptions.selectAuthenticationCertificate || this.certificateOptions.selectLocaleAuthenticationCertificate
    },
    isDocumentsMergingAvailable () {
      return this.isCurrentUserPm
    },
  },
  watch: {
    selectedSignatureIntern (signedDocuments) {
      if (signedDocuments.length === 0) {
        this.certificateOptions = {
          selectAuthenticationCertificate: false,
          selectLocaleAuthenticationCertificate: false,
          selectMergeDocuments: false,
        }
      }
    },
  },
  created () {
    this.noTodoFolder = this.currentRoom.isDataroom
  },
  mounted () {
    this.getRoomFiles()
    this.currentTab = this.initialTab
    this.selectedSignatureIntern = this.selectedSignatures
    this.noSignatureSelected = this.selectedSignatureIntern.length === 0
    this.selectedDocuments = this.initialSelectedDocuments
    this.selectedTodos = this.initialSelectedTodos
  },
  methods: {
    ...mapActions('bibles', [POST_BIBLE]),
    ...mapActions('documents', [GET_DOCUMENTS_BY_ID]),
    ...mapActions('envelopes', [GET_MY_ENVELOPES]),
    ...mapActions('room', [GET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT]),
    ...mapActions('todo', [GET_TODOS_FOLDER_STRUCTURE]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    onCloseDialog () {
      this.$emit('selectedSignature', this.selectedSignatureIntern)
      this.closeDialog()
    },
    async nextStep () {
      if (!this.isDocumentsMergingAvailable) {
        this.certificateOptions.selectAuthenticationCertificate = false
        this.certificateOptions.selectLocaleAuthenticationCertificate = false
        this.certificateOptions.selectMergeDocuments = false
      }

      if (this.selectedSignatureIntern.length !== 0) {
        await this.getRoomEnvelopesDownloadPreflight()
      }

      this.formStep = 2
    },
    async getRoomEnvelopesDownloadPreflight () {
      const envelopeIds = this.selectedSignatureIntern.map(envelope => { return envelope.id }).join(',')

      try {
        await this.GET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT({
          mnemo: this.mnemo,
          params: {
            envelopesIds: envelopeIds,
          },
        })
      } catch (error) {
        console.error(error)
      }
    },
    getRoomFiles () {
      this.documentsFolder = null
      this.sortedTodosFolder = null
      this.mySignaturesFolder = null
      this.getDocumentsFolder()
      if (!this.noTodoFolder) this.getTodosFolder()
      if (!this.currentRoom.isDataroom) this.getMySignaturesFolder()
    },
    async getDocumentsFolder () {
      await this.GET_DOCUMENTS_BY_ID({
        mnemo: this.mnemo,
        queryObject: { ignoreTodoFolder: true, lazyMode: true },
        id: 0,
      })
      this.documentsFolder = {
        ...this.documents,
        children: orderBy(this.documents.children, ['type', 'basename'], ['desc', 'asc']),
      }
    },
    async getTodosFolder () {
      try {
        await this.GET_TODOS_FOLDER_STRUCTURE({
          mnemo: this.mnemo,
          queryObject: { lazyMode: false },
        })
        this.sortedTodosFolder = {
          ...this.todosFolderStructure,
          children: orderBy(this.todosFolderStructure.children, ['type', 'basename'], ['desc', 'asc']),
        }
      } catch (error) {
        if (error.response?.status === 403) {
          this.noTodoFolder = true
        }
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.bibles.dialogs.BibleGenerateDialog.checklistTodosFolderError'))
      }
    },
    async getMySignaturesFolder () {
      await this.GET_MY_ENVELOPES({
        mnemo: this.mnemo,
        queryObject: { lazyMode: false, downloadableOnly: true },
      })
      this.mySignaturesFolder = {
        basename: this.$t('project.bibles.dialogs.BibleGenerateDialog.header3'),
        name: this.$t('project.bibles.dialogs.BibleGenerateDialog.header3'),
        children: orderBy(this.myEnvelopes, ['type', 'basename'], ['desc', 'asc']),
      }
    },
    formatSignTime (signTime) {
      return dayjs(signTime).format(`DD/MM/YYYY [${this.$t('common.at')}] HH:mm:ss`)
    },
    processTodosItem (item, fileIds, folderIds) {
      if (item.type === 'file') {
        fileIds.push(item.id)
      } else if (item.type === 'folder') {
        folderIds.push(item.id)
      } else if (item.type === 'title') {
        item.children.forEach((child) => {
          this.processTodosItem(child, fileIds, folderIds)
        })
      }
    },
    async preparePostBible () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const envelopeIds = []
        const fileIds = []
        const folderIds = []
        const todoFileIds = []
        const todoFolderIds = []

        if (this.selectedDocuments) {
          this.selectedDocuments.forEach(item => {
            if (item.type === 'file') {
              fileIds.push(item.id)
            } else if (item.type === 'folder') {
              folderIds.push(item.id)
            }
          })
        }

        if (this.selectedTodos) {
          this.selectedTodos.forEach(item => {
            this.processTodosItem(item, todoFileIds, todoFolderIds)
          })
        }

        if (this.selectedSignatureIntern) {
          this.selectedSignatureIntern.forEach(item => {
            envelopeIds.push(item.id)
          })
        }

        try {
          await this.POST_BIBLE({
            mnemo: this.mnemo,
            data: {
              certificate: this.certificateOptions.selectAuthenticationCertificate,
              certificateLocale: this.certificateOptions.selectLocaleAuthenticationCertificate,
              combined: this.certificateOptions.selectMergeDocuments,
              envelopeIds: envelopeIds,
              fileIds: fileIds,
              folderIds: folderIds,
              hasChecklist: this.hasChecklist,
              hasCustom: true,
              hasIndex: this.includeFilesIndex,
              hasQA: this.hasQA,
              history: this.includeHistory,
              name: this.newBibleName,
              todoFileIds: todoFileIds,
              todoFolderIds: todoFolderIds,
            },
          })

          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.bibles.dialogs.BibleGenerateDialog.postBibleSuccess'))

          if (this.pollAfterPost) {
            this.$emit('pollBibles')
          }

          this.onCloseDialog()
        } catch (error) {
          if (error.response?.status === 422) {
            if (error.response.data?.errorCode === 'ERROR_BIBLE_TOO_LARGE') {
              this.bibleTooBigData.currentSize = error.response.data.currentSize
              this.bibleTooBigData.maxSize = error.response.data.maxSize
              this.bibleTooBigModalIsOpen = true
            } else if (error.response.data?.errorCode === 'ERROR_BIBLE_EMPTY') {
              this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.bibles.dialogs.BibleGenerateDialog.emptyBibleError'))
            }
          } else if (error.response?.status === 403 && error.response.data?.errorCode === 'ERR_INVALID_RIGHTS') {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.bibles.dialogs.BibleGenerateDialog.invalidRightsBibleError'))
          } else {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.bibles.dialogs.BibleGenerateDialog.postBibleError'))
            this.closeDialog()
          }
        }
      }
    },
  },
}
</script>
