<template>
  <div>
    <AppButton color="white"
               height="30"
               class="my-1 btn-allow-wrap"
               :x-padding="1"
               @click="openDialog = true"
    >
      <font-awesome-icon :icon="['far', 'ban']"
                         class="error--text mr-1 body-1"
      ></font-awesome-icon>
      {{ $t('project.signing-checklist.list.SigningChecklistTableItemInvalidateDialog.validation.invalidateDocument') }}
    </AppButton>
    <AppDialog :is-open="openDialog"
               :ok-disabled="deleteValidationPending"
               :ok-loading="deleteValidationPending"
               :cancel-disabled="deleteValidationPending"
               @cancel="openDialog = false"
               @ok="prepareDeleteValidation"
    >
      <template #title>{{ $t('project.signing-checklist.list.SigningChecklistTableItemInvalidateDialog.dialog.title') }}</template>
      <template #body>
        {{ $t('project.signing-checklist.list.SigningChecklistTableItemInvalidateDialog.dialog.text') }}
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppButton from '../../../common/buttons/AppButton'
import AppDialog from '../../../common/dialogs/AppDialog'
import { DELETE_VALIDATION, REFRESH_STEP } from '../../../store/modules/signing-checklist/action_types'

export default {
  name: 'SigningChecklistTableItemInvalidateDialog',
  components: { AppDialog, AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    envelopeId: {
      type: Number,
      required: true,
    },
    validationId: {
      type: Number,
      required: true,
    },
    stepId: {
      type: Number,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      openDialog: false,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['deleteValidationPending']),
  },
  methods: {
    ...mapActions('signingChecklist', [DELETE_VALIDATION, REFRESH_STEP]),
    async prepareDeleteValidation () {
      try {
        await this.DELETE_VALIDATION({
          mnemo: this.mnemo,
          envelopeId: this.envelopeId,
          validationId: this.validationId,
        })
        this.openDialog = false
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.$t('project.signing-checklist.list.SigningChecklistTableItemInvalidateDialog.message.success'),
        })
        await this.REFRESH_STEP({
          mnemo: this.mnemo,
          stepId: this.stepId,
          parent: this.parent,
        })
      } catch (e) {
        console.error(e)
        this.$store.commit('enqueueSnackbar', {
          color: 'error',
          message: this.$t('project.signing-checklist.list.SigningChecklistTableItemInvalidateDialog.message.error'),
        })
      }
    },
  },
}
</script>
