<template>
  <AppTooltip top>
    <template #activator="{ on, attrs }">
      <AppButton class="ml-1"
                icon
                small
                v-bind="attrs"
                v-on="on"
      >
        <font-awesome-icon :icon="['fal', 'paperclip']"></font-awesome-icon>
      </AppButton>
    </template>
    <div>
      {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.checklistPreview.attachments') }}
      <ul>
        <li v-for="(fileName, index) in task.attachments" :key="`attachment-${index}`">
          {{ fileName }}
        </li>
      </ul>
    </div>
  </AppTooltip>
</template>

<script>
  import AppTooltip from '@/common/AppTooltip.vue'
  import AppButton from '@/common/buttons/AppButton.vue'

  export default {
    name: 'SavedChecklistPreviewAttachments',
    components: {
      AppButton,
      AppTooltip,
    },
    props: {
      task: {
        type: Object,
        required: true,
      },
    },
  }
</script>
