<template>
  <div class="AppTextFieldSuggestion info">
    <div>
      <span>{{ $t('common.identity-checking.AppTextFieldSuggestion.didYouMean') }}</span>
      <span class="font-weight-medium ml-2">{{ suggestedValue }}</span>
    </div>
    <div>
      <AppButton color="transparent"
                 class="white--text caption"
                 @click="$emit('validate')"
      >
        <font-awesome-icon :icon="['far', 'circle-check']" class="mr-1 body-1"></font-awesome-icon>
        {{ $t('common.validate') }}
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from '@/common/buttons/AppButton'
export default {
  name: 'AppTextFieldSuggestion',
  components: { AppButton },
  props: {
    suggestedValue: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.AppTextFieldSuggestion {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  border-radius: 8px;
  padding: 10px 8px;
  color: white;
  font-size: 12px;
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: inherit;
    position: absolute;
    top: -4px;
    left: 10px;
    transform: rotate(45deg);
  }
}
</style>
