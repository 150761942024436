<template>
  <span class="grey--text text--darken-2">{{ signersCount }}</span>
</template>

<script>
import { SIGNATURE_TYPES } from '@/project/signing-checklist/constants'

export default {
  name: 'Signatory',
  props: {
    signatures: {
      type: Array,
      required: true,
    },
  },
  computed: {
    signersCount () {
      return this.signatures.filter(signature => signature.signatureType === SIGNATURE_TYPES.SINGLE).length
    },
  },
}
</script>
