import { TAB_INDEX } from '@/project/checklist/dialogs/enums.ts'

export const state = {
  globalAttachedFilesDialogIsOpen: false,
  deleteTaskModal: null,
  checklistPending: false,
  checklist: null,
  checklistError: null,
  createTaskPending: false,
  createTaskError: null,
  editTitlePending: false,
  editTitleError: null,
  deleteTitlePending: false,
  deleteTitleError: null,
  titleIndentationPending: false,
  titleIndentationError: null,
  createTitlePending: false,
  createTitleError: null,
  taskFolderPendingIds: [],
  currentTaskFolder: null,
  taskFolderError: null,
  pane: {
    isOpen: false,
    tab: '',
  },
  tableTaskPane: {
    isOpen: false,
    tab: '',
    documents: [],
    parent: null,
  },
  checklistVersioningPane: {
    documentVersions: [],
    documents: [],
    isOpen: false,
    removeVersionPending: false,
    tab: '',
  },
  editTaskPending: false,
  editTaskError: null,
  shareAttachedFilesPending: false,
  shareAttachedFilesError: null,
  moveFildersPending: false,
  moveFildersError: null,
  deleteFildersPending: false,
  deleteFildersError: null,
  renameAttachedFilePending: false,
  renameAttachedFileError: null,
  renameAttachedFilesDialog: {
    isOpen: false,
    fileToRename: null,
  },
  attachedFileVersionsPending: false,
  attachedFileVersions: null,
  attachedFileVersionsError: null,
  postAttachedFileVersionsPending: false,
  postAttachedFileVersionsError: null,
  selectedAttachedFiles: [],
  shareTasksDialog: {
    isOpen: false,
    tasksToShare: [],
    parent: null,
  },
  shareTaskRolePickerSelectedResponsible: {
    groups: [],
    members: [],
  },
  shareTaskRolePickerSelectedObservers: {
    groups: [],
    members: [],
  },
  shareTaskRolePickerSelectedValidators: {
    groups: [],
    members: [],
  },
  shareTasksPending: false,
  shareTasksError: null,
  saveChecklistDialogIsOpen: false,
  saveChecklistPending: false,
  updateChecklistPending: false,
  saveChecklistError: null,
  importChecklistDialogIsOpen: false,
  importChecklistFromTemplateDialogIsOpen: false,
  checklistTemplatesSelectedTab: TAB_INDEX.UNSET,
  savedChecklistsPending: false,
  savedChecklists: [],
  selfSavedChecklistsPending: false,
  selfSavedChecklists: [],
  selectedSelfSavedChecklist: null,
  savedChecklistsError: null,
  selectedSavedChecklist: null,
  savedChecklistPreviewPending: false,
  savedChecklistPreview: null,
  savedChecklistPreviewError: null,
  lexisNexisChecklistsPending: false,
  lexisNexisChecklists: [],
  lexisNexisChecklistsError: null,
  selectedLexisNexisChecklist: null,
  lexisNexisChecklistPreviewPending: false,
  lexisNexisChecklistPreview: null,
  lexisNexisChecklistPreviewError: null,
  importChecklistFromClosdPending: false,
  importChecklistFromExcelPending: false,
  importChecklistFromClosdError: null,
  deleteSavedChecklistPending: false,
  deleteSavedChecklistError: null,
  shareSavedChecklistPending: false,
  shareSavedChecklistError: null,
  renameSavedChecklistPending: false,
  renameSavedChecklistError: null,
  lastTitleAdded: null,
  lastTaskAdded: null,
  taskCommentsPendingIds: [],
  taskCommentsError: null,
  postTaskCommentPendingIds: [],
  postTaskCommentError: null,
  deleteTaskCommentPendingIds: [],
  deleteTaskCommentError: null,
  deleteTaskPending: false,
  deleteTaskError: null,
  deleteChecklistDoubleValidationPending: false,
  contactResponsiblePending: false,
  contactResponsibleError: null,
  taskStatusCategoriesPending: false,
  taskStatusCategoriesError: null,
  taskStatusCategories: {},
  postMoveTaskPending: false,
  postMoveTaskError: null,
  refreshTaskPendingIds: [],
  refreshTaskError: null,
  deleteTableSelectionPending: false,
  deleteTableSelectionError: null,
  refreshChecklistPending: false,
  refreshChecklist: null,
  refreshChecklistError: null,
  currentTitleEditPending: false,
  currentTitleEdit: null,
  currentTitleEditError: null,
  duplicateTaskPending: false,
  duplicateTaskError: null,
  titlesExpandState: [],
  shareChecklistByEmailPending: false,
  postStatusPending: false,
  postStatusError: null,
  selectedChecklistItems: {
    titles: [],
    tasks: [],
  },
  // Filters
  filters: [],
  filtersPaneIsOpen: false,
  // Search
  searchQuery: [],
  searchQueryPending: false,
  globalTaskFolderPending: false,
  globalTaskFolder: [],
  downloadTodoFilesPending: false,
}
