<template>
  <th :class="{ 'fitwidth': fitWidth }">
    <template v-if="header">
      <div class="clickable d-flex" @click="sortTable(columnName)">
        <span class="header">{{ header }}</span>

        <div class="sorting">
          <font-awesome-icon v-show="sorting.name === columnName && sorting.order === 'asc'"
                             :icon="['fad', 'sort-up']"
          ></font-awesome-icon>
          <font-awesome-icon v-show="sorting.name === columnName && sorting.order === 'desc'"
                             :icon="['fad', 'sort-down']"
          ></font-awesome-icon>
        </div>
      </div>
    </template>
  </th>
</template>

<script>
export default {
  name: 'ProjectsTableHeader',
  props: {
    columnName: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      required: false,
    },
    sorting: {
      type: Object,
      validator (obj) {
        const hasNameProperty = obj.name && obj.name.length
        const hasOrderProperty = obj.order && ['asc', 'desc'].includes(obj.order)

        return hasNameProperty && hasOrderProperty
      },
    },
    fitWidth: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    sortTable (columnName) {
      this.$emit('sort', columnName)
    },
  },
}
</script>

<style scoped lang="scss">
th {
  border-bottom: thin solid rgba(0, 0, 0, .12);
  text-align: left;
  padding: 18px 24px 10px;

  &:first-child {
    padding-left: 16px;
  }

  @media (max-width: 1600px) {
    padding-left: 14px;
    padding-right: 14px;
  }
}

.header {
  font-size: 14px;
  text-transform: uppercase;
  color: #333333;
  margin-right: 8px;
  line-height: 16px;
}

.sorting {
  display: flex;
  justify-content: center;
  width: 10px;
}
</style>
