<template>
  <AppDialog :is-open="isDialogOpened"
              size="l"
              @cancel="resetAndClose"
  >
    <template #title>
      <h4>{{ $tc("project.question-answer.QuestionAnswerBulkRights.manageAccessToQuestions", selection.length, { nb: selection.length }) }}</h4>
    </template>

    <template #body>
      <h4 class="text-h4">
        {{ $t("project.question-answer.QuestionAnswerBulkRights.provideAccessTo") }}
      </h4>
      <SaveChecklistDialogShareType without-private-option
                                    :default-share-type="computedShareType"
                                    @change-share-type="computedShareType = $event"
      />
      <!-- ShareType Custom Content -->
      <template v-if="shareType === SHARE_TYPE_OPTIONS.CUSTOM">
        <h4 class="my-4 text-h4">
          {{ $t("project.question-answer.QuestionAnswerBulkRights.participantsWithAccess") }}
        </h4>
        <MembersAutocomplete :items="allRoomUsersExceptPms"
                             :placeholder="$t('project.question-answer.QuestionAnswerBulkRights.searchForParticipantOrGroup')"
                             @select="onMemberSelect"
        />
        <DocumentSharePicker force-pm-selection />
      </template>
    </template>

    <template #footer>
      <div class="d-flex justify-space-between align-center w-100">
        <v-spacer />
        <app-button type="outlined"
                    variant="neutral"
                    :disabled="questionsBulkRightsPending"
                    @click="resetAndClose"
        >
          {{ $t('common.cancel') }}
        </app-button>
        <app-button class="ml-4"
                    :loading="questionsBulkRightsPending"
                    :disabled="!selection.length"
                    @click="prepareQuestionsBulkRights"
        >
          {{ $t('common.register') }}
        </app-button>
      </div>
    </template>
  </AppDialog>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import AppDialog from '@/common/dialogs/AppDialog.vue'
  import DocumentSharePicker from '@/common/document-share/DocumentSharePicker.vue'
  import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes.ts'
  import { dialogMixin } from '@/mixins/dialogMixin'
  import SaveChecklistDialogShareType from '@/project/checklist/dialogs/SaveChecklistDialogShareType.vue'
  import MembersAutocomplete from '@/project/members/MembersAutocomplete.vue'
  import {
    HIGHLIGHT_MEMBER,
    INIT_SELECTED_MEMBERS,
    SELECT_MEMBER,
    INIT_SELECTED_GROUPS,
    RESET_DISABLED_MEMBERS,
    RESET_SELECTED_GROUPS_AND_MEMBERS,
  } from '@/store/modules/document-share-picker/action_types'
  import { QuestionAnswerActionTypes } from '@/store/modules/question-answer/action_types'
  import { GET_SELF_CONTACT_LIST } from '@/store/modules/user/action_types'
  import { ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

  export default {
    name: 'QuestionAnswerBulkRightsDialog',
    mixins: [dialogMixin],
    components: {
      AppDialog,
      SaveChecklistDialogShareType,
      DocumentSharePicker,
      MembersAutocomplete,
    },
    props: {
      selection: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        SHARE_TYPE_OPTIONS,
        shareType: SHARE_TYPE_OPTIONS.CUSTOM,
      }
    },
    computed: {
      ...mapState('questionAnswer', ['questionsBulkRightsPending']),
      ...mapGetters('groups', ['allRoomMembers', 'projectManagers']),
      ...mapState('groups', ['groups']),
      ...mapState('documentSharePicker', ['selectedGroups', 'selectedMembers']),
      ...mapGetters('room', ['roomMnemo']),
      computedShareType: {
        get () {
          return this.shareType
        },
        set (value) {
          this.shareType = value
          if (value === SHARE_TYPE_OPTIONS.PUBLIC) {
            this.INIT_SELECTED_GROUPS([])
            this.INIT_SELECTED_MEMBERS([])
          }
        },
      },
      allRoomUsersExceptPms () {
        return this.allRoomMembers.filter((user) => {
          return !this.projectManagers.some(pm => pm.id === user.id)
        })
      },
    },
    created () {
      this.initPmGroup()
    },
    mounted () {
      this.prepareGetSubscriptionUsers()
    },
    methods: {
      ...mapActions('user', [GET_SELF_CONTACT_LIST]),
      ...mapActions('groups', ['GET_GROUPS']),
      ...mapActions('questionAnswer', [
        QuestionAnswerActionTypes.QUESTIONS_BULK_RIGHTS,
        QuestionAnswerActionTypes.GET_QUESTIONS,
      ]),
      ...mapActions('documentSharePicker', [
        HIGHLIGHT_MEMBER,
        INIT_SELECTED_GROUPS,
        SELECT_MEMBER,
        INIT_SELECTED_MEMBERS,
        RESET_DISABLED_MEMBERS,
        RESET_SELECTED_GROUPS_AND_MEMBERS,
      ]),
      ...mapMutations([ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_ERROR_SNACKBAR]),
      async initPmGroup () {
        await this.GET_GROUPS(this.roomMnemo)
        const pmGroup = this.groups.find(g => g.isPm)
        if (pmGroup) {
          this.INIT_SELECTED_GROUPS([pmGroup])
        }
      },
      async prepareGetSubscriptionUsers () {
        try {
          await this.GET_SELF_CONTACT_LIST({ fromUserSubscriptionOnly: true })
        } catch (error) {
          console.error(error)
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.subscription.errors.getSubscriptionUsersError'))
        }
      },
      onMemberSelect (member) {
        this.SELECT_MEMBER({ member, multiselect: true })
        this.HIGHLIGHT_MEMBER(member)
      },
      async prepareQuestionsBulkRights () {
        try {
          const data = {
            shareType: this.shareType,
            withUsers: this.selectedMembers.map(m => m.id),
            withGroups: this.selectedGroups.map(g => g.id),
            questionsIds: this.selection.map(item => item.id),
          }
          await this.QUESTIONS_BULK_RIGHTS({
            mnemo: this.roomMnemo,
            data,
          })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.question-answer.QuestionAnswerBulkRights.questionsBulkRightsSucess'))
          this.prepareGetQuestions()
          this.resetAndClose()
        } catch (error) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.question-answer.QuestionAnswerBulkRights.questionsBulkRightsError'))
        }
      },
      async prepareGetQuestions () {
        try {
          await this.GET_QUESTIONS(this.roomMnemo)
        } catch (error) {
          console.error(error)
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.views.QuestionAnswerList.getQuestionsError'))
        }
      },
      resetAndClose () {
        this.RESET_DISABLED_MEMBERS()
        this.RESET_SELECTED_GROUPS_AND_MEMBERS()

        this.closeDialog()
      },
    },
  }
</script>
