<template>
  <v-row
    class="ma-0"
    :class="{
      'px-2': $vuetify.breakpoint.mobile,
      'px-14': !$vuetify.breakpoint.mobile,
    }"
  >
    <v-col cols="12" :class="{ 'pl-2 pr-5': $vuetify.breakpoint.mobile }">
      <v-row class="mb-4">
        <h2 class="font-weight-bold text-h2">{{$t('profile.views.Settings.settings')}}</h2>
      </v-row>
      <v-row class="mb-4">
        <h3 class="font-weight-bold text-h3">{{$t('profile.views.Settings.displayHeader')}}</h3>
      </v-row>
      <v-row>
        <AppSelect
          v-model="locale"
          :items="languages"
          :label="$t('profile.views.Settings.language')"
          outlined
          dense
          :loading="updateLanguagePending"
          @change="modifyLanguage"
        ></AppSelect>
      </v-row>
      <v-divider class="mb-10 mt-3" />
      <v-row class="mb-4">
        <h3 class="font-weight-bold text-h3">{{$t('profile.views.Settings.notificationsPanelHeader')}}</h3>
      </v-row>
      <v-row>
        <AppSelect
          v-model="notificationsId"
          :items="notificationsIdItems"
          :label="$t('profile.views.Settings.defaultNotificationsPanelLabel')"
          :loading="currentNotificationPreferencesPending || createNotificationPreferencesPending || deleteNotificationPreferencesPending || updateNotificationPreferencesPending"
        >
        </AppSelect>
        <v-row v-if="notificationsId !== 'default'" no-gutters align="center" class="mb-5">
          <template v-if="noNotificationPreferences">
            <span>{{$t('profile.views.Settings.noNotificationsPreferences')}}</span>
            <v-btn class="ml-2" color="primary" text @click="createCustomPreferences">
              {{$t('profile.views.Settings.createCustomPreferences')}}
            </v-btn>
          </template>
          <template v-else-if="!currentNotificationPreferencesPending">
            <span>{{$t('profile.views.Settings.usesCustomPreferences')}}</span>
            <v-btn class="ml-2" color="primary" text @click="resetCustomPreferences">
              {{$t('profile.views.Settings.resetCustomPreferences')}}
            </v-btn>
          </template>
        </v-row>
      </v-row>
      <v-divider class="mb-10 mt-3" />
      <v-form
        v-if="!noNotificationPreferences"
        :disabled="currentNotificationPreferencesPending || updateNotificationPreferencesPending"
      >
        <v-row>
          <h3 class="font-weight-bold text-h3">{{$t('profile.views.Settings.documentsNotificationsHeader')}}</h3>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['NewSharedDocument']"
            :label="$t('profile.views.Settings.newDocumentAccessNotifyLabel')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['BibleShared']"
            :label="$t('profile.views.Settings.notifyBible')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-divider class="mb-10 mt-3" />
        <v-row>
          <h3 class="font-weight-bold text-h3">{{$t('profile.views.Settings.electronicSignaturesHeader')}}</h3>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['SignatureOriginalAvailable']"
            :label="$t('profile.views.Settings.notifyNewOriginalAvailable')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['SignatureOriginalShared']"
            :label="$t('profile.views.Settings.notifyOriginalShared')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['EnvelopeValidationChanged']"
            :label="$t('profile.views.Settings.notifyEnvelopeValidationChanged')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-divider class="mb-10 mt-3" />
        <v-row>
          <h3 class="font-weight-bold text-h3">{{$t('profile.views.Settings.todolistHeader')}}</h3>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['UserCommentNotifyUser']"
            :label="$t('profile.views.Settings.notifyUserCommentTodolist')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['AgendaStepCreatedNotifyTaggedUser']"
            :label="$t('profile.views.Settings.notifyTodolistResponsible')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['AgendaStepCreatedNotifyTaggedGroup']"
            :label="$t('profile.views.Settings.notifyTodolistGroupResponsible')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['AgendaStepCreatedNotifyTaggedValidatorUser']"
            :label="$t('profile.views.Settings.notifyAgendaStepCreatedNotifyTaggedValidatorUser')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['AgendaStepCreatedNotifyTaggedValidatorGroup']"
            :label="$t('profile.views.Settings.notifyAgendaStepCreatedNotifyTaggedValidatorGroup')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['UserCommentNotifyTaggedUser']"
            :label="$t('profile.views.Settings.notifyUserCommentNotifyTaggedUser')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['UserCommentNotifyTaggedGroup']"
            :label="$t('profile.views.Settings.notifyUserCommentNotifyTaggedGroup')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['AgendaStepUpdateStatus']"
            :label="$t('profile.views.Settings.notifyAgendaStepUpdateStatus')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-divider class="mb-10 mt-3" />
        <v-row>
          <h3 class="font-weight-bold text-h3">{{$t('profile.views.Settings.questionAnswerHeader')}}</h3>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['QAAnswerAdded']"
            :label="$t('profile.views.Settings.notifyQAAnswerAdded')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['QAQuestionShared']"
            :label="$t('profile.views.Settings.notifyQAQuestionShared')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-radio-group
            v-model="notificationPreferences['QAQuestionValidated']"
            :label="$t('profile.views.Settings.notifyQAQuestionValidated')"
            @change="updatePreferences"
          >
            <v-radio :label="$t('profile.views.Settings.notifyImmediately')" value="gradually"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryMorning')" value="morning"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyEveryEvening')" value="evening"></v-radio>
            <v-radio :label="$t('profile.views.Settings.notifyDisable')" value="never"></v-radio>
          </v-radio-group>
        </v-row>
        <v-divider class="mb-10 mt-3" />
        <v-row>
          <v-switch
            v-model="turnOffNotif"
            class="mt-0"
            @click="turnOffNotif ? prepareShowTurnOffNotifDialog(true) : prepareDisableNotification(true) "
          >
            <template #label>
              <span class="text--primary">
                {{ $t("profile.views.Settings.disableAllNotification") }}
              </span>
            </template>
          </v-switch>
          <AppDialog :is-open="showTurnOffNotifDialog"
                     size="m"
                     @ok="prepareDisableNotification(false)"
                     @cancel="prepareShowTurnOffNotifDialog(false)"
          >
            <template #title>{{ $t('profile.views.Settings.disableNotificationDialog.title') }}</template>
            <template #body>{{ $t('profile.views.Settings.disableNotificationDialog.body') }}</template>
          </AppDialog>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppSelect from '../../common/AppSelect.vue'
import AppDialog from '../../common/dialogs/AppDialog'
import { CREATE_NOTIFICATION_PREFERENCES, DELETE_NOTIFICATION_PREFERENCES, GET_CURRENT_NOTIFICATION_PREFERENCES, UPDATE_LANGUAGE, UPDATE_NOTIFICATION_PREFERENCES, UPDATE_NOTIFICATION_MUTE_EMAILS } from '../../store/modules/user/action_types'

export default {
  name: 'Settings',
  components: { AppDialog, AppSelect },
  data () {
    return {
      locale: '',
      // NOTE: The texts are hard coded on purpose, since they don't need to use the translator
      // We always display the translation for the corresponding language and it never changes
      languages: [
        {
          text: 'Français',
          value: 'fr',
        },
        {
          text: 'English',
          value: 'en',
        },
        {
          text: 'Deutsch',
          value: 'de',
        },
      ],
      notificationsId: 'default',
      notificationPreferences: {},
      turnOffNotif: false,
      showTurnOffNotifDialog: false,
    }
  },
  computed: {
    ...mapState('user', [
      'profile',
      'updateLanguagePending',
      'updateNotificationPreferencesPending',
      'currentNotificationPreferencesPending',
      'currentNotificationPreferences',
      'createNotificationPreferencesPending',
      'deleteNotificationPreferencesPending',
    ]),
    ...mapState('rooms', ['rooms']),
    notificationsIdItems () {
      const defaultSettingsObj = {
        value: 'default',
        text: this.$t('profile.views.Settings.defaultNotificationsPanelLabel'),
      }
      if (this.rooms) {
        const roomItems = this.rooms.map((room) => ({
          value: room.id,
          text: room.name,
        }))
        return [
          defaultSettingsObj,
          ...roomItems,
        ]
      } else {
        return [
          defaultSettingsObj,
        ]
      }
    },
    noNotificationPreferences () {
      return Object.keys(this.currentNotificationPreferences).length === 0
    },
  },
  watch: {
    notificationsId: {
      async handler (newValue) {
        await this.GET_CURRENT_NOTIFICATION_PREFERENCES(newValue)
        this.notificationPreferences = { ...this.currentNotificationPreferences.preferences }
        this.turnOffNotif = this.currentNotificationPreferences.muteEmails === 1
      },
      immediate: true,
    },
  },
  created () {
    this.getRooms({ mini: true })
    this.locale = this.profile.locale
  },
  methods: {
    ...mapActions('user', [
      UPDATE_LANGUAGE,
      UPDATE_NOTIFICATION_PREFERENCES,
      UPDATE_NOTIFICATION_MUTE_EMAILS,
      CREATE_NOTIFICATION_PREFERENCES,
      DELETE_NOTIFICATION_PREFERENCES,
      GET_CURRENT_NOTIFICATION_PREFERENCES,
    ]),
    ...mapActions('rooms', ['getRooms']),
    async modifyLanguage () {
      await this.UPDATE_LANGUAGE(this.locale)
      this.$root.$i18n.locale = this.locale
      await this.$nextTick()
      this.$store.commit('enqueueSnackbar', {
        color: 'success',
        message: this.$t('profile.views.Settings.langModified'),
      })
    },
    async updatePreferences () {
      await this.UPDATE_NOTIFICATION_PREFERENCES({
        preferences: this.notificationPreferences,
        id: this.notificationsId,
      })
      this.$store.commit('enqueueSnackbar', {
        color: 'success',
        message: this.$t('profile.views.Settings.updateNotificationPreferencesSuccess'),
      })
    },
    async createCustomPreferences () {
      await this.CREATE_NOTIFICATION_PREFERENCES(this.notificationsId)
      this.notificationPreferences = this.currentNotificationPreferences
    },
    async resetCustomPreferences () {
      this.DELETE_NOTIFICATION_PREFERENCES(this.notificationsId)
    },
    async prepareDisableNotification (allow) {
      if (allow) {
        // on autorise les notif
        this.turnOffNotif = false
      } else {
        // on bloque les notif
        this.turnOffNotif = true
        this.showTurnOffNotifDialog = false
      }
      await this.UPDATE_NOTIFICATION_MUTE_EMAILS({
        muteEmails: this.turnOffNotif ? 1 : 0, // Convert true/false to 0/1
      })
      this.$store.commit('enqueueSnackbar', {
        color: 'success',
        message: this.$t('profile.views.Settings.updateNotificationPreferencesSuccess'),
      })
    },
    prepareShowTurnOffNotifDialog (display) {
      this.showTurnOffNotifDialog = display
      if (!display) {
        this.turnOffNotif = display
      }
    },
  },
}
</script>
