<template>
<AppDialog :is-open="isDialogOpened"
           :okText="$t('common.app-file-upload.CancelAllFilesDialog.confirmText')"
           :cancelText="$t('common.app-file-upload.CancelAllFilesDialog.cancelText')"
           @ok="onOk"
           @cancel="closeDialog"
>
  <template #title>{{ $t('common.app-file-upload.CancelAllFilesDialog.title') }}</template>
  <template #body>{{ $t('common.app-file-upload.CancelAllFilesDialog.text') }}</template>
</AppDialog>
</template>

<script>
import { mapActions } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'
export default {
  name: 'CancelAllFilesDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  methods: {
    ...mapActions('appUploadHandler', ['cancelAll']),
    onOk () {
      this.cancelAll()
      this.closeDialog()
    },
  },
}
</script>
