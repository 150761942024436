<template>
  <AppDialog :is-open="isDialogOpen"
             :ok-text="$t('common.move')"
             :ok-loading="isPending"
             :ok-disabled="!selectedFolder.length"
             size="l"
             @ok="isRoom ? prepareMoveRoom() : prepareMoveProjectsFolder()"
             @cancel="close"
  >
    <template #title>
      <div class="font-weight-bold">
        <template v-if="isRoom">
          {{ $t('projects.dialogs.ProjectsMoveRoomDialog.moveRoom', { name: roomToMove.name }) }}
        </template>
        <template v-else>
          {{ $t('projects.dialogs.ProjectsFoldersMoveFolderDialog.moveFolder', { name: projectsFolderToMove.name }) }}
        </template>
      </div>
    </template>
    <template #body>
      <div v-if="getProjectsFoldersTreePending && !projectsFoldersTree.length" class="text-center">
        <AppLoader small/>
      </div>
      <template v-else>
        <div class="mb-4">{{ $t('projects.dialogs.ProjectsMoveRoomDialog.selectDestinationFolder') }}</div>
        <AppFildersTree v-model="selectedFolder"
                        :root-folder="fullTree"
                        openFirstNode
                        isProjectsFolders
        >
          <template #prepend="{ item, open, folderStyles }">
              <app-icon v-if="item.icon"
                        :icon-name="item.icon"
              />
              <app-icon v-else
                        :icon-name="open ? 'folder-open' : 'folder'"
                        icon-weight="fad"
                        :style="!item.locked ? folderStyles : ''"
              />
          </template>
        </AppFildersTree>
      </template>
    </template>
  </AppDialog>
</template>

<script>
  import { cloneDeep } from 'lodash-es'
  import { mapState, mapActions, mapMutations } from 'vuex'

  import AppLoader from '@/common/AppLoader.vue'
  import AppFildersTree from '@/common/AppVuetifyFildersTree.vue'
  import { FOLDER_MAX_DEPTH } from '@/common/constants'
  import AppDialog from '@/common/dialogs/AppDialog.vue'
  import projectsFoldersMixin from '@/mixins/projectsFoldersMixin'
  import { MOVE_PROJECTS_FOLDER } from '@/store/modules/projects-folders-move/action_types'
  import {
    SET_PROJECTS_FOLDER_MOVE_DIALOG_IS_OPEN,
    SET_PROJECTS_FOLDER_TO_MOVE,
  } from '@/store/modules/projects-folders-move/mutation_types'
  import { MOVE_ROOM } from '@/store/modules/room/action_types'
  import {
    SET_MOVE_ROOM_DIALOG_IS_OPEN,
    SET_ROOM_TO_MOVE,
  } from '@/store/modules/room/mutation_types'
  import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

  export default {
    name: 'ProjectsMoveRoomDialog',
    components: { AppDialog, AppLoader, AppFildersTree },
    mixins: [projectsFoldersMixin],
    props: {
      // Is Room or Folder
      isRoom: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        selectedFolder: [],
        FOLDER_MAX_DEPTH,
      }
    },
    computed: {
      ...mapState('rooms', ['rooms']),
      ...mapState('projectsFolders', ['projectsFoldersTree', 'getProjectsFoldersTreePending']),
      ...mapState('room', ['moveRoomDialogIsOpen', 'moveRoomPending', 'roomToMove']),
      ...mapState('projectsFoldersMove', ['projectsFolderMovePending', 'projectsFolderMoveDialogIsOpen', 'projectsFolderToMove']),
      isDialogOpen () {
        return this.isRoom ? this.moveRoomDialogIsOpen : this.projectsFolderMoveDialogIsOpen
      },
      isPending () {
        return this.isRoom ? this.moveRoomPending : this.projectsFolderMovePending
      },
      fullTree () {
        return {
          id: 0,
          icon: 'rectangle-history',
          unSelectable: this.isRootFolderUnSelectable,
          name: this.$t('projects.ProjectsHeader.myProjects'),
          children: this.projectsFoldersTreeMapping,
        }
      },
      isRootFolderUnSelectable () {
        return this.projectsFolderToMove.parentId === null || this.rooms.map(item => item.id).includes(this.roomToMove.id)
      },
      projectsFoldersTreeMapping () {
        const datas = cloneDeep(this.projectsFoldersTree)

        datas.forEach(folder => {
          // Disable selection of the folder to be moved
          folder.locked = (folder.id === this.projectsFolderToMove.id)
          // Makes folder unselectable, but can select its children
          folder.unSelectable = (folder.id === this.projectsFolderToMove.parentId || folder.rooms.map(item => item.id).includes(this.roomToMove.id))

          if (folder.children.length !== 0) {
            folder.children.map(this.setChildrenLockedProp)
          } else {
            delete folder.children // To avoid displaying the tooggle icon in front of the folder name
          }
        })

        return datas
      },
    },
    methods: {
      ...mapMutations('room', [SET_MOVE_ROOM_DIALOG_IS_OPEN, SET_ROOM_TO_MOVE]),
      ...mapActions('room', [MOVE_ROOM]),
      ...mapActions('projectsFoldersMove', [MOVE_PROJECTS_FOLDER]),
      ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
      ...mapMutations('projectsFoldersMove', [SET_PROJECTS_FOLDER_MOVE_DIALOG_IS_OPEN, SET_PROJECTS_FOLDER_TO_MOVE]),
      setChildrenLockedProp (item) {
        // Only locked when is a folder, because projects can be moved to any folder
        if (this.projectsFolderToMove.id) {
          if (item.id === this.projectsFolderToMove.id ||
              item.depth === FOLDER_MAX_DEPTH) { // siblings that are leaves cannot be moved one inside the other
            item.locked = true
          }
        }
        if (item.children?.length) {
          item.children = item.children.map(this.setChildrenLockedProp)
        } else {
          delete item.children
        }
        return item
      },
      async prepareMoveRoom () {
        if (!this.roomToMove.id) return
        try {
          const { name, mnemo } = this.roomToMove
          await this.MOVE_ROOM({
            toFolderId: this.selectedFolder[0].id,
            roomMnemo: mnemo,
          })

          this.updateCurrentAndDestinationFolders(true)

          this.SET_MOVE_ROOM_DIALOG_IS_OPEN(false)

          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('projects.dialogs.ProjectsMoveRoomDialog.moveRoomSuccess', {
            projectName: name,
            folderName: this.selectedFolder[0].name,
          }))
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.dialogs.ProjectsMoveRoomDialog.moveRoomError'))
        }
      },
      async prepareMoveProjectsFolder () {
        if (!this.projectsFolderToMove.id) return

        const { name } = this.projectsFolderToMove
        try {
          await this.MOVE_PROJECTS_FOLDER({
            projectsFolderToMoveId: this.projectsFolderToMove.id,
            toFolderId: this.selectedFolder[0].id,
          })

          this.updateCurrentAndDestinationFolders()

          this.SET_PROJECTS_FOLDER_MOVE_DIALOG_IS_OPEN(false)

          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('projects.dialogs.ProjectsFoldersMoveFolderDialog.moveFolderSuccess', {
            folderName: name,
            destinationFolderName: this.selectedFolder[0].name,
          }))
        } catch (e) {
          if (e.response?.data?.errorCode === 'TOO_MANY_PARENT') {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.dialogs.ProjectsFoldersMoveFolderDialog.moveFolderErrorTooManyParent', {
              folderName: name,
            }))
          } else {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.dialogs.ProjectsFoldersMoveFolderDialog.moveFolderError'))
          }
        }
      },
      close () {
        if (this.isRoom) {
          this.SET_MOVE_ROOM_DIALOG_IS_OPEN(false)
          this.SET_ROOM_TO_MOVE({})
        } else {
          this.SET_PROJECTS_FOLDER_MOVE_DIALOG_IS_OPEN(false)
          this.SET_PROJECTS_FOLDER_TO_MOVE({})
        }
      },
    },
  }
</script>
