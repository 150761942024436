<template>
  <component :is="isQualified ? 'SignersOrderQualifiedDialog' : 'SignersOrderDialog'"
             v-if="isDialogOpen"
             :signers="signers"
             @close="onClose"
             @reset-signers-order="onResetSignersOrder"
             @patch-signers-order="patchSignersOrder"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { SIGNATURE_TYPE_DOCUSIGN_QUALIFIED } from '@/project/signing-checklist/constants'
import SignersOrderDialog from '@/project/signing-checklist/signer-order/SignersOrderDialog.vue'
import SignersOrderQualifiedDialog from '@/project/signing-checklist/signer-order/SignersOrderQualifiedDialog.vue'
import { GET_TAGGING_INFORMATION, PATCH_SIGNERS_ORDER } from '@/store/modules/prepareSignature/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'PrepareSignatureSignersOrderDialog',
  components: { SignersOrderQualifiedDialog, SignersOrderDialog },
  props: {
    envelopeId: {
      type: Number,
      required: true,
    },
    signatureType: {
      type: String,
      required: true,
    },
    signers: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isDialogOpen: false,
      isReseting: false,
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    isQualified () {
      return this.signatureType === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED
    },
    successMessage () {
      return this.isReseting ? 'successResetText' : 'successText'
    },
  },
  mounted () {
    this.isDialogOpen = true
  },
  methods: {
    ...mapActions('prepareSignature', [PATCH_SIGNERS_ORDER, GET_TAGGING_INFORMATION]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    onClose () {
      this.isDialogOpen = false
      this.$emit('close')
    },
    onResetSignersOrder () {
      this.isReseting = true
      this.patchSignersOrder([this.signers.flat()])
    },
    async patchSignersOrder (groups) {
      try {
        await this.PATCH_SIGNERS_ORDER({
          mnemo: this.roomMnemo,
          envId: this.envelopeId,
          data: {
            enforceOrder: true,
            signers: groups,
          },
        })

        this.$set(this.step.objectToPost, 'signers', groups)

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t(`project.signing-checklist.prepare-signature.PrepareSignatureSignersOrderDialog.patchSignersOrder.${this.successMessage}`))
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureSignersOrderDialog.patchSignersOrder.errorText'))
      }
      try {
        await this.GET_TAGGING_INFORMATION({
          mnemo: this.roomMnemo,
          envId: this.envelopeId,
        })
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
