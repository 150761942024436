<template>
    <v-card v-bind="$attrs"
            class="AppCard"
    >
      <!-- @slot for card content -->
      <slot></slot>
    </v-card>
</template>

<script>
export default {
  /*
    * All Vuetify props for Card component are usable
    * See doc : https://vuetifyjs.com/en/api/v-card/#props
    */
  name: 'AppCard',
  inheritAttrs: false,
}
</script>

<style scoped lang="scss">
.AppCard {
  padding: 15px 20px;
}
</style>

<docs>
  ```vue
  <template>
    <AppCard>
      <v-card-title>Card Title</v-card-title>
      <v-card-subtitle>Card subtitle</v-card-subtitle>
      <v-card-text>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam atque corporis, deleniti dignissimos error explicabo id, in laudantium magni nam necessitatibus nemo numquam quibusdam quos veniam vitae? Fugiat, unde.</v-card-text>
    </AppCard>
  </template>
  ```
</docs>
