<template>
  <v-row class="IdentityCheckingIDFileInput">
    <v-col cols="auto" sm="auto" class="d-flex align-center">
      <!-- Choose file -->
      <app-button :id="describedby"
                  class="mr-2"
                  :disabled="disableFileInput"
                  type="outlined"
                  variant="neutral"
                  @click="openFileInput"
      >
        {{ $t('common.identity-checking.IdentityCheckingID.importDocument.chooseFile') }}
      </app-button>
      <AppFileInput :ref="refValue"
                    v-model="file"
                    class="d-none"
                    :aria-describedby="describedby"
                    :accept="allowedExtensions"
                    :error-messages="errors"
                    prepend-icon=""
      />
    </v-col>
    <v-col cols="auto" sm="auto" class="d-flex align-center">
      <!-- File name -->
      <div v-if="file"
            :class="{ 'file-control-errors': isDocumentErrored, 'disabled-file-input': disableFileInput }"
            class="imported-file text-truncate"
      >
        <div class="text-truncate">{{ file.name }}</div>
        <app-button class="mx-2"
                    :disabled="disableFileInput"
                    type="icon"
                    @click="file = null"
        >
          <app-icon icon-name="times" size="medium"></app-icon>
        </app-button>
      </div>
      <!-- No file -->
      <div v-else :class="{ 'disabled-file-input': disableFileInput }"
                  class="text-muted"
      >
        {{ $t('common.identity-checking.IdentityCheckingID.importDocument.noFilesSelected') }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import AppFileInput from '@/common/AppFileInput.vue'

  export default {
    name: 'IdentityCheckingIDFileInput',
    components: { AppFileInput },
    props: {
      refValue: {
        type: String,
        required: true,
      },
      describedby: {
        type: String,
        required: true,
      },
      allowedExtensions: {
        required: true,
        validator: (value) => {
          return typeof value === 'string' || typeof value === 'object'
        },
      },
      existingFile: {
        type: File,
        default: () => {},
      },
      disableFileInput: {
        type: Boolean,
        default: false,
      },
      isDocumentErrored: {
        type: Boolean,
        default: false,
      },
      errors: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        file: null,
      }
    },
    watch: {
      file (value) {
        this.$emit('update-file', value)
      },
    },
    created () {
      if (this.existingFile) {
        this.file = this.existingFile
      }
    },
    methods: {
      openFileInput () {
        this.$refs[this.refValue].$refs.fileInput.$refs.input.click()
      },
    },
  }
</script>

<style scoped lang="scss">
  .IdentityCheckingIDFileInput {
    .imported-file {
      display: inline-flex;
      align-items: center;
      padding-left: 16px;
      border-radius: 4px;
      border: 1px solid var(--v-grey-lighten4);
      background: var(--v-originalGrey-lighten4);
    }

    .file-control-errors {
      border-color: var(--v-error-base);
    }

    .disabled-file-input {
      pointer-events: none;
      color: var(--v-grey-lighten3);
    }

    .v-btn.v-size--default {
      font-size: 14px;
      border-color: var(--v-grey-lighten4);
    }
  }
</style>
