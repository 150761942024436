<template>
  <div class="envelope-template-creation-form-actions">
    <AppButton type="outlined"
               variant="neutral"
               @click="onCancel"
    >
      {{ $t("common.cancel") }}
    </AppButton>

    <template v-if="currentStep === TemplateCreationSteps.DOCUMENTS_SELECTION">
      <div class="flex-grow-1" />

      <AppButton :disabled="!canValidateCurrentStep" @click="onNext"
      >
        {{ this.$t('common.next') }}
      </AppButton>
    </template>

    <template v-if="currentStep === TemplateCreationSteps.TEMPLATE_SETTINGS" >
      <AppButton :disabled="formMode === 'edition'"
                 @click="onPrevious"
      >
        {{ $t("common.previous") }}
      </AppButton>

      <div class="flex-grow-1" />

      <AppButton :disabled="!canValidateCurrentStep"
                 :loading="postEnvelopeTemplatePending"
                 type="outlined"
                 variant="neutral"
                 @click="onSaveAndExit"
      >
        {{ $t("common.saveAndExit") }}
      </AppButton>

      <AppButton :disabled="!canValidateCurrentStep"
                 :loading="postEnvelopeTemplatePending"
                 @click="onNext"
      >
        {{ this.$t('common.next') }}
      </AppButton>
    </template>

    <template v-if="currentStep === TemplateCreationSteps.SIGNATURE_TAGS" >
      <AppButton v-if="envelopeTemplatesToPrepareIndex === 0"
                 @click="onPrevious"
      >
        {{ $t("common.previous") }}
      </AppButton>
      <AppButton v-else
                 type="outlined"
                 variant="neutral"
                 @click="onPrevious"
      >
        <font-awesome-icon :icon="['far', 'long-arrow-left']"
                           class="mr-2"
        ></font-awesome-icon>
        {{ $t("common.previous") }}
      </AppButton>

      <div class="flex-grow-1" />

      <AppButton v-if="!isPreparationFinished"
                 type="outlined"
                 variant="neutral"
                 @click="onNext"
      >
        {{ $t("common.next") }}
        <font-awesome-icon :icon="['far', 'long-arrow-right']"
                           class="ml-2"
        ></font-awesome-icon>
      </AppButton>
      <AppButton v-else @click="onNext"
      >
        {{ this.$t('common.register') }}
      </AppButton>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

import { type FormMode } from '@/common/utils/form'
import { typedMapActions } from '@/common/utils/store'
import AppButton from '@/design-system/buttons/AppButton.vue'
import type { EnvelopeTemplateToPrepare } from '@/project/views/EnvelopeTemplateAdd.vue'
import { TemplateCreationSteps } from '@/project/views/EnvelopeTemplateAdd.vue'
import { ENVELOPE_TEMPLATE_UPDATE_ROUTE_NAME } from '@/router'
import type { EnvelopeTemplatesActionType } from '@/store/modules/envelope-templates/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'EnvelopeTemplateAddFormActions',
  components: { AppButton },
  props: {
    currentStep: {
      type: Number as PropType<TemplateCreationSteps>,
      required: true,
    },
    envelopeTemplatesToPrepare: {
      type: Array as PropType<Array<EnvelopeTemplateToPrepare>>,
      required: true,
    },
    envelopeTemplatesToPrepareIndex: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    formMode: {
      type: String as PropType<FormMode>,
      default: 'creation',
    },
  },
  computed: {
    ...mapGetters('signingChecklist', ['queuedDocuments']),
    ...mapState('envelopeTemplates', ['envelopeTemplateDrafts', 'postEnvelopeTemplatePending']),
    TemplateCreationSteps () { return TemplateCreationSteps },
    canValidateCurrentStep (): boolean {
      if (this.currentStep === TemplateCreationSteps.DOCUMENTS_SELECTION) {
        return this.envelopeTemplateDrafts.length > 0 && this.queuedDocuments.length === 0
      }
      if (this.currentStep === TemplateCreationSteps.TEMPLATE_SETTINGS) {
        return !this.form.$invalid
      }
      if (this.currentStep === TemplateCreationSteps.SIGNATURE_TAGS) {
        return true
      }

      return false
    },
    isPreparationFinished () {
      if (this.envelopeTemplatesToPrepareIndex === (this.envelopeTemplatesToPrepare.length - 1)) return true

      return false
    },
    envelopeTemplateToPrepare (): EnvelopeTemplateToPrepare {
      return this.envelopeTemplatesToPrepare[this.envelopeTemplatesToPrepareIndex]
    },
  },
  methods: {
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    ...typedMapActions<EnvelopeTemplatesActionType>('envelopeTemplates', ['UPDATE_DRAFT_TEMPLATE_DATA']),
    onCancel () {
      this.$emit('cancel')
    },
    onNext () {
      if (this.currentStep === TemplateCreationSteps.DOCUMENTS_SELECTION) {
        this.$emit('next')
      }

      if (this.currentStep === TemplateCreationSteps.TEMPLATE_SETTINGS) {
        this.$emit('save-and-next')
      }

      if (this.currentStep === TemplateCreationSteps.SIGNATURE_TAGS) {
        if (this.isPreparationFinished) {
          this.$emit('exit')
        } else {
          this.$emit('next-document')
        }
      }
    },
    async onPrevious () {
      if (this.currentStep === TemplateCreationSteps.SIGNATURE_TAGS) {
        try {
          await this.UPDATE_DRAFT_TEMPLATE_DATA(this.envelopeTemplateToPrepare.id)

          // redirect to form in edition mode
          if (this.formMode === 'creation') {
            await this.$router.push({
                name: ENVELOPE_TEMPLATE_UPDATE_ROUTE_NAME,
                query: { envelopeTemplateId: this.envelopeTemplateToPrepare.id.toString() },
            })
          }
        } catch (error) {
          console.error(error)
          this.ENQUEUE_ERROR_SNACKBAR(
            this.$t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddFormActions.updateDraftError'),
          )
        }

        if (this.envelopeTemplatesToPrepareIndex !== 0) {
          this.$emit('previous-document')
          return
        }
      }

      this.$emit('previous')
    },
    onSaveAndExit () {
      this.$emit('save-and-exit')
    },
  },
})
</script>

<style scoped lang="scss">
.envelope-template-creation-form-actions {
  column-gap: 16px;
  display: flex;
  padding: 16px 0;
}
</style>
