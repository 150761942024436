<template>
  <li class="feature">
    <font-awesome-icon :icon="['far', icon]"
                       :class="['feature__icon', iconClass]"
    />
    <h3 class="text-h3">{{ title }}</h3>
    <p>{{ description }}</p>
  </li>
</template>

<script>
export default {
  name: 'ProjectTypeFeature',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.feature {
  display: grid;
  grid-template-columns: 25px auto;
  grid-row-gap: 8px;
  grid-column-gap: 16px;

  &__icon {
    grid-row-end: span 2;
    width: 25px;
    height: 25px;
  }
}
</style>
