import { render, staticRenderFns } from "./QuestionAnswerItemThreadQuestion.vue?vue&type=template&id=7e9ad7e5&scoped=true"
import script from "./QuestionAnswerItemThreadQuestion.vue?vue&type=script&lang=js"
export * from "./QuestionAnswerItemThreadQuestion.vue?vue&type=script&lang=js"
import style0 from "./QuestionAnswerItemThreadQuestion.vue?vue&type=style&index=0&id=7e9ad7e5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9ad7e5",
  null
  
)

export default component.exports