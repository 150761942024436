import store from '../store'
let fileKey = 0
/**
 * Represents a File to upload.
 * @constructor
 * @param {File} file - File to upload.
 * @param {String} uploadType - type of file to upload.
 * @param {Number} parentId - File parent id'.
 */
export const FileToUpload = function (file, uploadType, parentId = null) {
  this.type = 'file'
  this.uploadType = uploadType
  this.fileObject = file
  this.progress = 0
  this.status = 'pending'
  this.parentId = parentId
  this.roomMnemo = store.getters['room/roomMnemo']
  this.cancelToken = null
  this.key = fileKey
  fileKey++
}

/**
 * Represents a Folder to upload.
 * @constructor
 * @param {String} name - Folder name.
 * @param {Array} children - Folder children.
 * @param {Number} parentId - Folder parent id'.
 */
export const FolderToUpload = function (name, children, parentId = null) {
  this.name = name
  this.type = 'folder'
  this.uploadType = 'document'
  this.children = children
  this.parentId = parentId
  this.roomMnemo = store.getters['room/roomMnemo']
}
