<template>
  <div class="DocumentsActionBarSelectionLabel">
    <AppTooltip top>
      <template #activator="{attrs, on}">
        <AppButton
          icon
          v-bind="attrs"
          @click="onCancelSelection"
          v-on="on"
        >
          <font-awesome-icon :icon="['fal', 'xmark']" size="lg"/>
        </AppButton>
      </template>
      {{$t('common.cancelSelection')}}
    </AppTooltip>
    <app-text is="span" variant="small-bold">
      {{ $tc('common.selectedItems', totSelectedDocuments, { count : totSelectedDocuments }) }}
    </app-text>
  </div>
</template>

<script>
import AppTooltip from '@/common/AppTooltip.vue'
import AppButton from '@/common/buttons/AppButton'

export default {
  name: 'DocumentsActionBarSelectionLabel',
  components: {
    AppButton,
    AppTooltip,
  },
  props: {
   totSelectedDocuments: {
     type: Number,
     required: true,
   },
  },
  methods: {
    onCancelSelection () {
        this.$emit('cancel-selection')
    },
  },
}
</script>

<style lang="scss" scoped>
.DocumentsActionBarSelectionLabel {
  align-items: center;
  column-gap: 8px;
  display: flex;
  white-space: nowrap;
}
</style>
