<template>
  <AppDialog :is-open="isDialogOpened"
              size="l"
              :ok-text="$t('common.register')"
              :ok-loading="shareSavedChecklistPending"
              :ok-disabled="shareSavedChecklistPending"
              :cancel-disabled="shareSavedChecklistPending"
              @ok="prepareShareSavedChecklist"
              @cancel="closeDialog"
  >
    <template #title>
      <app-text variant="large-bold">
        {{ $t('project.checklist.dialogs.ImportChecklistDialog.accessRights.share') }} {{ `“${checklist.name}”` }}
      </app-text>
    </template>
    <template #body>
      <SaveChecklistShare is-saved-checklist-share-update
                          :opened-checklist="checklist"
                          @change-share-type="shareType = $event"
                          @set-selected-users="selectedUsers = $event"
                          @set-send-notif="sendNotif = $event"
                          @set-notif-message="notifyMessage = $event"
      />
    </template>
  </AppDialog>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import AppDialog from '@/common/dialogs/AppDialog'
  import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes.ts'
  import { dialogMixin } from '@/mixins/dialogMixin'
  import SaveChecklistShare from '@/project/checklist/dialogs/SaveChecklistShare.vue'
  import { SHARE_SAVED_CHECKLIST } from '@/store/modules/checklist/action_types'
  import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

  export default {
    name: 'SaveChecklistShareDialog',
    components: {
      AppDialog,
      SaveChecklistShare,
    },
    mixins: [dialogMixin],
    props: {
      checklist: {
        required: true,
        validator: (value) => {
          return value === null || typeof value === 'object'
        },
      },
    },
    data () {
      return {
        shareType: SHARE_TYPE_OPTIONS.PUBLIC,
        selectedUsers: [],
        sendNotif: false,
        notifyMessage: '',
      }
    },
    computed: {
      ...mapState('checklist', ['shareSavedChecklistPending']),
      shareTypeIsPublic () {
        return this.shareType === SHARE_TYPE_OPTIONS.PUBLIC
      },
      shareTypeIsCustom () {
        return this.shareType === SHARE_TYPE_OPTIONS.CUSTOM
      },
      shareTypeIsPrivate () {
        return this.shareType === SHARE_TYPE_OPTIONS.PRIVATE
      },
    },
    methods: {
      ...mapActions('checklist', [SHARE_SAVED_CHECKLIST]),
      async prepareShareSavedChecklist () {
        try {
          if (this.shareTypeIsPublic || this.shareTypeIsPrivate) {
            this.selectedUsers = []
          }

          if (this.shareTypeIsCustom && this.selectedUsers.length === 0) {
            this.shareType = SHARE_TYPE_OPTIONS.PRIVATE
          }

          await this.SHARE_SAVED_CHECKLIST({
            todoListId: this.checklist.id,
            data: {
              shareType: this.shareType,
              withUsers: this.selectedUsers,
              sendNotif: !this.shareTypeIsPrivate && this.sendNotif,
              notifyMessage: this.notifyMessage,
            },
          })
          this.checklist.shareType = this.shareType
          this.checklist.rights = this.selectedUsers

          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.checklist.dialogs.SaveChecklistShareDialog.shareSavedChecklistSuccess'),
          })
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.dialogs.SaveChecklistShareDialog.shareSavedChecklistError'),
          })
        } finally {
          this.closeDialog()
        }
      },
    },
  }
</script>
