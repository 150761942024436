<template>
  <div>
    <div class="body-1 font-weight-bold pb-5 ml-1">
      {{ $t('project.project-settings.ProjectSettingsInformation.title') }}
    </div>
    <AppCard class="pa-8 ma-1">
      <template v-if="roomStats === null">
        <v-row class="{my-4 : $vuetify.breakpoint.xsOnly}">
          <v-col cols="12" md="3">
            <v-skeleton-loader type="text@3"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader type="text@3"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader type="text@3"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader type="text@3"></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row class="{my-4 : $vuetify.breakpoint.xsOnly}">
          <v-col cols="12" md="3">
            <div>
              <font-awesome-icon :icon="['far', 'list-alt']"/>
              <span class="font-weight-bold pl-4">{{ $t('project.project-settings.ProjectSettingsInformation.additionalInformation') }}</span>
            </div>
            <v-container class="px-0">
              <v-row no-gutters class="mb-4">
                <v-col>{{ $t('project.project-settings.ProjectSettingsInformation.createdAt') }}</v-col>
                <v-col class="pl-5">
                  <DateDisplay :value="currentRoom.createdAt"/>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>{{ $t('project.project-settings.ProjectSettingsInformation.duration') }}</v-col>
                <v-col class="pl-5">
                  {{ handleISOToRelativeTime(currentRoom.createdAt) }}
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" md="3"
                 :class="{dividerH : $vuetify.breakpoint.smAndDown, dividerV : $vuetify.breakpoint.mdAndUp}">
            <div>
              <font-awesome-icon :icon="['far', 'file-alt']"/>
              <span class="font-weight-bold pl-4">{{ $t('project.project-settings.ProjectSettingsInformation.documents') }}</span>
            </div>
            <v-container class="px-0">
              <v-row v-if="files" no-gutters class="mb-4">
                <v-col>{{ $t('project.project-settings.ProjectSettingsInformation.documentsCountText') }}</v-col>
                <v-col class="pl-5">
                  <span class="font-weight-bold">
                  {{
                      $tc('project.project-settings.ProjectSettingsInformation.documentsCount',
                        files,
                        { count: files },
                      )
                  }}
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>{{ $t('project.project-settings.ProjectSettingsInformation.archivedDocumentsCountText') }}</v-col>
                <v-col class="pl-5">
                  <span class="font-weight-bold">
                    {{
                      $tc('project.project-settings.ProjectSettingsInformation.documentsCount',
                        archivedDocuments,
                        { count: archivedDocuments },
                      )
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" md="3"
                 :class="{dividerH : $vuetify.breakpoint.smAndDown, dividerV : $vuetify.breakpoint.mdAndUp}">
            <div>
              <font-awesome-icon :icon="['far', 'sd-card']"/>
              <span class="font-weight-bold pl-4">{{ $t('project.project-settings.ProjectSettingsInformation.storage') }}</span>
            </div>
            <div class="my-3">
              {{
                $tc('project.project-settings.ProjectSettingsInformation.storageData',
                  Math.floor(usedStorage.storage),
                  {
                    totalCount: totalStorage.storage,
                    totalUnit: this.$t(`project.project-settings.ProjectSettingsInformation.${totalStorage.unit}`),
                    usedCount: usedStorage.storage,
                    usedUnit: this.$t(`project.project-settings.ProjectSettingsInformation.${usedStorage.unit}`),
                  })
              }}
            </div>
            <div>
              <v-progress-linear
                color="blue"
                :value="storagePercent"
                rounded
                height="10"
              />
            </div>
          </v-col>
          <v-col cols="12" md="3"
                 :class="{dividerH : $vuetify.breakpoint.smAndDown, dividerV : $vuetify.breakpoint.mdAndUp}">
            <div>
              <font-awesome-icon :icon="['far', 'file-signature']"/>
              <span class="font-weight-bold pl-4">{{ $t('project.project-settings.ProjectSettingsInformation.signatures') }}</span>
            </div>
            <template v-if="advancedSignature === null">
              <v-skeleton-loader type="text@3"></v-skeleton-loader>
            </template>
            <template v-else>
              <v-container class="px-0">
                <!-- Advanced Signatures -->
                <ProjectSettingsInformationSignatures class="mb-4"
                                                      :price="advancedSignature.cost"
                                                      :count="advancedSignature.count"
                                                      :is-billing-by-envelope="isBillingByEnvelope"
                                                      :documents-count="advancedSignature.documents"
                >
                  {{
                    $t(isBillingByEnvelope
                      ? 'project.project-settings.ProjectSettingsInformation.advancedEnvelopeSignatures'
                      : 'project.project-settings.ProjectSettingsInformation.advancedSignatures')
                  }}
                </ProjectSettingsInformationSignatures>
                <!-- Simple Signatures -->
                <ProjectSettingsInformationSignatures class="mb-4"
                                                      :price="simpleSignatures.cost"
                                                      :count="simpleSignatures.count"
                                                      :is-billing-by-envelope="isBillingByEnvelope"
                                                      :documents-count="simpleSignatures.documents"
                >
                  {{
                    $t(isBillingByEnvelope
                      ? 'project.project-settings.ProjectSettingsInformation.simpleEnvelopeSignatures'
                      : 'project.project-settings.ProjectSettingsInformation.simpleSignatures')
                  }}
                </ProjectSettingsInformationSignatures>
                <!-- Qualified Signatures -->
                <ProjectSettingsInformationSignatures class="mb-4"
                                                      :price="qualifiedSignatures.cost"
                                                      :count="qualifiedSignatures.count"
                                                      :documents-count="qualifiedSignatures.documents"
                >
                  {{ $t('project.project-settings.ProjectSettingsInformation.qualifiedSignatures') }}
                </ProjectSettingsInformationSignatures>
                <!-- Handwritten Signatures -->
                <v-row no-gutters>
                  <v-col>{{ $t('project.project-settings.ProjectSettingsInformation.handwrittenSignatures') }}</v-col>
                  <v-col class="pl-5">
                    {{
                      $tc('project.project-settings.ProjectSettingsInformation.handwrittenSignaturesCount', handwrittenSignatures.count, { number: handwrittenSignatures.count })
                    }}
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-col>
        </v-row>
      </template>
    </AppCard>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppCard from '@/common/AppCard'
import DateDisplay from '@/common/DateDisplay'
import { ISOToRelativeTime } from '@/common/utils/dates'
import { GET_ROOM_STATS } from '@/store/modules/room/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import ProjectSettingsInformationSignatures from './ProjectSettingsInformationSignatures.vue'

export default {
  name: 'ProjectSettingsInformation',
  components: {
    AppCard,
    DateDisplay,
    ProjectSettingsInformationSignatures,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('room', ['currentRoom', 'roomStats']),
    ...mapGetters('room', [
      'advancedSignature',
      'simpleSignatures',
      'qualifiedSignatures',
      'handwrittenSignatures',
      'archivedDocuments',
      'files',
      'storage',
      'totalStorage',
      'usedStorage',
      'storagePercent',
      'isBillingByEnvelope',
    ]),
  },
  mounted () {
    this.prepareGetRoomStats()
  },
  methods: {
    ...mapActions('room', [GET_ROOM_STATS]),
    async prepareGetRoomStats () {
      try {
        await this.GET_ROOM_STATS(this.mnemo)
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.project-settings.ProjectSettingsInformation.getRoomStatsError'),
        })
      }
    },
    handleISOToRelativeTime (date) {
      return ISOToRelativeTime(date, true)
    },
  },
}
</script>

<style scoped lang="scss">
.dividerV {
  padding: 8px 30px;
  border-left: 1px solid #E0E0E0;

  &:last-child {
    padding-right: 12px;
  }
}

.dividerH {
  padding: 30px 8px;
  border-top: 1px solid #E0E0E0;

  &:last-child {
    padding-bottom: 0;
  }
}
</style>
