<template>
    <AppDialog :is-open="isDialogOpened"
               size="l"
               :ok-loading="contactResponsiblePending"
               :ok-disabled="okDisabled"
               :cancel-disabled="contactResponsiblePending"
               @ok="prepareContactResponsible"
               @cancel="closeDialog"
    >
      <template #title>{{$t('project.checklist.dialogs.ContactResponsibleDialog.title')}}</template>
      <template #body>
        <v-row>
          <v-col class="text--primary text-h4">
            {{ task.display }} - {{ task.title }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text--primary">
            {{$t('project.checklist.dialogs.ContactResponsibleDialog.contactResponsibleInfo')}}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <VisualTextEditor v-model="message"
                              counter="footer"
                              :error-messages="messageErrors"
                              :max-length="messageMaxLength"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox v-model="getMessageCopy"
                        :label="$t('project.checklist.dialogs.ContactResponsibleDialog.getMessageCopy')"
                        class="mt-0 text--primary"
                        hide-details
                        dense
            />
          </v-col>
        </v-row>
      </template>
    </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { escapeHtml } from '../../../common/utils/strings'
import VisualTextEditor from '../../../common/visual-text-editor/VisualTextEditor'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { CONTACT_RESPONSIBLE } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

const MESSAGE_MAX_LENGTH = 400

export default {
  name: 'ContactResponsibleDialog',
  components: { AppDialog, VisualTextEditor },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      message: '',
      messageMaxLength: MESSAGE_MAX_LENGTH,
      getMessageCopy: false,
    }
  },
  computed: {
    ...mapState('checklist', ['contactResponsiblePending']),
    messageLength () {
      return escapeHtml(this.message).length
    },
    messageIsTooLong () {
      return this.messageLength > this.messageMaxLength
    },
    messageErrors () {
      const errors = []

      if (this.messageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    okDisabled () {
      return this.messageIsTooLong || this.contactResponsiblePending
    },
  },
  methods: {
    ...mapActions('checklist', [CONTACT_RESPONSIBLE]),

    async prepareContactResponsible () {
      try {
        await this.CONTACT_RESPONSIBLE({
          mnemo: this.mnemo,
          taskId: this.task.id,
          data: {
            chaseMsg: this.message,
            ccAuthor: this.getMessageCopy,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.dialogs.ContactResponsibleDialog.contactResponsibleSuccess'),
        })

        this.closeDialog()
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.dialogs.ContactResponsibleDialog.contactResponsibleError'),
        })
      }
    },
  },
}
</script>
