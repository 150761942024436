<template>
  <div class="signer-color-chip" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'SignerColorChip',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
  .signer-color-chip {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    background-color: v-bind(color);
  }
</style>
