<template>
  <div class="px-6 py-3">
    <v-breadcrumbs v-if="canDisplayBreadcrumbs" class="pa-0">
      <template v-for="(item, i) in itemsList">
        <AppDroppableArea :key="`item-${i}`"
                          :disabled="homeLayout || isFolderDropDisabled(item, i)"
                          @dragenter="dragEnterParentFolder(item, i)"
                          @dragleave="dragLeaveParentFolder"
                          @drop="dropDocumentsInParentFolder($event, item)"
        >
          <v-breadcrumbs-item class="py-1 px-3"
                              :class="item.id !== (documents.id || currentProjectsFolder.id) ? 'clickable text--primary' : ''"
                              @click="handleGoTo(item)"
          >
            <span v-if="!homeLayout && numberingEnabled && item.numbering" class="pr-1">{{item.numbering}}</span>
            <span v-if="homeLayout"
                  :class="`grey--text text--darken-${i < itemsList.length - 1 ? '2' : '3'}`"
            >
              {{ item.name }}
            </span>
            <span v-else>{{ item.name }}</span>
          </v-breadcrumbs-item>
        </AppDroppableArea>
        <v-breadcrumbs-divider v-if="i < itemsList.length - 1"
                               :key="`divider-${i}`"
        >
          <font-awesome-icon :icon="['fal', 'chevron-right']"></font-awesome-icon>
        </v-breadcrumbs-divider>
      </template>
    </v-breadcrumbs>

    <v-skeleton-loader v-if="!navigationIsEnabled" width="260px" type="text" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import AppDroppableArea from '@/common/AppDroppableArea.vue'
import { flatten } from '@/common/utils/flatArray'
import { FileToUpload, FolderToUpload } from '@/models/documentToUpload.model'
import { FOLDER_ROUTE_NAME, ROOMS_ROUTE_NAME } from '@/router/index'
import { SET_UPLOAD_HINT } from '@/store/modules/app-upload-handler/mutation_types'
import { DESELECT_ALL_DOCUMENTS, LOAD_FOLDERS_TREE, MOVE_FILDERS } from '@/store/modules/documents/action_types'
import { SET_MOVEMENT_SNACKBAR_IS_OPEN } from '@/store/modules/documents/mutation_types'
import { START_UPLOAD } from '@/store/modules/documents-upload-files/action_types'
import { ADD_FILES } from '@/store/modules/documents-upload-files/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'DocumentsBreadcrumb',
  components: { AppDroppableArea },
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      draggedFolder: null,
      FOLDER_ROUTE_NAME,
      ROOMS_ROUTE_NAME,
    }
  },
  computed: {
    ...mapState(['homeLayout']),
    ...mapState('projectsFolders', ['currentProjectsFolder', 'projectsFolderByIdPending']),
    ...mapGetters('documents', ['isRootFolder']),
    ...mapState('documents', ['documents', 'documentsToMove', 'foldersTree', 'loading', 'getFolderPathPending', 'numberingEnabled']),
    ...mapState('documentsBreadcrumb', ['breadcrumb']),
    ...mapGetters('room', ['currentUserRights', 'roomMnemo']),
    canDisplayBreadcrumbs () {
      return this.homeLayout ? !this.isRootFolder && this.navigationIsEnabled : this.navigationIsEnabled
    },
    itemsList () {
      return this.homeLayout ? this.items : this.breadcrumb
    },
    navigationIsEnabled () {
      if (this.homeLayout) {
        return !this.projectsFolderByIdPending
      }
      return !this.loading && !this.getFolderPathPending
    },
  },
  watch: {
    breadcrumb: {
      handler () {
        this.DESELECT_ALL_DOCUMENTS()
      },
      deep: true,
    },
    draggedFolder (folder) {
      if (folder) {
        this.openUploadHint(folder.name)
      } else {
        this.closeUploadHint()
      }
    },
  },
  beforeDestroy () {
    this.resetBreadcrumb()
  },
  methods: {
    ...mapActions('documentsBreadcrumb', ['goTo', 'resetBreadcrumb']),
    ...mapActions('documents', [DESELECT_ALL_DOCUMENTS, LOAD_FOLDERS_TREE]),
    ...mapMutations('appUploadHandler', [SET_UPLOAD_HINT]),
    ...mapActions('documents', [MOVE_FILDERS]),
    ...mapActions('documentsUploadFiles', [START_UPLOAD]),
    ...mapMutations('documentsUploadFiles', [ADD_FILES]),
    ...mapMutations('documents', [SET_MOVEMENT_SNACKBAR_IS_OPEN]),
    handleGoTo (item) {
      if (this.homeLayout && item.id !== this.currentProjectsFolder.id) {
        this.navigateToProjectsFolder(item)
      } else if (!this.homeLayout && item.id !== this.documents.id) {
        this.goTo(item)
      }
    },
    navigateToProjectsFolder (item) {
      if (item.id) {
        this.$router.push({ name: FOLDER_ROUTE_NAME, params: { folderId: item.id } })
      } else {
        this.$router.push({ name: ROOMS_ROUTE_NAME })
      }
    },
    dragEnterParentFolder (folder, index) {
      if (this.homeLayout) return
      if (!this.isFolderDropDisabled(folder, index)) {
        this.draggedFolder = folder
      }
    },
    dragLeaveParentFolder () {
      if (this.homeLayout) return
      this.draggedFolder = null
    },
    dropDocumentsInParentFolder ($event, destinationFolder) {
      const documentFromClosd = $event.dataTransfer.getData('fromClosd') === 'true'
      if (documentFromClosd) {
        const documentId = Number($event.dataTransfer.getData('documentId'))
        const documentType = $event.dataTransfer.getData('documentType')
        const dropFolderInItself = documentId === destinationFolder.id && documentType === destinationFolder.type

        if (!dropFolderInItself) {
          this.moveClosdDocument(destinationFolder)
        }
      } else {
        this.dropLocalDocuments($event, destinationFolder)
      }
    },
    openUploadHint (destinationFolder) {
      this.SET_UPLOAD_HINT({
        visible: true,
        destinationFolder: destinationFolder,
      })
    },
    closeUploadHint () {
      this.SET_UPLOAD_HINT({ visible: false })
    },
    async moveClosdDocument (targetFolder) {
      try {
        await this.MOVE_FILDERS({
          documents: this.documentsToMove.documents,
          targetFolderId: targetFolder.id,
        })
        this.SET_MOVEMENT_SNACKBAR_IS_OPEN(true)
      } catch {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.DocumentsBreadcrumb.moveError'),
        })
      }

      if (this.documentsToMove.documents.some(doc => doc.type === 'folder')) {
        await this.LOAD_FOLDERS_TREE()
      }
      if (this.documentsToMove.isDragElementSelected) {
        this.DESELECT_ALL_DOCUMENTS()
      }
    },
    async dropLocalDocuments ($event, targetFolder) {
      const dataTransferItemList = $event.dataTransfer ? $event.dataTransfer.items : []
      const documentsToUpload = []
      const queue = []
      for (let i = 0; i < dataTransferItemList.length; i++) {
        queue.push(dataTransferItemList[i].webkitGetAsEntry())
      }

      for (const entry of queue) {
        if (entry.isFile) {
          const fileToUpload = await this.generateFileToUpload(entry, targetFolder.id)
          documentsToUpload.push(fileToUpload)
        }

        if (entry.isDirectory) {
          if (!this.currentUserRights.canCreateFolders) {
            this.$store.commit(ENQUEUE_SNACKBAR, {
              color: 'error',
              message: this.$t('project.documents.DocumentsBreadcrumb.noFolderAsGuest'),
            })
            return
          }

          if (this.hasTodoFolderOrChild) {
            this.$store.commit(ENQUEUE_SNACKBAR, {
              color: 'error',
              message: this.$t('project.documents.DocumentsBreadcrumb.noFolderInChecklist'),
            })
            return
          }

          const folderToUpload = await this.generateFolderToUpload(entry, targetFolder.id)
          documentsToUpload.push(folderToUpload)
        }
      }

      this.ADD_FILES(documentsToUpload)
      this.START_UPLOAD()
    },
    async generateFolderToUpload (folder, parentFolderId) {
      const folderToUpload = new FolderToUpload(folder.name, [], parentFolderId)

      let readEntries
      try {
        readEntries = await new Promise((resolve, reject) => {
          folder.createReader().readEntries(resolve, reject)
        })
      } catch (err) {
        console.error(err)
      }

      for (const entry of readEntries) {
        if (entry.isFile) {
          folderToUpload.children.push(await this.generateFileToUpload(entry))
          continue
        }

        if (entry.isDirectory) {
          folderToUpload.children.push(await this.generateFolderToUpload(entry))
        }
      }

      return folderToUpload
    },
    async generateFileToUpload (file, parentFolderId) {
      let entryFile
      try {
        entryFile = await new Promise((resolve, reject) => {
          file.file(resolve, reject)
        })
      } catch (err) {
        console.error(err)
      }

      return new FileToUpload(entryFile, 'document', parentFolderId)
    },
    isFolderDropDisabled (folder, index) {
      if (this.documentsToMove.isDragFromFoldersTree && !this.canDropFromFolderTree(folder)) {
        return true
      }
      return index === this.breadcrumb.length - 1 || folder.hasOwnProperty('specialFolder')
    },
    canDropFromFolderTree (targetFolder) {
      if (!targetFolder || !this.documentsToMove.isDragFromFoldersTree) {
        return false
      }
      const flattenFolders = flatten([], this.foldersTree?.folders)
      const matchingFolder = flattenFolders.find(flattenFolder => flattenFolder.id === this.documentsToMove.documents[0].id)
      const childrenOfMatchingFolder = flatten([], matchingFolder?.children)
      const dropInSameParent = this.documentsToMove.originalFolderId === targetFolder.id
      const dropToSameFolder = this.documentsToMove.documents[0].id === targetFolder.id
      const dropInChildrenFolder = childrenOfMatchingFolder.some(childFolder => childFolder.id === targetFolder.id)
      return !dropInSameParent && !dropToSameFolder && !dropInChildrenFolder
    },
  },
}
</script>
