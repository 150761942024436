<template>
  <AppDialog size="m"
             :cancelDisabled="cancelDisabled"
             :is-open="isOpen"
             :okDisabled="!canSend"
             :okLoading="okLoading"
             :okText="$t('common.send')"
             @cancel="cancel"
             @ok="send"
  >
    <template #title>{{ title }}</template>
    <template #body>
      <Alert no-icon :dismissible="false">
        {{ $t("common.dialogs.SendByEmailDialog.info") }}
      </Alert>

      <div class="my-3" style="max-height: 320px; overflow-y: auto">
        <div v-for="(_, index) in emailAddresses"
             :key="`item${index}`"
             class="d-flex mt-3"
        >
          <AppTextField
            v-model.trim="emailAddresses[index]"
            autofocus
            :placeholder="$t('common.emailAddress')"
          />
          <AppTooltip top>
            <template #activator="{ on, attrs }">
              <AppButton icon
                         v-bind="attrs"
                         v-on="on"
                         @click="() => removeEmailAddressAtIndex(index)"
              >
                <font-awesome-icon :icon="['fal', 'xmark']" />
              </AppButton>
            </template>
            <span>{{ $t('common.delete') }}</span>
          </AppTooltip>

        </div>
      </div>
      <AppButton text
                 class="d-flex align-center"
                 color="primary"
                 @click="addEmptyEmail"
      >
        <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />
        {{ $t('common.dialogs.SendByEmailDialog.addRecipient') }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from './AppDialog'
import Alert from '../alerts/Alert'
import AppTextField from '../AppTextField'
import AppTooltip from '../AppTooltip'
import AppButton from '../buttons/AppButton'

export default {
  name: 'SendByEmailDialog',
  components: { Alert, AppButton, AppDialog, AppTextField, AppTooltip },
  props: {
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    okLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      emailAddresses: [''],
    }
  },
  computed: {
    canSend () {
      return this.notEmptyEmailAddresses.length > 0
    },
    notEmptyEmailAddresses () {
      return this.emailAddresses.filter(emailAddress => emailAddress !== '')
    },
  },
  methods: {
    addEmptyEmail () {
      this.emailAddresses.push('')
    },
    cancel () {
      this.$emit('cancel')
    },
    removeEmailAddressAtIndex (emailAddressIndex) {
      if (this.emailAddresses.length === 1) {
        this.emailAddresses = ['']
      } else {
        this.emailAddresses.splice(emailAddressIndex, 1)
      }
    },
    send () {
      this.$emit('send', this.notEmptyEmailAddresses)
    },
  },
}
</script>
