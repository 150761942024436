<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('common.validate')"
             :ok-loading="shouldValidateButtonDisplayLoader"
             @cancel="closeDialog"
             @ok="onValidate"
  >
    <template #title>{{ $t('project.documents.dialogs.DocumentsNumberingDialog.title') }}</template>

    <template #body>
      <div>
        <span class="mr-2">{{$t('project.documents.dialogs.DocumentsNumberingDialog.currentNumbering')}} :</span>
        <span class="font-weight-bold"
              data-testid="current-numbering"
        >
              {{formatNumberingPart(numberingParts)}}
            </span>
      </div>

      <div class="d-flex align-center mt-2">
        <span class="mr-2">{{$t('project.documents.dialogs.DocumentsNumberingDialog.newNumbering')}} :</span>
        <span class="font-weight-bold"
              data-testid="readonly-numbering-part"
        >
              {{formatNumberingPart(readonlyParts)}}
            </span>
        <AppTextField v-model="editablePart"
                      data-testid="editable-numbering-part"
                      class="editable-numbering-part"
                      type="number"
                      min="1"
                      @blur="validateNewNumbering"
        />
      </div>

      <Alert v-if="numberingError"
             type="error"
             :icon="false"
             :dismissible="false"
             class="mt-4"
      >
        {{ numberingError }}
      </Alert>
    </template>
  </AppDialog>
</template>

<script>
import { last, trim } from 'lodash-es'
import { mapActions, mapState } from 'vuex'

import Alert from '@/common/alerts/Alert'
import AppTextField from '@/common/AppTextField'
import { dialogMixin } from '@/mixins/dialogMixin'
import { EDIT_DOCUMENT_NUMBERING, GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'

import AppDialog from '../../../common/dialogs/AppDialog'

const NUMBERING_SEPARATOR = '.'

export default {
  name: 'DocumentsNumberingDialog',
  components: { AppDialog, Alert, AppTextField },
  mixins: [dialogMixin],
  props: {
    document: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: true,
    },
  },
  data () {
    return {
      editablePart: 1,
      numberingError: null,
    }
  },
  computed: {
    ...mapState('documents', ['editDocumentNumberingPending', 'loading']),
    shouldValidateButtonDisplayLoader () {
      return this.editDocumentNumberingPending || this.loading
    },
    numberingParts () {
      return this.document
        ? trim(this.document.numbering, NUMBERING_SEPARATOR).split(NUMBERING_SEPARATOR)
        : []
    },
    readonlyParts () {
      return this.numberingParts.slice(0, -1)
    },
  },
  watch: {
    document: {
      handler (currentDocument) {
        if (currentDocument) {
          this.editablePart = last(this.numberingParts)
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('documents', [EDIT_DOCUMENT_NUMBERING, GET_DOCUMENTS_BY_ID]),
    formatNumberingPart (parts) {
      return parts.reduce((result, part) => {
        result += `${part}${NUMBERING_SEPARATOR}`
        return result
      }, '')
    },
    async onValidate () {
      this.numberingError = null

      try {
        const response = await this.EDIT_DOCUMENT_NUMBERING({
          documentType: this.document.type,
          documentId: this.document.id,
          numbering: this.formatNumberingPart([...this.readonlyParts, this.editablePart]),
        })
        this.$emit('onSuccess', response)
        this.closeDialog()
      } catch (error) {
        console.error(error)

        this.numberingError = error.response?.data?.errorCode === 'ERR_NUMBERING_ALREADY_IN_USE'
          ? this.$t('project.documents.dialogs.DocumentsNumberingDialog.numberingAlreadyInUseError')
          : this.$t('project.documents.dialogs.DocumentsNumberingDialog.wrongNumberingError')
      }
    },
    validateNewNumbering () {
      if (this.editablePart < 1) {
        this.editablePart = 1
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .editable-numbering-part {
    &.v-input {
      max-width: 75px;
      font-weight: 700;
    }
  }
</style>
