import {
  ADD_UPLOAD_HIDDEN_FILE_PENDING,
  REMOVE_DOCUMENT_FILTER,
  REMOVE_SELECTED_DOCUMENT,
  REMOVE_UPLOAD_HIDDEN_FILE_PENDING,
  REMOVE_VERSION,
  SET_ALL_DOCUMENTS_AS_SELECTED,
  SET_DOCUMENTS,
  SET_DOCUMENTS_PANE,
  SET_DOCUMENT_FILTERS,
  SET_DOCUMENTS_TO_MOVE,
  SET_DUPLICATE_LOADING,
  SET_EDIT_DOCUMENT_NUMBERING_PENDING,
  SET_ERROR,
  SET_FILTERS_PANE_IS_OPEN,
  SET_FOLDERS_TREE,
  SET_FULL_TREE,
  SET_GET_FOLDERS_TREE_PENDING,
  SET_GET_FOLDER_PATH_PENDING,
  SET_IMANAGE_HIDDEN_FILE_IMPORT_ERROR,
  SET_IMANAGE_HIDDEN_FILE_IMPORT_PENDING,
  SET_LAST_ACCESS_TIMESTAMP,
  SET_LAST_MNEMO_ACCESSED,
  SET_LOADING,
  SET_MOVE_LOADING,
  SET_MOVEMENT_SNACKBAR_IS_OPEN,
  SET_NUMBERING,
  SET_NUMBERING_ERROR,
  SET_NUMBERING_SNACKBAR_IS_OPEN,
  SET_PATCH_NUMBERING_PENDING,
  SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_ERROR,
  SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_PENDING,
  SET_POST_FILDERS_FROM_IMANAGE_ERROR,
  SET_POST_FILDERS_FROM_IMANAGE_PENDING,
  SET_POST_FILDERS_TO_IMANAGE_ERROR,
  SET_POST_FILDERS_TO_IMANAGE_PENDING,
  SET_POST_MASS_FOLDERS_ERROR,
  SET_POST_MASS_FOLDERS_PENDING,
  SET_POST_REQUEST_AUTHORIZATION_BIBLE_PENDING,
  SET_POST_VERSION_FROM_IMANAGE_ERROR,
  SET_POST_VERSION_FROM_IMANAGE_PENDING,
  SET_REMOVE_VERSION_PENDING,
  SET_SEARCH_LOADING,
  SET_SEARCH_MODE_ENABLED,
  SET_SEARCH_RESULTS,
  SET_SELECTED_DOCUMENTS,
  SET_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PENDING,
  SET_SHARE_LOADING,
  SET_TABLE_DOCUMENTS_PANE_TAB,
  SET_UPDATE_ACCESS_TIME_ERROR,
  SET_UPDATE_ACCESS_TIME_PENDING,
  SET_VERSIONS_LOADING,
  TOGGLE_TABLE_DOCUMENTS_PANE,
  SET_DOCUMENTS_VERSIONING_PANE_TAB,
  TOGGLE_DOCUMENTS_VERSIONING_PANE,
  SET_CHECK_WOPI_LOCKED_FILES_PENDING,
  SET_WOPI_LOCKED_FILES,
  RESET_WOPI_LOCKED_FILES,
} from './mutation_types'

export const mutations = {
  [SET_DUPLICATE_LOADING] (state, value) {
    state.duplicateLoading = value
  },
  [SET_PATCH_NUMBERING_PENDING] (state, value) {
    state.patchNumberingPending = value
  },
  [SET_LAST_MNEMO_ACCESSED] (state, value) {
    state.lastMnemoAccessed = value
  },
  [SET_LAST_ACCESS_TIMESTAMP] (state, value) {
    state.lastAccessTimestamp = value
  },
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_SHARE_LOADING] (state, value) {
    state.shareLoading = value
  },
  [SET_SEARCH_LOADING] (state, value) {
    state.searchLoading = value
  },
  [SET_MOVE_LOADING] (state, value) {
    state.moveLoading = value
  },
  [SET_VERSIONS_LOADING] (state, value) {
    state.versionsLoading = value
  },
  [SET_DOCUMENTS] (state, documents) {
    state.documents = documents

    if (state.selectedDocuments.length && state.documents?.children) {
      state.selectedDocuments = state.selectedDocuments.map(selectedDocument => {
        const matchingItem = state.documents.children.find(document => document.id === selectedDocument.id && document.type === selectedDocument.type)
        return matchingItem ? { ...selectedDocument, ...matchingItem } : selectedDocument
      })
    }
  },
  [SET_FULL_TREE] (state, fullTree) {
    state.fullTree = fullTree
  },
  [SET_UPDATE_ACCESS_TIME_PENDING] (state, value) {
    state.updateAccessTimePending = value
  },
  [SET_UPDATE_ACCESS_TIME_ERROR] (state, value) {
    state.updateAccessTimeError = value
  },
  [ADD_UPLOAD_HIDDEN_FILE_PENDING] (state, value) {
    state.uploadHiddenFilePendingIds.push(value)
  },
  [REMOVE_UPLOAD_HIDDEN_FILE_PENDING] (state, indexToRemove) {
    state.uploadHiddenFilePendingIds.splice(indexToRemove, 1)
  },
  [SET_ERROR] (state, error) {
    state.error = error
  },
  [SET_SELECTED_DOCUMENTS] (state, selectedDocuments) {
    state.selectedDocuments = selectedDocuments
  },
  [SET_ALL_DOCUMENTS_AS_SELECTED] (state) {
    state.selectedDocuments.length === state.documents.children.filter(item => !item.specialFolder).length
      ? state.selectedDocuments = []
      : state.selectedDocuments = state.documents.children.filter(item => !item.specialFolder)
  },
  [TOGGLE_TABLE_DOCUMENTS_PANE] (state, payload) {
    state.documentsTablePane.documents = payload.documents || []
    state.documentsTablePane.forcePmSelection = payload.forcePmSelection || false
    state.documentsTablePane.isOpen = payload.isOpen || false
    state.documentsTablePane.previousDocument = payload.previousDocument || null
    state.documentsTablePane.tab = payload.tab || ''
  },
  [SET_TABLE_DOCUMENTS_PANE_TAB] (state, tab) {
    state.documentsTablePane.tab = tab
  },
  [SET_SEARCH_RESULTS] (state, results) {
    state.searchResults = results
  },
  [REMOVE_VERSION] (state, version) {
    state.documentsVersioningPane.documentVersions.splice(state.documentsVersioningPane.documentVersions.findIndex(i => i.id === version.id), 1)
  },
  [SET_REMOVE_VERSION_PENDING] (state, value) {
    state.documentsVersioningPane.removeVersionPending = value
  },
  [SET_SEARCH_MODE_ENABLED] (state, value) {
    state.searchModeEnabled = value
  },
  [REMOVE_SELECTED_DOCUMENT] (state, documentId) {
    state.documentsTablePane.documents.splice(state.documentsTablePane.documents.findIndex(document => document.id === documentId), 1)
  },
  [SET_NUMBERING] (state, value) {
    state.numberingEnabled = value
  },
  [SET_NUMBERING_ERROR] (state, error) {
    state.numberingError = error
  },
  [SET_POST_FILDERS_FROM_IMANAGE_PENDING] (state, value) {
    state.postFildersFromImanagePending = value
  },
  [SET_POST_FILDERS_FROM_IMANAGE_ERROR] (state, value) {
    state.postFildersFromImanageError = value
  },
  [SET_POST_FILDERS_TO_IMANAGE_PENDING] (state, value) {
    state.postFildersToImanagePending = value
  },
  [SET_POST_FILDERS_TO_IMANAGE_ERROR] (state, value) {
    state.postFildersToImanageError = value
  },
  [SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_PENDING] (state, value) {
    state.postFildersAsVersionToImanagePending = value
  },
  [SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_ERROR] (state, value) {
    state.postFildersAsVersionToImanageError = value
  },
  [SET_IMANAGE_HIDDEN_FILE_IMPORT_PENDING] (state, value) {
    state.imanageHiddenFileImportPending = value
  },
  [SET_IMANAGE_HIDDEN_FILE_IMPORT_ERROR] (state, value) {
    state.imanageHiddenFileImportError = value
  },
  [SET_POST_VERSION_FROM_IMANAGE_PENDING] (state, value) {
    state.postVersionFromImanagePending = value
  },
  [SET_POST_VERSION_FROM_IMANAGE_ERROR] (state, value) {
    state.postVersionFromImanageError = value
  },
  [SET_EDIT_DOCUMENT_NUMBERING_PENDING] (state, value) {
    state.editDocumentNumberingPending = value
  },
  [SET_POST_REQUEST_AUTHORIZATION_BIBLE_PENDING] (state, value) {
    state.postRequestAuthorizationBiblePending = value
  },
  [SET_POST_MASS_FOLDERS_PENDING] (state, value) {
    state.postMassFoldersPending = value
  },
  [SET_POST_MASS_FOLDERS_ERROR] (state, value) {
    state.postMassFoldersError = value
  },
  [SET_GET_FOLDER_PATH_PENDING] (state, value) {
    state.getFolderPathPending = value
  },
  [SET_DOCUMENTS_PANE] (state, value) {
    state.documentsPane = value
  },
  [SET_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PENDING] (state, value) {
    state.shareDocumentsIndexByEmailPending = value
  },
  [SET_DOCUMENT_FILTERS] (state, filters) {
    state.filters = filters
  },
  [SET_DOCUMENTS_TO_MOVE] (state, value) {
    state.documentsToMove = value
  },
  [REMOVE_DOCUMENT_FILTER] (state, filterToRemove) {
    state.filters = state.filters.filter(
      filter => filter.category.key !== filterToRemove.category.key || filter.value.key !== filterToRemove.value.key,
    )
  },
  [SET_FILTERS_PANE_IS_OPEN] (state, isOpen) {
    state.filtersPaneIsOpen = isOpen
  },
  [SET_MOVEMENT_SNACKBAR_IS_OPEN] (state, isOpen) {
    state.movementSnackbarIsOpen = isOpen
  },
  [SET_NUMBERING_SNACKBAR_IS_OPEN] (state, isOpen) {
    state.numberingSnackbarIsOpen = isOpen
  },
  [SET_FOLDERS_TREE] (state, foldersTree) {
    state.foldersTree = foldersTree
  },
  [SET_GET_FOLDERS_TREE_PENDING] (state, isPending) {
    state.getFoldersTreePending = isPending
  },
  [SET_DOCUMENTS_VERSIONING_PANE_TAB] (state, tab) {
    state.documentsVersioningPane.tab = tab
  },
  [TOGGLE_DOCUMENTS_VERSIONING_PANE] (state, payload) {
    state.documentsVersioningPane.documentVersions = payload.documentVersions || []
    state.documentsVersioningPane.documents = payload.documents || []
    state.documentsVersioningPane.isOpen = payload.isOpen || false
    state.documentsVersioningPane.tab = payload.tab || ''
  },
  [SET_CHECK_WOPI_LOCKED_FILES_PENDING] (state, isPending) {
    state.checkWopiLockedFilesPending = isPending
  },
  [SET_WOPI_LOCKED_FILES] (state, data) {
    state.wopiLockedFiles = data
  },
  [RESET_WOPI_LOCKED_FILES] (state) {
    state.wopiLockedFiles = []
  },
}
