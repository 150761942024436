<template>
  <tbody>
    <component :is="isLexisNexisChecklist ? 'LexisNexisChecklistTableItem' : 'SavedChecklistTableItem'"
               v-for="checklist in items"
               :key="checklist.id"
               :checklist="checklist"
    />

    <tr v-if="!items.length">
      <td :colspan="columnsCount"
          class="text-center"
      >
        {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.savedChecklists.noChecklistFound') }}
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions } from 'vuex'

import { GET_SELF_CONTACT_LIST } from '@/store/modules/user/action_types'

import LexisNexisChecklistTableItem from './LexisNexisChecklistTableItem.vue'
import SavedChecklistTableItem from './SavedChecklistTableItem.vue'

export default {
  name: 'SavedChecklistTableBody',
  components: {
    LexisNexisChecklistTableItem,
    SavedChecklistTableItem,
  },
  props: {
    isLexisNexisChecklist: {
      type: Boolean,
      default: false,
    },
    columnsCount: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  created () {
    if (!this.isLexisNexisChecklist) {
      this.GET_SELF_CONTACT_LIST({ fromUserSubscriptionOnly: true })
    }
  },
  methods: {
    ...mapActions('user', [GET_SELF_CONTACT_LIST]),
  },
}
</script>
