<template>
    <div class="ChecklistShareTasksDialogRolePickerGroupMember-row py-2 px-4 ml-8">
      <div>{{member.fullName}}</div>
      <v-checkbox :ripple="false"
                  class="mt-0 pt-0 mx-auto"
                  dense
                  hide-details
                  :input-value="responsibleIsChecked"
                  readonly
                  @click="onResponsibleClick"
      />
      <v-checkbox :ripple="false"
                  class="mt-0 pt-0 mx-auto"
                  dense
                  hide-details
                  :input-value="validatorIsChecked"
                  readonly
                  @click="onValidatorsClick"
      />
      <v-checkbox :ripple="false"
                  class="mt-0 pt-0 mx-auto"
                  dense
                  :disabled="isObserversCheckboxDisabled"
                  hide-details
                  :input-value="observersIsChecked"
                  readonly
                  @click="!isObserversCheckboxDisabled && onObserversClick()"
      />
    </div>
</template>

<script>
import { mapState } from 'vuex'

import {
  ADD_SELECTED_OBSERVERS_MEMBERS,
  ADD_SELECTED_RESPONSIBLE_MEMBERS, ADD_SELECTED_VALIDATORS_MEMBERS, REMOVE_SELECTED_OBSERVERS_GROUPS,
  REMOVE_SELECTED_RESPONSIBLE_GROUPS, REMOVE_SELECTED_VALIDATORS_GROUPS,
  TOGGLE_SELECTED_OBSERVERS_MEMBERS,
  TOGGLE_SELECTED_RESPONSIBLE_MEMBERS, TOGGLE_SELECTED_VALIDATORS_MEMBERS,
} from '../../../store/modules/checklist/mutation_types'
export default {
  name: 'ChecklistShareTasksDialogRolePickerGroupMember',
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    taskVisibleByAll: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('checklist', ['shareTaskRolePickerSelectedResponsible', 'shareTaskRolePickerSelectedObservers', 'shareTaskRolePickerSelectedValidators']),
    isObserversCheckboxDisabled () {
      return this.responsibleIsChecked || this.validatorIsChecked || this.group.isPm || this.taskVisibleByAll
    },
    responsibleIsChecked () {
      return this.shareTaskRolePickerSelectedResponsible.members.includes(this.member) ||
        this.shareTaskRolePickerSelectedResponsible.groups.includes(this.group)
    },
    observersIsChecked () {
      return this.shareTaskRolePickerSelectedObservers.members.includes(this.member) ||
        this.shareTaskRolePickerSelectedObservers.groups.includes(this.group)
    },
    validatorIsChecked () {
      return this.shareTaskRolePickerSelectedValidators.members.includes(this.member) ||
        this.shareTaskRolePickerSelectedValidators.groups.includes(this.group)
    },
  },
  methods: {
    onResponsibleClick () {
      if (this.shareTaskRolePickerSelectedResponsible.groups.includes(this.group) && !this.shareTaskRolePickerSelectedResponsible.members.includes(this.member)) {
        this.$store.commit(`checklist/${REMOVE_SELECTED_RESPONSIBLE_GROUPS}`, this.group)
        this.group.members.forEach(member => {
          if (member.id !== this.member.id) {
            this.$store.commit(`checklist/${ADD_SELECTED_RESPONSIBLE_MEMBERS}`, member)
          }
        })
      } else {
        this.$store.commit(`checklist/${TOGGLE_SELECTED_RESPONSIBLE_MEMBERS}`, this.member)
        if (this.responsibleIsChecked && !this.observersIsChecked) {
          this.onObserversClick()
        }
      }
    },
    onObserversClick () {
      if (this.shareTaskRolePickerSelectedObservers.groups.includes(this.group) && !this.shareTaskRolePickerSelectedObservers.members.includes(this.member)) {
        this.$store.commit(`checklist/${REMOVE_SELECTED_OBSERVERS_GROUPS}`, this.group)
        this.group.members.forEach(member => {
          if (member.id !== this.member.id) {
            this.$store.commit(`checklist/${ADD_SELECTED_OBSERVERS_MEMBERS}`, member)
          }
        })
      } else {
        this.$store.commit(`checklist/${TOGGLE_SELECTED_OBSERVERS_MEMBERS}`, this.member)
      }
    },
    onValidatorsClick () {
      if (this.shareTaskRolePickerSelectedValidators.groups.includes(this.group) && !this.shareTaskRolePickerSelectedValidators.members.includes(this.member)) {
        this.$store.commit(`checklist/${REMOVE_SELECTED_VALIDATORS_GROUPS}`, this.group)
        this.group.members.forEach(member => {
          if (member.id !== this.member.id) {
            this.$store.commit(`checklist/${ADD_SELECTED_VALIDATORS_MEMBERS}`, member)
          }
        })
      } else {
        this.$store.commit(`checklist/${TOGGLE_SELECTED_VALIDATORS_MEMBERS}`, this.member)
        if (this.validatorIsChecked && !this.observersIsChecked) {
          this.onObserversClick()
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistShareTasksDialogRolePickerGroupMember-row {
  display: grid;
  grid-template-columns: auto 180px 180px 180px;
  grid-gap: 10px;
  align-items: center;
  border: solid 1px #e0e0e0;
}
</style>
