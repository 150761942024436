import {
  SET_MY_ENVELOPES_PENDING,
  SET_MY_ENVELOPES,
  SET_MY_ENVELOPES_ERROR,
} from './mutation_types'

export const mutations = {
  [SET_MY_ENVELOPES_PENDING] (state, value) {
    state.myEnvelopesPending = value
  },
  [SET_MY_ENVELOPES] (state, value) {
    state.myEnvelopes = value
  },
  [SET_MY_ENVELOPES_ERROR] (state, value) {
    state.myEnvelopesError = value
  },
}
