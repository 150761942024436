<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('common.delete')"
             :ok-loading="deleteTableSelectionPending"
             :cancel-disabled="deleteTableSelectionPending"
             @cancel="closeDialog"
             @ok="deleteSelection"
  >
    <template #title>
      {{$tc('project.checklist.dialogs.ChecklistTableDeleteSelectionDialog.title', allSelectedChecklistItems.length)}}
    </template>
    <template #body>
      <div v-if="enableValidationChecklistItemDeletion"
           v-html="$tc('project.checklist.dialogs.ChecklistTableDeleteSelectionDialog.doubleValidationText', allSelectedChecklistItems.length)">
      </div>
      <template v-else>
        {{$t('project.checklist.dialogs.ChecklistTableDeleteSelectionDialog.text')}}
      </template>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { DELETE_TABLE_SELECTION } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'ChecklistTableDeleteSelectionDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  computed: {
    ...mapState('room', ['currentRoom']),
    ...mapState('checklist', ['deleteTableSelectionPending']),
    ...mapGetters('checklist', ['allSelectedChecklistItems']),
    enableValidationChecklistItemDeletion () {
      return this.currentRoom?.enableValidationChecklistItemDeletion
    },
    deleteSelectionSuccess () {
      return this.enableValidationChecklistItemDeletion
             ? this.$t('project.project-settings.ProjectSettingsFeatures.checklist.pendingDeletion')
             : this.$tc('project.checklist.dialogs.ChecklistTableDeleteSelectionDialog.deleteSelectionSuccess', this.allSelectedChecklistItems.length)
    },
  },
  methods: {
    ...mapActions('checklist', [DELETE_TABLE_SELECTION]),
    async deleteSelection () {
      try {
        await this.DELETE_TABLE_SELECTION()

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.deleteSelectionSuccess,
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('project.checklist.dialogs.ChecklistTableDeleteSelectionDialog.deleteSelectionError', this.allSelectedChecklistItems.length),
        })
      } finally {
        this.closeDialog()
      }
    },
  },
}
</script>
