import dayjs from 'dayjs'
import { uniq } from 'lodash-es'

import { ISOToYYYYMMDD } from '@/common/utils/dates'

function checklistTasksHaveSameReadRights (tasks) {
  const tasksReadRightsAsJsonString = tasks.map(task => {
    const groups = task.rights.read.groups?.map(group => group.id).sort()
    const members = task.rights.read.members?.map(member => member.id).sort()
    return JSON.stringify({ groups, members })
  })

  return uniq(tasksReadRightsAsJsonString).length === 1
}

function checklistTasksHaveSameTaggedRights (tasks) {
  const tasksTaggedRightsAsJsonString = tasks.map(task => {
    const groups = task.rights.tagged.groups?.map(group => group.id).sort()
    const members = task.rights.tagged.members?.map(member => member.id).sort()
    return JSON.stringify({ groups, members })
  })

  return uniq(tasksTaggedRightsAsJsonString).length === 1
}

function checklistTasksHaveSameValidatorsRights (tasks) {
  const tasksValidatorRightsAsJsonString = tasks.map(task => {
    const groups = task.rights.validators.groups?.map(group => group.id).sort()
    const members = task.rights.validators.members?.map(member => member.id).sort()
    return JSON.stringify({ groups, members })
  })

  return uniq(tasksValidatorRightsAsJsonString).length === 1
}

/**
 * Get the details of a status from information on a checklist task and the categories
 * @param {Number} statusId The id of the status we want to search details for
 * @param {String} statusCategory The category of the status we want to search details for
 * @param {Array} taskStatusCategories The status categories as received from the back-end
 * @returns {object} With more informations on the status
 */
export function getStatusDetailsByTaskInformation (statusId, statusCategory, taskStatusCategories) {
  const statusesInCategory = taskStatusCategories[statusCategory]
  return statusesInCategory
    ? statusesInCategory.find(label => label.id === statusId)
    : null
}

/**
 * Apply filters + search query to checklistTasks items and return the result
 * @param {Array} checklistTasks An array of checklist tasks
 * @param {Array} filters An array of Filter objects from filters pane
 * @param {Array} searchQuery An array of strings from the search field
 * @returns {Array} The resulting array of flat items after each filters have been applied
 */
export function filterChecklistTasks (checklistTasks, filters, searchQuery) {
  let filteredItems = checklistTasks

  const completionDateFilters = filters.find(filter => filter.category.key === 'completionDate')
  const responsiblePartiesFilters = filters.filter(filter => filter.category.key === 'responsibleParties')
  const validatorsFilters = filters.filter(filter => filter.category.key === 'validators')
  const statusesFilters = filters.filter(filter => filter.category.key === 'statuses')

  if (searchQuery.length > 0) {
    filteredItems = filteredItems.filter(item => {
      return searchQuery.some(x => item.title?.toLowerCase().includes(x.toLowerCase())) ||
      searchQuery.some(x => item.description?.toLowerCase().includes(x.toLowerCase())) ||
      searchQuery.some(x => item.parties?.toLowerCase().includes(x.toLowerCase())) ||
      searchQuery.some(x => item.validators?.toLowerCase().includes(x.toLowerCase())) ||
      searchQuery.some(x => item.dateString?.toLowerCase().includes(x.toLowerCase()))
    })
  }

  if (completionDateFilters) {
    const filterFrom = completionDateFilters.value.key[0]
    const filterTo = completionDateFilters.value.key[1]

    if (completionDateFilters.value.key.length === 1) {
      filteredItems = filteredItems.filter(item => dayjs(ISOToYYYYMMDD(item.date)).isAfter(dayjs(ISOToYYYYMMDD(filterFrom))))
    } else if (completionDateFilters.value.key.length === 2) {
      filteredItems = filteredItems.filter(item => dayjs(ISOToYYYYMMDD(item.date)).isAfter(dayjs(ISOToYYYYMMDD(filterFrom))) && dayjs(ISOToYYYYMMDD(item.date)).isBefore(dayjs(ISOToYYYYMMDD(filterTo))))
    }
  }
  if (responsiblePartiesFilters.length > 0) {
    filteredItems = filteredItems.filter(item => {
      if (!item.rights) { return }
      for (const responsible of responsiblePartiesFilters) {
        const responsibleValue = responsible.value.key.split('-')
        if (responsibleValue[0] === 'user') {
          return item.rights.tagged.users.some(user => user.id === Number(responsibleValue[1]))
        } else if (responsibleValue[0] === 'group') {
          return item.rights.tagged.groups.some(group => group.id === Number(responsibleValue[1]))
        }
      }
    })
  }
  if (validatorsFilters.length > 0) {
    filteredItems = filteredItems.filter(item => {
      if (!item.rights) { return }
      for (const validator of validatorsFilters) {
        const validatorValue = validator.value.key.split('-')
        if (validatorValue[0] === 'user') {
          return item.rights.validators.users.some(user => user.id === Number(validatorValue[1]))
        } else if (validatorValue[0] === 'group') {
          return item.rights.validators.groups.some(group => group.id === Number(validatorValue[1]))
        }
      }
    })
  }
  if (statusesFilters.length > 0) {
    filteredItems = filteredItems.filter(
      item => statusesFilters.some(filter => filter.value.key === item.statusId),
    )
  }

  return filteredItems
}

export function checklistTasksHaveSameRights (tasks) {
  return checklistTasksHaveSameReadRights(tasks) &&
    checklistTasksHaveSameTaggedRights(tasks) &&
    checklistTasksHaveSameValidatorsRights(tasks)
}
