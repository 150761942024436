<template>
  <v-container fluid
               class="py-0 px-2 subscription-teams"
  >
    <!-- Title -->
    <v-row>
      <v-col class="text-h1">{{ $t('subscriptions.views.SubscriptionTeams.title') }}</v-col>
    </v-row>
    <!-- SubTitle -->
    <app-text variant="small-regular" class="my-4">
      {{ $t('subscriptions.views.SubscriptionTeams.subTitle') }}
    </app-text>

    <!-- Table -->
    <template v-if="subscriptionTeamsError">
      <app-alert-in-page variant="error"
                         no-icon
                         :dismissible="false"
      >
        {{ $t('subscriptions.views.SubscriptionTeams.getTeamsError') }}
      </app-alert-in-page>
    </template>
    <template v-else>
      <template v-if="subscriptionTeamsPending">
        <AppDataTableSkeletonLoader class="w-100"/>
      </template>
      <template v-else>
        <template v-if="subscriptionTeams.length">
          <v-row class="my-4">
            <v-col>
              <SearchTextField v-model="searchQuery"/>
            </v-col>
            <v-col class="text-right">
              <AppButton color="primary"
                         @click="openSubscriptionPane"
              >
                <app-icon icon-name="plus" size="medium" class="mr-1"></app-icon>
                {{ $t('subscriptions.views.SubscriptionTeams.newTeam') }}
              </AppButton>
            </v-col>
          </v-row>

          <v-data-table ref="SubscriptionTeamsTable"
                        class="SubscriptionTeams-table"
                        :items="filteredSubscriptionTeams"
                        :headers="headers"
                        :footer-props="{
                          'items-per-page-options': ITEM_PER_PAGE,
                        }"
                        :items-per-page="tableItemsPerPage"
                        :options.sync="options"
                        @click:row="onRowClick"
          >
            <template #item.name="{item}">
              <AddTeamComboboxItem :item="item"></AddTeamComboboxItem>
            </template>
            <template #item.members="{item}">
              <span>
                {{$tc('subscriptions.views.SubscriptionTeams.memberCount', item.members.length)}}
              </span>
            </template>
            <template #item.actions="{item}">
              <SubscriptionTeamsTableItemMenu
                @open-team-edit-pane="onRowClick(item)"
                @delete-team="openDeleteTeamDialog(item)"
              />
            </template>

            <template #footer.prepend>
              <v-pagination
                v-model="dataTablePage"
                :length="dataTablePageCount"
                :total-visible="7"
                :disabled="disabledPagination"
                @input="onPageChange"
              ></v-pagination>
            </template>
          </v-data-table>
        </template>
        <div v-else class="d-flex flex-column justify-center align-center"
                    style="margin-top: 10%;"
        >
          <div class="mb-8">
            <v-img src="/img/subscription/no-teams-illustration.svg" width="325" />
          </div>
          <app-text variant="small-regular" class="mb-6">
            {{ $t('subscriptions.views.SubscriptionTeams.emptyStateText') }}
          </app-text>
          <AppButton color="primary"
                     @click="openSubscriptionPane"
          >
            {{ $t('subscriptions.views.SubscriptionTeams.newTeam') }}
          </AppButton>
        </div>
      </template>
    </template>

    <SubscriptionTeamsPane v-if="subscriptionPaneIsOpen" @close="closeSubscriptionPane" />
    <SubscriptionTeamsDeleteDialog v-if="deleteTeamDialog.isOpen"
      :subscriptionTeam="deleteTeamDialog.subscriptionTeam"
      @close="onCloseDeleteTeamDialog"
      @delete-success="OnDeleteSuccessDeleteTeamDialog"
    />
  </v-container>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDataTableSkeletonLoader from '@/common/AppDataTableSkeletonLoader.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import SearchTextField from '@/common/SearchTextField.vue'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import AddTeamComboboxItem from '@/project/members/AddTeamComboboxItem.vue'
import { GET_SUBSCRIPTION_TEAMS } from '@/store/modules/subscription/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

import {
  SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN,
  SET_SELECTED_SUBSCRIPTION_TEAM,
  SET_DELETE_TEAM_DIALOG,
  REMOVE_SUBSCRIPTION_TEAM,
} from '../../store/modules/subscription/mutation_types'
import { SUBSCRIPTION_TEAMS_ITEMS_PER_PAGE } from '../constants.js'
import SubscriptionTeamsDeleteDialog from '../SubscriptionTeamsDeleteDialog.vue'
import SubscriptionTeamsPane from '../SubscriptionTeamsPane.vue'
import SubscriptionTeamsTableItemMenu from '../SubscriptionTeamsTableItemMenu.vue'

const ITEM_PER_PAGE = [10, 50, 100]

export default {
  name: 'SubscriptionTeams',
  components: {
    AppButton,
    SearchTextField,
    AppDataTableSkeletonLoader,
    AddTeamComboboxItem,
    SubscriptionTeamsPane,
    SubscriptionTeamsTableItemMenu,
    SubscriptionTeamsDeleteDialog,
  },
  data () {
    return {
      searchQuery: '',
      headers: [
        {
          value: 'name',
          text: this.$t('subscriptions.views.SubscriptionTeams.headers.name'),
        },
        {
          value: 'members',
          text: this.$t('subscriptions.views.SubscriptionTeams.headers.members'),
          sortable: false,
        },
        {
          value: 'actions',
          width: '50px',
          sortable: false,
        },
      ],
      options: {},
      dataTablePage: 1,
      dataTablePageCount: 1,
      disabledPagination: false,
      ITEM_PER_PAGE,
      SUBSCRIPTION_TEAMS_ITEMS_PER_PAGE,
    }
  },
  computed: {
    ...mapGetters('user', ['currentUserId']),
    ...mapState('subscription', [
      'subscriptionPaneIsOpen',
      'deleteTeamDialog',
      'subscriptionTeamsPending',
      'subscriptionTeams',
      'subscriptionTeamsError',
    ]),
    filteredSubscriptionTeams () {
      let filteredSubscriptionTeams = cloneDeep(this.subscriptionTeams)
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase()
        filteredSubscriptionTeams = filteredSubscriptionTeams.filter((team) => {
          return team.name.toLowerCase().includes(query)
        })
      }
      return filteredSubscriptionTeams
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, SUBSCRIPTION_TEAMS_ITEMS_PER_PAGE)
      const itemsPerPage = itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : ITEM_PER_PAGE[0]
      this.setPagination(itemsPerPage)
      return itemsPerPage
    },
  },
  watch: {
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, SUBSCRIPTION_TEAMS_ITEMS_PER_PAGE))) {
          this.setPagination(newValue.itemsPerPage)
          setUserSetting(this.currentUserId, SUBSCRIPTION_TEAMS_ITEMS_PER_PAGE, newValue.itemsPerPage)
        }
      },
    },
  },
  created () {
    this.getSubscriptionTeams()
  },
  methods: {
    ...mapMutations('subscription', [
      SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN,
      SET_SELECTED_SUBSCRIPTION_TEAM,
      SET_DELETE_TEAM_DIALOG,
      REMOVE_SUBSCRIPTION_TEAM,
    ]),
    ...mapActions('subscription', [GET_SUBSCRIPTION_TEAMS]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async getSubscriptionTeams () {
      try {
        await this.GET_SUBSCRIPTION_TEAMS()
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('subscriptions.views.SubscriptionTeams.getTeamsError'))
      }
    },
    onRowClick (team) {
      this.SET_SELECTED_SUBSCRIPTION_TEAM(team)
      this.openSubscriptionPane()
    },
    setPagination (nbItemsPerPage) {
      const files = this.filteredSubscriptionTeams
      const nbPages = Math.ceil(files.length / nbItemsPerPage)
      this.dataTablePage = 1
      this.dataTablePageCount = nbPages > 0 ? nbPages : 1
      if (this.dataTablePageCount === 1) {
        this.disabledPagination = true
      } else {
        this.disabledPagination = false
      }
    },
    onPageChange (page) {
      this.$refs.SubscriptionTeamsTable.$options.propsData.options.page = page
    },
    openSubscriptionPane () {
      this.SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN(true)
    },
    closeSubscriptionPane () {
      this.SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN(false)
    },
    openDeleteTeamDialog (team) {
      this.SET_DELETE_TEAM_DIALOG({
        subscriptionTeam: team,
        isOpen: true,
      })
    },
    onCloseDeleteTeamDialog () {
      this.SET_DELETE_TEAM_DIALOG({
        subscriptionTeam: null,
        isOpen: false,
      })
    },
    OnDeleteSuccessDeleteTeamDialog (deletedTeam) {
      this.REMOVE_SUBSCRIPTION_TEAM(this.subscriptionTeams.findIndex((team) => team.id === deletedTeam.id))
    },
  },
}
</script>

<style scoped lang="scss">
.subscription-teams {
  ::v-deep .SubscriptionTeams-table {
    background-color: transparent;
    table {
      border-collapse: separate;
      border-spacing: 0 5px;
    }
    th {
      color: var(--v-accent-base) !important;
      font-size: 14px !important;
      border-bottom: none !important;
    }
    td {
      color: var(--v-accent-base) !important;
      font-size: 14px !important;
      border-bottom: none !important;
      vertical-align: center;
      height: 48px !important;
    }
    tbody {
      tr {
        cursor: pointer;
        background-color: #fff;
      }
    }
  }
  ::v-deep .v-data-footer__icons-before, ::v-deep .v-data-footer__icons-after {
    display: none;
  }
  ::v-deep .v-data-footer {
    flex-direction: row-reverse;
  }
  ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border: none;
  }
}
</style>
