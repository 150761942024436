export type QuestionAnswerState = {
  categories: Array<Category>
  categoriesPending: boolean
  deleteQuestionPending: boolean
  filters: Array<Filter>
  filtersPaneIsOpen: boolean
  importQuestionsPending: boolean
  markQuestionAsResolvedPending: boolean
  patchQuestionRightsPending: boolean
  questionsBulkRightsPending: boolean
  postAnswerPending: boolean
  postQuestionPending: boolean
  question: Question | null
  questionPending: boolean
  // TODO : refactor this state property to remove unnecessary nesting
  // questions: Array<Question>
  questions: {
    questions: Array<Question>
  }
  questionsPending: boolean
}

export const state: QuestionAnswerState = {
  categories: [],
  categoriesPending: false,
  deleteQuestionPending: false,
  filters: [],
  filtersPaneIsOpen: false,
  importQuestionsPending: false,
  markQuestionAsResolvedPending: false,
  patchQuestionRightsPending: false,
  questionsBulkRightsPending: false,
  postAnswerPending: false,
  postQuestionPending: false,
  question: null,
  questionPending: false,
  questions: {
    questions: [],
  },
  questionsPending: false,
}
