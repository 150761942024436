import Vue from 'vue'

import {
  SET_SIGNING_VIEW_PENDING,
  SET_SIGNING_VIEW,
  SET_SIGNING_VIEW_ERROR,
  SET_PAGES_ERROR,
  SET_PAGES,
  ADD_PAGES_NUMBER_PENDING,
  REMOVE_PAGES_NUMBER_PENDING,
  SET_ACTIVE_PAGE,
  SET_SCROLL_TO,
  SET_SIGN_TAG_PENDING,
  REMOVE_SIGN_TAG_PENDING,
  SET_SIGN_TAG_ERROR,
  SET_UNSIGN_TAG_PENDING,
  REMOVE_UNSIGN_TAG_PENDING,
  SET_UNSIGN_TAG_ERROR,
  REFRESH_TAG,
  SET_VALIDATION_MODAL_IS_OPEN,
  SET_SEND_VALIDATION_SMS_PENDING,
  SET_SEND_VALIDATION_SMS_ERROR,
  SET_SIGN_DOCUMENT_PENDING,
  SET_SIGN_DOCUMENT_ERROR,
  SET_RESEND_VALIDATION_SMS_PENDING,
  SET_RESEND_VALIDATION_SMS_ERROR,
  SET_CONFIRM_SIGNATURE_PENDING,
  SET_CONFIRM_SIGNATURE_ERROR,
  RESET,
} from './mutation_types'
export const mutations = {
  [SET_SIGNING_VIEW_PENDING] (state, value) {
    state.signingViewPending = value
  },
  [SET_SIGNING_VIEW] (state, value) {
    state.signingView = value
  },
  [SET_SIGNING_VIEW_ERROR] (state, value) {
    state.signingViewError = value
  },
  [SET_PAGES_ERROR] (state, value) {
    state.pagesError = value
  },
  [ADD_PAGES_NUMBER_PENDING] (state, { fromPage, toPage }) {
    for (let i = fromPage; i <= toPage; i++) {
      const index = state.pagesNumberPending.findIndex(p => p === i)
      if (index < 0) {
        state.pagesNumberPending.push(i)
      }
    }
  },
  [REMOVE_PAGES_NUMBER_PENDING] (state, { fromPage, toPage }) {
    for (let i = fromPage; i <= toPage; i++) {
      const index = state.pagesNumberPending.findIndex(p => p === i)
      if (index >= 0) {
        state.pagesNumberPending.splice(index, 1)
      }
    }
  },
  [SET_PAGES] (state, value) {
    for (const newPage of value) {
      if (!state.pages.includes(page => page.id === newPage.id)) {
        state.pages.push(newPage)
      }
    }
    state.pages.sort((a, b) => {
      return a.pageNumber - b.pageNumber
    })
  },
  [SET_ACTIVE_PAGE] (state, pageNumber) {
    state.activePage = pageNumber
  },
  [SET_SCROLL_TO] (state, pageNumber) {
    state.scrollTo = pageNumber
  },
  [SET_SIGN_TAG_PENDING] (state, value) {
    state.signTagPendingIds.push(value)
  },
  [REMOVE_SIGN_TAG_PENDING] (state, index) {
    state.signTagPendingIds.splice(index, 1)
  },
  [SET_SIGN_TAG_ERROR] (state, value) {
    state.signTagError = value
  },
  [SET_UNSIGN_TAG_PENDING] (state, value) {
    state.unsignTagPendingIds.push(value)
  },
  [REMOVE_UNSIGN_TAG_PENDING] (state, index) {
    state.unsignTagPendingIds.splice(index, 1)
  },
  [SET_UNSIGN_TAG_ERROR] (state, value) {
    state.unsignTagError = value
  },
  [REFRESH_TAG] (state, payload) {
    Vue.set(payload.page.tags, payload.page.tags.findIndex(el => el.id === payload.tag.id), payload.tag)
  },
  [SET_VALIDATION_MODAL_IS_OPEN] (state, value) {
    state.validationModalIsOpen = value
  },
  [SET_SEND_VALIDATION_SMS_PENDING] (state, value) {
    state.sendValidationSmsPending = value
  },
  [SET_SEND_VALIDATION_SMS_ERROR] (state, value) {
    state.sendValidationSmsError = value
  },
  [SET_SIGN_DOCUMENT_PENDING] (state, value) {
    state.signDocumentPending = value
  },
  [SET_SIGN_DOCUMENT_ERROR] (state, value) {
    state.signDocumentError = value
  },
  [SET_RESEND_VALIDATION_SMS_PENDING] (state, value) {
    state.resendValidationSmsPending = value
  },
  [SET_RESEND_VALIDATION_SMS_ERROR] (state, value) {
    state.resendValidationSmsError = value
  },
  [SET_CONFIRM_SIGNATURE_PENDING] (state, value) {
    state.confirmSignaturePending = value
  },
  [SET_CONFIRM_SIGNATURE_ERROR] (state, value) {
    state.confirmSignatureError = value
  },
  [RESET] (state) {
    state.signingView = null
    state.pages = []
    state.activePage = null
    state.scrollTo = null
    state.validationModalIsOpen = false
  },
}
