<template>
  <div class="message-separator">
    <v-divider />
    <span class="message-date">
      {{ formattedDate }}
    </span>
    <v-divider />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppChatMessageSeparator',
  props: {
    date: {
      type: String,
      default: () => dayjs().toISOString(),
    },
  },
  computed: {
    formattedDate () {
      if (dayjs(this.date).isSame(dayjs(), 'day')) {
        return this.$t('common.today')
      }

      return dayjs(this.date).format('dddd DD MMMM')
    },
  },
})
</script>

<style scoped>
.message-separator {
  display: flex;
  align-items: center;

  .message-date {
    margin-inline: 12px;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 16px;
    color: var(--v-grey-darken2);
    opacity: .65;
  }
}
</style>
