<template>
  <v-row align="center">
    <AppTooltip v-if="file.status === 'error'" bottom max-width="400">
      <template v-slot:activator="{ attrs, on }">
        <span v-bind="attrs" v-on="on">
          <font-awesome-icon
            :icon="['fas', 'circle-xmark']"
            class="error--text"
            size="lg"
          >
          </font-awesome-icon>
        </span>
      </template>
      <span>{{$t("common.msgFailErrorOccurred")}}</span>
    </AppTooltip>
    <font-awesome-icon
        v-if="file.status !== 'error' && !validator.$invalid"
        :icon="['fad', 'circle-check']"
        size="lg"
    >
    </font-awesome-icon>
    <template v-if="validator.$dirty && file.status !== 'error'">
      <AppTooltip v-if="validator.$anyError" bottom max-width="400">
        <template v-slot:activator="{ attrs, on }">
          <span v-bind="attrs" v-on="on">
            <font-awesome-icon
              :icon="['far', 'circle-exclamation']"
              size="lg"
            ></font-awesome-icon>
          </span>
        </template>
        <span v-if="validator.$anyError">{{$t("common.validations.fillOutAll")}}</span>
      </AppTooltip>
    </template>
    <ClosdFildersIcon class="mr-4" :document="file"></ClosdFildersIcon>
    <app-text as="span" variant="small-regular">{{ file.templateName ?? file.name }}</app-text>
    <AppButton v-if="file.status === 'error'" type="icon" @click.prevent.stop="onDeleteFile">
      <font-awesome-icon
        :icon="['fas', 'circle-xmark']"
        size="lg"
      >
      </font-awesome-icon>
    </AppButton>
    <v-col class="text-right mr-3">
      <span class="font-italic grey--text body-2">{{$t('project.signing-checklist.add.SigningChecklistSectionDocumentHeader.stepHint')}}</span>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import AppTooltip from '@/common/AppTooltip.vue'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon.vue'
import AppButton from '@/design-system/buttons/AppButton.vue'

export default defineComponent({
  name: 'SigningChecklistDocumentHeader',
  components: {
    AppButton,
    AppTooltip,
    ClosdFildersIcon,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onDeleteFile () {
      this.$emit('deleteFile')
    },
  },
})
</script>

<style scoped lang="scss">
.fa-circle-exclamation {
  color: var(--v-warning-base);
}
.fa-circle-check {
  --fa-primary-color: var(--v-white-base);
  --fa-secondary-color: var(--v-success-base);
  --fa-secondary-opacity: 1;
}
</style>
