<template>
  <AppDialog :is-open="isDialogOpened"
             size="xxxl"
             ok-only
             :ok-text="$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.addToSigningChecklist')"
             :ok-disabled="selectedEnvelopeTemplates.length === 0"
             @ok="goToSignaturePreparation"
             @cancel="closeDialog"
  >
    <template #title>{{$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.title')}}</template>
    <template #body>
      <v-row>
        <v-col>
          <AppAlertInPage color="light-blue">
            <AppText variant="small-regular" class="mb-0">{{$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.infoText')}}</AppText>
          </AppAlertInPage>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <SearchTextField v-model="searchQuery"
                           :text="$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.searchTemplatePlaceholder')"
                           style="min-width: 250px"
          />
        </v-col>
        <v-col v-if="isSubPayingUser && !hasNoData"
               class="text-right"
        >
          <AppButton color="primary"
                     outlined
                     @click="navigateToEnvelopeTemplateCreation"
          >
            {{$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.createDocumentTemplate')}}
          </AppButton>
        </v-col>
      </v-row>
      <v-row v-if="selectedEnvelopeTemplates.length">
        <v-col>
          <AppMultipleSelectionBar :items="selectedEnvelopeTemplates"
                                   @deselectAll="selectedEnvelopeTemplates = []"
          >
            <template #buttons>
              <AppMultipleSelectionBarButton v-if="canDeleteSelectedTemplates"
                                             type="delete"
                                             :loading="deleteEnvelopeTemplatesPending"
                                             @click="isConfirmDeleteDialogOpen = true"
              />
              <ConfirmDeleteDialog v-if="isConfirmDeleteDialogOpen"
                                   :envelope-templates="selectedEnvelopeTemplates"
                                   @close="onConfirmDeleteDialogClose"
              />
            </template>
          </AppMultipleSelectionBar>
        </v-col>
      </v-row>
      <v-row v-if="envelopeTemplates || isLoading">
        <v-col class="pb-0">
          <div v-if="!hasNoData" class="d-flex align-center body-1 font-weight-medium ml-4">
            {{ $tc('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.envelopeTemplates', envelopeTemplates.length) }}
            <div class="envelopeTemplates-count ml-2 caption font-weight-medium">{{ envelopeTemplates.length }}</div>
          </div>
          <v-skeleton-loader v-else-if="isLoading" type="text" max-width="300"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <AppDataTableSkeletonLoader v-if="getEnvelopeTemplatesPending"/>
          <EnvelopeTemplatesNoData v-else-if="hasNoData"/>
          <template v-else>
            <v-data-table v-model="selectedEnvelopeTemplates"
                          :headers="dataTableHeaders"
                          :items="computedEnvelopeTemplates"
                          show-select
                          item-key="id"
                          hide-default-footer
                          :items-per-page="-1"
                          fixed-header
                          must-sort
                          sort-by="title"
                          @click:row="selectRow"
            >
              <template #header.data-table-select="{props, on}">
                <v-simple-checkbox :value="props.value"
                                   :indeterminate="props.indeterminate"
                                   color="primary"
                                   :ripple="false"
                                   @input="on.input($event)"
                />
              </template>
              <template #header.sharing="{props, on}">
                <AppTooltip top>
                  <template #activator="{attrs, on}">
                    <span v-bind="attrs"
                         v-on="on"
                    >
                      {{ $t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.dataTableHeaders.sharing')}}
                    </span>
                  </template>
                  <span>{{ $t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.sharingHeaderTooltip') }}</span>
                </AppTooltip>
              </template>
              <template #item.data-table-select="{isSelected, select}">
                <v-simple-checkbox :value="isSelected"
                                   color="primary"
                                   :ripple="false"
                                   @input="select"
                />
              </template>
              <template #item.title="{item}">
                <Name :envelope-template-name="item.title"/>
              </template>
              <template #item.types="{item}">
                <Type :envelope-template="item"/>
              </template>
              <template #item.sharing="{item}">
                <Sharing :share-type="item.shareType" :with-users="item.rights.users"/>
              </template>
              <template #item.createdBy="{item}">
                <CreatedBy :user="item.createdBy"/>
              </template>
              <template #item.signatures="{item}">
                <Signatory :signatures="item.signatures"/>
              </template>
              <template #item.options="{item}">
                <Options :envelope-template="item"/>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDataTableSkeletonLoader from '@/common/AppDataTableSkeletonLoader.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import SearchTextField from '@/common/SearchTextField.vue'
import AppAlertInPage from '@/design-system/alert/AppAlertInPage.vue'
import AppMultipleSelectionBar from '@/design-system/multiple-selection-bar/AppMultipleSelectionBar.vue'
import AppMultipleSelectionBarButton from '@/design-system/multiple-selection-bar/AppMultipleSelectionBarButton.vue'
import AppText from '@/design-system/typography/AppText.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import ConfirmDeleteDialog from '@/project/signing-checklist/envelope-templates/dialogs/ConfirmDeleteDialog.vue'
import EnvelopeTemplatesNoData
  from '@/project/signing-checklist/envelope-templates/EnvelopeTemplatesNoData.vue'
import CreatedBy from '@/project/signing-checklist/envelope-templates/table-columns/CreatedBy.vue'
import Name from '@/project/signing-checklist/envelope-templates/table-columns/Name.vue'
import Options from '@/project/signing-checklist/envelope-templates/table-columns/Options.vue'
import Sharing from '@/project/signing-checklist/envelope-templates/table-columns/Sharing.vue'
import Signatory from '@/project/signing-checklist/envelope-templates/table-columns/Signatory.vue'
import Type from '@/project/signing-checklist/envelope-templates/table-columns/Type.vue'
import { ENVELOPE_TEMPLATE_ADD_ROUTE_NAME, SIGNING_CHECKLIST_ADD_ROUTE_NAME } from '@/router'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'EnvelopeTemplatesDialog',
  components: {
    AppAlertInPage,
    AppButton,
    AppDataTableSkeletonLoader,
    AppDialog,
    AppMultipleSelectionBar,
    AppMultipleSelectionBarButton,
    AppText,
    AppTooltip,
    ConfirmDeleteDialog,
    CreatedBy,
    EnvelopeTemplatesNoData,
    Name,
    Options,
    SearchTextField,
    Sharing,
    Signatory,
    Type,
  },
  mixins: [dialogMixin],
  data () {
    return {
      isConfirmDeleteDialogOpen: false,
      searchQuery: '',
      dataTableHeaders: [
        {
          text: this.$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.dataTableHeaders.name'),
          sortable: true,
          class: 'text--primary text-uppercase',
          value: 'title',
          sort: (a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
          },
        },
        {
          text: this.$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.dataTableHeaders.types'),
          class: 'text--primary text-uppercase',
          value: 'types',
          sortable: false,
        },
        {
          text: this.$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.dataTableHeaders.sharing'),
          class: 'text--primary text-uppercase',
          value: 'sharing',
          sort: (a, b) => ({
            numbernumber: a - b,
            stringnumber: 1,
            numberstring: -1,
            stringstring: a > b ? 1 : -1,
          }[typeof (a) + typeof (b)]),
        },
        {
          text: this.$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.dataTableHeaders.createdBy'),
          class: 'text--primary text-uppercase',
          value: 'createdBy',
          sortable: true,
          sort: (a, b) => {
            const aValue = a.id === this.profile.id ? this.$t('common.me') : a.fullName
            const bValue = b.id === this.profile.id ? this.$t('common.me') : b.fullName
            return aValue.toLowerCase().localeCompare(bValue.toLowerCase())
          },
        },
        {
          text: this.$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.dataTableHeaders.signatories'),
          class: 'text--primary text-uppercase',
          value: 'signatures',
          sort: (a, b) => {
            return a.length - b.length
          },
        },
        {
          value: 'options',
          align: 'end',
          sortable: false,
        },
      ],
      selectedEnvelopeTemplates: [],
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapState('envelopeTemplates', ['envelopeTemplates', 'getEnvelopeTemplatesPending', 'deleteEnvelopeTemplatesPending']),
    ...mapGetters('user', ['isSubPayingUser', 'currentUserId']),
    canDeleteSelectedTemplates () {
      return this.selectedEnvelopeTemplates.every(item => item.createdBy.id === this.currentUserId)
    },
    computedEnvelopeTemplates () {
      const filteredList = this.envelopeTemplates.filter(e => {
        return e.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
      const sortableList = filteredList.map(template => {
        template.sharing = template.rights.isPublic ? -1 : template.rights.users.length
        return template
      })
      return sortableList
    },
    isLoading () {
      return this.getEnvelopeTemplatesPending && !this.envelopeTemplates
    },
    hasNoData () {
      return !this.envelopeTemplates || !this.envelopeTemplates.length
    },
  },
  created () {
    this.getEnvelopeTemplates()
  },
  methods: {
    ...mapActions('envelopeTemplates', ['GET_ENVELOPE_TEMPLATES']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async getEnvelopeTemplates () {
      try {
        await this.GET_ENVELOPE_TEMPLATES()
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.envelope-templates.dialogs.EnvelopeTemplatesDialog.getEnvelopeTemplatesError'))
      }
    },
    navigateToEnvelopeTemplateCreation () {
      this.$router.push({ name: ENVELOPE_TEMPLATE_ADD_ROUTE_NAME })
    },
    async onConfirmDeleteDialogClose () {
      this.isConfirmDeleteDialogOpen = false
      this.selectedEnvelopeTemplates = []
    },
    goToSignaturePreparation () {
      this.$router.push({
          name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
          query: {
            step: 2,
            envelopeTemplatesIds: this.selectedEnvelopeTemplates.map(e => e.id).toString(),
          },
        })
    },
    selectRow (value, row) {
      row.select(!row.isSelected)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-data-table__wrapper {
  max-height: 300px;
}
.envelopeTemplates-count {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--v-originalGrey-lighten4);
  border-radius: 25px;
  color: var(--v-originalGrey-darken2);
}
</style>
