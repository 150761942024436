<template>
<div>
  <app-text variant="small-bold" class="mt-6 mb-0">{{ documentsBundleTitle }}</app-text>
  <i18n class="mb-0"
        path="project.signing-checklist.dialogs.SigningChecklistDocumentsBundleSection.documentsBundleText"
        tag="p"
  >
  <template #link>
      <a :href="documentsBundleLink"
          target="_blank"
          class="learnMoreLink font-weight-bold d-inline-flex align-center column-gap-2"
      >
      {{$t('project.signing-checklist.dialogs.SigningChecklistDocumentsBundleSection.documentsBundleTextLink')}}
      <app-icon icon-name="arrow-up-right-from-square"
                  size="small"
      />
      </a>
  </template>
  </i18n>
  <AppToggle  :value="currentRoom.allowEnvelopeGrouping"
              :label="$t('project.signing-checklist.dialogs.SigningChecklistDocumentsBundleSection.toggleLabel')"
              :loading="patchRoomPending"
              :disabled="currentRoom.inClosing"
              @input="onChangeEnvelopeGroupingToggle"
  />
</div>
</template>

<script>
import { mapState } from 'vuex'

import AppToggle from '@/design-system/inputs/AppToggle'

export default {
  name: 'SigningChecklistDocumentsBundleSection',
  components: { AppToggle },
  computed: {
    ...mapState('room', ['currentRoom', 'patchRoomPending']),
    documentsBundleTitle () {
      return this.currentRoom.allowEnvelopeGrouping ? this.$t('project.signing-checklist.dialogs.SigningChecklistDocumentsBundleSection.documentsBundleTitle') : this.$t('project.signing-checklist.dialogs.SigningChecklistDocumentsBundleSection.documentsBundleOffTitle')
    },
    documentsBundleLink () {
      if (this.$i18n.locale === 'fr') {
        return 'https://help.closd.com/fr/knowledge-base/comment-grouper-les-documents-a-signer-pour-vos-signataires-beta/'
      }

      return 'https://help.closd.com/en/knowledge-base/bundle-documents-in-the-signing-checklist-page-beta/'
    },
  },
  methods: {
    onChangeEnvelopeGroupingToggle () {
     this.$emit('change-toggle')
    },
  },
}
</script>
