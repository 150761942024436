import store from '@/store/'

type PendoEvent =
  | 'create_home_folder'
  | 'duplicate_M365_document'
  | 'open_M365_document'
  | 'request_document_ai_summary'
  | 'swap_success'

declare global {
  interface Window {
    // We disable it because we don't manage that type, and they don't provide a type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pendo: {
      track (eventName: PendoEvent, payload?: Record<PropertyKey, unknown>): void
    }
  }
}

/**
 * A function that wraps the pendo agent track method safely.
 * Can also be used to add more information to every tracked event.
 * @param {string} eventName Name of the event to track
 * @param {object} payload Object containing properties to send to Pendo
 */
function track (eventName:PendoEvent, payload?: Record<PropertyKey, unknown>) {
  if (store.state.global.isPendoLoaded) {
    window.pendo.track(eventName, payload)
  }
}
export { track }
