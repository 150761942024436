<template>
  <div class="fill-height d-flex align-center justify-center" style="flex-direction: column;">
      <img src="/img/logo.svg"
          width="150"
      />
      <form ref="fileDownloadForm"
          :action="link"
          method="post"
      >
        <!-- The bearer token and path token shouldn't conflict and this enables using this auto form for paths that only need the bearer token -->
          <input
           v-if="bearerToken"
           type="hidden"
           :value="bearerToken"
           name="api_token"
          >
      </form>
      <span style="cursor: pointer" @click="getFile">{{ $t('login.views.DownloadFromToken.clickForDl') }}</span>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'DownloadFromToken',
  props: {
    mnemo: String,
    /**
     * Token used as a prop. Can be empty but for the link to work the user should have an api_token
     */
    token: {
      type: String,
    },
  },
  computed: {
    bearerToken () {
      return axios.defaults.headers.common.Authorization
    },
    link () {
      return `${process.env.VUE_APP_API_URL}${this.$route.path}`
    },
  },
  mounted () {
    this.getFile()
  },
  methods: {
    getFile () {
      this.$refs.fileDownloadForm.submit()
    },
  },
}
</script>
