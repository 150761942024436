<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('common.confirm')"
             size="l"
             @ok="confirm"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('project.signing-checklist.dialogs.QualifiedDSSignatureWarningDialog.title') }}</template>

    <template #body>
      <app-text variant="small-regular">
        {{ $t('project.signing-checklist.dialogs.QualifiedDSSignatureWarningDialog.text') }}
      </app-text>

      <p>
        <v-checkbox
          v-model="doNotRemindMe"
          :label="$t('project.signing-checklist.dialogs.QualifiedDSSignatureWarningDialog.doNotRemindMeCheckboxLabel')"
          class="mt-4"
          hide-details
          @change="changeReminder"
        />
      </p>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'
import {
  MUTE_QUALIFIED_DS_SIGNATURE_WARNING,
  UNMUTE_QUALIFIED_DS_SIGNATURE_WARNING,
} from '@/store/modules/signing-checklist/action_types'

export default {
  name: 'QualifiedDSSignatureWarningDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  data () {
    return {
      doNotRemindMe: false,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['qualifiedDSWarningIsMuted']),
  },
  mounted () {
    this.doNotRemindMe = this.qualifiedDSWarningIsMuted
  },
  methods: {
    ...mapActions('signingChecklist', [MUTE_QUALIFIED_DS_SIGNATURE_WARNING, UNMUTE_QUALIFIED_DS_SIGNATURE_WARNING]),
    changeReminder (disableReminder) {
      if (disableReminder) {
        this.MUTE_QUALIFIED_DS_SIGNATURE_WARNING()
      } else {
        this.UNMUTE_QUALIFIED_DS_SIGNATURE_WARNING()
      }
    },
    confirm () {
      this.$emit('confirm')
    },
  },
}
</script>
