<template>
    <div class="text--primary">
      <v-row>
        <v-col>
          <div class="body-1 font-weight-medium mb-2">{{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.dataRoomSizeText') }}</div>
          <v-chip-group v-model="selectedData"
                        active-class="deep-purple white--text"
                        mandatory
                        @change="onDataRoomSettingsChange"
          >
            <v-chip v-for="item in data"
                    :key="`data-${item.value}`"
                    :value="item"
            >
              {{ item.text }}
            </v-chip>
            <v-chip class="customDataChip"
                    :value="selectedAdditionalData"
                    @click="onSelectedAdditionalDataBlur"
                    @input="onSelectedAdditionalDataBlur"
            >
              <v-select v-model="selectedAdditionalData"
                        :placeholder="$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.other')"
                        class="customDataSelect mt-0"
                        return-object
                        :items="additionalData"
                        rounded
                        dense
                        hide-details
                        @change="onSelectedAdditionalDataChange"
                        @blur="onSelectedAdditionalDataBlur"
              />
            </v-chip>
          </v-chip-group>
          <div v-if="hasAddonData"
               class="caption deep-purple--text font-weight-medium"
          >
            {{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.priceForAdditionalData', { size: computedAddonStorage, price: computedAddonStoragePrice }) }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="body-1 font-weight-medium mb-2">{{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.dataRoomDurationText') }}</div>
          <div>
            <v-chip-group v-model="selectedDuration"
                          active-class="deep-purple white--text"
                          mandatory
                          @change="onDataRoomSettingsChange"
            >
              <v-chip v-for="item in durations"
                      :key="`duration-${item.value}`"
                      :value="item"
              >
                {{ item.text }}
              </v-chip>
            </v-chip-group>
          </div>
          <div v-if="dataRoomCustomPricing && dataRoomCustomPricing.discountPercent"
               class="caption deep-purple--text font-weight-medium"
          >
            {{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.discount', { discount: dataRoomCustomPricing.discountPercent }) }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-if="dataRoomCustomPricing && newDataRoomData && newDataRoomDuration" class="pricing text--primary">
            <div v-if="dataRoomCustomPricing && dataRoomCustomPricing.customerDiscountPercent"
                 class="discount-title"
            >
              {{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.customerDiscount', { discount: dataRoomCustomPricing.customerDiscountPercent }) }}
            </div>
            <div class="body-1 font-weight-medium">{{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.amount') }}</div>
            <div v-if="dataRoomCustomPricing.discountPercent || dataRoomCustomPricing.customerDiscountPercent"
                 class="pricing-underline py-2"
            >
              <span>€ {{ dataRoomCustomPricing.monthlyCost }}</span>
              <span class="font-weight-medium">/ {{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.perMonth') }}</span>
            </div>
            <div>
              <template>
                <v-skeleton-loader v-if="dataRoomCustomPricingPending" type="text"/>
                <span v-else class="pricing-amountPerMonth">€ {{ $n(dataRoomCustomPricing.monthlyCostWithDiscount, 'decimal') }}</span>
                <span class="font-weight-medium">/ {{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.perMonth') }}</span>
              </template>
            </div>
            <template>
              <v-skeleton-loader v-if="dataRoomCustomPricingPending" type="text"/>
              <div v-else
                   class="mt-2"
                   v-html="$tc('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.totalAmount', newDataRoomDuration.value, { price: dataRoomCustomPricing.totalCostWithDiscount, storage: dataRoomCustomPricing.size, months: dataRoomCustomPricing.months, unit: $t(`projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.${dataRoomCustomPricing.unit}`) })"></div>
            </template>
            <template>
              <v-skeleton-loader v-if="dataRoomCustomPricingPending" type="text"/>
              <div v-else
                   class="mt-2"
                   v-html="$tc('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.commitment', newDataRoomDuration.value, { months: newDataRoomDuration.value, price: dataRoomCustomPricing.monthlyCostWithDiscount })"></div>
            </template>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="text--primary">
            <v-checkbox v-model="validator.$model"
                        :error-messages="termsCheckedError"
                        class="mt-0 pt-0"
            >
              <template #label>
                <span>{{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.terms.text1') }}
                <a class="deep-purple--text app-link" href="#" @click.stop>
                  <AppDownloadFile :link="dataroomTermsLink">
                    <template #default="{ submitForm }">
                      <span @click="submitForm">{{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.terms.text2') }}</span>
                    </template>
                  </AppDownloadFile>
                </a>
                {{ $t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.terms.text3') }}
                </span>
              </template>
            </v-checkbox>
          </div>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapState } from 'vuex'

import AppDownloadFile from '../../common/AppDownloadFile.vue'
import { GET_DATA_ROOM_CUSTOM_PRICING } from '../../store/modules/rooms/action_types'

export default {
  name: 'ProjectsNewDataroomDialogStep2',
  components: { AppDownloadFile },
  props: {
    newDataRoom: {
      type: Object,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      selectedDuration: null,
      selectedData: null,
      selectedAdditionalData: null,
      durations: [
        { value: 1, text: this.$tc('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.monthsCount', 1, { months: 1 }) },
        { value: 3, text: this.$tc('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.monthsCount', 3, { months: 3 }) },
        { value: 6, text: this.$tc('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.monthsCount', 6, { months: 6 }) },
        { value: 12, text: this.$tc('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.monthsCount', 12, { months: 12 }) },
      ],
      data: [
        { value: 500, unit: 'Mb', text: `500 ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Mb')}` },
        { value: 1, unit: 'Gb', text: `1 ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Gb')}` },
        { value: 2, unit: 'Gb', text: `2 ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Gb')}` },
        { value: 5, unit: 'Gb', text: `5 ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Gb')}` },
      ],
      additionalData: [
        { value: 10, unit: 'Gb', text: `10 ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Gb')}` },
        { value: 15, unit: 'Gb', text: `15 ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Gb')}` },
        { value: 20, unit: 'Gb', text: `20 ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Gb')}` },
        { value: 25, unit: 'Gb', text: `25 ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Gb')}` },
        { value: 30, unit: 'Gb', text: `30 ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Gb')}` },
      ],
    }
  },
  computed: {
    ...mapState('rooms', ['dataRoomCustomPricing', 'dataRoomCustomPricingPending']),
    newDataRoomData: {
      get () {
        return {
          value: this.newDataRoom.storage,
          unit: this.newDataRoom.unit,
        }
      },
      set (data) {
        this.newDataRoom.storage = data.value
        this.newDataRoom.unit = data.unit
      },
    },
    newDataRoomDuration: {
      get () {
        return {
          value: this.newDataRoom.duration,
        }
      },
      set (duration) {
        this.newDataRoom.duration = duration.value
      },
    },
    computedAddonStorage () {
      if (this.hasAddonData) {
        const size = this.dataRoomCustomPricing.additionalStorageSize
        if (size < 1) {
          return `${size * 1000} ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Mb')}`
        } else {
          return `${size} ${this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.sizeUnit.Gb')}`
        }
      }
      return null
    },
    computedAddonStoragePrice () {
      return this.dataRoomCustomPricing.customerDiscountPercent
        ? this.dataRoomCustomPricing.additionalStoragePriceWithDiscount
        : this.dataRoomCustomPricing.additionalStoragePrice
    },
    hasAddonData () {
      if (this.dataRoomCustomPricing) {
        return !!(this.dataRoomCustomPricing.additionalStorageSize && this.dataRoomCustomPricing.additionalStoragePrice)
      }
      return null
    },
    termsCheckedError () {
      const errors = []
      if (!this.validator.$dirty) return errors
      !this.validator.sameAs && errors.push(this.$t('projects.dialogs.ProjectsNewDataroomDialogSetupDataRoom.termsCheckedValidationError'))
      return errors
    },
    dataroomTermsLink () {
      return `${process.env.VUE_APP_API_URL}/assets/pdf?file=dataroom-price-list-${this.$i18n.locale.toUpperCase()}`
    },
  },
  methods: {
    ...mapActions('rooms', [GET_DATA_ROOM_CUSTOM_PRICING]),
    onDataRoomSettingsChange () {
      if (this.selectedDuration?.value && this.selectedData?.value && this.selectedData?.unit) {
        try {
          this.GET_DATA_ROOM_CUSTOM_PRICING({
            duration: this.selectedDuration.value,
            unit: this.selectedData.unit,
            storage: this.selectedData.value,
          })
          this.newDataRoomDuration = cloneDeep(this.selectedDuration)
          this.newDataRoomData = cloneDeep(this.selectedData)
        } catch (e) {
          console.error(e)
        }
      }
    },
    onSelectedAdditionalDataChange () {
      this.selectedData = this.selectedAdditionalData
      this.onDataRoomSettingsChange()
    },
    onSelectedAdditionalDataBlur () {
      if (!this.selectedData) {
        this.selectedData = this.data.find(d => d.value === this.newDataRoom.storage && d.unit === this.newDataRoom.unit)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.pricing {
  padding: 20px 30px;
  border: 1px solid #9e9e9e;
  background: #fafafa;
  border-radius: 8px;

  &-amountPerMonth {
    font-size: 28px;
    font-weight: bold;
    margin-right: 4px;
  }
  &-underline {
    font-weight: 600;
    font-size: 16px;
    color: #4CAF50;
    text-decoration: line-through;
  }
}
.customDurationChip,
.customDataChip {
  &.v-chip--active {
    ::v-deep .v-input__slot input,
    ::v-deep .v-select__selection,
    ::v-deep .v-input__append-inner {
      color: #fff;
    }
  }
}
::v-deep .customDataSelect {
  .v-input__control {
    min-height: 20px !important;
    margin-left: 4px;
    width: 80px;
    & .v-input__slot {
      padding: 0;
      background-color: transparent;
      box-shadow: none !important;
      & .v-input__append-inner {
        padding-left: 0;
        & .v-icon {
          font-size: 16px;
        }
      }
      & .v-select__selection--coma {
        text-overflow: unset;
      }
      input::placeholder {
        color: var(--v-accent-base);
      }
    }
  }
  &.v-input--is-focused {
    .v-input__append-inner .v-icon {
      color: #fff !important;
      caret-color: #fff !important;
    }
  }
}
.discount-title {
  position: relative;
  top: -20px;
  left: -30px;
  height: 35px;
  width: calc(100% + 60px);
  background-color: #91F21F66;

  display: flex;
  align-items:center;
  justify-content:center;

  font-weight: 600;
  font-size: 16px;
  color: #4CAF50;
}
</style>
