/**
 * Observes and retrieves selected dimension of an element when it changes.
 * Returns a function to remove the ResizeObserver.
 * @param {String} resizableElementSelector - Selector of the element to observe for dimension changes.
 * @param {String} dimensionToWatch - Selected dimension to watch : 'width' | 'height'
 * @param {Function} callback - A function to handle the new dimension of the observed element.
 * @returns {Function} - Function to remove the ResizeObserver.
 */
function handleElementDimensionResize (resizableElementSelector, dimensionToWatch, callback) {
    const resizableElement = document.querySelector(resizableElementSelector)
    if (!resizableElement) {
        console.error('Invalid element to watch')
        return
    }

    const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
            const newSize = entry.contentRect[dimensionToWatch]
            callback(newSize)
        }
    })

    resizeObserver.observe(resizableElement)

    return () => {
        resizeObserver.disconnect()
    }
}

export { handleElementDimensionResize }
