<template>
  <AppMenuAsPopover offset-x
                    top
                    :card-min-width="330"
                    :card-max-width="330"
                    @open="onMenuOpen"
  >
    <template #activator="{attrs, on}">
      <AppButton block
                 color="#eee"
                 rounded
                 v-bind="attrs"
                 v-on="on"
      >
        <font-awesome-icon :icon="['far', 'plus']"
                           class="mr-4"
        ></font-awesome-icon>
        {{ $t('project.checklist.task.ChecklistTableStatusNewStatus.createStatus') }}
      </AppButton>
    </template>
    <template #default="{closeMenu}">
      <v-card-title class="newStatus__card__title body-1 font-weight-medium d-flex align-center justify-space-between">
        {{ $t('project.checklist.task.ChecklistTableStatusNewStatus.createStatus') }}
        <AppButton icon
                   @click="closeMenu"
        >
          <font-awesome-icon :icon="['fal', 'xmark']"></font-awesome-icon>
        </AppButton>
      </v-card-title>
      <v-card-text class="text--primary pt-4">
        <v-row>
          <v-col>
            <div class="mb-4 font-weight-medium">{{$t('project.checklist.task.ChecklistTableStatusNewStatus.form.statusNameLabel')}}</div>
            <AppTextField v-model="$v.newStatus.label.$model"
                          class="required"
                          :error-messages="labelErrors"
                          :placeholder="$t('project.checklist.task.ChecklistTableStatusNewStatus.form.statusNamePlaceholder')"
                          :counter="45"
                          :maxlength="45"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="mb-4 font-weight-medium">{{$t('project.checklist.task.ChecklistTableStatusNewStatus.form.statusCategoryLabel')}}</div>
            <AppSelect v-model="$v.newStatus.category.$model"
                       :items="taskStatusCategoriesList"
                       item-value="name"
                       :error-messages="categoryErrors"
                       class="required"
            >
              <template #item="{item}">
                {{ $t(`common.statusCategoryNames.${item.name}`) }}
              </template>
              <template #selection="{item}">
                {{ $t(`common.statusCategoryNames.${item.name}`) }}
              </template>
            </AppSelect>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="mb-4 font-weight-medium">{{$t('project.checklist.task.ChecklistTableStatusNewStatus.form.statusColorLabel')}}</div>
            <ChecklistTableStatusColorPicker v-model="$v.newStatus.color.$model"/>
            <span v-if="colorErrors.length"
                  class="caption error--text">
              {{ colorErrors[0] }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <AppButton color="white"
                       class="mr-4"
                       :disabled="postStatusPending"
                       @click="closeMenu"
            >
              {{ $t('common.cancel') }}
            </AppButton>
            <AppButton color="primary"
                       :loading="postStatusPending"
                       @click="preparePostStatus(closeMenu)"
            >
              {{ $t('common.register') }}
            </AppButton>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </AppMenuAsPopover>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import ChecklistTableStatusColorPicker from './ChecklistTableStatusColorPicker'
import AppMenuAsPopover from '../../../common/AppMenuAsPopover'
import AppSelect from '../../../common/AppSelect'
import AppTextField from '../../../common/AppTextField'
import AppButton from '../../../common/buttons/AppButton'
import { POST_STATUS } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'ChecklistTableStatusNewStatus',
  components: { ChecklistTableStatusColorPicker, AppSelect, AppTextField, AppMenuAsPopover, AppButton },
  mixins: [validationMixin],
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  validations: {
    newStatus: {
      label: { required },
      category: { required },
      color: { required },
    },
  },
  data () {
    return {
      newStatus: {
        label: '',
        category: this.category,
        color: '',
      },
    }
  },
  computed: {
    ...mapState('checklist', ['postStatusPending', 'taskStatusCategories']),
    ...mapGetters('checklist', ['taskStatusCategoriesList']),
    ...mapGetters('room', ['roomMnemo']),
    labelErrors () {
      const errors = []
      if (!this.$v.newStatus.label.$dirty) return errors
      !this.$v.newStatus.label.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.checklist.task.ChecklistTableStatusNewStatus.form.statusNameLabel') }))
      return errors
    },
    categoryErrors () {
      const errors = []
      if (!this.$v.newStatus.category.$dirty) return errors
      !this.$v.newStatus.category.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.checklist.task.ChecklistTableStatusNewStatus.form.statusCategoryLabel') }))
      return errors
    },
    colorErrors () {
      const errors = []
      if (!this.$v.newStatus.color.$dirty) return errors
      !this.$v.newStatus.color.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.checklist.task.ChecklistTableStatusNewStatus.form.statusColorLabel') }))
      return errors
    },
  },
  methods: {
    ...mapActions('checklist', [POST_STATUS]),
    onMenuOpen () {
      this.$v.$reset()
      this.newStatus = {
        label: '',
        category: this.category,
        color: '',
      }
    },
    async preparePostStatus (closeMenuFunction) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const statusCategoriesToPost = cloneDeep(this.taskStatusCategories)
        statusCategoriesToPost[this.newStatus.category].unshift({
          label: this.newStatus.label,
          color: this.newStatus.color,
        })

        try {
          await this.POST_STATUS({
            mnemo: this.roomMnemo,
            data: { statuses: statusCategoriesToPost },
          })
        } catch (error) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.task.ChecklistTableStatusNewStatus.postStatusError'),
          })
        } finally {
          closeMenuFunction()
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.newStatus {
  &__card {
    &__title {
      border-bottom: 1px solid var(--v-grey-lighten1);
    }
  }
}
</style>
