<template>
  <v-list-item
    key="itemMenu-polyOffice-import"
    @click="handleClick"
  >
    <v-list-item-title>
      <div class="d-flex align-center">
        {{ $t('common.polyOffice.PolyOfficeListItemImport.importFiles') }}
        <v-img src="/img/LexisPoly.svg"
               max-height="18"
               max-width="18"
               class="ml-2"
        />
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script>

export default {
  name: 'PolyOfficeListItemImport',
  methods: {
    handleClick () {
      this.$emit('polyOfficeOpenDialog')
    },
  },
}
</script>
