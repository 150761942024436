<template>
  <div class="QuestionAnswerNoData">
    <v-img src="/img/qna_illustration.svg"
           width="325"
           class="mx-auto mb-8"
    />
    <div class="noDataText mt-2 mb-8">
      {{ $t('project.question-answer.QuestionAnswerNoData.noQuestions') }}
    </div>

    <QuestionAnswerTopBarAdd @open-import-excel-dialog="$emit('open-import-excel-dialog', $event)"/>
  </div>
</template>

<script>
  import QuestionAnswerTopBarAdd from './QuestionAnswerTopBarAdd.vue'

  export default {
    name: 'QuestionAnswerNoData',
    components: {
      QuestionAnswerTopBarAdd,
    },
    methods: {
      openImportDialog () {
        this.$emit('open-import-excel-dialog')
      },
    },
  }
</script>

<style lang="scss">
  .QuestionAnswerNoData {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;

    .app-button {
      margin: auto;
    }
  }
</style>
