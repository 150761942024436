const ROOM_TYPES = Object.freeze({
  // Unlimited projects
  PROJECT_UNLIMITED: 'PRJ',
  // Pay per use Dataroom
  DATAROOM: 'PPD',
  // "Au forfait" dataroom (alternatively called "dataroom on a plan")
  DATAROOM_UNLIMITED: 'DR',
})

export { ROOM_TYPES }
