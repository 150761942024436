<template>
<v-row>
  <v-col>
    <app-alert-in-page class="mb-6" variant="info" :dismissible="false">
      <v-row>
        <v-col class="body-1 font-weight-medium">
          {{ computedTitle }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="d-md-flex align-center justify-space-between">
            <div class="d-flex">
              <div v-if="startDate"
                   class="d-flex mr-6"
              >
                <font-awesome-icon :icon="['far', 'calendar-alt']"
                                   class="info--text mr-2"
                ></font-awesome-icon>
                <div class="mr-2">{{ $t('project.signing-checklist.list.SigningChecklistSigningInfo.startDate') }}</div>
                <div class="font-weight-medium">{{ startDate }}</div>
              </div>
              <div v-if="endDate"
                   class="d-flex"
              >
                <font-awesome-icon :icon="['far', 'calendar-alt']"
                                   class="info--text mr-2"
                ></font-awesome-icon>
                <div class="mr-2">{{ $t('project.signing-checklist.list.SigningChecklistSigningInfo.endDate') }}</div>
                <div class="font-weight-medium">{{ endDate }}</div>
              </div>
            </div>
            <div v-if="isCurrentUserPm" class="d-flex mt-4 mt-md-0">
              <AppButton color="info"
                         link
                         class="mr-4"
                         @click="$emit('editSigningDates')"
              >
                <font-awesome-icon :icon="['far', 'edit']" class="mr-1"></font-awesome-icon>
                {{ $t('project.signing-checklist.list.SigningChecklistSigningInfo.modify') }}
              </AppButton>
              <AppTooltip top :disabled="!isSendingDocument">
                <template #activator="{ on, attrs }">
                  <div v-on="on">
                    <AppButton v-if="closingInProgress"
                            color="info"
                            outlined
                            :disabled="isSendingDocument"
                            v-bind="attrs"
                            @click="cancelClosingDialogIsOpen = true"
                    >
                      <font-awesome-icon :icon="['far', 'xmark']" class="mr-2"></font-awesome-icon>
                      {{ $t('project.signing-checklist.list.SigningChecklistSigningInfo.cancelSigning') }}
                    </AppButton>
                  </div>
                </template>
                {{ $t('project.signing-checklist.list.SigningChecklistSigningInfo.tooltipDisabledCancelSigning') }}
              </AppTooltip>
              <AppButton v-if="closingScheduled"
                         class="btn-allow-wrap"
                         color="info"
                         outlined
                         @click="cancelScheduledSigningDialogIsOpen = true"
              >
                <font-awesome-icon :icon="['far', 'xmark']" class="mr-2"></font-awesome-icon>
                {{ $t('project.signing-checklist.list.SigningChecklistSigningInfo.cancelScheduledSigning') }}
              </AppButton>
            </div>
          </div>
        </v-col>
      </v-row>
    </app-alert-in-page>
    <CancelScheduledSigningDialog v-if="cancelScheduledSigningDialogIsOpen && isCurrentUserPm"
                                  :mnemo="mnemo"
                                  @close="cancelScheduledSigningDialogIsOpen = false"
    />
    <SigningChecklistCancelClosingDialog v-if="cancelClosingDialogIsOpen && isCurrentUserPm"
                                         :mnemo="mnemo"
                                         @close="cancelClosingDialogIsOpen = false"
    />
  </v-col>
</v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import AppButton from '@/common/buttons/AppButton'
import { ISOToShortDate } from '@/common/utils/dates'
import CancelScheduledSigningDialog from '@/project/signing-checklist/list/CancelScheduledSigningDialog'
import SigningChecklistCancelClosingDialog from '@/project/signing-checklist/list/SigningChecklistCancelClosingDialog'

export default {
  name: 'SigningChecklistSigningInfo',
  components: { AppButton, AppTooltip, CancelScheduledSigningDialog, SigningChecklistCancelClosingDialog },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    isSendingDocument: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      cancelScheduledSigningDialogIsOpen: false,
      cancelClosingDialogIsOpen: false,
    }
  },
  computed: {
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['isCurrentUserPm']),
    closingInProgress () {
      return this.currentRoom.inClosing
    },
    closingScheduled () {
      return this.currentRoom.closingScheduledAt && !this.closingInProgress
    },
    computedTitle () {
      return this.closingInProgress ? this.$t('project.signing-checklist.list.SigningChecklistSigningInfo.inClosingTitle') : this.$t('project.signing-checklist.list.SigningChecklistSigningInfo.scheduledTitle')
    },
    startDate () {
      if (this.currentRoom.closingScheduledAt) {
        return ISOToShortDate(this.currentRoom.closingScheduledAt)
      } else if (this.currentRoom.closingStartedAt) {
        return ISOToShortDate(this.currentRoom.closingStartedAt)
      }
      return null
    },
    endDate () {
      if (this.currentRoom.closingScheduledEndAt) {
        return ISOToShortDate(this.currentRoom.closingScheduledEndAt)
      }
      return null
    },
  },
}
</script>
