import { uniqWith } from 'lodash-es'

import {
  ADD_GROUP_TO_SELECTED,
  ADD_MEMBERS_TO_SELECTED,
  REMOVE_GROUP_FROM_SELECTED,
  REMOVE_MEMBERS_FROM_SELECTED,
  ADD_MEMBERS_TO_DISABLED,
  SET_SELECTED_GROUPS,
  SET_SELECTED_MEMBERS,
  SET_DISABLED_MEMBERS,
  SET_HIGHLIGHTED_MEMBER,
} from './mutation_types'

export const mutations = {
  [ADD_MEMBERS_TO_DISABLED]: function (state, members) {
    state.disabledMembers = uniqWith(
      [...state.disabledMembers, ...members],
      (memberA, memberB) => memberA.id === memberB.id,
    )
  },
  [SET_DISABLED_MEMBERS]: function (state, members) {
    state.disabledMembers = members
  },
  [ADD_GROUP_TO_SELECTED]: function (state, group) {
    const itemIndex = state.selectedGroups.findIndex(item => item.id === group.id)

    if (itemIndex < 0) {
      state.selectedGroups.push(group)
    }
  },
  [REMOVE_GROUP_FROM_SELECTED]: function (state, group) {
    const itemIndex = state.selectedGroups.findIndex(item => item.id === group.id)

    if (itemIndex >= 0) {
      state.selectedGroups.splice(itemIndex, 1)
    }
  },
  [SET_SELECTED_GROUPS]: function (state, groups) {
    state.selectedGroups = groups
  },
  [ADD_MEMBERS_TO_SELECTED]: function (state, members) {
    state.selectedMembers = uniqWith(
      [...state.selectedMembers, ...members],
      (groupA, groupB) => groupA.id === groupB.id,
    )
  },
  [REMOVE_MEMBERS_FROM_SELECTED]: function (state, members) {
    state.selectedMembers = state.selectedMembers.filter(member => members.findIndex(m => m.id === member.id) < 0)
  },
  [SET_SELECTED_MEMBERS]: function (state, members) {
    state.selectedMembers = members
  },
  [SET_HIGHLIGHTED_MEMBER]: function (state, member) {
    state.highlightedMember = member
  },
}
