<template>
  <AppFileUploadFilder :filder="folder"
                       :filder-name="folder.name"
                       :filder-progress="folderProgress"
                       :filder-status="folderStatus"
                       @cancelPendingFilder="onCancelPendingFolder"
                       @cancelInProgressFilder="onCancelInProgressFolder"
                       @redoFilder="onRedoFolder"
  />
</template>

<script>
import { mapActions } from 'vuex'

import AppFileUploadFilder from '@/common/app-file-upload/AppFileUploadFilder'
import { flatten } from '@/common/utils/flatArray'

import { uploadStatus } from './uploadStatus'
export default {
  name: 'AppFileUploadFolder',
  components: { AppFileUploadFilder },
  props: {
    folder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    folderFiles () {
      const flatFiles = []
      flatten(flatFiles, this.folder)
      return flatFiles.filter(f => f.type === 'file')
    },
    folderProgress () {
      return Math.round(this.folderFiles.reduce((acc, next) => acc + next.progress, 0) / this.folderFiles.length)
    },
    folderStatus () {
      if (this.folderFiles.every(file => file.status === uploadStatus.SUCCESS)) {
        return uploadStatus.SUCCESS
      } else if (this.folderFiles.every(file => file.status === uploadStatus.PENDING)) {
        return uploadStatus.PENDING
      } else if (this.folderFiles.every(file => file.status === uploadStatus.CANCELED)) {
        return uploadStatus.CANCELED
      } else if (this.folderFiles.find(file => file.status === uploadStatus.IN_PROGRESS)) {
        return uploadStatus.IN_PROGRESS
      } else if (this.folderFiles.find(file => file.status === uploadStatus.ERROR)) {
        return uploadStatus.ERROR
      }
      return ''
    },
  },
  methods: {
    ...mapActions('appUploadHandler', ['cancelFile', 'redoFileUpload']),
    onCancelPendingFolder () {
      this.folderFiles.forEach(file => {
        this.cancelFile(file.key)
      })
    },
    onCancelInProgressFolder () {
      this.onCancelPendingFolder()
    },
    onRedoFolder () {
      this.folderFiles.forEach(file => {
        if (file.status === uploadStatus.ERROR) {
          this.redoFileUpload(file.key)
        }
      })
    },
  },
}
</script>
