import { render, staticRenderFns } from "./DocumentSelectionListItem.vue?vue&type=template&id=ae05cbc0&scoped=true"
import script from "./DocumentSelectionListItem.vue?vue&type=script&lang=js"
export * from "./DocumentSelectionListItem.vue?vue&type=script&lang=js"
import style0 from "./DocumentSelectionListItem.vue?vue&type=style&index=0&id=ae05cbc0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae05cbc0",
  null
  
)

export default component.exports