import {
  CLOSE_DOCUMENTS_UPLOAD_FILES,
  OPEN_DOCUMENTS_UPLOAD_FILES,
  START_UPLOAD,
} from './action_types'
import { RESET_SELECTED_DOCUMENTS, SET_IS_OPEN } from './mutation_types'

export const actions = {
  [OPEN_DOCUMENTS_UPLOAD_FILES]: function ({ commit }) {
    commit(SET_IS_OPEN, true)
  },
  [CLOSE_DOCUMENTS_UPLOAD_FILES]: function ({ commit }) {
    commit(SET_IS_OPEN, false)
    commit(RESET_SELECTED_DOCUMENTS)
  },
  [START_UPLOAD]: function ({ dispatch, state }) {
    dispatch('appUploadHandler/addFilesToUpload', state.selectedDocuments, { root: true })
    dispatch(CLOSE_DOCUMENTS_UPLOAD_FILES)
  },
}
