<template>
  <div class="AppFileUploadFilder">
    <AppTooltip top>
      <template #activator="{attrs, on}">
        <div class="AppFileUploadFilder__name"
             v-bind="attrs"
             v-on="on"
        >
          <slot name="filderName"></slot>
          <ClosdFildersIcon :document="filder.fileObject || filder" size="22" small/>
          <span class="caption font-weight-semi-bold">{{ filderName }}</span>
        </div>
      </template>
      <span>{{ filderName }}</span>
    </AppTooltip>
    <div class="AppFileUploadFilder__action">
      <div v-if="filderStatus === uploadStatus.PENDING || filderStatus === uploadStatus.IN_PROGRESS"
           @mouseenter="progressHover = true"
           @mouseleave="progressHover = false"
      >
        <template v-if="progressHover">
          <AppTooltip top>
            <template #activator="{attrs, on}">
              <AppButton icon
                         v-bind="attrs"
                         v-on="on"
                         @click="onCancelClick"
              >
                <font-awesome-icon :icon="['far', 'xmark']"
                                   class="body-1 text--primary"
                ></font-awesome-icon>
              </AppButton>
            </template>
            <span>{{ $t('common.cancel') }}</span>
          </AppTooltip>
        </template>
        <template v-else>
          <v-progress-circular :value="filderProgress"
                               color="info"
                               size="30"
                               width="3"
          >
          </v-progress-circular>
        </template>
      </div>
      <font-awesome-icon v-if="filderStatus === uploadStatus.SUCCESS"
                         :icon="['far', 'check']"
                         class="success--text"
      ></font-awesome-icon>
      <div v-if="filderStatus === uploadStatus.CANCELED"
           class="caption font-weight-semi-bold originalGrey--text"
      >
        {{ $t('common.app-file-upload.AppFileUploadFilder.canceledImport') }}
      </div>
      <template v-if="filderStatus === uploadStatus.ERROR">
        <div v-if="filderError">
          {{ filderError }}
        </div>
        <AppButton v-else
                   icon
                   @click="redoFilder"
        >
          <font-awesome-icon :icon="['far', 'redo']"
                             class="error--text"
          ></font-awesome-icon>
        </AppButton>
      </template>
    </div>
  </div>
</template>

<script>
import { uploadStatus } from './uploadStatus'
import AppTooltip from '../AppTooltip'
import AppButton from '../buttons/AppButton'
import ClosdFildersIcon from '../filders/ClosdFildersIcon'

export default {
  name: 'AppFileUploadFilder',
  components: { AppButton, AppTooltip, ClosdFildersIcon },
  props: {
    filder: {
      type: Object,
      required: true,
    },
    filderName: {
      type: String,
      required: true,
    },
    filderProgress: {
      type: Number,
      required: true,
    },
    filderStatus: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      uploadStatus,
      progressHover: false,
    }
  },
  computed: {
    filderError () {
      if (this.filder.error) {
        if (this.filder.error === 'ERR_WRONG_FILE_TYPE' || this.filder.error === 'ERR_FILE_TOO_LARGE') {
          return this.$t(`common.app-file-upload.AppFileUploadFilder.error.${this.filder.error}`)
        }
      }
      return null
    },
  },
  methods: {
    onCancelClick () {
      this.filderStatus === this.uploadStatus.PENDING ? this.cancelPendingFilder() : this.cancelInProgressFilder()
    },
    cancelPendingFilder () {
      this.$emit('cancelPendingFilder')
    },
    cancelInProgressFilder () {
      this.$emit('cancelInProgressFilder')
    },
    redoFilder () {
      this.$emit('redoFilder')
    },
  },
}
</script>

<style scoped lang="scss">
.AppFileUploadFilder {
  display: grid;
  grid-template-columns: minmax(30%, 100%) auto;
  align-items: center;
  border-bottom: 1px solid var(--v-originalGrey-lighten2);
  column-gap: 10px;
  margin-bottom: 5px;
  min-height: 40px;

  &__name {
    max-width: 70%;
    display: flex;
    align-items: center;
    & span {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
