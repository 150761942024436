<template>
  <div class="signer-assignation-list-item">
    <div class="d-flex align-center">
      <SignerColorChip :color="genericSignature.signerColor" class="mr-3" />
      <app-text as="span" variant="small-regular" class="required-asterisk">
        {{ getSignerName }}
      </app-text>
    </div>

    <!-- No special requirement for this width, only matching mockups dimensions -->
    <div style="width: 344px">
      <AppSelect v-model="assignedSigner"
                 :items="signers"
                 item-text="name"
                 item-value="id"
                 return-object
                 no-label
                 class="signer-selector"
                 :placeholder="$t('project.signing-checklist.add.step2.signers-assignation.SigningChecklistSignersAssignationListItem.signerSelectorPlaceholder')"
                 :error-messages="missingAssignedSignerErrorMessage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'

import AppSelect from '@/common/AppSelect.vue'
import SignerColorChip from '@/common/signer/SignerColorChip.vue'
import { formatNumberedSignerName } from '@/common/utils/signingChecklist'
import { ENVELOPE_TYPES } from '@/project/signing-checklist/constants'

import { type GenericSignature } from './SigningChecklistSignersAssignationList.vue'

export type GenericSignatureListItem = GenericSignature & {
  signerColor: string
}

export default defineComponent({
  name: 'SigningChecklistSignersAssignationListItem',
  components: {
    AppSelect,
    SignerColorChip,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    envelopeType: {
      type: String as PropType<ENVELOPE_TYPES>,
      required: true,
    },
    genericSignature: {
      type: Object as PropType<GenericSignatureListItem>,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
    signers: {
      type: Array as PropType<Array<EnvelopeSigner>>,
      default: () => [],
    },
  },
  computed: {
    assignedSigner: {
      get (): EnvelopeSigner | null {
        return this.genericSignature.assignedSigner
      },
      set (signer: EnvelopeSigner): void {
        this.$emit('assign-signer', this.genericSignature.id, signer)
      },
    },
    missingAssignedSignerErrorMessage (): Array<TranslateResult> | null {
      if (this.validator.$dirty && !this.validator.assignedSigner.required) {
        return [this.$t('project.signing-checklist.add.step2.signers-assignation.SigningChecklistSignersAssignationListItem.signerRequiredErrorMessage')]
      }

      return null
    },
    getSignerName () : string {
      if (this.genericSignature.signerName) return this.genericSignature.signerName
      return formatNumberedSignerName(this.genericSignature, this.index, this.envelopeType === ENVELOPE_TYPES.MULTIPLE)
    },
  },
})
</script>

<style scoped lang="scss">
.signer-assignation-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
