<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             :ok-text="$t('common.confirm')"
             :ok-loading="cancelClosingPending"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>{{ $t('project.signing-checklist.list.SigningChecklistCancelClosingDialog.title') }}</template>

    <template #body>
      <p v-html="$t('project.signing-checklist.list.SigningChecklistCancelClosingDialog.body')"></p>
    </template>
  </AppDialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { CANCEL_CLOSING } from '../../../store/modules/signing-checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

const { mapState, mapActions } = createNamespacedHelpers('signingChecklist')

export default {
  name: 'SigningChecklistCancelClosingDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['cancelClosingPending']),
  },
  methods: {
    ...mapActions([CANCEL_CLOSING]),
    async confirmClick () {
      try {
        await this.CANCEL_CLOSING(this.mnemo)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          message: this.$t('project.signing-checklist.list.SigningChecklistCancelClosingDialog.success'),
          color: 'success',
        })
      } finally {
        this.$emit('confirm')
        this.closeDialog()
      }
    },
  },
}
</script>
