export const state = {
  documentsToMove: {},
  duplicateLoading: false,
  lastMnemoAccessed: '',
  lastAccessTimestamp: 0,
  loading: false,
  shareLoading: false,
  moveLoading: false,
  deleteLoading: false,
  searchLoading: false,
  searchResults: [],
  versionsLoading: false,
  documents: {},
  fullTree: {},
  error: null,
  selectedDocuments: [],
  numberingEnabled: false,
  numberingError: null,
  patchNumberingPending: false,
  documentsPane: {
    isOpen: false,
    tab: '',
  },
  documentsTablePane: {
    documents: [],
    forcePmSelection: false,
    isOpen: false,
    previousDocument: null,
    tab: '',
  },
  documentsVersioningPane: {
    documentVersions: [],
    documents: [],
    isOpen: false,
    removeVersionPending: false,
    tab: '',
  },
  searchModeEnabled: false,
  filters: [],
  filtersPaneIsOpen: false,
  uploadHiddenFilePendingIds: [],
  postFildersFromImanagePending: false,
  postFildersFromImanageError: null,
  postFildersToImanagePending: false,
  postFildersToImanageError: null,
  postFildersAsVersionToImanagePending: false,
  postFildersAsVersionToImanageError: null,
  imanageHiddenFileImportPending: false,
  imanageHiddenFileImportError: null,
  updateAccessTimePending: false,
  updateAccessTimeError: null,
  postVersionFromImanagePending: false,
  postVersionFromImanageError: null,
  editDocumentNumberingPending: false,
  postRequestAuthorizationBiblePending: false,
  postMassFoldersPending: false,
  postMassFoldersError: null,
  getFolderPathPending: false,
  shareDocumentsIndexByEmailPending: false,
  movementSnackbarIsOpen: false,
  numberingSnackbarIsOpen: false,
  foldersTree: null,
  getFoldersTreePending: false,
  wopiLockedFiles: [],
  checkWopiLockedFilesPending: false,
}
