<template>
  <AppDialog :is-open="isOpen"
             ok-only
             :ok-text="$t('common.close')"
             @ok="$emit('update:isOpen', false)"
             @cancel="$emit('update:isOpen', false)"
  >
    <template #title>{{$t('project.bibles.BibleTooBigDialog.title')}}</template>
    <template #body>
      <p v-if="maxSize && currentSize">
        {{ $t('project.bibles.BibleTooBigDialog.text1', {currentSize, maxSize}) }}
      </p>
      <p v-else>
        {{ $t('project.bibles.BibleTooBigDialog.text1WithoutData') }}
      </p>
      <i18n v-if="isCurrentUserPm"
            path="project.bibles.BibleTooBigDialog.text2"
            tag="p"
      >
        <template v-slot:emailAddress>
          <a :href="'mailto:' + supportEmailAddress">{{ supportEmailAddress }}</a>
        </template>
      </i18n>
    </template>
  </AppDialog>
</template>

<script>
import { mapGetters } from 'vuex'

import { SUPPORT_EMAIL_ADDRESS } from '@/common/utils/closdInfo'

import AppDialog from '../../common/dialogs/AppDialog'

export default {
  name: 'BibleTooBigDialog',
  components: { AppDialog },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    bibleTooBigData: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      supportEmailAddress: SUPPORT_EMAIL_ADDRESS,
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm']),
    maxSize () {
      if (this.bibleTooBigData.maxSize) {
        return Math.round(10 * this.bibleTooBigData.maxSize / 1024 / 1024 / 1024) / 10
      }
      return null
    },
    currentSize () {
      if (this.bibleTooBigData.currentSize) {
        return Math.round(10 * this.bibleTooBigData.currentSize / 1024 / 1024 / 1024) / 10
      }
      return null
    },
  },
}
</script>
