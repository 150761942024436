<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat :disabled="disabled">
          <v-tabs v-model="currentTab" fixed-tabs>
            <v-tab href="#documents">{{ $t("project.signing-checklist.SigningChecklistClosdFileBrowser.header1") }}</v-tab>
            <v-tab href="#todos">{{ $t("project.signing-checklist.SigningChecklistClosdFileBrowser.header2") }}</v-tab>
            <!-- <v-tab href="#templates">{{ $t("project.signing-checklist.SigningChecklistClosdFileBrowser.header3") }}</v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="currentTab">
            <v-tab-item value="documents">
              <v-card-text style="max-height: 300px; overflow-y: auto">
                <template v-if="documentsFolder">
                  <template v-if="documentsFolder.children.length !== 0">
                    <AppClosdFildersTree
                      id="documentsPicker"
                      v-model="selectedDocuments"
                      :rootFolder="documentsFolder"
                      :multiple="multiple"
                      :files-only="filesOnly"
                      disable-folder-without-file
                      :disable-files-too-large-to-be-signed="true"
                      :maxSignableSize="maxSignableSize"
                    >
                      <template v-slot:label="{ item }">
                        <template v-if="item.disabled">
                          <AppTooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                            </template>
                            <span>{{$t('project.signing-checklist.SigningChecklistClosdFileBrowser.fileTooLarge')}}</span>
                          </AppTooltip>
                        </template>
                      </template>
                    </AppClosdFildersTree>
                  </template>
                  <template v-else>
                    <div class="caption text-center">
                      {{ $t("project.signing-checklist.SigningChecklistClosdFileBrowser.noDocuments") }}
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="text-center">
                    <v-progress-circular
                      style="width: 48px; margin: 100px 0"
                      color="primary"
                      size="48"
                      indeterminate
                    />
                  </div>
                </template>
              </v-card-text>
            </v-tab-item>
            <v-tab-item value="todos">
              <v-card-text style="max-height: 300px; overflow-y: auto">
                <template v-if="sortedTodosFolder">
                  <template v-if="sortedTodosFolder.children.length !== 0">
                    <AppClosdFildersTree
                      id="todosPicker"
                      v-model="selectedTodos"
                      :rootFolder="todosFolder"
                      :multiple="multiple"
                      :files-only="filesOnly"
                      disable-folder-without-file
                      :disable-files-too-large-to-be-signed="true"
                      :maxSignableSize="maxSignableSize"
                    >
                      <template v-slot:label="{ item }">
                        <template v-if="item.disabled">
                          <AppTooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                            </template>
                            <span>{{$t('project.signing-checklist.SigningChecklistClosdFileBrowser.fileTooLarge')}}</span>
                          </AppTooltip>
                        </template>
                      </template>
                    </AppClosdFildersTree>
                  </template>
                  <template v-else>
                    <div class="caption text-center">
                      {{ $t("project.signing-checklist.SigningChecklistClosdFileBrowser.noTasks") }}
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="text-center">
                    <v-progress-circular
                      style="width: 48px; margin: 100px 0"
                      color="primary"
                      size="48"
                      indeterminate
                    />
                  </div>
                </template>
              </v-card-text>
            </v-tab-item>
            <v-tab-item value="templates">
              <v-card-text style="max-height: 300px; overflow-y: auto">
                <template v-if="myTemplatesFolder">
                  <template v-if="myTemplatesFolder.children.length !== 0">
                    <AppFildersTree
                      id="templatesPicker"
                      v-model="selectedTemplates"
                      :rootFolder="myTemplatesFolder"
                      multiSelect
                      openFirstNode
                      :disable-files-too-large-to-be-signed="true"
                      :maxSignableSize="maxSignableSize"
                    />
                  </template>
                  <template v-else>
                    <div class="caption text-center">
                      {{ $t("project.signing-checklist.SigningChecklistClosdFileBrowser.noTemplates") }}
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="text-center">
                    <v-progress-circular
                      style="width: 48px; margin: 100px 0"
                      color="primary"
                      size="48"
                      indeterminate
                    />
                  </div>
                </template>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-4 text-center">
      <AppButton
        v-if="hasFiles"
        color="tertiary"
        :dark="!noFilesSelected && !disabled"
        small
        :disabled="noFilesSelected || disabled"
        @click="exportFiles"
      >
        <i class="fa fa-plus mr-2"></i>
        {{ $t("project.signing-checklist.SigningChecklistClosdFileBrowser.okTitle") }}
      </AppButton>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { flatMapDeep, orderBy } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'

import { GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'

import AppClosdFildersTree from '../../common/app-closd-filders-tree/AppClosdFildersTree'
import AppTooltip from '../../common/AppTooltip'
import AppFildersTree from '../../common/AppVuetifyFildersTree.vue'
import AppButton from '../../common/buttons/AppButton.vue'
import { isFileLowerThanMaxSignableSize } from '../../common/utils/files'
import { GET_TODOS_FOLDER } from '../../store/modules/todo/action_types'

const { mapState: mapDocumentsState, mapActions: mapDocumentsActions } = createNamespacedHelpers('documents')
const { mapState: mapTodoState, mapActions: mapTodoActions } = createNamespacedHelpers('todo')

export default {
  name: 'SigningChecklistClosdFileBrowser',
  components: {
    AppFildersTree,
    AppButton,
    AppClosdFildersTree,
    AppTooltip,
  },
  props: {
    initialTab: {
      type: String,
      default: 'documents',
    },
    mnemo: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    filesOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxSignableSize: {
      type: [Number, String],
      default: 0,
    },
  },
  data () {
    return {
      selectedRoom: null,
      documentsFolder: null,
      sortedTodosFolder: null,
      myTemplatesFolder: null,
      closdRooms: null,
      selectedDocuments: [],
      selectedTodos: [],
      selectedTemplates: [],
      currentTab: this.initialTab,
    }
  },
  computed: {
    ...mapDocumentsState(['documents']),
    ...mapTodoState(['todosFolder']),
    noFilesSelected () {
      return this.selectedDocuments.length === 0 && this.selectedTodos.length === 0 && this.selectedTemplates.length === 0
    },
    hasFiles () {
      return this.documentsFolder?.children.length > 0 ||
        this.todoFolderHasFile ||
        this.myTemplatesFolder?.children.length > 0
    },
    todoFolderHasFile () {
      if (this.sortedTodosFolder) {
        if (this.sortedTodosFolder.children.length > 0) {
          if (this.sortedTodosFolder.children.some(doc => doc.children.length > 0)) {
            return true
          }
        }
      }
      return false
    },
  },
  watch: {
    // Those watchers are there to make sure only one item can be selected at a time
    // When multiple=false
    selectedDocuments (old, current) {
      if (!this.multiple && (current.length + this.selectedTodos.length) > 1) {
        if (this.selectedDocuments.length > 1) {
          this.selectedDocuments = [current.pop()]
        }
        if (this.selectedDocuments.length !== 0 && this.selectedTodos.length !== 0) {
          this.selectedTodos = []
        }
      }
    },
    selectedTodos (old, current) {
      if (!this.multiple && (current.length + this.selectedDocuments.length) > 1) {
        if (this.selectedTodos.length > 1) {
          this.selectedTodos = [current.pop()]
        }
        if (this.selectedTodos.length !== 0 && this.selectedDocuments.length !== 0) {
          this.selectedDocuments = []
        }
      }
    },
  },
  created () {
    this.documentsFolder = null
    this.sortedTodosFolder = null
    this.myTemplatesFolder = null
    this.getDocumentsFolder()
    this.getTodosFolder()
    this.getTemplatesFolder()
  },
  methods: {
    ...mapDocumentsActions([GET_DOCUMENTS_BY_ID]),
    ...mapTodoActions([GET_TODOS_FOLDER]),
    async getDocumentsFolder () {
      const fileType = ['pdf']
      if (process.env.VUE_APP_ENABLE_WORD_SIGNING) {
        fileType.push('doc', 'docx')
      }
      await this.GET_DOCUMENTS_BY_ID({
        mnemo: this.mnemo,
        queryObject: {
          ignoreTodoFolder: true,
          lazyMode: false,
          fileType,
        },
        id: 0,
      })
      this.documentsFolder = {
        ...this.documents,
        children: orderBy(this.mapSignableItems(this.documents.children, ['type', 'basename'], ['desc', 'asc'])),
      }
    },
    async getTodosFolder () {
      const fileType = ['pdf']
      if (process.env.VUE_APP_ENABLE_WORD_SIGNING) {
        fileType.push('doc', 'docx')
      }
      await this.GET_TODOS_FOLDER({
        mnemo: this.mnemo,
        queryObject: {
          lazyMode: false,
          fileType,
        },
      })
      this.sortedTodosFolder = {
        ...this.todosFolder,
        children: orderBy(this.mapSignableItems(this.todosFolder.children, ['type', 'basename'], ['desc', 'asc'])),
      }
    },
    async getTemplatesFolder () {
      // TODO
      // this.myTemplatesFolder = {
      //   basename: this.$t('project.signing-checklist.SigningChecklistClosdFileBrowser.header3'),
      //   name: this.$t('project.signing-checklist.SigningChecklistClosdFileBrowser.header3'),
      //   children: orderBy(this.myTemplates, ['type', 'basename'], ['desc', 'asc']),
      // }
    },
    mapSignableItems (items) {
      return items.map((item) => {
        if (item.size && !isFileLowerThanMaxSignableSize(item, this.maxSignableSize)) {
          item.disabled = true
        } else if (item.type === 'folder') {
          item.children = this.mapSignableItems(item.children)
        }
        return item
      })
    },
    exportFiles (e) {
      if (e) e.preventDefault()
      const flatFiles = this.getFlatFiles().filter(file => isFileLowerThanMaxSignableSize(file, this.maxSignableSize))
      this.$emit('selectedFiles', flatFiles)
      this.selectedDocuments = []
      this.selectedTodos = []
      this.selectedTemplates = []
    },
    getFlatFiles () {
      const flatFiles = []
      for (const file of this.selectedDocuments) {
        if (file.type === 'file') {
          flatFiles.push(file)
        } else if (file.type === 'folder' && file.childrenCount !== 0) {
          flatFiles.push(this.getChildrenFlatFiles(file))
        }
      }
      for (const file of this.selectedTodos) {
        if (file.type === 'file') {
          flatFiles.push(file)
        } else if (file.type === 'folder' && file.childrenCount !== 0) {
          flatFiles.push(this.getChildrenFlatFiles(file))
        }
      }
      // TODO templates
      return flatMapDeep(flatFiles)
    },
    getChildrenFlatFiles (f) {
      const childrenFiles = []
      if (f.hasOwnProperty('children')) {
        for (const c of f.children) {
          childrenFiles.push(this.getChildrenFlatFiles(c))
        }
      } else {
        childrenFiles.push(f)
      }
      return childrenFiles
    },
  },
}
</script>
