import { render, staticRenderFns } from "./DocumentsAISummaryRequestButton.vue?vue&type=template&id=7f37a5fc&scoped=true"
import script from "./DocumentsAISummaryRequestButton.vue?vue&type=script&lang=ts"
export * from "./DocumentsAISummaryRequestButton.vue?vue&type=script&lang=ts"
import style0 from "./DocumentsAISummaryRequestButton.vue?vue&type=style&index=0&id=7f37a5fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f37a5fc",
  null
  
)

export default component.exports