<template>
  <AppDialog :is-open="isDialogOpened"
             :size="dialogSize"
             :no-click-animation="saveEnvelopeAsTemplatePending"
             :cancel-disabled="saveEnvelopeAsTemplatePending"
             @cancel="closeDialog"
  >
    <template #title>
      {{$t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.title')}}
    </template>
    <template #body>
      <v-stepper v-model="currentStep">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row>
              <v-col>
                <AppTextField v-model="$v.templateData.title.$model"
                              :label="$t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.templateTitleLabel')"
                              :error-messages="templateTitleErrors"
                              class="mt-2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <Alert type="info"
                       color="light-blue"
                       :dismissible="false"
                       icon="fas fa-info-circle"
                >
                  <p>{{ $t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.alertInfo.presevedElements') }}</p>
                  <ul>
                    <li>{{$t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.alertInfo.signatureType')}}</li>
                    <li>{{$t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.alertInfo.envelopeType')}}</li>
                    <li>{{$t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.alertInfo.signers')}}</li>
                    <li>{{$t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.alertInfo.tags')}}</li>
                  </ul>
                </Alert>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <SigningChecklistSectionDocumentSharingOptions :share-type="templateData.shareType"
                                                           :file-with-users="templateData.withUsers"
                                                           :file-rights="envelope.rights || { isCustom : true }"
                                                           :is-editing-limited="false"
                                                           @send-notif-change="setTemplateDocumentShareSendNotif"
                                                           @share-type-change="setTemplateDocumentShareType"
                                                           @with-users-change="setTemplateDocumentShareWithUsers"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
    <template #footer>
      <AppButton v-if="currentStep === 2"
                 type="outlined"
                 variant="neutral"
                 :disabled="saveEnvelopeAsTemplatePending"
                 @click="currentStep--"
      >
        {{ $t('common.previous') }}
      </AppButton>
      <AppButton type="outlined"
                 variant="neutral"
                 class="ml-auto mr-2"
                 :disabled="saveEnvelopeAsTemplatePending"
                 @click="closeDialog"
      >
        {{ $t('common.cancel') }}
      </AppButton>
      <AppButton type="primary"
                  :disabled="!canValidateCurrentStep"
                  @click="onOk"
      >
        {{ okText }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'

import Alert from '@/common/alerts/Alert.vue'
import AppTextField from '@/common/AppTextField.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'
import { typedMapActions } from '@/common/utils/store'
import AppButton from '@/design-system/buttons/AppButton.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import SigningChecklistSectionDocumentSharingOptions from '@/project/signing-checklist/add/SigningChecklistSectionDocumentSharingOptions.vue'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'SaveDocumentAsTemplateDialog',
  components: { Alert, AppButton, AppTextField, AppDialog, SigningChecklistSectionDocumentSharingOptions },
  mixins: [dialogMixin, validationMixin],
  props: {
    envelope: {
      type: Object,
      required: true,
    },
  },
  validations: {
    templateData: {
      title: { required },
      shareType: {
        required,
      },
      withUsers: {
        required: requiredIf(templateModel => templateModel.shareType === SHARE_TYPE_OPTIONS.CUSTOM),
      },
    },
    steps: {
      1: ['templateData.title'],
      2: ['templateData.shareType', 'templateData.withUsers'],
    },
  },
  data () {
    return {
      currentStep: 1,
      templateData: {
        sendNotif: false,
        shareType: SHARE_TYPE_OPTIONS.PUBLIC,
        title: '',
        withUsers: [],
      },
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    ...mapState('envelopeTemplates', ['saveEnvelopeAsTemplatePending']),
    okText () {
      return this.currentStep === 1 ? this.$t('common.next') : this.$t('common.register')
    },
    templateTitleErrors () {
      const errors = []

      if (!this.$v.templateData.title.$dirty) return errors
      if (!this.$v.templateData.title.required) {
        errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.templateTitleLabel') }))
      }

      return errors
    },
    canValidateCurrentStep () {
      const currentStepIsValid = this.currentStep === 1 || (this.currentStep === 2 && !this.$v.steps[2].$invalid)

      return !this.saveEnvelopeAsTemplatePending && currentStepIsValid
    },
    dialogSize () {
      return this.currentStep === 2 ? 'xl' : 'l'
    },
  },
  methods: {
    ...typedMapActions('envelopeTemplates', ['SAVE_ENVELOPE_AS_TEMPLATE']),
    setTemplateDocumentShareSendNotif (sendNotif) {
      this.templateData.sendNotif = sendNotif
    },
    setTemplateDocumentShareType (shareType) {
      this.templateData.shareType = shareType
      this.templateData.withUsers = []
    },
    setTemplateDocumentShareWithUsers (withUsers) {
      this.templateData.withUsers = withUsers
    },
    onOk () {
      this.$v.$touch()
      if (this.$v.steps[this.currentStep].$invalid) {
        return
      }

      if (this.currentStep === 2) {
        this.saveEnvelopeAsTemplate()
      } else {
        this.currentStep++
      }
    },
    async saveEnvelopeAsTemplate () {
      try {
        await this.SAVE_ENVELOPE_AS_TEMPLATE({
          mnemo: this.roomMnemo,
          envelopeId: this.envelope.id,
          data: this.templateData,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.saveEnvelopeAsTemplateSuccess'),
        })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signing-checklist.envelope-templates.dialogs.SaveDocumentAsTemplateDialog.saveEnvelopeAsTemplateError'),
        })
      } finally {
        this.closeDialog()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-stepper__content {
    padding: 0 8px;
  }
</style>
