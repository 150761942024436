import { render, staticRenderFns } from "./AppSegmentedControl.vue?vue&type=template&id=c132f0b0"
import script from "./AppSegmentedControl.vue?vue&type=script&lang=js"
export * from "./AppSegmentedControl.vue?vue&type=script&lang=js"
import style0 from "./AppSegmentedControl.vue?vue&type=style&index=0&id=c132f0b0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports