export const DESELECT_ALL_DOCUMENTS = 'DESELECT_ALL_DOCUMENTS'
export const GET_SEARCH_ARCHIVES = 'GET_SEARCH_ARCHIVES'
export const GET_ARCHIVE_CONFIG = 'GET_ARCHIVE_CONFIG'
export const GET_ARCHIVE_RIGHTS = 'GET_ARCHIVE_RIGHTS'
export const SEND_ARCHIVES = 'SEND_ARCHIVES'
export const SHARE_ARCHIVES = 'SHARE_ARCHIVES'
export const UPLOAD_ARCHIVE = 'UPLOAD_ARCHIVE'
export const POST_ARCHIVE = 'POST_ARCHIVE'
export const SELECT_DOCUMENTS = 'SELECT_DOCUMENTS'
export const SELECT_ALL_DOCUMENTS = 'SELECT_ALL_DOCUMENTS'
export const GET_ARCHIVES_DOWNLOAD = 'GET_ARCHIVES_DOWNLOAD'
