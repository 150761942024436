<template>
  <v-expansion-panel class="SigningChecklistAddStep2File-expansionPanel" :elevation="0">
    <v-expansion-panel-header>
      <SigningChecklistDocumentHeader :file="file"
                                      :validator="validator"
                                      @deleteFile="isDeleteFileDialogOpened = true"
      />
    </v-expansion-panel-header>
    <v-expansion-panel-content class="py-6">
      <SigningChecklistSection>
        <template #title>
          1. {{ $t(`${TRANSLATION_KEY_PREFIX}.sectionTitles.documentInformations`) }}
        </template>
        <template #content>
          <Section1 :file-id="file.id || file.originalFile.id"
                    :file-name="file.objectToPost.title"
                    :file-date="file.objectToPost.date"
                    :is-editing-limited="isEditingLimited"
                    :title-validator="validator.objectToPost.title"
          />
        </template>
      </SigningChecklistSection>

      <SigningChecklistSection>
        <template #title>
          2. {{ $t(`${TRANSLATION_KEY_PREFIX}.sectionTitles.signatureType`) }}
        </template>
        <template #content>
          <Section2 :envelope-type="file.objectToPost.envelopeType"
                    :file-size="file.size"
                    :has-mercury-option-activated="hasMercuryOptionActivated"
                    :hasSignersOrder="hasSignersOrder"
                    :is-editing-limited="isEditingLimited"
                    :signature-type-validator="validator.objectToPost.signatureType"
                    :signature-type="file.objectToPost.signatureType"
                    @setQualifiedDSWarningDialog="qualifiedDSWarningDialog = $event"
                    @setSelectSignatureType="selectSignatureType"
          />
        </template>
      </SigningChecklistSection>

      <SigningChecklistSection>
        <template #title>
          3. {{ $t(`${TRANSLATION_KEY_PREFIX}.sectionTitles.envelopeType`) }}
        </template>
        <template #content>
          <Section3 :file-id="file.id || file.originalFile.id"
                    :envelope-type-validator="validator.objectToPost.envelopeType"
                    :is-editing-limited="isEditingLimited"
                    :isMultiEnvelopeChild="isMultiEnvelopeChild"
                    :envelope-type="file.objectToPost.envelopeType"
                    :signature-type="file.objectToPost.signatureType"
          />
        </template>
      </SigningChecklistSection>

      <SigningChecklistSection>
        <template #title>
          4. {{ $t(`${TRANSLATION_KEY_PREFIX}.sectionTitles.roles`) }}
        </template>
        <template #content>
          <Section4 :file="file"
                    :has-signers-order="hasSignersOrder"
                    :is-editing-limited="isEditingLimited"
                    @resetSigners="resetSigners"
          />
        </template>
      </SigningChecklistSection>

      <SigningChecklistSection v-if="addFromTemplate">
        <template #title>
          5. {{ $t(`${TRANSLATION_KEY_PREFIX}.sectionTitles.signersAssignation`) }}
        </template>

        <template #content>
          <SigningChecklistSignersAssignationList :signers="fileSigners"
                                                  :envelope-type="file.objectToPost.envelopeType"
                                                  :template-signatures-validator="validator.templateSignatures"
                                                  @assign-signer="assignGenericSigner"
          />
        </template>
      </SigningChecklistSection>
    </v-expansion-panel-content>

    <SigningChecklistAddStep2DeleteStepWarningDialog v-if="isDeleteFileDialogOpened"
                                                     @close="isDeleteFileDialogOpened = false"
                                                     @confirm="deleteFile"
    />

    <QualifiedDSSignatureWarningDialog v-if="qualifiedDSWarningDialog"
                                       @close="qualifiedDSWarningDialog = false"
                                       @confirm="confirmQualifiedSignatureSelection"
    />
  </v-expansion-panel>
</template>

<script>
import { intersectionWith } from 'lodash-es'
import { createNamespacedHelpers, mapGetters } from 'vuex'

import SigningChecklistDocumentHeader from '@/project/signing-checklist/add/SigningChecklistDocumentHeader.vue'
import SigningChecklistSection from '@/project/signing-checklist/add/SigningChecklistSection.vue'
import { SIGNATURE_TYPE_HANDWRITTEN } from '@/project/signing-checklist/constants'
import QualifiedDSSignatureWarningDialog from '@/project/signing-checklist/dialogs/QualifiedDSSignatureWarningDialog.vue'
import {
  ASSIGN_SIGNER_ON_GENERIC_SIGNATURE,
  REMOVE_IMPORTED_FILE,
  SET_IMPORTED_FILE_SIGNATURE_TYPE,
} from '@/store/modules/signing-checklist/mutation_types'

import Section1 from './Section1.vue'
import Section2 from './Section2.vue'
import Section3 from './Section3.vue'
import Section4 from './Section4.vue'
import SigningChecklistSignersAssignationList from './signers-assignation/SigningChecklistSignersAssignationList.vue'
import SigningChecklistAddStep2DeleteStepWarningDialog from './SigningChecklistAddStep2DeleteStepWarningDialog.vue'

const { mapState: mapSigningChecklistState, mapMutations: mapSigningChecklistMutations } = createNamespacedHelpers('signingChecklist')
const TRANSLATION_KEY_PREFIX = 'project.signing-checklist.add.step2.SigningChecklistAddStep2File'

export default {
  name: 'SigningChecklistAddStep2File',
  components: {
    QualifiedDSSignatureWarningDialog,
    Section1,
    Section2,
    Section3,
    Section4,
    SigningChecklistAddStep2DeleteStepWarningDialog,
    SigningChecklistDocumentHeader,
    SigningChecklistSection,
    SigningChecklistSignersAssignationList,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isDeleteFileDialogOpened: false,
      qualifiedDSWarningDialog: false,
      SIGNATURE_TYPE_HANDWRITTEN,
      TRANSLATION_KEY_PREFIX,
    }
  },
  inject: ['addFromTemplate'],
  computed: {
    ...mapGetters('groups', ['allRoomMembers']),
    ...mapSigningChecklistState(['importedFiles']),
    hasSignersOrder () {
      return this.file?.objectToPost?.signers?.length > 1
    },
    isMultiEnvelopeChild () {
      return this.file.objectToPost.envelope?.parentMultienvelopeId || false
    },
    // If an envelope is in process, we will not be able to change much info about it, it's too late
    isEditingLimited () {
      return (this.file.objectToPost?.envelope?.status === 'signed' ||
        this.file.objectToPost?.envelope?.status === 'sent' ||
        this.file.objectToPost?.envelope?.status === 'voided' ||
        this.file.objectToPost?.envelope?.status === 'unvalidated'
      )
    },
    hasMercuryOptionActivated () {
      return !!this.file.providerOptions?.mercury
    },
    fileSigners () {
      return intersectionWith(
        this.allRoomMembers,
        this.file.objectToPost.signers.flat(),
        (roomMember, signerId) => roomMember.id === signerId,
      ).map(member => ({
        id: member.id,
        name: member.fullName,
      }))
    },
  },
  methods: {
    ...mapSigningChecklistMutations([
      ASSIGN_SIGNER_ON_GENERIC_SIGNATURE,
      REMOVE_IMPORTED_FILE,
      SET_IMPORTED_FILE_SIGNATURE_TYPE,
    ]),
    deleteFile (file) {
      this.REMOVE_IMPORTED_FILE(this.importedFiles.findIndex((f) => f === file))
    },
    confirmQualifiedSignatureSelection () {
      this.qualifiedDSWarningDialog = false
      this.selectSignatureType('ds.qualified')
    },
    selectSignatureType (signatureType) {
      this.SET_IMPORTED_FILE_SIGNATURE_TYPE({
        fileId: this.file.id,
        signatureType: signatureType,
      })

      // reset signature order if user selects handwritten signature
      if (signatureType === SIGNATURE_TYPE_HANDWRITTEN) {
        if (this.hasSignersOrder) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t(`${TRANSLATION_KEY_PREFIX}.signatoriesOrderRemovedSuccess`),
          })
        }
        this.resetSigners()
      }

      this.$emit('signatureTypeChange', this.file, signatureType)
    },
    resetSigners () {
      this.file.objectToPost.signers = [this.fileSigners]
    },
    assignGenericSigner (signatureId, signer) {
      this.ASSIGN_SIGNER_ON_GENERIC_SIGNATURE({
        fileId: this.file.id,
        signatureId: signatureId,
        signer: signer,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.SigningChecklistAddStep2File-expansionPanel {
  &:after {
    border-top: none !important;
  }
  ::v-deep .v-expansion-panel-header {
    border-bottom: 1px solid #DEE2E6;
    border-radius: 6px !important;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  ::v-deep .v-messages {
    min-height: 0;
  }
}
</style>
