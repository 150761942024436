<template>
  <v-snackbar
    :value="true"
    app
    bottom
    color="success"
    class="toast"
    timeout="3000"
    transition="slide-y-reverse-transition"
  >
    <div class="d-flex align-center column-gap-4">
      {{ $tc('project.documents.DocumentsMovementSnackbar.movementSnackbarSuccess', documentsToMove.documents.length, { count: documentsToMove.documents.length }) }}
    </div>
    <template #action="{ attrs }">
      <SnackBarButton class="mr-2"
                      v-bind="attrs"
                      @click="cancelMovement"
      >
      {{ $t('common.cancel') }}
      </SnackBarButton>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import SnackBarButton from '@/common/buttons/SnackBarButton.vue'
import { MOVE_FILDERS, LOAD_FOLDERS_TREE } from '@/store/modules/documents/action_types'
import { SET_MOVEMENT_SNACKBAR_IS_OPEN } from '@/store/modules/documents/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'DocumentsMovementSnackbar',
  components: { SnackBarButton },
  computed: {
    ...mapState('documents', ['documentsToMove']),
  },
  methods: {
    ...mapActions('documents', [MOVE_FILDERS, LOAD_FOLDERS_TREE]),
    ...mapMutations('documents', [SET_MOVEMENT_SNACKBAR_IS_OPEN]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async cancelMovement () {
      try {
           await this.MOVE_FILDERS({
             documents: this.documentsToMove.documents,
             targetFolderId: this.documentsToMove.originalFolderId,
           })
           this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.documents.table.DocumentsTableBody.moveCancel'))
           this.onClose()
         } catch {
           this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.table.DocumentsTableBody.moveError'))
         }
      await this.LOAD_FOLDERS_TREE()
    },
    onClose () {
      this.SET_MOVEMENT_SNACKBAR_IS_OPEN(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.toast {
  ::v-deep(.v-snack__wrapper) {
    margin: 40px;

    // max-width: 1264px match our mobile breakpoint
    @media only screen and (max-width: 1264px) {
      margin: 16px;
    }
  }
}
</style>
