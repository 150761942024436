<template>
  <div class="wrapper" :class="{ 'wrapper--active': currentlyResizing }">
    <vue-resizable
      v-if="$slots['left']"
      class="left"
      :width="width"
      :minWidth="minWidth"
      :maxWidth="maxWidth"
      :active="['r']"
      :disableAttributes="['l', 't', 'h']"
      @resize:start="onResizeStart"
      @resize:end="onResizeEnd"
    >
      <slot name="left" />
    </vue-resizable>

    <div class="right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import VueResizable from 'vue-resizable'

export default defineComponent({
  name: 'ColResizer',
  components: { VueResizable },
  props: {
    width: {
      type: Number,
      required: false,
    },
    minWidth: {
      type: Number,
      required: false,
    },
    maxWidth: {
      type: Number,
      required: false,
    },
  },
  data () {
    return {
      currentlyResizing: false,
    }
  },
  emits: ['resize'],
  methods: {
    onResizeStart () {
      this.currentlyResizing = true
    },
    onResizeEnd ({ width }) {
      this.currentlyResizing = false
      this.$emit('resize', width)
    },
  },
})
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;

  &--active,
  ::v-deep .resizable-r {
    cursor: url("data:image/svg+xml,%3Csvg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%23444' d='M7 0h2v16h-2v-16z'%3E%3C/path%3E%3Cpath fill='%23444' d='M3 5l-3 3 3 3v-2h3v-2h-3z'%3E%3C/path%3E%3Cpath fill='%23444' d='M16 8l-3-3v2h-3v2h3v2z'%3E%3C/path%3E%3C/svg%3E") 9 9, e-resize !important;
  }
}

.right {
  flex-grow: 1;
}
</style>
