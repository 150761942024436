<template>
  <v-container fluid class="py-0 px-2">
    <v-row>
      <v-col class="text-h1">
        {{ $t('subscriptions.views.SubscriptionBilling.invoiceHistory') }}
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col class="d-flex align-center">
        <div class="text-h3 mr-3 ml-auto">{{ $t('subscriptions.views.SubscriptionBilling.yearFilter') }}</div>
        <AppSelect v-model="selectedYear"
                   class="SubscriptionBilling-yearFilter white"
                   :items="selectableYears"
                   no-label
        />
      </v-col>
    </v-row>
    <template v-if="selectedInvoices.length > 0">
      <v-row>
        <v-col>
          <div class="SubscriptionBilling-multiSelect-bar">
            <div class="text-h4">{{ $tc('subscriptions.views.SubscriptionBilling.selectedInvoices', selectedInvoices.length, { count: selectedInvoices.length }) }}</div>
            <div class="text--primary app-link ml-3"
                 @click="selectedInvoices = []"
            >
              {{ $t('subscriptions.views.SubscriptionBilling.unSelect') }}
            </div>
            <AppDownloadFile :link="invoicesZipLink">
              <template #default="{submitForm}">
                <MultiSelectBarButton class="ml-3"
                                      @click="submitForm"
                >
                  {{ $t('common.download') }}
                </MultiSelectBarButton>
              </template>
            </AppDownloadFile>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-if="subscriptionInvoicesPending && !subscriptionInvoices">
      <v-row>
        <v-col class="d-flex justify-center">
          <AppDataTableSkeletonLoader class="w-100"/>
        </v-col>
      </v-row>
    </template>
    <v-row v-if="subscriptionInvoices && !subscriptionInvoicesPending">
      <v-col>
        <v-data-table ref="SubscriptionBillingTable"
                      v-model="selectedInvoices"
                      :items="filteredInvoices"
                      :headers="headers"
                      show-select
                      :item-class="itemClass"
                      class="SubscriptionBilling-table"
                      :footer-props="{
                        'items-per-page-options': ITEM_PER_PAGE_DEFAULT,
                      }"
                      :items-per-page="tableItemsPerPage"
                      :options.sync="options"
        >
          <template #item.reference="{item}">
            {{ item.ref }}
          </template>
          <template #item.invoiceDate="{item}">
            {{ ISOToShortenedDate(item.invoiceDate) }}
          </template>
          <template #item.dueDate="{item}">
            {{ ISOToShortenedDate(item.dueDate) }}
          </template>
          <template #item.status="{item}">
            <div v-if="item.status === 'paid'">{{ $t('subscriptions.views.SubscriptionBilling.paymentStatus.paid') }}</div>
            <div v-if="item.status === 'sent' && dayjs().isBefore(item.dueDate)"
                 class="text--primary"
            >
              {{ $t('subscriptions.views.SubscriptionBilling.paymentStatus.sent') }}
              <router-link :to="{ name: PAYMENT_ROUTE_NAME, params: { locale: $i18n.locale, token: item.token } }">
                <font-awesome-icon class="SubscriptionBilling-creditCardIcon ml-1 clickable"
                                   :icon="['fad', 'credit-card']"
                >
                </font-awesome-icon>
              </router-link>
            </div>
            <div v-if="item.status === 'sent' && dayjs().isAfter(item.dueDate)"
                 class="error--text"
            >
              {{ $t('subscriptions.views.SubscriptionBilling.paymentStatus.overdue') }}
              <router-link :to="{ name: PAYMENT_ROUTE_NAME, params: { locale: $i18n.locale, token: item.token } }">
                <font-awesome-icon class="SubscriptionBilling-creditCardIcon ml-1 clickable"
                                   :icon="['fad', 'credit-card']"
                >
                </font-awesome-icon>
              </router-link>
            </div>
          </template>
          <template #item.amountNet="{item}">
            {{ item.amountNet.toString().replace('.', ',') }} €
          </template>
          <template #item.dates="{item}">
            {{ $t('subscriptions.views.SubscriptionBilling.period', { startDate: ISOToDDMMYYYY(item.fromDate), endDate: ISOToDDMMYYYY(item.toDate) }) }}
          </template>
          <template #item.options="{item}">
            <AppDownloadFile :link="getInvoiceLink(item)">
              <template #default="{submitForm}">
                <div class="app-link" @click="submitForm">
                  <font-awesome-icon :icon="['fal', 'file-pdf']" class="error--text mr-1" style="font-size: 18px;"></font-awesome-icon>
                  {{ $t('common.download') }}
                </div>
              </template>
            </AppDownloadFile>
          </template>
          <template #footer.prepend>
            <v-pagination
              v-model="dataTablePage"
              :length="dataTablePageCount"
              :total-visible="7"
              :disabled="disabledPagination"
              @input="onPageChange"
            ></v-pagination>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppDataTableSkeletonLoader from '@/common/AppDataTableSkeletonLoader'
import AppDownloadFile from '@/common/AppDownloadFile'
import AppSelect from '@/common/AppSelect'
import MultiSelectBarButton from '@/common/buttons/MultiSelectBarButton'
import { ITEM_PER_PAGE_DEFAULT } from '@/common/utils/dataTablePagination'
import { ISOToShortenedDate, ISOToDDMMYYYY } from '@/common/utils/dates'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import { PAYMENT_ROUTE_NAME } from '@/router'
import {
  DOWNLOAD_INVOICES,
  GET_SUBSCRIPTION_INVOICES,
} from '@/store/modules/subscription/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import { SUBSCRIPTION_BILLING_ITEMS_PER_PAGE } from '../constants.js'

export default {
  name: 'SubscriptionBilling',
  components: { AppDataTableSkeletonLoader, MultiSelectBarButton, AppDownloadFile, AppSelect },
  data () {
    return {
      ITEM_PER_PAGE_DEFAULT,
      ISOToShortenedDate,
      ISOToDDMMYYYY,
      dayjs,
      selectedInvoices: [],
      selectedYear: dayjs().year(),
      headers: [
        { value: 'reference', text: this.$t('subscriptions.views.SubscriptionBilling.headers.reference') },
        { value: 'invoiceDate', text: this.$t('subscriptions.views.SubscriptionBilling.headers.invoiceDate') },
        { value: 'dueDate', text: this.$t('subscriptions.views.SubscriptionBilling.headers.dueDate') },
        { value: 'status', text: this.$t('subscriptions.views.SubscriptionBilling.headers.status') },
        { value: 'amountNet', text: this.$t('subscriptions.views.SubscriptionBilling.headers.amountNet') },
        { value: 'dates', text: this.$t('subscriptions.views.SubscriptionBilling.headers.dates') },
        { value: 'options', text: this.$t('subscriptions.views.SubscriptionBilling.headers.options') },
      ],
      options: {},
      dataTablePage: 1,
      dataTablePageCount: 1,
      disabledPagination: false,
      PAYMENT_ROUTE_NAME,
      SUBSCRIPTION_BILLING_ITEMS_PER_PAGE,
    }
  },
  computed: {
    ...mapState('subscription', ['subscriptionInvoices', 'subscriptionInvoicesPending']),
    ...mapGetters('user', ['currentUserId']),
    invoicesZipLink () {
      return `${process.env.VUE_APP_API_URL}/subscription/download/invoices?invoicesIds=${this.selectedInvoices.map(invoice => invoice.id)}`
    },
    selectableYears () {
      const years = []
      for (let i = dayjs().year(); i >= 2020; i--) {
        years.push(i)
      }
      return years
    },
    filteredInvoices () {
      if (this.subscriptionInvoices) {
        return this.subscriptionInvoices.data.filter(invoice => dayjs(invoice.invoiceDate).year() === this.selectedYear)
      }
      return []
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, SUBSCRIPTION_BILLING_ITEMS_PER_PAGE)
      const itemsPerPage = itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : 50
      this.setPagination(itemsPerPage)
      return itemsPerPage
    },
  },
  watch: {
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, SUBSCRIPTION_BILLING_ITEMS_PER_PAGE))) {
          this.setPagination(newValue.itemsPerPage)
          setUserSetting(this.currentUserId, SUBSCRIPTION_BILLING_ITEMS_PER_PAGE, newValue.itemsPerPage)
        }
      },
    },
  },
  mounted () {
    this.prepareGetSubscriptionInvoices()
  },
  methods: {
    ...mapActions('subscription', [GET_SUBSCRIPTION_INVOICES, DOWNLOAD_INVOICES]),
    async prepareGetSubscriptionInvoices () {
      try {
        await this.GET_SUBSCRIPTION_INVOICES()
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('subscriptions.views.SubscriptionBilling.getSubscriptionInvoicesError'),
        })
      }
    },
    itemClass (item) {
      let classes = ''
      if (item.status === 'sent' && dayjs().isAfter(item.dueDate)) {
        classes += 'errorBorder'
      }
      if (this.selectedInvoices.includes(item)) {
        classes += ' selectedInvoice'
      }
      return classes
    },
    getInvoiceLink (item) {
      return `${process.env.VUE_APP_API_URL}/subscription/download/invoice/${item.id}`
    },
    async prepareDownloadInvoices () {
      try {
        await this.DOWNLOAD_INVOICES({
          invoiceId: this.selectedInvoices.map(invoice => invoice.id),
        })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('subscriptions.views.SubscriptionBilling.downloadInvoicesError'),
        })
      }
    },
    setPagination (nbItemsPerPage) {
      const files = this.filteredInvoices
      const nbPages = Math.ceil(files.length / nbItemsPerPage)
      this.dataTablePage = 1
      this.dataTablePageCount = nbPages > 0 ? nbPages : 1
      if (this.dataTablePageCount === 1) {
        this.disabledPagination = true
      } else {
        this.disabledPagination = false
      }
    },
    onPageChange (page) {
      this.$refs.SubscriptionBillingTable.$options.propsData.options.page = page
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .SubscriptionBilling-yearFilter {
  max-width: 150px;
}
::v-deep .SubscriptionBilling-table {
  background-color: transparent;
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }
  th {
    color: var(--v-accent-base) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    border-bottom: none !important;
  }
  td {
    color: var(--v-accent-base) !important;
    font-size: 14px !important;
    border-bottom: none !important;
    vertical-align: center;
    height: 52px !important;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  tbody {
    tr {
      background-color: #fff;
      &.errorBorder {
        td {
          border-top: 1px solid var(--v-error-base) !important;
          border-bottom: 1px solid var(--v-error-base) !important;
          &:first-child {
            border-left: 1px solid var(--v-error-base) !important;
          }
          &:last-child {
            border-right: 1px solid var(--v-error-base) !important;
          }
        }
      }
      &.selectedInvoice {
        td {
          background-color: var(--v-primary-lighten4);
        }
      }
    }
    tr.v-data-table__expanded__content {
      box-shadow: none;
    }
  }
  .v-input--selection-controls__input {
    width: 20px;
    height: 20px;
    i {
      background-color: #fff;
    }
  }
}
.SubscriptionBilling-creditCardIcon {
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  --fa-primary-color: #000;
  --fa-secondary-color: #f1d399;
  font-size: 16px;
}
.SubscriptionBilling-multiSelect-bar {
  display: flex;
  align-items: center;
  border: 1px solid var(--v-primary-base);
  border-radius: 8px;
  padding-left: 20px;
}

::v-deep .v-data-footer__icons-before {
  display: none;
}

::v-deep .v-data-footer__icons-after {
  display: none;
}
::v-deep .v-data-footer {
  flex-direction: row-reverse;
}

::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}
</style>
