<template>
  <v-container
    fluid
    class="pa-0"
    :class="{ 'px-1': $vuetify.breakpoint.mdAndDown }"
  >
    <v-row>
      <v-col>
        <h1 class="text-h1">{{ $t('project.views.Logs.title') }}</h1>
        <p class="mt-3 mb-0 body-1 accent--text text--lighten-2">{{ $t('project.views.Logs.subtitle') }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <LogsTopBar :mnemo="mnemo"/>
      </v-col>
    </v-row>
    <v-row v-if="hasActiveFilters">
      <v-col>
        <DataTableFiltersSummary :active-filters="logsFilters"
                                 class="mt-4"
                                 @clear-filter="clearFilter"
                                 @clear-all-filters="clearAllFilters"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <LogsDataTable :mnemo="mnemo"/>
      </v-col>
    </v-row>
    <LogsFiltersPane v-if="logsFiltersPaneIsOpen"/>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import DataTableFiltersSummary from '@/common/filters/DataTableFiltersSummary.vue'
import { REMOVE_LOG_FILTER, SET_LOGS_FILTERS } from '@/store/modules/logs/mutation_types'

import LogsDataTable from '../Logs/LogsDataTable'
import LogsFiltersPane from '../Logs/LogsFiltersPane'
import LogsTopBar from '../Logs/LogsTopBar'

export default {
  name: 'Logs',
  components: {
    DataTableFiltersSummary,
    LogsDataTable,
    LogsFiltersPane,
    LogsTopBar,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  metaInfo () {
    return {
      title: this.$t('project.views.Logs.title'),
    }
  },
  computed: {
    ...mapState('logs', ['logsFilters', 'logsFiltersPaneIsOpen']),
    ...mapGetters('logs', ['hasActiveFilters']),
  },
  beforeDestroy () {
    this.clearAllFilters()
  },
  methods: {
    ...mapMutations('logs', [REMOVE_LOG_FILTER, SET_LOGS_FILTERS]),
    clearFilter (filter) {
      this.REMOVE_LOG_FILTER(filter)
    },
    clearAllFilters () {
      this.SET_LOGS_FILTERS([])
    },
  },
}
</script>
