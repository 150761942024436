import { intersection } from 'lodash-es'

import { SurveyId, surveyCanBeTriggered, triggerSurvey } from '@/plugins/satismeter'
import documentsService from '@/services/documents.service'
import service from '@/services/signingChecklist.service'

import {
  BULK_UPSERT_SIGNING_CHECKLIST_STEP,
  CANCEL_CLOSING,
  CANCEL_SCHEDULED_CLOSING,
  CANCEL_STEP,
  CANCEL_STEP_MULTIPLE,
  CHANGE_PANE_TAB,
  CLOSE_PANE,
  COMPILE_FINAL_FILE,
  CREATE_GROUP,
  CREATE_SIGNING_CHECKLIST_STEP,
  CREATE_TITLE,
  DELETE_COMPILED_FILE,
  DELETE_GROUP,
  DELETE_SEARCH_RESULTS,
  DELETE_STEP,
  DELETE_STEP_MULTIPLE,
  DELETE_TITLE,
  DELETE_VALIDATION,
  GET_CLOSING_PREDICT,
  GET_CLOSING_SUMMARY,
  GET_DELETION_TYPE,
  GET_SEARCH_RESULTS,
  GET_SIGNATURE_FILES,
  GET_SIGNING_CHECKLIST,
  GET_SIGNING_CHECKLIST_GROUP,
  GET_SIGNING_VIEW,
  GET_STEP,
  MOVE_GROUP,
  MOVE_STEP,
  MUTE_QUALIFIED_DS_SIGNATURE_WARNING,
  OPEN_SHARE_ATTACHMENT_FILE_PANE,
  PATCH_COMPILED_FILE,
  POST_FINAL_FILE,
  POST_LAST_COMPILED_FILE,
  POST_MOVE_STEP,
  POST_SIGNED_FILE,
  POST_VALIDATION,
  PROCESS_ENVELOPE_DOCUMENTS_UPLOAD_QUEUE,
  REFRESH_CLOSING_STATUS,
  REFRESH_SIGNING_CHECKLIST,
  REFRESH_STATUS,
  REFRESH_STEP,
  RESET_SIGNATURE_FILES,
  RESET_STEP,
  RESET_STEP_MULTIPLE,
  SEND_SIGN_NOW,
  SEND_SIGN_NOW_MULTIPLE,
  SHARE_ATTACHMENT_FILE,
  START_CLOSING,
  START_SCHEDULED_CLOSING,
  SWAP_STEP_FILES,
  TITLE_INDENTATION,
  UNMUTE_QUALIFIED_DS_SIGNATURE_WARNING,
  UNSELECT_SIGNER_WITNESSES,
  UPDATE_CLOSING_END_DATE,
  UPDATE_SIGNING_CHECKLIST_STEP,
  UPDATE_TITLE,
  VALIDATE_THEN_SEND_NOW,
  VALIDATE_THEN_SEND_NOW_MULTIPLE,
  VALIDATE_WITNESSES_SELECTIONS,
} from './action_types'
import {
  ADD_CREATE_SIGNING_CHECKLIST_STEP_PENDING,
  ADD_OBSERVERS,
  ADD_RECIPIENTS,
  ADD_REFRESH_STEP_PENDING,
  ADD_SIGNER_WITNESSES,
  ADD_UPDATE_SIGNING_CHECKLIST_STEP_PENDING,
  MARK_QUEUED_DOCUMENT_AS_UPLOADING,
  REMOVE_CANCEL_STEP_MULTIPLE_PENDING,
  REMOVE_CANCEL_STEP_PENDING,
  REMOVE_CREATE_SIGNING_CHECKLIST_STEP_PENDING,
  REMOVE_DELETE_GROUP_PENDING,
  REMOVE_DELETE_STEP_MULTIPLE_PENDING,
  REMOVE_DELETE_STEP_PENDING,
  REMOVE_DELETE_TITLE_PENDING,
  REMOVE_ENVELOPE_DOCUMENTS_FROM_UPLOAD_QUEUE,
  REMOVE_FROM_EXPAND_TITLE_AFTER_DELETE,
  REMOVE_MOVE_GROUP_PENDING,
  REMOVE_MOVE_STEP_PENDING,
  REMOVE_OBSERVERS, REMOVE_RECIPIENTS,
  REMOVE_REFRESH_STEP_PENDING,
  REMOVE_RESET_STEP_MULTIPLE_PENDING,
  REMOVE_RESET_STEP_PENDING,
  REMOVE_SEND_SIGN_NOW_MULTIPLE_PENDING,
  REMOVE_SEND_SIGN_NOW_PENDING,
  REMOVE_SIGNER_WITNESSES, REMOVE_SIGNERS,
  REMOVE_STEP_PENDING,
  REMOVE_TITLE_INDENTATION_PENDING,
  REMOVE_UPDATE_SIGNING_CHECKLIST_STEP_PENDING,
  REMOVE_UPDATE_TITLE_PENDING,
  REMOVE_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING,
  REMOVE_VALIDATE_THEN_SEND_NOW_PENDING,
  SET_ATTACHMENT_FILE_TO_SHARE,
  SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_ERROR,
  SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_PENDING,
  SET_CANCEL_CLOSING_ERROR,
  SET_CANCEL_CLOSING_PENDING,
  SET_CANCEL_SCHEDULED_CLOSING_ERROR,
  SET_CANCEL_SCHEDULED_CLOSING_PENDING,
  SET_CANCEL_STEP_ERROR,
  SET_CANCEL_STEP_MULTIPLE_ERROR,
  SET_CANCEL_STEP_MULTIPLE_PENDING,
  SET_CANCEL_STEP_PENDING,
  SET_CLOSING_PREDICT,
  SET_CLOSING_PREDICT_ERROR,
  SET_CLOSING_PREDICT_PENDING,
  SET_CLOSING_SUMMARY,
  SET_CLOSING_SUMMARY_ERROR,
  SET_CLOSING_SUMMARY_PENDING,
  SET_COMPILE_FINAL_FILE_ERROR,
  SET_COMPILE_FINAL_FILE_PENDING,
  SET_CREATE_GROUP_ERROR,
  SET_CREATE_SIGNING_CHECKLIST_STEP_ERROR,
  SET_CREATE_TITLE_ERROR,
  SET_CREATE_TITLE_PENDING,
  SET_DELETE_COMPILED_FILE_ERROR,
  SET_DELETE_COMPILED_FILE_PENDING,
  SET_DELETE_GROUP_ERROR,
  SET_DELETE_GROUP_PENDING,
  SET_DELETE_STEP_ERROR,
  SET_DELETE_STEP_MULTIPLE_ERROR,
  SET_DELETE_STEP_MULTIPLE_PENDING,
  SET_DELETE_STEP_PENDING,
  SET_DELETE_TITLE_ERROR,
  SET_DELETE_TITLE_PENDING,
  SET_DELETE_VALIDATION_ERROR,
  SET_DELETE_VALIDATION_PENDING,
  SET_DELETION_TYPE,
  SET_DELETION_TYPE_ERROR,
  SET_DELETION_TYPE_PENDING,
  SET_EDIT_TITLE,
  SET_IS_PANE_OPENED,
  SET_IS_SENDING_DOCUMENT,
  SET_MOVE_GROUP_ERROR,
  SET_MOVE_GROUP_PENDING,
  SET_MOVE_STEP_ERROR,
  SET_MOVE_STEP_PENDING,
  SET_PANE_TAB,
  SET_PARENT_CHILDREN,
  SET_PATCH_COMPILED_FILE_ERROR,
  SET_PATCH_COMPILED_FILE_PENDING,
  SET_POST_FINAL_FILE_ERROR,
  SET_POST_FINAL_FILE_PENDING,
  SET_POST_LAST_COMPILED_FILE_ERROR,
  SET_POST_LAST_COMPILED_FILE_PENDING,
  SET_POST_MOVE_STEP_ERROR,
  SET_POST_MOVE_STEP_PENDING,
  SET_POST_SIGNED_FILE_ERROR,
  SET_POST_SIGNED_FILE_PENDING,
  SET_POST_VALIDATION_ERROR,
  SET_POST_VALIDATION_PENDING,
  SET_QUALIFIED_DS_WARNING_IS_MUTED,
  SET_REFRESH_CLOSING_STATUS_ERROR,
  SET_REFRESH_CLOSING_STATUS_PENDING,
  SET_REFRESH_SIGNING_CHECKLIST,
  SET_REFRESH_SIGNING_CHECKLIST_ERROR,
  SET_REFRESH_SIGNING_CHECKLIST_PENDING,
  SET_REFRESH_STATUS_ERROR,
  SET_REFRESH_STATUS_PENDING,
  SET_REFRESH_STEP,
  SET_REFRESH_STEP_ERROR,
  SET_RESET_SIGNATURE_FILES,
  SET_RESET_SIGNATURE_FILES_ERROR,
  SET_RESET_SIGNATURE_FILES_PENDING,
  SET_RESET_STEP_ERROR,
  SET_RESET_STEP_MULTIPLE_ERROR,
  SET_RESET_STEP_MULTIPLE_PENDING,
  SET_RESET_STEP_PENDING,
  SET_SEARCH_QUERY,
  SET_SEARCH_QUERY_PENDING,
  SET_SELECTED_STEPS,
  SET_SEND_SIGN_NOW_ERROR,
  SET_SEND_SIGN_NOW_MULTIPLE_ERROR,
  SET_SEND_SIGN_NOW_MULTIPLE_PENDING,
  SET_SEND_SIGN_NOW_PENDING,
  SET_SHARE_ATTACHMENT_PENDING,
  SET_SIGNATURE_FILES,
  SET_SIGNATURE_FILES_ERROR,
  SET_SIGNATURE_FILES_PENDING,
  SET_SIGNING_CHECKLIST,
  SET_SIGNING_CHECKLIST_ERROR,
  SET_SIGNING_CHECKLIST_GROUP,
  SET_SIGNING_CHECKLIST_GROUP_ERROR,
  SET_SIGNING_CHECKLIST_GROUP_PENDING,
  SET_SIGNING_CHECKLIST_PENDING,
  SET_SIGNING_CHECKLIST_STEP,
  SET_SIGNING_VIEW,
  SET_SIGNING_VIEW_ERROR,
  SET_SIGNING_VIEW_PENDING,
  SET_START_CLOSING_ERROR,
  SET_START_CLOSING_PENDING,
  SET_START_SCHEDULED_CLOSING_ERROR,
  SET_START_SCHEDULED_CLOSING_PENDING,
  SET_STEP_ERROR,
  SET_STEP_PENDING,
  SET_SWAP_FILES_PENDING,
  SET_TITLE_INDENTATION_ERROR,
  SET_TITLE_INDENTATION_PENDING,
  SET_TOTAL_DOCUMENTS,
  SET_UPDATE_CLOSING_END_DATE_ERROR,
  SET_UPDATE_CLOSING_END_DATE_PENDING,
  SET_UPDATE_SIGNING_CHECKLIST_STEP_ERROR,
  SET_UPDATE_TITLE_ERROR,
  SET_UPDATE_TITLE_PENDING,
  SET_VALIDATE_THEN_SEND_NOW_ERROR,
  SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_ERROR,
  SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING,
  SET_VALIDATE_THEN_SEND_NOW_PENDING,
} from './mutation_types'
import {
  SET_CLOSING_SCHEDULED_AT,
  SET_CLOSING_SCHEDULED_END_AT,
  SET_IN_CLOSING,
  SET_CLOSING_STARTED_AT,
  RESET_CLOSING_DATA,
} from '../room/mutation_types'

export const actions = {
  async [UPDATE_CLOSING_END_DATE] ({ commit, rootGetters }, { mnemo, data }) {
    commit(SET_UPDATE_CLOSING_END_DATE_PENDING, true)
    try {
      const response = await service.updateClosingEndDate(mnemo, data)
      if (rootGetters['room/roomMnemo'] === mnemo) {
        commit('room/' + SET_CLOSING_SCHEDULED_END_AT, response.data.closingScheduledEndAt, { root: true })
      }
    } catch (error) {
      commit(SET_UPDATE_CLOSING_END_DATE_ERROR, error)
      throw error
    } finally {
      commit(SET_UPDATE_CLOSING_END_DATE_PENDING, false)
    }
  },
  async [PATCH_COMPILED_FILE] ({ commit }, { mnemo, envelopeId, data }) {
    commit(SET_PATCH_COMPILED_FILE_PENDING, true)
    try {
      await service.patchCompiledFile(mnemo, envelopeId, data)
    } catch (error) {
      commit(SET_PATCH_COMPILED_FILE_ERROR, error)
      throw error
    } finally {
      commit(SET_PATCH_COMPILED_FILE_PENDING, false)
    }
  },
  async [DELETE_COMPILED_FILE] ({ commit }, { mnemo, envelopeId }) {
    commit(SET_DELETE_COMPILED_FILE_PENDING, true)
    try {
      await service.deleteCompiledFile(mnemo, envelopeId)
    } catch (error) {
      commit(SET_DELETE_COMPILED_FILE_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_COMPILED_FILE_PENDING, false)
    }
  },
  async [POST_LAST_COMPILED_FILE] ({ commit }, { mnemo, envelopeId, data }) {
    commit(SET_POST_LAST_COMPILED_FILE_PENDING, true)
    try {
      await service.postLastCompiledFile(mnemo, envelopeId, data)
    } catch (error) {
      commit(SET_POST_LAST_COMPILED_FILE_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_LAST_COMPILED_FILE_PENDING, false)
    }
  },
  async [DELETE_TITLE] ({ commit, state }, { mnemo, id }) {
    commit(SET_DELETE_TITLE_PENDING, id)
    try {
      await service.deleteTitle(mnemo, id)
      commit(REMOVE_FROM_EXPAND_TITLE_AFTER_DELETE, id)
    } catch (error) {
      commit(SET_DELETE_TITLE_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_DELETE_TITLE_PENDING,
        state.deleteTitlePendingIds.indexOf(id))
    }
  },
  async [TITLE_INDENTATION] ({ commit, state }, { mnemo, id, action }) {
    commit(SET_TITLE_INDENTATION_PENDING, {
      id,
      action,
    })
    try {
      let response
      if (action === 'increment') {
        response = await service.incrementTitleIndentation(mnemo, id)
      } else if (action === 'decrement') {
        response = await service.decrementTitleIndentation(mnemo, id)
      }
      return response.data
    } catch (error) {
      commit(SET_TITLE_INDENTATION_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_TITLE_INDENTATION_PENDING,
        state.titleIndentationPendingIds.findIndex(
          (obj) => obj.id === id && obj.action === action,
        ),
      )
    }
  },
  async [VALIDATE_THEN_SEND_NOW_MULTIPLE] ({ commit, state }, { mnemo, ids }) {
    commit(SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING, ids)
    try {
      const { data } = await service.validateThenSendNowMultiple(mnemo, ids)
      return data
    } catch (error) {
      commit(SET_VALIDATE_THEN_SEND_NOW_MULTIPLE_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_VALIDATE_THEN_SEND_NOW_MULTIPLE_PENDING, ids)
    }
  },
  async [RESET_STEP_MULTIPLE] ({ commit, state }, { mnemo, ids }) {
    commit(SET_RESET_STEP_MULTIPLE_PENDING, ids)
    try {
      await service.resetStepMultiple(mnemo, ids)
    } catch (error) {
      commit(SET_RESET_STEP_MULTIPLE_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_RESET_STEP_MULTIPLE_PENDING, ids)
    }
  },
  async [DELETE_STEP_MULTIPLE] ({ commit }, { mnemo, ids }) {
    commit(SET_DELETE_STEP_MULTIPLE_PENDING, ids)
    try {
      return await service.deleteStepMultiple(mnemo, ids)
    } catch (error) {
      commit(SET_DELETE_STEP_MULTIPLE_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_DELETE_STEP_MULTIPLE_PENDING, ids)
    }
  },
  async [CANCEL_STEP_MULTIPLE] ({ commit, state }, { mnemo, stepsIds }) {
    commit(SET_CANCEL_STEP_MULTIPLE_PENDING, stepsIds)
    try {
      const { data } = await service.cancelStepMultiple(mnemo, stepsIds)

      for (const step of data.steps) {
        commit(SET_SIGNING_CHECKLIST_STEP, step)
      }
    } catch (error) {
      commit(SET_CANCEL_STEP_MULTIPLE_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_CANCEL_STEP_MULTIPLE_PENDING, stepsIds)
    }
  },
  async [SEND_SIGN_NOW_MULTIPLE] ({ state, commit }, { mnemo, stepsIds }) {
    commit(SET_SEND_SIGN_NOW_MULTIPLE_PENDING, stepsIds)
    try {
      const { data } = await service.sendSignNowMultiple(mnemo, stepsIds)

      for (const step of data.steps) {
        commit(SET_SIGNING_CHECKLIST_STEP, step)
      }
    } catch (error) {
      commit(SET_SEND_SIGN_NOW_MULTIPLE_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_SEND_SIGN_NOW_MULTIPLE_PENDING, stepsIds)
    }
  },
  async [RESET_STEP] ({ commit, state }, { mnemo, envId }) {
    commit(SET_RESET_STEP_PENDING, envId)
    try {
      return await service.resetStep(mnemo, envId)
    } catch (error) {
      commit(SET_RESET_STEP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_RESET_STEP_PENDING,
        state.resetStepPendingIds.indexOf(envId))
    }
  },
  async [VALIDATE_THEN_SEND_NOW] ({ commit, state }, { mnemo, envId }) {
    commit(SET_VALIDATE_THEN_SEND_NOW_PENDING, envId)
    try {
      return await service.validateThenSendNow(mnemo, envId)
    } catch (error) {
      commit(SET_VALIDATE_THEN_SEND_NOW_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_VALIDATE_THEN_SEND_NOW_PENDING,
        state.validateThenSendNowPendingIds.indexOf(envId))
    }
  },
  async [CANCEL_STEP] ({ commit, state }, { mnemo, stepId }) {
    commit(SET_CANCEL_STEP_PENDING, stepId)
    try {
      const { data } = await service.cancelStep(mnemo, stepId)
      commit(SET_SIGNING_CHECKLIST_STEP, data)
    } catch (error) {
      commit(SET_CANCEL_STEP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_CANCEL_STEP_PENDING,
        state.cancelStepPendingIds.indexOf(stepId))
    }
  },
  async [CANCEL_CLOSING] ({ commit, rootGetters }, mnemo) {
    commit(SET_CANCEL_CLOSING_PENDING, true)
    try {
      await service.cancelClosing(mnemo)
      if (rootGetters['room/roomMnemo'] === mnemo) {
        commit('room/' + RESET_CLOSING_DATA, undefined, { root: true })
      }
    } catch (error) {
      commit(SET_CANCEL_CLOSING_ERROR, error)
      throw error
    } finally {
      commit(SET_CANCEL_CLOSING_PENDING, false)
    }
  },
  async [CANCEL_SCHEDULED_CLOSING] ({ commit, rootGetters }, mnemo) {
    commit(SET_CANCEL_SCHEDULED_CLOSING_PENDING, true)
    try {
      await service.cancelScheduledClosing(mnemo)
      if (rootGetters['room/roomMnemo'] === mnemo) {
        commit('room/' + RESET_CLOSING_DATA, undefined, { root: true })
      }
    } catch (error) {
      commit(SET_CANCEL_SCHEDULED_CLOSING_ERROR, error)
      throw error
    } finally {
      commit(SET_CANCEL_SCHEDULED_CLOSING_PENDING, false)
    }
  },
  async [START_SCHEDULED_CLOSING] ({ commit, rootGetters }, { mnemo, data }) {
    commit(SET_START_SCHEDULED_CLOSING_PENDING, true)
    try {
      await service.updateScheduledClosing(mnemo, data)
      if (rootGetters['room/roomMnemo'] === mnemo) {
        commit('room/' + SET_CLOSING_SCHEDULED_AT, data.scheduledAt, { root: true })
        commit('room/' + SET_CLOSING_SCHEDULED_END_AT, data.scheduledEndAt, { root: true })
      }
    } catch (error) {
      commit(SET_START_SCHEDULED_CLOSING_ERROR, error)
      throw error
    } finally {
      commit(SET_START_SCHEDULED_CLOSING_PENDING, false)
    }
  },
  async [START_CLOSING] ({ commit, rootGetters, rootState }, { mnemo, data }) {
    commit(SET_START_CLOSING_PENDING, true)
    try {
      const response = await service.startClosing(mnemo, data)
      if (rootGetters['room/roomMnemo'] === mnemo) {
        commit('room/' + SET_IN_CLOSING, true, { root: true })
        commit('room/' + SET_CLOSING_STARTED_AT, response.data.closingStartedAt, { root: true })
        commit('room/' + SET_CLOSING_SCHEDULED_END_AT, response.data.closingScheduledEndAt, { root: true })
        commit(SET_IS_SENDING_DOCUMENT, true)
        const { id: userId, fullName, email, isSubUser } = rootState.user.profile
        if (surveyCanBeTriggered(SurveyId.NPS, userId)) {
          triggerSurvey(SurveyId.NPS, userId, fullName, email, isSubUser)
        }
      }
    } catch (error) {
      commit(SET_START_CLOSING_ERROR, error)
      throw error
    } finally {
      commit(SET_START_CLOSING_PENDING, false)
    }
  },
  async [GET_CLOSING_SUMMARY] ({ commit }, mnemo) {
    commit(SET_CLOSING_SUMMARY_PENDING, true)
    try {
      const { data } = await service.getClosingSummary(mnemo)
      // Force PDF extension on all of them for display purpose
      data.groups.forEach((group) => {
        group.docs.forEach((doc) => {
          doc.ext = 'pdf'
        })
      })
      commit(SET_CLOSING_SUMMARY, data)
      return data
    } catch (error) {
      commit(SET_CLOSING_SUMMARY_ERROR, error)
      throw error
    } finally {
      commit(SET_CLOSING_SUMMARY_PENDING, false)
    }
  },
  async [GET_CLOSING_PREDICT] ({ commit }, { mnemo, params }) {
    commit(SET_CLOSING_PREDICT_PENDING, true)
    try {
      const { data } = await service.getClosingPredict(mnemo, { params: params })
      commit(SET_CLOSING_PREDICT, data)
      return data
    } catch (error) {
      commit(SET_CLOSING_PREDICT_ERROR, error)
      throw error
    } finally {
      commit(SET_CLOSING_PREDICT_PENDING, false)
    }
  },
  async [REFRESH_CLOSING_STATUS] ({ commit, dispatch, rootGetters }, { mnemo, isPolling = false }) {
    commit(SET_REFRESH_CLOSING_STATUS_PENDING, true)
    try {
      const { data } = await service.refreshClosingStatus(mnemo, isPolling)
      if (rootGetters['room/roomMnemo'] === mnemo) {
        commit('room/' + SET_CLOSING_STARTED_AT, data.closingStartedAt, { root: true })
        commit('room/' + SET_CLOSING_SCHEDULED_AT, data.closingScheduledAt, { root: true })
        commit('room/' + SET_CLOSING_SCHEDULED_END_AT, data.closingScheduledEndAt, { root: true })
        commit('room/' + SET_IN_CLOSING, !!data.closingStarted, { root: true })
      }
      return data
    } catch (error) {
      commit(SET_REFRESH_CLOSING_STATUS_ERROR, error)
      throw error
    } finally {
      commit(SET_REFRESH_CLOSING_STATUS_PENDING, false)
    }
  },
  async [REFRESH_STATUS] ({ commit }, mnemo) {
    commit(SET_REFRESH_STATUS_PENDING, true)
    try {
      const { data } = await service.refreshStatus(mnemo)
      return data
    } catch (error) {
      commit(SET_REFRESH_STATUS_ERROR, error)
      throw error
    } finally {
      commit(SET_REFRESH_STATUS_PENDING, false)
    }
  },
  async [UPDATE_TITLE] ({ commit, state }, { mnemo, data, item, parent }) {
    const { id } = item
    commit(SET_UPDATE_TITLE_PENDING, id)
    try {
      const { data: updatedTitle } = await service.updateTitle(mnemo, data, id)
      item.title = updatedTitle.title
      commit(SET_EDIT_TITLE, { parent, item })
    } catch (error) {
      commit(SET_UPDATE_TITLE_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_UPDATE_TITLE_PENDING,
        state.updateTitlePendingIds.indexOf(id))
    }
  },
  async [CREATE_TITLE] ({ commit }, { mnemo, data, parent }) {
    commit(SET_CREATE_TITLE_PENDING, true)
    try {
      const { data: createdTitle } = await service.createTitle(mnemo, data)

      // If we don't have a parent, it's an array of the direct children
      // Otherwise it's an object with a children property
      const root = Array.isArray(parent)

      const shallowCopy = root ? parent.slice() : parent.children.slice()
      // If the prevId is -1, it's a push to the end
      const insertIndex = data.prevId === -1
        ? shallowCopy.length + 1
        : shallowCopy.findIndex((step) => step.id === data.prevId && step.type === data.prevType) + 1
      shallowCopy.splice(insertIndex, 0, createdTitle)

      if (root) {
        commit(SET_SIGNING_CHECKLIST, shallowCopy)
      } else {
        commit(SET_PARENT_CHILDREN, {
          parent,
          children: shallowCopy,
        })
      }
      return createdTitle
    } catch (error) {
      commit(SET_CREATE_TITLE_ERROR, error)
      throw error
    } finally {
      commit(SET_CREATE_TITLE_PENDING, false)
    }
  },
  async [GET_DELETION_TYPE] ({ commit }, { mnemo, stepId }) {
    commit(SET_DELETION_TYPE_PENDING, true)
    try {
      const { data } = await service.getDeletionType(mnemo, stepId)
      commit(SET_DELETION_TYPE, data)
    } catch (error) {
      commit(SET_DELETION_TYPE_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETION_TYPE_PENDING, false)
    }
  },
  async [SEND_SIGN_NOW] ({ commit, state }, { mnemo, step }) {
    commit(SET_SEND_SIGN_NOW_PENDING, step.id)
    try {
      const { data } = await service.sendSignNow(mnemo, step.id)
      commit(SET_SIGNING_CHECKLIST_STEP, data)
    } catch (error) {
      commit(SET_SEND_SIGN_NOW_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_SEND_SIGN_NOW_PENDING,
        state.sendSignNowPendingIds.indexOf(step.id))
    }
  },
  async [DELETE_STEP] ({ commit, state }, { mnemo, stepId }) {
    commit(SET_DELETE_STEP_PENDING, stepId)
    try {
      await service.deleteStep(mnemo, stepId)
    } catch (error) {
      commit(SET_DELETE_STEP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_DELETE_STEP_PENDING,
        state.deleteStepPendingIds.indexOf(stepId))
    }
  },
  async [SWAP_STEP_FILES] ({ commit, state }, { mnemo, data }) {
    commit(SET_SWAP_FILES_PENDING, true)
    try {
      await service.swapStepFiles(mnemo, data)
    } catch (error) {
      console.error(error)
    } finally {
      commit(SET_SWAP_FILES_PENDING, false)
    }
  },
  async [GET_STEP] ({ commit, state }, { mnemo, id }) {
    commit(SET_STEP_PENDING, id)
    try {
      const { data } = await service.getStep(mnemo, id)
      return data
    } catch (error) {
      commit(SET_STEP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_STEP_PENDING,
        state.stepPendingIds.indexOf(id))
    }
  },
  async [RESET_SIGNATURE_FILES] ({ commit }) {
    commit(SET_RESET_SIGNATURE_FILES_PENDING, true)
    try {
      commit(SET_RESET_SIGNATURE_FILES)
    } catch (error) {
      commit(SET_RESET_SIGNATURE_FILES_ERROR, error)
      throw error
    } finally {
      commit(SET_RESET_SIGNATURE_FILES_PENDING, false)
    }
  },
  async [MOVE_STEP] ({ commit, state }, { mnemo, stepId, groupId }) {
    commit(SET_MOVE_STEP_PENDING, stepId)
    try {
      await service.moveStep(mnemo, stepId, groupId)
    } catch (error) {
      commit(SET_MOVE_STEP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_MOVE_STEP_PENDING,
        state.moveStepPendingIds.indexOf(stepId),
      )
    }
  },
  async [MOVE_GROUP] ({ commit, state }, { mnemo, position, blockId }) {
    commit(SET_MOVE_GROUP_PENDING, blockId)
    try {
      await service.moveGroup(mnemo, position, blockId)
    } catch (error) {
      commit(SET_MOVE_GROUP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_MOVE_GROUP_PENDING,
        state.moveGroupPendingIds.indexOf(blockId),
      )
    }
  },
  async [DELETE_GROUP] ({ commit, state }, { mnemo, groupId }) {
    commit(SET_DELETE_GROUP_PENDING, groupId)
    try {
      await service.deleteGroup(mnemo, groupId)
    } catch (error) {
      commit(SET_DELETE_GROUP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_DELETE_GROUP_PENDING,
        state.deleteGroupPendingIds.indexOf(groupId),
      )
    }
  },
  async [CREATE_GROUP] ({ commit }, { mnemo, position }) {
    try {
      return service.createGroup(mnemo, position)
    } catch (error) {
      commit(SET_CREATE_GROUP_ERROR, error)
      throw error
    }
  },
  async [GET_SIGNING_CHECKLIST_GROUP] ({ commit }, mnemo) {
    commit(SET_SIGNING_CHECKLIST_GROUP_PENDING, true)
    try {
      const { data } = await service.getsigningChecklistGroups(mnemo)
      commit(SET_SIGNING_CHECKLIST_GROUP, data.groups)
    } catch (error) {
      commit(SET_SIGNING_CHECKLIST_GROUP_ERROR, error)
      throw error
    } finally {
      commit(SET_SIGNING_CHECKLIST_GROUP_PENDING, false)
    }
  },
  async [BULK_UPSERT_SIGNING_CHECKLIST_STEP] ({ commit }, { mnemo, steps }) {
    commit(SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_PENDING, true)
    try {
      const { data } = await service.bulkUpsertSigningChecklistStep(mnemo, steps)
      return data
    } catch (error) {
      commit(SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_ERROR, error)
      throw error
    } finally {
      commit(SET_BULK_UPSERT_SIGNING_CHECKLIST_STEP_PENDING, false)
    }
  },
  async [GET_SIGNING_VIEW] ({ commit }, { mnemo, envelopeId }) {
    commit(SET_SIGNING_VIEW_PENDING, true)
    try {
      const response = await service.getSigningView(mnemo, envelopeId)
      commit(SET_SIGNING_VIEW, response.data)
    } catch (error) {
      commit(SET_SIGNING_VIEW_ERROR, error)
      throw error
    } finally {
      commit(SET_SIGNING_VIEW_PENDING, false)
    }
  },
  async [REFRESH_SIGNING_CHECKLIST] ({ commit, state, dispatch, rootGetters }, { mnemo, queryObject }) {
    commit(SET_REFRESH_SIGNING_CHECKLIST_PENDING, true)
    try {
      const { data } = await service.getSigningChecklist(mnemo, queryObject)
      // We could have no data if we have a query param that specifies no data if nothing changed
      // We also don't want to update if we're currently editing a title
      if (!state.currentTitleEdit && data && typeof data === 'object' && data.titles) {
        const fakeTitle = data.titles.find(el => el.id === 0 && el.type === 'title')
        if (fakeTitle) {
          fakeTitle.display = 0
        }
        commit(SET_REFRESH_SIGNING_CHECKLIST, data.titles.sort((a, b) => a.display - b.display) || [])
        commit(SET_TOTAL_DOCUMENTS, data.totalDocuments)
      }
    } catch (error) {
      commit(SET_REFRESH_SIGNING_CHECKLIST_ERROR, error)
      throw error
    } finally {
      commit(SET_REFRESH_SIGNING_CHECKLIST_PENDING, false)
    }
  },
  async [POST_MOVE_STEP] ({ commit }, { mnemo, stepId, stepType, data }) {
    commit(SET_POST_MOVE_STEP_PENDING, true)
    try {
      await service.postMoveStep(mnemo, stepId, stepType, data)
    } catch (error) {
      commit(SET_POST_MOVE_STEP_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_MOVE_STEP_PENDING, false)
    }
  },
  async [POST_VALIDATION] ({ commit }, { mnemo, envelopeId, validationId, data }) {
    commit(SET_POST_VALIDATION_PENDING, true)
    try {
      await service.postValidation(mnemo, envelopeId, validationId, data)
    } catch (error) {
      commit(SET_POST_VALIDATION_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_VALIDATION_PENDING, false)
    }
  },
  async [DELETE_VALIDATION] ({ commit }, { mnemo, envelopeId, validationId }) {
    commit(SET_DELETE_VALIDATION_PENDING, true)
    try {
      await service.deleteValidation(mnemo, envelopeId, validationId)
    } catch (error) {
      commit(SET_DELETE_VALIDATION_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_VALIDATION_PENDING, false)
    }
  },
  async [REFRESH_STEP] ({ commit, state }, { mnemo, stepId, parent }) {
    commit(ADD_REFRESH_STEP_PENDING, stepId)
    try {
      const response = await service.getStep(mnemo, stepId)
      commit(SET_REFRESH_STEP, { newStep: response.data, parent })
    } catch (error) {
      commit(SET_REFRESH_STEP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_REFRESH_STEP_PENDING, state.refreshStepPendingIds.indexOf(stepId))
    }
  },
  async [GET_SIGNING_CHECKLIST] ({ commit }, { mnemo, queryObject }) {
    commit(SET_SIGNING_CHECKLIST_PENDING, true)
    try {
      const { data } = await service.getSigningChecklist(mnemo, queryObject)
      const fakeTitle = data.titles.find(el => el.id === 0 && el.type === 'title')
      if (fakeTitle) {
        fakeTitle.display = 0
      }
      commit(SET_SIGNING_CHECKLIST, data.titles.sort((a, b) => a.display - b.display) || [])
      commit(SET_TOTAL_DOCUMENTS, data.totalDocuments)
    } catch (error) {
      commit(SET_SIGNING_CHECKLIST_ERROR, error)
      throw error
    } finally {
      commit(SET_SIGNING_CHECKLIST_PENDING, false)
    }
  },
  async [GET_SIGNATURE_FILES] ({ commit }, { mnemo, envelopeId, signerId }) {
    commit(SET_SIGNATURE_FILES_PENDING, true)
    try {
      const response = await service.getSignatureFiles(mnemo, envelopeId)
      for (const s of response.data.signatures) {
        if (s.signature.signerId === signerId) {
          commit(SET_SIGNATURE_FILES, s)
        }
      }
    } catch (error) {
      commit(SET_SIGNATURE_FILES_ERROR, error)
      throw error
    } finally {
      commit(SET_SIGNATURE_FILES_PENDING, false)
    }
  },
  async [COMPILE_FINAL_FILE] ({ commit }, { mnemo, envelopeId }) {
    commit(SET_COMPILE_FINAL_FILE_PENDING, true)
    try {
      return await service.compileFinalFile(mnemo, envelopeId)
    } catch (error) {
      commit(SET_COMPILE_FINAL_FILE_ERROR, error)
      throw error
    } finally {
      commit(SET_COMPILE_FINAL_FILE_PENDING, false)
    }
  },
  async [POST_FINAL_FILE] ({ commit }, { mnemo, envelopeId, formData }) {
    commit(SET_POST_FINAL_FILE_PENDING, true)
    try {
      await service.postFinalFile(mnemo, envelopeId, formData)
    } catch (error) {
      commit(SET_POST_FINAL_FILE_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_FINAL_FILE_PENDING, false)
    }
  },
  async [POST_SIGNED_FILE] ({ commit }, { mnemo, envelopeId, formData }) {
    commit(SET_POST_SIGNED_FILE_PENDING, true)
    try {
      await service.postSignedFile(mnemo, envelopeId, formData)
    } catch (error) {
      commit(SET_POST_SIGNED_FILE_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_SIGNED_FILE_PENDING, false)
    }
  },
  async [UPDATE_SIGNING_CHECKLIST_STEP] ({ commit, state }, { mnemo, step }) {
    commit(ADD_UPDATE_SIGNING_CHECKLIST_STEP_PENDING, step.id)
    try {
      return await service.updateSigningChecklistStep(mnemo, step)
    } catch (error) {
      commit(SET_UPDATE_SIGNING_CHECKLIST_STEP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_UPDATE_SIGNING_CHECKLIST_STEP_PENDING, state.updateSigningChecklistStepPendingIds.indexOf(step.id))
    }
  },
  async [CREATE_SIGNING_CHECKLIST_STEP] ({ commit, state }, { mnemo, step }) {
    commit(ADD_CREATE_SIGNING_CHECKLIST_STEP_PENDING, step.id)
    try {
      const { data } = await service.createSigningChecklistStep(mnemo, step)
      step.envelope = data.envelope
      step.stepId = data.id
    } catch (error) {
      commit(SET_CREATE_SIGNING_CHECKLIST_STEP_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_CREATE_SIGNING_CHECKLIST_STEP_PENDING, state.createSigningChecklistStepPendingIds.indexOf(step.id))
    }
  },
  [CLOSE_PANE] ({ commit }) {
    commit(SET_IS_PANE_OPENED, false)
  },
  [CHANGE_PANE_TAB] ({ commit }, tab) {
    commit(SET_PANE_TAB, tab)
  },
  [OPEN_SHARE_ATTACHMENT_FILE_PANE] ({ commit }, attachmentFile) {
    commit(SET_PANE_TAB, 'share-attachment')
    commit(SET_ATTACHMENT_FILE_TO_SHARE, attachmentFile)
    commit(SET_IS_PANE_OPENED, true)
  },
  async [SHARE_ATTACHMENT_FILE] ({ commit, rootGetters, rootState, state }, {
    notifyMessage,
    sendNotification,
    shareType,
  }) {
    const data = {
      fileIds: [state.pane.fileToShare.id],
      shareType,
      withUsers: rootState.documentSharePicker.selectedMembers.map(i => { return i.id }),
      withGroups: rootState.documentSharePicker.selectedGroups.map(i => { return i.id }),
      sendNotif: sendNotification,
      notifyMessage: notifyMessage,
    }
    commit(SET_SHARE_ATTACHMENT_PENDING, true)

    try {
      await documentsService.shareFilders(rootGetters['room/roomMnemo'], data)
    } finally {
      commit(SET_SHARE_ATTACHMENT_PENDING, false)
    }
  },
  [MUTE_QUALIFIED_DS_SIGNATURE_WARNING] ({ commit }) {
    commit(SET_QUALIFIED_DS_WARNING_IS_MUTED, true)
  },
  [UNMUTE_QUALIFIED_DS_SIGNATURE_WARNING] ({ commit }) {
    commit(SET_QUALIFIED_DS_WARNING_IS_MUTED, false)
  },
  [DELETE_SEARCH_RESULTS] ({ commit }) {
    commit(SET_SEARCH_QUERY, [])
    commit(SET_SELECTED_STEPS, [])
  },
  [GET_SEARCH_RESULTS] ({ commit }, searchQuery) {
    commit(SET_SEARCH_QUERY_PENDING, true)

    try {
      const searchQueryArray = searchQuery.split(' ').filter(item => item)
      commit(SET_SEARCH_QUERY, searchQueryArray)
      commit(SET_SELECTED_STEPS, [])
    } catch (error) {
      commit(SET_SIGNING_CHECKLIST_ERROR, error)
      throw error
    } finally {
      setTimeout(() => {
        commit(SET_SEARCH_QUERY_PENDING, false)
      }, 300)
    }
  },
  [PROCESS_ENVELOPE_DOCUMENTS_UPLOAD_QUEUE] (
    { commit, dispatch, getters, rootGetters, state },
    { onEachSuccessCallback, onEachUploadProgressCallback, onEachErrorCallback },
  ) {
    const simultaneousFilesUpload = process.env.VUE_APP_SIMULTANEOUS_FILES_UPLOAD || 2

    for (const documentToUpload of getters.queuedDocumentsToUpload) {
      if (getters.documentsCurrentlyUploading.length >= simultaneousFilesUpload) {
        break
      }

      commit(MARK_QUEUED_DOCUMENT_AS_UPLOADING, documentToUpload.queueId)

      const onDocumentUploadProgress = (uploadProgression) => {
        onEachUploadProgressCallback?.(documentToUpload.queueId, uploadProgression)
      }

      documentsService.postHiddenFile(
        documentToUpload,
        rootGetters['room/roomMnemo'],
        onDocumentUploadProgress,
      )
        .then(({ data: uploadedDocument }) => onEachSuccessCallback(uploadedDocument, documentToUpload.queueId))
        .catch(error => {
          console.error(error)
          onEachErrorCallback(documentToUpload.queueId)
        })
        .finally(() => {
          commit(REMOVE_ENVELOPE_DOCUMENTS_FROM_UPLOAD_QUEUE, documentToUpload.queueId)

          if (getters.documentsCurrentlyUploading.length < simultaneousFilesUpload) {
            dispatch(PROCESS_ENVELOPE_DOCUMENTS_UPLOAD_QUEUE, { onEachSuccessCallback, onEachErrorCallback })
          }
        })
    }
  },
  [UNSELECT_SIGNER_WITNESSES] ({ commit, state, rootGetters }, { importedFileId, signerId }) {
    const importedFile = state.importedFiles.find(importedFile => importedFile.id === importedFileId)
    if (!importedFile) {
      throw new Error(`Imported file ${importedFileId} does not exist`)
    }

    const signerWithWitnesses = importedFile.objectToPost.witnesses.find(
      signerWithWitnesses => signerWithWitnesses.signer === signerId,
    )
    if (!signerWithWitnesses) {
      throw new Error(`Signer ${signerId} is not bound to any witnesses`)
    }

    const observersToRemove = signerWithWitnesses.witness.filter(
      witnessId => !rootGetters['groups/projectManagers'].some(projectManager => projectManager.id === witnessId),
    )
    commit(REMOVE_OBSERVERS, {
      importedFileId,
      observersIds: observersToRemove,
    })
    commit(REMOVE_SIGNER_WITNESSES, { importedFileId, signerId })
  },
  [VALIDATE_WITNESSES_SELECTIONS] ({ commit, state }, { importedFileId, signersWithWitnesses }) {
    const importedFile = state.importedFiles.find(importedFile => importedFile.id === importedFileId)
    if (!importedFile) {
      throw new Error(`Imported file ${importedFileId} does not exist`)
    }

    const newWitnessesIds = signersWithWitnesses.flatMap(signerAndWitnesses => signerAndWitnesses.witness)
    const previousWitnessesIds = (importedFile.objectToPost.witnesses ?? []).flatMap(signerAndWitnesses => signerAndWitnesses.witness)
    const newWitnessesPreviouslySigners = intersection((importedFile.objectToPost.signers ?? []).flat(), newWitnessesIds)

    if (previousWitnessesIds.length > 0) {
      commit(REMOVE_OBSERVERS, { importedFileId, observersIds: previousWitnessesIds })
      commit(REMOVE_RECIPIENTS, { importedFileId, recipientsIds: previousWitnessesIds })
    }

    if (newWitnessesPreviouslySigners.length > 0) {
      commit(REMOVE_SIGNERS, { importedFileId, signersIds: newWitnessesPreviouslySigners })
      commit(REMOVE_RECIPIENTS, { importedFileId, recipientsIds: newWitnessesPreviouslySigners })
    }

    commit(ADD_SIGNER_WITNESSES, { importedFileId, signersWithWitnesses })
    commit(ADD_OBSERVERS, { importedFileId, observersIds: newWitnessesIds })
    commit(ADD_RECIPIENTS, { importedFileId, recipientsIds: newWitnessesIds })
  },
}
