<template>
  <div v-intersect.quiet="{
                  handler: onIntersect,
                  options: {
                    threshold: .5,
                  },
                }">
    <template v-if="thumb">
      <div class="CerteuropeSignThumb-container">
        <v-img :src="`data:image/png;base64,${thumb}`"
               :width="100"
               :height="142"
               class="CerteuropeSignThumb mx-auto"
               :class="{'CerteuropeSignThumb--active': isActive}"
               @click="onThumbClick"
               @mouseenter="displayPageNumber = true"
               @mouseleave="displayPageNumber = false"
        />
        <font-awesome-icon v-if="hasTag"
                           class="CerteuropeSignThumb-hasTag"
                           :style="{left: isActive ? '-14px' : '-11px'}"
                           :icon="['fas', 'bookmark']"
        ></font-awesome-icon>
        <div v-if="displayPageNumber"
             class="CerteuropeSignThumb-pageNumber"
             @click="onThumbClick"
             @mouseenter="displayPageNumber = true"
             @mouseleave="displayPageNumber = false"
        >
          {{ page.number }}
        </div>
      </div>

    </template>
    <template v-else>
      <div class="CerteuropeSignThumb-placeholder mx-auto d-flex align-center justify-center"
           :class="{'CerteuropeSignThumb-placeholder--active': isActive}"
      >
        <font-awesome-icon :icon="['far', 'ellipsis']"  class="text--primary"></font-awesome-icon>
      </div>
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapMutations, mapState } from 'vuex'

import { INTERSECT_DEBOUNCE_TIMER } from '@/common/constants'
import { SET_SCROLL_TO } from '@/store/modules/certeurope-sign/mutation_types'

import service from '../services/certeurope-sign.service'
export default {
  name: 'CerteuropeSignThumb',
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      thumb: null,
      displayPageNumber: false,
    }
  },
  computed: {
    ...mapState('certeuropeSign', ['activePage', 'signingView']),
    isActive () {
      return this.activePage === this.page.number
    },
    hasTag () {
      return this.page.tags.length > 0
    },
  },
  created () {
    this.onIntersect = debounce(this.onIntersect, INTERSECT_DEBOUNCE_TIMER)
  },
  mounted () {
    if (this.page.number <= 10) {
      this.getPageThumb()
    }
  },
  methods: {
    ...mapMutations('certeuropeSign', [SET_SCROLL_TO]),
    async getPageThumb () {
      const response = await service.getPageThumb(
        this.mnemo,
        this.signingView.id,
        this.page.id,
      )
      this.thumb = response.data.thumb
    },
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (!this.thumb) {
          this.getPageThumb()
        }
      }
    },
    onThumbClick () {
      this.SET_SCROLL_TO(this.page.number)
    },
  },
}
</script>

<style scoped lang="scss">
.CerteuropeSignThumb-container {
  position: relative;
  width: 100px;
  height: 142px;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    transform: scale(1.05);
  }
}
.CerteuropeSignThumb-placeholder {
  width: 100px;
  height: 142px;
  border: 1px solid #979797;
  cursor: pointer;
  &--active {
     border-color: var(--v-primary-base);
     border-width: 2px;
  }
}
.CerteuropeSignThumb {
  border: 1px solid #979797;
  cursor: pointer;
  &--active {
    border-color: var(--v-primary-base);
    border-width: 2px;
    transform: scale(1.05);
  }
}
.CerteuropeSignThumb-pageNumber {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  font-size: 60px;
  transform: translate(-50%, -50%);
  color: var(--v-grey-base);
}
.CerteuropeSignThumb-hasTag {
  position: absolute;
  font-size: 32px;
  top: 10px;
  color: #ffc700;
}
</style>
