<template>
  <div class="d-flex align-center grey--text text--darken-2">
    <img :src="`/img/icons/pdf.svg`"
         class="mr-2"
         alt="PDF icon"
         width="17"
    />
    {{ envelopeTemplateName }}
  </div>
</template>

<script>
export default {
  name: 'Name',
  props: {
    envelopeTemplateName: {
      type: String,
      required: true,
    },
  },
}
</script>
