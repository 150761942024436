import axios from 'axios'

export default {
  getTodosFolder: function (mnemo, queryObject = {}) {
    return axios.get(`/room/${mnemo}/todoFolder`, { params: queryObject })
  },
  getTodosFolderStructure: function (mnemo, queryObject = {}) {
    return axios.get(`/room/${mnemo}/todoFolder/structure`, { params: queryObject })
  },
}
