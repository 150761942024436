<template>
  <AppCard hover
           class="SignedDocumentsItemGrid h-100 d-flex flex-column pa-3"
           :class="{'SignedDocumentsItemGrid--selected': selected}"
           elevation="0"
  >
    <v-row no-gutters class="d-flex align-center" style="margin-top: -10px;">
      <v-col cols="auto">
        <v-checkbox v-if="hasOriginal"
                    v-model="selected"
                    class="my-0 mr-2 pt-0"
                    hide-details
                    :ripple="false"
        />
      </v-col>
      <v-col class="d-flex align-center" style="min-height: 36px">
        <AppSignatureStatus :signature="signedDocument"/>
      </v-col>
      <v-col v-if="hasOriginal">
        <div class="ml-auto d-flex justify-end">
          <ArchiveAddAccess :showText="false" :routeOptions="archiveAddRouteOptions" />
          <PolyOfficeIconButtonExport v-if="polyOfficeEnabled"
                                      :fileSize="combinedSignedDocumentSize"
                                      @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
          />
          <AppTooltip top>
            <template #activator="{ on, attrs }">
              <app-button class="SignedDocumentsItemGrid-headerIcon"
                          type="icon"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="$emit('shareOriginal', signedDocument)"
              >
                <font-awesome-icon :icon="['fal', 'share']"></font-awesome-icon>
              </app-button>
            </template>
            <span>{{ $t('common.share') }}</span>
          </AppTooltip>
          <AppTooltip top>
            <template #activator="{ on, attrs }">
              <app-button class="SignedDocumentsItemGrid-headerIcon"
                          type="icon"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="$emit('deleteOriginal', signedDocument)"
              >
                <font-awesome-icon :icon="['fal', 'trash-can']"></font-awesome-icon>
              </app-button>
            </template>
            <span>{{ $t('common.delete') }}</span>
          </AppTooltip>
          <AppTooltip v-if="imanageEnabledAndLogged" top>
            <template #activator="{attrs, on}">
              <app-button type="icon"
                          v-bind="attrs"
                          v-on="on"
                          @click="$emit('exportToImanage', signedDocument.id)"
              >
                <v-img src="/img/iManage_logo.png" width="25" />
              </app-button>
            </template>
            {{$t('project.signed-documents.SignedDocumentsItemGrid.exportToImanage')}}
          </AppTooltip>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0" no-gutters>
      <v-col>
        <div class="SignedDocumentsItemGrid-title text-h4">
          <AppTooltip top>
            <template #activator="{attrs, on}">
              <span v-bind="attrs"
                    v-on="on"
                    @click="selected = !selected"
              >
                {{ signedDocument.name }}
              </span>
            </template>
            <span>{{ signedDocument.name }}</span>
          </AppTooltip>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="date">
      <v-col class="ml-3 py-0">
        <div class="d-flex align-center">
          <font-awesome-icon :icon="['far', 'calendar-alt']" class="accent--text text--lighten-4 mr-2"></font-awesome-icon>
          <span v-html="date"/>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="SignedDocumentsItemGrid-signatoriesLink mx-3 py-2 d-flex align-center"
             @click.stop="$emit('openSignatoriesListModal', signedDocument)"
      >
        <span>
          {{ $tc('project.signed-documents.SignedDocumentsItemGrid.signatoriesHaveSigned', signatoriesWhoSigned, { signatoriesWhoSigned, signatories }) }}
        </span>
      </v-col>
    </v-row>
    <template>
      <template v-if="hasOriginal">
        <v-row>
          <v-col class="text-center">
            <app-button fullWidth
                        type="outlined"
                        variant="neutral"
                        @click.stop="openSingleFileDownloadDialog = true"
            >
              <font-awesome-icon :icon="['fal','file-download']"
                                 style="font-size: 16px;"
                                 class="mr-2"
              ></font-awesome-icon>
              {{$t('project.signed-documents.SignedDocumentsItemGrid.documentDownload')}}
            </app-button>
            <SignedDocumentsDownloadDialog v-if="openSingleFileDownloadDialog"
                                           :signedDocument="signedDocument"
                                           @close="openSingleFileDownloadDialog = false"/>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col>
            <Alert type="warning" :dismissible="false"
                   :icon="false"
            >
              {{$t('project.signed-documents.SignedDocumentsItemGrid.noAccessToOriginal')}}
            </Alert>
          </v-col>
        </v-row>
      </template>
    </template>
    <template>
      <v-row>
        <v-col class="d-flex align-center">
          <font-awesome-icon :icon="['fad', 'circle-check']"
                             style="font-size: 33px; --fa-primary-color: #7bb287; --fa-secondary-color: #7bb287"
                             class="mr-2"
          ></font-awesome-icon>
          {{ $t('project.signed-documents.SignedDocumentsItemGrid.youSignedTheDocument') }}
        </v-col>
      </v-row>
    </template>
  </AppCard>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapGetters } from 'vuex'

import Alert from '@/common/alerts/Alert'
import AppCard from '@/common/AppCard'
import AppSignatureStatus from '@/common/AppSignatureStatus'
import AppTooltip from '@/common/AppTooltip'
import ArchiveAddAccess from '@/common/archives/ArchiveAddAccess.vue'
import { SignedEnvExport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeIconButtonExport from '@/common/polyOffice/PolyOfficeIconButtonExport.vue'
import { ISOToShortDate } from '@/common/utils/dates'
import { GET_EXPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import SignedDocumentsDownloadDialog from './dialogs/SignedDocumentsDownloadDialog'

export default {
  name: 'SignedDocumentsItemGrid',
  components: {
    PolyOfficeIconButtonExport,
    SignedDocumentsDownloadDialog,
    Alert,
    AppSignatureStatus,
    AppCard,
    AppTooltip,
    ArchiveAddAccess,
  },
  props: {
    signedDocument: {
      type: Object,
      required: true,
    },
    selectedSignedDocuments: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      openSingleFileDownloadDialog: false,
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    selected: {
      get () {
        return this.selectedSignedDocuments.some(o => o.id === this.signedDocument.id)
      },
      set (value) {
        const newArray = cloneDeep(this.selectedSignedDocuments)
        if (value) {
          newArray.push(this.signedDocument)
          this.$emit('update:selectedSignedDocuments', newArray)
        } else {
          newArray.splice(newArray.findIndex(o => o.id === this.signedDocument.id), 1)
          this.$emit('update:selectedSignedDocuments', newArray)
        }
      },
    },
    date () {
      return this.$t('project.signed-documents.SignedDocumentsItemGrid.originalSignedOn', { date: ISOToShortDate(this.signedDocument.time) })
    },
    signatories () {
      let counter = 0
      if (this.signedDocument.mySignature) counter++
      if (this.signedDocument.otherSignatures.length > 0) {
        counter += this.signedDocument.otherSignatures.length
      }
      return counter
    },
    signatoriesWhoSigned () {
      let counter = 0
      if (this.signedDocument.mySignature) counter++
      if (this.signedDocument.otherSignatures.length > 0) {
        counter += this.signedDocument.otherSignatures.length
      }
      return counter
    },
    hasOriginal () {
      return (this.signedDocument.proofFileURL || this.signedDocument.signedDocumentURL)
    },
    combinedSignedDocumentSize () {
      return this.signedDocument.signSize + this.signedDocument.proofSize
    },
    archiveAddRouteOptions () {
      return {
        params: {
          signedDocuments: [this.signedDocument],
        },
        query: {
          signedDocumentIds: this.signedDocument.id,
          fromMnemo: this.roomMnemo,
        },
      }
    },
  },
  methods: {
    ...mapActions('polyOffice', [
      GET_EXPORT_IFRAME,
    ]),
    async polyOfficeOpenDialogHandler () {
      const payload = new SignedEnvExport(
        this.roomMnemo,
        [this.signedDocument.id],
      )
      try {
        await this.GET_EXPORT_IFRAME({
          payload,
          successMessage: this.$t('project.signed-documents.SignedDocumentsItemGrid.polyOffice.exportSuccessful'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.SignedDocumentsItemGrid {
  cursor: default;
  box-shadow: 0 0 23px 0 rgba(148, 148, 148, 0.2) !important;
  &:hover,
  &--selected {
    box-shadow: 0 0 23px 0 rgba(148, 148, 148, 0.2), 0 0 8px 0 rgba(0, 0, 0, 0.5) !important;
  }
}
.SignedDocumentsItemGrid-signatoriesLink {
  cursor: pointer;
  &:hover {
    background-color: #eee;
    color: var(--v-primary-base);
    text-decoration: underline;
  }
}
.SignedDocumentsItemGrid-title {
  overflow: hidden;
  position: relative;
  line-height: 20px;
  max-height: 60px;
}
.SignedDocumentsItemGrid-headerIcon {
  font-size: 16px;
}
</style>
