<template>
  <div class="d-flex h-100 align-center justify-center">
    <template v-if="loading">
      <AppLoader />
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import { getRoomRouteType } from '@/common/utils/rooms'
import { ROOM_DOCUMENTS_ROUTE_NAME } from '@/router'

import AppLoader from '../../common/AppLoader.vue'
import { GET_FILE } from '../../store/modules/files/action_types'
import { SET_WATERMARKED_DIALOG_DATA } from '../../store/modules/files/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'

export default {
  name: 'WatermarkView',
  components: { AppLoader },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    fileId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      loading: true,
      file: null,
    }
  },
  computed: {
    ...mapState('files', ['watermarkedDialogData']),
    ...mapState('room', ['currentRoom']),
  },
  async created () {
    try {
      this.file = await this.GET_FILE({
        mnemo: this.mnemo,
        id: this.fileId,
      })
      this.SET_WATERMARKED_DIALOG_DATA({ file: this.file, isOpen: true })
    } catch (e) {
      console.error(e)
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'error',
        message: this.$t('common.msgFailErrorOccurred'),
      })
    } finally {
      this.closeWatermarkView()
      this.loading = false
    }
  },
  methods: {
    ...mapActions('files', [GET_FILE]),
    ...mapMutations('files', [SET_WATERMARKED_DIALOG_DATA]),
    closeWatermarkView () {
      this.$router.replace({ name: ROOM_DOCUMENTS_ROUTE_NAME, params: { mnemo: this.mnemo, roomType: getRoomRouteType(this.currentRoom) } })
    },
  },
}
</script>
