import axios from 'axios'

export default {
  getDocumentAISummaries (mnemo: string, documentId: number) {
    return axios.get(`/room/${mnemo}/file/${documentId}/ai-summaries`)
  },
  patchAISummaryDownvote (mnemo: string, summaryId: number) {
    return axios.patch(`/room/${mnemo}/ai-summary/${summaryId}/downvote`)
  },
  patchAISummaryUpvote (mnemo: string, summaryId: number) {
    return axios.patch(`/room/${mnemo}/ai-summary/${summaryId}/upvote`)
  },
  requestDocumentAISummary (mnemo: string, documentId: number) {
    return axios.post(`/room/${mnemo}/file/${documentId}/ai-summary`)
  },
}
