<template>
  <div>
    <ProjectsNewDataroomDialog v-if="dialogIsOpen"
                               @requestActivation="openQuoteRequestDialog"
                               @close="dialogIsOpen = false"
    />

    <ProjectTypeCard
      color="deep-purple"
      is-project-category
      is-new
      :disabled="!canCurrentUserCreateDataroom"
      main-icon-src="/img/project/dataroom-project-category-filled.svg"
      @click="onDataroomCardClicked"
    >
      <span>{{$t('projects.project-types.ProjectTypeDataroom.label')}}</span>
    </ProjectTypeCard>

    <div class="mt-2 text-center">
      <AppButton text
                 class="text-decoration-underline discover-label"
                 @click="discoverDialogIsOpen = true"
      >
        {{ $t('projects.project-types.ProjectTypeDataroom.discoverLabel') }}
      </AppButton>
    </div>

    <DiscoverDataroomDialog v-if="discoverDialogIsOpen"
                            @close="discoverDialogIsOpen = false"
                            @createDataroom="createDataroom"
                            @requestActivation="openQuoteRequestDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { QUOTE_REQUEST_TOPICS } from '@/common/utils/quoteRequestTopics'

import AppButton from '../../common/buttons/AppButton'
import { SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG } from '../../store/modules/subscription/mutation_types'
import DiscoverDataroomDialog from '../dialogs/DiscoverDataroomDialog'
import ProjectsNewDataroomDialog from '../dialogs/ProjectsNewDataroomDialog.vue'
import ProjectTypeCard from '../ProjectTypeCard.vue'

export default {
  name: 'ProjectTypeDataroom',
  components: {
    AppButton,
    DiscoverDataroomDialog,
    ProjectTypeCard,
    ProjectsNewDataroomDialog,
  },
  data () {
    return {
      dialogIsOpen: false,
      discoverDialogIsOpen: false,
    }
  },
  computed: {
    ...mapGetters('user', ['canCurrentUserCreateDataroom']),
  },
  methods: {
    ...mapMutations('subscription', [SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG]),
    createDataroom () {
      this.discoverDialogIsOpen = false
      this.dialogIsOpen = true
    },
    onDataroomCardClicked () {
      if (this.canCurrentUserCreateDataroom) {
        this.dialogIsOpen = true
      } else {
        this.discoverDialogIsOpen = true
      }
    },
    openQuoteRequestDialog () {
      this.SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG({
        isOpen: true,
        defaultProjectType: QUOTE_REQUEST_TOPICS.DATAROOM,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .discover-label {
    color: #626262;
  }
</style>
