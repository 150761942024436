export const getters = {
  hasTodoFolderOrChild (state) {
    return !!state.breadcrumb.some(
      (element) => element.specialFolder === 'todo',
    )
  },
  isCurrentlyInTodoFolderRoot (state) {
    return state.breadcrumb.length !== 0 &&
      state.breadcrumb[state.breadcrumb.length - 1].specialFolder === 'todo'
  },
}
