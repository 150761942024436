<template>
  <div>
    <template v-if="menuPicker">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <AppTextField
            :value="formatDateValue(value)"
            :clearable="clearable"
            readonly
            :class="inputClass"
            v-bind="{...attrs, ...$attrs }"
            :placeholder="computedInputPlaceholder"
            v-on="on"
            @input="onInputClear"
          >
          <template v-for="(_, slot) in $slots" v-slot:[slot]>
            <slot :name="slot" />
          </template>
          </AppTextField>
        </template>
        <v-date-picker
          v-model="computedValue"
          :color="pickerColor"
          :min="computedMin"
          :locale="$vuetify.lang.current"
          v-bind="$attrs"
          v-on="$listeners"
          @change="menu = false"
        ></v-date-picker>
      </v-menu>
    </template>
    <template v-else>
      <v-date-picker
        v-model="computedValue"
        :color="pickerColor"
        :min="computedMin"
        v-bind="$attrs"
        v-on="$listeners"
      >
      </v-date-picker>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import AppTextField from './AppTextField.vue'
import { ISOToShortenedDate, formatDateValue } from './utils/dates'
/**
 * Default Date picker
 * @displayName AppDatePicker
 */
/**
   * A wrapper around the date picker component with default styling and custom default behavior
   * It is invoked on a menu through a text field by default and proxies props to it when configured with the menuPicker
   * All Vuetify props for the date picker component are usable.
   * @see See doc : https://vuetifyjs.com/en/api/v-date-picker/#props
   */
export default {
  name: 'AppDatePicker',
  components: {
    AppTextField,
  },
  inheritAttrs: false,
  props: {
    /**
     * @model
     * Value of Date picker
     * @values String or [String]
     */
    value: {
      required: true,
    },
    /**
     * If the picker is invoked on a menu through a text field. Defaults to true
     * @default true
     */
    menuPicker: {
      type: Boolean,
      default: true,
    },
    /**
     * A class given to the internal input of AppDatePicker component.
     * We can't pass a class through normal means, so we put a named prop for it
     * @default ''
     */
    inputClass: {
      default: '',
    },
    /**
     * Color variant of picker
     * @default primary
     */
    pickerColor: {
      type: String,
      default: 'primary',
    },
    /**
     * Display the clear icon in the date field
     * @default true
     */
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      menu: false,
    }
  },
  computed: {
    computedInputPlaceholder () {
      if (this.usesRangePicker && !this.$attrs.placeholder) {
        return ISOToShortenedDate(dayjs().subtract(1, 'months').format('YYYY-MM-DD')) + ' - ' + ISOToShortenedDate(dayjs().format('YYYY-MM-DD'))
      } else {
        return this.$attrs.placeholder
      }
    },
    usesRangePicker () {
      return this.$attrs.range === '' || this.$attrs.range
    },
    computedMin () {
      return this.$attrs.min || (this.usesRangePicker && this.value[0])
    },
    computedValue: {
      get () {
        return this.value
      },
      set (newValue) {
        /**
         * Input event.
         * @property {string, array} value
         */
        this.$emit('input', newValue)
      },
    },
  },
  watch: {
    menu (newValue) {
      this.$emit(newValue ? 'show' : 'hidden')
    },
  },
  methods: {
    formatDateValue,
    onInputClear () {
      if (this.usesRangePicker) {
        this.$emit('input', [])
      } else {
        this.$emit('input', '')
      }
    },
  },
}
</script>

<docs>
```vue
<template>
  <AppDatePicker v-model="date"
                 range
  />
</template>
<script>
  export default {
    data () {
      return {
        date: []
      }
    },
  }
</script>
```
</docs>
