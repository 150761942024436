<template>
  <div>
    <v-row no-gutters>
      <div class="text-subtitle-1">
        <b>{{$t('project.documents.pane.DocumentsPaneMove.moveTo')}}</b>
      </div>
    </v-row>
    <v-row no-gutters class="mt-4">
      <AppFildersTree v-model="selectedFolder"
                      :root-folder="fullTree"
                      selection-type="independent"
                      folders-only
                      openFirstNode
                      style="width: 100%;"
      />
    </v-row>
    <v-row>
      <v-col>
        <AppButton color="primary"
                   outlined
                   block
                   @click="close"
        >
          {{$t('common.cancel')}}
        </AppButton>
      </v-col>
      <v-col>
        <AppButton color="primary"
                   block
                   :disabled="isMoveDisabled"
                   :loading="moveLoading"
                   @click="move"
        >
          {{$t('project.documents.pane.DocumentsPaneMove.move')}}
        </AppButton>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import { CLOSE_TABLE_DOCUMENTS_PANE, LOAD_FOLDERS_TREE, MOVE_FILDERS } from '@/store/modules/documents/action_types'
import { SET_DOCUMENTS_TO_MOVE, SET_MOVEMENT_SNACKBAR_IS_OPEN } from '@/store/modules/documents/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import AppFildersTree from '../../../common/AppVuetifyFildersTree'
import AppButton from '../../../common/buttons/AppButton'

export default {
  name: 'DocumentsPaneMove',
  components: { AppFildersTree, AppButton },
  data () {
    return {
      selectedFolder: [],
    }
  },
  computed: {
    ...mapState('documents', ['documents', 'fullTree', 'moveLoading', 'documentsTablePane']),
    isMoveDisabled () {
      return this.documentsTablePane.documents.length === 0 || !this.isSelectedFolderValid
    },
    isSelectedFolderToDoChild () {
      const toDoFolder = this.fullTree?.children.find(doc => doc.specialFolder === 'todo')
      return toDoFolder?.children?.some(doc => doc.id === this.selectedFolder[0].id) || false
    },
    isSelectedFolderValid () {
      return this.selectedFolder.length !== 0 &&
          this.selectedFolder[0]?.specialFolder !== 'todo' &&
          (this.documentsTablePane.documents.every(doc => doc.type !== 'folder') || !this.isSelectedFolderToDoChild)
    },
  },
  methods: {
    ...mapActions('documents', [CLOSE_TABLE_DOCUMENTS_PANE, LOAD_FOLDERS_TREE, MOVE_FILDERS]),
    ...mapMutations('documents', [SET_DOCUMENTS_TO_MOVE, SET_MOVEMENT_SNACKBAR_IS_OPEN]),
    async move () {
      this.SET_MOVEMENT_SNACKBAR_IS_OPEN(false)
      this.SET_DOCUMENTS_TO_MOVE({
          documents: this.documentsTablePane.documents,
          originalFolderId: this.documents.id,
        })
      try {
        await this.MOVE_FILDERS({
          documents: this.documentsTablePane.documents,
          targetFolderId: this.selectedFolder[0].id,
        })
        this.SET_MOVEMENT_SNACKBAR_IS_OPEN(true)
        this.close()
        await this.LOAD_FOLDERS_TREE()
      } catch {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.pane.DocumentsPaneMove.errorMessage'),
        })
      }
    },
    close () {
      this.CLOSE_TABLE_DOCUMENTS_PANE()
      this.selectedFolder = []
    },
  },
}
</script>
