export const ADD_BIBLE = 'ADD_BIBLE'
export const REMOVE_BIBLE_PENDING = 'REMOVE_BIBLE_PENDING'
export const SET_BIBLES = 'SET_BIBLES'
export const SET_BIBLES_ERROR = 'SET_BIBLES_ERROR'
export const SET_BIBLES_PENDING = 'SET_BIBLES_PENDING'
export const SET_BIBLE_ERROR = 'SET_BIBLE_ERROR'
export const SET_BIBLE_PENDING = 'SET_BIBLE_PENDING'
export const SET_EXPORT_BIBLE_TO_IMANAGE_ERROR = 'SET_EXPORT_BIBLE_TO_IMANAGE_ERROR'
export const SET_EXPORT_BIBLE_TO_IMANAGE_PENDING = 'SET_EXPORT_BIBLE_TO_IMANAGE_PENDING'
export const SET_GET_RECENT_BIBLES_ERROR = 'SET_GET_RECENT_BIBLES_ERROR'
export const SET_GET_RECENT_BIBLES_PENDING = 'SET_GET_RECENT_BIBLES_PENDING'
export const SET_POST_BIBLE_PENDING = 'SET_POST_BIBLE_PENDING'
