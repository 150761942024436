<template>
  <div class="PrepareSignatureViewer">
    <template v-if="isDataReady">
      <slot name="header" :containerHeight="containerHeight"></slot>
      <v-row ref="prepareSignatureContainer"
             v-resize="containerHeight"
             class="flex-nowrap PrepareSignatureViewer__container"
             no-gutters
      >
        <v-col cols="3" class="PrepareSignatureViewer__sidebar">
          <slot name="sidebar"></slot>
        </v-col>
        <v-col class="PrepareSignatureViewer__pages">
          <slot name="pages"></slot>
        </v-col>
        <v-col cols="auto" class="overflow-y-auto">
          <div class="PrepareSignatureViewer__thumbs">
            <slot name="thumbs"></slot>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <div class="PrepareSignatureViewer__loader">
        <v-progress-circular indeterminate color="primary" :size="80" :width="5"></v-progress-circular>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PrepareSignatureViewer',
  props: {
    isDataReady: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    isDataReady: {
      handler (newVal) {
        if (newVal) {
          this.containerHeight()
        }
      },
    },
  },
  created () {
    window.scrollTo()
  },
  methods: {
    async containerHeight () {
      await this.$nextTick()
      const buttonsBar = document.querySelector('.stepper__actionsBar')
      const container = this.$refs.prepareSignatureContainer as HTMLElement

      if (container && buttonsBar) {
        const totalHeight = window.innerHeight
        const offset = container.getBoundingClientRect().y
        container.style.height = `${totalHeight - offset - buttonsBar.scrollHeight}px`
      }
    },
  },
})
</script>

<style scoped lang="scss">
.PrepareSignatureViewer {
  &__container {
    border-left: 1px solid #DEE2E6;
    border-right: 1px solid #DEE2E6;
    margin-top: 0 !important;
  }
  &__pages,
  &__sidebar,
  &__thumbs {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  &__sidebar,
  &__pages {
    overflow-y: auto;
  }
  &__sidebar {
    border-right: 1px solid #DEE2E6;
    max-width: 340px;
    min-width: 300px;
  }
  &__pages {
    background-color: var(--v-grey-lighten5);
    min-width: 0;
    padding: 24px 0 !important;
  }
  &__thumbs {
    height: 100%;
    min-width: 100px;
    padding: 12px;
  }
  &__loader {
    min-height: 400px;
    text-align: center;
  }
}
</style>
