 <template>
  <v-menu
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="default">
      <v-list v-if="menu.length !== 0">
        <template v-for="(item, index) in menu">
            <v-divider
              v-if="item.title === 'separator'"
              :key="`item${index}`"
            />
            <v-list-item
              v-else
              :key="`item${index}`"
              :disabled="item.loading || item.disabled"
              @click="doItemAction(item)"
            >
            <v-progress-circular v-if="item.loading" indeterminate color="primary" size="24" class="mr-2">
            </v-progress-circular>
            <v-list-item-title>
              <span v-html="item.title"/>
              <AppNewLabel v-if="item.hasNewLabel"
                           class="ml-4"
              />
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </slot>
    <template v-slot:activator="attrs">
      <!--
        @slot Activator slot
          @binding {object} attrs attrs to bind
          @binding {object} on events to bind
      -->
      <slot name="activator" v-bind="attrs"></slot>
    </template>
  </v-menu>
</template>

<script>
/**
 * Default Menu
 * @displayName AppMenu
 */
import AppNewLabel from '@/common/AppNewLabel.vue'

/**
 * A wrapper around the menu component with default styling and custom default behavior
 * All Vuetify props for the menu component are usable.
 * @see https://vuetifyjs.com/en/api/v-menu/#props
 */
export default {
  name: 'AppMenu',
  components: { AppNewLabel },
  props: {
    /**
     * JSON description of the menu content
     * @example
     * [
     *    {
     *      title: String,
     *      loading: Boolean,
     *      action: function,
     *      disabled: Boolean,
     *    }
     * ]
     */
    menu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    doItemAction (item) {
      item.action(item)
    },
  },
}
</script>

 <docs>
 ```vue
 <template>
   <AppMenu :menu="menu">
     <template #activator="{attrs, on}">
       <AppButton color="primary" v-bind="attrs" v-on="on">Menu button</AppButton>
     </template>
   </AppMenu>
 </template>
 <script>
   import AppButton from '@/common/buttons/AppButton'
   export default {
     components: { AppButton },
     data () {
       return {
         menu: [
           { title: 'item 1', },
           { title: 'item 2', },
           { title: 'item 3', },
         ]
       }
     },
   }
 </script>
 ```
 </docs>
