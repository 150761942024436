import { render, staticRenderFns } from "./DocumentsHeader.vue?vue&type=template&id=0793d2d7&scoped=true"
import script from "./DocumentsHeader.vue?vue&type=script&lang=js"
export * from "./DocumentsHeader.vue?vue&type=script&lang=js"
import style0 from "./DocumentsHeader.vue?vue&type=style&index=0&id=0793d2d7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0793d2d7",
  null
  
)

export default component.exports