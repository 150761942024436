<template>
  <ArchiveAddAccessBase :disableTooltip="disableTooltip" :routeOptions="routeOptions">
    <template #default="{ isArchivingEnabled }">
      <div v-bind="$attrs" v-on="$listeners">
        <!-- Wrapped in a button if alone -->
        <app-button v-if="showIcon && !showText" type="icon">
          <ArchiveAddAccessIcon
            :isArchivingEnabled="isArchivingEnabled"
            :solid="solidIcon"
          />
        </app-button>
        <ArchiveAddAccessIcon
          v-if="showIcon && showText"
          :isArchivingEnabled="isArchivingEnabled"
          :solid="solidIcon"
        />
        <span v-if="showText" :class="{ 'ml-2': showIcon }">
          {{ $t('common.archives.ArchiveAddAccess.text') }}
        </span>
      </div>
    </template>
  </ArchiveAddAccessBase>
</template>

<script>
import ArchiveAddAccessBase from './ArchiveAddAccessBase.vue'
import ArchiveAddAccessIcon from './ArchiveAddAccessIcon.vue'
export default {
  name: 'ArchiveAddAccess',
  components: { ArchiveAddAccessBase, ArchiveAddAccessIcon },
  inheritAttrs: false,
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    solidIcon: {
      type: Boolean,
      default: false,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    routeOptions: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
