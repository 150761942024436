<template>
  <div v-if="isDisplayed">
    <div
      :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? `${28 * item.depth}px` : 0 }"
      class="
        SigningChecklistTableTitle
        d-flex
        align-center
        text--primary
        position-relative
      "
      @click="setExpandSigningChecklist(!isExpand)"
      @mouseenter="onMouseEnter(`${item.type}-${item.id}`)"
    >
      <div>
        <font-awesome-icon
          :icon="['fas', 'chevron-right']"
          class="SigningChecklistTableTitle-chevron mr-2 body-1"
          :class="{ 'SigningChecklistTableTitle-chevron--open': isExpand }"
        ></font-awesome-icon>
      </div>
      <div v-if="isPm && $vuetify.breakpoint.mdAndUp">
        <v-checkbox
          :input-value="isEveryChildrenStepsSelected"
          hide-details
          readonly
          :ripple="false"
          class="mt-0 pt-0"
          @click.stop="onSelectedStepsClick"
        />
      </div>
      <div v-if="isPm && !isFakeTitle && $vuetify.breakpoint.mdAndUp" class="mr-2">
        <font-awesome-icon
          :icon="['fas', 'grip-vertical']"
          style="cursor: grab;color: var(--v-greyDark-lighten1)"
          :class="`SigningChecklistTable-dragIcon`"
          @click.stop
        ></font-awesome-icon>
      </div>
      <div>
        <div v-if="isFakeTitle" class="SigningChecklistTableTitle-text">
          {{ computedTitle }}
        </div>
        <template v-else>
          <div class="d-flex align-center">
            <span class="mr-2">{{ item.display }}.</span>
            <template v-if="isCurrentEdit">
              <AppTextField
                v-model.trim="editableTitle"
                autofocus
                :loading="isTitleUpdating"
                counter="100"
                maxlength="100"
                @blur="prepareUpdateTitle"
                @keypress.enter="prepareUpdateTitle"
                @click.stop
              />
            </template>
            <template v-else>
              <span
                class="SigningChecklistTableTitle-text"
                :class="{ 'title--inactive': isPm }"
                @click.stop="isPm && SET_CURRENT_TITLE_EDIT(item)"
              >
                {{ computedTitle }}
              </span>
            </template>
          </div>
        </template>
      </div>
      <div v-if="isPm && !isFakeTitle" class="py-2 px-1">
        <AppTooltip top>
          <template #activator="{ on, attrs }">
            <AppButton
              icon
              small
              :disabled="!canOutdent"
              :loading="currentIndentAction === 'decrement'"
              :x-padding="1"
              v-bind="attrs"
              v-on="on"
              @click.stop="outdent"
            >
              <font-awesome-icon :icon="['far', 'outdent']" />
            </AppButton>
          </template>
          <span>{{ $t("project.signing-checklist.list.SigningChecklistTableTitle.outdentTooltip") }}</span>
        </AppTooltip>
        <AppTooltip top>
          <template #activator="{ on, attrs }">
            <AppButton
              icon
              small
              :disabled="!canIndent"
              :loading="currentIndentAction === 'increment'"
              :x-padding="1"
              v-bind="attrs"
              v-on="on"
              @click.stop="indent"
            >
              <font-awesome-icon :icon="['far', 'indent']" />
            </AppButton>
          </template>
          <span>{{ $t("project.signing-checklist.list.SigningChecklistTableTitle.indentTooltip") }}</span>
        </AppTooltip>
        <AppTooltip top>
          <template #activator="{ on, attrs }">
            <AppButton
              icon
              small
              :disabled="isCurrentEdit"
              :input-value="isCurrentEdit"
              :x-padding="1"
              v-bind="attrs"
              v-on="on"
              @click.stop="SET_CURRENT_TITLE_EDIT(item)"
            >
              <font-awesome-icon :icon="['far', 'edit']" />
            </AppButton>
          </template>
          <span>{{ $t("project.signing-checklist.list.SigningChecklistTableTitle.editTooltip") }}</span>
        </AppTooltip>
        <AppTooltip top>
          <template #activator="{ on, attrs }">
            <AppButton
              icon
              small
              :disabled="isCurrentEdit"
              :x-padding="1"
              v-bind="attrs"
              v-on="on"
              @click.stop="SET_DELETE_TITLE_MODAL(item.id)"
            >
              <font-awesome-icon :icon="['far', 'trash-can']" />
            </AppButton>
          </template>
          <span>{{ $t("project.signing-checklist.list.SigningChecklistTableTitle.deleteTooltip") }}</span>
        </AppTooltip>
      </div>
      <div v-show="floatingAdd && isPm">
        <AppMenu :menu="floatingMenu">
          <template #activator="{ on, attrs }">
            <!-- We use a bare v-tooltip here because AppTooltip has trouble when combined with another activator -->
            <v-tooltip top transition="fade-transition">
              <template #activator="{on: onTooltip}">
                <font-awesome-icon
                  v-bind="{...attrs}"
                  :icon="['fas', 'map-marker-plus']"
                  class="fa-rotate-270 marker"
                  v-on="{...onTooltip, ...on}"
                ></font-awesome-icon>
              </template>
              <span>{{$t('project.signing-checklist.list.SigningChecklistTableTitle.floatingAddTooltip')}}</span>
            </v-tooltip>
          </template>
        </AppMenu>
      </div>
    </div>
    <CollapseTransition>
      <template v-if="item.children && isExpand">
        <Draggable
          v-model="signingChildrenModel"
          class="SigningChecklistTableTitle-draggableContainer"
          :forceFallback="true"
          :group="dragGroup"
          :emptyInsertThreshold="100"
          :disabled="!isPm || isSigningChecklistFiltered"
          :delay="500"
          :delayOnTouchOnly="true"
          :handle="`.SigningChecklistTable-dragIcon`"
          @change="preparePostMoveStep"
        >
          <template v-for="child in item.children">
            <component
              v-bind:is="
                child.type === 'title'
                  ? 'SigningChecklistTableTitle'
                  : 'SigningChecklistTableItem'
              "
              :id="`${child.type}-${child.id}`"
              :key="`${child.type}-${child.id}`"
              :item="child"
              :isMatchingFilters="childIsMatchingFilters(child)"
              :mnemo="mnemo"
              :expand-order="isExpand"
              :headerSelectedColumn.sync="headerSelectedColumnModel"
              :header-select-items="headerSelectItems"
              :parent="item"
              :floatingAdd="elementEntered === `${child.type}-${child.id}`"
              :elementEntered="child.type === 'title' ? elementEntered : null"
              :hasSigningOrder="hasSigningOrder"
              @mouseenter.native="child.type === 'step' && onMouseEnter(`${child.type}-${child.id}`)"
              @newElementEntered="onNewElementEntered"
              @save-as-template="$emit('save-as-template', $event)"
            ></component>
          </template>
        </Draggable>
      </template>
    </CollapseTransition>
  </div>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import Draggable from 'vuedraggable'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import { filterSigningChecklistTasks } from '@/common/utils/signingChecklist'

import SigningChecklistTableItem from './SigningChecklistTableItem'
import AppMenu from '../../../common/AppMenu.vue'
import AppTextField from '../../../common/AppTextField.vue'
import AppTooltip from '../../../common/AppTooltip'
import AppButton from '../../../common/buttons/AppButton.vue'
import { SIGNING_CHECKLIST_ADD_ROUTE_NAME } from '../../../router'
import { POST_MOVE_STEP, REFRESH_SIGNING_CHECKLIST, UPDATE_TITLE, CREATE_TITLE, TITLE_INDENTATION } from '../../../store/modules/signing-checklist/action_types'
import {
  SET_REMOVE_SELECTED_STEPS,
  SET_ADD_SELECTED_STEPS,
  SET_PARENT_CHILDREN,
  SET_CURRENT_TITLE_EDIT,
  SET_DELETE_TITLE_MODAL,
  SET_EXPAND_SIGNING_CHECKLIST,
} from '../../../store/modules/signing-checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

// If a title is less or equal than this, it'll be expanded by default
const AUTO_EXPAND_COUNT_LIMIT = 5

export default {
  name: 'SigningChecklistTableTitle',
  components: {
    SigningChecklistTableItem,
    Draggable,
    CollapseTransition,
    AppMenu,
    AppTextField,
    AppButton,
    AppTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
    parent: {
      type: Object,
      default: null,
    },
    headerSelectedColumn: {
      type: Object,
      required: true,
    },
    headerSelectItems: {
      type: Array,
      required: true,
    },
    expandOrder: {
      required: false,
    },
    floatingAdd: {
      type: Boolean,
      required: true,
    },
    elementEntered: {
      type: String,
    },
    hasSigningOrder: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      editIsActive: false,
      editableTitle: '',
      lastSavedTitle: null,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['filters', 'searchQuery', 'signingChecklist', 'selectedSteps', 'createTitlePending', 'updateTitlePendingIds', 'currentTitleEdit', 'titleIndentationPendingIds', 'isExpandSigningChecklist']),
    ...mapGetters('signingChecklist', ['flatSigningChecklist', 'isSigningChecklistFiltered']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
    isExpand () {
      const storedExpand = this.isExpandSigningChecklist.find(({ id }) => id === this.item.id)
      if (storedExpand) {
        return storedExpand.value
      } else {
        return this.item.children && this.item.children.length <= AUTO_EXPAND_COUNT_LIMIT
      }
    },
    headerSelectedColumnModel: {
      get () { return this.headerSelectedColumn },
      set (value) { this.$emit('update:headerSelectedColumn', value) },
    },
    signingChildrenModel: {
      get () {
        return this.item.children
      },
      set (value) {
        this.$store.commit(`signingChecklist/${SET_PARENT_CHILDREN}`, {
          parent: this.item,
          children: value,
        })
      },
    },
    isFakeTitle () {
      return this.item.id === 0 && this.item.type === 'title'
    },
    computedTitle () {
      if (this.isFakeTitle) {
        return this.$t('project.signing-checklist.list.SigningChecklistTableTitle.fakeTitle')
      }
      if (this.lastSavedTitle) {
        return this.lastSavedTitle
      }
      return this.item.title || ''
    },
    dragGroup () {
      if (this.isFakeTitle) {
        return {
          name: 'fakeTitle',
          pull: ['children'],
          put: false,
        }
      }
      return {
        name: 'children',
        pull: ['children', 'topLevel'],
        put: this.item.depth < 4
          ? ['children', 'topLevel', 'fakeTitle']
          : false,
      }
    },
    isEveryChildrenStepsSelected () {
      return this.displayedChildren.length !== 0 && this.displayedChildren.every(el => {
        return this.selectedSteps.some(item => item.id === el.id && item.type === el.type)
      })
    },
    flatChildren () {
      return this.flatten([], this.item.children).filter(item => item.type === 'step')
    },
    floatingMenu () {
      return [
        {
          title: this.$t('project.signing-checklist.list.SigningChecklistTableTitle.addStep'),
          action: () => {
            this.$router.push({
              name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
              params: {
                prevId: this.item.id,
                prevType: this.item.type,
              },
            })
          },
        },
        {
          title: this.$t('project.signing-checklist.list.SigningChecklistTableTitle.addTitle'),
          action: async () => {
            const payload = {
              prevId: this.item.id,
              prevType: this.item.type,
              depth: this.item.depth,
              title: '',
            }
            let parent = this.parent || this.signingChecklist

            // If the title is not expanded and has children
            // prevId should be the id of its last child, to align with the title's floating button's position
            if (!this.isExpand && this.item?.children.length > 0) {
              const lastChild = this.item.children[this.item.children.length - 1]
              payload.prevId = lastChild.id
              payload.prevType = lastChild.type
              parent = this.item
            }

            const newTitle = await this.CREATE_TITLE({
              mnemo: this.mnemo,
              data: payload,
              parent,
            })
            await this.REFRESH_SIGNING_CHECKLIST({
              mnemo: this.mnemo,
            })
            this.SET_CURRENT_TITLE_EDIT(newTitle)
          },
          loading: this.createTitlePending,
        },
      ]
    },
    isCurrentEdit () {
      if (this.currentTitleEdit) {
        return this.currentTitleEdit.id === this.item.id && this.currentTitleEdit.type === this.item.type
      }
      return false
    },
    isTitleUpdating () {
      return this.updateTitlePendingIds.includes(this.item.id)
    },
    currentIndentAction () {
      const currentIndentation = this.titleIndentationPendingIds.find(
        (obj) => obj.id === this.item.id,
      )
      return currentIndentation && currentIndentation.action
    },
    canOutdent () {
      return this.item.depth > 0
    },
    canIndent () {
      return this.item.depth <= 4 && this.item.display !== '1'
    },
    isDisplayed () {
      return !this.isSigningChecklistFiltered || (this.isSigningChecklistFiltered && this.matchingFilterChildren.length > 0)
    },
    matchingFilterChildren () {
      const onlyChildren = this.signingChildrenModel.filter((checklistElement) => checklistElement.type === 'step')
      return filterSigningChecklistTasks(onlyChildren, this.filters, this.searchQuery)
    },
    displayedChildren () {
      return this.isSigningChecklistFiltered ? this.matchingFilterChildren : this.flatChildren
    },
  },
  watch: {
    expandOrder (value) {
      if (value === true || value === false) {
        const expand = { id: this.item.id, value: value }
        this.SET_EXPAND_SIGNING_CHECKLIST(expand)
      }
    },
    isCurrentEdit (value) {
      if (value) {
        this.editableTitle = this.item.title
        this.$el.scrollIntoView({ block: 'center' })
      }
    },
    'item.title' (value) {
      if (value === this.lastSavedTitle) {
        this.lastSavedTitle = null
      }
    },
  },
  beforeDestroy () {
    if (this.currentTitleEdit) {
      this.SET_CURRENT_TITLE_EDIT(null)
    }
  },
  methods: {
    ...mapActions('signingChecklist', [POST_MOVE_STEP, REFRESH_SIGNING_CHECKLIST, CREATE_TITLE, UPDATE_TITLE, TITLE_INDENTATION]),
    ...mapMutations('signingChecklist', [SET_CURRENT_TITLE_EDIT, SET_DELETE_TITLE_MODAL, SET_EXPAND_SIGNING_CHECKLIST]),
    async preparePostMoveStep (e) {
      if (e.moved || e.added) {
        let itemToMove = null
        if (e.moved) {
          itemToMove = e.moved.element
        } else if (e.added) {
          itemToMove = e.added.element
        }
        const itemToMoveIndexInFlattenSigningChecklist = this.flatSigningChecklist.findIndex(el => el.id === itemToMove.id && el.type === itemToMove.type)
        const data = itemToMoveIndexInFlattenSigningChecklist === 0
          ? { newPosition: 0 }
          : {
            prevId: this.flatSigningChecklist[itemToMoveIndexInFlattenSigningChecklist - 1].id,
            prevType: this.flatSigningChecklist[itemToMoveIndexInFlattenSigningChecklist - 1].type,
          }
        const trailItem = this.getTrailItem(itemToMove)
        if (itemToMove.type === 'title') {
          data.newTrailId = trailItem.id
          data.newTrailType = trailItem.type
        }
        try {
          await this.POST_MOVE_STEP({
            mnemo: this.mnemo,
            stepId: itemToMove.id,
            stepType: itemToMove.type,
            data,
          })
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: this.$t('project.signing-checklist.list.SigningChecklistTableTitle.message.postMoveStepSuccess'),
          })
        } catch (e) {
          this.$store.commit('enqueueSnackbar', {
            color: 'error',
            message: this.$t('project.signing-checklist.list.SigningChecklistTableTitle.message.postMoveStepError'),
          })
        }
        this.REFRESH_SIGNING_CHECKLIST({
          mnemo: this.mnemo,
        })
      }
    },
    getTrailItem (item) {
      if (item.type === 'step') {
        return item
      } else {
        if (item.children.length === 0) {
          return item
        } else {
          return this.getTrailItem(item.children[item.children.length - 1])
        }
      }
    },
    flatten (into, node) {
      if (node == null) return into
      if (Array.isArray(node)) return node.reduce(this.flatten, into)
      into.push(node)
      return this.flatten(into, node.children)
    },
    onSelectedStepsClick () {
      if (this.isEveryChildrenStepsSelected) {
        this.displayedChildren.forEach(item => {
          this.$store.commit(`signingChecklist/${SET_REMOVE_SELECTED_STEPS}`, item)
        })
      } else {
        this.displayedChildren.forEach(item => {
          this.$store.commit(`signingChecklist/${SET_ADD_SELECTED_STEPS}`, item)
        })
      }
    },
    onMouseEnter (item) {
      this.$emit('newElementEntered', item)
    },
    // We emit it further up the chain if we got a child title emitting it
    onNewElementEntered (item) {
      this.$emit('newElementEntered', item)
    },
    async prepareUpdateTitle () {
      if (this.item.title === this.editableTitle) {
        this.SET_CURRENT_TITLE_EDIT(null)
      } else {
        try {
          this.lastSavedTitle = this.editableTitle
          await this.UPDATE_TITLE({
            parent: this.parent || this.signingChecklist,
            mnemo: this.mnemo,
            item: this.item,
            data: {
              title: this.editableTitle,
            },
          })
          this.SET_CURRENT_TITLE_EDIT(null)
        } catch {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.signing-checklist.list.SigningChecklistTableTitle.message.editTitleError'),
          })
        }
      }
    },
    indent () {
      this.prepareEditIndentation('increment')
    },
    outdent () {
      this.prepareEditIndentation('decrement')
    },
    async prepareEditIndentation (action) {
      try {
        await this.TITLE_INDENTATION({
          mnemo: this.mnemo,
          id: this.item.id,
          action,
        })
        this.REFRESH_SIGNING_CHECKLIST({
          mnemo: this.mnemo,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.signing-checklist.list.SigningChecklistTableTitle.message.editTitleSuccess'),
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.signing-checklist.list.SigningChecklistTableTitle.message.editTitleError'),
        })
      }
    },
    setExpandSigningChecklist (value) {
      const expand = { id: this.item.id, value: value }
      this.SET_EXPAND_SIGNING_CHECKLIST(expand)
    },
    childIsMatchingFilters (child) {
      return child.type === 'step' && this.matchingFilterChildren.some(step => step.id === child.id && step.fullName === child.fullName)
    },
  },
}
</script>

<style scoped lang="scss">
.SigningChecklistTableTitle {
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

.SigningChecklistTableTitle-text {
  user-select: none;
  font-size: 15px;
  font-weight: 600;
}
.SigningChecklistTableTitle-chevron {
  transition: transform 0.3s;
  &--open {
    transform: rotate(90deg);
  }
}
.SigningChecklistTableTitle-draggableContainer {
  min-height: 20px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: -12px;
  margin-right: -12px;
}
.sortable-ghost .SigningChecklistTableTitle,
.sortable-chosen .SigningChecklistTableTitle,
.sortable-drag .SigningChecklistTableTitle {
  background-color: var(--v-primary-lighten4) !important;
  border: 2px dashed var(--v-primary-base) !important;
}

.marker {
  position: absolute;
  bottom: -15px;
  left: -25px;
  cursor: pointer;
  opacity: 0.5;
  font-size: 26px;
  color: var(--v-primary-base);
  z-index: 1;
}

.marker:hover {
  opacity: 1;
}

.title--inactive {
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed transparent;
  padding: 4px 6px;
  min-height: 24px;
  min-width: 75px;
  &:hover {
    background-color: #eee;
    border-color: #bdbdbd;
  }
}
</style>
