import axios from 'axios'

export default {
  searchArchives: function (search, queryObject) {
    return axios.post(
      'archive/search',
      { ...search },
      { params: queryObject },
    )
  },
  getConfig: function () {
    return axios.get(
      'archive/config',
    )
  },
  getArchiveRights: function (archiveId) {
    return axios.get(
      `archive/${archiveId}/rights`,
    )
  },
  shareArchives: function (data) {
    return axios.post(
      'archive/rights',
      data,
    )
  },
  postArchive: function (file) {
    const fileData = new FormData()
    for (const key in file.objectToPost) {
      fileData.append(key, file.objectToPost[key])
    }
    return axios.post('archive', fileData, {
      onUploadProgress: function (progressEvent) {
        file.progress =
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
      },
    })
  },
  sendArchives: function (params) {
    return axios.post(
      'archive/share',
      params,
    )
  },
  getArchivesDownload: function (data) {
    return axios.get('downloads/archives', data, { responseType: 'blob' })
  },
}
