<template>
  <AppDialog :is-open="isDialogOpened"
             :cancel-disabled="postGroupLoading"
             :ok-loading="postGroupLoading"
             @cancel="closeDialog"
             @ok="preparePostGroup"
  >
    <template #title>{{ $t('project.members.dialogs.MembersAddGroupDialog.addGroup') }}</template>

    <template #body>
      <form @submit.prevent="preparePostGroup">
        <v-text-field v-model="groupName"
                      :label="$t('project.members.dialogs.MembersAddGroupDialog.lblGroupName')"
                      required
                      dense
                      color="blue darken-2"
                      counter="100"
                      :error-messages="groupNameErrors"
                      outlined
                      autofocus
        ></v-text-field>
      </form>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'

export default {
  name: 'MembersAddGroupDialog',
  components: { AppDialog },
  mixins: [dialogMixin, validationMixin],
  validations: {
    groupName: {
      required,
      maxLength: maxLength(100),
    },
  },
  data () {
    return {
      groupName: '',
    }
  },
  computed: {
    ...mapState('groups', ['postGroupLoading']),
    groupNameErrors () {
      const errors = []
      if (!this.$v.groupName.$dirty) return errors
      !this.$v.groupName.maxLength && errors.push(this.$t('project.members.dialogs.MembersAddGroupDialog.maxLength'))
      !this.$v.groupName.required && errors.push(this.$t('project.members.dialogs.MembersAddGroupDialog.required'))
      return errors
    },
  },
  methods: {
    ...mapActions('groups', ['POST_GROUP']),
    async preparePostGroup () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.POST_GROUP({
            mnemo: this.$route.params.mnemo,
            groupName: this.groupName,
          })
        } catch (e) {
          console.error(e)
          this.$store.commit('enqueueSnackbar', {
            color: 'error',
            message: this.$t('common.msgFailErrorOccurred'),
          })
          // Unhandled and shouldn't happen... Throw it to either handle it later or let Sentry send it back
          throw e
        }
        this.closeDialog()
      }
    },
  },
}
</script>
