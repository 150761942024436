<template>
  <Pane v-model="documentsPane.isOpen"
        :current-tab="documentsPane.tab"
        eager
        :tabs="tabs"
        temporary
  >
    <template v-if="documentsPane.isOpen" #content-share-email>
      <DocumentsShareByEmailPane />
    </template>
  </Pane>
</template>

<script>
import { mapState } from 'vuex'

import DocumentsShareByEmailPane from './DocumentsShareByEmailPane'
import Pane from '../../../common/Pane'

export default {
  name: 'DocumentsPane',
  components: { DocumentsShareByEmailPane, Pane },
  data () {
    return {
      tabs: [
        {
          title: this.$t('project.documents.pane.DocumentsPane.shareByEmail'),
          id: 'share-email',
        },
      ],
    }
  },
  computed: {
    ...mapState('documents', ['documentsPane']),
  },
}
</script>
