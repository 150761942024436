import {
  REMOVE_SIGNED_DOCUMENT_PENDING,
  SET_EXPORT_SIGNED_DOCUMENT_TO_IMANAGE_ERROR,
  SET_EXPORT_SIGNED_DOCUMENT_TO_IMANAGE_PENDING,
  SET_SIGNED_DOCUMENT_ERROR,
  SET_SIGNED_DOCUMENT_PENDING,
} from '@/store/modules/signed-documents/mutation_types'

export const mutations = {
  SET_SIGNED_DOCUMENTS (state, mySignatures) {
    state.signedDocuments = mySignatures
  },
  SET_LOADING: function (state, value) {
    state.loading = value
  },
  SET_SEARCH_QUERY (state, searchQuery) {
    state.searchQuery = searchQuery
  },
  SET_SIGN_DATE_MODEL (state, signDateModel) {
    state.signDateModel = signDateModel
  },
  SET_FILTERS_START_DATE (state, startDate) {
    state.filters.startDate = startDate
  },
  SET_FILTERS_END_DATE (state, endDate) {
    state.filters.endDate = endDate
  },
  SET_FILTERS_SIGNATORIES (state, signatories) {
    state.filters.signatories = signatories
  },
  [SET_EXPORT_SIGNED_DOCUMENT_TO_IMANAGE_PENDING] (state, value) {
    state.exportSignedDocumentToImanagePending = value
  },
  [SET_EXPORT_SIGNED_DOCUMENT_TO_IMANAGE_ERROR] (state, value) {
    state.exportSignedDocumentToImanageError = value
  },
  [SET_SIGNED_DOCUMENT_PENDING] (state, value) {
    state.signedDocumentPendingIds.push(value)
  },
  [REMOVE_SIGNED_DOCUMENT_PENDING] (state, index) {
    state.signedDocumentPendingIds.splice(index, 1)
  },
  [SET_SIGNED_DOCUMENT_ERROR] (state, value) {
    state.signedDocumentError = value
  },
}
