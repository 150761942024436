<template>
<div class="SubscriptionMenu">
  <div v-if="$vuetify.breakpoint.mdAndUp"
       class="text-h1 text-center my-7"
  >
    <template v-if="subscription && subscription.company">
      {{ $t('subscriptions.SubscriptionMenu.subscription') }} - {{ subscription.company }}
    </template>
    <template v-else>
      <v-skeleton-loader type="text"/>
    </template>
  </div>
  <div v-if="subscription" class="SubscriptionMenu-menu">
    <div v-for="item in subscriptionMenu"
         :key="item.value"
         class="SubscriptionMenu-menuItem"
         :class="{'SubscriptionMenu-menuItem--active': $route.name === item.to}"
         @click="goTo(item.to)"
    >
      {{ item.text }}
      <AppNewLabel v-if="item.isNew" class="ml-2" />
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

import AppNewLabel from '@/common/AppNewLabel.vue'
import {
  SUBSCRIPTION_BILLING_ROUTE_NAME,
  SUBSCRIPTION_SUBSCRIBERS_ROUTE_NAME,
  SUBSCRIPTION_TEAMS_ROUTE_NAME,
  SUBSCRIPTION_PROJECTS_ROUTE_NAME,
  SUBSCRIPTION_CLOSED_PROJECTS_ROUTE_NAME,
} from '@/router'

export default {
  name: 'SubscriptionMenu',
  components: { AppNewLabel },
  data () {
    return {
      menu: [
        {
          text: this.$t('subscriptions.SubscriptionMenu.billing'),
          value: 'billing',
          to: SUBSCRIPTION_BILLING_ROUTE_NAME,
        },
        {
          text: this.$t('subscriptions.SubscriptionMenu.subscribers'),
          value: 'subscribers',
          to: SUBSCRIPTION_SUBSCRIBERS_ROUTE_NAME,
        },
        {
          text: this.$t('subscriptions.SubscriptionMenu.teams'),
          value: 'teams',
          to: SUBSCRIPTION_TEAMS_ROUTE_NAME,
          isNew: true,
        },
        {
          text: this.$t('subscriptions.SubscriptionMenu.projects'),
          value: 'projects',
          to: SUBSCRIPTION_PROJECTS_ROUTE_NAME,
        },
        {
          text: this.$t('subscriptions.SubscriptionMenu.closedProjects'),
          value: 'closedProjects',
          to: SUBSCRIPTION_CLOSED_PROJECTS_ROUTE_NAME,
        },
      ],
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapState('subscription', ['subscription', 'subscriptionPending']),
    subscriptionMenu () {
      return this.profile.isSubAdmin ? this.menu : this.menu.filter(item => item.value !== 'teams')
    },
  },
  methods: {
    goTo (route) {
      if (this.$route.name !== route) {
        this.$router.push({ name: route })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.SubscriptionMenu-menu {
  margin-left: -12px;
  margin-right: -12px;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    margin-left: -8px;
    margin-right: -8px;
  }
}
.SubscriptionMenu-menuItem {
  padding: 13px 0 13px 30px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  &:hover, &:focus {
    background-color: #eee;
  }
  &:first-child {
    border-top: 1px solid #eee;
  }
  &--active {
    border-left: 3px solid var(--v-primary-base);
    background-color: var(--v-primary-lighten4);
  }
}
</style>
