<template>
  <div ref="pagesContainer"
       v-resize="setContainerWidth"
       class="WatermarkedDocumentsViewer"
       :class="{'WatermarkedDocumentsViewer--loading': init}"
  >
    <template v-if="init">
      <AppLoader/>
    </template>
    <template v-else>
      <WatermarkedDocumentsPage v-for="index in totalPages"
                                :key="`watermarkedPage-${index}`"
                                :page="index"
                                :mnemo="mnemo"
                                :pages-container-width="pagesContainerWidth"
                                :max-container-width="MAX_CONTAINER_WIDTH"
                                @getWatermarkedPages="prepareGetWatermarkedFile"
      />
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapActions, mapState } from 'vuex'

import WatermarkedDocumentsPage from './WatermarkedDocumentsPage'
import AppLoader from '../../../common/AppLoader'
import { GET_WATERMARKED_FILE } from '../../../store/modules/files/action_types'
import { RESET_WATERMARKED_FILE } from '../../../store/modules/files/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'
const PAGES_REQUEST_DEBOUNCE_TIMER = 1000
const CONTAINER_RESIZE_DEBOUNCE_TIMER = 500
const MAX_CONTAINER_WIDTH = 1000
export default {
  name: 'WatermarkedDocumentsViewer',
  components: { WatermarkedDocumentsPage, AppLoader },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    fileId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      MAX_CONTAINER_WIDTH,
      requestedPages: {
        startPage: 1,
        endPage: 3,
      },
      init: true,
      pagesContainerWidth: MAX_CONTAINER_WIDTH,
    }
  },
  computed: {
    ...mapState('files', ['watermarkedFile']),
    totalPages () {
      if (this.watermarkedFile) {
        return this.watermarkedFile.totalNbPages
      }
      return 0
    },
  },
  created () {
    this.prepareGetWatermarkedFile({
      pagesToRequest: this.requestedPages,
      fileId: this.fileId,
    })
    this.prepareGetWatermarkedFile = debounce(this.prepareGetWatermarkedFile, PAGES_REQUEST_DEBOUNCE_TIMER)
    this.setContainerWidth = debounce(this.setContainerWidth, CONTAINER_RESIZE_DEBOUNCE_TIMER)
  },
  beforeDestroy () {
    this.$store.commit(`files/${RESET_WATERMARKED_FILE}`)
  },
  methods: {
    ...mapActions('files', [GET_WATERMARKED_FILE]),
    async prepareGetWatermarkedFile ({ pagesToRequest, fileId }) {
      try {
        await this.GET_WATERMARKED_FILE({
          mnemo: this.mnemo,
          fileId,
          data: { ...pagesToRequest },
        })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.watermarked-viewer.WatermarkedDocumentsViewer.getWatermarkedFileError'),
        })
      } finally {
        this.init = false
      }
    },
    setContainerWidth () {
      const containerStyles = window.getComputedStyle(this.$refs.pagesContainer)
      const paddingToSubstract = parseFloat(containerStyles.paddingLeft) + parseFloat(containerStyles.paddingRight)
      this.pagesContainerWidth = this.$refs.pagesContainer.clientWidth - paddingToSubstract
    },
  },
}
</script>

<style scoped lang="scss">
.WatermarkedDocumentsViewer {
  &--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
