<template>
  <span>{{ providerName }}</span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { SIGNATURE_PROVIDER } from '@/project/signing-checklist/constants'

export default defineComponent({
  name: 'AppProviderText',
  props: {
    provider: {
      type: String as PropType<SIGNATURE_PROVIDER>,
      required: true,
    },
  },
  computed: {
    providerName (): string {
      return this.provider === SIGNATURE_PROVIDER.OODRIVE ? 'CertEurope' : 'DocuSign'
    },
  },
})
</script>
