<template>
  <div>
    <div class="d-flex align-center column-gap-2">
      <span>
        <app-text as="span" variant="small-bold">{{ $t('project.signing-checklist.SigningChecklistSummary.totalDocuments') }}</app-text>
        <v-chip small class="ml-2">
          {{ totalDocuments }}
        </v-chip>
      </span>
      <template v-if="allowEnvelopeGrouping && totalEnvelopes">
        <v-divider vertical class="mx-2" />
        <span>
          <app-text as="span" variant="small-bold">{{ $tc('project.signing-checklist.SigningChecklistSummary.totalEnvelopes', totalEnvelopes) }}</app-text>
          <v-chip small class="ml-2">
            {{ totalEnvelopes }}
          </v-chip>
        </span>
      </template>
    </div>
    <app-text v-if="isSendingDocuments" variant="small-regular" class="mt-2">{{ $tc('project.signing-checklist.SigningChecklistSummary.isSendingDocumentsIntro', totalDocuments) }}</app-text>
    <app-alert-in-page v-if="totalDocumentsInError" :dismissible="false" variant="warning" class="my-4">
      <template>
        <span v-html="alertContent"></span>
      </template>
    </app-alert-in-page>
    <div class="signing-checklist-summary-lists">
      <template v-if="data && !isLoading">
        <template v-if="!isOrdered">
          <SigningChecklistSummaryList :groups="computedGroups[0]"/>
        </template>
        <template v-else>
          <v-timeline align-top dense line-inset="80" truncate-line="start">
            <v-timeline-item
              v-for="groups, index in computedGroups"
              :key="'groups_' + index"
              color="#D3E3FD"
              fill-dot
              small
            >
              <SigningChecklistSummaryList :envelope-starting-index="getEnvelopeStartingIndex(index)"
                                           :groups="groups" />
              <template v-slot:icon>
                <app-text variant="button-small" class="timeline-step ma-auto">{{ index + 1 }}</app-text>
              </template>
            </v-timeline-item>
          </v-timeline>
        </template>
      </template>
      <template v-else>
        <v-skeleton-loader type="paragraph"/>
      </template>
    </div>
  </div>
</template>

<script>
import { DARK_BLUE_500 } from '@/common/utils/colors'
import SigningChecklistSummaryList from '@/project/signing-checklist/SigningChecklistSummaryList'

export default {
  name: 'SigningChecklistSummary',
  components: { SigningChecklistSummaryList },
  props: {
    allowEnvelopeGrouping: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [Object, null],
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isSendingDocuments: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      DARK_BLUE_500,
    }
  },
  computed: {
    alertContent () {
      return this.$tc('project.signing-checklist.SigningChecklistSummary.' + (this.isSendingDocuments ? 'errorSendDocuments' : 'errorStartSigning'), this.totalDocumentsInError)
    },
    computedGroups () {
      return Object.values(this.data?.groups)
    },
    isOrdered () {
      return this.computedGroups.length > 1 || false
    },
    totalDocuments () {
      return this.data?.documentsCount || null
    },
    totalDocumentsInError () {
      let tot = 0
      this.data?.groups.forEach(groups => {
        if (groups.waitingCorrection) { tot += groups.waitingCorrection.length }
      })
      return tot
    },
    totalEnvelopes () {
      return this.data?.envelopesCount || null
    },
  },
  methods: {
    getEnvelopeStartingIndex (activeIndex) {
      let envelopeStartingIndex = 0

      for (let index = 0; index < activeIndex; index++) {
        if (this.computedGroups[index].ready?.length) envelopeStartingIndex++
        if (this.computedGroups[index].waitingValidation?.length) envelopeStartingIndex++
      }

      return envelopeStartingIndex
    },
  },
}
</script>

<style scoped lang="scss">
.signing-checklist-summary-lists {
  max-height: 35vh;
  margin-top: 24px;
  overflow-y: auto;
}
.v-timeline {
  padding-top: 0;
}
.timeline-step {
  color: v-bind(DARK_BLUE_500);
}
</style>
