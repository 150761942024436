<template>
  <div>
    <div class="body-1 font-weight-bold pb-5 ml-1">
      {{$t('project.project-settings.signatures.ProjectSettingsSignatures.signatures')}} <span v-if="signatures">({{signatures.pagination.itemCount}})</span>
      <AppTooltip v-if="canGlobalChase" top>
        <template #activator="{attrs, on}">
          <AppButton color="primary"
                     small
                     :disabled="globalChaseDisabled"
                     class="ml-2"
                     :loading="globalChaseLoading"
                     v-bind="attrs"
                     style="z-index: 1"
                     v-on="on"
                     @click="prepareGlobalChase"
          >
            {{ $t('project.project-settings.signatures.ProjectSettingsSignatures.globalChase') }}
          </AppButton>
        </template>
        <span>{{ globalChaseTooltip }}</span>
      </AppTooltip>
    </div>
    <AppCard class="ma-1">
      <template v-if="signaturesPending">
        <v-container fluid class="white px-8 py-4">
          <v-skeleton-loader type="text@3"></v-skeleton-loader>
        </v-container>
      </template>
      <template v-else>
          <v-data-table v-if="signatures"
                        :headers="tableHeaders"
                        :items="signatures.data"
                        :custom-sort="customSort"
                        class="ProjectSettingsSignatures-table"
                        :footer-props="{
                          'items-per-page-options': [50, 100, 250],
                        }"
                        :items-per-page="tableItemsPerPage"
                        :options.sync="options"
                        :hide-default-footer="disabledPagination"
                        @click:row="onExpandClick($event)"
          >
            <template #item.expand="{item}">
              <div class="d-flex align-center p-0" style="max-width: 20px;">
                <AppButton v-if="showExpand(item)"
                           :disabled="!canExpand(item)"
                           icon
                           color="primary"
                >
                  <app-icon icon-name="chevron-right" icon-weight="far" class="ProjectSettingsSignatures-expandIcon" :class="{'ProjectSettingsSignatures-expandIcon--open': expanded.length > 0 && expanded[0].id === item.id}"/>
                </AppButton>
              </div>
            </template>
            <template #item.documents="{item}">
              <div class="d-flex align-center">
                {{ item.name }}
              </div>
            </template>
            <template #item.validators="{item}">
              <ProjectSettingsSignaturesValidators :item="item" :expanded="expanded.length > 0 && expanded[0].id === item.id" :current-date="currentDate"/>
            </template>
            <template #item.signatories="{item}">
              <ProjectSettingsSignaturesSignatoriesList :item="item" :expanded="expanded.length > 0 && expanded[0].id === item.id" />
            </template>
            <template #item.status="{item}">
              <v-chip :color="envelopeStatusColor(item)"
                      label
              >
                {{ $t(`common.envelopeStatus.${item.status}`) }}
              </v-chip>
            </template>
            <template #item.date="{item}">
              <div v-if="item.timestamp">
                {{ getDate(item.timestamp) }}
              </div>
            </template>
            <template #item.options="{item}">
              <div class="d-flex align-center justify-end">
                <ProjectSettingsSignaturesEnvelopeChaseButton :item="item" :current-date="currentDate"/>
                <AppTooltip top>
                  <template #activator="{attrs, on}">
                    <div v-bind="attrs"
                         v-on="on"
                    >
                      <AppDownloadFile :link="envelopeResourceLink(item.id)" forceReader>
                        <template #default="{submitForm}">
                          <AppButton icon
                                     class="text--primary"
                                     @click="submitForm"
                          >
                            <app-icon icon-name="eye" icon-weight="fas" class="accent--text text--lighten-2"/>
                          </AppButton>
                        </template>
                      </AppDownloadFile>
                    </div>
                  </template>
                  <span>{{ $t('project.project-settings.signatures.ProjectSettingsSignatures.previewTooltip') }}</span>
                </AppTooltip>
              </div>
            </template>
          </v-data-table>
      </template>
    </AppCard>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppCard from '@/common/AppCard.vue'
import AppDownloadFile from '@/common/AppDownloadFile.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import { ISOToShortDate } from '@/common/utils/dates'
import { numericSort } from '@/common/utils/sorting'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import ProjectSettingsSignaturesEnvelopeChaseButton
  from '@/project/project-settings/signatures/ProjectSettingsSignaturesEnvelopeChaseButton.vue'
import ProjectSettingsSignaturesSignatoriesList from '@/project/project-settings/signatures/ProjectSettingsSignaturesSignatoriesList.vue'
import ProjectSettingsSignaturesValidators
  from '@/project/project-settings/signatures/ProjectSettingsSignaturesValidators.vue'
import { CHASE_USERS, CHASE_VALIDATOR, GET_SIGNATURES } from '@/store/modules/room/action_types'
import { ADD_CHASE_TIMER_GLOBAL } from '@/store/modules/room/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'ProjectSettingsSignatures',
  components: {
    ProjectSettingsSignaturesEnvelopeChaseButton,
    ProjectSettingsSignaturesValidators,
    AppButton,
    AppCard,
    AppDownloadFile,
    AppTooltip,
    ProjectSettingsSignaturesSignatoriesList,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      expanded: [],
      tableHeaders: [
        {
          text: '',
          value: 'expand',
          width: '10px',
          sortable: false,
        },
        {
          text: this.$t('project.project-settings.signatures.ProjectSettingsSignatures.headers.documents'),
          value: 'documents',
        },
        {
          text: this.$t('project.project-settings.signatures.ProjectSettingsSignatures.headers.validators'),
          value: 'validators',
        },
        {
          text: this.$t('project.project-settings.signatures.ProjectSettingsSignatures.headers.signatories'),
          value: 'signatories',
        },
        {
          text: this.$t('project.project-settings.signatures.ProjectSettingsSignatures.headers.status'),
          value: 'status',
        },
        {
          text: this.$t('project.project-settings.signatures.ProjectSettingsSignatures.headers.date'),
          value: 'date',
        },
        {
          text: this.$t('project.project-settings.signatures.ProjectSettingsSignatures.headers.options'),
          class: 'text-end',
          sortable: false,
          value: 'options',
        },
      ],
      currentDate: dayjs(),
      currentDateIntervalId: null,
      globalChaseLoading: false,
      envelopeChaseLoading: null,
      options: {},
    }
  },
  computed: {
    ...mapState('room', ['signatures', 'signaturesPending', 'chaseTimers', 'currentRoom']),
    ...mapGetters('user', ['currentUserId']),
    globalChaseDisabled () {
      const globalChaseTimer = this.chaseTimers.global?.[this.currentRoom.id]
      return !this.signatures ||
        this.signatures.data.length === 0 ||
        (globalChaseTimer && this.currentDate.isBefore(globalChaseTimer))
    },
    globalChaseTooltip () {
      const hasValidatorsToChase = this.signatures?.data?.some(s => !s.validated && (s.status === 'unvalidated' || s === 'sent'))
      const hasSignatoriesToChase = this.signatures?.data?.some(s => s.signatories.some(signatory => signatory.status === 'sent'))
      if (hasValidatorsToChase && hasSignatoriesToChase) {
        return this.$t('project.project-settings.signatures.ProjectSettingsSignatures.globalChaseValidatorsAndSignatories')
      } else if (hasValidatorsToChase) {
        return this.$t('project.project-settings.signatures.ProjectSettingsSignatures.globalChaseValidators')
      } else if (hasSignatoriesToChase) {
        return this.$t('project.project-settings.signatures.ProjectSettingsSignatures.globalChaseSignatories')
      }
      return null
    },
    disabledPagination () {
      return !(this.signatures) || this.signatures.data.length < 50
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, 'project-settings-signatures-items-per-page')
      return itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : 50
    },
  },
  watch: {
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, 'project-settings-signatures-items-per-page'))) {
          setUserSetting(this.currentUserId, 'project-settings-signatures-items-per-page', newValue.itemsPerPage)
        }
      },
    },
  },
  created () {
    this.prepareGetSignatures()
    this.currentDateInterval()
    const userSettings = localStorage.getItem('user-settings')
    if (userSettings) {
      const newUserSettings = JSON.parse(userSettings)
      if (newUserSettings[this.currentUserId]) {
        delete newUserSettings[this.currentUserId]['dashboard-signatures-items-per-page']
        localStorage.setItem('user-settings', JSON.stringify(newUserSettings))
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.currentDateIntervalId)
  },
  methods: {
    ...mapActions('room', [GET_SIGNATURES, CHASE_USERS, CHASE_VALIDATOR]),
    async prepareGetSignatures () {
      try {
        await this.GET_SIGNATURES(this.mnemo)
        if (this.signatures.data.length > 0) {
          this.expanded.push(this.signatures.data[0])
        }
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.project-settings.signatures.ProjectSettingsSignatures.getSignaturesError'),
        })
      }
    },
    envelopeStatusColor (item) {
      switch (item.status) {
        case 'draft':
        case 'temp':
          return '#e0e0e0'
        case 'sent':
          return 'warning'
        case 'signed':
          return 'success'
        case 'voided':
          return 'error'
        case 'unvalidated':
          return 'warning'
      }
      return null
    },
    getDate (date) {
      return ISOToShortDate(date)
    },
    envelopeCanBeChase (envelope) {
      return envelope.signatories.some(signatory => signatory.status === 'sent') ||
        envelope.status === 'unvalidated'
    },
    canExpand (envelope) {
      return envelope.signatories.length > 0 || envelope.validations.length > 0
    },
    showExpand (envelope) {
      return envelope.signatories.length > 1 || envelope.validations.length > 1
    },
    canGlobalChase () {
      return this.signatures?.data.some(envelope => {
        return this.envelopeCanBeChase(envelope)
      })
    },
    onExpandClick (envelope) {
      if (!this.canExpand(envelope)) return

      if (this.expanded.length === 0) {
        this.expanded.push(envelope)
      } else if (this.expanded[0].id === envelope.id) {
        this.expanded.splice(0, 1)
      } else {
        this.expanded.splice(0, 1)
        this.expanded.push(envelope)
      }
    },
    async prepareGlobalChase () {
      // TODO : Handle pendingIDS with vuex-action
      this.globalChaseLoading = true
      try {
        for (const envelope of this.signatures.data) {
          if (!envelope.validated && (envelope.status === 'unvalidated' || envelope.status === 'sent')) {
            await this.CHASE_VALIDATOR({
              mnemo: this.mnemo,
              envelopeId: envelope.id,
              data: [],
            })
          }
          if (envelope.status === 'sent') {
            await this.CHASE_USERS({
              mnemo: this.mnemo,
              envelopeId: envelope.id,
            })
          }
        }
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('common.chaseSuccess'),
        })
        this.$store.commit(`room/${ADD_CHASE_TIMER_GLOBAL}`, this.currentRoom.id)
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('common.chaseError'),
        })
      } finally {
        this.globalChaseLoading = false
      }
    },
    envelopeResourceLink (envelopeId) {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/envelope/${envelopeId}`
    },
    currentDateInterval () {
      this.currentDateIntervalId = setInterval(() => { this.currentDate = dayjs() }, 1000 * 60)
    },
    customSort (items, sortBy, sortDesc) {
      if (sortBy[0] === 'documents') {
        return items.sort((a, b) => {
          return numericSort(a.file.basename, b.file.basename, sortDesc[0])
        })
      }
      if (sortBy[0] === 'signatories') {
        return items.sort((a, b) => {
          return numericSort(a.signatories[0]?.fullName, b.signatories[0]?.fullName, sortDesc[0])
        })
      }
      if (sortBy[0] === 'status') {
        return items.sort((a, b) => {
          return numericSort(this.$t(`common.envelopeStatus.${a.status}`), this.$t(`common.envelopeStatus.${b.status}`), sortDesc[0])
        })
      }
      if (sortBy[0] === 'date') {
        if (!sortDesc[0]) {
          return items.sort((a, b) => {
            return dayjs(a.timestamp).isBefore(dayjs(b.timestamp)) ? -1 : 1
          })
        } else {
          return items.sort((a, b) => {
            return dayjs(a.timestamp).isBefore(dayjs(b.timestamp)) ? 1 : -1
          })
        }
      }
      return items
    },
  },
}
</script>

<style scoped lang="scss">
.ProjectSettingsSignatures-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#fff, .8);
  z-index: 1;
}
::v-deep .ProjectSettingsSignatures-table {
  background-color: transparent;
  table {
    border-collapse: separate;
  }
  th {
    color: var(--v-accent-base) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;

    &:first-child {
      max-width: 20px;
      padding: 0px
    }
  }
  td {
    color: var(--v-accent-base) !important;
    font-size: 14px !important;
    vertical-align: baseline;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  tbody {
    tr {
      cursor: pointer;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        margin-bottom: 10px;
      }

      td:first-child {
        max-width: 20px;
        padding: 0px
      }
    }
    tr.v-data-table__expanded__content {
      box-shadow: none;
    }
  }
}
.ProjectSettingsSignatures-expandIcon {
  transition-duration: 400ms;
  transition-property: transform;
}
.ProjectSettingsSignatures-expandIcon--open {
  transform: rotate(90deg);
}
::v-deep .v-data-footer{
  margin-top: 12px;
}
</style>
