<template>
  <v-btn class="expanse-button"
         fab
         plain
         @click="onClick"
  >
    <font-awesome-icon :icon="['fas', chevronIcon]" size="xs" />
  </v-btn>
</template>

<script>
const DIRECTIONS = Object.freeze({
  RIGHT: 'right',
  LEFT: 'left',
})

export default {
  name: 'ExpandButton',
  props: {
    direction: {
      type: String,
      validator (value) {
        // There's currently a bug in vue-styleguidist which makes us unable to check an array here...
        // So we should use this code instead if it becomes part of the styleguide:
        // return (value === DIRECTIONS.LEFT || value === DIRECTIONS.RIGHT)
        return [DIRECTIONS.LEFT, DIRECTIONS.RIGHT].includes(value)
      },
    },
  },
  computed: {
    chevronIcon () {
      if (this.direction === DIRECTIONS.LEFT) {
        return 'chevron-double-left'
      }

      return 'chevron-double-right'
    },
  },
  methods: {
    onClick () {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.expanse-button {
  width: 24px;
  height: 24px;
  color: var(--v-grey-darken3);
  background-color: var(--v-grey-lighten3);
}
</style>
