<template>
  <div class="add-team-combobox-item d-flex align-center">
    <app-button class="teams-btn-icon mr-3" type="ghost"
    >
      <app-icon icon-name="users" icon-weight="far" size="medium" />
    </app-button>
    <div>
      <!-- Team -->
      <app-text variant="small-bold" class="grey--text text--darken-3 mb-1">
        {{ item.name }}
      </app-text>
      <!-- Members -->
      <app-text v-if="withMembers" variant="x-small-bold" class="grey--text text--darken-2 mb-1"
                style="font-weight: 400;"
      >
        {{ $tc('subscriptions.views.SubscriptionTeams.memberCount', item.members.length) }}
      </app-text>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AddTeamComboboxItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
      withMembers: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style scoped lang="scss">
  .add-team-combobox-item {
    .teams-btn-icon {
      width: 32px;
      height: 32px;
      min-height: 0px;
      border-radius: 50%;
      color: var(--v-grey-base);
      background-color: var(--v-grey-lighten4);
    }
  }
</style>
