export const PROJECT_NAV_DRAWER_WIDTHS = {
  WITH_UNIT: {
    FOLDED: '80px',
    XS_SCREEN: '80vh',
    MOBILE: '33%',
    REGULAR: '200px',
  },
  RAW: {
    FOLDED: 80,
    REGULAR: 200,
  },
} as const
