<template>
  <ArchiveAddAccessBase :routeOptions="routeOptions">
    <template #default>
      <MultiSelectBarButton
        v-bind="$attrs"
        v-on="$listeners"
      >
        <ArchiveAddAccess :disableTooltip="true" :routeOptions="routeOptions" :solidIcon="true"></ArchiveAddAccess>
      </MultiSelectBarButton>
    </template>
  </ArchiveAddAccessBase>
</template>

<script>
import ArchiveAddAccess from './ArchiveAddAccess.vue'
import ArchiveAddAccessBase from './ArchiveAddAccessBase.vue'
import MultiSelectBarButton from '../buttons/MultiSelectBarButton.vue'
export default {
  name: 'ArchiveAddAccessMultiSelectBarButton',
  components: { ArchiveAddAccessBase, ArchiveAddAccess, MultiSelectBarButton },
  inheritAttrs: false,
  props: {
    routeOptions: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
