<template>
  <v-badge
    class="archive-add-access-icon-badge"
    overlap
    left
    bordered
    color="#646c73"
    offset-x="5"
    offset-y="7"
    :value="!isArchivingEnabled"
    icon="fa fa-crown"
  >
    <font-awesome-icon :icon="[solid ? 'fas' : 'fal', 'archive']"></font-awesome-icon>
  </v-badge>
</template>

<script>
export default {
  name: 'ArchiveAddAccessIcon',
  props: {
    isArchivingEnabled: {
      type: Boolean,
      default: true,
    },
    solid: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
  .archive-add-access-icon-badge ::v-deep .v-badge__badge {
    height: 14px;
    min-width: 12px;
    font-size: 12px;
    padding: 0 3px;
    & .v-icon {
      font-size: 7px;
      width: 12px;
      height: 12px;
      color: white !important;
    }
  }
</style>
