<template>
  <div class="ChecklistTable">
    <template v-if="(checklistPending && !checklist) || searchQueryPending">
      <v-skeleton-loader type="table-thead, table-heading, table-tbody"/>
    </template>
    <template v-if="checklist && checklist.length === 0">
      <div class="d-flex flex-column justify-center align-center">
        <div class="ChecklistTable-noTasks-illustration mb-8">
          <v-img src="/img/checklistIllustration.svg"
                 width="325"
          />
        </div>
        <app-text variant="medium-bold" class="mb-7 grey--text">{{$t('project.checklist.ChecklistTable.noTasks')}}</app-text>
        <div class="d-flex">
          <AppButton v-if="isCurrentUserPm"
                     class="mr-3"
                     :loading="createTaskPending"
                     :disabled="createTaskPending"
                     @click="prepareCreateTask"
          >
            {{$t('project.checklist.ChecklistTable.noTasksButton')}}
          </AppButton>
          <AppDropdownButton v-if="isCurrentUserPm && isSubscriber"
                             :menu-items="importChecklistDropdownMenu"
                             type="outlined"
                             :disabled="createTaskPending"
          >
            {{ $t('project.checklist.ChecklistTable.importChecklistButton') }}
          </AppDropdownButton>
        </div>
      </div>
    </template>
    <template v-if="checklist && checklist.length > 0">
      <v-card>
        <v-card-text class="pb-8"
                     :class="{'px-4': $vuetify.breakpoint.smAndDown, 'px-6': $vuetify.breakpoint.md, 'px-8': $vuetify.breakpoint.lgAndUp}"
        >
          <Alert v-if="fakeTitleIsPresent"
                 icon="fas fa-triangle-exclamation"
                 color="warning"
          >
            {{ $t('project.checklist.ChecklistTable.taskWithoutTitleWarning') }}
          </Alert>

          <ChecklistTableHeader v-show="$vuetify.breakpoint.mdAndUp" />
          <div>
            <div v-if="isChecklistFiltered && filteredChecklist && filteredChecklist.length === 0" class="mt-8">
              <DataTableFilterNoData />
            </div>
            <Draggable v-model="checklistModel"
                       :forceFallback="true"
                       :group="{name: 'topLevel', pull: false, put: false}"
                       :emptyInsertThreshold="100"
                       :delay="500"
                       :delayOnTouchOnly="true"
                       handle=".dragIcon"
                       :disabled="isChecklistFiltered"
                       @change="preparePostMoveTask"
                       @start="dragging = true"
                       @end="dragging = false"

            >
              <ChecklistTableTitle v-for="title in checklist"
                                   :key="`checklist-${title.type}-${title.id}`"
                                   :mnemo="mnemo"
                                   :parent="checklist"
                                   :item="title"
                                   :floatingAdd="tableElementMouseEntered === `${title.type}-${title.id}`"
                                   :elementEntered="tableElementMouseEntered"
                                   @newElementEntered="onNewElementEntered"

              />
            </Draggable>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <div v-if="checklistPending && checklist"
         class="ChecklistTable-refreshChecklistLoader"
    >
      <v-progress-circular class="ChecklistTable-refreshChecklistLoader-loader"
                           color="primary"
                           :size="80"
                           :width="5"
                           indeterminate
      />
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import Draggable from 'vuedraggable'
import { mapActions, mapState, mapGetters } from 'vuex'

import Alert from '@/common/alerts/Alert.vue'
import DataTableFilterNoData from '@/common/filters/DataTableFilterNoData'
import AppButton from '@/design-system/buttons/AppButton'
import AppDropdownButton from '@/design-system/buttons/AppDropdownButton'
import { surveyCanBeTriggered, SurveyId, triggerSurvey } from '@/plugins/satismeter'
import { GET_CHECKLIST, CREATE_TASK, GET_TASK_STATUS_CATEGORIES, POST_MOVE_TASK, GET_REFRESH_CHECKLIST } from '@/store/modules/checklist/action_types'
import {
  RESET_CHECKLIST,
  SET_CHECKLIST,
  SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN,
  SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN,
} from '@/store/modules/checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import ChecklistTableHeader from './ChecklistTableHeader'
import ChecklistTableTitle from './title/ChecklistTableTitle'

const REFRESH_LOOP_POLLING_DELAY = 30000

export default {
  name: 'ChecklistTable',
  components: { Alert, AppButton, ChecklistTableHeader, ChecklistTableTitle, Draggable, DataTableFilterNoData, AppDropdownButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      tableElementMouseEntered: '',
      dragging: false,
      refreshLoopId: 0,
      firstTaskHasBeenCreated: false,
      importChecklistDropdownMenu: [
        {
          title: this.$t('project.checklist.ChecklistTable.importChecklistFromTemplate'),
          action: () => { this.openImportChecklistFromTemplate() },
          icon: 'file-invoice',
        },
        {
          title: this.$t('project.checklist.ChecklistTable.importChecklistFromExcel'),
          action: () => { this.openImportChecklistDialog() },
          icon: 'file-excel',
        },
      ],
    }
  },
  computed: {
    ...mapState('checklist', ['checklist', 'checklistPending', 'searchQueryPending', 'createTaskPending', 'createTitlePending', 'filterPane', 'currentTitleEdit']),
    ...mapGetters('checklist', ['fakeTitleIsPresent', 'filteredChecklist', 'flatChecklist', 'hasActiveFilter', 'isChecklistFiltered']),
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapGetters('user', ['isSubscriber']),
    ...mapState('user', ['profile']),
    checklistModel: {
      get () {
        return this.checklist
      },
      set (value) {
        this.$store.commit(`checklist/${SET_CHECKLIST}`, value)
      },
    },
  },
  async mounted () {
    try {
      await this.GET_CHECKLIST(this.mnemo)
    } catch (e) {
      console.error(e)
    }
    this.refreshLoopId = setTimeout(() => { this.refreshLoop() }, REFRESH_LOOP_POLLING_DELAY)
    this.GET_GROUPS(this.mnemo)
    this.prepareGetTaskStatuses()
  },
  beforeDestroy () {
    this.$store.commit('checklist/' + RESET_CHECKLIST)
    clearTimeout(this.refreshLoopId)
    // Set to a recognizable value so that we know if we should stop refreshing if already fired
    this.refreshLoopId = -1

    const { id: userId, fullName, email } = this.profile
    if (this.firstTaskHasBeenCreated && surveyCanBeTriggered(SurveyId.CSAT, userId)) {
      triggerSurvey(SurveyId.CSAT, userId, fullName, email)
    }
  },
  methods: {
    ...mapActions('checklist', [GET_CHECKLIST, CREATE_TASK, POST_MOVE_TASK, GET_TASK_STATUS_CATEGORIES, GET_REFRESH_CHECKLIST]),
    ...mapActions('groups', ['GET_GROUPS']),
    async refreshLoop () {
      // We don't try to refresh if we're currently editing
      if (!this.currentTitleEdit) {
        // We want to avoid a zombie timer trying to refresh for a non-current mnemo
        // This should always be true...
        if (this.mnemo === this.$store.getters['room/roomMnemo']) {
          try {
            this.GET_REFRESH_CHECKLIST({
              mnemo: this.mnemo,
              params: {
                ifRecent: true,
              },
              isPolling: true,
            })
          } finally {
            if (this.refreshLoopId !== -1) {
              this.refreshLoopId = setTimeout(() => { this.refreshLoop() }, REFRESH_LOOP_POLLING_DELAY)
            }
          }
        } else {
          // This shouldn't happen, the timer has gone wild, the component is a zombie
          // Try to exorcise it ?
          clearTimeout(this.refreshLoopId)
          // Report this. This is unnatural
          Sentry.captureMessage('🧟 Trying to refresh a checklist that doesn\'t match the current mnemo', {
            tags: {
              propMnemo: this.mnemo,
              storeMnemo: this.$store.getters['room/roomMnemo'],
            },
          })
        }
      }
    },
    async prepareGetTaskStatuses () {
      try {
        await this.GET_TASK_STATUS_CATEGORIES({
          mnemo: this.mnemo,
        })
      } catch (e) {
        console.error(e)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.ChecklistTable.getTaskStatusesError'),
        })
      }
    },
    onNewElementEntered (elementString) {
      // It seems that when we're dragging or when user is a PM, we shouldn't re-render this component,
      // otherwise Draggable doesn't accept anything at the top level
      if (!this.dragging || !this.isCurrentUserPm) {
        this.tableElementMouseEntered = elementString
      }
    },
    async prepareCreateTask () {
      try {
        const data = {
          prevId: null,
          prevType: null,
        }
        if (this.flatChecklist.length > 0) {
          const lastElement = this.flatChecklist[this.flatChecklist.length - 1]
          data.prevId = lastElement.id
          data.prevType = lastElement.type
        }
        await this.CREATE_TASK({
          mnemo: this.mnemo,
          data,
        })

        this.firstTaskHasBeenCreated = true

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.ChecklistTable.createTaskSuccess'),
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.ChecklistTable.createTaskError'),
        })
      }
    },
    async preparePostMoveTask (e) {
      if (e.moved) {
        const itemToMove = e.moved.element
        const itemToMoveIndexInFlattenChecklist = this.flatChecklist.findIndex(el => el.id === itemToMove.id && el.type === itemToMove.type)
        const data = itemToMoveIndexInFlattenChecklist === 0
          ? { newPosition: 0 }
          : {
            prevId: this.flatChecklist[itemToMoveIndexInFlattenChecklist - 1].id,
            prevType: this.flatChecklist[itemToMoveIndexInFlattenChecklist - 1].type,
          }
        const trailItem = this.getTrailItem(itemToMove)
        data.newTrailId = trailItem.id
        data.newTrailType = trailItem.type
        try {
          await this.POST_MOVE_TASK({
            mnemo: this.mnemo,
            item: itemToMove,
            data,
          })
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.checklist.ChecklistTable.postMoveTitleSuccess'),
          })
          this.GET_CHECKLIST(this.mnemo)
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.ChecklistTable.postMoveTitleError'),
          })
        }
      }
    },
    getTrailItem (item) {
      if (item.type === 'task') {
        return item
      } else {
        if (item.children.length === 0) {
          return item
        } else {
          return this.getTrailItem(item.children[item.children.length - 1])
        }
      }
    },
    openImportChecklistDialog () {
      this.$store.commit(`checklist/${SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN}`, true)
    },
    openImportChecklistFromTemplate () {
      this.$store.commit(`checklist/${SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN}`, true)
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTable {
  position: relative;
}
.ChecklistTable-noTasks-illustration {
  margin-top: 70px;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    margin-top: 100px;
  }
}
.ChecklistTable-refreshChecklistLoader {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(#fff, .8);

  & .ChecklistTable-refreshChecklistLoader-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%, -50%);
  }
}

</style>
