<template>
  <AppDialog :is-open.sync="isDialogOpened"
             size="l"
             ok-only
             :ok-text="$t('common.share')"
             :ok-disabled="getArchiveRightsPending"
             :ok-loading="shareArchivesPending"
             @cancel="closeDialog"
             @ok="share"
  >
    <template #title>{{ $t('archives.dialogs.ArchivesShareDialog.title') }}</template>

    <template #subTitle>
      {{ $tc("archives.dialogs.ArchivesShareDialog.subtitle", data.length - 1, { name: data[0].docName }) }}
    </template>

    <template #body>
      <ArchivesShareModule
        v-if="!getArchiveRightsPending"
        v-model="shareConfig"
      />
      <div v-else class="text-center">
        <v-progress-circular
          style="width: 64px; height: 64px"
          color="primary"
          indeterminate
          size="64"
        />
      </div>
    </template>
  </AppDialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import { GET_ARCHIVE_RIGHTS, SHARE_ARCHIVES } from '@/store/modules/archives/action_types'

import AppDialog from '../../common/dialogs/AppDialog'
import { dialogMixin } from '../../mixins/dialogMixin'
import ArchivesShareModule from '../ArchivesShareModule'

const { mapActions, mapState } = createNamespacedHelpers('archives')

export default {
  name: 'ArchivesListShareDialog',
  components: { AppDialog, ArchivesShareModule },
  mixins: [dialogMixin],
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      shareConfig: {
        fileRights: [],
        notifyMessage: '',
        notify: false,
      },
    }
  },
  computed: {
    ...mapState(['getArchiveRightsPending', 'currentArchiveRights', 'shareArchivesPending']),
  },
  async created () {
    // If there is only one archive to share, we will get its initial config
    if (this.data.length === 1) {
      await this.GET_ARCHIVE_RIGHTS(this.data[0].id)
      this.shareConfig.fileRights = this.currentArchiveRights.read.users.map((u) => u.id)
    }
  },
  methods: {
    ...mapActions([GET_ARCHIVE_RIGHTS, SHARE_ARCHIVES]),
    async share () {
      const formData = new FormData()
      formData.append('withUsers', this.shareConfig.fileRights)
      formData.append('notify', this.shareConfig.notify)
      formData.append('notifyMessage', this.shareConfig.notifyMessage)
      formData.append('archives', this.data.map((a) => a.id))
      await this.SHARE_ARCHIVES(formData)
      this.$store.commit('enqueueSnackbar', {
        color: 'success',
        message: this.$tc('archives.dialogs.ArchivesShareDialog.success', this.data.length),
      })
      this.closeDialog()
    },
  },
}
</script>
