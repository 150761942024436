<template>
  <div>
    <v-row class="text--primary text-center mb-4">
      <v-col>
        <div class="d-flex flex-column align-center px-10">
          <font-awesome-icon :icon="['fas', 'sliders-v-square']"
                             class="ChooseFormula__icon deep-purple--text mb-4"
          ></font-awesome-icon>
          <div class="body-1 font-weight-medium mb-6">{{ $t('projects.dialogs.ProjectsNewDataroomDialogChooseFormula.specificNeed') }}</div>
          <div class="mb-6">{{ $t('projects.dialogs.ProjectsNewDataroomDialogChooseFormula.activateDataRoom') }}</div>
          <AppButton color="primary"
                     @click="$emit('configureClicked')"
          >
            {{ $t('projects.dialogs.ProjectsNewDataroomDialogChooseFormula.configure') }}
          </AppButton>
        </div>
      </v-col>
      <v-divider vertical/>
      <v-col>
        <div class="d-flex flex-column align-center px-10">
          <font-awesome-icon :icon="['fas', 'envelope']"
                             class="ChooseFormula__icon deep-purple--text mb-4"
          ></font-awesome-icon>
          <div class="body-1 font-weight-medium mb-6">{{ $t('projects.dialogs.ProjectsNewDataroomDialogChooseFormula.recurringNeed') }}</div>
          <div class="mb-6">{{ $t('projects.dialogs.ProjectsNewDataroomDialogChooseFormula.contactUsForSubscription') }}</div>
          <AppButton color="primary"
                     @click="$emit('requestActivation')"
          >
            {{ $t('projects.dialogs.ProjectsNewDataroomDialogChooseFormula.contactUs') }}
          </AppButton>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppButton from '@/common/buttons/AppButton.vue'

export default {
  name: 'ProjectsNewDataroomDialogChooseFormula',
  components: { AppButton },
}
</script>

<style scoped lang="scss">
.ChooseFormula {
  &__icon {
    font-size: 25px;
  }
}
</style>
