<template>
  <app-treeview-item class="section-item text-truncate" :selected="selected">
    <app-icon size="regular"
              :icon-name="icon"
              class="grey--text text--darken-1 no-shrink"
    />

    <AppTooltip top :open-delay="500" :disabled="!hasTooltip">
      <template #activator="{ attrs, on }">
        <div v-bind="attrs"
             ref="sectionName"
             class="text-truncate"
             v-on="on"
        >
          <app-text :variant="selected ? 'small-bold' : 'small-regular'" as="span">
            {{ sectionName }}
          </app-text>
        </div>
      </template>
      {{ sectionName }}
    </AppTooltip>
  </app-treeview-item>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  import AppTooltip from '@/common/AppTooltip.vue'

  export default defineComponent({
    name: 'DocumentsSidebarSectionItem',
    components: { AppTooltip },
    props: {
      icon: {
        type: String,
        required: true,
      },
      sectionName: {
        type: String,
        required: true,
      },
      selected: {
        type: Boolean,
        required: false,
      },
    },
    data () {
      return {
        hasTooltip: false,
      }
    },
    async mounted () {
      await this.$nextTick()
      const sectionNameDivElement = this.$refs.sectionName as HTMLDivElement
      this.hasTooltip = sectionNameDivElement?.offsetWidth < sectionNameDivElement?.scrollWidth
    },
  })
</script>

<style lang="scss" scoped>
  .section-item {
    display: flex;
    align-items: flex-end;
    column-gap: 8px;
    cursor: pointer;
  }
</style>
