<template>
  <v-select
    ref="select"
    :label="selectLabel"
    v-bind="$attrs"
    :items="items"
    :hide-details="hideDetails"
    outlined
    dense
    item-color="blue darken-2"
    color="blue darken-2"
    v-on="$listeners"
  >
    <!-- Pass down all the slots received to VSelect to benefit from
         all its non-scoped own slots
    -->
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <slot :name="slot" />
    </template>
    <template v-slot:prepend-inner>
      <!-- @slot Inner prepend slot. Replaces the default icon slot -->
      <slot name="prepend-inner"></slot>
    </template>
    <template v-slot:append>
      <template>
        <AppButton small icon>
          <font-awesome-icon :icon="['fas', 'caret-down']" size="lg"></font-awesome-icon>
        </AppButton>
        <!-- @slot Append slot -->
        <slot name="append"></slot>
      </template>
    </template>
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
  </v-select>
</template>

<script>
import AppButton from './buttons/AppButton.vue'
/**
* All Vuetify props for Select component are usable.
* @see https://vuetifyjs.com/en/api/v-select/#props
*/
export default {
  name: 'AppSelect',
  components: { AppButton },
  inheritAttrs: false,
  props: {
    /**
     * Array of select options
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * Displayed select label
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Disable input's label
     */
    noLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * Hide details space under input
     */
    hideDetails: {
      default: 'auto',
    },
  },
  computed: {
    selectLabel () {
      if (this.noLabel) {
        return null
      }
      return this.label || this.$t('common.AppSelect.selectOption')
    },
  },
}
</script>

<docs>
  ```vue
  <template>
    <AppSelect label="My select label"
               :items="['item1', 'item2', 'item3']"
    ></AppSelect>
  </template>
  ```
</docs>
