<template>
  <div class="filters-summary">
    <v-chip v-for="({ category, value }) in activeFilters"
            :key="`${category.key}_${value.key}`"
            class="px-4"
            close
            close-icon="fal fa-xmark"
            @click:close="() => clearFilter(category, value)"
    >
      <span v-if="category.label" class="font-weight-medium mr-1">{{category.label}} :</span>
      {{value.label}}
    </v-chip>

    <app-button v-if="hasMultipleActiveFilters" size="small" type="outlined" variant="neutral" @click="clearAllFilters">
      {{ $t('common.components-filters.DataTableFiltersSummary.clearFilters') }}
    </app-button>
  </div>
</template>

<script>
export default {
  name: 'DataTableFiltersSummary',
  computed: {
    hasMultipleActiveFilters () {
      return this.activeFilters.length > 1
    },
  },
  props: {
    /**
     * Filters to display in chips as summary
     * A filter is defined by a category and a value, each of these properties having a key and a label
     * Eg: { category: { key: 'author', label: 'Auteur' }, value: { key: 1, label: 'Bruno Thomas' } }
     * @default []
     */
    activeFilters: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clearFilter (category, value) {
      this.$emit('clear-filter', { category, value })
    },
    clearAllFilters () {
      this.$emit('clear-all-filters')
    },
  },
}
</script>

<style scoped lang="scss">
.filters-summary {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
}
</style>
