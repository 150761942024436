<template>
  <AppPhoneField
    :value="model.$model"
    :label="label"
    :error="isErrored"
    :error-messages="telephoneErrors"
    hide
    @input="handlePhoneChange"
  />
</template>

<script>
import AppPhoneField from '@/common/AppPhoneField'
import i18n from '@/i18n'

export default {
  name: 'Telephone',
  components: { AppPhoneField },
  props: {
    label: {
      type: String,
      default: i18n.t('common.phoneNumber'),
    },
    model: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isValidPhoneNumber: false,
      isMobilePhoneNumber: false,
    }
  },
  computed: {
    isErrored () {
      return !this.model.required || !this.isValidPhoneNumber || !this.isMobilePhoneNumber
    },
    telephoneErrors () {
      const errors = []
      if (!this.model.$dirty) return errors
      !this.model.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.label }))
      !this.isValidPhoneNumber && errors.push(this.$t('common.identity-checking.IdentityCheckingInformation.errors.invalidPhoneNumber'))
      !this.isMobilePhoneNumber && errors.push(this.$t('common.identity-checking.IdentityCheckingInformation.errors.invalidMobilePhoneNumber'))
      return errors
    },
  },
  methods: {
    handlePhoneChange (input, event) {
      this.isValidPhoneNumber = event.isValid
      // In some countries it's possible to upgrade a fixed line number to a mobile line
      // It's the case in Canada or the US for example (See V2-3466)
      // Hence event.type === 'fixed-line-or-mobile'
      // so we don't restrict those cases
      this.isMobilePhoneNumber = event.type === 'mobile' || event.type === 'fixed-line-or-mobile'
      this.$emit('phone-validation-change', this.isValidPhoneNumber && this.isMobilePhoneNumber)

      this.model.$model = event.number.e164 || input
    },
  },
}
</script>
