<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('common.confirm')"
             @cancel="closeDialog"
             @ok="onConfirm"
  >
    <template #title>{{ $t('project.signing-checklist.envelope-templates.dialogs.DeleteFileDialog.title') }}</template>

    <template #body>
      <p>{{ $t("project.signing-checklist.envelope-templates.dialogs.DeleteFileDialog.warning") }}</p>
    </template>
  </AppDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'

export default defineComponent({
  name: 'DeleteFileDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  methods: {
    onConfirm () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
})
</script>
