<template>
  <div> <!-- div to add classes eg: mr-4 (not supported by AppTooltip) -->
    <AppTooltip bottom max-width="300" :disabled="!tooltipText">
      <template v-slot:activator="{ on, attrs }">
        <AppTextField
          class="SearchInput white"
          :value="searchQuery"
          :class="{'SearchInput--fluid': fluid}"
          :placeholder="$t('common.SearchTextField.search')"
          color="blue darken-2"
          outlined
          prepend-inner-icon="fal fa-magnifying-glass"
          clearable
          hide-details
          dense
          v-bind="{...attrs, ...$attrs}"
          v-on="on"
          @click:clear="$emit('click:clear')"
          @input="searchQuery = $event"
        />
      </template>
      <span>{{ tooltipText }}</span>
    </AppTooltip>
  </div>
</template>

<script>
import AppTextField from './AppTextField.vue'
import AppTooltip from '../common/AppTooltip.vue'
/**
 * @displayName SearchTextField
*/
/**
 * A v-text-field with custom behavior for search use-cases.
 * @see  v-text-field from Vuetify (https://vuetifyjs.com/en/api/v-text-field/)
 * */
export default {
  name: 'SearchTextField',
  components: {
    AppTooltip,
    AppTextField,
  },
  inheritAttrs: false,
  /**
   * props from v-text-field from Vuetify (https://vuetifyjs.com/en/api/v-text-field/) are also available
   */
  props: {
    /**
     * @model
     * Value passed from parent
     */
    value: {
      type: String,
      required: true,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
  computed: {
    searchQuery: {
      get () { return this.value },
      set (val) {
        if (val === null) {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
.SearchInput {
  max-width: 200px;
  transition: max-width .25s ease-in-out;
  &--fluid {
    max-width: 100%;
  }
}

::v-deep.theme--light.SearchInput.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: #bdbdbd;
}

.SearchInput ::v-deep .v-icon {
  font-size: 16px;
}

.v-application--is-ltr .SearchInput ::v-deep .v-input__prepend-inner {
  padding-right: 12px;
}

.SearchInput ::v-deep .v-input__prepend-inner.v-icon {
  color: #333;
}
::v-deep i.v-icon.notranslate.fal.fa-magnifying-glass.theme--light {
  color: #333 !important;
}
::v-deep button.v-icon.notranslate.v-icon--link.fal.fa-xmark.theme--light {
  color: #333 !important;
}
</style>

<docs>
    ```vue
    <template>
        <SearchTextField v-model="searchQuery"/>
    </template>
    <script>
        export default {
            data() {
                return {
                    searchQuery: ''
                }
            }
        }
    </script>
    ```
</docs>
