import Vue from 'vue'

import { StoreMutations } from '@/types/store'

import { EnvelopeTemplatesMutationType } from './mutation_types'

export const mutations: StoreMutations<EnvelopeTemplatesMutationType> = {
  SET_SAVE_ENVELOPE_AS_TEMPLATE_PENDING (state, value) {
    state.saveEnvelopeAsTemplatePending = value
  },
  SET_GET_ENVELOPE_TEMPLATES_PENDING (state, value) {
    state.getEnvelopeTemplatesPending = value
  },
  SET_GET_ENVELOPE_TEMPLATES (state, value) {
    state.envelopeTemplates = value
  },
  SET_DRAFT_ENVELOPE_TEMPLATE (state, templateData) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
        templateDraft => templateDraft.templateId === templateData.templateId,
    )

    if (templateDraftIndex >= 0) {
      Vue.set(state.envelopeTemplateDrafts, templateDraftIndex, templateData)
    } else {
      state.envelopeTemplateDrafts.push(templateData)
    }
  },
  RESET_DRAFT_ENVELOPE_TEMPLATES (state) {
    state.envelopeTemplateDrafts = []
  },
  ADD_DOCUMENTS_TO_TEMPLATE_DRAFT (state, documents) {
    const newTemplatesDraft: Array<DraftEnvelopeTemplate> = documents.map(document => ({
      document: {
        ...document,
        objectToPost: {
          ...document.objectToPost,
          signers: 0,
        },
      },
    }))
    state.envelopeTemplateDrafts.push(...newTemplatesDraft)
  },
  REMOVE_DOCUMENT_FROM_TEMPLATE_DRAFT (state, documentToRemove) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document === documentToRemove,
    )
    state.envelopeTemplateDrafts.splice(templateDraftIndex, 1)
  },
  SET_TEMPLATE_DOCUMENT_ID_AFTER_CREATION_SUCCESS (state, { fileId, templateId }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.id === fileId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].templateId = templateId
    }
  },
  SET_TEMPLATE_DOCUMENT_DATA_AFTER_UPLOAD_SUCCESS (state, { queueId, uploadedDocument }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.queueId === queueId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document = {
        ...state.envelopeTemplateDrafts[templateDraftIndex].document,
        id: uploadedDocument.id,
        name: uploadedDocument.name, // File name may be truncated
        basename: uploadedDocument.basename, // TODO : pourquoi name ET basename (name = basename + extension) ?
        ext: uploadedDocument.ext, // TODO : pourquoi ext et non extension ?
        status: 'success',
        objectToPost: {
          ...state.envelopeTemplateDrafts[templateDraftIndex].document.objectToPost,
          fileId: uploadedDocument.id, // TODO : utile d'avoir ces informations en double, ici et au niveau parent ?
          title: uploadedDocument.basename,
        },
      }
    }
  },
  SET_TEMPLATE_DOCUMENT_UPLOAD_PROGRESS (state, { queueId, uploadProgression }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.queueId === queueId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document.progress = uploadProgression
    }
  },
  SET_TEMPLATE_DOCUMENT_DATA_AFTER_UPLOAD_ERROR (state, queueId) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.queueId === queueId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document.status = 'error'
    }
  },
  SET_DUPLICATE_CHECKBOX_PENDING (state, value) {
    state.duplicateCheckboxPending = value
  },
  SET_DUPLICATE_ENVELOPE_TEMPLATE_PENDING (state, value) {
    state.duplicateEnvelopeTemplatePending = value
  },
  SET_DUPLICATE_TAG_PENDING (state, value) {
    state.duplicateTagPending = value
  },
  ADD_ENVELOPE_TEMPLATE (state, envelopeTemplate) {
    state.envelopeTemplates.push(envelopeTemplate)
  },
  SET_DELETE_ENVELOPE_TEMPLATES_PENDING (state, value) {
    state.deleteEnvelopeTemplatesPending = value
  },
  SET_RENAME_ENVELOPE_TEMPLATE_PENDING (state, value) {
    state.renameEnvelopeTemplatePending = value
  },
  SET_RENAME_ENVELOPE_TEMPLATE_SIGNER_PENDING (state, value) {
    state.renameEnvelopeTemplateSignerPending = value
  },
  REMOVE_ENVELOPE_TEMPLATES (state, envelopeTemplateIds: number[]) {
    state.envelopeTemplates = state.envelopeTemplates.filter(envelopeTemplate => !envelopeTemplateIds.includes(envelopeTemplate.id))
  },
  UPDATE_ENVELOPE_TEMPLATE (state, envelopeTemplate) {
    const envelopeTemplateIndex = state.envelopeTemplates.findIndex(e => e.id === envelopeTemplate.id)
    Vue.set(state.envelopeTemplates, envelopeTemplateIndex, envelopeTemplate)
  },
  SET_TEMPLATE_DOCUMENT_ENVELOPE_TYPE (state, { fileId, envelopeType }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.id === fileId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document.objectToPost.envelopeType = envelopeType
    }
  },
  SET_TEMPLATE_DOCUMENT_SHARE_SEND_NOTIF (state, { fileId, sendNotif }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.id === fileId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document.objectToPost.sendNotif = sendNotif
    }
  },
  SET_TEMPLATE_DOCUMENT_SHARE_TYPE (state, { fileId, shareType }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.id === fileId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document.objectToPost.shareType = shareType
      state.envelopeTemplateDrafts[templateDraftIndex].document.objectToPost.withUsers = []
    }
  },
  SET_TEMPLATE_DOCUMENT_SHARE_WITH_USERS (state, { fileId, withUsers }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.id === fileId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document.objectToPost.withUsers = withUsers
    }
  },
  SET_TEMPLATE_DOCUMENT_SIGNATURE_TYPE (state, { fileId, signatureType }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.id === fileId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document.objectToPost.signatureType = signatureType
    }
  },
  SET_TEMPLATE_DOCUMENT_SIGNERS (state, { fileId, signers }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.id === fileId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document.objectToPost.signers = signers
    }
  },
  SET_TEMPLATE_DOCUMENT_TITLE (state, { fileId, title }) {
    const templateDraftIndex = state.envelopeTemplateDrafts.findIndex(
      templateDraft => templateDraft.document.id === fileId,
    )

    if (templateDraftIndex >= 0) {
      state.envelopeTemplateDrafts[templateDraftIndex].document.objectToPost.title = title
    }
  },
  SET_PATCH_ENVELOPE_TEMPLATE_PENDING (state, value) {
    state.patchEnvelopeTemplatePending = value
  },
  SET_POST_ENVELOPE_TEMPLATE_PENDING (state, value) {
    state.postEnvelopeTemplatePending = value
  },
  SET_ENVELOPE_TEMPLATE_DISPLAY_INFORMATIONS (state, displayInformations) {
    state.envelopeTemplateDisplayInformations = displayInformations
  },
  SET_ENVELOPE_TEMPLATE_DISPLAY_INFORMATIONS_PENDING (state, value) {
    state.envelopeTemplateDisplayInformationsPending = value
  },
  SET_ENVELOPE_TEMPLATE_TAGGING_INFORMATIONS (state, value) {
    state.envelopeTemplateTaggingInformations = value
  },
  SET_ENVELOPE_TEMPLATE_TAGGING_INFORMATIONS_PENDING (state, value) {
    state.envelopeTemplateTaggingInformationsPending = value
  },
  SET_DELETE_ALL_TAGS_PENDING (state, value) {
    state.deleteAllTagsPending = value
  },
  SET_DELETE_TAG_PENDING (state, value) {
    state.deleteTagPending = value
  },
  ADD_DELETE_SIGNER_PENDING_ID (state, value) {
    state.deleteSignerPendingIds = state.deleteSignerPendingIds.concat(value)
  },
  REMOVE_DELETE_SIGNER_PENDING_ID (state, id) {
    state.deleteSignerPendingIds =
      state.deleteSignerPendingIds.filter(ids => ids === id)
  },
  SET_PAGE_CONTENT (state, { pageNumber, content }) {
    if (state.pageContents[pageNumber]) {
      state.pageContents[pageNumber].data = content
    } else {
      Vue.set(state.pageContents, pageNumber, {
        data: content,
        isLoading: false,
      })
    }
  },
  SET_PAGE_CONTENT_IS_LOADING  (state, { pageNumber, isLoading }) {
    if (state.pageContents[pageNumber]) {
      state.pageContents[pageNumber].isLoading = isLoading
    } else {
      Vue.set(state.pageContents, pageNumber, {
        data: null,
        isLoading: isLoading,
      })
    }
  },
  SET_PAGE_THUMB (state, { pageNumber, thumb }) {
    if (state.pageThumbs[pageNumber]) {
      state.pageThumbs[pageNumber].data = thumb
    } else {
      Vue.set(state.pageThumbs, pageNumber, {
        data: thumb,
        isLoading: false,
      })
    }
  },
  SET_PAGE_THUMB_IS_LOADING (state, { pageNumber, isLoading }) {
    if (state.pageThumbs[pageNumber]) {
      state.pageThumbs[pageNumber].isLoading = isLoading
    } else {
      Vue.set(state.pageThumbs, pageNumber, {
        data: null,
        isLoading: isLoading,
      })
    }
  },
  RESET_ENVELOPE_TEMPLATE_TO_PREPARE (state) {
    state.envelopeTemplateDisplayInformations = null
    state.envelopeTemplateTaggingInformations = null
    state.pageContents = {}
    state.pageThumbs = {}
  },
  SET_DRAG_TAG_ACTIVE_PENDING (state, value) {
    state.dragTagActivePending = value
  },
  SET_DRAG_TAG_ACTIVE (state, payload) {
    state.dragTagActive.value = payload.value
    state.dragTagActive.tag = payload.tag
  },
  SET_LAST_SAVE (state, value) {
    state.lastSave = value
  },
  SET_PATCH_TAG_PENDING (state, value) {
    state.patchTagPending = value
  },
  SET_POST_TAG_PENDING (state, value) {
    state.postTagPending = value
  },
}
