<template>
  <div>
    <div class="documents"
         data-locator="documents"
         :style="{ 'left' : pageOffsetForNavDrawer }"
    >
      <ColResizer class="fill-height"
                  :width="documentsSidebarWidth"
                  :min-width="documentsSidebarMinMaxWidths.min"
                  :max-width="documentsSidebarMinMaxWidths.max"
                  @resize="onSidebarWidthChanged"
      >
        <template v-if="!$vuetify.breakpoint.xs" #left>
          <div class="documents__sidebar-wrapper">
            <DocumentsSidebar class="documents__sidebar"/>
          </div>
        </template>

        <template #right>
          <div class="documents__list-wrapper">
            <div class="documents__list">
              <template v-if="isShowEmptyState">
                <DocumentsStorageExceededAlert v-if="isDocumentsStorageExceededAlertDisplayed" />
                <DocumentsEmptyState :mnemo="mnemo"/>
              </template>
              <template v-else>
                <DocumentsHeader :mnemo="mnemo"/>
                <DocumentsStorageExceededAlert v-if="isDocumentsStorageExceededAlertDisplayed" />
                <DocumentsTableTemplate :mnemo="mnemo"/>
              </template>
            </div>
          </div>
        </template>
      </ColResizer>
    </div>

    <DocumentsPane />
    <DocumentsFiltersPane />
    <DocumentsTablePane :mnemo="mnemo" />
    <DocumentsVersioningPane :mnemo="mnemo"/>
    <DocumentsAISummaryPane />

    <DocumentsMovementSnackbar v-if="movementSnackbarIsOpen"/>
    <DocumentsNumberingSnackbar v-if="numberingSnackbarIsOpen"/>

    <DocumentsDeleteDialog
      v-if="documentsDeleteDialogIsOpen"
      @close="CLOSE_DOCUMENTS_DELETION_DIALOG"
      @validate-deletion="onDeletionValidation"
    />
    <DocumentsNewFolderDialog/>
    <DocumentsFileUploadDialog v-if="documentsUploadFilesDialogIsOpen"
                               :mnemo="mnemo"
                               @close="CLOSE_DOCUMENTS_UPLOAD_FILES"
    />
    <DocumentsDownloadFilesDialog v-if="documentsDownloadFilesDialogIsOpen"
                                  :mnemo="mnemo"
                                  :fileType="currentPossibleFileType"
                                  @close="CLOSE_DOCUMENTS_DOWNLOAD_FILES"
    />
    <DocumentsRenameDialog :key="documentToRename.id"/>
    <DocumentsLogsDialog :mnemo="mnemo"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import ColResizer from '@/common/col-resizer/ColResizer.vue'
import { PROJECT_NAV_DRAWER_WIDTHS } from '@/common/layout/constants'
import { getRoomRouteType } from '@/common/utils/rooms'
import { ROOM_TYPES } from '@/common/utils/roomTypes'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import { ROOM_MEMBERS_ROUTE_NAME } from '@/router'
import {
  GET_DOCUMENTS_BY_ID,
  LOAD_FOLDERS_TREE,
  RESET_DOCUMENTS,
  RESET_FULL_TREE,
} from '@/store/modules/documents/action_types'
import { CLOSE_DOCUMENTS_DELETION_DIALOG, DELETE_FILDERS } from '@/store/modules/documents-delete/action_types'
import { CLOSE_DOCUMENTS_DOWNLOAD_FILES } from '@/store/modules/documents-download-files/action_types'
import { CLOSE_DOCUMENTS_UPLOAD_FILES } from '@/store/modules/documents-upload-files/action_types'
import { GET_ROOM_STATS } from '@/store/modules/room/action_types'
import { ENQUEUE_SNACKBAR, ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

import DocumentsDeleteDialog from './dialogs/DocumentsDeleteDialog'
import DocumentsDownloadFilesDialog, { FILE_TYPES } from './dialogs/DocumentsDownloadFilesDialog.vue'
import DocumentsFileUploadDialog from './dialogs/DocumentsFileUploadDialog'
import DocumentsLogsDialog from './dialogs/DocumentsLogsDialog'
import DocumentsNewFolderDialog from './dialogs/DocumentsNewFolderDialog'
import DocumentsRenameDialog from './dialogs/DocumentsRenameDialog'
import DocumentsEmptyState from './DocumentsEmptyState'
import DocumentsFiltersPane from './DocumentsFiltersPane.vue'
import DocumentsHeader from './DocumentsHeader'
import DocumentsMovementSnackbar from './DocumentsMovementSnackbar.vue'
import DocumentsNumberingSnackbar from './DocumentsNumberingSnackbar.vue'
import DocumentsStorageExceededAlert from './DocumentsStorageExceededAlert.vue'
import DocumentsSidebar from './navigation-sidebar/DocumentsSidebar.vue'
import DocumentsAISummaryPane from './pane/DocumentsAISummaryPane.vue'
import DocumentsPane from './pane/DocumentsPane'
import DocumentsTablePane from './pane/DocumentsTablePane'
import DocumentsVersioningPane from './pane/DocumentsVersioningPane'
import DocumentsTableTemplate from './table/DocumentsTableTemplate'

const DOCUMENTS_SIDEBAR_WIDTH_KEY = 'documents-sidebar-width'

export default {
  name: 'Documents',
  components: {
    ColResizer,
    DocumentsAISummaryPane,
    DocumentsDeleteDialog,
    DocumentsDownloadFilesDialog,
    DocumentsFileUploadDialog,
    DocumentsFiltersPane,
    DocumentsHeader,
    DocumentsLogsDialog,
    DocumentsMovementSnackbar,
    DocumentsNewFolderDialog,
    DocumentsNumberingSnackbar,
    DocumentsPane,
    DocumentsVersioningPane,
    DocumentsRenameDialog,
    DocumentsSidebar,
    DocumentsStorageExceededAlert,
    DocumentsTablePane,
    DocumentsTableTemplate,
    DocumentsEmptyState,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  provide () {
    return {
      loadDocumentsTable: this.loadDocumentsTable,
    }
  },
  metaInfo () {
    return {
      title: this.$t('project.documents.Documents.metaTitle'),
    }
  },
  computed: {
    ...mapState(['toggleProjectDrawer']),
    ...mapState('documents', ['documents', 'movementSnackbarIsOpen', 'numberingSnackbarIsOpen', 'fullTree', 'loading']),
    ...mapState('documentsDelete', {
      documentsDeleteDialogIsOpen: 'isOpen',
      documentsToDelete: 'documents',
    }),
    ...mapState('documentsRename', ['documentToRename']),
    ...mapState('room', ['currentRoom', 'roomStats', 'roomStorageExceededDialogIsOpen']),
    ...mapGetters('room', ['storagePercent', 'isStorageFull']),
    ...mapGetters('documentsBreadcrumb', ['hasTodoFolderOrChild']),
    ...mapGetters('user', ['currentUserId']),
    ...mapState('documentsUploadFiles', {
      documentsUploadFilesDialogIsOpen: 'isOpen',
    }),
    ...mapState('documentsDownloadFiles', {
      documentsDownloadFilesDialogIsOpen: 'isOpen',
    }),
    ...mapState('global', ['mini']),
    isDocumentsStorageExceededAlertDisplayed () {
      return (this.currentRoom?.type === ROOM_TYPES.DATAROOM_UNLIMITED && this.isStorageFull) ||
      (this.currentRoom?.type === ROOM_TYPES.DATAROOM && this.roomStats?.storage && this.storagePercent >= 80)
    },
    currentPossibleFileType () {
      return this.hasTodoFolderOrChild
        ? FILE_TYPES.TODO
        : FILE_TYPES.DOCUMENT
    },
    pageOffsetForNavDrawer () {
      if (this.$vuetify.breakpoint.mobile) {
        return 0
      }

      if (this.mini) {
        return PROJECT_NAV_DRAWER_WIDTHS.WITH_UNIT.FOLDED
      }

      return PROJECT_NAV_DRAWER_WIDTHS.WITH_UNIT.REGULAR
    },
    documentsSidebarMinMaxWidths () {
      switch (true) {
        case this.$vuetify.breakpoint.xs:
          return { min: 200, max: 240 }
        case this.$vuetify.breakpoint.sm:
          return { min: 200, max: 280 }
        case this.$vuetify.breakpoint.md:
          return { min: 200, max: 320 }
        default:
          return { min: 240, max: 480 }
      }
    },
    documentsSidebarWidth () {
      const { min: sidebarMinWidth, max: sidebarMaxWidth } = this.documentsSidebarMinMaxWidths
      const storedWidth = +getUserSetting(this.currentUserId, DOCUMENTS_SIDEBAR_WIDTH_KEY) ?? sidebarMinWidth

      // We do not handle width for mobile and xs-screen because documents sidebar don't expand with these modes
      const navDrawerExpandedWidth = PROJECT_NAV_DRAWER_WIDTHS.RAW.REGULAR
      const navDrawerFoldedWidth = PROJECT_NAV_DRAWER_WIDTHS.RAW.FOLDED
      const navDrawerCurrentWidth = this.mini
        ? navDrawerFoldedWidth
        : navDrawerExpandedWidth
      const availableSpaceWhenFolded = navDrawerExpandedWidth - navDrawerCurrentWidth
      const fillingSpaceWidth = storedWidth + availableSpaceWhenFolded

      return Math.min(Math.max(sidebarMinWidth, fillingSpaceWidth), sidebarMaxWidth)
    },
    isShowEmptyState () {
      return (
              !this.loading &&
              (
                (this.fullTree?.children?.length === 1 &&
                  this.fullTree.children[0].specialFolder === 'todo' &&
                  !this.fullTree.children[0].childrenCount) ||
                (!this.fullTree?.children?.length)
              )
            )
    },
  },
  created () {
    if (this.currentRoom?.hideDocuments) {
      this.$router.replace({ name: ROOM_MEMBERS_ROUTE_NAME, params: { roomType: getRoomRouteType(this.currentRoom) } })
    }
    this.GET_GROUPS(this.mnemo)
  },
  async mounted () {
    await this.loadDocumentsTable()
    this.prepareGetRoomStats()
    this.addBreadcrumbItem(this.documents)
  },
  watch: {
    async roomStorageExceededDialogIsOpen (dialogIsOpened) {
      if (!dialogIsOpened) {
        await this.prepareGetRoomStats()
        this.loadDocumentsTable()
      }
    },
  },
  methods: {
    ...mapActions('documents', [
      GET_DOCUMENTS_BY_ID,
      LOAD_FOLDERS_TREE,
      RESET_DOCUMENTS,
      RESET_FULL_TREE,
    ]),
    ...mapActions('documentsBreadcrumb', ['addBreadcrumbItem']),
    ...mapActions('groups', ['GET_GROUPS']),
    ...mapActions('documentsDelete', [CLOSE_DOCUMENTS_DELETION_DIALOG, DELETE_FILDERS]),
    ...mapActions('documentsUploadFiles', [CLOSE_DOCUMENTS_UPLOAD_FILES]),
    ...mapActions('documentsDownloadFiles', [CLOSE_DOCUMENTS_DOWNLOAD_FILES]),
    ...mapActions('room', [GET_ROOM_STATS]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async loadDocumentsTable () {
      try {
        await this.GET_DOCUMENTS_BY_ID({ queryObject: { markRecent: true } })
      } catch (error) {
        console.error(error)

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.Documents.getDocumentsError'),
        })
      }
    },
    async onDeletionValidation () {
      const needFoldersTreeRefreshAfterDeletion = this.documentsToDelete.some(document => document.type === 'folder')
      await this.DELETE_FILDERS()

      if (needFoldersTreeRefreshAfterDeletion) {
        await this.LOAD_FOLDERS_TREE()
      }
    },
    onSidebarWidthChanged (width) {
      setUserSetting(this.currentUserId, DOCUMENTS_SIDEBAR_WIDTH_KEY, width)
    },
    async prepareGetRoomStats () {
      try {
        await this.GET_ROOM_STATS(this.mnemo)
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.project-settings.ProjectSettingsDataRoomInfo.getRoomStatsError'))
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    this.RESET_DOCUMENTS()
    this.RESET_FULL_TREE()
    next()
  },
}
</script>

<style lang="scss" scoped>
.documents {
  --header-height: 64px;

  height: calc(100% - var(--header-height));
  position: fixed;
  right: 0;

  &__sidebar,
  &__list {
    padding: 16px 24px;
  }

  &__sidebar-wrapper,
  &__list-wrapper {
    position: relative;
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
  }

  &__list-wrapper {
    border-left: 1px solid var(--v-grey-lighten4);
  }

  &__list {
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>
