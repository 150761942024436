export const ADD_ALL_ITEMS_TO_SELECTION = 'ADD_ALL_ITEMS_TO_SELECTION'
export const ADD_TASK_TO_SELECTION = 'ADD_TASK_TO_SELECTION'
export const ADD_TITLE_CHILDREN_TO_SELECTION = 'ADD_TITLE_CHILDREN_TO_SELECTION'
export const ADD_TITLE_TO_SELECTION = 'ADD_TITLE_TO_SELECTION'
export const CLEAR_ITEMS_SELECTION = 'CLEAR_ITEMS_SELECTION'
export const CLOSE_CHECKLIST_PANE = 'CLOSE_CHECKLIST_PANE'
export const CLOSE_SAVE_CHECKLIST_DIALOG = 'CLOSE_SAVE_CHECKLIST_DIALOG'
export const CLOSE_IMPORT_CHECKLIST_DIALOG = 'CLOSE_IMPORT_CHECKLIST_DIALOG'
export const CLOSE_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG = 'CLOSE_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG'
export const CONTACT_RESPONSIBLE = 'CONTACT_RESPONSIBLE'
export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TITLE = 'CREATE_TITLE'
export const DELETE_FILDERS = 'DELETE_FILDERS'
export const DELETE_SAVED_CHECKLIST = 'DELETE_SAVED_CHECKLIST'
export const SHARE_SAVED_CHECKLIST = 'SHARE_SAVED_CHECKLIST'
export const RENAME_SAVED_CHECKLIST = 'RENAME_SAVED_CHECKLIST'
export const DELETE_CHECKLIST_DOUBLE_VALIDATION = 'DELETE_CHECKLIST_DOUBLE_VALIDATION'
export const DELETE_TABLE_SELECTION = 'DELETE_TABLE_SELECTION'
export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_TASK_COMMENT = 'DELETE_TASK_COMMENT'
export const DELETE_TITLE = 'DELETE_TITLE'
export const DUPLICATE_TASK = 'DUPLICATE_TASK'
export const EDIT_TASK = 'EDIT_TASK'
export const EDIT_TITLE = 'EDIT_TITLE'
export const GET_ATTACHED_FILE_VERSIONS = 'GET_ATTACHED_FILE_VERSIONS'
export const POST_ATTACHED_FILE_VERSION = 'POST_ATTACHED_FILE_VERSION'
export const SHARE_TASKS = 'SHARE_TASKS'
export const SAVE_CHECKLIST = 'SAVE_CHECKLIST'
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST'
export const GET_SAVED_CHECKLISTS = 'GET_SAVED_CHECKLISTS'
export const GET_SELF_SAVED_CHECKLISTS = 'GET_SELF_SAVED_CHECKLISTS'
export const GET_SAVED_CHECKLIST_PREVIEW = 'GET_SAVED_CHECKLIST_PREVIEW'
export const GET_LEXISNEXIS_CHECKLISTS = 'GET_LEXISNEXIS_CHECKLISTS'
export const GET_LEXISNEXIS_CHECKLIST_PREVIEW = 'GET_LEXISNEXIS_CHECKLIST_PREVIEW'
export const IMPORT_CHECKLIST_FROM_CLOSD = 'IMPORT_CHECKLIST_FROM_CLOSD'
export const IMPORT_CHECKLIST_FROM_EXCEL = 'IMPORT_CHECKLIST_FROM_EXCEL'
export const GET_TASK_STATUS_CATEGORIES = 'GET_TASK_STATUS_CATEGORIES'
export const REFRESH_TASK = 'REFRESH_TASK'
export const POST_MOVE_TASK = 'POST_MOVE_TASK'
export const DELETE_TASK_MULTIPLE = 'DELETE_TASK_MULTIPLE'
export const GET_CHECKLIST = 'GET_CHECKLIST'
export const GET_REFRESH_CHECKLIST = 'GET_REFRESH_CHECKLIST'
export const GET_TASK_COMMENTS = 'GET_TASK_COMMENTS'
export const GET_TASK_FOLDER = 'GET_TASK_FOLDER'
export const GET_TASK_STATUSES = 'GET_TASK_STATUSES'
export const MOVE_FILDERS = 'MOVE_FILDERS'
export const OPEN_SAVE_CHECKLIST_DIALOG = 'OPEN_SAVE_CHECKLIST_DIALOG'
export const OPEN_SHARE_CHECKLIST_BY_EMAIL_PANE = 'OPEN_SHARE_CHECKLIST_BY_EMAIL_PANE'
export const POST_TASK_COMMENT = 'POST_TASK_COMMENT'
export const REMOVE_TASK_FROM_SELECTION = 'REMOVE_TASK_FROM_SELECTION'
export const REMOVE_TITLE_CHILDREN_FROM_SELECTION = 'REMOVE_TITLE_CHILDREN_FROM_SELECTION'
export const REMOVE_TITLE_FROM_SELECTION = 'REMOVE_TITLE_FROM_SELECTION'
export const RENAME_ATTACHED_FILE = 'RENAME_ATTACHED_FILE'
export const SHARE_ATTACHED_FILES = 'SHARE_ATTACHED_FILES'
export const SHARE_CHECKLIST_BY_EMAIL = 'SHARE_CHECKLIST_BY_EMAIL'
export const POST_STATUS = 'POST_STATUS'
export const TITLE_INDENTATION = 'TITLE_INDENTATION'
export const EXPAND_TITLE = 'EXPAND_TITLE'
export const EXPAND_ALL_TITLES = 'EXPAND_ALL_TITLES'
export const COLLAPSE_TITLE = 'COLLAPSE_TITLE'
export const COLLAPSE_ALL_TITLES = 'COLLAPSE_ALL_TITLES'
export const DELETE_SEARCH_RESULTS = 'DELETE_SEARCH_RESULTS'
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS'
export const GET_GLOBAL_TASK_FOLDER = 'GET_GLOBAL_TASK_FOLDER'
export const DOWNLOAD_TODO_FILES = 'DOWNLOAD_TODO_FILES'
export const CHANGE_CHECKLIST_VERSIONING_PANE_TAB = 'CHANGE_CHECKLIST_VERSIONING_PANE_TAB'
export const SET_CHECKLIST_VERSIONING_PANE = 'SET_CHECKLIST_VERSIONING_PANE'
export const CLOSE_CHECKLIST_VERSIONING_PANE = 'CLOSE_CHECKLIST_VERSIONING_PANE'
