export const metadata = (state) => state.config?.metadata
export const sections = (state) => state.config?.sections

export const selectedDocumentsSize = (state) => {
    return state.selectedDocuments.reduce(
      function (accSum, item) {
        return accSum + parseInt(item.docSize)
      },
    0)
}
