<template>
  <div>
    <v-data-table
      v-model="computedSelectedItems"
      class="checklist-global-attached-files-dialog-table"
      :items="items"
      :headers="headers"
      :footer-props="{
        'items-per-page-options': ITEM_PER_PAGE_DEFAULT,
        showCurrentPage: true,
      }"
      show-select
      item-key="id"
    >
      <template v-slot:item="props">
        <tr data-locator="checklist-file-row">
          <td v-for="header in props.headers" :key="header.value">
            <template v-if="header.value === 'data-table-select'">
              <v-checkbox
                :input-value="props.isSelected"
                hide-details
                @click.stop="props.select(!props.isSelected)"
              ></v-checkbox>
            </template>
            <template v-else-if="header.value === 'basename'">
              <div class="d-flex align-center column-gap-1">
                <ClosdFildersIcon :document="props.item" />
                <span class="filderName">{{ props.item.basename }}</span>
                <app-badge v-if="props.item.version > 1">{{ `v${props.item.version}` }}</app-badge>
              </div>
            </template>
            <template v-else-if="header.value === 'parent'">
              <span>{{ props.item.parent.name }}</span>
            </template>
            <template v-else-if="header.value === 'rights'">
              <div v-if="isCurrentUserPm" style="max-width: 120px">
                <RightsDisplayButton :rights="props.item.rights" @click="onShareButtonClick(props.item)" />
              </div>
            </template>
            <template v-else-if="header.value === 'size'">
              <app-filesize-display :value="props.item.size" />
            </template>
            <template v-else-if="header.value === 'updatedBy'">
              <span>{{ props.item.updateUser }}</span>
            </template>
            <template v-else-if="header.value === 'updatedAt'">
              <span>{{ fileDate(props.item.updateTime) }}</span>
            </template>
            <template v-else-if="header.value === 'actions'">
              <div class="d-flex align-center column-gap-2">
                <OfficeOnlineEditionButton v-if="isOfficeEditionButtonShowed(props.item)" :document="props.item"/>
                <AppDownloadFile :link="fileLink(props.item.id)">
                  <template #default="{ submitForm }">
                    <app-button type="icon" data-locator="download-button" @click="submitForm">
                      <app-icon icon-weight="fas" icon-name="download" />
                    </app-button>
                  </template>
                </AppDownloadFile>
                <ChecklistTableTaskAttachedFilesItemMenu :mnemo="mnemo" :file="props.item" />
              </div>
            </template>
            <template v-else>
              {{ props.item[header.value] }}
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { cloneDeep } from 'lodash-es'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile.vue'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon.vue'
import OfficeOnlineEditionButton from '@/common/office/OfficeOnlineEditionButton.vue'
import RightsDisplayButton from '@/common/users/RightsDisplayButton.vue'
import { ISOToShortenedDate } from '@/common/utils/dates'
import { SET_TABLE_TASK_PANE } from '@/store/modules/checklist/mutation_types'
import { INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS } from '@/store/modules/document-share-picker/action_types'

import ChecklistTableTaskAttachedFilesItemMenu from '../task/ChecklistTableTaskAttachedFilesItemMenu.vue'

const ITEM_PER_PAGE_DEFAULT = [10, 25, 50]

export default defineComponent({
  components: {
    AppDownloadFile,
    ChecklistTableTaskAttachedFilesItemMenu,
    ClosdFildersIcon,
    OfficeOnlineEditionButton,
    RightsDisplayButton,
  },
    name: 'ChecklistGlobalAttachedFilesDialogTable',
    props: {
      items: {
        type: Array,
        required: true,
      },
      // Selected items model
      value: {
        type: Array,
        default: () => [],
      },
      mnemo: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        headers: [
          {
            value: 'basename',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.filename'),
          },
          {
            value: 'parent',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.taskParent'),
            sort (a, b) {
              return a?.name?.localeCompare(b?.name)
            },
          },
          {
            value: 'rights',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.rights'),
            sortable: false,
          },
          {
            value: 'size',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.size'),
          },
          {
            value: 'updatedBy',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.updatedBy'),
          },
          {
            value: 'updatedAt',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.updatedAt'),
          },
          {
            value: 'actions',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.actions'),
            sortable: false,
          },
        ],
        ITEM_PER_PAGE_DEFAULT,
      }
    },
    computed: {
      ...mapGetters('room', ['isCurrentUserPm', 'isWopiEnabled']),
      computedSelectedItems: {
        get (): Array<unknown> {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    methods: {
      ...mapActions('documentSharePicker', [INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS]),
      ...mapMutations('checklist', [SET_TABLE_TASK_PANE]),
      onShareButtonClick (item) {
        this.INIT_SELECTED_GROUPS(cloneDeep(item.rights.read.groups))
        this.INIT_SELECTED_MEMBERS(cloneDeep(item.rights.read.users))
        this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
          isOpen: true,
          tab: 'share',
          documents: [item],
          parent: item.parent.id,
        })
      },
      fileDate (updateDate) {
        return ISOToShortenedDate(updateDate)
      },
      fileLink (fileId) {
        return `${axios.defaults.baseURL}/room/${this.mnemo}/downloads/file/${fileId}`
      },
      isOfficeEditionButtonShowed (document) {
        return this.isWopiEnabled && document.canEdit && this.isCurrentUserPm
      },
    },
  })
</script>

<style scoped lang="scss">
.filderName {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-input--selection-controls {
  margin: auto;
}
</style>
