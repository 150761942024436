import { SurveyId, surveyCanBeTriggered, triggerSurvey } from '@/plugins/satismeter'

import { QuestionAnswerActionTypes } from './action_types'
import { QuestionAnswerMutationTypes } from './mutation_types'
import service from '../../../services/questionAnswer.service'

export const actions = {
  async [QuestionAnswerActionTypes.IMPORT_QUESTIONS] ({ commit, state }, { mnemo, formData }) {
    commit(QuestionAnswerMutationTypes.SET_IMPORT_QUESTIONS_PENDING, true)
    try {
      const { data } = await service.importQuestions(mnemo, formData)

      commit(QuestionAnswerMutationTypes.SET_QUESTIONS, {
        ...state.questions,
        questions: [...state.questions.questions, ...data.questions],
        waiting: state.questions.waiting + data.questions.length,
      })
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_IMPORT_QUESTIONS_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_IMPORT_QUESTIONS_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.PATCH_QUESTION_RIGHTS] ({ commit }, { mnemo, questionId, data }) {
    commit(QuestionAnswerMutationTypes.SET_PATCH_QUESTION_RIGHTS_PENDING, true)
    try {
      await service.patchQuestionRights(mnemo, questionId, data)
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_PATCH_QUESTION_RIGHTS_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_PATCH_QUESTION_RIGHTS_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.QUESTIONS_BULK_RIGHTS] ({ commit }, { mnemo, data }) {
    commit(QuestionAnswerMutationTypes.SET_QUESTIONS_BULK_RIGHTS_PENDING, true)
    try {
      await service.questionsBulkRights(mnemo, data)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_QUESTIONS_BULK_RIGHTS_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.POST_ANSWER] ({ commit }, { mnemo, question, data }) {
    commit(QuestionAnswerMutationTypes.SET_POST_ANSWER_PENDING, true)
    try {
      return await service.postAnswer(mnemo, question.id, data)
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_POST_ANSWER_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_POST_ANSWER_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.GET_QUESTION] ({ commit }, { mnemo, questionId }) {
    commit(QuestionAnswerMutationTypes.SET_QUESTION_PENDING, true)
    try {
      const response = await service.getQuestion(mnemo, questionId)
      commit(QuestionAnswerMutationTypes.SET_QUESTION, response.data.question)
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_QUESTION_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_QUESTION_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.POST_QUESTION] ({ commit, rootState }, { mnemo, data }) {
    commit(QuestionAnswerMutationTypes.SET_POST_QUESTION_PENDING, true)
    try {
      const promise = await service.postQuestion(mnemo, data)
      const { id: userId, fullName, email, isSubUser } = rootState.user.profile
      if (surveyCanBeTriggered(SurveyId.NPS, userId)) {
        triggerSurvey(SurveyId.NPS, userId, fullName, email, isSubUser)
      }
      return promise
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_POST_QUESTION_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_POST_QUESTION_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.UPDATE_QUESTION_IN_LIST] ({ commit }, { mnemo, questionId }) {
    commit(QuestionAnswerMutationTypes.SET_UPDATE_QUESTION_IN_LIST_PENDING, true)
    try {
      const response = await service.getQuestion(mnemo, questionId)
      const updatedQuestion = response.data.question
      commit(QuestionAnswerMutationTypes.SET_UPDATE_QUESTION_IN_LIST, updatedQuestion)
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_UPDATE_QUESTION_IN_LIST_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_UPDATE_QUESTION_IN_LIST_PENDING, false)
    }
  },
  [QuestionAnswerActionTypes.PATCH_QUESTION] ({ commit }, { mnemo, questionId, data }) {
    commit(QuestionAnswerMutationTypes.SET_PATCH_QUESTION_PENDING, true)
    try {
      return service.patchQuestion(mnemo, questionId, data)
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_PATCH_QUESTION_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_PATCH_QUESTION_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.GET_CATEGORIES] ({ commit }, mnemo) {
    commit(QuestionAnswerMutationTypes.SET_CATEGORIES_PENDING, true)
    try {
      const response = await service.getCategories(mnemo)
      commit(QuestionAnswerMutationTypes.SET_CATEGORIES, response.data.categories)
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_CATEGORIES_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_CATEGORIES_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.DELETE_QUESTION] ({ commit }, { mnemo, questionId }) {
    commit(QuestionAnswerMutationTypes.SET_DELETE_QUESTION_PENDING, true)
    try {
      return await service.deleteQuestion(mnemo, questionId)
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_DELETE_QUESTION_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_DELETE_QUESTION_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.GET_QUESTIONS] ({ commit }, mnemo) {
    commit(QuestionAnswerMutationTypes.SET_QUESTIONS_PENDING, true)
    try {
      const response = await service.getQuestions(mnemo)
      commit(QuestionAnswerMutationTypes.SET_QUESTIONS, response.data)
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_QUESTIONS_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_QUESTIONS_PENDING, false)
    }
  },
  async [QuestionAnswerActionTypes.MARK_QUESTION_AS_VALIDATED] ({ commit }, { mnemo, questionId }) {
    commit(QuestionAnswerMutationTypes.SET_MARK_QUESTION_AS_ANSWERED_PENDING, true)
    try {
      const response = await service.getQuestion(mnemo, questionId)

      if (response?.data?.question) {
        const question = {
          ...response.data.question,
          status: 'validated',
        }

        await service.patchQuestion(mnemo, questionId, question)
        commit(QuestionAnswerMutationTypes.SET_QUESTION, question)
      }
    } catch (error) {
      commit(QuestionAnswerMutationTypes.SET_PATCH_QUESTION_ERROR, error)
      throw error
    } finally {
      commit(QuestionAnswerMutationTypes.SET_MARK_QUESTION_AS_ANSWERED_PENDING, false)
    }
  },
}
