<template>
  <Pane :value="filtersPaneIsOpen"
        :tabs="tabs"
        :right="false"
        current-tab="filters"
        temporary
        class="ChecklistFiltersPane"
        @input="onPaneValueChanged"
  >
    <template #content-filters>
      <div v-if="filtersPaneIsOpen">
        <v-row>
          <v-col>
            <div class="font-weight-bold mb-2">{{ $t('project.checklist.ChecklistFiltersPane.completionDateFilterTitle') }}</div>
            <AppDatePicker v-model="localFilters.completionDate"
                          range
                          class="w-100"
            />
            <v-divider class="my-4"/>
          </v-col>
        </v-row>
        <div v-show="checklistResponsibles.length > 0">
          <div class="font-weight-bold mb-2">{{ $t('project.checklist.ChecklistFiltersPane.responsiblePartiesFilterTitle') }}</div>
          <div>
            <AppTextField v-if="checklistResponsibles.length >= 10"
                          v-model.trim="responsiblePartiesSearchQuery"
                          :label="$t('project.checklist.ChecklistFiltersPane.searchResponsibleParty')"
                          prepend-inner-icon="fal fa-magnifying-glass"
                          style="max-width: 100%"
                          class="ChecklistFiltersPane-searchInput"
            />
          </div>
          <div>
            <v-checkbox v-for="responsible in responsiblesToDisplay"
                        :key="`responsible-${responsible.key}`"
                        v-model="localFilters.responsibleParties"
                        :value="responsible"
                        :label="responsible.fullName || responsible.name"
                        hide-details
                        :ripple="false"
                        class="my-1"
            />
          </div>
          <div v-if="filteredChecklistResponsibles.length > maxResponsiblesToDisplay" class="mt-3">
            <button class="font-weight-medium text-decoration-underline black--text"
                    @click="maxResponsiblesToDisplay += 10">
              {{$t('project.checklist.ChecklistFiltersPane.seeMoreResponsibles')}}
            </button>
          </div>
          <v-divider class="my-4"/>
        </div>
        <div v-show="checklistValidators.length > 0">
          <div class="font-weight-bold mb-2">{{ $t('project.checklist.ChecklistFiltersPane.validatorsFilterTitle') }}</div>
          <div>
            <AppTextField v-if="checklistValidators.length >= 10"
                          v-model.trim="validatorsSearchQuery"
                          :label="$t('project.checklist.ChecklistFiltersPane.searchValidators')"
                          prepend-inner-icon="fal fa-magnifying-glass"
                          style="max-width: 100%"
                          class="ChecklistFiltersPane-searchInput"
            />
          </div>
          <div>
            <v-checkbox v-for="validator in validatorsToDisplay"
                        :key="`validators-${validator.key}`"
                        v-model="localFilters.validators"
                        :value="validator"
                        :label="validator.fullName || validator.name"
                        hide-details
                        :ripple="false"
                        class="my-1"
            />
          </div>
          <div v-if="filteredChecklistValidators.length > maxValidatorsToDisplay" class="mt-3">
            <button class="font-weight-medium text-decoration-underline black--text"
                    @click="maxValidatorsToDisplay += 10">
              {{$t('project.checklist.ChecklistFiltersPane.seeMoreValidators')}}
            </button>
          </div>
          <v-divider class="my-4"/>
        </div>
        <div v-show="checklistStatuses.length > 0">
          <div class="font-weight-bold mb-2">{{ $t('project.checklist.ChecklistFiltersPane.statusesFilterTitle') }}</div>
          <div>
            <v-checkbox v-for="(status) in checklistStatuses"
                        :key="`status-${status.id}`"
                        v-model="localFilters.statuses"
                        :label="status.label"
                        :ripple="false"
                        :value="status"
                        hide-details
                        class="my-1"
            />
          </div>
        </div>
        <v-row class="ChecklistFiltersPane-stickyButton-bar mt-4">
          <v-col>
            <AppButton
                       block
                       @click="onClearAllFilters"
            >
              {{ $t('project.checklist.ChecklistFiltersPane.resetFiltersButton') }}
            </AppButton>
          </v-col>
          <v-col>
            <AppButton color="primary"
                       block
                       @click="onApplyFilters"
            >
              {{ $t('project.checklist.ChecklistFiltersPane.applyFiltersButton') }}
            </AppButton>
          </v-col>
        </v-row>
      </div>
      <AppDialog :is-open="openCloseDialog"
                 :ok-text="$t('common.yes')"
                 :cancel-text="$t('common.no')"
                 @ok="onOkCloseDialog"
                 @cancel="onCancelCloseDialog"
      >
        <template #title>{{ $t('project.checklist.ChecklistFiltersPane.closeDialogTitle') }}</template>
        <template #body>
          <v-row>
            <v-col>
              {{ $t('project.checklist.ChecklistFiltersPane.closeDialogText') }}
            </v-col>
          </v-row>
        </template>
      </AppDialog>
    </template>
  </Pane>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import AppDatePicker from '@/common/AppDatePicker'
import AppTextField from '@/common/AppTextField'
import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import Pane from '@/common/Pane'
import { getStatusDetailsByTaskInformation } from '@/common/utils/checklist'
import { formatDateValue } from '@/common/utils/dates'
import { Filter } from '@/models/filter.model'
import {
  SET_CHECKLIST_FILTERS,
  SET_FILTERS_PANE_IS_OPEN,
  SET_SELECTED_TASKS,
  SET_SELECTED_TITLES,
} from '@/store/modules/checklist/mutation_types'

const EMPTY_FILTERS = {
  completionDate: [],
  responsibleParties: [],
  statuses: [],
  validators: [],
}

export default {
  name: 'ChecklistFiltersPane',
  components: { AppDialog, AppButton, AppDatePicker, AppTextField, Pane },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      localFilters: { ...EMPTY_FILTERS },
      maxResponsiblesToDisplay: 10,
      maxValidatorsToDisplay: 10,
      openCloseDialog: false,
      responsiblePartiesSearchQuery: '',
      tabs: [{ id: 'filters', title: this.$t('common.filters') }],
      validatorsSearchQuery: '',
    }
  },
  computed: {
    ...mapState('checklist', ['filters', 'filtersPaneIsOpen', 'taskStatusCategories']),
    ...mapGetters('checklist', ['flatChecklist', 'filteredChecklist']),
    filtersHasChange () {
      return this.localFilters?.completionDate.length !== this.filters.filter(filter => filter.category.key === 'completionDate').length ||
        this.localFilters?.responsibleParties.length !== this.filters.filter(filter => filter.category.key === 'responsibleParties').length ||
        this.localFilters?.validators.length !== this.filters.filter(filter => filter.category.key === 'validators').length ||
        this.localFilters?.statuses.length !== this.filters.filter(filter => filter.category.key === 'statuses').length
    },
    checklistResponsibles () {
      const responsibles = []
      for (const task of this.filteredChecklist) {
        if (task.rights) {
          for (const group of task.rights.tagged.groups) {
            if (!responsibles.some(el => el.id === group.id && el.members)) {
              responsibles.push({ ...group, key: `group-${group.id}` })
            }
          }
          for (const user of task.rights.tagged.users) {
            if (!responsibles.some(el => el.id === user.id && el.fullName === user.fullName)) {
              responsibles.push({ ...user, key: `user-${user.id}` })
            }
          }
        }
      }

      return responsibles
    },
    sortedChecklistResponsibles () {
      return [...this.checklistResponsibles].sort((a, b) => {
        if (a.fullName && b.fullName) {
          return a.fullName.localeCompare(b.fullName)
        } else if (a.fullName) {
          return a.fullName.localeCompare(b.name)
        } else if (b.fullName) {
          return a.name.localeCompare(b.fullName)
        } else {
          return a.name.localeCompare(b.name)
        }
      })
    },
    filteredChecklistResponsibles () {
      if (!this.responsiblePartiesSearchQuery) {
        return this.sortedChecklistResponsibles
      }

      return this.sortedChecklistResponsibles.filter(resp => {
        if (resp.fullName) {
          return resp.fullName.toLowerCase().includes(this.responsiblePartiesSearchQuery.toLowerCase())
        } else if (resp.name) {
          return resp.name.toLowerCase().includes(this.responsiblePartiesSearchQuery.toLowerCase())
        }
      })
    },
    responsiblesToDisplay () {
      return this.filteredChecklistResponsibles.slice(0, this.maxResponsiblesToDisplay)
    },
    checklistValidators () {
      const validators = []
      for (const task of this.filteredChecklist) {
        if (task.rights) {
          for (const group of task.rights.validators.groups) {
            if (!validators.some(el => el.id === group.id && el.members)) {
              validators.push({
                ...group,
                key: `group-${group.id}`,
              })
            }
          }
          for (const user of task.rights.validators.users) {
            if (!validators.some(el => el.id === user.id && el.fullName === user.fullName)) {
              validators.push({
                ...user,
                key: `user-${user.id}`,
              })
            }
          }
        }
      }

      return validators
    },
    sortedChecklistValidators () {
      return [...this.checklistValidators].sort((a, b) => {
        if (a.fullName && b.fullName) {
          return a.fullName.localeCompare(b.fullName)
        } else if (a.fullName) {
          return a.fullName.localeCompare(b.name)
        } else if (b.fullName) {
          return a.name.localeCompare(b.fullName)
        } else {
          return a.name.localeCompare(b.name)
        }
      })
    },
    filteredChecklistValidators () {
      if (!this.validatorsSearchQuery) {
        return this.sortedChecklistValidators
      }

      return this.sortedChecklistValidators.filter(val => {
        if (val.fullName) {
          return val.fullName.toLowerCase().includes(this.validatorsSearchQuery.toLowerCase())
        } else if (val.name) {
          return val.name.toLowerCase().includes(this.validatorsSearchQuery.toLowerCase())
        }
      })
    },
    validatorsToDisplay () {
      return this.filteredChecklistValidators.slice(0, this.maxValidatorsToDisplay)
    },
    checklistStatuses () {
      const statuses = []
      this.filteredChecklist.forEach(task => {
        const statusDetails =
          getStatusDetailsByTaskInformation(
            task.statusId,
            task.statusCategory,
            this.taskStatusCategories,
          )
        if (statusDetails && !statuses.find((status) => status.id === task.statusId)) {
          statuses.push(statusDetails)
        }
      })
      return statuses
    },
  },
  watch: {
    filteredChecklist () {
      this.syncAppliedFiltersWithLocals()
    },
    filtersPaneIsOpen (value) {
      if (value) {
        this.syncAppliedFiltersWithLocals()
      }
    },
  },
  methods: {
    ...mapMutations('checklist', [
      SET_CHECKLIST_FILTERS,
      SET_FILTERS_PANE_IS_OPEN,
      SET_SELECTED_TASKS,
      SET_SELECTED_TITLES,
    ]),
    applyFilters () {
      const completionDateFilters = this.localFilters.completionDate.length > 0
        ? [
          new Filter(
            'completionDate',
            this.$t('project.checklist.ChecklistFiltersPane.completionDateFilterTitle'),
            this.localFilters.completionDate,
            formatDateValue(this.localFilters.completionDate),
          )]
        : []
      const responsiblePartiesFilters = this.localFilters.responsibleParties.map(responsibleParty =>
        new Filter('responsibleParties', this.$t('project.checklist.ChecklistFiltersPane.responsiblePartiesFilterTitle'), responsibleParty.key, (responsibleParty?.fullName || responsibleParty?.name)),
      )
      const statusesFilters = this.localFilters.statuses.map(status =>
        new Filter('statuses', this.$t('project.checklist.ChecklistFiltersPane.statusesFilterTitle'), status.id, status.label),
      )
      const validatorsFilters = this.localFilters.validators.map(validator =>
        new Filter('validators', this.$t('project.checklist.ChecklistFiltersPane.validatorsFilterTitle'), validator.key, (validator?.fullName || validator?.name)),
      )

      const filters = [
        ...completionDateFilters,
        ...responsiblePartiesFilters,
        ...statusesFilters,
        ...validatorsFilters,
      ]

      this.SET_CHECKLIST_FILTERS(filters)
      this.SET_SELECTED_TASKS([])
      this.SET_SELECTED_TITLES([])
    },
    closePane () {
      this.SET_FILTERS_PANE_IS_OPEN(false)
    },
    onApplyFilters () {
      this.applyFilters()
      this.closePane()
    },
    onCancelCloseDialog () {
      this.openCloseDialog = false
      this.closePane()
    },
    onClearAllFilters () {
      this.localFilters = { ...EMPTY_FILTERS }
      this.applyFilters()
    },
    onOkCloseDialog () {
      this.openCloseDialog = false
      this.applyFilters()
      this.closePane()
    },
    onPaneValueChanged (isOpen) {
      if (!isOpen && this.filtersPaneIsOpen && this.filtersHasChange) {
        this.openCloseDialog = true
      } else if (!isOpen && this.filtersPaneIsOpen) {
        this.closePane()
      }
    },
    syncAppliedFiltersWithLocals () {
      const datesFilters = this.filters.find(filter => filter.category.key === 'completionDate')
      this.localFilters.completionDate = datesFilters?.value.key ?? []

      this.localFilters.responsibleParties = this.filters
        .filter(filter => filter.category.key === 'responsibleParties')
        .map(filter => this.checklistResponsibles.find(responsible => responsible.key === filter.value.key))
      this.localFilters.statuses = this.filters
        .filter(filter => filter.category.key === 'statuses')
        .map(filter => filter.value.key)
      this.localFilters.validators = this.filters
        .filter(filter => filter.category.key === 'validators')
        .map(filter => this.checklistValidators.find(validator => validator.key === filter.value.key))
    },
  },
}
</script>

<style scoped lang="scss">
  ::v-deep .v-icon.notranslate.fas.fa-caret-down.theme--light {
    display: none;
  }
  ::v-deep .ChecklistFiltersPane-searchInput .v-icon {
    font-size: 16px;
  }
  .ChecklistFiltersPane-stickyButton-bar {
    position: sticky;
    bottom: 0;
    background-color: #fff;
  }
  ::v-deep.ChecklistFiltersPane .pane-content {
    padding-bottom: 0;
    overflow-y: auto;
  }
</style>
