<template>
  <div class="SignedDocumentsMultiSelectBar px-6">
    <div class="text-h4">
      {{$tc('project.signed-documents.SignedDocumentsMultiSelectBar.fileCount', selection.length, {files: selection.length})}}
    </div>
      <div class="app-link text--primary ml-2"
           @click="$emit('deselectAll')"
      >
        {{$t('common.cancelSelection')}}
      </div>
      <v-spacer />
      <MultiSelectBarButton @click="onDownloadSignedDocumentsClick">
        <font-awesome-icon :icon="['fas', 'file-download']" class="mr-2"></font-awesome-icon>
        {{$t('common.download')}}
      </MultiSelectBarButton>
      <BibleGenerateDialog v-if="openBibleGenerateDialog"
                           :mnemo="$route.params.mnemo"
                           initial-tab="signatures"
                           :selected-signatures="selection"
                           :poll-after-post="false"
                           @close="onBibleGenerateDialogClose"
                           @selectedSignature="onSelectedSignatures"
      />
      <SignedDocumentsDownloadDialog v-if="openSingleFileDownloadDialog"
                                     :signedDocument="selection[0]"
                                     @close="openSingleFileDownloadDialog = false"
      />
    <MultiSelectBarButton
      @click="$emit('shareOriginal', selection)"
    >
      <font-awesome-icon :icon="['fas', 'share']" class="mr-2"></font-awesome-icon>
      {{$t('common.share')}}
    </MultiSelectBarButton>
    <ArchiveAddAccessMultiSelectBarButton :routeOptions="archiveAddRouteOptions" />
    <MultiSelectBarButton
      @click="deleteSignedDocumentModalIsOpen = true"
    >
      <font-awesome-icon :icon="['fas', 'trash']" class="mr-2"></font-awesome-icon>
      {{$t('common.delete')}}
    </MultiSelectBarButton>
    <DeleteSignedDocument :is-open.sync="deleteSignedDocumentModalIsOpen"
                          :documents="selection"
    />
    <PolyOfficeMultiSelectBarButtonExport
      v-if="polyOfficeEnabled"
      :selectionSize="documentSelectionSize"
      :selectionCount="selection.length"
      @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
    />
<!--  Post release  -->
<!--    <template v-if="imanageEnabledAndLogged">
      <AppButton plain
                 class="text-h4 SignedDocumentsMultiSelectBar-button"
                 :class="{'py-2': $vuetify.breakpoint.xsOnly}"
                 height="auto"
                 color="primary"
                 @click="exportToImanage"
      >
        <font-awesome-icon :icon="['fas', 'file-export']" class="mr-2"></font-awesome-icon>
        {{$t('project.signed-documents.SignedDocumentsMultiSelectBar.exportToImanage')}}
      </AppButton>
    </template>-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import ArchiveAddAccessMultiSelectBarButton from '@/common/archives/ArchiveAddAccessMultiSelectBarButton.vue'
import MultiSelectBarButton from '@/common/buttons/MultiSelectBarButton.vue'
import { SignedEnvExport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeMultiSelectBarButtonExport from '@/common/polyOffice/PolyOfficeMultiSelectBarButtonExport.vue'
import { getRoomRouteType } from '@/common/utils/rooms'
import { ROOM_BIBLES_ROUTE_NAME } from '@/router'
import { GET_EXPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import DeleteSignedDocument from './dialogs/DeleteSignedDocument'
import SignedDocumentsDownloadDialog from './dialogs/SignedDocumentsDownloadDialog.vue'
import BibleGenerateDialog from '../bibles/dialogs/BibleGenerateDialog'

export default {
  name: 'SignedDocumentsMultiSelectBar',
  components: {
    BibleGenerateDialog,
    DeleteSignedDocument,
    MultiSelectBarButton,
    SignedDocumentsDownloadDialog,
    ArchiveAddAccessMultiSelectBarButton,
    PolyOfficeMultiSelectBarButtonExport,
  },
  props: {
    selection: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      deleteSignedDocumentModalIsOpen: false,
      openBibleGenerateDialog: false,
      openSingleFileDownloadDialog: false,
    }
  },
  computed: {
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('room', ['roomMnemo']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    ...mapState('room', ['currentRoom']),
    documentSelectionSize () {
      return this.selection.reduce((accSum, item) => {
        return accSum + (item.signSize + item.proofSize)
      }, 0)
    },
    selectedSignatures: {
      get () {
        return this.selection
      },
      set (val) {
        this.$emit('selectedSignature', val)
      },
    },
    archiveAddRouteOptions () {
      return {
        params: {
          signedDocuments: this.selection,
        },
        query: {
          signedDocumentIds: this.selection.map((signedDocument) => signedDocument.id).toString(),
          fromMnemo: this.roomMnemo,
        },
      }
    },
  },
  methods: {
    ...mapActions('polyOffice', [GET_EXPORT_IFRAME]),
    onBibleGenerateDialogClose () {
      this.$emit('deselectAll')
      this.openBibleGenerateDialog = false
    },
    exportToImanage () {
      this.$emit('exportToImanage', this.selection.map(item => item.id))
    },
    onDownloadSignedDocumentsClick () {
      if (this.selection.length > 1) {
        this.openBibleGenerateDialog = true
      } else if (this.selection.length === 1) {
        this.openSingleFileDownloadDialog = true
      }
    },
    onSelectedSignatures (selectedSignatures) {
      this.selectedSignatures = selectedSignatures
      this.$router.push({ name: ROOM_BIBLES_ROUTE_NAME, params: { poll: true, roomType: getRoomRouteType(this.currentRoom) } })
    },
    preparePolyOfficePayload () {
      const signedEnvIds = this.selection.reduce((acc, item) => {
        return [
          ...acc,
          item.id,
        ]
      }, [])

      return new SignedEnvExport(this.roomMnemo, signedEnvIds)
    },
    async polyOfficeOpenDialogHandler () {
      const payload = this.preparePolyOfficePayload()
      try {
        await this.GET_EXPORT_IFRAME({
          payload,
          successMessage: this.$t('project.signed-documents.SignedDocumentsMultiSelectBar.polyOffice.exportSuccessful'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .SignedDocumentsMultiSelectBar {
    display: flex;
    align-items: center;
    border: 1px solid var(--v-primary-base);
    border-radius: 8px;
    flex-wrap: wrap;
  }
  ::v-deep .SignedDocumentsMultiSelectBar-button {
    span.v-btn__content {
      opacity: 1 !important;
    }
  }
</style>
