import {
  GET_FILE_LOGS,
  GET_FOLDER_LOGS,
  GET_ROOM_LOGS,
  GET_LOGS_TYPES,
} from './action_types'
import {
  SET_FILE_LOGS_PENDING,
  SET_FILE_LOGS_ERROR,
  SET_FOLDER_LOGS_PENDING,
  SET_FOLDER_LOGS_ERROR,
  SET_ROOM_LOGS,
  SET_ROOM_LOGS_PENDING,
  SET_ROOM_LOGS_ERROR,
  SET_LOGS_TYPES,
  SET_LOGS_TYPES_PENDING,
  SET_LOGS_TYPES_ERROR,
} from './mutation_types'
import service from '../../../services/logs.service'

export const actions = {
  async [GET_LOGS_TYPES] ({ commit }, mnemo) {
    commit(SET_LOGS_TYPES_PENDING, true)
    try {
      const response = await service.getLogsTypes(mnemo)
      commit(SET_LOGS_TYPES, response.data.types)
    } catch (error) {
      commit(SET_LOGS_TYPES_ERROR, error)
      throw error
    } finally {
      commit(SET_LOGS_TYPES_PENDING, false)
    }
  },
  async [GET_ROOM_LOGS] ({ commit }, { mnemo, params }) {
    commit(SET_ROOM_LOGS_PENDING, true)
    try {
      const response = await service.getRoomLogs(mnemo, { params: params })
      commit(SET_ROOM_LOGS, response.data)
    } catch (error) {
      commit(SET_ROOM_LOGS_ERROR, error)
      throw error
    } finally {
      commit(SET_ROOM_LOGS_PENDING, false)
    }
  },
  async [GET_FOLDER_LOGS] ({ commit }, { mnemo, folderId }) {
    commit(SET_FOLDER_LOGS_PENDING, true)
    try {
      return await service.getFolderLogs(mnemo, folderId)
    } catch (error) {
      commit(SET_FOLDER_LOGS_ERROR, error)
      throw error
    } finally {
      commit(SET_FOLDER_LOGS_PENDING, false)
    }
  },
  async [GET_FILE_LOGS] ({ commit }, { mnemo, fileId }) {
    commit(SET_FILE_LOGS_PENDING, true)
    try {
      return await service.getFileLogs(mnemo, fileId)
    } catch (error) {
      commit(SET_FILE_LOGS_ERROR, error)
      throw error
    } finally {
      commit(SET_FILE_LOGS_PENDING, false)
    }
  },
}
