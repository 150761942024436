import { canShowReadOnlyStep, filterSigningChecklistTasks } from '@/common/utils/signingChecklist'
import { ENVELOPE_STATUS } from '@/project/signing-checklist/constants'

export const getters = {
  flatSigningChecklist: state => {
    return flatten([], state.signingChecklist)
  },
  filteredSigningChecklist: (state, getters) => {
    return filterSigningChecklistTasks(getters.flatSigningChecklist, state.filters, state.searchQuery)
  },
  hasActiveFilter: state => {
    return flatten([], state.filters).length > 0
  },
  isSigningChecklistFiltered: (state, getters) => {
    return getters.hasActiveFilter || state.searchQuery.length > 0
  },
  importedFilesInDraft: state => {
    return state.importedFiles.filter(importedFile => importedFile.objectToPost?.envelope?.status === ENVELOPE_STATUS.DRAFT || (canShowReadOnlyStep(importedFile.objectToPost) && state.importedFiles.length === 1))
  },
  queuedDocuments: state => Object.values(state.envelopeDocumentsUploadQueue),
  queuedDocumentsToUpload: (state, getters) => getters.queuedDocuments
    .filter(queuedDocument => queuedDocument.status === 'queued'),
  documentsCurrentlyUploading: (state, getters) => getters.queuedDocuments
    .filter(queuedDocument => queuedDocument.status === 'uploading'),
}

function flatten (into, node) {
  if (node == null) return into
  if (Array.isArray(node)) return node.reduce(flatten, into)
  into.push(node)
  return flatten(into, node.children)
}
