<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-loading="deleteEnvelopeTemplatesPending"
             :cancel-disabled="deleteEnvelopeTemplatesPending"
             :ok-text="$t('common.delete')"
             @ok="prepareDeleteEnvelopeTemplates"
             @cancel="closeDialog"
  >
    <template #title>
      <div class="d-flex align-center">
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-2" style="color: #eb8916"/>
        {{ $tc('project.signing-checklist.envelope-templates.dialogs.ConfirmDeleteDialog.title', envelopeTemplates.length) }}
      </div>
    </template>
    <template #body>
      <template v-if="isSingleDelete">
        {{ $t('project.signing-checklist.envelope-templates.dialogs.ConfirmDeleteDialog.singleText', {envelopeTemplateName: envelopeTemplates[0].title}) }}
      </template>
      <template v-else>
        {{ $t('project.signing-checklist.envelope-templates.dialogs.ConfirmDeleteDialog.multipleText', {count: envelopeTemplates.length}) }}
      </template>
    </template>
  </AppDialog>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { typedMapActions, typedMapMutations } from '@/common/utils/store'
import { dialogMixin } from '@/mixins/dialogMixin'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'ConfirmDeleteDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    envelopeTemplates: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('envelopeTemplates', ['deleteEnvelopeTemplatesPending']),
    isSingleDelete () {
      return this.envelopeTemplates.length === 1
    },
  },
  methods: {
    ...mapActions('envelopeTemplates', ['DELETE_ENVELOPE_TEMPLATES']),
    ...mapMutations(['ENQUEUE_ERROR_SNACKBAR']),
    async prepareDeleteEnvelopeTemplates () {
      try {
        await this.DELETE_ENVELOPE_TEMPLATES({
          ids: this.envelopeTemplates.map(e => e.id),
        })
        this.closeDialog()
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$tc('project.signing-checklist.envelope-templates.dialogs.ConfirmDeleteDialog.deleteEnvelopeTemplateError', this.envelopeTemplates.length))
      }
    },
  },
}
</script>
