<template>
  <span>
    <AppTooltip v-if="computedStatus && useTooltip" bottom>
      <template v-slot:activator="{ attrs, on }">
        <font-awesome-icon :icon="computedStatus.icon"
                           :color="computedStatus.color"
                           v-bind="attrs"
                           style="width:20px; height: 20px;"
                           v-on="on"
        ></font-awesome-icon>
      </template>
      <span>{{computedStatus.tooltip}}</span>
    </AppTooltip>
    <span v-else-if="computedStatus">
      <font-awesome-icon :icon="computedStatus.icon"
                         :color="computedStatus.color"
                         style="width:20px; height: 20px;"
      ></font-awesome-icon>
    </span>
  </span>
</template>

<script>
import colors from 'vuetify/lib/util/colors'

import AppTooltip from '@/common/AppTooltip'
import { QA_STATUS } from '@/common/utils/qa-status'
// Use colors from default theme
// https://vuetifyjs.com/en/styles/colors/#material-colors

export default {
  name: 'QuestionAnswerStatusIcon',
  components: { AppTooltip },
  props: {
    status: {
      type: String,
      required: true,
    },
    useTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      QA_STATUS,
    }
  },
  computed: {
    computedStatus () {
      if (this.status === QA_STATUS.TO_ANSWER) {
        return {
          icon: ['fas', 'clock'],
          color: colors.orange.base,
          tooltip: this.$t('project.question-answer.QuestionAnswerStatusIcon.toAnswer'),
        }
      }

      if (this.status === QA_STATUS.TO_VALIDATE) {
        return {
          icon: ['far', 'hourglass-start'],
          color: colors.blue.base,
          tooltip: this.$t('project.question-answer.QuestionAnswerStatusIcon.toValidate'),
        }
      }

      if (this.status === QA_STATUS.VALIDATED) {
        return {
          icon: ['fas', 'circle-check'],
          color: colors.green.base,
          tooltip: this.$t('project.question-answer.QuestionAnswerStatusIcon.validated'),
        }
      }

      return null
    },
  },
}
</script>
