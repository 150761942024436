<template>
  <Pane v-model="documentsVersioningPane.isOpen"
        temporary
        :current-tab.sync="tab"
        :permanent="documentsVersioningPane.isOpen"
        :tabs="tabs"
        @input="onClosePane"
  >
    <template #content-manage>
      <DocumentVersioning :loading="postVersionFromImanagePending || versionsLoading"
                          :selected-documents="documentsVersioningPane.documents"
                          @add-version="onAddVersion"
                          @add-version-from-imanage="onAddVersionFromImanage"
                          @close-pane="onClosePane"
      />
    </template>
    <template #content-history>
      <DocumentVersioningHistory :selected-documents="documentsVersioningPane.documents"
                                 @share-version="onShareVersion"
      />
    </template>
  </Pane>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DocumentVersioning from '@/common/document-versioning/DocumentVersioning'
import DocumentVersioningHistory from '@/common/document-versioning/DocumentVersioningHistory'
import Pane from '@/common/Pane'
import {
  ADD_VERSION,
  CHANGE_DOCUMENTS_VERSIONING_PANE_TAB,
  CLOSE_DOCUMENTS_VERSIONING_PANE,
  DESELECT_ALL_DOCUMENTS,
  GET_DOCUMENTS_BY_ID,
  POST_VERSION_FROM_IMANAGE,
  SET_TABLE_DOCUMENTS_PANE,
} from '@/store/modules/documents/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

const TRANSLATION_KEY_PREFIX = 'project.documents.pane.DocumentsVersioningPane'

export default defineComponent({
  name: 'DocumentsVersioningPane',
  components: {
    DocumentVersioning,
    DocumentVersioningHistory,
    Pane,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm', 'currentUserRights']),
    ...mapState('documents', ['documentsVersioningPane', 'numberingEnabled', 'postVersionFromImanagePending', 'versionsLoading']),
    canManageVersions () {
      return this.isCurrentUserPm || this.currentUserRights.canUploadVersions
    },
    tab: {
      get () {
        return this.documentsVersioningPane.tab
      },
      set (val) {
        this.CHANGE_DOCUMENTS_VERSIONING_PANE_TAB(val)
      },
    },
    tabs () {
      const tabs = []
      if (this.documentsVersioningPane.documents.length === 1 && this.documentsVersioningPane.documents[0].type === 'file') {
        if (this.canManageVersions) {
          tabs.push({
            title: this.$t(`${TRANSLATION_KEY_PREFIX}.tabManage`),
            id: 'manage',
          })
        }
        if (this.canManageVersions || this.documentsVersioningPane.documents[0].nbVersions > 1) {
          tabs.push({
            title: this.canManageVersions ? this.$t(`${TRANSLATION_KEY_PREFIX}.tabHistory`) : this.$t(`${TRANSLATION_KEY_PREFIX}.tabHistoryGuests`),
            id: 'history',
          })
        }
      }
      return tabs
    },
  },
  methods: {
    ...mapActions('documents', [
      ADD_VERSION,
      CHANGE_DOCUMENTS_VERSIONING_PANE_TAB,
      CLOSE_DOCUMENTS_VERSIONING_PANE,
      DESELECT_ALL_DOCUMENTS,
      GET_DOCUMENTS_BY_ID,
      POST_VERSION_FROM_IMANAGE,
      SET_TABLE_DOCUMENTS_PANE,
    ]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async onAddVersion (data) {
      try {
        await this.ADD_VERSION(data)

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t(`${TRANSLATION_KEY_PREFIX}.addVersionFromClosdSuccess`))

        this.GET_DOCUMENTS_BY_ID({ queryObject: { markRecent: true } })

        this.onClosePane()
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t(`${TRANSLATION_KEY_PREFIX}.addVersionFromClosdError`))
      }
    },
    async onAddVersionFromImanage (data) {
      try {
        await this.POST_VERSION_FROM_IMANAGE({
          mnemo: this.mnemo,
          data: data,
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t(`${TRANSLATION_KEY_PREFIX}.addVersionFromImanageSuccess`))

        this.GET_DOCUMENTS_BY_ID({ queryObject: { markRecent: true } })

        this.onClosePane()
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t(`${TRANSLATION_KEY_PREFIX}.addVersionFromImanageError`))
      }
    },
    onClosePane (value) {
      if (!value) {
        this.DESELECT_ALL_DOCUMENTS()
        this.CLOSE_DOCUMENTS_VERSIONING_PANE()
      }
    },
    onShareVersion (data) {
      this.CLOSE_DOCUMENTS_VERSIONING_PANE()
      this.SET_TABLE_DOCUMENTS_PANE({ isOpen: true, documents: [data], tab: 'share', forcePmSelection: true, previousDocument: this.documentsVersioningPane.documents[0] })
    },
  },
})
</script>
<style lang="scss" scoped>
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .v-navigation-drawer {
    min-width: 480px !important;
  }
}
</style>
