<template>
  <div>
      <v-row>
      <v-col cols="12" sm="6">
        <div class="text-h1">{{$t('project.documents.DocumentsHeader.documents')}}</div>
        <p class="mt-3 mb-0 body-1 accent--text text--lighten-2">
          {{$t('project.documents.DocumentsHeader.storeAndExchangeDocuments')}}
          <AppInfoTooltip>
            <div>{{$t('project.documents.DocumentsHeader.documentsPageInfo1')}}</div>
            <div>{{$t('project.documents.DocumentsHeader.documentsPageInfo2')}}</div>
          </AppInfoTooltip>
        </p>
      </v-col>
    </v-row>
    <div class="noData"
    >
      <div class="text-center">
        <v-row>
          <v-col>
            <v-img src="/img/documentIllustration.svg" width="325" class="mx-auto"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="noDataText">{{ $t('project.documents.Documents.noData') }}</div>
          </v-col>
        </v-row>
        <v-row v-if="displayAddMenu" class="mx-auto" style="width:fit-content">
          <v-col
            >
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
                  <v-tooltip top :disabled="!showSizeExceededTooltip">
                    <template #activator="{attrs: tooltipAttrs, on: tooltipOn}">
                      <div
                        v-bind="tooltipAttrs"
                        v-on="tooltipOn"
                      >
                        <AppButton
                          color="primary"
                          :disabled="showSizeExceededTooltip"
                          v-bind="menuAttrs"
                          v-on="menuOn"
                        >
                          {{ $t("common.add") }}
                          <v-icon right>fal fa-chevron-down</v-icon>
                        </AppButton>
                      </div>
                    </template>
                    {{ $t('project.documents.DocumentsHeader.sizeExceededTooltip') }}
                  </v-tooltip>
                </template>

                <v-list>
                  <v-list-item v-if="currentUserRights.canCreateFolders"
                               @click="OPEN_DOCUMENTS_UPLOAD_FILES"
                  >
                    <v-list-item-title>{{
                      $t("project.documents.DocumentsHeader.dropdownIcon.addFoldersOrFiles")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-else-if="currentUserRights.canUpload"
                               @click="OPEN_DOCUMENTS_UPLOAD_FILES"
                  >
                    <v-list-item-title>{{
                        $t("project.documents.DocumentsHeader.dropdownIcon.addFiles")
                      }}</v-list-item-title>
                  </v-list-item>
                  <PolyOfficeListItemImport
                    v-if="isImportFromPolyMenuDisplayed"
                    @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
                  />
                  <v-list-item v-if="(currentRoom.isPM || currentUserRights.canCreateFolders)" @click="openDocumentsCreateNewFolder">
                    <v-list-item-title>{{
                      $t("project.documents.DocumentsHeader.dropdownIcon.newFolder")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col>
            <AppButton
            v-if="currentRoom.isPM" color="primary" outlined @click="existingTreeDialogIsOpen = true"
            >
            {{ $t("project.documents.DocumentsHeader.dropdownIcon.existingTree") }}
            </AppButton>
          </v-col>
        </v-row>
        <DocumentsExistingTreeDialog :is-open.sync="existingTreeDialogIsOpen"
                                 :mnemo="mnemo"
        />
        <PolyOfficeDialog v-if="polyOfficeEnabled && dialogIsOpen" @callback="polyOfficeDialogCallback"/>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppInfoTooltip from '@/common/AppInfoTooltip'
import { DocumentsImport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeDialog, { POLY_OFFICE_DIALOG_STATUS_SUCCESS } from '@/common/polyOffice/PolyOfficeDialog.vue'
import PolyOfficeListItemImport from '@/common/polyOffice/PolyOfficeListItemImport.vue'
import DocumentsExistingTreeDialog from '@/project/documents/dialogs/DocumentsExistingTreeDialog'
import { OPEN_DOCUMENTS_UPLOAD_FILES } from '@/store/modules/documents-upload-files/action_types'
import { GET_IMPORT_IFRAME } from '@/store/modules/poly-office/action_types'

import AppButton from '../../common/buttons/AppButton'

export default {
  name: 'DocumentsEmptyState',
  components: {
    AppInfoTooltip,
    AppButton,
    PolyOfficeListItemImport,
    DocumentsExistingTreeDialog,
    PolyOfficeDialog,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      existingTreeDialogIsOpen: false,
    }
  },
  computed: {
    ...mapState('documents', ['documents']),
    ...mapGetters('room', ['isStorageFull']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['currentUserRights']),
    ...mapState('polyOffice', ['dialogIsOpen', 'dialogSuccess']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    ...mapGetters('documents', ['isRootFolder']),
    showSizeExceededTooltip () {
      return this.isStorageFull
    },
    isImportFromPolyMenuDisplayed () {
      return this.polyOfficeEnabled &&
        (this.currentRoom.isPM || this.currentUserRights.canUpload)
    },
    displayAddMenu () {
      return (
        this.isRootFolder &&
        (
          this.currentRoom.isPM ||
          this.currentUserRights.canUpload ||
          this.currentUserRights.canCreateFolders
        )
      )
    },
  },
  methods: {
    ...mapActions('documentsUploadFiles', [OPEN_DOCUMENTS_UPLOAD_FILES]),
    ...mapActions('documentsCreateNewFolder', ['openDocumentsCreateNewFolder']),
    ...mapActions('polyOffice', [
      GET_IMPORT_IFRAME,
    ]),
    async polyOfficeOpenDialogHandler () {
      const payload = new DocumentsImport(
        this.mnemo,
        this.documents.id,
      )
      await this.GET_IMPORT_IFRAME({
        payload,
        successMessage: this.$t('project.documents.DocumentsHeader.polyOffice.importSuccessful'),
      })
    },
    polyOfficeDialogCallback (data) {
      if (data.status === POLY_OFFICE_DIALOG_STATUS_SUCCESS) {
        this.refreshDocuments()
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.dialogSuccess,
        })
      }
    },
  },
}
</script>
<style scoped>
.noData {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}
</style>
