<template>
  <div>
    <template v-if="lexisNexisChecklistsPending">
      <v-skeleton-loader type="table-thead, table-tbody"/>
    </template>
    <template v-else>
      <v-row v-if="lexisNexisChecklists.length" class="mb-2">
        <v-col>
          <div class="mb-5">
            {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.Description') }}
          </div>
          <div class="d-flex">
            <!-- Search for a checklist -->
            <SearchTextField v-model="search"
                             class="mb-6"
                             :tooltipText="$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.search')"
            />

            <!-- Dropdown menu for categories -->
            <AppSelect
              v-model="selectedCategory"
              :items="lexisNexisChecklistCategories"
              :label="$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.allCategories')"
              class="ml-4"
              dense
              outlined
              hide-details
              clearable
              style="max-width: 200px;"
            /><!-- max-width: 200px is the same max-width as SearchTextField -->
          </div>

          <v-row>
            <v-col md="7" sm="12">
              <v-data-table :headers="headers"
                            :items="tableItems"
                            :items-per-page="-1"
                            disable-pagination
                            hide-default-footer
                            style="border: 1px solid #EEEEEE;"
              >
                <template #body="{items, headers}">
                  <SavedChecklistTableBody :columns-count="headers.length"
                                           :items="items"
                                           isLexisNexisChecklist
                  />
                </template>
              </v-data-table>
            </v-col>
            <v-col md="5" sm="12">
              <SavedChecklistPreview isLexisNexisChecklist/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-else class="d-flex flex-column justify-center align-center" style="height: 500px;">
        <template v-if="!lexisNexisChecklistsError">
          <font-awesome-icon :icon="['fas', 'tasks']" size="xl"></font-awesome-icon>
          <div class="text-center mt-8">
            {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.noData') }}
          </div>
        </template>
        <template v-else>
          {{ $t('project.checklist.dialogs.ImportChecklistDialog.getSavedChecklistsError') }}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import { cloneDeep, debounce } from 'lodash-es'
  import { mapState, mapActions } from 'vuex'

  import AppSelect from '@/common/AppSelect'
  import SearchTextField from '@/common/SearchTextField.vue'
  import { ISOToShortenedDate } from '@/common/utils/dates'
  import { SET_SELECTED_LEXISNEXIS_CHECKLIST } from '@/store/modules/checklist/mutation_types'

  import SavedChecklistPreview from './SavedChecklistPreview.vue'
  import SavedChecklistTableBody from './SavedChecklistTableBody.vue'
  import { GET_LEXISNEXIS_CHECKLISTS } from '../../../store/modules/checklist/action_types'
  import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

  export default {
    name: 'LexisNexisChecklistTableTemplate',
    components: {
      AppSelect,
      SearchTextField,
      SavedChecklistPreview,
      SavedChecklistTableBody,
    },
    data () {
      return {
        searchQuery: '',
        selectedCategory: '',
      }
    },
    computed: {
      ...mapState('checklist', [
        'lexisNexisChecklistsPending',
        'lexisNexisChecklists',
        'lexisNexisChecklistsError',
        'selectedLexisNexisChecklist',
      ]),
      search: {
        get () {
          return this.searchQuery
        },
        set: debounce(function (val) {
          this.searchQuery = val
        }, 1000),
      },
      lexisNexisChecklistCategories () {
        const categoriesObject = this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.categories')
        return Object.values(categoriesObject)
      },
      headers () {
        return [
          {
            value: 'name',
            text: this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.name'),
            class: ['caption', 'font-weight-semi-bold'],
          },
          {
            value: 'category',
            sortable: false,
            text: this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.category'),
            class: ['caption', 'font-weight-semi-bold'],
          },
          {
            value: 'author',
            sortable: false,
            text: this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.author'),
          },
          {
            value: 'date',
            text: this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.date'),
            class: ['caption', 'font-weight-semi-bold'],
            width: 150,
          },
        ]
      },
      tableItems () {
        let itemsToDisplay = this.searchQuery ? this.filteredSearchResult : this.lexisNexisChecklists

        if (this.selectedCategory) {
          let isSavedChecklistIncluded = false
          const selectedCategoryIndex = this.lexisNexisChecklistCategories.findIndex(i => i === this.selectedCategory)
          itemsToDisplay = itemsToDisplay.filter((item) => {
            const matchesItems = item.category === selectedCategoryIndex
            if (matchesItems && item?.name === this.selectedLexisNexisChecklist?.name) {
              isSavedChecklistIncluded = true
            }
            return matchesItems
          })

          if (!isSavedChecklistIncluded) {
            this.$store.commit(`checklist/${SET_SELECTED_LEXISNEXIS_CHECKLIST}`, null)
          }
        }
        return itemsToDisplay
      },
      filteredSearchResult () {
        if (!this.searchQuery) return []
        let checklists = cloneDeep(this.lexisNexisChecklists)
        const query = this.searchQuery.toLowerCase()
        let isSavedChecklistIncluded = false

        checklists = checklists.filter(checklist => {
          const matchesSearch = checklist.name.toLowerCase().includes(query) ||
                                checklist.author.toLowerCase().includes(query) ||
                                ISOToShortenedDate(checklist.date).toLowerCase().includes(query)

          if (matchesSearch && checklist?.name === this.selectedLexisNexisChecklist?.name) {
            isSavedChecklistIncluded = true
          }

          return matchesSearch
        })

        if (!isSavedChecklistIncluded) {
          this.$store.commit(`checklist/${SET_SELECTED_LEXISNEXIS_CHECKLIST}`, null)
        }

        return checklists
      },
    },
    created () {
      this.loadData()
    },
    methods: {
      ...mapActions('checklist', [GET_LEXISNEXIS_CHECKLISTS]),
      async loadData () {
        try {
          await this.GET_LEXISNEXIS_CHECKLISTS()
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.dialogs.ImportChecklistDialog.getSavedChecklistsError'),
          })
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep .v-data-table-header {
    background:#EEEEEE;
  }
</style>
