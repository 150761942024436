import Vue from 'vue'

import {
  ADD_DELETE_SIGNER_PENDING_ID,
  REMOVE_DELETE_SIGNER_PENDING_ID,
  SET_AUTO_TAGS,
  SET_AUTO_TAGS_ERROR,
  SET_AUTO_TAGS_PENDING,
  SET_CHANGE_SIGNER_ERROR,
  SET_CHANGE_SIGNER_PENDING,
  SET_DELETE_SIGNER_ERROR,
  SET_DELETE_TAG_ERROR,
  SET_DELETE_TAG_PENDING,
  SET_DRAG_TAG_ACTIVE,
  SET_DRAG_TAG_ACTIVE_ERROR,
  SET_DRAG_TAG_ACTIVE_PENDING,
  SET_DUPLICATE_TAG_ERROR,
  SET_DUPLICATE_TAG_PENDING,
  SET_ENVELOPE_DISPLAY_INFORMATION,
  SET_ENVELOPE_DISPLAY_INFORMATION_ERROR,
  SET_ENVELOPE_DISPLAY_INFORMATION_PENDING,
  SET_LAST_SAVE,
  SET_PAGE_CONTENT,
  SET_PAGE_CONTENT_IS_LOADING,
  SET_PAGE_THUMB,
  SET_PAGE_THUMB_IS_LOADING,
  SET_PATCH_SIGNERS_ORDER_PENDING,
  SET_PATCH_TAG_ERROR,
  SET_PATCH_TAG_PENDING,
  SET_POST_AUTO_TAGS_ERROR,
  SET_POST_AUTO_TAGS_PENDING,
  SET_POST_SIGNERS_ERROR,
  SET_POST_SIGNERS_PENDING,
  SET_RESET_DOCUMENT_TO_PREPARE,
  SET_SIGNATURE_ORDER_ERROR,
  SET_TAGGING_INFORMATION,
  SET_TAGGING_INFORMATION_ERROR,
  SET_TAGGING_INFORMATION_PENDING,
  SET_TAG_ERROR,
  SET_TAG_PENDING,
  ADD_TAG_OVERLAP,
  REMOVE_TAG_OVERLAP_BY_ENVELOPE_ID,
  REMOVE_TAG_OVERLAP_BY_TAG_ID,
} from './mutation_types'

export const mutations = {
  [SET_ENVELOPE_DISPLAY_INFORMATION_PENDING] (state, value) {
    state.envelopeDisplayInformationPending = value
  },
  [SET_ENVELOPE_DISPLAY_INFORMATION] (state, value) {
    state.envelopeDisplayInformation = value
    state.pageContents = {}
    state.pageThumbs = {}
  },
  [SET_ENVELOPE_DISPLAY_INFORMATION_ERROR] (state, value) {
    state.envelopeDisplayInformationError = value
  },
  [SET_TAGGING_INFORMATION_PENDING] (state, value) {
    state.taggingInformationPending = value
  },
  [SET_TAGGING_INFORMATION] (state, value) {
    state.taggingInformation = value
  },
  [SET_TAGGING_INFORMATION_ERROR] (state, value) {
    state.taggingInformationError = value
  },
  [SET_PAGE_CONTENT_IS_LOADING] (state, { pageNumber, isLoading }) {
    if (state.pageContents[pageNumber]) {
      state.pageContents[pageNumber].isLoading = isLoading
    } else {
      Vue.set(state.pageContents, pageNumber, {
        data: null,
        isLoading: isLoading,
      })
    }
  },
  [SET_PAGE_CONTENT] (state, { pageNumber, content }) {
    if (state.pageContents[pageNumber]) {
      state.pageContents[pageNumber].data = content
    } else {
      Vue.set(state.pageContents, pageNumber, {
        data: content,
        isLoading: false,
      })
    }
  },
  [SET_PAGE_THUMB_IS_LOADING] (state, { pageNumber, isLoading }) {
    if (state.pageThumbs[pageNumber]) {
      state.pageThumbs[pageNumber].isLoading = isLoading
    } else {
      Vue.set(state.pageThumbs, pageNumber, {
        data: null,
        isLoading: isLoading,
      })
    }
  },
  [SET_PAGE_THUMB] (state, { pageNumber, thumb }) {
    if (state.pageThumbs[pageNumber]) {
      state.pageThumbs[pageNumber].data = thumb
    } else {
      Vue.set(state.pageThumbs, pageNumber, {
        data: thumb,
        isLoading: false,
      })
    }
  },
  [SET_AUTO_TAGS_PENDING] (state, value) {
    state.autoTagsPending = value
  },
  [SET_AUTO_TAGS] (state, value) {
    state.autoTags = value
  },
  [SET_AUTO_TAGS_ERROR] (state, value) {
    state.autoTagsError = value
  },
  [SET_POST_AUTO_TAGS_PENDING] (state, value) {
    state.postAutoTagsPending = value
  },
  [SET_POST_AUTO_TAGS_ERROR] (state, value) {
    state.postAutoTagsError = value
  },
  [SET_PATCH_TAG_PENDING] (state, value) {
    state.patchTagPending = value
  },
  [SET_PATCH_TAG_ERROR] (state, value) {
    state.patchTagError = value
  },
  [SET_TAG_PENDING] (state, value) {
    state.tagPending = value
  },
  [SET_TAG_ERROR] (state, value) {
    state.tagError = value
  },
  [SET_DELETE_TAG_PENDING] (state, value) {
    state.deleteTagPending = value
  },
  [SET_DELETE_TAG_ERROR] (state, value) {
    state.deleteTagError = value
  },
  [SET_DRAG_TAG_ACTIVE_PENDING] (state, value) {
    state.dragTagActivePending = value
  },
  [SET_DRAG_TAG_ACTIVE] (state, payload) {
    state.dragTagActive.value = payload.value
    state.dragTagActive.tag = payload.tag
  },
  [SET_DRAG_TAG_ACTIVE_ERROR] (state, value) {
    state.dragTagActiveError = value
  },
  [SET_PATCH_SIGNERS_ORDER_PENDING] (state, value) {
    state.patchSignersOrderPending = value
  },
  [SET_SIGNATURE_ORDER_ERROR] (state, value) {
    state.signatureOrderError = value
  },
  [SET_POST_SIGNERS_PENDING] (state, value) {
    state.postSignersPending = value
  },
  [SET_POST_SIGNERS_ERROR] (state, value) {
    state.postSignersError = value
  },
  [ADD_DELETE_SIGNER_PENDING_ID] (state, value) {
    state.deleteSignerPendingIds = state.deleteSignerPendingIds.concat(value)
  },
  [REMOVE_DELETE_SIGNER_PENDING_ID] (state, id) {
    state.deleteSignerPendingIds =
      state.deleteSignerPendingIds.filter(ids => ids === id)
  },
  [SET_DELETE_SIGNER_ERROR] (state, value) {
    state.deleteSignerError = value
  },
  [SET_CHANGE_SIGNER_PENDING] (state, value) {
    state.changeSignerPending = value
  },
  [SET_CHANGE_SIGNER_ERROR] (state, value) {
    state.changeSignerError = value
  },
  [SET_DUPLICATE_TAG_PENDING] (state, value) {
    state.duplicateTagPending = value
  },
  [SET_DUPLICATE_TAG_ERROR] (state, value) {
    state.duplicateTagError = value
  },
  [SET_LAST_SAVE] (state, value) {
    state.lastSave = value
  },
  [SET_RESET_DOCUMENT_TO_PREPARE] (state) {
    state.envelopeDisplayInformation = null
    state.taggingInformation = null
  },
  [ADD_TAG_OVERLAP] (state, data) {
    const index = state.tagsOverlaps.findIndex(item => item.envelopeId === data.envelopeId)
    if (index < 0) {
      state.tagsOverlaps.push(data)
    } else {
      state.tagsOverlaps.splice(index, 1, data)
    }
  },
  [REMOVE_TAG_OVERLAP_BY_ENVELOPE_ID] (state, envelopeId) {
    const index = state.tagsOverlaps.findIndex(item => item.envelopeId === envelopeId)
    if (index >= 0) {
      state.tagsOverlaps[index].tagIds = null
    }
  },
  [REMOVE_TAG_OVERLAP_BY_TAG_ID] (state, tagId) {
    state.tagsOverlaps = state.tagsOverlaps.map(item => {
      if (item.tagIds !== null) { item.tagIds = item.tagIds.filter(tagPair => !tagPair.includes(tagId)) }
      return item
    })
  },
}
