export const BULK_UPSERT_SIGNING_CHECKLIST_STEP = 'BULK_UPSERT_SIGNING_CHECKLIST_STEP'
export const CANCEL_CLOSING = 'CANCEL_CLOSING'
export const CANCEL_SCHEDULED_CLOSING = 'CANCEL_SCHEDULED_CLOSING'
export const CANCEL_STEP = 'CANCEL_STEP'
export const CANCEL_STEP_MULTIPLE = 'CANCEL_STEP_MULTIPLE'
export const CHANGE_PANE_TAB = 'CHANGE_PANE_TAB'
export const CLOSE_PANE = 'CLOSE_PANE'
export const COMPILE_FINAL_FILE = 'COMPILE_FINAL_FILE'
export const CREATE_GROUP = 'CREATE_GROUP'
export const CREATE_SIGNING_CHECKLIST_STEP = 'CREATE_SIGNING_CHECKLIST_STEP'
export const CREATE_TITLE = 'CREATE_TITLE'
export const DELETE_COMPILED_FILE = 'DELETE_COMPILED_FILE'
export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_SEARCH_RESULTS = 'DELETE_SEARCH_RESULTS'
export const DELETE_STEP = 'DELETE_STEP'
export const DELETE_STEP_MULTIPLE = 'DELETE_STEP_MULTIPLE'
export const DELETE_TITLE = 'DELETE_TITLE'
export const DELETE_VALIDATION = 'DELETE_VALIDATION'
export const GET_CLOSING_PREDICT = 'GET_CLOSING_PREDICT'
export const GET_CLOSING_SUMMARY = 'GET_CLOSING_SUMMARY'
export const GET_DELETION_TYPE = 'GET_DELETION_TYPE'
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS'
export const GET_SIGNATURE_FILES = 'GET_SIGNATURE_FILES'
export const GET_SIGNING_CHECKLIST = 'GET_SIGNING_CHECKLIST'
export const GET_SIGNING_CHECKLIST_GROUP = 'GET_SIGNING_CHECKLIST_GROUP'
export const GET_SIGNING_VIEW = 'GET_SIGNING_VIEW'
export const GET_STEP = 'GET_STEP'
export const MOVE_GROUP = 'MOVE_GROUP'
export const MOVE_STEP = 'MOVE_STEP'
export const MUTE_QUALIFIED_DS_SIGNATURE_WARNING = 'MUTE_QUALIFIED_DS_SIGNATURE_WARNING'
export const OPEN_SHARE_ATTACHMENT_FILE_PANE = 'OPEN_SHARE_ATTACHMENT_FILE_PANE'
export const PATCH_COMPILED_FILE = 'PATCH_COMPILED_FILE'
export const POST_FINAL_FILE = 'POST_FINAL_FILE'
export const POST_LAST_COMPILED_FILE = 'POST_LAST_COMPILED_FILE'
export const POST_MOVE_STEP = 'POST_MOVE_STEP'
export const POST_SIGNED_FILE = 'POST_SIGNED_FILE'
export const POST_VALIDATION = 'POST_VALIDATION'
export const PROCESS_ENVELOPE_DOCUMENTS_UPLOAD_QUEUE = 'PROCESS_ENVELOPE_DOCUMENTS_UPLOAD_QUEUE'
export const REFRESH_CLOSING_STATUS = 'REFRESH_CLOSING_STATUS'
export const REFRESH_SIGNING_CHECKLIST = 'REFRESH_SIGNING_CHECKLIST'
export const REFRESH_STATUS = 'REFRESH_STATUS'
export const REFRESH_STEP = 'REFRESH_STEP'
export const RESET_SIGNATURE_FILES = 'RESET_SIGNATURE_FILES'
export const RESET_STEP = 'RESET_STEP'
export const RESET_STEP_MULTIPLE = 'RESET_STEP_MULTIPLE'
export const SEND_SIGN_NOW = 'SEND_SIGN_NOW'
export const SEND_SIGN_NOW_MULTIPLE = 'SEND_SIGN_NOW_MULTIPLE'
export const SHARE_ATTACHMENT_FILE = 'SHARE_ATTACHMENT_FILE'
export const START_CLOSING = 'START_CLOSING'
export const START_SCHEDULED_CLOSING = 'START_SCHEDULED_CLOSING'
export const SWAP_STEP_FILES = 'SWAP_STEP_FILES'
export const TITLE_INDENTATION = 'TITLE_INDENTATION'
export const UNMUTE_QUALIFIED_DS_SIGNATURE_WARNING = 'UNMUTE_QUALIFIED_DS_SIGNATURE_WARNING'
export const UNSELECT_SIGNER_WITNESSES = 'UNSELECT_SIGNER_WITNESSES'
export const UPDATE_CLOSING_END_DATE = 'UPDATE_CLOSING_END_DATE'
export const UPDATE_SIGNING_CHECKLIST_STEP = 'UPDATE_SIGNING_CHECKLIST_STEP'
export const UPDATE_TITLE = 'UPDATE_TITLE'
export const VALIDATE_THEN_SEND_NOW = 'VALIDATE_THEN_SEND_NOW'
export const VALIDATE_THEN_SEND_NOW_MULTIPLE = 'VALIDATE_THEN_SEND_NOW_MULTIPLE'
export const VALIDATE_WITNESSES_SELECTIONS = 'VALIDATE_WITNESSES_SELECTIONS'
