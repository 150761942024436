<template>
  <div class="sharePicker__container">
    <template v-if="getSelfContactListPending">
      <v-skeleton-loader type="paragraph@3" />
    </template>
    <template v-else>
      <MembersAutocomplete class="sharePicker__search"
                           :items="computedContactList"
                           :placeholder="$t('common.subscription.membersPicker.autocompletePlaceholder')"
                           @select="onSelectUser"
      />

      <app-text variant="small-bold">{{ $t('common.subscription.membersPicker.title')}}</app-text>
      <app-text variant="small-regular">{{ $t('common.subscription.membersPicker.subtitle')}}</app-text>

      <div class="sharePicker__items">
          <v-checkbox v-for="user in computedContactList"
                      :key="`user-${user.id}`" v-model="members"
                      :label="user.fullName"
                      :value="user.id"
                      class="sharePicker__item"
                      hide-details
                      @change="onWithUsersChange"
          />
      </div>
    </template>
  </div>
</template>

<script lang='ts'>
import { defineComponent, PropType } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import MembersAutocomplete from '@/project/members/MembersAutocomplete.vue'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

type DataType = {
  membersValue: number[]
}

export default defineComponent({
  name: 'SubscriptionMembersPicker',
  components: { MembersAutocomplete },
  props: {
    selectedMembers: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  data (): DataType {
    return {
      membersValue: [],
    }
  },
  computed: {
    ...mapState('user', ['selfContactListFromSubscription', 'getSelfContactListPending']),
    ...mapGetters('user', ['currentUserId']),
    computedContactList () : string[] {
      return this.selfContactListFromSubscription.filter(contact => contact.id !== this.currentUserId) || []
    },
    members: {
      get () : number[] {
        if (this.membersValue.length) {
          return this.membersValue
        } else {
          return this.selectedMembers
        }
      },
      set (val) {
        this.membersValue = val
      },
    },
  },
  mounted () {
    this.prepareGetSubscriptionUsers()
  },
  methods: {
    ...mapActions('user', ['GET_SELF_CONTACT_LIST']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async prepareGetSubscriptionUsers () {
      try {
        await this.GET_SELF_CONTACT_LIST({ fromUserSubscriptionOnly: true })
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.subscription.errors.getSubscriptionUsersError'))
      }
    },
    onSelectUser (member) {
      const memberIsNotCurrentlySelected = this.members.every(id => id !== member.id)

      if (memberIsNotCurrentlySelected) {
        this.membersValue = [...this.membersValue, member.id]
        this.$emit('change', this.membersValue)
      }
    },
    onWithUsersChange (members) {
      this.$emit('change', members)
    },
  },
})
</script>

<style lang="scss" scoped>
.sharePicker__container {
  margin-top: 16px;
}
.sharePicker__items,
.sharePicker__search,
.v-skeleton-loader {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
::v-deep .v-input__prepend-outer {
  margin: 0;
  position: absolute;
}
.sharePicker__search {
  margin-top: 24px;
}

.sharePicker__items {
  margin-top: 24px;
}

.sharePicker__item {
  justify-self: stretch;
  padding: 16px 24px;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  margin: 0;
  transition: background-color .4s ease-in-out;

  ::v-deep  & .v-input--checkbox {
    margin-top: 0;
  }
}
</style>
