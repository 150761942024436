var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.$vuetify.breakpoint.mobile)?[_c('v-card',{staticClass:"my-4 mx-2",attrs:{"flat":"","outlined":""}},[_c('v-tabs',{staticClass:"tabs-profile",attrs:{"vertical":"","value":_vm.path}},[_vm._l((_vm.PROFILE_SECTIONS),function(section){return [_c('div',{key:section.key},[_c('v-tab',{attrs:{"href":`#/profile/${section.key}`},on:{"click":function($event){_vm.$router.push('/profile/' + section.key).catch(() => {})}}},[_c('v-icon',{staticClass:"profile-icon",attrs:{"small":"","left":""},domProps:{"textContent":_vm._s(
                  _vm.path === `/profile/${section.key}`
                    ? section.icon
                    : 'fal ' + section.icon
                )}}),_c('span',[_vm._v(_vm._s(_vm.$t(section.title)))]),(section.key === 'identity-checking' && !_vm.canCurrentUserSign)?_c('v-badge',{staticClass:"mt-1",attrs:{"color":"error","value":true,"dot":"","offset-x":-5,"offset-y":-5}}):_vm._e()],1),_c('v-divider')],1)]})],2)],1),_c('v-card',{staticClass:"py-5 profile rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-tabs-items',{model:{value:(_vm.path),callback:function ($$v) {_vm.path=$$v},expression:"path"}},[_c('v-tab-item',{attrs:{"value":_vm.$route.path}},[_c('transition',{attrs:{"name":"app-fade","tag":"div"}},[_c('router-view')],1)],1)],1)],1)]:_c('v-row',{staticClass:"my-4",staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"py-0 offset-sm-1",attrs:{"cols":"12","sm":"3"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"justify":"end","align":"center"}},[_c('v-card',{staticClass:"ml-2 mt-3 pb-2 rounded-lg",staticStyle:{"width":"100%","height":"100%"},attrs:{"flat":"","outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.profile.firstName)+" "+_vm._s(_vm.profile.lastName))])],1),_c('v-divider'),_c('v-tabs',{staticClass:"tabs-profile",attrs:{"vertical":"","value":_vm.path}},[_vm._l((_vm.PROFILE_SECTIONS),function(section){return [_c('div',{key:section.key},[_c('v-tab',{attrs:{"href":`#/profile/${section.key}`},on:{"click":function($event){_vm.$router.push('/profile/' + section.key).catch(() => {})}}},[_c('v-icon',{staticClass:"profile-icon",attrs:{"small":"","left":""},domProps:{"textContent":_vm._s(
                      _vm.path === `/profile/${section.key}`
                        ? section.icon
                        : 'fal ' + section.icon
                    )}}),_c('span',[_vm._v(_vm._s(_vm.$t(section.title)))]),(section.isNew)?_c('AppNewLabel',{staticClass:"ml-2"}):_vm._e(),(section.key === 'identity-checking' && !_vm.canCurrentUserSign)?_c('v-badge',{staticClass:"mt-1",attrs:{"color":"error","value":true,"dot":"","offset-x":-5,"offset-y":-5}}):_vm._e()],1),_c('v-divider')],1)]})],2)],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"7"}},[_c('v-card',{staticClass:"py-5 profile rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-tabs-items',{model:{value:(_vm.path),callback:function ($$v) {_vm.path=$$v},expression:"path"}},[_c('v-tab-item',{attrs:{"value":_vm.path}},[_c('transition',{attrs:{"name":"app-fade","tag":"div"}},[_c('router-view')],1)],1)],1)],1)],1)],1),(_vm.$vuetify.breakpoint.mobile)?_c('ProfileNavigationDrawer',{attrs:{"profileSections":_vm.PROFILE_SECTIONS}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }