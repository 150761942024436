<template>
<AppTooltip right
            content-class="AppInfoTooltip"
            v-bind="$attrs"
            color="grey darken-2"
            :max-width="435"
>
  <template #activator="{ on, attrs }">
    <v-icon v-bind="attrs"
            class="mx-1 clickable"
            :class="{ 'tertiary--text': isActive }"
            :size="iconSize"
            v-on="on"
            @mouseenter="isActive = true"
            @mouseleave="isActive = false"
    >
      {{ isActive ? 'fas fa-circle-info' : 'far fa-circle-info' }}
      far fa-circle-info
    </v-icon>
  </template>
  <span>
    <!-- @slot for tooltip content -->
    <slot></slot>
  </span>
</AppTooltip>
</template>

<script>
import AppTooltip from '@/common/AppTooltip'
/**
 * @displayName AppInfoTooltip
 */
/**
 * Default Info tooltip.
 * Uses the AppTooltip component.
 */
export default {
  name: 'AppInfoTooltip',
  components: { AppTooltip },
  inheritAttrs: false,
  props: {
    /**
     * Icon size
     */
    iconSize: {
      type: [String, Number],
      default: 16,
    },
  },
  data () {
    return {
      isActive: false,
    }
  },
}
</script>

<style lang="scss">
.AppInfoTooltip {
  opacity: 1 !important;
  line-height: 16px;
  padding: 15px 20px;
}
</style>

<docs>
```vue
<template>
  <div>
    Some text
    <AppInfoTooltip>
      My tooltip content
    </AppInfoTooltip>
  </div>
</template>
```
</docs>
