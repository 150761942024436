<template>
  <div class="ChecklistShareTasksDialogRolePicker overflow-y-auto">
    <template v-if="loading">
      <v-row>
        <v-col class="text-center">
          <AppLoader/>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <div class="ChecklistShareTasksDialogRolePicker-headerRow px-4">
        <div></div>
        <div class="text-h4 text-center">{{$t('project.checklist.dialogs.ChecklistShareTasksDialogRolePicker.responsible')}}</div>
        <div class="text-h4 text-center">{{$t('project.checklist.dialogs.ChecklistShareTasksDialogRolePicker.validators')}}</div>
        <div class="text-h4 text-center">{{$t('project.checklist.dialogs.ChecklistShareTasksDialogRolePicker.observers')}}</div>
      </div>
      <ChecklistShareTasksDialogRolePickerGroup v-for="group in groups"
                                                :key="`group-${group.id}`"
                                                :mnemo="mnemo"
                                                :group="group"
                                                :taskVisibleByAll="taskVisibleByAll"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import { checklistTasksHaveSameRights } from '@/common/utils/checklist'

import ChecklistShareTasksDialogRolePickerGroup from './ChecklistShareTasksDialogRolePickerGroup'
import AppLoader from '../../../common/AppLoader'
import {
  ADD_SELECTED_OBSERVERS_GROUPS,
  ADD_SELECTED_OBSERVERS_MEMBERS,
  ADD_SELECTED_RESPONSIBLE_GROUPS,
  ADD_SELECTED_RESPONSIBLE_MEMBERS,
  ADD_SELECTED_VALIDATORS_GROUPS,
  REMOVE_SELECTED_OBSERVERS_GROUPS,
  ADD_SELECTED_VALIDATORS_MEMBERS,
  RESET_ROLE_PICKER,
} from '../../../store/modules/checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'ChecklistShareTasksDialogRolePicker',
  components: { ChecklistShareTasksDialogRolePickerGroup, AppLoader },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    taskVisibleByAll: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('checklist', ['shareTasksDialog']),
    ...mapState('groups', ['groups', 'loading']),
  },
  watch: {
    taskVisibleByAll (value) {
      this.onTaskVisibleByAllClick(value)
    },
  },
  async mounted () {
    try {
      await this.GET_GROUPS(this.mnemo)

      const pmGroup = this.groups.find(item => item.isPm)
      this.ADD_SELECTED_OBSERVERS_GROUPS(pmGroup)

      const everyTasksToShareHaveTheSameRights = checklistTasksHaveSameRights(this.shareTasksDialog.tasksToShare)
      if (everyTasksToShareHaveTheSameRights) {
        this.shareTasksDialog.tasksToShare[0].rights.read.groups.forEach(item => {
          this.ADD_SELECTED_OBSERVERS_GROUPS(this.groups.find(o => o.id === item.id))
        })
        this.shareTasksDialog.tasksToShare[0].rights.read.users.forEach(item => {
          this.ADD_SELECTED_OBSERVERS_MEMBERS(this.findUser(item))
        })
        this.shareTasksDialog.tasksToShare[0].rights.tagged.groups.forEach(item => {
          this.ADD_SELECTED_RESPONSIBLE_GROUPS(this.groups.find(o => o.id === item.id))
        })
        this.shareTasksDialog.tasksToShare[0].rights.tagged.users.forEach(item => {
          this.ADD_SELECTED_RESPONSIBLE_MEMBERS(this.findUser(item))
        })
        this.shareTasksDialog.tasksToShare[0].rights.validators.groups.forEach(item => {
          this.ADD_SELECTED_VALIDATORS_GROUPS(this.groups.find(o => o.id === item.id))
        })
        this.shareTasksDialog.tasksToShare[0].rights.validators.users.forEach(item => {
          this.ADD_SELECTED_VALIDATORS_MEMBERS(this.findUser(item))
        })
      }
      if (this.taskVisibleByAll) {
        this.onTaskVisibleByAllClick(true)
      }
    } catch (e) {
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'error',
        message: this.$t('project.checklist.dialogs.ChecklistShareTasksDialogRolePicker.getShareTaskRolePickerGroupsError'),
      })
    }
  },
  beforeDestroy () {
    this.RESET_ROLE_PICKER()
  },
  methods: {
    ...mapActions('groups', ['GET_GROUPS']),
    ...mapMutations('checklist', [
      ADD_SELECTED_OBSERVERS_GROUPS,
      ADD_SELECTED_OBSERVERS_MEMBERS,
      ADD_SELECTED_RESPONSIBLE_GROUPS,
      ADD_SELECTED_RESPONSIBLE_MEMBERS,
      ADD_SELECTED_VALIDATORS_GROUPS,
      ADD_SELECTED_VALIDATORS_MEMBERS,
      REMOVE_SELECTED_OBSERVERS_GROUPS,
      RESET_ROLE_PICKER,
    ]),
    findUser (user) {
      if (this.groups && this.groups.length > 0) {
        for (const group of this.groups) {
          if (group.members.some(item => item.id === user.id)) {
            return group.members.find(item => item.id === user.id)
          }
        }
      }
    },
    onTaskVisibleByAllClick (visibleToAll) {
      for (const group of this.groups) {
        if (visibleToAll) {
          this.ADD_SELECTED_OBSERVERS_GROUPS(group)
        } else {
          if (!group.isPm) {
            this.REMOVE_SELECTED_OBSERVERS_GROUPS(group)
          }
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistShareTasksDialogRolePicker {
  max-height: 370px;
}
.ChecklistShareTasksDialogRolePicker-headerRow {
  display: grid;
  grid-template-columns: auto 180px 180px 180px;
  grid-gap: 10px;
}
</style>
