<template>
  <div>
    <v-row>
      <v-col>
        <div class="HomeLogin-title">{{$t('login.ClosdTagLine.header')}}</div>
      </v-col>
    </v-row>
    <v-row align="center" class="mb-4">
      <v-col cols="auto">
        <v-img src="/img/icons/signature.svg" width="40" class="mr-3"/>
      </v-col>
      <v-col>
        <div class="HomeLogin-infoText text-h3">{{$t('login.ClosdTagLine.signature')}}</div>
      </v-col>
    </v-row>
    <v-row align="center" class="mb-4">
      <v-col cols="auto">
        <v-img src="/img/icons/securite.svg" width="40" class="mr-3"/>
      </v-col>
      <v-col>
        <div class="HomeLogin-infoText text-h3">{{$t('login.ClosdTagLine.security')}}</div>
      </v-col>
    </v-row>
    <v-row align="center" class="mb-4">
      <v-col cols="auto">
        <v-img src="/img/icons/document.svg" width="40" class="mr-3"/>
      </v-col>
      <v-col>
        <div class="HomeLogin-infoText text-h3">{{$t('login.ClosdTagLine.project')}}</div>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col class="HomeLogin-infoText HomeLogin-text">{{$t('login.ClosdTagLine.paragraphOne')}}</v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col class="HomeLogin-infoText HomeLogin-text">{{$t('login.ClosdTagLine.paragraphTwo')}}</v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ClosdTagLine',
}
</script>
<style scoped>
.HomeLogin-title {
  font-size: 26px;
}
.HomeLogin-infoText {
  color: #515151;
}
.HomeLogin-text{
  font-size: 16px;
  line-height: 21px;
}
@media only screen and (max-width: 900px) {
  .textExplication {
    display:none;
  }
}
</style>
