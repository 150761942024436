<template>
  <div class="qa-item-thread-navigation">
    <AppButton
               outlined
               :disabled="!question.previousInRoom"
               @click="() => getQuestion(question.previousInRoom)"
    >
      <font-awesome-icon class="mr-4" :icon="['far', 'chevron-left']" />
      {{ $t('project.question-answer.QuestionAnswerItemThreadNavigation.previousLabel') }}
    </AppButton>

    <AppButton
               outlined
               :to="{ name: QUESTION_ANSWER_LIST_ROUTE_NAME }"
    >
      {{ $t("project.question-answer.QuestionAnswerItemThreadNavigation.headerGoToQuestionList") }}
    </AppButton>

    <AppButton
               outlined
               :disabled="!question.nextInRoom"
               @click="() => getQuestion(question.nextInRoom)"
    >
      {{ $t('project.question-answer.QuestionAnswerItemThreadNavigation.nextLabel') }}
      <font-awesome-icon class="ml-4" :icon="['far', 'chevron-right']" />
    </AppButton>
  </div>
</template>

<script>
import AppButton from '@/common/buttons/AppButton'
import { QUESTION_ANSWER_ITEM_ROUTE_NAME, QUESTION_ANSWER_LIST_ROUTE_NAME } from '@/router'

export default {
  name: 'QuestionAnswerItemThreadNavigation',
  components: { AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      QUESTION_ANSWER_LIST_ROUTE_NAME,
    }
  },
  methods: {
    async displayAnswerForm () {
      this.answerFormIsDisplay = true

      await this.$nextTick()
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
    },
    getQuestion (questionId) {
      this.$router.push({
        name: QUESTION_ANSWER_ITEM_ROUTE_NAME,
        params: {
          questionId: questionId,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.qa-item-thread-navigation {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 18px;

  .v-btn {
    background: white;

    &::before {
      opacity: 0;
    }
  }
 }
</style>
