<template>
  <div class="DocumentsActionBar">
    <ul v-resize="setActionBarMoreMenuItems" class="DocumentsActionBar-List">
      <li class="DocumentsActionBar-List-Item DocumentsActionBar-List-Item--selectionLabel">
        <DocumentsActionBarSelectionLabel :totSelectedDocuments="totSelectedDocuments" @cancel-selection="DESELECT_ALL_DOCUMENTS" />
      </li>
      <slot></slot>
    </ul>
    <DocumentsActionBarMoreActionsMenu v-if="moreActionsMenuItems" :menu="moreActionsMenuItems" />
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapState, mapActions } from 'vuex'

import { handleElementDimensionResize } from '@/common/utils/dimensionObserver'
import DocumentsActionBarMoreActionsMenu from '@/project/documents/DocumentsActionBarMoreActionsMenu.vue'
import DocumentsActionBarSelectionLabel from '@/project/documents/DocumentsActionBarSelectionLabel.vue'
import { DESELECT_ALL_DOCUMENTS } from '@/store/modules/documents/action_types'

// This duration is meant to wait after any drawer width change so that
// we can trigger a refresh to update the v-content position relative to the new drawer width
// This is due to a Vuetify bug when the navigation drawer has multiple width values set to it
// Especially when using different units
const CONTENT_REFLOW_DELAY = 190

export default {
  name: 'DocumentsActionBarWrapper',
  components: {
    DocumentsActionBarMoreActionsMenu,
    DocumentsActionBarSelectionLabel,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      menuItemsDisplayed: [],
      resizeObserverDestroyCallback: null,
    }
  },
  created () {
    this.setActionBarMoreMenuItems = debounce(this.setActionBarMoreMenuItems, 250, { leading: true })
  },
  mounted () {
    this.setActionBarMoreMenuItems()
    this.resizeObserverDestroyCallback = handleElementDimensionResize('.DocumentsActionBar', 'width', (newWidth) => {
      if (newWidth) {
        this.setActionBarMoreMenuItems()
      }
    })
  },
  computed: {
    ...mapState('documents', ['selectedDocuments']),
    moreActionsMenuItems () {
      return this.menuItems.filter((item) => this.menuItemsDisplayed.includes(item.name))
    },
    totSelectedDocuments () {
      return this.selectedDocuments.length
    },
  },
  methods: {
    ...mapActions('documents', [DESELECT_ALL_DOCUMENTS]),
    async setActionBarMoreMenuItems (e) {
      await this.$nextTick()

      setTimeout(() => {
        const actionBar = document.getElementsByClassName('DocumentsActionBar-List')[0]

        if (actionBar?.children) {
          const actionBarItems = Array.from(actionBar.children)
          const actionBarOffsetTop = actionBar.offsetTop

          actionBarItems.forEach(item => {
            const itemName = item.getAttribute('name')
            const itemIndex = this.menuItems.findIndex((item) => item.name === itemName)
            const isDisplayed = this.menuItemsDisplayed.includes(itemName)

            if (item.offsetTop > actionBarOffsetTop && itemName) {
              if (!isDisplayed) {
                this.menuItemsDisplayed.push(itemName)
              }
            } else if (itemIndex !== -1) {
              if (isDisplayed) {
                this.menuItemsDisplayed = this.menuItemsDisplayed.filter(itemDisplayedName => itemDisplayedName !== itemName)
              }
            }
          })
        }
      }, CONTENT_REFLOW_DELAY)
    },
  },
  watch: {
    totSelectedDocuments (value) {
      if (value) {
        this.setActionBarMoreMenuItems()
      }
    },
  },
  beforeDestroy () {
    if (this.resizeObserverDestroyCallback) {
      this.resizeObserverDestroyCallback()
    }
  },
}
</script>

<style scoped lang="scss">
.DocumentsActionBar {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border: 1px solid var(--v-primary-base);
  border-radius: 8px;
}

.DocumentsActionBar-List {
  align-items: stretch;
  display: flex;
  flex-basis : 0;
  flex-grow : 1;
  flex-shrink : 1;
  flex-wrap: wrap;
  height: 36px;
  overflow: hidden;
  padding: 0;
}

.DocumentsActionBar-List-Item {
  list-style-type: none;
  display: inline-block;
}

.DocumentsActionBar-List-Item--selectionLabel {
  & + .DocumentsActionBar-List-Item {
    margin-left: 16px;
    border-left: 1px solid var(--v-grey-lighten4)
  }
}
</style>
