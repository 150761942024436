<template>
  <span class="feature-tag" :style="{'--bg-color': tagData.backgroundColor, '--font-color': tagData.fontColor}">
    <slot>
      <app-text variant="small-bold" as="span">
        {{ tagData.text }}
      </app-text>
    </slot>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

type FeatureTag = 'beta' | 'new'
type TagData = {
  backgroundColor: string;
  fontColor: string;
  text: string;
}

export default defineComponent({
  name: 'AppFeatureTag',
  props: {
    tag: {
      type: String as PropType<FeatureTag>,
      required: true,
    },
  },
  computed: {
    tagData (): TagData {
      // A la migration, utiliser 'useVuetify' pour récupérer les couleurs du theme plutôt que les valeurs en dur
      const data = {
        beta: {
          backgroundColor: '#D8F1C5',
          fontColor: '#5A853C',
          text: 'Bêta',
        },
        new: {
          backgroundColor: '#D4E3FC',
          fontColor: '#0C3D91',
          text: 'New',
        },
      }

      return data[this.tag]
    },
  },
})
</script>

<style scoped lang="scss">
.feature-tag {
  background: var(--bg-color);
  color: var(--font-color);

  user-select: none;
  border-radius: 4px;
  padding: 2px 8px;
}
</style>
