<template>
  <div>
    <v-chip color="indigo lighten-5"
            text-color="indigo"
            class="mr-2"
            small
    >
      <AppSignatureTypeText :provider="envelopeTemplate.provider" :signature-type="envelopeTemplate.signatureType"/>
    </v-chip>
    <v-chip color="pink lighten-5"
            text-color="pink"
            class="mr-2"
            small
    >
      <AppProviderText :provider="envelopeTemplate.provider"/>
    </v-chip>
    <v-chip v-if="envelopeTemplate.envelopeType === 'multiple'"
            color="light-blue lighten-5"
            text-color="light-blue"
            class="mr-2"
            small
    >
      {{ $t('common.envelopeType.multiple') }}
    </v-chip>
  </div>
</template>

<script>

import AppProviderText from '@/common/AppProviderText.vue'
import AppSignatureTypeText from '@/common/AppSignatureTypeText.vue'

export default {
  name: 'Type',
  components: { AppProviderText, AppSignatureTypeText },
  props: {
    envelopeTemplate: {
      type: Object,
      required: true,
    },
  },
}
</script>
