<template>
  <div :style="gridStyles">
    <div class="AccessRightsTable-gridHeaderCell sticky-left font-weight-medium"></div>
    <div v-for="column in displayedColumns"
         :key="`header-${column.propertyName}`"
         class="AccessRightsTable-gridHeaderCell text-center font-weight-medium"
    >
      <div>{{ $t(`project.project-settings.access-rights.AccessRightsHeader.${column.propertyName}`) }}</div>
      <AppHelpTooltip v-if="column.hasHeaderTooltip"
                      :text="$t(`project.project-settings.access-rights.AccessRightsHeader.${column.propertyName}Tooltip`)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppHelpTooltip from '@/common/AppHelpTooltip.vue'

export default {
  name: 'AccessRightsHeader',
  components: { AppHelpTooltip },
  props: {
    gridStyles: {
      type: Object,
      required: true,
    },
    displayedColumns: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('room', ['isDataRoom']),
  },
}
</script>
