<template>
 <AppCard elevation="0">
   <v-card-text class="text--primary">
     <v-row>
       <v-col class="body-1 font-weight-bold d-flex justify-space-between align-center">
         <div>
           <font-awesome-icon :icon="['fas', 'user-friends']" class="accent--text text--lighten-2 mr-1"></font-awesome-icon>
           {{ $t('project.question-answer.QuestionAnswerItemShared.questionSharedWith') }}
         </div>
         <AppTooltip top>
          <template #activator="{ on, attrs }">
            <AppButton icon
                        class="body-1"
                        v-bind="attrs"
                        @click="sharePaneIsOpen = true"
                        v-on="on"
            >
              <font-awesome-icon
                :icon="['fas', 'gear']"
                class="accent--text text--lighten-2"
              ></font-awesome-icon>
            </AppButton>
          </template>
          {{ $t('project.question-answer.QuestionAnswerItemShared.shareActivatorTooltip') }}
         </AppTooltip>
       </v-col>
     </v-row>
     <v-row>
       <v-col>
         <v-treeview
            v-if="!loading"
            :items="computedRights"
            item-children="members"
            item-text="computedName"
            item-key="key"
            dense
         />
         <v-skeleton-loader
            v-else
            type="list-item@2"
         />
       </v-col>
     </v-row>
   </v-card-text>
   <QuestionAnswerItemSharePane v-model="sharePaneIsOpen"
                                :mnemo="mnemo"
   />
 </AppCard>
</template>

<script>
import { cloneDeep, differenceBy, intersectionBy } from 'lodash-es'
import { mapState, mapActions } from 'vuex'

import AppCard from '@/common/AppCard'
import AppButton from '@/common/buttons/AppButton'
import QuestionAnswerItemSharePane from '@/project/question-answer/QuestionAnswerItemSharePane'

import AppTooltip from '../../common/AppTooltip.vue'

export default {
  name: 'QuestionAnswerItemShared',
  components: {
    QuestionAnswerItemSharePane,
    AppCard,
    AppButton,
    AppTooltip,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      sharePaneIsOpen: false,
    }
  },
  computed: {
    ...mapState('questionAnswer', ['question']),
    ...mapState('groups', ['groups', 'loading']),
    computedRights () {
      const allGroups = this.groups
      let groupsToShow = cloneDeep(this.groups)
      // If it's shared to everyone, we'll just keep all the groups as the default init
      if (!this.question.rights.isPublic) {
        // We only keep the groups that were shared to, then we'll retrieve the users as we go
        groupsToShow = intersectionBy(groupsToShow, this.question.rights.groups, 'id')
        const groupsNotFullySharedTo = differenceBy(allGroups, groupsToShow, 'id')
        for (const userSharedTo of this.question.rights.users) {
          for (const group of groupsNotFullySharedTo) {
            const userSharedToIsInThatGroup = group.members.some((groupMember) => groupMember.id === userSharedTo.id)
            if (userSharedToIsInThatGroup) {
              const groupInGroupsToShow = groupsToShow.find((groupToShow) => group.id === groupToShow.id)
              // If we already found a member for this group, it's already here
              if (groupInGroupsToShow) {
                // If it's already here, we can push into its members
                groupInGroupsToShow.members.push(userSharedTo)
              } else {
                // We recreate the group and add the first user we found that's in it
                groupsToShow.push({
                  ...group,
                  members: [
                    userSharedTo,
                  ],
                })
              }
              break
            }
          }
        }
      }
      groupsToShow.forEach(group => {
        group.key = `g${group.id}`
        group.computedName = `${group.name} (${group.members.length})`
        group.members.forEach(member => {
          member.key = `m${member.id}`
          member.computedName = `${member.fullName}`
        })
      })
      return groupsToShow
    },
  },
  mounted () {
    if (this.$route.params.openSharePane) {
      this.sharePaneIsOpen = true
      // Don't forget to remove route param to not re-open the pane after form submission
      this.$route.params.openSharePane = false
    }
    this.GET_GROUPS(this.mnemo)
  },
  methods: {
    ...mapActions('groups', ['GET_GROUPS']),
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-treeview {
  & > .v-treeview-node > .v-treeview-node__root {
    font-weight: 500;
  }
}
</style>
