<template>
  <v-snackbars
    :elevation="0"
    :objects.sync="snackbars"
    app
    bottom
    class="toast"
    transition="slide-y-reverse-transition"
  >
    <template v-slot:action="{ close }">
      <v-btn icon @click="close">
        <font-awesome-icon :icon="['fal', 'xmark']" size="lg" />
      </v-btn>
    </template>
    <template v-slot:default="{ message }">
      <span v-html="message"></span>
    </template>
  </v-snackbars>
</template>

<script>
import VSnackbars from 'v-snackbars'
/**
 * Toasts stack
 * @displayName AppToastsStack
 */
/**
 * Used as application global toasts stack, handled through store mutations :
 * ENQUEUE_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR and ENQUEUE_ERROR_SNACKBAR
 */
export default {
  name: 'AppToastsStack',
  components: {
    VSnackbars,
  },
  computed: {
    snackbars: {
      get () {
        return this.$store.state.snackbars
      },
      set (elements) {
        this.$store.commit('updateSnackbarQueue', elements)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.toast {
  ::v-deep(.v-snack__wrapper) {
    margin: 40px;

    // max-width: 1264px match our mobile breakpoint
    @media only screen and (max-width: 1264px) {
      margin: 16px;
    }
  }
}
</style>

<docs>
```vue
<template>
  <div>
    <AppButton color="success"
               @click="displaySuccessSnackbar"
    >
      Display Success Snackbar
    </AppButton>
    <AppButton color="error"
               @click="displayErrorSnackbar"
    >
      Display Error Snackbar
    </AppButton>
    <Snackbar/>
  </div>
</template>
<script>
  import AppButton from '@/common/buttons/AppButton'
  import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'
  export default {
    components: { AppButton },
    methods: {
      displaySuccessSnackbar () {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: 'This is a success snackbar',
        })
      },
      displayErrorSnackbar () {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: 'This is an error snackbar',
        })
      },
    },
  }
</script>
```
</docs>
