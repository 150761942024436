import { render, staticRenderFns } from "./AppIconBadge.vue?vue&type=template&id=31e29a59&scoped=true"
import script from "./AppIconBadge.vue?vue&type=script&lang=ts"
export * from "./AppIconBadge.vue?vue&type=script&lang=ts"
import style0 from "./AppIconBadge.vue?vue&type=style&index=0&id=31e29a59&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e29a59",
  null
  
)

/* custom blocks */
import block0 from "./AppIconBadge.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports