<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             @cancel="closeDialog"
  >
    <template #title>
      <ProjectTypeFeatureHeader :shadow-color="PROJECT_TYPE_COLORS.ELECTRONIC_SAFE"
                                illustration="/img/discover_electronic_safe.svg"
      >
        <template #title>
          <h3 class="text-h3' blue--text text--darken-3">{{ $t('common.discover') }}</h3>
          <h1 class="text-h1">{{ $t('projects.dialogs.DiscoverElectronicSafeDialog.moduleTitle') }}</h1>
        </template>
      </ProjectTypeFeatureHeader>
    </template>

    <template #body>
      <div class="copyright">
        <CopyrightLink author="fullvector"
                       link="https://fr.freepik.com/vecteurs-libre/isometrique-securite-donnees-icone-base-donnees-bouclier-cle-verrouillage-donnees-assistance-personnelle-securite_4102338.htm"
        />
      </div>
      <div class="features-list">
        <ProjectTypeFeaturesList :features="features" icon-class="blue--text text--darken-3" />
      </div>

      <v-img src="/img/electronic_safe_partners.png"
             class="partners"
             contain
      />
    </template>

    <template #footer>
      <v-spacer />
      <AppButton
        color="white"
        @click="closeDialog"
      >
        {{$t('common.cancel')}}
      </AppButton>

      <AppButton
        v-if="isArchivingEnabled"
        color="primary"
        :to="{ name: ARCHIVES_ROUTE_NAME } "
      >
        {{ $t("projects.dialogs.DiscoverElectronicSafeDialog.mySafe") }}
      </AppButton>
      <AppButton
        v-else
        color="primary"
        @click="requestActivation"
      >
        {{ $t("projects.dialogs.DiscoverElectronicSafeDialog.activate") }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { mapGetters } from 'vuex'

import CopyrightLink from '@/common/CopyrightLink.vue'
import { PROJECT_TYPE_COLORS } from '@/projects/dialogs/projectTypeColors'

import AppButton from '../../common/buttons/AppButton'
import AppDialog from '../../common/dialogs/AppDialog'
import { dialogMixin } from '../../mixins/dialogMixin'
import { ARCHIVES_ROUTE_NAME } from '../../router'
import ProjectTypeFeatureHeader from '../project-types/ProjectTypeFeatureHeader'
import ProjectTypeFeaturesList from '../project-types/ProjectTypeFeaturesList'

const FEATURES_ICONS = {
  1: 'shield-check',
  2: 'gavel',
  3: 'bolt',
}

export default {
  name: 'DiscoverElectronicSafeDialog',
  components: { ProjectTypeFeatureHeader, AppButton, AppDialog, CopyrightLink, ProjectTypeFeaturesList },
  mixins: [dialogMixin],
  data () {
    return {
      ARCHIVES_ROUTE_NAME,
      PROJECT_TYPE_COLORS,
      features: Object.keys(FEATURES_ICONS).map(key => ({
        icon: FEATURES_ICONS[key],
        title: this.$t(`projects.dialogs.DiscoverElectronicSafeDialog.features.${key}.title`),
        description: this.$t(`projects.dialogs.DiscoverElectronicSafeDialog.features.${key}.description`),
      })),
    }
  },
  computed: {
    ...mapGetters('user', ['isArchivingEnabled']),
  },
  methods: {
    requestActivation () {
      this.closeDialog()
      this.$emit('requestActivation')
    },
  },
}
</script>

<style scoped lang="scss">
.features-list {
  margin: 0 45px;
  max-width: 600px;
}

.partners {
  max-width: 440px;
  height: 75px;
  margin: 0 62px;
}

.contact-us {
  a {
    color: white;
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .partners {
    margin: 0 36px;
  }

  .features-list {
    margin: 0 20px;
  }
}

.copyright {
  position: absolute;
  right: -20px;
  transform: rotate(-90deg);
}
</style>
