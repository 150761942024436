import i18n from '@/i18n'

type UseTranslationParams = {
  keyPrefix: string
}
type UseTranslationReturn = {
  t: (key: string) => string
}

export function useTranslation ({ keyPrefix }: UseTranslationParams): UseTranslationReturn {
  const t = (key: string) => {
    return i18n.t(`${keyPrefix}.${key}`).toString()
  }

  return { t }
}
