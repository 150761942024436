<template>
  <DocumentSelectionListItem :document="item" downloadable>
    <template #under>
      <div class="mt-1">
        <small><app-filesize-display :value="item.size"/> - {{ $t(`${TRANSLATION_KEY_PREFIX}.updateInfo`, { date: updateDate, name: item.updateUser }) }}</small>
      </div>
    </template>
    <template #append>
      <RightsDisplayButton v-if="canShareVersion"
                           :hoverOnly="noShare"
                           :rights="item.rights"
                           @click="onShareVersion"
      />
      <template v-if="canDeleteVersion">
        <Popover horizontal-position="right" :opened="isPopoverOpen">
          <template #trigger>
            <app-icon icon-name="trash-alt" class="clickable grey--text text--darken-1" @click="onDeleteVersion" />
          </template>
          <template #content>
            <app-text variant="small-regular">{{ $t(`${TRANSLATION_KEY_PREFIX}.deleteVersionConfirmationText`) }}</app-text>
            <div class="popover-actions">
              <app-button size="small" type="outlined" variant="neutral" @click="onCancel">{{ $t(`common.cancel`) }}</app-button>
              <app-button size="small" variant="danger" @click="onDeleteVersionConfirmation">{{ $t(`${TRANSLATION_KEY_PREFIX}.deleteVersionConfirmationLabel`) }}</app-button>
            </div>
          </template>
        </Popover>
      </template>
    </template>
  </DocumentSelectionListItem>
</template>

<script>
import { mapGetters } from 'vuex'

import DocumentSelectionListItem from '@/common/document-selection/DocumentSelectionListItem.vue'
import Popover from '@/common/popover/Popover.vue'
import RightsDisplayButton from '@/common/users/RightsDisplayButton.vue'
import { ISOToShortenedDate } from '@/common/utils/dates'
const TRANSLATION_KEY_PREFIX = 'common.document-versioning.DocumentVersioningHistoryItem'

export default {
  name: 'DocumentVersioningHistoryItem',
  components: {
    DocumentSelectionListItem,
    Popover,
    RightsDisplayButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    noShare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm', 'currentUserRights']),
    canDeleteVersion () {
      return this.deletable && this.isCurrentUserPm
    },
    canShareVersion () {
      return this.item.rights && this.isCurrentUserPm
    },
    updateDate () {
      return ISOToShortenedDate(this.item.updateTime)
    },
  },
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
      isPopoverOpen: false,
    }
  },
  emits: ['delete-version', 'share-version'],
  methods: {
    onCancel () {
      this.isPopoverOpen = false
    },
    onDeleteVersionConfirmation () {
      this.$emit('delete-version', this.item)
      this.isPopoverOpen = false
    },
    onDeleteVersion () {
      this.isPopoverOpen = true
    },
    onShareVersion () {
      this.$emit('share-version', this.item)
    },
  },
}
</script>

<style lang="scss" scoped>
.popover-actions {
  column-gap: 8px;
  display: flex;
  justify-content: end;
  margin-top: 16px;
}
</style>
