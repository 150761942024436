<template>
  <v-row v-if="hasActiveFilter">
    <v-col>
      <DataTableFiltersSummary :active-filters="filters"
                              @clear-filter="onClearAllFilter"
                              @clear-all-filters="onClearAllFilters"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import DataTableFiltersSummary from '@/common/filters/DataTableFiltersSummary.vue'
import { REMOVE_SIGNING_CHECKLIST_FILTER, SET_SIGNING_CHECKLIST_FILTERS } from '@/store/modules/signing-checklist/mutation_types'

export default {
  name: 'SigningChecklistFiltersActive',
  components: { DataTableFiltersSummary },
  computed: {
    ...mapState('signingChecklist', ['filters']),
    ...mapGetters('signingChecklist', ['hasActiveFilter']),
  },
  methods: {
    ...mapMutations('signingChecklist', [REMOVE_SIGNING_CHECKLIST_FILTER, SET_SIGNING_CHECKLIST_FILTERS]),
    onClearAllFilter (filter) {
      this.REMOVE_SIGNING_CHECKLIST_FILTER(filter)
    },
    onClearAllFilters () {
      this.SET_SIGNING_CHECKLIST_FILTERS([])
    },
  },
}
</script>
