<template>
  <div>
    <AppTooltip top>
      <template #activator="{attrs, on}">
        <AppButton icon
                   class="text--primary ignore-draggable-elements"
                   v-bind="attrs"
                   @click="isOpen = true"
                   v-on="on"
        >
          <font-awesome-icon v-if="!withTrashIcon" :icon="['fal', 'xmark']"></font-awesome-icon>
          <font-awesome-icon v-else :icon="['fas', 'trash']"></font-awesome-icon>
        </AppButton>
      </template>
      <span>{{$t('project.members.dialogs.MemberDeleteDialog.removeMemberTooltip')}}</span>
    </AppTooltip>
    <AppDialog :is-open="isOpen"
               @cancel="close"
               @ok="prepareDeleteMember"
    >
      <template #title>{{ $t('project.members.dialogs.MemberDeleteDialog.title') }}</template>
      <template #body>{{ $t('project.members.dialogs.MemberDeleteDialog.text') }}</template>
    </AppDialog>

    <AppDialog :is-open="lastSubscriberModalIsOpen"
               ok-only
               :ok-text="$t('common.close')"
               @cancel="lastSubscriberModalIsOpen = false"
               @ok="close"
    >
      <template #title>{{ $t('project.members.dialogs.MemberDeleteDialog.lastSubscriberModalTitle') }}</template>
      <template #body>{{ $t('project.members.dialogs.MemberDeleteDialog.lastSubscriberModalText', {subName: subName}) }}</template>
    </AppDialog>

    <AppDialog :is-open="removeMemberWithSignatureIsOpen"
               @cancel="close"
               @ok="prepareDeleteMember(true)"
    >
      <template #title>{{ $t('project.members.dialogs.MemberDeleteDialog.removeMemberWithSignatureModalTitle') }}</template>
      <template #body>
        <v-row>
          <v-col>
            {{ $t('project.members.dialogs.MemberDeleteDialog.removeMemberWithSignatureModalText') }}
          </v-col>
        </v-row>
        <v-row v-if="memberToRemoveFromGroupWithSignature">
          <v-col>
            <ul class="px-3" style="list-style-position: inside">
              <li v-for="(doc, i) in memberToRemoveFromGroupWithSignature.docs"
                  :key="`doc-${i}`"
              >
                {{doc}}
              </li>
            </ul>
          </v-col>
        </v-row>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import AppButton from '@/common/buttons/AppButton'

import AppDialog from '../../../common/dialogs/AppDialog'

export default {
  name: 'MemberDeleteDialog',
  components: {
    AppDialog,
    AppButton,
    AppTooltip,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    withTrashIcon: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isOpen: false,
      subName: null,
      lastSubscriberModalIsOpen: false,
      removeMemberWithSignatureIsOpen: false,
      memberToRemoveFromGroupWithSignature: null,
    }
  },
  methods: {
    ...mapActions('groups', ['DELETE_MEMBER', 'GET_GROUPS']),
    close () {
      this.isOpen = false
      this.subName = null
      this.lastSubscriberModalIsOpen = false
      this.removeMemberWithSignatureIsOpen = false
      this.memberToRemoveFromGroupWithSignature = null
    },
    prepareDeleteMember (force = false) {
      this.DELETE_MEMBER({ memberId: this.member.id, params: { confirmVoidSignatures: force } })
        .then(() => {
          this.GET_GROUPS(this.$route.params.mnemo)
          this.isOpen = false
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: this.$t('project.members.dialogs.MemberDeleteDialog.messages.successRemoveAccess'),
          })
        })
        .catch(error => {
          if (error.response?.data?.error === 'ERR_ROOM_NEED_SUBSCRIBED_USER') {
            this.isOpen = false
            this.subName = error.response?.data?.subscription?.name
            this.lastSubscriberModalIsOpen = true
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.dialogs.MemberDeleteDialog.messages.errorRemoveAccess'),
            })
          } else if (error.response?.status === 300 && error.response?.data?.length > 0) {
            this.memberToRemoveFromGroupWithSignature = {
              docs: error.response?.data,
            }
            this.isOpen = false
            this.removeMemberWithSignatureIsOpen = true
          } else {
            this.isOpen = false
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.dialogs.MemberDeleteDialog.messages.errorRemoveAccess'),
            })
            throw error
          }
        })
    },
  },
}
</script>
