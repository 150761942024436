<template>
  <div>
    <template v-if="!getSelfContactListPending">
      <Alert type="info" class="my-4">
        <p class="mb-0">{{ $t("archives.ArchivesShareModule.alertText") }}</p>
      </Alert>
      <v-checkbox
        class="my-2"
        :input-value="allMembersChecked"
        :indeterminate="someMembersChecked"
        :label="$t('archives.ArchivesShareModule.inputSelectAllMembersLabel')"
        @change="toggleSelectAllMembers"
      >
      </v-checkbox>

      <div class="members-container pb-4 ml-2">
        <template v-for="(userRow, i) in knownUsersMatrix">
          <v-row :key="i" class="row-border pb-2 mt-2" no-gutters align="center">
            <template v-for="user in userRow">
              <v-col :key="user.id" :cols="3">
                <v-checkbox
                  v-model="value.fileRights"
                  :disabled="user.id === profile.id || user.isArchivingAdmin"
                  :value="user.id"
                  :label="user.firstName + ' ' + user.lastName"
                  hide-details
                  class="mt-0 px-2"
                >
                </v-checkbox>
              </v-col>
            </template>
          </v-row>
        </template>
      </div>
      <v-checkbox v-model="value.notify" :label="$t('archives.ArchivesShareModule.inputNotifyLabel')" />
      <v-textarea
        v-show="value.notify"
        id="notify-message-input"
        v-model="value.notifyMessage"
        outlined
        color="blue darken-2"
        :label="$t('archives.ArchivesShareModule.inputNotifyMessageLabel')"
      />
    </template>
    <div v-else class="text-center">
      <v-progress-circular
        style="width: 64px; height: 64px"
        color="primary"
        indeterminate
        size="64"
      />
    </div>
  </div>
</template>

<script>
import { chunk } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'

import Alert from '../common/alerts/Alert.vue'
import { GET_SELF_CONTACT_LIST } from '../store/modules/user/action_types'
const { mapActions, mapState } = createNamespacedHelpers('user')
const NUMBER_OF_USER_COLUMNS = 4
/**
 * Check if a provided user is an archivingAdmin not already in the userArray
 * @param {{ id: number, isArchivingAdmin }} user User object
 * @param {array} userArray user ids array to check from
 * @returns {boolean}
 */
function uniqueArchivingAdminUser (user, userArray) {
  return !userArray.includes(user.id) && user.isArchivingAdmin
}

export default {
  name: 'ArchivesShareModule',
  components: { Alert },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      initialFileRights: [],
      knownUsers: [],
    }
  },
  computed: {
    allMembersChecked () {
      return this.value.fileRights && this.value.fileRights.length === this.knownUsers.length
    },
    someMembersChecked () {
      return this.value.fileRights && this.value.fileRights.length !== 1 && !this.allMembersChecked
    },
    ...mapState(['selfContactListFromSubscription', 'getSelfContactListPending', 'profile']),
    knownUsersMatrix () {
      return chunk(this.knownUsers, NUMBER_OF_USER_COLUMNS)
    },
  },
  async created () {
    await this.GET_SELF_CONTACT_LIST({ fromUserSubscriptionOnly: true })
    this.knownUsers = this.selfContactListFromSubscription
    // fileRights should always be an array so we can do array methods on it
    if (!Array.isArray(this.value.fileRights)) {
      this.value.fileRights = []
    }
    // We always add the current user and the archiving admins by default
    this.initialFileRights = [this.profile.id]
    this.initialFileRights = this.initialFileRights.concat(
      this.selfContactListFromSubscription
        .filter((user) => uniqueArchivingAdminUser(user, [...this.value.fileRights, ...this.initialFileRights]))
        .map((user) => user.id),
    )
    this.value.fileRights = [...this.initialFileRights, ...this.value.fileRights]
  },
  methods: {
    ...mapActions([GET_SELF_CONTACT_LIST]),
    toggleSelectAllMembers () {
      // If we already checked everything, we should deselect them and go back to the initialFileRights
      // otherwise, select them all
      if (this.allMembersChecked) {
        this.value.fileRights = [...this.initialFileRights]
      } else {
        this.value.fileRights = this.knownUsers.map((user) => {
          return user.id
        })
      }
    },
  },
}
</script>

<style scoped>
.members-container {
  max-height: 200px;
  overflow-y: auto;
}

.row-border {
  border-bottom: 1px solid #e0e0e0;
}
</style>
