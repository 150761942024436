<template>
  <Pane :value="filtersPaneIsOpen"
        :tabs="tabs"
        :right="false"
        current-tab="filters"
        temporary
        @input="onPaneValueChanged"
  >
    <template #content-filters>
        <v-row>
          <v-col>
            <div class="font-weight-bold mb-2">{{ $t('project.documents.DocumentsFiltersPane.dates') }}</div>
            <AppDatePicker v-model="localFilters.dates" range label="" />
          </v-col>
        </v-row>
        <template v-if="members.length > 0">
          <v-divider class="my-4"/>
          <v-row>
            <v-col>
              <div class="font-weight-bold mb-2">{{ $t('project.documents.DocumentsFiltersPane.members') }}</div>
              <div>
                <v-checkbox v-for="(member, index) in members.length > 10 && !displayMoreMembers ? shortMembers : members"
                            :key="`member-${index}`"
                            v-model="localFilters.members"
                            :value="member"
                            :label="member"
                            hide-details
                            class="my-1"
                />
              </div>
            </v-col>
          </v-row>
          <v-row v-if="members.length > 10 || displayMoreMembers">
            <v-col>
              <div class="displayMoreMembers text-h4 clickable"
                  @click="displayMoreMembers = true"
              >
                {{ $t('project.documents.DocumentsFiltersPane.displayMoreMembers') }}
              </div>
            </v-col>
          </v-row>
          <v-row v-if="members.length > 10 && displayMoreMembers">
            <v-col>
              <div class="displayLessMembers text-h4 clickable"
                  @click="displayMoreMembers = false"
              >
                {{ $t('project.documents.DocumentsFiltersPane.displayLessMembers') }}
              </div>
            </v-col>
          </v-row>
        </template>
      <v-row>
        <v-col>
          <AppButton
                      block
                      @click="onClearAllFilters"
          >
            {{$t('common.clear')}}
          </AppButton>
        </v-col>
        <v-col>
          <AppButton color="primary"
                      block
                      @click="onApplyFilters"
          >
            {{$t('common.apply')}}
          </AppButton>
        </v-col>
      </v-row>
    </template>
  </Pane>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import Pane from '@/common/Pane'
import { ISOToShortenedDate } from '@/common/utils/dates'
import { Filter } from '@/models/filter.model'
import { SET_DOCUMENT_FILTERS, SET_FILTERS_PANE_IS_OPEN } from '@/store/modules/documents/mutation_types'

import AppDatePicker from '../../common/AppDatePicker'
import AppButton from '../../common/buttons/AppButton'

const EMPTY_FILTERS = {
  dates: [],
  members: [],
}

export default {
  name: 'DocumentsFiltersPane',
  components: { Pane, AppButton, AppDatePicker },
  data () {
    return {
      displayMoreMembers: false,
      localFilters: { ...EMPTY_FILTERS },
      tabs: [{ id: 'filters', title: this.$t('common.filters') }],
    }
  },
  computed: {
    ...mapState('documents', ['documents', 'filters', 'filtersPaneIsOpen']),
    members () {
      const array = []
      if (this.documents.children) {
        this.documents.children.forEach(child => {
          if (child.type === 'file') {
            if (!array.includes(child.updateUser)) {
              array.push(child.updateUser)
            }
          }
        })
      }
      return array
    },
    shortMembers () {
      return this.members.slice(0, 10)
    },
  },
  watch: {
    filtersPaneIsOpen (value) {
      if (value) {
        this.syncAppliedFiltersWithLocals()
      }
    },
  },
  methods: {
    ...mapMutations('documents', [SET_DOCUMENT_FILTERS, SET_FILTERS_PANE_IS_OPEN]),
    applyFilters () {
      const datesFilters = this.localFilters.dates.length > 0
        ? [
          new Filter(
            'dates',
            this.$t('project.documents.DocumentsFiltersPane.dates'),
            this.localFilters.dates,
            `${ISOToShortenedDate(this.localFilters.dates[0])} - ${ISOToShortenedDate(this.localFilters.dates[1])}`,
          )]
        : []

      const membersFilters = this.localFilters.members.map(member =>
        new Filter('members', this.$t('common.user'), member, member),
      )

      const filters = [
        ...datesFilters,
        ...membersFilters,
      ]

      this.SET_DOCUMENT_FILTERS(filters)
    },
    closePane () {
      this.SET_FILTERS_PANE_IS_OPEN(false)
    },
    onApplyFilters () {
      this.applyFilters()
      this.closePane()
    },
    onClearAllFilters () {
      this.localFilters = { ...EMPTY_FILTERS }
      this.applyFilters()
    },
    onPaneValueChanged (isOpen) {
      if (!isOpen) {
        this.closePane()
      }
    },
    syncAppliedFiltersWithLocals () {
      const datesFilters = this.filters.find(filter => filter.category.key === 'dates')
      this.localFilters.dates = datesFilters?.value.key ?? []

      this.localFilters.members = this.filters
        .filter(filter => filter.category.key === 'members')
        .map(filter => filter.value.key)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-icon.notranslate.fas.fa-caret-down.theme--light {
  display: none;
}
::v-deep .v-text-field__details {
  display: none;
}
.displayMoreMembers:hover,
.displayLessMembers:hover {
  color: var(--v-primary-base);
}
</style>
