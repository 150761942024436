<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('archives.steps.ArchivesAddWarningDialog.title') }}</template>

    <template #body>
      <p>{{ $t("archives.steps.ArchivesAddWarningDialog.warning") }}</p>
      <p class="mb-0">{{ $t("archives.steps.ArchivesAddWarningDialog.confirmText") }}</p>
    </template>

    <template #footer>
      <v-checkbox
        v-model="doNotRemindMe"
        :label="$t('archives.steps.ArchivesAddWarningDialog.doNotRemindMeCheckboxLabel')"
        class="mt-0 pt-0"
        hide-details
      />
      <v-spacer />
      <AppButton @click="closeDialog">
        {{ $t('common.cancel') }}
      </AppButton>
      <AppButton
        color="primary"
        @click="confirmClick"
      >
        {{ $t('common.confirm') }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import AppButton from '../../common/buttons/AppButton.vue'
import AppDialog from '../../common/dialogs/AppDialog'
import { dialogMixin } from '../../mixins/dialogMixin'
import { SET_MUTE_ARCHIVING_WARNING } from '../../store/modules/archives/mutation_types'

const { mapMutations } = createNamespacedHelpers('archives')

export default {
  name: 'ArchivesAddWarningDialog',
  components: { AppButton, AppDialog },
  mixins: [dialogMixin],
  data () {
    return {
      doNotRemindMe: false,
    }
  },
  methods: {
    ...mapMutations([SET_MUTE_ARCHIVING_WARNING]),
    confirmClick () {
      this.$emit('confirm')
      if (this.doNotRemindMe) {
        this.SET_MUTE_ARCHIVING_WARNING(true)
      }
      this.closeDialog()
    },
  },
}
</script>
