export const state = {
  bibleError: null,
  biblePendingIds: [],
  bibles: [],
  biblesError: null,
  biblesPending: false,
  exportBibleToImanageError: null,
  exportBibleToImanagePending: false,
  postBibleError: null,
  postBiblePending: false,
}
