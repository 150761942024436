import {
  SET_PROJECTS_FOLDER_RENAME_DIALOG_IS_OPEN,
  SET_PROJECTS_FOLDER_RENAME_PENDING,
  SET_PROJECTS_FOLDER_TO_RENAME,
} from './mutation_types'

export const mutations = {
  [SET_PROJECTS_FOLDER_RENAME_DIALOG_IS_OPEN] (state, value) {
    state.projectsFolderRenameDialogIsOpen = value
  },
  [SET_PROJECTS_FOLDER_RENAME_PENDING] (state, value) {
    state.projectsFolderRenamePending = value
  },
  [SET_PROJECTS_FOLDER_TO_RENAME] (state, projectsFolderToRename) {
    state.projectsFolderToRename = projectsFolderToRename
  },
}
