<template>
  <div>
    <app-text variant="small-bold" class="d-flex align-center mb-4">
      <span class="stepper-number">1</span>{{ $t('project.checklist.dialogs.ImportChecklistDialog.downloadOurExcelTemplate') }}
    </app-text>
    <AppDownloadFile class="mb-6" :link="templateLink">
      <template #default="{ submitForm }">
        <app-button type="outlined"
                    variant="neutral"
                    @click="submitForm"
        >
          {{ $t('project.checklist.dialogs.ImportChecklistDialog.downloadExcelTemplate') }}
        </app-button>
      </template>
    </AppDownloadFile>

    <app-text variant="small-bold" class="d-flex align-center mb-4">
      <span class="stepper-number">2</span>{{ $t('project.checklist.dialogs.ImportChecklistDialog.uploadExcelFile') }}
    </app-text>
    <template v-if="isQuestionAnswerDialog">
      <IdentityCheckingIDFileInput refValue="QAExcelFileInput"
                                   describedby="QAExcelFileUploadButton"
                                   :allowedExtensions="allowedExtensions"
                                   :isDocumentErrored="!!hasImportErrors"
                                   @update-file="file = $event; updateFile()"
      >
      </IdentityCheckingIDFileInput>
    </template>
    <template v-else>
      <!-- File name -->
      <div v-if="file" class="imported-file w-100" :class="{ 'imported-file--error': hasImportErrors }">
        {{ file.name }}
        <app-button type="icon" class="ml-auto" @click="removeFile">
          <app-icon icon-name="times" size="medium"></app-icon>
        </app-button>
      </div>
      <!-- Dropzone -->
      <div v-else class="excel-file-dropzone">
        <AppDroppableArea class="excel-file-dropzone__droppable-area pa-12"
                          @drop="dropFile">
          <ClosdFildersIcon size="50" :document="{extension: 'xls'}" />
          <input ref="fileInput"
                 class="d-none"
                 type="file"
                 :accept="allowedExtensions"
                 @input="addFile"
          >
          <app-text variant="small-bold" class="mt-6">
            {{ $t('project.checklist.dialogs.ImportChecklistDialog.dropZoneText1') }}
          </app-text>
          <app-text variant="small-regular">
            {{ $t('project.checklist.dialogs.ImportChecklistDialog.dropZoneText2') }}
          </app-text>
          <app-button class="mt-4" @click="openFileInput">
            {{ $t('project.checklist.dialogs.ImportChecklistDialog.importFromComputer') }}
          </app-button>
        </AppDroppableArea>
      </div>
    </template>
  </div>
</template>

<script>
  import { getFilesFromDataTransferItems } from 'datatransfer-files-promise'

  import AppDownloadFile from '@/common/AppDownloadFile.vue'
  import AppDroppableArea from '@/common/AppDroppableArea.vue'
  import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon.vue'
  import IdentityCheckingIDFileInput from '@/common/identity-checking/IdentityCheckingIDFileInput.vue'

  export default {
    name: 'ImportChecklistDialogExcelFile',
    components: {
      AppDownloadFile,
      AppDroppableArea,
      ClosdFildersIcon,
      IdentityCheckingIDFileInput,
    },
    props: {
      isQuestionAnswerDialog: {
        type: Boolean,
        default: false,
      },
      templateLink: {
        type: String,
        required: true,
      },
      existingFile: {
        validator: (value) => {
          return value === null || typeof value === 'object'
        },
      },
      allowedExtensions: {
        type: Array,
        default: () => ['.xls', '.xlsx'],
      },
      hasImportErrors: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        file: null,
      }
    },
    created () {
      if (this.existingFile) {
        this.file = this.existingFile
      }
    },
    methods: {
      openFileInput () {
        this.$refs.fileInput.click()
      },
      addFile (event) {
        this.file = event.target.files[0]
        this.updateFile()
      },
      async dropFile (event) {
        const dataTransferItems = event.dataTransfer?.items
        if (dataTransferItems) {
          const droppedFiles = await getFilesFromDataTransferItems(dataTransferItems)
          this.file = droppedFiles[0]
          this.updateFile()
        }
      },
      removeFile () {
        this.file = null
        this.updateFile()
        if (this.hasImportErrors) {
          this.$emit('reset-import-errors')
        }
      },
      updateFile () {
        this.$emit('update-file', this.file)
      },
    },
  }
</script>

<style scoped lang="scss">
  .stepper-number {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: var(--v-grey-base);
    background-color: var(--v-grey-lighten4);
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
  }

  .excel-file-dropzone {
    border: 2px dashed #E0E0E0;
    border-radius: 7px;

    &__droppable-area {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .drag-in-progress {
      background-color: #F0F6FF;
      outline: 2px dashed #2671F2;
    }
  }
  .imported-file {
    display: inline-flex;
    align-items: center;
    padding: 4px 16px;
    border-radius: 4px;
    border: 1px solid var(--v-grey-lighten5);
    background: var(--v-originalGrey-lighten5);
    &--error {
      border: 1px solid var(--v-error-base);
    }
  }
</style>
