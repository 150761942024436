// This file contains all the mutations which was in index.ts before module structure creation
// TODO: Clean this file

import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_WARNING_SNACKBAR } from '@/store/mutation_types'

const SET_HOME_LAYOUT = (state, value) => {
  state.homeLayout = value
}
const SET_PROJECT_LAYOUT = (state, value) => {
  state.projectLayout = value
}
const SET_TOGGLE_PROJECT_DRAWER = (state, value) => {
  state.toggleProjectDrawer = value
}
const setIdentityCheckingStep = (state, val) => {
  state.identityCheckingStep = val
}
const setDialogDelete = (state, val) => {
  state.dialogDelete = val
}
const setDialogDisplaySigners = (state, val) => {
  state.dialogDisplaySigners = val
}
const setNavigationDrawerProfile = (state, val) => {
  state.navigationDrawerProfile = val
}
const setDialogMembersOfGroup = (state, val) => {
  state.dialogMembersOfGroup = val
}
const setDialogDeleteMember = (state, val) => {
  state.dialogDeleteMember = val
}
const setDialogAddMember = (state, val) => {
  state.dialogAddMember = val
}
const setDialogGenerateBible = (state, val) => {
  state.dialogGenerateBible = val
}
const setNavigationDrawerBibles = (state, val) => {
  state.navigationDrawerBibles = val
}
const setOverlay = (state, val) => {
  state.overlay = val
}
const setPermanentOverlay = (state, val) => {
  state.permanentOverlay = val
}
const setChat = (state, val) => {
  state.chat = val
}
const setTasks = (state, val) => {
  state.tasks = val
}
const setBibles = (state, val) => {
  state.bibles = val
}
const setNavigationDrawerDocuments = (state, val) => {
  state.navigationDrawerDocuments = val
}
const setNavigationDrawerTasks = (state, val) => {
  state.navigationDrawerTasks = val
}
const setNotifications = (state, val) => {
  state.notifications = val
}
const setSelectedProject = (state, val) => {
  state.selectedProject = val
}
const setNewProject = (state, val) => {
  state.newProject = val
}
const setSelectedTask = (state, val) => {
  state.selectedTask = val
}
const setProjectDrawerPinned = (state, val) => {
  state.projectDrawerPinned = val
}
const setDisplay = (state, val) => {
  state.display = val
}
const setBadgeProfileVisible = (state, val) => {
  state.badgeProfileVisible = val
}
const updateSnackbarQueue = (state, val) => {
  state.snackbars = val
}
const enqueueSnackbar = (state, val) => {
  // V2-566 If the message is already here, don't queue it more
  if (!state.snackbars.find((snackbar) => snackbar?.message === val?.message)) {
    state.snackbars.push(val)
  }
}

const setProjects = (state, val) => {
  state.projects = val
}
const setBreadcrumbs = (state, val) => {
  if (val.text) {
    state.breadcrumbs.push({
      text: val.text,
      index: state.breadcrumbs.length,
    })
  }
  if (val.index === 0 || val.index) {
    state.breadcrumbs.splice(
      val.index + 1,
      state.breadcrumbs.length - val.index,
    )
  }
}
const addProject = (state, val) => {
  state.projects.unshift(val)
}

const SET_ROUTER_HISTORY = (state, val) => {
  state.routerHistory.push(val)
}

export default {
  SET_HOME_LAYOUT,
  SET_PROJECT_LAYOUT,
  SET_TOGGLE_PROJECT_DRAWER,
  setIdentityCheckingStep,
  setDialogDelete,
  setDialogDisplaySigners,
  setNavigationDrawerProfile,
  setDialogMembersOfGroup,
  setDialogDeleteMember,
  setDialogAddMember,
  setDialogGenerateBible,
  setNavigationDrawerBibles,
  setOverlay,
  setPermanentOverlay,
  setChat,
  setTasks,
  setBibles,
  setNavigationDrawerDocuments,
  setNavigationDrawerTasks,
  setNotifications,
  setSelectedProject,
  setNewProject,
  setSelectedTask,
  setProjectDrawerPinned,
  setDisplay,
  setBadgeProfileVisible,
  updateSnackbarQueue,
  enqueueSnackbar,
  setProjects,
  setBreadcrumbs,
  addProject,
  SET_ROUTER_HISTORY,
  [ENQUEUE_SUCCESS_SNACKBAR] (state, message) {
    const snackbar = { message, color: 'success' }
    enqueueSnackbar(state, snackbar)
  },
  [ENQUEUE_ERROR_SNACKBAR] (state, message) {
    const snackbar = { message, color: 'error' }
    enqueueSnackbar(state, snackbar)
  },
  [ENQUEUE_WARNING_SNACKBAR] (state, message) {
    const snackbar = { message, color: 'warning' }
    enqueueSnackbar(state, snackbar)
  },
}
