export const state = {
  deleteTitleModal: null,
  sendSignStepModal: null,
  voidedToDraftStepModal: null,
  currentTitleEdit: null,
  cancelStepModal: null,
  deleteStepModal: null,
  swapFiles: null,
  swapFilesPending: null,
  setStepChildren: null,
  signingChecklistPending: false,
  signingChecklist: [],
  signingChecklistError: null,
  deleteValidationPending: false,
  deleteValidationError: null,
  postValidationPending: false,
  postValidationError: null,
  postMoveStepPending: false,
  postMoveStepError: null,
  refreshSigningChecklistPending: false,
  refreshSigningChecklistError: null,
  signingChecklistGroupsPending: false,
  signingChecklistGroups: null,
  signingChecklistGroupsError: null,
  createGroupError: null,
  deleteGroupPendingIds: [],
  deleteGroupError: null,
  moveStepPendingIds: [],
  moveStepError: null,
  moveGroupPendingIds: [],
  moveGroupError: null,
  signingViewPending: false,
  signingView: null,
  signingViewError: null,
  createSigningChecklistStepPendingIds: [],
  createSigningCheckListStepError: null,
  updateSigningChecklistStepPendingIds: [],
  updateSigningChecklistStepError: null,
  bulkUpsertSigningChecklistStepPending: false,
  bulkUpsertSigningChecklistStepError: null,
  postSignedFilePending: false,
  postSignedFileError: null,
  postFinalFilePending: false,
  postFinalFileError: null,
  compileFinalFilePending: false,
  compileFinalFileError: null,
  signatureFilesPending: false,
  signatureFiles: null,
  signatureFilesError: null,
  refreshStepPendingIds: [],
  refreshStepError: null,
  resetSignatureFilesPending: false,
  resetSignatureFilesError: null,
  selectedSteps: [],
  stepPendingIds: [],
  stepError: null,
  deleteStepPendingIds: [],
  deleteStepError: null,
  sendSignNowPendingIds: [],
  sendSignNowError: null,
  deletionTypePending: false,
  deletionType: null,
  deletionTypeError: null,
  cancelStepPendingIds: [],
  cancelStepError: null,
  validateThenSendNowPendingIds: [],
  validateThenSendNowError: null,
  resetStepPendingIds: [],
  resetStepError: null,
  createTitlePending: false,
  createTitleError: null,
  updateTitlePendingIds: [],
  updateTitleError: null,
  refreshStatusPending: false,
  refreshStatusError: null,
  refreshClosingStatusPending: false,
  refreshClosingStatusError: null,
  closingPredict: false,
  closingPredictPending: false,
  closingPredictError: false,
  closingSummaryPending: false,
  closingSummary: null,
  closingSummaryError: null,
  startClosingPending: false,
  startClosingError: null,
  startScheduledClosingPending: false,
  startScheduledClosingError: null,
  cancelScheduledClosingPending: false,
  cancelScheduledClosingError: null,
  cancelClosingPending: false,
  cancelClosingError: null,
  titleIndentationPendingIds: [],
  titleIndentationError: null,
  deleteTitlePendingIds: [],
  deleteTitleError: null,
  sendSignNowMultiplePendingIds: [],
  sendSignNowMultipleError: null,
  cancelStepMultiplePendingIds: [],
  cancelStepMultipleError: null,
  deleteStepMultiplePendingIds: [],
  deleteStepMultipleError: null,
  resetStepMultiplePendingIds: [],
  resetStepMultipleError: null,
  validateThenSendNowMultiplePendingIds: [],
  validateThenSendNowMultipleError: null,
  postLastCompiledFilePending: false,
  postLastCompiledFileError: null,
  deleteCompiledFilePending: false,
  deleteCompiledFileError: null,
  patchCompiledFilePending: false,
  patchCompiledFileError: null,
  pane: {
    isOpen: false,
    tab: '',
    fileToShare: null,
  },
  shareAttachmentPending: false,
  qualifiedDSWarningIsMuted: false,
  isExpandSigningChecklist: [],
  isSendingDocument: false,
  importedFiles: [],
  updateClosingEndDatePending: false,
  updateClosingEndDateError: null,
  // Filters
  filters: [],
  filtersPaneIsOpen: false,
  // Search
  searchQuery: [],
  searchQueryPending: false,
  envelopeStatsPending: false,
  envelopeStats: null,
  envelopeStatsError: null,
  envelopeDocumentsUploadQueue: {},
  totalDocuments: null,
}
