export const statusCategories = Object.freeze({
  DONE: 'done',
  DRAFT: 'draft',
  HELD: 'held',
  IN_PROGRESS: 'in_progress',
  FAILED: 'failed',
})

export const statusCategoryColors = Object.freeze({
  done: '#4caf50',
  draft: '#8d8d8d',
  held: '#ff9800',
  in_progress: '#ff9800',
  failed: '#ff5252',
})

export const customStatusesColors = Object.freeze([
  '#03a9f4',
  '#0097a7',
  '#8d8d8d',
  '#ff5252',
  '#b388ff',
  '#0288d1',
  '#4caf50',
  '#00bcd4',
  '#8bc34a',
  '#ff4081',
  '#ff9800',
  '#ff9e80',
  '#e64a19',
  '#8c9eff',
])
