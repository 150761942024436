<template>
  <v-list :class="{'mt-0 pt-0': $vuetify.breakpoint.smAndDown}">
    <v-list-item-group v-model="selectedItem" mandatory>
      <template v-for="(item, i) in items">
        <template v-if="item.title === 'separator'">
          <v-divider :key="i"></v-divider>
        </template>
        <template v-else>
          <slot name="section" :item="item">
            <ProjectNavigationSection :key="item.key"
                                      :title="item.title"
                                      :to="item.key"
                                      :icon="item.icon"
            />
          </slot>
        </template>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
  import ProjectNavigationSection from '@/project/navigation-drawer/ProjectNavigationSection.vue'

  export default {
    name: 'ProjectNavigationDrawerLayout',
    components: { ProjectNavigationSection },
    props: {
      items: {
        type: Array,
        required: true,
      },
      defaultSelectedItem: {
        required: true,
        default: '',
      },
    },
    data () {
      return {
        selectedItem: null,
      }
    },
    watch: {
      defaultSelectedItem: {
        handler (value) {
          this.selectedItem = value
        },
        immediate: true,
      },
    },
  }
</script>
