<template>
  <v-row class="add-member-from-team">
    <v-col>
      <app-text variant="small-bold" class="grey--text text--darken-4 mb-4">
        {{ $t('project.members.AddMemberFromTeam.selectTeam') }}
      </app-text>
      <!-- Select Team -->
      <AppCombobox :value="memberForm.team"
                   :items="subscriptionTeams"
                   :placeholder="$t('project.members.AddMemberFromTeam.searchTeam')"
                   :error-messages="teamErrors"
                   item-value="id"
                   item-text="name"
                   clearable
                   return-object
                   @input="handleInput"
                   @blur="model.memberForm.team.$touch"
      >
        <template #item="{item}">
          <AddTeamComboboxItem class="mb-2" :item="item" withMembers/>
        </template>
      </AppCombobox>

      <div v-if="memberForm.team" class="mt-4">
        <!-- Team -->
        <div class="d-flex align-center justify-space-between">
          <AddTeamComboboxItem :item="memberForm.team" />
          <app-button class="mr-2 button-with-high-z-index"
                      type="icon"
                      @click="memberForm.team = null">
            <app-icon icon-name="xmark" />
          </app-button>
        </div>

        <!-- See Members -->
        <AppFlatExpansionPanel style="padding-left: 45px; margin-top: -26px;">
          <template v-slot:title>
            <app-text variant="x-small-bold" class="my-0">
              {{ $t('project.members.AddMemberFromTeam.seeMemmbers') }}
            </app-text>
          </template>
          <template v-slot:content>
            <div style="max-height: 300px; overflow-y: auto;">
              <div v-for="user in memberForm.team.members"
                   :key="`user-${user.id}`"
                   class="ml-2"
              >
                <AddMemberFormComboboxItem class="mt-0 mb-3" :item="user" :withCompany="false" />
              </div>
            </div>
          </template>
        </AppFlatExpansionPanel>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'

  import AppCombobox from '@/common/AppCombobox.vue'
  import AppFlatExpansionPanel from '@/common/AppFlatExpansionPanel.vue'
  import AddMemberFormComboboxItem from '@/project/members/AddMemberFormComboboxItem.vue'
  import AddTeamComboboxItem from '@/project/members/AddTeamComboboxItem.vue'

  export default {
    name: 'AddMemberFromTeam',
    components: {
      AppCombobox,
      AppFlatExpansionPanel,
      AddMemberFormComboboxItem,
      AddTeamComboboxItem,
    },
    props: {
      model: {
        type: Object,
        required: true,
      },
      memberForm: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapState('subscription', ['subscriptionTeams']),
      teamErrors () {
        const errors = []
        if (!this.model.memberForm.team.$dirty) return errors
        !this.model.memberForm.team.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.form.team') }))
        return errors
      },
    },
    methods: {
      handleInput (value) {
        const selectedTeam = this.subscriptionTeams.find(team => team.id === value?.id)
        if (selectedTeam) {
          this.$emit('input', value)
        } else {
          this.$emit('input', null)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .add-member-from-team {
    /* button is covered by the panel expansion from below */
    .button-with-high-z-index {
      z-index: 2;
    }
  }
</style>
