<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-disabled="changeSignerPending || selectedMembers.length === 0"
             :ok-loading="changeSignerPending"
             :ok-text="$t('common.change')"
             size="l"
             :cancel-disabled="changeSignerPending"
             @cancel="closeDialog"
             @ok="prepareChangeSigner"
  >
    <template #title>
      {{
        isSignerWitness
          ? $t('project.signing-checklist.prepare-signature.PrepareSignatureChangeSignerDialog.modifyWitness')
          : $t('project.signing-checklist.prepare-signature.PrepareSignatureChangeSignerDialog.modifyCommonSignatory')
      }}
    </template>
    <template #body>
      <template v-if="!componentLoading">
        <v-row>
          <v-col class="subtitle-2">
            {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureChangeSignerDialog.selectSignatory', {name: signer.fullName}) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DocumentSharePicker :force-pm-selection="false"
                                  :multi-select="false"
                                  users-only
            />
          </v-col>
        </v-row>
      </template>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import DocumentSharePicker from '@/common/document-share/DocumentSharePicker'
import { dialogMixin } from '@/mixins/dialogMixin'
import {
  DISABLE_MEMBERS,
} from '@/store/modules/document-share-picker/action_types'
import { CHANGE_SIGNER } from '@/store/modules/prepareSignature/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'PrepareSignatureChangeSignerDialog',
  components: {
    AppDialog,
    DocumentSharePicker,
  },
  props: {
    signer: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      componentLoading: false,
    }
  },
  computed: {
    ...mapState('prepareSignature', ['changeSignerPending', 'taggingInformation', 'envelopeDisplayInformation']),
    ...mapState('documentSharePicker', ['selectedMembers']),
    ...mapState('groups', ['groups']),
    ...mapGetters('room', ['roomMnemo']),
    isSignerWitness () {
      return this.signer.signatureType === 'witness'
    },
  },
  mixins: [dialogMixin],
  methods: {
    ...mapActions('groups', ['GET_GROUPS']),
    ...mapActions('documentSharePicker', [DISABLE_MEMBERS]),
    ...mapActions('prepareSignature', [CHANGE_SIGNER]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    isMemberSelected (memberId) {
      return this.taggingInformation.signers.find(signersGroup => signersGroup.some(signer => signer.signerId === memberId))
    },
    async onOpen () {
      this.componentLoading = true
      try {
        this.openDialog = true
        await this.getGroups(this.roomMnemo)

        const membersToDisable = this.groups.flatMap(group => group.members)
          .filter(member => this.isMemberSelected(member.id))

        if (membersToDisable.length) {
          this.DISABLE_MEMBERS(membersToDisable)
        }
      } finally {
        this.componentLoading = false
      }
    },
    async prepareChangeSigner () {
      try {
        await this.CHANGE_SIGNER({
          mnemo: this.roomMnemo,
          envId: this.envelopeDisplayInformation.envelopeId,
          oldUserId: this.signer.signerId,
          data: {
            newSigner: this.selectedMembers[0].id,
          },
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureChangeSignerDialog.changeSigner.successText'))
        this.$emit('update-signers-list')
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureChangeSignerDialog.changeSigner.errorText'))
      } finally {
        this.closeDialog()
      }
    },
  },
}
</script>
