import {
  REMOVE_PATCH_SUB_ADMIN_BILLING_PENDING,
  REMOVE_PATCH_SUB_ADMIN_PENDING,
  REMOVE_PATCH_SUB_ADMIN_USER_PENDING,
  SET_ACTIVE_ROOMS,
  SET_ACTIVE_ROOMS_ERROR,
  SET_ACTIVE_ROOMS_PENDING,
  SET_CLOSED_ROOMS,
  SET_CLOSED_ROOMS_ERROR,
  SET_CLOSED_ROOMS_PENDING,
  SET_DELETE_USER_ERROR,
  SET_DELETE_USER_PENDING,
  SET_DOWNLOAD_INVOICES_ERROR,
  SET_DOWNLOAD_INVOICES_PENDING,
  SET_PATCH_SUB_ADMIN_BILLING_ERROR,
  SET_PATCH_SUB_ADMIN_BILLING_PENDING,
  SET_PATCH_SUB_ADMIN_ERROR,
  SET_PATCH_SUB_ADMIN_PENDING,
  SET_PATCH_SUB_ADMIN_USER_ERROR,
  SET_PATCH_SUB_ADMIN_USER_PENDING,
  SET_POST_NEW_SUBSCRIBER_ERROR,
  SET_POST_NEW_SUBSCRIBER_PENDING,
  SET_POST_SUBSCRIPTION_TEAM_PENDING,
  SET_PATCH_SUBSCRIPTION_TEAM_PENDING,
  SET_SELECTED_SUBSCRIPTION_TEAM,
  SET_SUBSCRIPTION_TEAMS_PENDING,
  SET_SUBSCRIPTION_TEAMS,
  SET_SUBSCRIPTION_TEAMS_ERROR,
  SET_QUOTE_REQUEST_PENDING,
  SET_RESTORE_ROOM_ERROR,
  SET_RESTORE_ROOM_PENDING,
  SET_SEATS,
  SET_SEATS_ERROR,
  SET_RESTORE_ROOM_DIALOG,
  SET_SEATS_PENDING,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_ERROR,
  SET_SUBSCRIPTION_BRANDING,
  SET_SUBSCRIPTION_INVOICES,
  SET_SUBSCRIPTION_INVOICES_ERROR,
  SET_SUBSCRIPTION_INVOICES_PENDING,
  SET_SUBSCRIPTION_PENDING,
  SET_SUBSCRIPTION_BRANDING_PENDING,
  SET_SUBSCRIPTION_USERS,
  SET_SUBSCRIPTION_USERS_ERROR,
  SET_SUBSCRIPTION_USERS_PENDING,
  SET_USER_SUB_ADMIN,
  SET_USER_SUB_ADMIN_BILLING,
  SET_USER_SUB_ADMIN_USER,
  SET_CLOSED_ROOMS_FILTERS,
  REMOVE_CLOSED_ROOMS_FILTER,
  SET_CLOSED_ROOMS_FILTERS_PANE_IS_OPEN,
  SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN,
  SET_ACTIVE_ROOMS_FILTERS,
  REMOVE_ACTIVE_ROOMS_FILTER,
  SET_ACTIVE_ROOMS_FILTERS_PANE_IS_OPEN,
  SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG,
  SET_DELETE_SUBSCRIPTION_TEAM_PENDING,
  SET_DELETE_TEAM_DIALOG,
  REMOVE_SUBSCRIPTION_TEAM,
} from './mutation_types'

export const mutations = {
  [SET_DELETE_TEAM_DIALOG] (state, value) {
    state.deleteTeamDialog = value
  },
  [SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG] (state, value) {
    state.projectTypeQuoteRequestDialog = value
  },
  [SET_RESTORE_ROOM_DIALOG] (state, value) {
    state.restoreRoomDialog = value
  },
  [SET_ACTIVE_ROOMS_FILTERS_PANE_IS_OPEN] (state, value) {
    state.activeRoomsFiltersPaneIsOpen = value
  },
  [REMOVE_ACTIVE_ROOMS_FILTER] (state, filterToRemove) {
    state.activeRoomsFilters = state.activeRoomsFilters.filter(
      filter => filter.category.key !== filterToRemove.category.key || filter.value.key !== filterToRemove.value.key,
    )
  },
  [SET_ACTIVE_ROOMS_FILTERS] (state, value) {
    state.activeRoomsFilters = value
  },
  [SET_CLOSED_ROOMS_FILTERS_PANE_IS_OPEN] (state, value) {
    state.closedRoomsFiltersPaneIsOpen = value
  },
  [SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN] (state, value) {
    state.subscriptionPaneIsOpen = value
  },
  [REMOVE_CLOSED_ROOMS_FILTER] (state, filterToRemove) {
    state.closedRoomsFilters = state.closedRoomsFilters.filter(
      filter => filter.category.key !== filterToRemove.category.key || filter.value.key !== filterToRemove.value.key,
    )
  },
  [SET_CLOSED_ROOMS_FILTERS] (state, value) {
    state.closedRoomsFilters = value
  },
  [SET_SUBSCRIPTION_PENDING] (state, value) {
    state.subscriptionPending = value
  },
  [SET_SUBSCRIPTION] (state, value) {
    state.subscription = value
  },
  [SET_SUBSCRIPTION_BRANDING_PENDING] (state, value) {
    state.subscriptionBrandingPending = value
  },
  [SET_SUBSCRIPTION_BRANDING] (state, value) {
    state.subscriptionBranding = value
  },
  [SET_SUBSCRIPTION_ERROR] (state, value) {
    state.subscriptionError = value
  },
  [SET_SUBSCRIPTION_USERS_PENDING] (state, value) {
    state.subscriptionUsersPending = value
  },
  [SET_SUBSCRIPTION_USERS] (state, value) {
    state.subscriptionUsers = value
  },
  [SET_SUBSCRIPTION_USERS_ERROR] (state, value) {
    state.subscriptionUsersError = value
  },
  [SET_PATCH_SUB_ADMIN_PENDING] (state, value) {
    state.patchSubAdminPendingIds.push(value)
  },
  [REMOVE_PATCH_SUB_ADMIN_PENDING] (state, index) {
    state.patchSubAdminPendingIds.splice(index, 1)
  },
  [SET_PATCH_SUB_ADMIN_ERROR] (state, value) {
    state.patchSubAdminError = value
  },
  [SET_USER_SUB_ADMIN] (state, userId) {
    const user = state.subscriptionUsers.data.find(user => user.id === userId)
    if (user) {
      user.isSubAdmin = !user.isSubAdmin
    }
  },
  [SET_SEATS] (state, value) {
    state.seats = value
  },
  [SET_SEATS_PENDING] (state, value) {
    state.seatsPending = value
  },
  [SET_SEATS_ERROR] (state, value) {
    state.seatsError = value
  },
  [SET_PATCH_SUB_ADMIN_BILLING_PENDING] (state, value) {
    state.patchSubAdminBillingPendingIds.push(value)
  },
  [REMOVE_PATCH_SUB_ADMIN_BILLING_PENDING] (state, index) {
    state.patchSubAdminBillingPendingIds.splice(index, 1)
  },
  [SET_PATCH_SUB_ADMIN_BILLING_ERROR] (state, value) {
    state.patchSubAdminBillingError = value
  },
  [SET_USER_SUB_ADMIN_BILLING] (state, userId) {
    const user = state.subscriptionUsers.data.find(user => user.id === userId)
    if (user) {
      user.isSubBillingAdmin = !user.isSubBillingAdmin
    }
  },
  [SET_USER_SUB_ADMIN_USER] (state, userId) {
    const user = state.subscriptionUsers.data.find(user => user.id === userId)
    if (user) {
      user.isSubUser = !user.isSubUser
    }
  },
  [SET_PATCH_SUB_ADMIN_USER_PENDING] (state, value) {
    state.patchSubAdminUserPendingIds.push(value)
  },
  [REMOVE_PATCH_SUB_ADMIN_USER_PENDING] (state, index) {
    state.patchSubAdminUserPendingIds.splice(index, 1)
  },
  [SET_PATCH_SUB_ADMIN_USER_ERROR] (state, value) {
    state.patchSubAdminUserError = value
  },
  [SET_DELETE_USER_PENDING] (state, value) {
    state.deleteUserPending = value
  },
  [SET_DELETE_USER_ERROR] (state, value) {
    state.deleteUserError = value
  },
  [SET_POST_NEW_SUBSCRIBER_PENDING] (state, value) {
    state.postNewSubscriberPending = value
  },
  [SET_POST_NEW_SUBSCRIBER_ERROR] (state, value) {
    state.postNewSubscriberError = value
  },
  [SET_ACTIVE_ROOMS_PENDING] (state, value) {
    state.activeRoomsPending = value
  },
  [SET_ACTIVE_ROOMS] (state, value) {
    state.activeRooms = value
  },
  [SET_ACTIVE_ROOMS_ERROR] (state, value) {
    state.activeRoomsError = value
  },
  [SET_CLOSED_ROOMS_PENDING] (state, value) {
    state.closedRoomsPending = value
  },
  [SET_CLOSED_ROOMS] (state, value) {
    state.closedRooms = value
  },
  [SET_CLOSED_ROOMS_ERROR] (state, value) {
    state.closedRoomsError = value
  },
  [SET_POST_SUBSCRIPTION_TEAM_PENDING] (state, value) {
    state.postSubscriptionTeamPending = value
  },
  [SET_PATCH_SUBSCRIPTION_TEAM_PENDING] (state, value) {
    state.patchSubscriptionTeamPending = value
  },
  [SET_DELETE_SUBSCRIPTION_TEAM_PENDING] (state, value) {
    state.deleteSubscriptionTeamPending = value
  },
  [SET_SELECTED_SUBSCRIPTION_TEAM] (state, value) {
    state.selectedSubscriptionTeam = value
  },
  [SET_SUBSCRIPTION_TEAMS_PENDING] (state, value) {
    state.subscriptionTeamsPending = value
  },
  [SET_SUBSCRIPTION_TEAMS] (state, value) {
    state.subscriptionTeams = value
  },
  [REMOVE_SUBSCRIPTION_TEAM] (state, index) {
    state.subscriptionTeams.splice(index, 1)
  },
  [SET_SUBSCRIPTION_TEAMS_ERROR] (state, value) {
    state.subscriptionTeamsError = value
  },
  [SET_SUBSCRIPTION_INVOICES_PENDING] (state, value) {
    state.subscriptionInvoicesPending = value
  },
  [SET_SUBSCRIPTION_INVOICES] (state, value) {
    state.subscriptionInvoices = value
  },
  [SET_SUBSCRIPTION_INVOICES_ERROR] (state, value) {
    state.subscriptionInvoicesError = value
  },
  [SET_DOWNLOAD_INVOICES_PENDING] (state, value) {
    state.downloadInvoicesPending = value
  },
  [SET_DOWNLOAD_INVOICES_ERROR] (state, value) {
    state.downloadInvoicesError = value
  },
  [SET_RESTORE_ROOM_PENDING] (state, value) {
    state.restoreRoomPending = value
  },
  [SET_RESTORE_ROOM_ERROR] (state, value) {
    state.restoreRoomError = value
  },
  [SET_QUOTE_REQUEST_PENDING] (state, value) {
    state.quoteRequestPending = value
  },
}
