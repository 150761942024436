<template>
  <div>
    <div class="mb-4">
      <slot />
    </div>
    <div v-for="child in item.children"
         :key="child.id" :class="`ml-${depth * INDENT_MARGIN}`" class="mb-4">
      <span :class="{ 'font-weight-medium': isTitle(child) }">{{ `${child.display}. ${(child.title || '')}` }}</span>
      <SavedChecklistPreviewAttachments v-if="child.nbAttachments" :task="child"/>

      <SavedChecklistPreviewTitle v-if="child.children"
                                  :item="child"
                                  :depth="depth + 1"
      />
    </div>
  </div>
</template>

<script>
  import SavedChecklistPreviewAttachments from './SavedChecklistPreviewAttachments.vue'

  const INDENT_MARGIN = 3

  export default {
    name: 'SavedChecklistPreviewTitle',
    components: {
      SavedChecklistPreviewAttachments,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      depth: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        INDENT_MARGIN,
      }
    },
    methods: {
      isTitle (child) {
        return child.type === 'title'
      },
    },
  }
</script>
