import { STATUS_SUCCESS } from '@/common/utils/httpStatus'
import service from '@/services/polyOffice.service'

import {
  GET_ACTION_INFO,
  GET_EXPORT_IFRAME,
  GET_IMPORT_IFRAME,
  RESET_DIALOG,
} from './action_types'
import {
  SET_ACTION_TOKEN,
  SET_DIALOG_ERROR,
  SET_DIALOG_IFRAME_URL,
  SET_DIALOG_IS_OPEN,
  SET_DIALOG_PENDING,
  SET_DIALOG_SUCCESS,
} from './mutation_types'

export const actions = {
  async [GET_ACTION_INFO] ({ state }) {
    const response = await service.getInfo(state.actionToken)

    if (response.status === STATUS_SUCCESS) {
      return response.data
    }
  },

  async [GET_IMPORT_IFRAME] ({ commit, dispatch }, { payload, successMessage }) {
    commit(SET_DIALOG_PENDING, true)
    try {
      const response = await service.postImport(payload)

      if (response.status === STATUS_SUCCESS) {
        if (!response.data?.fullIframeUrl) {
          throw new Error('Missing fullIframeUrl !')
        }
        if (!response.data?.actionToken) {
          throw new Error('Missing actionToken !')
        }
        commit(SET_DIALOG_IFRAME_URL, response.data.fullIframeUrl)
        commit(SET_ACTION_TOKEN, response.data.actionToken)
        commit(SET_DIALOG_IS_OPEN, true)
        commit(SET_DIALOG_SUCCESS, successMessage)
      }
      return response.data
    } catch (error) {
      commit(SET_DIALOG_IS_OPEN, false)
      commit(SET_DIALOG_ERROR, error)
      throw error
    } finally {
      commit(SET_DIALOG_PENDING, false)
    }
  },

  async [GET_EXPORT_IFRAME] ({ commit, dispatch }, { payload, successMessage }) {
    commit(SET_DIALOG_PENDING, true)
    try {
      const response = await service.postExport(payload)
      if (response.status === STATUS_SUCCESS) {
        if (!response.data?.fullIframeUrl) {
          throw new Error('Missing fullIframeUrl !')
        }
        if (!response.data?.actionToken) {
          throw new Error('Missing actionToken !')
        }
        commit(SET_DIALOG_IFRAME_URL, response.data.fullIframeUrl)
        commit(SET_ACTION_TOKEN, response.data.actionToken)
        commit(SET_DIALOG_IS_OPEN, true)
        commit(SET_DIALOG_SUCCESS, successMessage)
      }
      return response.data
    } catch (error) {
      commit(SET_DIALOG_IS_OPEN, false)
      commit(SET_DIALOG_ERROR, error)
      throw error
    } finally {
      commit(SET_DIALOG_PENDING, false)
    }
  },

  [RESET_DIALOG] ({ commit }) {
    commit(SET_ACTION_TOKEN, null)
    commit(SET_DIALOG_IS_OPEN, false)
    commit(SET_DIALOG_IFRAME_URL, null)
    commit(SET_DIALOG_PENDING, false)
  },
}
