import documentsService from '@/services/documents.service'
import { GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'
import {
  CLOSE_DOCUMENT_RENAMING_DIALOG,
  OPEN_DOCUMENT_RENAMING_DIALOG,
  RENAME_DOCUMENT,
} from '@/store/modules/documents-rename/action_types'

export const actions = {
  [OPEN_DOCUMENT_RENAMING_DIALOG] ({ commit }, documentToRename) {
    commit('SET_DOCUMENT_TO_RENAME', documentToRename)
    commit('SET_IS_OPEN', true)
  },
  [CLOSE_DOCUMENT_RENAMING_DIALOG] ({ commit }) {
    commit('SET_IS_OPEN', false)
    commit('SET_LOADING', false)
    commit('SET_DOCUMENT_TO_RENAME', {})
  },
  [RENAME_DOCUMENT] ({ commit, rootGetters, dispatch, state }, newDocumentName) {
    commit('SET_LOADING', true)
    return documentsService.renameDocument(rootGetters['room/roomMnemo'], state.documentToRename, newDocumentName)
      .then(() => {
        dispatch(`documents/${GET_DOCUMENTS_BY_ID}`, {
          mnemo: undefined,
          id: undefined,
          queryObject: {
            markRecent: true,
          },
        }, { root: true })
        dispatch(CLOSE_DOCUMENT_RENAMING_DIALOG)
      })
      .catch(() => {
        commit('SET_LOADING', false)
      })
  },
}
