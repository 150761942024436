<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             :ok-disabled="noFilesSelected || !dataReady"
             :ok-text="$t('archives.steps.ArchivesClosdFileBrowserDialog.okTitle')"
             @cancel="closeDialog"
             @ok="exportFiles"
  >
    <template #title>{{ $t("archives.steps.ArchivesClosdFileBrowserDialog.title") }}</template>

    <template #body>
      <template v-if="dataReady">
        <v-row>
          <v-col>
            <AppSelect
              id="selectRoom"
              v-model="selectedRoom"
              :items="closdProjectsItems"
              :label="$t('archives.steps.ArchivesClosdFileBrowserDialog.lblSelectRoom')"
              @change="getRoomFiles"
            />
          </v-col>
        </v-row>
        <v-row v-show="selectedRoom">
          <v-col>
            <v-card>
              <v-tabs v-model="currentTab" fixed-tabs>
                <v-tab href="#documents">{{ $t("archives.steps.ArchivesClosdFileBrowserDialog.header1") }}</v-tab>

                <template v-if="selectedRoom && !selectedRoom.isDataroom">
                  <v-tab href="#todos">{{ $t("archives.steps.ArchivesClosdFileBrowserDialog.header2") }}</v-tab>
                  <v-tab href="#signatures">{{ $t('archives.steps.ArchivesClosdFileBrowserDialog.header3') }}</v-tab>
                </template>
              </v-tabs>
              <v-tabs-items v-model="currentTab">
                <v-tab-item value="documents">
                  <v-card-text style="max-height: 300px; overflow-y: auto">
                    <template v-if="documentsFolder">
                      <template v-if="documentsFolder.children.length !== 0">
                        <AppClosdFildersTree id="documentsPicker"
                                             v-model="selectedDocuments[selectedRoom.mnemo]"
                                             :rootFolder="documentsFolder"
                                             multiSelect
                                             openFirstNode
                        />
                      </template>
                      <template v-else>
                        <div class="caption text-center">
                          {{$t('archives.steps.ArchivesClosdFileBrowserDialog.noDocuments')}}
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="text-center">
                        <v-progress-circular
                          style="width: 48px; margin: 100px 0"
                          color="primary"
                          size="48"
                          indeterminate
                        />
                      </div>
                    </template>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item value="todos">
                  <v-card-text style="max-height: 300px; overflow-y: auto">
                    <template v-if="sortedTodosFolder">
                      <template v-if="sortedTodosFolder.children.length !== 0">
                        <AppClosdFildersTree id="todosPicker"
                                             v-model="selectedTodos[selectedRoom.mnemo]"
                                             :rootFolder="todosFolder"
                                             multiSelect
                                             openFirstNode
                        />
                      </template>
                      <template v-else>
                        <div class="caption text-center">
                          {{$t('archives.steps.ArchivesClosdFileBrowserDialog.noTasks')}}
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="text-center">
                        <v-progress-circular
                          style="width: 48px; margin: 100px 0"
                          color="primary"
                          size="48"
                          indeterminate
                        />
                      </div>
                    </template>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item value="signatures">
                  <v-card-text style="max-height: 300px; overflow-y: auto">
                    <template v-if="mySignaturesFolder">
                      <template v-if="mySignaturesFolder.children.length !== 0">
                        <AppFildersTree id="signaturesPicker"
                                        v-model="selectedSignatures[selectedRoom.mnemo]"
                                        :rootFolder="mySignaturesFolder"
                                        isSignature
                                        multiSelect
                                        openFirstNode
                        >
                          <template v-slot:append="{ item }">
                            <span v-if="item.time && $vuetify.breakpoint.smAndUp">{{ $t("archives.steps.ArchivesClosdFileBrowserDialog.myOrigSignedOn") + " " + formatSignTime(item.time) }}</span>
                          </template>
                        </AppFildersTree>
                      </template>
                      <template v-else>
                        <div class="caption text-center">
                          {{ $t('archives.steps.ArchivesClosdFileBrowserDialog.noSignedDocuments') }}
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="text-center">
                        <v-progress-circular
                          style="width: 48px; margin: 100px 0"
                          color="primary"
                          size="48"
                          indeterminate
                        />
                      </div>
                    </template>
                  </v-card-text>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <div class="d-flex align-center justify-center">
          <v-progress-circular
            style="width: 48px; margin: 100px 0"
            color="primary"
            size="48"
            indeterminate
          />
        </div>
      </template>
    </template>
  </AppDialog>
</template>

<script>
import dayjs from 'dayjs'
import { cloneDeep, flatMapDeep, orderBy } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'

import { GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'

import AppClosdFildersTree from '../../common/app-closd-filders-tree/AppClosdFildersTree'
import AppSelect from '../../common/AppSelect'
import AppFildersTree from '../../common/AppVuetifyFildersTree.vue'
import AppDialog from '../../common/dialogs/AppDialog'
import { dialogMixin } from '../../mixins/dialogMixin'
import { GET_MY_ENVELOPES } from '../../store/modules/envelopes/action_types'
import { GET_TODOS_FOLDER } from '../../store/modules/todo/action_types'

const { mapState: mapRoomsState, mapActions: mapRoomsActions } = createNamespacedHelpers('rooms')
const { mapState: mapDocumentsState, mapActions: mapDocumentsActions } = createNamespacedHelpers('documents')
const { mapState: mapTodoState, mapActions: mapTodoActions } = createNamespacedHelpers('todo')
const { mapState: mapEnvelopesState, mapActions: mapEnvelopesActions } = createNamespacedHelpers('envelopes')

export default {
  name: 'ArchivesClosdFileBrowserDialog',
  components: {
    AppDialog,
    AppFildersTree,
    AppSelect,
    AppClosdFildersTree,
  },
  mixins: [dialogMixin],
  data () {
    return {
      closdFileBrowserModalIsOpen: false,
      selectedRoom: null,
      documentsFolder: null,
      sortedTodosFolder: null,
      mySignaturesFolder: null,
      closdRooms: null,
      selectedDocuments: null,
      selectedTodos: null,
      selectedSignatures: null,
      processing: false,
      dataReady: false,
      currentTab: 'documents',
    }
  },
  computed: {
    ...mapRoomsState(['rooms']),
    ...mapDocumentsState(['documents']),
    ...mapTodoState(['todosFolder']),
    ...mapEnvelopesState(['myEnvelopes']),
    noFilesSelected () {
      if (this.selectedSignatures && this.selectedTodos && this.selectedSignatures) {
        let docEmpty = true
        let todoEmpty = true
        let signatureEmpty = true
        for (const value of Object.values(this.selectedDocuments)) {
          if (value.length > 0) docEmpty = false
        }
        for (const value of Object.values(this.selectedTodos)) {
          if (value.length > 0) todoEmpty = false
        }
        for (const value of Object.values(this.selectedSignatures)) {
          if (value.length > 0) signatureEmpty = false
        }
        return docEmpty && todoEmpty && signatureEmpty
      }
      return false
    },
    closdProjectsItems () {
      return this.closdRooms.map((room) => ({
        value: room,
        text: room.name,
      }))
    },
  },
  async created () {
    await this.getRooms({ mini: true })
    this.closdRooms = this.rooms
    if (this.rooms) {
      const selectedItems = {}
      for (const room of this.rooms) {
        selectedItems[room.mnemo] = []
      }
      this.selectedDocuments = cloneDeep(selectedItems)
      this.selectedTodos = cloneDeep(selectedItems)
      this.selectedSignatures = cloneDeep(selectedItems)
    }
    this.dataReady = true
  },
  methods: {
    ...mapRoomsActions(['getRooms']),
    ...mapDocumentsActions([GET_DOCUMENTS_BY_ID]),
    ...mapTodoActions([GET_TODOS_FOLDER]),
    ...mapEnvelopesActions([GET_MY_ENVELOPES]),
    getRoomFiles () {
      this.documentsFolder = null
      this.sortedTodosFolder = null
      this.mySignaturesFolder = null
      this.getDocumentsFolder()

      if (this.selectedRoom && !this.selectedRoom.isDataroom) {
        this.getTodosFolder()
        this.getMySignaturesFolder()
      }
    },
    async getDocumentsFolder () {
      if (this.selectedRoom) {
        await this.GET_DOCUMENTS_BY_ID({
          mnemo: this.selectedRoom.mnemo,
          queryObject: { ignoreTodoFolder: true, lazyMode: false },
          id: 0,
        })
        this.documentsFolder = {
          ...this.documents,
          children: orderBy(this.documents.children, ['type', 'basename'], ['desc', 'asc']),
        }
      }
    },
    async getTodosFolder () {
      await this.GET_TODOS_FOLDER({
        mnemo: this.selectedRoom.mnemo,
        queryObject: { lazyMode: false },
      })
      this.sortedTodosFolder = {
        ...this.todosFolder,
        children: orderBy(this.todosFolder.children, ['type', 'basename'], ['desc', 'asc']),
      }
    },
    async getMySignaturesFolder () {
      await this.GET_MY_ENVELOPES({
        mnemo: this.selectedRoom.mnemo,
        queryObject: { lazyMode: false, downloadableOnly: true },
      })
      this.mySignaturesFolder = {
        basename: this.$t('archives.steps.ArchivesClosdFileBrowserDialog.header3'),
        name: this.$t('archives.steps.ArchivesClosdFileBrowserDialog.header3'),
        children: orderBy(this.myEnvelopes, ['type', 'basename'], ['desc', 'asc']),
      }
    },
    exportFiles (e) {
      if (e) e.preventDefault()
      this.processing = true
      const flatFiles = this.getFlatFiles()
      this.$emit('selectedFiles', flatFiles)
      this.closeDialog()
    },
    getFlatFiles () {
      const flatFiles = []
      for (const value of Object.values(this.selectedDocuments)) {
        if (value.length > 0) {
          for (const file of value) {
            if (file.type === 'file') {
              flatFiles.push(this.preformatFile(file))
            } else if (file.type === 'folder' && file.childrenCount !== 0) {
              flatFiles.push(this.getChildrenFlatFiles(file))
            }
          }
        }
      }
      for (const value of Object.values(this.selectedTodos)) {
        if (value.length > 0) {
          for (const file of value) {
            if (file.type === 'file') {
              flatFiles.push(this.preformatFile(file))
            } else if (file.type === 'folder' && file.childrenCount !== 0) {
              flatFiles.push(this.getChildrenFlatFiles(file))
            }
          }
        }
      }
      for (const value of Object.values(this.selectedSignatures)) {
        if (value.length > 0) {
          for (const file of value) {
            if (file.type === 'file' || file.hasOwnProperty('status')) {
              flatFiles.push(this.preformatFile(file))
            } else if (file.type === 'folder' && file.childrenCount !== 0) {
              flatFiles.push(this.getChildrenFlatFiles(file))
            }
          }
        }
      }
      return flatMapDeep(flatFiles)
    },
    // Preformat a file that's being exported by modifying and returning it
    preformatFile (f) {
      if (this.selectedRoom.cmRef) {
        f.fileRef = this.selectedRoom.cmRef
      }
      return f
    },
    getChildrenFlatFiles (f) {
      const childrenFiles = []
      if (f.hasOwnProperty('children')) {
        for (const c of f.children) {
          childrenFiles.push(this.getChildrenFlatFiles(c))
        }
      } else {
        childrenFiles.push(this.preformatFile(f))
      }
      return childrenFiles
    },
    formatSignTime (signTime) {
      return dayjs(signTime).format(`DD/MM/YYYY [${this.$t('common.at')}] HH:mm:ss`)
    },
  },
}
</script>
