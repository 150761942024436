<template>
  <AppDialog :is-open="isDialogOpened"
             size="m"
             :ok-text="$t('common.confirm')"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>{{ $t('project.signing-checklist.SigningChecklistOrderDeleteGroupDialog.title') }}</template>

    <template #body>
      <p>{{ $t("project.signing-checklist.SigningChecklistOrderDeleteGroupDialog.warning") }}</p>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from '../../common/dialogs/AppDialog'
import { dialogMixin } from '../../mixins/dialogMixin'

export default {
  name: 'SigningChecklistOrderDeleteGroupDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  methods: {
    confirmClick () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
