<template>
    <div>
      <AppDialog :is-open="openDialog"
                 size="xxxl"
                 ok-only
                 :ok-text="$t('common.close')"
                 @cancel="$emit('setOpenDialog',false)"
                 @ok="$emit('setOpenDialog',false)"
      >
        <template #title> {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogButton.title') }} </template>
        <template #body>
          <v-row>
            <v-col>
              <Alert>
                {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogButton.alertText') }}
              </Alert>
            </v-col>
          </v-row>
          <v-row v-for="step in filteredSteps"
                 :key="`envelope-${step.id}`"
          >
            <v-col>
              <PdfSigningPmDialogEnvelope v-if="openDialog"
                                          :mnemo="mnemo"
                                          :step="step"
                                          @refreshSignedDocuments="refreshSignedDocuments"
              />
            </v-col>
          </v-row>
        </template>
        <template #footer-infos>
          <AppDownloadFile :link="downloadAllSignaturePagesLink"
                           class="mr-2"
          >
            <template #default="{submitForm}">
              <AppButton @click="submitForm">
                {{ $t('project.signing-checklist.pdf-signing.PdfSigningPmDialogButton.downloadAllSignaturesPages') }}
              </AppButton>
            </template>
          </AppDownloadFile>
        </template>
      </AppDialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { GET_SIGNED_DOCUMENTS } from '@/store/modules/signed-documents/action_types'

import PdfSigningPmDialogEnvelope from './PdfSigningPmDialogEnvelope'
import Alert from '../../../common/alerts/Alert'
import AppDownloadFile from '../../../common/AppDownloadFile'
import AppButton from '../../../common/buttons/AppButton'
import AppDialog from '../../../common/dialogs/AppDialog'

export default {
  name: 'PdfSigningPmDialogButton',
  components: { AppDownloadFile, PdfSigningPmDialogEnvelope, Alert, AppDialog, AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    openDialog: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('signingChecklist', ['flatSigningChecklist']),
    filteredSteps () {
      return this.flatSigningChecklist.filter(s => s.envelope && s.envelope.provider === 'pdf')
    },
    downloadAllSignaturePagesLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.$route.params.mnemo}/downloads/envelopePdfSignedFile`
    },
  },
  async mounted () {
    await this.GET_SIGNED_DOCUMENTS({ mnemo: this.$route.params.mnemo, params: { withRights: true, downloadableOnly: true } })
  },
  methods: {
    ...mapActions('signedDocuments', [GET_SIGNED_DOCUMENTS]),
    async refreshSignedDocuments () {
      await this.GET_SIGNED_DOCUMENTS({ mnemo: this.$route.params.mnemo, params: { withRights: true, downloadableOnly: true } })
    },
  },
}
</script>
