import { SurveyId, surveyCanBeTriggered, triggerSurvey } from '@/plugins/satismeter'

import {
  ADD_ALL_ITEMS_TO_SELECTION,
  ADD_TASK_TO_SELECTION,
  ADD_TITLE_CHILDREN_TO_SELECTION,
  ADD_TITLE_TO_SELECTION,
  CLEAR_ITEMS_SELECTION,
  CLOSE_CHECKLIST_PANE,
  CLOSE_SAVE_CHECKLIST_DIALOG,
  CLOSE_IMPORT_CHECKLIST_DIALOG,
  CLOSE_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG,
  COLLAPSE_ALL_TITLES,
  COLLAPSE_TITLE,
  CONTACT_RESPONSIBLE,
  CREATE_TASK,
  CREATE_TITLE,
  DELETE_FILDERS,
  DELETE_SAVED_CHECKLIST,
  SHARE_SAVED_CHECKLIST,
  RENAME_SAVED_CHECKLIST,
  DELETE_CHECKLIST_DOUBLE_VALIDATION,
  DELETE_TABLE_SELECTION,
  DELETE_TASK,
  DELETE_TASK_COMMENT,
  DELETE_TITLE,
  DUPLICATE_TASK,
  EDIT_TASK,
  EDIT_TITLE,
  EXPAND_ALL_TITLES,
  EXPAND_TITLE,
  GET_ATTACHED_FILE_VERSIONS,
  POST_ATTACHED_FILE_VERSION,
  SHARE_TASKS,
  SAVE_CHECKLIST,
  UPDATE_CHECKLIST,
  GET_SAVED_CHECKLISTS,
  GET_SELF_SAVED_CHECKLISTS,
  GET_SAVED_CHECKLIST_PREVIEW,
  GET_LEXISNEXIS_CHECKLISTS,
  GET_LEXISNEXIS_CHECKLIST_PREVIEW,
  IMPORT_CHECKLIST_FROM_CLOSD,
  IMPORT_CHECKLIST_FROM_EXCEL,
  GET_TASK_COMMENTS,
  POST_TASK_COMMENT,
  GET_TASK_STATUS_CATEGORIES,
  REFRESH_TASK,
  GET_CHECKLIST,
  GET_REFRESH_CHECKLIST,
  GET_TASK_FOLDER,
  MOVE_FILDERS,
  OPEN_SAVE_CHECKLIST_DIALOG,
  OPEN_SHARE_CHECKLIST_BY_EMAIL_PANE,
  POST_MOVE_TASK,
  REMOVE_TASK_FROM_SELECTION,
  REMOVE_TITLE_CHILDREN_FROM_SELECTION,
  REMOVE_TITLE_FROM_SELECTION,
  RENAME_ATTACHED_FILE,
  SHARE_ATTACHED_FILES,
  SHARE_CHECKLIST_BY_EMAIL,
  POST_STATUS,
  TITLE_INDENTATION,
  DELETE_SEARCH_RESULTS,
  GET_SEARCH_RESULTS,
  GET_GLOBAL_TASK_FOLDER,
  DOWNLOAD_TODO_FILES,
  CHANGE_CHECKLIST_VERSIONING_PANE_TAB,
  CLOSE_CHECKLIST_VERSIONING_PANE,
  SET_CHECKLIST_VERSIONING_PANE,
} from './action_types'
import {
  REMOVE_FROM_EXPAND_TASK_AFTER_DELETE,
  REMOVE_TASK_COMMENTS_PENDING,
  REMOVE_TASK_FOLDER_PENDING,
  SET_ATTACHED_FILE_VERSIONS,
  SET_ATTACHED_FILE_VERSIONS_ERROR,
  SET_ATTACHED_FILE_VERSIONS_PENDING,
  SET_CHECKLIST,
  SET_CHECKLIST_ERROR,
  SET_CHECKLIST_PENDING,
  SET_CONTACT_RESPONSIBLE_ERROR,
  SET_CONTACT_RESPONSIBLE_PENDING,
  SET_CREATE_TASK_ERROR,
  SET_CREATE_TASK_PENDING,
  SET_CREATE_TITLE_ERROR,
  SET_CREATE_TITLE_PENDING,
  SET_DELETE_FILDERS_ERROR,
  SET_DELETE_FILDERS_PENDING,
  SET_DELETE_SAVED_CHECKLIST_PENDING,
  SET_DELETE_SAVED_CHECKLIST_ERROR,
  SET_SHARE_SAVED_CHECKLIST_PENDING,
  SET_SHARE_SAVED_CHECKLIST_ERROR,
  SET_RENAME_SAVED_CHECKLIST_PENDING,
  SET_RENAME_SAVED_CHECKLIST_ERROR,
  SET_SELECTED_SAVED_CHECKLIST,
  SET_DELETE_TABLE_SELECTION_ERROR,
  SET_DELETE_CHECKLIST_DOUBLE_VALIDATION_PENDING,
  SET_DELETE_TABLE_SELECTION_PENDING,
  SET_DELETE_TASK_COMMENT_ERROR,
  SET_DELETE_TASK_COMMENT_PENDING,
  SET_DELETE_TASK_ERROR,
  SET_DELETE_TASK_PENDING,
  SET_DELETE_TITLE_ERROR,
  SET_DELETE_TITLE_PENDING,
  SET_DUPLICATE_TASK_ERROR,
  SET_DUPLICATE_TASK_PENDING,
  SET_EDIT_TASK,
  SET_EDIT_TASK_ERROR,
  SET_EDIT_TASK_PENDING,
  SET_EDIT_TITLE,
  SET_EDIT_TITLE_ERROR,
  SET_EDIT_TITLE_PENDING,
  SET_IMPORT_CHECKLIST_FROM_CLOSD_ERROR,
  SET_IMPORT_CHECKLIST_FROM_CLOSD_PENDING,
  SET_IMPORT_CHECKLIST_FROM_EXCEL_PENDING,
  SET_LAST_TASK_ADDED,
  SET_LAST_TITLE_ADDED,
  SET_MOVE_FILDERS_ERROR,
  SET_MOVE_FILDERS_PENDING,
  SET_PANE,
  SET_POST_ATTACHED_FILE_VERSIONS_ERROR,
  SET_POST_ATTACHED_FILE_VERSIONS_PENDING,
  SET_POST_MOVE_TASK_ERROR,
  SET_POST_MOVE_TASK_PENDING,
  SET_POST_TASK_COMMENT_ERROR,
  SET_POST_TASK_COMMENT_PENDING,
  SET_REFRESH_CHECKLIST,
  SET_REFRESH_CHECKLIST_ERROR,
  SET_REFRESH_CHECKLIST_PENDING,
  SET_REFRESH_TASK,
  SET_REFRESH_TASK_ERROR,
  SET_REFRESH_TASK_PENDING,
  SET_RENAME_ATTACHED_FILE_ERROR,
  SET_RENAME_ATTACHED_FILE_PENDING,
  SET_SAVED_CHECKLISTS,
  SET_SAVED_CHECKLISTS_ERROR,
  SET_SAVED_CHECKLISTS_PENDING,
  SET_SELF_SAVED_CHECKLISTS_PENDING,
  SET_SELF_SAVED_CHECKLISTS,
  SET_SAVED_CHECKLIST_PREVIEW_PENDING,
  SET_SAVED_CHECKLIST_PREVIEW,
  SET_SAVED_CHECKLIST_PREVIEW_ERROR,
  SET_LEXISNEXIS_CHECKLISTS_PENDING,
  SET_LEXISNEXIS_CHECKLISTS,
  SET_LEXISNEXIS_CHECKLISTS_ERROR,
  SET_LEXISNEXIS_CHECKLIST_PREVIEW_PENDING,
  SET_LEXISNEXIS_CHECKLIST_PREVIEW,
  SET_LEXISNEXIS_CHECKLIST_PREVIEW_ERROR,
  SET_SAVE_CHECKLIST_DIALOG_IS_OPEN,
  SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN,
  SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN,
  SET_SAVE_CHECKLIST_ERROR,
  SET_SAVE_CHECKLIST_PENDING,
  SET_UPDATE_CHECKLIST_PENDING,
  SET_SELECTED_TASKS,
  SET_SELECTED_TITLES,
  SET_SHARE_ATTACHED_FILES_ERROR,
  SET_SHARE_ATTACHED_FILES_PENDING,
  SET_SHARE_CHECKLIST_BY_EMAIL_PENDING,
  SET_POST_STATUS_PENDING,
  SET_POST_STATUS_ERROR,
  SET_SHARE_TASKS_ERROR,
  SET_SHARE_TASKS_PENDING,
  SET_TASK_COMMENTS_ERROR,
  SET_TASK_COMMENTS_PENDING,
  SET_TASK_FOLDER,
  SET_TASK_FOLDER_ERROR,
  SET_TASK_FOLDER_PENDING,
  SET_TITLE_INDENTATION_ERROR,
  SET_TITLE_INDENTATION_PENDING,
  REMOVE_REFRESH_TASK_PENDING,
  SET_TASK_STATUS_CATEGORIES_PENDING,
  SET_TASK_STATUS_CATEGORIES_ERROR,
  SET_TASK_STATUS_CATEGORIES,
  REMOVE_POST_TASK_COMMENT_PENDING,
  REMOVE_DELETE_TASK_COMMENT_PENDING,
  SET_TITLES_EXPAND_STATE,
  SET_SEARCH_QUERY,
  SET_SEARCH_QUERY_PENDING,
  SET_GLOBAL_TASK_FOLDER,
  SET_GLOBAL_TASK_FOLDER_PENDING,
  SET_DOWNLOAD_TODO_FILES_PENDING,
  SET_CHECKLIST_VERSIONING_PANE_TAB,
  TOGGLE_CHECKLIST_VERSIONING_PANE,
} from './mutation_types'
import service from '../../../services/checklist.service'
import documentsService from '../../../services/documents.service'

export const actions = {
  async [DOWNLOAD_TODO_FILES] ({ commit }, { mnemo, data }) {
    commit(SET_DOWNLOAD_TODO_FILES_PENDING, true)
    try {
      const response = await service.downloadTodoFiles(mnemo, data)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_DOWNLOAD_TODO_FILES_PENDING, false)
    }
  },
  async [GET_GLOBAL_TASK_FOLDER] ({ commit }, mnemo) {
    commit(SET_GLOBAL_TASK_FOLDER_PENDING, true)
    try {
      const { data } = await service.getAllTodoFiles(mnemo)
      commit(SET_GLOBAL_TASK_FOLDER, data.files)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_GLOBAL_TASK_FOLDER_PENDING, false)
    }
  },
  async [POST_STATUS] ({ commit }, { mnemo, data }) {
    commit(SET_POST_STATUS_PENDING, true)
    try {
      const response = await service.postStatus(mnemo, data)
      commit(SET_TASK_STATUS_CATEGORIES, response.data.statuses)
      return !!response.data.success
    } catch (error) {
      commit(SET_POST_STATUS_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_STATUS_PENDING, false)
    }
  },
  async [DUPLICATE_TASK] ({ commit }, { mnemo, taskId }) {
    commit(SET_DUPLICATE_TASK_PENDING, true)
    try {
      await service.duplicateTask(mnemo, taskId)
    } catch (error) {
      commit(SET_DUPLICATE_TASK_ERROR, error)
      throw error
    } finally {
      commit(SET_DUPLICATE_TASK_PENDING, false)
    }
  },
  async [GET_REFRESH_CHECKLIST] ({ commit, state }, { mnemo, params, isPolling = false }) {
    commit(SET_REFRESH_CHECKLIST_PENDING, true)
    try {
      const { data } = await service.getChecklist(mnemo, params, isPolling)
      if (!state.currentTitleEdit && data && typeof data === 'object' && data.titles) {
        const fakeTitle = data.titles.find(el => el.id === 0 && el.type === 'title')
        if (fakeTitle) {
          fakeTitle.display = 0
        }
        commit(SET_REFRESH_CHECKLIST, data.titles.sort((a, b) => a.display - b.display) || [])
      }
    } catch (error) {
      commit(SET_REFRESH_CHECKLIST_ERROR, error)
      throw error
    } finally {
      commit(SET_REFRESH_CHECKLIST_PENDING, false)
    }
  },
  async [CONTACT_RESPONSIBLE] ({ commit }, { mnemo, taskId, data }) {
    commit(SET_CONTACT_RESPONSIBLE_PENDING, true)
    try {
      await service.contactResponsible(mnemo, taskId, data)
    } catch (error) {
      commit(SET_CONTACT_RESPONSIBLE_ERROR, error)
      throw error
    } finally {
      commit(SET_CONTACT_RESPONSIBLE_PENDING, false)
    }
  },
  async [DELETE_TASK] ({ commit }, { mnemo, taskId }) {
    commit(SET_DELETE_TASK_PENDING, true)
    try {
      await service.deleteTask(mnemo, taskId)
    } catch (error) {
      commit(SET_DELETE_TASK_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_TASK_PENDING, false)
    }
  },
  async [DELETE_CHECKLIST_DOUBLE_VALIDATION] ({ commit }, { mnemo, data }) {
    commit(SET_DELETE_CHECKLIST_DOUBLE_VALIDATION_PENDING, true)
    try {
      await service.deleteChecklistDoubleValidation(mnemo, data)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_DELETE_CHECKLIST_DOUBLE_VALIDATION_PENDING, false)
    }
  },
  async [DELETE_TABLE_SELECTION] ({ commit, dispatch, getters, rootGetters }) {
    commit(SET_DELETE_TABLE_SELECTION_PENDING, true)

    const roomMnemo = rootGetters['room/roomMnemo']
    const taskIds = getters.selectedTasks.map(task => task.id)
    const titleIds = getters.selectedTitles.map(title => title.id)

    try {
      await service.deleteChecklistItems(roomMnemo, { taskIds, titleIds })
      dispatch(CLEAR_ITEMS_SELECTION)
      dispatch(GET_CHECKLIST, roomMnemo)
    } catch (error) {
      commit(SET_DELETE_TABLE_SELECTION_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_TABLE_SELECTION_PENDING, false)
    }
  },
  async [POST_MOVE_TASK] ({ commit }, { mnemo, item, data }) {
    commit(SET_POST_MOVE_TASK_PENDING, true)
    try {
      if (item.type === 'title') {
        await service.postMoveTitle(mnemo, item.id, data)
      } else if (item.type === 'task') {
        await service.postMoveTask(mnemo, item.id, data)
      }
    } catch (error) {
      commit(SET_POST_MOVE_TASK_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_MOVE_TASK_PENDING, false)
    }
  },
  async [POST_ATTACHED_FILE_VERSION] ({ commit }, { mnemo, documentId, data }) {
    commit(SET_POST_ATTACHED_FILE_VERSIONS_PENDING, true)
    try {
      await service.postAttachedFileVersion(mnemo, documentId, data)
    } catch (error) {
      commit(SET_POST_ATTACHED_FILE_VERSIONS_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_ATTACHED_FILE_VERSIONS_PENDING, false)
    }
  },
  async [GET_ATTACHED_FILE_VERSIONS] ({ commit }, { mnemo, fileId }) {
    commit(SET_ATTACHED_FILE_VERSIONS_PENDING, true)
    try {
      const response = await service.getAttachedFileVersions(mnemo, fileId)
      commit(SET_ATTACHED_FILE_VERSIONS, response.data)
    } catch (error) {
      commit(SET_ATTACHED_FILE_VERSIONS_ERROR, error)
      throw error
    } finally {
      commit(SET_ATTACHED_FILE_VERSIONS_PENDING, false)
    }
  },
  async [RENAME_ATTACHED_FILE] ({ commit }, { mnemo, fileId, data }) {
    commit(SET_RENAME_ATTACHED_FILE_PENDING, true)
    try {
      await service.renameAttachedFile(mnemo, fileId, data)
    } catch (error) {
      commit(SET_RENAME_ATTACHED_FILE_ERROR, error)
      throw error
    } finally {
      commit(SET_RENAME_ATTACHED_FILE_PENDING, false)
    }
  },
  async [DELETE_FILDERS] ({ commit }, { mnemo, data }) {
    commit(SET_DELETE_FILDERS_PENDING, true)
    try {
      await service.deleteFilders(mnemo, data)
    } catch (error) {
      commit(SET_DELETE_FILDERS_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_FILDERS_PENDING, false)
    }
  },
  async [MOVE_FILDERS] ({ commit }, { mnemo, data }) {
    commit(SET_MOVE_FILDERS_PENDING, true)
    try {
      await service.moveFilders(mnemo, data)
    } catch (error) {
      commit(SET_MOVE_FILDERS_ERROR, error)
      throw error
    } finally {
      commit(SET_MOVE_FILDERS_PENDING, false)
    }
  },
  async [SHARE_ATTACHED_FILES] ({ commit }, { mnemo, data }) {
    commit(SET_SHARE_ATTACHED_FILES_PENDING, true)
    try {
      await service.shareAttachedFiles(mnemo, data)
    } catch (error) {
      commit(SET_SHARE_ATTACHED_FILES_ERROR, error)
      throw error
    } finally {
      commit(SET_SHARE_ATTACHED_FILES_PENDING, false)
    }
  },
  async [GET_TASK_FOLDER] ({ commit, state }, { mnemo, folderId, queryObject }) {
    commit(SET_TASK_FOLDER_PENDING, folderId)
    try {
      const response = await documentsService.getDocumentsById(mnemo, folderId, queryObject)
      commit(SET_TASK_FOLDER, response.data)
    } catch (error) {
      commit(SET_TASK_FOLDER_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_TASK_FOLDER_PENDING,
        state.taskFolderPendingIds.indexOf(folderId))
    }
  },
  async [REFRESH_TASK] ({ commit, state }, { mnemo, taskId, parent }) {
    commit(SET_REFRESH_TASK_PENDING, taskId)
    try {
      const response = await service.getTask(mnemo, taskId)
      commit(SET_REFRESH_TASK, { newTask: response.data.task, parent })
    } catch (error) {
      commit(SET_REFRESH_TASK_ERROR, error)
    } finally {
      commit(REMOVE_REFRESH_TASK_PENDING,
        state.refreshTaskPendingIds.indexOf(taskId))
    }
  },
  async [SHARE_TASKS] ({ commit }, { mnemo, data }) {
    commit(SET_SHARE_TASKS_PENDING, true)
    try {
      await service.shareTasks(mnemo, data)
    } catch (error) {
      commit(SET_SHARE_TASKS_ERROR, error)
      throw error
    } finally {
      commit(SET_SHARE_TASKS_PENDING, false)
    }
  },
  async [DELETE_SAVED_CHECKLIST] ({ commit }, { todoListId }) {
    commit(SET_DELETE_SAVED_CHECKLIST_PENDING, true)
    try {
      await service.deleteSavedChecklist(todoListId)
      commit(SET_SELECTED_SAVED_CHECKLIST, null)
    } catch (error) {
      commit(SET_DELETE_SAVED_CHECKLIST_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_DELETE_SAVED_CHECKLIST_PENDING, false)
    }
  },
  async [SHARE_SAVED_CHECKLIST] ({ commit }, { todoListId, data }) {
    commit(SET_SHARE_SAVED_CHECKLIST_PENDING, true)
    try {
      await service.shareSavedChecklist(todoListId, data)
    } catch (error) {
      commit(SET_SHARE_SAVED_CHECKLIST_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_SHARE_SAVED_CHECKLIST_PENDING, false)
    }
  },
  async [RENAME_SAVED_CHECKLIST] ({ commit }, { todoListId, name }) {
    commit(SET_RENAME_SAVED_CHECKLIST_PENDING, true)
    try {
      await service.renameSavedChecklist(todoListId, name)
    } catch (error) {
      commit(SET_RENAME_SAVED_CHECKLIST_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_RENAME_SAVED_CHECKLIST_PENDING, false)
    }
  },
  async [IMPORT_CHECKLIST_FROM_CLOSD] ({ commit }, { mnemo, data }) {
    commit(SET_IMPORT_CHECKLIST_FROM_CLOSD_PENDING, true)
    try {
      await service.importChecklistFromClosd(mnemo, data)
    } catch (error) {
      commit(SET_IMPORT_CHECKLIST_FROM_CLOSD_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_IMPORT_CHECKLIST_FROM_CLOSD_PENDING, false)
    }
  },
  async [IMPORT_CHECKLIST_FROM_EXCEL] ({ commit, dispatch }, { mnemo, data }) {
    commit(SET_IMPORT_CHECKLIST_FROM_EXCEL_PENDING, true)
    try {
      await service.importChecklistFromExcel(mnemo, data)
      dispatch(GET_CHECKLIST, mnemo)
    } finally {
      commit(SET_IMPORT_CHECKLIST_FROM_EXCEL_PENDING, false)
    }
  },
  async [GET_SAVED_CHECKLISTS] ({ commit }) {
    commit(SET_SAVED_CHECKLISTS_PENDING, true)
    commit(SET_SAVED_CHECKLISTS_ERROR, null)
    try {
      const response = await service.getSavedChecklists()
      commit(SET_SAVED_CHECKLISTS, response.data)
    } catch (error) {
      commit(SET_SAVED_CHECKLISTS_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_SAVED_CHECKLISTS_PENDING, false)
    }
  },
  async [GET_SELF_SAVED_CHECKLISTS] ({ commit }) {
    commit(SET_SELF_SAVED_CHECKLISTS_PENDING, true)
    try {
      const response = await service.getSelfSavedChecklists()
      commit(SET_SELF_SAVED_CHECKLISTS, response.data.data)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_SELF_SAVED_CHECKLISTS_PENDING, false)
    }
  },
  async [GET_SAVED_CHECKLIST_PREVIEW] ({ commit }, { todoListId }) {
    commit(SET_SAVED_CHECKLIST_PREVIEW_PENDING, true)
    commit(SET_SAVED_CHECKLIST_PREVIEW_ERROR, null)
    try {
      const response = await service.getSavedChecklistPreview(todoListId)
      if (response?.data?.titles) {
        commit(SET_SAVED_CHECKLIST_PREVIEW, response.data.titles)
      }
    } catch (error) {
      commit(SET_SAVED_CHECKLIST_PREVIEW_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_SAVED_CHECKLIST_PREVIEW_PENDING, false)
    }
  },
  async [GET_LEXISNEXIS_CHECKLISTS] ({ commit }) {
    commit(SET_LEXISNEXIS_CHECKLISTS_PENDING, true)
    commit(SET_LEXISNEXIS_CHECKLISTS_ERROR, null)
    try {
      const response = await service.getLexisNexisChecklists()
      if (response?.data?.checklists) {
        commit(SET_LEXISNEXIS_CHECKLISTS, response.data.checklists)
      }
    } catch (error) {
      commit(SET_LEXISNEXIS_CHECKLISTS_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_LEXISNEXIS_CHECKLISTS_PENDING, false)
    }
  },
  async [GET_LEXISNEXIS_CHECKLIST_PREVIEW] ({ commit }, { todoListId }) {
    commit(SET_LEXISNEXIS_CHECKLIST_PREVIEW_PENDING, true)
    commit(SET_LEXISNEXIS_CHECKLIST_PREVIEW_ERROR, null)
    try {
      const response = await service.getLexisNexisChecklistPreview(todoListId)
      if (response?.data?.titles) {
        commit(SET_LEXISNEXIS_CHECKLIST_PREVIEW, response.data.titles)
      }
    } catch (error) {
      commit(SET_LEXISNEXIS_CHECKLIST_PREVIEW_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_LEXISNEXIS_CHECKLIST_PREVIEW_PENDING, false)
    }
  },
  async [SAVE_CHECKLIST] ({ commit }, { mnemo, data }) {
    commit(SET_SAVE_CHECKLIST_PENDING, true)
    try {
      await service.saveChecklist(mnemo, data)
    } catch (error) {
      console.error(error)
      commit(SET_SAVE_CHECKLIST_ERROR, error)
      throw error
    } finally {
      commit(SET_SAVE_CHECKLIST_PENDING, false)
    }
  },
  async [UPDATE_CHECKLIST] ({ commit }, { mnemo, taskId, data }) {
    commit(SET_UPDATE_CHECKLIST_PENDING, true)
    try {
      await service.updateChecklist(mnemo, taskId, data)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_UPDATE_CHECKLIST_PENDING, false)
    }
  },
  async [DELETE_TASK_COMMENT] ({ commit, state }, { mnemo, taskId, commentId }) {
    commit(SET_DELETE_TASK_COMMENT_PENDING, taskId)
    try {
      await service.deleteTaskComment(mnemo, taskId, commentId)
    } catch (error) {
      commit(SET_DELETE_TASK_COMMENT_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_DELETE_TASK_COMMENT_PENDING,
        state.deleteTaskCommentPendingIds.indexOf(taskId))
    }
  },
  async [POST_TASK_COMMENT] ({ commit, state }, { mnemo, taskId, data }) {
    commit(SET_POST_TASK_COMMENT_PENDING, taskId)
    try {
      await service.postTaskComment(mnemo, taskId, data)
    } catch (error) {
      commit(SET_POST_TASK_COMMENT_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_POST_TASK_COMMENT_PENDING,
        state.postTaskCommentPendingIds.indexOf(taskId))
    }
  },
  async [GET_TASK_COMMENTS] ({ commit, state }, { mnemo, taskId, params }) {
    commit(SET_TASK_COMMENTS_PENDING, taskId)
    try {
      const response = await service.getTaskComments(mnemo, taskId, params)
      return response.data.comments
    } catch (error) {
      commit(SET_TASK_COMMENTS_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_TASK_COMMENTS_PENDING,
        state.taskCommentsPendingIds.indexOf(taskId))
    }
  },
  async [GET_TASK_STATUS_CATEGORIES] ({ commit, state }, { mnemo }) {
    commit(SET_TASK_STATUS_CATEGORIES_PENDING, true)
    try {
      const response = await service.getTaskStatuses(mnemo)
      commit(SET_TASK_STATUS_CATEGORIES, response.data.statuses)
    } catch (error) {
      commit(SET_TASK_STATUS_CATEGORIES_ERROR, error)
      throw error
    } finally {
      commit(SET_TASK_STATUS_CATEGORIES_PENDING, false)
    }
  },
  async [EDIT_TASK] ({ commit }, { parent, mnemo, taskId, data }) {
    commit(SET_EDIT_TASK_PENDING, taskId)
    try {
      const response = await service.patchTask(mnemo, taskId, data)
      commit(SET_EDIT_TASK, { parent, item: response.data })
    } catch (error) {
      commit(SET_EDIT_TASK_ERROR, error)
      throw error
    } finally {
      commit(SET_EDIT_TASK_PENDING, false)
    }
  },
  async [TITLE_INDENTATION] ({ commit }, { mnemo, titleId, action }) {
    commit(SET_TITLE_INDENTATION_PENDING, true)
    try {
      if (action === 'increment') {
        await service.incrementTitleIndentation(mnemo, titleId)
      } else if (action === 'decrement') {
        await service.decrementTitleIndentation(mnemo, titleId)
      }
    } catch (error) {
      commit(SET_TITLE_INDENTATION_ERROR, error)
      throw error
    } finally {
      commit(SET_TITLE_INDENTATION_PENDING, false)
    }
  },
  async [DELETE_TITLE] ({ commit, dispatch }, { mnemo, titleId }) {
    commit(SET_DELETE_TITLE_PENDING, true)
    try {
      await service.deleteTitle(mnemo, titleId)
      commit(REMOVE_FROM_EXPAND_TASK_AFTER_DELETE, titleId)
      dispatch(GET_CHECKLIST, mnemo)
    } catch (error) {
      commit(SET_DELETE_TITLE_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_TITLE_PENDING, false)
    }
  },
  async [EDIT_TITLE] ({ commit }, { parent, mnemo, item, data }) {
    const { id } = item
    commit(SET_EDIT_TITLE_PENDING, id)
    try {
      const response = await service.patchTitle(mnemo, id, data)
      item.title = response.data.title
      commit(SET_EDIT_TITLE, { parent, item })
    } catch (error) {
      commit(SET_EDIT_TITLE_ERROR, error)
      throw error
    } finally {
      commit(SET_EDIT_TITLE_PENDING, false)
    }
  },
  async [CREATE_TITLE] ({ commit, dispatch }, { mnemo, data }) {
    commit(SET_CREATE_TITLE_PENDING, true)
    try {
      const response = await service.postTitle(mnemo, data)
      commit(SET_LAST_TITLE_ADDED, response.data)
      await dispatch(GET_CHECKLIST, mnemo)
    } catch (error) {
      commit(SET_CREATE_TITLE_ERROR, error)
      throw error
    } finally {
      commit(SET_CREATE_TITLE_PENDING, false)
    }
  },
  async [CREATE_TASK] ({ commit, dispatch, rootState }, { mnemo, data }) {
    commit(SET_CREATE_TASK_PENDING, true)
    try {
      const response = await service.postTask(mnemo, data)
      await dispatch(GET_CHECKLIST, mnemo)
      commit(SET_LAST_TASK_ADDED, response.data)

      const { id: userId, fullName, email, isSubUser } = rootState.user.profile
      if (surveyCanBeTriggered(SurveyId.CSAT, userId)) {
        triggerSurvey(SurveyId.CSAT, userId, fullName, email, isSubUser)
      }
      if (surveyCanBeTriggered(SurveyId.NPS, userId)) {
        triggerSurvey(SurveyId.NPS, userId, fullName, email, isSubUser)
      }
    } catch (error) {
      commit(SET_CREATE_TASK_ERROR, error)
      throw error
    } finally {
      commit(SET_CREATE_TASK_PENDING, false)
    }
  },
  async [GET_CHECKLIST] ({ commit }, mnemo) {
    commit(SET_CHECKLIST_PENDING, true)
    try {
      const { data } = await service.getChecklist(mnemo)
      const fakeTitle = data.titles.find(el => el.id === 0 && el.type === 'title')
      if (fakeTitle) {
        fakeTitle.display = 0
      }
      commit(SET_CHECKLIST, data.titles.sort((a, b) => a.display - b.display) || [])
    } catch (error) {
      commit(SET_CHECKLIST_ERROR, error)
      throw error
    } finally {
      commit(SET_CHECKLIST_PENDING, false)
    }
  },
  [OPEN_SAVE_CHECKLIST_DIALOG] ({ commit }) {
    commit(SET_SAVE_CHECKLIST_DIALOG_IS_OPEN, true)
  },
  [CLOSE_SAVE_CHECKLIST_DIALOG] ({ commit }) {
    commit(SET_SAVE_CHECKLIST_DIALOG_IS_OPEN, false)
  },
  [CLOSE_IMPORT_CHECKLIST_DIALOG] ({ commit }) {
    commit(SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN, false)
  },
  [CLOSE_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG] ({ commit }) {
    commit(SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN, false)
  },
  [OPEN_SHARE_CHECKLIST_BY_EMAIL_PANE] ({ commit }) {
    commit(SET_PANE, {
      isOpen: true,
      tab: 'share-email',
    })
  },
  [CLOSE_CHECKLIST_PANE] ({ commit }) {
    commit(SET_PANE, {
      isOpen: false,
      tab: '',
    })
  },
  async [SHARE_CHECKLIST_BY_EMAIL] ({ commit }, { mnemo, data }) {
    commit(SET_SHARE_CHECKLIST_BY_EMAIL_PENDING, true)

    try {
      await service.shareChecklistByEmail(mnemo, data)
    } catch (error) {
      commit(SET_CHECKLIST_ERROR, error)
      throw error
    } finally {
      commit(SET_SHARE_CHECKLIST_BY_EMAIL_PENDING, false)
    }
  },
  [ADD_TASK_TO_SELECTION] ({ commit, getters }, task) {
    const taskIsAlreadySelected = getters.selectedTasks.some(selectedTask => selectedTask.id === task.id)

    if (taskIsAlreadySelected) {
      return
    }

    const selectedTasks = [...getters.selectedTasks, task]
    commit(SET_SELECTED_TASKS, selectedTasks)
  },
  [REMOVE_TASK_FROM_SELECTION] ({ commit, getters }, task) {
    const taskIsAlreadySelected = getters.selectedTasks.some(selectedTask => selectedTask.id === task.id)

    if (taskIsAlreadySelected) {
      const selectedTasks = getters.selectedTasks.filter(selectedTask => selectedTask.id !== task.id)
      commit(SET_SELECTED_TASKS, selectedTasks)
    }
  },
  [ADD_TITLE_TO_SELECTION] ({ commit, getters }, title) {
    const titleIsAlreadySelected = getters.selectedTitles.some(selectedTitle => selectedTitle.id === title.id)

    if (titleIsAlreadySelected) {
      return
    }

    const selectedTitles = [...getters.selectedTitles, title]
    commit(SET_SELECTED_TITLES, selectedTitles)
  },
  [REMOVE_TITLE_FROM_SELECTION] ({ commit, getters }, title) {
    const titleIsNotSelected = !getters.selectedTitles.some(selectedTitle => selectedTitle.id === title.id)

    if (titleIsNotSelected) {
      return
    }

    const selectedTitles = getters.selectedTitles.filter(selectedTitle => selectedTitle.id !== title.id)
    commit(SET_SELECTED_TITLES, selectedTitles)
  },
  [ADD_TITLE_CHILDREN_TO_SELECTION] ({ commit, dispatch, getters }, title) {
    const childrenTasksNotSelectedYet = title.children.filter(
      child => child.type === 'task' &&
      getters.filteredTasks.some(task => task.id === child.id) &&
      !getters.selectedTasks.some(task => task.id === child.id),
    )

    if (childrenTasksNotSelectedYet.length > 0) {
      const selectedTasks = [].concat(getters.selectedTasks, childrenTasksNotSelectedYet)
      commit(SET_SELECTED_TASKS, selectedTasks)
    }

    const childrenTitlesNotSelectedYet = title.children.filter(
      child => child.type === 'title' &&
      getters.filteredTitles.some(title => title.id === child.id) &&
      !getters.selectedTitles.some(title => title.id === child.id),
    )

    childrenTitlesNotSelectedYet.forEach(childrenTitle => {
      dispatch(ADD_TITLE_TO_SELECTION, childrenTitle)
      dispatch(ADD_TITLE_CHILDREN_TO_SELECTION, childrenTitle)
    })
  },
  [REMOVE_TITLE_CHILDREN_FROM_SELECTION] ({ commit, dispatch, getters }, title) {
    const childrenTasksAlreadySelected = title.children.filter(
      child => child.type === 'task' && getters.selectedTasks.some(task => task.id === child.id),
    )

    if (childrenTasksAlreadySelected.length > 0) {
      const selectedTasks = getters.selectedTasks.filter(
        task => !childrenTasksAlreadySelected.some(child => child.id === task.id),
      )
      commit(SET_SELECTED_TASKS, selectedTasks)
    }

    const childrenTitlesAlreadySelected = title.children.filter(
      child => child.type === 'title' && getters.selectedTitles.some(title => title.id === child.id),
    )
    childrenTitlesAlreadySelected.forEach(childrenTitle => {
      dispatch(REMOVE_TITLE_FROM_SELECTION, childrenTitle)
      dispatch(REMOVE_TITLE_CHILDREN_FROM_SELECTION, childrenTitle)
    })
  },
  [CLEAR_ITEMS_SELECTION] ({ commit }) {
    commit(SET_SELECTED_TASKS, [])
    commit(SET_SELECTED_TITLES, [])
  },
  [ADD_ALL_ITEMS_TO_SELECTION] ({ commit, getters, state }) {
    const selectableTasks = getters.isChecklistFiltered ? getters.filteredTasks : getters.tasks
    const selectableTitles = getters.isChecklistFiltered ? getters.filteredTitles : getters.titles

    commit(SET_SELECTED_TASKS, selectableTasks)
    commit(SET_SELECTED_TITLES, selectableTitles.filter(title => title.id !== 0))
  },
  [EXPAND_TITLE] ({ commit, state }, titleId) {
    const titlesExpandState = state.titlesExpandState
      .filter(titleExpandState => titleExpandState.id !== titleId)
      .concat({ id: titleId, value: true })

    commit(SET_TITLES_EXPAND_STATE, titlesExpandState)
  },
  [EXPAND_ALL_TITLES] ({ commit, getters }) {
    const titlesExpandState = getters.titles.map(title => ({
      id: title.id,
      value: true,
    }))

    commit(SET_TITLES_EXPAND_STATE, titlesExpandState)
  },
  [COLLAPSE_TITLE] ({ commit, state }, titleId) {
    const titlesExpandState = state.titlesExpandState
      .filter(titleExpandState => titleExpandState.id !== titleId)
      .concat({ id: titleId, value: false })

    commit(SET_TITLES_EXPAND_STATE, titlesExpandState)
  },
  [COLLAPSE_ALL_TITLES] ({ commit, getters }) {
    const titlesExpandState = getters.titles.map(title => ({
      id: title.id,
      value: false,
    }))

    commit(SET_TITLES_EXPAND_STATE, titlesExpandState)
  },
  [DELETE_SEARCH_RESULTS] ({ commit }) {
    commit(SET_SEARCH_QUERY, [])
    commit(SET_SELECTED_TASKS, [])
  },
  [GET_SEARCH_RESULTS] ({ commit }, searchQuery) {
    commit(SET_SEARCH_QUERY_PENDING, true)

    try {
      const searchQueryArray = searchQuery.split(' ').filter(item => item)
      commit(SET_SEARCH_QUERY, searchQueryArray)
      commit(SET_SELECTED_TASKS, [])
    } catch (error) {
      commit(SET_CHECKLIST_ERROR, error)
      throw error
    } finally {
      setTimeout(() => {
        commit(SET_SEARCH_QUERY_PENDING, false)
      }, 300)
    }
  },
  [CHANGE_CHECKLIST_VERSIONING_PANE_TAB] ({ commit }, tab) {
    commit(SET_CHECKLIST_VERSIONING_PANE_TAB, tab)
  },
  [CLOSE_CHECKLIST_VERSIONING_PANE] ({ commit }) {
    commit(TOGGLE_CHECKLIST_VERSIONING_PANE, {
      documentVersions: [],
      documents: [],
      isOpen: false,
      tab: null,
    })
  },
  [SET_CHECKLIST_VERSIONING_PANE] ({ commit }, payload) {
    commit(TOGGLE_CHECKLIST_VERSIONING_PANE, payload)
  },
}
