<template>
  <div v-click-outside="onClickOutside"
         class="ChecklistTableTaskValidators"
       :class="{'pa-0': editMode && $vuetify.breakpoint.mdAndUp, 'py-2 px-1': !editMode && $vuetify.breakpoint.mdAndUp}"
  >
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <template v-if="editMode">
        <div class="ChecklistTableTaskValidators-editInfo">
          {{ $t('project.checklist.task.ChecklistTableTaskValidators.editInfo') }}
        </div>
        <VisualTextEditor ref="taskValidatorsField"
                          v-model="newValidators"
                          :error-messages="newValidatorsErrors"
                          :taggable-members="taggableMembers"
                          :taggable-groups="taggableGroups"
                          counter="footer"
                          :max-length="newValidatorsMaxLength"
                          @blur="!validateNewValidatorsDisabled && preparePatchTask"
        />
        <div class="pa-2 blur-editor-exception" tabindex="0">
          <v-checkbox v-model="notifyNewValidators"
                      :label="$t('project.checklist.task.ChecklistTableTaskValidators.notifyNewValidators')"
                      class="caption my-1 pt-0"
                      hide-details
          />
          <div class="d-flex align-center justify-end">
            <AppTooltip top>
              <template #activator="{on, attrs}">
                <AppButton class="blur-editor-exception mr-2"
                           icon
                           v-bind="attrs"
                           v-on="on"
                           @click="editMode = false"
                >
                  <font-awesome-icon :icon="['fad', 'circle-xmark']"
                                     style="font-size: 20px;"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{$t('common.cancel')}}</span>
            </AppTooltip>
            <AppTooltip top>
              <template #activator="{on, attrs}">
                <AppButton color="success"
                           icon
                           v-bind="attrs"
                           class="blur-editor-exception"
                           :disabled="validateNewValidatorsDisabled"
                           v-on="on"
                           @click="preparePatchTask"
                >
                  <font-awesome-icon :icon="['fad', 'circle-check']"
                                     style="font-size: 20px;"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{$t('common.save')}}</span>
            </AppTooltip>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="item.validators">
          <template v-if="isCurrentUserPm">
            <div class="ChecklistTableTaskValidators-display h-100"
                 @click="activeEditMode"
                 v-html="item.validators"
            />
          </template>
          <template v-else>
            <div class="ChecklistTableTaskValidators-display--guest h-100"
                 v-html="item.validators"
            />
          </template>
        </template>
        <template v-else-if="isCurrentUserPm">
          <div class="ChecklistTableTaskValidators-display h-100 d-flex align-center justify-center"
               @click="activeEditMode"
          >
            <div class="ChecklistTableTaskValidators-placeholder">
              {{$t('project.checklist.task.ChecklistTableTaskValidators.clickToAddValidators')}}
            </div>
          </div>
        </template>
      </template>
    </template>

    <template v-if="$vuetify.breakpoint.smAndDown">
      <div class="ChecklistTableTaskValidators-mobileRowGrid">
        <div class="pa-2">
          {{ $t('project.checklist.task.ChecklistTableTaskValidators.validators') }}
        </div>
        <div>
          <template v-if="editMode">
            <div class="ChecklistTableTaskValidators-editInfo">
              {{ $t('project.checklist.task.ChecklistTableTaskValidators.editInfo') }}
            </div>
            <VisualTextEditor ref="taskValidatorsField"
                              v-model="newValidators"
                              :error-messages="newValidatorsErrors"
                              :taggable-members="taggableMembers"
                              :taggable-groups="taggableGroups"
                              counter="footer"
                              :max-length="newValidatorsMaxLength"
            />
            <div class="pa-2 blur-editor-exception" tabindex="0">
              <v-checkbox v-model="notifyNewValidators"
                          :label="$t('project.checklist.task.ChecklistTableTaskValidators.notifyNewValidators')"
                          class="my-2 pt-0"
                          hide-details
              />
              <div class="d-flex align-center justify-end">
                <AppButton class="blur-editor-exception mr-2"
                           @click="editMode = false"
                >
                  {{ $t('common.cancel') }}
                </AppButton>
                <AppButton color="primary"
                           class="blur-editor-exception"
                           :disabled="validateNewValidatorsDisabled"
                           @click="preparePatchTask"
                >
                  {{ $t('common.save') }}
                </AppButton>
              </div>
            </div>
          </template>

          <template v-else>
            <template v-if="isCurrentUserPm">
              <div class="ChecklistTableTaskValidators-display h-100"
                   @click="activeEditMode"
                   v-html="item.parties"
              />
            </template>
            <template v-else>
              <div class="ChecklistTableTaskValidators-display--guest h-100"
                   v-html="item.parties"
              />
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppTooltip from '../../../common/AppTooltip'
import AppButton from '../../../common/buttons/AppButton'
import { escapeHtml } from '../../../common/utils/strings'
import VisualTextEditor from '../../../common/visual-text-editor/VisualTextEditor'
import { EDIT_TASK } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

const NEW_VALIDATORS_MAX_LENGTH = 400

export default {
  name: 'ChecklistTableTaskValidators',
  components: {
    AppButton,
    VisualTextEditor,
    AppTooltip,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      editMode: false,
      newValidators: '',
      newValidatorsMaxLength: NEW_VALIDATORS_MAX_LENGTH,
      notifyNewValidators: true,
    }
  },
  computed: {
    ...mapState('checklist', ['editTaskPending']),
    ...mapState('groups', ['groups']),
    ...mapGetters('room', ['isCurrentUserPm']),
    taggableMembers () {
      // All room members are taggable (PM sees them all anyway)
      const taggableMembers = []
      for (const group of this.groups) {
        for (const member of group.members) {
          const memberCopy = cloneDeep(member)
          memberCopy.value = memberCopy.fullName
          taggableMembers.push(memberCopy)
        }
      }
      return taggableMembers
    },
    taggableGroups () {
      // All room groups are taggable (PM sees them all anyway)
      const taggableGroups = []
      for (const group of this.groups) {
        const groupCopy = cloneDeep(group)
        groupCopy.value = groupCopy.name
        taggableGroups.push(groupCopy)
      }
      return taggableGroups
    },
    newValidatorsLength () {
      return escapeHtml(this.newValidators).length
    },
    newValidatorsIsTooLong () {
      return this.newValidatorsLength > this.newValidatorsMaxLength
    },
    newValidatorsErrors () {
      const errors = []

      if (this.newValidatorsIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    validateNewValidatorsDisabled () {
      return this.newValidatorsIsTooLong
    },
  },
  mounted () {
    this.newValidators = this.item.validators
  },
  methods: {
    ...mapActions('checklist', [EDIT_TASK]),
    async activeEditMode () {
      this.newValidators = this.item.validators
      this.editMode = true

      await this.$nextTick()
      this.$refs.taskValidatorsField.focus()
    },
    async preparePatchTask () {
      if (this.newValidators === this.item.validators) {
        this.editMode = false
      } else {
        try {
          await this.EDIT_TASK({
            parent: this.parent,
            mnemo: this.mnemo,
            taskId: this.item.id,
            data: {
              validators: this.newValidators,
              sendNotif: this.notifyNewValidators,
            },
          })
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.checklist.task.ChecklistTableTaskValidators.editTaskSuccess'),
          })
          this.editMode = false
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.task.ChecklistTableTaskValidators.editTaskError'),
          })
          console.error(e)
        }
      }
    },
    onClickOutside (e) {
      if (this.editMode && this.validateNewValidatorsDisabled) {
        this.newValidators = this.item.validators
        this.editMode = false
      } else if (this.editMode && !e.srcElement?.classList.contains('no-click-outside')) {
        this.preparePatchTask()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableTaskValidators {
  background-color: #eee;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    background-color: #f5f5f5;
    border-bottom: 2px solid #eee;
  }
}
.ChecklistTableTaskValidators-display {
  border: 1px dashed transparent;
  border-radius: 2px;
  padding: 4px 6px;
  cursor: pointer;
  &:hover {
    border-color: #bdbdbd;
    background-color: #eee;
  }
  ::v-deep span[data-mention] {
    color: var(--v-tertiary-base);
    font-weight: bold;
    overflow-wrap: anywhere;
  }
}
.ChecklistTableTaskValidators-display--guest {
  padding: 4px 6px;
  ::v-deep span[data-mention] {
    color: var(--v-tertiary-base);
    font-weight: bold;
    overflow-wrap: anywhere;
  }
}
.ChecklistTableTaskValidators-editInfo {
  padding: 6px 10px;
  font-size: 12px;
  background-color: #fdf0dc;
}
.ChecklistTableTaskValidators-placeholder {
  font-style: italic;
  color: rgba(51, 51, 51, .51);
}
.ChecklistTableTaskValidators-mobileRowGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
</style>
