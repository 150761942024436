<template>
  <app-text variant="small-regular">
    <template v-if="genericSigners.length > 0">
      <i18n :path="`${TRANSLATION_KEY_PREFIX}.base`" :tag="false">
        <app-text as="span" variant="small-regular">
          {{
             $tc(
                `${TRANSLATION_KEY_PREFIX}.${envelopeType === ENVELOPE_TYPES.SINGLE ? 'countSingleEnvelope' : 'countMultipleEnvelopes'}`,
                genericSigners.length,
                { count: genericSigners.length },
            )
          }}
        </app-text>
        <app-text as="span" variant="small-bold">
          {{ formattedGenericSignersList }}
        </app-text>
      </i18n>
    </template>
    <template v-else>
      {{ $t(`${TRANSLATION_KEY_PREFIX}.noSigners`) }}
    </template>
  </app-text>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { ENVELOPE_TYPES } from '@/project/signing-checklist/constants'

const TRANSLATION_KEY_PREFIX = 'project.signing-checklist.add.step2.SigningChecklistTemplateSignersDetails'

export default defineComponent({
  name: 'SigningChecklistTemplateSignersDetails',
  props: {
    genericSigners: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  data () {
    return {
      ENVELOPE_TYPES,
      TRANSLATION_KEY_PREFIX,
    }
  },
  computed: {
    formattedGenericSignersList (): string {
      if (this.genericSigners.length === 1) {
        return this.genericSigners[0]
      }
      const firsts = this.genericSigners.slice(0, this.genericSigners.length - 1)
      const last = this.genericSigners[this.genericSigners.length - 1]
      return `${firsts.join(', ')} ${this.$t('common.and')} ${last}`
    },
  },
})
</script>
