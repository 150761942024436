import {
  SET_GET_ROOM_LABELS_ERROR,
  SET_GET_ROOM_LABELS_PENDING,
  SET_ROOMS_LABELS,
  SET_DISPLAY_TYPE,
  SET_SUBSCRIPTION_LABELS_PENDING,
  SET_SUBSCRIPTION_LABELS,
  SET_SUBSCRIPTION_LABELS_ERROR,
  SET_DATA_ROOM_PRICING,
  SET_DATA_ROOM_PRICING_ERROR,
  SET_DATA_ROOM_PRICING_PENDING,
  SET_DATA_ROOM_CUSTOM_PRICING_PENDING,
  SET_DATA_ROOM_CUSTOM_PRICING,
  SET_DATA_ROOM_CUSTOM_PRICING_ERROR,
  SET_LAST_VISITED_ROOMS,
  SET_LAST_VISITED_ROOMS_PENDING,
  SET_RECENT_ROOMS_PENDING,
  SET_RECENT_ROOMS,
  SET_RECENT_ROOMS_ERROR,
  SET_ROOM_SEARCH_RESULTS_PENDING,
  SET_ROOM_SEARCH_RESULTS,
  SET_ROOM_SEARCH_RESULTS_ERROR,
  SET_SEARCH_MODE_ENABLED,
} from './mutation_types'
import { initialRoomFiltersPaneState } from './state.js'

export const mutations = {
  [SET_SEARCH_MODE_ENABLED] (state, value) {
    state.searchModeEnabled = value
  },
  SET_PROCESSING: function (state, value) {
    state.processing = value
  },
  SET_ROOMS: function (state, rooms) {
    state.rooms = rooms
  },
  SET_ERROR: function (state, error) {
    state.error = error
  },
  SET_LOADING_ROOMS_INFO: function (state, loadingRoomsInfo) {
    state.loadingRoomsInfo = loadingRoomsInfo
  },
  [SET_ROOMS_LABELS] (state, roomLabels) {
    state.roomLabels = roomLabels
  },
  OPEN_FILTER_PANE: function (state) {
    state.filtersPane.isOpen = true
  },
  CLOSE_FILTER_PANE: function (state) {
    state.filtersPane.isOpen = false
  },
  DELETE_FILTERS: function (state) {
    state.filtersPane.filters = { ...initialRoomFiltersPaneState }
  },
  [SET_ROOM_SEARCH_RESULTS] (state, value) {
    state.roomSearchResults = value
  },
  [SET_ROOM_SEARCH_RESULTS_ERROR] (state, value) {
    state.roomSearchResultsError = value
  },
  [SET_ROOM_SEARCH_RESULTS_PENDING] (state, value) {
    state.roomSearchResultsPending = value
  },
  [SET_SUBSCRIPTION_LABELS_PENDING] (state, value) {
    state.subscriptionLabelsPending = value
  },

  [SET_SUBSCRIPTION_LABELS] (state, value) {
    state.subscriptionLabels = value
  },
  [SET_SUBSCRIPTION_LABELS_ERROR] (state, value) {
    state.subscriptionLabelsError = value
  },
  DELETE_CREATION_DATE_FILTER: function (state) {
    state.filtersPane.filters.creationDate = []
  },
  DELETE_DUE_DATE_FILTER: function (state) {
    state.filtersPane.filters.dueDate = []
  },
  DELETE_SELECTED_LABELS_FILTER: function (state) {
    state.filtersPane.filters.selectedLabels = []
  },
  DELETE_PROJECT_TYPE_FILTER: function (state) {
    state.filtersPane.filters.projectType = []
  },
  DELETE_ROLE_FILTER: function (state) {
    state.filtersPane.filters.role = []
  },
  SET_FILTERS: function (state, value) {
    state.filtersPane.filters = value
  },
  SET_SORT: function (state, value) {
    state.sort = value
  },
  [SET_DISPLAY_TYPE] (state, value) {
    state.displayType = value
  },
  [SET_GET_ROOM_LABELS_PENDING] (state, value) {
    state.roomsLabelsPending = value
  },
  [SET_GET_ROOM_LABELS_ERROR] (state, value) {
    state.roomsLabelsError = value
  },
  [SET_DATA_ROOM_PRICING_PENDING] (state, value) {
    state.dataRoomPricingPending = value
  },
  [SET_DATA_ROOM_PRICING] (state, value) {
    state.dataRoomPricing = value
  },
  [SET_DATA_ROOM_PRICING_ERROR] (state, value) {
    state.dataRoomPricingError = value
  },
  [SET_DATA_ROOM_CUSTOM_PRICING_PENDING] (state, value) {
    state.dataRoomCustomPricingPending = value
  },
  [SET_DATA_ROOM_CUSTOM_PRICING] (state, value) {
    state.dataRoomCustomPricing = value
  },
  [SET_DATA_ROOM_CUSTOM_PRICING_ERROR] (state, value) {
    state.dataRoomCustomPricingError = value
  },
  [SET_LAST_VISITED_ROOMS] (state, rooms) {
    state.lastVisitedRooms = rooms
  },
  [SET_LAST_VISITED_ROOMS_PENDING] (state, isPending) {
    state.lastVisitedRoomsPending = isPending
  },
  [SET_RECENT_ROOMS_PENDING] (state, value) {
    state.recentRoomsPending = value
  },
  [SET_RECENT_ROOMS] (state, value) {
    state.recentRooms = value
  },
  [SET_RECENT_ROOMS_ERROR] (state, value) {
    state.recentRoomsError = value
  },
}
