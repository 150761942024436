<template>
    <div>
      <AppTooltip top>
          <template #activator="{ on, attrs }">
              <app-button type="icon"
                         v-bind="attrs"
                         v-on="on"
                         @click="openDialog = true"
              >
                <font-awesome-icon :icon="['fal', 'trash-can']" class="body-1"></font-awesome-icon>
              </app-button>
          </template>
        <span>{{ $t('common.delete') }}</span>
      </AppTooltip>
      <AppDialog :is-open="openDialog"
                 size="l"
                 :ok-text="$t('common.delete')"
                 :ok-loading="loading"
                 :cancel-disabled="loading"
                 @cancel="openDialog = false"
                 @ok="prepareDeleteBible"
      >
        <template #title>{{ $t('project.bibles.BibleDeleteDialog.title') }}</template>
        <template #body>
          <div> {{$t('project.bibles.BibleDeleteDialog.text1', { name: bible.name })}} </div>
          <div> {{$t('project.bibles.BibleDeleteDialog.text2')}} </div>
        </template>
      </AppDialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import AppDialog from '@/common/dialogs/AppDialog'

export default {
  name: 'BibleDeleteDialog',
  components: { AppDialog, AppTooltip },
  props: {
    bible: {
      type: Object,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      openDialog: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('bibles', ['DELETE_BIBLE', 'GET_BIBLES']),
    async prepareDeleteBible () {
      this.loading = true
      try {
        await this.DELETE_BIBLE({
          mnemo: this.mnemo,
          bibleId: this.bible.id,
        })
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.$t('project.bibles.BibleDeleteDialog.deleteBibleSuccess'),
        })
        this.GET_BIBLES({ mnemo: this.mnemo })
      } catch (e) {
        this.$store.commit('enqueueSnackbar', {
          color: 'error',
          message: this.$t('project.bibles.BibleDeleteDialog.deleteBibleError'),
        })
      } finally {
        this.loading = false
        this.openDialog = false
      }
    },
  },
}
</script>
