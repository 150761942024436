import {
  GET_MY_ENVELOPES,
} from './action_types'
import { SET_MY_ENVELOPES, SET_MY_ENVELOPES_ERROR, SET_MY_ENVELOPES_PENDING } from './mutation_types'
import envelopesService from '../../../services/envelopes.service'

export const actions = {
  async [GET_MY_ENVELOPES] ({ commit }, { mnemo, queryObject }) {
    commit(SET_MY_ENVELOPES_PENDING, true)
    try {
      const { data } = await envelopesService.getMyEnvelopes(mnemo, queryObject)
      commit(SET_MY_ENVELOPES, data)
    } catch (error) {
      commit(SET_MY_ENVELOPES_ERROR, error)
    } finally {
      commit(SET_MY_ENVELOPES_PENDING, false)
    }
  },
}
