<template>
  <v-badge :bordered="bordered"
           :bottom="bottom"
           :color="color"
           :disabled="disabled"
           :icon="icon"
           :left="left"
           :offset-x="offsetX"
           :offset-y="offsetY"
           :overlap="overlap"
          v-bind="$attrs"
          v-on="$listeners"
  >
    <!-- @slot used for the badge’s content -->
    <slot name="badge"></slot>
    <!-- @slot used for the element the badge hovers on -->
    <slot></slot>
  </v-badge>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

export type AppIconBadgePosition = 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'

/**
 * @displayName Badge Icon
 */
export default Vue.extend({
  name: 'AppIconBadge',
  inheritAttrs: false,
  computed: {
    bottom (): boolean {
      return this.position.includes('bottom')
    },
    left (): boolean {
      return this.position.includes('left')
    },
  },
  props: {
    /**
     * If true, add border on badge
     */
    bordered: {
      type: Boolean,
      default: true,
    },
    /**
     * Color of badge
     */
    color: {
      type: String,
      default: 'primary',
    },
    /**
     * If true, don't display badge
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Icon to display inside the badge
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Position of badge |
     * Possible values are : bottom-left, bottom-right, top-left, top-right
     */
    position: {
      type: String as PropType<AppIconBadgePosition>,
      default: 'top-left',
    },
    /**
     * Offset the badge on the x axis
     */
    offsetX: {
      type: [Number, String],
      default: 0,
    },
    /**
     * Offset the badge on the y axis
     */
    offsetY: {
      type: [Number, String],
      default: 0,
    },
    /**
     * If true, overlap badge on top of the slotted content
     */
    overlap: {
      type: Boolean,
      default: true,
    },
  },

})
</script>

<style scoped lang="scss">
  ::v-deep .v-badge__badge {
    margin: 2px; // to avoid too much overlapping on "paddingless" & small elements

    & .v-icon {
      font-size: 0.7rem;
      color: white !important;
    }
  }
</style>

<docs>
```vue
<template>
  <div class="d-flex align-center column-gap-4">
    <app-icon-badge :icon="overlayIcon" color="primary">
      <app-button type="primary">
        <template #left-icon>
            <font-awesome-icon :icon="mainIcon" />
        </template>
        Button
      </app-button>
    </app-icon-badge>
    <app-icon-badge :icon="overlayIconAlt">
      <app-button type="outlined">
        <template #left-icon>
            <font-awesome-icon :icon="mainIcon" />
        </template>
        Button
      </app-button>
    </app-icon-badge>
    <app-icon-badge :icon="overlayIcon" color="grey darken-2">
      <app-button type="tonal">
        <template #left-icon>
            <font-awesome-icon :icon="mainIcon" />
        </template>
        Button
      </app-button>
    </app-icon-badge>
    <app-icon-badge :icon="overlayIcon">
      <app-button type="primary" size="small">
       Button
      </app-button>
    </app-icon-badge>
    <div class="px-2">
      <app-icon-badge :icon="overlayIcon" offset-x="6">
        <font-awesome-icon :icon="mainIcon" class="primary--text" />
      </app-icon-badge>
    </div>
  </div>
</template>
<script>
  import { solidLibrary } from '@/plugins/font-awesome-libraries/solid-library'

  export default {
    data () {
      return {
        overlayIcon : 'fa-crown',
        overlayIconAlt : 'fa-star',
        mainIcon: solidLibrary.faArchive,
      }
    },
  }
</script>
```
</docs>
