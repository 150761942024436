<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             :persistent="true"
             :ok-text="$t('common.download')"
             :ok-disabled="!canDownload"
             :ok-loading="archivesDownloadPending"
             @ok="downloadArchives"
             @cancel="closeDialog"
  >
    <template #title>
      <span>{{ $t('project.documents.dialogs.DocumentsDownloadFilesDialog.dialogTitle') }}</span>
    </template>
    <template #body>
      <template v-if="hasAlerts && selectedDocumentsSize > SIZE_LIMIT">
        <app-alert-in-page class="mb-6" :dismissible="false">
          <i18n path="project.documents.dialogs.DocumentsDownloadFilesDialog.caption" tag="span"><app-filesize-display :value="SIZE_LIMIT"/></i18n>
        </app-alert-in-page>
      </template>
      <app-text variant="small-bold">
        {{ $t('project.documents.dialogs.DocumentsDownloadFilesDialog.yourSelection') }}
      </app-text>
      <v-list class="listContent">
        <template v-for="(document, i) in selectedDocuments">
          <v-list-item :key="`doc-${i}`" class="px-0">
            <v-list-item-icon>
              <ClosdFildersIcon :document="document"/>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="d-flex justify-space-between">
                <span>{{ document.docName }}</span>
                <app-filesize-display :value="document.docSize" :default-value="0"/>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <AppButton
                icon
                class="delete-button"
                @click="onRemoveDocument(i)"
              >
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                ></font-awesome-icon>
              </AppButton>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="i !== selectedDocuments.length"
            :key="`divider-${i}`"
          ></v-divider>
        </template>
      </v-list>
      <div class="my-4 d-flex justify-space-between">
        <span class="text-h4">{{ $tc('project.documents.dialogs.DocumentsDownloadFilesDialog.selectedItems', selectedDocuments.length, { selectedItems: selectedDocuments.length }) }}</span>
        <div>
          <span>{{ $tc('project.documents.dialogs.DocumentsDownloadFilesDialog.selectionSize') }}</span>
          <span class="ml-4 text-h4"><app-filesize-display :value="selectedDocumentsSize" :default-value="0"/></span>
        </div>
      </div>
      <AppDownloadFile ref="appDownloadFileRef" :link="downloadLink" />
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon'
import { dialogMixin } from '@/mixins/dialogMixin'
import { GET_ARCHIVES_DOWNLOAD } from '@/store/modules/archives/action_types'
import { REMOVE_DOCUMENT } from '@/store/modules/archives/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

/**
 * Size limit in byte
 * @type {number}
 */
const SIZE_LIMIT = 209715200

export default {
  name: 'ArchivesDownloadDialog',
  components: {
    AppButton,
    AppDialog,
    AppDownloadFile,
    ClosdFildersIcon,
  },
  mixins: [dialogMixin],
  data () {
    return {
      downloadLink: '',
      isBibleGenerateDialogOpened: false,
      SIZE_LIMIT,
    }
  },
  computed: {
    ...mapGetters('archives', ['selectedDocumentsSize']),
    ...mapState('archives', ['selectedDocuments', 'archivesDownloadPending']),
    canDownload () {
      return this.selectedDocuments.length !== 0 && this.selectedDocumentsSize < SIZE_LIMIT
    },
    hasAlerts () {
      return this.selectedDocumentsSize > SIZE_LIMIT || this.hasAtLeastOneEmptyFolder
    },
    selectedDocumentsFileIds () {
      return this.selectedDocuments.map(doc => doc.id)
    },
  },
  methods: {
    ...mapActions('archives', [GET_ARCHIVES_DOWNLOAD]),
    ...mapMutations('archives', [REMOVE_DOCUMENT]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async downloadArchives () {
      this.downloadLink = `${this.axios.defaults.baseURL}/downloads/archives?archiveIds=${this.selectedDocumentsFileIds.join(',')}`
      await this.$nextTick()
      this.$refs.appDownloadFileRef.submitForm()
      this.closeDialog()
    },
    onRemoveDocument (index) {
      this.REMOVE_DOCUMENT(index)
      if (this.selectedDocuments.length === 0) {
        this.closeDialog()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.listContent {
  max-height: 300px;
  overflow-y: auto;
}
</style>
