import { render, staticRenderFns } from "./AppTreeviewItem.vue?vue&type=template&id=8b56186a&scoped=true"
import script from "./AppTreeviewItem.vue?vue&type=script&lang=ts"
export * from "./AppTreeviewItem.vue?vue&type=script&lang=ts"
import style0 from "./AppTreeviewItem.vue?vue&type=style&index=0&id=8b56186a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b56186a",
  null
  
)

export default component.exports