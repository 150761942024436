import i18n from '../../i18n'
import router, { SIGN_IN_ROUTE_NAME } from '../../router'

export const AUTH_DATA_COOKIE_KEY = 'user-auth-data'

/**
 * Sends the user back to the signIn view
 * @param {object} params The params to send to the signIn view. By default, the locale passed is the current one.
 */
function backToSignIn (params = {}, query = {}) {
  router.push(getSignInRoute(params, query))
}

/**
 * Get the signIn route with default params added
 * @param {object} params The params to send to the signIn view. By default, the locale passed is the current one.
 * @param {object} query The query parameters to send to the signIn view.
 * @returns {object} A route object to go to the signIn view
 */
function getSignInRoute (params = {}, query = {}) {
  params = {
    locale: i18n.locale,
    ...params,
  }
  return { name: SIGN_IN_ROUTE_NAME, params, query }
}

export { backToSignIn, getSignInRoute }
