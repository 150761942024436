<template>
  <div class="documents-selectors">
    <div class="d-flex flex-column align-center row-gap-4">
      <app-text variant="medium-bold">
        {{ displayedFromClosdTitle }}
      </app-text>
      <ClosdFildersSelector :validate-label="$t('common.filders.ClosdFildersSelector.addToSelection')"
                            :disabled="disabled"
                            disable-folder-without-file
                            disable-files-too-large-to-be-signed
                            :file-type="fileType"
                            :files-only="filesOnly"
                            :multiple="multiple"
                            @select="selectClosdFiles"
      />
    </div>
    <div class="d-flex flex-column align-center row-gap-4">
      <slot name="fromLocalHeader">
        <app-text variant="medium-bold">
          {{ displayedFromLocalTitle }}
        </app-text>
      </slot>
      <MixedFildersSelector :disabled="disabled"
                            :files-only="filesOnly"
                            :multiple="multiple"
                            @select="selectLocalFiles"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import ClosdFildersSelector from '@/common/filders/ClosdFildersSelector.vue'
import MixedFildersSelector from '@/common/filders/MixedFildersSelector.vue'
import { isFileSignable } from '@/common/utils/files'
import { formatImportedFile, UPLOAD_SESSION_LIMIT } from '@/common/utils/signingChecklist'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_WARNING_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'SigningChecklistDocumentsSelector',
  components: {
    ClosdFildersSelector,
    MixedFildersSelector,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    filesOnly: {
      type: Boolean,
      default: false,
    },
    fromClosdTitle: {
      type: String,
      default: '',
    },
    fromLocalTitle: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('room', ['roomMnemo', 'maxSignableSize']),
    displayedFromClosdTitle () {
      return this.fromClosdTitle ?? this.$t('common.filders.ClosdFildersSelector.addFileFromClosd')
    },
    displayedFromLocalTitle () {
      return this.fromLocalTitle ?? this.$t('common.filders.ClosdFildersSelector.addFileFromClosd')
    },
    fileType () {
      const fileType = ['pdf']
      if (process.env.VUE_APP_ENABLE_WORD_SIGNING) {
        fileType.push('doc', 'docx')
      }
      return fileType
    },
  },
  methods: {
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_WARNING_SNACKBAR]),
    selectClosdFiles (closdFiles) {
      const filesToAddToSelection = this.limitSelectionFilesToSessionLimit(closdFiles)
      this.$emit('select', { files: filesToAddToSelection, fromClosd: true })
    },
    selectLocalFiles (localFiles) {
      const signableFiles = []
      const notSignableFiles = []

      for (const file of localFiles) {
        if (isFileSignable(file, this.maxSignableSize)) {
          signableFiles.push(file)
        } else {
          notSignableFiles.push(file)
        }
      }

      if (notSignableFiles.length > 0) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$tc('project.signing-checklist.SigningChecklistDocumentsSelector.invalidFiles', notSignableFiles.length, {
          name: notSignableFiles[0].name,
          remainingCount: notSignableFiles.length - 1,
        }))
      }

      if (signableFiles.length > 0) {
        const filesToAddToSelection = this.limitSelectionFilesToSessionLimit(signableFiles)
        this.$emit('select', { files: filesToAddToSelection, fromClosd: false })
      }
    },
    limitSelectionFilesToSessionLimit (selectedFiles) {
      if (selectedFiles.length > UPLOAD_SESSION_LIMIT) {
        this.ENQUEUE_WARNING_SNACKBAR(this.$t('project.signing-checklist.SigningChecklistDocumentsSelector.documentsLimitReachedWarning', { limit: UPLOAD_SESSION_LIMIT }))
      }

      return selectedFiles.slice(0, UPLOAD_SESSION_LIMIT).map(formatImportedFile)
    },
  },
})
</script>

<style lang="scss" scoped>
.documents-selectors {
  display: flex;
  column-gap: 40px;

  & > div {
    flex: 50%;
  }
}
</style>
