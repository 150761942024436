<template>
  <div class="project-settings-notifications-table">
    <template v-if="roomNotificationsError">
      <app-alert-in-page variant="error"
                         no-icon
                         :dismissible="false"
      >
        {{ $t('project.project-settings.ProjectSettingsNotifications.getNotificationsError') }}
      </app-alert-in-page>
    </template>
    <template v-else>
      <template v-if="roomNotificationsPending">
        <AppDataTableSkeletonLoader/>
      </template>
      <template v-else>
        <v-data-table :items="notifications"
                      :headers="headers"
                      :server-items-length="pagination && pagination.itemCount"
                      :footer-props="{
                        'items-per-page-options': ITEM_PER_PAGE_DEFAULT,
                        showCurrentPage: true,
                      }"
                      :options.sync="options"
                      :items-per-page="tableItemsPerPage"
                      :hide-default-footer="hideDefaultFooter"
                      @update:options="onSortByChange"
                      @update:items-per-page="onItemsPerPageChange"
                      @update:page="onPageChange"
        >
          <template #item.user_full_name="{item}">
            {{ item.userFullName }}
          </template>
          <template #item.user_email="{item}">
            {{ item.userEmail }}
          </template>
          <template #item.sent_at="{item}">
            <DateDisplay :value="item.sentAt"
                         with-hours
            />
          </template>
          <template #item.subject="{item}">
            <AppTooltip top>
              <template #activator="{on}">
                <div class="email-subject-col text-truncated"
                    v-on="on"
                >
                  <span>{{ item.subject }}</span>
                </div>
              </template>
              <span>{{ item.subject }}</span>
            </AppTooltip>
          </template>
          <template #item.bounce="{item}">
            <AppTooltip v-if="item.isBounced" top>
                <template #activator="{on}">
                  <app-icon icon-weight="fas"
                            icon-name="warning"
                            class="warning--text"
                            v-on="on"
                  />
                </template>
                <span v-html="$t('project.project-settings.ProjectSettingsNotifications.emailBounceTooltip')"></span>
              </AppTooltip>
          </template>
        </v-data-table>
      </template>
    </template>
  </div>
</template>

<script>
  import { debounce } from 'lodash-es'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import AppDataTableSkeletonLoader from '@/common/AppDataTableSkeletonLoader.vue'
  import AppTooltip from '@/common/AppTooltip.vue'
  import DateDisplay from '@/common/DateDisplay.vue'
  import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
  import { GET_ROOM_NOTIFICATIONS } from '@/store/modules/room/action_types'
  import {
    SET_ROOM_NOTIFICATIONS_SEARCH_QUERY,
    SET_ROOM_NOTIFICATIONS_SORT_BY,
    SET_ROOM_NOTIFICATIONS_SORT_DESC,
    RESET_ROOM_NOTIFICATIONS_SORT,
  } from '@/store/modules/room/mutation_types'
  import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

  const ITEM_PER_PAGE_DEFAULT = [10, 25, 50]

  export default {
    name: 'ProjectSettingsNotificationsTable',
    components: {
      AppTooltip,
      DateDisplay,
      AppDataTableSkeletonLoader,
    },
    props: {
      mnemo: {
        type: String,
        required: true,
      },
    },
    data () {
      const headersClass = 'text--primary body-2 font-weight-medium white-space-nowrap'
      return {
        headers: [
          {
            text: this.$t('project.project-settings.ProjectSettingsNotifications.headers.recipients'),
            class: headersClass,
            value: 'user_full_name',
          },
          {
            text: this.$t('project.project-settings.ProjectSettingsNotifications.headers.email'),
            class: headersClass,
            value: 'user_email',
          },
          {
            text: this.$t('project.project-settings.ProjectSettingsNotifications.headers.date'),
            class: headersClass,
            value: 'sent_at',
          },
          {
            text: this.$t('project.project-settings.ProjectSettingsNotifications.headers.emailSubject'),
            class: headersClass,
            value: 'subject',
          },
          {
            sortable: false,
            width: '50px',
            value: 'bounce',
          },
        ],
        options: {
          sortBy: [''],
          sortDir: [''],
        },
        tableItemsPerPage: null,
        ITEM_PER_PAGE_DEFAULT,
      }
    },
    computed: {
      ...mapState('room', [
        'roomNotificationsPending',
        'roomNotifications',
        'roomNotificationsSearchQuery',
        'roomNotificationsSort',
        'roomNotificationsError',
      ]),
      ...mapGetters('room', ['getNotificationsParams']),
      ...mapGetters('user', ['currentUserId']),
      notifications () {
        return this.roomNotifications?.data
      },
      pagination () {
        return this.roomNotifications?.pagination
      },
      hideDefaultFooter () {
        return this.pagination?.pageCount === 0
      },
    },
    watch: {
      roomNotificationsSearchQuery: {
        handler () {
          this.prepareGetRoomNotificationsImmediate(1)
        },
        deep: true,
      },
      roomNotificationsSort: {
        handler () {
          this.prepareGetRoomNotificationsImmediate(1)
        },
        deep: true,
      },
    },
    created () {
      this.prepareGetRoomNotificationsDebounced = debounce(this.prepareGetRoomNotifications, 1000)
      this.prepareGetRoomNotificationsImmediate = this.prepareGetRoomNotifications

      const storedPagination = getUserSetting(this.currentUserId, 'project-settings-notifications-items-per-page')
      this.tableItemsPerPage = storedPagination !== null ? parseInt(storedPagination) : ITEM_PER_PAGE_DEFAULT[0]
      this.prepareGetRoomNotificationsImmediate(1)
    },
    beforeDestroy () {
      this.SET_ROOM_NOTIFICATIONS_SEARCH_QUERY('')
      this.RESET_ROOM_NOTIFICATIONS_SORT()
    },
    methods: {
      ...mapActions('room', [GET_ROOM_NOTIFICATIONS]),
      ...mapMutations('room', [
        SET_ROOM_NOTIFICATIONS_SEARCH_QUERY,
        SET_ROOM_NOTIFICATIONS_SORT_BY,
        SET_ROOM_NOTIFICATIONS_SORT_DESC,
        RESET_ROOM_NOTIFICATIONS_SORT,
      ]),
      onSortByChange ({ sortBy, sortDesc }) {
        if (!this.pagination.itemCount) return
        const value = {
          sortBy: sortBy[0],
          sortDir: sortDesc[0] ? 'DESC' : 'ASC',
        }
        if (this.roomNotificationsSort.sortBy !== value.sortBy) {
          this.onSortByUpdate(value.sortBy)
        }
        if (this.roomNotificationsSort.sortDir !== value.sortDir) {
          this.onSortDescUpdate(value.sortDir)
        }
      },
      onItemsPerPageChange (value) {
        this.tableItemsPerPage = value
        setUserSetting(this.currentUserId, 'project-settings-notifications-items-per-page', value)
        this.prepareGetRoomNotificationsDebounced(1)
      },
      onPageChange (page) {
        this.prepareGetRoomNotificationsImmediate(page)
      },
      async prepareGetRoomNotifications (page) {
        try {
          await this.GET_ROOM_NOTIFICATIONS({
            mnemo: this.mnemo,
            params: {
              page,
              pageSize: this.tableItemsPerPage,
              ...this.getNotificationsParams,
            },
          })
        } catch (error) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.project-settings.ProjectSettingsNotifications.getNotificationsError'),
          })
        }
      },
      onSortByUpdate (value) {
        this.SET_ROOM_NOTIFICATIONS_SORT_BY(value)
      },
      onSortDescUpdate (value) {
        this.SET_ROOM_NOTIFICATIONS_SORT_DESC(value)
      },
    },
  }
</script>

<style scoped lang="scss">
  .project-settings-notifications-table {
    .email-subject-col {
      max-width: 200px;
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        max-width: 600px;
      }
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        max-width: 700px;
      }
      @media #{map-get($display-breakpoints, 'xl-only')} {
        max-width: 1000px;
      }
    }
  }
</style>
