<template>
  <div>{{ text }}</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'CreatedBy',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['profile']),
    text (): string | TranslateResult {
      return this.user.id === this.profile.id ? this.$t('common.me') : this.user.fullName
    },
  },
})
</script>
