<template>
  <AppTextField
    :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
    :rules="passwordRules"
    :type="show ? 'text' : 'password'"
    counter
    v-bind="$attrs"
    @input="handleInput"
    @click:append="show = !show"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <slot :name="slot" />
    </template>
  </AppTextField>
</template>

<script>
import AppTextField from './AppTextField.vue'
/**
 * Default Password input
 * @displayName PasswordTextField
 */
/**
 * Display a password input
 * @see props from v-text-field from Vuetify (https://vuetifyjs.com/en/api/v-text-field/) are also available
 */
export default {
  name: 'PasswordTextField',
  components: { AppTextField },
  /**
   * @model
   * input value
   */
  prop: ['value'],
  data () {
    return {
      show: false,
    }
  },
  computed: {
    passwordRules () {
      return this.$attrs.rules || [
        (v) => !!v || this.$t('common.PasswordTextField.required'),
        (v) => (v && v.length >= 8) || this.$attrs.hint,
      ]
    },
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e)
    },
  },
}
</script>

<style>
.v-icon.v-icon--link {
  font-size: 16px;
}
</style>

<docs>
```vue
<template>
  <PasswordTextField v-model="password"
                     placeholder="Type your password"
                     label="Password"
  />
</template>
<script>
  export default {
    data () {
      return {
        password: ''
      }
    },
  }
</script>
```
</docs>
