import axios from 'axios'

export default {
  /**
   * @function getRooms - Retrieve rooms
   * @param {object} queryObject - An object describing optional queryParameters
   * @return AxiosInstance
   */
  getRooms: function (queryObject = {}) {
    return axios.get('rooms', { params: queryObject })
  },
  /**
   * @function getRoomsLabels - Retrieve labels for the room with the mnemo provided
   * @return AxiosInstance
   */
  getRoomLabels: function (mnemo) {
    return axios.get(`room/${mnemo}/labels`)
  },
  /**
   * @function getRoomsLabels - Retrieve labels for this subscription
   * @return AxiosInstance
   */
  getSubscriptionLabels: function (config) {
    return axios.get('rooms/labels', config)
  },
  getDataRoomPricing () {
    return axios.get('subscription/dataroom/pricing')
  },
  getDataRoomCustomPricing (data) {
    return axios.get('subscription/dataroom/pricing/custom', { params: data })
  },
  getLastVisitedRooms (params) {
    return axios.get('rooms/last', { params })
  },
}
