<template>
  <div>
    <v-skeleton-loader
      v-if="summariesCreditsPending"
      type="text"
      :min-width="170"
    />
    <div v-else-if="summariesCredits" class="d-flex align-center column-gap-1">
      <app-icon
        v-if="remaining === 0"
        icon-name="diamond-exclamation"
        icon-weight="fas"
        class="error--text"
      />
      <app-icon
        v-else-if="remaining <= THRESHOLD_WARNING"
        icon-name="exclamation-triangle"
        icon-weight="fas"
        class="yellow--text"
      />

      <app-text
        variant="small-regular"
        as="span"
        v-html="$tc('project.documents.ai-summary.DocumentsAISummaryRemainingCredits.remainingCredits', remaining, { remaining, total })"
      />
      <AppInfoTooltip v-if="renewalDate" top>
        {{ $t('project.documents.ai-summary.DocumentsAISummaryRemainingCredits.creditsRenewalDate', { renewalDate }) }}
      </AppInfoTooltip>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import AppInfoTooltip from '@/common/AppInfoTooltip.vue'
import { ISOToDDMMYYYY } from '@/common/utils/dates'

const THRESHOLD_WARNING = 3

export default defineComponent({
  name: 'DocumentsAISummaryRemainingCredits',
  components: { AppInfoTooltip },
  data () {
    return {
      THRESHOLD_WARNING,
    }
  },
  computed: {
    ...mapState('documentsAISummary', ['summariesCredits', 'summariesCreditsPending']),
    total () {
      return this.summariesCredits?.total ?? 0
    },
    remaining () {
      return this.summariesCredits?.remaining ?? 0
    },
    renewalDate () {
      return this.summariesCredits?.renewalDate
        ? ISOToDDMMYYYY(this.summariesCredits.renewalDate)
        : null
    },
  },
})
</script>
