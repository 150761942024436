<template>
  <div class="d-flex align-center">
    <app-icon v-if="!validation.validated" icon-name="clock" icon-weight="far" size="small" class="tertiary--text mr-1"/>
    <AppTooltip v-if="validation.validated" top>
      <template #activator="{attrs, on}">
        <app-icon icon-name="circle-check" icon-weight="fas" class="mr-1 success--text" size="small" v-bind="attrs" v-on="on"/>
      </template>
      <span>{{ $t('project.project-settings.signatures.ProjectSettingsSignaturesValidators.validatedAtTooltip', { date: ISOToShortDate(validation.validator.validatedAt) }) }}</span>
    </AppTooltip>
    <div>{{ validation.validator.fullName }}</div>
    <app-button v-if="canChase()"
                :loading="isValidationChaseLoading()"
                :disabled="validationChaseButtonIsDisabled()"
                size="small"
                type="tonal"
                variant="brand"
                class="ml-1"
                @click.stop="preparechaseValidators"
    >
      {{ $t('common.chase') }}
    </app-button>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import { ISOToShortDate } from '@/common/utils/dates'
import { CHASE_VALIDATOR } from '@/store/modules/room/action_types'
import { ADD_CHASE_TIMER_VALIDATION } from '@/store/modules/room/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'ProjectSettingsSignaturesValidator',
  components: { AppTooltip },
  props: {
    validation: {
      type: Object,
      required: true,
    },
    currentDate: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('room', ['chaseTimers', 'chaseValidatorPendingIds']),
    ...mapGetters('room', ['roomMnemo']),
  },
  methods: {
    ...mapActions('room', [CHASE_VALIDATOR]),
    ...mapMutations('room', [ADD_CHASE_TIMER_VALIDATION]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    ISOToShortDate,
    isValidationChaseLoading () {
      return this.chaseValidatorPendingIds.includes(this.validation.id)
    },
    canChase () {
      return (this.item.status === 'unvalidated' || this.item.status === 'sent') && !this.validation.validated
    },
    validationChaseButtonIsDisabled () {
      const validationChaseTimer = this.chaseTimers.validation?.[this.validation.id]
      return validationChaseTimer && dayjs().isBefore(validationChaseTimer)
    },
    async preparechaseValidators () {
      try {
        await this.CHASE_VALIDATOR({
          mnemo: this.roomMnemo,
          envelopeId: this.item.id,
          data: [this.validation.validator.id],
          validationId: this.validation.id,
        })
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('common.chaseSuccess'))
        this.ADD_CHASE_TIMER_VALIDATION(this.validation.id)
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.chaseError'))
      }
    },
  },
})
</script>
