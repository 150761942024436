<template>
  <v-timeline-item fill-dot small class="qa-item-thread-answer">
    <template #icon>
      <v-avatar
                size="26"
                class="qa-item-thread-answer__initials"
      >
        {{ answer.userShortInitials }}
      </v-avatar>
    </template>

    <div class="qa-item-thread-answer__header">
      <span class="qa-item-thread-answer__username">{{ answer.userName }}</span>

      <span class="caption qa-item-thread-answer__creation-date">
          {{ $t('project.question-answer.QuestionAnswerItemThreadAnswer.publishedOn') }}
          <DateDisplay :value="answer.createdAt"
                       with-hours
          />
        </span>
    </div>

    <div class="mt-3" v-html="answer.description"></div>
  </v-timeline-item>
</template>

<script>
import DateDisplay from '@/common/DateDisplay'

export default {
  name: 'QuestionAnswerItemThreadAnswer',
  components: { DateDisplay },
  props: {
    answer: {
      type: Object,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-timeline-item__dot {
    width: 26px;
    height: 26px;
    box-shadow: none;
    font-size: 12px;
    // Fixes an alignment problem wit the username
    margin-top: 4px;
}

::v-deep .v-timeline-item__divider {
  // The margin is handled this way for timeline dots
  min-width: 44px;
}
.qa-item-thread-answer {
  &__initials {
    color: white;
    font-size: 12px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
  }

  &__username {
    font-size: 16px;
    font-weight: 500;
  }

  &__creation-date {
    color: #626262;
  }
}
</style>
