<template>
  <v-card>
    <v-card-title>
      <AppButton icon class="ml-auto mr-0" @click="closePreview">
        <font-awesome-icon :icon="['fa', 'xmark']" />
      </AppButton>
    </v-card-title>

    <v-card-text>
      <v-skeleton-loader v-if="previewIsLoading" type="card-heading, paragraph@5" />

      <div v-if="!previewIsLoading && invitationPreview">
        <iframe :srcdoc="invitationPreview"
                style="width: 100%; height: 70vh; border: 0"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AppButton from '@/common/buttons/AppButton'
import groupsService from '@/services/groups.service'

export default {
  name: 'MemberInvitationPreview',
  components: {
    AppButton,
  },
  props: {
    memberForm: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      invitationPreview: null,
      previewIsLoading: false,
    }
  },
  async mounted () {
    this.previewIsLoading = true
    const {
      firstName,
      lastName,
      email,
      locale,
      customInvitationMessage: customMessage,
    } = this.memberForm

    try {
      const previewResponse = await groupsService.getPreviewInvitation(this.$route.params.mnemo, {
        firstName,
        lastName,
        email,
        locale,
        customMessage,
      })

      this.invitationPreview = previewResponse.data.html
    } catch (error) {
      console.error(error)

      this.$store.commit('enqueueSnackbar', {
        color: 'error',
        message: this.$t('project.members.MemberInvitationPreview.getInvitationPreviewError'),
      })
    } finally {
      this.previewIsLoading = false
    }
  },
  methods: {
    closePreview () {
      this.$emit('closeInvitationPreview')
    },
  },
}
</script>
