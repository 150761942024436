<template>
  <div>
    <v-row v-if="isMultipleEnvelope">
      <v-col>
        <Alert class="mb-4"
               color="light-blue"
        >
          <div>
            <span v-html="$t('project.signing-checklist.add.step2.Section4.multiEnvelopeSignerHint.intro')"></span>
            <ul>
              <li v-html="$t('project.signing-checklist.add.step2.Section4.multiEnvelopeSignerHint.commonSigners')"></li>
              <li v-html="$t('project.signing-checklist.add.step2.Section4.multiEnvelopeSignerHint.uniqueSigners')"></li>
            </ul>
          </div>
        </Alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col xl="10" cols="12" :offset="$vuetify.breakpoint.xlOnly ? 1 : 0">
        <SigningChecklistTemplateSignersDetails v-if="addFromTemplate"
                                                class="mb-6"
                                                :generic-signers="templateGenericSigners"
        />

        <div class="mb-8 d-flex justify-space-between align-center">
          <div>
            <SearchTextField v-model="searchQuery" />
            <app-text variant="x-small-regular" class="mt-2">
              {{ $t('project.signing-checklist.add.step2.Section4.searchMemberHelpText') }}
            </app-text>
          </div>

          <div class="d-flex align-center">
            <AppTooltip v-if="isWitnessButtonDisplayed"
                        top
                        :disabled="!isWitnessButtonDisabled"
            >
              <template #activator="{ on }">
                <div v-on="on">
                  <app-button class="witness-button mr-4"
                             type="tonal"
                             variant="neutral"
                             :disabled="isWitnessButtonDisabled"
                             @click="isWitnessDialogOpen = true"
                  >
                    <template #left-icon>
                      <font-awesome-icon :icon="['fas', 'link']"/>
                    </template>
                    {{ $t('project.signing-checklist.add.step2.Section4.addWitness') }}
                  </app-button>
                </div>
              </template>
              {{ getWitnessTooltip }}
            </AppTooltip>
            <AppTooltip v-if="isSignersOrderButtonDisplayed" top :disabled="hasMultipleSigners && !isEditingLimited">
              <template #activator="{ on }">
                <div v-on="on">
                  <app-button class="role-order-button"
                             type="tonal"
                             variant="neutral"
                             :disabled="!hasMultipleSigners || isEditingLimited"
                             @click="onSignersOrderClick"
                  >
                    <template #left-icon>
                      <font-awesome-icon :icon="['fas', 'list']"/>
                    </template>
                    {{ $t('project.signing-checklist.add.step2.Section4.signatoriesOrder') }}
                  </app-button>
                </div>
              </template>
              {{ $t('project.signing-checklist.add.step2.Section4.signatoriesOrderTooltipDisabled') }}
            </AppTooltip>
          </div>
        </div>

        <app-alert-in-page
          v-if="isQualifiedSignatoriesOrderAlertDisplayed"
          variant="warning"
          :dismissible="false"
        >
          <div v-html="$t('project.signing-checklist.add.step2.Section4.defineSignatoriesOrder')"></div>

          <a class="app-link learnMoreLink font-weight-medium"
             :href="qualifiedSignatureHelpLink"
             target="_blank"
          >
            {{ $t("common.learnMore") }}
            <font-awesome-icon :icon="['far', 'external-link']" class="ml-1" size="xs"/>
          </a>
        </app-alert-in-page>

        <EnvelopeUsersSelector
          :step="file.objectToPost"
          :is-for-multi-envelope="isMultipleEnvelope"
          :search-query="searchQuery"
        />
      </v-col>
    </v-row>
    <SignersOrderDialog v-if="signatoriesOrderDialog"
                        :signers="file.objectToPost.signers"
                        :file="file"
                        @close="signatoriesOrderDialog = false"
                        @patch-signers-order="validateSignersOrder"
                        @reset-signers-order="$emit('resetSigners')"
    />
    <SignersOrderQualifiedDialog v-if="isSignersOrderQualifiedDialogOpen"
                                 :signers="file.objectToPost.signers"
                                 :file="file"
                                 @close="isSignersOrderQualifiedDialogOpen = false"
                                 @patch-signers-order="validateSignersOrder"
                                 @reset-signers-order="$emit('resetSigners')"
    />
    <WitnessDialog v-if="isWitnessDialogOpen"
                   :signers="file.objectToPost.signers"
                   :file-id="file.id"
                   :witnesses="file.objectToPost.witnesses"
                   @close="isWitnessDialogOpen = false"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { createNamespacedHelpers, mapGetters } from 'vuex'

import Alert from '@/common/alerts/Alert.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import SearchTextField from '@/common/SearchTextField.vue'
import EnvelopeUsersSelector from '@/common/users/envelopeUsersSelector/EnvelopeUsersSelector.vue'
import { formatNumberedSignerName } from '@/common/utils/signingChecklist'
import {
  ENVELOPE_TYPES,
  SIGNATURE_TYPES,
  SIGNATURE_TYPE_DOCUSIGN_ADVANCED,
  SIGNATURE_TYPE_DOCUSIGN_QUALIFIED,
  SIGNATURE_TYPE_DOCUSIGN_SIMPLE,
  SIGNATURE_TYPE_HANDWRITTEN,
} from '@/project/signing-checklist/constants'
import SignersOrderDialog from '@/project/signing-checklist/signer-order/SignersOrderDialog.vue'
import WitnessDialog from '@/project/signing-checklist/witness/WitnessDialog.vue'
import { SET_SIGNERS_GROUP_ORDER } from '@/store/modules/signing-checklist/mutation_types'

import { type GenericSignature } from './signers-assignation/SigningChecklistSignersAssignationList.vue'
import SigningChecklistTemplateSignersDetails from './SigningChecklistTemplateSignersDetails.vue'
import SignersOrderQualifiedDialog from '../../signer-order/SignersOrderQualifiedDialog.vue'

const { mapMutations: mapSigningChecklistMutations } = createNamespacedHelpers('signingChecklist')
export default Vue.extend({
  name: 'Section4',
  components: {
    SigningChecklistTemplateSignersDetails,
    Alert,
    AppTooltip,
    EnvelopeUsersSelector,
    SearchTextField,
    SignersOrderDialog,
    SignersOrderQualifiedDialog,
    WitnessDialog,
  },
  props: {
    file: { type: Object, required: true },
    hasSignersOrder: { type: Boolean, required: true },
    isEditingLimited: { type: Boolean, required: true },
  },
  data () {
    return {
      SIGNATURE_TYPE_HANDWRITTEN,
      isSignersOrderQualifiedDialogOpen: false,
      isWitnessDialogOpen: false,
      searchQuery: '',
      signatoriesOrderDialog: false,
    }
  },
  inject: ['addFromTemplate'],
  computed: {
    ...mapGetters('groups', ['allRoomMembers']),
    ...mapGetters('room', ['isWitnessEnabled']),
    hasAtLeastOneSigner (): boolean {
      return this.file?.objectToPost?.signers?.flat().length > 0
    },
    hasMultipleSigners (): boolean {
      return this.file?.objectToPost?.signers?.flat().length > 1
    },
    isMultipleEnvelope (): boolean {
      return this.file?.objectToPost?.envelopeType === ENVELOPE_TYPES.MULTIPLE
    },
    isSignersOrderButtonDisplayed (): boolean {
      return this.file.objectToPost.signatureType !== SIGNATURE_TYPE_HANDWRITTEN &&
        !this.isMultipleEnvelope
    },
    isQualifiedSignatoriesOrderAlertDisplayed (): boolean {
      return this.file.objectToPost.signatureType === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED &&
        this.hasMultipleSigners &&
        !this.hasSignersOrder
    },
    templateGenericSigners (): Array<string> {
      return this.file.templateSignatures
        .filter((templateSignature: GenericSignature) => templateSignature.signerType === SIGNATURE_TYPES.SINGLE)
        .map((templateSignature: GenericSignature, index) => templateSignature.signerName ?? formatNumberedSignerName(templateSignature, index + 1, this.isMultipleEnvelope))
    },
    isWitnessButtonDisplayed (): boolean {
      const witnessCompatibleSignatureTypes = [
        SIGNATURE_TYPE_DOCUSIGN_SIMPLE,
        SIGNATURE_TYPE_DOCUSIGN_ADVANCED,
        SIGNATURE_TYPE_DOCUSIGN_QUALIFIED,
      ]
      return witnessCompatibleSignatureTypes.includes(this.file.objectToPost.signatureType) && this.isWitnessEnabled
    },
    getWitnessTooltip (): TranslateResult | null {
      const witnessesFeatureNotAvailable = this.file.objectToPost.envelopeType === ENVELOPE_TYPES.MULTIPLE ||
        this.file.objectToPost.signatureType === SIGNATURE_TYPE_DOCUSIGN_ADVANCED ||
        this.file.objectToPost.signatureType === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED

      if (witnessesFeatureNotAvailable) {
        return this.$t('project.signing-checklist.add.step2.Section4.addWitnessErrorSignatureMultipleTooltip')
      }

      return this.$t('project.signing-checklist.add.step2.Section4.addWitnessTooltip')
    },
    isWitnessButtonDisabled (): boolean {
      if (this.file.objectToPost.envelopeType === ENVELOPE_TYPES.MULTIPLE) {
        return true
      } if (this.file.objectToPost.signatureType !== SIGNATURE_TYPE_DOCUSIGN_SIMPLE) {
        return true
      }

      return !this.hasAtLeastOneSigner || this.isEditingLimited
    },
    qualifiedSignatureHelpLink () {
      if (this.$i18n.locale === 'fr') {
        return 'https://help.closd.com/fr/knowledge-base/signature-qualifiee-ordonner-les-signataires/'
      }
      return 'https://help.closd.com/en/knowledge-base/qualified-electronic-signature-order-the-signatories/'
    },
  },
  methods: {
    ...mapSigningChecklistMutations([SET_SIGNERS_GROUP_ORDER]),
    validateSignersOrder (groups: number[]): void {
      this.SET_SIGNERS_GROUP_ORDER({
        groups,
        file: this.file,
      })
    },
    onSignersOrderClick (): void {
      if (this.file.objectToPost.signatureType === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED) {
        this.isSignersOrderQualifiedDialogOpen = true
      } else {
        this.signatoriesOrderDialog = true
      }
    },
  },
})
</script>
