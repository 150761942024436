export const DELETE_USER = 'DELETE_USER'
export const DOWNLOAD_INVOICES = 'DOWNLOAD_INVOICES'
export const GET_ACTIVE_ROOMS = 'GET_ACTIVE_ROOMS'
export const GET_CLOSED_ROOMS = 'GET_CLOSED_ROOMS'
export const GET_SEATS = 'GET_SEATS'
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION'
export const GET_SUBSCRIPTION_BRANDING = 'GET_SUBSCRIPTION_BRANDING'
export const GET_SUBSCRIPTION_INVOICES = 'GET_SUBSCRIPTION_INVOICES'
export const GET_SUBSCRIPTION_USERS = 'GET_SUBSCRIPTION_USERS'
export const PATCH_SUB_ADMIN = 'PATCH_SUB_ADMIN'
export const PATCH_SUB_ADMIN_BILLING = 'PATCH_SUB_ADMIN_BILLING'
export const PATCH_SUB_ADMIN_USER = 'PATCH_SUB_ADMIN_USER'
export const POST_NEW_SUBSCRIBER = 'POST_NEW_SUBSCRIBER'
export const POST_SUBSCRIPTION_TEAM = 'POST_SUBSCRIPTION_TEAM'
export const PATCH_SUBSCRIPTION_TEAM = 'PATCH_SUBSCRIPTION_TEAM'
export const GET_SUBSCRIPTION_TEAMS = 'GET_SUBSCRIPTION_TEAMS'
export const REQUEST_FEATURE_QUOTE = 'REQUEST_FEATURE_QUOTE'
export const RESTORE_ROOM = 'RESTORE_ROOM'
export const DELETE_SUBSCRIPTION_TEAM = 'DELETE_SUBSCRIPTION_TEAM'
