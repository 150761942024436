<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             :ok-text="$t('common.yes')"
             :ok-disabled="confirmDisabled"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>
      {{ $tc('project.signing-checklist.list.SigningChecklistDeleteStepDialog.title', ids.length) }}
    </template>
    <template #body>
      <div v-if="!isMulti && deletionTypePending" class="text-center">
        <v-progress-circular
          size="48"
          indeterminate
          color="primary"
        />
      </div>
      <template v-else-if="deletionType">
        <div v-if="!isMulti && deletionType.inClosing === true && deletionType.partOfClosing === true">
          <p>{{ $t('project.signing-checklist.list.SigningChecklistDeleteStepDialog.cantDeleteNeedToCancel') }}</p>
        </div>

        <div v-else-if="!isMulti && deletionType.status === 'sent'">
          <p>{{ $t('project.signing-checklist.list.SigningChecklistDeleteStepDialog.cantDeleteIfSent') }}</p>
        </div>

        <div v-else>
          <p>{{ $tc('project.signing-checklist.list.SigningChecklistDeleteStepDialog.body', ids.length) }}</p>
          <p v-if="!isMulti && deletionType.status === 'signed'">
            {{ $t('project.signing-checklist.list.SigningChecklistDeleteStepDialog.signaturesRemainValid') }}
          </p>
          <p v-if="!isMulti && deletionType.inClosing === true">
            {{ $t('project.signing-checklist.list.SigningChecklistDeleteStepDialog.closingContinues') }}
          </p>
        </div>
      </template>
    </template>
  </AppDialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { GET_DELETION_TYPE } from '../../../store/modules/signing-checklist/action_types'

const { mapState, mapActions } = createNamespacedHelpers('signingChecklist')

export default {
  name: 'SigningChecklistDeleteStepDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    ids: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(['deletionType', 'deletionTypePending']),
    isMulti () {
      return this.ids.length > 1
    },
    confirmDisabled () {
      return this.isMulti
        ? false
        : this.deletionTypePending || !this.deletionType ||
          (this.deletionType.inClosing === true && this.deletionType.partOfClosing === true) ||
          this.deletionType?.status === 'sent'
    },
  },
  created () {
    if (!this.isMulti) {
      this.GET_DELETION_TYPE({ mnemo: this.mnemo, stepId: this.ids[0] })
    }
  },
  methods: {
    ...mapActions([GET_DELETION_TYPE]),
    confirmClick () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
