<template>
  <div>
    <AppButton color="white"
               height="30"
               class="my-1 btn-allow-wrap"
               :x-padding="1"
               @click="openDialog = true"
    >
      <font-awesome-icon :icon="['far', 'circle-check']"
                         class="success--text mr-1 body-1"
      ></font-awesome-icon>
      {{ $t('project.signing-checklist.list.SigningChecklistTableItemValidateDialog.validation.validateDocument') }}
    </AppButton>
    <AppDialog :is-open="openDialog"
               size="l"
               :ok-disabled="postValidationPending"
               :ok-loading="postValidationPending"
               :cancel-disabled="postValidationPending"
               @cancel="resetAndClose"
               @ok="preparePostValidation"
    >
      <template #title>{{ $t('project.signing-checklist.list.SigningChecklistTableItemValidateDialog.dialog.title') }}</template>
      <template #body>
        <v-row>
          <v-col>
            <Alert>
              {{ $t('project.signing-checklist.list.SigningChecklistTableItemValidateDialog.alertText') }}
            </Alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="8" offset-lg="2">
            <v-file-input v-model="file"
                          hide-details
                          color="blue darken-2"
                          dense
                          outlined
                          :label="$t('project.signing-checklist.list.SigningChecklistTableItemValidateDialog.fileInputLabel')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="font-italic text--primary">
            {{ $t('project.signing-checklist.list.SigningChecklistTableItemValidateDialog.dialog.text') }}
          </v-col>
        </v-row>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import { SET_SELECTED_STEPS } from '@/store/modules/signing-checklist/mutation_types'

import Alert from '../../../common/alerts/Alert'
import AppButton from '../../../common/buttons/AppButton'
import AppDialog from '../../../common/dialogs/AppDialog'
import { POST_VALIDATION, REFRESH_STEP } from '../../../store/modules/signing-checklist/action_types'

export default {
  name: 'SigningChecklistTableItemValidateDialog',
  components: { Alert, AppButton, AppDialog },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    envelopeId: {
      type: Number,
      required: true,
    },
    validationId: {
      type: Number,
      required: true,
    },
    stepId: {
      type: Number,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      openDialog: false,
      file: null,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['postValidationPending']),
  },
  methods: {
    ...mapActions('signingChecklist', [POST_VALIDATION, REFRESH_STEP]),
    ...mapMutations('signingChecklist', [SET_SELECTED_STEPS]),
    async preparePostValidation () {
      try {
        let formData = null
        if (this.file) {
          formData = new FormData()
          formData.append('file', this.file, this.file.name)
        }
        await this.POST_VALIDATION({
          mnemo: this.mnemo,
          envelopeId: this.envelopeId,
          validationId: this.validationId,
          data: formData || null,
        })
        this.openDialog = false
        this.SET_SELECTED_STEPS([])
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.$t('project.signing-checklist.list.SigningChecklistTableItemValidateDialog.message.success'),
        })
        await this.REFRESH_STEP({
          mnemo: this.mnemo,
          stepId: this.stepId,
          parent: this.parent,
        })
      } catch (e) {
        console.error(e)
        this.$store.commit('enqueueSnackbar', {
          color: 'error',
          message: this.$t('project.signing-checklist.list.SigningChecklistTableItemValidateDialog.message.error'),
        })
      }
    },
    resetAndClose () {
      this.file = null
      this.openDialog = false
    },
  },
}
</script>
