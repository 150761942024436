<template>
  <v-autocomplete solo
                  color="blue darken-2"
                  outlined
                  dense
                  flat
                  append-icon=""
                  v-bind="$attrs"
                  v-on="$listeners"
  >
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>

    <template v-slot:prepend-inner>
      <!-- @slot Inner prepend slot. Replaces the default icon slot -->
      <slot name="prepend-inner"></slot>
    </template>

    <template v-slot:item="{ item }">
      <!-- @slot Item slot. -->
      <slot name="item" v-bind="item"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'AppAutocomplete',
  inheritAttrs: false,
}
</script>
