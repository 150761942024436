<template>
 <Pane :value="filtersPaneIsOpen"
       :tabs="tabs"
       :current-tab="currentTab"
       temporary
       class="qaFiltersPane"
       :right="false"
       @input="onPaneValueChanged"
 >
   <template #content-filters>
     <template v-if="categoriesAsObject && !categoriesPending">
       <v-row>
         <v-col>
           <div class="body-1 font-weight-bold mb-3">{{$t('project.question-answer.QuestionAnswerFiltersPane.category')}}</div>
           <v-row class="checkboxes-container">
             <v-col v-for="(categoryName, categoryId) in categoriesAsObject"
                    :key="`category-${categoryId}`"
                    class="py-2"
                    cols="12"
                    md="6"
             >
               <!-- we need to cast the category's id value -->
               <!-- if not, a string is stored in the filters's state and can't match when comparing with category.id (which is a number) -->
               <v-checkbox v-model="localFilters.categories"
                           :label="categoryName"
                           :value="+categoryId"
                           dense
                           hide-details
                           class="mt-0 pt-0"
               />
             </v-col>
           </v-row>
         </v-col>
       </v-row>
      <v-row>
        <v-col>
          <v-divider class="my-2"/>
        </v-col>
      </v-row>
     </template>
     <v-row>
       <v-col>
         <div class="body-1 font-weight-bold mb-3">{{$t('project.question-answer.QuestionAnswerFiltersPane.questioner')}}</div>
         <v-row class="checkboxes-container">
           <v-col v-for="(questioner, i) in questioners"
                  :key="`questioner-${i}`"
                  class="py-2"
                  cols="12"
                  md="6"
           >
             <v-checkbox v-model="localFilters.questioners"
                         :label="questioner"
                         :value="questioner"
                         dense
                         hide-details
                         class="mt-0 pt-0"
             />
           </v-col>
         </v-row>
       </v-col>
     </v-row>
     <v-row>
       <v-col>
         <v-divider class="my-2"/>
       </v-col>
     </v-row>
     <v-row>
       <v-col>
         <div class="body-1 font-weight-bold mb-3">{{$t('project.question-answer.QuestionAnswerFiltersPane.createdAt')}}</div>
         <AppDatePicker v-model="localFilters.createdAt"
                        range
         />
       </v-col>
     </v-row>
    <v-row>
      <v-col>
        <v-divider class="my-2"/>
      </v-col>
    </v-row>
     <v-row>
       <v-col>
         <div class="body-1 font-weight-bold mb-3">{{$t('project.question-answer.QuestionAnswerFiltersPane.lastUpdate')}}</div>
         <AppDatePicker v-model="localFilters.lastUpdate"
                        range
         />
       </v-col>
     </v-row>
      <v-row>
        <v-col>
          <v-divider class="my-2"/>
        </v-col>
      </v-row>
     <v-row>
       <v-col>
         <div class="body-1 font-weight-bold mb-3">{{$t('project.question-answer.QuestionAnswerFiltersPane.status')}}</div>
         <v-row class="checkboxes-container">
           <v-col v-for="(status, i) in statusList"
                  :key="`status-${i}`"
                  class="py-2"
                  cols="12"
                  md="6"
           >
             <v-checkbox v-model="localFilters.status"
                         :label="$t(`common.qaStatus.${status}`)"
                         :value="status"
                         dense
                         hide-details
                         class="mt-0 pt-0"
             />
           </v-col>
         </v-row>
       </v-col>
     </v-row>
    <v-row>
      <v-col>
        <v-divider class="my-2"/>
      </v-col>
    </v-row>
     <v-row class="sticky-buttons-row">
       <v-col>
         <AppButton
                    block
                    @click="onClearAllFilters"
         >
           {{ $t('common.clear') }}
         </AppButton>
       </v-col>
       <v-col>
         <AppButton color="primary"
                    block
                    @click="onApplyFilters"
         >
           {{ $t('project.question-answer.QuestionAnswerFiltersPane.apply') }}
         </AppButton>
       </v-col>
     </v-row>
   </template>
 </Pane>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDatePicker from '@/common/AppDatePicker'
import AppButton from '@/common/buttons/AppButton'
import Pane from '@/common/Pane'
import { ISOToShortenedDate } from '@/common/utils/dates'
import { QA_STATUS } from '@/common/utils/qa-status'
import { Filter } from '@/models/filter.model'
import { QuestionAnswerActionTypes } from '@/store/modules/question-answer/action_types'
import { QuestionAnswerMutationTypes } from '@/store/modules/question-answer/mutation_types'

const EMPTY_FILTERS = {
  categories: [],
  questioners: [],
  createdAt: [],
  lastUpdate: [],
  status: [],
}

export default {
  name: 'QuestionAnswerFiltersPane',
  components: { AppButton, AppDatePicker, Pane },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      tabs: [{ title: this.$t('project.question-answer.QuestionAnswerFiltersPane.filters'), id: 'filters' }],
      currentTab: 'filters',
      localFilters: { ...EMPTY_FILTERS },
      statusList: Object.values(QA_STATUS),
    }
  },
  computed: {
    ...mapState('questionAnswer', ['filtersPaneIsOpen', 'filters', 'categoriesPending', 'questions']),
    ...mapGetters('questionAnswer', ['questioners', 'categoriesAsObject']),
  },
  watch: {
    filtersPaneIsOpen (value) {
      if (value) {
        this.syncAppliedFiltersWithLocals()
        this.GET_CATEGORIES(this.mnemo)
      }
    },
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.GET_CATEGORIES]),
    ...mapMutations('questionAnswer', [QuestionAnswerMutationTypes.SET_FILTERS, QuestionAnswerMutationTypes.SET_FILTERS_PANE_IS_OPEN]),
    closePane () {
      this.SET_FILTERS_PANE_IS_OPEN(false)
    },
    applyFilters () {
      const categoriesFilters = this.localFilters.categories.map(categoryId =>
        new Filter('categories', this.$t('project.question-answer.QuestionAnswerFiltersPane.categoryFilter'), categoryId, this.categoriesAsObject[categoryId]),
      )
      const questionersFilters = this.localFilters.questioners.map(questioner =>
        new Filter('questioners', this.$t('project.question-answer.QuestionAnswerFiltersPane.questionerFilter'), questioner, questioner),
      )
      const createdAtFilter = this.localFilters.createdAt.length > 0
        ? [
          new Filter(
            'createdAt',
            this.$t('project.question-answer.QuestionAnswerFiltersPane.createdAt'),
            this.localFilters.createdAt,
            `${ISOToShortenedDate(this.localFilters.createdAt[0])} - ${ISOToShortenedDate(this.localFilters.createdAt[1])}`,
          )]
        : []
      const lastUpdateFilter = this.localFilters.lastUpdate.length > 0
        ? [
          new Filter(
            'lastUpdate',
            this.$t('project.question-answer.QuestionAnswerFiltersPane.lastUpdate'),
            this.localFilters.lastUpdate,
            `${ISOToShortenedDate(this.localFilters.lastUpdate[0])} - ${ISOToShortenedDate(this.localFilters.lastUpdate[1])}`,
          )]
        : []
      const statusFilters = this.localFilters.status.map(status =>
        new Filter('status', this.$t('project.question-answer.QuestionAnswerFiltersPane.statusFilter'), status, this.$t(`common.qaStatus.${status}`)),
      )

      const filters = [
        ...categoriesFilters,
        ...questionersFilters,
        ...createdAtFilter,
        ...lastUpdateFilter,
        ...statusFilters,
      ]

      this.SET_FILTERS(filters)
    },
    syncAppliedFiltersWithLocals () {
      this.localFilters.categories = this.filters
        .filter(filter => filter.category.key === 'categories')
        .map(filter => filter.value.key)

      this.localFilters.questioners = this.filters
        .filter(filter => filter.category.key === 'questioners')
        .map(filter => filter.value.key)

      const createdAtFilter = this.filters.find(filter => filter.category.key === 'createdAt')
      this.localFilters.createdAt = createdAtFilter?.value.key ?? []

      const lastUpdateFilter = this.filters.find(filter => filter.category.key === 'lastUpdate')
      this.localFilters.lastUpdate = lastUpdateFilter?.value.key ?? []

      this.localFilters.status = this.filters
        .filter(filter => filter.category.key === 'status')
        .map(filter => filter.value.key)
    },
    onApplyFilters () {
      this.applyFilters()
      this.closePane()
    },
    onClearAllFilters () {
      this.localFilters = { ...EMPTY_FILTERS }
      this.applyFilters()
    },
    onPaneValueChanged (isOpen) {
      if (!isOpen) {
        this.closePane()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.checkboxes-container {
  max-height: calc(36px * 5);
  overflow-y: auto;
}
.sticky-buttons-row {
  position: sticky;
  bottom: 0;
  background-color: #fff;
}
::v-deep.qaFiltersPane .pane-content {
  padding-bottom: 0;
}
</style>
