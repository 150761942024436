<template>
  <Pane :value="paneIsOpen"
        :tabs="tabs"
        :right="false"
        current-tab="filter"
        temporary
        @closeButtonClicked="paneIsOpen = false"
        @input="onInput"
  >
    <template #content-filter>
      <v-row>
        <v-col>
          <div class="body-1 font-weight-bold mb-4">{{ $t('subscriptions.SubscriptionClosedProjectsFiltersPane.projectType') }}</div>
          <v-row no-gutters>
            <v-col
              v-for="(projectType, index) in projectTypes"
              :key="`projectType-${index}`"
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="localFilters.projectTypes"
                :label="$t(`common.projectTypes.${projectType}`)"
                :value="projectType"
                hide-details
                class="my-1 py-0"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider class="my-2"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="body-1 font-weight-bold">{{ $t('subscriptions.SubscriptionClosedProjectsFiltersPane.deletedAt') }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <AppDatePicker v-model="localFilters.deletedAt"
                         range
          />
        </v-col>
      </v-row>
      <template v-if="members.length > 0">
        <v-row>
          <v-col>
            <v-divider class="my-2"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="body-1 font-weight-bold">
            {{ $t('subscriptions.SubscriptionClosedProjectsFiltersPane.members') }}
          </v-col>
        </v-row>
          <v-row>
            <v-col v-for="(member, index) in members.length > 10 && !displayMoreMembers ? shortMembers : members"
                   :key="`member-${index}`"
                   cols="12"
                   lg="6"
            >
              <v-checkbox v-model="localFilters.members"
                          :label="member"
                          dense
                          hide-details
                          :value="member"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="members.length > 10 || displayMoreMembers">
            <v-col>
              <div class="displayMoreMembers text-h4 clickable"
                   @click="displayMoreMembers = true"
              >
                {{ $t('subscriptions.SubscriptionClosedProjectsFiltersPane.displayMoreMembers') }}
              </div>
            </v-col>
          </v-row>
          <v-row v-if="members.length > 10 && displayMoreMembers">
            <v-col>
              <div class="displayLessMembers text-h4 clickable"
                   @click="displayMoreMembers = false"
              >
                {{ $t('subscriptions.SubscriptionClosedProjectsFiltersPane.displayLessMembers') }}
              </div>
            </v-col>
          </v-row>
      </template>
      <v-row>
        <v-col>
          <v-divider class="my-2"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <AppButton block
                     @click="onClearAllFilters"
          >
            {{ $t('common.clear') }}
          </AppButton>
        </v-col>
        <v-col>
          <AppButton color="primary"
                     block
                     @click="onValidateClick"
          >
            {{ $t('common.apply') }}
          </AppButton>
        </v-col>
      </v-row>
    </template>
  </Pane>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import { ISOToShortenedDate } from '@/common/utils/dates'
import { Filter } from '@/models/filter.model'

import AppDatePicker from '../common/AppDatePicker'
import AppButton from '../common/buttons/AppButton'
import Pane from '../common/Pane'
import { SET_CLOSED_ROOMS_FILTERS, SET_CLOSED_ROOMS_FILTERS_PANE_IS_OPEN } from '../store/modules/subscription/mutation_types'

const INITIAL_FILTERS = {
  projectTypes: [],
  deletedAt: [],
  members: [],
}

export default {
  name: 'SubscriptionClosedProjectsFiltersPane',
  components: { AppButton, Pane, AppDatePicker },
  data () {
    return {
      tabs: [{ id: 'filter', title: this.$t('subscriptions.SubscriptionClosedProjectsFiltersPane.filters') }],
      projectTypes: ['project', 'dataroom'],
      paneIsOpen: false,
      displayMoreMembers: false,
      localFilters: { ...INITIAL_FILTERS },
    }
  },
  computed: {
    ...mapState('subscription', ['closedRoomsFiltersPaneIsOpen', 'closedRoomsFilters', 'closedRooms']),
    members () {
      const members = []
      if (this.closedRooms?.data) {
        this.closedRooms.data.forEach(room => {
          if (room.usersCount !== -1) {
            room.users.forEach((user) => {
              if (!members.includes(user.fullName)) {
                members.push(user.fullName)
              }
            })
          }
        })
      }
      return members
    },
    shortMembers () {
      return this.members.slice(0, 10)
    },
  },
  watch: {
    closedRoomsFiltersPaneIsOpen: {
      handler (value) {
        if (value) {
          this.syncAppliedFiltersWithLocals()
          setTimeout(() => { this.paneIsOpen = true }, 300)
        }
      },
      immediate: true,
    },
    paneIsOpen (value) {
      if (!value) {
        setTimeout(() => {
          this.SET_CLOSED_ROOMS_FILTERS_PANE_IS_OPEN(false)
        }, 300)
      }
    },
  },
  methods: {
    ...mapMutations('subscription', [
      SET_CLOSED_ROOMS_FILTERS_PANE_IS_OPEN,
      SET_CLOSED_ROOMS_FILTERS,
    ]),
    applyFilters () {
      const dateFilter = this.localFilters.deletedAt.length > 0
        ? [
          new Filter(
            'deletedAt',
            this.$t('subscriptions.SubscriptionClosedProjectsFiltersPane.deletedAt'),
            this.localFilters.deletedAt,
            `${ISOToShortenedDate(this.localFilters.deletedAt[0])} - ${ISOToShortenedDate(this.localFilters.deletedAt[1])}`,
          )]
        : []

      const membersFilters = this.localFilters.members.map(member =>
        new Filter('members', this.$t('common.user'), member, member),
      )

      const projectTypeFilters = this.localFilters.projectTypes.map((type) =>
        new Filter(
          'projectType',
          this.$t('subscriptions.SubscriptionClosedProjectsFiltersPane.projectTypeFilter'),
          type,
          this.$t('common.projectTypes.' + type),
        ),
      )
      const filters = [
        ...dateFilter,
        ...membersFilters,
        ...projectTypeFilters,
      ]
      this.SET_CLOSED_ROOMS_FILTERS(filters)
    },
    syncAppliedFiltersWithLocals () {
      const dateFilter = this.closedRoomsFilters.find(filter => filter.category.key === 'deletedAt')
      this.localFilters.deletedAt = dateFilter?.value.key ?? []

      const projectTypeFilters = this.closedRoomsFilters.filter((filter) => filter.category.key === 'projectType')
      this.localFilters.projectTypes = projectTypeFilters.map((filter) => filter.value.key)

      this.localFilters.members = this.closedRoomsFilters
        .filter(filter => filter.category.key === 'members')
        .map(filter => filter.value.key)
    },
    onClearAllFilters () {
      this.SET_CLOSED_ROOMS_FILTERS([])
    },
    onValidateClick () {
      this.applyFilters()
      this.paneIsOpen = false
    },
    onInput (value) {
      if (!value) {
        this.paneIsOpen = false
      }
    },
  },
}
</script>
