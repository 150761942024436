import {
  SET_MAINTENANCE_MODE_ENABLED,
  SET_STATUS_PENDING,
  SET_STATUS_ERROR,
  SET_RETRY_AT,
} from './mutation_types'

export const mutations = {
  [SET_RETRY_AT] (state, value) {
    state.retryAt = value
  },
  [SET_MAINTENANCE_MODE_ENABLED] (state, value) {
    state.maintenanceModeEnabled = value
  },
  [SET_STATUS_PENDING] (state, value) {
    state.statusPending = value
  },

  [SET_STATUS_ERROR] (state, value) {
    state.statusError = value
  },
}
