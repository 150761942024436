<template>
<div class="fill-height d-flex flex-column align-center justify-center">
  <img src="/img/logo.svg"
       width="150"
       class="mb-5"
  />
  <div v-if="authenticationInProgress"
       class="text-h3 mb-5"
  >
    {{ $t('login.views.AuthByToken.authInProgress') }}
  </div>
  <div v-if="redirectInProgress"
       class="text-h3 mb-5"
  >
    {{ $t('login.views.AuthByToken.redirectInProgress') }}
  </div>
  <div style="width: 200px;">
    <v-progress-linear v-if="authenticationInProgress || redirectInProgress"
                       color="primary"
                       indeterminate
    />
  </div>
  <div v-if="errorToDisplay"
       class="text-center"
  >
    <Alert type="error"
           :icon="false"
           :dismissible="false"
    >
      {{ $t(errorToDisplay) }}
    </Alert>
    <AppButton color="primary"
               class="mt-5"
               @click="forceSignInView"
    >
      {{ $t('login.views.AuthByToken.signIn') }}
    </AppButton>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapMutations } from 'vuex'

import Alert from '@/common/alerts/Alert'
import AppButton from '@/common/buttons/AppButton'
import { USER_ID_STORAGE_KEY, USER_TOKEN_STORAGE_KEY } from '@/common/utils/users'
import {
  ROOM_ROUTE_TYPES,
  SIMPLE_SIGNATURE_ROUTE_NAME,
  SIGNING_VIEW_ROUTE_NAME,
  NOTIFICATIONS_PREFERENCES_ROUTE_NAME,
  PROFILE_SETTINGS_ROUTE_NAME,
  LOGOUT_ROUTE_NAME,
  TOKEN_SCOPES,
} from '@/router'
import { GET_AUTH_FROM_TOKEN } from '@/store/modules/login/action_types'
import { SET_USER_PROFILE } from '@/store/modules/user/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import { SET_USER_TOKEN_SCOPE } from '../../store/modules/login/mutation_types'

export default {
  name: 'AuthByToken',
  components: { AppButton, Alert },
  props: {
    envelopeId: String,
    mnemo: String,
    token: {
      type: String,
      required: true,
    },
    withoutApiToken: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      authenticationInProgress: true,
      redirectInProgress: false,
      errorToDisplay: null,
    }
  },
  computed: {
    purpose () {
      for (const value of Object.values(TOKEN_SCOPES)) {
        if (value === this.$route.name) {
          return value
        }
      }
      return null
    },
    routeName () {
      return this.$route.name
    },
  },
  mounted () {
    if (this.purpose && this.token) {
      this.prepareGetAuthFromToken()
    }
    const lang = window.navigator.language
    if (lang.includes('-')) {
      this.$i18n.locale = lang.split('-')[0]
    } else {
      this.$i18n.locale = lang
    }
  },
  methods: {
    ...mapActions('login', [GET_AUTH_FROM_TOKEN]),
    ...mapMutations('login', [SET_USER_TOKEN_SCOPE]),
    async prepareGetAuthFromToken () {
      try {
        const response = await this.GET_AUTH_FROM_TOKEN({
          params: {
            purpose: this.purpose,
            token: this.token,
          },
          withoutApiToken: this.withoutApiToken,
        })
        localStorage.setItem(USER_ID_STORAGE_KEY, response.data.user.id)
        localStorage.setItem(USER_TOKEN_STORAGE_KEY, response.data.access_token)
        this.SET_USER_TOKEN_SCOPE(response.data.scopes)
        axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`
        this.authenticationInProgress = false
        this.redirectInProgress = true
        this.$store.commit(`user/${SET_USER_PROFILE}`, response.data.user)
        this.redirectToRightView()
      } catch (error) {
        if (['ERR_INCORRECT_DATA', 'ERR_TOKEN_EXPIRED'].includes(error.response?.data?.errorCode)) {
          this.errorToDisplay = error.response.data.errorCode
        } else if (error.response?.data?.errorCode !== 'ERR_WRONG_USER_LOGGED_IN') {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('login.views.AuthByToken.genericError'),
          })
          this.forceSignInView()
        }
      } finally {
        this.authenticationInProgress = false
      }
    },
    redirectToRightView () {
      if (this.routeName === SIMPLE_SIGNATURE_ROUTE_NAME) {
        this.$router.replace({
          name: SIGNING_VIEW_ROUTE_NAME,
          params: {
            envelopeId: this.envelopeId,
            mnemo: this.mnemo,
            roomType: ROOM_ROUTE_TYPES.PROJECT,
            token: this.token,
          },
        })
      } else if (this.routeName === NOTIFICATIONS_PREFERENCES_ROUTE_NAME) {
        this.$router.replace({
          name: PROFILE_SETTINGS_ROUTE_NAME,
        })
      }
    },
    forceSignInView () {
      this.$router.replace({ name: LOGOUT_ROUTE_NAME })
    },
  },
}
</script>
