<template>
  <div class="d-flex align-center column-gap-2 mb-4">
    <slot></slot>
    <v-chip small>
      <app-text variant="button-small" class="ma-auto">{{ counter }}</app-text>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'SigningChecklistSummaryListTitle',
  props: {
    counter: {
      type: Number,
      required: true,
    },
  },
}
</script>
