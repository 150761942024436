<template>
  <draggable :list="list"
             :handle="handle"
             group="statuses"
             :forceFallback="true"
             :emptyInsertThreshold="100"
             :delay="500"
             :delayOnTouchOnly="true"
  >
    <slot name="content"></slot>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'AppDraggable',
  components: { draggable },
  props: {
    list: {
      type: Array,
      required: true,
    },
    handle: {
      type: String,
      required: true,
    },
  },
}
</script>
