<template>
  <div class="QuestionAnswerMultiSelectBar px-3">
    <app-button class="mr-2"
                type="icon"
                @click="$emit('deselectAll')">
      <app-icon icon-name="xmark" />
    </app-button>

    <div class="text-h4">
      {{ $tc('project.question-answer.QuestionAnswerBulkRights.selectedQuestionsCount', selection.length, {nb: selection.length}) }}
    </div>

    <v-divider class="mx-4 my-1" vertical />

    <MultiSelectBarButton @click="openQuestionAnswerBulkRights">
      <app-icon icon-name="user-lock" class="mr-2" />
      {{ $t('project.question-answer.QuestionAnswerBulkRights.manageAccess') }}
    </MultiSelectBarButton>

    <!-- MODALS -->
    <QuestionAnswerBulkRightsDialog v-if="questionAnswerBulkRightsIsOpen"
                                    :selection="selection"
                                    @close="closeQuestionAnswerBulkRights"
    />
  </div>
</template>

<script>
  import MultiSelectBarButton from '@/common/buttons/MultiSelectBarButton.vue'
  import QuestionAnswerBulkRightsDialog from '@/project/question-answer/dialogs/QuestionAnswerBulkRightsDialog.vue'

  export default {
    name: 'QuestionAnswerMultiSelectBar',
    components: {
      MultiSelectBarButton,
      QuestionAnswerBulkRightsDialog,
    },
    props: {
      selection: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        questionAnswerBulkRightsIsOpen: false,
      }
    },
    methods: {
      openQuestionAnswerBulkRights () {
        this.questionAnswerBulkRightsIsOpen = true
      },
      closeQuestionAnswerBulkRights () {
        this.questionAnswerBulkRightsIsOpen = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .QuestionAnswerMultiSelectBar {
    display: flex;
    align-items: center;
    border: 1px solid var(--v-primary-base);
    border-radius: 8px;
    flex-wrap: wrap;
  }
  ::v-deep .QuestionAnswerMultiSelectBar-button {
    span.v-btn__content {
      opacity: 1 !important;
    }
  }
</style>
