<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t(`common.app-file-upload.RoomStorageExceededDialog.dialogOkLabel.${currentUserTranslationStatus}`)"
             :ok-loading="notifyPmNoMoreSpacePending"
             :cancel-only="isCurrentRoomOnAPlan"
             :cancel-text="$t('common.close')"
             @cancel="closeDialog"
             @ok="onConfirm"
  >
    <template #title>
      <div class="d-flex align-center">
        <app-icon icon-name="times-circle" icon-weight="fas" class="error--text mr-2"/>
        {{ $t('common.app-file-upload.RoomStorageExceededDialog.dialogTitle') }}
      </div>
    </template>
    <template #body>
     <span v-html="dialogBodyMessage"></span>
    </template>
  </AppDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import { ROOM_SETTINGS_ROUTE_NAME } from '@/router'
import { NOTIFY_PM_NO_MORE_SPACE } from '@/store/modules/room/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

import { ROOM_TYPES } from '../utils/roomTypes'

export default defineComponent({
  name: 'RoomStorageExceededDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  computed: {
    ...mapGetters('room', ['roomMnemo', 'isPmOfRoomSubscription']),
    ...mapState('room', ['currentRoom', 'notifyPmNoMoreSpacePending']),
    currentUserTranslationStatus () : string {
      return this.isPmOfRoomSubscription ? 'isPm' : 'isGuest'
    },
    isCurrentRoomOnAPlan () : boolean {
      return this.currentRoom.type === ROOM_TYPES.DATAROOM_UNLIMITED
    },
    dialogBodyMessage () : TranslateResult {
      if (!this.isCurrentRoomOnAPlan) {
        return this.$t(`common.app-file-upload.RoomStorageExceededDialog.dialogContent.${this.currentUserTranslationStatus}`)
      } else {
        return this.$t('common.app-file-upload.RoomStorageExceededDialog.dialogContent.roomOnAPlan')
      }
    },
  },
  methods: {
    ...mapActions('room', [NOTIFY_PM_NO_MORE_SPACE]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async onConfirm () {
      if (this.isPmOfRoomSubscription) {
        this.$router.push({ name: ROOM_SETTINGS_ROUTE_NAME, query: { show: 'upgradeDialog' } })
      } else {
        try {
          await this.NOTIFY_PM_NO_MORE_SPACE({ mnemo: this.roomMnemo })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyPmSuccess'))
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyPmError'))
        }
      }
      this.closeDialog()
    },
  },
})
</script>
