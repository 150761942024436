var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',_vm._g({attrs:{"group":_vm.group,"handle":".step-handle","move":_vm.move,"forceFallback":true},model:{value:(_vm.computedSteps),callback:function ($$v) {_vm.computedSteps=$$v},expression:"computedSteps"}},_vm.$listeners),[_c('transition-group',{class:[
      'd-flex',
      'flex-wrap', {
      'justify-center': _vm.value.length === 0,
      'flex-column': _vm.sidebar,
      'pa-4': !_vm.sidebar,
      },
    ],attrs:{"tag":"div"}},[(_vm.value.length !== 0)?_vm._l((_vm.value),function(step,index){return _c('div',{key:index + '-'  + step.id,staticClass:"my-2 mr-2 step-handle d-flex align-center"},[_c('font-awesome-icon',{staticClass:"body-1 mr-2",attrs:{"icon":['fal', 'arrows']}}),_c('div',{staticClass:"step-container pa-2"},[_vm._v(" "+_vm._s(step.position || step.display)+" "+_vm._s(step.title)+" "),(_vm.removable)?_c('AppButton',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeStep(step)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'circle-xmark']}})],1):_vm._e()],1)],1)}):(!_vm.sidebar)?_c('p',{key:"hint",staticClass:"text-center w-100"},[_vm._v(_vm._s(_vm.$t('project.signing-checklist.SigningChecklistOrderSteps.dragHint')))]):(_vm.sidebar)?_c('p',{key:"sidebar-empty-hint",staticClass:"text-center w-100"},[_vm._v(_vm._s(_vm.$t('project.signing-checklist.SigningChecklistOrderSteps.emptySidebar')))]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }