<template>
 <div v-if="isCurrentUserPm"
      class="ChecklistTableTaskMenu text-right"
 >
   <v-menu>
     <template #activator="{on: on, attrs:attrs}">
        <!-- We use a bare v-tooltip here because AppTooltip has trouble when combined with another activator -->
        <v-tooltip top transition="fade-transition">
          <template #activator="{attrs: attrsTooltip, on: onTooltip}">
            <AppButton icon
              class="text--primary body-1"
              v-bind="{...attrs, ...attrsTooltip}"
              v-on="{...on, ...onTooltip}"
            >
              <font-awesome-icon :icon="['far', 'ellipsis-vertical']"></font-awesome-icon>
            </AppButton>
          </template>
          <span>{{ $t('common.additionalActions') }}</span>
        </v-tooltip>
     </template>
     <v-list>
       <v-list-item link
                    @click="attachedFilesDialogIsOpen = true"
       >
         <v-list-item-title>{{ $t('project.checklist.task.ChecklistTableTaskMenu.menu.attachedFiles') }}</v-list-item-title>
       </v-list-item>
       <v-list-item link
                    @click="contactResponsibleDialogIsOpen = true"
       >
         <v-list-item-title>{{ $t('project.checklist.task.ChecklistTableTaskMenu.menu.contactResponsible') }}</v-list-item-title>
       </v-list-item>
       <v-list-item link
                    @click="prepareShareTasksDialog"
       >
         <v-list-item-title>{{ $t('common.share') }}</v-list-item-title>
       </v-list-item>
       <v-list-item link
                    @click="prepareDuplicateTask"
       >
         <v-list-item-title>{{ $t('project.checklist.task.ChecklistTableTaskMenu.menu.duplicate') }}</v-list-item-title>
       </v-list-item>
       <v-list-item link
                    @click="deleteTaskDialogIsOpen = true"
       >
         <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
       </v-list-item>
     </v-list>
   </v-menu>
   <ChecklistTableTaskDeleteDialog v-if="deleteTaskDialogIsOpen"
                                   :mnemo="mnemo"
                                   :task="item"
                                   @close="deleteTaskDialogIsOpen = false"
                                   @onDelete="refreshChecklist"
   />
   <ContactResponsibleDialog v-if="contactResponsibleDialogIsOpen"
                             :mnemo="mnemo"
                             :task="item"
                             @close="contactResponsibleDialogIsOpen = false"
   />
   <ChecklistTableTaskAttachedFilesDialog :task="item"
                                          :mnemo="mnemo"
                                          noButton
                                          :open="attachedFilesDialogIsOpen"
                                          @refreshTask="prepareRefreshTask"
   />
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppButton from '../../../common/buttons/AppButton'
import { DUPLICATE_TASK, GET_CHECKLIST, REFRESH_TASK } from '../../../store/modules/checklist/action_types'
import { SET_SHARE_TASKS_DIALOG } from '../../../store/modules/checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'
import ChecklistTableTaskAttachedFilesDialog from '../dialogs/ChecklistTableTaskAttachedFilesDialog'
import ChecklistTableTaskDeleteDialog from '../dialogs/ChecklistTableTaskDeleteDialog'
import ContactResponsibleDialog from '../dialogs/ContactResponsibleDialog'

export default {
  name: 'ChecklistTableTaskMenu',
  components: {
    AppButton,
    ChecklistTableTaskAttachedFilesDialog,
    ChecklistTableTaskDeleteDialog,
    ContactResponsibleDialog,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      deleteTaskDialogIsOpen: false,
      contactResponsibleDialogIsOpen: false,
      attachedFilesDialogIsOpen: false,
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm']),
  },
  methods: {
    ...mapActions('checklist', [REFRESH_TASK, DUPLICATE_TASK, GET_CHECKLIST]),
    prepareShareTasksDialog () {
      this.$store.commit(`checklist/${SET_SHARE_TASKS_DIALOG}`, {
        isOpen: true,
        tasksToShare: [this.item],
        parent: this.parent,
      })
    },
    prepareRefreshTask () {
      this.REFRESH_TASK({
        mnemo: this.mnemo,
        taskId: this.item.id,
        parent: this.parent,
      })
    },
    async prepareDuplicateTask () {
      try {
        await this.DUPLICATE_TASK({
          mnemo: this.mnemo,
          taskId: this.item.id,
        })
        this.refreshChecklist()
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.task.ChecklistTableTaskMenu.duplicateTaskError'),
        })
      }
    },
    refreshChecklist () {
      this.GET_CHECKLIST(this.mnemo)
    },
  },
}
</script>

<style scoped>
.ChecklistTableTaskMenu {
  background-color: #eee;
}
</style>
