import { filterChecklistTasks } from '@/common/utils/checklist'
import { flatten } from '@/common/utils/flatArray'

export const getters = {
  flatChecklist: state => {
    return flatten([], state.checklist)
  },
  // To get the final filtered checklist, the filters are run again on each titles through their children,
  // to have a view that shows titles where children match the current filters.
  filteredChecklist: (state, getters) => {
    return filterChecklistTasks(getters.flatChecklist, state.filters, state.searchQuery)
  },
  filteredTasks: (state, getters) => getters.filteredChecklist.filter(item => item.type === 'task'),
  filteredTitles: (state, getters) => {
    const filteredTasksIds = getters.filteredTasks.map(item => item.id)

    return getters.titles.filter(title => title.children.some(child => filteredTasksIds.includes(child.id)))
  },
  hasActiveFilter: state => {
    return flatten([], state.filters).length > 0
  },
  isChecklistFiltered: (state, getters) => {
    return getters.hasActiveFilter || state.searchQuery.length > 0
  },
  taskStatusCategoriesList: state => Object.entries(state.taskStatusCategories).map(
    ([category, statuses]) => ({ name: category, statuses }),
  ),
  tasks: (state, getters) => getters.flatChecklist.filter(item => item.type === 'task'),
  titles: (state, getters) => getters.flatChecklist.filter(item => item.type === 'title'),
  selectedTasks: state => state.selectedChecklistItems.tasks,
  selectedTitles: state => state.selectedChecklistItems.titles,
  allSelectedChecklistItems: (state, getters) => [].concat(getters.selectedTasks, getters.selectedTitles),
  fakeTitleIsPresent: (state, getters) => getters.titles.some(title => title.id === 0),
  selectableChecklistItems: (state, getters) => {
    const selectableChecklist = getters.isChecklistFiltered ? getters.filteredChecklist : getters.flatChecklist

    return getters.fakeTitleIsPresent
      ? selectableChecklist.filter(item => item.type !== 'title' || item.id !== 0)
      : selectableChecklist
  },
}
