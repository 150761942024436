<template>
<v-stepper v-model="value"
           class="AppDialogStepper"
>
  <v-card-title class="AppDialogStepper__cardTitle">
    <v-stepper-header class="AppDialogStepper__stepperHeader">
      <slot name="stepper-header"></slot>
    </v-stepper-header>
    <AppButton v-if="closeButton"
               class="AppDialogStepper__closeButton"
               :disabled="cancelDisabled"
               icon
               @click="$emit('cancel')"
    >
      <font-awesome-icon :icon="['fal', 'xmark']" size="lg" />
    </AppButton>
  </v-card-title>

  <v-divider />

  <v-card-text class="AppDialogStepper__cardText">
    <v-stepper-items class="AppDialogStepper__stepperItems">
      <slot name="stepper-items"></slot>
    </v-stepper-items>
  </v-card-text>
</v-stepper>
</template>

<script>
import AppButton from '@/common/buttons/AppButton'

export default {
  name: 'AppDialogStepperTemplate',
  components: { AppButton },
  props: {
    value: {
      required: true,
    },
    closeButton: {
      type: Boolean,
      required: true,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .AppDialogStepper {
  &__cardTitle {
    justify-content: center;
  }
  &__stepperHeader {
    margin-left: auto;
    height: auto;
    & .v-stepper__step {
      font-size: 13px;
      font-weight: 500;
      padding-top: 0;
      padding-bottom: 0;
      &__step {
        height: 20px;
        width: 20px;
        min-width: 20px;
        font-size: 12px;
        font-weight: 500;
      }
    }
    & hr {
      width: 20px;
    }
  }
  &__closeButton {
    margin-left: auto;
  }
}
</style>
