import projectsService from '@/services/projectsFolders.service'

import {
  GET_PROJECTS_FOLDER_BY_ID,
  LOAD_PROJECTS_FOLDERS_TREE,
} from './action_types'
import {
  SET_PROJECTS_FOLDERS_TREE,
  SET_GET_PROJECTS_FOLDERS_TREE_PENDING,
  SET_CURRENT_PROJECTS_FOLDER,
  UPDATE_PROJECTS_FOLDERS_TREE,
  SET_PROJECTS_FOLDER_BY_ID_PENDING,
} from './mutation_types'

export const actions = {
  async [GET_PROJECTS_FOLDER_BY_ID] ({ commit }, { folderId, silent = false }) {
    if (!silent) {
      commit(SET_PROJECTS_FOLDER_BY_ID_PENDING, true)
    }
    try {
      const response = await projectsService.getProjectsFolderById(folderId)
      if (response?.data) {
        commit(UPDATE_PROJECTS_FOLDERS_TREE, response.data)

        if (!silent) {
          commit(SET_CURRENT_PROJECTS_FOLDER, response.data)
        }
      }
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      if (!silent) {
        commit(SET_PROJECTS_FOLDER_BY_ID_PENDING, false)
      }
    }
  },
  async [LOAD_PROJECTS_FOLDERS_TREE] ({ commit }) {
    commit(SET_GET_PROJECTS_FOLDERS_TREE_PENDING, true)
    try {
      const response = await projectsService.getProjectsFolderById()
      if (response?.data?.data) {
        commit(SET_PROJECTS_FOLDERS_TREE, response.data.data)
      }
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_GET_PROJECTS_FOLDERS_TREE_PENDING, false)
    }
  },
}
