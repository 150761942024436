<template>
  <AppTooltip top v-bind="$attrs">
    <template #activator="{ on, attrs }">
      <v-icon v-bind="attrs"
              class="mx-1"
              size="14"
              v-on="on"
      >
        fas fa-circle-question
      </v-icon>
    </template>
    <span v-html="text"></span>
  </AppTooltip>
</template>

<script>
import AppTooltip from './AppTooltip.vue'
/**
 * @displayName AppHelpTooltip
 */
/**
 * Default Help tooltip.
 * Uses the AppTooltip component.
 */
export default {
  name: 'AppHelpTooltip',
  components: {
    AppTooltip,
  },
  inheritAttrs: false,
  props: {
    /**
     * Text displayed in tooltip
     */
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<docs>
```vue
<template>
  <div>
    Some text
    <AppHelpTooltip text="My tooltip"/>
  </div>
</template>
```
</docs>
