<template>
  <span>
    {{ name }}
    <span v-if="currentUserName === name" class="font-weight-bold">
      {{ this.$t("archives.ArchivesListArchiverName.archiverNameMatch") }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'ArchivesListArchiverName',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentUserName () {
      return this.$store.state.user.profile.fullName
    },
  },
}
</script>
