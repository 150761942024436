<template>
  <v-data-table v-model="selectedItems"
                :headers="headers"
                must-sort
                sort-by="date"
                :sort-desc="true"
                :items="computedSignedDocuments"
                class="SignedDocumentsTable"
                :custom-sort="customSort"
                show-select
                :footer-props="{
                          'items-per-page-options': ITEM_PER_PAGE_DEFAULT,
                        }"
                :items-per-page="tableItemsPerPage"
                :options.sync="options"
  >
    <template #item.data-table-select="{isSelected, select, item}">
      <v-simple-checkbox v-if="item.proofFileURL && item.signedDocumentURL"
                         :ripple="false"
                         :value="isSelected"
                         color="primary"
                         @input="select($event)"
      ></v-simple-checkbox>
    </template>
    <template #item.status="{ item }">
      <AppSignatureStatus :signature="item" />
    </template>
    <template #item.date="{ item }">
      <SignedDocumentsTableItemDate :item="item" />
    </template>
    <template #item.signatories="{ item }">
      <span class="SignedDocumentsTable-signatoriesLink"
            @click.stop="$emit('openSignatoriesListModal', item)"
      >
        {{ item.mySignature ? 1 + item.otherSignatures.length : item.otherSignatures.length }}
      </span>
    </template>
    <template #item.options="{ item }">
      <SignedDocumentsTableItemOptions :item="item" v-on="$listeners"/>
    </template>
  </v-data-table>
</template>

<script>
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { mapGetters } from 'vuex'

import { ITEM_PER_PAGE_DEFAULT } from '@/common/utils/dataTablePagination'
import { SIGNATURE_STATUS } from '@/common/utils/signatureStatus'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'

import SignedDocumentsTableItemDate from './SignedDocumentsTableItemDate'
import SignedDocumentsTableItemOptions from './SignedDocumentsTableItemOptions'
import AppSignatureStatus from '../../common/AppSignatureStatus'

export default {
  name: 'SignedDocumentsTable',
  components: { SignedDocumentsTableItemOptions, SignedDocumentsTableItemDate, AppSignatureStatus },
  props: {
    signedDocuments: {
      type: Array,
      required: true,
    },
    selectedSignedDocuments: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      SIGNATURE_STATUS,
      options: {},
      ITEM_PER_PAGE_DEFAULT,
      headers: [
        {
          text: this.$t('project.signed-documents.SignedDocumentsTable.headers.projectName'),
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
          value: 'name',
        },
        {
          text: this.$t('project.signed-documents.SignedDocumentsTable.headers.status'),
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
          value: 'status',
          sortable: false,
        },
        {
          text: this.$t('project.signed-documents.SignedDocumentsTable.headers.date'),
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
          value: 'date',
        },
        {
          text: this.$t('project.signed-documents.SignedDocumentsTable.headers.signatories'),
          class: ['caption', 'text--primary', 'text-uppercase', 'font-weight-semi-bold'],
          value: 'signatories',
        },
        {
          value: 'options',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['currentUserId']),
    selectedItems: {
      get () {
        return this.selectedSignedDocuments
      },
      set (value) {
        const array = []
        value.forEach(item => {
          if (item.signedDocumentURL) {
            array.push(item)
          }
        })
        this.$emit('update:selected-signed-documents', array)
      },
    },
    computedSignedDocuments () {
      const array = cloneDeep(this.signedDocuments)
      array.forEach(o => {
        o.isSelectable = !!o.signedDocumentURL
      })
      return array
    },
    tableItemsPerPage () {
      const itemsPerPageStorage = getUserSetting(this.currentUserId, 'signed-documents-items-per-page')
      return itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : 50
    },
  },
  watch: {
    options: {
      handler (newValue) {
        if (newValue.itemsPerPage !== parseInt(getUserSetting(this.currentUserId, 'signed-documents-items-per-page'))) {
          setUserSetting(this.currentUserId, 'signed-documents-items-per-page', newValue.itemsPerPage)
        }
      },
    },
  },
  methods: {
    customSort (items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (sortBy[0] === 'name') {
          if (sortDesc[0]) {
            return b.name.localeCompare(a.name)
          } else {
            return a.name.localeCompare(b.name)
          }
        }
        if (sortBy[0] === 'date') {
          if (sortDesc[0]) {
            return dayjs(a.time).isBefore(dayjs(b.time)) ? 1 : -1
          } else {
            return dayjs(a.time).isBefore(dayjs(b.time)) ? -1 : 1
          }
        }
        if (sortBy[0] === 'signatories') {
          if (sortDesc[0]) {
            return (b.mySignature ? 1 + b.otherSignatures.length : b.otherSignatures.length) - (a.mySignature ? 1 + a.otherSignatures.length : a.otherSignatures.length)
          } else {
            return (a.mySignature ? 1 + a.otherSignatures.length : a.otherSignatures.length) - (b.mySignature ? 1 + b.otherSignatures.length : b.otherSignatures.length)
          }
        }
      })
      return items
    },
  },
}
</script>

<style scoped lang="scss">
.SignedDocumentsTable-signatoriesLink {
  cursor: pointer;
  &:hover {
    color: var(--v-primary-base);
    text-decoration: underline;
  }
}
::v-deep.SignedDocumentsTable {
  .v-data-table__checkbox i {
    font-size: 18px !important;
    &.fa-check-square {
      color: var(--v-primary-base);
    }
  }
}
</style>
