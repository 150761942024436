<template>
  <div>
    <template v-if="multiSelect">
      <v-treeview :items="tree"
                  hoverable
                  :activatable="false"
                  return-object
                  :selectable="true"
                  item-key="key"
                  :open.sync="openNodes"
                  :load-children="loadChildren"
                  selected-color="primary"
                  dense
                  :selection-type="selectionType"
                  :value="selectedItems"
                  @input="selectedItems = $event"

      >
        <template v-slot:prepend="{ item, open }">
          <template v-if="item.type === 'folder'">
            <font-awesome-icon v-if="open"
                               :icon="['fad', 'folder-open']"
                               size="lg"
                               :style="folderStyles"
            >
            </font-awesome-icon>
            <font-awesome-icon v-if="!open"
                               :icon="['fad', 'folder']"
                               size="lg"
                               :style="folderStyles"
            >
            </font-awesome-icon>
          </template>
          <template v-if="item.type === 'file'">
            <ClosdFildersIcon :document="item"/>
          </template>
        </template>
        <template v-slot:append="attrs">
          <slot name="append" v-bind="attrs"></slot>
        </template>
        <template v-slot:label="attrs">
          <slot name="label" v-bind="attrs">
            {{ attrs.item.name }}
          </slot>
        </template>
      </v-treeview>
    </template>
    <template v-else>
      <v-treeview :items="tree"
                  hoverable
                  :activatable="!multiSelect"
                  :active.sync="selectedItems"
                  return-object
                  :selectable="multiSelect"
                  item-disabled="locked"
                  item-key="key"
                  :open.sync="openNodes"
                  :load-children="loadChildren"
                  selected-color="primary"
                  dense
                  :selection-type="selectionType"
      >
        <template v-slot:prepend="{ item, open }">
          <slot name="prepend" :item="item" :open="open" :folderStyles="folderStyles">
            <template v-if="item.type === 'folder'">
              <font-awesome-icon v-if="open"
                                 :icon="['fad', 'folder-open']"
                                 size="lg"
                                 :style="folderStyles"
              >
              </font-awesome-icon>
              <font-awesome-icon v-if="!open"
                                 :icon="['fad', 'folder']"
                                 size="lg"
                                 :style="folderStyles"
              >
              </font-awesome-icon>
            </template>
            <template v-if="item.type === 'file'">
              <ClosdFildersIcon :document="item"/>
            </template>
          </slot>
        </template>
        <template v-slot:append="attrs">
          <slot name="append" v-bind="attrs"></slot>
        </template>
        <template v-slot:label="attrs">
          <slot name="label" v-bind="attrs">
            <span v-if="numberingEnabled && attrs.item.numbering" class="pr-1">{{attrs.item.numbering}}</span>
            {{ attrs.item.name }}
          </slot>
        </template>
      </v-treeview>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapState } from 'vuex'

import ClosdFildersIcon from '../common/filders/ClosdFildersIcon'
import documentsService from '../services/documents.service'

/**
 * @displayName AppFildersTree
 */
/** Default Filders tree view */
export default {
  name: 'AppFildersTree',
  components: { ClosdFildersIcon },
  props: {
    /**
     * @model
     * Selected files
     */
    value: {
      type: Array,
      required: true,
    },
    /**
     * The root folder Object
     */
    rootFolder: {
      type: Object,
      required: true,
    },
    /**
     * If multiselect is active
     */
    multiSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * If only folders are displayed
     */
    foldersOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * If the first folder is open at init
     */
    openFirstNode: {
      type: Boolean,
      default: false,
    },
    /**
     * Selection type.
     * @see https://vuetifyjs.com/en/api/v-treeview/#props-selection-type
     */
    selectionType: {
      type: String,
      default: 'leaf',
    },
    isProjectsFolders: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      items: null,
      folderStyles: {
        '--fa-primary-opacity': 1,
        '--fa-secondary-opacity': 1,
        '--fa-primary-color': '#6aaefe',
        '--fa-secondary-color': '#6aaefe',
      },
      openNodes: [],
    }
  },
  computed: {
    ...mapState('room', ['currentRoom']),
    ...mapState('documents', ['numberingEnabled']),
    selectedItems: {
      get () {
        return this.value.map(this.mapKeyItem)
      },
      set (val) {
        this.$emit('input', val.filter(item => !item.unSelectable))
      },
    },
    tree () {
      const rootFolder = {
        ...cloneDeep(this.rootFolder),
        key: `${this.rootFolder.type || 'folder'}-${this.rootFolder.id}`,
        children: this.rootFolder.children
          .filter(child => !child?.specialFolder && (this.foldersOnly ? child?.type === 'folder' : true))
          .map(this.mapKeyItem),
      }
      const toDoFolder = this.rootFolder.children.find(child => child?.specialFolder === 'todo')
      return this.currentRoom?.isDataroom ? [rootFolder] : [rootFolder, toDoFolder]
    },
  },
  created () {
    if (this.openFirstNode) {
      this.openNodes.push(this.tree[0])
    }
  },
  methods: {
    mapKeyItem (item) {
      if (!item.key) {
        if (item.childrenCount === 0) {
          // TODO : Use an internal value instead of mutating the raw prop
          delete item.children
        } else if (item.type === 'folder') {
          item.children = item.children.map(this.mapKeyItem)
        } else if (item.children && item.children.length) {
          item.children = item.children.map(this.mapKeyItem)
        }
        item.key = `${item.type || 'folder'}-${item.id}`
      }
      return item
    },
    async loadChildren (item) {
      if (item.childrenCount > 0) {
        const response = await documentsService.getDocumentsById(this.currentRoom.mnemo, item.id)
        if (this.foldersOnly) {
          item.children = response.data.children.filter(i => i.type === 'folder').map(o => {
            if (o.childrenCount === 0) {
              delete o.children
            }
            o.key = `${o.type || 'folder'}-${o.id}`
            return o
          })
        } else {
          item.children = response.data.children.map(o => {
            if (o.childrenCount === 0) {
              delete o.children
            }
            o.key = `${o.type || 'folder'}-${o.id}`
            return o
          })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep.v-treeview .v-treeview-node__root {
  cursor: pointer;
  font-size: 14px !important;
}
</style>

<docs>
```vue
<template>
  <AppFildersTree :root-folder="rootFolder"
                  :value="items"
                  open-first-node
  />
</template>
<script>
  import AppFildersTree from '@/common/AppVuetifyFildersTree'
  export default {
    components: { AppFildersTree },
    data () {
      return {
        rootFolder: {
          id:0,
          basename:"Documents",
          name:"Documents",
          parentFolderId: null,
          type:"folder",
          children: [
            {
              id:23,
              basename:"Folder",
              name:"Folder",
              parentFolderId: null,
              type:"folder",
              children: [
                {
                  id:634,
                  basename:"file",
                  name:"file.pdf",
                  type:"file",
                },
                {
                  id:3423,
                  basename:"file",
                  name:"file.pdf",
                  type:"file",
                }
              ],
            },
            {
              id:291,
              basename:"file",
              name:"file.pdf",
              type:"file",
            },
            {
              id:345,
              basename:"file",
              name:"file.pdf",
              type:"file",
            },
            {
              id:463,
              basename:"file",
              name:"file.pdf",
              type:"file",
            }
          ],
        },
        items: []
      }
    },
  }
</script>
```
</docs>
