<template>
  <div class="d-flex flex-column align-center">
    <p class="caption text-center">
      <span class="font-weight-bold">
        {{ $t('common.components-filters.DataTableFilterNoData.filterNoData') }}
      </span>
      <br>
      {{ $t('common.components-filters.DataTableFilterNoData.filterNoDataDescription') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DataTableFilterNoData',
}
</script>
