<template>
<div class="ChecklistTableStatusColorPicker">
  <div v-for="(color, i) in customStatusesColors"
       :key="`color-${i}`"
       class="ChecklistTableStatusColorPicker__item clickable"
       :class="{'ChecklistTableStatusColorPicker__item--active': value === color}"
       :style="{backgroundColor: color}"
       @click="$emit('input', color)"
  ></div>
</div>
</template>

<script>
import { customStatusesColors } from './taskStatus'

export default {
  name: 'ChecklistTableStatusColorPicker',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      customStatusesColors,
    }
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableStatusColorPicker {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__item {
    width: 26px;
    height: 26px;
    margin: 8px;
    border-radius: 50%;
    &--active {
      border: 2px solid var(--v-tertiary-base);
    }
  }
}
</style>
