<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             :ok-text="$t('common.delete')"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>{{ $t('project.signing-checklist.list.SigningChecklistDeleteTitleDialog.title') }}</template>

    <template #body>{{ $t('project.signing-checklist.list.SigningChecklistDeleteTitleDialog.body') }}</template>
  </AppDialog>
</template>

<script>
import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'

export default {
  name: 'SigningChecklistDeleteTitleDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  methods: {
    confirmClick () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
