<template>
<div class="py-2 pl-4 user-row"
     :style="gridStyles"
>
  <span class="user-name">{{ user.fullName }}</span>
  <div v-if="hasSignersOrder"
       class="text-center"
  >
    {{ isSignatory ? groupIndex : null }}
  </div>
  <template v-if="hasWitnesses">
    <AppTooltip v-if="isWitness" :disabled="!isWitnessOf" top>
      <template #activator="{on}">
        <div class="d-inline-flex" v-on="on">
          <v-checkbox :input-value="isWitness"
                      dense
                      readonly
                      hide-details
                      :disabled="true"
                      class="mx-auto pt-0 mt-0 user-signer-checkbox"
          />
        </div>
      </template>
      <span>
        {{ $t('common.users.envelopeUsersSelector.EnvelopeUsersSelectorUser.witnessOf') }}
        <ul>
          <li v-for="(signer, i) in isWitnessOf"
              :key="`witnes-of-${i}`">
            {{ signer }}
          </li>
        </ul>
      </span>
    </AppTooltip>
    <div v-else></div>
  </template>
  <template v-if="isForMultiEnvelope">
    <v-checkbox
      :input-value="flattenedSigners"
      dense
      hide-details
      class="mx-auto pt-0 mt-0 user-signer-checkbox"
      :value="user.id"
      :disabled="isMultiEnvelopeSignerDisabled"
      @change="$emit('toggle', {id: user.id, list: 'signers'})"
    >
    </v-checkbox>
    <v-checkbox
      :input-value="step.uniqueSigners"
      dense
      hide-details
      class="mx-auto pt-0 mt-0 user-unique-signer-checkbox"
      :value="user.id"
      :disabled="isMultiEnvelopeUniqueSignerDisabled"
      @change="$emit('toggle', {id: user.id, list: 'uniqueSigners'})"
    >
    </v-checkbox>
  </template>
  <template v-else>
    <v-checkbox
      :input-value="flattenedSigners"
      dense
      hide-details
      class="mx-auto pt-0 mt-0 user-signer-checkbox"
      :value="user.id"
      :disabled="isSignerCheckboxDisabled"
      @change="$emit('toggle', {id: user.id, list: 'signers'})"
    >
    </v-checkbox>
  </template>
  <v-checkbox
    :input-value="step.validators"
    dense
    hide-details
    class="mx-auto pt-0 mt-0 user-validator-checkbox"
    :value="user.id"
    :disabled="isValidatorCheckboxDisabled"
    @change="$emit('toggle', {id: user.id, list: 'validators'})"
  >
  </v-checkbox>
  <v-checkbox
    :input-value="step.recipients"
    dense
    hide-details
    class="mx-auto pt-0 mt-0 user-recipient-checkbox"
    :value="user.id"
    :disabled="observersOnly"
    @change="$emit('toggle', {id: user.id, list: 'recipients'})"
  >
  </v-checkbox>
  <v-checkbox
    :input-value="step.observers"
    dense
    hide-details
    class="mx-auto pt-0 mt-0 user-observer-checkbox"
    :value="user.id"
    :disabled="isObserverCheckboxDisabled"
    @change="$emit('toggle', {id: user.id, list: 'observers'})"
  >
  </v-checkbox>
</div>
</template>

<script>

import { mapGetters } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'

export default {
  name: 'EnvelopeUsersSelectorUser',
  components: { AppTooltip },
  props: {
    user: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
    recipientsAndObserversOnly: {
      type: Boolean,
      required: true,
    },
    enableSigners: {
      type: Boolean,
      required: true,
    },
    observersOnly: {
      type: Boolean,
      required: true,
    },
    enableValidators: {
      type: Boolean,
      required: true,
    },
    isPmGroup: {
      type: Boolean,
      required: true,
    },
    hasSignersOrder: {
      type: Boolean,
      required: true,
    },
    gridStyles: {
      type: Object,
      default: null,
      validator: (value) => { return typeof value === 'object' || value === null },
    },
    isForMultiEnvelope: {
      type: Boolean,
      required: true,
    },
    hasWitnesses: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      signerWithWitnesses: [],
    }
  },
  computed: {
    ...mapGetters('groups', ['allRoomMembers']),
    flattenedSigners () {
      return this.step?.signers?.flat()
    },
    isSignatory () {
      return this.flattenedSigners?.includes(this.user.id)
    },
    isWitness () {
      return this.step?.witnesses?.flatMap(w => w.witness).includes(this.user.id)
    },
    isWitnessOf () {
      if (this.isWitness) {
        const signerIds = this.step?.witnesses.filter(ws => ws.witness.includes(this.user.id)).map(s => s.signer)
        const signers = this.allRoomMembers.filter(s => signerIds.includes(s.id))
        return signers.map(s => s.fullName)
      }
      return null
    },
    groupIndex () {
      if (this.isSignatory) {
        const index = this.step.signers.findIndex(g => {
          return g.includes(this.user.id)
        })
        return index + 1
      }
      return null
    },
    isSignerCheckboxDisabled () {
      return !this.enableSigners || this.recipientsAndObserversOnly || this.observersOnly || this.isWitness
    },
    isValidatorCheckboxDisabled () {
      return !this.enableValidators && (this.recipientsAndObserversOnly || this.observersOnly)
    },
    isObserverCheckboxDisabled () {
      return this.isSelected(this.user.id, 'signers') ||
        this.isSelected(this.user.id, 'uniqueSigners') ||
        this.isSelected(this.user.id, 'validators') ||
        this.isWitness ||
        this.isPmGroup
    },
    isMultiEnvelopeSignerDisabled () {
      return this.isSelected(this.user.id, 'uniqueSigners') || (!this.enableSigners || this.recipientsAndObserversOnly || this.observersOnly || this.isWitness)
    },
    isMultiEnvelopeUniqueSignerDisabled () {
      return this.isSelected(this.user.id, 'signers') || (!this.enableSigners || this.recipientsAndObserversOnly || this.observersOnly || this.isWitness)
    },
  },
  methods: {
    isSelected (id, list) {
      if (list === 'signers') {
        return this.step?.signers?.some(group => {
          return group.some(userId => userId === id)
        })
      }
      return this.step[list]?.includes(id)
    },
  },
}
</script>

<style scoped lang="scss">
.user-row {
  display: grid;
  grid-gap: 10px;
}
</style>
