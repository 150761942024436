<template>
  <div>
    <AppText variant="small-regular">{{ $t('project.signing-checklist.add.SigningChecklistSectionDocumentSharingOptions.sectionIntroduction')}}</AppText>
    <a class="app-link learnMoreLink"
      :href="learnMoreLinkUrl"
      target="_blank"
    >
      {{ $t("common.learnMore") }}
      <font-awesome-icon :icon="['far', 'external-link']" class="ml-1" size="xs"/>
    </a>

    <v-radio-group
      :value="shareType"
      :disabled="isEditingLimited"
      hide-details
      active-class="shareType__card--active"
      class="shareType__container"
      required
      row
      @change="onShareTypeChange"
    >
      <div v-for="option in shareTypeOptions"
            :key="`option-${option}`"
            class="d-flex flex-column"
      >
        <v-radio :value="option"
                 class="shareType__card"
                 style="pointer-events: all"
        >
          <template v-slot:label>
            <div class="w-100 d-flex align-center justify-space-between">
              <h4>{{ $t(`project.signing-checklist.add.SigningChecklistSectionDocumentSharingOptions.shareTypeOptions.${option}`) }}</h4>
            </div>
          </template>
        </v-radio>
      </div>
    </v-radio-group>

    <AppSubscriptionMembersPicker v-if="shareType === SHARE_TYPE_OPTIONS.CUSTOM"
                                  :selected-members="fileWithUsers || []"
                                  @change="onWithUsersChange"
    />

    <v-checkbox v-if="canRequireNotificationSend"
                v-model="shareSendNotif"
                :label="$t('project.signing-checklist.add.SigningChecklistSectionDocumentSharingOptions.sendNotifLabel')"
                hide-details
                @change="onSendNotifChange"
    ></v-checkbox>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import AppSubscriptionMembersPicker from '@/common/AppSubscriptionMembersPicker.vue'
import { SHARE_TYPE_OPTIONS, type ShareType } from '@/common/utils/shareTypes'
import AppText from '@/design-system/typography/AppText.vue'

export default defineComponent({
  name: 'SigningChecklistSectionDocumentSharingOptions',
  components: {
    AppText,
    AppSubscriptionMembersPicker,
  },
  props: {
    shareType: {
      type: String as PropType<ShareType>,
    },
    fileRights: {
      type: Object,
      required: true,
    },
    fileWithUsers: {
      type: Array,
      default: () => [],
    },
    isEditingLimited: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      SHARE_TYPE_OPTIONS,
      shareSendNotif: false,
    }
  },
  computed: {
    canRequireNotificationSend (): boolean {
      return this.shareType === SHARE_TYPE_OPTIONS.PUBLIC || this.shareType === SHARE_TYPE_OPTIONS.CUSTOM
    },
    shareTypeOptions (): Array<ShareType> {
      return Object.values(SHARE_TYPE_OPTIONS)
    },
    learnMoreLinkUrl (): string {
      if (this.$i18n.locale === 'fr') {
        return 'https://help.closd.com/fr/'
      }

      return 'https://help.closd.com/en/'
    },
  },
  methods: {
    onWithUsersChange (withUsers) {
      this.$emit('with-users-change', withUsers)
    },
    onSendNotifChange (sendNotif) {
      this.$emit('send-notif-change', sendNotif)
    },
    onShareTypeChange (newShareType: string) {
      this.$emit('share-type-change', newShareType)
    },
  },
})
</script>

<style scoped lang="scss">
.shareType__container {
  margin-top: 24px;
  margin-bottom: 0;

  ::v-deep .v-input--radio-group__input {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .shareType__card {
    justify-self: stretch;
    padding: 16px 24px;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    margin: 0;

    &--active {
      outline: 2px solid var(--v-primary-base);
      outline-offset: -2px;
      background-color: var(--v-primary-lighten4);
    }

    .caption {
      color: #717B85;
      font-size: 12px;
    }
  }
}
</style>
