<template>
  <form ref="fileDownloadForm"
        :action="computedLink"
        class="d-inline-block"
        method="post"
        :target="isNewTab ? '_blank' : ''"
  >
    <input type="hidden"
           :value="bearerToken"
           name="api_token"
    >
    <!-- @slot Use this slot to display download trigger
          @binding {function} submitForm - Function to call for triggering download
     -->
    <slot v-bind:submitForm="submitForm"></slot>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AppDownloadFile',
  props: {
    /**
     * Link to download target
     */
    link: {
      type: String,
      required: true,
    },
    /**
     * If the link should open in a new tab
     */
    isNewTab: {
      type: Boolean,
      default: true,
    },
    /**
     * If the download must force the reader of the browser
     */
    forceReader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bearerToken () {
      return axios.defaults.headers.common.Authorization
    },
    computedLink () {
      const queryToAppend = this.forceReader
        ? 'forceReader=true'
        : 'forceDownload=true'
      // If this already has a query string.
      if (this.link.includes('?')) {
        // Don't be dumb and use an &
        // See V2-979... 🤦‍♂️
        return this.link + '&' + queryToAppend
      } else {
        return this.link + '?' + queryToAppend
      }
    },
  },
  methods: {
    submitForm () {
      this.$refs.fileDownloadForm.submit()
    },
  },
}
</script>

<docs>
```vue
<template>
  <AppDownloadFile link="">
    <template #default="{submitForm}">
      <AppButton @click="submitForm">Download</AppButton>
    </template>
  </AppDownloadFile>
</template>
<script>
  import AppButton from '@/common/buttons/AppButton'
  export default {
    components: { AppButton },
    data () {
      return {
        date: []
      }
    },
  }
</script>
```
</docs>
