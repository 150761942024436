<template>
  <AppCard class="projectGhostClass">
    <div class="d-flex align-center">
      <app-icon v-if="isFolder"
                size="regular"
                icon-weight="fas"
                icon-name="folder"
                class="folder-icon"
      />
      <RoomTypeIcon v-else :room="item"/>
      <span class="ml-2 font-weight-semi-bold text-truncated"
            style="max-width: 80px;"
      >
        {{ item.name }}
      </span>
    </div>
  </AppCard>
</template>

<script>
  import AppCard from '@/common/AppCard.vue'
  import { FOLDER_ICON_COLOR } from '@/common/constants'
  import RoomTypeIcon from '@/common/RoomTypeIcon.vue'
  import { DARK_BLUE_500 } from '@/common/utils/colors'

  export default {
    name: 'ProjectsGridGhost',
    components: { AppCard, RoomTypeIcon },
    props: {
      // Room or Folder
      item: {
        type: Object,
        required: true,
      },
      isFolder: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        FOLDER_ICON_COLOR,
        DARK_BLUE_500,
      }
    },
  }
</script>

<style scoped lang="scss">
  .projectGhostClass {
    display: none;
    border: 2px solid v-bind(DARK_BLUE_500);
    // We put it off-screen so that it doesn't force any overflow and it's not visible
    position: fixed;
    top: 0;
    left: 0;
    // 200% is done specifically so that any big containers aren't ever visible no matter their sizes
    transform: translate(-200%, -200%);

    .folder-icon {
      color: v-bind(FOLDER_ICON_COLOR)
    }
  }
</style>
