<template>
  <v-stepper-content step="3">
    <div v-if="currentStep === 3" class="identity-checking-id pb-8">
      <h3 class="text-h3">
        {{ $t('common.identity-checking.IdentityCheckingID.importDocument.title') }}
      </h3>
      <div class="mt-3">
        {{ $t('common.identity-checking.IdentityCheckingID.importDocument.subTitle') }}
        <!-- LearnMore -->
        <div class="mt-2">
          <a :href="learnMoreLinkUrl" target="_blank" class="learnMoreLink">
            {{ $t('common.identity-checking.IdentityCheckingID.importDocument.learnMore') }}
            <font-awesome-icon :icon="['far', 'external-link']" class="ml-1" size="xs"/>
          </a>
        </div>
      </div>

      <div class="mt-8">
        <!-- No tries left -->
        <app-alert-in-page v-if="displayNoTriesLeftTemplate" :dismissible="false" variant="error">
          <template #title>{{ $t('common.identity-checking.NoTriesLeftTemplate.text1') }}</template>
          <div class="mt-2">{{ $t('common.identity-checking.NoTriesLeftTemplate.text2') }}</div>
        </app-alert-in-page>

        <!-- Document Errors -->
        <template v-else>
          <app-alert-in-page v-for="(error, index) in fileControlErrors"
                            :key="`file-control-error-${index}`"
                            class="mt-2"
                            :dismissible="false"
                            variant="error"
          >
            <div v-html="error"/>
          </app-alert-in-page>
          <app-alert-in-page v-for="(error, index) in documentErrors"
                            :key="`document-error-${index}`"
                            class="mt-2"
                            :dismissible="false"
                            variant="error"
          >
            <div v-html="$t('common.idCheckErrors.' + error)"/>
          </app-alert-in-page>
        </template>

        <!-- Tries left -->
        <div v-if="triesLeft !== 0" class="mt-2">
          <app-alert-in-page v-if="this.idCheckError?.errors.includes(ID_CHECK_DOCUMENT_ERRORS.ERROR_UNHANDLED)" :dismissible="false" variant="error">
            <template #title>{{ $t('common.idCheckErrors.ERR_GENERIC') }}</template>
            <div class="mt-2">{{ this.$tc('common.identity-checking.IdentityCheckingDialog.triesLeft', triesLeft, { triesLeft: triesLeft }) }}</div>
          </app-alert-in-page>
          <app-alert-in-page v-else :dismissible="false" variant="error">
            <template #title>{{ $t('common.identity-checking.IdentityCheckingDialog.documentError') }}</template>
            <div class="mt-2">{{ this.$tc('common.identity-checking.IdentityCheckingDialog.triesLeft', triesLeft, { triesLeft: triesLeft }) }}</div>
          </app-alert-in-page>
        </div>
      </div>

      <!-- ID Front -->
      <div class="mt-8">
        <div class="font-weight-medium mb-2">
          <template v-if="selectedIdDocumentType === ID_TYPE_OPTIONS.PASSPORT">
            {{ $t('common.identity-checking.IdentityCheckingID.importDocument.identityCard') }}
          </template>
          <template v-else>
            {{ $t('common.identity-checking.IdentityCheckingID.idFront') }}
          </template>
        </div>
        <IdentityCheckingIDFileInput refValue="idFrontInput"
                                     describedby="frontDocumentUploadButton"
                                     :existingFile="id.documentFront.$model"
                                     :disableFileInput="displayNoTriesLeftTemplate"
                                     :allowedExtensions="idCheckFileAllowedExtensions"
                                     :isDocumentErrored="!!isDocumentFrontErrored"
                                     :errors="documentFrontErrors"
                                     @update-file="id.documentFront.$model = $event"
        >
        </IdentityCheckingIDFileInput>
      </div>

      <!-- ID Back -->
      <div v-if="selectedIdDocumentType !== ID_TYPE_OPTIONS.PASSPORT" class="mt-6">
        <div class="font-weight-medium mb-2">
          {{ $t('common.identity-checking.IdentityCheckingID.idBack') }}
        </div>
        <IdentityCheckingIDFileInput refValue="idBackInput"
                                     describedby="backDocumentUploadButton"
                                     :existingFile="id.documentBack.$model"
                                     :disableFileInput="displayNoTriesLeftTemplate"
                                     :allowedExtensions="idCheckFileAllowedExtensions"
                                     :isDocumentErrored="!!isDocumentBackErrored"
                                     :errors="documentBackErrors"
                                     @update-file="id.documentBack.$model = $event"
        >
        </IdentityCheckingIDFileInput>
      </div>
    </div>
  </v-stepper-content>
</template>

<script>
import { intersection } from 'lodash-es'
import { mapState, mapMutations } from 'vuex'

import { idCheckFileAllowedExtensions } from '@/common/utils/files'
import { ID_CHECK_DOCUMENT_ERRORS, ID_CHECK_ERRORS } from '@/common/utils/idCheckErrors'
import { ID_TYPE_OPTIONS } from '@/common/utils/IDTypes.ts'
import { SET_ID_CHECK_ERROR } from '@/store/modules/user/mutation_types'

import IdentityCheckingIDFileInput from './IdentityCheckingIDFileInput.vue'

export default {
  name: 'IdentityCheckingID',
  components: { IdentityCheckingIDFileInput },
  props: {
    id: {
      type: Object,
      required: true,
    },
    triesLeft: {
      type: Number,
      required: true,
    },
    displayNoTriesLeftTemplate: {
      type: Boolean,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      idCheckFileAllowedExtensions,
      ID_TYPE_OPTIONS,
      ID_CHECK_DOCUMENT_ERRORS,
    }
  },
  computed: {
    ...mapState('user', ['idCheckError', 'selectedIdDocumentType']),
    learnMoreLinkUrl () {
      const baseUrl = 'https://help.closd.com/'
      if (this.$i18n.locale === 'fr') {
        return `${baseUrl}fr/knowledge-base/a-quoi-sert-la-verification-didentite/`
      }
      return `${baseUrl}en/knowledge-base/what-is-identity-verification-used-for/`
    },
    documentFrontErrors () {
      const errors = []
      if (!this.id.documentFront.$dirty) {
        return errors
      }

      if (!this.id.documentFront.required) {
        errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.identity-checking.IdentityCheckingID.idFront') }))
      }
      if (this.idCheckError?.errors.includes(ID_CHECK_ERRORS.ERR_FRONT_DOCUMENT_WRONG_FORMAT)) {
        errors.push(this.$t('common.idCheckErrors.ERR_WRONG_FORMAT'))
      }
      if (this.idCheckError?.errors.includes(ID_CHECK_ERRORS.ERR_FRONT_DOCUMENT_IMG_TOO_BIG)) {
        errors.push(this.$t('common.idCheckErrors.ERR_IMG_TOO_BIG'))
      }
      if (this.idCheckError?.errors.includes(ID_CHECK_ERRORS.ERR_FRONT_DOCUMENT_PDF_TOO_BIG)) {
        errors.push(this.$t('common.idCheckErrors.ERR_PDF_TOO_BIG'))
      }
      return errors
    },
    documentBackErrors () {
      const errors = []
      if (!this.id.documentBack.$dirty) {
        return errors
      }

      if (this.idCheckError?.errors.includes(ID_CHECK_ERRORS.ERR_BACK_DOCUMENT_WRONG_FORMAT)) {
        errors.push(this.$t('common.idCheckErrors.ERR_WRONG_FORMAT'))
      }
      if (this.idCheckError?.errors.includes(ID_CHECK_ERRORS.ERR_BACK_DOCUMENT_IMG_TOO_BIG)) {
        errors.push(this.$t('common.idCheckErrors.ERR_IMG_TOO_BIG'))
      }
      if (this.idCheckError?.errors.includes(ID_CHECK_ERRORS.ERR_BACK_DOCUMENT_PDF_TOO_BIG)) {
        errors.push(this.$t('common.idCheckErrors.ERR_PDF_TOO_BIG'))
      }
      return errors
    },
    fileControlErrors () {
      return this.documentFrontErrors.concat(this.documentBackErrors)
    },
    documentErrors () {
      return intersection(this.idCheckError?.errors, ID_CHECK_DOCUMENT_ERRORS)
    },
    isDocumentFrontErrored () {
      if (this.displayNoTriesLeftTemplate) return false
      return this.documentFrontErrors.length || this.documentErrors.length
    },
    isDocumentBackErrored () {
      if (this.displayNoTriesLeftTemplate) return false
      return this.documentBackErrors.length || this.documentErrors.length
    },
  },
  watch: {
    'id.documentFront.$model' () {
      this.SET_ID_CHECK_ERROR(null)
    },
    'id.documentBack.$model' () {
      this.SET_ID_CHECK_ERROR(null)
    },
  },
  methods: {
    ...mapMutations('user', [SET_ID_CHECK_ERROR]),
  },
}
</script>
