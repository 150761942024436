<template>
  <div>
    <app-text variant="small-regular" class="mb-4">
      {{ $t(`${TRANSLATION_KEY_PREFIX}.${envelopeType === ENVELOPE_TYPES.SINGLE ? 'subtitleSingleEnvelope' : 'subtitleMultipleEnvelopes'}`) }}
    </app-text>

    <div class="generic-signers-list">
      <SigningChecklistSignersAssignationListItem v-for="(genericSignature, index) in genericSignaturesList"
                                                  :key="genericSignature.id"
                                                  class="generic-signers-list-item"
                                                  :index="index + 1"
                                                  :envelopeType="envelopeType"
                                                  :generic-signature="genericSignature"
                                                  :validator="templateSignaturesValidator.$each.$iter[index]"
                                                  :signers="signers"
                                                  @assign-signer="onSignerAssign"
      />
    </div>

    <div v-if="additionalSigners.length > 0" class="additional-signers-list">
      <app-text variant="small-regular" class="mb-4">
        {{ $t(`${TRANSLATION_KEY_PREFIX}.${envelopeType === ENVELOPE_TYPES.SINGLE ? 'additionalSignersSubtitleSingleEnvelope' : 'additionalSignersSubtitleMultipleEnvelopes'}`) }}
      </app-text>

      <div>
        <app-text v-for="signer in additionalSigners"
                  :key="signer.id"
                  variant="small-regular"
                  class="additional-signers-list-item"
        >
          {{ signer.name }}
        </app-text>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { ENVELOPE_TYPES, SIGNATURE_TYPES } from '@/project/signing-checklist/constants'
import { SignersColors } from '@/project/signing-checklist/prepare-signature/signers-colors'

import SigningChecklistSignersAssignationListItem, { type GenericSignatureListItem } from './SigningChecklistSignersAssignationListItem.vue'

export type GenericSignature = {
  id: number
  signerName : string
  assignedSigner: EnvelopeSigner | null
  signerType: SIGNATURE_TYPES
}

const TRANSLATION_KEY_PREFIX = 'project.signing-checklist.add.step2.signers-assignation.SigningChecklistSignersAssignationList'

export default defineComponent({
  name: 'SigningChecklistSignersAssignationList',
  components: { SigningChecklistSignersAssignationListItem },
  props: {
    envelopeType: {
      type: String as PropType<ENVELOPE_TYPES>,
      required: true,
    },
    signers: {
      type: Array as PropType<Array<EnvelopeSigner>>,
      default: () => [],
    },
    templateSignaturesValidator: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      ENVELOPE_TYPES,
      TRANSLATION_KEY_PREFIX,
    }
  },
  computed: {
    templateSignatures (): Array<GenericSignature> {
      return this.templateSignaturesValidator.$model
    },
    genericSignaturesList (): Array<GenericSignatureListItem> {
      return this.templateSignatures
        .filter(templateSignature => templateSignature.signerType === SIGNATURE_TYPES.SINGLE)
        .map((templateSignature, index) => {
          const signerColor = SignersColors[index % (Object.entries(SignersColors).length / 2)]
          return {
            ...templateSignature,
            signerColor: signerColor,
          }
        })
    },
    additionalSigners (): Array<EnvelopeSigner> {
      return this.signers.filter(
        signer => this.genericSignaturesList.every(genericSignature => genericSignature.assignedSigner?.id !== signer.id),
      )
    },
  },
  methods: {
    onSignerAssign (signatureId: GenericSignature['id'], signer: EnvelopeSigner) {
      this.$emit('assign-signer', signatureId, signer)
    },
  },
})
</script>

<style lang="scss" scoped>
.generic-signers-list,
.additional-signers-list {
  border: 1px solid var(--v-grey-lighten3);
  border-radius: 8px;
}

.generic-signers-list {
  &:empty {
    display: none;
  }

  &-item {
    padding: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--v-grey-lighten3);
    }
  }
}

.additional-signers-list {
  padding: 16px;
  margin-top: 24px;

  &-item {
    display: inline-flex;
    align-items: center;
    height: 32px;
    padding: 0 8px;
    border-radius: 8px;
    color: var(--v-grey-darken2);
    background-color: var(--v-grey-lighten5);

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>
