<template>
  <v-alert class="AppAlert pa-4"
           :color="backgroundColor"
           :dismissible="dismissible"
           :style="{ borderLeftColor: color }"
           close-icon="far fa-xmark"
           @input="$emit('input')"
  >
    <div class="d-flex align-center column-gap-4">
      <app-icon v-if="!noIcon" :icon-name="computedIcon"
                :style="{color: color}"
                icon-weight="fas"
      />
      <div class="d-flex align-center flex-grow-1">
        <div class="flex-grow-1">
          <div class="AppAlert-title font-weight-semi-bold">
            <!-- @slot Use this slot for title -->
            <slot name="title"></slot>
          </div>
          <div class="AppAlert-text">
            <!-- @slot Use this slot for alert text -->
            <slot></slot>
          </div>
        </div>
        <div class="AppAlert-button">
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </v-alert>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { AppAlertBackgroundColor, AppAlertColor, AppAlertIcon } from '@/design-system/alert/enums'
import { AppAlertVariant } from '@/design-system/alert/types'

/**
 * Alert in app component.
 * @displayName In Page
 */
export default Vue.extend({
  name: 'AppAlertInPage',
  props: {
    /**
     * Variant of the Alert.
     * @values info, success, error or warning
     */
    variant: {
      type: String as PropType<AppAlertVariant>,
      default: 'info',
    },
    /**
     * Adds a close icon that can hide the alert
     */
     dismissible: {
      type: Boolean,
      default: true,
    },
    /**
     * Remove icon before the alert text
     */
     noIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedIcon () {
      return AppAlertIcon[this.variant].valueOf()
    },
    backgroundColor () {
      return AppAlertBackgroundColor[this.variant].valueOf()
    },
    color () {
      return AppAlertColor[this.variant].valueOf()
    },
  },
})
</script>

<style scoped lang="scss">
.AppAlert {
  border-left-width: 8px;
  border-left-style: solid;
  border-collapse: collapse;
  margin-bottom: 0;

  ::v-deep .v-alert__dismissible i.v-icon {
    font-size: 14px;
    width: 14px;
    height: 14px;
  }

  &-button {
    margin-left: auto;
  }
}
</style>

<docs>
```vue
<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="text-h1">In page</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h2">Usage</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Provide users nondisruptive information about an action, before or after doing it.</p>
        <p>
          Use In Page to let a user know when:
          <ul>
            <li>Taking a particular action may have potentially destructive consequences.</li>
            <li>They need to take action to keep moving through an experience.</li>
            <li>Generic information about the product.</li>
          </ul>
        </p>
        <p>
          Interaction :
          Persist until the message is resolved or dismissed by user and may include an alert button
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h3 mb-2">Info without title</div>
        <app-alert-in-page variant="info">
          <template>I’m an Alert Usage Example!</template>
        </app-alert-in-page>
        <div class="text-h3 my-2">Info with title</div>
        <app-alert-in-page variant="info">
          <template #title>I’m an Alert Usage Example!</template>
          <template>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</template>
        </app-alert-in-page>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h3 mb-2">Success without title</div>
        <app-alert-in-page variant="success">
          <template>I’m an Alert Usage Example!</template>
        </app-alert-in-page>
        <div class="text-h3 my-2">Success with title</div>
        <app-alert-in-page variant="success">
          <template #title>I’m an Alert Usage Example!</template>
          <template>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</template>
        </app-alert-in-page>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h3 mb-2">Error without title</div>
        <app-alert-in-page variant="error">
          <template>I’m an Alert Usage Example!</template>
        </app-alert-in-page>
        <div class="text-h3 my-2">Error with title</div>
        <app-alert-in-page variant="error">
          <template #title>I’m an Alert Usage Example!</template>
          <template>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</template>
        </app-alert-in-page>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h3 mb-2">Warning without title</div>
        <app-alert-in-page variant="warning">
          <template>I’m an Alert Usage Example!</template>
        </app-alert-in-page>
        <div class="text-h3 my-2">Warning with title</div>
        <app-alert-in-page variant="warning">
          <template #title>I’m an Alert Usage Example!</template>
          <template>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</template>
        </app-alert-in-page>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
  }
</script>
```
</docs>
