export const ADD_VERSION = 'ADD_VERSION'
export const CHANGE_TABLE_DOCUMENTS_PANE_TAB = 'CHANGE_TABLE_DOCUMENTS_PANE_TAB'
export const CLOSE_DOCUMENTS_PANE = 'CLOSE_DOCUMENTS_PANE'
export const CLOSE_TABLE_DOCUMENTS_PANE = 'CLOSE_TABLE_DOCUMENTS_PANE'
export const DELETE_SEARCH_RESULTS = 'DELETE_SEARCH_RESULTS'
export const DELETE_VERSION = 'DELETE_VERSION'
export const DESELECT_ALL_DOCUMENTS = 'DESELECT_ALL_DOCUMENTS'
export const DUPLICATE_FILDERS = 'DUPLICATE_FILDERS'
export const EDIT_DOCUMENT_NUMBERING = 'EDIT_DOCUMENT_NUMBERING'
export const GET_DOCUMENTS_BY_ID = 'GET_DOCUMENTS_BY_ID'
export const GET_DOCUMENT_VERSIONS = 'GET_DOCUMENT_VERSIONS'
export const GET_FOLDER_PATH = 'GET_FOLDER_PATH'
export const GET_NUMBERING = 'GET_NUMBERING'
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS'
export const GO_TO_FOLDER = 'GO_TO_FOLDER'
export const IMANAGE_HIDDEN_FILE_IMPORT = 'IMANAGE_HIDDEN_FILE_IMPORT'
export const LOAD_FOLDERS_TREE = 'LOAD_FOLDERS_TREE'
export const MOVE_FILDERS = 'MOVE_FILDERS'
export const OPEN_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PANE = 'OPEN_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PANE'
export const PATCH_NUMBERING = 'PATCH_NUMBERING'
export const POST_FILDERS_AS_VERSION_TO_IMANAGE = 'POST_FILDERS_AS_VERSION_TO_IMANAGE'
export const POST_FILDERS_FROM_IMANAGE = 'POST_FILDERS_FROM_IMANAGE'
export const POST_FILDERS_TO_IMANAGE = 'POST_FILDERS_TO_IMANAGE'
export const POST_MASS_FOLDERS = 'POST_MASS_FOLDERS'
export const POST_REQUEST_AUTHORIZATION_BIBLE = 'POST_REQUEST_AUTHORIZATION_BIBLE'
export const POST_VERSION_FROM_IMANAGE = 'POST_VERSION_FROM_IMANAGE'
export const RESET_DOCUMENTS = 'RESET_DOCUMENTS'
export const RESET_FULL_TREE = 'RESET_FULL_TREE'
export const SELECT_ALL_DOCUMENTS = 'SELECT_ALL_DOCUMENTS'
export const SELECT_DOCUMENTS = 'SELECT_DOCUMENTS'
export const SET_TABLE_DOCUMENTS_PANE = 'SET_TABLE_DOCUMENTS_PANE'
export const SHARE_DOCUMENTS_INDEX_BY_EMAIL = 'SHARE_DOCUMENTS_INDEX_BY_EMAIL'
export const SHARE_FILDERS = 'SHARE_FILDERS'
export const UNSELECT_DOCUMENT = 'UNSELECT_DOCUMENT'
export const UPDATE_ACCESS_TIME = 'UPDATE_ACCESS_TIME'
export const UPLOAD_HIDDEN_FILE = 'UPLOAD_HIDDEN_FILE'
export const CHANGE_DOCUMENTS_VERSIONING_PANE_TAB = 'CHANGE_DOCUMENTS_VERSIONING_PANE_TAB'
export const CLOSE_DOCUMENTS_VERSIONING_PANE = 'CLOSE_DOCUMENTS_VERSIONING_PANE'
export const SET_DOCUMENTS_VERSIONING_PANE = 'SET_DOCUMENTS_VERSIONING_PANE'
export const CHECK_WOPI_LOCKED_FILES = 'CHECK_WOPI_LOCKED_FILES'
