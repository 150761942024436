<template>
  <div>
    <div class="ml-1">
      <div class="body-1 font-weight-bold pb-5">
        {{ $t('project.project-settings.ProjectSettingsNotifications.title') }}
      </div>

      <ProjectSettingsNotificationsTopBar class="mb-4" :mnemo="mnemo"/>
    </div>

    <AppCard class="ma-1">
      <ProjectSettingsNotificationsTable :mnemo="mnemo" />
    </AppCard>
  </div>
</template>

<script>
  import AppCard from '@/common/AppCard.vue'

  import ProjectSettingsNotificationsTable from './ProjectSettingsNotificationsTable.vue'
  import ProjectSettingsNotificationsTopBar from './ProjectSettingsNotificationsTopBar.vue'

  export default {
    name: 'ProjectSettingsNotifications',
    components: {
      AppCard,
      ProjectSettingsNotificationsTable,
      ProjectSettingsNotificationsTopBar,
    },
    props: {
      mnemo: {
        type: String,
        required: true,
      },
    },
  }
</script>
