export const SET_LIST_SEARCH_PENDING = 'SET_LIST_SEARCH_PENDING'
export const SET_LIST_SEARCH_DATA = 'SET_LIST_SEARCH_DATA'
export const SET_LIST_SEARCH_DATA_COUNT = 'SET_LIST_SEARCH_DATA_COUNT'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_CONFIG_PENDING = 'SET_CONFIG_PENDING'
export const SET_GET_ARCHIVE_RIGHTS_PENDING = 'SET_GET_ARCHIVE_RIGHTS_PENDING'
export const SET_CURRENT_ARCHIVE_RIGHTS = 'SET_CURRENT_ARCHIVE_RIGHTS'
export const SET_SEND_ARCHIVES_PENDING = 'SET_SEND_ARCHIVES_PENDING'
export const SET_SHARE_ARCHIVES_PENDING = 'SET_SHARE_ARCHIVES_PENDING'
export const SET_MUTE_ARCHIVING_WARNING = 'SET_MUTE_ARCHIVING_WARNING'
export const ADD_UPLOAD_ARCHIVE_PENDING = 'ADD_UPLOAD_ARCHIVE_PENDING'
export const REMOVE_UPLOAD_ARCHIVE_PENDING = 'REMOVE_UPLOAD_ARCHIVE_PENDING'
export const ADD_UPLOAD_ARCHIVE_ERROR = 'ADD_UPLOAD_ARCHIVE_ERROR'
export const REMOVE_UPLOAD_ARCHIVE_ERROR = 'REMOVE_UPLOAD_ARCHIVE_ERROR'
export const ADD_POST_ARCHIVE_PENDING = 'ADD_POST_ARCHIVE_PENDING'
export const REMOVE_POST_ARCHIVE_PENDING = 'REMOVE_POST_ARCHIVE_PENDING'
export const ADD_POST_ARCHIVE_ERROR = 'ADD_POST_ARCHIVE_ERROR'
export const REMOVE_POST_ARCHIVE_ERROR = 'REMOVE_POST_ARCHIVE_ERROR'
export const SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN = 'SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN'
export const SET_ALL_DOCUMENTS_AS_SELECTED = 'SET_ALL_DOCUMENTS_AS_SELECTED'
export const SET_SELECTED_DOCUMENTS = 'SET_SELECTED_DOCUMENTS'
export const SET_ARCHIVES_DOWNLOAD_PENDING = 'SET_ARCHIVES_DOWNLOAD_PENDING'
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT'
