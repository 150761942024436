import { render, staticRenderFns } from "./DocumentsAISummaryLoadingState.vue?vue&type=template&id=e828a80c&scoped=true"
import script from "./DocumentsAISummaryLoadingState.vue?vue&type=script&lang=ts"
export * from "./DocumentsAISummaryLoadingState.vue?vue&type=script&lang=ts"
import style0 from "./DocumentsAISummaryLoadingState.vue?vue&type=style&index=0&id=e828a80c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e828a80c",
  null
  
)

export default component.exports