<template>
  <v-card>
    <div v-if="tabsTemplateHasTitle" class="tabs-template-header">
      <v-card-title class="pt-2">
        <slot name="tabs-header"></slot>

        <AppButton v-if="closeButton"
                  class="ml-auto"
                  :disabled="cancelDisabled"
                  icon
                  @click="$emit('cancel')"
        >
          <font-awesome-icon :icon="['fal', 'xmark']" size="lg" />
        </AppButton>
      </v-card-title>

      <v-divider/>
    </div>

    <div>
      <slot name="tabs"></slot>
    </div>
  </v-card>
</template>

<script>
import AppButton from '@/common/buttons/AppButton'

export default {
  name: 'AppDialogTabsTemplate',
  components: {
    AppButton,
  },
  props: {
    closeButton: {
      type: Boolean,
      required: true,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable title above v-tabs header
     */
     tabsTemplateHasTitle: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.tabs-template-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.primary--text {
  color: var(--v-primary-base) !important;
}
</style>
