<template>
  <v-container container class="ActivateAccount-container mx-auto">
    <template v-if="!loadingPage">
      <LoginTopbar/>
      <div :class="$vuetify.breakpoint.smAndDown ? '' : 'ActivateAccount-center-box'">
        <v-row class="ActivateAccount-content">
            <v-col class="ActivateAccount-colonne-explain">
                <ClosdTagLine/>
            </v-col>
            <v-col>
                <AppCard :elevation="0"
                        :class="!isNatixisAccount ? 'ActivateAccount-card' : 'ActivateAccount-card-Natixis'"
                >
                    <v-row>
                        <v-col class="text-h2">{{ $t('login.views.ActivateAccount.title') }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                        <PasswordTextField  v-model="$v.login.password.$model"
                                            :type="displayPassword ? 'text' : 'password'"
                                            class="ActivateAccount-input"
                                            :error-messages="passwordErrors"
                                            maxlength="255"
                                            @blur="validatePassword"
                                            @keypress.enter="prepareActivateAccount"
                        >
                          <template v-slot:label>
                            {{ $t('login.views.ActivateAccount.password')}}<i class="ActivateAccount-color">{{!isNatixisAccount ? $t('login.views.ActivateAccount.passwordInfo') : '' }}</i>
                          </template>
                        </PasswordTextField>
                        </v-col>
                    </v-row>
                    <v-row v-if="isNatixisAccount" class="mt-n3">
                        <v-col>
                        <v-row class="">
                            <v-col>
                                <p>{{$t('login.views.ActivateAccount.natixisRule')}}</p>
                            </v-col>
                        </v-row>
                        <v-row v-for="(rule, i) in natixisValidator" :key="`rule-${i}`" :class="rule.ruleIsValid() ? 'success--text mt-n6' : 'error--text mt-n6'" >
                            <v-col cols="1">
                                <i class="fal" :class="rule.ruleIsValid() ? 'fa-check' : 'fa-xmark'"></i>
                            </v-col>
                            <v-col cols="11" class="ml-n3">
                                <span>{{$t(rule.ruleName)}}</span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n6 ActivateAccount-color">
                            <v-col cols="1">
                                <i class="fal fa-info"></i>
                            </v-col>
                            <v-col cols="11" class="ml-n3">
                                <span>{{$t('login.views.ActivateAccount.natixisRules.userInfo')}}</span>
                            </v-col>
                        </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                        <PasswordTextField  v-model="$v.login.passwordConfirmation.$model"
                                            :type="displayConfirmPassword ? 'text' : 'password'"
                                            :label="$t('login.views.ActivateAccount.confirmPassword')"
                                            class="ActivateAccount-input mt-n3"
                                            :error-messages="passwordConfirmErrors"
                                            maxlength="255"
                                            @blur="validatePasswordConfirmation"
                                            @keypress.enter="prepareActivateAccount"
                        >
                        </PasswordTextField>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n4">
                        <v-col>
                            <v-checkbox
                                v-model.trim="$v.login.acceptPrivacy.$model"
                                outlined
                                color="primary"
                                class="ActivateAccount-checkbox"
                                dense
                                :error-messages="privacyErrors"
                                @change="validatePrivacy"
                            >
                              <template v-slot:label>
                                <div class="ActivateAccount-fs">
                                  {{ $t('login.views.ActivateAccount.privacy1')}}
                                  <template>
                                    <a href="#" @click.stop>
                                      <AppDownloadFile :link="getResourceLink($t('login.views.ActivateAccount.privacyUrl'))" forceReader>
                                        <template  #default="{ submitForm }">
                                          <div style="color:var(--v-anchor-base)" @click="submitForm">
                                            {{ $t('login.views.ActivateAccount.privacy2')}}
                                          </div>
                                        </template>
                                      </AppDownloadFile>
                                    </a>
                                  </template>
                                </div>
                              </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n8">
                        <v-col>
                            <v-checkbox
                                v-model.trim="$v.login.acceptTOS.$model"
                                outlined
                                color="primary"
                                class="ActivateAccount-checkbox"
                                dense
                                :error-messages="tosErrors"
                                @change="validateTos"
                            >
                              <template v-slot:label>
                                  <div class="ActivateAccount-fs">
                                    {{ $t('login.views.ActivateAccount.tos1')}}
                                    <template>
                                      <a href="#" @click.stop>
                                        <AppDownloadFile :link="getResourceLink($t('login.views.ActivateAccount.tosUrl'))" forceReader>
                                          <template  #default="{ submitForm }">
                                            <div style="color:var(--v-anchor-base)" @click="submitForm">
                                              {{ $t('login.views.ActivateAccount.tos2')}}
                                            </div>
                                          </template>
                                        </AppDownloadFile>
                                      </a>
                                    </template>
                                  </div>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n3">
                        <v-col class="text-center">
                            <template>
                                <AppButton  color="primary"
                                            :loading="loading"
                                            @click="prepareActivateAccount"
                                >
                                {{$t('login.views.ActivateAccount.activateAccountBtn')}}
                                </AppButton>
                            </template>
                        </v-col>
                    </v-row>
                </AppCard>
                <BackToClosd />
            </v-col>
        </v-row>
      </div>
     </template>
     <template v-if="loadingPage">
        <div class="d-flex align-center justify-center">
          <v-progress-circular
            style="width: 64px; height: 64px; position: absolute;"
            class="ActivateAccount-loader"
            color="primary"
            indeterminate
            size="64"
          />
      </div>
     </template>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { createNamespacedHelpers } from 'vuex'

import { ROOM_ROUTE_TYPES, SIGNING_VIEW_ROUTE_NAME } from '@/router'
import { ACTIVATE_ACCOUNT, CHECK_TOKEN } from '@/store/modules/login/action_types'

import AppCard from '../../common/AppCard'
import AppDownloadFile from '../../common/AppDownloadFile'
import AppButton from '../../common/buttons/AppButton'
import PasswordTextField from '../../common/PasswordTextField'
import { Login } from '../../models/login.model'
import { SET_LOADING_PAGE } from '../../store/modules/login/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'
import BackToClosd from '../BackToClosd'
import ClosdTagLine from '../ClosdTagLine'
import LoginTopbar from '../LoginTopbar'
const { mapState: mapLoginState, mapActions: mapLoginActions } = createNamespacedHelpers('login')

export default {
  name: 'ActivateAccount',
  components: { AppButton, PasswordTextField, AppCard, ClosdTagLine, LoginTopbar, BackToClosd, AppDownloadFile },
  mixins: [validationMixin],
  validations: {
    login: {
      password: { required, minLength: minLength(process.env.VUE_APP_ENABLE_NATIXIS_PASSWORD === 'true' ? 12 : 8) },
      passwordConfirmation: { required, sameAs: sameAs(function () { return this.login.password }) },
      acceptPrivacy: { sameAs: sameAs(() => true) },
      acceptTOS: { sameAs: sameAs(() => true) },
    },
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      login: new Login('', '', '', '', '', '', this.token),
      displayPassword: false,
      isNatixisAccount: false,
      displayConfirmPassword: false,
    }
  },
  computed: {
    ...mapLoginState(['loading', 'loadingPage']),
    submitButtonDisabled () {
      return this.$v.login.password.$anyError ||
        this.$v.login.passwordConfirmation.$anyError ||
        !this.$v.login.passwordConfirmation.$dirty ||
        this.$v.login.acceptPrivacy.$anyError ||
        !this.$v.login.acceptPrivacy.$dirty ||
        this.$v.login.acceptTOS.$anyError ||
        !this.$v.login.acceptTOS.$dirty ||
        (this.isNatixisAccount ? !this.natixisValidatorIsValid : false)
    },
    natixisValidator () {
      return [
        {
          ruleName: this.$t('login.views.ActivateAccount.natixisRules.minLength'),
          ruleIsValid: () => { return this.login.password ? this.login.password.length >= 12 : false },
        },
        {
          ruleName: this.$t('login.views.ActivateAccount.natixisRules.maj'),
          ruleIsValid: () => { return this.login.password ? /(?=[a-z])/.test(this.login.password) : false },
        },
        {
          ruleName: this.$t('login.views.ActivateAccount.natixisRules.min'),
          ruleIsValid: () => { return this.login.password ? /(?=[A-Z])/.test(this.login.password) : false },
        },
        {
          ruleName: this.$t('login.views.ActivateAccount.natixisRules.number'),
          ruleIsValid: () => { return this.login.password ? /(?=[^a-zA-Z\s\u00C0-\u00FF])/.test(this.login.password) : false },
        },
      ]
    },
    natixisValidatorIsValid () {
      return this.natixisValidator.every(rule => rule.ruleIsValid())
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.login.password.$dirty) return errors
      !this.$v.login.password.minLength && errors.push(this.$t(process.env.VUE_APP_ENABLE_NATIXIS_PASSWORD === 'true' ? 'login.views.ActivateAccount.rules.password.minNatixis' : 'login.views.ActivateAccount.rules.password.min', { fieldName: this.$t('login.views.ActivateAccount.password') }))
      !this.$v.login.password.required && errors.push(this.$t('login.views.ActivateAccount.rules.password.required', { fieldName: this.$t('login.views.ActivateAccount.password') }))
      return errors
    },
    passwordConfirmErrors () {
      const errors = []
      if (!this.$v.login.passwordConfirmation.$dirty) return errors
      !this.$v.login.passwordConfirmation.sameAs && errors.push(this.$t('login.views.ActivateAccount.rules.confirmPassword.equals', { fieldName: this.$t('login.views.ActivateAccount.confirmPassword') }))
      !this.$v.login.passwordConfirmation.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('login.views.ActivateAccount.confirmPassword') }))
      return errors
    },
    privacyErrors () {
      const errors = []
      if (!this.$v.login.acceptPrivacy.$dirty) return errors
      !this.$v.login.acceptPrivacy.sameAs && errors.push(this.$t('login.views.ActivateAccount.checkboxRequired', { fieldName: this.$t('login.views.ActivateAccount.privacy') }))
      return errors
    },
    tosErrors () {
      const errors = []
      if (!this.$v.login.acceptTOS.$dirty) return errors
      !this.$v.login.acceptTOS.sameAs && errors.push(this.$t('login.views.ActivateAccount.checkboxRequired', { fieldName: this.$t('login.views.ActivateAccount.tos') }))
      return errors
    },
  },
  created () {
    this.$store.commit(`login/${SET_LOADING_PAGE}`, true)
    this.checkInitToken()
    this.isNatixisAccount = process.env.VUE_APP_ENABLE_NATIXIS_PASSWORD === 'true'
  },
  methods: {
    ...mapLoginActions([ACTIVATE_ACCOUNT, CHECK_TOKEN]),
    checkInitToken () {
      this.CHECK_TOKEN(this.token)
    },
    validatePassword () {
      this.$v.login.password.$touch()
    },
    validatePasswordConfirmation () {
      this.$v.login.passwordConfirmation.$touch()
    },
    validatePrivacy () {
      this.$v.login.acceptPrivacy.$touch()
    },
    validateTos () {
      this.$v.login.acceptTOS.$touch()
    },
    getResourceLink (fileName) {
      return `${process.env.VUE_APP_API_URL}/assets/pdf?file=${fileName}`
    },
    async prepareActivateAccount () {
      if (this.loading) {
        return
      }
      this.$v.login.$touch()
      if (!this.$v.login.$invalid) {
        try {
          const redirectRoute = this.$route.query.envelopeIdToSign &&
            {
              name: SIGNING_VIEW_ROUTE_NAME,
              params: {
                envelopeId: this.$route.query.envelopeIdToSign,
                mnemo: this.$route.query.mnemo,
                roomType: ROOM_ROUTE_TYPES.PROJECT,
              },
            }
          await this.ACTIVATE_ACCOUNT({
            login: this.login,
            redirectRoute,
          })
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('login.views.ActivateAccount.submitError'),
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .ActivateAccount-container {
    max-width: 1100px;
    height: 100%;
  }
  .ActivateAccount-loader{
    margin-top: 50%;
    transform: translateY(-75%);
  }
  .ActivateAccount-card {
    box-shadow: 0 0 10px 0 rgba(94, 86, 105, 0.1) !important;
    border: 1px solid #bdbdbd;
    padding: 25px 35px;
    height: 480px;
    position: relative;
  }
  .ActivateAccount-card-Natixis {
    box-shadow: 0 0 10px 0 rgba(94, 86, 105, 0.1) !important;
    border: 1px solid #bdbdbd;
    padding: 25px 35px;
    height: 590px;
    position: relative;
  }
  .ActivateAccount-fs{
    font-size: 14px;
  }
  .ActivateAccount-font-color{
    color: #6c757d;
  }
  ::v-deep .ActivateAccount-input .v-input__slot {
    background-color: #fff;
  }
  ::v-deep .ActivateAccount-checkbox .fa-square:before {
      background-color: #fff;
  }
  ::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: #ffe3e1 !important;
  }
  .ActivateAccount-link{
      text-decoration: none;
  }
  .ActivateAccount-center-box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')}{
    .ActivateAccount-colonne-explain{
      display: none;
    }
    .ActivateAccount-content{
      margin-top: 0;
      transform: translateY(0);
    }
  }
</style>
