<template>
  <Alert color="light-blue" class="refresh-page-alert" :value="show" transition="fade-transition" @input="$emit('close')">
    <div class="d-sm-flex align-center justify-space-between">
      <div class="mr-4">
        <p class="font-weight-semi-bold">{{ $t('common.AppVersionCheckerAlert.alertTitle') }}</p>
        <p>{{ $t('common.AppVersionCheckerAlert.alertContent') }}</p>
      </div>
      <AppButton :disabled="isReloading" color="light-blue" outlined @click="onReload">{{ $t('common.AppVersionCheckerAlert.alertCtaLabel') }}</AppButton>
    </div>
  </Alert>
</template>

<script>
import Alert from '@/common/alerts/Alert.vue'
import AppButton from '@/common/buttons/AppButton.vue'

export default {
  name: 'AppVersionCheckerAlert',
  components: { Alert, AppButton },
  data () {
    return {
      isReloading: false,
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onReload () {
      this.isReloading = true
      this.$emit('reload')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.refresh-page-alert {
  background-color: #EBF9FF !important;
  box-shadow: 0 4px 30px rgba(148, 148, 148, 0.3) !important;
  left: 50%;
  max-width: 675px;
  position: fixed;
  top: 3.5rem;
  transform: translateX(-50%) perspective(1px);
  width: 95%;
  z-index: 10;

  &:before {
    opacity: 0
  }

  .v-alert__icon {
    align-self: center;
  }

  .v-btn--disabled {
    background: none;
    border-color: #CED4DA;
    color: #CED4DA !important;
  }
}
</style>
