<template>
  <div :class="{'py-2 px-1': $vuetify.breakpoint.mdAndUp}"
  >
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <div class="ChecklistTableTaskDate h-100"
           :class="{'ChecklistTableTaskDate-clickableArea': item.canWrite}"
           @click="openDialog"
      >
        <div v-if="computedDate">
          {{ computedDate }}
          <AppTooltip v-if="item.canWrite && item.reminders.length > 0"
                     top
          >
            <template #activator="{on, attrs}">
              <font-awesome-icon :icon="['fal', 'alarm-clock']"
                                 v-bind="attrs"
                                 v-on="on"
              ></font-awesome-icon>
            </template>
            <span>{{ $t('project.checklist.task.ChecklistTableTaskDate.taskHasRemindersTooltip') }}</span>
          </AppTooltip>
        </div>
        <div v-if="item.dateString" :class="{'ChecklistTableTaskDateString': computedDate}" v-html="item.dateString"></div>
        <div v-if="!computedDate && !item.dateString && item.canWrite"
             class="h-100 d-flex align-center justify-center"
        >
          <div class="ChecklistTableTaskDate-placeholder text-center">
            {{ $t('project.checklist.task.ChecklistTableTaskDate.clickToAddDate') }}
          </div>
        </div>
      </div>
    </template>
    <template v-if="$vuetify.breakpoint.smAndDown">
      <div class="ChecklistTableTaskDate-mobileRowGrid pa-2">
        <div>
          {{ $t('project.checklist.task.ChecklistTableTaskDate.dueDate') }}
        </div>
        <div>
          <div class="ChecklistTableTaskDate h-100"
               :class="{'ChecklistTableTaskDate-clickableArea': item.canWrite}"
               @click="openDialog"
          >
            <div v-if="computedDate">
              {{ computedDate }}
              <AppTooltip v-if="item.canWrite && item.reminders.length > 0"
                         top
              >
                <template #activator="{on, attrs}">
                  <font-awesome-icon :icon="['fal', 'alarm-clock']"
                                     v-bind="attrs"
                                     v-on="on"
                  ></font-awesome-icon>
                </template>
                <span>{{ $t('project.checklist.task.ChecklistTableTaskDate.taskHasRemindersTooltip') }}</span>
              </AppTooltip>
            </div>
            <div v-if="item.dateString" :class="{'ChecklistTableTaskDateString': computedDate}" v-html="item.dateString"></div>
            <div v-if="!computedDate && !item.dateString && item.canWrite"
                 class="h-100 d-flex align-center justify-center"
            >
              <div class="ChecklistTableTaskDate-placeholder text-center">
                {{ $t('project.checklist.task.ChecklistTableTaskDate.clickToAddDate') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <AppDialog :is-open="dialogIsOpen"
               size="l"
               :ok-disabled="validatePostDateDisabled"
               :okText="$t('common.save')"
               @cancel="resetAndClose"
               @ok="preparePostDate"
    >
      <template #title>{{ $t('project.checklist.task.ChecklistTableTaskDate.dialogTitle') }}</template>
      <template #body>
        <v-row>
          <v-col class="text--primary">
            <div class="text-h4 mb-4">{{ $t('project.checklist.task.ChecklistTableTaskDate.pickADate') }}</div>
            <div class="d-flex align-center">
              <AppDatePicker
                v-model="newDate"
                :min="minStartDate"
                class="w-100 ChecklistTableTaskDate-datePicker"
                :placeholder="$t('common.dateUtils.dateFormatHelp')"
                append-icon="far fa-calendar"
                :error-messages="noDate ? $t('project.checklist.task.ChecklistTableTaskDate.noDate') : ''"
                @input="noDate = false"
              >
              </AppDatePicker>
            </div>
          </v-col>
        </v-row>
        <v-row class="text--primary">
          <v-col>
            <div class="mb-3 d-flex align-center">
              <h4 class="text-h4">{{ $t('project.checklist.task.ChecklistTableTaskDate.reminders') }}</h4>
              <AppTooltip top max-width="600">
                <template #activator="{ attrs, on }">
                  <app-icon
                    icon-name="circle-info"
                    class="ml-2 grey--text text--darken-1"
                    size="small"
                    weight="regular"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>{{ $t('project.checklist.task.ChecklistTableTaskDate.remindersText')  }}</span>
              </AppTooltip>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="newReminders.length > 0">
          <v-col>
            <template v-for="(reminder, index) in newReminders">
              <div :key="`reminder-${index}`"
                   class="d-flex align-center my-2"
              >
                <AppTextField
                  v-model.number="reminder.daysBefore"
                  style="max-width: 146px"
                  :disabled="reminder.sent === 1"
                  type="number"
                  min="0"
                  autofocus
                  :suffix="$tc('project.checklist.task.ChecklistTableTaskDate.reminderDaysBefore', reminder.daysBefore)"
                  class="ChecklistTableTaskDate-dateReminderPicker mr-2"
                  :error="reminder.errorWrongValue || reminder.errorBackToFuture || reminder.errorAfterEvent"
                  @blur="validateReminder(reminder)"
                  @change="validateReminder(reminder)"
                />
                <app-button
                  type="icon"
                  color="#d8d8d8"
                  @click="removeReminder(index)"
                >
                  <font-awesome-icon :icon="['far', 'xmark']"
                                     class="text--primary body-1"
                  ></font-awesome-icon>
                </app-button>
              </div>
              <div v-show="reminder.error"
                   :key="`reminderError-${index}`"
                   class="caption error--text"
              >
                <p v-show="reminder.errorWrongValue">{{ $t('project.checklist.task.ChecklistTableTaskDate.lblReminderWrongData') }}</p>
                <p v-show="reminder.errorBackToFuture">{{ $t('project.checklist.task.ChecklistTableTaskDate.lblReminderInThePast') }}</p>
                <p v-show="reminder.errorAfterEvent">{{ $t('project.checklist.task.ChecklistTableTaskDate.lblReminderNegativeValue') }}</p>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <p>{{ $t('project.checklist.task.ChecklistTableTaskDate.noReminder') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-button
              type="outlined"
              variant="neutral"
              @click="addNewReminder"
            >
              <font-awesome-icon :icon="['fas', 'plus']" style="margin-right: 6px;"></font-awesome-icon>
              {{ $t('project.checklist.task.ChecklistTableTaskDate.addReminder') }}
          </app-button>
          </v-col>
        </v-row>
        <v-row class="text--primary">
          <v-col>
            <div class="text-h4 mb-3">
              {{ $t('project.checklist.task.ChecklistTableTaskDate.moreInfo') }}
            </div>
            <div>
              <VisualTextEditor
                v-model="notifyMessage"
                counter="footer"
                :placeholder="$t('project.checklist.task.ChecklistTableTaskDate.moreInfoPlaceholder')"
                :error-messages="notifyMessageErrors"
                :max-length="notifyMessageMaxLength"
              />
            </div>
          </v-col>
        </v-row>
      </template>
    </AppDialog>
  </div>
</template>

<script>

import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { mapActions, mapState } from 'vuex'

import AppDatePicker from '../../../common/AppDatePicker'
import AppTextField from '../../../common/AppTextField'
import AppTooltip from '../../../common/AppTooltip'
import AppDialog from '../../../common/dialogs/AppDialog'
import { DATE_FORMAT, ISOToShortenedDate, ISOToYYYYMMDD } from '../../../common/utils/dates'
import { escapeHtml } from '../../../common/utils/strings'
import VisualTextEditor from '../../../common/visual-text-editor/VisualTextEditor'
import { EDIT_TASK } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

const timezone = require('dayjs/plugin/timezone')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
dayjs.extend(timezone)

const NOTIFY_MESSAGE_MAX_LENGTH = 100

export default {
  name: 'ChecklistTableTaskDate',
  components: {
    AppDatePicker,
    AppDialog,
    AppTextField,
    AppTooltip,
    VisualTextEditor,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      dialogIsOpen: false,
      minStartDate: null,
      newDate: null,
      newReminders: [],
      noDate: false,
      notifyMessage: '',
      notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
    }
  },
  computed: {
    ...mapState('checklist', ['editTaskPending']),
    computedDate () {
      if (this.item.date) {
        return ISOToShortenedDate(this.item.date)
      }
      return null
    },
    notifyMessageLength () {
      return escapeHtml(this.notifyMessage).length
    },
    notifyMessageIsTooLong () {
      return this.notifyMessageLength > this.notifyMessageMaxLength
    },
    notifyMessageErrors () {
      const errors = []

      if (this.notifyMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    validatePostDateDisabled () {
      return this.notifyMessageIsTooLong
    },
  },
  created () {
    this.minStartDate = dayjs().format(DATE_FORMAT)
  },
  methods: {
    ...mapActions('checklist', [EDIT_TASK]),
    async preparePostDate () {
      this.newReminders.forEach(reminder => {
        this.validateReminder(reminder)
      })
      let sentReminders = []
      if (!this.newDate) {
        this.noDate = true
      } else if (this.newDate && this.allRemindersValid()) {
        this.noDate = false
        sentReminders = this.newReminders
      }
      try {
        await this.EDIT_TASK({
          parent: this.parent,
          mnemo: this.mnemo,
          taskId: this.item.id,
          data: {
            date: this.newDate,
            dateString: this.notifyMessage,
            reminders: sentReminders,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.task.ChecklistTableTaskDate.postDateSuccess'),
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.task.ChecklistTableTaskDate.postDateError'),
        })
      } finally {
        this.resetAndClose()
      }
    },
    allRemindersValid () {
      return this.newReminders.every(reminder => { return !reminder.error })
    },
    resetAndClose () {
      this.dialogIsOpen = false
      this.newDate = null
      this.newReminders = []
      this.notifyMessage = ''
      this.noDate = false
    },
    openDialog () {
      if (this.item.canWrite && this.item.canWriteStatus) {
        this.newDate = ISOToYYYYMMDD(this.item.date)
        this.newReminders = cloneDeep(this.item.reminders)
        this.notifyMessage = this.item.dateString
        this.dialogIsOpen = true
      }
    },
    addNewReminder () {
      this.newReminders.push({
        daysBefore: 0,
        id: null,
        reminderAt: null,
        sent: 0,
      })
    },
    validateReminder (reminder) {
      this.$set(reminder, 'errorWrongValue', false)
      this.$set(reminder, 'errorBackToFuture', false)
      this.$set(reminder, 'errorAfterEvent', false)
      // Check for wrong input value
      if ((!reminder.daysBefore && reminder.daysBefore !== 0) || isNaN(reminder.daysBefore) || reminder.daysBefore === '') this.$set(reminder, 'errorWrongValue', true)
      // Check for reminder set after task
      if (reminder.daysBefore && reminder.daysBefore < 0) this.$set(reminder, 'errorAfterEvent', true)
      // Check for reminder happening in the past
      if (reminder.daysBefore && dayjs().tz('Europe/Paris').set({ hour: 0, minute: 0 }).diff(dayjs(this.newDate).subtract(reminder.daysBefore, 'days'), 'days') > 0) this.$set(reminder, 'errorBackToFuture', true)

      this.$set(reminder, 'error', reminder.errorWrongValue || reminder.errorBackToFuture || reminder.errorAfterEvent)
    },
    removeReminder (reminderIndex) {
      this.newReminders.splice(reminderIndex, 1)
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableTaskDate {
  padding: 4px 6px;
}
.ChecklistTableTaskDateString {
  margin-top: 15px;
}
.ChecklistTableTaskDate-clickableArea {
  border: 1px dashed transparent;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border-color: #bdbdbd;
    background-color: #eee;
  }
}
.ChecklistTableTaskDate-placeholder {
  font-style: italic;
  color: rgba(51, 51, 51, .51);
}
::v-deep .ChecklistTableTaskDate-datePicker {
  .v-input__icon i {
    color: var(--v-accent-base) !important;
    margin-right: $spacer * 4;
    font-size: 16px;
  }
}

.ChecklistTableTaskDate-dateReminderPicker ::v-deep .v-text-field__suffix {
  // Space allocated to make the text align even with plurals
  min-width: 75px;
}

.ChecklistTableTaskDate-mobileRowGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
</style>
