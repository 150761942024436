<template>
  <div>
    <template v-if="!uploadFinished">
      <Alert variant="info" show>
        <p class="mb-0">{{ $t("archives.steps.ArchivesAddStep4.confirmationHint") }}</p>
      </Alert>
      <v-row v-if="filesToUpload.length !== 0">
        <v-col cols="12" class="ArchivesAddStep4-title">
          <p v-if="!uploadStarted">{{ $t("archives.steps.ArchivesAddStep4.titleUploadNotStarted") }}</p>
          <p v-else>{{ $t("archives.steps.ArchivesAddStep4.titleUploadStarted") }}</p>
        </v-col>
      </v-row>
      <v-row v-if="filesToUpload.length !== 0">
        <v-col>
          <ArchivesAddStep4File
            v-for="(file, i) in filesToUpload"
            :key="`fileToUpload-${i}`"
            upload
            :file="file"
          />
        </v-col>
      </v-row>
      <v-row v-if="filesOnClosd.length !== 0" class="mt-4">
        <v-col cols="12" class="ArchivesAddStep4-title">
          <p>{{ $t("archives.steps.ArchivesAddStep4.titleUploadReady") }}</p>
        </v-col>
      </v-row>
      <v-row v-if="filesOnClosd.length !== 0">
        <v-col>
          <ArchivesAddStep4File
            v-for="file in filesOnClosd"
            :key="`fileOnClosd-${
              file.objectToPost.fileId || file.objectToPost.envId || file.objectToPost.bibleId
            }`"
            :file="file"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="archivingSuccess">
      <v-row>
        <v-col class="text-center">
          <div class="my-4">
            <i
              class="fad fa-circle-check archiving-status-icon archivingSuccessIcon"
            ></i>
          </div>
          <div class="archivingFilesNumber my-4">
            {{
              $tc(
                "archives.steps.ArchivesAddStep4.successTemplate.archivingFilesNumber",
                importedFiles.length,
                { nb: importedFiles.length },
              )
            }}
          </div>
          <div class="archivingRedirect my-4">
            {{ $t("archives.steps.ArchivesAddStep4.successTemplate.archivingRedirect") }}
            <router-link :to="{ name: ARCHIVES_ROUTE_NAME }">{{
              $t("archives.steps.ArchivesAddStep4.successTemplate.archivingRedirectLink")
            }}</router-link>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="archivingFailure">
      <div class="text-center">
        <div class="my-4">
          <i
            class="fad fa-circle-exclamation archiving-status-icon archivingFailureIcon"
          ></i>
        </div>
        <div class="archivingFilesNumber my-4">
          {{
            $tc("archives.steps.ArchivesAddStep4.failureTemplate.archivingFilesNumber", failedFiles.length, {
              nb: failedFiles.length,
            })
          }}
        </div>
      </div>
      <v-row>
        <v-col cols="12" lg="8" class="ArchivesAddStep4-title">
          <p>{{ $t("archives.steps.ArchivesAddStep4.failureTemplate.failedFiles") }}</p>
        </v-col>
      </v-row>
      <v-row class="my-2">
        <v-col>
          <ArchivesAddStep4File
            v-for="(file, i) in failedFiles"
            :key="`failedFile-${i}`"
            :file="file"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ArchivesAddStep4File from './ArchivesAddStep4File'
import Alert from '../../common/alerts/Alert'
import { ARCHIVES_ROUTE_NAME } from '../../router'
import { UPLOAD_ARCHIVE, POST_ARCHIVE } from '../../store/modules/archives/action_types'
const { mapActions } = createNamespacedHelpers('archives')
export default {
  name: 'ArchivesAddStep4',
  components: {
    ArchivesAddStep4File,
    Alert,
  },
  props: {
    importedFiles: {
      required: true,
      validator: (value) => {
        return value === undefined || Array.isArray(value)
      },
    },
  },
  data () {
    return {
      uploadStarted: false,
      uploadFinished: false,
      SIMULTANEOUS_FILES_UPLOAD: Number(process.env.VUE_APP_SIMULTANEOUS_FILES_UPLOAD) || 2,
      failedFiles: [],
    }
  },
  computed: {
    queuedFilesInProgress () {
      return this.$store.state.archives.uploadArchivePendingIds.length
    },
    filesToUpload () {
      return this.importedFiles.filter(f => f.objectToPost.hasOwnProperty('fileRaw'))
    },
    filesOnClosd () {
      return this.importedFiles.filter(f => !!f.objectToPost.fileId || !!f.objectToPost.envId || !!f.objectToPost.bibleId)
    },
    archivingSuccess () {
      return this.importedFiles.every(f => f.status === 'success')
    },
    archivingFailure () {
      return this.failedFiles.length !== 0
    },
  },
  watch: {
    importedFiles: {
      handler (newImportedFiles) {
        if (newImportedFiles.every((file) => file.status === 'success' || file.status === 'error')) {
          this.uploadFinished = true
          this.$emit('uploadFinished')
          this.failedFiles = newImportedFiles.filter((file) => file.status === 'error')
        }
      },
      deep: true,
    },
  },
  created () {
    this.ARCHIVES_ROUTE_NAME = ARCHIVES_ROUTE_NAME
  },
  methods: {
    ...mapActions([UPLOAD_ARCHIVE, POST_ARCHIVE]),
    startUpload () {
      this.uploadStarted = true
      this.$emit('uploadStarted')
      this.launchQueue()
      for (const file of this.filesOnClosd) {
        this.POST_ARCHIVE(file)
      }
    },
    launchQueue () {
      for (const f of this.filesToUpload) {
        if (f.status === 'queued') {
          if (this.queuedFilesInProgress < (process.env.VUE_APP_SIMULTANEOUS_FILES_UPLOAD || 2)) {
            f.status = 'uploading'
            this.UPLOAD_ARCHIVE(f)
              .finally(() => {
                if (this.queuedFilesInProgress < (process.env.VUE_APP_SIMULTANEOUS_FILES_UPLOAD || 2)) {
                  this.launchQueue()
                }
              })
          }
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ArchivesAddStep4-title {
  font-size: 16px;
}

.archiving-status-icon {
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  font-size: 4rem;
}
.archivingSuccessIcon {
  --fa-primary-color: #7bb287;
  --fa-secondary-color: rgba(123, 178, 135, 0.4);
}

.archivingFailureIcon {
  --fa-primary-color: #b99c6b;
  --fa-secondary-color: rgb(245, 230, 178);
}
.archivingFilesNumber {
  font-weight: 600;
}
</style>
