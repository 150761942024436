import axios from 'axios'

export default {
  changeSigner (mnemo, envId, oldUserId, data) {
    return axios.patch(`/room/${mnemo}/envelope/${envId}/signer/${oldUserId}`, data)
  },
  deleteAllTagByType (mnemo, envId, data) {
    return axios.post(`/room/${mnemo}/envelope/${envId}/tags/delete`, data)
  },
  deleteSigner (mnemo, envId, signatureId) {
    return axios.delete(`/room/${mnemo}/envelope/${envId}/signature/${signatureId}`)
  },
  deleteTag (mnemo, envId, tagId, data) {
    return axios.delete(`/room/${mnemo}/envelope/${envId}/tags/${tagId}`, { data: data })
  },
  duplicateTag (mnemo, envId, tagId, data) {
    return axios.post(`/room/${mnemo}/envelope/${envId}/tags/${tagId}/autofill`, data)
  },
  getAutoTags (mnemo, envId) {
    return axios.get(`room/${mnemo}/envelope/${envId}/searchTags`)
  },
  getEnvelopeDisplayInformation (mnemo, envId) {
    return axios.get(`room/${mnemo}/envelope/${envId}/displayInfo`)
  },
  getPages (mnemo, envId, pageQuery) {
    return axios.get(`room/${mnemo}/envelope/${envId}/pages?pages=${pageQuery}`)
  },
  getPageThumb (mnemo, envId, pageId) {
    return axios.get(`room/${mnemo}/downloads/envelopePageThumb/${envId}-${pageId}`)
  },
  getTaggingInformation (mnemo, envId) {
    return axios.get(`room/${mnemo}/envelope/${envId}/taggingInfo`)
  },
  patchSignersOrder (mnemo, envId, data) {
    return axios.patch(`/room/${mnemo}/envelope/${envId}`, data)
  },
  patchTag (mnemo, envId, data) {
    return axios.patch(`room/${mnemo}/envelope/${envId}/tags/${data.tag.id}`, data)
  },
  patchSigningOrder (mnemo, envId, data) {
    return axios.patch(`/room/${mnemo}/envelope/${envId}`, data)
  },
  postAutoTags (mnemo, envId, data) {
    return axios.post(`room/${mnemo}/envelope/${envId}/searchTags`, data)
  },
  postSigners (mnemo, envId, signers) {
    return axios.post(`/room/${mnemo}/envelope/${envId}/signers`, signers)
  },
  postTag (mnemo, envId, data) {
    return axios.patch(`room/${mnemo}/envelope/${envId}/tags/0`, data)
  },
}
