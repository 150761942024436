<template>
  <div class="local-filders-selector">
    <AppDroppableArea class="local-filders-selector__droppable-area pa-6"
                      :disabled="disabled"
                      @drop="dropFilders"
    >
      <div class="d-flex flex-column align-center justify-center row-gap-6 h-100">
        <img src="/img/icons/2fichiers.svg"
             alt=""
             width="105"
        />
        <div class="text-center">
          <app-text as="span" variant="small-bold">
            {{ $tc("common.filders.MixedFildersSelector.dragAndDropFildersHint", multiple) }}
          </app-text>
          <app-text variant="small-regular">
            {{ $t("common.filders.MixedFildersSelector.importAlternativeInstruction") }}
          </app-text>
        </div>

        <div class="d-flex flex-column align-center row-gap-2">
          <AppButton type="primary"
                      size="small"
                      :disabled="disabled"
                      @click="$refs.filesLoaderInput.click()"
          >
            <font-awesome-icon :icon="['far', 'plus']" class="mr-2" />
            {{ $tc("common.filders.MixedFildersSelector.importFiles", multiple) }}
          </AppButton>

          <AppButton v-if="!filesOnly"
                      type="primary"
                      size="small"
                      :disabled="disabled"
                      @click="$refs.foldersLoaderInput.click()"
          >
            <font-awesome-icon :icon="['far', 'plus']" class="mr-2" />
            {{ $t("common.filders.MixedFildersSelector.importFolders") }}
          </AppButton>

          <ExternalFildersSelector @select="onExternalFiles" />
        </div>
      </div>

      <input
        ref="filesLoaderInput"
        type="file"
        accept=".pdf, .doc, .docx"
        multiple
        class="d-none"
        @change="importFiles($event)"
      />
      <input
        ref="foldersLoaderInput"
        type="file"
        accept=".pdf, .doc, .docx"
        webkitdirectory
        multiple
        class="d-none"
        @change="importFiles($event)"
      />
    </AppDroppableArea>
  </div>
</template>

<script lang="ts">
import { getFilesFromDataTransferItems } from 'datatransfer-files-promise'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import AppDroppableArea from '@/common/AppDroppableArea.vue'
import ExternalFildersSelector from '@/common/filders/ExternalFildersSelector.vue'
import AppButton from '@/design-system/buttons/AppButton.vue'

export default defineComponent({
  name: 'MixedFildersSelector',
  components: {
    AppButton,
    AppDroppableArea,
    ExternalFildersSelector,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    filesOnly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
  },
  methods: {
    async dropFilders ($event) {
      const dataTransferItems = $event.dataTransfer?.items
      if (dataTransferItems) {
        const droppedFiles = await getFilesFromDataTransferItems(dataTransferItems)
        this.$emit('select', droppedFiles)
      }
    },
    importFiles ($event) {
      const { files } = $event.target
      this.$emit('select', files)
      ;(this.$refs.filesLoaderInput as HTMLInputElement).value = ''
      ;(this.$refs.foldersLoaderInput as HTMLInputElement).value = ''
    },
    onExternalFiles (event) {
      this.$emit('select', event.files)
    },
  },
})
</script>

<style scoped lang="scss">
.local-filders-selector {
  border: 2px dashed #E0E0E0;
  border-radius: 7px;
  height: 100%;
  width: 100%;

  &__droppable-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
