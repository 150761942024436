<template>
  <span>{{formattedSize}}</span>
</template>

<script lang="ts">
import Vue from 'vue'

/**
 * @displayName File Size
 */
export default Vue.extend({
  name: 'FileSizeDisplay',
  props: {
    /**
     * Given size
     */
    value: {
      type: Number,
      default: 0,
    },
    /**
     * Default value used when the prop value is falsy
     * Can be a string, which will be displayed as is
     * Or a number, which will be displayed in KB.
     */
    defaultValue: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    formattedSize (): string {
      const size: number = this.value
      if (size) {
        if (size < 1024) {
          return size + ' ' + this.$t('design-system.display.FileSizeDisplay.lblB')
        } else if (size < 1024 * 1024) {
          return (size / 1024).toFixed(0) + ' ' + this.$t('design-system.display.FileSizeDisplay.lblKB')
        } else if (size < 1024 * 1024 * 1024) {
          return (size / (1024 * 1024)).toFixed(1) + ' ' + this.$t('design-system.display.FileSizeDisplay.lblMB')
        } else {
          return (size / (1024 * 1024 * 1024)).toFixed(2) + ' ' + this.$t('design-system.display.FileSizeDisplay.lblGB')
        }
      }
      return `${this.defaultValue}${typeof this.defaultValue === 'number' ? ' ' + this.$t('design-system.display.FileSizeDisplay.lblKB') : ''}`
    },
  },
})
</script>

<docs>
```vue
<template>
  <div>
    <AppTextField v-model="size"
                  placeholder="Type any number"
                  label="Size"
                  type="number"
    />
    <app-filesize-display :value="size"/>
  </div>
</template>
<script>
  import AppTextField from '@/common/AppTextField.vue'
  export default {
    components: { AppTextField },
    data () {
      return {
        size: 0,
      }
    },
  }
</script>
```
</docs>
