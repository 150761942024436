<template>
  <v-overlay app
             :opacity=".85"
             :z-index="999"
  >
    <div>
      <div class="Maintenance-title mb-6">{{ $t('maintenance.Maintenance.closdComeBackSoon') }}</div>
      <v-img src="/img/maintenance/maintenance-illustration.png"
             width="300"
      />
      <div v-if="retry"
           class="mt-4 body-1"
      >
        {{ $t('maintenance.Maintenance.retryAt', { date: retry }) }}
      </div>
    </div>
  </v-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { ISOToShortDate } from '@/common/utils/dates'
import { GET_STATUS } from '@/store/modules/maintenance/action_types'
import { SET_MAINTENANCE_MODE_ENABLED, SET_RETRY_AT } from '@/store/modules/maintenance/mutation_types'

export default {
  name: 'Maintenance',
  data () {
    return {
      getStatusTimeout: null,
    }
  },
  computed: {
    ...mapState('maintenance', ['retryAt']),
    retry () {
      if (this.retryAt) {
        return ISOToShortDate(this.retryAt)
      }
      return null
    },
  },
  created () {
    this.getStatus()
  },
  beforeDestroy () {
    clearTimeout(this.getStatusTimeout)
  },
  methods: {
    ...mapActions('maintenance', [GET_STATUS]),
    async getStatus () {
      try {
        await this.GET_STATUS()
        this.$store.commit(`maintenance/${SET_RETRY_AT}`, null)
        this.$store.commit(`maintenance/${SET_MAINTENANCE_MODE_ENABLED}`, false)
      } catch (error) {
        if (error.response?.status === 503) {
          this.getStatusTimeout = setTimeout(() => { this.getStatus() }, 1000 * 60)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.Maintenance-title {
  text-align: center;
  font-size: 30px;
}
</style>
