<template>
  <div v-show="isDisplayed"
       class="ChecklistTableTask-container text--primary position-relative"
       :class="{'ChecklistTableTask-container--guestNoChat': item.comments.length === 0 && !currentRoom.userRights.canCommentTasks}"
  >
    <template v-if="isEdited">
      <v-skeleton-loader type="text@2"/>
    </template>
    <template v-else>
      <div :class="{ 'is-awaiting-delete-validation': isAwaitingDeleteValidation }">
        <div class="ChecklistTableTask">
          <template>
            <ChecklistTableTaskDocument :mnemo="mnemo"
                                        :item="item"
                                        :parent="parent"
            />
            <ChecklistTableTaskResponsible :mnemo="mnemo"
                                           :item="item"
                                           :parent="parent"
            />
            <ChecklistTableTaskValidators :mnemo="mnemo"
                                          :item="item"
                                          :parent="parent"
            />
            <ChecklistTableTaskStatus :mnemo="mnemo"
                                      :item="item"
                                      :parent="parent"
                                      @edit-status="checklistTableStatusDialogIsOpen = true"
            />
            <ChecklistTableTaskDate :mnemo="mnemo"
                                    :item="item"
                                    :parent="parent"
            />
            <ChecklistTableTaskMenu v-if="$vuetify.breakpoint.mdAndUp"
                                    :mnemo="mnemo"
                                    :item="item"
                                    :parent="parent"
            />
          </template>
        </div>
        <div class="ChecklistTableTask-chat">
          <ChecklistTableTaskChat :mnemo="mnemo"
                                  :item="item"
                                  :parent="parent"
          />
        </div>
      </div>
    </template>

    <div v-show="floatingAdd && isCurrentUserPm">
      <AppMenu :menu="floatingMenu">
        <template #activator="{ on, attrs }">
          <!-- We use a bare v-tooltip here because AppTooltip has trouble when combined with another activator -->
          <v-tooltip top transition="fade-transition">
            <template #activator="{on: onTooltip}">
              <font-awesome-icon
                v-bind="{...attrs}"
                :icon="['fas', 'map-marker-plus']"
                class="fa-rotate-270 marker"
                v-on="{...onTooltip, ...on}"
              ></font-awesome-icon>
            </template>
            <span>{{$t('project.checklist.task.ChecklistTableTask.floatingAddTooltip')}}</span>
          </v-tooltip>
        </template>
      </AppMenu>
    </div>

    <ChecklistTableStatusDialog v-if="checklistTableStatusDialogIsOpen"
                                :mnemo="mnemo"
                                :task="item"
                                :parent="parent"
                                @close="checklistTableStatusDialogIsOpen = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import ChecklistTableStatusDialog from '@/project/checklist/task/ChecklistTableStatusDialog.vue'
import { CREATE_TITLE, CREATE_TASK } from '@/store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import ChecklistTableTaskChat from './ChecklistTableTaskChat'
import ChecklistTableTaskDate from './ChecklistTableTaskDate'
import ChecklistTableTaskDocument from './ChecklistTableTaskDocument'
import ChecklistTableTaskMenu from './ChecklistTableTaskMenu'
import ChecklistTableTaskResponsible from './ChecklistTableTaskResponsible'
import ChecklistTableTaskStatus from './ChecklistTableTaskStatus'
import ChecklistTableTaskValidators from './ChecklistTableTaskValidators'
import AppMenu from '../../../common/AppMenu.vue'

export default {
  name: 'ChecklistTableTask',
  components: {
    AppMenu,
    ChecklistTableStatusDialog,
    ChecklistTableTaskChat,
    ChecklistTableTaskDate,
    ChecklistTableTaskDocument,
    ChecklistTableTaskMenu,
    ChecklistTableTaskResponsible,
    ChecklistTableTaskStatus,
    ChecklistTableTaskValidators,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
    isMatchingFilters: {
      type: Boolean,
      required: true,
    },
    floatingAdd: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      checklistTableStatusDialogIsOpen: false,
    }
  },
  computed: {
    ...mapState('checklist', ['editTaskPending', 'refreshTaskPendingIds', 'createTitlePending', 'createTaskPending', 'taskStatuses']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('checklist', ['isChecklistFiltered']),
    ...mapGetters('room', ['isCurrentUserPm']),
    isEdited () {
      return this.editTaskPending === this.item.id || this.refreshTaskPendingIds.includes(this.item.id)
    },
    floatingMenu () {
      return [
        {
          title: this.$t('project.checklist.task.ChecklistTableTask.addTask'),
          action: async () => {
            try {
              await this.CREATE_TASK({
                mnemo: this.mnemo,
                data: {
                  prevId: this.item.id,
                  prevType: this.item.type,
                },
              })
              this.$store.commit(ENQUEUE_SNACKBAR, {
                color: 'success',
                message: this.$t('project.checklist.task.ChecklistTableTask.createTaskSuccess'),
              })
            } catch (e) {
              this.$store.commit(ENQUEUE_SNACKBAR, {
                color: 'error',
                message: this.$t('project.checklist.task.ChecklistTableTask.createTaskError'),
              })
            }
          },
          loading: this.createTaskPending,
        },
        {
          title: this.$t('project.checklist.task.ChecklistTableTask.addTitle'),
          action: async () => {
            try {
              const payload = {
                prevId: this.item.id,
                prevType: this.item.type,
                depth: this.item.depth,
                title: '',
              }
              await this.CREATE_TITLE({
                mnemo: this.mnemo,
                data: payload,
              })
              this.$store.commit(ENQUEUE_SNACKBAR, {
                color: 'success',
                message: this.$t('project.checklist.task.ChecklistTableTask.createTitleSuccess'),
              })
            } catch (e) {
              this.$store.commit(ENQUEUE_SNACKBAR, {
                color: 'error',
                message: this.$t('project.checklist.task.ChecklistTableTask.createTitleError'),
              })
            }
          },
          loading: this.createTitlePending,
        },
      ]
    },
    isDisplayed () {
      return !this.isChecklistFiltered || (this.isChecklistFiltered && this.isMatchingFilters)
    },
    statusCategory () {
      if (this.item.status) {
        if (this.taskStatuses) {
          for (const category in this.taskStatuses) {
            if (this.taskStatuses[category].includes(this.item.status)) {
              return category
            }
          }
        }
      }
      return null
    },
    isAwaitingDeleteValidation () {
      return this.item.isAwaitingValidation
    },
  },
  methods: {
    ...mapActions('checklist', [CREATE_TASK, CREATE_TITLE]),
  },
}
</script>

<style scoped lang="scss">
  .ChecklistTableTask-container {
    margin-bottom: 10px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      border-left: 8px solid transparent;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  .ChecklistTableTask {
    display: grid;
    grid-template-columns: 10fr 4fr 4fr 4fr 3fr 1fr;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #f5f5f5;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    overflow-wrap: anywhere;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: block;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    & ::v-deep .editor__content .ProseMirror * {
      // We're living inside a cell, so we want to break anywhere to avoid layout break
      overflow-wrap: anywhere;
    }
  }
  .ChecklistTableTask-container--guestNoChat {
    border-bottom: 1px solid #ddd;
  }

  .ChecklistTableTask-chat {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fafafa;
  }

  .sortable-ghost,
  .sortable-chosen,
  .sortable-drag {
    border: 2px dashed var(--v-primary-base) !important;
    .ChecklistTableTask,
    ::v-deep .editor__content  {
      background-color: var(--v-primary-lighten4) !important;
    }
    .ChecklistTableTaskResponsible,
    .ChecklistTableTaskStatus {
      background-color: var(--v-primary-lighten3) !important;
    }
  }

  .marker {
    position: absolute;
    bottom: -17px;
    left: -20px;
    cursor: pointer;
    opacity: 0.5;
    font-size: 26px;
    color: var(--v-primary-base);
    z-index: 1;
  }

  .marker:hover {
    opacity: 1;
  }

  .is-awaiting-delete-validation {
    pointer-events: none;
    background-color: var(--v-grey-lighten5) !important;
    color: var(--v-grey-lighten3) !important;

    * {
      background-color: var(--v-grey-lighten5) !important;
    }

    ::v-deep {
      .v-btn,
      .ChecklistTableTaskAttachedFilesDialog-button {
        color: var(--v-grey-lighten3) !important;
      }
    }
  }
</style>
