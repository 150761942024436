<template>
  <div class="swap-file-selector">
    <SwapFilesSelectorItem :selected-file="selectedFile"
                           :swap-file="swapFile"
                           :is-opened="isOpen"
                           @update-retain-tags="onUpdateRetainsTags"
    >
    <app-button
      v-if="!isOpen"
      size="small"
      type="ghost"
      @click="onToggleSelector"
    >
      {{ $t('common.replace') }}
      <app-icon icon-name="chevron-right" size="small" />
    </app-button>
    <app-button
      v-if="selectedFile"
      type="outlined"
      size="small"
      variant="danger"
      @click="onCancelSwap"
    >
      {{ $t('common.cancel') }}
    </app-button>
    </SwapFilesSelectorItem>
    <transition name="app-fade">
      <SigningChecklistDocumentsSelector v-if="isOpen"
                                        class="mt-4"
                                        files-only
                                        :multiple="false"
                                        :from-closd-title="$t('project.signing-checklist.SwapFilesSelector.swapFileFromClosdTitle')"
                                        :from-local-title="$t('project.signing-checklist.SwapFilesSelector.swapFileFromLocalTitle')"
                                        @select="selectDocuments"
      />
    </transition>
    <div v-if="imanageLoading" class="text-center">
      <p>{{$t('project.signing-checklist.dialogs.SwapFilesDialog.imanageLoading')}}</p>
      <v-progress-circular color="primary" indeterminate size="48" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

import SigningChecklistDocumentsSelector from '@/project/signing-checklist/SigningChecklistDocumentsSelector.vue'
import SwapFilesSelectorItem
  from '@/project/signing-checklist/SwapFilesSelectorItem.vue'
import { UPLOAD_HIDDEN_FILE } from '@/store/modules/documents/action_types'
import {
  ADD_ENVELOPE_DOCUMENTS_TO_UPLOAD_QUEUE,
  REMOVE_ENVELOPE_DOCUMENTS_FROM_UPLOAD_QUEUE,
} from '@/store/modules/signing-checklist/mutation_types'

export default defineComponent({
  name: 'SwapFilesSelector',
  components: {
    SigningChecklistDocumentsSelector,
    SwapFilesSelectorItem,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    swapFile: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      imanageLoading: false,
      isOpen: false,
      selectedFile: null,
      selectedFileIsFromClosd: false,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['swapFiles']),
    swapFileIsSelected () {
      return !!this.selectedFile
    },
    isMultipleSwapFiles () {
      return this.swapFiles.ids.length > 1
    },
  },
  created () {
    if (!this.isMultipleSwapFiles) this.isOpen = true
  },
  methods: {
    ...mapActions('documents', [UPLOAD_HIDDEN_FILE]),
    ...mapMutations('signingChecklist', [
      ADD_ENVELOPE_DOCUMENTS_TO_UPLOAD_QUEUE,
      REMOVE_ENVELOPE_DOCUMENTS_FROM_UPLOAD_QUEUE,
    ]),
    onCancelSwap () {
      this.selectedFile = null
      this.$emit('reset-swap')
    },
    onToggleSelector () {
      this.isOpen = !this.isOpen
    },
    onUpdateRetainsTags (fileData) {
      this.selectedFile = fileData
      this.updateSwapFile()
    },
    async selectDocuments ({ files, fromClosd }) {
      if (files.length === 0) {
        return
      }

      this.selectedFile = files[0]
      this.selectedFileIsFromClosd = fromClosd

      if (!this.selectedFileIsFromClosd && this.selectedFile?.objectToPost?.fileRaw) {
        await this.uploadExternalFile(this.selectedFile)
      }

      this.updateSwapFile()
      this.onToggleSelector()
    },
    updateSwapFile () {
      const swapData = {
        envelopeId: this.swapFile.envelope.id,
        fileId: this.selectedFile?.objectToPost?.fileId,
        retainTags: this.selectedFile?.objectToPost?.retainTags,
      }

      this.$emit('add-swap-file', swapData)
    },
    async uploadExternalFile (externalFile) {
      externalFile.status = 'uploading'
      await this.UPLOAD_HIDDEN_FILE({ file: externalFile, mnemo: this.mnemo })
        .then(() => {
          externalFile.objectToPost.fileId = externalFile.id
          externalFile.objectToPost.title = externalFile.basename
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.swap-file-selector {
  border: 1px solid var(--v-grey-lighten4);
  border-radius: 12px;
  padding: 24px;
}
</style>
