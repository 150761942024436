<template>
  <div>
    <template v-if="loading || Object.keys(documents).length === 0">
      <v-row>
        <v-col class="text-center mt-5">
          <AppLoader/>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row no-gutters>
        <div class="text-subtitle-1">
          <b>{{$t('project.checklist.pane.ChecklistTableTaskPaneDuplicate.duplicateTo')}}</b>
        </div>
      </v-row>
      <v-row no-gutters class="mt-4">
        <AppFildersTree v-model="selectedFolder"
                        :root-folder="documents"
                        open-first-node
                        folders-only
                        style="width: 100%;"
        />
      </v-row>
      <v-row>
        <v-col>
          <AppButton color="primary"
                     outlined
                     block
                     :disabled="duplicateLoading"
                     @click="resetAndClose"
          >
            {{$t('common.cancel')}}
          </AppButton>
        </v-col>
        <v-col>
          <AppButton color="primary"
                     block
                     :disabled="isDuplicateDisabled"
                     :loading="duplicateLoading"
                     @click="duplicate"
          >
          {{$t('project.checklist.pane.ChecklistTableTaskPaneDuplicate.duplicate')}}
          </AppButton>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import { GET_DOCUMENTS_BY_ID, DUPLICATE_FILDERS } from '@/store/modules/documents/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

import AppLoader from '../../../common/AppLoader'
import AppFildersTree from '../../../common/AppVuetifyFildersTree'
import AppButton from '../../../common/buttons/AppButton'
import { RESET_SELECTED_ATTACHED_FILE, SET_TABLE_TASK_PANE } from '../../../store/modules/checklist/mutation_types'

export default {
  name: 'ChecklistTableTaskPaneDuplicate',
  components: { AppLoader, AppFildersTree, AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      selectedFolder: [],
    }
  },
  computed: {
    ...mapState('documents', ['documents', 'duplicateLoading', 'loading']),
    ...mapState('checklist', ['tableTaskPane']),
    isDuplicateDisabled () {
      return this.tableTaskPane.documents.length === 0 || !this.isSelectedFolderValid || this.duplicateLoading
    },
    isSelectedFolderValid () {
      return this.selectedFolder.length !== 0 && this.selectedFolder[0]?.specialFolder !== 'todo'
    },
    paneIsOpen () {
      return this.tableTaskPane.isOpen
    },
  },
  watch: {
    paneIsOpen (val) {
      if (!val) {
        this.resetAndClose()
      }
    },
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('documents', [GET_DOCUMENTS_BY_ID, DUPLICATE_FILDERS]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async loadData () {
      try {
        await this.GET_DOCUMENTS_BY_ID({
          mnemo: this.mnemo,
          id: 0,
        })
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.checklist.pane.ChecklistTableTaskPaneDuplicate.getDocumentsError'))
      }
    },
    async duplicate () {
      try {
        await this.DUPLICATE_FILDERS({
          documents: this.tableTaskPane.documents,
          targetFolderId: this.selectedFolder[0].id,
        })
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.checklist.pane.ChecklistTableTaskPaneDuplicate.duplicateSuccess'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.checklist.pane.ChecklistTableTaskPaneDuplicate.duplicateError'))
      } finally {
        this.resetAndClose()
      }
    },
    resetAndClose () {
      this.selectedFolder = []
      this.$store.commit(`checklist/${RESET_SELECTED_ATTACHED_FILE}`)
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: false,
        tab: '',
        documents: [],
        parent: null,
      })
    },
  },
}
</script>
