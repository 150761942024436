<template>
  <div class="text-center my-12">
    <app-text variant="medium-bold" class="mb-3">
      {{ $t('project.signing-checklist.prepare-signature.PrepareSignaturePagesLoader.loadingPagesWaitingText') }}
    </app-text>
    <v-progress-circular :value="renderProgress" rotate="270" color="primary" :size="80" :width="5">
      <app-text as="span" variant="medium-bold">{{renderProgress}}%</app-text>
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'PrepareSignaturePagesLoader',
  props: {
    renderProgress: {
      type: Number,
      required: true,
    },
  },
}
</script>
