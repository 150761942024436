<template>
  <AppDialog :is-open="isDialogOpened"
             :cancel-text="$t('project.signing-checklist.dialogs.SigningChecklistAddBeforeLeaveDialog.cancelButtonLabel')"
             :ok-text="$t('project.signing-checklist.dialogs.SigningChecklistAddBeforeLeaveDialog.okButtonLabel')"
             size="l"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>{{ $t('project.signing-checklist.dialogs.SigningChecklistAddBeforeLeaveDialog.dialogTitle') }}</template>

    <template #body>
      <p>{{ $t("project.signing-checklist.dialogs.SigningChecklistAddBeforeLeaveDialog.dialogBody") }}</p>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'

export default {
  name: 'SigningChecklistAddBeforeLeaveDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  methods: {
    confirmClick () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
