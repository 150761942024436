<template>
 <AppDialog :is-open="isDialogOpen"
            :ok-text="okText"
            :ok-loading="isPending"
            @cancel="prepareCloseRenameDocument"
            @ok="prepareRenameDocument"
 >
   <template #title>
     <template v-if="homeLayout">
      <div class="font-weight-bold">
        {{ $t('project.documents.dialogs.DocumentsRenameDialog.renameProjectsFolder', { name: projectsFolderToRename.name }) }}
      </div>
     </template>
     <template v-else-if="documentToRename.type === 'folder'">
       {{ $t('project.documents.dialogs.DocumentsRenameDialog.renameFolder') }}
     </template>
     <template v-else>
       {{ $t('project.documents.dialogs.DocumentsRenameDialog.renameFile') }}
     </template>
   </template>
   <template #body>
     <form @submit.prevent="prepareRenameDocument"
     >
       <AppTextField v-model.trim="$v.newName.$model"
                     :label="$t(`project.documents.dialogs.DocumentsRenameDialog.${homeLayout ? 'folderName' : 'lblRenameDocument'}`)"
                     :counter="nameMaxLength"
                     :error-messages="newNameErrors"
                     :maxlength="nameMaxLength"
                     autofocus
       />
     </form>
   </template>
 </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapMutations, mapActions } from 'vuex'

import AppTextField from '@/common/AppTextField'
import AppDialog from '@/common/dialogs/AppDialog'
import { DIRECTORY_NAME_MAX_LENGTH } from '@/common/utils/directory'
import { FILE_NAME_MAX_LENGTH } from '@/common/utils/files'
import { LOAD_FOLDERS_TREE } from '@/store/modules/documents/action_types'
import { CLOSE_DOCUMENT_RENAMING_DIALOG, RENAME_DOCUMENT } from '@/store/modules/documents-rename/action_types'
import { LOAD_PROJECTS_FOLDERS_TREE, GET_PROJECTS_FOLDER_BY_ID } from '@/store/modules/projects-folders/action_types'
import { RENAME_PROJECTS_FOLDER } from '@/store/modules/projects-folders-rename/action_types'
import {
  SET_PROJECTS_FOLDER_RENAME_DIALOG_IS_OPEN,
  SET_PROJECTS_FOLDER_TO_RENAME,
} from '@/store/modules/projects-folders-rename/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'DocumentsRenameDialog',
  components: { AppDialog, AppTextField },
  mixins: [validationMixin],
  validations: {
    newName: {
      required,
      maxLength: (value, vm) => value.length <= vm.nameMaxLength,
    },
  },
  data () {
    return {
      newName: '',
    }
  },
  computed: {
    ...mapState(['homeLayout']),
    ...mapState('documentsRename', ['loading', 'isOpen', 'documentToRename']),
    ...mapState('projectsFolders', ['currentProjectsFolder']),
    ...mapState('projectsFoldersRename', ['projectsFolderRenamePending', 'projectsFolderRenameDialogIsOpen', 'projectsFolderToRename']),
    isPending () {
      return this.homeLayout ? this.projectsFolderRenamePending : this.loading
    },
    isDialogOpen () {
      return this.homeLayout ? this.projectsFolderRenameDialogIsOpen : this.isOpen
    },
    okText () {
      return this.homeLayout ? this.$t('common.register') : this.$t('project.documents.dialogs.DocumentsRenameDialog.rename')
    },
    newNameErrors () {
      const errors = []
      if (!this.$v.newName.$dirty) return errors
      !this.$v.newName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.documents.dialogs.DocumentsRenameDialog.lblRenameDocument') }))
      !this.$v.newName.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.documents.dialogs.DocumentsRenameDialog.lblRenameDocument'), maxLength: this.nameMaxLength }))
      return errors
    },
    nameMaxLength () {
      if (this.homeLayout) {
        return DIRECTORY_NAME_MAX_LENGTH
      }
      return this.documentToRename.type === 'folder' ? DIRECTORY_NAME_MAX_LENGTH : FILE_NAME_MAX_LENGTH
    },
  },
  mounted () {
    if (this.homeLayout) {
      this.newName = this.projectsFolderToRename.name
    } else if (this.documentToRename.type === 'folder') {
      this.newName = this.documentToRename.name
    } else if (this.documentToRename.type === 'file') {
      this.newName = this.documentToRename.basename
    }
  },
  methods: {
    ...mapActions('documents', [LOAD_FOLDERS_TREE]),
    ...mapActions('documentsRename', [RENAME_DOCUMENT, CLOSE_DOCUMENT_RENAMING_DIALOG]),
    ...mapActions('projectsFolders', [LOAD_PROJECTS_FOLDERS_TREE, GET_PROJECTS_FOLDER_BY_ID]),
    ...mapMutations('projectsFoldersRename', [SET_PROJECTS_FOLDER_RENAME_DIALOG_IS_OPEN, SET_PROJECTS_FOLDER_TO_RENAME]),
    ...mapActions('projectsFoldersRename', [RENAME_PROJECTS_FOLDER]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async prepareRenameDocument () {
      if (this.isPending) {
        return
      }

      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.homeLayout) {
          try {
            await this.RENAME_PROJECTS_FOLDER(this.newName)

            if (this.currentProjectsFolder.id) {
              try {
                this.GET_PROJECTS_FOLDER_BY_ID({
                  folderId: this.currentProjectsFolder.id,
                })
              } catch (error) {
                this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsNewFolderDialog.getFolderError', { name: this.currentProjectsFolder.name }))
              }
            } else {
              try {
                this.LOAD_PROJECTS_FOLDERS_TREE()
              } catch (error) {
                this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.ProjectsHeader.getProjectsFoldersTreeError'))
              }
            }

            this.SET_PROJECTS_FOLDER_RENAME_DIALOG_IS_OPEN(false)
          } catch (error) {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsRenameDialog.renameFolderError'))
          }
        } else {
          const needFoldersTreeRefreshAfterRenaming = this.documentToRename.type === 'folder'

          await this.RENAME_DOCUMENT(this.newName)
          this.newName = ''

          if (needFoldersTreeRefreshAfterRenaming) {
            await this.LOAD_FOLDERS_TREE()
          }
        }
      }
    },
    prepareCloseRenameDocument () {
      if (this.homeLayout) {
        this.SET_PROJECTS_FOLDER_RENAME_DIALOG_IS_OPEN(false)
        this.SET_PROJECTS_FOLDER_TO_RENAME({})
      } else {
        this.CLOSE_DOCUMENT_RENAMING_DIALOG()
      }
      this.newName = ''
    },
  },
}
</script>
