<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h1">{{ $t("project.views.SigningChecklist.title") }}</h1>
        <p class="mt-3 mb-0 body-1 accent--text text--lighten-2"
        >
          {{ $t("project.views.SigningChecklist.subtitle") }}
          <AppInfoTooltip>
            <div v-html="$t('project.views.SigningChecklist.alertContent')"></div>
          </AppInfoTooltip>
        </p>
      </v-col>
    </v-row>
    <div v-if="signingChecklistPending">
      <v-skeleton-loader class="mt-4" type="table-thead, table-heading, table-tbody">
      </v-skeleton-loader>
    </div>
    <div v-if="hasAtLeastOneSigningChecklist">
      <vue-affix-box v-if="isPm"
                     :offset-top="headerTop"
      >
        <v-row v-if="$vuetify.breakpoint.mdAndUp"
               class="signingChecklist-sticky justify-space-between"
        >

          <v-col cols="12" md="auto">
            <v-row class="align-center">
                <v-col cols="auto">
                  <SearchTextField v-model="search" :tooltipText="$t('project.views.SigningChecklist.searchPlaceholder')" />
                </v-col>
                <v-col cols="auto">
                  <AppButton color="white"
                            @click="onOpenFiltersPane"
                  >
                    <font-awesome-icon :icon="['far', 'sliders']" class="mr-2"></font-awesome-icon>
                    {{ $t('common.filters') }}
                  </AppButton>
                </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="auto">
            <v-row align="center">
              <v-col v-if="!currentRoom.inClosing || hasPdfSignature">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <AppButton color="white" v-bind="attrs" v-on="on">
                      {{ $t("project.views.SigningChecklist.optionsHeader") }}
                      <v-icon right>fal fa-chevron-down</v-icon>
                    </AppButton>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item v-if="!currentRoom.inClosing" :to="{ name: SIGNING_CHECKLIST_ORDER_ROUTE_NAME }">
                        <v-list-item-title>{{
                            $t("project.views.SigningChecklist.signatureOrderOption")
                          }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="hasPdfSignature" @click="openDialog = true">
                        <v-list-item-title>
                          {{ $t("project.views.SigningChecklist.signaturePages") }}
                        </v-list-item-title>
                      </v-list-item>
                      <AppDownloadFile v-if="!currentRoom.inClosing" :link="exportToPDFLink">
                        <template #default="{submitForm}">
                          <v-list-item @click="submitForm">
                            <v-list-item-title>{{ $t("project.views.SigningChecklist.exportToPDF") }}</v-list-item-title>
                          </v-list-item>
                        </template>
                      </AppDownloadFile>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col
                v-if="isPm && !currentRoom.inClosing"
                class="py-1"
              >
                <AppMenu open-on-hover
                         offset-y
                         :menu="signingMenuOptions"
                         :disabled="!hasAtLeastOneDraftEnvelope"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-tooltip top
                               :disabled="hasAtLeastOneDraftEnvelope"
                    >
                      <template #activator="{attrs: attrsTooltip, on: onTooltip}">
                        <AppButton :color="hasAtLeastOneDraftEnvelope ? 'primary' : 'grey lighten-2'"
                                   outlined
                                   :disabled="currentRoom.inClosing"
                                   :dark="!currentRoom.inClosing"
                                   v-bind="{...attrs, ...attrsTooltip}"
                                   v-on="{...on, ...onTooltip}"
                        >
                          <font-awesome-icon
                            class="mr-4"
                            :icon="['fas', 'play-circle']"
                            size="lg"
                          ></font-awesome-icon>
                          <span class="mr-4">{{ $t("project.views.SigningChecklist.startClosing") }}</span>
                          <font-awesome-icon
                            :icon="['fal', 'chevron-down']"
                            size="lg"
                          ></font-awesome-icon>
                        </AppButton>
                      </template>
                      <span>{{$t('project.views.SigningChecklist.startSigningDisabledReason')}}</span>
                    </v-tooltip>
                  </template>
                </AppMenu>
              </v-col>
              <v-col class="py-1">
                <AppMenu open-on-hover offset-y :menu="addMenuOptions">
                  <template v-slot:activator="{ on, attrs }">
                    <AppButton
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <font-awesome-icon
                        class="mr-4"
                        :icon="['far', 'plus']"
                        size="lg"
                      ></font-awesome-icon>
                      <span class="mr-4">{{ $t("project.views.SigningChecklist.newDocumentShort") }}</span>
                      <font-awesome-icon
                        :icon="['fal', 'chevron-down']"
                        size="lg"
                      ></font-awesome-icon>
                    </AppButton>
                  </template>
                </AppMenu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.smAndDown" class="signingChecklist-sticky">
          <v-col cols="6">
            <AppMenu open-on-hover offset-y :menu="addMenuOptions">
              <template v-slot:activator="{ on, attrs }">
                <AppButton
                  color="primary"
                  dark
                  v-bind="attrs"
                  class="mr-4"
                  v-on="on"
                >
                  <font-awesome-icon
                    class="mr-4"
                    :icon="['far', 'plus']"
                    size="lg"
                  ></font-awesome-icon>
                  <span class="mr-4">{{ $t("project.views.SigningChecklist.newDocumentShort") }}</span>
                  <font-awesome-icon
                    :icon="['fal', 'chevron-down']"
                    size="lg"
                  ></font-awesome-icon>
                </AppButton>
              </template>
            </AppMenu>
          </v-col>
          <v-col v-if="isPm && !currentRoom.inClosing && !currentRoom.closingScheduledAt" cols="6">
            <AppMenu open-on-hover
                     offset-y
                     :disabled="!hasAtLeastOneDraftEnvelope"
                     :menu="signingMenuOptions"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip top
                           :disabled="hasAtLeastOneDraftEnvelope"
                >
                  <template #activator="{on: onTooltip}">
                    <AppButton :color="hasAtLeastOneDraftEnvelope ? 'secondary' : 'grey lighten-2'"
                               dark
                               v-bind="attrs"
                               v-on="{...on, ...onTooltip}"
                    >
                      <font-awesome-icon
                        class="mr-4"
                        :icon="['far', 'play']"
                        size="lg"
                      ></font-awesome-icon>
                      <span class="mr-4">{{ $t("project.views.SigningChecklist.startClosingShort") }}</span>
                      <font-awesome-icon
                        :icon="['fal', 'chevron-down']"
                        size="lg"
                      ></font-awesome-icon>
                    </AppButton>
                  </template>
                  <span>{{ $t('project.views.SigningChecklist.startSigningDisabledReason') }}</span>
                </v-tooltip>
              </template>
            </AppMenu>
          </v-col>
          <v-col>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <AppButton color="white" v-bind="attrs" v-on="on">
                  {{ $t("project.views.SigningChecklist.optionsHeader") }}
                  <v-icon right>fal fa-chevron-down</v-icon>
                </AppButton>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item v-if="!currentRoom.inClosing"
                               :to="{ name: SIGNING_CHECKLIST_ORDER_ROUTE_NAME }"
                  >
                    <v-list-item-title>{{
                        $t("project.views.SigningChecklist.signatureOrderOption")
                      }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="hasPdfSignature" @click="openDialog = true">
                    <v-list-item-title>
                      {{ $t("project.views.SigningChecklist.signaturePages") }}
                    </v-list-item-title>
                    </v-list-item>
                  <AppDownloadFile :link="exportToPDFLink">
                    <template #default="{submitForm}">
                      <v-list-item @click="submitForm">
                        <v-list-item-title>{{ $t("project.views.SigningChecklist.exportToPDF") }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </AppDownloadFile>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-col v-if="isPm && currentRoom.inClosing" cols="6">
            <AppButton
              color="grey darken-3"
              dark
              @click="cancelClosingModal = true"
            >
              <font-awesome-icon
                class="mr-4"
                :icon="['far', 'ban']"
                size="lg"
              ></font-awesome-icon>
              {{ $t("project.views.SigningChecklist.stopSigningShort") }}
            </AppButton>
          </v-col>
        </v-row>
        <v-row v-if="selectedSteps.length !== 0" class="signingChecklist-sticky mt-n2">
          <v-col>
            <v-row
              no-gutters
              class="selected-items-bar my-4"
            >
              <div :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
                <span class="mr-2 selected-items-count">
                {{ $tc("common.selectedItems", selectedSteps.length) }}
              </span>
                <AppButton
                  class="mr-10"
                  text
                  color="primary"
                  @click="SET_SELECTED_STEPS([])"
                >
                  {{ $t("common.cancelSelection") }}
                </AppButton>
              </div>
              <MultiSelectBarButton
                v-if="canSendMultiple"
                @click="showSendSignStepMultiple"
              >
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fas', 'file-signature']"
                ></font-awesome-icon>
                {{ $t("project.views.SigningChecklist.selection.send") }}
              </MultiSelectBarButton>
              <MultiSelectBarButton
                v-if="canValidateMultiple"
                @click="showSendSignStepMultiple"
              >
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fas', 'file-signature']"
                ></font-awesome-icon>
                {{ $t("project.views.SigningChecklist.selection.validateThenSend") }}
              </MultiSelectBarButton>
              <MultiSelectBarButton @click="goToMultipleSettings">
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fas', 'gear']"
                ></font-awesome-icon>
                {{ $t("project.views.SigningChecklist.selection.settings") }}
              </MultiSelectBarButton>
              <MultiSelectBarButton
              v-if="canSwapFileMultiple"
              @click="onMultipleSwap">
                <app-icon class="mr-2" icon-name="exchange"></app-icon>
                {{ $t("project.views.SigningChecklist.selection.swapFile") }}
              </MultiSelectBarButton>
              <MultiSelectBarButton
                v-if="canSignTagsMultiple"
                @click="goToMultipleSignatureTags"
              >
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fas', 'bullseye-arrow']"
                ></font-awesome-icon>
                {{ $t("project.views.SigningChecklist.selection.signatureTags") }}
              </MultiSelectBarButton>
              <MultiSelectBarButton
                v-if="canCancelMultiple"
                @click="showCancelMultiple"
              >
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fas', 'stop-circle']"
                ></font-awesome-icon>
                {{ $t("project.views.SigningChecklist.selection.cancel") }}
              </MultiSelectBarButton>
              <MultiSelectBarButton
                v-if="canDeleteMultiple"
                @click="showDeleteMultiple"
              >
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fas', 'trash']"
                ></font-awesome-icon>
                {{ $t("project.views.SigningChecklist.selection.delete") }}
              </MultiSelectBarButton>
              <MultiSelectBarButton
                v-if="canResetToDraftMultiple"
                @click="showVoidedToDraftStepMultiple"
              >
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fas', 'redo-alt']"
                ></font-awesome-icon>
                {{ $t("project.views.SigningChecklist.selection.voidedToDraft") }}
              </MultiSelectBarButton>
            </v-row>
          </v-col>
        </v-row>
      </vue-affix-box>
      <SigningChecklistFiltersActive />
      <v-row class="SigningChecklistTable-container">
        <v-col>
          <AppCard :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
            <v-card-text :class="{'px-4': $vuetify.breakpoint.smAndDown, 'px-6': $vuetify.breakpoint.md, 'px-8': $vuetify.breakpoint.lgAndUp}">
              <SigningChecklistSigningInfo v-if="isSigningChecklistInfoVisible"
                                           :mnemo="mnemo"
                                           :isSendingDocument="isSendingDocument"
                                           @editSigningDates="onEditSigningDates"
              />
              <v-row v-if="isPm" class="mb-6" justify="space-between" align="start" no-gutters>
                <!-- We use allowEnvelopeGrouping as key to recreate the component if it gets toggled -->
                <!-- isSigningChecklistFiltered is a v-show because we don't want to recreate the component if it changes -->
                <SigningChecklistEnvelopeStats
                  v-show="!isSigningChecklistFiltered"
                  :key="currentRoom.allowEnvelopeGrouping"
                />
                <AppTooltip v-if="isEnvelopeGroupingVisible"
                            :disabled="roomProvidersPending || !currentRoom.inClosing"
                            max-width="400"
                            top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-switch
                        :input-value="currentRoom.allowEnvelopeGrouping"
                        :disabled="currentRoom.inClosing"
                        class="mt-0 pt-0"
                        readonly
                        :loading="patchRoomPending"
                        :label="$t('project.views.SigningChecklist.envelopeGroupingLabel')"
                        hide-details
                        @click="(!currentRoom.inClosing && !patchRoomPending) && onChangeEnvelopeGrouping()"
                      />
                    </div>
                  </template>
                  <span v-html="$t('project.views.SigningChecklist.envelopeGroupingInClosingDisabled')"></span>
                </AppTooltip>
              </v-row>
              <!-- This divider is also used to divide stats from the table -->
              <!-- so it needs the stats to be there -->
              <!-- If we don't add a z-index here, we will be covered by the table header -->
              <v-divider
                v-if="isSigningChecklistEnvelopeStatsVisible"
                v-show="!isSigningChecklistFiltered"
                class="position-relative mb-4"
                style="z-index:3">
              </v-divider>
              <SigningChecklistTable :mnemo="mnemo"/>
            </v-card-text>
          </AppCard>
        </v-col>
      </v-row>
    <SigningChecklistStartSigningDialog v-if="startSigningDialogIsOpen"
                                        :mnemo="mnemo"
                                        :action="startSigningDialogAction"
                                        @close="onStartSigningDialogClose"
    />
    </div>
    <v-layout
      v-else-if="!signingChecklistPending"
      style="margin-top: 100px;"
      align-center
      justify-center
    >
      <div>
        <img
          class="d-block mx-auto mb-8"
          src="/img/signing-checklist/seance-sign.svg"
          width="325"
        />
        <p class="mx-auto text-center noDataText" style="max-width: 250px">
          {{ $t("project.views.SigningChecklist.emptyText") }}
        </p>
        <v-row v-if="isPm"
               class="my-2 text-center mx-auto"
        >
          <v-col>
            <AppButton class="mx-1"
                       :to="{ name: SIGNING_CHECKLIST_ADD_ROUTE_NAME }"
                       color="primary"
            >
              {{ $t("project.views.SigningChecklist.newDocument") }}
            </AppButton>

            <AppButton v-if="isSubPayingUser"
                       class="mx-1"
                       color="primary"
                       @click="onUseDocumentTemplate"
            >
              {{ $t("project.views.SigningChecklist.useDocumentTemplate") }}
            </AppButton>
          </v-col>
        </v-row>
      </div>
    </v-layout>

    <QualifiedDSSignatureWarningDialog v-if="qualifiedDSWarningDialog.isVisible"
                                       @close="closeQualifiedDSWarningDialog"
                                       @confirm="qualifiedDSWarningDialog.confirmCallback?.()"
    />
    <PdfSigningPmDialogButton :mnemo="mnemo" :openDialog="openDialog" v-on:setOpenDialog="setOpenDialog"/>
    <SigningChecklistFiltersPane :mnemo="mnemo"/>
    <EnvelopeTemplatesDialog v-if="isDocumentTemplatesDialogOpen"
                             @close="isDocumentTemplatesDialogOpen = false"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { debounce } from 'lodash-es'
import VueAffixBox from 'vue-affix-box'
import { createNamespacedHelpers, mapState, mapGetters } from 'vuex'

import AppInfoTooltip from '@/common/AppInfoTooltip'
import SearchTextField from '@/common/SearchTextField'
import SigningChecklistStartSigningDialog from '@/project/signing-checklist/dialogs/SigningChecklistStartSigningDialog'
import EnvelopeTemplatesDialog from '@/project/signing-checklist/envelope-templates/dialogs/EnvelopeTemplatesDialog.vue'
import SigningChecklistSigningInfo from '@/project/signing-checklist/list/SigningChecklistSigningInfo'
import { START_SIGNING_ACTIONS } from '@/project/signing-checklist/list/startSigningActions'
import SigningChecklistFiltersActive from '@/project/signing-checklist/SigningChecklistFiltersActive'
import SigningChecklistFiltersPane from '@/project/signing-checklist/SigningChecklistFiltersPane'
import {
  ROOM_DOCUMENTS_ROUTE_NAME,
  ROOM_ROUTE_TYPES,
  SIGNING_CHECKLIST_ADD_ROUTE_NAME,
  SIGNING_CHECKLIST_ORDER_ROUTE_NAME,
} from '@/router'
import { GET_ROOM_PROVIDERS, PATCH_ROOM } from '@/store/modules/room/action_types'
import {
  CANCEL_SCHEDULED_CLOSING,
  CREATE_TITLE,
  DELETE_SEARCH_RESULTS,
  GET_SEARCH_RESULTS,
  GET_SIGNING_CHECKLIST,
  REFRESH_CLOSING_STATUS,
  REFRESH_SIGNING_CHECKLIST,
  REFRESH_STATUS,
  REFRESH_STEP,
  VALIDATE_THEN_SEND_NOW,
  VALIDATE_THEN_SEND_NOW_MULTIPLE,
} from '@/store/modules/signing-checklist/action_types'
import {
  SET_CURRENT_TITLE_EDIT,
  SET_IS_SENDING_DOCUMENT,
  SET_SIGNING_CHECKLIST,
  SET_PARENT_CHILDREN,
  SET_SELECTED_STEPS,
  SET_DELETE_STEP_MODAL,
  SET_CANCEL_STEP_MODAL,
  SET_SEND_SIGN_STEP_MODAL,
  SET_VOIDED_TO_DRAFT_STEP_MODAL,
  ADD_REFRESH_STEP_PENDING,
  SET_REFRESH_STEP_PENDING_IDS,
  SET_FILTERS_PANE_IS_OPEN,
  SET_SWAP_FILES,
} from '@/store/modules/signing-checklist/mutation_types'

import AppCard from '../../common/AppCard.vue'
import AppDownloadFile from '../../common/AppDownloadFile'
import AppMenu from '../../common/AppMenu.vue'
import AppTooltip from '../../common/AppTooltip.vue'
import AppButton from '../../common/buttons/AppButton.vue'
import MultiSelectBarButton from '../../common/buttons/MultiSelectBarButton.vue'
import {
  canShowCancelStep,
  canShowDeleteStep,
  canShowResetToDraftStep,
  canShowSendStep,
  canShowSignTagsStep,
  canShowValidateStep,
  canSwapFile,
  findStepParent,
  UPLOAD_SESSION_LIMIT,
} from '../../common/utils/signingChecklist'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'
import QualifiedDSSignatureWarningDialog from '../signing-checklist/dialogs/QualifiedDSSignatureWarningDialog'
import SigningChecklistTable from '../signing-checklist/list/SigningChecklistTable'
import PdfSigningPmDialogButton from '../signing-checklist/pdf-signing/PdfSigningPmDialogButton'
import SigningChecklistEnvelopeStats from '../signing-checklist/SigningChecklistEnvelopeStats.vue'

const { mapActions, mapMutations, mapState: mapSigningCheklistState } = createNamespacedHelpers('signingChecklist')
const { mapActions: mapRoomActions, mapState: mapRoomState } = createNamespacedHelpers('room')
const REFRESH_LOOP_POLLING_DELAY = 30000
const REFRESH_STATUS_POLLING_DELAY = 5000
const REFRESH_CLOSING_STATUS_POLLING_DELAY = 5000

export default {
  name: 'SigningChecklist',
  components: {
    EnvelopeTemplatesDialog,
    AppButton,
    AppCard,
    AppDownloadFile,
    AppInfoTooltip,
    AppMenu,
    AppTooltip,
    MultiSelectBarButton,
    PdfSigningPmDialogButton,
    QualifiedDSSignatureWarningDialog,
    SearchTextField,
    SigningChecklistFiltersActive,
    SigningChecklistFiltersPane,
    SigningChecklistSigningInfo,
    SigningChecklistStartSigningDialog,
    SigningChecklistTable,
    VueAffixBox,
    SigningChecklistEnvelopeStats,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  provide () {
    return {
      onChangeEnvelopeGrouping: this.onChangeEnvelopeGrouping,
    }
  },
  metaInfo () {
    return {
      title: this.$t('project.views.SigningChecklist.title'),
    }
  },
  errorCaptured (error) {
    // Ignore it.
    if (error.message === 'ResizeObserver.observe: Argument 1 is not an object.') {
      return false
    }
  },
  data () {
    return {
      SIGNING_CHECKLIST_ADD_ROUTE_NAME,
      SIGNING_CHECKLIST_ORDER_ROUTE_NAME,
      openDialog: false,
      qualifiedDSWarningDialog: {
        isVisible: false,
        confirmCallback: null,
      },
      refreshClosingStatusLoopId: 0,
      refreshLoopId: 0,
      refreshStatusLoopId: 0,
      searchQuery: '',
      skipRecap: false,
      startSigningDialogAction: START_SIGNING_ACTIONS.NOW,
      startSigningDialogIsOpen: false,
      isDocumentTemplatesDialogOpen: false,
    }
  },
  computed: {
    ...mapSigningCheklistState([
      'signingChecklist',
      'signingChecklistPending',
      'createTitlePending',
      'cancelScheduledClosingPending',
      'cancelClosingPending',
      'startScheduledClosingPending',
      'selectedSteps',
      'refreshStepPendingIds',
      'currentTitleEdit',
      'qualifiedDSWarningIsMuted',
      'isSendingDocument',
    ]),
    ...mapState('user', ['profile']),
    ...mapRoomState(['currentRoom', 'patchRoomPending', 'roomProvidersPending']),
    ...mapGetters('signingChecklist', ['flatSigningChecklist', 'hasActiveFilter', 'isSigningChecklistFiltered']),
    ...mapGetters('user', ['isSubPayingUser']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
    isEnvelopeGroupingVisible () {
      return process.env.VUE_APP_ENABLE_ENVELOPE_GROUPING &&
        (this.currentRoom.canAllowEnvelopeGrouping === true ||
          this.currentRoom.allowEnvelopeGrouping === true
        ) && !this.isSigningChecklistFiltered
    },
    hasAtLeastOneSigningChecklist () {
      return this.signingChecklist && this.signingChecklist.length !== 0
    },
    hasAtLeastOneDraftEnvelope () {
      return this.flatSigningChecklist.some(item => item.type === 'step' && item.envelope?.status === 'draft')
    },
    hasPdfSignature () {
      return this.flatSigningChecklist.some(item => item.type === 'step' && item.envelope?.provider === 'pdf')
    },
    signingMenuOptions () {
      const menu = []
      if (!this.currentRoom.inClosing) {
        menu.push(
          {
            title: this.$t('project.views.SigningChecklist.startClosingNow'),
            action: () => this.startSigning(START_SIGNING_ACTIONS.NOW),
            loading: this.startClosingPending,
          },
        )
      }
      if (!this.currentRoom.closingScheduledAt) {
        menu.push({
          title: this.$t('project.views.SigningChecklist.scheduleClosing'),
          action: () => this.startSigning(START_SIGNING_ACTIONS.SCHEDULED),
          loading: this.startScheduledClosingPending,
        })
      }
      return menu
    },
    addMenuOptions () {
      const options = [
        {
          title: this.$t('project.views.SigningChecklist.newDocument'),
          action: () => {
            this.$router.push({ name: SIGNING_CHECKLIST_ADD_ROUTE_NAME })
          },
        },
        {
          title: this.$t('project.views.SigningChecklist.newTitle'),
          action: async () => {
            const payload = {
              prevId: -1,
              depth: 0,
              title: '',
            }
            const newTitle = await this.CREATE_TITLE({
              mnemo: this.mnemo,
              data: payload,
              parent: this.signingChecklist,
            })
            await this.REFRESH_SIGNING_CHECKLIST({
              mnemo: this.mnemo,
            })
            this.SET_CURRENT_TITLE_EDIT(newTitle)
          },
          loading: this.createTitlePending,
        },
      ]

      if (this.isSubPayingUser) {
        options.push({
          title: this.$t('project.views.SigningChecklist.useDocumentTemplate'),
          action: () => this.onUseDocumentTemplate(),
        })
      }

      return options
    },
    canSendMultiple () {
      return this.selectedSteps.every(
        (step) => canShowSendStep(step) || canShowValidateStep(step),
      ) && !this.canValidateMultiple
    },
    canValidateMultiple () {
      return this.selectedSteps.every(
        (step) => canShowValidateStep(step) && !step.envelope.missingToSend,
      )
    },
    canSignTagsMultiple () {
      return this.selectedSteps.every(canShowSignTagsStep)
    },
    canResetToDraftMultiple () {
      return this.selectedSteps.every(canShowResetToDraftStep)
    },
    canCancelMultiple () {
      return this.selectedSteps.every(canShowCancelStep)
    },
    canDeleteMultiple () {
      return this.selectedSteps.every(canShowDeleteStep)
    },
    canSwapFileMultiple () {
      return this.selectedSteps.every(canSwapFile) && this.selectedSteps.length <= UPLOAD_SESSION_LIMIT
    },
    headerTop () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 64
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 76
      }
      return 72
    },
    exportToPDFLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/signing-checklist-pdf`
    },
    atLeastOneDraftDocumentRequiresQualifiedSignature () {
      return this.flatSigningChecklist.some(item =>
        item.type === 'step' &&
        item.envelope?.signatureType === 'qualified' &&
        item.envelope?.status === 'draft',
      )
    },
    isSigningChecklistInfoVisible () {
      return this.currentRoom.inClosing || this.currentRoom.closingScheduledAt
    },
    isSigningChecklistEnvelopeStatsVisible () {
      return this.isPm
    },
    search: {
      get () {
        return this.searchQuery
      },
      set: debounce(function (val) {
        this.searchQuery = val
        this.searchChecklist()
      }, 1000),
    },
  },
  watch: {
    flatSigningChecklist (newValue) {
      this.hasSendingStep(newValue)
    },
  },
  async created () {
    if (this.currentRoom.isDataroom) {
      this.$router.replace({ name: ROOM_DOCUMENTS_ROUTE_NAME, params: { roomType: ROOM_ROUTE_TYPES.DATAROOM } })
    }
    if (this.isPm && !this.roomProvidersPending && !this.currentRoom.providers) {
      this.GET_ROOM_PROVIDERS(this.mnemo)
    }
    await this.GET_SIGNING_CHECKLIST({ mnemo: this.mnemo })
    this.refreshLoopId = setTimeout(() => { this.refreshLoop() }, REFRESH_LOOP_POLLING_DELAY)
    this.refreshStatusLoopId = setTimeout(() => { this.refreshStatusLoop() }, REFRESH_STATUS_POLLING_DELAY)
    this.REFRESH_CLOSING_STATUS({
      mnemo: this.mnemo,
    })
    if (this.isPm) {
      this.refreshClosingStatusLoopId =
        setTimeout(() => { this.refreshClosingStatusLoop() }, REFRESH_CLOSING_STATUS_POLLING_DELAY)
    }
    if (this.$route.params.openDocumentTemplatesDialog) { this.isDocumentTemplatesDialogOpen = true }
  },
  beforeDestroy () {
    // Clear timeouts first. We want to avoid getting stuck with a zombie timer.
    clearTimeout(this.refreshLoopId)
    clearTimeout(this.refreshStatusLoopId)
    clearTimeout(this.refreshClosingStatusLoopId)
    // Set to a recognizable value so that we know if we should stop refreshing if already fired
    this.refreshLoopId = -1
    this.refreshStatusLoopId = -1
    this.refreshClosingStatusLoopId = -1
    // Make it empty again
    this.SET_SIGNING_CHECKLIST([])
    this.SET_SELECTED_STEPS([])
    this.DELETE_SEARCH_RESULTS()
  },
  methods: {
    ...mapMutations([SET_FILTERS_PANE_IS_OPEN, SET_SIGNING_CHECKLIST, SET_CURRENT_TITLE_EDIT, SET_PARENT_CHILDREN, SET_SELECTED_STEPS, SET_CANCEL_STEP_MODAL, SET_DELETE_STEP_MODAL, SET_SEND_SIGN_STEP_MODAL, SET_VOIDED_TO_DRAFT_STEP_MODAL, ADD_REFRESH_STEP_PENDING, SET_REFRESH_STEP_PENDING_IDS, SET_IS_SENDING_DOCUMENT, SET_SWAP_FILES]),
    ...mapActions([
      DELETE_SEARCH_RESULTS,
      GET_SEARCH_RESULTS,
      GET_SIGNING_CHECKLIST,
      CREATE_TITLE,
      REFRESH_SIGNING_CHECKLIST,
      REFRESH_STATUS,
      REFRESH_CLOSING_STATUS,
      CANCEL_SCHEDULED_CLOSING,
      VALIDATE_THEN_SEND_NOW,
      VALIDATE_THEN_SEND_NOW_MULTIPLE,
      REFRESH_STEP,
    ]),
    ...mapRoomActions([GET_ROOM_PROVIDERS, PATCH_ROOM]),
    startSigning (startSigningAction) {
      this.startSigningDialogAction = startSigningAction
      const shouldWarnAboutQualifiedSignature = this.atLeastOneDraftDocumentRequiresQualifiedSignature && !this.qualifiedDSWarningIsMuted
      if (shouldWarnAboutQualifiedSignature) {
        this.qualifiedDSWarningDialog = {
          isVisible: true,
          confirmCallback: () => {
            this.closeQualifiedDSWarningDialog()
            this.startSigningDialogIsOpen = true
          },
        }
      } else {
        this.startSigningDialogIsOpen = true
      }
    },
    onEditSigningDates () {
      this.startSigningDialogAction = START_SIGNING_ACTIONS.EDIT
      this.startSigningDialogIsOpen = true
    },
    onStartSigningDialogClose () {
      this.startSigningDialogAction = START_SIGNING_ACTIONS.NOW
      this.skipRecap = false
      this.startSigningDialogIsOpen = false
    },
    onMultipleSwap () {
      const stepsIds = this.selectedSteps.map((step) => step.id)

      this.SET_SWAP_FILES({
        ids: stepsIds,
        steps: this.selectedSteps,
      })
    },
    goToMultipleSettings () {
      this.$router.push({
        name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
        params: {
          steps: this.selectedSteps,
        },
        query: {
          step: 2,
          stepIds: this.selectedSteps.map((step) => step.id).toString(),
        },
      })
    },
    goToMultipleSignatureTags () {
      this.$router.push({
        name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
        params: {
          steps: this.selectedSteps,
        },
        query: {
          step: 3,
          stepIds: this.selectedSteps.map((step) => step.id).toString(),
        },
      })
    },
    hasSendingStep (newValue) {
      let firstDocumentIsSending = this.isSendingDocument
      if (this.isSendingDocument && newValue.some(item => item.type === 'step' && (item.envelope.status === 'sent'))) {
        firstDocumentIsSending = false
      }
      const documentsAreSending = newValue.some(item => item.type === 'step' && (item.envelope.status === 'sending' || item.envelope.status === 'queued'))
      this.SET_IS_SENDING_DOCUMENT(documentsAreSending || firstDocumentIsSending)
    },
    showDeleteMultiple () {
      this.SET_DELETE_STEP_MODAL(this.selectedSteps.map((step) => step.id))
    },
    showCancelMultiple () {
      this.SET_CANCEL_STEP_MODAL({
        steps: this.selectedSteps,
        ids: this.selectedSteps.map((step) => step.id),
      })
    },
    showSendSignStepMultiple () {
      const setSendSignStepModalFn = () => this.SET_SEND_SIGN_STEP_MODAL({
        ids: this.selectedSteps.map((step) => step.id),
        steps: this.selectedSteps,
      })

      const shouldWarnAboutQualifiedSignature = this.selectedSteps.some(step => step.envelope?.signatureType === 'qualified') && !this.qualifiedDSWarningIsMuted
      if (shouldWarnAboutQualifiedSignature) {
        this.qualifiedDSWarningDialog = {
          isVisible: true,
          confirmCallback: () => {
            this.closeQualifiedDSWarningDialog()
            setSendSignStepModalFn()
          },
        }
      } else {
        setSendSignStepModalFn()
      }
    },
    showVoidedToDraftStepMultiple () {
      this.SET_VOIDED_TO_DRAFT_STEP_MODAL({
        ids: this.selectedSteps.map((step) => step.envelope.id),
        steps: this.selectedSteps,
      })
    },
    async onChangeEnvelopeGrouping () {
      try {
        await this.PATCH_ROOM({
          mnemo: this.mnemo,
          data: {
            optAllowEnvelopeGrouping: !this.currentRoom.allowEnvelopeGrouping,
          },
        })
        this.currentRoom.allowEnvelopeGrouping = !this.currentRoom.allowEnvelopeGrouping
      } catch (error) {
        // If we get this error, it means the room is in closing
        if (error.request.status === 400) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.views.SigningChecklist.envelopeGroupingInClosingDisabled'),
          })
          // Check back the closing status
          this.REFRESH_CLOSING_STATUS()
        } else {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('common.msgFailErrorOccurred'),
          })
        }
      }
    },
    async refreshLoop () {
      // We don't try to refresh if we're currently editing
      if (!this.currentTitleEdit) {
        // We want to avoid a zombie timer trying to refresh for a non-current mnemo
        // This should always be true...
        if (this.mnemo === this.$store.getters['room/roomMnemo']) {
          const nbSteps = this.flatSigningChecklist.filter((step) => step.type === 'step').length
          const nbTitles = this.flatSigningChecklist.filter((step) => step.type === 'title' && step.display !== 0).length
          try {
            await this.REFRESH_SIGNING_CHECKLIST({
              mnemo: this.mnemo,
              queryObject: {
                ifRecent: true,
                nbS: nbSteps,
                nbT: nbTitles,
              },
              isPolling: true,
            })
          } finally {
            if (this.refreshLoopId !== -1) {
              this.refreshLoopId = setTimeout(() => { this.refreshLoop() }, REFRESH_LOOP_POLLING_DELAY)
            }
          }
        } else {
          // This shouldn't happen, the timer has gone wild, the component is a zombie
          // Try to exorcise it ?
          clearTimeout(this.refreshLoopId)
          // Report this. This is unnatural
          Sentry.captureMessage('🧟 Trying to refresh a signing checklist that doesn\'t match the current mnemo', {
            tags: {
              propMnemo: this.mnemo,
              storeMnemo: this.$store.getters['room/roomMnemo'],
            },
          })
        }
      } else {
        if (this.refreshStatusLoop !== -1) {
          this.refreshLoopId = setTimeout(() => { this.refreshLoop() }, REFRESH_LOOP_POLLING_DELAY)
        }
      }
    },
    async refreshStatusLoop () {
      // We don't try to refresh if we're currently editing
      if (!this.currentTitleEdit) {
        // We want to avoid a zombie timer trying to refresh for a non-current mnemo
        // This should always be true...
        if (this.mnemo === this.$store.getters['room/roomMnemo']) {
          // Sometimes the timer does not kill itself properly and there's no signing checklist anymore
          // In those cases we don't want to do anything else and also kill the timer, again.
          // With fire, this time.
          if (this.signingChecklist.length === 0) {
            clearTimeout(this.refreshStatusLoopId)
            return
          }
          try {
            const data = await this.REFRESH_STATUS(this.mnemo)
            // We could have gotten out of the signing checklist in the meantime
            // We still don't want to do anything in that case
            if (this.signingChecklist.length === 0) {
              clearTimeout(this.refreshStatusLoopId)
              return
            }
            // We don't update either if we're currently editing right now
            if (!this.currentTitleEdit && Array.isArray(data)) {
              for (const step of data) {
                const parent = findStepParent(step, this.signingChecklist)
                if (parent) {
                  const shallowCopy = parent.children.slice()
                  const insertIndex = shallowCopy.findIndex((stepFromChildren) => step.id === stepFromChildren.id)
                  shallowCopy.splice(insertIndex, 1, step)
                  this.SET_PARENT_CHILDREN({
                    parent: parent,
                    children: shallowCopy,
                  })
                }
              }
            }
          } finally {
            if (this.refreshStatusLoopId !== -1) {
              this.refreshStatusLoopId = setTimeout(() => { this.refreshStatusLoop() }, REFRESH_STATUS_POLLING_DELAY)
            }
          }
        } else {
          // This shouldn't happen, the timer has gone wild, the component is a zombie
          // Try to exorcise it ?
          clearTimeout(this.refreshStatusLoopId)
          // Report this. This is unnatural
          Sentry.captureMessage('🧟 Trying to refresh the statuses of a signing checklist that doesn\'t match the current mnemo', {
            tags: {
              propMnemo: this.mnemo,
              storeMnemo: this.$store.getters['room/roomMnemo'],
            },
          })
        }
      } else {
        if (this.refreshStatusLoopId !== -1) {
          this.refreshStatusLoopId = setTimeout(() => { this.refreshStatusLoop() }, REFRESH_STATUS_POLLING_DELAY)
        }
      }
    },
    async refreshClosingStatusLoop () {
      // We want to avoid a zombie timer trying to refresh for a non-current mnemo
      // This should always be true...
      if (this.mnemo === this.$store.getters['room/roomMnemo']) {
        try {
          await this.REFRESH_CLOSING_STATUS({
            mnemo: this.mnemo,
            isPolling: true,
          })
        } finally {
          if (this.refreshClosingStatusLoopId !== -1) {
            this.refreshClosingStatusLoopId = setTimeout(() => { this.refreshClosingStatusLoop() }, REFRESH_CLOSING_STATUS_POLLING_DELAY)
          }
        }
      } else {
        // This shouldn't happen, the timer has gone wild, the component is a zombie
        // Try to exorcise it ?
        clearTimeout(this.refreshClosingStatusLoopId)
        // Report this. This is unnatural
        Sentry.captureMessage('🧟 Trying to refresh the closing status of a signing checklist that doesn\'t match the current mnemo', {
          tags: {
            propMnemo: this.mnemo,
            storeMnemo: this.$store.getters['room/roomMnemo'],
          },
        })
      }
    },
    setOpenDialog (value) {
      this.openDialog = value
    },
    onOpenFiltersPane () {
      this.SET_FILTERS_PANE_IS_OPEN(true)
    },
    searchChecklist () {
      this.search ? this.GET_SEARCH_RESULTS(this.searchQuery) : this.DELETE_SEARCH_RESULTS()
    },
    onUseDocumentTemplate () {
      this.isDocumentTemplatesDialogOpen = true
    },
    closeQualifiedDSWarningDialog () {
      this.qualifiedDSWarningDialog = {
        isVisible: false,
        confirmCallback: null,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  border-top: 1px solid black;
}

.signingChecklist-sticky {
  background-color: var(--v-originalGrey-lighten5);
}

::v-deep .c-affix.is-fixed {
  background-color: var(--v-originalGrey-lighten5);
  z-index: 5 !important;
}
.SigningChecklistTable-container {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-left: -20px;
    margin-right: -20px;
  }
}
</style>
