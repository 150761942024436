export const SET_AUTH_STATUS_PENDING = 'SET_AUTH_STATUS_PENDING'
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS'
export const SET_AUTH_STATUS_ERROR = 'SET_AUTH_STATUS_ERROR'
export const SET_USER_WANT_IMANAGE = 'SET_USER_WANT_IMANAGE'
export const SET_AUTH_URL_PENDING = 'SET_AUTH_URL_PENDING'
export const SET_AUTH_URL = 'SET_AUTH_URL'
export const SET_AUTH_URL_ERROR = 'SET_AUTH_URL_ERROR'
export const SET_AUTH_CODE_PENDING = 'SET_AUTH_CODE_PENDING'
export const SET_AUTH_CODE = 'SET_AUTH_CODE'
export const SET_AUTH_CODE_ERROR = 'SET_AUTH_CODE_ERROR'
export const SET_IMANAGE_FILE_PICKER_PENDING = 'SET_IMANAGE_FILE_PICKER_PENDING'
export const SET_IMANAGE_FILE_PICKER_ERROR = 'SET_IMANAGE_FILE_PICKER_ERROR'
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME'
export const SET_CURRENT_TIME_TIMEOUT = 'SET_CURRENT_TIME_TIMEOUT'
export const SET_IMANAGE_FOLDER_PICKER_PENDING = 'SET_IMANAGE_FOLDER_PICKER_PENDING'
export const SET_IMANAGE_FOLDER_PICKER_ERROR = 'SET_IMANAGE_FOLDER_PICKER_ERROR'
