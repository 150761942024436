export const getters = {
  allDocumentsSelected: state => {
    if (Object.keys(state.documents).length !== 0) {
      return state.documents.children.filter(item => !item.specialFolder).length === state.selectedDocuments.length
    }
  },
  isRootFolder: state => state.documents.id === 0,
  isRecentFolder: state => state.documents.id === 'recentFolder',
  documentsTableItems: state => {
    const { documents, searchModeEnabled, searchResults } = state
    return searchModeEnabled ? searchResults : documents.children
  },
  isTodoFolderAccessible: state => state.fullTree.children?.some(document => document.specialFolder === 'todo'),
}
