import {
  ADD_FILES,
  ADD_FOLDER,
  REMOVE_DOCUMENT,
  RESET_SELECTED_DOCUMENTS,
  SET_IS_OPEN,
} from './mutation_types'

export const mutations = {
  [SET_IS_OPEN] (state, value) {
    state.isOpen = value
  },
  [ADD_FILES] (state, files) {
    state.selectedDocuments.push(...files)
  },
  [ADD_FOLDER] (state, folder) {
    state.selectedDocuments.push(folder)
  },
  [REMOVE_DOCUMENT] (state, index) {
    state.selectedDocuments.splice(index, 1)
  },
  [RESET_SELECTED_DOCUMENTS] (state) {
    state.selectedDocuments = []
  },
}
