<template>
  <AppTooltip top>
    <template #activator="{on, attrs}">
      <span v-bind="attrs"
            v-on="on"
      >
        <app-icon :icon-name="computedIcon.name"
                  :icon-weight="computedIcon.weight"
                  :class="computedIcon.class"
                  size="small"
        />
      </span>
    </template>
    <span v-html="computedContent"/>
  </AppTooltip>
</template>

<script>
import AppTooltip from '@/common/AppTooltip'

export default {
  name: 'SigningChecklistTableItemStatusTooltip',
  components: {
    AppTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    computedContent () {
      if (this.isReadyToSend) {
        return this.$t('project.signing-checklist.list.SigningChecklistTableItem.readyTooltip.successTitle')
      }

      let content = ''

      if (this.item.envelope.missingToSend) {
        content = this.getMissingToSendInfos()
      } else if (this.item.envelope.missingToSign) {
        content = this.getMissingToSignInfos()
      }

      if (this.item.envelope.warningToSend.length) {
        content += this.getWarningToSendInfos()
      }

      return content
    },
    computedIcon () {
      if (this.isReadyToSend) {
        return {
          class: 'success--text',
          name: 'circle-check',
          weight: 'fas',
        }
      } else {
        return {
          class: 'warning--text',
          name: 'circle-exclamation',
          weight: 'far',
        }
      }
    },
    isReadyToSend () {
      return !this.item.envelope.missingToSend && !this.item.envelope.missingToSign && !this.item.envelope.warningToSend.length
    },
  },
  methods: {
    getMissingToSignInfos () {
      let infos = `<p>${this.$t('project.signing-checklist.list.SigningChecklistTableItem.readyTooltip.successTitle')}`

      for (const reason in this.item.envelope.missingToSign) {
        const innerReason = this.item.envelope.missingToSign[reason]
        if (!Array.isArray(innerReason)) {
          infos += '<br />- ' + this.$t('common.completeToSign.error_' + innerReason, {
            size: process.env.VUE_APP_MAX_FILE_SIZE,
            maxPage: process.env.VUE_APP_MAX_SIGNABLE_PAGES,
          })
        } else {
          for (const signer of innerReason) {
            for (const action of signer.reasons) {
              infos += '<br />- ' + this.$t('common.profileErrors.' + action, { name: signer.fullName })
            }
          }
        }
      }
      return `${infos}</p>`
    },
    getMissingToSendInfos () {
      let infos = `<p>${this.$t('project.signing-checklist.list.SigningChecklistTableItem.readyTooltip.errorTitle')}`

      for (const reason in this.item.envelope.missingToSend) {
        const innerReason = this.item.envelope.missingToSend[reason]
        if (!Array.isArray(innerReason)) {
          infos += '<br />- ' + this.$t('common.completeToSign.error_' + innerReason, {
            maxPage: process.env.VUE_APP_MAX_SIGNABLE_PAGES,
          })
        } else {
          for (const signer of innerReason) {
            for (const action of signer.reasons) {
              infos += '<br />- ' + this.$t('common.profileErrors.' + action, { name: signer.fullName })
            }
          }
        }
      }

      return `${infos}</p>`
    },
    getWarningToSendInfos () {
      let warningContent = `<p>${this.$t('project.signing-checklist.list.SigningChecklistTableItem.readyTooltip.warningTitle')}`

      const warnings = this.item.envelope.warningToSend

      warnings.forEach(reason => {
        warningContent += `<br />- ${this.$t('common.completeToSign.warning_' + reason)}`
      })

      return `${warningContent}</p>`
    },
  },
}
</script>
