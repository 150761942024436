<template>
  <v-snackbar
    :value="true"
    app
    bottom
    color="success"
    class="toast"
    timeout="-1"
    transition="slide-y-reverse-transition"
  >
    <div class="d-flex align-center column-gap-4">
      <font-awesome-icon :icon="['fal', 'hourglass-start']" />
      {{ $t("project.documents.DocumentsNumberingSnackbar.numberingSnackbarText") }}
    </div>
    <template #action="{ attrs }">
      <SnackBarButton class="mr-2"
                      v-bind="attrs"
                      @click="onRefresh"
      >
        {{ $t("project.documents.DocumentsNumberingSnackbar.numberingSnackbarRefresh") }}
      </SnackBarButton>
      <SnackBarButton class="ml-2"
                      v-bind="attrs"
                      @click="onClose"
      >
        {{ $t("project.documents.DocumentsNumberingSnackbar.numberingSnackbarClose") }}
      </SnackBarButton>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import SnackBarButton from '@/common/buttons/SnackBarButton.vue'
import { GET_DOCUMENTS_BY_ID, LOAD_FOLDERS_TREE } from '@/store/modules/documents/action_types'
import { SET_NUMBERING_SNACKBAR_IS_OPEN } from '@/store/modules/documents/mutation_types'

export default {
  name: 'DocumentsNumberingSnackbar',
  components: { SnackBarButton },
  methods: {
    ...mapActions('documents', [GET_DOCUMENTS_BY_ID, LOAD_FOLDERS_TREE]),
    ...mapMutations('documents', [SET_NUMBERING_SNACKBAR_IS_OPEN]),
    onRefresh () {
      this.GET_DOCUMENTS_BY_ID({
        mnemo: undefined,
        id: undefined,
        queryObject: { markRecent: true },
      })
      this.LOAD_FOLDERS_TREE()
    },
    onClose () {
      this.SET_NUMBERING_SNACKBAR_IS_OPEN(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.toast {
  ::v-deep(.v-snack__wrapper) {
    margin: 40px;
    bottom: 60px;

    // max-width: 1264px match our mobile breakpoint
    @media only screen and (max-width: 1264px) {
      margin: 16px;
    }
  }
}
</style>
