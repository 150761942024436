<template>
  <!-- For simplicity with back-end possible values, we only check for "truthy" values -->
  <!-- eslint-disable vue/eqeqeq  -->
  <!-- eslint-disable vue/valid-v-slot -->
  <AppDialog :is-open="isDialogOpened"
             size="l"
             @cancel="closeDialog"
  >
    <template #title>{{ data.docName }}</template>

    <template #subTitle>{{ $t("archives.dialogs.ArchivesListDetailsDialog.subtitle") }}</template>

    <template #body>
      <v-data-table
        :headers="fields"
        :mobile-breakpoint="1000000"
        hide-default-footer
        disable-sort
        class="archive-list-details-modal-table"
        :items="[data]"
      >
        <template v-slot:item.signersNames="{ item }">
          <ArchivesListSignersNames
            :signers-names="item.signersNames.split(',')"
          />
        </template>
        <template v-slot:item.archiverName="{ item }">
          <ArchivesListArchiverName :name="item.archiverName" />
        </template>
        <template v-slot:item.signedDate="{ item }">
          <DateDisplay :value="item.signedDate" />
        </template>
        <template v-slot:item.docExt="{ item }">
          <ClosdFildersIcon
            :document="item"
            :signedFile="item.isSigned == true"
          />
        </template>
        <template v-slot:item.docSize="{ item }">
          <app-filesize-display :value="item.docSize" />
        </template>
        <template v-slot:item.signedDocument="{ item }">
          <BooleanDisplay :value="item.isSigned == true" />
        </template>
        <template v-slot:item.archiveDate="{ item }">
          <DateDisplay :value="item.archiveDate" />
        </template>
        <template v-slot:item.archiveEndDate="{ item }">
          <DateDisplay :value="item.archiveEndDate" />
        </template>
      </v-data-table>
    </template>

    <template #footer>
      <v-spacer />
      <AppButton
        color="white"
        @click="closeDialog"
      >
        {{$t('common.close')}}
      </AppButton>
      <AppButton
        color="primary"
        outlined
        @click="actionClick('download')"
      >
        <i class="fa fa-download mr-2"></i>
        {{ $t("archives.dialogs.ArchivesListDetailsDialog.btnDownload") }}
      </AppButton>
      <AppButton
        v-if="isArchivingAdmin"
        color="primary"
        @click="actionClick('share')"
      >
        {{ $t("archives.dialogs.ArchivesListDetailsDialog.btnShare") }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import BooleanDisplay from '../../common/BooleanDisplay'
import AppButton from '../../common/buttons/AppButton.vue'
import DateDisplay from '../../common/DateDisplay.vue'
import AppDialog from '../../common/dialogs/AppDialog'
import ClosdFildersIcon from '../../common/filders/ClosdFildersIcon'
import { dialogMixin } from '../../mixins/dialogMixin'
import ArchivesListArchiverName from '../ArchivesListArchiverName'
import ArchivesListSignersNames from '../ArchivesListSignersNames'

const { mapGetters } = createNamespacedHelpers('user')

export default {
  name: 'ArchivesListDetailsDialog',
  components: {
    AppDialog,
    AppButton,
    ArchivesListArchiverName,
    ArchivesListSignersNames,
    BooleanDisplay,
    ClosdFildersIcon,
    DateDisplay,
  },
  mixins: [dialogMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['isArchivingAdmin']),
  },
  methods: {
    actionClick (actionName) {
      this.$emit('action', actionName)
      this.closeDialog()
    },
  },
}
</script>

<style scoped>
  ::v-deep.archive-list-details-modal-table .v-data-table__mobile-row {
    justify-content: initial;
  }

  ::v-deep.archive-list-details-modal-table .v-data-table__mobile-row__header {
    min-width: 50%;
  }
</style>
