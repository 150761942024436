<template>
  <AppCard class="pa-8 ma-1">
    <template v-if="patchRoomPending || processing">
      <div class="ProjectSettingsSettings-loader">
        <AppLoader/>
      </div>
    </template>
    <v-row>
      <v-col cols="12" md="6">
        <AppTextField v-model.trim="$v.roomName.$model"
                      :label="roomNameLabel"
                      class="ProjectSettingsSettings-roomNameLabel required"
                      maxlength="100"
                      :counter="100"
                      :error-messages="roomNameErrors"
        />
      </v-col>
      <v-col cols="12" md="6">
        <AppSelect v-model="roomLabel"
                   :items="labelItems"
                   :label="projectLabel"
                   :item-text="`label.${$i18n.locale}`"
                   item-value="id"
        ></AppSelect>
      </v-col>
      <v-col cols="12" md="6">
        <AppDatePicker v-model="dueDate"
                       :label="$t('project.project-settings.ProjectSettingsMainSettings.dueDateLabel')"
        >
        </AppDatePicker>
      </v-col>
      <v-col cols="12" md="6">
        <AppTextField v-model.trim="roomCmRef"
                      :label="$t('project.project-settings.ProjectSettingsMainSettings.roomCmRefLabel')"
                      maxlength="100"
        />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <ProjectSettingsCloseRoomDialog :mnemo="mnemo"/>
      </v-col>
      <v-col cols="auto">
        <AppButton color="primary"
                   :disabled="saveButtonDisabled"
                   @click="preparePatchRoom"
        >
          {{ $t('project.project-settings.ProjectSettingsMainSettings.save') }}
        </AppButton>
      </v-col>
    </v-row>
  </AppCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppCard from '@/common/AppCard'
import ProjectSettingsCloseRoomDialog from '@/project/project-settings/ProjectSettingsCloseRoomDialog'

import AppDatePicker from '../../common/AppDatePicker'
import AppLoader from '../../common/AppLoader'
import AppSelect from '../../common/AppSelect'
import AppTextField from '../../common/AppTextField'
import AppButton from '../../common/buttons/AppButton'
import { extractOnlyDateFromDateTime } from '../../common/utils/dates'
import { PATCH_ROOM } from '../../store/modules/room/action_types'
import { GET_ROOM_LABELS } from '../../store/modules/rooms/action_types'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'

export default {
  name: 'ProjectSettingsMainSettings',
  components: { AppCard, ProjectSettingsCloseRoomDialog, AppLoader, AppButton, AppSelect, AppDatePicker, AppTextField },
  mixins: [validationMixin],
  validations: {
    roomName: { required },
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      roomName: '',
      roomCmRef: '',
      dueDate: null,
      roomLabel: null,
    }
  },
  computed: {
    ...mapState('room', ['currentRoom', 'patchRoomPending', 'processing']),
    ...mapState('rooms', ['roomLabels']),
    ...mapGetters('room', ['isDataRoom']),
    labelItems () {
      const items = [{
        id: null,
        label: { fr: 'Aucun', en: 'None' },
      }]
      if (this.roomLabels) {
        items.push(...this.roomLabels)
      }
      return items
    },
    roomNameErrors () {
      const errors = []
      if (!this.$v.roomName.$dirty) return errors
      !this.$v.roomName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.project-settings.ProjectSettingsMainSettings.roomNameLabel') }))
      return errors
    },
    saveButtonDisabled () {
      return this.roomName === this.currentRoom.name &&
        extractOnlyDateFromDateTime(this.dueDate) === extractOnlyDateFromDateTime(this.currentRoom.expectedClosingDate) &&
        (this.currentRoom.label ? this.roomLabel === this.currentRoom.label.id : this.roomLabel === null) &&
        (this.currentRoom.hasOwnProperty('cmRef') ? this.roomCmRef === this.currentRoom.cmRef : !this.roomCmRef)
    },
    roomNameLabel () {
      return this.isDataRoom
        ? this.$t('project.project-settings.ProjectSettingsMainSettings.dataRoomNameLabel')
        : this.$t('project.project-settings.ProjectSettingsMainSettings.projectNameLabel')
    },
    projectLabel () {
      return this.isDataRoom
        ? this.$t('project.project-settings.ProjectSettingsMainSettings.dataRoomLabel')
        : this.$t('project.project-settings.ProjectSettingsMainSettings.projectLabel')
    },
  },
  async mounted () {
    try {
      this.GET_ROOM_LABELS(this.mnemo)
    } catch (e) {
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'error',
        message: this.$t('project.project-settings.ProjectSettingsMainSettings.getRoomsLabelsError'),
      })
    }
    this.roomName = this.currentRoom.name
    this.dueDate = this.currentRoom.expectedClosingDate ? extractOnlyDateFromDateTime(this.currentRoom.expectedClosingDate) : null
    this.roomLabel = this.currentRoom.label ? this.currentRoom.label.id : null
    this.roomCmRef = this.currentRoom.cmRef || ''
  },
  methods: {
    ...mapActions('room', [PATCH_ROOM, 'getRoomByMnemo']),
    ...mapActions('rooms', [GET_ROOM_LABELS]),
    async preparePatchRoom () {
      try {
        const data = {
          name: this.roomName,
          labelId: this.roomLabel,
          expClosingDate: this.dueDate,
          cmRef: this.roomCmRef,
        }
        await this.PATCH_ROOM({
          mnemo: this.mnemo,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.project-settings.ProjectSettingsMainSettings.patchRoomSuccess'),
        })
        await this.getRoomByMnemo({ mnemo: this.mnemo, params: null })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.project-settings.ProjectSettingsMainSettings.patchRoomError'),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ProjectSettingsSettings-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#fff, .8);
  z-index: 1;
}
::v-deep .ProjectSettingsSettings-roomNameLabel .v-text-field__details {
  margin-bottom: 0;
}
</style>
