export const mutations = {
  ADD_ITEM: function (state, item) {
    if (!state.breadcrumb.some(i => i.id === item.id)) { state.breadcrumb.push(item) }
  },
  SET_ITEMS: function (state, items = []) {
    state.breadcrumb = items
  },
  REMOVE_ALL_AFTER: function (state, item) {
    const index = state.breadcrumb.findIndex(i => i.id === item.id)
    state.breadcrumb.splice(index + 1, state.breadcrumb.length - 1)
  },
  RESET_BREADCRUMB: function (state) {
    state.breadcrumb = []
  },
}
