<template>
  <Pane :value="paneIsOpen"
        :tabs="tabs"
        :right="false"
        current-tab="filter"
        temporary
        @closeButtonClicked="paneIsOpen = false"
        @input="onInput"
  >
    <template #content-filter>
      <v-row>
        <v-col>
          <div class="body-1 font-weight-bold">{{ $t('project.Logs.LogsFiltersPane.dates') }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <AppDatePicker v-model="filters.date"
                         range
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider class="my-2"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="body-1 font-weight-bold">{{ $t('project.Logs.LogsFiltersPane.users') }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DocumentSharePicker :force-pm-selection="false"/>
        </v-col>
      </v-row>
      <v-row>
        <v-divider class="my-2"/>
      </v-row>
      <template v-if="logsTypes">
        <v-row>
          <v-col>
            <div class="font-weight-bold body-1">{{ $t('project.Logs.LogsFiltersPane.logsTypes') }}</div>
          </v-col>
        </v-row>
        <v-row class="mb-6">
          <v-col>
            <div class="logs-types-container">
              <v-checkbox v-for="(value, key, index) in logsTypes"
                          :key="`log-type-${index}`"
                          v-model="filters.types"
                          :value="key"
                          dense
                          hide-details
              >
                <template #label>
                  <span class="text-capitalize-first-letter">{{ value.replaceAll('_', ' ').toLowerCase() }}</span>
                </template>
              </v-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider class="my-2"/>
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col>
          <AppButton block
                     @click="onClearAllFilters"
          >
            {{ $t('common.clear') }}
          </AppButton>
        </v-col>
        <v-col>
          <AppButton color="primary"
                     block
                     @click="onValidateClick"
          >
            {{ $t('common.apply') }}
          </AppButton>
        </v-col>
      </v-row>
    </template>
  </Pane>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import { ISOToShortenedDate } from '@/common/utils/dates'
import { Filter } from '@/models/filter.model'
import {
  INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS,
  RESET_SELECTED_GROUPS_AND_MEMBERS,
} from '@/store/modules/document-share-picker/action_types'
import { GET_LOGS_TYPES } from '@/store/modules/logs/action_types'
import {
  SET_LOGS_FILTERS,
  SET_LOGS_FILTERS_PANE_IS_OPEN,
} from '@/store/modules/logs/mutation_types'

import AppDatePicker from '../../common/AppDatePicker'
import AppButton from '../../common/buttons/AppButton'
import DocumentSharePicker from '../../common/document-share/DocumentSharePicker'
import Pane from '../../common/Pane'

export default {
  name: 'LogsFiltersPane',
  components: { AppButton, Pane, AppDatePicker, DocumentSharePicker },
  data () {
    return {
      tabs: [{ id: 'filter', title: this.$t('project.Logs.LogsFiltersPane.filters') }],
      paneIsOpen: false,
      filters: {
        date: [],
        types: [],
      },
    }
  },
  computed: {
    ...mapState('logs', ['logsFiltersPaneIsOpen', 'logsFilters', 'logsTypes']),
    ...mapState('documentSharePicker', ['selectedMembers', 'selectedGroups']),
    ...mapState('groups', ['groups']),
    ...mapGetters('groups', ['allRoomMembers']),
    ...mapGetters('room', ['roomMnemo']),
  },
  watch: {
    logsFiltersPaneIsOpen: {
      handler (value) {
        if (value) {
          this.syncAppliedFiltersWithLocals()
          setTimeout(() => { this.paneIsOpen = true }, 300)
        }
      },
      immediate: true,
    },
    paneIsOpen (value) {
      if (!value) {
        setTimeout(() => {
          this.SET_LOGS_FILTERS_PANE_IS_OPEN(false)
        }, 300)
      }
    },
  },
  created () {
    if (!this.logsTypes) {
      this.prepareGetLogsTypes()
    }
  },
  methods: {
    ...mapActions('documentSharePicker', [
      INIT_SELECTED_GROUPS,
      INIT_SELECTED_MEMBERS,
      RESET_SELECTED_GROUPS_AND_MEMBERS,
    ]),
    ...mapMutations('logs', [
      SET_LOGS_FILTERS_PANE_IS_OPEN,
      SET_LOGS_FILTERS,
    ]),
    ...mapActions('logs', [GET_LOGS_TYPES]),
    applyFilters () {
      const logDateFilter = this.filters.date.length > 0
        ? [
          new Filter(
            'date',
            this.$t('project.Logs.LogsFiltersPane.dates'),
            this.filters.date,
            `${ISOToShortenedDate(this.filters.date[0])} - ${ISOToShortenedDate(this.filters.date[1])}`,
          )]
        : []

      const usersFilters = this.selectedMembers.map(member =>
        new Filter('users', this.$t('common.user'), member.id, member.fullName),
      )

      const groupsFilters = this.selectedGroups.map(group =>
        new Filter('groups', this.$t('common.group'), group.id, group.name),
      )

      const typesFilter = this.filters.types.map(type => {
        let typeName = this.logsTypes[type].replaceAll('_', ' ').toLowerCase()
        typeName = typeName.charAt(0).toUpperCase() + typeName.slice(1)
        return new Filter('types', this.$t('common.type'), type, typeName)
      })

      const filters = [
        ...logDateFilter,
        ...usersFilters,
        ...groupsFilters,
        ...typesFilter,
      ]

      this.SET_LOGS_FILTERS(filters)
    },
    syncAppliedFiltersWithLocals () {
      const dateFilter = this.logsFilters.find(filter => filter.category.key === 'date')
      this.filters.date = dateFilter?.value.key ?? []

      const userFilters = this.logsFilters.filter(filter => filter.category.key === 'users')
      const usersWithMatchingFilter = this.allRoomMembers.filter(
        member => userFilters.some(userFilter => userFilter.value.key === member.id),
      )
      this.INIT_SELECTED_MEMBERS(usersWithMatchingFilter)

      const groupFilters = this.logsFilters.filter(filter => filter.category.key === 'groups')
      const groupsWithMatchingFilter = this.groups.filter(
        group => groupFilters.some(groupFilter => groupFilter.value.key === group.id),
      )
      this.INIT_SELECTED_GROUPS(groupsWithMatchingFilter)

      const typesFilter = this.logsFilters.filter(filter => filter.category.key === 'types')
      this.filters.types = typesFilter.map(type => type.value.key) || []
    },
    onClearAllFilters () {
      this.RESET_SELECTED_GROUPS_AND_MEMBERS()
      this.SET_LOGS_FILTERS([])
    },
    onValidateClick () {
      this.applyFilters()
      this.paneIsOpen = false
    },
    onInput (value) {
      if (!value) {
        this.paneIsOpen = false
      }
    },
    async prepareGetLogsTypes () {
      await this.GET_LOGS_TYPES(this.roomMnemo)
    },
  },
}
</script>

<style scoped lang="scss">
.logs-types-container {
  max-height: 300px;
  overflow-y: auto;
}
</style>
