<template>
  <div>
    <div v-if="isTotalDocumentsDisplayed">
      <span class="envelope-stats-text">
        {{ $t('project.signing-checklist.SigningChecklistEnvelopeStats.totalDocuments') }}
        <v-chip small class="envelope-stats-chip envelope-stats-total-documents ml-2">
          {{ totalDocuments }}
        </v-chip>
      </span>
    </div>
    <v-skeleton-loader v-else class="envelope-stats-skeleton-loader" type="text,avatar" width="140">
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'SigningChecklistEnvelopeStats',
  computed: {
    ...mapState('signingChecklist', ['totalDocuments']),
    isTotalDocumentsDisplayed () {
      return typeof this.totalDocuments === 'number'
    },
  },
})
</script>

<style scoped>
/* The skeleton loader adds the different types as different block divs otherwise */
.envelope-stats-skeleton-loader {
    display: flex;
    align-items: center;
    gap: 12px;
}

.envelope-stats-skeleton-loader::v-deep .v-skeleton-loader__avatar {
    width: 24px;
    height: 24px;
}

.envelope-stats-text {
  color: #333 !important;
}
  /* We don't want to have the usual slight light from an hover here
     This change is meant to show the user they can't interact with the chip
     Since it's used purely for styling purposes
  */
  /*
    TODO: if this sort of thing is used again, make it a part of a component / global style.
    This may be a design system concern for chips.
  */
.envelope-stats-chip::v-deep.v-chip:hover::before {
  opacity: 0;
}
</style>
