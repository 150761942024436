import axios from 'axios'

const signableFileAllowedExtensions = ['pdf']
const QA_IMPORT_ALLOWED_EXTENSIONS = ['.csv', '.xls', '.xlsx']
const FILE_NAME_MAX_LENGTH = 100

export const idCheckFileAllowedExtensions = '.pdf, .jpg, .png'

if (process.env.VUE_APP_ENABLE_WORD_SIGNING) {
  signableFileAllowedExtensions.push('doc')
  signableFileAllowedExtensions.push('docx')
}

function isFileLowerThanMaxSignableSize (file, maxSignableSize = 0) {
  // If we don't have a maxSignableSize... We could at least use the max file size.
  const availableSize = maxSignableSize || process.env.VUE_APP_MAX_FILE_SIZE
  return file.size < availableSize * 1024 * 1024
}

function isFileExtensionSignable (file) {
  if (file.extension) {
    return signableFileAllowedExtensions.includes(file.extension)
  } else {
    return signableFileAllowedExtensions.includes(file.name.split('.').pop().toLowerCase())
  }
}

function isFileSignable (file, maxSignableSize) {
  return isFileLowerThanMaxSignableSize(file, maxSignableSize) && isFileExtensionSignable(file)
}

/**
 * Start downloading a file by the browser
 * @param {File} file
 * @return void
 */
function downloadFileObject (file) {
  const blob = new Blob([file], { type: file.type })
  const blobURL = URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = blobURL
  link.download = file.name
  document.body.append(link)
  link.click()

  link.remove()
  setTimeout(() => URL.revokeObjectURL(link.href), 7000)
}

function fileNameWithoutExtension (fileName) {
  const nameSegments = fileName.split('.')

  if (nameSegments.length > 1) {
    return nameSegments.slice(0, -1).join('.')
  }

  return fileName
}

function roomFileDownloadUrl (fileId, roomMnemo) {
  return `${axios.defaults.baseURL}/room/${roomMnemo}/downloads/file/${fileId}`
}

function flattenFildersTree (filders) {
  const flattenFolderChildren = folder => {
    const childrenFiles = []

    if (folder.hasOwnProperty('children')) {
      for (const child of folder.children) {
        childrenFiles.push(...flattenFolderChildren(child))
      }
    } else {
      childrenFiles.push(folder)
    }
    return childrenFiles
  }

  const files = []
  filders.map(filder => {
    if (filder.type === 'folder') {
      files.push(...flattenFolderChildren(filder))
    } else {
      files.push(filder)
    }
  })

  return files
}

function getFileExtension (file) {
  let ext = null
  if (file.ext) {
    ext = file.ext
  } else if (file.docExt) {
    ext = file.docExt
  } else if (file.extension) {
    ext = file.extension
  } else if (file.name?.includes('.')) {
    ext = file.name.split('.').pop()
  }
  // Special cases where we can't deduct the extension and it has to be contextually applied
  // E.g In case of bibles
  if (!ext) {
    if (file.bibleId) {
      ext = 'zip'
    }
  }
  return ext
}

export {
  QA_IMPORT_ALLOWED_EXTENSIONS,
  FILE_NAME_MAX_LENGTH,
  isFileLowerThanMaxSignableSize,
  isFileExtensionSignable,
  isFileSignable,
  downloadFileObject,
  fileNameWithoutExtension,
  flattenFildersTree,
  roomFileDownloadUrl,
  getFileExtension,
}
