<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-loading="deleteTitlePending"
             :ok-disabled="deleteTitlePending"
             :cancel-disabled="deleteTitlePending"
             :ok-text="$t('common.delete')"
             @cancel="closeDialog"
             @ok="prepareDeleteTitle"
  >
    <template #title>
      {{ $t('project.checklist.dialogs.ChecklistTableTitleDeleteDialog.title') }}
    </template>
    <template #body>
      <div v-if="enableValidationChecklistItemDeletion"
           v-html="$t('project.checklist.dialogs.ChecklistTableTitleDeleteDialog.doubleValidationText')">
      </div>
      <template v-else>
        {{ $t('project.checklist.dialogs.ChecklistTableTitleDeleteDialog.text') }}
      </template>
    </template>
  </AppDialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { DELETE_TITLE, REMOVE_TITLE_FROM_SELECTION } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'ChecklistTableTitleDeleteDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    title: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('room', ['currentRoom']),
    ...mapState('checklist', ['deleteTitlePending']),
    ...mapGetters('checklist', ['selectedTitles']),
    enableValidationChecklistItemDeletion () {
      return this.currentRoom?.enableValidationChecklistItemDeletion
    },
    titleIsSelected () {
      return this.selectedTitles.some(title => title.id === this.title.id)
    },
    deleteTitleSuccess () {
      return this.enableValidationChecklistItemDeletion
             ? this.$t('project.project-settings.ProjectSettingsFeatures.checklist.pendingDeletion')
             : this.$t('project.checklist.dialogs.ChecklistTableTitleDeleteDialog.deleteTitleSuccess')
    },
  },
  methods: {
    ...mapActions('checklist', [DELETE_TITLE, REMOVE_TITLE_FROM_SELECTION]),
    async prepareDeleteTitle () {
      try {
        await this.DELETE_TITLE({
          mnemo: this.mnemo,
          titleId: this.title.id,
        })

        if (this.titleIsSelected) {
          this.REMOVE_TITLE_FROM_SELECTION(this.title)
        }

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.deleteTitleSuccess,
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.dialogs.ChecklistTableTitleDeleteDialog.deleteTitleError'),
        })
      } finally {
        this.closeDialog()
      }
    },
  },
}
</script>
