<template>
  <div class="save-checklist-share">
    <app-text variant="small-regular">
      {{ $t('project.checklist.dialogs.ImportChecklistDialog.accessRights.dialogSubTitle') }}
    </app-text>
    <h4 class="text-h4 mt-6">
      {{ $t('project.checklist.dialogs.SaveChecklistDialog.shareWith') }}
    </h4>

    <SaveChecklistDialogShareType :default-share-type="shareType"
                                  @change-share-type="shareType = $event"
    />

    <div>
      <template v-if="shareType === SHARE_TYPE_OPTIONS.CUSTOM">
        <v-row>
          <v-col>
            <div v-if="getSelfContactListPending" class="text-center">
              <AppLoader small/>
            </div>
            <div v-else>
              <!-- Search member -->
              <v-combobox ref="searchMemberCombobox"
                          :items="nonSelectedUsersList"
                          :label="$t('project.checklist.dialogs.SaveChecklistDialog.searchMember')"
                          :item-text="getItemText"
                          class="mt-3"
                          prepend-inner-icon="fal fa-magnifying-glass"
                          hide-details
                          clearable
                          outlined
                          dense
                          @change="addUser"
              >
                <template #selection="{item}"></template>
                <template #item="{item}">
                  <AddMemberFormComboboxItem :item="item"/>
                </template>
              </v-combobox>

              <!-- Members with access -->
              <h4 class="mt-6 mb-2 text-h4">
                {{ $t('project.checklist.dialogs.SaveChecklistDialog.membersWithAccess') }}
              </h4>

              <div class="share-type-custom-content mt-2">
                <template v-for="user in selectedUsersList">
                  <div :key="`user-${user.id}`"
                       class="d-flex align-center justify-space-between ml-2 mt-2"
                  >
                    <AddMemberFormComboboxItem :item="user"/>
                    <div class="mr-1">
                      <SaveChecklistShareRightsMenu :userAccessRight="userRightsMap[user.id]"
                                                    @set-user-rights="setUserRights(user, $event)">
                      </SaveChecklistShareRightsMenu>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>

      <!-- Send notif -->
      <template v-if="!this.shareTypeIsPrivate">
        <template v-if="isSavedChecklistShareUpdate">
          <v-checkbox v-model="sendNotif"
                      class="mt-4"
                      hide-details
                      :label="$t('project.checklist.dialogs.ImportChecklistDialog.accessRights.addCustomMessage')"
          ></v-checkbox>
          <VisualTextEditor v-if="sendNotif"
                            v-model="notifyMessage"
                            class="mt-4"
                            counter="footer"
                            :error-messages="notifyMessageErrors"
                            :max-length="notifyMessageMaxLength"
          />
        </template>
        <v-checkbox v-else
            v-model="sendNotif"
            :label="$t('project.checklist.dialogs.SaveChecklistDialog.sharingOptions.sendNotifLabel')"
            hide-details
        ></v-checkbox>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AppLoader from '@/common/AppLoader.vue'
import { RIGHT_TYPE_OPTIONS } from '@/common/utils/rightTypes.ts'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes.ts'
import { escapeHtml } from '@/common/utils/strings'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor.vue'
import AddMemberFormComboboxItem from '@/project/members/AddMemberFormComboboxItem.vue'
import { GET_SELF_CONTACT_LIST } from '@/store/modules/user/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import SaveChecklistDialogShareType from './SaveChecklistDialogShareType.vue'
import SaveChecklistShareRightsMenu from './SaveChecklistShareRightsMenu.vue'

const NOTIFY_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'SaveChecklistShare',
  components: {
    AppLoader,
    SaveChecklistDialogShareType,
    SaveChecklistShareRightsMenu,
    VisualTextEditor,
    AddMemberFormComboboxItem,
  },
  props: {
    isSavedChecklistShareUpdate: {
      type: Boolean,
      default: false,
    },
    openedChecklist: {
      required: false,
      validator: (value) => {
        return value === null || typeof value === 'object'
      },
    },
    defaultShareType: {
      type: String,
      default: SHARE_TYPE_OPTIONS.PUBLIC,
    },
    defaultSelectedUsers: {
      type: Array,
      default: () => [],
    },
    defaultSendNotif: {
      type: Boolean,
      default: false,
    },
    defaultNotifyMessage: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      SHARE_TYPE_OPTIONS,
      shareType: this.defaultShareType,
      selectedUsers: this.defaultSelectedUsers,
      sendNotif: this.defaultSendNotif,
      notifyMessage: this.defaultNotifyMessage,
      notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
    }
  },
  computed: {
    ...mapState('user', ['profile', 'selfContactListFromSubscription', 'getSelfContactListPending']),
    computedSubscriptionUsers () {
      return this.selfContactListFromSubscription.filter(user => user.id !== this.profile.id)
    },
    selectedUsersList () {
      return this.computedSubscriptionUsers.filter(user => this.selectedUsersId.includes(user.id))
    },
    nonSelectedUsersList () {
      return this.computedSubscriptionUsers.filter(user => !this.selectedUsersId.includes(user.id))
    },
    selectedUsersId () {
      return this.selectedUsers.map(user => user.userId)
    },
    userRightsMap () {
      const map = {}
      this.selectedUsers.forEach((user) => {
        map[user.userId] = user.right
      })
      return map
    },
    notifyMessageErrors () {
      const errors = []
      if (this.notifyMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }
      return errors
    },
    notifyMessageIsTooLong () {
      return this.notifyMessageLength > this.notifyMessageMaxLength
    },
    notifyMessageLength () {
      return escapeHtml(this.notifyMessage).length
    },
    shareTypeIsPublic () {
      return this.shareType === SHARE_TYPE_OPTIONS.PUBLIC
    },
    shareTypeIsPrivate () {
      return this.shareType === SHARE_TYPE_OPTIONS.PRIVATE
    },
  },
  watch: {
    shareType (value) {
      this.$emit('change-share-type', value)
    },
    selectedUsers (value) {
      this.$emit('set-selected-users', value)
    },
    sendNotif (value) {
      if (!value) {
        this.notifyMessage = ''
      }
      this.$emit('set-send-notif', value)
    },
    notifyMessage (value) {
      this.$emit('set-notif-message', value)
    },
  },
  mounted () {
    this.prepareGetSubscriptionUsers()
  },
  created () {
    if (this.openedChecklist) {
      this.shareType = this.openedChecklist.shareType
      if (this.openedChecklist.rights?.length) {
        this.selectedUsers = this.openedChecklist.rights.filter(user => user.userId !== this.profile.id)
      }
    }
  },
  methods: {
    ...mapActions('user', [GET_SELF_CONTACT_LIST]),
    async prepareGetSubscriptionUsers () {
      try {
        await this.GET_SELF_CONTACT_LIST({ fromUserSubscriptionOnly: true })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('common.subscription.errors.getSubscriptionUsersError'),
        })
      }
    },
    addUser (newUser) {
      if (newUser) {
        this.selectedUsers.push({ userId: newUser.id, right: 'read' })
        this.$emit('set-selected-users', this.selectedUsers)
        this.$refs.searchMemberCombobox.reset()
      }
    },
    setUserRights (user, newRight) {
      if (newRight === RIGHT_TYPE_OPTIONS.REMOVE) {
        const index = this.selectedUsers.findIndex(item => item.userId === user.id)
        if (index > -1) {
          this.selectedUsers.splice(index, 1)
        }
      } else {
        const selectedUser = this.selectedUsers.find(item => item.userId === user.id)
        selectedUser.right = newRight
      }
      this.$emit('set-selected-users', this.selectedUsers)
    },
    getItemText (item) {
      return `${item.firstName} ${item.lastName}`
    },
  },
}
</script>

<style lang="scss">
  .save-checklist-share {
    .share-type-custom-content {
      max-height: 250px;
      margin-right: 0px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .v-icon.v-icon {
    font-size: 16px;
  }
</style>
