import projectsFoldersService from '@/services/projectsFolders.service'

import {
  DELETE_PROJECTS_FOLDER,
} from './action_types'
import {
  SET_PROJECTS_FOLDER_DELETE_PENDING,
  SET_PROJECTS_FOLDER_TO_DELETE,
} from './mutation_types'

export const actions = {
  async [DELETE_PROJECTS_FOLDER] ({ commit }, projectsFolderToDeleteId) {
    commit(SET_PROJECTS_FOLDER_DELETE_PENDING, true)
    try {
      await projectsFoldersService.deleteProjectsFolder(projectsFolderToDeleteId)
      commit(SET_PROJECTS_FOLDER_TO_DELETE, {})
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_PROJECTS_FOLDER_DELETE_PENDING, false)
    }
  },
}
