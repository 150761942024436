<template>
  <div>
    <div class="body-1 font-weight-bold pb-5 ml-1">
      {{ $t('project.project-settings.ProjectSettingsDataRoomInfo.title') }}
    </div>
    <AppCard class="pa-8 ma-1">
      <template v-if="roomStats === null">
        <v-row class="{my-4 : $vuetify.breakpoint.xsOnly}">
          <v-col cols="12" sm="4">
            <v-skeleton-loader type="text@3"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4">
            <v-skeleton-loader type="text@3"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4">
            <v-skeleton-loader type="text@3"></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row class="{my-4 : $vuetify.breakpoint.xsOnly}">
          <v-col :class="{ 'pb-8' : $vuetify.breakpoint.xsOnly, 'pr-8' : $vuetify.breakpoint.smAndUp}"
                 class="d-flex flex-column row-gap-4"
                 cols="12"
                 sm="4"
          >
            <app-text variant="medium-bold" class="d-flex align-center column-gap-2">
              <font-awesome-icon :icon="['far', 'list-alt']" />
              {{ $t('project.project-settings.ProjectSettingsDataRoomInfo.additionalInformation') }}
            </app-text>
            <ul class="settings-list">
              <li>
                <app-text as="span" variant="small-regular">{{ $t('project.project-settings.ProjectSettingsDataRoomInfo.createdAt') }}</app-text>
                <app-text as="span" variant="small-bold"><DateDisplay :value="currentRoom.createdAt" /></app-text>
              </li>
              <li>
                <app-text as="span" variant="small-regular">{{ $t('project.project-settings.ProjectSettingsDataRoomInfo.duration') }}</app-text>
                <app-text as="span" variant="small-bold">{{ $tc('project.project-settings.ProjectSettingsDataRoomInfo.months', currentRoom.dataroomInfo.initialDuration, { count: currentRoom.dataroomInfo.initialDuration }) }}</app-text>
              </li>
              <li>
                <app-text as="span" variant="small-regular">{{ $t('project.project-settings.ProjectSettingsDataRoomInfo.renewal') }}</app-text>
                <app-text as="span" variant="small-bold"><DateDisplay :value="currentRoom.dataroomInfo.renewalDate"/></app-text>
              </li>
            </ul>
          </v-col>
          <v-col :class="{dividerH : $vuetify.breakpoint.xsOnly, dividerV : $vuetify.breakpoint.smAndUp}"
                 class="d-flex flex-column row-gap-4"
                 cols="12"
                 sm="4"
          >
            <app-text variant="medium-bold" class="d-flex align-center column-gap-2">
              <font-awesome-icon :icon="['far', 'file-alt']" />
              {{ $t('project.project-settings.ProjectSettingsDataRoomInfo.documents') }}
            </app-text>
            <ul class="settings-list">
              <li>
                <app-text as="span" variant="small-regular">{{ $t('project.project-settings.ProjectSettingsDataRoomInfo.documentsCountText') }}</app-text>
                <app-text as="span" variant="small-bold"> {{ $tc('project.project-settings.ProjectSettingsDataRoomInfo.documentsCount', files, { count: files }) }}</app-text>
              </li>
              <li>
                <app-text as="span" variant="small-regular">{{ $t('project.project-settings.ProjectSettingsDataRoomInfo.archivedDocumentsCountText') }}</app-text>
                <app-text as="span" variant="small-bold">{{ $tc('project.project-settings.ProjectSettingsDataRoomInfo.documentsCount',  archivedDocuments, { count: archivedDocuments }) }}</app-text>
              </li>
            </ul>
          </v-col>
          <v-col :class="{dividerH : $vuetify.breakpoint.xsOnly, dividerV : $vuetify.breakpoint.smAndUp}"
                 class="d-flex flex-column row-gap-4"
                 cols="12"
                 sm="4"
          >
            <app-text variant="medium-bold" class="d-flex align-center column-gap-2">
              <font-awesome-icon :icon="['far', 'database']" />
              {{ $t('project.project-settings.ProjectSettingsDataRoomInfo.storage') }}
            </app-text>
            <ul class="settings-list">
              <app-text as="li" variant="small-regular">
                <span>{{ $t('project.project-settings.ProjectSettingsDataRoomInfo.storageDataLabel') }}</span>
                <template v-if="roomStatsPending">
                  <v-skeleton-loader type="text" min-width="120" />
                </template>
                <template v-else>
                  <app-text as="span" variant="small-bold">
                    {{ computedDisplayStorage }}
                  </app-text>
                </template>
              </app-text>
            </ul>
            <v-progress-linear
              :value="storagePercent"
              color="blue"
              height="10"
              rounded
            />
            <app-button v-if="isUpgradeButtonDisplayed"
                        class="mr-auto"
                        type="outlined"
                        @click="onUpgrade"
            >
              {{ $t(`project.project-settings.ProjectSettingsDataRoomInfo.upgradeButtonLabel`)}}
            </app-button>
          </v-col>
        </v-row>
      </template>
    </AppCard>
    <ProjectSettingsDataroomUpgradeDialog v-if="isDataroomUpgradeDialogOpened"
                                          @close="isDataroomUpgradeDialogOpened = false"
                                          @refresh-stats="prepareGetRoomStats"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppCard from '@/common/AppCard'
import DateDisplay from '@/common/DateDisplay'
import { ROOM_TYPES } from '@/common/utils/roomTypes'
import ProjectSettingsDataroomUpgradeDialog from '@/project/project-settings/ProjectSettingsDataroomUpgradeDialog.vue'
import { GET_ROOM_STATS } from '@/store/modules/room/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'ProjectSettingsDataRoomInfo',
  components: {
    AppCard,
    DateDisplay,
    ProjectSettingsDataroomUpgradeDialog,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    showUpgradeDialog: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isDataroomUpgradeDialogOpened: false,
    }
  },
  computed: {
    ...mapState('room', ['currentRoom', 'roomStats', 'roomStatsPending']),
    ...mapGetters('room', [
      'archivedDocuments',
      'files',
      'storage',
      'totalStorage',
      'usedStorage',
      'storagePercent',
      'isPmOfRoomSubscription',
    ]),
    computedDisplayStorage () {
      return this.$t('project.project-settings.ProjectSettingsDataRoomInfo.storageData',
        {
          totalCount: this.totalStorage.storage,
          totalUnit: this.$t(`project.project-settings.ProjectSettingsDataRoomInfo.${this.totalStorage.unit}`),
          usedCount: this.usedStorage.storage,
          usedUnit: this.$t(`project.project-settings.ProjectSettingsDataRoomInfo.${this.usedStorage.unit}`),
        },
      )
    },
    isUpgradeButtonDisplayed () {
      return this.currentRoom.type !== ROOM_TYPES.DATAROOM_UNLIMITED && this.isPmOfRoomSubscription
    },
  },
  mounted () {
    this.prepareGetRoomStats()
  },
  methods: {
    ...mapActions('room', [GET_ROOM_STATS]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    onUpgrade () {
      this.isDataroomUpgradeDialogOpened = true
    },
    async prepareGetRoomStats () {
      try {
        await this.GET_ROOM_STATS(this.mnemo)
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.project-settings.ProjectSettingsDataRoomInfo.getRoomStatsError'))
      }
    },
  },
  watch: {
    showUpgradeDialog (newValue) {
      this.isDataroomUpgradeDialogOpened = newValue
    },
    '$route.query': function (newVal) {
      if (this.$route.query.show) {
        this.isDataroomUpgradeDialogOpened = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.dividerV {
  padding: 8px 30px;
  border-left: 1px solid #E0E0E0;

  &:last-child {
    padding-right: 12px;
  }
}

.dividerH {
  padding: 30px 8px;
  border-top: 1px solid #E0E0E0;

  &:last-child {
    padding-bottom: 0;
  }
}

.settings-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  row-gap: 16px;

  li {
    display: flex;
    justify-content: space-between;
  }
}
</style>
