import {
  GET_TODOS_FOLDER,
  GET_TODOS_FOLDER_STRUCTURE,
} from './action_types'
import {
  SET_TODOS_FOLDER,
  SET_TODOS_FOLDER_ERROR,
  SET_TODOS_FOLDER_PENDING,
  SET_TODOS_FOLDER_STRUCTURE,
  SET_TODOS_FOLDER_STRUCTURE_PENDING,
  SET_TODOS_FOLDER_STRUCTURE_ERROR,
} from './mutation_types'
import todosService from '../../../services/todos.service'

export const actions = {
  async [GET_TODOS_FOLDER] ({ commit }, { mnemo, queryObject }) {
    commit(SET_TODOS_FOLDER_PENDING, true)
    try {
      const { data } = await todosService.getTodosFolder(mnemo, queryObject)
      commit(SET_TODOS_FOLDER, data)
    } catch (error) {
      commit(SET_TODOS_FOLDER_ERROR, error)
      throw error
    } finally {
      commit(SET_TODOS_FOLDER_PENDING, false)
    }
  },
  async [GET_TODOS_FOLDER_STRUCTURE] ({ commit }, { mnemo, queryObject }) {
    commit(SET_TODOS_FOLDER_STRUCTURE_PENDING, true)
    commit(SET_TODOS_FOLDER_STRUCTURE_ERROR, null)
    try {
      const { data } = await todosService.getTodosFolderStructure(mnemo, queryObject)
      commit(SET_TODOS_FOLDER_STRUCTURE, data.titles)
    } catch (error) {
      commit(SET_TODOS_FOLDER_STRUCTURE_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_TODOS_FOLDER_STRUCTURE_PENDING, false)
    }
  },
}
