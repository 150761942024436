<template>
  <div ref="container"
       v-intersect.quiet="{
        handler: onIntersect,
        options: {
          threshold: 0.2,
        }}"
  >
    <template v-if="pageContentData">
      <v-img ref="page"
             :src="`data:image/png;base64,${pageContentData}`"
             :width="placeholderStyles.width"
             :max-width="placeholderStyles.width"
             :height="placeholderStyles.height"
             class="PrepareSignaturePage-page"
             @dragover.prevent
             @drop="dropItem"
      >
          <PrepareSignatureTag v-for="(tag) in pageTags"
                               :key="`tag-${tag.id}`"
                               :envelope-id="envelopeId"
                               :envelope-type="envelopeType"
                               :is-pending="isPending"
                               :page="page"
                               :pointerEventDisabled="(dragTagActive.value && dragTagActive.tag !== tag) || isReadOnly"
                               :signers="signers"
                               :tag="tag"
                               @delete-initials-tag="onDeleteInitialsTags"
                               @delete-tag="onDeleteTag"
                               @duplicate-checkbox="onDuplicateCheckbox"
                               @duplicate-tag="onDuplicateTag"
                               @drag-tag-active="onDragTagActive"
                               @patch-tag="onPatchTag"
                               @tag-ready="onTagReady"
          />
      </v-img>
    </template>
    <template v-else>
      <div class="PrepareSignaturePage-placeholder mx-auto"
           :style="placeholderStyles"
      >
        <div class="PrepareSignaturePage-placeholderContent">
          <div>{{ $t('common.pageLoading') }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'

import { INTERSECT_DEBOUNCE_TIMER } from '@/common/constants'

import PrepareSignatureTag from './PrepareSignatureTag'

export default {
  name: 'PrepareSignaturePage',
  components: { PrepareSignatureTag },
  props: {
    dragTagActive: {
      type: Object,
      required: true,
    },
    envelopeId: {
      type: Number,
      required: true,
    },
    envelopeType: {
      type: String,
      required: true,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
    },
    page: {
      type: Object,
      required: true,
    },
    pageContent: {
      type: Object,
      default: () => {},
    },
    pagesTags: {
      type: Array,
      required: true,
    },
    signers: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      containerWidth: null,
      dropInfos: [],
      tags: [],
      tagsReady: [],
    }
  },
  computed: {
    pageContentData () {
      return this.pageContent?.data
    },
    pageTags () {
      return this.pagesTags.filter(tag => tag.pageNumber === this.page.pageNumber)
    },
    pageTagsReady () {
      return this.pageTags.length === 0 || this.tagsReady.length === this.pageTags.length
    },
    placeholderStyles () {
      return {
        width: `${this.page.width}px`,
        height: `${this.page.height}px`,
      }
    },
  },
  mounted () {
    this.containerWidth = this.$refs.container.clientWidth
  },
  created () {
    this.onIntersect = debounce(this.onIntersect, INTERSECT_DEBOUNCE_TIMER)
  },
  methods: {
    dropItem (event) {
      this.$emit('drag-tag-active', false)

      if (event.dataTransfer.getData('action') === 'post') {
        const tagData = this.preparePostTag(event)

        this.$emit('post-tag', tagData)
      } else if (event.dataTransfer.getData('action') === 'patch') {
        const tagData = this.preparePatchTag(event)

        this.$emit('patch-tag', tagData)
      }
    },
    onDeleteInitialsTags (data) {
      this.$emit('delete-initials-tag', data)
    },
    onDeleteTag (tagId) {
      this.$emit('delete-tag', tagId)
    },
    onDuplicateCheckbox (data) {
      this.$emit('duplicate-checkbox', data)
    },
    onDuplicateTag (tagId) {
      this.$emit('duplicate-tag', tagId)
    },
    onDragTagActive (data) {
      this.$emit('drag-tag-active', data)
    },
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$emit('page-visible')
      }
    },
    onPatchTag (tagData) {
      this.$emit('patch-tag', tagData)
    },
    preparePatchTag (event) {
      const tag = this.pagesTags.find(tag => tag.id === Number(event.dataTransfer.getData('tagId')))
      const tagEl = document.querySelector(`#tag-${tag.id}`)
      const tagCoordinates = {
        x: null,
        y: null,
      }
      const cursorOffset = {
        x: 0,
        y: 0,
      }
      if (event.dataTransfer.getData('cursorOffsetX')) {
        cursorOffset.x = event.dataTransfer.getData('cursorOffsetX')
      }
      if (event.dataTransfer.getData('cursorOffsetY')) {
        cursorOffset.y = event.dataTransfer.getData('cursorOffsetY')
      }
      tagCoordinates.x = event.x - this.$refs.page.$el.getBoundingClientRect().left - cursorOffset.x
      tagCoordinates.y = event.y - this.$refs.page.$el.getBoundingClientRect().top - cursorOffset.y
      if (tagCoordinates.x < 0) { tagCoordinates.x = 0 }
      if (tagCoordinates.y < 0) { tagCoordinates.y = 0 }
      if (tagCoordinates.x + tagEl.offsetWidth > this.page.width) { tagCoordinates.x = this.page.width - tagEl.offsetWidth }
      if (tagCoordinates.y + tagEl.offsetHeight > this.page.height) { tagCoordinates.y = this.page.height - tagEl.offsetHeight }

      return {
        id: tag.id,
        tagSigId: tag.signatureId,
        tagX: tagCoordinates.x,
        tagY: tagCoordinates.y,
        tagWidth: tag.width || tagEl.offsetWidth,
        tagHeight: tag.height || tagEl.offsetHeight,
        tagType: tag.type,
        tagPage: this.page.pageNumber,
        tagData: tag.data,
      }
    },
    preparePostTag (event) {
      const tagCoordinates = {
        x: event.layerX,
        y: event.layerY,
      }
      const tagSize = {
        width: Number(event.dataTransfer.getData('tagWidth')),
        height: Number(event.dataTransfer.getData('tagHeight')),
      }
      if (tagCoordinates.x + tagSize.width > this.page.width) { tagCoordinates.x = this.page.width - tagSize.width }
      if (tagCoordinates.y + tagSize.height > this.page.height) { tagCoordinates.y = this.page.height - tagSize.height }

      const tagData = {
        tagSigId: event.dataTransfer.getData('signatureId'),
        tagX: tagCoordinates.x,
        tagY: tagCoordinates.y,
        tagWidth: tagSize.width,
        tagHeight: tagSize.height,
        tagType: event.dataTransfer.getData('tagType'),
        tagPage: this.page.pageNumber,
        tagData: null,
      }

      return tagData
    },
    onTagReady (tagId) {
      if (!this.tagsReady.includes(tagId)) { this.tagsReady.push(tagId) }
    },
  },
  watch: {
    pageTagsReady: {
      handler (newVal) {
        if (newVal) {
          this.$emit('page-tags-ready', this.page.pageNumber)
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
  .PrepareSignaturePage-page,
  .PrepareSignaturePage-placeholder {
    margin: 0 auto;
    position: relative;
  }
  .PrepareSignaturePage-placeholder {
    background-color: #fff;
  }
  .PrepareSignaturePage-placeholderContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
</style>
