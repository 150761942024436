type DocumentAISummariesCredits = {
  remaining: number
  renewalDate: string
  total: number
}

export type DocumentsAISummaryState = {
  documentToSummarize: object | null
  requestSummaryPending: boolean
  summariesCredits: DocumentAISummariesCredits | null
  summariesCreditsPending: boolean
}

export const state: DocumentsAISummaryState = {
  documentToSummarize: null,
  requestSummaryPending: false,
  summariesCredits: null,
  summariesCreditsPending: false,
}
