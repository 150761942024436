<template>
  <div class="WitnessSelectionCard pa-4">
    <div class="d-flex align-center justify-space-between my-4">
      <div class="d-flex align-center">
        <div class="WitnessSelectionCard-color mr-2"
             :style="{backgroundColor: color}"
        ></div>
        <app-text as="span" variant="small-bold">{{ $t('project.signing-checklist.witness.WitnessSelectionCard.selectSigner', {signerNumber: displayedIndex}) }}</app-text>
      </div>
      <AppButton v-if="canDelete"
                 type="icon"
                 @click="$emit('delete')"
      >
        <template #left-icon>
          <font-awesome-icon :icon="['far', 'trash-can']"/>
        </template>
      </AppButton>
    </div>
    <div class="my-4">
      <v-autocomplete :value="witnessSelection.signer.$model"
                      :items="computedSigners"
                      :error-messages="signerErrors"
                      :hide-details="!signerErrors.length"
                      auto-select-first
                      color="brand-secondary"
                      clearable
                      :readonly="witnessSelection.signer.$model !== null"
                      clear-icon="far fa-xmark"
                      item-value="id"
                      item-text="fullName"
                      outlined
                      dense
                      :class="{'autocomplete_witness--clickable' : witnessSelection.signer.$model === null}"
                      @input="onSignerInput"
      >
        <template #selection="{item}">
          <app-text as="span">{{item.fullName}}</app-text>
        </template>
        <template #item="{item}">
          <div class="d-flex align-center">
            <AppAvatar class="mr-2" :initials="item.initials"/>
            <app-text as="span" :variant="'small-regular'" class="grey--text text--darken-2">{{ item.fullName }}</app-text>
          </div>
        </template>
      </v-autocomplete>
    </div>
    <template v-if="witnessSelection.signer.$model">
      <v-divider/>
      <div class="d-flex align-center my-4">
        <AppIcon icon-name="link"
                 :style="{color: color}"
                 class="mr-2"
        />
        <app-text as="span" variant="small-bold">
          {{ $t('project.signing-checklist.witness.WitnessSelectionCard.witnesses') }}
          <app-text as="span" variant="small-regular" class="grey--text text--lighten-1">{{ $t('project.signing-checklist.witness.WitnessSelectionCard.witnessesInfo') }}</app-text>
        </app-text>
      </div>
      <div class="mt-4">
        <v-autocomplete :value="witnessSelection.witness.$model"
                        :items="computedWitnesses"
                        :menu-props="{closeOnContentClick: witnessSelection.witness.$model.length >=2}"
                        :error-messages="witnessesErrors"
                        :hide-details="!witnessesErrors.length"
                        auto-select-first
                        color="brand-secondary"
                        clearable
                        :readonly="hasTwoWitnesses"
                        item-text="fullName"
                        item-value="id"
                        :item-disabled="() => { return hasTwoWitnesses}"
                        hide-selected
                        clear-icon="far fa-xmark"
                        multiple
                        outlined
                        dense
                        class="autocomplete_witness"
                        :class="{'autocomplete_witness--clickable' : !hasTwoWitnesses}"
                        @input="onWitnessInput"
        >
          <template #selection="{item}">
            <v-chip close
                    close-icon="far fa-xmark"
                    class="grey lighten-5"
                    label
                    @click:close="onItemDeleteClick(item)"
            >
              <span class="mr-2">{{ item.fullName }}</span>
            </v-chip>
          </template>
          <template #item="{item, on, attrs}">
            <v-list-item
              v-bind="attrs"
              :class="{'d-none' :item.disabled}"
              v-on="on"
            >
              <v-list-item-content class="d-inline-block">
                <div class="d-flex justify-space-between align-center">
                  <div>
                    <AppAvatar class="mr-2 d-inline-flex" :initials="item.initials"/>
                    <app-text as="span" :variant="'small-regular'" class="grey--text text--darken-2">{{ item.fullName }}</app-text>
                  </div>
                  <div>
                    <v-chip v-if="item.isSigner"
                            small
                            color="indigo lighten-5"
                            text-color="indigo"
                    >
                      <app-text as="span" variant="x-small-bold">{{ $tc('common.signer', 1) }}</app-text>
                    </v-chip>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'

import AppAvatar from '@/design-system/AppAvatar.vue'
import AppButton from '@/design-system/buttons/AppButton.vue'
import AppIcon from '@/design-system/icons/AppIcon.vue'
import { SignersColors } from '@/project/signing-checklist/prepare-signature/signers-colors'
import { Member } from '@/types/member'

type MemberAutocomplete = Member & {
  disabled: boolean;
  isSigner: boolean;
};

export default defineComponent({
  name: 'WitnessSelectionCard',
  components: { AppButton, AppIcon, AppAvatar },
  props: {
    witnessSelection: {
      type: Object,
      required: true,
    },
    index: {
      type: Number as PropType<number>,
      required: true,
    },
    signers: {
      type: Array as PropType<Member[]>,
      required: true,
    },
    witnesses: {
      type: Array as PropType<Member[]>,
      required: true,
    },
    selectedSigners: {
      type: Array,
      required: true,
    },
    canDelete: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    color (): string {
      const i = this.index % (Object.entries(SignersColors).length / 2)
      return SignersColors[i]
    },
    displayedIndex (): number {
      return this.index + 1
    },
    hasTwoWitnesses (): boolean {
      return this.witnessSelection.witness.$model.length === 2
    },
    computedSigners (): Member[] {
      return this.signers.filter(s => {
        return !this.selectedSigners.includes(s.id) ||
          (this.selectedSigners.includes(s.id) && s.id === this.witnessSelection.signer.$model)
      })
    },
    computedWitnesses () {
      const witnessesAutocompleteList: MemberAutocomplete[] = []
      this.witnesses.map(s => {
        witnessesAutocompleteList.push({ ...s, disabled: this.witnessSelection.witness.$model.includes(s.id), isSigner: this.signers.includes(s) })
      })
      return witnessesAutocompleteList.sort((a, b) => Number(a.isSigner) - Number(b.isSigner))
    },
    signerErrors (): TranslateResult[] {
      const errors: TranslateResult[] = []
      if (!this.witnessSelection.signer.$dirty) return errors
      !this.witnessSelection.signer.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.signing-checklist.witness.WitnessSelectionCard.signatory') }))
      return errors
    },
    witnessesErrors (): TranslateResult[] {
      const errors: TranslateResult[] = []
      if (!this.witnessSelection.witness.$dirty) return errors
      !this.witnessSelection.witness.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.signing-checklist.witness.WitnessSelectionCard.witnesses') }))
      return errors
    },
  },
  methods: {
    onSignerInput (value) {
      if (!value) {
        this.$emit('add-witness', [])
      }
      this.$emit('add-signer', value)
    },
    onWitnessInput (value) {
      if (value.length <= 2) {
        this.$emit('add-witness', value)
      }
    },
    onItemDeleteClick (item): void {
      const newArray = this.witnessSelection.witness.$model.filter(o => o !== item.id)
      this.$emit('add-witness', newArray)
    },
  },
})
</script>

<style scoped lang="scss">
.WitnessSelectionCard {
  border-radius: 8px;
  border: 1px solid var(--v-grey-lighten4);
  box-shadow: 0 4px 15px 0 rgba(169, 170, 182, 0.15);
  margin-bottom: 8px;

  &-color {
    width: 12px;
    height: 12px;
    border-radius: 4px;
  }
}

.autocomplete_witness {
  &--clickable ::v-deep .v-input__icon--append {
    cursor: pointer;
  }
}
</style>
