<template>
  <div class="segmented-control">
    <v-radio-group
      v-model="activeOptionValue"
      active-class="segmented-control__item--active"
      class="segmented-control__container pa-1"
      hide-details
      required
      row
    >
      <div v-for="option in options"
           :key="`option-${option.value}`"
           :value="option"
           :class="{'segmented-control__item--active': option.value === activeOptionValue}"
           class="segmented-control__item py-2 px-4 clickable"
           @click="activeOptionValue = option.value"
        >
        <app-text as="span" variant="small-bold" class="text-truncated">{{ option.name }}</app-text>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppSegmentedControl',
  props: {
    activeOption: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      activeOptionValue: this.activeOption,
    }
  },
  watch: {
    activeOptionValue (value) {
      this.$emit('change-active-option', value)
    },
  },
})
</script>

<style lang="scss">
.segmented-control {
  background-color: var(--v-grey-lighten5);
  border-radius: 8px;

  &__container {
    margin: 0;

    .v-input--radio-group__input {
      display: flex;
      column-gap: 8px;
      justify-content: space-between;
    }

    .segmented-control__item {
      align-content: center;
      border-radius: 8px;
      color: var(--v-grey-base);
      display: flex;
      justify-content: center;
      transition: all .12s ease-in-out;
      flex: 1;

      &--active {
        color: var(--v-grey-darken3);
        background-color: var(--v-white-base);
      }
    }
  }
}
</style>
