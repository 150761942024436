<template>
  <div>
    <AppTooltip top>
      <template #activator="{attrs, on}">
        <div v-bind="attrs" v-on="on">
          <v-menu offset-y left min-width="150">
            <template #activator="{ on, attrs }">
              <app-button type="icon" v-bind="attrs" v-on="on">
                <font-awesome-icon
                  :icon="['fas', 'ellipsis-vertical']"
                  style="font-size: 16px"
                ></font-awesome-icon>
              </app-button>
            </template>
            <v-list class="py-0">
              <template v-for="(item, i) in savedChecklistMenu">
                <v-list-item :key="`itemMenu-${i}`"
                  @click="handleMenuAction(item.action)"
                >
                  <v-list-item-icon v-if="item.icon"
                                    class="mr-2"
                  >
                    <app-icon :icon-name="item.icon"/>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </div>
      </template>
      <span>{{ $t('common.additionalActions') }}</span>
    </AppTooltip>

    <!-- MODALS -->
    <SavedChecklistDeleteDialog v-if="savedChecklistDeleteDialogData.isOpen"
                                :checklist-id="savedChecklistDeleteDialogData.item.id"
                                @close="onSavedChecklistDeleteDialogClose"
    />
    <SaveChecklistShareDialog v-if="savedChecklistShareDialogData.isOpen"
                              :checklist="savedChecklistShareDialogData.item"
                              @close="onSavedChecklistShareDialogClose"
    />
    <SavedChecklistRenameDialog v-if="savedChecklistRenameDialogData.isOpen"
                                :checklist="savedChecklistRenameDialogData.item"
                                @close="onSavedChecklistRenameDialogClose"
    />
  </div>
</template>

<script>
  import AppTooltip from '@/common/AppTooltip'
  import SaveChecklistShareDialog from '@/project/checklist/dialogs/SaveChecklistShareDialog.vue'
  import SavedChecklistDeleteDialog from '@/project/checklist/dialogs/SavedChecklistDeleteDialog.vue'
  import SavedChecklistRenameDialog from '@/project/checklist/dialogs/SavedChecklistRenameDialog.vue'

  export default {
    name: 'SavedChecklistTableItemMenu',
    components: {
      AppTooltip,
      SavedChecklistRenameDialog,
      SaveChecklistShareDialog,
      SavedChecklistDeleteDialog,
    },
    props: {
      savedChecklist: {
        type: Object,
        required: true,
      },
      isCurrentUserChecklistOwner: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        savedChecklistDeleteDialogData: {
          item: null,
          isOpen: false,
        },
        savedChecklistShareDialogData: {
          item: null,
          isOpen: false,
        },
        savedChecklistRenameDialogData: {
          item: null,
          isOpen: false,
        },
      }
    },
    computed: {
      savedChecklistMenu () {
        const menu = [
          {
            icon: 'pen-to-square',
            text: this.$t('project.documents.table.DocumentsTableItemMenu.rename'),
            action: 'rename',
          },
        ]
        if (this.isCurrentUserChecklistOwner) {
          menu.push(
            {
              icon: 'building',
              text: this.$t('project.documents.table.DocumentsTableItemMenu.accessRights'),
              action: 'share',
            },
            {
              icon: 'trash-can',
              text: this.$t('project.documents.table.DocumentsTableItemMenu.delete'),
              action: 'delete',
            },
          )
        }
        return menu
      },
    },
    methods: {
      openSavedChecklistDeleteDialog (item) {
        this.savedChecklistDeleteDialogData = {
          item,
          isOpen: true,
        }
      },
      onSavedChecklistDeleteDialogClose () {
        this.savedChecklistDeleteDialogData = {
          isOpen: false,
          item: null,
        }
      },
      openSavedChecklistShareDialog (item) {
        this.savedChecklistShareDialogData = {
          item,
          isOpen: true,
        }
      },
      onSavedChecklistShareDialogClose () {
        this.savedChecklistShareDialogData = {
          isOpen: false,
          item: null,
        }
      },
      openSavedChecklistRenameDialog (item) {
        this.savedChecklistRenameDialogData = {
          item,
          isOpen: true,
        }
      },
      onSavedChecklistRenameDialogClose () {
        this.savedChecklistRenameDialogData = {
          isOpen: false,
          item: null,
        }
      },
      handleMenuAction (action) {
        switch (action) {
          case 'delete':
            this.openSavedChecklistDeleteDialog(this.savedChecklist)
            break
          case 'share':
            this.openSavedChecklistShareDialog(this.savedChecklist)
            break
          case 'rename':
            this.openSavedChecklistRenameDialog(this.savedChecklist)
            break
        }
      },
    },
  }
</script>
