<template>
  <v-row class="ArchivesUploadedFileListItem-row align-center">
    <v-col cols="10" sm="11" class="d-flex">
      <ClosdFildersIcon
        :document="file"
        :signed-file="itemIsSignedOnClosd"
        class="mr-1"
      />
      <div>
        <p class="ArchivesUploadedFileListItem-fileName">
          {{ file.name }}
        </p>
        <p class="ArchivesUploadedFileListItem-fileSize">
          <app-filesize-display :value="file.size" />
        </p>
      </div>
    </v-col>
    <v-col cols="2" sm="1" class="text-center">
      <i
        class="ArchivesUploadedFileListItem-deleteBtn fad fa-circle-xmark"
        @click="$emit('deleteFile', file)"
      />
    </v-col>
  </v-row>
</template>

<script>
import ClosdFildersIcon from '../../common/filders/ClosdFildersIcon.vue'

export default {
  name: 'ArchivesUploadedFileListItem',
  components: { ClosdFildersIcon },
  props: {
    file: {
      type: [File, Object],
      required: true,
    },
  },
  computed: {
    fileExtension () {
      if (this.itemIsSignedOnClosd) {
        return 'pdf'
      } else {
        const fileArr = this.file.name.split('.')
        return fileArr[fileArr.length - 1]
      }
    },
    itemIsSignedOnClosd () {
      return this.file.signedOnClosd
    },
  },
}
</script>

<style scoped lang="scss">
.ArchivesUploadedFileListItem-row {
  border-bottom: 1px solid rgb(222, 226, 230);
}
.ArchivesUploadedFileListItem-fileName {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  font-size: 12px;
}
.ArchivesUploadedFileListItem-fileSize {
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: $textGreyLight;
  margin-bottom: 0;
}
.ArchivesUploadedFileListItem-deleteBtn {
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  --fa-primary-color: #333;
  --fa-secondary-color: #d8d8d8;
  font-size: 1.1rem;
  cursor: pointer;
}
</style>
