import { v4 as uuidv4 } from 'uuid'

import { flatten } from '@/common/utils/flatArray'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'
import { BtoMB } from '@/common/utils/sizes'
import i18n from '@/i18n'
import { ENVELOPE_STATUS, ENVELOPE_TYPES, SIGNATURE_TYPES } from '@/project/signing-checklist/constants'

import { isFileSignable } from './files'
import store from '../../store'

export const UPLOAD_SESSION_LIMIT = 30

function findElementByDisplay (display, elements) {
  // We can have an integer as well
  // eslint-disable-next-line eqeqeq
  return elements.find((e) => e.display == display)
}
function findStepParent (step, root) {
  const displayTable = step.display.split('.')
  // The last element is the determinant for the step.
  // We don't need it to find the parent, which has to be a title
  displayTable.pop()
  let parent = { children: root }
  let displayFragment = ''
  for (const displayToAddToSearch of displayTable) {
    displayFragment += displayToAddToSearch
    parent = findElementByDisplay(displayFragment, parent.children)
    displayFragment += '.'
  }
  return parent
}

/**
* NOTE:
* Pour les conditions d'envoi, on montre à l'utilisateur les éléments de façon assez large,
* même si il ne peut pas envoyer fonctionellement. Afin que l'utilisateur puisse savoir pourquoi
* Via la modale ou message donné après l'action. Si aucune modale n'est normalement affichée juste après
* pour confirmer, on check si il manque des informations d'envoi pour réellement faire l'action
*/

function canShowSendStep (step) {
  return (
    (step.envelope.status === 'draft' && step.envelope.validated) ||
      step.envelope.status === 'queued' ||
      step.envelope.status === 'unvalidated'
  )
}

function canShowCancelStep (step) {
  return step.envelope.status === 'sent' || step.envelope.status === 'unvalidated'
}

function canShowDeleteStep (step) {
  return step.envelope.status !== 'sent' && step.envelope.status !== 'sending' && step.envelope.status !== 'unvalidated'
}

function canShowValidateStep (step) {
  return (
    !step.envelope.validated && (
      step.envelope.status === 'draft' ||
      step.envelope.status === 'queued'
    )
  )
}

function canShowReadOnlyStep (step) {
  const readOnlyStatus = [
    ENVELOPE_STATUS.PENDING,
    ENVELOPE_STATUS.QUEUED,
    ENVELOPE_STATUS.SENDING,
    ENVELOPE_STATUS.SENT,
    ENVELOPE_STATUS.SENT_PARTIAL,
    ENVELOPE_STATUS.UNVALIDATED,
    ENVELOPE_STATUS.VOIDED,
  ]
  return readOnlyStatus.includes(step.envelope?.status) || false
}

function canShowResetToDraftStep (step) {
  return step.envelope.status === 'voided'
}

function canShowSignTagsStep (step) {
  return step.envelope.status === 'draft'
}

function canFilesBeAddedToChecklist (files, isCurrentUserPm, maxSignableSize) {
  return isCurrentUserPm &&
    files.length <= UPLOAD_SESSION_LIMIT &&
    files.every((file) => isFileSignable(file, maxSignableSize))
}

function canSwapFile (step) {
  return step.envelope.status === 'draft' && ((step.envelope.validations.length === 0 && step.envelope.validated) || !step.envelope.validated)
}

/**
 * Get the max size supported out of the providers passed as parameters
 * @param {Array} providers An array of providers object with the maxSize property
 * @returns {number} The max size or 0 if it wasn't found or there's no providers
 */
function getProvidersMaxSize (providers = []) {
  const providerMaxSize = providers.map(provider => provider.maxSize)
  const maxSizeInMB = BtoMB(Math.max(...providerMaxSize, 0))
  // We don't want 0.0 to keep a falsy evaluation and avoid a weird decimal anyway
  if (maxSizeInMB !== 0) {
    return maxSizeInMB.toFixed(1)
  }

  return maxSizeInMB
}

function formatImportedFile (file, prevOptions = { id: -1 }) {
  const fileObject = {
    id: file.id,
    objectToPost: {
      signers: [[]],
      validators: [],
      recipients: [],
      observers: [],
      title: file.basename,
      date: null,
      signatureType: '',
      envelopeType: ENVELOPE_TYPES.SINGLE,
      providerOptions: {},
      fileId: null,
      prevId: prevOptions.id,
      retainTags: true,
      shareType: SHARE_TYPE_OPTIONS.PUBLIC,
      withUsers: [],
    },
    // Serves as a buffer for options when they're needed
    providerOptions: {},
    extension: file.extension,
    size: file.size,
    originalFile: file,
    name: file.name,
    status: 'pending',
  }
  // This does assumes that groups are loaded beforehand by the view that used this util
  for (const group of store.state.groups.groups) {
    if (group.isPm) {
      for (const user of group.members) {
        fileObject.objectToPost.observers.push(user.id)
        fileObject.objectToPost.recipients.push(user.id)
      }
    }
  }
  if (prevOptions.type) {
    fileObject.objectToPost.prevType = prevOptions.type
  }
  if (file.hasOwnProperty('id')) {
    fileObject.objectToPost.fileId = file.id
    fileObject.objectToPost.fileName = file.basename
    fileObject.extension = file.ext ?? file.extension
  } else if (file instanceof File) {
    fileObject.objectToPost.fileRaw = file
    fileObject.objectToPost.fileName = file.name
    fileObject.objectToPost.title = file.name
    fileObject.extension = file.name.split('.').pop()
    fileObject.status = 'queued'
    fileObject.queueId = uuidv4()
    fileObject.progress = 0
  }
  return fileObject
}

/**
 * Apply filters + search query to checklistTasks items and return the result
 * @param {Array} checklistTasks An array of checklist tasks
 * @param {Array} filters An array of Filter objects from filters pane
 * @param {Array} searchQuery An array of strings from the search field
 * @returns {Array} The resulting array of flat items after each filters have been applied
 */
export function filterSigningChecklistTasks (checklistTasks, filters, searchQuery) {
  let filteredItems = checklistTasks

  const envelopeTypesFilters = filters.filter(filter => filter.category.key === 'envelopeTypes')
  const signingTypesFilters = filters.filter(filter => filter.category.key === 'signingTypes')
  const statusesFilters = filters.filter(filter => filter.category.key === 'statuses')

  // matching search query
  if (searchQuery.length > 0) {
    filteredItems = filteredItems.filter(item => {
      const observers = item.envelope?.observers || []
      const signatories = flatten([], item.envelope?.signatories)
      const validators = item.envelope?.validations.map(validation => validation.validator) || []

      const signers = flatten([], signatories.map(signatory => {
        return signatory?.fullName ? signatory : signatory.signers
      }))

      return searchQuery.some(x => item.title?.toLowerCase().includes(x.toLowerCase())) ||
      searchQuery.some(x => observers.some(observer => observer.fullName.toLowerCase().includes(x.toLowerCase()))) ||
      searchQuery.some(x => signers.some(signer => signer.fullName.toLowerCase().includes(x.toLowerCase()))) ||
      searchQuery.some(x => validators.some(validator => validator.fullName.toLowerCase().includes(x.toLowerCase())))
    })
  }

  // matching filters
  if (envelopeTypesFilters.length > 0) {
    filteredItems = filteredItems.filter(
      item => envelopeTypesFilters.some(filter =>
        (filter.value.key === item.envelope?.envelopeType && !item.envelope?.parentMultienvelopeId) ||
        (filter.value.key === 'fromBulk' && item.envelope?.parentMultienvelopeId)),
    )
  }
  if (signingTypesFilters.length > 0) {
    filteredItems = filteredItems.filter(
      item => signingTypesFilters.some(filter => filter.value.key === `${item.envelope?.provider}.${item.envelope?.signatureType}`),
    )
  }
  if (statusesFilters.length > 0) {
    filteredItems = filteredItems.filter(
      item => statusesFilters.some(filter => filter.value.key === item.envelope?.status),
    )
  }

  return filteredItems
}

function formatStep (step) {
  const formattedStep = {
    id: step.id,
    objectToPost: {
      id: step.id,
      validators: step.envelope.validations.map((v) => v.validator.id),
      recipients: step.envelope.recipients.map((r) => r.id),
      observers: step.envelope.observers.map((o) => o.id),
      witnesses: formatWitnesses(step.envelope.witnesses),
      title: step.title,
      fileName: step.title,
      envelope: step.envelope,
      date: step.date,
      signatureType: step.envelope.provider + '.' + step.envelope.signatureType,
      envelopeType: step.envelope.envelopeType,
      providerOptions: step.envelope.providerOptions ?? {},
      fileId: step.envelope.file.id,
    },
    // Serves as a buffer for options when they're needed
    providerOptions: step.envelope.providerOptions ?? {},
    extension: step.envelope.file.ext,
    size: step.envelope.file.size,
    originalFile: step,
    name: step.title,
    status: 'pending',
  }

  const { commonSigners, uniqueSigners } = getCommonAndUniqueSignerIdsFromSignersGroup(step.envelope.signatories)
  formattedStep.objectToPost.signers = commonSigners
  if (step.envelope.envelopeType === ENVELOPE_TYPES.MULTIPLE) {
    formattedStep.objectToPost.uniqueSigners = uniqueSigners
  }
  return formattedStep
}

function formatWitnesses (witnesses) {
  const signerWithWitnesses = []
  for (const witness of witnesses) {
    const index = signerWithWitnesses.findIndex(item => item.signer === witness.witnessOfSignerId)
    if (index === -1) {
      signerWithWitnesses.push({ signer: witness.witnessOfSignerId, witness: [witness.signerId] })
    } else {
      signerWithWitnesses[index].witness.push(witness.signerId)
    }
  }
  return signerWithWitnesses
}

/**
 * Find the common and unique signers ids from a signersGroup array
 * @param {Array} signersGroup - An array of a group of signers
 * @returns { { commonSigners: Array<Array<number>>, uniqueSigners: Array<number> } }
 */
function getCommonAndUniqueSignerIdsFromSignersGroup (signersGroup) {
  const signersDescription = {
    commonSigners: [],
    uniqueSigners: [],
  }
  for (const group of signersGroup) {
    const g = []
    for (const signer of group) {
      if (signer.signatureType === ENVELOPE_TYPES.SINGLE) {
        g.push(signer.id || signer.signerId)
      } else if (signer.signatureType === ENVELOPE_TYPES.MULTIPLE) {
        signersDescription.uniqueSigners = signer.signers.map(signer => signer.id || signer.signerId)
      }
    }
    signersDescription.commonSigners.push(g)
  }
  if (!signersDescription.commonSigners.length) {
    signersDescription.commonSigners = [[]]
  }
  return signersDescription
}

function formatNumberedSignerName (signature, number, isMultipleEnvelope) {
  if (isMultipleEnvelope) {
    return signature.signatureType === SIGNATURE_TYPES.MULTIPLE
      ? `${i18n.tc('common.signerType.uniqueSigner', 1)} #${number}`
      : `${i18n.tc('common.signerType.commonSigner', 1)} #${number}`
  }

  return `${i18n.tc('common.signer', 1)} #${number}`
}

function formatStepFromEnvelopeTemplate (envelopeTemplate) {
  const isMultiEnvelope = envelopeTemplate.envelopeType === ENVELOPE_TYPES.MULTIPLE

  return {
      id: envelopeTemplate.file.id,
      objectToPost: {
        signers: [[]],
        uniqueSigners: [],
        validators: [],
        recipients: store.getters['groups/projectManagers'].map(pm => pm.id),
        observers: [],
        title: '',
        date: null,
        signatureType: `${envelopeTemplate.provider}.${envelopeTemplate.signatureType}`,
        envelopeType: envelopeTemplate.envelopeType,
        providerOptions: {},
        fileId: envelopeTemplate.file.id,
        fileName: envelopeTemplate.file.basename,
        prevId: -1,
        retainTags: true,
      },
      providerOptions: {},
      extension: envelopeTemplate.file.ext,
      size: envelopeTemplate.file.size,
      originalFile: envelopeTemplate.file,
      name: envelopeTemplate.file.basename,
      status: 'pending',
      templateId: envelopeTemplate.id,
      templateName: envelopeTemplate.title,
      templateSignatures: envelopeTemplate.signatures.map((templateSignature, index) => {
        return {
          id: templateSignature.id,
          signerName: templateSignature.signerName ?? null,
          assignedSigner: null,
          signerType: templateSignature.signatureType,
        }
      }),
    }
}

export {
  canFilesBeAddedToChecklist,
  canShowCancelStep,
  canShowDeleteStep,
  canShowReadOnlyStep,
  canShowResetToDraftStep,
  canShowSendStep,
  canShowSignTagsStep,
  canShowValidateStep,
  canSwapFile,
  findElementByDisplay,
  findStepParent,
  formatImportedFile,
  formatNumberedSignerName,
  formatStep,
  formatStepFromEnvelopeTemplate,
  getCommonAndUniqueSignerIdsFromSignersGroup,
  getProvidersMaxSize,
}
