import documentsService from '@/services/documents.service'
import userService from '@/services/user.service'
import { DocumentsAISummaryActionType } from '@/store/modules/documents-ai-summary/action_types'
import { DocumentsAISummaryMutationType } from '@/store/modules/documents-ai-summary/mutation_type'
import { StoreActions } from '@/types/store'

export const actions: StoreActions<DocumentsAISummaryActionType, DocumentsAISummaryMutationType> = {
  async REQUEST_SUMMARY ({ commit, rootGetters }, documentId) {
    commit('SET_REQUEST_SUMMARY_PENDING', true)

    try {
      const { data } = await documentsService.requestDocumentAISummary(rootGetters['room/roomMnemo'], documentId)
      return data
    } finally {
      commit('SET_REQUEST_SUMMARY_PENDING', false)
    }
  },
  async LOAD_SUMMARIES_CREDITS ({ commit }) {
    commit('SET_SUMMARIES_CREDITS', null)
    commit('SET_SUMMARIES_CREDITS_PENDING', true)

    try {
      const { data } = await userService.getCurrentUserAITokens()
      commit('SET_SUMMARIES_CREDITS', data)
    } catch (error) {
      commit('SET_SUMMARIES_CREDITS', null)
      throw error
    } finally {
      commit('SET_SUMMARIES_CREDITS_PENDING', false)
    }
  },
}
