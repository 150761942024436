<template>
  <div>
    <v-row no-gutters>
      <div class="text-subtitle-1">
        <b>{{$t('project.documents.pane.DocumentsPaneDuplicate.duplicateTo')}}</b>
      </div>
    </v-row>
    <v-row no-gutters class="mt-4">
      <AppFildersTree v-model="selectedFolder"
                      :root-folder="fullTree"
                      selection-type="independent"
                      folders-only
                      openFirstNode
                      style="width: 100%;"
      />
    </v-row>
    <v-row>
      <v-col>
        <AppButton color="primary"
                   outlined
                   block
                   @click="close"
        >
          {{$t('common.cancel')}}
        </AppButton>
      </v-col>
      <v-col>
        <AppButton color="primary"
                   block
                   :disabled="isDuplicateDisabled"
                   :loading="duplicateLoading"
                   @click="duplicate"
        >
          {{$t('project.documents.pane.DocumentsPaneDuplicate.duplicate')}}
        </AppButton>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import { CLOSE_TABLE_DOCUMENTS_PANE, LOAD_FOLDERS_TREE, DUPLICATE_FILDERS } from '@/store/modules/documents/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

import AppFildersTree from '../../../common/AppVuetifyFildersTree'
import AppButton from '../../../common/buttons/AppButton'

export default {
  name: 'DocumentsPaneDuplicate',
  components: { AppButton, AppFildersTree },
  data () {
    return {
      selectedFolder: [],
    }
  },
  computed: {
    ...mapState('documents', ['documents', 'fullTree', 'duplicateLoading', 'documentsTablePane']),
    isDuplicateDisabled () {
      return this.documentsTablePane.documents.length === 0 || !this.isSelectedFolderValid
    },
    isSelectedFolderValid () {
      return this.selectedFolder.length !== 0 &&
          this.selectedFolder[0]?.specialFolder !== 'todo' &&
          this.documentsTablePane.documents.every(doc => doc.type !== 'folder')
    },
  },
  methods: {
    ...mapActions('documents', [CLOSE_TABLE_DOCUMENTS_PANE, LOAD_FOLDERS_TREE, DUPLICATE_FILDERS]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async duplicate () {
      try {
        await this.DUPLICATE_FILDERS({
          documents: this.documentsTablePane.documents,
          targetFolderId: this.selectedFolder[0].id,
        })
        this.close()
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.documents.pane.DocumentsPaneDuplicate.successMessage'))
      } catch {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.pane.DocumentsPaneDuplicate.errorMessage'))
      }
    },
    close () {
      this.CLOSE_TABLE_DOCUMENTS_PANE()
      this.selectedFolder = []
    },
  },
}
</script>
