<template>
  <div
    ref="triggerEl"
    class="popover"
    @click="handleTriggerClick"
  >
    <slot name="trigger"/>
  </div>
</template>

<script>
import Vue from 'vue'

import PopoverContent from './PopoverContent.vue'

export default {
  name: 'Popover',
  props: {
    position: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom'].includes(value),
    },
    horizontalPosition: {
      type: String,
      default: 'middle',
      validator: (value) => ['left', 'middle', 'right'].includes(value),
    },
    autoCloseDelay: {
      type: Number,
      default: 0, // 0 means no automatic closing
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Number,
      default: 10, // 10px offset by default
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      popoverVM: null,
      autoCloseTimer: null,
    }
  },
  methods: {
    handleTriggerClick () {
      if (!this.popoverVM && !this.disabled) {
        this.openPopover()
      }
    },
    openPopover () {
      this.createPopover()
      if (this.autoCloseDelay > 0) {
        this.setAutoCloseTimer()
      }
    },
    closePopover () {
      if (this.popoverVM) {
        this.popoverVM.$el.classList.add('fade-leave-active')
        this.popoverVM.$el.classList.add('fade-leave-to')
        // Wait for the transition to end before destroying the popover
        this.popoverVM.$el.addEventListener('transitionend', this.destroyPopover, { once: true })
      }
      this.clearAutoCloseTimer()
    },
    createPopover () {
      // Note: When migrating to Vue 3, use createApp instead of Vue.extend
      const PopoverConstructor = Vue.extend(PopoverContent)
      this.popoverVM = new PopoverConstructor({
        propsData: {
          customStyle: this.calculatePosition(),
        },
      })

      this.popoverVM.$slots.default = this.$slots.content

      this.popoverVM.$mount()
      document.body.appendChild(this.popoverVM.$el)

      this.$nextTick(() => {
        this.adjustPosition()
      })

      window.addEventListener('resize', this.adjustPosition)
    },
    destroyPopover () {
      if (this.popoverVM) {
        document.body.removeChild(this.popoverVM.$el)
        this.popoverVM.$destroy()
        this.popoverVM = null
      }
      window.removeEventListener('resize', this.adjustPosition)
    },
    calculatePosition () {
      const triggerRect = this.$refs.triggerEl.getBoundingClientRect()
      let left

      switch (this.horizontalPosition) {
        case 'left':
          left = triggerRect.left
          break
        case 'right':
          left = triggerRect.right
          break
        default: // 'middle'
          left = triggerRect.left + (triggerRect.width / 2)
      }

      let top
      if (this.position === 'top') {
        top = triggerRect.top - this.offset
      } else {
        top = triggerRect.bottom + this.offset
      }

      return {
        left: `${left}px`,
        top: `${top}px`,
        transform: this.getTransform(),
      }
    },
    getTransform () {
      switch (this.horizontalPosition) {
        case 'left':
          return 'translateX(0)'
        case 'right':
          return 'translateX(-100%)'
        default: // 'middle'
          return 'translateX(-50%)'
      }
    },
    adjustPosition () {
      if (this.popoverVM) {
        this.$nextTick(() => {
          const popoverRect = this.popoverVM.$el.getBoundingClientRect()
          const triggerRect = this.$refs.triggerEl.getBoundingClientRect()
          const style = this.calculatePosition()

          // Adjust vertical position to avoid overlapping
          if (this.position === 'top') {
            style.top = `${triggerRect.top - popoverRect.height - this.offset}px`
          } else {
            style.top = `${triggerRect.bottom + this.offset}px`
          }

          Object.assign(this.popoverVM.$el.style, style)
        })
      }
    },
    setAutoCloseTimer () {
      this.clearAutoCloseTimer()
      this.autoCloseTimer = setTimeout(() => {
        this.closePopover()
      }, this.autoCloseDelay)
    },
    clearAutoCloseTimer () {
      if (this.autoCloseTimer) {
        clearTimeout(this.autoCloseTimer)
        this.autoCloseTimer = null
      }
    },
  },
  created () {
    if (this.opened) {
      this.openPopover()
    }
  },
  beforeDestroy () {
    this.closePopover()
  },
  watch: {
    opened (value) {
      if (value) {
        this.openPopover()
      } else {
        this.closePopover()
      }
    },
  },
}
</script>

<style scoped>
.popover {
  display: inline-block;
  width: fit-content;
}
</style>
