<template>
  <div v-click-outside="onClickOutside"
       class="py-2"
       :class="{'px-2': $vuetify.breakpoint.mdAndUp}"
  >
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <div class="d-flex align-center"
           :class="{ 'is-awaiting-delete-validation': isAwaitingDeleteValidation }"
      >
        <AppTooltip v-if="!isChecklistFiltered && isPm" top>
          <template #activator="{on, attrs}">
            <font-awesome-icon :icon="['far', 'arrows-alt']"
                               class="dragIcon"
                               style="cursor: grab"
                               v-bind="attrs"
                               v-on="on"
                               @click.stop
            ></font-awesome-icon>
          </template>
          <span>{{ $t('project.checklist.task.ChecklistTableTaskDocument.dragTooltip') }}</span>
        </AppTooltip>
        <v-checkbox v-if="isPm"
                    hide-details
                    readonly
                    :input-value="isTaskSelected"
                    :ripple="false"
                    class="mt-0 pt-0 ml-2"
                    @click.stop="onSelectedTaskClick"
        />
        <div class="font-weight-medium mr-1 no-shrink">
          {{ item.display }}
        </div>
        <template v-if="editTitleIsActive">
          <AppTextField ref="taskTitleField"
                        v-model.trim="editableTask.title"
                        autofocus
                        maxlength="100"
                        counter="100"
                        @blur="prepareEditTask"
                        @keypress.enter="prepareEditTask"
                        @click.stop
          />
        </template>
        <template v-else-if="isPm">
          <div class="ChecklistTableTaskDocument-title--inactive font-weight-medium"
               @click.stop="activeEditTitleMode"
          >
            {{ computedTitle }}
          </div>
        </template>
        <template v-else>
          <div class="font-weight-bold">
            {{ computedTitle }}
          </div>
        </template>
      </div>
      <div class="mt-2">
        <template v-if="!item.description && !editDescriptionIsActive">
          <template v-if="isPm">
            <span class="ChecklistTableTaskDocument-noDescription"
                  @click.stop="activeEditDescriptionMode"
            >
              {{ $t('project.checklist.task.ChecklistTableTaskDocument.noDescription') }}
            </span>
          </template>
        </template>
        <template v-if="editDescriptionIsActive">
          <VisualTextEditor ref="taskDescriptionField"
                            v-model="editableTask.description"
                            autofocus
                            counter="footer"
                            :error-messages="taskDescriptionErrors"
                            :max-length="taskDescriptionMaxLength"
                            @blur="!validateTaskDescriptionDisabled && prepareEditTask"
          />
          <div class="text-right mt-2">
            <AppTooltip top>
              <template #activator="{on, attrs}">
                <AppButton class="blur-editor-exception mr-2"
                           icon
                           :disabled="isPending"
                           v-bind="attrs"
                           v-on="on"
                           @click="editDescriptionIsActive = false"
                >
                  <font-awesome-icon :icon="['fad', 'circle-xmark']"
                                     style="font-size: 20px;"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{$t('common.cancel')}}</span>
            </AppTooltip>
            <AppTooltip top>
              <template #activator="{on, attrs}">
                <AppButton color="success"
                           icon
                           v-bind="attrs"
                           class="blur-editor-exception"
                           :loading="isPending"
                           :disabled="isPending || taskDescriptionIsTooLong"
                           v-on="on"
                           @click="prepareEditTask"
                >
                  <font-awesome-icon :icon="['fad', 'circle-check']"
                                     style="font-size: 20px;"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{$t('common.save')}}</span>
            </AppTooltip>
          </div>
        </template>
        <template v-if="item.description && !editDescriptionIsActive">
          <template v-if="isPm">
            <div class="ChecklistTableTaskDocument-description--inactive"
                 @click.stop="activeEditDescriptionMode"
                 v-html="computedDescription"
            />
          </template>
          <template v-else>
            <div v-html="computedDescription"/>
          </template>
        </template>
      </div>
      <div class="mt-2 d-flex align-center">
        <ChecklistTableTaskAttachedFilesDialog :task="item"
                                               :mnemo="mnemo"
                                               @refreshTask="prepareRefreshTask"
        />
        <RightsDisplayButton
          v-if="isPm && item.rights"
          :rights="item.rights"
          class="ml-2"
          @click="prepareShareTasksDialog"
        />
      </div>
    </template>

    <template v-else>
      <div class="ChecklistTableTaskDocument-mobileRow pb-2 pl-2">
        <div class="d-flex align-center flex-wrap">
          <v-checkbox v-if="isPm"
                      hide-details
                      readonly
                      :ripple="false"
                      class="mt-0 pt-0"
                      @click.stop
          />
          <ChecklistTableTaskAttachedFilesDialog :task="item"
                                                 :mnemo="mnemo"
                                                 @refreshTask="prepareRefreshTask"
          />
          <RightsDisplayButton v-if="isPm && item.rights"
                               :rights="item.rights"
                               class="ml-2"
                               @click="prepareShareTasksDialog"
          />
          <ChecklistTableTaskMenu :mnemo="mnemo"
                                  :item="item"
                                  :parent="parent"
          />
        </div>
      </div>
      <div class="ChecklistTableTaskDocument-mobileRow ChecklistTableTaskDocument-mobileRowGrid">
        <div class="pa-2">
          {{ $t('project.checklist.task.ChecklistTableTaskDocument.task') }}
        </div>
        <div class="pa-2">
          <div class="d-flex align-center">
            <div class="font-weight-bold mr-1 no-shrink">
              {{ item.display }}
            </div>
            <template v-if="editTitleIsActive">
              <AppTextField ref="taskTitleField"
                            v-model.trim="editableTask.title"
                            autofocus
                            maxlength="100"
                            counter="100"
                            @blur="prepareEditTask"
                            @keypress.enter="prepareEditTask"
                            @click.stop
              />
            </template>
            <template v-else-if="isPm">
              <div class="ChecklistTableTaskDocument-title--inactive font-weight-bold"
                   @click.stop="activeEditTitleMode"
              >
                {{ item.title || '' }}
              </div>
            </template>
            <template v-else>
              <div class="font-weight-bold">
                {{ item.title || '' }}
              </div>
            </template>
          </div>
          <div class="mt-3">
            <template v-if="!item.description && !editDescriptionIsActive && isPm">
              <span class="ChecklistTableTaskDocument-noDescription"
                    @click.stop="activeEditDescriptionMode"
              >
                {{ $t('project.checklist.task.ChecklistTableTaskDocument.noDescription') }}
              </span>
            </template>
            <template v-if="editDescriptionIsActive">
              <VisualTextEditor ref="taskDescriptionField"
                                v-model="editableTask.description"
                                counter="footer"
                                :error-messages="taskDescriptionErrors"
                                :max-length="taskDescriptionMaxLength"
                                @blur="!validateTaskDescriptionDisabled && prepareEditTask"
              />
              <div class="text-right mt-2">
                <AppButton color="white"
                           class="mr-2 blur-editor-exception"
                           :disabled="isPending"
                           @click="editDescriptionIsActive = false"
                >
                  {{$t('common.cancel')}}
                </AppButton>
                <AppButton color="primary"
                           class="blur-editor-exception"
                           :loading="isPending"
                           :disabled="isPending || taskDescriptionIsTooLong"
                           @click="prepareEditTask"
                >
                  {{$t('common.save')}}
                </AppButton>
              </div>
            </template>
            <template v-if="item.description && !editDescriptionIsActive">
              <template v-if="isPm">
                <div class="ChecklistTableTaskDocument-description--inactive"
                     @click.stop="activeEditDescriptionMode"
                     v-html="item.description"
                />
              </template>
              <template v-else>
                <div v-html="item.description"></div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { escapeHtml } from '@/common/utils/strings'

import ChecklistTableTaskMenu from './ChecklistTableTaskMenu'
import AppTextField from '../../../common/AppTextField'
import AppTooltip from '../../../common/AppTooltip'
import AppButton from '../../../common/buttons/AppButton'
import RightsDisplayButton from '../../../common/users/RightsDisplayButton.vue'
import VisualTextEditor from '../../../common/visual-text-editor/VisualTextEditor'
import {
  ADD_TASK_TO_SELECTION,
  EDIT_TASK,
  REFRESH_TASK,
  REMOVE_TASK_FROM_SELECTION,
} from '../../../store/modules/checklist/action_types'
import { SET_SHARE_TASKS_DIALOG } from '../../../store/modules/checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'
import ChecklistTableTaskAttachedFilesDialog from '../dialogs/ChecklistTableTaskAttachedFilesDialog'

const TASK_DESCRIPTION_MAX_LENGTH = 3000

export default {
  name: 'ChecklistTableTaskDocument',
  components: {
    ChecklistTableTaskMenu,
    ChecklistTableTaskAttachedFilesDialog,
    AppButton,
    VisualTextEditor,
    AppTextField,
    AppTooltip,
    RightsDisplayButton,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      editTitleIsActive: false,
      editDescriptionIsActive: false,
      editableTask: null,
      lastSavedTitle: null,
      lastSavedDescription: null,
      taskDescriptionMaxLength: TASK_DESCRIPTION_MAX_LENGTH,
    }
  },
  computed: {
    ...mapState('checklist', ['editTaskPending', 'lastTaskAdded']),
    ...mapGetters('checklist', ['isChecklistFiltered', 'selectedTasks']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
    isPending () {
      return this.editTaskPending === this.item.id
    },
    isLastTaskAdded () {
      if (this.lastTaskAdded) {
        return this.lastTaskAdded.id === this.item.id && this.lastTaskAdded.type === this.item.type
      }
      return false
    },
    isTaskSelected () {
      return this.selectedTasks.some(task => task.id === this.item.id)
    },
    computedTitle () {
      if (this.lastSavedTitle) {
        return this.lastSavedTitle
      }
      return this.item.title || ''
    },
    computedDescription () {
      return this.lastSavedDescription || this.item.description
    },
    taskDescriptionLength () {
      return escapeHtml(this.editableTask.description).length
    },
    taskDescriptionIsTooLong () {
      return this.taskDescriptionLength > this.taskDescriptionMaxLength
    },
    taskDescriptionErrors () {
      const errors = []

      if (this.taskDescriptionIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    validateTaskDescriptionDisabled () {
      return this.taskDescriptionIsTooLong
    },
    isAwaitingDeleteValidation () {
      return this.item.isAwaitingValidation
    },
  },
  watch: {
    isLastTaskAdded (value) {
      if (value) {
        this.onLastTaskAdded()
      }
    },
    'item.title' (value) {
      if (value === this.lastSavedTitle) {
        this.lastSavedTitle = null
      }
    },
    'item.description' (value) {
      if (value === this.lastSavedTitle) {
        this.lastSavedTitle = null
      }
    },
  },
  mounted () {
    this.editableTask = { title: this.item.title, description: this.item.description }
  },
  methods: {
    ...mapActions('checklist', [EDIT_TASK, REFRESH_TASK, ADD_TASK_TO_SELECTION, REMOVE_TASK_FROM_SELECTION]),
    onLastTaskAdded () {
      this.$el.scrollIntoView({ block: 'center' })
      this.activeEditTitleMode()
    },
    activeEditTitleMode () {
      this.editableTask.title = this.item.title
      this.editTitleIsActive = true
    },
    async activeEditDescriptionMode () {
      this.editableTask.description = this.item.description
      this.editDescriptionIsActive = true

      await this.$nextTick()
      this.$refs.taskDescriptionField.focus()
    },
    async prepareEditTask () {
      if ((this.item.title === this.editableTask.title) && (this.item.description === this.editableTask.description)) {
        this.editTitleIsActive = false
        this.editDescriptionIsActive = false
      } else {
        const data = {}
        if (this.item.title !== this.editableTask.title) {
          data.title = this.editableTask.title
          this.lastSavedTitle = this.editableTask.title
        }
        if (this.item.description !== this.editableTask.description) {
          data.description = this.editableTask.description
          this.lastSavedDescription = this.editableTask.description
        }
        try {
          await this.EDIT_TASK({
            parent: this.parent,
            mnemo: this.mnemo,
            taskId: this.item.id,
            data,
          })

          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.checklist.task.ChecklistTableTaskDocument.editTaskSuccess'),
          })

          this.editTitleIsActive = false
          this.editDescriptionIsActive = false
        } catch (e) {
          this.lastSavedTitle = null
          this.lastSavedDescription = null
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.task.ChecklistTableTaskDocument.editTaskError'),
          })
        }
      }
    },
    prepareShareTasksDialog () {
      this.$store.commit(`checklist/${SET_SHARE_TASKS_DIALOG}`, {
        isOpen: true,
        tasksToShare: [this.item],
        parent: this.parent,
      })
    },
    prepareRefreshTask () {
      this.REFRESH_TASK({
        mnemo: this.mnemo,
        taskId: this.item.id,
        parent: this.parent,
      })
    },
    onClickOutside () {
      if (this.editDescriptionIsActive && this.validateTaskDescriptionDisabled) {
        this.editableTask.description = this.item.description
        this.editDescriptionIsActive = false
      } else if (this.editTitleIsActive || this.editDescriptionIsActive) {
        this.prepareEditTask()
      }
    },
    onSelectedTaskClick () {
      if (this.isTaskSelected) {
        this.REMOVE_TASK_FROM_SELECTION(this.item)
      } else {
        this.ADD_TASK_TO_SELECTION(this.item)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableTaskDocument-title--inactive {
  min-height: 24px;
  min-width: 100px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    min-height: 0;
  }
}
.ChecklistTableTaskDocument-title--inactive,
.ChecklistTableTaskDocument-description--inactive {
  border: 1px dashed transparent;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 6px;
  &:hover {
    background-color: #eee;
    border-color: #bdbdbd;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 0;
    border: none;
  }
}
.ChecklistTableTaskDocument-noDescription {
  font-style: italic;
  color: rgba(51, 51, 51, .51);
  border: 1px dashed transparent;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 6px;
  &:hover {
    background-color: #eee;
    border-color: #bdbdbd;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 0;
  }
}
.ChecklistTableTaskDocument-mobileRow {
  border-bottom: 2px solid #eee;
}
.ChecklistTableTaskDocument-mobileRowGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.is-awaiting-delete-validation {
  color: var(--v-grey-lighten3) !important;
}
</style>
