<template>
  <AppDialog :is-open="computedIsOpen"
             ok-only
             size="xl"
             :persistent="false"
             :ok-text="$t('common.close')"
             @click:outside="closeModal"
             @ok="closeModal"
             @cancel="closeModal"
  >
    <template #title>
      {{ $t('project.signed-documents.SignedDocumentsSignatoriesListDialog.title', { signatoriesCount: signatories.length }) }}
    </template>
    <template #body>
      <SignedDocumentsSignatoriesListModalItem v-for="signatory in signatories"
                                               :key="`signatory-${signatory.id}`"
                                               :signatory="signatory"
      />
    </template>
  </AppDialog>
</template>

<script>
import { mapActions } from 'vuex'

import SignedDocumentsSignatoriesListModalItem from './SignedDocumentsSignatoriesListModalItem'
import AppDialog from '../../common/dialogs/AppDialog'

export default {
  name: 'SignedDocumentsSignatoriesListDialog',
  components: {
    AppDialog,
    SignedDocumentsSignatoriesListModalItem,
  },
  props: {
    signedDocument: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    computedIsOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
    signatories () {
      const signatories = []
      if (this.signedDocument.mySignature) {
        signatories.push(this.signedDocument.mySignature)
      }
      if (this.signedDocument.otherSignatures.length > 0) {
        signatories.push(...this.signedDocument.otherSignatures)
      }
      return signatories
    },
  },
  mounted () {
    this.GET_GROUPS(this.mnemo)
  },
  methods: {
    ...mapActions('groups', ['GET_GROUPS']),
    closeModal () {
      this.computedIsOpen = false
      this.$emit('update:signed-document', null)
    },
  },
}
</script>
