<template>
<v-file-input ref="fileInput"
              v-model="computedValue"
              outlined
              :hide-details="hideDetails"
              clear-icon="fal fa-xmark"
              dense
              :color="color"
              v-bind="$attrs"
              v-on="$listeners"
>
  <template v-for="(_, slot) in $slots" v-slot:[slot]>
    <slot :name="slot" />
  </template>
</v-file-input>
</template>

<script>
/**
 * A wrapper around the file input with default styling and custom default behavior
 * All Vuetify props for the file input component are usable.
 * @see https://vuetifyjs.com/en/api/v-file-input/#props
 */
export default {
  name: 'AppFileInput',
  inheritAttrs: false,
  props: {
    /**
     * @model
     * The input’s value
     */
    value: {
      required: true,
    },
    /**
     * Applies specified color to the control
     * @default blue darken-2
     */
    color: {
      default: 'blue darken-2',
    },
    /**
     * Hides hint and validation errors
     */
    hideDetails: {
      default: 'auto',
    },
  },
  computed: {
    computedValue: {
      get () {
        return this.value
      },
      set (newValue) {
        /**
         * The updated bound model
         *
         * @property {string} newValue new value set
         */
        this.$emit('input', newValue)
      },
    },
  },
}
</script>

<docs>
```vue
<template>
  <AppFileInput v-model="value"
                placeholder="Type something"
                label="My text input"
  />
</template>
<script>
  export default {
    data () {
      return {
        value: ''
      }
    },
  }
</script>
```
</docs>
