<template>
  <Pane v-model="pane.isOpen"
        temporary
        :tabs="tabs"
        :current-tab.sync="tab"
        @input="onPaneInput"
  >
    <template #content-share-attachment>
      <SigningChecklistPaneShareAttachment />
    </template>
  </Pane>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import Pane from '@/common/Pane'
import { CHANGE_PANE_TAB } from '@/store/modules/signing-checklist/action_types'

import SigningChecklistPaneShareAttachment from './SigningChecklistPaneShareAttachment'

export default {
  name: 'SigningChecklistPane',
  components: { Pane, SigningChecklistPaneShareAttachment },
  computed: {
    ...mapState('signingChecklist', ['pane']),
    ...mapGetters('room', ['isCurrentUserPm']),
    tab: {
      get () {
        return this.pane.tab
      },
      set (tab) {
        this.CHANGE_PANE_TAB(tab)
      },
    },
    tabs () {
      return this.isCurrentUserPm
        ? [{ title: this.$t('common.share'), id: 'share-attachment' }]
        : []
    },
  },
  methods: {
    ...mapActions('signingChecklist', [CHANGE_PANE_TAB]),
    onPaneInput (value) {
      if (!value) {
        this.CHANGE_PANE_TAB('')
      }
    },
  },
}
</script>
