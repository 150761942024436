<template>
  <v-chip v-if="status"
          label
          small
          :color="status.color"
          class="body-2"
  >
    {{ $t(`common.AppSignatureStatus.${status.key}`) }}
  </v-chip>
</template>

<script>
const SIGNATURE_STATUS = Object.freeze({
  SENT: { key: 'sent', color: 'primary' },
  SIGNED: { key: 'signed', color: 'warning' },
  SIGNED_BY_ALL: { key: 'signedByAll', color: 'success' },
  VOIDED: { key: 'voided', color: 'secondary' },
})
/**
 * Default Signature status
 * @displayName AppSignatureStatus
 */
export default {
  name: 'AppSignatureStatus',
  props: {
    /**
     * Signature Object
     * {
     *  docSize: Number
     *  id: Number
     *  mySignature: Object
     *  name: String
     *  numbering: Number
     *  otherSignatures: Array
     *  proofFileURL: String
     *  proofSize: Number
     *  provider: String
     *  rights: Object
     *  signSize: Number
     *  signedDocumentURL: String
     *  status: String
     *  time: String
     * }
     */
    signature: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      SIGNATURE_STATUS,
    }
  },
  computed: {
    status () {
      if (this.signature.status === 'sent' && this.signature.mySignature.status === 'sent') return this.SIGNATURE_STATUS.SENT
      if (this.signature.status === 'sent' && this.signature.mySignature.status === 'signed') return this.SIGNATURE_STATUS.SIGNED
      if (this.signature.status === 'signed' && !this.signature.mySignature && this.signature.otherSignatures.every(o => o.status === 'signed')) return this.SIGNATURE_STATUS.SIGNED_BY_ALL
      if (this.signature.status === 'signed' && this.signature.mySignature.status === 'signed') return this.SIGNATURE_STATUS.SIGNED_BY_ALL
      if (this.signature.status === 'voided') return this.SIGNATURE_STATUS.VOIDED
      return null
    },
  },
}
</script>

<docs>
```vue
<template>
  <div class="d-flex">
    <AppSignatureStatus :signature="signature"/>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        signature: {
          status: "signed",
          mySignature: {
            status: "signed",
          }
        },
      }
    },
  }
</script>
```
</docs>
