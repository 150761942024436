import {
  faFolder,
  faFolderOpen,
  faSort,
  faSortUp,
  faSortDown,
  faUserLock,
  faFile,
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faCreditCard,
} from '@fortawesome/pro-duotone-svg-icons'

export const duotoneLibrary = {
  faFolder,
  faFolderOpen,
  faSort,
  faSortUp,
  faSortDown,
  faUserLock,
  faFile,
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faCreditCard,
}
