<template>
  <v-col cols="12" class="px-10 user-email-alias">
    <v-row no-gutters>
      <h2 class="text-h2 font-weight-bold">
        {{ $t('profile.views.EmailAlias.title') }}
      </h2>
    </v-row>

    <!-- EMAIL -->
    <app-text variant="large-bold" class="mt-6">
      {{ $t("profile.views.EmailAlias.emailAddress") }}
    </app-text>
    <div class="d-flex align-center">
      <app-text variant="small-regular">
        {{ $t("profile.views.EmailAlias.yourEmailAddress") }}
      </app-text>
      <app-text variant="small-bold" class="ml-1">
        {{ profile.email }}
      </app-text>
    </div>

    <!-- ALIAS -->
    <app-text variant="large-bold" class="mt-6">
      {{ $t("profile.views.EmailAlias.alias") }}
    </app-text>
    <app-text variant="small-regular" class="mb-6">
      {{ $t("profile.views.EmailAlias.aliasDescription") }}
    </app-text>

    <!-- Add new alias -->
    <app-text variant="small-bold">
      {{ $t("profile.views.EmailAlias.addAlias") }}
    </app-text>
    <div class="d-flex align-center">
      <AppTextField v-model.trim="newAlias"
                    placeholder="john.doe@acme.com"
                    type="email"
                    :maxlength="255"
                    style="max-width: 320px"
                    :error-messages="emailErrors"
                    @blur="validateEmail"
                    @keypress.enter="addNewAlias"
      />
      <app-button class="ml-4 mb-auto"
                  type="outlined"
                  variant="neutral"
                  :loading="addNewAliasPending"
                  :disabled="aliasesPending"
                  @click="addNewAlias"
      >
        {{ $t("common.add") }}
      </app-button>
    </div>

    <!-- Aliases -->
    <div class="mt-6">
      <div v-if="validateAliasPending || aliasesPending" class="text-center">
        <AppLoader small></AppLoader>
      </div>
      <template v-else>
        <div v-if="aliasesError" class="error--text">
          <app-alert-in-page variant="error"
                             no-icon
                             :dismissible="false"
          >
            {{ $t('profile.views.EmailAlias.getAliasesError') }}
          </app-alert-in-page>
        </div>
        <template v-if="aliases.length">
          <v-row v-for="(alias, index) in aliases"
                 :key="index"
                 no-gutters
                 align="center"
                 class="section-container"
                 :class="{
                   'border-top-radius': index === 0,
                   'border-bottom-radius': index === aliases.length - 1,
                   'border-bottom-none': index !== aliases.length - 1,
                 }"
          >
            <v-col sm="10" cols="12">
              <app-text variant="small-bold">
                {{ alias.email }}
              </app-text>
              <div v-if="alias.isConfirmed" class="d-flex align-center">
                <app-icon-badge color="success"
                                class="mb-2 ml-1 mr-3"
                                dot
                >
                </app-icon-badge>
                <app-text variant="small-bold" class="success--text">
                  {{ $t("profile.views.EmailAlias.verified") }}
                </app-text>
              </div>
              <div v-else class="d-flex align-center">
                <app-icon-badge color="warning"
                                class="mb-2 ml-1 mr-3"
                                dot
                >
                </app-icon-badge>
                <app-text variant="small-bold" class="warning--text">
                  {{ $t("profile.views.EmailAlias.pendingVerification") }}
                </app-text>
                <span class="mx-2 mb-">—</span>
                <app-icon v-if="resendAliasCheckLinkPending[alias.id]"
                          class="ml-2"
                          icon-name="spinner-third"
                          spin
                />
                <div v-else class="clickable" @click="resendAliasCheckLink(alias.id)">
                  <app-text variant="small-bold" style="color: #2671F2">
                    {{ $t('profile.views.EmailAlias.resendLink') }}
                  </app-text>
                </div>
              </div>
            </v-col>
            <v-col sm="2" cols="12" align="right">
              <app-button variant="text"
                          class="error--text"
                          @click="openDeleteAliasDialog(alias)"
              >
                {{ $t('common.delete') }}
              </app-button>
            </v-col>
          </v-row>
        </template>
      </template>
    </div>

    <!-- DIALOGS -->
    <EmailAliasDeleteDialog v-if="deleteAliasDialog.isOpen"
                            :alias="deleteAliasDialog.alias"
                            @close="closeDeleteAliasDialog"
    />
  </v-col>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  import { mapActions, mapMutations, mapState } from 'vuex'

  import AppLoader from '@/common/AppLoader.vue'
  import AppTextField from '@/common/AppTextField.vue'
  import {
    GET_ALIASES,
    ADD_NEW_ALIAS,
    VALIDATE_USER_ALIAS,
    RESEND_ALIAS_CHECK_LINK,
  } from '@/store/modules/user/action_types'
  import { SET_DELETE_ALIAS_DIALOG } from '@/store/modules/user/mutation_types'
  import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

  import EmailAliasDeleteDialog from './EmailAliasDeleteDialog.vue'

  export default {
    name: 'EmailAlias',
    components: { AppLoader, AppTextField, EmailAliasDeleteDialog },
    mixins: [validationMixin],
    validations: {
      newAlias: { required, email },
    },
    computed: {
      ...mapState('user', [
        'profile',
        'aliasesPending',
        'aliases',
        'aliasesError',
        'addNewAliasPending',
        'validateAliasPending',
        'resendAliasCheckLinkPending',
        'deleteAliasDialog',
      ]),
      emailErrors () {
        const errors = []
        if (!this.$v.newAlias.$dirty) return errors
        !this.$v.newAlias.email && errors.push(this.$t('common.validations.email'))
        !this.$v.newAlias.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('profile.views.EmailAlias.addAlias') }))
        return errors
      },
    },
    data () {
      return {
        newAlias: '',
      }
    },
    created () {
      const token = this.$route.params.token
      if (token) {
        // If the token is present, validate the user email alias
        this.validateUserAlias(token)
      } else {
        this.getAlias()
      }
    },
    watch: {
      newAlias (newval) {
        // Do not display error when the email field is empty
        if (newval === '') {
          this.$v.newAlias.$reset()
        }
      },
    },
    methods: {
      ...mapActions('user', [
        GET_ALIASES,
        ADD_NEW_ALIAS,
        VALIDATE_USER_ALIAS,
        RESEND_ALIAS_CHECK_LINK,
      ]),
      ...mapMutations('user', [SET_DELETE_ALIAS_DIALOG]),
      ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
      async getAlias () {
        try {
          await this.GET_ALIASES()
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('profile.views.EmailAlias.getAliasesError'))
        }
      },
      async validateUserAlias (token) {
        try {
          const response = await this.VALIDATE_USER_ALIAS({ token })

          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('profile.views.EmailAlias.validateAliasSuccess', { email: response?.data?.email }))
        } catch (e) {
          if (e?.response?.data?.errorCode === 'ERR_INVALID_TOKEN') {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('profile.views.EmailAlias.validateAliasExpired'))
          } else if (e?.response?.data?.errorCode === 'ERR_NOT_FOUND') {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('profile.views.EmailAlias.validateAliasAfterDeletion'))
          } else {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('profile.views.EmailAlias.validateAliasError'))
          }
        } finally {
          this.getAlias()
        }
      },
      validateEmail () {
        this.$v.newAlias.$touch()
        // Do not display error when leaving the empty email field
        if (this.newAlias === '') {
          this.$v.newAlias.$reset()
        }
      },
      async addNewAlias () {
        this.$v.newAlias.$touch()
        if (!this.$v.newAlias.$invalid) {
          try {
            await this.ADD_NEW_ALIAS({
              email: this.newAlias,
            })
            this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('profile.views.EmailAlias.sendEmailSuccess', { email: this.newAlias }))
            this.newAlias = ''
          } catch (e) {
            if (e?.response?.data?.errorCode === 'ERR_EXISTING_EMAIL') {
              this.ENQUEUE_ERROR_SNACKBAR(this.$t('profile.views.EmailAlias.addAliasErrorExistingEmail'))
            } else {
              this.ENQUEUE_ERROR_SNACKBAR(this.$t('profile.views.EmailAlias.addAliasError'))
            }
          }
        }
      },
      async resendAliasCheckLink (aliasId) {
        try {
          await this.RESEND_ALIAS_CHECK_LINK({ aliasId })

          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('profile.views.EmailAlias.verificationLinkSentSuccess'))
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('profile.views.EmailAlias.verificationLinkSentError'))
        }
      },
      openDeleteAliasDialog (alias) {
        this.SET_DELETE_ALIAS_DIALOG({
          alias,
          isOpen: true,
        })
      },
      closeDeleteAliasDialog () {
        this.SET_DELETE_ALIAS_DIALOG({
          alias: null,
          isOpen: false,
        })
      },
    },
  }
</script>

<style lang="scss">
  .user-email-alias {
    .section-container {
      padding: 12px 20px;
      margin-top: 0px !important;
      border: 1px solid #D4D7DA;
    }
    .border-top-radius {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .border-bottom-radius {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .border-bottom-none {
      border-bottom: none;
    }
  }
</style>
