<template>
  <div>
    <PrepareSignatureViewer :is-data-ready="isDataReady">
      <template #header="{containerHeight}">
        <app-alert-in-page v-if="isReadOnly"
                           class="mt-6"
                           @input="containerHeight"
        >
          <template>
            {{ $t(`project.signing-checklist.add.step3.SigningChecklistAddStep3.readOnlyAlertContent`) }}
            <p v-html="$t(`project.signing-checklist.add.step3.SigningChecklistAddStep3.readOnlyAlertContentItems`)"></p>
            <a :href="learnMoreLinkUrl" target="_blank" class="learnMoreLink d-flex align-center column-gap-2">
              {{ $t(`project.signing-checklist.add.step3.SigningChecklistAddStep3.readOnlyAlertContentLink`) }}
              <app-icon icon-name="arrow-up-right-from-square"
                        size="small"
              />
            </a>
          </template>
        </app-alert-in-page>
        <PrepareSignatureTopbar :envelope-name="envelopeDisplayInformation.name"
                                :pages-count="envelopeDisplayInformation.totalPages || computedPages.length"
                                :current-page="currentPage"
                                :class="{ 'mt-6' : isReadOnly }"
                                @scroll-to="scrollTo"
        />
      </template>
      <template #sidebar>
        <PrepareSignatureSidebar
            :delete-signer-pending-ids="deleteSignerPendingIds"
            :envelope-id="envelopeId"
            :envelope-type="step.objectToPost.envelope.envelopeType"
            :is-read-only="isReadOnly"
            :provider="step.objectToPost.envelope.provider"
            :signature-type="step.objectToPost.envelope.signatureType"
            :signers="taggingInformation.signers"
            @add-auto-tags="onAddAutoTags"
            @add-signers="onAddSigners"
            @change-signer="onChangeSigner"
            @delete-all-tags="onDeleteAllTags"
            @delete-signer="deleteSigner"
            @edit-signers-order="onEditSignersOrder"
            @scroll-to="scrollTo"
            @get-pages="getPagesContent"
            @get-tags="getTags"
        />
      </template>
      <template #pages>
        <PrepareSignaturePagesLoader v-if="isLoadingPages" :render-progress="envelopeDisplayInformation.renderProgress" />
        <PrepareSignaturePage v-for="page in computedPages"
                              :ref="`page-${page.pageNumber}`"
                              :key="`page-${page.pageNumber}`"
                              :drag-tag-active="dragTagActive"
                              :envelope-id="envelopeId"
                              :envelope-type="step.objectToPost.envelope.envelopeType"
                              :is-pending="isPending"
                              :is-read-only="isReadOnly"
                              :page="page"
                              :page-content="pageContents[page.pageNumber]"
                              :pages-tags="taggingInformation.tags"
                              :signers="taggingInformation.signers"
                              @delete-initials-tag="onDeleteInitialsTags"
                              @delete-tag="deleteTag"
                              @drag-tag-active="setDragTagActive"
                              @duplicate-checkbox="duplicateCheckbox"
                              @duplicate-tag="duplicateTag"
                              @page-visible="pageEnteredViewPort(page)"
                              @patch-tag="patchTag"
                              @page-tags-ready="onPageTagsReady"
                              @post-tag="postTag"
        />
      </template>
      <template #thumbs>
        <PrepareSignatureThumb v-for="page in computedPages"
                              :key="`page-${page.pageNumber}`"
                              :is-active-page="activePage === page.pageNumber"
                              :page-number="page.pageNumber"
                              :page-thumb="pageThumbs[page.pageNumber]"
                              :pages-tags="taggingInformation.tags"
                              @scroll-to="scrollTo"
                              @thumb-visible="thumbEnteredViewPort(page)"
        />
      </template>
    </PrepareSignatureViewer>

    <PrepareSignatureAutoTagsDialog v-if="isAddAutoTagsDialogOpen"
                                    @close="isAddAutoTagsDialogOpen = false"
                                    @get-pages="getPagesContent"
                                    @get-tags="getTags"
                                    @scroll-to="scrollTo"
    />
    <PrepareSignatureChangeSignerDialog v-if="isChangeSignersDialogOpen"
                                        :signer="signerToChange"
                                        @close="isChangeSignersDialogOpen = false"
                                        @update-signers-list="updateSignersList"
    />
    <PrepareSignatureDeleteInitialsTagDialog v-if="isDeleteInitialsTagDialogOpen"
                                             :is-pending="isPending"
                                             @close="onCloseDeleteInitialsTagDialog"
                                             @delete-tag="deleteTag(tagToDelete.id)"
                                             @delete-all-tags="deleteAllTags({ bySignatureIds: tagToDelete.signatureIds, byType: tagToDelete.type})"
    />
    <PrepareSignatureDeleteAllTagsDialog v-if="isDeleteAllTagsDialogOpen"
                                         :is-pending="deleteTagPending"
                                         @close="isDeleteAllTagsDialogOpen = false"
                                         @delete-all-tags="deleteAllTags({ all: true })"
    />
    <PrepareSignatureSignersOrderDialog v-if="isSignersOrderDialogOpen"
                                        :envelope-id="envelopeId"
                                        :signature-type="step.objectToPost.signatureType"
                                        :signers="step.objectToPost.signers"
                                        :step="step"
                                        @close="isSignersOrderDialogOpen = false"
                                        @update-signers-order="updateSignersOrder"
    />
    <SigningChecklistAddSignersDialog v-if="isAddSignersDialogOpen"
                                      :bulk-send="isMultipleEnvelope"
                                      :witnesses="witnesses"
                                      @close="isAddSignersDialogOpen = false"
                                      @update-signers-list="updateSignersList"
    />
  </div>
</template>

<script>
import { debounce, slice, take } from 'lodash-es'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import { tagsTypes } from '@/common/prepare-signature/tagsTypes'
import { canShowReadOnlyStep, getCommonAndUniqueSignerIdsFromSignersGroup } from '@/common/utils/signingChecklist'
import { ENVELOPE_TYPES } from '@/project/signing-checklist/constants'
import SigningChecklistAddSignersDialog from '@/project/signing-checklist/dialogs/SigningChecklistAddSignersDialog.vue'
import PrepareSignatureDeleteAllTagsDialog from '@/project/signing-checklist/prepare-signature/PrepareSignatureDeleteAllTagsDialog.vue'
import PrepareSignatureDeleteInitialsTagDialog from '@/project/signing-checklist/prepare-signature/PrepareSignatureDeleteInitialsTagDialog.vue'
import PrepareSignaturePagesLoader from '@/project/signing-checklist/prepare-signature/PrepareSignaturePagesLoader.vue'
import PrepareSignatureSignersOrderDialog from '@/project/signing-checklist/prepare-signature/PrepareSignatureSignersOrderDialog.vue'
import PrepareSignatureViewer from '@/project/signing-checklist/prepare-signature/PrepareSignatureViewer.vue'
import {
  DELETE_ALL_TAG_TYPE,
  DELETE_SIGNER,
  DELETE_TAG,
  DRAG_TAG_ACTIVE,
  DUPLICATE_CHECKBOX,
  DUPLICATE_TAG,
  GET_ENVELOPE_DISPLAY_INFORMATION,
  GET_TAGGING_INFORMATION,
  GET_PAGES_CONTENT,
  GET_PAGE_THUMB,
  PATCH_TAG,
  POST_TAG,
  RESET_DOCUMENT_TO_PREPARE,
  UPDATE_TAGS_OVERLAPS,
} from '@/store/modules/prepareSignature/action_types'
import { REMOVE_TAG_OVERLAP_BY_TAG_ID } from '@/store/modules/prepareSignature/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

import PrepareSignatureAutoTagsDialog from './PrepareSignatureAutoTagsDialog.vue'
import PrepareSignatureChangeSignerDialog from './PrepareSignatureChangeSignerDialog'
import PrepareSignaturePage from './PrepareSignaturePage'
import PrepareSignatureSidebar from './PrepareSignatureSidebar'
import PrepareSignatureThumb from './PrepareSignatureThumb'
import PrepareSignatureTopbar from './PrepareSignatureTopbar'

const PAGES_TO_LOAD_OFFSET = 1
const THUMBS_TO_LOAD_OFFSET = 5

export default {
  name: 'PrepareSignature',
  components: {
    PrepareSignatureAutoTagsDialog,
    PrepareSignatureChangeSignerDialog,
    PrepareSignatureDeleteAllTagsDialog,
    PrepareSignatureDeleteInitialsTagDialog,
    PrepareSignaturePage,
    PrepareSignaturePagesLoader,
    PrepareSignatureSidebar,
    PrepareSignatureSignersOrderDialog,
    PrepareSignatureThumb,
    PrepareSignatureTopbar,
    PrepareSignatureViewer,
    SigningChecklistAddSignersDialog,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      activePage: null,
      currentPage: 1,
      displayInformationsPollingTimeout: null,
      isAddAutoTagsDialogOpen: false,
      isAddSignersDialogOpen: false,
      isChangeSignersDialogOpen: false,
      isDeleteAllTagsDialogOpen: false,
      isDeleteInitialsTagDialogOpen: false,
      isSignersOrderDialogOpen: false,
      pagesTagsReady: [],
      signerToChange: null,
      tagToDelete: null,
    }
  },
  computed: {
    ...mapState('prepareSignature', [
      'deleteSignerPendingIds',
      'deleteTagPending',
      'dragTagActive',
      'duplicateCheckboxPending',
      'duplicateTagPending',
      'envelopeDisplayInformation',
      'pageContents',
      'pageThumbs',
      'patchSignersOrderPending',
      'patchTagPending',
      'taggingInformation',
    ]),
    ...mapGetters('prepareSignature', ['pageContentByNumber', 'pageThumbByNumber']),
    ...mapGetters('room', ['roomMnemo']),
    computedPages () {
      const { pages: envelopePages, totalPages } = this.envelopeDisplayInformation || {}

      if (!totalPages || totalPages <= 0) {
        return []
      }

      if (totalPages === envelopePages.length) {
        return envelopePages
      }

      const pages = []
      for (let i = 1; i <= totalPages; i++) {
        const existingPage = envelopePages.find(el => el.pageNumber === i)
        if (existingPage) {
          pages.push(existingPage)
        } else {
          pages.push({
            height: 1056,
            id: null,
            content: '',
            pageNumber: i,
            width: 816,
          })
        }
      }

      return pages
    },
    envelopeId () {
      return this.step.objectToPost.envelope.id
    },
    isDataReady () {
      return this.envelopeDisplayInformation !== null && this.taggingInformation !== null
    },
    isLoadingPages () {
      if (this.envelopeDisplayInformation) {
        return this.envelopeDisplayInformation.pages.length === 0
      }
      return false
    },
    isMultipleEnvelope () {
      return this.step.objectToPost.envelope.envelopeType === ENVELOPE_TYPES.MULTIPLE
    },
    isPending () {
      return this.deleteTagPending || this.duplicateCheckboxPending || this.duplicateTagPending || this.patchTagPending || this.patchSignersOrderPending
    },
    isReadOnly () {
      return canShowReadOnlyStep(this.step.objectToPost)
    },
    learnMoreLinkUrl () {
      if (this.$i18n.locale === 'fr') {
        return 'https://help.closd.com/fr/'
      }

      return 'https://help.closd.com/en/'
    },
    signersGroups () {
      return this.taggingInformation.signers.map(signersGroup => {
        const commonSigners = signersGroup.filter(signer => signer.signatureType === 'single')
        const uniqueSigners = signersGroup.filter(signer => signer.signatureType === 'multiple')

        return [...commonSigners, ...uniqueSigners]
      })
    },
    witnesses () {
      return this.step.objectToPost.witnesses.flatMap(w => w.witness)
    },
  },
  watch: {
    pagesTagsReady: {
      handler (newVal) {
        if (newVal) {
          this.checkTagsOverlaps()
        }
      },
    },
  },
  created () {
    this.pageEnteredViewPort = debounce(this.pageEnteredViewPort, 500)
    this.thumbEnteredViewPort = debounce(this.thumbEnteredViewPort, 500)
  },
  async mounted () {
    try {
      await this.GET_ENVELOPE_DISPLAY_INFORMATION({ mnemo: this.roomMnemo, envId: this.envelopeId })

      if (this.envelopeDisplayInformation.totalPages !== null) {
        this.getPagesContent({
          fromPage: 1,
          toPage: Math.min(this.envelopeDisplayInformation.totalPages, 3),
        })

        take(this.envelopeDisplayInformation.pages, Math.min(this.envelopeDisplayInformation.totalPages, 10))
          .forEach(({ id, pageNumber }) => {
            this.getPageThumb(id, pageNumber)
          })

        this.setActivePage({ pageNumber: 1 })
      }

      if (this.envelopeDisplayInformation.totalPages === null || (this.envelopeDisplayInformation.totalPages > 0 && this.envelopeDisplayInformation.pages.length < this.envelopeDisplayInformation.totalPages)) {
        this.retryGetEnvelopeDisplayInformation()
      }

      this.getTags()
    } catch (e) {
      console.error(e)
    }
  },
  beforeDestroy () {
    this.RESET_DOCUMENT_TO_PREPARE()
    if (this.displayInformationsPollingTimeout) {
      clearTimeout(this.displayInformationsPollingTimeout)
    }
  },
  methods: {
    ...mapActions('prepareSignature', [
      DELETE_ALL_TAG_TYPE,
      DELETE_SIGNER,
      DELETE_TAG,
      DRAG_TAG_ACTIVE,
      DUPLICATE_CHECKBOX,
      DUPLICATE_TAG,
      GET_ENVELOPE_DISPLAY_INFORMATION,
      GET_PAGES_CONTENT,
      GET_PAGE_THUMB,
      GET_TAGGING_INFORMATION,
      PATCH_TAG,
      POST_TAG,
      RESET_DOCUMENT_TO_PREPARE,
      UPDATE_TAGS_OVERLAPS,
    ]),
    ...mapMutations('prepareSignature', [REMOVE_TAG_OVERLAP_BY_TAG_ID]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    getPagesContent (params) {
      params = {
        mnemo: this.roomMnemo,
        envelopeId: this.envelopeId,
        ...params,
      }

      this.GET_PAGES_CONTENT(params)
    },
    getPageThumb (pageId, pageNumber) {
      this.GET_PAGE_THUMB({
        mnemo: this.roomMnemo,
        envelopeId: this.envelopeId,
        pageId: pageId,
        pageNumber: pageNumber,
      })
    },
    async getTags () {
      await this.GET_TAGGING_INFORMATION({ mnemo: this.roomMnemo, envId: this.envelopeId })
    },
    pageEnteredViewPort (page) {
      this.setActivePage(page)

      const pageIndex = this.computedPages.findIndex(p => p.id === page.id)

      if (pageIndex === -1) {
        return
      }

      const pagesInRange = slice(this.computedPages, pageIndex - PAGES_TO_LOAD_OFFSET, pageIndex + PAGES_TO_LOAD_OFFSET + 1)

      pagesInRange.forEach(({ pageNumber }) => {
        const pageContent = this.pageContentByNumber(pageNumber)

        if (!pageContent || (!pageContent.data && !pageContent.isLoading)) {
          this.getPagesContent({ fromPage: pageNumber, toPage: pageNumber })
        }
      })
    },
    scrollTo (page) {
      const el = this.$refs[`page-${page}`][0].$el
      if (el) {
        this.currentPage = page
        setTimeout(() => {
          el.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      }
    },
    setActivePage (page) {
      this.activePage = page.pageNumber
      this.currentPage = page.pageNumber
    },
    setDragTagActive (value) {
      this.DRAG_TAG_ACTIVE(value)
    },
    retryGetEnvelopeDisplayInformation () {
      this.displayInformationsPollingTimeout = setTimeout(async () => {
        await this.GET_ENVELOPE_DISPLAY_INFORMATION({ mnemo: this.roomMnemo, envId: this.envelopeId })
        if (this.envelopeDisplayInformation.totalPages === null || (this.envelopeDisplayInformation.totalPages > 0 && this.envelopeDisplayInformation.pages.length < this.envelopeDisplayInformation.totalPages)) {
          this.retryGetEnvelopeDisplayInformation()
        } else {
          this.getPagesContent({
            fromPage: 1,
            toPage: Math.min(this.envelopeDisplayInformation.totalPages, 3),
          })

          take(this.envelopeDisplayInformation.pages, 10)
            .forEach(({ id, pageNumber }) => {
              this.getPageThumb(id, pageNumber)
            })

          if (this.envelopeDisplayInformation.pages.length > 0) {
            this.setActivePage({ pageNumber: 1 })
          }
        }
      }, 3000)
    },
    thumbEnteredViewPort (page) {
      const pageIndex = this.computedPages.findIndex(p => p.id === page.id)

      if (pageIndex === -1) {
        return
      }

      const startIndex = Math.max(pageIndex - THUMBS_TO_LOAD_OFFSET, 0)
      const endIndex = Math.min(pageIndex + THUMBS_TO_LOAD_OFFSET, this.computedPages.length - 1)
      const pagesInRange = slice(this.computedPages, startIndex, endIndex)

      pagesInRange.forEach(({ id, pageNumber }) => {
        const pageThumb = this.pageThumbByNumber(pageNumber)

        if (!pageThumb || (!pageThumb.data && !pageThumb.isLoading)) {
          this.getPageThumb(id, pageNumber)
        }
      })
    },
    onAddAutoTags () {
      this.isAddAutoTagsDialogOpen = true
    },
    onAddSigners () {
      this.isAddSignersDialogOpen = true
    },
    onChangeSigner (signer) {
      this.signerToChange = signer
      this.isChangeSignersDialogOpen = true
    },
    onCloseDeleteInitialsTagDialog () {
      this.tagToDelete = null
      this.isDeleteInitialsTagDialogOpen = false
    },
    onDeleteAllTags () {
      this.isDeleteAllTagsDialogOpen = true
    },
    onDeleteInitialsTags (tagData) {
      const signatureInitialsTags = this.taggingInformation.tags.filter(tag => tag.type === tagsTypes.INITIAL && tagData?.signatureIds.includes(tag.signatureId))

      if (signatureInitialsTags.length > 1) {
        this.tagToDelete = tagData
        this.isDeleteInitialsTagDialogOpen = true
      } else {
        this.deleteTag(tagData.id)
      }
    },
    onEditSignersOrder () {
      this.isSignersOrderDialogOpen = true
    },
    async deleteAllTags (data) {
      try {
        await this.DELETE_ALL_TAG_TYPE({
          mnemo: this.roomMnemo,
          envId: this.envelopeId,
          data: data,
        })

        await this.getTags()
        this.checkTagsOverlaps()

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.deleteAllTags.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.deleteAllTags.errorText'))
      } finally {
        this.isDeleteAllTagsDialogOpen = false
      }
    },
    async deleteSigner (signatureId) {
      try {
        await this.DELETE_SIGNER({
          mnemo: this.roomMnemo,
          envId: this.envelopeId,
          signatureId: signatureId,
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.deleteSigner.successText'))

        await this.updateSignersList()
        this.checkTagsOverlaps()
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.deleteSigner.errorText'))
      }
    },
    async deleteTag (tagId) {
      try {
        this.REMOVE_TAG_OVERLAP_BY_TAG_ID(tagId)

        await this.DELETE_TAG({
          mnemo: this.roomMnemo,
          envId: this.envelopeId,
          tagId: tagId,
        })

        await this.getTags()
        this.checkTagsOverlaps()

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.deleteTag.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.deleteTag.errorText'))
      }
    },
    async duplicateCheckbox (data) {
      try {
        await this.DUPLICATE_CHECKBOX({
          mnemo: this.roomMnemo,
          envId: this.envelopeId,
          data: {
            tag: data,
          },
        })

        await this.getTags()
        this.checkTagsOverlaps()

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.duplicateTag.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.duplicateTag.errorText'))
      }
    },
    async duplicateTag (tagId) {
      try {
        const duplicatedTags = await this.DUPLICATE_TAG({
          mnemo: this.roomMnemo,
          envId: this.envelopeId,
          tagId: tagId,
          data: null,
        })

        await this.getTags()

        // Force overlaps check after tags duplication
        duplicatedTags.forEach(tag => {
          const tagData = {
            id: tag.id,
            tagHeight: tag.height || 0,
            tagPage: tag.page,
            tagType: tag.type,
            tagWidth: tag.width || 0,
            tagX: tag.x,
            tagY: tag.y,
          }

          this.patchTag(tagData, { quiet: true })
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.duplicateTag.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.duplicateTag.errorText'))
      }
    },
    async patchTag (data, options) {
      try {
        await this.PATCH_TAG({
          mnemo: this.roomMnemo,
          envId: this.envelopeId,
          data: {
            tag: data,
          },
        })

        if (!options?.quiet) {
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.patchTag.successText'))
        }
      } catch (e) {
        if (!options?.quiet) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.patchTag.errorText'))
        }
      }

      await this.getTags()
      this.checkTagsOverlaps()
    },
    async postTag (data) {
      try {
        await this.POST_TAG({
          mnemo: this.roomMnemo,
          envId: this.envelopeId,
          data: {
            tag: data,
          },
        })

        await this.getTags()
        this.checkTagsOverlaps()

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.postTag.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignature.postTag.errorText'))
      }
    },
    updateSignersOrder (groups) {
      this.$set(this.step.objectToPost, 'signers', groups)
    },
    async updateSignersList () {
      await this.getTags()

      const signersDescription = getCommonAndUniqueSignerIdsFromSignersGroup(this.signersGroups)

      this.$set(this.step.objectToPost, 'signers', signersDescription.commonSigners)

      if (this.step.objectToPost.envelope.envelopeType === ENVELOPE_TYPES.MULTIPLE) {
        this.$set(this.step.objectToPost, 'uniqueSigners', signersDescription.uniqueSigners)
      }
    },
    onPageTagsReady (pageNumber) {
      if (!this.pagesTagsReady.includes(pageNumber)) { this.pagesTagsReady.push(pageNumber) }
    },
    async checkTagsOverlaps (newTagData) {
      await this.$nextTick()

      const tagsReady = this.taggingInformation.tags.filter(tag => this.pagesTagsReady.includes(tag.pageNumber))
      const formattedNewTagData = {
        id: newTagData?.id || 0,
        height: newTagData?.tagHeight,
        pageNumber: newTagData?.tagPage,
        width: newTagData?.tagWidth,
        x: newTagData?.tagX,
        y: newTagData?.tagY,
      }

      const tagsToCheck = tagsReady.map(tag => {
        if (tag.id === formattedNewTagData?.id) {
          return formattedNewTagData
        } else {
          return {
            id: tag.id,
            height: tag.tagHeight || document.querySelector(`#tag-${tag.id}`)?.getBoundingClientRect()?.height || null,
            pageNumber: tag.pageNumber,
            width: tag.tagWidth || document.querySelector(`#tag-${tag.id}`)?.getBoundingClientRect()?.width || null,
            x: tag.left,
            y: tag.top,
          }
        }
      })

      if (formattedNewTagData?.id === 0) {
        tagsToCheck.push(formattedNewTagData)
      }

      this.UPDATE_TAGS_OVERLAPS({ envId: this.envelopeDisplayInformation.envelopeId, tags: tagsToCheck })
    },
  },
}
</script>

<style scoped lang="scss">
.PrepareSignature-container {
  border-left: 1px solid #DEE2E6;
  border-right: 1px solid #DEE2E6;
  border-bottom: 1px solid #DEE2E6;
  margin-top: 0 !important;
}
.PrepareSignaturePage-container,
.PrepareSignatureSidebar-container,
.PrepareSignatureThumb-container {
  background-color: #fff;
  display: flex;
  row-gap: 12px;
  flex-direction: column;
}
.PrepareSignatureSidebar-container {
  border-right: 1px solid #DEE2E6;
  max-width: 340px;
  min-width: 300px;
}
.PrepareSignaturePage-container {
  background-color: var(--v-grey-lighten5);
  min-width: 0;
}
.PrepareSignatureThumb-container {
  padding: 12px;
}
</style>
