<template>
  <div>
    <h3 class="text-h3">
      {{ $t('project.checklist.dialogs.ImportChecklistDialog.accessRights.title') }}
    </h3>
    <div class="mt-3 mb-6">
      {{ $t('project.checklist.dialogs.ImportChecklistDialog.accessRights.subTitle') }}
    </div>

    <app-alert-in-page variant="info">
      {{ $t('project.checklist.dialogs.ImportChecklistDialog.accessRights.alertInPage') }}
      <a :href="learnMoreLinkUrl" target="_blank" class="learnMoreLink">
        {{ $t('project.checklist.dialogs.ImportChecklistDialog.accessRights.learnMore') }}
        <font-awesome-icon :icon="['far', 'external-link']" class="ml-1" size="xs"/>
      </a>
    </app-alert-in-page>

    <h4 class="text-h4 mt-6">
      {{ $t('project.checklist.dialogs.ImportChecklistDialog.accessRights.shareWith') }}
    </h4>
    <SaveChecklistDialogShareType without-private-option
                                  :default-share-type="computedShareType"
                                  @change-share-type="computedShareType = $event"
    />

    <!-- ShareType Custom Content -->
    <template v-if="shareType === SHARE_TYPE_OPTIONS.CUSTOM">
      <h4 class="my-4 text-h4">
        {{ $t('project.checklist.dialogs.ImportChecklistDialog.accessRights.participantsWithAccess') }}
      </h4>

      <MembersAutocomplete :items="allRoomUsersExceptPms" @select="onMemberSelect" />
      <DocumentSharePicker forcePmSelection reset-groups-and-members-in-parent/>
    </template>

    <!-- Add Customized Message -->
    <v-checkbox v-model="computedSendNotif"
                class="mt-4"
                hide-details
                :label="$t('project.checklist.dialogs.ImportChecklistDialog.accessRights.addCustomMessage')"
    ></v-checkbox>
    <VisualTextEditor v-if="sendNotif"
                      v-model="computedNotifyMessage"
                      class="mt-4"
                      counter="footer"
                      :error-messages="notifyMessageErrors"
                      :max-length="notifyMessageMaxLength"
    />
  </div>
</template>

<script>
  import { maxLength, required } from 'vuelidate/lib/validators'
  import { mapActions, mapGetters } from 'vuex'

  import DocumentSharePicker from '@/common/document-share/DocumentSharePicker.vue'
  import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes.ts'
  import { escapeHtml } from '@/common/utils/strings'
  import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor.vue'
  import MembersAutocomplete from '@/project/members/MembersAutocomplete.vue'
  import { HIGHLIGHT_MEMBER, SELECT_MEMBER } from '@/store/modules/document-share-picker/action_types'
  import { GET_SELF_CONTACT_LIST } from '@/store/modules/user/action_types'
  import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

  import SaveChecklistDialogShareType from './SaveChecklistDialogShareType.vue'

  const NOTIFY_MESSAGE_MAX_LENGTH = 400

  export default {
    name: 'ImportChecklistDialogAccessRights',
    components: {
      DocumentSharePicker,
      SaveChecklistDialogShareType,
      MembersAutocomplete,
      VisualTextEditor,
    },
    props: {
      sendNotif: {
        type: Boolean,
        required: true,
      },
      notifyMessage: {
        type: String,
        required: true,
      },
      shareType: {
        type: String,
        required: true,
      },
    },
    validations: {
      name: { required, maxLength: maxLength(100) },
    },
    data () {
      return {
        SHARE_TYPE_OPTIONS,
        notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
      }
    },
    computed: {
      ...mapGetters('room', ['roomMnemo']),
      ...mapGetters('groups', ['allRoomMembers', 'projectManagers']),
      computedSendNotif: {
        get () {
          return this.sendNotif
        },
        set (value) {
          this.$emit('set-send-notif', value)
        },
      },
      computedNotifyMessage: {
        get () {
          return this.notifyMessage
        },
        set (value) {
          this.$emit('set-notif-message', value)
        },
      },
      computedShareType: {
        get () {
          return this.shareType
        },
        set (value) {
          this.$emit('change-share-type', value)
        },
      },
      allRoomUsersExceptPms () {
        return this.allRoomMembers.filter((user) => {
          return !this.projectManagers.some(pm => pm.id === user.id)
        })
      },
      notifyMessageErrors () {
        const errors = []
        if (this.notifyMessageIsTooLong) {
          errors.push(this.$t('common.validations.textTooLong'))
        }
        return errors
      },
      notifyMessageIsTooLong () {
        return this.notifyMessageLength > this.notifyMessageMaxLength
      },
      notifyMessageLength () {
        return escapeHtml(this.notifyMessage).length
      },
      learnMoreLinkUrl () {
        const baseUrl = 'https://help.closd.com/'
        if (this.$i18n.locale === 'fr') {
          return `${baseUrl}fr/knowledge-base/comment-fonctionnent-les-droits-dacces-de-la-page-cheklist/`
        }
        return `${baseUrl}en/knowledge-base/how-do-access-rights-work-on-the-checklist/`
      },
    },
    mounted () {
      this.prepareGetSubscriptionUsers()
    },
    methods: {
      ...mapActions('user', [GET_SELF_CONTACT_LIST]),
      ...mapActions('documentSharePicker', [HIGHLIGHT_MEMBER, SELECT_MEMBER]),
      async prepareGetSubscriptionUsers () {
        try {
          await this.GET_SELF_CONTACT_LIST({ fromUserSubscriptionOnly: true })
        } catch (error) {
          console.error(error)
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('common.subscription.errors.getSubscriptionUsersError'),
          })
        }
      },
      onMemberSelect (member) {
        if (member) {
          this.SELECT_MEMBER({ member, multiselect: true })
          this.HIGHLIGHT_MEMBER(member)
        }
      },
    },
  }
</script>
