export const USER_SETTINGS_KEY = 'user-settings'

/**
 * Returns user's settings stored locally in the local storage
 * @param {number} userId user's id
 * @returns {object} user's settings object { [settingKey]: settingValue }, or null if no settings are stored for the provided user id
 * */
export function getUserSettings (userId) {
  if (!userId) {
    return null
  }

  const settings = localStorage.getItem(USER_SETTINGS_KEY)
  if (!settings) {
    return null
  }

  let parsedSettings
  try {
    parsedSettings = JSON.parse(settings)
  } catch {
    return null
  }

  return parsedSettings[userId] ?? null
}

/**
 * Returns specific user's setting stored locally in the local storage
 * @param {number} userId user's id
 * @param {string} settingKey key of the setting to retrieve
 * @returns {string | null} user's setting matching the provided key
 * */
export function getUserSetting (userId, settingKey) {
  return getUserSettings(userId)?.[settingKey] ?? null
}

/**
 * Set specific user's setting in the local storage
 * @param {number} userId user's id
 * @param {string} key key of the setting to set
 * @param {any} value value of the setting to set
 * */
export function setUserSetting (userId, key, value) {
  const userSettings = localStorage.getItem(USER_SETTINGS_KEY)
  let newUserSettings

  if (!userSettings) {
    newUserSettings = {
      [userId]: {
        [key]: value,
      },
    }
  } else {
    try {
      newUserSettings = JSON.parse(userSettings)
    } catch {
      newUserSettings = {}
    }

    newUserSettings[userId] = {
      ...newUserSettings[userId],
      [key]: value,
    }
  }

  localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(newUserSettings))
}

/**
 * Remove a specific user's setting stored locally in the local storage
 * @param {number} userId user's id
 * @param {string} settingKey key of the setting to remove
 * */
export function removeUserSetting (userId, settingKey) {
  const userSettings = getUserSettings(userId)

  if (!userSettings) {
    return
  }

  delete userSettings[settingKey]

  localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(userSettings))
}
