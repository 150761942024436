<template>
  <v-expansion-panel class="PrepareSignatureSidebarSigner">
    <v-expansion-panel-header expand-icon="far fa-chevron-down">
      <div class="d-flex align-center justify-lg-space-between pr-2">
        <div v-if="isWitness" class="d-flex align-center">
          <AppTooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <app-icon v-if="signerColor"
                          icon-name="link"
                          class="mr-2"
                          size="small"
                          :style="{color: signerColor}"
                />
              </div>
            </template>
            <span>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSigner.witnessTooltip') }}</span>
          </AppTooltip>
          <app-text as="span" variant="small-regular">{{ computedSignerName }}</app-text>
        </div>
        <div v-else class="d-flex align-center column-gap-2">
          <div v-if="signerColor"
               class="PrepareSignatureSidebarSigner-color"
               :style="{'backgroundColor': signerColor}"
          ></div>
          <AppTextField v-if="isEditModeActive"
                        v-model.trim="editedSignerName"
                        autofocus
                        maxlength="100"
                        @blur="onRenameSigner"
                        @keypress.enter="onRenameSigner"
                        @click.stop
          />
          <app-text v-else
                    as="span"
                    :variant="isUniqueSigner ? 'small-bold' : 'small-regular'" class="text-break"
          >
            {{ computedSignerName }}
          </app-text>
          <AppHelpTooltip v-if="isUniqueSigner" :text="$t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSigner.uniqueSignerHelpText')" />
        </div>

        <div class="d-flex align-center">
          <AppTooltip v-if="isTemplate" top>
            <template #activator="{on, attrs}">
              <app-button :disabled="isEditModeActive || isReadOnly"
                          :size="$vuetify.breakpoint.lgAndDown ? 'small' : 'medium'"
                          type="icon"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="onActiveEditMode"
              >
                <font-awesome-icon :icon="['far', 'edit']" />
              </app-button>
            </template>
            <span>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSigner.renameSignerLabel') }}</span>
          </AppTooltip>

          <AppTooltip v-if="!isUniqueSigner && !isTemplate" top>
            <template #activator="{on, attrs}">
              <app-button :disabled="isReadOnly"
                          :size="$vuetify.breakpoint.lgAndDown ? 'small' : 'medium'"
                          type="icon"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="onChangeSigner"
              >
                <font-awesome-icon :icon="['far', 'exchange']" />
              </app-button>
            </template>
            <span> {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSigner.changeSignatory') }} </span>
          </AppTooltip>

          <AppTooltip top>
            <template #activator="{on, attrs}">
              <app-button :disabled="isDeleteSignerPending || isReadOnly"
                          :loading="isDeleteSignerPending"
                          :size="$vuetify.breakpoint.lgAndDown ? 'small' : 'medium'"
                          type="icon"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="onDeleteSigner"
              >
                <font-awesome-icon :icon="['far', 'trash-alt']" />
              </app-button>
            </template>
            <span>
                  {{
                isWitness
                  ? $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSigner.deleteWitness')
                  : $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSigner.deleteSignatory')
              }}
                </span>
          </AppTooltip>
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pt-4">
      <v-row>
        <v-col v-for="tag in availableTags"
               :key="`tag-${tag.type}`"
               class="py-1"
               cols="12"
        >
            <PrepareSignatureSidebarSignerTag :tag="tag"
                                              :signer="signer"
                                              :signer-color="signerColor"
                                              :signer-initials="computedSignerInitials"
                                              :signer-name="computedSignerName"
                                              @drag-tag-active="onDragTagActive"
            />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>

import AppHelpTooltip from '@/common/AppHelpTooltip.vue'
import AppTextField from '@/common/AppTextField'
import AppTooltip from '@/common/AppTooltip'
import { tagsTypes } from '@/common/prepare-signature/tagsTypes'
import { SignersColors } from '@/project/signing-checklist/prepare-signature/signers-colors'

import PrepareSignatureSidebarSignerTag from './PrepareSignatureSidebarSignerTag'

export default {
  name: 'PrepareSignatureSidebarSigner',
  components: {
    AppHelpTooltip,
    AppTextField,
    AppTooltip,
    PrepareSignatureSidebarSignerTag,
  },
  props: {
    deleteSignerPendingIds: {
      type: Array,
      required: true,
    },
    dragActive: {
      type: Boolean,
      required: true,
    },
    isMultipleEnvelope: {
      type: Boolean,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
    },
    isTemplate: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    order: {
      type: Number,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
    signer: {
      type: Object,
      required: true,
    },
    isWitness: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      editedSignerName: null,
      isEditModeActive: false,
    }
  },
  computed: {
    availableTags () {
      const tags = []
      tags.push({ type: tagsTypes.SIGN, icon: 'file-signature', iconWeight: 'far' })

      if (this.provider !== 'pdf') {
        tags.push(
          { type: tagsTypes.INITIAL, icon: 'id-badge', iconWeight: 'far' },
          { type: tagsTypes.TEXT, icon: 'i-cursor', iconWeight: 'far' },
          { type: tagsTypes.TEXT_READ_ONLY, icon: 'text-size', iconWeight: 'far', hasNewLabel: true },
          { type: tagsTypes.NAME, icon: 'id-card', iconWeight: 'far' },
          { type: tagsTypes.DATE, icon: 'calendar-alt', iconWeight: 'far' },
          { type: tagsTypes.CERTIFIED, icon: 'quote-right', iconWeight: 'fas' },
          { type: tagsTypes.CHECKBOX, icon: 'check-square', iconWeight: 'far' },
          { type: tagsTypes.OPTIONAL_CHECKBOX, icon: 'check-square', iconWeight: 'far' },
        )
      }
      return tags
    },
    computedSignerName () {
      const numbering = `#${this.index + 1}`

      if (this.signer.fullName) { return this.signer.fullName }
      if (this.isMultipleEnvelope) {
        if (this.isUniqueSigner) { return this.$tc('common.signerType.uniqueSigner', 1) }
        return `${this.$tc('common.signerType.commonSigner', 1)} ${numbering}`
      }
      return `${this.$tc('common.signer', 1)} ${numbering}`
    },
    computedSignerInitials () {
      if (this.signer.initials) { return this.signer.initials }

      const array = this.computedSignerName.split(' ')
      let string = ''
      array.forEach(i => {
        const initial = i[0]
        string += initial === '#' ? i : i[0]
      })
      return string.toUpperCase()
    },
    isSignerNameEdited () {
      return this.editedSignerName && this.computedSignerName !== this.editedSignerName
    },
    isDeleteSignerPending () {
      return this.deleteSignerPendingIds.includes(this.signer.signatureId)
    },
    isUniqueSigner () {
      return this.signer.signatureType === 'multiple'
    },
    signerColor () {
      const i = this.index % (Object.entries(SignersColors).length / 2)
      return SignersColors[i]
    },
  },
  methods: {
    onActiveEditMode () {
      this.editedSignerName = this.computedSignerName
      this.isEditModeActive = true
    },
    onChangeSigner () {
      this.$emit('change-signer', this.signer)
    },
    onDeleteSigner () {
      this.$emit('delete-signer', this.signer.signatureId)
    },
    onDragTagActive (data) {
      this.$emit('drag-tag-active', data)
    },
    onRenameSigner () {
      if (this.isSignerNameEdited) {
        this.$emit('rename-signer', { signatureId: this.signer.signatureId, name: this.editedSignerName })
      }
      this.isEditModeActive = false
    },
  },
}
</script>

<style scoped lang="scss">
  ::v-deep .v-expansion-panel-header {
    padding : 16px;
  }
  .PrepareSignatureSidebarSigner {
    transition: none;
    border-bottom: 1px solid var(--v-grey-lighten4);

    &-color {
      width: 12px;
      min-width: 12px;
      height: 12px;
      border-radius: 4px;
    }
  }
</style>
