<template>
  <v-container
    fluid
    class="pa-0"
    :class="{ 'px-1': $vuetify.breakpoint.mdAndDown }"
  >
    <DataRoomLayout v-if="isDataRoom"
                    :mnemo="mnemo"
    />
    <ProjectLayout v-else
                   :mnemo="mnemo"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import { RESET_ROOM } from '../../store/modules/room/mutation_types'
import DataRoomLayout from '../project-settings/layouts/DataRoomLayout'
import ProjectLayout from '../project-settings/layouts/ProjectLayout'
export default {
  name: 'ProjectSettings',
  components: {
    DataRoomLayout,
    ProjectLayout,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  metaInfo () {
    return {
      title: this.$t('project.views.ProjectSettings.metaTitle'),
    }
  },
  computed: {
    ...mapGetters('room', ['isDataRoom']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
  },
  created () {
    if (!this.isPm) {
      this.$store.commit('enqueueSnackbar', {
        color: 'error',
        timeout: 5000,
        message: this.$t('project.views.ProjectSettings.nonExistentProject'),
      })
      this.$router.push('/')
    }
  },
  beforeDestroy () {
    this.$store.commit(`room/${RESET_ROOM}`)
  },
}
</script>
