<template>
  <AppDialog :is-open="isDialogOpened"
             fullscreen
             :persistent="false"
             hide-footer
             background-grey
             :header-color="computedColorTheme"
             header-dark
             transition="dialog-transition"
             @cancel="closeDialog"
             v-on:update:isOpen="onUpdateIsOpen"
  >
    <template #title>
      <div class="WatermarkedViewerDialog__title d-flex align-center flex-wrap">
        <AppTooltip bottom>
          <template #activator="{attrs, on}">
            <div class="WatermarkedViewerDialog__title__fileName text-truncated mr-1"
                 v-bind="attrs"
                 v-on="on"
            >
              {{ watermarkedDialogData.file.basename }}
            </div>
          </template>
          <span>{{ watermarkedDialogData.file.basename }}</span>
        </AppTooltip>
        <div class="mr-1">{{ $t('project.documents.watermarked-viewer.WatermarkedViewerDialog.uploadedOn') }}</div>
        <DateDisplay :value="watermarkedDialogData.file.uploadTime"
                     with-hours
                     class="mr-1"
        />
        <div>{{ $t('project.documents.watermarked-viewer.WatermarkedViewerDialog.uploadedBy') }} {{ watermarkedDialogData.file.uploadUser }}</div>
      </div>
    </template>
    <template #body>
      <WatermarkedDocumentsViewer :mnemo="mnemo"
                                  :file-id="watermarkedDialogData.file.id"
      />
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import WatermarkedDocumentsViewer from './WatermarkedDocumentsViewer'
import AppTooltip from '../../../common/AppTooltip'
import DateDisplay from '../../../common/DateDisplay'
import AppDialog from '../../../common/dialogs/AppDialog'
import { getRoomColorTheme } from '../../../common/utils/rooms'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { CANCEL_ALL_WATERMARKED_REQUEST_PENDING } from '../../../store/modules/files/action_types'
export default {
  name: 'WatermarkedViewerDialog',
  components: { DateDisplay, WatermarkedDocumentsViewer, AppDialog, AppTooltip },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('files', ['watermarkedDialogData']),
    ...mapState('room', ['currentRoom']),
    computedColorTheme () {
      return getRoomColorTheme(this.currentRoom)
    },
  },
  beforeDestroy () {
    this.CANCEL_ALL_WATERMARKED_REQUEST_PENDING()
  },
  methods: {
    ...mapActions('files', [CANCEL_ALL_WATERMARKED_REQUEST_PENDING]),
    onUpdateIsOpen (value) {
      if (!value) {
        this.closeDialog()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.WatermarkedViewerDialog {
  &__title {
    &__fileName {
      max-width: 350px;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-width: 250px;
      }
    }
  }
}
</style>
