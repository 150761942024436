<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          fixed-header
          disable-sort
          :headers="tableFields"
          :items="importedFiles"
          mobile-breakpoint="900"
          :items-per-page="-1"
          :item-class="getRowClass"
          class="ArchivesAddStep2-table"
        >
          <template v-slot:item.name="data">
            <ArchivesAddStep2TableCellName :item="data.item" />
          </template>
          <template v-slot:item.ref="data">
            <ArchivesAddStep2TableCellRef :item="data.item" />
          </template>
          <template v-slot:item.signers="data">
            <ArchivesAddStep2TableCellSigners :item="data.item" />
          </template>
          <template v-slot:item.date="data">
            <ArchivesAddStep2TableCellDate
              :item="data.item"
              @newDate="data.item.objectToPost.fileSigningDate = $event"
            />
          </template>
          <template
            v-for="field in additionalFields"
            v-slot:[`item.${field.value}`]="data"
          >
            <div :key="field.value">
            <ArchivesAddStep2TableCellAdditionalField
              :key="field.value"
              :item="data.item"
              :field="field.value"
            />
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ArchivesAddStep2TableCellAdditionalField from './ArchivesAddStep2TableCellAdditionalField'
import ArchivesAddStep2TableCellDate from './ArchivesAddStep2TableCellDate'
import ArchivesAddStep2TableCellName from './ArchivesAddStep2TableCellName'
import ArchivesAddStep2TableCellRef from './ArchivesAddStep2TableCellRef'
import ArchivesAddStep2TableCellSigners from './ArchivesAddStep2TableCellSigners'

const { mapGetters } = createNamespacedHelpers('archives')
export default {
  name: 'ArchivesAddStep2',
  components: {
    ArchivesAddStep2TableCellName,
    ArchivesAddStep2TableCellRef,
    ArchivesAddStep2TableCellSigners,
    ArchivesAddStep2TableCellDate,
    ArchivesAddStep2TableCellAdditionalField,
  },
  props: {
    importedFiles: {
      required: true,
      validator: (value) => {
        return value === undefined || Array.isArray(value)
      },
    },
  },
  data () {
    return {
      tableFields: [],
      additionalFields: [],
    }
  },
  computed: {
    ...mapGetters(['metadata']),
  },
  created () {
    let additionalFields = []
    if (this.metadata) {
      additionalFields = this.metadata.map((metadata) => ({
        value: metadata.field,
        text: metadata[this.$i18n.locale],
        class: 'ArchivesAddStep2-table-headerText',
      }))
    }

    this.tableFields = [
      {
        value: 'name',
        text: this.$t('archives.steps.ArchivesAddStep2.name'),
        class: 'ArchivesAddStep2-table-headerText',
        sortable: false,
      },
      {
        value: 'ref',
        text: this.$t('archives.steps.ArchivesAddStep2.ref'),
        class: 'ArchivesAddStep2-table-headerText',
      },
      {
        value: 'signers',
        text: this.$t('archives.steps.ArchivesAddStep2.signers'),
        class: 'ArchivesAddStep2-table-headerText',
      },
      {
        value: 'date',
        text: this.$t('archives.steps.ArchivesAddStep2.date'),
        class: 'ArchivesAddStep2-table-headerText',
      },
      ...additionalFields,
    ]
    this.additionalFields = additionalFields
  },
  methods: {
    getRowClass (item) {
      let classes = 'ArchivesAddStep2-table-borderColor '
      const ext = item.extension
      if (
        ext === 'doc' ||
                    ext === 'docx' ||
                    ext === 'docm' ||
                    ext === 'dotx' ||
                    ext === 'dotm'
      ) {
        classes += 'ArchivesAddStep2-table-borderColor--word'
      } else if (
        ext === 'xls' ||
                    ext === 'xlt' ||
                    ext === 'xlm' ||
                    ext === 'xlsx' ||
                    ext === 'xlsm' ||
                    ext === 'xltx' ||
                    ext === 'xltm'
      ) {
        classes += 'ArchivesAddStep2-table-borderColor--excel'
      } else if (
        ext === 'ppt' ||
                    ext === 'pptx' ||
                    ext === 'vnd.ms-powerpoint' ||
                    ext === 'pps'
      ) {
        classes += 'ArchivesAddStep2-table-borderColor--powerpoint'
      } else if (
        ext === 'jpg' ||
                    ext === 'jpeg' ||
                    ext === 'png' ||
                    ext === 'gif'
      ) {
        classes += 'ArchivesAddStep2-table-borderColor--image'
      } else if (
        ext === 'zip' ||
                    ext === 'rar' ||
                    ext === '7z' ||
                    ext === 'tar' ||
                    ext === 'gz' ||
                    ext === 'bz'
      ) {
        classes += 'ArchivesAddStep2-table-borderColor--zip'
      } else if (
        ext === 'pdf'
      ) {
        classes += ' ArchivesAddStep2-table-borderColor--pdf'
      }
      if (this.$vuetify.breakpoint.mdAndUp) { classes += ' column-limit' }
      return classes
    },
  },
}
</script>

<style scoped lang="scss">
$zipColor: #f7e49b;
$pdfColor: #cc453e;
$wordColor: #5d8eee;
$excelColor: #4fa169;
$powerpointColor: #cb4323;
$imageColor: #31b45f;
$folderColor: #6aaefe;
$fileColor: #a7a7a7;

::v-deep .column-limit td {
  max-width: 250px;
}
::v-deep .ArchivesAddStep2-table {
  .v-data-table-header-mobile {
    display: none;
  }

  thead {
    background-color: $greyLighter;
    border-radius: 1px;

    tr {
      td:first-child {
        border-left-style: solid;
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
      }
      td:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 1px;
        border-top-right-radius: 1px;
      }
    }
  }
  table {
    border-spacing: 0 0.5rem !important;
  }
  .ArchivesAddStep2-table-headerText {
    font-size: 16px;
    font-weight: 600;
  }
  tbody {
    tr {
      background-color: $bgGrey;

      td:not(.v-data-table__mobile-row) {
        width: 20%;

        &:first-child {
          width: 40%;
        }
      }

      &.ArchivesAddStep2-table-borderColor {
        border-left: 4px solid $fileColor;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        &.ArchivesAddStep2-table-borderColor--word td {
          border-color: $wordColor;
        }
        &.ArchivesAddStep2-table-borderColor--excel td {
          border-color: $excelColor;
        }
        &.ArchivesAddStep2-table-borderColor--powerpoint td {
          border-color: $powerpointColor;
        }
        &.ArchivesAddStep2-table-borderColor--image td {
          border-color: $imageColor;
        }
        &.ArchivesAddStep2-table-borderColor--zip td {
          border-color: $zipColor;
        }
        &.ArchivesAddStep2-table-borderColor--pdf td {
          border-color: $pdfColor;
        }
      }

      td:first-child, .v-data-table__mobile-row {
        &.ArchivesAddStep2-table-noBorder {
          border: none !important;
        }
        border-left: 4px solid $fileColor;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        &.ArchivesAddStep2-table-borderColor--word {
          border-color: $wordColor;
        }
        &.ArchivesAddStep2-table-borderColor--excel {
          border-color: $excelColor;
        }
        &.ArchivesAddStep2-table-borderColor--powerpoint {
          border-color: $powerpointColor;
        }
        &.ArchivesAddStep2-table-borderColor--image {
          border-color: $imageColor;
        }
        &.ArchivesAddStep2-table-borderColor--zip {
          border-color: $zipColor;
        }
        &.ArchivesAddStep2-table-borderColor--pdf {
          border-color: $pdfColor;
        }
        &.v-data-table__mobile-row {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        &.v-data-table__mobile-row:first-child {
          border-top-left-radius: 6px;
        }
        &.v-data-table__mobile-row:last-child {
          border-bottom-left-radius: 6px;
        }
      }
      &.v-data-table__mobile-table-row {
        border-left: none;
        // We remove the "initial" value from Vuetify so it takes
        // the border spacing into account even in mobile
        display: revert;
      }
    }
  }
}
</style>
