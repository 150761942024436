<template>
    <span>
        <template v-if="value">
            {{$t('common.BooleanDisplay.true')}}
        </template>
        <template v-else>
            {{$t('common.BooleanDisplay.false')}}
        </template>
    </span>
</template>

<script>
/**
 * Default Boolean display
 * @displayName BooleanDisplay
 */
/**
 * Display a text value for a Boolean
 */
export default {
  name: 'BooleanDisplay',
  props: {
    /**
     * @model
     * Boolean value
     */
    value: {
      required: true,
    },
  },
}
</script>

<docs>
```vue
<template>
  <BooleanDisplay value="true"/>
</template>
```
</docs>
