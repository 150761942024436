export const state = {
  filesToUpload: [],
  simultaneousFileUpload: Number(process.env.VUE_APP_SIMULTANEOUS_FILES_UPLOAD) || 2,
  uploadInProgress: 0,
  uploadQueue: [],
  uploadHint: {
    visible: false,
    destinationFolder: null,
    messageType: null,
  },
}
