<template>
  <div class="AppFileUploadHint rounded-lg elevation-10">
    <div class="header-icon">
      <div class="header-icon__stack">
        <font-awesome-icon :icon="['fas', 'circle']"
                           :style="{color: isError ? '#DEE2E6' : '#2196F3'}"
                           style="width: 72px; height: 72px"
        >
        </font-awesome-icon>
        <font-awesome-icon v-if="!isError"
                           :icon="['fas', 'folder']"
                           style="color: white; width: 36px; height: 31px; top: 20px; left: 18px"
        />
        <font-awesome-icon v-if="!isError"
                           :icon="['fas', 'arrow-up']"
                           style="color: #2196F3; width: 16px; height: 16px; top: 30px; left: 28px"
        />
        <font-awesome-icon v-if="isError"
                           :icon="['fas', 'folder-xmark']"
                           style="color: white; width: 36px; height: 31px; top: 20px; left: 18px"
        />
      </div>
    </div>

    <span class="text-body-2 my-1 text-center"
          v-html="$tc(
            isError
              ? `common.app-file-upload.AppFileUploadHint.${uploadHint.messageType}`
              : 'common.app-file-upload.AppFileUploadHint.textMovable', documentsToMove?.documents?.length, { count: documentsToMove?.documents?.length },
          )"
    />

    <div v-if="!isError">
      <i class="fas fa-folder" style="color: #2196F3"></i>
      <span class="text-body-2 font-weight-medium ml-2">{{ uploadHint.destinationFolder }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { DRAG_AND_DROP_MESSAGE_TYPE } from '../utils/dragAndDropMessageType'

export default {
  name: 'AppFileUploadHint',
  data () {
    return {
      DRAG_AND_DROP_MESSAGE_TYPE,
    }
  },
  computed: {
    ...mapState('appUploadHandler', ['uploadHint']),
    ...mapState('documents', ['documentsToMove']),
    isError () {
      return this.uploadHint.messageType === DRAG_AND_DROP_MESSAGE_TYPE.ERROR_NOT_ALLOWED_FOLDER || this.uploadHint.messageType === DRAG_AND_DROP_MESSAGE_TYPE.ERROR_DROP_IN_SELECTED_FOLDER
    },
  },
}
</script>

<style scoped lang="scss">
.AppFileUploadHint {
  z-index: 5;
  position: fixed;
  bottom: 42px;
  left:50%;
  transform: translateX(-50%);
  width: 388px;
  padding: 42px 32px 16px 32px;
  background-color: white;

  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px 0;
  }
}

.header-icon {
  position: absolute;
  top: -42px;
  left: 158px;

  &__stack {
    position: relative;

    & > * {
      position: absolute;
    }
  }
}
</style>
