<template>
  <Pane v-model="computedValue" :tabs="tabs" current-tab="share" temporary>
    <template #content-share>
      <v-row class="px-5 pt-5">
        <v-col class="text-h4">{{$tc('project.signed-documents.pane.SharePane.selectedDocuments', documents.length)}}</v-col>
      </v-row>
      <v-row class="px-5 mb-3">
        <v-col>
          <v-chip v-for="document in documents"
                  :key="`document-${document.type}-${document.id}`"
                  class="Document-chip mr-2 mb-2"
                  outlined
                  close
                  close-icon="fal fa-xmark"
                  @click:close="$emit('removeDocumentToShare', document)"
          >
            <font-awesome-icon class="DocumentIcon mr-1"
                               :icon="['fal', 'file-signature']"
                               fixed-width
            ></font-awesome-icon>
            <AppTooltip top>
              <template #activator="{ on, attrs }">
                <div class="Document-name"
                     v-bind="attrs"
                     v-on="on"
                >
                  {{document.basename || document.name}}
                </div>
              </template>
              <span>{{document.basename || document.name}}</span>
            </AppTooltip>
          </v-chip>
        </v-col>
      </v-row>
      <div class="px-5 mb-3">
        <v-row>
          <v-col>
            <DocumentSharePicker v-if="computedValue"
                                 users-only
                                 :force-pm-selection="false"
                                 :documents="documents"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox v-model="sendNotif"
                        hide-details
                        class="mt-0 pt-0"
                        :label="$t('project.signed-documents.pane.SharePane.sendNotif')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <VisualTextEditor v-model="notifyMessage"
                              content=" "
                              counter="footer"
                              :editing="true"
                              :error-messages="notifyMessageErrors"
                              :max-length="notifyMessageMaxLength"
                              :padding="true"
                              :placeholder="$t('project.signed-documents.pane.SharePane.optionalMessageWithCopy')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <AppButton color="primary"
                       outlined
                       block
                       @click="computedValue = false"
            >
              {{$t('common.cancel')}}
            </AppButton>
          </v-col>
          <v-col>
            <AppButton color="primary"
                       block
                       :loading="shareLoading"
                       :disabled="shareDisabled"
                       @click="share"
            >
              {{$t('common.share')}}
            </AppButton>
          </v-col>
        </v-row>
      </div>
    </template>
  </Pane>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { GET_SIGNED_DOCUMENTS } from '@/store/modules/signed-documents/action_types'

import AppTooltip from '../../../common/AppTooltip'
import AppButton from '../../../common/buttons/AppButton'
import DocumentSharePicker from '../../../common/document-share/DocumentSharePicker'
import Pane from '../../../common/Pane'
import { escapeHtml } from '../../../common/utils/strings'
import VisualTextEditor from '../../../common/visual-text-editor/VisualTextEditor'
import {
  RESET_DISABLED_MEMBERS,
  RESET_SELECTED_GROUPS_AND_MEMBERS,
} from '../../../store/modules/document-share-picker/action_types'

const NOTIFY_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'SharePane',
  components: {
    AppButton,
    DocumentSharePicker,
    Pane,
    VisualTextEditor,
    AppTooltip,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      tabs: [
        {
          title: this.$t('common.share'),
          id: 'share',
        },
      ],
      sendNotif: true,
      notifyMessage: '',
      notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
      shareLoading: false,
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    ...mapState('documentSharePicker', ['selectedMembers']),
    computedValue: {
      get () { return this.value },
      set (value) {
        this.$emit('input', value)
        if (!value) {
          this.$emit('update:documents', [])
          this.closePane()
        }
      },
    },
    notifyMessageLength () {
      return escapeHtml(this.notifyMessage).length
    },
    notifyMessageIsTooLong () {
      return this.notifyMessageLength > this.notifyMessageMaxLength
    },
    notifyMessageErrors () {
      const errors = []

      if (this.notifyMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    shareDisabled () {
      return this.documents.length === 0 || this.notifyMessageIsTooLong
    },
  },
  methods: {
    ...mapActions('signedDocuments', ['shareOriginal', GET_SIGNED_DOCUMENTS]),
    ...mapActions('documentSharePicker', [RESET_SELECTED_GROUPS_AND_MEMBERS, RESET_DISABLED_MEMBERS]),
    closePane () {
      this.sendNotif = true
      this.notifyMessage = ''
      this.shareLoading = false
    },
    async share () {
      await this.shareOriginal({
        mnemo: this.roomMnemo,
        data: {
          envelopesIds: this.documents.map(o => o.id),
          withUsers: this.selectedMembers.map(o => o.id),
          notifyMessage: this.notifyMessage,
        },
      })
        .then(() => {
          this.computedValue = false
          this.GET_SIGNED_DOCUMENTS({ mnemo: this.$route.params.mnemo, params: { withRights: true } })
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: this.$tc('project.signed-documents.pane.SharePane.shareSuccess', this.documents.length),
          })
        })
    },
  },
}
</script>

<style scoped lang="scss">
  .Document-name {
    max-width: 350px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Document-chip {
  .v-chip__close:hover {
    color: var(--v-error-base) !important;
  }
  }
</style>
