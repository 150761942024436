<template>
<div class="AppFileManager">
  <v-expansion-panels v-model="isOpen"
                      class="AppFileManager__panels"
  >
    <v-expansion-panel class="AppFileManager__panel">
      <v-expansion-panel-header class="AppFileManager__panel__header">
        <slot name="header"></slot>
        <template #actions>
          <AppButton icon>
            <font-awesome-icon :icon="['far', 'chevron-up']"
                               class="AppFileManager__modelIcon text--primary"
                               :class="{'AppFileManager__modelIcon--open': computedIsOpen}"
            ></font-awesome-icon>
          </AppButton>
          <AppButton icon
                     @click.stop="$emit('cancel')"
          >
            <font-awesome-icon :icon="['far', 'xmark']"
                               class="AppFileManager__cancelIcon text--primary"
            ></font-awesome-icon>
          </AppButton>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="AppFileManager__panel__content">
        <slot name="content"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</div>
</template>

<script>
import AppButton from './buttons/AppButton'
export default {
  name: 'AppFileManager',
  components: { AppButton },
  data () {
    return {
      isOpen: 0,
    }
  },
  computed: {
    computedIsOpen () {
      return typeof this.isOpen === 'number'
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep.AppFileManager {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  z-index: 5;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    right: 50px;
  }

  &__panels,
  &__panel {
    border-radius: 8px 9px 0 0 !important;
  }

  &__panels {
    box-shadow: 0 4px 30px rgba(148, 148, 148, 0.3);
    border: 1px solid #e0e0e0;
  }

  &__panel {
    &__header {
      min-height: 56px;
      border-bottom: 1px solid #eee;
      font-size: 16px;
      font-weight: 500;
    }
    &__content {
      padding-top: 8px;
    }
  }

  &__modelIcon {
    transition: transform .3s;
    &--open {
      transform: rotate(180deg);
    }
  }

  &__cancelIcon {
    font-size: 20px;
  }
}
</style>
