<template>
  <div>
    <PrepareSignatureViewer :is-data-ready="isDataReady">
      <template #header>
        <PrepareSignatureTopbar :current-page="currentPage"
                                :envelope-name="displayInformations.name"
                                :pages-count="displayInformations.totalPages"
                                @scroll-to="scrollTo"
        />
      </template>
      <template #sidebar>
        <PrepareSignatureSidebar
          :delete-signer-pending-ids="deleteSignerPendingIds"
          :envelope-id="envelopeToPrepare.id"
          :envelope-type="envelopeToPrepare.envelopeType"
          :provider="envelopeToPrepare.provider"
          :signature-type="envelopeToPrepare.signatureType"
          :signers="computedSigners"
          @delete-all-tags="onDeleteAllTags"
          @delete-signer="deleteSigner"
          @rename-signer="renameSigner"
          @scroll-to="scrollTo"
          @get-pages="getPagesContent"
          @get-tags="getTaggingInformations"
        />
      </template>
      <template #pages>
        <PrepareSignaturePagesLoader v-if="!isRenderingComplete"
                                     :render-progress="displayInformations.renderProgress"
        />

        <PrepareSignaturePage v-for="page in documentPages"
                              :ref="`page-${page.pageNumber}`"
                              :key="`page-${page.pageNumber}`"
                              :drag-tag-active="dragTagActive"
                              :envelope-id="envelopeToPrepare.id"
                              :envelope-type="envelopeToPrepare.envelopeType"
                              :is-pending="isTagEditionInProgress"
                              :page="page"
                              :page-content="pageContents[page.pageNumber]"
                              :pages-tags="envelopeTemplateTaggingInformations.tags"
                              :signers="computedSigners"
                              @delete-initials-tag="onDeleteInitialsTags"
                              @delete-tag="deleteTag"
                              @drag-tag-active="setDragTagActive"
                              @duplicate-checkbox="duplicateCheckbox"
                              @duplicate-tag="duplicateTag"
                              @page-visible="pageEnteredViewPort(page)"
                              @page-tags-ready="onPageTagsReady"
                              @patch-tag="patchTag"
                              @post-tag="postTag"
        />
      </template>
      <template #thumbs>
        <PrepareSignatureThumb v-for="page in documentPages"
                               :key="`page-${page.pageNumber}`"
                               :is-active-page="activePage === page.pageNumber"
                               :page-number="page.pageNumber"
                               :page-thumb="pageThumbs[page.pageNumber]"
                               :pages-tags="envelopeTemplateTaggingInformations.tags"
                               @scroll-to="scrollTo"
                               @thumb-visible="thumbEnteredViewPort(page)"
        />
      </template>
    </PrepareSignatureViewer>

    <PrepareSignatureDeleteAllTagsDialog v-if="isDeleteAllTagsDialogOpen"
                                         :is-pending="deleteAllTagsPending"
                                         @close="isDeleteAllTagsDialogOpen = false"
                                         @delete-all-tags="deleteAllTags({ all: true })"
    />
    <PrepareSignatureDeleteInitialsTagDialog v-if="isDeleteInitialsTagDialogOpen"
                                             :is-pending="isTagEditionInProgress"
                                             @close="onCloseDeleteInitialsTagDialog"
                                             @delete-tag="deleteTag(tagToDelete.id)"
                                             @delete-all-tags="deleteAllTags({ bySignatureIds: tagToDelete.signatureIds, byType: tagToDelete.type})"
    />
  </div>
</template>

<script lang="ts">
import { debounce, slice, take } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

import { tagsTypes } from '@/common/prepare-signature/tagsTypes'
import { typedMapActions, typedMapMutations } from '@/common/utils/store'
import PrepareSignatureDeleteAllTagsDialog from '@/project/signing-checklist/prepare-signature/PrepareSignatureDeleteAllTagsDialog.vue'
import PrepareSignatureDeleteInitialsTagDialog from '@/project/signing-checklist/prepare-signature/PrepareSignatureDeleteInitialsTagDialog.vue'
import PrepareSignaturePage from '@/project/signing-checklist/prepare-signature/PrepareSignaturePage.vue'
import PrepareSignaturePagesLoader from '@/project/signing-checklist/prepare-signature/PrepareSignaturePagesLoader.vue'
import PrepareSignatureSidebar from '@/project/signing-checklist/prepare-signature/PrepareSignatureSidebar.vue'
import PrepareSignatureThumb from '@/project/signing-checklist/prepare-signature/PrepareSignatureThumb.vue'
import PrepareSignatureTopbar from '@/project/signing-checklist/prepare-signature/PrepareSignatureTopbar.vue'
import PrepareSignatureViewer from '@/project/signing-checklist/prepare-signature/PrepareSignatureViewer.vue'
import type { EnvelopeTemplateToPrepare } from '@/project/views/EnvelopeTemplateAdd.vue'
import type { EnvelopeTemplatesActionType } from '@/store/modules/envelope-templates/action_types'
import type { EnvelopeTemplatesMutationType } from '@/store/modules/envelope-templates/mutation_types'
import { UPDATE_TAGS_OVERLAPS } from '@/store/modules/prepareSignature/action_types'
import { REMOVE_TAG_OVERLAP_BY_TAG_ID } from '@/store/modules/prepareSignature/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

const PAGES_TO_LOAD_OFFSET = 1
const THUMBS_TO_LOAD_OFFSET = 5
const DISPLAY_INFORMATIONS_POLLING_DELAY = 3000

type Tag = {
  id: number | null,
  tagHeight: number | null,
  tagPage: number,
  tagWidth: number | null,
  tagX: number,
  tagY: number,
}

type TagToDelete = {
  id: number | null,
  signatureIds: number[],
  type: string
}

export default defineComponent({
  name: 'PrepareSignatureTemplate',
  components: {
    PrepareSignatureDeleteAllTagsDialog,
    PrepareSignatureDeleteInitialsTagDialog,
    PrepareSignaturePage,
    PrepareSignaturePagesLoader,
    PrepareSignatureSidebar,
    PrepareSignatureTopbar,
    PrepareSignatureThumb,
    PrepareSignatureViewer,
  },
  props: {
    envelopeToPrepare: {
      type: Object as PropType<EnvelopeTemplateToPrepare>,
      required: true,
    },
  },
  data (): {
    activePage: number | null,
    currentPage: number,
    isDeleteAllTagsDialogOpen: boolean,
    isDeleteInitialsTagDialogOpen: boolean,
    displayInformationsPollingTimeout: number,
    pagesTagsReady: number[],
    tagToDelete: TagToDelete | null,
  } {
    return {
      activePage: null,
      currentPage: 1,
      isDeleteAllTagsDialogOpen: false,
      isDeleteInitialsTagDialogOpen: false,
      displayInformationsPollingTimeout: 0,
      pagesTagsReady: [],
      tagToDelete: null,
    }
  },
  computed: {
    ...mapState('envelopeTemplates', [
      'deleteAllTagsPending',
      'deleteSignerPendingIds',
      'deleteTagPending',
      'dragTagActive',
      'duplicateCheckboxPending',
      'duplicateTagPending',
      'envelopeTemplateDisplayInformations',
      'envelopeTemplateTaggingInformations',
      'envelopeTemplateDisplayInformationsPending',
      'pageContents',
      'pageThumbs',
      'patchTagPending',
      'postTagPending',
    ]),
    documentPages (): Array<EnvelopeDocumentPage> {
      return this.displayInformations.pages
    },
    computedSigners () {
      const signers : object[] = this.envelopeTemplateTaggingInformations.signers.map(signer => {
        signer.signatureId = signer.id
        signer.fullName = signer.name
        return signer
      })

      return [signers]
    },
    // same as envelopeTemplateDisplayInformations, but typed
    // should be fixed when we'll ba able to type vuex helpers
    displayInformations (): EnvelopeDisplayInformations {
      return this.envelopeTemplateDisplayInformations ?? {
        name: '',
        pages: [],
        totalPages: 0,
        renderProgress: 0,
      }
    },
    isDataReady (): boolean {
      return this.envelopeTemplateTaggingInformations !== null
    },
    isRenderingComplete (): boolean {
      return this.displayInformations.renderProgress === 100
    },
    isTagEditionInProgress (): boolean {
      return this.deleteAllTagsPending ||
          this.duplicateCheckboxPending ||
          this.duplicateTagPending ||
          this.patchTagPending ||
          this.postTagPending
    },
  },
  watch: {
    isRenderingComplete (isComplete) {
      if (isComplete) {
        clearTimeout(this.displayInformationsPollingTimeout)
        this.handleDisplayInformationsLoadingCompletion()
      }
    },
    pagesTagsReady: {
      handler (newVal) {
        if (newVal) {
          this.checkTagsOverlaps()
        }
      },
    },
  },
  created () {
    this.pageEnteredViewPort = debounce(this.pageEnteredViewPort, 500)
    this.thumbEnteredViewPort = debounce(this.thumbEnteredViewPort, 500)
  },
  async mounted () {
    this.getTaggingInformations()
    this.poolEnvelopeDisplayInformation()
  },
  beforeDestroy () {
    this.RESET_ENVELOPE_TEMPLATE_TO_PREPARE()
    if (this.displayInformationsPollingTimeout) {
      clearTimeout(this.displayInformationsPollingTimeout)
    }
  },
  methods: {
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    ...mapMutations('prepareSignature', [REMOVE_TAG_OVERLAP_BY_TAG_ID]),
    ...typedMapMutations<EnvelopeTemplatesMutationType>('envelopeTemplates', [
      'RESET_ENVELOPE_TEMPLATE_TO_PREPARE',
    ]),
    ...typedMapActions<EnvelopeTemplatesActionType>('envelopeTemplates', [
      'GET_ENVELOPE_TEMPLATE_DISPLAY_INFORMATIONS',
      'GET_ENVELOPE_TEMPLATE_TAGGING_INFORMATIONS',
      'GET_PAGES_CONTENT',
      'GET_PAGE_THUMB',
      'DRAG_TAG_ACTIVE',
      'DELETE_ALL_TAGS',
      'DELETE_SIGNER',
      'DELETE_TAG',
      'DUPLICATE_CHECKBOX',
      'DUPLICATE_TAG',
      'PATCH_TAG',
      'POST_TAG',
      'RENAME_ENVELOPE_TEMPLATE_SIGNER',
    ]),
    ...mapActions('prepareSignature', [UPDATE_TAGS_OVERLAPS]),
    async checkTagsOverlaps (newTagData : Tag | null = null) {
      await this.$nextTick()

      const tagsReady = this.envelopeTemplateTaggingInformations.tags.filter(tag => this.pagesTagsReady.includes(tag.pageNumber))

      let formattedNewTagData
      if (newTagData) {
        formattedNewTagData = {
          id: newTagData?.id || 0,
          height: newTagData?.tagHeight,
          pageNumber: newTagData?.tagPage,
          width: newTagData?.tagWidth,
          x: newTagData?.tagX,
          y: newTagData?.tagY,
        }
      }

      const tagsToCheck = tagsReady.map(tag => {
        if (tag.id === formattedNewTagData?.id) {
          return formattedNewTagData
        } else {
          return {
            id: tag.id,
            height: tag.tagHeight || document.querySelector(`#tag-${tag.id}`)?.getBoundingClientRect()?.height || null,
            pageNumber: tag.pageNumber,
            width: tag.tagWidth || document.querySelector(`#tag-${tag.id}`)?.getBoundingClientRect()?.width || null,
            x: tag.left,
            y: tag.top,
          }
        }
      })

      if (formattedNewTagData?.id === 0) {
        tagsToCheck.push(formattedNewTagData)
      }

      this.UPDATE_TAGS_OVERLAPS({ envId: this.envelopeToPrepare.id, tags: tagsToCheck })
    },
    async deleteSigner (signatureId) {
      try {
        await this.DELETE_SIGNER({
          envelopeTemplateId: this.envelopeToPrepare.id,
          signatureId: signatureId,
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.deleteSigner.successText'))

        this.updateStepSigners()
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.deleteSigner.errorText'))
      }
    },
    async deleteAllTags (data) {
      try {
        await this.DELETE_ALL_TAGS({
          envelopeTemplateId: this.envelopeToPrepare.id,
          data: data,
        })

        await this.getTaggingInformations()
        this.checkTagsOverlaps()

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.deleteAllTags.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.deleteAllTags.errorText'))
      } finally {
        this.isDeleteAllTagsDialogOpen = false
      }
    },
    async deleteTag (tagId) {
      try {
        this.REMOVE_TAG_OVERLAP_BY_TAG_ID(tagId)

        await this.DELETE_TAG({
          envelopeTemplateId: this.envelopeToPrepare.id,
          tagId: tagId,
        })

        await this.getTaggingInformations()
        this.checkTagsOverlaps()

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.deleteTag.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.deleteTag.errorText'))
      }
    },
    async duplicateCheckbox (data) {
      try {
        await this.DUPLICATE_CHECKBOX({
          envelopeTemplateId: this.envelopeToPrepare.id,
          data: {
            tag: data,
          },
        })

        await this.getTaggingInformations()
        this.checkTagsOverlaps()

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.duplicateTag.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.duplicateTag.errorText'))
      }
    },
    async duplicateTag (tagId) {
      try {
        const duplicatedTags = await this.DUPLICATE_TAG({
          envelopeTemplateId: this.envelopeToPrepare.id,
          tagId: tagId,
          data: null,
        })

        await this.getTaggingInformations()

        // Force overlaps check after tags duplication
        duplicatedTags.forEach(tag => {
          const tagData = {
            id: tag.id,
            tagHeight: tag.height || 0,
            tagPage: tag.page,
            tagType: tag.type,
            tagWidth: tag.width || 0,
            tagX: tag.x,
            tagY: tag.y,
          }

          this.patchTag(tagData, { quiet: true })
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.duplicateTag.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.duplicateTag.errorText'))
      }
    },
    async renameSigner (signerData) {
      try {
        await this.RENAME_ENVELOPE_TEMPLATE_SIGNER({
          envelopeTemplateId: this.envelopeToPrepare.id,
          signatureId: signerData.signatureId,
          data: {
            name: signerData.name,
          },
        })
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.renameSigner.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.renameSigner.errorText'))
      }

      this.getTaggingInformations()
    },
    getPagesContent (params: { fromPage: number, toPage: number }) {
      this.GET_PAGES_CONTENT({
        envelopeTemplateId: this.envelopeToPrepare.id,
        ...params,
      })
    },
    getPageThumb (pageId: number, pageNumber: number) {
      this.GET_PAGE_THUMB({
        envelopeTemplateId: this.envelopeToPrepare.id,
        pageId: pageId,
        pageNumber: pageNumber,
      })
    },
    async getTaggingInformations () {
      try {
        await this.GET_ENVELOPE_TEMPLATE_TAGGING_INFORMATIONS(this.envelopeToPrepare.id)
      } catch {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.loadingTemplateTagsErrorText'))
      }
    },
    async getDisplayInformations () {
      try {
        await this.GET_ENVELOPE_TEMPLATE_DISPLAY_INFORMATIONS(this.envelopeToPrepare.id)
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.loadingTemplateErrorText'))
      }
    },
    onCloseDeleteInitialsTagDialog () {
      this.tagToDelete = null
      this.isDeleteInitialsTagDialogOpen = false
    },
    onDeleteAllTags () {
      this.isDeleteAllTagsDialogOpen = true
    },
    onDeleteInitialsTags (tagData) {
      const signatureInitialsTags = this.envelopeTemplateTaggingInformations.tags.filter(tag => tag.type === tagsTypes.INITIAL && tagData?.signatureIds.includes(tag.signatureId))

      if (signatureInitialsTags.length > 1) {
        this.tagToDelete = tagData
        this.isDeleteInitialsTagDialogOpen = true
      } else {
        this.deleteTag(tagData.id)
      }
    },
    onPageTagsReady (pageNumber) {
      if (!this.pagesTagsReady.includes(pageNumber)) { this.pagesTagsReady.push(pageNumber) }
    },
    async patchTag (data, options) {
      try {
        await this.PATCH_TAG({
          envelopeTemplateId: this.envelopeToPrepare.id,
          data: {
            tag: data,
          },
        })

        if (!options?.quiet) {
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.patchTag.successText'))
        }
      } catch (e) {
        if (!options?.quiet) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.patchTag.errorText'))
        }
      }

      await this.getTaggingInformations()
      this.checkTagsOverlaps()
    },
    async postTag (data) {
      try {
        await this.POST_TAG({
          envelopeTemplateId: this.envelopeToPrepare.id,
          data: {
            tag: data,
          },
        })

        await this.getTaggingInformations()
        this.checkTagsOverlaps()

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.postTag.successText'))
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.prepare-signature.PrepareSignatureTemplate.postTag.errorText'))
      }
    },
    pageEnteredViewPort (page: EnvelopeDocumentPage) {
      this.setActivePage(page.pageNumber)

      const pageIndex = this.documentPages.findIndex(p => p.id === page.id)

      if (pageIndex === -1) {
        return
      }

      const pagesInRange = slice(this.documentPages, pageIndex - PAGES_TO_LOAD_OFFSET, pageIndex + PAGES_TO_LOAD_OFFSET + 1)

      pagesInRange.forEach(({ pageNumber }) => {
        const pageContent = this.pageContents[pageNumber]

        if (!pageContent || (!pageContent.data && !pageContent.isLoading)) {
          this.getPagesContent({ fromPage: pageNumber, toPage: pageNumber })
        }
      })
    },
    async poolEnvelopeDisplayInformation () {
      await this.getDisplayInformations()

      if (!this.isRenderingComplete) {
        this.displayInformationsPollingTimeout = setTimeout(
          this.poolEnvelopeDisplayInformation,
          DISPLAY_INFORMATIONS_POLLING_DELAY,
        )
      }
    },
    setActivePage (pageNumber: number) {
      this.activePage = pageNumber
      this.currentPage = pageNumber
    },
    setDragTagActive (value) {
      this.DRAG_TAG_ACTIVE(value)
    },
    scrollTo (pageNumber: number) {
      const el = (this.$refs[`page-${pageNumber}`] as Element)[0].$el
      if (el) {
        this.currentPage = pageNumber
        setTimeout(() => {
          el.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      }
    },
    thumbEnteredViewPort (page) {
      const pageIndex = this.documentPages.findIndex(p => p.id === page.id)

      if (pageIndex === -1) {
        return
      }

      const startIndex = Math.max(pageIndex - THUMBS_TO_LOAD_OFFSET, 0)
      const endIndex = Math.min(pageIndex + THUMBS_TO_LOAD_OFFSET, this.documentPages.length - 1)
      const pagesInRange = slice(this.documentPages, startIndex, endIndex)

      pagesInRange.forEach(({ id, pageNumber }) => {
        const pageThumb = this.pageThumbs[pageNumber]

        if (!pageThumb || (!pageThumb.data && !pageThumb.isLoading)) {
          this.getPageThumb(id, pageNumber)
        }
      })
    },
    async updateStepSigners () {
      this.getTaggingInformations()
    },
    handleDisplayInformationsLoadingCompletion () {
      const { pages, totalPages } = this.displayInformations

      this.getPagesContent({
        fromPage: 1,
        toPage: Math.min(totalPages, 3),
      })

      take(pages, 10)
          .forEach(({ id, pageNumber }) => {
            this.getPageThumb(id, pageNumber)
          })

      if (pages.length > 0) {
        this.setActivePage(1)
      }
    },
  },
})
</script>
