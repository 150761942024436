import axios from 'axios'

export default {
  getSigningView (mnemo, envelopeId) {
    return axios.get(`room/${mnemo}/envelope/${envelopeId}/signingView`)
  },
  getPageThumb (mnemo, envId, pageId) {
    return axios.post(`room/${mnemo}/downloads/envelopePageThumb/${envId}-${pageId}`)
  },
  signTag (mnemo, envelopeId, tagId, data) {
    return axios.post(`room/${mnemo}/envelope/${envelopeId}/oo_sign/tags/${tagId}`, data)
  },
  unsignTag (mnemo, envelopeId, tagId) {
    return axios.post(`room/${mnemo}/envelope/${envelopeId}/oo_sign/tags/${tagId}/untag`)
  },
  sentValidationSms (mnemo, envelopeId, data) {
    return axios.post(`room/${mnemo}/envelope/${envelopeId}/oo_sign/consent`, data)
  },
  resentValidationSms (mnemo, envelopeId) {
    return axios.post(`room/${mnemo}/envelope/${envelopeId}/oo_sign/resend`)
  },
  signDocument (mnemo, envelopeId, data) {
    return axios.post(`room/${mnemo}/envelope/${envelopeId}/oo_sign/confirm`, data)
  },
  confirmSignature (mnemo, envelopeId) {
    return axios.post(`/room/${mnemo}/envelope/${envelopeId}/oo_sign/confirm`)
  },
}
