<template>
  <AppMenuAsPopover :card-min-width="286"
                    :card-max-width="286"
                    offset-y
                    bottom
                    left
                    @menu-state-change="isMenuOpened = $event"
  >
    <template #activator="{attrs, on: menuOn}">
      <AppButton :height="28"
                 color="white"
                 v-bind="attrs"
                 v-on="{...menuOn}"
      >
        {{ $t(`project.checklist.dialogs.SaveChecklistDialog.rightTypeOptions.${accessRight}.title`) }}
        <app-icon :icon-name="`chevron-${isMenuOpened ? 'up' : 'down'}`"
                  icon-weight="fal"
                  size="medium"
                  class="ml-2"
        />
      </AppButton>
    </template>

    <template #default="{closeMenu}">
      <v-card-text class="text--primary pa-2">
        <div v-for="option in rightTypeOptionsValues"
             :key="`option-${option}`"
        >
          <v-divider v-if="option === RIGHT_TYPE_OPTIONS.REMOVE" class="my-1"></v-divider>
          <div class="d-flex pa-2 clickable rounded-lg"
               :class="option === RIGHT_TYPE_OPTIONS.REMOVE ? 'menu-item-remove' : 'menu-item'"
               @click="editUserRight(closeMenu, option)"
          >
            <div>
              <!-- Title -->
              <app-text :class="{ 'error--text': option === RIGHT_TYPE_OPTIONS.REMOVE }"
                        class="mb-0"
                        variant="small-bold"
              >
                {{ $t(`project.checklist.dialogs.SaveChecklistDialog.rightTypeOptions.${option}.title`) }}
              </app-text>
              <!-- subTitle -->
              <app-text v-if="option !== RIGHT_TYPE_OPTIONS.REMOVE"
                        class="mt-1"
                        variant="x-small-regular"
              >
                {{ $t(`project.checklist.dialogs.SaveChecklistDialog.rightTypeOptions.${option}.subTitle`) }}
              </app-text>
            </div>
            <template v-if="option === accessRight">
              <app-icon icon-name="check" size="medium" class="ml-2" style="color: #2671F2"/>
            </template>
          </div>
        </div>
      </v-card-text>
    </template>
  </AppMenuAsPopover>
</template>

<script>
  import AppMenuAsPopover from '@/common/AppMenuAsPopover.vue'
  import AppButton from '@/common/buttons/AppButton.vue'
  import { RIGHT_TYPE_OPTIONS } from '@/common/utils/rightTypes.ts'

  export default {
    name: 'SaveChecklistShareRightsMenu',
    components: { AppMenuAsPopover, AppButton },
    props: {
      userAccessRight: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        isMenuOpened: false,
        accessRight: this.userAccessRight,
        RIGHT_TYPE_OPTIONS,
      }
    },
    computed: {
      rightTypeOptionsValues () {
        return Object.values(RIGHT_TYPE_OPTIONS)
      },
    },
    methods: {
      editUserRight (closeMenuFunction, newRight) {
        this.accessRight = newRight
        this.$emit('set-user-rights', this.accessRight)
        closeMenuFunction()
      },
    },
  }
</script>

<style scoped lang="scss">
  .menu-item:hover {
    background-color: var(--v-grey-lighten5);
  }
  .menu-item-remove:hover {
    background-color: #FEEAED;
  }
</style>
