<template>
    <div>
      <v-row>
        <v-col cols="12" md="6">
          <AppTextField v-model.trim="validator.name.$model"
                        class="required"
                        :label="$t('projects.dialogs.ProjectsNewDataroomDialogCreateDataRoom.fieldsLabels.name')"
                        :error-messages="roomNameError"
                        maxLength="100"
                        counter="100"
          />
        </v-col>
        <v-col cols="12" md="6">
          <AppTextField v-model="newDataRoom.cmRef"
                        :label="$t('projects.dialogs.ProjectsNewDataroomDialogCreateDataRoom.fieldsLabels.cmRef')"
                        maxLength="100"
                        counter="100"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <AppDatePicker ref="picker"
                         v-model="newDataRoom.expClosingDate"
                         :label="$t('projects.dialogs.ProjectsNewDataroomDialogCreateDataRoom.fieldsLabels.plannedCompletionDate')"
                         class="w-100"
                         min="2000-01-01"
          />
        </v-col>
        <v-col cols="12" md="6">
          <template v-if="subscriptionLabelsPending">
            <v-skeleton-loader type="text"/>
          </template>
          <template v-else>
            <AppSelect v-if="computedRoomsLabels"
                       v-model="newDataRoom.labelId"
                       :label="$t('projects.dialogs.ProjectsNewDataroomDialogCreateDataRoom.fieldsLabels.status')"
                       :items="computedRoomsLabels"
                       item-value="id"
            >
              <template #item="{item}">
                <div v-if="item.id === null">
                  {{ item.text }}
                </div>
                <AppProjectLabel v-else :label="item"/>
              </template>
              <template #selection="{ item }">
                <div v-if="item.id === null">
                  {{ item.text }}
                </div>
                <AppProjectLabel v-else :label="item"/>
              </template>
            </AppSelect>
          </template>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppDatePicker from '../../common/AppDatePicker'
import AppProjectLabel from '../../common/AppProjectLabel'
import AppSelect from '../../common/AppSelect'
import AppTextField from '../../common/AppTextField'
import { GET_SUBSCRIPTION_LABELS } from '../../store/modules/rooms/action_types'
import { ENQUEUE_SNACKBAR } from '../../store/mutation_types'

export default {
  name: 'ProjectsNewDataroomDialogStep1',
  components: { AppSelect, AppTextField, AppDatePicker, AppProjectLabel },
  props: {
    newDataRoom: {
      type: Object,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('rooms', ['subscriptionLabels', 'subscriptionLabelsPending']),
    computedRoomsLabels () {
      const labels = []
      labels.push({
        id: null,
        text: this.$t('projects.dialogs.ProjectsNewDataroomDialogCreateDataRoom.noLabel'),
      })
      if (this.subscriptionLabels) {
        labels.push(...this.subscriptionLabels)
      }
      return labels
    },
    roomNameError () {
      const errors = []
      if (!this.validator.name.$dirty) return errors
      !this.validator.name.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('projects.dialogs.ProjectsNewDataroomDialogCreateDataRoom.fieldsLabels.name') }))
      !this.validator.name.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('projects.dialogs.ProjectsNewDataroomDialogCreateDataRoom.fieldsLabels.name') }))
      return errors
    },
  },
  async mounted () {
    try {
      await this.GET_SUBSCRIPTION_LABELS(
        {
          params: {
            isDataroom: true,
          },
        },
      )
    } catch (e) {
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'error',
        message: this.$t('projects.dialogs.ProjectsNewDataroomDialogCreateDataRoom.getRoomsLabelsError'),
      })
    }
  },
  methods: {
    ...mapActions('rooms', [GET_SUBSCRIPTION_LABELS]),
  },
}
</script>
