<template>
  <AppTooltip v-if="shouldDisplayButton" top eager>
    <template #activator="{ attrs, on }">
      <app-button class="document-duplicate-button"
                  size="small"
                  type="icon"
                  v-bind="attrs"
                  v-on="on"
                  @click="onDuplicate"
      >
        <app-icon icon-name="copy" size="small" />
      </app-button>
    </template>
    <span>
      {{ $t(`${TRANSLATION_KEY_PREFIX}.tooltip`) }}
    </span>
  </AppTooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import AppTooltip from '@/common/AppTooltip.vue'
import { track } from '@/common/pendo/agent'
import { getFileExtension, isFileLowerThanMaxSignableSize } from '@/common/utils/files'
import {
  getOfficeDocumentType,
  OFFICE_EDITION_MAX_FILE_SIZE,
  type SupportedOfficeDocumentTypes,
} from '@/common/utils/office'

const TRANSLATION_KEY_PREFIX = 'common.document-versioning.DocumentVersioningWopiDuplicationButton'

export default defineComponent({
  name: 'DocumentVersioningWopiDuplicationButton',
  components: { AppTooltip },
  emits: ['duplicate'],
  props: {
    document: {
      type: Object,
      required: true,
    },
    duplicationAvailable: {
      type: Boolean,
    },
  },
  data () {
  return {
      TRANSLATION_KEY_PREFIX,
    }
  },
  computed: {
    shouldDisplayButton (): boolean {
      return this.officeDocumentType && this.duplicationAvailable && this.isDocumentSizeUnderOfficeEditionLimit && this.document.isUserFromSub
    },
    isDocumentSizeUnderOfficeEditionLimit (): boolean {
      if (!this.officeDocumentType) {
        return true
      }

      const editionMaxFileSize = OFFICE_EDITION_MAX_FILE_SIZE[this.officeDocumentType]
      return isFileLowerThanMaxSignableSize(this.document, editionMaxFileSize)
    },
    officeDocumentType (): SupportedOfficeDocumentTypes | null {
      const fileExtension = getFileExtension(this.document)

      if (!fileExtension) {
        return null
      }

      return getOfficeDocumentType(fileExtension)
    },
  },
  methods: {
    onDuplicate () {
      track('duplicate_M365_document', { type: this.officeDocumentType })
      this.$emit('duplicate')
    },
  },
})
</script>

<style scoped>
.document-duplicate-button {
  height: 28px !important;
  min-width: 28px !important;
  border: 1px solid var(--v-grey-lighten4);
  padding: 4px !important;
}
</style>
