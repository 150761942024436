<template>
<AppMenuAsPopover offset-x
                  top
                  :card-min-width="330"
                  :card-max-width="330"
                  @open="onMenuOpen"
>
  <template #activator="{attrs, on: menuOn}">
    <v-tooltip top
               transition="fade-transition"
    >
      <template #activator="{on: tooltipOn}">
        <AppButton icon
                   :width="32"
                   :height="32"
                   v-bind="attrs"
                   v-on="{...menuOn, ...tooltipOn}"
        >
          <font-awesome-icon class="greyDark--text text--lighten-1" :icon="['fas', 'edit']"></font-awesome-icon>
        </AppButton>
      </template>
      <span>{{ $t('project.checklist.task.ChecklistTableStatusEditMenu.editStatusTooltip') }}</span>
    </v-tooltip>
  </template>
  <template #default="{closeMenu}">
    <v-card-title class="editStatus__card__title body-1 font-weight-medium d-flex align-center justify-space-between">
      {{ $t('project.checklist.task.ChecklistTableStatusEditMenu.editStatus') }}
      <AppButton icon
                 @click="closeMenu"
      >
        <font-awesome-icon :icon="['fal', 'xmark']"></font-awesome-icon>
      </AppButton>
    </v-card-title>
    <v-card-text class="text--primary pt-4">
      <v-row>
        <v-col>
          <div class="mb-4 font-weight-medium">{{$t('project.checklist.task.ChecklistTableStatusEditMenu.form.statusNameLabel')}}</div>
          <AppTextField v-model="$v.editedStatus.label.$model"
                        class="required"
                        :error-messages="labelErrors"
                        :placeholder="$t('project.checklist.task.ChecklistTableStatusEditMenu.form.statusNamePlaceholder')"
                        :counter="45"
                        :maxlength="45"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="mb-4 font-weight-medium">{{$t('project.checklist.task.ChecklistTableStatusEditMenu.form.statusCategoryLabel')}}</div>
          <AppSelect v-model="$v.editedStatus.category.$model"
                     :items="taskStatusCategoriesList"
                     item-value="name"
                     :error-messages="categoryErrors"
                     class="required"
          >
            <template #item="{item}">
              {{ $t(`common.statusCategoryNames.${item.name}`) }}
            </template>
            <template #selection="{item}">
              {{ $t(`common.statusCategoryNames.${item.name}`) }}
            </template>
          </AppSelect>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="mb-4 font-weight-medium">{{$t('project.checklist.task.ChecklistTableStatusEditMenu.form.statusColorLabel')}}</div>
          <ChecklistTableStatusColorPicker v-model="$v.editedStatus.color.$model"/>
          <span v-if="colorErrors.length"
                class="caption error--text">
              {{ colorErrors[0] }}
            </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <AppButton color="white"
                     class="mr-4"
                     :disabled="postStatusPending"
                     @click="closeMenu"
          >
            {{ $t('common.cancel') }}
          </AppButton>
          <AppButton color="primary"
                     :loading="postStatusPending"
                     @click="editStatus(closeMenu)"
          >
            {{ $t('common.register') }}
          </AppButton>
        </v-col>
      </v-row>
    </v-card-text>
  </template>
</AppMenuAsPopover>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'

import ChecklistTableStatusColorPicker from './ChecklistTableStatusColorPicker'
import AppMenuAsPopover from '../../../common/AppMenuAsPopover'
import AppSelect from '../../../common/AppSelect'
import AppTextField from '../../../common/AppTextField'
import AppButton from '../../../common/buttons/AppButton'

export default {
  name: 'ChecklistTableStatusEditMenu',
  components: { AppMenuAsPopover, AppButton, AppTextField, AppSelect, ChecklistTableStatusColorPicker },
  mixins: [validationMixin],
  props: {
    status: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
  validations: {
    editedStatus: {
      label: { required },
      category: { required },
      color: { required },
    },
  },
  data () {
    return {
      editedStatus: {
        label: this.status.label || '',
        category: this.category,
        color: this.status.color || '',
      },
    }
  },
  computed: {
    ...mapState('checklist', ['postStatusPending']),
    ...mapGetters('checklist', ['taskStatusCategoriesList']),
    labelErrors () {
      const errors = []
      if (!this.$v.editedStatus.label.$dirty) return errors
      !this.$v.editedStatus.label.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.checklist.task.ChecklistTableStatusEditMenu.form.statusNameLabel') }))
      return errors
    },
    categoryErrors () {
      const errors = []
      if (!this.$v.editedStatus.category.$dirty) return errors
      !this.$v.editedStatus.category.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.checklist.task.ChecklistTableStatusEditMenu.form.statusCategoryLabel') }))
      return errors
    },
    colorErrors () {
      const errors = []
      if (!this.$v.editedStatus.color.$dirty) return errors
      !this.$v.editedStatus.color.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.checklist.task.ChecklistTableStatusEditMenu.form.statusColorLabel') }))
      return errors
    },
  },
  methods: {
    editStatus (closeMenuFunction) {
      this.$emit('edit', { ...this.editedStatus, id: this.status.id })
      closeMenuFunction()
    },
    onMenuOpen () {
      this.$v.$reset()
      this.editedStatus = {
        label: this.status.label || '',
        category: this.category,
        color: this.status.color || '',
      }
    },
  },
}
</script>

<style scoped lang="scss">
.editStatus {
  &__card {
    &__title {
      border-bottom: 1px solid var(--v-grey-lighten1);
    }
  }
}
</style>
