<template>
  <vue-tel-input-vuetify
    :value="value"
    class="body-2 tel-input"
    :class="{'tel-input--hideDetails': hideDetails}"
    :hide-details="hideDetails"
    outlined
    dense
    disabledFetchingCountry
    :defaultCountry="defaultCountry"
    maxlength="20"
    :placeholder="placeholder"
    autocomplete="tel-country-code"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <slot :name="slot" />
    </template>
  </vue-tel-input-vuetify>
</template>

<script>
/**
 * A wrapper around vue-tel-input-vuetify with default styling and custom default behavior
 * @see https://github.com/yogakurniawan/vue-tel-input-vuetify#props
 */
export default {
  name: 'AppPhoneField',
  inheritAttrs: false,
  props: {
    /**
     * @model
     * The input’s value
     */
    value: {
      required: true,
    },
    /**
     * The input’s placeholder
     * @default ''
     */
    placeholder: {
      default: '',
    },
    /**
     * The input’s default country
     * @default 'FR'
     */
    defaultCountry: {
      default: 'FR',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
  .tel-input ::v-deep .primary--text{
    // Blue darken-2
    color: #1976D2 !important;
    caret-color: #1976D2 !important;
  }

  .tel-input.required ::v-deep .v-input label:after {
    content: " *";
    font-weight: 600;
    color: var(--v-error-base);
  }

  ::v-deep.tel-input--hideDetails {
    .v-select {
      .v-input__slot {
        margin-bottom: 0;
      }
      .v-text-field__details {
        display: none;
      }
    }
  }
</style>

<docs>
```vue
<template>
  <div>
    <AppPhoneField :value="phone"
                   placeholder="Enter your phone number"
                   label="Phone number"
    />
  </div>
</template>
<script>
  export default {
    data () {
      return {
        phone: ''
      }
    },
  }
</script>
```
</docs>
