import { buildGetPagesQuery } from '@/common/utils/prepareSignature'
import { getTagsOverlaps } from '@/common/utils/prepareSignature.js'
import prepareSignatureService from '@/services/prepareSignature.service'

import {
  CHANGE_SIGNER,
  DELETE_ALL_TAG_TYPE,
  DELETE_SIGNER,
  DELETE_TAG,
  DRAG_TAG_ACTIVE,
  DUPLICATE_CHECKBOX,
  DUPLICATE_TAG,
  GET_AUTO_TAGS,
  GET_ENVELOPE_DISPLAY_INFORMATION,
  GET_PAGES_CONTENT,
  GET_PAGE_THUMB,
  GET_TAGGING_INFORMATION,
  PATCH_SIGNERS_ORDER,
  PATCH_TAG,
  POST_AUTO_TAGS,
  POST_SIGNERS,
  POST_TAG,
  RESET_DOCUMENT_TO_PREPARE,
  UPDATE_TAGS_OVERLAPS,
} from './action_types'
import {
  ADD_DELETE_SIGNER_PENDING_ID,
  REMOVE_DELETE_SIGNER_PENDING_ID,
  SET_AUTO_TAGS,
  SET_AUTO_TAGS_ERROR,
  SET_AUTO_TAGS_PENDING,
  SET_CHANGE_SIGNER_ERROR,
  SET_CHANGE_SIGNER_PENDING,
  SET_DELETE_SIGNER_ERROR,
  SET_DELETE_TAG_ERROR,
  SET_DELETE_TAG_PENDING,
  SET_DRAG_TAG_ACTIVE,
  SET_DRAG_TAG_ACTIVE_ERROR,
  SET_DRAG_TAG_ACTIVE_PENDING,
  SET_DUPLICATE_TAG_ERROR,
  SET_DUPLICATE_TAG_PENDING,
  SET_ENVELOPE_DISPLAY_INFORMATION,
  SET_ENVELOPE_DISPLAY_INFORMATION_ERROR,
  SET_ENVELOPE_DISPLAY_INFORMATION_PENDING,
  SET_LAST_SAVE,
  SET_PAGE_CONTENT,
  SET_PAGE_CONTENT_IS_LOADING,
  SET_PAGE_THUMB,
  SET_PAGE_THUMB_IS_LOADING,
  SET_PATCH_SIGNERS_ORDER_PENDING,
  SET_PATCH_TAG_ERROR,
  SET_PATCH_TAG_PENDING,
  SET_POST_AUTO_TAGS_ERROR,
  SET_POST_AUTO_TAGS_PENDING,
  SET_POST_SIGNERS_ERROR,
  SET_POST_SIGNERS_PENDING,
  SET_RESET_DOCUMENT_TO_PREPARE,
  SET_SIGNATURE_ORDER_ERROR,
  SET_TAGGING_INFORMATION,
  SET_TAGGING_INFORMATION_ERROR,
  SET_TAGGING_INFORMATION_PENDING,
  SET_TAG_ERROR,
  SET_TAG_PENDING,
  ADD_TAG_OVERLAP,
  REMOVE_TAG_OVERLAP_BY_ENVELOPE_ID,
} from './mutation_types'

const GET_PAGES_RETRY_DELAY = 5000

export const actions = {
  async [POST_AUTO_TAGS] ({ commit }, { mnemo, envId, data }) {
    commit(SET_POST_AUTO_TAGS_PENDING, true)
    try {
      await prepareSignatureService.postAutoTags(mnemo, envId, data)
    } catch (error) {
      commit(SET_POST_AUTO_TAGS_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_AUTO_TAGS_PENDING, false)
    }
  },
  async [GET_AUTO_TAGS] ({ commit }, { mnemo, envId }) {
    commit(SET_AUTO_TAGS_PENDING, true)
    try {
      const { data } = await prepareSignatureService.getAutoTags(mnemo, envId)
      commit(SET_AUTO_TAGS, data)
      return data
    } catch (error) {
      commit(SET_AUTO_TAGS_ERROR, error)
      throw error
    } finally {
      commit(SET_AUTO_TAGS_PENDING, false)
    }
  },
  async [DUPLICATE_CHECKBOX] ({ commit }, { mnemo, envId, data }) {
    commit(SET_DUPLICATE_TAG_PENDING, true)
    try {
      await prepareSignatureService.postTag(mnemo, envId, data)
    } catch (error) {
      commit(SET_DUPLICATE_TAG_ERROR, error)
      throw error
    } finally {
      setTimeout(() => {
        commit('SET_DUPLICATE_TAG_PENDING', false)
        commit('SET_LAST_SAVE', Date.now())
      }, 500)
    }
  },
  async [DUPLICATE_TAG] ({ commit }, { mnemo, envId, tagId, tagData }) {
    commit(SET_DUPLICATE_TAG_PENDING, true)
    try {
      const { data } = await prepareSignatureService.duplicateTag(mnemo, envId, tagId, tagData)
      return data.tags
    } catch (error) {
      commit(SET_DUPLICATE_TAG_ERROR, error)
      throw error
    } finally {
      commit(SET_DUPLICATE_TAG_PENDING, false)
    }
  },
  async [CHANGE_SIGNER] ({ commit }, { mnemo, envId, oldUserId, data }) {
    commit(SET_CHANGE_SIGNER_PENDING, true)
    try {
      await prepareSignatureService.changeSigner(mnemo, envId, oldUserId, data)
    } catch (error) {
      commit(SET_CHANGE_SIGNER_ERROR, error)
      throw error
    } finally {
      commit(SET_CHANGE_SIGNER_PENDING, false)
    }
  },
  async [DELETE_SIGNER] ({ commit }, { mnemo, envId, signatureId }) {
    commit(ADD_DELETE_SIGNER_PENDING_ID, signatureId)
    try {
      await prepareSignatureService.deleteSigner(mnemo, envId, signatureId)
    } catch (error) {
      commit(SET_DELETE_SIGNER_ERROR, error)
      throw error
    } finally {
      commit(REMOVE_DELETE_SIGNER_PENDING_ID, signatureId)
    }
  },
  async [POST_SIGNERS] ({ commit }, { mnemo, envId, signers }) {
    commit(SET_POST_SIGNERS_PENDING, true)
    try {
      await prepareSignatureService.postSigners(mnemo, envId, signers)
    } catch (error) {
      commit(SET_POST_SIGNERS_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_SIGNERS_PENDING, false)
    }
  },
  async [PATCH_SIGNERS_ORDER] ({ commit }, { mnemo, envId, data }) {
    commit(SET_PATCH_SIGNERS_ORDER_PENDING, true)
    try {
      await prepareSignatureService.patchSignersOrder(mnemo, envId, data)
    } catch (error) {
      commit(SET_SIGNATURE_ORDER_ERROR, error)
      throw error
    } finally {
      commit(SET_PATCH_SIGNERS_ORDER_PENDING, false)
    }
  },
  async [DRAG_TAG_ACTIVE] ({ commit }, { value, tag }) {
    commit(SET_DRAG_TAG_ACTIVE_PENDING, true)
    try {
      commit(SET_DRAG_TAG_ACTIVE, { value, tag })
    } catch (error) {
      commit(SET_DRAG_TAG_ACTIVE_ERROR, error)
      throw error
    } finally {
      commit(SET_DRAG_TAG_ACTIVE_PENDING, false)
    }
  },
  async [DELETE_TAG] ({ commit }, { mnemo, envId, tagId }) {
    commit(SET_DELETE_TAG_PENDING, true)
    try {
      await prepareSignatureService.deleteTag(mnemo, envId, tagId)
    } catch (error) {
      commit(SET_DELETE_TAG_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_TAG_PENDING, false)
    }
  },
  async [DELETE_ALL_TAG_TYPE] ({ commit }, { mnemo, envId, data }) {
    commit(SET_DELETE_TAG_PENDING, true)
    try {
      await prepareSignatureService.deleteAllTagByType(mnemo, envId, data)
    } catch (error) {
      commit(SET_DELETE_TAG_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_TAG_PENDING, false)
    }
  },
  async [PATCH_TAG] ({ commit }, { mnemo, envId, data }) {
    commit(SET_PATCH_TAG_PENDING, true)
    try {
      await prepareSignatureService.patchTag(mnemo, envId, data)
    } catch (error) {
      commit(SET_PATCH_TAG_ERROR, error)
      throw error
    } finally {
      commit(SET_PATCH_TAG_PENDING, false)
      commit(SET_LAST_SAVE, Date.now())
    }
  },
  async [POST_TAG] ({ commit }, { mnemo, envId, data }) {
    commit(SET_TAG_PENDING, true)
    try {
      return await prepareSignatureService.postTag(mnemo, envId, data)
    } catch (error) {
      commit(SET_TAG_ERROR, error)
      throw error
    } finally {
      commit(SET_TAG_PENDING, false)
      commit(SET_LAST_SAVE, Date.now())
    }
  },
  async [GET_PAGES_CONTENT] ({ commit, dispatch, state }, { mnemo, envelopeId, fromPage, toPage, pages }) {
    if (envelopeId !== state.envelopeDisplayInformation?.envelopeId || fromPage > toPage) {
      return
    }

    if (pages) {
      for (const page of pages) {
        commit(SET_PAGE_CONTENT_IS_LOADING, {
          pageNumber: page,
          isLoading: true,
        })
      }
    } else {
      for (let pageNumber = fromPage; pageNumber <= toPage; pageNumber++) {
        commit(SET_PAGE_CONTENT_IS_LOADING, {
          pageNumber: pageNumber,
          isLoading: true,
        })
      }
    }

    try {
      const response = await prepareSignatureService.getPages(mnemo, envelopeId, buildGetPagesQuery({ fromPage, toPage, pages }))

      if ((typeof response.data !== 'object' || (Array.isArray(response.data) && response.data.length === 0)) && state.envelopeDisplayInformation?.envelopeId === envelopeId) {
        setTimeout(() => {
          dispatch(GET_PAGES_CONTENT, { mnemo, envelopeId, fromPage, toPage })
        }, GET_PAGES_RETRY_DELAY)
      }

      if (envelopeId === state.envelopeDisplayInformation?.envelopeId) {
        const pagesToRetry = response.data.filter(page => !page.content).map((page) => page.pageNumber)
        if (pagesToRetry.length !== 0) {
          setTimeout(() => {
            dispatch(GET_PAGES_CONTENT, { mnemo, envelopeId, pages: pagesToRetry })
          }, GET_PAGES_RETRY_DELAY)
        }

        for (const { pageNumber, content } of response.data) {
          commit(SET_PAGE_CONTENT, { pageNumber, content })
        }
      }
    } finally {
      if (pages) {
        for (const page of pages) {
          commit(SET_PAGE_CONTENT_IS_LOADING, {
            pageNumber: page,
            isLoading: false,
          })
        }
      } else {
        for (let pageNumber = fromPage; pageNumber <= toPage; pageNumber++) {
          commit(SET_PAGE_CONTENT_IS_LOADING, {
            pageNumber: pageNumber,
            isLoading: false,
          })
        }
      }
    }
  },
  async [GET_PAGE_THUMB] ({ commit, dispatch, state }, { mnemo, envelopeId, pageId, pageNumber }) {
    commit(SET_PAGE_THUMB_IS_LOADING, {
      pageNumber: pageNumber,
      isLoading: true,
    })

    try {
      const response = await prepareSignatureService.getPageThumb(mnemo, envelopeId, pageId)

      if (response?.data?.thumb && envelopeId === state.envelopeDisplayInformation?.envelopeId) {
        commit(SET_PAGE_THUMB, {
          pageNumber: pageNumber,
          thumb: response.data.thumb,
        })
      }
    } finally {
      commit(SET_PAGE_THUMB_IS_LOADING, {
        pageNumber: pageNumber,
        isLoading: false,
      })
    }
  },
  async [GET_TAGGING_INFORMATION] ({ commit, state }, { mnemo, envId }) {
    commit(SET_TAGGING_INFORMATION_PENDING, true)
    try {
      const response = await prepareSignatureService.getTaggingInformation(mnemo, envId)
      if (envId === state.envelopeDisplayInformation?.envelopeId) {
        commit(SET_TAGGING_INFORMATION, response.data)
      }
      return response.data
    } catch (error) {
      commit(SET_TAGGING_INFORMATION_ERROR, error)
      throw error
    } finally {
      commit(SET_TAGGING_INFORMATION_PENDING, false)
    }
  },
  async [GET_ENVELOPE_DISPLAY_INFORMATION] ({ commit }, { mnemo, envId }) {
    commit(SET_ENVELOPE_DISPLAY_INFORMATION_PENDING, true)
    try {
      const response = await prepareSignatureService.getEnvelopeDisplayInformation(mnemo, envId)
      commit(SET_ENVELOPE_DISPLAY_INFORMATION, response.data)
    } catch (error) {
      commit(SET_ENVELOPE_DISPLAY_INFORMATION_ERROR, error)
      throw error
    } finally {
      commit(SET_ENVELOPE_DISPLAY_INFORMATION_PENDING, false)
    }
  },
  async [RESET_DOCUMENT_TO_PREPARE] ({ commit }) {
    commit(SET_RESET_DOCUMENT_TO_PREPARE)
  },
  [UPDATE_TAGS_OVERLAPS] ({ commit }, { envId, tags }) {
    if (tags.length > 1) {
      const tagsOverlaps = getTagsOverlaps(tags)

      if (tagsOverlaps.length > 0) {
        commit(ADD_TAG_OVERLAP, {
          envelopeId: envId,
          tagIds: tagsOverlaps,
        })
      } else {
        commit(REMOVE_TAG_OVERLAP_BY_ENVELOPE_ID, envId)
      }
    } else {
      commit(REMOVE_TAG_OVERLAP_BY_ENVELOPE_ID, envId)
    }
  },
}
