import axios from 'axios'

export default {
  /**
     * @function samlCheck
     * @param {string} userEmail
     * @return AxiosInstance
     */
  samlCheck: function (queryParams) {
    return axios.get('samlCheck', { params: queryParams })
  },

  signIn: function (userCredentials) {
    return axios.post(
      'login',
      { ...userCredentials },
    )
  },

  forgotPassword: function (mailAddress) {
    return axios.get(`password/reset?email=${encodeURIComponent(mailAddress.email)}`)
  },

  resetPassword: function (userCredentials) {
    return axios.post(
      'password/reset',
      { ...userCredentials },
    )
  },

  activateAccount: function (userCredentials) {
    return axios.post(
      'password/init',
      { ...userCredentials },
    )
  },

  checkToken: function (token) {
    return axios.get(`password/init/${encodeURIComponent(token)}`)
  },

  sendTwoFactorCode: function (code) {
    return axios.post(`/confirmation/pin/${encodeURIComponent(code)}`)
  },

  resendTwoFactorCode: function () {
    return axios.post('/confirmation/resend')
  },
  getAuthFromToken: function (queryParams, withoutApiToken = false) {
    if (withoutApiToken) {
      return axios.get('/auth/fromToken', { params: queryParams, headers: { Authorization: '' } })
    }
    return axios.get('/auth/fromToken', { params: queryParams })
  },
}
