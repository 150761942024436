<template>
    <div v-if="signingView">
      <CerteuropeSignThumb v-for="page in signingView.pages"
                           :key="`thumb-${page.number}`"
                           class="my-4"
                           :mnemo="mnemo"
                           :page="page"
      />
    </div>
</template>

<script>
import { mapState } from 'vuex'

import CerteuropeSignThumb from './CerteuropeSignThumb'
export default {
  name: 'CerteuropeSignThumbs',
  components: { CerteuropeSignThumb },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('certeuropeSign', ['signingView']),
  },
}
</script>
