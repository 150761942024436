<template>
  <component :is="as"
             :class="{'drag-in-progress': !disabled && dragOverComponentInProgress}"
             v-bind="$attrs"
             v-on="componentListeners"
             @dragover="dragover"
             @dragenter="dragEnter"
             @dragleave="dragLeave"
             @drop.prevent="drop"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'AppDroppableArea',
  props: {
    as: {
      type: [String, Object],
      default: 'div',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      childComponentsEntered: 0,
    }
  },
  computed: {
    componentListeners () {
      const { dragenter, dragleave, drop, ...componentListeners } = this.$listeners
      return componentListeners
    },
    dragOverComponentInProgress () {
      return this.childComponentsEntered > 0
    },
  },
  watch: {
    dragOverComponentInProgress (value, previousValue) {
      if (!previousValue && value) {
        this.$emit('dragenter')
      }

      if (previousValue && !value) {
        this.$emit('dragleave')
      }
    },
  },
  methods: {
    dragover (event) {
      if (!this.disabled) {
        event.preventDefault()
      }
    },
    dragEnter () {
      this.childComponentsEntered += 1
    },
    dragLeave () {
      this.childComponentsEntered -= 1
    },
    drop (event) {
      this.childComponentsEntered = 0

      if (!this.disabled) {
        this.$emit('drop', event)
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .drag-in-progress {
    transition: all .1s ease-in-out;
    background-color: rgba(255, 93, 53, .1);
    outline: 2px dashed var(--v-primary-base);
    outline-offset: -2px;
    border-radius: 4px;
  }
</style>
