<template>
  <font-awesome-icon :icon="[active ? 'fas' : 'fal', icon]" style="font-size: 18px" />
</template>

<script>
export default {
  name: 'AppBarMenuItemIcon',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
