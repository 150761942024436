import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const translationFiles = require.context('./locales', true, /([A-Za-z0-9-_,\s]+).(\.json)$/i)
  const messages = {}

  translationFiles.keys().forEach(translationFile => {
    const matched = translationFile.match(/\.\/([A-Za-z]{2})\/([A-Za-z0-9-_,\s]+).(\.json)$/i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = {
        ...messages[locale],
        ...translationFiles(translationFile),
        // common should be handled specifically as it is present
        // in both common and components translation files
        common: {
          ...messages[locale]?.common,
          ...translationFiles(translationFile)?.common,
        },
      }
    }
  })

  return messages
}

const navigatorLanguage = navigator.language.slice(0, 2)

const numberFormats = {
  en: {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
  fr: {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
  de: {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
}

export default new VueI18n({
  locale: navigatorLanguage,
  fallbackLocale: ['en', 'fr', 'de'],
  messages: loadLocaleMessages(),
  silentFallbackWarn: true,
  modifiers: {
    initials: (message) => message.split(' ').reduce((acc, m) => acc + m.toUpperCase()[0], ''),
  },
  numberFormats,
})
