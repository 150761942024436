import axios from 'axios'

export default {
  getSignedDocuments (mnemo, params) {
    return axios.get(`room/${mnemo}/myEnvelopes`, { params })
  },
  getSignedDocument (mnemo, envId) {
    return axios.get(`room/${mnemo}/myEnvelopes?onlyIds=${envId}`)
  },
  deleteOriginal (mnemo, data) {
    return axios.delete(`room/${mnemo}/envelopes/rights/readSigned/self`, { data })
  },
  shareOriginal (mnemo, data) {
    return axios.post(`room/${mnemo}/envelopes/rights/readSigned`, data)
  },
  exportSignedDocumentToImanage (mnemo, envelopeId, data, token) {
    return axios.post(`/room/${mnemo}/imanageExport/envelope/${envelopeId}`, data, { headers: { 'X-CLOSD-IMCONFIG': token } })
  },
}
