<template>
  <DocumentsActionBarWrapper :menu-items="actionBarItems">
    <DocumentsActionBarListItem v-for="item in displayedActionBarItems"
                                :key="item.name"
                                :icon="item.icon"
                                :name="item.name"
                                :title="item.title"
                                @click="item.action"
    >
      <PolyOfficeMultiSelectBarButtonExport
            v-if="item.name === 'poly'"
            :selectionSize="fileSelectionSize"
            :selectionCount="selectedDocuments.length"
            @polyOfficeOpenDialog="onExportToPoly"
      />
    </DocumentsActionBarListItem>
    <IManageFolderPickerDialog v-if="imanageEnabledAndLogged"
                               :is-open.sync="isExportToImanageDialogOpen"
                               @folders="prepareExportFildersToImanage"
    />
  </DocumentsActionBarWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import IManageFolderPickerDialog from '@/common/imanage/IManageFolderPickerDialog'
import { POLYOFFICE_MAX_EXPORT_SIZE } from '@/common/polyOffice/constants'
import { DocumentsExport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeMultiSelectBarButtonExport from '@/common/polyOffice/PolyOfficeMultiSelectBarButtonExport'
import { canFilesBeAddedToChecklist } from '@/common/utils/signingChecklist'
import DocumentsActionBarListItem from '@/project/documents/DocumentsActionBarListItem.vue'
import DocumentsActionBarWrapper from '@/project/documents/DocumentsActionBarWrapper.vue'
import { ARCHIVES_ADD_ROUTE_NAME, SIGNING_CHECKLIST_ADD_ROUTE_NAME } from '@/router'
import {
  DESELECT_ALL_DOCUMENTS,
  GET_DOCUMENTS_BY_ID,
  POST_FILDERS_TO_IMANAGE,
  SET_TABLE_DOCUMENTS_PANE,
} from '@/store/modules/documents/action_types'
import { OPEN_DOCUMENTS_DELETION_DIALOG } from '@/store/modules/documents-delete/action_types'
import { OPEN_DOCUMENTS_DOWNLOAD_FILES } from '@/store/modules/documents-download-files/action_types'
import { GET_EXPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'DocumentsActionBar',
  components: {
    PolyOfficeMultiSelectBarButtonExport,
    IManageFolderPickerDialog,
    DocumentsActionBarWrapper,
    DocumentsActionBarListItem,
  },
  data () {
    return {
      ARCHIVES_ADD_ROUTE_NAME,
      SIGNING_CHECKLIST_ADD_ROUTE_NAME,
      isExportToImanageDialogOpen: false,
    }
  },
  computed: {
    ...mapState('documents', ['selectedDocuments', 'documents']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('room', ['isCurrentUserPm', 'isDataRoom', 'currentUserRights', 'maxSignableSize']),
    ...mapGetters('user', ['currentUserId', 'polyOfficeEnabled', 'isArchivingEnabled']),
    ...mapGetters('groups', ['currentUserGroupId']),
      archiveAddRouteOptions () {
        return {
          params: {
            files: this.selectedDocuments,
          },
          query: {
            fileIds: this.selectedDocuments.map((doc) => doc.id).toString(),
            fromMnemo: this.currentRoom.mnemo,
          },
        }
      },
      actionBarItems () {
        return [
        {
          action: () => { this.onShare() },
          displayed: this.selectionCanBeShared,
          icon: ['fas', 'share'],
          name: 'share',
          title: this.$t('common.share'),
        },
        {
          action: () => { this.onMove() },
          displayed: this.canSelectionBeWritten,
          icon: ['fas', 'folder-tree'],
          name: 'move',
          title: this.$t('project.documents.DocumentsActionBar.move'),
        },
        {
          action: () => { this.onDownload() },
          displayed: this.selectionCanBeDownloaded,
          icon: ['fas', 'download'],
          name: 'download',
          title: this.$t('common.download'),
        },
        {
          action: () => { this.onDelete() },
          displayed: this.canSelectionBeWritten,
          icon: ['fas', 'trash'],
          name: 'delete',
          title: this.$t('common.delete'),
        },
        {
          action: () => { this.onAddToArchives() },
          displayed: this.canFilesBeArchived,
          icon: ['fal', 'box-archive'],
          name: 'archive',
          title: this.$t('common.archives.ArchiveAddAccess.text'),
        },
        {
          action: () => { this.onAddToSigningChecklist() },
          displayed: this.canFilesBeAddedToChecklist,
          icon: ['fa', 'file-signature'],
          name: 'add',
          title: this.$t('project.documents.table.DocumentsTableItemMenu.signingChecklistAdd'),
        },
        {
          action: () => { this.onExportToPoly() },
          disabled: this.isExportToPolyDisabled,
          displayed: this.canFilesBeExportedToPoly,
          icon: ['fas', 'file-export'],
          name: 'poly',
          title: this.$t('common.polyOffice.PolyOfficeMultiSelectBarButtonExport.exportFiles'),
        },
        {
          action: () => { this.onExportToImanage() },
          displayed: this.canFilesBeExportedToImanage,
          icon: ['fas', 'file-export'],
          name: 'imanage',
          title: this.$t('project.documents.DocumentsActionBar.exportToImanage'),
        },
      ]
    },
    displayedActionBarItems () {
      return this.actionBarItems.filter(item => item.displayed)
    },
    canFilesBeArchived () {
      return this.isDocumentAllFiles && this.isArchivingEnabled
    },
    canFilesBeAddedToChecklist () {
      return !this.isDataRoom && canFilesBeAddedToChecklist(this.selectedDocuments, this.isCurrentUserPm, this.maxSignableSize) && this.isDocumentAllFiles
    },
    canFilesBeExportedToImanage () {
      return this.imanageEnabledAndLogged && this.isDocumentAllFiles
    },
    canFilesBeExportedToPoly () {
      return this.polyOfficeEnabled && this.isDocumentAllFiles
    },
    canSelectionBeWritten () {
      return this.selectedDocuments.every(doc => {
        if (doc.type !== 'file' && this.isSelectionTodoFolderOrChild) return false
        if (doc.canWrite) return true
        if (doc.rights?.write) {
          return doc.rights.write.groups.find((group) => group.id === this.currentUserGroupId) ||
              doc.rights.write.users.find((user) => user.id === this.currentUserId)
        } else {
          return false
        }
      })
    },
    fileSelectionSize () {
      return this.selectedDocuments.reduce((accSum, item) => {
        return item.type === 'file' ? accSum + item.size : accSum
      }, 0)
    },
    isDocumentAllFiles () {
      return this.selectedDocuments.every((doc) => doc.type === 'file')
    },
    isExportToPolyDisabled () {
      return POLYOFFICE_MAX_EXPORT_SIZE <= this.fileSelectionSize
    },
    isSelectionTodoFolderOrChild () {
      return this.$store.getters['documentsBreadcrumb/hasTodoFolderOrChild']
    },
    selectionCanBeDownloaded () {
      return this.currentUserRights.canDownload
    },
    selectionCanBeShared () {
      return this.isCurrentUserPm || this.selectedDocuments.every(doc => doc.canShare)
    },
  },
  methods: {
    ...mapActions('documents', [DESELECT_ALL_DOCUMENTS, SET_TABLE_DOCUMENTS_PANE, POST_FILDERS_TO_IMANAGE, GET_DOCUMENTS_BY_ID]),
    ...mapActions('documentsDelete', [OPEN_DOCUMENTS_DELETION_DIALOG]),
    ...mapActions('polyOffice', [GET_EXPORT_IFRAME]),
    ...mapActions('documentsDownloadFiles', [OPEN_DOCUMENTS_DOWNLOAD_FILES]),
    onAddToArchives () {
      this.$router.push(
        {
          name: ARCHIVES_ADD_ROUTE_NAME,
          ...this.archiveAddRouteOptions,
        },
      )
    },
    onAddToSigningChecklist () {
      this.$router.push(
        {
          name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
          params: {
            files: this.selectedDocuments,
          },
          query: {
            fileIds: this.selectedDocuments.map((document) => document.id).toString(),
          },
        },
      )
    },
    onDelete () {
      this.OPEN_DOCUMENTS_DELETION_DIALOG(this.selectedDocuments)
    },
    onDownload () {
      this.OPEN_DOCUMENTS_DOWNLOAD_FILES(this.selectedDocuments)
    },
    onExportToImanage () {
      this.isExportToImanageDialogOpen = true
    },
    async onExportToPoly () {
      const payload = this.preparePolyOfficePayload()
      try {
        await this.GET_EXPORT_IFRAME({
          payload,
          successMessage: this.$t('project.documents.DocumentsActionBar.polyOffice.exportSuccessful'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1),
        })
      }
    },
    onMove () {
      this.SET_TABLE_DOCUMENTS_PANE({ isOpen: true, documents: this.selectedDocuments, tab: 'move' })
    },
    onShare () {
      this.SET_TABLE_DOCUMENTS_PANE({ isOpen: true, documents: this.selectedDocuments, tab: 'share', forcePmSelection: true })
    },
    async prepareExportFildersToImanage (target) {
      const data = {
        files: [],
        folders: [],
        targetId: target.selected,
      }
      for (const doc of this.selectedDocuments) {
        if (doc.type === 'folder') {
          data.folders.push(doc.id)
        } else if (doc.type === 'file') {
          data.files.push(doc.id)
        }
      }
      try {
        await this.POST_FILDERS_TO_IMANAGE({
          mnemo: this.$route.params.mnemo,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.documents.DocumentsActionBar.exportFildersToImanageSuccess'),
        })
        this.GET_DOCUMENTS_BY_ID({
          mnemo: this.$route.params.mnemo,
          id: this.documents.id,
          queryObject: { markRecent: true },
        })
        this.DESELECT_ALL_DOCUMENTS()
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.DocumentsActionBar.exportFildersToImanageError'),
        })
      }
    },
    preparePolyOfficePayload () {
      const selectedFileIds = this.selectedDocuments.reduce((acc, item) => {
        return [
          ...acc,
          item.id,
        ]
      }, [])

      return new DocumentsExport(this.currentRoom.mnemo, selectedFileIds)
    },
  },
}
</script>
