<template>
 <AppDialog :is-open="isDialogOpened"
             :cancel-text="$t('common.cancel')"
             :ok-text="$t('common.confirm')"
             :ok-loading="isPending"
             :cancel-disabled="isPending"
             size="m"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>{{ $t('project.signing-checklist.prepare-signature.PrepareSignatureDeleteAllTagsDialog.dialogTitle') }}</template>

    <template #body>
      <p>{{ $t("project.signing-checklist.prepare-signature.PrepareSignatureDeleteAllTagsDialog.dialogBody") }}</p>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'

export default {
  name: 'PrepareSignatureDeleteAllTagsDialog',
  components: { AppDialog },
  props: {
    isPending: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [dialogMixin],
  methods: {
    confirmClick () {
      this.$emit('delete-all-tags')
      this.closeDialog()
    },
  },
}
</script>
