import Vue from 'vue'

import { QuestionAnswerMutationTypes } from './mutation_types'

export const mutations = {
  [QuestionAnswerMutationTypes.SET_QUESTIONS_PENDING] (state, value) {
    state.questionsPending = value
  },
  [QuestionAnswerMutationTypes.SET_QUESTIONS] (state, value) {
    state.questions = value
  },
  [QuestionAnswerMutationTypes.SET_QUESTIONS_ERROR] (state, value) {
    state.questionsError = value
  },
  [QuestionAnswerMutationTypes.SET_DELETE_QUESTION_PENDING] (state, value) {
    state.deleteQuestionPending = value
  },
  [QuestionAnswerMutationTypes.SET_DELETE_QUESTION_ERROR] (state, value) {
    state.deleteQuestionError = value
  },
  [QuestionAnswerMutationTypes.REMOVE_QUESTION_FROM_LIST] (state, questionId) {
    const questionIndex = state.questions.questions.findIndex(q => q.id === questionId)

    if (questionIndex >= 0) {
      state.questions.questions.splice(questionIndex, 1)
    }
  },
  [QuestionAnswerMutationTypes.SET_CATEGORIES_PENDING] (state, value) {
    state.categoriesPending = value
  },
  [QuestionAnswerMutationTypes.SET_CATEGORIES] (state, value) {
    state.categories = value
  },
  [QuestionAnswerMutationTypes.SET_CATEGORIES_ERROR] (state, value) {
    state.categoriesError = value
  },
  [QuestionAnswerMutationTypes.SET_PATCH_QUESTION_PENDING] (state, value) {
    state.patchQuestionPending = value
  },
  [QuestionAnswerMutationTypes.SET_PATCH_QUESTION_ERROR] (state, value) {
    state.patchQuestionError = value
  },
  [QuestionAnswerMutationTypes.SET_UPDATE_QUESTION_IN_LIST_PENDING] (state, value) {
    state.updateQuestionInListPending = value
  },
  [QuestionAnswerMutationTypes.SET_UPDATE_QUESTION_IN_LIST_ERROR] (state, value) {
    state.updateQuestionInListError = value
  },
  [QuestionAnswerMutationTypes.SET_UPDATE_QUESTION_IN_LIST] (state, question) {
    const index = state.questions.questions.findIndex(el => el.id === question.id)
    Vue.set(state.questions.questions, index, question)
  },
  [QuestionAnswerMutationTypes.SET_POST_QUESTION_PENDING] (state, value) {
    state.postQuestionPending = value
  },
  [QuestionAnswerMutationTypes.SET_POST_QUESTION_ERROR] (state, value) {
    state.postQuestionError = value
  },
  [QuestionAnswerMutationTypes.SET_QUESTION_PENDING] (state, value) {
    state.questionPending = value
  },
  [QuestionAnswerMutationTypes.SET_QUESTION] (state, value) {
    state.question = value
  },
  [QuestionAnswerMutationTypes.SET_QUESTION_ERROR] (state, value) {
    state.questionError = value
  },
  [QuestionAnswerMutationTypes.SET_POST_ANSWER_PENDING] (state, value) {
    state.postAnswerPending = value
  },
  [QuestionAnswerMutationTypes.SET_POST_ANSWER_ERROR] (state, value) {
    state.postAnswerError = value
  },
  [QuestionAnswerMutationTypes.SET_PATCH_QUESTION_RIGHTS_PENDING] (state, value) {
    state.patchQuestionRightsPending = value
  },
  [QuestionAnswerMutationTypes.SET_PATCH_QUESTION_RIGHTS_ERROR] (state, value) {
    state.patchQuestionRightsError = value
  },
  [QuestionAnswerMutationTypes.SET_QUESTIONS_BULK_RIGHTS_PENDING] (state, value) {
    state.questionsBulkRightsPending = value
  },
  [QuestionAnswerMutationTypes.SET_FILTERS_PANE_IS_OPEN] (state, value) {
    state.filtersPaneIsOpen = value
  },
  [QuestionAnswerMutationTypes.SET_FILTERS] (state, value) {
    state.filters = value
  },
  [QuestionAnswerMutationTypes.REMOVE_FILTER] (state, filterToRemove) {
    state.filters = state.filters.filter(
      filter => filter.category.key !== filterToRemove.category.key || filter.value.key !== filterToRemove.value.key,
    )
  },
  [QuestionAnswerMutationTypes.ADD_ANSWER_TO_QUESTION] (state, { questionId, answer }) {
    if (state.question.id === questionId) {
      state.question.answers.push(answer)
    }
  },
  [QuestionAnswerMutationTypes.SET_MARK_QUESTION_AS_ANSWERED_PENDING] (state, value) {
    state.markQuestionAsResolvedPending = value
  },
  [QuestionAnswerMutationTypes.SET_IMPORT_QUESTIONS_PENDING] (state, value) {
    state.importQuestionsPending = value
  },
  [QuestionAnswerMutationTypes.SET_IMPORT_QUESTIONS_ERROR] (state, value) {
    state.importQuestionsError = value
  },
}
