<template>
  <v-alert v-model="isDisplayed"
           v-bind="$attrs"
           :icon="noIcon ? false : icon"
           :type="type"
           :text="text"
           :dismissible="dismissible"
           :border="noBorder ? null : 'left'"
           class="mb-0"
           :transition="transition"
  >
    <!-- @slot for alert content -->
    <slot></slot>
    <template v-if="dismissible" #close="{toggle}">
      <AppButton icon
                 class="ml-4"
                 style="font-size: 16px; color: black;"
                 @click="closeAlert(toggle)"
      >
        <font-awesome-icon :icon="['fal', 'xmark']"></font-awesome-icon>
      </AppButton>
      <!-- <i class="fal fa-xmark accent--text clickable ml-5"
         style="font-size: 16px;"
         @click="closeAlert(toggle)"
      ></i> -->
    </template>
  </v-alert>
</template>

<script>
import AppButton from '../buttons/AppButton'
const ALERT_TYPES = Object.freeze({
  info: 'info',
  warning: 'warning',
  error: 'error',
})
/**
 * Default Alert
 */
export default {
  name: 'Alert',
  components: {
    AppButton,
  },
  /*
  * All Vuetify props for Alert components are usable.
  * See doc : https://vuetifyjs.com/en/api/v-alert/#props
  */
  inheritAttrs: false,
  props: {
    /**
     * display state
     */
    value: {
      type: Boolean,
      default: true,
    },
    /**
     * Designates a specific icon
     */
    icon: {
      type: [Boolean, String],
      default: 'fal fa-circle-info',
    },
    /**
     * Adds a close icon that can hide the alert
     */
    dismissible: {
      type: Boolean,
      default: true,
    },
    /**
     * Applies the defined color to text and a low opacity background of the same
     */
    text: {
      type: Boolean,
      default: true,
    },
    /**
     * Specify a success, info, warning or error alert. Uses the contextual color and has a pre-defined icon
     */
    type: {
      type: String,
      default: 'info',
      validator: value => {
        return value in ALERT_TYPES
      },
    },
    /**
     * Wether the left icon is displayed
     */
    noIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Wether the left border is displayed
     */
    noBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * Apply specific transition
     */
    transition: {
      type: String,
      default: 'scroll-y-reverse-transition',
    },
  },
  computed: {
    isDisplayed: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    closeAlert (toggle) {
      this.isDisplayed = false
      toggle()
    },
  },
}
</script>

<style scoped lang="scss">
  ::v-deep .v-icon {
    align-self: flex-start;
  }
</style>

<docs>
```vue
  <template>
    <Alert>
      This is a simple alert
    </Alert>
  </template>
</docs>
