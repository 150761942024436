<template>
  <div class="app-avatar"
       :class="{
          'app-avatar--xs': size === 'xs',
          'app-avatar--s': size === 's',
       }"
  >
    <div class="app-avatar-text">
      {{initials}}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

type AppAvatarSize = 'xs' | 's'
export default defineComponent({
  name: 'AppAvatar',
  props: {
    /**
     * The text to display in avatar
     */
    initials: {
      type: String,
      required: true,
    },
    /**
     * Size of the avatar
     * @values: xs, s
     */
    size: {
      type: String as PropType<AppAvatarSize>,
      default: 's',
    },
  },
})
</script>

<style scoped lang="scss">
.app-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--v-grey-darken2);
  background-color: var(--v-grey-lighten3);
  font-weight: 500;

  &--xs { width: 24px; height: 24px; font-size: 12px; }
  &--s { width: 32px; height: 32px; font-size: 14px; }

  &-text {
    text-transform: uppercase;
  }

}
</style>

<docs>
```vue
<template>
  <v-container fluid>
    <v-row>
      <v-col class="text-h1">
        Avatar
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        Avatar is a screen-based representation of the user’s identify.
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>Size xs</div>
        <AppAvatar :size="'xs'" initials="DS"/>
      </v-col>
      <v-col>
        <div>Size s</div>
        <AppAvatar initials="DS"/>
      </v-col>
    </v-row>
  </v-container>
</template>
```
</docs>
