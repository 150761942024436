<template>
  <AppCard
    hover
    class="p-4 project-type-card text-center"
    :class="{'disabled': disabled}"
    :style="{'--color': MATERIAL_COLORS[color]}"
    v-bind="$attrs"
    @click.native="$emit('click')"
  >
    <img class="mr-4" :src="mainIconSrc" width="24" height="24" />
    <span class="label">
      <slot name="default">
      </slot>
    </span>
    <font-awesome-icon
      v-if="isProjectCategory"
      class="ml-8 white--text project-category-icon"
      size="lg"
      :icon="['fal', 'plus']"
    />
    <img class="background-icon" :src="mainIconSrc" />

    <span v-if="disabled" class="indicator px-2">{{$t('projects.ProjectTypeCard.disabledIndicator')}}</span>
    <span v-else-if="isNew" class="indicator px-2">{{$t('projects.ProjectTypeCard.newIndicator')}}</span>
  </AppCard>
</template>

<script>
import AppCard from '../common/AppCard.vue'
import { MATERIAL_COLORS } from '../common/utils/colors'

export default {
  name: 'ProjectTypeCard',
  components: { AppCard },
  props: {
    color: {
      type: String,
      required: true,
    },
    mainIconSrc: {
      type: String,
      required: true,
    },
    isProjectCategory: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      MATERIAL_COLORS,
    }
  },
}
</script>

<style lang="scss" scoped>
  .project-type-card {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .background-icon {
      position: absolute;
      bottom: -40px;
      right: 18px;
      width: 110px;
      height: 100px;
      opacity: .14;
      clip: rect(auto, auto, 60px, auto);
    }
    .label {
      font-weight: 700;
      color: white;
    }
    .indicator {
      position: absolute;
      top: 5px;
      right: 6px;
      color: white;
      font-size: 11px;
      border: 1px solid white;
      border-radius: 6px;
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--color);
      z-index: -1;
      border-radius: 6px;
    }

    &.disabled:before {
      opacity: .6;
    }
  }
</style>
