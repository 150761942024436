<template>
    <v-btn v-bind="$attrs"
           depressed
           :plain="link"
           :color="computedColor"
           :class="[
              {'defaultBorder': defaultBorder}, computedColorClasses, xPaddingComputed,
              {'bg-primary-lighten-4': color === 'primary-lighten4'},
            ]"
           :text="text"
           v-on="$listeners"
    >
        <!-- @slot for button text -->
        <slot></slot>
    </v-btn>
</template>

<script>
import { opts } from '@/plugins/vuetify'

const COLOR_MAP = {
  'primary-muted': '#ffeeee',
  'primary-lighten4': opts.theme.themes.light.primary.lighten4,
}
const COLOR_CLASS_MAP = {
  'primary-muted': ['text--primary', 'font-weight-semi-bold'],
  'primary-lighten4': ['primary--text'],
}
/**
 * Default Button
 * @displayName AppButton
 */
/**
 * Display a Button.
 *
 * All Vuetify props for Button component are usable.
 * @see doc : https://vuetifyjs.com/en/api/v-btn/#props
 */
export default {
  name: 'AppButton',
  inheritAttrs: false,
  props: {
    /**
     * Applies specified color to the control - it can be the name of material color (for example success or purple) or css color (#033 or rgba(255, 0, 0, 0.5))
     * @see https://vuetifyjs.com/en/styles/colors/#material-colors
     * @TODO refactor this to use vuetify theme colors
     * */
    color: {
      type: String,
      default: '',
    },
    /**
     * Makes the background transparent. When using the color prop, the color will be applied to the button text instead of the background
     * */
    text: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom x padding using a number that's a multiple of the app's spacer unit
     * */
    xPadding: {
      type: Number,
      default: undefined,
    },
    /**
     * Designates that the component is a link. This is automatic when using the href or to prop
     * */
    link: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    defaultBorder () {
      return this.color === 'white' && !this.text
    },
    computedColor () {
      return COLOR_MAP[this.color] || this.color
    },
    computedColorClasses () {
      return COLOR_CLASS_MAP[this.color] || []
    },
    xPaddingComputed () {
      if (this.link) {
        return 'px-0'
      } else if (this.xPadding !== undefined) {
        return `px-${this.xPadding}`
      }
      return 'px-4'
    },
  },
}
</script>

<style scoped lang="scss">
  // For vuetify customization color
  // primary-lighten4 is not in opts.theme.themes.light.primary.lighten4
  // to get the right color use --v-primary-lighten4
  .bg-primary-lighten-4 {
    background-color: var(--v-primary-lighten4) !important;
  }

  ::v-deep.v-btn.defaultBorder {
    border: 1px solid #e0e0e0 !important;
  }
  ::v-deep.v-btn--plain {
    .v-btn__content {
      opacity: 1 !important;
      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>

<docs>
```vue
<template>
  <AppButton color="primary">My button</AppButton>
</template>
```
</docs>
