<template>
  <!-- NAVIGATION DRAWER ON LEFT-->
  <v-navigation-drawer
    v-model="drawerIsOpen"
    class="elevation-1 drawer overflow-visible"
    :permanent="permanent"
    :mini-variant="computedMini"
    :mini-variant-width="miniWidth"
    app
    :width="responsiveWidth"
  >
    <div class="d-flex flex-column align-center pt-5 mb-2">
      <AppTooltip v-if="!$vuetify.breakpoint.mobile"
                  top
                  :nudge-right="105"
      >
        <template #activator="{attrs, on}" >
            <span v-bind="attrs"
                  style="z-index: 2"
                  :class="{ 'expand-button--floating': !computedMini }"
                  v-on="on"
            >
              <ExpandButton :direction="computedMini ? 'right' : 'left'" @click="computedMini = !mini"/>
            </span>
        </template>
        <span>{{ $t(computedMini ? 'project.ProjectNavigationDrawer.openDrawer' : 'project.ProjectNavigationDrawer.closeDrawer') }}</span>
      </AppTooltip>

      <template v-if="computedMini">
        <AppTooltip
          v-if="!homeLayout"
          top
        >
          <template #activator="{attrs, on}">
            <span v-bind="attrs" class="mt-3" v-on="on">
              <router-link :to="{ name: projectsRoute }"
                           class="text-decoration-none grey--text text--darken-3"
              >
                {{ $t('project.ProjectNavigationDrawer.home') }}
              </router-link>
            </span>
          </template>
          <span class="text-caption">{{ $t('project.ProjectNavigationDrawer.backToHome') }}</span>
        </AppTooltip>
      </template>
      <template v-else>
        <router-link style="z-index: 1" :to="{ name: projectsRoute }">
          <img alt="Subscription Logo"
               :style="{'max-width': hasSubscriptionLogo ? '170px' : '100px', 'max-height': '140px'}"
               :src="computedLogo"
          />
        </router-link>

        <div v-if="hasSubscriptionLogo" class="d-flex align-center my-2">
          <span>{{$t('project.ProjectNavigationDrawer.with')}}</span>
          <router-link :to="{ name: projectsRoute }">
            <v-img
              alt="Closd Name"
              class="ml-2"
              style="vertical-align: middle"
              contain
              min-width="60"
              src="/img/logo.svg"
              width="70"
            />
          </router-link>
        </div>
        <router-link
          v-if="!homeLayout"
          :to="{ name: projectsRoute }"
          class="text-decoration-none grey--text text--darken-3"
        >
          <p class="text-caption">{{ $t('project.ProjectNavigationDrawer.backToHome') }}</p>
        </router-link>
      </template>
    </div>

    <template v-if="homeLayout">
      <DocumentsSidebar class="mt-3 overflow-y-auto home-sidebar-content"
                        :class="{'mt-0 pt-0': $vuetify.breakpoint.smAndDown}"/>
    </template>
    <template v-if="projectLayout">
      <ProjectNavigationDrawerLayout class="mt-3"
                                     :items="projectSections"
                                     :defaultSelectedItem="selectedItem"
      />
      <div class="text-right caption mt-auto pa-4">{{ closdVersion }}</div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import ExpandButton from '@/common/ExpandButton.vue'
import { PROJECT_NAV_DRAWER_WIDTHS } from '@/common/layout/constants'
import DocumentsSidebar from '@/project/documents/navigation-sidebar/DocumentsSidebar.vue'
import ProjectNavigationDrawerLayout from '@/project/navigation-drawer/ProjectNavigationDrawerLayout.vue'
import {
  QUESTION_ANSWER_LIST_ROUTE_NAME,
  ROOMS_ROUTE_NAME,
  ROOM_BIBLES_ROUTE_NAME,
  ROOM_CHECKLIST_ROUTE_NAME,
  ROOM_DOCUMENTS_ROUTE_NAME,
  ROOM_LOGS_ROUTE_NAME,
  ROOM_MEMBERS_ROUTE_NAME,
  ROOM_SETTINGS_ROUTE_NAME,
  ROOM_SIGNED_DOCUMENTS_ROUTE_NAME,
  SIGNING_CHECKLIST_ROUTE_NAME,
} from '@/router'
import { SET_MINI } from '@/store/modules/global/mutation_types'

// This duration is meant to wait after any drawer width change so that
// we can trigger a refresh to update the v-content position relative to the new drawer width
// This is due to a Vuetify bug when the navigation drawer has multiple width values set to it
// Especially when using different units
const CONTENT_REFLOW_DELAY = 190

export default {
  name: 'ProjectNavigationDrawer',
  components: { ProjectNavigationDrawerLayout, AppTooltip, ExpandButton, DocumentsSidebar },
  data () {
    return {
      miniWidth: PROJECT_NAV_DRAWER_WIDTHS.RAW.FOLDED,
      permanent: false,
      selectedItem: null,
    }
  },
  computed: {
    ...mapState(['homeLayout', 'projectLayout']),
    ...mapState('room', ['currentRoom']),
    ...mapState('subscription', ['subscriptionBranding']),
    ...mapState(['toggleProjectDrawer']),
    ...mapState('global', ['mini']),
    ...mapState('user', ['profile']),
    subscriptionLogo () {
      return this.currentRoom?.subscriptionLogo
    },
    hasSubscriptionLogo () {
      return !!this.subscriptionLogo
    },
    computedLogo () {
      if (this.computedMini) {
        return '/img/logo-small.svg'
      } else if (this.hasSubscriptionLogo) {
        return this.subscriptionLogo
      }
      return '/img/logo.svg'
    },
    responsiveWidth () {
      if (this.$vuetify.breakpoint.xs) {
        return PROJECT_NAV_DRAWER_WIDTHS.WITH_UNIT.XS_SCREEN
      }

      if (this.$vuetify.breakpoint.mobile) {
        return PROJECT_NAV_DRAWER_WIDTHS.WITH_UNIT.MOBILE
      }

      return PROJECT_NAV_DRAWER_WIDTHS.WITH_UNIT.REGULAR
    },
    drawerIsOpen: {
      get () {
        return this.$vuetify.breakpoint.mobile
          ? this.toggleProjectDrawer
          : true
      },
      set (val) {
        this.setToggleProjectDrawer(val)
      },
    },
    projectsRoute () {
      return ROOMS_ROUTE_NAME
    },
    membersMenuElement () {
      return {
        key: ROOM_MEMBERS_ROUTE_NAME,
        title: this.$t('project.ProjectNavigationDrawer.participants'),
        icon: 'user-friends',
        iconSize: '18px',
      }
    },
    documentsMenuElement () {
      return {
        key: ROOM_DOCUMENTS_ROUTE_NAME,
        title: this.$t('project.ProjectNavigationDrawer.documents'),
        icon: 'folder-open',
        iconSize: '18px',
      }
    },
    checkListMenuElement () {
      return {
        key: ROOM_CHECKLIST_ROUTE_NAME,
        title: this.$t('project.ProjectNavigationDrawer.checklist'),
        icon: 'tasks',
        iconSize: '18px',
      }
    },
    signingChecklistMenuElement () {
      return {
        key: SIGNING_CHECKLIST_ROUTE_NAME,
        title: this.$t('project.ProjectNavigationDrawer.signing-checklist'),
        icon: 'clipboard-list-check',
        iconSize: '20px',
      }
    },
    signedDocumentsMenuElement () {
      return {
        key: ROOM_SIGNED_DOCUMENTS_ROUTE_NAME,
        title: this.$t('project.ProjectNavigationDrawer.signedDocuments'),
        icon: 'file-signature',
        iconSize: '18px',
      }
    },
    bibleMenuElement () {
      return {
        key: ROOM_BIBLES_ROUTE_NAME,
        title: this.$t('project.ProjectNavigationDrawer.bibles'),
        icon: 'book',
        iconSize: '19px',
      }
    },
    settingsMenuElement () {
      return {
        key: ROOM_SETTINGS_ROUTE_NAME,
        title: this.$t('project.ProjectNavigationDrawer.settings'),
        icon: 'sliders-v',
        iconSize: '18px',
      }
    },
    questionAnswersListMenuElement () {
      return {
        key: QUESTION_ANSWER_LIST_ROUTE_NAME,
        title: this.$t('project.ProjectNavigationDrawer.qa'),
        icon: 'comments-alt',
        iconSize: '18px',
      }
    },
    logsMenuElement () {
      return {
        key: ROOM_LOGS_ROUTE_NAME,
        title: this.$t('project.ProjectNavigationDrawer.logs'),
        icon: 'user-shield',
        iconSize: '18px',
      }
    },
    projectSections () {
      const menu = []
      if (this.currentRoom?.isDataroom) {
        // No need to check for room access, if the prop is truthy, we have a room
        menu.push(this.membersMenuElement)
        if (!this.currentRoom.hideDocuments) {
          menu.push(this.documentsMenuElement)
        }
        menu.push(this.questionAnswersListMenuElement)
        if (this.currentRoom.isPM) {
          menu.push(this.logsMenuElement)
        }
        menu.push(this.bibleMenuElement)
        if (this.currentRoom.isPM) {
          menu.push(
            {
              title: 'separator',
            },
            this.settingsMenuElement,
          )
        }
      } else if (this.currentRoom) {
        menu.push(this.membersMenuElement)
        if (!this.currentRoom.hideDocuments) {
          menu.push(this.documentsMenuElement)
        }
        if (!this.currentRoom.hideTodo) {
          menu.push(this.checkListMenuElement)
        }
        menu.push(this.signingChecklistMenuElement, this.signedDocumentsMenuElement, this.bibleMenuElement)
        if (this.currentRoom.isPM) {
          menu.push(
            {
              title: 'separator',
            },
            this.settingsMenuElement,
          )
        }
      }
      return menu
    },
    closdVersion () {
      return process.env.VUE_APP_VERSION
    },
    computedMini: {
      get () {
        return this.mini
      },
      set (val) {
        this.$store.commit(`global/${SET_MINI}`, val)
      },
    },
  },
  watch: {
    '$vuetify.breakpoint.mobile': {
      async handler (newValue) {
        await this.$nextTick()
        setTimeout(() => {
          this.permanent = !newValue
          if (newValue) {
            this.computedMini = false
          }
        }, CONTENT_REFLOW_DELAY)
      },
      immediate: true,
    },
    computedMini: {
      async handler () {
        if (!this.$vuetify.breakpoint.mobile) {
          await this.$nextTick()
          setTimeout(() => {
            this.permanent = !this.permanent
          }, CONTENT_REFLOW_DELAY)
          this.permanent = !this.permanent
        }
      },
      immediate: true,
    },
  },
  mounted () {
    this.selectedItem = this.projectSections.findIndex(el => el.key === this.$route.name)
  },
  methods: {
    ...mapActions(['setToggleProjectDrawer']),
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}

.home-sidebar-content {
  // Magic number. The HappyFoxChat module is at the bottom left, at the same place as the sidebar.
  // To avoid overlapping content, we put a margin and since we use an overflow class on the content,
  // we'll be scrolling without the chat on top.
  // The chat module is around 72px of height with a container height of 90px, so this is right.
  margin-bottom: 80px
}

.expand-button {
  &--floating {
    position: absolute;
    right: 8px;
    top: 20px;
  }
}
</style>
