export const state = {
  logsSort: {
    sortBy: '',
    sortDesc: false,
  },
  logsFilters: [],
  logsFiltersPaneIsOpen: false,
  roomLogsSearchQuery: '',
  logsDialog: {
    isOpen: false,
    document: null,
  },
  fileLogsPending: false,
  fileLogsError: null,
  folderLogsPending: false,
  folderLogsError: null,
  roomLogsPending: false,
  roomLogs: null,
  roomLogsError: null,
  logsTypesPending: false,
  logsTypes: null,
  logsTypesError: null,
}
