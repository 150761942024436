<template>
  <AppDialog :is-open="validationModalIsOpen"
             size="xl"
             ok-only
             :ok-text="$t('common.cancel')"
             @ok="resetAndClose"
             @cancel="resetAndClose"
  >
    <template #title>{{$t('certeurope-sign.CerteuropeSignValidationDialog.title')}}</template>
    <template #body>
      <v-row>
        <v-col class="text-center">
          <svg v-if="signaturesConfirmed" style="width: 130px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle
              class="path circle"
              fill="none"
              stroke="#23AE73"
              stroke-width="6"
              stroke-miterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <polyline
              class="path check"
              fill="none"
              stroke="#23AE73"
              stroke-width="6"
              stroke-linecap="round"
              stroke-miterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
          <v-img v-else
                 src="/img/phone_sms.png"
                 width="160"
                 class="mx-auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <template v-if="!signaturesConfirmed">
            <div class="body-2 text--primary text-center"
                 v-html="$t('certeurope-sign.CerteuropeSignValidationDialog.fillConfirmationCode', {phone: profile.telephone})">
            </div>
          </template>
          <template v-else>
            <div class="body-2 text--primary text-center"
                 v-html="$t('certeurope-sign.CerteuropeSignValidationDialog.redirection')">
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" offset="3">
          <div class="d-flex align-center">
            <font-awesome-icon :icon="['fas', 'key']"
                               class="mr-4"
                               style="font-size: 24px;"
            ></font-awesome-icon>
            <v-otp-input v-model.trim="codeValidationInput"
                         length="6"
            />
          </div>
          <div v-if="wrongValidationSms"
               class="mt-1 error--text body-2 text-center"
          >
            {{ $t("certeurope-sign.CerteuropeSignValidationDialog.incorrectValidationCode") }}
          </div>
          <div v-if="wrongValidationSms3Times"
               class="mt-1 error--text body-2 text-center"
          >
            {{ $t("certeurope-sign.CerteuropeSignValidationDialog.incorrectValidationCode3Times") }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox v-model="confirmeInformation"
                      class="body-2 text--primary"
                      :label="$t('certeurope-sign.CerteuropeSignValidationDialog.confirmeInformation')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <AppButton color="primary"
                     :disabled="signDisabled"
                     :loading="signDocumentPending"
                     @click="prepareSignDocument"
          >
            {{ $t('certeurope-sign.CerteuropeSignValidationDialog.sign') }}
          </AppButton>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="body-2">
          <div v-if="!smsResent && displaySmsResend"
               class="CerteuropeSignValidationDialog-codeNotReceivedActive text-center"
               @click="prepareResendSms"
          >
            {{ $t('certeurope-sign.CerteuropeSignValidationDialog.codeNotReceived') }}
          </div>
          <div v-if="smsResent && displaySmsResend" class="text-center">
            <font-awesome-icon :icon="['fa', 'check']" class="text--success"></font-awesome-icon>
          </div>
          <div v-if="!smsResent && !displaySmsResend"
               class="CerteuropeSignValidationDialog-codeNotReceivedInactive text-center">
            {{ $t('certeurope-sign.CerteuropeSignValidationDialog.codeNotReceived') }} ({{ smsResentTimer }})
          </div>
        </v-col>
      </v-row>
    </template>
  </AppDialog>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { mapState, mapActions } from 'vuex'

import AppButton from '../common/buttons/AppButton'
import AppDialog from '../common/dialogs/AppDialog'
import { SIGN_LANDING_PAGE_ROUTE_NAME } from '../router'
import { RESEND_VALIDATION_SMS, SEND_VALIDATION_SMS, SIGN_DOCUMENT } from '../store/modules/certeurope-sign/action_types'
import { SET_VALIDATION_MODAL_IS_OPEN } from '../store/modules/certeurope-sign/mutation_types'
import { ENQUEUE_SNACKBAR } from '../store/mutation_types'
export default {
  name: 'CerteuropeSignValidationDialog',
  components: { AppDialog, AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      signaturesConfirmed: false,
      codeValidationInput: '',
      confirmeInformation: false,
      smsResent: false,
      displaySmsResend: false,
      smsResentTimer: null,
      smsResendTimeout: null,
      wrongValidationSms: false,
      wrongValidationSms3Times: false,
    }
  },
  computed: {
    ...mapState('certeuropeSign', ['validationModalIsOpen', 'signingView', 'signDocumentPending']),
    ...mapState('user', ['profile']),
    signDisabled () {
      return !this.codeValidationInput || !this.confirmeInformation || this.signDocumentPending || this.signaturesConfirmed
    },
  },
  watch: {
    smsResentTimer: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.smsResentTimer--
          }, 1000)
        } else if (value !== null) {
          this.displaySmsResend = true
        }
      },
      immediate: true,
    },
    validationModalIsOpen (value) {
      if (value) {
        this.onDialogOpen()
      }
    },
  },
  methods: {
    ...mapActions('certeuropeSign', [SEND_VALIDATION_SMS, SIGN_DOCUMENT, RESEND_VALIDATION_SMS]),
    onDialogOpen () {
      this.prepareSendValidationSms()
    },
    async prepareSendValidationSms () {
      try {
        if (this.smsResentTimer === null || this.smsResentTimer === -1) {
          this.smsResentTimer = 30
        }
        this.smsResendTimeout = setTimeout(() => { this.displaySmsResend = true }, 30000)
        if (this.smsSent) return
        this.smsSent = true
        this.wrongValidationSms = false
        await this.SEND_VALIDATION_SMS({
          mnemo: this.mnemo,
          envelopeId: this.signingView.id,
          data: { consent: true },
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('certeurope-sign.CerteuropeSignValidationDialog.sendValidationSmsError'),
        })
      }
    },
    async prepareResendSms () {
      try {
        this.wrongValidationSms = false
        this.smsResent = true
        setTimeout(() => {
          this.smsResent = false
        }, 20000)
        await this.RESEND_VALIDATION_SMS({
          mnemo: this.mnemo,
          envelopeId: this.signingView.id,
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('certeurope-sign.CerteuropeSignValidationDialog.sendValidationSmsError'),
        })
      }
    },
    async prepareSignDocument () {
      try {
        await this.SIGN_DOCUMENT({
          mnemo: this.mnemo,
          envelopeId: this.signingView.id,
          data: { otp: this.codeValidationInput },
        })
        this.signaturesConfirmed = true
        setTimeout(() => {
          this.$router.push({
            name: SIGN_LANDING_PAGE_ROUTE_NAME,
            params: {
              mnemo: this.mnemo,
            },
            query: {
              event: 'signing_complete',
            },
          })
        }, 3000)
      } catch (error) {
        // Debug info for V2-677
        let tagsInfo = ''
        this.signingView.pages.forEach((page) => {
          if (page.tags.length > 0) {
            tagsInfo += `--\nPage ${page.number} (id: ${page.id}) tags: \n`
            page.tags.forEach((tag) => {
              tagsInfo += `  - id: ${tag.id} type: ${tag.type} isSigned: ${tag.isSigned}\n`
            })
            tagsInfo += '--\n'
          }
        })
        console.info(tagsInfo)
        Sentry.captureException(error)

        if (['ERR_WRONG_OTP', 'ERROR_SIGN_SERVICE'].includes(error.response?.data?.errorCode)) {
          this.wrongValidationSms = true
        }

        if (error.response?.data?.errorCode === 'ERR_WRONG_OTP_RESENT') {
          this.wrongValidationSms3Times = true
        }
      }
    },
    resetAndClose () {
      clearTimeout(this.smsResendTimeout)
      this.smsResendTimeout = null
      this.smsResent = false
      this.signaturesConfirmed = false
      this.confirmeInformation = false
      this.wrongValidationSms = false
      this.displaySmsResend = false
      this.smsResentTimer = null
      this.codeValidationInput = ''
      this.$store.commit(`certeuropeSign/${SET_VALIDATION_MODAL_IS_OPEN}`, false)
    },
  },
}
</script>

<style scoped lang="scss">
.CerteuropeSignValidationDialog-codeNotReceivedInactive {
  text-decoration: underline;
  cursor: not-allowed;
}
.CerteuropeSignValidationDialog-codeNotReceivedActive {
  text-decoration: underline;
  cursor: pointer;
}
</style>
