<template>
  <div>
    <v-breadcrumbs :items="routes">
      <template v-slot:divider>
        <v-icon style="opacity: 0.4">fa-angle-right</v-icon>
      </template>
      </v-breadcrumbs>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h1">{{$t('project.views.SigningChecklistOrder.title')}}</h1>
      </v-col>
    </v-row>
    <div v-if="signingChecklistPending || !initFinished">
      <AppButton :to="{ name: SIGNING_CHECKLIST_ROUTE_NAME }" exact class="mt-4" color="white">
        <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-2"></font-awesome-icon>
        {{$t('project.views.SigningChecklistOrder.backBtnLabel')}}
      </AppButton>
      <v-row class="mt-4">
        <v-col v-if="$vuetify.breakpoint.mdAndUp" md="4">
          <v-skeleton-loader type="list-item@3"></v-skeleton-loader>
        </v-col>
        <v-col md="8" cols="12">
          <AppCard>
            <v-skeleton-loader type="heading, list-item@3"></v-skeleton-loader>
          </AppCard>
          <v-skeleton-loader type="button" class="fullwidth-skeleton-button my-4"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="!signingChecklistPending && hasAtLeastOneSigningChecklist && initFinished">
      <v-row  class="my-0" >
        <v-col class="pb-0">
          <Alert>
            <div v-html="$t('project.views.SigningChecklistOrder.topHint')"></div>
          </Alert>
        </v-col>
      </v-row>
      <v-row>
        <AppButton :to="{ name: SIGNING_CHECKLIST_ROUTE_NAME }" exact class="mt-4" color="white">
          <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-2"></font-awesome-icon>
          {{$t('project.views.SigningChecklistOrder.backBtnLabel')}}
        </AppButton>
        <v-spacer />
        <AppButton
          class="my-4"
          color="primary"
          :loading="pendingAddPositions.includes(groupList.length)"
          @click="addGroup(groupList.length)"
        >
          {{$t('project.views.SigningChecklistOrder.addGroupBtnLabel')}}
        </AppButton>
      </v-row>
    </div>
    <div v-if="!signingChecklistPending && hasAtLeastOneSigningChecklist && initFinished" class="mt-4">
      <v-row>
        <v-col md="4"
               class="align-self-stretch"
        >
          <AppCard class="pa-4 h-100"
                   :elevation="0"
          >
            <SigningChecklistOrderSteps
              v-model="stepList"
              :group="{
                name: 'available-steps',
                pull: ['steps'],
                put: ['steps'],
              }"
              sidebar
              @change="stepChange(-1, $event)"
            >
            </SigningChecklistOrderSteps>
          </AppCard>
        </v-col>
        <v-col ref="groupCol"
               v-resize="groupColHeight"
               md="8"
               cols="12"
               class="overflow-y-auto"
        >
          <draggable
            v-model="groupList"
            group="group"
            handle=".group-handle"
            @change="groupChange"
          >
          <transition-group name="move">
            <div v-for="(group, index) in groupList" :key="group.id" class="move-item">
              <AppCard class="groupCard pa-4"
                       :elevation="0"
              >
                <div class="group-handle mb-2">
                  <font-awesome-icon class="body-1 mr-2" :icon="['fal', 'arrows']"></font-awesome-icon>
                  <span class="font-weight-semi-bold">#{{ index + 1}}</span>
                </div>
              <div class="group-steps-container">
                <SigningChecklistOrderSteps
                  v-model="group.steps"
                  :group="{
                    name: 'steps',
                    pull: ['steps', 'available-steps'],
                    put: ['steps', 'available-steps'],
                  }"
                  :move="onMove"
                  removable
                  @change="stepChange(group.id, $event)"
                  @remove-step="removeStep(group, $event)"
                >
                </SigningChecklistOrderSteps>
              </div>
              <div class="text-right">
              <AppButton
                class="mt-2"
                text
                :loading="deleteGroupPendingIds.includes(group.id)"
                @click="showDeleteGroupModal(group.id)"
              >
                <font-awesome-icon class="mr-2" :icon="['fas', 'trash']"></font-awesome-icon>
                {{$t('project.views.SigningChecklistOrder.deleteGroupBtnLabel')}}
              </AppButton>
              </div>
            </AppCard>
            <AppButton
              class="groupAddButton w-100 my-0"
              color="primary-muted"
              :loading="pendingAddPositions.includes(index + 1)"
              @click="addGroup(index + 1)"
            >
              {{$t('project.views.SigningChecklistOrder.addGroupBtnLabel')}}
            </AppButton>
          </div>
          </transition-group>
          </draggable>
        </v-col>
      </v-row>
    </div>
    <v-layout v-else-if="!signingChecklistPending && initFinished" class="mt-16" align-center justify-center>
      <p>{{$t('project.views.SigningChecklistOrder.noData')}}</p>
    </v-layout>
    <SigningChecklistOrderDeleteGroupDialog
      v-if="deleteGroupModal"
      @confirm="confirmGroupDelete"
      @close="deleteGroupModal = false"
    />

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { createNamespacedHelpers } from 'vuex'

import { ROOM_DOCUMENTS_ROUTE_NAME, SIGNING_CHECKLIST_ROUTE_NAME, ROOM_ROUTE_TYPES } from '@/router'

import Alert from '../../common/alerts/Alert.vue'
import AppCard from '../../common/AppCard.vue'
import AppButton from '../../common/buttons/AppButton.vue'
import { CREATE_GROUP, DELETE_GROUP, GET_SIGNING_CHECKLIST, GET_SIGNING_CHECKLIST_GROUP, MOVE_GROUP, MOVE_STEP } from '../../store/modules/signing-checklist/action_types'
import { SET_SIGNING_CHECKLIST } from '../../store/modules/signing-checklist/mutation_types'
import SigningChecklistOrderDeleteGroupDialog from '../signing-checklist/SigningChecklistOrderDeleteGroupDialog.vue'
import SigningChecklistOrderSteps from '../signing-checklist/SigningChecklistOrderSteps.vue'

const { mapActions, mapMutations, mapState, mapGetters } = createNamespacedHelpers('signingChecklist')
const { mapState: mapRoomState } = createNamespacedHelpers('room')

export default {
  name: 'SigningChecklistOrder',
  components: {
    AppButton,
    Alert,
    AppCard,
    draggable,
    SigningChecklistOrderSteps,
    SigningChecklistOrderDeleteGroupDialog,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      SIGNING_CHECKLIST_ROUTE_NAME: SIGNING_CHECKLIST_ROUTE_NAME,
      routes: [
        {
          exact: true,
          to: {
            name: SIGNING_CHECKLIST_ROUTE_NAME,
          },
          text: this.$t('project.views.SigningChecklistOrder.initialBreadcrumb'),
        },
        {
          text: this.$t('project.views.SigningChecklistOrder.title'),
        },
      ],
      groupList: [],
      stepList: [],
      pendingAddPositions: [],
      groupPendingDelete: 0,
      deleteGroupModal: false,
      initFinished: false,
    }
  },
  metaInfo () {
    return {
      title: this.$t('project.views.SigningChecklistOrder.metaTitle'),
    }
  },
  computed: {
    ...mapState(['signingChecklistPending', 'signingChecklistGroups', 'deleteGroupPendingIds']),
    ...mapRoomState(['currentRoom']),
    ...mapGetters(['flatSigningChecklist']),
    hasAtLeastOneSigningChecklist () {
      return this.flatSigningChecklist.length !== 0
    },
  },
  watch: {
    groupList (newValue) {
      const stepIdsAlreadyInGroups = this.getStepIdsInGroupList(newValue)
      this.stepList = this.flatSigningChecklist.filter((step) => !stepIdsAlreadyInGroups.includes(step.id) && (step.envelope && step.envelope.status === 'draft'))
      if (!this.initFinished) {
        this.initFinished = true
      }
    },
  },
  async created () {
    if (this.currentRoom.isDataroom) {
      this.$router.replace({ name: ROOM_DOCUMENTS_ROUTE_NAME, params: { roomType: ROOM_ROUTE_TYPES.DATAROOM } })
    }
    await this.GET_SIGNING_CHECKLIST({ mnemo: this.mnemo })
    if (this.hasAtLeastOneSigningChecklist) {
      await this.GET_SIGNING_CHECKLIST_GROUP(this.mnemo)
      this.groupList = this.signingChecklistGroups
    }
  },
  destroyed () {
    // Make it empty again
    this.SET_SIGNING_CHECKLIST([])
  },
  methods: {
    ...mapMutations([SET_SIGNING_CHECKLIST]),
    ...mapActions([GET_SIGNING_CHECKLIST, GET_SIGNING_CHECKLIST_GROUP, CREATE_GROUP, DELETE_GROUP, MOVE_GROUP, MOVE_STEP]),
    getStepIdsInGroupList (groupList) {
      const ids = []
      groupList.forEach((group) => {
        group.steps.forEach((step) => {
          ids.push(step.id)
        })
      })
      return ids
    },
    async addGroup (position) {
      try {
        this.pendingAddPositions.push(position)
        const response = await this.CREATE_GROUP({
          mnemo: this.mnemo,
          position,
        })
        this.groupList.splice(position, 0, { id: response.data.group.id, position, steps: [] })
      } catch (e) {
        console.error(e)
      } finally {
        this.pendingAddPositions.splice(this.pendingAddPositions.indexOf(position), 1)
      }
    },
    async deleteGroup (groupId) {
      await this.DELETE_GROUP({
        mnemo: this.mnemo,
        groupId,
      })
      this.groupList = this.groupList.filter((group) => group.id !== groupId)
    },
    async groupChange (event) {
      this.MOVE_GROUP({
        mnemo: this.mnemo,
        blockId: event.moved.element.id,
        position: event.moved.newIndex,
      })
    },
    async stepChange (groupId, event) {
      if (event.added) {
        this.MOVE_STEP({
          mnemo: this.mnemo,
          stepId: event.added.element.id,
          groupId,
        })
      }
    },
    async removeStep (group, step) {
      await this.stepChange(-1, { added: { element: { id: step.id } } })
      this.stepList.push(step)
      group.steps = group.steps.filter((s) => s.id !== step.id)
    },
    onMove (event) {
      // Don't allow steps to move inside the same group
      if (event.from === event.to) {
        return false
      }
    },
    showDeleteGroupModal (groupIdToDelete) {
      this.groupPendingDelete = groupIdToDelete
      this.deleteGroupModal = true
    },
    confirmGroupDelete () {
      this.deleteGroup(this.groupPendingDelete)
      this.groupPendingDelete = 0
    },
    async groupColHeight () {
      if (this.$refs.groupCol) {
        await this.$nextTick()
        const totalHeight = window.innerHeight
        const offset = this.$refs.groupCol.getBoundingClientRect().y
        this.$refs.groupCol.style.height = `${totalHeight - offset - 20}px`
      }
    },
  },
}
</script>

<style scoped lang="scss">
.group-steps-container {
  border: 2px dashed #e0e0e0;
  border-radius: 5px;
  background-color: #fafafa;
}

.fullwidth-skeleton-button ::v-deep div {
  width: 100%;
}

.group-handle {
  cursor: move;
}

.groupCard,
.groupAddButton {
  border-radius: 0;
}

.move-item:first-child {
  .groupCard {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}
</style>
