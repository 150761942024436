<template>
  <div>
    <v-row>
      <v-col cols="12">
        <app-alert-in-page v-if="isWitnessEnabled" class="mb-4">
          <template>
            <div class="d-flex">
              <app-text variant="small-regular">{{ $t('project.signing-checklist.add.step2.Section2.witnessInfo.regular') }}</app-text>
              <app-text variant="small-bold" class="ml-1">{{ $t('project.signing-checklist.add.step2.Section2.witnessInfo.bold') }}</app-text>
            </div>
           <span class="d-block">
             <a class="app-link learnMoreLink d-flex"
             href="https://help.closd.com"
             target="_blank"
             >
              <app-text variant="small-bold">
                {{ $t('common.learnMore') }}
                <font-awesome-icon :icon="['far', 'external-link']" class="ml-1" size="xs"/>
              </app-text>

            </a>
           </span>
          </template>
        </app-alert-in-page>

        <div>{{ $t('project.signing-checklist.add.step2.Section2.signatureTypeCaption')}}</div>

        <div>
          <AppDownloadFile :link="learnMoreSignatureTypesLink" forceReader class="app-link learnMoreLink">
            <template #default="{ submitForm }">
              <span @click="submitForm">
                {{ $t("project.signing-checklist.add.step2.Section2.learnMoreSignatureType") }}
                <font-awesome-icon :icon="['fal', 'external-link']" size="xs"/>
              </span>
            </template>
          </AppDownloadFile>
        </div>
        <v-radio-group
          ref="signatureTypeRadioGroup"
          :value="signatureType"
          required
          :disabled="isEditingLimited"
          class="envelopeType__container"
          row
          active-class="envelopeType__card--active"
          :error-messages="signatureTypeErrorMessages"
          @change="signatureTypeChange"
        >
          <template v-for="item in envelopeTypeOptions">
            <AppTooltip :key="item.value"
                        :disabled="!item.disabled"
                        top
            >
              <template #activator="{attrs, on}">
                <div v-bind="attrs" v-on="on">
                  <v-radio
                    :value="item.value"
                    :disabled="item.disabled"
                    :class="`envelopeType__card envelopeType__card-${item.value}`"
                    readonly
                    @click="!item.disabled && signatureTypeChange(item.value)"
                  >
                    <template v-slot:label>
                      <h4>{{ item.text }}</h4>
                      <div class="caption">
                          <span
                            v-if="SIGNATURE_TYPE_WITH_AUTH.includes(item.value)"
                            class="mr-1"
                          >
                            {{ $t('project.signing-checklist.add.step2.Section2.signingTypeAuthRequired') }}
                          </span>
                          <span
                            v-if="item.value === SIGNATURE_TYPE_HANDWRITTEN"
                          >
                            {{ $t('project.signing-checklist.add.step2.Section2.signingTypeNoOrder') }}
                          </span>
                        <i18n path="project.signing-checklist.add.step2.Section2.signingTypeMaxSize" tag="span">
                          <app-filesize-display :value="getProviderMaxSizeInBytes(item.providerMaxSize)"/>
                        </i18n>
                      </div>
                    </template>
                  </v-radio>
                </div>
              </template>
              <span> {{ $t(`project.signing-checklist.add.step2.Section2.${textTooltipDisabledOption}`) }}</span>
            </AppTooltip>
          </template>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="signatureType === SIGNATURE_TYPE_HANDWRITTEN">
      <v-col cols="12">
        <v-checkbox
          :input-value="hasMercuryOptionActivated"
          class="mt-0"
          :label="$t('project.signing-checklist.add.step2.Section2.mercuryCaseLabel')"
          @change="mercuryOptionChange"
        ></v-checkbox>
      </v-col>
    </v-row>
    <SignerOrderSignatureTypeIncompatibilityWarningDialog v-if="signerOrderSignatureTypeIncompatibilityWarningDialog"
                              @close="signerOrderSignatureTypeIncompatibilityWarningDialog = false"
                              @confirm="confirmChangeSignatureType"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { createNamespacedHelpers, mapGetters } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import { BtoMB } from '@/common/utils/sizes'
import {
  ENVELOPE_TYPES,
  SIGNATURE_TYPE_DISPLAY_ORDER,
  SIGNATURE_TYPE_DOCUSIGN_QUALIFIED,
  SIGNATURE_TYPE_HANDWRITTEN,
  SIGNATURE_TYPE_WITH_AUTH,
} from '@/project/signing-checklist/constants'
import { SET_IMPORTED_FILE_MERCURY_OPTION } from '@/store/modules/signing-checklist/mutation_types'

import SignerOrderSignatureTypeIncompatibilityWarningDialog from '../../dialogs/SignerOrderSignatureTypeIncompatibilityWarningDialog.vue'

const { mapState: mapRoomState } = createNamespacedHelpers('room')
const { mapState: mapSigningChecklistState, mapMutations: mapSigningChecklistMutations } = createNamespacedHelpers('signingChecklist')

export default Vue.extend({
  name: 'Section2',
  components: {
    AppDownloadFile,
    AppTooltip,
    SignerOrderSignatureTypeIncompatibilityWarningDialog,
  },
  props: {
    signatureType: {
      type: String,
      required: true,
    },
    isEditingLimited: {
      type: Boolean,
      required: true,
    },
    signatureTypeValidator: {
      type: Object,
      required: true,
    },
    fileSize: {
      type: Number,
      required: true,
    },
    hasSignersOrder: {
      type: Boolean,
      required: true,
    },
    hasMercuryOptionActivated: {
      type: Boolean,
      required: true,
    },
    envelopeType: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      ENVELOPE_TYPES,
      SIGNATURE_TYPE_WITH_AUTH,
      SIGNATURE_TYPE_HANDWRITTEN,
      signerOrderSignatureTypeIncompatibilityWarningDialog: false,
      envelopeTypeOptions: [],
    }
  },
  computed: {
    ...mapSigningChecklistState(['qualifiedDSWarningIsMuted']),
    ...mapRoomState(['currentRoom', 'roomProvidersPending']),
    ...mapGetters('room', ['isWitnessEnabled']),
    learnMoreSignatureTypesLink (): string {
      return `${process.env.VUE_APP_API_URL}/assets/pdf?file=signatures-types-${this.$i18n.locale.toUpperCase()}`
    },
    signatureTypeErrorMessages (): TranslateResult[] {
      if (this.signatureTypeValidator.$dirty &&
      !this.signatureTypeValidator.required) {
        return [this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.signing-checklist.add.step2.Section2.signatureTypeLabel') })]
      }
      return []
    },
    textTooltipDisabledOption (): string {
      return this.envelopeType === ENVELOPE_TYPES.MULTIPLE ? 'envelopeMultipleDSQOptionDisabledTooltip' : 'envelopeTypeOptionDisabledTooltip'
    },
  },
  methods: {
    ...mapSigningChecklistMutations[SET_IMPORTED_FILE_MERCURY_OPTION],
    signatureTypeChange (signatureType: string) {
      const shouldWarnAboutManualSignature = signatureType === SIGNATURE_TYPE_HANDWRITTEN && this.hasSignersOrder
      if (shouldWarnAboutManualSignature) {
        this.signerOrderSignatureTypeIncompatibilityWarningDialog = true
        return
      }
      const shouldWarnAboutQualifiedSignature = signatureType === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED && !this.qualifiedDSWarningIsMuted
      if (shouldWarnAboutQualifiedSignature) {
        this.$emit('setQualifiedDSWarningDialog', true)
        this.$emit('setSelectSignatureType', this.signatureType)
        // We have to manually set the value of the v-radio-group, because its internal value is out of sync with the value prop
        // Both of them are needed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.signatureTypeRadioGroup.value = this.signatureType
        this.$refs.signatureTypeRadioGroup.lazyValue = this.signatureType
      } else {
        this.$emit('setSelectSignatureType', signatureType)
      }
    },
    confirmChangeSignatureType () {
      this.signerOrderSignatureTypeIncompatibilityWarningDialog = false
      this.$emit('setSelectSignatureType', SIGNATURE_TYPE_HANDWRITTEN)
    },
    getProviderMaxSizeInBytes (providerMaxSize: number): number {
      // TODO ask the back to improve that
      return providerMaxSize * 1048576
    },
    mercuryOptionChange (value) {
      this.SET_IMPORTED_FILE_MERCURY_OPTION({
        fileId: this.file.id,
        value: value,
      })
    },
    getEnvelopesTypeOptions () {
      const enveloppes = []
      if (!this.roomProvidersPending && this.currentRoom.providers) {
        for (const provider of this.currentRoom.providers) {
          for (const type of provider.types) {
            const value = provider.provider + '.' + type
            const isDisabled = this.envelopeType === ENVELOPE_TYPES.MULTIPLE && value === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED
            enveloppes[value] = {
              text: this.$t('project.signing-checklist.add.step2.Section2.signingTypeSelect')[value],
              value,
              disabled: this.fileSize > provider.maxSize || isDisabled,
              providerMaxSize: BtoMB(provider.maxSize),
            }
          }
        }
      }
      this.envelopeTypeOptions = SIGNATURE_TYPE_DISPLAY_ORDER
        .map(signatureType => enveloppes[signatureType])
        .filter(e => e !== undefined)
    },

  },
  mounted () {
    this.getEnvelopesTypeOptions()
  },
  watch: {
    envelopeType (newValue) {
      this.envelopeTypeOptions = this.envelopeTypeOptions.map(sig =>
      sig.value === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED ? { ...sig, disabled: newValue === ENVELOPE_TYPES.MULTIPLE } : sig,
      )
    },
  },
})
</script>

<style scoped lang="scss">
.envelopeType__container {

  ::v-deep .v-input--radio-group__input {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }

  .envelopeType__card {
    justify-self: stretch;
    min-height: 72px;
    padding: 16px 24px;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    margin: 0;

    &--active {
      outline: 2px solid var(--v-primary-base);
      outline-offset: -2px;
      background-color: var(--v-primary-lighten4);
    }

    ::v-deep .v-label{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
    }
    // Place radio button on top of card
    ::v-deep .v-input--selection-controls__input {
      align-self: flex-start;
    }

    .caption {
      color: #717B85;
      font-size: 12px;
    }
  }
}

.learnMoreLink {
  color: #4C75B5;
}
</style>
