<template>
 <div>
   <v-row>
     <v-col cols="12" sm="6">
       <div class="text-h1">{{$t('project.bibles.BiblesHeader.biblesTitle')}}</div>
       <p class="mt-3 mb-0 body-1 accent--text text--lighten-2">{{$t('project.bibles.BiblesHeader.biblesSubTitle')}}</p>
     </v-col>
   </v-row>

 </div>
</template>

<script>
export default {
  name: 'BiblesHeader',
}
</script>
