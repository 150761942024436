import {
  SET_FORGOT_PASSWORD_PENDING,
  SET_FORGOT_PASSWORD,
  SET_FORGOT_PASSWORD_ERROR,
  SET_ACTIVATE_ACCOUNT_PENDING,
  SET_ACTIVATE_ACCOUNT,
  SET_ACTIVATE_ACCOUNT_ERROR,
  SET_RESET_PASSWORD_PENDING,
  SET_RESET_PASSWORD,
  SET_RESET_PASSWORD_ERROR,
  SET_SIGNIN_PENDING,
  SET_SIGNIN,
  SET_SIGNIN_ERROR,
  SET_LOADING_PAGE,
  SET_TWO_FACTOR_ERROR,
  SET_AUTH_FROM_TOKEN_PENDING,
  SET_AUTH_FROM_TOKEN_ERROR,
  SET_USER_TOKEN_SCOPE,
  SET_STEP,
} from './mutation_types'
export const mutations = {
  SET_LOADING: function (state, value) {
    state.loading = value
  },
  [SET_STEP] (state, step) {
    state.step = step
  },
  SET_SAMLCHECK_ERROR: function (state, error) {
    state.samlCheckError = error
  },
  [SET_LOADING_PAGE] (state, value) {
    state.loadingPage = value
  },
  [SET_FORGOT_PASSWORD_PENDING] (state, value) {
    state.forgotPasswordPending = value
  },
  [SET_FORGOT_PASSWORD] (state, value) {
    state.forgotPassword = value
  },
  [SET_FORGOT_PASSWORD_ERROR] (state, value) {
    state.forgotPasswordError = value
  },
  [SET_ACTIVATE_ACCOUNT_PENDING] (state, value) {
    state.activateAccountPending = value
  },
  [SET_ACTIVATE_ACCOUNT] (state, value) {
    state.activateAccount = value
  },
  [SET_ACTIVATE_ACCOUNT_ERROR] (state, value) {
    state.activateAccountError = value
  },
  [SET_RESET_PASSWORD_PENDING] (state, value) {
    state.resetPasswordPending = value
  },
  [SET_RESET_PASSWORD] (state, value) {
    state.resetPassword = value
  },
  [SET_RESET_PASSWORD_ERROR] (state, value) {
    state.resetPasswordError = value
  },
  [SET_SIGNIN_PENDING] (state, value) {
    state.signinPending = value
  },
  [SET_SIGNIN] (state, value) {
    state.signin = value
  },
  [SET_SIGNIN_ERROR] (state, value) {
    state.signinError = value
  },
  [SET_TWO_FACTOR_ERROR] (state, value) {
    state.twoFactorError = value
  },
  [SET_AUTH_FROM_TOKEN_PENDING] (state, value) {
    state.authFromTokenPending = value
  },
  [SET_AUTH_FROM_TOKEN_ERROR] (state, value) {
    state.authFromTokenError = value
  },
  [SET_USER_TOKEN_SCOPE] (state, value) {
    state.userTokenScope = value
  },
}
