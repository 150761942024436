import { render, staticRenderFns } from "./AppChatMessage.vue?vue&type=template&id=421a3d8d&scoped=true"
import script from "./AppChatMessage.vue?vue&type=script&lang=js"
export * from "./AppChatMessage.vue?vue&type=script&lang=js"
import style0 from "./AppChatMessage.vue?vue&type=style&index=0&id=421a3d8d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "421a3d8d",
  null
  
)

export default component.exports