<template>
  <AppDialog :is-open="isDialogOpened"
             size="xxl"
             :ok-text="$t('common.share')"
             :ok-loading="shareTasksPending"
             :ok-disabled="shareTasksPending"
             :cancel-disabled="shareTasksPending"
             @ok="prepareShareTasks"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('common.share') }}</template>
    <template #body>
      <div class="text--primary">
        <v-row class="mt-0">
          <v-col class="text-h4 accent--text text--darken-4">
            {{ $tc('project.checklist.dialogs.ChecklistShareTasksDialog.selectedTasks', tasksToShare.length, { tasksCount: tasksToShare.length }) }}
            <span v-if="singleTask">: {{ singleTask.display }} {{ singleTask.title }}</span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            {{ $tc('project.checklist.dialogs.ChecklistShareTasksDialog.selectResponsibleAndObservers', tasksToShare.length) }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <Alert type="warning"
                   noIcon
                   :dismissible="false"
            >
              <div class="d-flex">
                <div class="font-weight-bold mr-2">{{$t('common.warning')}} :</div>
                <div class="d-flex flex-column row-gap-2">
                  <div>{{$tc('project.checklist.dialogs.ChecklistShareTasksDialog.warningAboutObervers', tasksToShare.length)}}</div>
                  <div>{{$t('project.checklist.dialogs.ChecklistShareTasksDialog.warningAboutTitles')}}</div>
                </div>
              </div>
            </Alert>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-checkbox v-model="taskVisibleByAll"
                        class="mt-0"
                        hide-details
                        :disabled="groups.length === 0"
                        :label="$tc('project.checklist.dialogs.ChecklistShareTasksDialog.taskVisibleByAll', tasksToShare.length)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox v-model="sendNotif"
                        hide-details
                        class="mt-0 pt-0"
                        :label="$t('project.checklist.dialogs.ChecklistShareTasksDialog.notifyNew')"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <ChecklistShareTasksDialogRolePicker :mnemo="mnemo"
                                                 :taskVisibleByAll="taskVisibleByAll"
            />
          </v-col>
        </v-row>
      </div>
    </template>
  </AppDialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { dialogMixin } from '@/mixins/dialogMixin'
import {
  CLEAR_ITEMS_SELECTION,
  GET_CHECKLIST,
  REFRESH_TASK,
  SHARE_TASKS,
} from '@/store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import ChecklistShareTasksDialogRolePicker from './ChecklistShareTasksDialogRolePicker'
import Alert from '../../../common/alerts/Alert'
import AppDialog from '../../../common/dialogs/AppDialog'

export default {
  name: 'ChecklistShareTasksDialog',
  components: { AppDialog, ChecklistShareTasksDialogRolePicker, Alert },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      taskVisibleByAll: false,
      sendNotif: true,
    }
  },
  computed: {
    ...mapState('checklist', [
      'shareTasksDialog',
      'shareTaskRolePickerSelectedResponsible',
      'shareTaskRolePickerSelectedObservers',
      'shareTaskRolePickerSelectedValidators',
      'currentTaskFolder',
      'shareTasksPending',
    ]),
    ...mapState('groups', ['groups']),
    tasksToShare () { return this.shareTasksDialog.tasksToShare },
    singleTask () {
      return this.tasksToShare.length === 1
        ? this.tasksToShare[0]
        : null
    },
  },
  created () {
    if (this.singleTask) {
      this.taskVisibleByAll = this.singleTask.rights.read.isPublic
    } else {
      this.taskVisibleByAll = this.tasksToShare.every(task => task.rights.read.isPublic)
    }
  },
  methods: {
    ...mapActions('checklist', [CLEAR_ITEMS_SELECTION, SHARE_TASKS, REFRESH_TASK, GET_CHECKLIST]),
    async prepareShareTasks () {
      try {
        await this.SHARE_TASKS({
          mnemo: this.mnemo,
          data: {
            tasks: this.tasksToShare.map(item => { return item.id }),
            isPublicRead: this.taskVisibleByAll,
            rightsUsersResponsibles: this.shareTaskRolePickerSelectedResponsible.members.map(item => item.id),
            rightsGroupsResponsibles: this.shareTaskRolePickerSelectedResponsible.groups.map(item => item.id),
            rightsUsersObservers: this.shareTaskRolePickerSelectedObservers.members.map(item => item.id),
            rightsGroupsObservers: this.shareTaskRolePickerSelectedObservers.groups.map(item => item.id),
            rightsUsersValidators: this.shareTaskRolePickerSelectedValidators.members.map(item => item.id),
            rightsGroupsValidators: this.shareTaskRolePickerSelectedValidators.groups.map(item => item.id),
            notify: this.sendNotif,
          },
        })

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$tc('project.checklist.dialogs.ChecklistShareTasksDialog.shareTasksSuccess', this.tasksToShare.length),
        })

        if (this.singleTask && this.shareTasksDialog.parent) {
          this.REFRESH_TASK({
            mnemo: this.mnemo,
            taskId: this.singleTask.id,
            parent: this.shareTasksDialog.parent,
          })
        } else {
          this.GET_CHECKLIST(this.mnemo)
        }

        this.CLEAR_ITEMS_SELECTION()
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('project.checklist.dialogs.ChecklistShareTasksDialog.shareTasksError', this.tasksToShare.length),
        })
      } finally {
        this.closeDialog()
      }
    },
  },
}
</script>
