<template>
  <div>
    <div class="treeNode">
      <v-icon class="treeNode-caretButton"
              :class="{'treeNode-caretButton--closed': !folderIsOpen}"
              @click="folderIsOpen = !folderIsOpen"
      >
        fas fa-caret-down
      </v-icon>
      <v-checkbox v-model="isChecked"
                  color="primary"
                  dense
                  hide-details
                  :disabled="selectionIsDisabled"
                  :indeterminate="partiallyChecked"
                  :ripple="false"
                  class="treeNode-checkbox mt-0"
      >
        <template #label>
          <template v-if="rootFolder.type === 'folder'">
            <div class="d-flex align-center">
              <font-awesome-icon v-if="folderIsOpen"
                                 class="treeNode-checkboxLabelIcon--folder"
                                 :icon="['fad', 'folder-open']"
                                 size="lg"
              >
              </font-awesome-icon>
              <font-awesome-icon v-if="!folderIsOpen"
                                 class="treeNode-checkboxLabelIcon--folder"
                                 :icon="['fad', 'folder']"
                                 size="lg"
              >
              </font-awesome-icon>
              {{ fullname }}
            </div>
          </template>
          <template v-if="rootFolder.type === 'file'">
            <ClosdFildersIcon :document="item"/>
          </template>
        </template>
      </v-checkbox>
    </div>
    <div v-show="folderIsOpen">
      <AppClosdFildersTreeItem v-for="filder in rootFolder.children"
                               :key="`${filder.type}-${filder.id}`"
                               :filder="filder"
                               :display-tree-structure="displayTreeStructure"
                               :multiple="multiple"
                               :files-only="filesOnly"
                               :selected-filders="value"
                               :parent-is-selected="isChecked"
                               :disable-folder-without-file="disableFolderWithoutFile"
                               :disable-files-too-large-to-be-signed="disableFilesTooLargeToBeSigned"
                               :max-signable-size="maxSignableSize"
                               @child-checked="onChildCheck"
                               @check-children-except-one="checkChildrenExceptOne"
      />
    </div>
  </div>
</template>

<script>
import { numericSort } from '@/common/utils/sorting'

import AppClosdFildersTreeItem from './AppClosdFildersTreeItem'
import ClosdFildersIcon from '../filders/ClosdFildersIcon'
/**
 * Default Folders and files tree
 * @displayName AppClosdFildersTree
 */
/**
 * Display a folder tree view
 */
export default {
  name: 'AppClosdFildersTree',
  components: { AppClosdFildersTreeItem, ClosdFildersIcon },
  props: {
    /**
     * The folder to display as a tree
     */
    rootFolder: {
      type: Object,
      required: true,
    },
    /**
     * Displays tree titles and subtitles
     */
     displayTreeStructure: {
      type: Boolean,
      default: false,
    },
    /**
     * @model
     * The selected item or items if multiple
     */
    value: {
      type: Array,
      required: true,
    },
    /**
     * Allow selecting multiples items
     */
    multiple: {
      type: Boolean,
      default: true,
    },
    /**
     * If true, display only files
     */
    filesOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, disable checkbox of folders without files in children (deep)
     */
    disableFolderWithoutFile: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, disable selection for files larger than VUE_APP_MAX_FILE_SIZE
     */
    disableFilesTooLargeToBeSigned: {
      type: Boolean,
      default: false,
    },
    /**
     * If disableFilesTooLargeToBeSigned is true, this should be set
     */
    maxSignableSize: {
      type: [Number, String],
      default: 0,
    },
    /**
     * If true, disable selection for root folder
     */
    disableRootFolder: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      folderIsOpen: true,
    }
  },
  computed: {
    isChecked: {
      get () { return this.indexIfSelected >= 0 },
      set () {
        if (this.indexIfSelected < 0) {
          if (!this.multiple) {
            this.value.splice(0, this.value.length)
            this.value.push(this.rootFolder)
          } else {
            this.value.push(this.rootFolder)
          }
        } else {
          this.value.splice(this.indexIfSelected, 1)
        }
      },
    },
    indexIfSelected () {
      return this.value.findIndex(o => o.id === this.rootFolder.id && o.type === this.rootFolder.type)
    },
    partiallyChecked () {
      if (this.rootFolder.type === 'folder') {
        return this.rootFolder.children.some(o => this.value.some(selectedFilder => selectedFilder.id === o.id && selectedFilder.type === o.type)) && this.multiple
      }
      return false
    },
    fullname () {
      return (this.rootFolder.numbering ? this.rootFolder.numbering + ' ' : '') + this.rootFolder.basename
    },
    selectionIsDisabled () {
      const singleFileOnly = !this.multiple && this.filesOnly
      return singleFileOnly || this.disableRootFolder
    },
  },
  mounted () {
    if (!this.displayTreeStructure && this.rootFolder.children) {
      this.rootFolder.children = this.rootFolder.children.sort((a, b) => {
        if (a.type === 'folder' && b.type === 'file') {
          return 0
        } else if (a.type === 'file' && b.type === 'folder') {
          return 1
        } else {
          const aFullname = a.numbering ? `${a.numbering} ${a.basename}` : a.basename
          const bFullname = b.numbering ? `${b.numbering} ${b.basename}` : b.basename

          return numericSort(aFullname, bFullname)
        }
      })
    }
  },
  methods: {
    onChildCheck () {
      if (this.filder?.type !== 'title' && this.rootFolder.children.every(child => this.value.some(o => o.id === child.id && o.type === child.type)) && this.multiple) {
        this.isChecked = true
        // If we don't return only the files, we can return the folder containg them all
        if (!this.filesOnly) {
          this.rootFolder.children.forEach(f => {
            const index = this.value.findIndex(o => o.id === f.id && o.type === f.type)
            if (index >= 0) {
              this.value.splice(index, 1)
            }
          })
        }
      }
    },
    checkChildrenExceptOne (exceptFilder) {
      if (this.indexIfSelected >= 0) {
        this.value.splice(this.indexIfSelected, 1)
      }
      this.rootFolder.children.forEach(child => {
        if (child.id !== exceptFilder.id) {
          this.value.push(child)
        } else {
          if (child.type !== exceptFilder.type) {
            this.value.push(child)
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.treeNode {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding-right: 8px;
  padding-left: 8px;
 &:hover, &:focus {
   background-color: #f7f7f7;
 }
}
.treeNode-caretButton {
  width: 24px;
  height: 24px;
  &--closed {
    transform: rotate(-90deg);
  }
}
.treeNode-checkbox {
  margin-left: 6px;
}
.treeNode-checkboxLabelIcon--folder {
  margin-right: 6px;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  --fa-primary-color: #6aaefe;
  --fa-secondary-color: #6aaefe;
}
</style>

<docs>
```vue
<template>
  <AppClosdFildersTree :root-folder="rootFolder"
                       :value="selectedFiles"
                       multiple
  />
</template>
<script>
  import { rootFolder } from '@/fixtures/rootFolder'

  export default {
    data () {
      return {
        rootFolder,
        selectedFiles: [],
      }
    },
  }
</script>
```
</docs>
