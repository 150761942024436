<template>
<div class="Section__wrapper">
  <div class="Section__title">
    <app-headline :level="3">
      <slot name="title"></slot>
    </app-headline>
  </div>
  <div class="Section__content">
    <slot name="content"></slot>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SigningChecklistSection',
})
</script>

<style scoped lang="scss">
.Section__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Section__title {
  align-items: flex-start;
  background: var(--v-grey-lighten5);
  border-radius: 6px;
  color: var(--v-grey-darken2);
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 12px;
}
</style>
