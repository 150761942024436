<template>
  <AppButton class="app-button-alert"
             :class="'app-button-alert--' + type"
             size="small"
             type="outlined"
             v-bind="$attrs"
             :to="to"
             @click="onButtonClick"
  >
    <!-- @slot for button text -->
    <slot></slot>
  </AppButton>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import AppButton from './AppButton.vue'

export type AppButtonAlertType = 'info' | 'success' | 'warning' | 'error'
/**
 * All props from AppButton are available
 * @see [AppButton](#appbutton)
 * @displayName Alert Button
 *
*/
export default Vue.extend({
  name: 'AppButtonAlert',
  components: { AppButton },
  props: {
    /**
     * Color of the button
     * @values info, success, warning, error
     */
    type: {
      type: String as PropType<AppButtonAlertType>,
      default: 'info',
    },
    /**
     * Link
     * Default bind doesn't works so we pass to value as prop.
     */
    to: {
      type: Object as PropType<object | null>,
      default: null,
    },
  },
  methods: {
    onButtonClick ($event) {
      if (!this.$props.loading) {
        this.$emit('click', $event)
      }
    },
  },
})
</script>

<style scoped lang="scss">
.app-button-alert {
  &.app-button--outlined {
    &.app-button-alert--info {
      background-color: var(--v-white-base);
      outline: 1px solid var(--v-info-base);
      color: var(--v-info-base);

      &:hover,
      &:hover:enabled {
        background-color: var(--v-info-lighten1);
      }

      &:active,
      &:active:enabled {
        background-color: var(--v-white-base);
        color: var(--v-info-base);
      }

      &:disabled {
        outline: none;
        color: var(--v-grey-lighten3);

        &:hover,
        &:hover:enabled {
          background-color: var(--v-white-base);
        }

        &:active,
        &:active:enabled {
          background-color: var(--v-white-base);
          color: var(--v-grey-lighten3);
        }
      }
    }

    &.app-button-alert--success {
      background-color: var(--v-white-base);
      outline: 1px solid var(--v-success-base);
      color: var(--v-success-base);

      &:hover,
      &:hover:enabled {
        background-color: var(--v-success-lighten1);
      }

      &:active,
      &:active:enabled {
        background-color: var(--v-white-base);
        color: var(--v-success-base);
      }

      &:disabled {
        outline: none;
        color: var(--v-grey-lighten3);

        &:hover,
        &:hover:enabled {
          background-color: var(--v-white-base);
        }

        &:active,
        &:active:enabled {
          background-color: var(--v-white-base);
          color: var(--v-grey-lighten3);
        }
      }
    }

    &.app-button-alert--warning {
      background-color: var(--v-white-base);
      outline: 1px solid var(--v-warning-base);
      color: var(--v-warning-base);

      &:hover,
      &:hover:enabled {
        background-color: var(--v-warning-lighten1);
      }

      &:active,
      &:active:enabled {
        background-color: var(--v-white-base);
        color: var(--v-warning-base);
      }

      &:disabled {
        outline: none;
        color: var(--v-grey-lighten3);

        &:hover,
        &:hover:enabled {
          background-color: var(--v-white-base);
        }

        &:active,
        &:active:enabled {
          background-color: var(--v-white-base);
          color: var(--v-grey-lighten3);
        }
      }
    }
    &.app-button-alert--error {
      background-color: var(--v-white-base);
      outline: 1px solid var(--v-error-base);
      color: var(--v-error-base);

      &:hover,
      &:hover:enabled {
        background-color: var(--v-error-lighten1);
      }

      &:active,
      &:active:enabled {
        background-color: var(--v-white-base);
        color: var(--v-error-base);
      }

      &:disabled {
        outline: none;
        color: var(--v-grey-lighten3);

        &:hover,
        &:hover:enabled {
          background-color: var(--v-white-base);
        }

        &:active,
        &:active:enabled {
          background-color: var(--v-white-base);
          color: var(--v-grey-lighten3);
        }
      }
    }
  }
}
</style>

<docs>
```vue
<template>
  <div>
    <div>
      <label>
        Loading
        <input type="checkbox" v-model="isLoading">
      </label>
    </div>

    <table>
      <thead>
        <tr>
          <th />
          <th>info</th>
          <th>success</th>
          <th>warning</th>
          <th>error</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td class="align-right">
            enabled
          </td>
          <td>
            <app-button-alert type="info"
                              :loading="isLoading"
            >
              Button
            </app-button-alert>
          </td>
          <td>
            <app-button-alert type="success"
                              :loading="isLoading"
            >
              Button
            </app-button-alert>
          </td>
          <td>
            <app-button-alert type="warning"
                              :loading="isLoading"
            >
              Button
            </app-button-alert>
          </td>
          <td>
            <app-button-alert type="error"
                              :loading="isLoading"
            >
              Button
            </app-button-alert>
          </td>
        </tr>
        <tr>
          <td class="align-right">
            disabled
          </td>
          <td>
            <app-button-alert type="info"
                              :loading="isLoading"
                              :disabled="true"
            >
              Button
            </app-button-alert>
          </td>
          <td>
            <app-button-alert type="success"
                              :loading="isLoading"
                              :disabled="true"
            >
              Button
            </app-button-alert>
          </td>
          <td>
            <app-button-alert type="warning"
                              :loading="isLoading"
                              :disabled="true"
            >
              Button
            </app-button-alert>
          </td>
          <td>
            <app-button-alert type="error"
                              :loading="isLoading"
                              :disabled="true"
            >
              Button
            </app-button-alert>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        isLoading: false,
      }
    },
  }
</script>

<style lang="scss">
  label {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  table {
    border-collapse: separate;
    border-spacing: 12px;
    width: 100%;
    margin-bottom: 50px;
  }

  td {
    text-align: center;

    & > * {
      display: inline-block;
    }
  }

  .align-right {
    text-align: right;
  }
</style>
```
</docs>
