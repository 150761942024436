import {
  SET_FILE_PENDING,
  REMOVE_FILE_PENDING,
  SET_FILE_ERROR,
  SET_WATERMARKED_FILE_PENDING,
  REMOVE_WATERMARKED_FILE_PENDING,
  SET_WATERMARKED_FILE,
  SET_WATERMARKED_FILE_ERROR,
  RESET_WATERMARKED_FILE_WITH_FILE_ID,
  RESET_WATERMARKED_FILE,
  SET_WATERMARKED_DIALOG_DATA,
  RESET_WATERMARKED_DIALOG_DATA,
  SET_WATERMARKED_REQUEST_PENDING,
  REMOVE_WATERMARKED_REQUEST_PENDING,
} from './mutation_types'

export const mutations = {
  [SET_FILE_PENDING] (state, value) {
    state.filePendingIds.push(value)
  },
  [REMOVE_FILE_PENDING] (state, index) {
    state.filePendingIds.splice(index, 1)
  },
  [SET_FILE_ERROR] (state, value) {
    state.fileError = value
  },
  [SET_WATERMARKED_FILE_PENDING] (state, { fileId, data, identifier }) {
    for (let page = data.startPage; page <= data.endPage; page++) {
      state.watermarkedFilePendingPages.push({
        fileId,
        page,
        identifier,
      })
    }
  },
  [REMOVE_WATERMARKED_FILE_PENDING] (state, identifier) {
    state.watermarkedFilePendingPages = state.watermarkedFilePendingPages.filter(o => o.identifier !== identifier)
  },
  [SET_WATERMARKED_FILE] (state, value) {
    state.watermarkedFile.totalNbPages = value.totalNbPages
    const requestedPages = Array(value.endPage - value.startPage + 1).fill().map((_, idx) => value.startPage + idx)
    for (let i = 0; i < requestedPages.length; i++) {
      const pageExist = state.watermarkedFile.pages.findIndex(p => p.page === requestedPages[i])
      if (pageExist >= 0) {
        state.watermarkedFile.pages[pageExist].pageB64 = value.pngImages[i]
      } else {
        state.watermarkedFile.pages.push({
          page: requestedPages[i],
          pageB64: value.pngImages[i],
        })
      }
    }
  },
  [SET_WATERMARKED_FILE_ERROR] (state, value) {
    state.watermarkedFileError = value
  },
  [RESET_WATERMARKED_FILE_WITH_FILE_ID] (state, fileId) {
    state.watermarkedFile = {
      fileId: fileId,
      totalNbPages: 0,
      pages: [],
    }
  },
  [RESET_WATERMARKED_FILE] (state, fileId) {
    state.watermarkedFile = {
      fileId: null,
      totalNbPages: 0,
      pages: [],
    }
  },
  [SET_WATERMARKED_DIALOG_DATA] (state, { file, isOpen }) {
    state.watermarkedDialogData = {
      file,
      isOpen,
    }
  },
  [RESET_WATERMARKED_DIALOG_DATA] (state) {
    state.watermarkedDialogData = {
      file: null,
      isOpen: false,
    }
  },
  [SET_WATERMARKED_REQUEST_PENDING] (state, request) {
    state.watermarkedRequestPending.push(request)
  },
  [REMOVE_WATERMARKED_REQUEST_PENDING] (state, identifier) {
    const index = state.watermarkedRequestPending.findIndex(r => r.identifier === identifier)
    if (index >= 0) {
      state.watermarkedRequestPending.splice(index, 1)
    }
  },
}
