<template>
  <div class="mr-4">
    <div v-for="(envelope, index) in readyItems" :key="'indexReady_' + index">
      <SigningChecklistSummaryListTitle v-if="currentRoom.allowEnvelopeGrouping" :counter="envelope.length">
        <app-icon icon-name="envelope-open-text" size="medium" />
        <app-text variant="small-regular" as="span">
          {{$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.envelope')}} {{ envelopeStartingIndex + index + 1 }}
        </app-text>
      </SigningChecklistSummaryListTitle>
      <SigningChecklistSummaryListItem
        v-for="doc in envelope"
        :key="'itemReady_' + doc.id"
        :doc="doc"
      />
    </div>
    <div v-for="(envelope, index) in waitingValidationItems" :key="'indexWaitingValidation_' + index">
      <SigningChecklistSummaryListTitle v-if="currentRoom.allowEnvelopeGrouping"
                                         :counter="envelope.length"
      >
        <app-icon icon-name="envelope-open-text" size="medium" />
        <app-text variant="small-regular" as="span">
          {{$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.envelope')}} {{ envelopeStartingIndex + readyItems.length + index + 1 }}
        </app-text>
      </SigningChecklistSummaryListTitle>
      <SigningChecklistSummaryListItem
        v-for="doc in envelope"
        :key="'itemWaitingValidation_' + doc.id"
        :doc="doc"
      />
    </div>
    <div v-for="(envelope, index) in waitingCorrectionItems" :key="'indexWaitingCorrection_' + index" class="group--waiting-correction">
      <SigningChecklistSummaryListTitle :counter="envelope.length">
        <app-icon icon-name="triangle-exclamation" icon-weight="fas" size="medium" class="warning--text" />
        <app-text variant="small-bold" as="span">
          {{$t('project.signing-checklist.dialogs.SigningChecklistStartSigningDialog.documentsWaitingCorrection')}}
        </app-text>
      </SigningChecklistSummaryListTitle>
      <SigningChecklistSummaryListItem
        v-for="doc in envelope"
        :key="'itemWaitingCorrection_' + doc.id"
        :doc="doc"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import SigningChecklistSummaryListItem from './SigningChecklistSummaryListItem.vue'
import SigningChecklistSummaryListTitle from './SigningChecklistSummaryListTitle.vue'

const { mapState: mapRoomState } = createNamespacedHelpers('room')
export default {
  name: 'SigningChecklistSummary',
  components: { SigningChecklistSummaryListItem, SigningChecklistSummaryListTitle },
  props: {
    envelopeStartingIndex: {
      type: Number,
      default: 0,
    },
    groups: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapRoomState(['currentRoom']),
    readyItems () {
      if (!this.groups?.ready || !this.groups?.ready.length) return []
      if (Array.isArray(this.groups.ready[0])) { return this.groups.ready } else return [this.groups.ready]
    },
    waitingCorrectionItems () {
      if (!this.groups?.waitingCorrection || !this.groups?.waitingCorrection.length) return []
      if (Array.isArray(this.groups.waitingCorrection[0])) { return this.groups.waitingCorrection } else return [this.groups.waitingCorrection]
    },
    waitingValidationItems () {
      if (!this.groups?.waitingValidation || !this.groups?.waitingValidation.length) return []
      if (Array.isArray(this.groups.waitingValidation[0])) { return this.groups.waitingValidation } else return [this.groups.waitingValidation]
    },
  },
}
</script>

<style>
.group--waiting-correction {
  border: 1px var(--v-grey-lighten4) solid;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
}
</style>
