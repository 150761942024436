<template>
<div>
  <v-row>
    <v-col md="8" cols="12">
      <AppTextField
        :value="fileName"
        required
        class="required document-name-input"
        :disabled="isEditingLimited"
        :label="$t('project.signing-checklist.add.step2.Section1.documentNameLabel')"
        counter="70"
        maxlength="70"
        :error-messages="titleErrorMessages"
        @change="documentNameChange"
      >
      </AppTextField>
    </v-col>
    <v-col md="4" cols="12">
      <AppDatePicker
        class="document-signature-date-input"
        :value="fileDate"
        :disabled="isEditingLimited"
        :label="$t('project.signing-checklist.add.step2.Section1.dateLabel')"
        :placeholder="$t('project.signing-checklist.add.step2.Section1.datePlaceholder')"
        @change="signatureDateChange"
      ></AppDatePicker>
    </v-col>
  </v-row>
</div>
</template>

<script lang="ts">
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { createNamespacedHelpers } from 'vuex'

import AppDatePicker from '@/common/AppDatePicker.vue'
import AppTextField from '@/common/AppTextField.vue'
import { SET_IMPORTED_FILE_DOCUMENT_NAME, SET_IMPORTED_FILE_SIGNATURE_DATE } from '@/store/modules/signing-checklist/mutation_types'

const { mapMutations: mapSigningChecklistMutations } = createNamespacedHelpers('signingChecklist')

export default Vue.extend({
  name: 'Section1',
  components: {
    AppTextField,
    AppDatePicker,
  },
  props: {
    fileId: {
      type: Number,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    fileDate: {
      required: true,
      validator: (value) => typeof value === 'string' || value === null,
    },
    isEditingLimited: {
      type: Boolean,
      required: true,
    },
    titleValidator: {
      type: Object,
      required: true,
    },
  },
  computed: {
    titleErrorMessages (): TranslateResult[] {
      if (this.titleValidator.$dirty && !this.titleValidator.required) {
        return [this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.signing-checklist.add.step2.Section1.documentNameLabel') })]
      }
      return []
    },
  },
  methods: {
    ...mapSigningChecklistMutations([SET_IMPORTED_FILE_DOCUMENT_NAME, SET_IMPORTED_FILE_SIGNATURE_DATE]),
    documentNameChange (documentName: string) {
      this.SET_IMPORTED_FILE_DOCUMENT_NAME({
        fileId: this.fileId,
        documentName: documentName.trim(),
      })
    },
    signatureDateChange (signatureDate: string) {
      this.SET_IMPORTED_FILE_SIGNATURE_DATE({
        fileId: this.fileId,
        signatureDate: signatureDate,
      })
    },
  },
})
</script>
