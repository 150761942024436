<template>
  <div>
    <template v-if="envelopeTemplateDrafts.length === 0">
      <AppAlertInPage variant="error">{{$t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettings.noValidFilesAlert')}}</AppAlertInPage>
    </template>
    <template v-else>
      <app-text v-if="formMode === 'creation'" variant="large-bold" class="grey--text text--darken-3">
        {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettings.titleCreation') }}
      </app-text>
      <app-text v-else variant="large-bold" class="grey--text text--darken-3">
        {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettings.titleEdition') }}
      </app-text>

      <app-text variant="small-regular" class="grey--text text--darken-2 mb-3">
        {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettings.subTitle') }}
      </app-text>

      <v-expansion-panels flat>
        <template>
          <EnvelopeTemplateAddSettingsDocument
            v-for="(templateDraft, index) in envelopeTemplateDrafts"
            :key="index"
            :file="templateDraft.document"
            :validator="validator.envelopeTemplateDrafts.$each[index]"
            @signatureTypeChange="onSignatureTypeChange"
          >
          </EnvelopeTemplateAddSettingsDocument>
        </template>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import { defineComponent, PropType } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppAlertInPage from '@/design-system/alert/AppAlertInPage.vue'
import EnvelopeTemplateAddSettingsDocument from '@/project/signing-checklist/envelope-templates/add/EnvelopeTemplateAddSettingsDocument.vue'
import { GET_ROOM_PROVIDERS } from '@/store/modules/room/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'EnvelopeTemplateAddSettings',
  props: {
    formMode: {
      type: String,
      default: 'creation',
    },
    validator: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('envelopeTemplates', ['envelopeTemplateDrafts']),
    ...mapState('room', ['currentRoom']),
  },
  metaInfo () {
    return {
      title: this.$t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettings.metaTitle'),
    }
  },
  components: { AppAlertInPage, EnvelopeTemplateAddSettingsDocument },
  methods: {
    ...mapActions('room', [GET_ROOM_PROVIDERS]),
    onSignatureTypeChange (originalFile, signatureType) {
      if (
        this.envelopeTemplateDrafts.length > 1 &&
        this.envelopeTemplateDrafts.every(
          (templateDraft) => templateDraft.document.objectToPost.signatureType === '' || templateDraft.document === originalFile,
        )
      ) {
        let updatedSomethingElse = false

        for (const templateDraft of this.envelopeTemplateDrafts) {
          if (templateDraft.document === originalFile) {
            continue
          }
          const provider = signatureType.split('.')[0]
          const maxSize = this.currentRoom.providers.find((roomProvider) => roomProvider.provider === provider).maxSize
          if (maxSize > templateDraft.document.size) {
            templateDraft.document.objectToPost.signatureType = signatureType
            updatedSomethingElse = true
          }
        }
        if (updatedSomethingElse) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddSettings.groupSignatureTypeChange'),
          })
        }
      }
    },
  },
  async created () {
    if (!this.roomProvidersPending && !this.currentRoom.providers) {
      await this.GET_ROOM_PROVIDERS(this.currentRoom.mnemo)
    }
  },
})
</script>
