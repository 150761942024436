export const MATERIAL_COLORS = {
  'light-blue': '#03A9F4',
  'deep-purple': '#673AB7',
  'blue-darken-3': '#1565C0',
}

export function hexToRGB (hexColor) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
  return result
? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  }
: null
}

export const DARK_BLUE_50 = '#E9F1FE'
export const DARK_BLUE_500 = '#2671F2'
