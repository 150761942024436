import {
  SET_ACTION_TOKEN,
  SET_DIALOG_ERROR,
  SET_DIALOG_IFRAME_URL,
  SET_DIALOG_IS_OPEN,
  SET_DIALOG_PENDING,
  SET_DIALOG_SUCCESS,
} from './mutation_types'

export const mutations = {
  [SET_ACTION_TOKEN] (state, value) {
    state.actionToken = value
  },
  [SET_DIALOG_PENDING] (state, value) {
    state.dialogPending = value
  },
  [SET_DIALOG_IS_OPEN] (state, value) {
    state.dialogIsOpen = value
  },
  [SET_DIALOG_IFRAME_URL] (state, value) {
    state.dialogIframeUrl = value
  },
  [SET_DIALOG_ERROR] (state, value) {
    state.dialogError = value
  },
  [SET_DIALOG_SUCCESS] (state, value) {
    state.dialogSuccess = value
  },
}
