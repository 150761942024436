<template>
  <div class="d-flex align-center my-2">
    <v-avatar color="tertiary"
              size="32"
              class="mr-2"
    >
      <span class="white--text text-uppercase">{{ item.shortInitials }}</span>
    </v-avatar>
    <div>
      <div class="font-weight-medium">
        {{ item.firstName }} {{ item.lastName }}
        <span v-if="withCompany && item.company" class="font-weight-regular">({{ item.company }})</span>
      </div>
      <div class="caption">{{ item.email }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddMemberFormComboboxItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    withCompany: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
