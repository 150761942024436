<template>
  <AppDialog ref="Dialog"
             :is-open="isOpen"
             hide-header
             hide-footer
             size="xxxl"
  >
    <template #body>
      <template v-if="imanageFilePickerPending">
        <div class="text-center">
          <AppLoader/>
        </div>
      </template>
      <iframe v-if="iframeSrc"
              class="IManageFilePickerDialog-iframe"
              :src="iframeSrc.dialogUrl"
      ></iframe>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppLoader from '@/common/AppLoader'
import { GET_IMANAGE_FILE_PICKER } from '@/store/modules/imanage/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import AppDialog from '../dialogs/AppDialog'

export default {
  name: 'IManageFilePickerDialog',
  components: { AppDialog, AppLoader },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      iframeSrc: null,
      iManageEventHandlerIsActive: false,
    }
  },
  computed: {
    ...mapState('imanage', ['authCode', 'imanageFilePickerPending']),
  },
  watch: {
    isOpen (value) {
      if (value) {
        this.prepareGetIManageFilePicker()
        this.iManageEventHandlerIsActive = true
        window.addEventListener('message', e => { this.handleIManageReturns(e) }, false)
      } else {
        this.iManageEventHandlerIsActive = false
        window.removeEventListener('message', e => { this.handleIManageReturns(e) }, false)
      }
    },
  },
  methods: {
    ...mapActions('imanage', [GET_IMANAGE_FILE_PICKER]),
    async prepareGetIManageFilePicker () {
      try {
        this.iframeSrc = await this.GET_IMANAGE_FILE_PICKER(this.params)
      } catch (error) {
        if (error.response?.data?.errorCode === 'ERR_REQUIRE_IMANAGE_AUTH' && this.authCode) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('common.imanage.IManageFilePickerDialog.notAuth'),
          })
        } else {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('common.iManage.unavailable'),
          })
        }
        this.destroyIManageIframe()
      }
    },
    handleIManageReturns (e) {
      if (e.data) {
        if (this.iManageEventHandlerIsActive) {
          const event = e.data
          switch (event.type) {
            case 'cancel':
              this.destroyIManageIframe()
              break
            case 'select':
              this.getFileFromImanage(event.data)
              break
          }
        }
      }
    },
    destroyIManageIframe () {
      this.iframeSrc = null
      this.$emit('update:isOpen', false)
    },
    getFileFromImanage (files) {
      this.$emit('files', files)
      this.destroyIManageIframe()
    },
  },
}
</script>

<style scoped lang="scss">
.IManageFilePickerDialog-iframe {
  width: 100%;
  height: 75vh;
  overflow-y: auto;
}
</style>
