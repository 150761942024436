<template>
  <div>
    <template v-if="file.displayWatermarked">
      <div class="d-flex column-gap-1 align-center clickable"
           @click="openWatermarkedDialog"
      >
        <ClosdFildersIcon :document="file"/>
        <template v-if="file.numbering">
          <span>{{`${file.numbering}`}}</span>
        </template>
        <div class="filderName">{{file.basename || file.name}}</div>
        <app-badge v-if="file.version > 1" class="ml-auto">{{ `v${file.version}` }}</app-badge>
      </div>
    </template>
    <template v-else-if="file.canDownload">
      <AppDownloadFile :link="fileLink" :forceReader="fileExt === 'pdf'" class="d-block">
        <template #default="{ submitForm }">
          <div class="d-flex column-gap-1 align-center clickable" @click="submitForm">
            <ClosdFildersIcon :document="file"/>
            <template v-if="file.numbering">
              <span>{{`${file.numbering}`}}</span>
            </template>
            <div class="filderName">{{file.basename || file.name}}</div>
            <app-badge v-if="file.version > 1" class="ml-auto">{{ `v${file.version}` }}</app-badge>
          </div>
        </template>
      </AppDownloadFile>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile.vue'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon.vue'
import { roomFileDownloadUrl } from '@/common/utils/files'
import { SET_WATERMARKED_DIALOG_DATA } from '@/store/modules/files/mutation_types'

export default {
  name: 'DocumentsTableItemFileName',
  components: { AppDownloadFile, ClosdFildersIcon },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    fileExt () {
      if (this.file instanceof File) {
        return this.file.name.split('.').pop()
      } else if (this.file.ext) {
        return this.file.ext
      } else if (this.file.docExt) {
        return this.file.docExt
      } else if (this.file.extension) {
        return this.file.extension
      }
      return null
    },
    fileLink () {
      return roomFileDownloadUrl(this.file.id, this.roomMnemo)
    },
  },
  methods: {
    ...mapMutations('files', [SET_WATERMARKED_DIALOG_DATA]),
    openWatermarkedDialog () {
      this.SET_WATERMARKED_DIALOG_DATA({
        file: this.file,
        isOpen: true,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.filderName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
