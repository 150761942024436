<template>
  <v-dialog v-model="activator"
            v-bind="$attrs"
            :persistent="persistent"
            :width="dialogSize"
            v-on="$listeners"
  >
    <!-- Most of the time, the other slots should be used -->
    <!-- This is useful for components that explicitly need to fully control the template -->
    <slot name="default">
      <v-card :class="{'background-grey': backgroundGrey}">
        <!-- STEPPER -->
        <template v-if="stepperDialog">
          <AppDialogStepperTemplate v-model="stepperModel"
                                    :close-button="closeButton"
                                    :cancel-disabled="cancelDisabled"
                                    @cancel="cancel"
          >
            <template v-for="(_, slot) in $slots" v-slot:[slot]>
              <slot :name="slot" />
            </template>
          </AppDialogStepperTemplate>
        </template>
        <!-- TABS -->
        <template v-else-if="tabsDialog">
          <AppDialogTabsTemplate :close-button="closeButton"
                                 :tabs-template-has-title="tabsTemplateHasTitle"
                                 :cancel-disabled="cancelDisabled"
                                 @cancel="cancel"
          >
            <template v-for="(_, slot) in $slots" v-slot:[slot]>
              <slot :name="slot" />
            </template>
          </AppDialogTabsTemplate>
        </template>
        <template v-else>
          <v-card-title v-if="!hideHeader"
                        :class="[headerColor, {'white--text': headerDark}]"
          >
            <!-- @slot for title content -->
            <slot name="title"></slot>
            <AppButton v-if="closeButton"
                       class="close-button mb-auto"
                       :class="{'white--text': headerDark}"
                       :disabled="cancelDisabled"
                       icon
                       tabindex="-1"
                       @click="cancel"
            >
              <font-awesome-icon :icon="['fal', 'xmark']" size="lg" />
            </AppButton>
          </v-card-title>
          <v-card-subtitle>
            <!-- @slot for sub title content -->
            <slot name="subTitle"></slot>
          </v-card-subtitle>
          <v-card-text>
            <!-- @slot for body content -->
            <slot name="body"></slot>
          </v-card-text>
        </template>
        <v-card-actions v-if="!hideFooter && !$slots.footer"
                        class="pb-5"
        >
          <v-spacer></v-spacer>
          <slot name="footer-infos"></slot>
          <!--
              triggered on click
              @event click
            -->
          <AppButton v-if="!okOnly"
                    color="white"
                    :disabled="cancelDisabled"
                    :loading="cancelLoading"
                    @click="cancel"
          >
            {{ computedCancelText }}
          </AppButton>
          <!--
              triggered on click
              @event click
            -->
          <AppButton v-if="!cancelOnly"
                    :color="okColor"
                    :disabled="okDisabled"
                    :loading="okLoading"
                    @click="ok"
          >
            {{ computedOkText }}
          </AppButton>
        </v-card-actions>
        <v-card-actions v-if="$slots.footer">
          <slot name="footer"></slot>
        </v-card-actions>
      </v-card>
    </slot>
  </v-dialog>
</template>

<script>
import AppDialogStepperTemplate from '@/common/dialogs/AppDialogStepperTemplate'
import AppDialogTabsTemplate from '@/common/dialogs/AppDialogTabsTemplate'

import AppButton from '../buttons/AppButton'

const DIALOG_SIZES = Object.freeze({
  s: '200',
  m: '400',
  l: '600',
  xl: '800',
  xxl: '900',
  xxxl: '1200',
  auto: 'auto',
  'dialog-xs': '480',
  'dialog-sm': '640',
})

// TODO: Actually directly use this here.
export const MODAL_FADE_TRANSITION_TIME = 300

/**
 * Default dialog
 * @displayName Dialog
 */
export default {
  /**
   * All Vuetify props for Dialog components are usable
   * @see See doc : https://vuetifyjs.com/en/api/v-dialog/
   */
  name: 'AppDialog',
  components: { AppDialogStepperTemplate, AppDialogTabsTemplate, AppButton },
  inheritAttrs: false,
  props: {
    /**
     * Set loading state of the cancel button
     */
    cancelLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Removes the ability to click or target the cancel button
     */
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Text displayed inside the cancel button
     */
    cancelText: {
      type: String,
      default: undefined,
    },
    /**
     * @model
     * Visibility state of the dialog
     */
    isOpen: {
      type: Boolean,
      default: false,
    },
    /**
     * Removes the ability to click or target the ok button
     */
    okDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Set loading state of the ok button
     */
    okLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Set color of the ok button
     */
    okColor: {
      type: String,
      default: 'primary',
    },
    /**
     * Ok button will not be displayed if set to true
     */
    cancelOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Cancel button will not be displayed if set to true
     */
    okOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Text displayed inside the ok button
     */
    okText: {
      type: String,
    },
    /**
     * Clicking outside of the element will not deactivate it
     */
    persistent: {
      type: Boolean,
      default: true,
    },
    /**
     * Set width of dialog
     * @values s (200px), m (400px, default), l (600px), xl (800px), auto
     */
    size: {
      type: String,
      validator: value => {
        return Object.keys(DIALOG_SIZES).includes(value)
      },
      default: 'm',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    /**
     * If close button is displayed
     */
    closeButton: {
      type: Boolean,
      default: true,
    },
    /**
     * Enable Stepper template
     */
    stepperDialog: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable Tabs template
     */
    tabsDialog: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable title above v-tabs header
     */
     tabsTemplateHasTitle: {
      type: Boolean,
      default: false,
    },
    /**
     * The designated model value for the component.
     */
    stepperModel: {
      default: undefined,
    },
    /**
     * Set card text background to #f5f5f5
     */
    backgroundGrey: {
      type: Boolean,
      default: false,
    },
    /**
     * Set card header background color
     */
    headerColor: {
      type: String,
      default: 'white',
    },
    /**
     * Set card header with dark prop
     */
    headerDark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activator: {
      get () {
        return this.isOpen
      },
      set (val) {
        this.$emit('update:isOpen', val)
      },
    },
    computedCancelText () {
      return this.cancelText ?? this.$t('common.cancel')
    },
    computedOkText () {
      return this.okText ?? this.$t('common.validate')
    },
    dialogSize () {
      return DIALOG_SIZES[this.size]
    },
  },
  methods: {
    /**
     * Gets called when the user clicks on the cancel button
     * @event cancel
     * @type void
     */
    cancel () {
      if (!this.cancelDisabled) {
        this.$emit('cancel')
      }
    },
    /**
     * Gets called when the user clicks on the cancel button
     * @event ok
     * @type void
     */
    ok () {
      if (!this.okLoading && !this.okDisabled) {
        this.$emit('ok')
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .v-dialog > .v-card {
    &.background-grey {
      background-color: #f5f5f5;
    }
    position: relative;
  }

  .v-dialog > .v-card > .v-card__title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

.v-dialog > .v-card > .v-card__subtitle {
  padding: 10px 24px 20px;
  margin-top: -10px;
}

  .v-dialog > .v-card > .v-card__text {
    padding: 0 24px 12px;
    color: var(--v-grey-darken3);
  }

  .v-dialog > .v-card > .v-card__actions {
    position: sticky;
    z-index: 1;
    bottom: 0;
    width: 100%;
    background-color: #fff;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-dialog > .v-card > .v-card__title {
      padding: 12px 12px 10px;
    }
    .v-dialog > .v-card > .v-card__text {
      padding: 0 12px 20px;
    }
    .v-dialog > .v-card > .v-card__actions {
      padding: 8px 12px;
    }
  }
::v-deep .v-dialog--fullscreen {
  border-radius: 0;
  .closeButton {
    font-size: 20px;
  }
  .v-card__title {
    position: sticky !important;
    top: 0;
    z-index: 6;
    padding-bottom: 24px !important;
  }
}
</style>
