<template>
  <div>
    <AppButton @click="openPane">
      {{ $t('common.share') }}
    </AppButton>
    <Pane v-model="computedPaneIsOpen"
          :tabs="tabs"
          temporary
          :current-tab="currentTab"
    >
      <template #content-share>
        <v-row>
          <v-col>
            <DocumentSharePicker v-if="paneIsOpen && !bibleRightsPending"
                                 :force-pm-selection="false"
                                 users-only
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <VisualTextEditor v-model="customMessage"
                              counter="footer"
                              :error-messages="customMessageErrors"
                              :max-length="customMessageMaxLength"
                              :placeholder="$t('common.optionalMessageWithCopy')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <AppButton block
                       outlined
                       color="primary"
                       :disabled="loading"
                       @click="closePane"
            >
              {{ $t('common.cancel') }}
            </AppButton>
          </v-col>
          <v-col>
            <AppButton color="primary"
                       block
                       :loading="loading"
                       :disabled="shareDisabled"
                       @click="preparePostBibleRights"
            >
              {{ $t('common.share') }}
            </AppButton>
          </v-col>
        </v-row>
      </template>
    </Pane>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { surveyCanBeTriggered, SurveyId, triggerSurvey } from '@/plugins/satismeter'
import { GET_BIBLE } from '@/store/modules/bibles/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import AppButton from '../../common/buttons/AppButton'
import DocumentSharePicker from '../../common/document-share/DocumentSharePicker'
import Pane from '../../common/Pane'
import { escapeHtml } from '../../common/utils/strings'
import VisualTextEditor from '../../common/visual-text-editor/VisualTextEditor'
import {
  DISABLE_MEMBERS,
  INIT_SELECTED_GROUPS,
  INIT_SELECTED_MEMBERS,
} from '../../store/modules/document-share-picker/action_types'

const CUSTOM_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'BiblesSharePane',
  components: { VisualTextEditor, DocumentSharePicker, Pane, AppButton },
  props: {
    bible: {
      type: Object,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      paneIsOpen: false,
      tabs: [
        {
          title: this.$t('common.share'),
          id: 'share',
        },
      ],
      currentTab: 'share',
      customMessage: '',
      customMessageMaxLength: CUSTOM_MESSAGE_MAX_LENGTH,
      loading: false,
    }
  },
  computed: {
    ...mapState('documentSharePicker', ['selectedMembers']),
    ...mapState('groups', ['groups']),
    ...mapState('bibles', ['biblePendingIds']),
    ...mapState('user', ['profile']),
    computedPaneIsOpen: {
      get () {
        return this.paneIsOpen
      },
      set (value) {
        value
          ? this.openPane()
          : this.closePane()
      },
    },
    customMessageLength () {
      return escapeHtml(this.customMessage).length
    },
    customMessageIsTooLong () {
      return this.customMessageLength > this.customMessageMaxLength
    },
    customMessageErrors () {
      const errors = []

      if (this.customMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    shareDisabled () {
      return this.customMessageIsTooLong
    },
    bibleRightsPending () {
      return this.biblePendingIds.includes(this.bible.id)
    },
  },
  created () {
    this.GET_GROUPS(this.mnemo)
  },
  methods: {
    ...mapActions('documentSharePicker', [INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS, DISABLE_MEMBERS]),
    ...mapActions('bibles', ['POST_BIBLE_RIGHTS', GET_BIBLE]),
    ...mapActions('groups', ['GET_GROUPS']),
    openPane () {
      this.INIT_SELECTED_GROUPS([])
      this.INIT_SELECTED_MEMBERS([])
      this.initBibleRights()
      this.paneIsOpen = true
    },
    async initBibleRights () {
      try {
        const response = await this.GET_BIBLE({
          mnemo: this.mnemo,
          bibleId: this.bible.id,
        })

        if (response.data.rights?.read?.users.length > 0 && this.groups.length > 0) {
          const membersToDisable = this.groups.flatMap(group => group.members)
            .filter(member => response.data.rights.read.users.some(o => o === member.id))

          if (membersToDisable.length) {
            this.DISABLE_MEMBERS(membersToDisable)
          }
        }
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.bibles.BiblesSharePane.getBibleRightsError'),
        })
      }
    },
    closePane () {
      this.paneIsOpen = false
      this.customMessage = ''
      this.loading = false
    },
    async preparePostBibleRights () {
      try {
        this.loading = true
        await this.POST_BIBLE_RIGHTS({
          mnemo: this.mnemo,
          bibleId: this.bible.id,
          data: {
            withUsers: this.selectedMembers.map(o => o.id),
            notifyMessage: this.customMessage,
          },
        })
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.$t('project.bibles.BiblesSharePane.postBibleRightsSuccess'),
        })
        this.$emit('share-success')

        const { id: userId, fullName, email, isSubUser } = this.profile
        if (surveyCanBeTriggered(SurveyId.CSAT, userId)) {
          triggerSurvey(SurveyId.CSAT, userId, fullName, email, isSubUser)
        }
        if (surveyCanBeTriggered(SurveyId.NPS, userId)) {
          triggerSurvey(SurveyId.NPS, userId, fullName, email, isSubUser)
        }
      } catch (e) {
        this.$store.commit('enqueueSnackbar', {
          color: 'error',
          message: this.$t('project.bibles.BiblesSharePane.postBibleRightsError'),
        })
      } finally {
        this.closePane()
      }
    },
  },
}
</script>
