<template>
  <app-alert-in-page :variant="computedAlertVariant" class="my-4">
    <template>
      <app-text variant="small-bold" class="mb-0">
        {{ $t(`project.documents.DocumentsStorageExceededAlert.alertContent.${computedAlertMessageKey}`) }}
      </app-text>
    </template>
    <template #button>
      <template v-if="isCurrentRoomOnAPlan">
        <app-button-alert v-if="!isSubAdmin" :type="computedAlertVariant" :loading="notifyPmPlanDRFullPending" @click="notifyAdmin">
          {{ $t('project.documents.DocumentsStorageExceededAlert.alertContent.plan.action') }}
        </app-button-alert>
      </template>
      <template v-else>
        <app-button-alert v-if="isPmOfRoomSubscription" :type="computedAlertVariant" :to="{ name: ROOM_SETTINGS_ROUTE_NAME, query: { show: 'upgradeDialog' } }">
          {{ $t('project.documents.DocumentsStorageExceededAlert.alertCtaLabel')}}
        </app-button-alert>
        <app-button-alert v-if="!isPmOfRoomSubscription && computedAlertVariant === 'error'" :type="computedAlertVariant" @click="notifyPms">
          {{ $t(`common.app-file-upload.RoomStorageExceededDialog.dialogOkLabel.isGuest`)}}
        </app-button-alert>
      </template>
    </template>
  </app-alert-in-page>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import { ROOM_TYPES } from '@/common/utils/roomTypes'
  import { ROOM_SETTINGS_ROUTE_NAME } from '@/router'
  import { NOTIFY_PM_NO_MORE_SPACE, NOTIFY_PM_PLAN_DATAROOM_FULL } from '@/store/modules/room/action_types'
  import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

  export default defineComponent({
    name: 'DocumentsStorageExceededAlert',
    data () {
      return {
        ROOM_SETTINGS_ROUTE_NAME,
      }
    },
    computed: {
      ...mapState('room', ['notifyPmPlanDRFullPending', 'currentRoom']),
      ...mapGetters('room', ['storagePercent', 'roomMnemo', 'isPmOfRoomSubscription']),
      ...mapGetters('user', ['isSubAdmin']),
      isCurrentRoomOnAPlan () : boolean {
        return this.currentRoom.type === ROOM_TYPES.DATAROOM_UNLIMITED
      },
      computedAlertVariant () : 'error' | 'warning' {
        // We're only shown when exceeding 100% in that case
        if (this.isCurrentRoomOnAPlan) {
          return 'error'
        } else {
          return this.storagePercent >= 100 ? 'error' : 'warning'
        }
      },
      computedAlertMessageKey () : 'plan.error' | 'error' | 'warning' {
        if (this.isCurrentRoomOnAPlan) {
          return 'plan.error'
        } else {
          return this.storagePercent >= 100 ? 'error' : 'warning'
        }
      },
    },
    methods: {
      ...mapActions('room', [NOTIFY_PM_NO_MORE_SPACE, NOTIFY_PM_PLAN_DATAROOM_FULL]),
      ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
      async notifyPms () {
        try {
          await this.NOTIFY_PM_NO_MORE_SPACE({ mnemo: this.roomMnemo })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyPmSuccess'))
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyPmError'))
        }
      },
      async notifyAdmin () {
        try {
          await this.NOTIFY_PM_PLAN_DATAROOM_FULL({ mnemo: this.roomMnemo })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyAdminSuccess'))
        } catch (e) {
          console.error(e)
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyAdminError'))
        }
      },
    },
  })
</script>
