<template>
    <div class="CerteuropeSignTag"
         :style="{left: `${tag.x * ratio}px`, top: `${tag.y * ratio}px`, transform: `scale(${ratio})`, transformOrigin: 'top left'}"
    >
      <!--   INITIALS   -->
      <div class="position-relative">
        <div :id="`tag-${tag.id}`" style="position:absolute;top:-400px"></div>
      </div>
      <template v-if="tag.type === tagsTypes.INITIAL">
        <template v-if="tag.isSigned">
          <div :class="`CerteuropeSignTag-signed--${tag.type}`"
               @click="prepareUnsignTag"
          >
            <span v-if="!unsignPending">{{ tag.signed_data }}</span>
            <v-progress-circular v-if="unsignPending"
                                 color="primary"
                                 :size="20"
                                 :width="2"
                                 indeterminate
            />
          </div>
        </template>
        <template v-else>
          <AppButton color="primary"
                     class="CerteuropeSignTag-button btn-allow-wrap caption"
                     :class="[`CerteuropeSignTag-button--${tag.type}`, { 'CerteuropeSignTag-button--selected': tagIsSelected }]"
                     :loading="signPending"
                     @click="prepareSignTag"
          >
            <div>{{ $t(`certeurope-sign.CerteuropeSignTag.tag.${tag.type}`) }}</div>
            <div>
              <font-awesome-icon :icon="['fal', 'file-signature']"></font-awesome-icon>
            </div>
          </AppButton>
        </template>
      </template>
      <!--   SIGN   -->
      <template v-if="tag.type === tagsTypes.SIGN">
        <template v-if="tag.isSigned">
          <div :class="`CerteuropeSignTag-signed--${tag.type}`"
               @click="prepareUnsignTag"
          >
            <span v-if="!unsignPending">{{ tag.signed_data }}</span>

            <v-progress-circular v-if="unsignPending"
                                 color="primary"
                                 :size="20"
                                 :width="2"
                                 indeterminate
            />
          </div>
        </template>
        <template v-else>
          <AppButton color="primary"
                     class="CerteuropeSignTag-button"
                     :class="[`CerteuropeSignTag-button--${tag.type}`, { 'CerteuropeSignTag-button--selected': tagIsSelected }]"
                     :loading="signPending"
                     @click="prepareSignTag"
          >
            <font-awesome-icon :icon="['fal', 'file-signature']"
                               class="mr-2"
            ></font-awesome-icon>
            {{ $t(`certeurope-sign.CerteuropeSignTag.tag.${tag.type}`) }}
          </AppButton>
        </template>
      </template>
      <!--   NAME   -->
      <template v-if="tag.type === tagsTypes.NAME">
        <template v-if="tag.isSigned">
          <div :class="`CerteuropeSignTag-signed--${tag.type}`"
               @click="prepareUnsignTag"
          >
            <span v-if="!unsignPending">{{ tag.signed_data }}</span>
            <v-progress-circular v-if="unsignPending"
                                 color="primary"
                                 :size="20"
                                 :width="2"
                                 indeterminate
            />
          </div>
        </template>
        <template v-else>
          <AppButton color="primary"
                     class="CerteuropeSignTag-button"
                     :class="[`CerteuropeSignTag-button--${tag.type}`, { 'CerteuropeSignTag-button--selected': tagIsSelected }]"
                     :x-padding="1"
                     height="auto"
                     :loading="signPending"
                     @click="prepareSignTag"
          >
            {{ profile.fullName }}
          </AppButton>
        </template>
      </template>
      <!--   DATE   -->
      <template v-if="tag.type === tagsTypes.DATE">
        <div class="CerteuropeSignTag-dateTag">
          {{ dateFormat }}
        </div>
      </template>
      <!--   CERTIFIED   -->
      <template v-if="tag.type === tagsTypes.CERTIFIED">
        <template v-if="tag.isSigned">
          <div :class="`CerteuropeSignTag-signed--${tag.type}`"
               @click="prepareUnsignTag"
          >
            <span v-if="!unsignPending">{{ tag.signed_data }}</span>
            <v-progress-circular v-if="unsignPending"
                                 color="primary"
                                 :size="20"
                                 :width="2"
                                 indeterminate
            />
          </div>
        </template>
        <template v-else>
          <AppButton color="primary"
                     class="CerteuropeSignTag-button"
                     :class="[`CerteuropeSignTag-button--${tag.type}`, { 'CerteuropeSignTag-button--selected': tagIsSelected }]"
                     :loading="signPending"
                     @click="prepareSignTag"
          >
            <font-awesome-icon :icon="['fal', 'certificate']"
                               class="mr-2"
            ></font-awesome-icon>
            {{ $t(`certeurope-sign.CerteuropeSignTag.tag.${tag.type}`) }}
          </AppButton>
        </template>
      </template>
      <!--   TEXT   -->
      <template v-if="tag.type === tagsTypes.TEXT">
        <AppTooltip top>
          <template #activator="{on, attrs}">
            <v-textarea :value="textTagValue || tag.signed_data"
                        outlined
                        color="blue darken-2"
                        class="CerteuropeSignTag-textTag"
                        hide-details
                        :placeholder="$t('certeurope-sign.CerteuropeSignTag.textTagPlaceholder')"
                        row-height="17"
                        :rows="tag.height / 17"
                        no-resize
                        :style="{'width': `${tag.width}px`}"
                        v-bind="attrs"
                        v-on="on"
                        @input="textTagValue = $event"
                        @blur="prepareSignOrUnsignTextTag"
            />
          </template>
          <span>{{ textTagTooltip }}</span>
        </AppTooltip>
      </template>
      <!--   TEXT READ ONLY  -->
      <template v-if="tag.type === tagsTypes.TEXT_READ_ONLY">
        <div class="CerteuropeSignTag-textReadOnlyTag" :style="`height:${tag.height}px; width:${tag.width}px`">
          {{ tag.data }}
        </div>
      </template>
      <!--   CHECKBOX   -->
      <template v-if="tag.type === tagsTypes.CHECKBOX">
        <AppTooltip left>
          <template #activator="{attrs, on}">
            <div v-bind="attrs"
                 v-on="on"
            >
              <v-checkbox v-model="isChecked"
                          :disabled="signPending || unsignPending"
                          color="#bf1e2e"
                          hide-details
                          dense
                          class="CerteuropeSignTag-checkboxTag CerteuropeSignTag-checkboxTag--required mt-0 pt-0"
                          :ripple="false"
              />
            </div>
          </template>
          <span>{{ $t('certeurope-sign.CerteuropeSignTag.required') }}</span>
        </AppTooltip>
      </template>
      <template v-if="tag.type === tagsTypes.OPTIONAL_CHECKBOX">
        <AppTooltip left>
          <template #activator="{attrs, on}">
            <div v-bind="attrs"
                 v-on="on"
            >
              <v-checkbox v-model="isChecked"
                          :disabled="signPending || unsignPending"
                          hide-details
                          dense
                          class="CerteuropeSignTag-checkboxTag mt-0 pt-0"
                          :ripple="false"
              />
            </div>
          </template>
          <span>{{ $t('certeurope-sign.CerteuropeSignTag.optional') }}</span>
        </AppTooltip>
      </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AppTooltip from '../common/AppTooltip'
import AppButton from '../common/buttons/AppButton'
import { tagsTypes } from '../common/prepare-signature/tagsTypes'
import { dateTag } from '../common/utils/dates'
import { SIGN_TAG, UNSIGN_TAG } from '../store/modules/certeurope-sign/action_types'
import { ENQUEUE_SNACKBAR } from '../store/mutation_types'

export default {
  name: 'CerteuropeSignTag',
  components: {
    AppButton,
    AppTooltip,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    tag: {
      type: Object,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
    selectedTag: {
      required: true,
    },
    ratio: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      tagsTypes,
      textTagValue: '',
      previousTextTagValue: null,
    }
  },
  computed: {
    ...mapState('certeuropeSign', ['signingView', 'signTagPendingIds', 'unsignTagPendingIds']),
    ...mapState('user', ['profile']),
    isChecked: {
      get () { return this.tag.isSigned },
      set (value) {
        value ? this.prepareSignTag() : this.prepareUnsignTag()
      },
    },
    tagIsSelected () {
      if (this.selectedTag) {
        return this.selectedTag.id === this.tag.id
      }
      return null
    },
    signPending () {
      return this.signTagPendingIds.includes(this.tag.id)
    },
    unsignPending () {
      return this.unsignTagPendingIds.includes(this.tag.id)
    },
    dateFormat () {
      if (this.tag.type === tagsTypes.DATE) {
        return dateTag(Date.now())
      }
      return null
    },
    textTagTooltip () {
      if (this.tag.type === tagsTypes.TEXT) {
        if (this.tag.data) {
          return this.$t('certeurope-sign.CerteuropeSignTag.textTagTooltipWithData', { data: this.tag.data })
        } else {
          return this.$t('certeurope-sign.CerteuropeSignTag.textTagTooltip')
        }
      }
      return null
    },
    flattenTags () {
      const array = []
      for (const page of this.signingView.pages) {
        array.push(...page.tags)
      }
      return array
    },
    tagsToSign () {
      return this.flattenTags.filter(tag => tag.isSigned === false)
    },
  },
  methods: {
    ...mapActions('certeuropeSign', [SIGN_TAG, UNSIGN_TAG]),
    async prepareSignOrUnsignTextTag () {
      if (this.previousTextTagValue === null || this.textTagValue !== this.previousTextTagValue) {
        if (this.textTagValue.trim().length === 0) {
          await this.prepareUnsignTag()
        } else {
          await this.prepareSignTag()
        }
        this.previousTextTagValue = this.textTagValue
      }
    },
    async prepareSignTag () {
      try {
        let data = null
        if (this.tag.type === this.tagsTypes.TEXT) {
          data = { custom_text: this.textTagValue }
        }
        await this.SIGN_TAG({
          mnemo: this.mnemo,
          envelopeId: this.signingView.id,
          tagId: this.tag.id,
          data,
          page: this.page,
        })
        this.$nextTick(() => {
          this.goToNextTag()
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('certeurope-sign.CerteuropeSignTag.signTagSuccess'),
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('certeurope-sign.CerteuropeSignTag.signTagError'),
        })
      }
    },
    async prepareUnsignTag () {
      try {
        await this.UNSIGN_TAG({
          mnemo: this.mnemo,
          envelopeId: this.signingView.id,
          tagId: this.tag.id,
          page: this.page,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('certeurope-sign.CerteuropeSignTag.unsignTagSuccess'),
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('certeurope-sign.CerteuropeSignTag.unsignTagError'),
        })
      }
    },
    goToNextTag () {
      if (this.tagsToSign.length === 0) return
      if (!this.selectedTag) {
        this.$emit('updateSelectedTag', this.tagsToSign[0])
      } else {
        const nextTag = this.tagsToSign.findIndex(t => t.id === this.selectedTag.id) + 1
        if (nextTag >= this.tagsToSign.length) {
          this.$emit('updateSelectedTag', this.tagsToSign[0])
        } else {
          this.$emit('updateSelectedTag', this.tagsToSign[nextTag])
        }
      }
      this.$nextTick(() => {
        if (this.selectedTag?.id) {
          const tag = document.querySelector(`#tag-${this.selectedTag.id}`)
          if (tag) {
            tag.scrollIntoView({
              behavior: 'smooth',
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: "Comili";
  src: url("/public/fonts/Comili-Book.otf");
}

.CerteuropeSignTag {
  position: absolute;
}
.CerteuropeSignTag-button--selected {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
  0 3px 5px -1px rgba(0, 0, 0, 0.3);
}
::v-deep .CerteuropeSignTag-button {
  &--initialHere {
    padding: 6px !important;
  }
  &--fullName {
    font-family: "Times New Roman", serif;
    font-size: 12pt;
  }
  .v-btn__content {
    display: block;
  }
}
.CerteuropeSignTag-dateTag {
  font-family: "Times New Roman", serif;
  font-size: 12pt;
}
::v-deep .CerteuropeSignTag-textTag {
  font-family: "EB Garamond", serif;
  font-style: italic;
  font-size: 11pt;
  background-color: #fff;
  .v-input__slot {
    padding: 0 !important;
    min-height: 19px;
  }
  .v-text-field__slot {
    margin: 0 !important;
  }
  fieldset {
    padding: 0;
  }
  textarea {
    padding: 4px 5px 2px !important;
    margin: 0 !important;
    align-self: auto;
    line-height: 19px;
    min-height: 19px;
  }
}
::v-deep .CerteuropeSignTag-textReadOnlyTag {
  font-size: 11.5pt !important;
  font-style: italic;
  line-height: 17px;
  padding: 4px 5px 2px !important;
  white-space: pre-wrap;
  word-break: break-all;
}
::v-deep .CerteuropeSignTag-checkboxTag {
  .v-input--selection-controls__input {
    width: 17px;
    height: 17px;
    margin-right: 0;
    .v-icon {
      font-size: 13px;
    }
  }
  &--required {
    .v-icon {
      color: #bf1e2e;
    }
  }
}
.CerteuropeSignTag-signed {
  &--initialHere {
    width: 60px;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Comili", sans-serif;
    font-size: 11pt;
    cursor: pointer;
    &:hover {
      color: var(--v-primary-base);
    }
  }
  &--fullName {
    font-family: "Times New Roman", serif;
    font-size: 12pt;
    cursor: pointer;
    &:hover {
      color: var(--v-primary-base);
    }
  }
  &--certifiedField {
    font-family: "EB Garamond", serif;
    font-size: 11pt;
    font-style: italic;
    cursor: pointer;
    &:hover {
      color: var(--v-primary-base);
    }
  }
  &--signHere {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16pt;
    height: 49px;
    min-width: 122px;
    cursor: pointer;
    font-family: "Comili", sans-serif;
    white-space: nowrap;
    &:hover {
      color: var(--v-primary-base);
    }
  }
}
</style>
