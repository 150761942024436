<template>
<div class="ChecklistTableStatusCategory">
  <div class="text--primary font-weight-medium py-2">
    {{ $t(`common.statusCategoryNames.${category.name}`) }}
  </div>

  <div class="ChecklistTableStatusCategory__statusContainer">
    <AppDraggable :list="category.statuses"
                  handle=".ChecklistTableStatusCategory__grabIcon"
                  class="ChecklistTableStatusCategory__draggableArea"
    >
      <template #content>
        <div v-for="status in category.statuses"
             :key="`${category.name}-status-${status.label}`"
             class="ChecklistTableStatusCategory__statusFlexContainer"
        >
            <div v-if="editMode && status.isEditable" class="ChecklistTableStatusCategory__grabIcon pr-2">
              <font-awesome-icon :icon="['fas', 'grip-vertical']" />
            </div>

            <div style="min-width: 0">
              <AppTooltip top :disabled="status.label.length <= statusLabelMaxDisplayedLength">
                <template #activator="{attrs, on}">
                  <v-chip active-class="ChecklistTableStatusCategory__status--active"
                          class="ChecklistTableStatusCategory__status font-weight-medium white--text px-4"
                          :color="status.color || statusCategoryColor"
                          :disabled="!category.areStatusesSelectable"
                          :input-value="status.id === selectedStatusId"
                          v-bind="attrs"
                          @click="category.areStatusesSelectable && onStatusClick(status.id)"
                          v-on="on"
                  >
                    {{ status.label }}
                  </v-chip>
                </template>
                <span>{{ status.label }}</span>
              </AppTooltip>
            </div>

            <div v-if="editMode && status.isEditable" class="ChecklistTableStatusCategory__edition-buttons ml-2">
              <AppTooltip top>
                <template #activator="{attrs, on}">
                  <AppButton icon
                             :width="32"
                             :height="32"
                             v-bind="attrs"
                             v-on="on"
                             @click="() => onStatusDelete(status)"
                  >
                    <font-awesome-icon class="greyDark--text text--lighten-1" :icon="['fas', 'trash']"></font-awesome-icon>
                  </AppButton>
                </template>
                <span>{{ $t('project.checklist.task.ChecklistTableStatusCategory.deleteStatusTooltip') }}</span>
              </AppTooltip>

              <ChecklistTableStatusEditMenu :status="status"
                                            :category="category.name"
                                            @edit="onStatusEdit"
              />
            </div>
        </div>
      </template>
    </AppDraggable>
  </div>

  <div v-if="!editMode && currentUserCanCreateStatus" class="mt-5 mr-3">
    <ChecklistTableStatusNewStatus :category="category.name" />
  </div>

  <ChecklistTableStatusDeleteDialog v-if="statusToDelete"
                                    :status="statusToDelete"
                                    @confirm="onConfirmStatusDeletion"
                                    @close="onCancelStatusDeletion"
  />
</div>
</template>

<script>
import { mapGetters } from 'vuex'

import ChecklistTableStatusDeleteDialog from './ChecklistTableStatusDeleteDialog.vue'
import ChecklistTableStatusEditMenu from './ChecklistTableStatusEditMenu'
import ChecklistTableStatusNewStatus from './ChecklistTableStatusNewStatus'
import { statusCategoryColors } from './taskStatus'
import AppDraggable from '../../../common/AppDraggable'
import AppTooltip from '../../../common/AppTooltip'
import AppButton from '../../../common/buttons/AppButton'

const STATUS_LABEL_MAX_DISPLAYED_LENGTH_IN_EDITION = 18
const STATUS_LABEL_MAX_DISPLAYED_LENGTH_IN_READONLY = 33

export default {
  name: 'ChecklistTableStatusCategory',
  components: {
    AppButton,
    AppDraggable,
    AppTooltip,
    ChecklistTableStatusDeleteDialog,
    ChecklistTableStatusEditMenu,
    ChecklistTableStatusNewStatus,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    selectedStatusId: {
      type: Number,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      statusToDelete: null,
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm']),
    statusCategoryColor () {
      return statusCategoryColors[this.category.name]
    },
    currentUserCanCreateStatus () {
      return this.isCurrentUserPm
    },
    statusLabelMaxDisplayedLength () {
      return this.editMode
        ? STATUS_LABEL_MAX_DISPLAYED_LENGTH_IN_EDITION
        : STATUS_LABEL_MAX_DISPLAYED_LENGTH_IN_READONLY
    },
  },
  methods: {
    onStatusClick (statusId) {
      this.$emit('update:selectedStatusId', statusId)
    },
    onStatusEdit (editedStatus) {
      this.$emit('status-edit', { editedCategory: this.category.name, editedStatus })
    },
    onStatusDelete (statusToDelete) {
      this.statusToDelete = statusToDelete
    },
    onConfirmStatusDeletion () {
      this.$emit('status-delete', { status: this.statusToDelete, category: this.category.name })
      this.statusToDelete = null
    },
    onCancelStatusDeletion () {
      this.statusToDelete = null
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableStatusCategory {
  display: flex;
  flex-direction: column;
  width: 280px;
  padding: 12px;
  padding-right: 0;
  border: 1px solid #eee;
  border-radius: 6px;

  &__statusContainer {
    display: flex;
    overflow-x: hidden;
    flex: 1;
  }
  &__statusFlexContainer {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 32px;

    &:last-child {
      margin-bottom: 80px;
    }
  }
  &__draggableArea {
    overflow-y: auto;
    width: 100%;
    padding-right: 12px;
  }
  &__grabIcon {
    font-size: 16px;
    color: var(--v-greyDark-lighten1);
    cursor: grab;
  }
  &__status {
    height: auto;
    min-height: 32px;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    border: 2px solid transparent;

    &--active {
      border-width: 4px;
      border-color: var(--v-tertiary-base) !important;
    }

    ::v-deep .v-chip__content {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
  &__edition-buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
