<template>
  <div>
    <v-row>
      <v-col>
        <p class="mt-3 mb-0 body-1 accent--text text--lighten-2">
          {{ $t('project.question-answer.QuestionAnswerListHeader.subTitle') }}
        </p>
      </v-col>
    </v-row>
    <div class="KPIs-container mt-5">
      <div class="KPIs-item">
        <div>
          <v-progress-circular v-if="questionsPending"
                               indeterminate
                               color="primary"
                               class="KPIs-numberLoader"
                               size="40"
          />
          <div v-else
               class="KPIs-number"
          >
            {{ counterAllQuestions }}
          </div>
        </div>
        <div class="KPIs-text">{{ $t('project.question-answer.QuestionAnswerListHeader.totalQuestionsCount') }}</div>
      </div>
      <div class="KPIs-item">
        <div>
          <v-progress-circular v-if="questionsPending"
                               indeterminate
                               color="primary"
                               class="KPIs-numberLoader"
                               size="40"
          />
          <div v-else
               class="KPIs-number"
          >
            {{ counterAwaitingQuestions }}
          </div>
        </div>
        <div class="KPIs-text">
          <QuestionAnswerStatusIcon :status="QA_STATUS.TO_ANSWER" :useTooltip="false" />
          {{ $t('project.question-answer.QuestionAnswerListHeader.pendingQuestionsCount') }}
        </div>
      </div>
      <div class="KPIs-item">
        <div>
          <v-progress-circular v-if="questionsPending"
                               indeterminate
                               color="primary"
                               class="KPIs-numberLoader"
                               size="40"
          />
          <div v-else
               class="KPIs-number"
          >
            {{ counterAwaitingAnswers }}
          </div>
        </div>
        <div class="KPIs-text">
          <QuestionAnswerStatusIcon :status="QA_STATUS.TO_VALIDATE" :useTooltip="false" />
          {{ $t('project.question-answer.QuestionAnswerListHeader.pendingAnswersCount') }}
        </div>
      </div>
      <div class="KPIs-item">
        <div>
          <v-progress-circular v-if="questionsPending"
                               indeterminate
                               color="primary"
                               class="KPIs-numberLoader"
                               size="40"
          />
          <div v-else
               class="KPIs-number"
          >
            {{ counterValidatedQuestions }}
          </div>
        </div>
        <div class="KPIs-text">
          <QuestionAnswerStatusIcon :status="QA_STATUS.VALIDATED" :useTooltip="false" />
          {{ $t('project.question-answer.QuestionAnswerListHeader.answeredQuestionsCount') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { QA_STATUS } from '@/common/utils/qa-status'
import QuestionAnswerStatusIcon from '@/project/question-answer/QuestionAnswerStatusIcon'

export default {
  name: 'QuestionAnswerListHeader',
  components: { QuestionAnswerStatusIcon },
  data () {
    return {
      QA_STATUS,
    }
  },
  computed: {
    ...mapState('questionAnswer', ['questionsPending']),
    ...mapGetters('questionAnswer', [
      'counterAllQuestions',
      'counterAwaitingQuestions',
      'counterValidatedQuestions',
      'counterAwaitingAnswers',
    ]),
  },
}
</script>

<style scoped lang="scss">
.KPIs {
  &-container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &-item {
    flex: 1;
    padding: 24px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(51, 51, 51, 0.2);
    text-align: center;
  }

  &-number {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 22px;
  }

  &-numberLoader {
    margin-bottom: 32px;
    margin-top: 10px;
  }

  &-text {
    font-size: 16px;
    font-weight: 500;
    color: var(--v-accent-lighten2);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
  }
}
</style>
