import {
  faXmark,
  faChevronRight,
  faFileExcel,
  faFileInvoice,
  faFileUpload,
  faFileImport,
  faSlidersV,
  faUserFriends,
  faFolderOpen,
  faTasks,
  faFileSignature,
  faBook,
  faMagnifyingGlass,
  faTrashCan,
  faCalendarAlt,
  faHourglassStart,
  faUpload,
  faChevronDown,
  faFolder,
  faChevronUp,
  faFileMagnifyingGlass,
  faFileDownload,
  faArrowDownToLine,
  faShare,
  faPlus,
  faPaperPlane,
  faFilePdf,
  faAlarmClock,
  faPaperclip,
  faClipboardListCheck,
  faBullseyeArrow,
  faCertificate,
  faFileExport,
  faCreditCard,
  faUsers,
  faFilePlus,
  faArrows,
  faCircleCheck,
  faCircleXmark,
  faFileWord,
  faSquare,
  faEnvelope,
  faFolderUpload,
  faQuestionSquare,
  faPencil,
  faClock,
  faCommentsAlt,
  faUserShield,
  faTrash,
  faPen,
  faArchive,
  faCircleQuestion,
  faExternalLink,
  faHashtag,
} from '@fortawesome/pro-light-svg-icons'

export const lightLibrary = {
  faXmark,
  faChevronRight,
  faFileExcel,
  faFileInvoice,
  faFileUpload,
  faFileImport,
  faSlidersV,
  faUserFriends,
  faFolderOpen,
  faTasks,
  faFileSignature,
  faBook,
  faMagnifyingGlass,
  faTrashCan,
  faCalendarAlt,
  faHourglassStart,
  faUpload,
  faChevronDown,
  faFolder,
  faChevronUp,
  faFileMagnifyingGlass,
  faFileDownload,
  faArrowDownToLine,
  faShare,
  faPlus,
  faPaperPlane,
  faAlarmClock,
  faPaperclip,
  faFilePdf,
  faClipboardListCheck,
  faBullseyeArrow,
  faCertificate,
  faFileExport,
  faCreditCard,
  faUsers,
  faFilePlus,
  faArrows,
  faCircleCheck,
  faCircleXmark,
  faFileWord,
  faSquare,
  faEnvelope,
  faFolderUpload,
  faQuestionSquare,
  faPencil,
  faClock,
  faCommentsAlt,
  faUserShield,
  faTrash,
  faPen,
  faArchive,
  faCircleQuestion,
  faExternalLink,
  faHashtag,
}
