import {
  SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN,
  SET_PROJECTS_FOLDER_DELETE_PENDING,
  SET_PROJECTS_FOLDER_TO_DELETE,
} from './mutation_types'

export const mutations = {
  [SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN] (state, value) {
    state.projectsFolderDeleteDialogIsOpen = value
  },
  [SET_PROJECTS_FOLDER_DELETE_PENDING] (state, value) {
    state.projectsFolderDeletePending = value
  },
  [SET_PROJECTS_FOLDER_TO_DELETE] (state, projectsFolderToDelete) {
    state.projectsFolderToDelete = projectsFolderToDelete
  },
}
