<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('common.delete')"
             :ok-loading="deleteSavedChecklistPending"
             :ok-disabled="deleteSavedChecklistPending"
             :cancel-disabled="deleteSavedChecklistPending"
             @ok="prepareDeleteSavedChecklist"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('project.checklist.dialogs.SavedChecklistDeleteDialog.title') }}</template>
    <template #body>{{ $t('project.checklist.dialogs.SavedChecklistDeleteDialog.text') }}</template>
  </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { dialogMixin } from '../../../mixins/dialogMixin'
import { DELETE_SAVED_CHECKLIST } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'SavedChecklistDeleteDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    checklistId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('checklist', ['savedChecklists', 'deleteSavedChecklistPending']),
  },
  methods: {
    ...mapActions('checklist', [DELETE_SAVED_CHECKLIST]),
    async prepareDeleteSavedChecklist () {
      try {
        await this.DELETE_SAVED_CHECKLIST({
          todoListId: this.checklistId,
        })
        const index = this.savedChecklists.findIndex(item => item.id === this.checklistId)
        if (index !== -1) {
          this.savedChecklists.splice(index, 1)
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.checklist.dialogs.SavedChecklistDeleteDialog.deleteSavedChecklistSuccess'),
          })
        }
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.dialogs.SavedChecklistDeleteDialog.deleteSavedChecklistError'),
        })
      } finally {
        this.closeDialog()
      }
    },
  },
}
</script>
