<template>
  <div>
    <div v-if="signatoriesCount === 1">
      <ProjectSettingsSignaturesSignatory :item="item" :signatory="signatoriesList[0]"/>
    </div>
    <span v-else-if="signatoriesCount > 1" class="d-flex align-center">
      <app-icon v-if="allSignatorySigned" icon-name="clock" icon-weight="far" size="small" class="tertiary--text mr-1"/>
      <app-icon v-else  icon-name="circle-check" icon-weight="fas" class="success--text" size="small mr-1"/>
      {{ signatoriesList[0].fullName }}
      {{ $tc('project.project-settings.signatures.ProjectSettingsSignaturesSignatoriesList.otherSignatories', signatoriesCount - 1, { count: signatoriesCount - 1 }) }}
    </span>
    <CollapseTransition v-if="signatoriesCount > 1">
      <div v-if="expanded">
        <div v-if="signatoriesCount > 1" class="mt-2 caption font-weight-bold" style="color: #616161">{{$t('project.project-settings.signatures.ProjectSettingsSignaturesSignatoriesList.signatoriesListTitle')}}</div>
        <div v-for="(signatory, i) in signatoriesList" :key="`signatory-${i}`">
          <ProjectSettingsSignaturesSignatory :item="item" :signatory="signatory"/>
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'

import ProjectSettingsSignaturesSignatory from './ProjectSettingsSignaturesSignatory.vue'

export default {
  name: 'ProjectSettingsSignaturesSignatoriesList',
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProjectSettingsSignaturesSignatory,
    CollapseTransition,
  },
  computed: {
    signatoriesList () {
      if (this.item.envelopeType === 'single') { return this.item.signatories.filter(s => s.signatureType !== 'witness') }

      return [...this.signatoriesCommonList, ...this.signatoriesUniqueList]
    },
    signatoriesCommonList () {
      return this.item.signatories.filter(signatory => !signatory.signers && signatory.signatureType !== 'witness')
    },
    signatoriesCount () {
      return this.signatoriesUniqueList.length + this.signatoriesCommonList.length
    },
    signatoriesUniqueList () {
      return this.item.signatories.find(signatory => signatory.signers)?.signers || []
    },
    allSignatorySigned () {
      return this.signatoriesList.filter(signatory => signatory.status !== 'signed').length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
.ProjectSettingsSignatures-listDot {
  background-color: var(--v-accent-base);
  border-radius: 50%;
  display: inline-block;
  height: 5px;
  margin-right: 5px;
  vertical-align: middle;
  width: 5px;
}
</style>
