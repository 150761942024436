<template>
  <Pane v-model="pane.isOpen"
        :current-tab="pane.tab"
        eager
        :tabs="tabs"
        temporary
  >
    <template v-if="pane.isOpen" #content-share-email>
      <ChecklistShareByEmailPane />
    </template>
  </Pane>
</template>

<script>
import { mapState } from 'vuex'

import ChecklistShareByEmailPane from './ChecklistShareByEmailPane'
import Pane from '../../../common/Pane'

export default {
  name: 'ChecklistPane',
  components: { ChecklistShareByEmailPane, Pane },
  data () {
    return {
      tabs: [
        {
          title: this.$t('project.checklist.pane.ChecklistPane.shareByEmail'),
          id: 'share-email',
        },
      ],
    }
  },
  computed: {
    ...mapState('checklist', ['pane']),
  },
}
</script>
