<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <AppButton :type="type"
                 :size="size"
                 :variant="variant"
                 :full-width="fullWidth"
                 :class="buttonClasses"
                 v-bind="attrs"
                 v-on="on"
      >
        <template #left-icon>
          <!-- @slot for left icon -->
          <slot name="left-icon"></slot>
        </template>

        <!-- @slot for button text -->
        <slot></slot>

        <template #right-icon>
          <font-awesome-icon class="ml-2" :icon="['fas', 'chevron-down']" />
        </template>
      </AppButton>
    </template>

    <!-- @slot for list -->
    <slot name="list">
      <v-list>
        <v-list-item v-for="(item, index) in menuItems"
                     :key="`item_${index}`"
                     :disabled="item.loading"
                     @click="handleItemClick(item)"
        >
          <v-list-item-icon v-if="item.icon || item.img"
                            class="mr-2"
          >
            <app-icon v-if="item.icon" :icon-name="item.icon"/>
            <v-img v-else :src="item.img"/>
          </v-list-item-icon>
          <v-list-item-content class="d-flex justify-space-between">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </slot>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import AppButton, { AppButtonSize, AppButtonType, AppButtonVariant } from './AppButton.vue'

type MenuItemType = {
  title: string,
  action: string,
  loading?: boolean,
  icon?: string,
  img?: string,
}

/**
 * @displayName Dropdown Button
 */
export default defineComponent({
  name: 'AppDropdownButton',
  components: { AppButton },
  props: {
    /**
     * Size of the button
     * Possible values are : small, medium
     */
    size: {
      type: String as PropType<AppButtonSize>,
      default: 'medium',
    },
    /**
     * Hierarchy of the button
     * Possible values are : primary, secondary, tertiary
     */
    type: {
      type: String as PropType<AppButtonType>,
      default: 'primary',
    },
    /**
     * Color variant
     * Possible values are : brand, project, dataroom, archive, neutral
     */
    variant: {
      type: String as PropType<AppButtonVariant>,
      default: 'brand',
    },
    /**
     * Dropdown menu options
     */
    menuItems: {
      type: Array as PropType<Array<MenuItemType>>,
      default: () => [],
    },
    /**
     * Specifies whether the button should fill parent's width
     */
    fullWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * Classes added to the button.
     */
    buttonClasses: {
      type: [String, Array],
    },
  },
  methods: {
    handleItemClick (item) {
      item.action(item)
    },
  },
})
</script>

<docs>
```vue
<template>
  <div class="d-flex column-gap-2">
    <app-dropdown-button type="primary" :menu-items="menuItems">
      Primary

      <template #left-icon>
        <font-awesome-icon :icon="leftIcon" />
      </template>
    </app-dropdown-button>

    <app-dropdown-button type="secondary" :menu-items="menuItems">
      Secondary
    </app-dropdown-button>

    <app-dropdown-button type="tertiary" :menu-items="menuItems">
      Tertiary
    </app-dropdown-button>
  </div>
</template>

<script>
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
  import { solidLibrary } from '@/plugins/font-awesome-libraries/solid-library'

  export default {
    components: { FontAwesomeIcon },
    data () {
      return {
        menuItems: [
          { title: 'Option 1' },
          { title: 'Option 2' },
          { title: 'Option 3', loading: true, },
        ],
        leftIcon: solidLibrary.faPlus,
      }
    },
  }
</script>

```
</docs>
