<template>
  <div class="editor w-100">
    <div v-if="editor && (!hideMenubar || editorIsFocused)"
         class="editor__menubar"
         :class="{
           'editor__menubar--error': errorMessages.length,
           'editor__menubar--qaVariant': qaVariant,
         }"
    >
      <v-slide-x-transition>
        <div class="menubar d-flex align-center flex-wrap">
          <div>
            <AppTooltip top>
              <template #activator="{attrs, on}">
                <AppButton class="menubar-btn blur-editor-exception"
                           :input-value="editor.isActive('bold')"
                           :x-padding="0"
                           small
                           icon
                           v-bind="attrs"
                           v-on="on"
                           @click="toggleBold"
                >
                  <font-awesome-icon :icon="['far', 'bold']"
                                     class="editor__menubar-icon"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{ $t('common.visual-text-editor.VisualTextEditor.boldTooltip') }}</span>
            </AppTooltip>
            <AppTooltip top>
              <template #activator="{attrs, on}">
                <AppButton class="ml-1 menubar-btn blur-editor-exception"
                           :input-value="editor.isActive('italic')"
                           :x-padding="0"
                           small
                           icon
                           v-bind="attrs"
                           v-on="on"
                           @click="toggleItalic"
                >
                  <font-awesome-icon :icon="['far', 'italic']"
                                     class="editor__menubar-icon"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{ $t('common.visual-text-editor.VisualTextEditor.italicTooltip') }}</span>
            </AppTooltip>
            <AppTooltip top>
              <template #activator="{attrs, on}">
                <AppButton class="ml-1 menubar-btn blur-editor-exception"
                           :input-value="editor.isActive('strike')"
                           :x-padding="0"
                           small
                           icon
                           v-bind="attrs"
                           v-on="on"
                           @click="toggleStrike"
                >
                  <font-awesome-icon :icon="['far', 'strikethrough']"
                                     class="editor__menubar-icon"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{ $t('common.visual-text-editor.VisualTextEditor.strikeTooltip') }}</span>
            </AppTooltip>
            <AppTooltip top>
              <template #activator="{attrs, on}">
                <AppButton class="ml-1 menubar-btn blur-editor-exception"
                           :input-value="editor.isActive('underline')"
                           :x-padding="0"
                           small
                           icon
                           v-bind="attrs"
                           v-on="on"
                           @click="toggleUnderline"
                >
                  <font-awesome-icon :icon="['far', 'underline']"
                                     class="editor__menubar-icon"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{ $t('common.visual-text-editor.VisualTextEditor.underlineTooltip') }}</span>
            </AppTooltip>
            <v-divider vertical class="my-2 mx-1"/>
            <AppTooltip top>
              <template #activator="{attrs, on}">
                <AppButton class="menubar-btn blur-editor-exception"
                           :input-value="editor.isActive('bulletList')"
                           :x-padding="0"
                           small
                           icon
                           v-bind="attrs"
                           v-on="on"
                           @click="toggleBulletList"
                >
                  <font-awesome-icon :icon="['far', 'list']"
                                     class="editor__menubar-icon"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{ $t('common.visual-text-editor.VisualTextEditor.bulletListTooltip') }}</span>
            </AppTooltip>
            <AppTooltip top>
              <template #activator="{attrs, on}">
                <AppButton class="ml-1 menubar-btn blur-editor-exception"
                           :input-value="editor.isActive('orderedList')"
                           :x-padding="0"
                           small
                           icon
                           v-bind="attrs"
                           v-on="on"
                           @click="toggleOrderedList"
                >
                  <font-awesome-icon :icon="['far', 'list-ol']"
                                     class="editor__menubar-icon"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{ $t('common.visual-text-editor.VisualTextEditor.orderedListTooltip') }}</span>
            </AppTooltip>
            <v-divider vertical class="my-2 mx-1"/>
            <AppTooltip top>
              <template #activator="{attrs, on}">
                <AppButton class="menubar-btn blur-editor-exception"
                           :input-value="editor.isActive('highlight')"
                           :x-padding="0"
                           small
                           icon
                           v-bind="attrs"
                           v-on="on"
                           @click="toggleHighlight"
                >
                  <font-awesome-layers class="fa-lg editor__menubar-icon">
                    <font-awesome-icon :icon="['fas', 'circle']"
                                       :style="{ color: pickedHighlightColor, 'font-size': '20px' }"
                                       transform="left-3"
                    />
                    <font-awesome-icon :icon="['fas', 'highlighter']" />
                  </font-awesome-layers>
                </AppButton>
              </template>
              <span>{{ $t('common.visual-text-editor.VisualTextEditor.highlightTooltip') }}</span>
            </AppTooltip>
            <v-divider vertical class="my-2 mx-1"/>
            <AppTooltip top>
              <template #activator="{attrs, on}">
                <AppButton class="menubar-btn blur-editor-exception"
                           small
                           :x-padding="0"
                           icon
                           v-bind="attrs"
                           v-on="on"
                           @click="clearFormat"
                >
                  <font-awesome-icon :icon="['far', 'remove-format']"
                                     class="editor__menubar-icon"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{ $t('common.visual-text-editor.VisualTextEditor.clearFormatTooltip') }}</span>
            </AppTooltip>
          </div>
          <div class="ml-auto">
            <TextCounter v-if="counter === 'menubar'"
                         :currentLength="textLength"
                         :maxLength="maxLength"
            />

            <AppTooltip v-if="sendButton" top>
              <template #activator="{attrs, on}">
                <AppButton class="blur-editor-exception"
                           icon
                           :disabled="textIsTooLong"
                           v-bind="attrs"
                           v-on="on"
                           @click="onSendClicked"
                >
                  <font-awesome-icon :icon="['fal', 'paper-plane']"
                                     class="editor__menubar-icon"
                                     :class="{ 'grey--text text--lighten-1': textIsTooLong }"
                  ></font-awesome-icon>
                </AppButton>
              </template>
              <span>{{ $t('common.visual-text-editor.VisualTextEditor.sendTooltip') }}</span>
            </AppTooltip>
          </div>
        </div>
      </v-slide-x-transition>
    </div>
    <EditorContent ref="editor"
                   :editor="editor"
                   class="editor__content white"
                   :class="{
                     'editor__content-oneLine': oneLine,
                     'editor__content--error': errorMessages.length,
                     'editor__content--qaVariant': qaVariant,
                   }"
    />

    <div v-if="errorMessages.length || counter === 'footer'" class="editor__footer mt-1">
      <div v-if="errorMessages" class="editor__errors">
        <div v-for="(errorMessage, index) in errorMessages"
             :key="index"
             class="error--text caption"
        >
          {{ errorMessage }}
        </div>
      </div>

      <TextCounter v-if="counter === 'footer'"
                   :currentLength="textLength"
                   :maxLength="maxLength"
                   class="editor__counter"
      />
    </div>
  </div>
</template>

<script>
import Highlight from '@tiptap/extension-highlight'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2'
import { cloneDeep } from 'lodash-es'
import tippy from 'tippy.js'

import MentionList from './MentionList'
import AppTooltip from '../AppTooltip'
import AppButton from '../buttons/AppButton'
import TextCounter from '../TextCounter'
import { escapeHtml } from '../utils/strings'
export default {
  name: 'VisualTextEditor',
  components: {
    AppButton,
    EditorContent,
    AppTooltip,
    TextCounter,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    taggableMembers: {
      type: Array,
    },
    taggableGroups: {
      type: Array,
    },
    oneLine: {
      type: Boolean,
      default: false,
    },
    sendButton: {
      type: Boolean,
      default: false,
    },
    hideMenubar: {
      type: Boolean,
      default: false,
    },
    cleanContentOnValidation: {
      type: Boolean,
      default: false,
    },
    cleanContentOnBlur: {
      type: Boolean,
      default: true,
    },
    keepToolbarOnBlur: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    counter: {
      validator (value) {
        return ['menubar', 'footer'].includes(value)
      },
      required: false,
    },
    qaVariant: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      editor: null,
      editorIsFocused: false,
      pickedHighlightColor: '#FFE066',
      items: [],
      tippyPopup: null,
    }
  },
  computed: {
    mentionable () {
      const array = []
      if (this.taggableGroups) {
        const groups = cloneDeep(this.taggableGroups)
        groups.sort((a, b) => {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        })
        array.push(...groups)
      }
      if (this.taggableMembers && this.taggableGroups) {
        array.push({ name: 'separator' })
        const members = cloneDeep(this.taggableMembers)
        members.sort((a, b) => {
          return a.fullName.localeCompare(b.fullName, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        })
        array.push(...members)
      } else if (this.taggableMembers) {
        const members = cloneDeep(this.taggableMembers)
        members.sort((a, b) => {
          return a.fullName.localeCompare(b.fullName, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        })
        array.push(...members)
        array.push(...this.taggableMembers)
      }
      return array
    },
    textLength () {
      return escapeHtml(this.value).length
    },
    textIsTooLong () {
      return this.maxLength && this.textLength > this.maxLength
    },
    formattedValue () {
      // Format mentions spans and add a space before the end tag
      const formattedMention = this.value.replaceAll('data-mention', 'data-type="mention"')
      if (formattedMention.length) {
        const lastClosingTagIndex = formattedMention.lastIndexOf('</')
        if (lastClosingTagIndex !== -1) {
          return formattedMention.slice(0, lastClosingTagIndex) + ' ' + formattedMention.slice(lastClosingTagIndex)
        }
      }
      return formattedMention
    },
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  mounted () {
    this.editor = new Editor({
      extensions: [
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: {
            items: editor => {
              return this.mentionable.filter(item => {
                if (item.fullName) {
                  if (item.fullName.toLowerCase().includes(editor.query.toLowerCase())) {
                    return item
                  }
                } else if (item.name && item.name !== 'separator') {
                  if (item.name.toLowerCase().includes(editor.query.toLowerCase())) {
                    return item
                  }
                }
              })
            },
            render: () => {
              let component
              let popup
              return {
                onStart: props => {
                  component = new VueRenderer(MentionList, {
                    parent: this,
                    propsData: props,
                  })
                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                  })
                  this.tippyPopup = popup
                },
                onUpdate (props) {
                  component.updateProps(props)
                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  })
                },
                onKeyDown (props) {
                  if (props.event.key === 'Escape') {
                    popup[0].hide()
                    return true
                  }
                  return component.ref?.onKeyDown(props)
                },
                onExit () {
                  popup[0].destroy()
                  component.destroy()
                },
              }
            },
          },
        }),
        StarterKit,
        Underline,
        TextStyle,
        Highlight,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
      ],
      editable: true,
      parseOptions: {
        preserveWhitespace: 'full',
      },
      injectCSS: false,
      content: this.formattedValue,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      },
      onBlur: ({ event }) => {
        if (event.relatedTarget) {
          if (!event.relatedTarget.classList.contains('blur-editor-exception') && !event.relatedTarget.classList.contains('tippy-box')) {
            if (this.tippyPopup) {
              this.tippyPopup[0].destroy()
            }
            if (this.cleanContentOnBlur || !this.keepToolbarOnBlur || this.value === '<p></p>') {
              this.editorIsFocused = false
            }
            this.$emit('blur')
            if (this.cleanContentOnValidation && this.cleanContentOnBlur) {
              this.editor.commands.clearContent()
            } else if (!this.keepToolbarOnBlur || this.value === '<p></p>') {
              this.editorIsFocused = false
            }
          }
        } else {
          this.$emit('blur')
          if (!this.keepToolbarOnBlur || this.value === '<p></p>') {
            this.editorIsFocused = false
          }
          if (this.cleanContentOnValidation && this.cleanContentOnBlur) {
            this.editor.commands.clearContent()
          }
          if (this.tippyPopup) {
            this.tippyPopup[0].destroy()
          }
        }
      },
      onFocus: () => {
        this.editorIsFocused = true
        this.$emit('focus')
      },
    })
  },
  methods: {
    toggleBold () { this.editor.chain().focus().toggleBold().run() },
    toggleItalic () { this.editor.chain().focus().toggleItalic().run() },
    toggleStrike () { this.editor.chain().focus().toggleStrike().run() },
    toggleUnderline () { this.editor.chain().focus().toggleUnderline().run() },
    toggleBulletList () { this.editor.chain().focus().toggleBulletList().run() },
    toggleOrderedList () { this.editor.chain().focus().toggleOrderedList().run() },
    clearFormat () { this.editor.chain().focus().clearNodes().unsetAllMarks().run() },
    toggleHighlight () { this.editor.chain().focus().toggleHighlight().run() },
    onSendClicked () {
      this.$emit('sendClicked')
      if (this.cleanContentOnValidation) {
        this.editor.commands.clearContent()
        this.editorIsFocused = false
      }
    },
    focus () {
      this.editor.commands.focus('end')
    },
    clear () {
      this.editor.commands.clearContent()
    },
  },
}
</script>

<style lang="scss">
.editor {
  &__menubar {
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f5f5f5;
    border: 1px solid var(--v-grey-lighten2);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    &--qaVariant {
      border-left-color: var(--v-accent-darken3);
      border-right-color: var(--v-accent-darken3);
      border-top-color: var(--v-accent-darken3);
      border-bottom-color: transparent;
    }

    &-icon {
      font-size: 12px;
      color: var(--v-accent-lighten2);
    }

    &--error {
      border-top: 1px solid var(--v-error-base);
      border-left: 1px solid var(--v-error-base);
      border-right: 1px solid var(--v-error-base);
    }
  }

  &__content {
    .ProseMirror {
      min-height: 100px;
      max-height: 400px;
      overflow-y: auto;
      padding: 6px 10px 0px 10px;
      transition: all 0.2s;
      border: 1px solid #ddd;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      font-size: 14px;
      outline: none !important;
      &-focused {
        outline: none;
        border-color: var(--v-tertiary-lighten3);
      }
      .mention {
        color: var(--v-tertiary-base);
        font-weight: bold;
      }
      * {
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }

    &-oneLine {
      .ProseMirror {
        min-height: 17px;
      }
    }

    &--qaVariant {
      .ProseMirror {
        border-left-color: var(--v-accent-darken3);
        border-right-color: var(--v-accent-darken3);
        border-bottom-color: var(--v-accent-darken3);
        border-top-color: transparent;
      }
    }

    &--error {
      .ProseMirror {
        border-bottom: 1px solid var(--v-error-base);
        border-left: 1px solid var(--v-error-base);
        border-right: 1px solid var(--v-error-base);
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__errors {
    flex: 1;
  }

  &__counter {
    flex: 1;
    text-align: right;
  }
}

.padding-prose-mirror .ProseMirror {
  /* border: solid 1px rgba(0, 0, 0, 0.5); */
  padding: 10px 10px 10px 10px;
}
.ProseMirror code {
  background-color: rgb(255, 255, 0);
  padding: 0px;
  font-size: 100%;
  color: var(--v-accent-base);
  font-weight: initial;
  border-radius: 0px;
  font-family: 'Roboto', sans-serif;
}

.menubar {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f5f5f5;
}
.menubar-icon-container {
  padding: 3px;
  cursor: pointer;
}
.menubar-icon {
  font-size: 12px;
  color: var(--v-accent-lighten2);
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: rgba(51, 51, 51, 0.51);
  font-size: 14px;
  pointer-events: none;
  height: 0;
}
</style>
