<template>
  <v-stepper-content step="2">
    <template v-if="currentStep === 2">
      <h3 class="text-h3">
        {{ $t('common.identity-checking.IdentityCheckingID.identityCard.title') }}
      </h3>
      <div class="mt-3">
        {{ $t('common.identity-checking.IdentityCheckingID.identityCard.subTitle') }}
      </div>

      <IdentityDocumentsTypes class="mt-8" />
    </template>
  </v-stepper-content>
</template>

<script>
  import IdentityDocumentsTypes from './IdentityDocumentsTypes.vue'

  export default {
    name: 'IdentityDocuments',
    components: {
      IdentityDocumentsTypes,
    },
    props: {
      currentStep: {
        type: Number,
        required: true,
      },
    },
  }
</script>
