/**
 * Builds a query usable with the get envelope pages endpoint
 * @param {Object} pagesToQuery If the query is about non contiguous pages, use the pages property as an number array. This parameter has the priority.
 * If the query is about contiguous pages, specify both fromPage and toPage to query every page within the range (including from and to)
 * If the query is about a single page, only specify fromPage property
 * @returns {string|Array} A query usable with the get envelope pages endpoint
 */
export function buildGetPagesQuery ({ fromPage, toPage, pages }) {
  if (pages) {
    return pages
  }

  if (toPage && toPage !== fromPage) {
    return `${fromPage}-${toPage}`
  }

  return fromPage
}
/**
 * Get tags overlaps
 * @param {Array} tagsToCheck An array of formatted tags { id, height, width, x, y }
 * @returns {Array} An array of overlapping tags pairs
 */
export function getTagsOverlaps (tagsToCheck) {
  const tagsPairs = tagsToCheck.flatMap((v, i) => tagsToCheck.slice(i + 1).map(w => [v.id, w.id]))
  const tagsOverlaps = []

  tagsPairs.forEach(tagsPair => {
    const tag1 = tagsToCheck.find(tag => tag.id === tagsPair[0])
    const tag2 = tagsToCheck.find(tag => tag.id === tagsPair[1])

    if (tag1.pageNumber === tag2.pageNumber) {
      const isInHorizontalBounds = tag1.x < tag2.x + tag2.width && tag1.x + tag1.width > tag2.x
      const isInVerticalBounds = tag1.y < tag2.y + tag2.height && tag1.y + tag1.height > tag2.y
      const isOverlapping = isInHorizontalBounds && isInVerticalBounds

      if (isOverlapping) {
        tagsOverlaps.push(tagsPair)
      }
    }
  })

  return tagsOverlaps
}
