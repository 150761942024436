<template>
  <div>
    <ProjectsNew
      no-activator
      :dialogIsOpen.sync="dialogIsOpen"
    />

    <ProjectTypeCard
      color="light-blue"
      is-project-category
      main-icon-src="/img/project/project-category-filled.svg"
      :disabled="!canCurrentUserCreateProject"
      @click="onProjectCardClicked"
    >
      <span>{{$t('projects.project-types.ProjectTypeProject.label')}}</span>
    </ProjectTypeCard>

    <div class="mt-2 text-center">
      <AppButton text
                 class="text-decoration-underline discover-label"
                 @click="discoverDialogIsOpen = true"
      >
        {{$t('projects.project-types.ProjectTypeProject.discoverLabel')}}
      </AppButton>
    </div>

    <DiscoverProjectDialog v-if="discoverDialogIsOpen"
                           @close="discoverDialogIsOpen = false"
                           @createProject="createProject"
                           @requestActivation="openQuoteRequestDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { QUOTE_REQUEST_TOPICS } from '@/common/utils/quoteRequestTopics'

import AppButton from '../../common/buttons/AppButton'
import { SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG } from '../../store/modules/subscription/mutation_types'
import DiscoverProjectDialog from '../dialogs/DiscoverProjectDialog'
import ProjectsNew from '../dialogs/ProjectsNew.vue'
import ProjectTypeCard from '../ProjectTypeCard.vue'

export default {
  name: 'ProjectTypeProject',
  components: {
    AppButton,
    DiscoverProjectDialog,
    ProjectTypeCard,
    ProjectsNew,
  },
  data () {
    return {
      dialogIsOpen: false,
      discoverDialogIsOpen: false,
    }
  },
  computed: {
    ...mapGetters('user', ['canCurrentUserCreateProject']),
  },
  methods: {
    ...mapMutations('subscription', [SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG]),
    createProject () {
      this.discoverDialogIsOpen = false
      this.dialogIsOpen = true
    },
    onProjectCardClicked () {
      if (this.canCurrentUserCreateProject) {
        this.dialogIsOpen = true
      } else {
        this.discoverDialogIsOpen = true
      }
    },
    openQuoteRequestDialog () {
      this.SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG({
        isOpen: true,
        defaultProjectType: QUOTE_REQUEST_TOPICS.STD_PROJECT,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .discover-label {
    color: #626262;
  }
</style>
