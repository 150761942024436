<template>
  <div>
    <AppTooltip top>
      <template #activator="{attrs, on}">
        <AppButton icon
                   v-bind="attrs"
                   v-on="on"
                   @click="isOpen = true"
        >
          <font-awesome-icon :icon="['fas', 'trash']" class="text--primary"></font-awesome-icon>
        </AppButton>
      </template>
      <span>{{$t('project.members.dialogs.GroupDeleteDialog.tooltipDelete')}}</span>
    </AppTooltip>
    <AppDialog :is-open="isOpen"
               :cancel-disabled="deleteGroupLoading"
               :ok-loading="deleteGroupLoading"
               @ok="prepareDeleteGroup"
               @cancel="isOpen = false"
    >
      <template #title>{{ $t('project.members.dialogs.GroupDeleteDialog.deleteGroup') }}</template>
      <template #body>
        <div v-html="$t('project.members.dialogs.GroupDeleteDialog.deleteGroupText', {groupName: groupToDelete.name})"></div>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import AppButton from '@/common/buttons/AppButton'

import AppDialog from '../../../common/dialogs/AppDialog'

export default {
  name: 'DeleteGroup',
  components: { AppDialog, AppButton, AppTooltip },
  props: {
    groupToDelete: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState('groups', ['deleteGroupLoading']),
  },
  methods: {
    ...mapActions('groups', ['DELETE_GROUP']),
    prepareDeleteGroup () {
      this.DELETE_GROUP({ mnemo: this.$route.params.mnemo, groupId: this.groupToDelete.id })
        .then(() => {
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            timeout: 3000,
            message: this.$t('project.members.dialogs.GroupDeleteDialog.deleteGroupSuccess'),
          })
        })
        .catch(error => {
          if (error.response?.data?.error === 'ERR_GROUP_NOT_EMPTY') {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              timeout: 3000,
              message: this.$t('project.members.dialogs.GroupDeleteDialog.deleteGroupNotEmpty'),
            })
          } else {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              timeout: 3000,
              message: this.$t('project.members.dialogs.GroupDeleteDialog.deleteGroupError'),
            })
          }
        })
        .finally(() => {
          this.isOpen = false
        })
    },
  },
}
</script>
