<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-text="$t('common.delete')"
             :ok-loading="checkWopiLockedFilesPending || isPending"
             size="dialog-xs"
             @ok="validateFildersDeletion"
             @cancel="closeDialog"
  >
    <template #title>
      <template v-if="checkWopiLockedFilesPending">
        <v-skeleton-loader type="text" :width="250" />
      </template>
      <template v-else>
        {{ dialogTitle }}
      </template>
    </template>
    <template #body>
      <template v-if="homeLayout">
        <i18n :path="`${TRANSLATION_KEY_PREFIX}.deleteProjectsFolderText`" tag="p">
          <template v-slot:name>
            <span class="font-weight-bold">{{ projectsFolderToDelete.name }}</span>
          </template>
          <template v-slot:parentName>
            <span class="font-weight-bold">{{ parentFolderName }}</span>
          </template>
        </i18n>
      </template>
      <template v-else>
        <template v-if="checkWopiLockedFilesPending">
          <v-skeleton-loader type="text@4" />
        </template>
        <template v-else-if="atLeastOneFileIsWopiLocked">
          <div class="d-flex flex-column row-gap-4">
            <p>
              <span v-if="documents.length > 1">
                {{ $tc(`${TRANSLATION_KEY_PREFIX}.filesCurrentlyWopiLocked.text.multipleSelection`, wopiLockedFiles.length) }}
              </span>
              <span v-else>
                {{ $t(`${TRANSLATION_KEY_PREFIX}.filesCurrentlyWopiLocked.text.singleSelection`) }}
              </span>
              {{ $t(`${TRANSLATION_KEY_PREFIX}.filesCurrentlyWopiLocked.text.secondPart`) }}
            </p>
            <p>{{ $t(`${TRANSLATION_KEY_PREFIX}.filesCurrentlyWopiLocked.confirmation`) }}</p>
            <DocumentSelectionList v-if="documents.length > 1"
                                   :selected-documents="wopiLockedFiles"
            />
          </div>
        </template>
        <template v-else>
          {{ dialogText }}
        </template>
      </template>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import DocumentSelectionList from '@/common/document-selection/DocumentSelectionList'
import { dialogMixin } from '@/mixins/dialogMixin'
import { CHECK_WOPI_LOCKED_FILES } from '@/store/modules/documents/action_types'
import { RESET_WOPI_LOCKED_FILES } from '@/store/modules/documents/mutation_types'

const TRANSLATION_KEY_PREFIX = 'project.documents.dialogs.DocumentsDeleteDialog'

export default {
  name: 'DocumentsDeleteDialog',
  components: { AppDialog, DocumentSelectionList },
  emits: ['validate-deletion'],
  mixins: [dialogMixin],
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
    }
  },
  computed: {
    ...mapState(['homeLayout']),
    ...mapState('projectsFolders', ['currentProjectsFolder']),
    ...mapState('documents', ['checkWopiLockedFilesPending', 'wopiLockedFiles']),
    ...mapState('documentsDelete', ['isOpen', 'loading', 'documents']),
    ...mapState('projectsFoldersDelete', ['projectsFolderDeleteDialogIsOpen', 'projectsFolderDeletePending', 'projectsFolderToDelete']),
    ...mapGetters('room', ['isWopiEnabled', 'roomMnemo']),
    atLeastOneFileIsWopiLocked () {
      return this.wopiLockedFiles.length > 0
    },
    dialogText () {
      if (this.singleFile) {
        return this.$t(`${TRANSLATION_KEY_PREFIX}.deleteSingleFile`)
      }

      if (this.singleFolder) {
        return this.$t(`${TRANSLATION_KEY_PREFIX}.deleteSingleFolder`)
      }

      if (this.multipleDeletion) {
        return this.$t(`${TRANSLATION_KEY_PREFIX}.deleteMultiple`)
      }

      return null
    },
    dialogTitle () {
      if (this.homeLayout) {
        return this.$t(`${TRANSLATION_KEY_PREFIX}.deleteProjectsFolder`, {
          name: this.projectsFolderToDelete.name,
        })
      }

      if (this.atLeastOneFileIsWopiLocked) {
        return this.$tc(`${TRANSLATION_KEY_PREFIX}.filesCurrentlyWopiLocked.title`, this.wopiLockedFiles.length)
      }

      return this.$tc(`${TRANSLATION_KEY_PREFIX}.deleteDocuments`, this.documents.length)
    },
    isDialogOpen () {
      return this.homeLayout ? this.projectsFolderDeleteDialogIsOpen : this.isOpen
    },
    isPending () {
      return this.homeLayout ? this.projectsFolderDeletePending : this.loading
    },
    parentFolderName () {
      return this.currentProjectsFolder.name || this.$t('projects.ProjectsHeader.myProjects')
    },
    possiblyWopiLockedFiles () {
      return this.documents.filter(document => document.type === 'file' && document.canEdit)
    },
    multipleDeletion () {
      return this.documents.length > 1
    },
    shouldCheckForWopiLockOnFiles () {
      return this.isWopiEnabled && this.possiblyWopiLockedFiles.length > 0
    },
    singleFile () {
      return this.documents.length === 1 && this.documents[0].type === 'file'
    },
    singleFolder () {
      return this.documents.length === 1 && this.documents[0].type === 'folder'
    },
  },
  beforeDestroy () {
    this.RESET_WOPI_LOCKED_FILES()
  },
  async created () {
    if (this.shouldCheckForWopiLockOnFiles) {
      await this.checkWopiLockedFiles()
    }
  },
  methods: {
    ...mapActions('documents', [CHECK_WOPI_LOCKED_FILES]),
    ...mapMutations('documents', [RESET_WOPI_LOCKED_FILES]),
    async validateFildersDeletion () {
      this.$emit('validate-deletion')
      this.closeDialog()
    },
    async checkWopiLockedFiles () {
      try {
        await this.CHECK_WOPI_LOCKED_FILES({
            mnemo: this.roomMnemo,
            filesIds: this.possiblyWopiLockedFiles.map(file => file.id),
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>
