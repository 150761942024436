<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-loading="renameEnvelopeTemplatePending"
             :cancel-disabled="renameEnvelopeTemplatePending"
             @ok="prepareRenameEnvelopeTemplate"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('project.signing-checklist.envelope-templates.dialogs.RenameDialog.title') }}</template>
    <template #body>
      <form @submit.prevent="prepareRenameEnvelopeTemplate">
        <AppTextField v-model="$v.newName.$model"
                      :label="$t('project.signing-checklist.envelope-templates.dialogs.RenameDialog.labelName')"
                      :counter="MAX_NAME_LENGTH"
                      maxLength="100"
                      :error-messages="newNameErrors"
        />
      </form>
    </template>
  </AppDialog>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapMutations, mapState } from 'vuex'

import AppTextField from '@/common/AppTextField.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

const MAX_NAME_LENGTH = 100
export default {
  name: 'RenameDialog',
  components: { AppTextField, AppDialog },
  mixins: [dialogMixin, validationMixin],
  validations: {
    newName: { required, maxLength: maxLength(MAX_NAME_LENGTH) },
  },
  props: {
    envelopeTemplate: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      MAX_NAME_LENGTH,
      newName: this.envelopeTemplate.title,
    }
  },
  computed: {
    ...mapState('envelopeTemplates', ['renameEnvelopeTemplatePending']),
    newNameErrors () {
      const errors = []
      if (!this.$v.newName.$dirty) return errors
      !this.$v.newName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.signing-checklist.envelope-templates.dialogs.RenameDialog.labelName') }))
      !this.$v.newName.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.signing-checklist.envelope-templates.dialogs.RenameDialog.labelName'), maxLength: MAX_NAME_LENGTH }))
      return errors
    },
  },
  methods: {
    ...mapActions('envelopeTemplates', ['RENAME_ENVELOPE_TEMPLATE']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async prepareRenameEnvelopeTemplate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.RENAME_ENVELOPE_TEMPLATE({
            envelopeTemplateId: this.envelopeTemplate.id,
            data: {
              title: this.newName,
            },
          })
          this.closeDialog()
        } catch (error) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.envelope-templates.dialogs.RenameDialog.renameEnvelopeTemplateError'))
        }
      }
    },
  },
}
</script>
