import { ID_CHECK_ERROR_TYPES } from '@/common/utils/idCheckErrors'
import { ROOM_TYPES } from '@/common/utils/roomTypes'
import { canUserSign, FAILED_ID_CHECKS_LIMIT } from '@/common/utils/users'

export const getters = {
  isArchivingAdmin: state => state.profile?.isArchivingAdmin,
  isSubscriber: state => !!state.profile?.subscription,
  isSubPayingUser: state => state.profile?.isSubUser,
  isSubAdmin: state => state.profile?.isSubAdmin,
  isArchivingEnabled: state => !!state.profile?.subscription?.archivingEnabled,
  isArchivingHidden: state => !!state.profile?.subscription?.hideArchiveOption,
  customizationEnabled: state => !!state?.profile?.subscription?.customizationEnabled,
  validationChecklistItemDeletionEnabled: state => !!state?.profile?.subscription?.validationChecklistItemDeletionEnabled,
  areNotificationsForcedOnRoomClose: state => !!state.profile?.subscription?.forceNotificationsWhenClosingRoom,
  failedIdChecks: state => state.profile?.failedIdChecks,
  isIdCheckPending: state => state.profile?.failedIdChecks === FAILED_ID_CHECKS_LIMIT,
  canCurrentUserSign: state => canUserSign(state.profile),
  canAccessSubscription: state => state.profile?.isSubAdmin || state.profile?.isSubBillingAdmin,
  iManageEnabled: state => !!state.profile?.subscription?.iManageEnabled,
  profileFullName: state => state.profile?.fullName,
  currentUserId: state => state.profile?.id,
  hasIdCheckErrorInfo: state => state.idCheckError && state.idCheckError.errorType === ID_CHECK_ERROR_TYPES.ERROR_TYPE_INFO,
  canCurrentUserCreateProject: (state, getters) => {
    return getters.roomTypes.findIndex(roomType =>
        roomType.type === ROOM_TYPES.PROJECT_UNLIMITED,
    ) !== -1
  },
  canCurrentUserCreateDataroom: (state, getters) => {
    return getters.roomTypes.findIndex(roomType =>
        roomType.type === ROOM_TYPES.DATAROOM || roomType.type === ROOM_TYPES.DATAROOM_UNLIMITED,
    ) !== -1
  },
  roomTypes: state => state.profile?.subscription?.roomTypes ?? [],
  isDataroomUnlimited: (state, getters) => {
    return getters.roomTypes.findIndex(roomType => roomType.type === ROOM_TYPES.DATAROOM_UNLIMITED) !== -1
  },
  polyOfficeEnabled: state => state.profile?.subscription?.polyOfficeEnabled,
}
