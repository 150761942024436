<template>
  <div>
    <ProjectTypeCard
      color="blue-darken-3"
      main-icon-src="/img/project/archive-category-filled.svg"
      :disabled="!isArchivingEnabled"
      @click="onArchivesCardClicked"
    >
      <span>{{$t('projects.project-types.ProjectTypeArchive.label')}}</span>
    </ProjectTypeCard>

    <div class="mt-2 text-center">
      <AppButton text
                 class="text-decoration-underline discover-label"
                 @click="discoverDialogIsOpen = true"
      >
        {{ $t('projects.project-types.ProjectTypeArchive.discoverLabel') }}
      </AppButton>
    </div>

    <DiscoverElectronicSafeDialog v-if="discoverDialogIsOpen"
                                  @close="discoverDialogIsOpen = false"
                                  @requestActivation="openQuoteRequestDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { QUOTE_REQUEST_TOPICS } from '@/common/utils/quoteRequestTopics'

import AppButton from '../../common/buttons/AppButton'
import router, { ARCHIVES_ROUTE_NAME } from '../../router'
import { SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG } from '../../store/modules/subscription/mutation_types'
import DiscoverElectronicSafeDialog from '../dialogs/DiscoverElectronicSafeDialog'
import ProjectTypeCard from '../ProjectTypeCard.vue'

export default {
  name: 'ProjectTypeArchive',
  components: {
    AppButton,
    DiscoverElectronicSafeDialog,
    ProjectTypeCard,
  },
  data () {
    return {
      ARCHIVES_ROUTE_NAME,
      discoverDialogIsOpen: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isArchivingEnabled']),
  },
  methods: {
    ...mapMutations('subscription', [SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG]),
    onArchivesCardClicked () {
      if (this.isArchivingEnabled) {
        router.push({ name: ARCHIVES_ROUTE_NAME })
      } else {
        this.discoverDialogIsOpen = true
      }
    },
    openQuoteRequestDialog () {
      this.SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG({
        isOpen: true,
        defaultProjectType: QUOTE_REQUEST_TOPICS.ELECTRONIC_SAFE,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .discover-label {
    color: #626262;
  }
</style>
