<template>
  <v-row no-gutters class="mt-5 mb-2">
    <v-col class="d-flex justify-center">
      <div class="text-h5 back-closd-link">
          <a :href="$t('login.BackToClosd.urlClosd')">{{$t('login.BackToClosd.backClosd')}}</a>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BackToClosd',
}
</script>

<style scoped>
.back-closd-link a{
    text-decoration: none;
    color: #9e9e9e
}
</style>
