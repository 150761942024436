<template>
 <AppDialog :is-open="isOpen"
            size="xxl"
            :ok-text="$t('common.download')"
            :cancel-text="$t('common.close')"
            :ok-disabled="!(logsData && logsData.length > 0)"
            @cancel="onCancel"
            @ok="onOk"
 >
   <template v-if="document" #title>{{ $t('project.documents.dialogs.DocumentsLogsDialog.logActivity', { filename: document.basename }) }}</template>
   <template #body>
     <v-data-table :items="logsData"
                   item-key="id"
                   :headers="headers"
                   :loading="loading"
                   :footer-props="{
                     'items-per-page-options': [10, 50, 100],
                   }"
     ></v-data-table>
     <AppDownloadFile v-if="downloadLink" :link="downloadLink">
       <template #default="{submitForm}">
         <div ref="downloadForm"
              class="d-none"
              @click="submitForm"
         ></div>
       </template>
     </AppDownloadFile>
   </template>
 </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import { GET_FILE_LOGS, GET_FOLDER_LOGS } from '@/store/modules/logs/action_types'
import { SET_CLOSE_LOGS_DIALOG } from '@/store/modules/logs/mutation_types'

import AppDialog from '../../../common/dialogs/AppDialog'

export default {
  name: 'DocumentsLogsDialog',
  components: { AppDialog, AppDownloadFile },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      logs: null,
      headers: [
        {
          text: this.$t('project.documents.dialogs.DocumentsLogsDialog.headers.date'),
          value: 'dateUTC',
        },
        {
          text: this.$t('project.documents.dialogs.DocumentsLogsDialog.headers.user'),
          value: 'userFullname',
        },
        {
          text: this.$t('project.documents.dialogs.DocumentsLogsDialog.headers.action'),
          value: 'type',
        },
        {
          text: this.$t('project.documents.dialogs.DocumentsLogsDialog.headers.description'),
          value: 'description',
        },
      ],
    }
  },
  computed: {
    ...mapState('logs', ['logsDialog', 'fileLogsPending', 'folderLogsPending']),
    isOpen () {
      return this.logsDialog.isOpen
    },
    document () {
      return this.logsDialog.document
    },
    loading () {
      return this.fileLogsPending || this.folderLogsPending
    },
    logsData () {
      return this.logs?.data || []
    },
    downloadLink () {
      if (this.document?.type === 'file') {
        return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/file/${this.document.id}/logs`
      } else if (this.document?.type === 'folder') {
        return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/folder/${this.document.id}/logs`
      }
      return null
    },
  },
  watch: {
    isOpen (value) {
      if (value) {
        this.prepareGetLogs()
      }
    },
  },
  methods: {
    ...mapActions('logs', [GET_FILE_LOGS, GET_FOLDER_LOGS]),
    onCancel () {
      this.logs = null
      this.$store.commit(`logs/${SET_CLOSE_LOGS_DIALOG}`)
    },
    onOk () {
      this.$refs.downloadForm.click()
    },
    prepareGetLogs () {
      if (this.document) {
        if (this.document.type === 'file') {
          this.prepareGetFileLogs()
        } else if (this.document.type === 'folder') {
          this.prepareGetFolderLogs()
        }
      }
    },
    async prepareGetFileLogs () {
      try {
        const response = await this.GET_FILE_LOGS({
          mnemo: this.mnemo,
          fileId: this.document.id,
        })
        this.logs = response.data
      } catch (error) {
        console.error(error.response)
      }
    },
    async prepareGetFolderLogs () {
      try {
        const response = await this.GET_FOLDER_LOGS({
          mnemo: this.mnemo,
          folderId: this.document.id,
        })
        this.logs = response.data
      } catch (error) {
        console.error(error.response)
      }
    },
  },
}
</script>
