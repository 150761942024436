<template>
  <div>
    <AppButton
      v-if="!noActivator"
      color="primary"
      @click.stop="isOpen = true"
    >
      <template v-if="headerDesign">
        <font-awesome-icon
          v-if="!$vuetify.breakpoint.xs"
          class="mr-2"
          :icon="['far', 'plus']"
        ></font-awesome-icon>
        <span v-if="!$vuetify.breakpoint.xs">{{ $t('projects.dialogs.ProjectsNew.newProject') }}</span>
      </template>
      <template v-else>
        <font-awesome-icon
          :icon="['far', 'plus']"
          style="font-size: 24px;"
        ></font-awesome-icon>
      </template>
    </AppButton>
    <ProjectsNewSubscriptionDialog
      v-if="computedIsOpen"
      :is-open="computedIsOpen"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import AppButton from '@/common/buttons/AppButton'
import ProjectsNewSubscriptionDialog from '@/projects/dialogs/ProjectsNewSubscriptionDialog'
export default {
  name: 'ProjectsNew',
  components: { ProjectsNewSubscriptionDialog, AppButton },
  props: {
    headerDesign: {
      type: Boolean,
      default: true,
    },
    dialogIsOpen: {
      type: Boolean,
      default: false,
    },
    noActivator: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    computedIsOpen () {
      return this.isOpen || this.dialogIsOpen
    },
  },
  methods: {
    closeDialog () {
      this.isOpen = false
      this.$emit('update:dialogIsOpen', false)
    },
  },
}
</script>
