<template>
  <v-menu
    v-model="menuProjects"
    min-width="206"
    offset-y
    rounded="lg"
    :open-on-hover="!$vuetify.breakpoint.xs"
    transition="slide-y-transition"
    bottom
    left
    close-on-content-click
  >
    <template v-slot:activator="{ on, attrs, value }">
      <AppBarMenuItem v-slot="{active}"
                      :label="$t('common.app-bar.ProjectsMenuItem.projects')"
                      :active="$route.path === '/'"
                      :hover="value"
                      v-bind="attrs"
                      @click="goToProjects"
                      v-on="on"
      >
        <AppBarMenuItemIcon icon="folder" :active="active" />
      </AppBarMenuItem>
    </template>

    <v-list class="py-0">
      <v-subheader class="font-weight-medium text-uppercase"
                   style="font-size: 12px; position: sticky; top: 0; z-index: 10"
      >
        {{$t('common.app-bar.ProjectsMenuItem.recentlyOpened')}}
      </v-subheader>

      <v-list-item v-if="lastVisitedRoomsPending">
        <v-list-item-content>
          <v-progress-circular
            style="width: 32px; height: 32px"
            size="32"
            color="primary"
            indeterminate
          />
        </v-list-item-content>
      </v-list-item>

      <div v-else>
        <v-list-item v-for="project in lastVisitedRooms"
                     :key="project.id"
                     class="project-list-item"
                     :input-value="roomMnemo === project.mnemo"
                     @click="goToProject(project)"
        >
          <v-list-item-content>
            <v-list-item-title class="d-flex align-end" style="font-size: 14px">
              <img
                class="mr-2"
                alt="room-icon"
                :src="getRoomIcon(project)"
                width="24"
              />
              <span>
                {{project.name.substr(0, 92) + (project.name.length > 92 ? '...' : '')}}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="goToProjects">
          <v-list-item-content>
            {{$t('common.app-bar.ProjectsMenuItem.seeAllProjects')}}
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppBarMenuItem from '@/common/app-bar/AppBarMenuItem.vue'
import AppBarMenuItemIcon from '@/common/app-bar/AppBarMenuItemIcon.vue'
import { getRoomIcon, getRoomRouteType } from '@/common/utils/rooms'
import { ROOM_ROUTE_NAME, ROOMS_ROUTE_NAME } from '@/router'
import { GET_LAST_VISITED_ROOMS } from '@/store/modules/rooms/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

const LAST_VISITED_ROOMS_LIMIT = 5

export default {
  name: 'ProjectsMenuItem',
  components: { AppBarMenuItemIcon, AppBarMenuItem },
  data () {
    return {
      menuProjects: false,
      projectsHomeRoute: {
        name: ROOMS_ROUTE_NAME,
      },
    }
  },
  computed: {
    ...mapState('room', ['currentRoom']),
    ...mapState('rooms', ['lastVisitedRooms', 'lastVisitedRoomsPending']),
    ...mapGetters('room', ['roomMnemo']),
    projectsRoute () {
      return ROOMS_ROUTE_NAME
    },
  },
  watch: {
    menuProjects (value) {
      if (value) {
        this.loadLastVisitedRooms()
      }
    },
  },
  methods: {
    ...mapActions('rooms', [GET_LAST_VISITED_ROOMS]),
    goToProject (project) {
      if (!this.currentRoom || this.currentRoom.id !== project.id) {
        this.$router.push({ name: ROOM_ROUTE_NAME, params: { mnemo: project.mnemo, roomType: getRoomRouteType(project) } })
      }
    },
    goToProjects () {
      if (this.$route.path !== '/') {
        this.$router.push({ name: ROOMS_ROUTE_NAME })
      }
    },
    getRoomIcon,
    getRoomRouteType,
    async loadLastVisitedRooms () {
      try {
        await this.GET_LAST_VISITED_ROOMS(LAST_VISITED_ROOMS_LIMIT)
      } catch (error) {
        console.error(error)

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('common.app-bar.ProjectsMenuItem.loadLastVisitedRoomsError'),
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-list-item__content {
    span {
      max-width: 206px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .project-list-item.v-list-item--active {
    &::before {
      opacity: 0 !important;
    }

    &:hover {
      &::before {
        opacity: 0.04 !important;
      }
    }
  }
</style>
