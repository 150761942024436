<template>
  <div style="position:relative;">
    <template v-if="searchQueryPending">
      <v-skeleton-loader type="table-thead, table-heading, table-tbody"/>
    </template>
    <template v-else>
      <v-row v-if="$vuetify.breakpoint.mdAndUp"
            :class="[
        'SigningChecklistTable-header',
        'text--primary',
        'flex-nowrap',
        'signing-checklist-tab-header',
        selectedSteps.length > 0 ? 'signing-checklist-tab-header-with-multiple' : 'signing-checklist-tab-header-without-multiple',
        {'signing-checklist-tab-header-guest': !isPm},
        ]"
            align="center"
      >
        <v-col class="SigningChecklistTable-header-item text-h4"
              :cols="hasSigningOrder ? 4 : 5"
        >
          <v-row class="align-center"
                no-gutters
          >
            <v-col v-if="isPm" cols="auto">
              <v-checkbox
                :value="isEveryStepsSelected"
                readonly
                hide-details
                class="mt-0 pt-0 mr-2"
                :ripple="false"
                @click.stop="onSelectedStepsClick"
              />
            </v-col>
            <v-col cols="auto">
              {{ $t('project.signing-checklist.list.SigningChecklistTable.header.documents') }}
            </v-col>
            <v-col>
              <AppTooltip top>
                <template #activator="{attrs, on}">
                  <AppButton icon
                            small
                            :x-padding="0"
                            v-bind="attrs"
                            v-on="on"
                            @click="onExpandOrder(true)"
                  >
                    <font-awesome-icon :icon="['fas', 'up-right-and-down-left-from-center']"
                                      class="caption text--primary"
                                      :transform="{ rotate: -45 }"
                    ></font-awesome-icon>
                  </AppButton>
                </template>
                <span>{{$t('project.signing-checklist.list.SigningChecklistTable.expandTooltip')}}</span>
              </AppTooltip>
              <AppTooltip top>
                <template #activator="{attrs, on}">
                  <AppButton icon
                            small
                            :x-padding="0"
                            v-bind="attrs"
                            v-on="on"
                            @click="onExpandOrder(false)"
                  >
                    <font-awesome-icon :icon="['fas', 'down-left-and-up-right-to-center']"
                                      class="caption text--primary"
                                      :transform="{ rotate: -45 }"
                    ></font-awesome-icon>
                  </AppButton>
                </template>
                <span>{{$t('project.signing-checklist.list.SigningChecklistTable.compressTooltip')}}</span>
              </AppTooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <template v-if="isPm">
            <AppTooltip top>
              <template #activator="{on, attrs}">
                <div v-bind="attrs"
                    v-on="on"
                >
                  <AppSelect v-model="headerSelectedColumn"
                            class="SigningChecklistTable-header-select text-h4"
                            no-label
                            return-object
                            style="width: 200px;"
                            :items="headerSelectItems"
                  />
                </div>
              </template>
              <span> {{ headerSelectedColumn.text }}  </span>
            </AppTooltip>
          </template>
          <template v-else>
            <div class="SigningChecklistTable-header-item text-h4">
              {{ $t('project.signing-checklist.list.SigningChecklistTable.header.signatories') }}
            </div>
          </template>
        </v-col>
        <v-col class="SigningChecklistTable-header-item text-h4"
              cols="2"
        >
          {{ $t('project.signing-checklist.list.SigningChecklistTable.header.validators') }}
        </v-col>
        <v-col class="SigningChecklistTable-header-item text-h4"
              cols="2"
        >
          {{ $t('project.signing-checklist.list.SigningChecklistTable.header.status') }}
        </v-col>
        <v-col v-if="hasSigningOrder"
              class="SigningChecklistTable-header-item text-h4"
              style="overflow-wrap: normal"
              cols="1"
        >
          {{ $t('project.signing-checklist.list.SigningChecklistTable.header.signOrder')}}
        </v-col>
        <v-col class="SigningChecklistTable-header-item text-h4 text-right"
              cols="1"
        >
          {{ $t('project.signing-checklist.list.SigningChecklistTable.header.options') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <div v-if="isSigningChecklistFiltered && filteredSigningChecklist && filteredSigningChecklist.length === 0" class="mt-8">
            <DataTableFilterNoData />
          </div>
          <Draggable v-model="signingChecklistModel"
                    :forceFallback="true"
                    :group="{name: 'topLevel', pull: false, put: false}"
                    :emptyInsertThreshold="100"
                    :delay="500"
                    :delayOnTouchOnly="true"
                    :disabled="!isPm || isSigningChecklistFiltered"
                    handle=".SigningChecklistTable-dragIcon"
                    @change="preparePostMoveStep"
                    @start="dragging = true"
                    @end="dragging = false"
          >
          <SigningChecklistTableTitle v-for="(title) in signingChecklistModel"
                                      :id="`topTitle-${title.id}`"
                                      :key="`topTitle-${title.id}`"
                                      :item="title"
                                      :isSigningChecklistFiltered="isSigningChecklistFiltered"
                                      :mnemo="mnemo"
                                      :headerSelectedColumn.sync="headerSelectedColumn"
                                      :header-select-items="headerSelectItems"
                                      :floatingAdd="tableElementMouseEntered === `${title.type}-${title.id}`"
                                      :elementEntered="tableElementMouseEntered"
                                      :hasSigningOrder="hasSigningOrder"
                                      @newElementEntered="onNewElementEntered"
                                      @save-as-template="envelopeToSaveAsTemplate = $event"
            />
          </Draggable>
        </v-col>
      </v-row>
    </template>
    <SigningChecklistDeleteStepDialog
      v-if="deleteStepModal"
      :ids="deleteStepModal"
      :mnemo="mnemo"
      @close="stepDeletionHidden"
      @confirm="stepDeletionConfirm"
    />
    <SigningChecklistDeleteTitleDialog
      v-if="deleteTitleModal"
      @close="titleDeletionHidden"
      @confirm="titleDeletionConfirm"
    />
    <SigningChecklistCancelStepDialog
      v-if="cancelStepModal"
      :ids="cancelStepModal.ids"
      :mnemo="mnemo"
      @close="cancelStepHidden"
      @confirm="cancelStepConfirm"
    />
    <SigningChecklistVoidedToDraftStepDialog
      v-if="voidedToDraftStepModal"
      :count="voidedToDraftStepModal.ids.length"
      @close="voidedToDraftStepHidden"
      @confirm="voidedToDraftStepConfirm"
    />
    <SigningChecklistSendDocumentsDialog
      v-if="sendSignStepModal"
      :mnemo="mnemo"
      @close="sendSignStepHidden"
      @confirm="sendSignStepConfirm"
    />
    <SaveDocumentAsTemplateDialog v-if="envelopeToSaveAsTemplate"
                                  :envelope="envelopeToSaveAsTemplate"
                                  @close="envelopeToSaveAsTemplate = null"
    />
    <SwapFilesDialog
      v-if="swapFiles"
      :mnemo="mnemo"
      @cancel="showCancelDialog = true"
      @close="resetSwapFiles"
      @confirm="onSwapFileConfirm"
    />
    <SigningChecklistPrepareCancelDialog
      v-if="showCancelDialog"
      @close="showCancelDialog = false"
      @confirm="resetSwapFiles"
    />
    <SigningChecklistPane />
  </div>
</template>
<script>
import Draggable from 'vuedraggable'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import DataTableFilterNoData from '@/common/filters/DataTableFilterNoData'
import { track } from '@/common/pendo/agent'
import { findStepParent } from '@/common/utils/signingChecklist'
import SigningChecklistPrepareCancelDialog from '@/project/signing-checklist/dialogs/SigningChecklistPrepareCancelDialog'
import SigningChecklistSendDocumentsDialog from '@/project/signing-checklist/dialogs/SigningChecklistSendDocumentsDialog'
import SwapFilesDialog from '@/project/signing-checklist/dialogs/SwapFilesDialog.vue'
import SaveDocumentAsTemplateDialog from '@/project/signing-checklist/envelope-templates/dialogs/SaveDocumentAsTemplateDialog.vue'
import SigningChecklistTableTitle from '@/project/signing-checklist/list/SigningChecklistTableTitle'
import {
  CANCEL_STEP,
  CANCEL_STEP_MULTIPLE,
  DELETE_STEP,
  DELETE_TITLE,
  DELETE_STEP_MULTIPLE,
  POST_MOVE_STEP,
  REFRESH_SIGNING_CHECKLIST,
  REFRESH_STEP,
  RESET_STEP,
  RESET_STEP_MULTIPLE,
  SEND_SIGN_NOW,
  SEND_SIGN_NOW_MULTIPLE,
  SWAP_STEP_FILES,
  VALIDATE_THEN_SEND_NOW,
  VALIDATE_THEN_SEND_NOW_MULTIPLE,
} from '@/store/modules/signing-checklist/action_types'
import {
  SET_SIGNING_CHECKLIST,
  SET_ADD_SELECTED_STEPS,
  SET_REMOVE_SELECTED_STEPS,
  SET_DELETE_STEP_MODAL,
  SET_SWAP_FILES,
  SET_CANCEL_STEP_MODAL,
  SET_SEND_SIGN_STEP_MODAL,
  SET_VOIDED_TO_DRAFT_STEP_MODAL,
  SET_DELETE_TITLE_MODAL,
  ADD_REFRESH_STEP_PENDING,
  SET_REFRESH_STEP_PENDING_IDS,
  SET_SELECTED_STEPS,
  SET_EXPAND_ALL_SIGNING_CHECKLIST,
} from '@/store/modules/signing-checklist/mutation_types'
import { ENQUEUE_SNACKBAR, ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

import SigningChecklistCancelStepDialog from './SigningChecklistCancelStepDialog'
import SigningChecklistDeleteStepDialog from './SigningChecklistDeleteStepDialog'
import SigningChecklistDeleteTitleDialog from './SigningChecklistDeleteTitleDialog.vue'
import SigningChecklistVoidedToDraftStepDialog from './SigningChecklistVoidedToDraftStepDialog'
import AppSelect from '../../../common/AppSelect'
import AppTooltip from '../../../common/AppTooltip'
import AppButton from '../../../common/buttons/AppButton'
import SigningChecklistPane from '../pane/SigningChecklistPane'

export default {
  name: 'SigningChecklistTable',
  components: {
    SaveDocumentAsTemplateDialog,
    AppButton,
    AppSelect,
    AppTooltip,
    DataTableFilterNoData,
    Draggable,
    SigningChecklistPrepareCancelDialog,
    SigningChecklistCancelStepDialog,
    SigningChecklistDeleteStepDialog,
    SwapFilesDialog,
    SigningChecklistDeleteTitleDialog,
    SigningChecklistPane,
    SigningChecklistSendDocumentsDialog,
    SigningChecklistTableTitle,
    SigningChecklistVoidedToDraftStepDialog,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      headerSelectItems: [
        {
          value: 'signatories',
          text: this.$t('project.signing-checklist.list.SigningChecklistTable.header.signatories'),
        },
        {
          value: 'recipients',
          text: this.$t('project.signing-checklist.list.SigningChecklistTable.header.recipients'),
        },
        {
          value: 'observers',
          text: this.$t('project.signing-checklist.list.SigningChecklistTable.header.observers'),
        },
      ],
      headerSelectedColumn: {
        value: 'signatories',
        text: this.$t('project.signing-checklist.list.SigningChecklistTable.header.signatories'),
      },
      tableElementMouseEntered: '',
      dragging: false,
      envelopeToSaveAsTemplate: null,
      showCancelDialog: false,
    }
  },
  computed: {
    ...mapState('signingChecklist', ['searchQuery', 'searchQueryPending', 'signingChecklist', 'refreshSigningChecklistPending', 'selectedSteps', 'deleteStepModal', 'swapFiles', 'cancelStepModal', 'sendSignStepModal', 'voidedToDraftStepModal', 'refreshStepPendingIds', 'deleteTitleModal', 'isExpandSigningChecklist']),
    ...mapGetters('signingChecklist', ['filteredSigningChecklist', 'flatSigningChecklist', 'isSigningChecklistFiltered']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
    signingChecklistModel: {
      get () {
        return this.signingChecklist
      },
      set (value) {
        this.$store.commit(`signingChecklist/${SET_SIGNING_CHECKLIST}`, value)
      },
    },
    isEveryStepsSelected () {
      return this.displayedSigningChecklistSteps.every(el => {
        return this.selectedSteps.some(item => item.id === el.id && item.type === el.type)
      })
    },
    displayedSigningChecklistSteps () {
      const displayedList = this.isSigningChecklistFiltered ? this.filteredSigningChecklist : this.flatSigningChecklist
      return displayedList.filter(item => item.type === 'step')
    },
    hasSigningOrder () {
      return this.displayedSigningChecklistSteps.some((step) => step.signingGroup)
    },
    hasSearchQuery () {
      return this.searchQuery.length > 0
    },
  },
  methods: {
    ...mapActions('signingChecklist', [POST_MOVE_STEP, REFRESH_SIGNING_CHECKLIST, DELETE_STEP, DELETE_STEP_MULTIPLE, CANCEL_STEP, CANCEL_STEP_MULTIPLE, SEND_SIGN_NOW, SEND_SIGN_NOW_MULTIPLE, RESET_STEP, RESET_STEP_MULTIPLE, REFRESH_STEP, VALIDATE_THEN_SEND_NOW, VALIDATE_THEN_SEND_NOW_MULTIPLE, DELETE_TITLE, SWAP_STEP_FILES]),
    ...mapMutations('signingChecklist', [SET_DELETE_STEP_MODAL, SET_SWAP_FILES, SET_CANCEL_STEP_MODAL, SET_SEND_SIGN_STEP_MODAL, SET_VOIDED_TO_DRAFT_STEP_MODAL, ADD_REFRESH_STEP_PENDING, SET_REFRESH_STEP_PENDING_IDS, SET_DELETE_TITLE_MODAL, SET_SELECTED_STEPS, SET_EXPAND_ALL_SIGNING_CHECKLIST]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    onNewElementEntered (elementString) {
      // It seems that when we're dragging or when user is a PM, we shouldn't re-render this component,
      // otherwise Draggable doesn't accept anything at the top level
      if (!this.dragging || !this.isPm) {
        this.tableElementMouseEntered = elementString
      }
    },
    async preparePostMoveStep (e) {
      if (e.moved) {
        const itemToMove = e.moved.element
        const itemToMoveIndexInFlattenSigningChecklist = this.flatSigningChecklist.findIndex(el => el.id === itemToMove.id && el.type === itemToMove.type)
        const data = itemToMoveIndexInFlattenSigningChecklist === 0
          ? { newPosition: 0 }
          : {
            prevId: this.flatSigningChecklist[itemToMoveIndexInFlattenSigningChecklist - 1].id,
            prevType: this.flatSigningChecklist[itemToMoveIndexInFlattenSigningChecklist - 1].type,
          }
        const trailItem = this.getTrailItem(itemToMove)
        data.newTrailId = trailItem.id
        data.newTrailType = trailItem.type
        try {
          await this.POST_MOVE_STEP({
            mnemo: this.mnemo,
            stepId: itemToMove.id,
            stepType: itemToMove.type,
            data,
          })
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: this.$t('project.signing-checklist.list.SigningChecklistTable.message.postMoveStepSuccess'),
          })
        } catch (e) {
          this.$store.commit('enqueueSnackbar', {
            color: 'error',
            message: this.$t('project.signing-checklist.list.SigningChecklistTable.message.postMoveStepError'),
          })
        }
        this.REFRESH_SIGNING_CHECKLIST({
          mnemo: this.mnemo,
        })
      }
    },
    getTrailItem (item) {
      if (item.type === 'step') {
        return item
      } else {
        if (item.children.length === 0) {
          return item
        } else {
          return this.getTrailItem(item.children[item.children.length - 1])
        }
      }
    },
    onSelectedStepsClick () {
      if (this.isEveryStepsSelected) {
        this.displayedSigningChecklistSteps.forEach(item => {
          this.$store.commit(`signingChecklist/${SET_REMOVE_SELECTED_STEPS}`, item)
        })
      } else {
       this.displayedSigningChecklistSteps.forEach(item => {
          this.$store.commit(`signingChecklist/${SET_ADD_SELECTED_STEPS}`, item)
        })
      }
    },
    onExpandOrder (value) {
      if (value === true || value === false) {
        const obj = { value: value, items: this.signingChecklistModel }
        this.SET_EXPAND_ALL_SIGNING_CHECKLIST(obj)
      }
    },
    async stepDeletionConfirm () {
      const deletionLength = this.deleteStepModal.length
      const ids = this.deleteStepModal
      if (deletionLength > 1) {
        await this.DELETE_STEP_MULTIPLE({ mnemo: this.mnemo, ids: this.deleteStepModal })
      } else {
        await this.DELETE_STEP({ mnemo: this.mnemo, stepId: this.deleteStepModal[0] })
      }
      this.$store.commit(ENQUEUE_SNACKBAR, {
        message: this.$tc('project.signing-checklist.list.SigningChecklistTable.message.deleteStepSuccess', deletionLength),
        color: 'success',
      })
      this.REFRESH_SIGNING_CHECKLIST({
        mnemo: this.mnemo,
      })
      if (this.selectedSteps.length !== 0) {
        this.SET_SELECTED_STEPS(
          this.selectedSteps.filter((selectedStep) => !ids.includes(selectedStep.id),
          ),
        )
      }
    },
    stepDeletionHidden () {
      this.SET_DELETE_STEP_MODAL(null)
    },
    async titleDeletionConfirm () {
      await this.DELETE_TITLE({
        mnemo: this.mnemo,
        id: this.deleteTitleModal,
      })
      this.REFRESH_SIGNING_CHECKLIST({
        mnemo: this.mnemo,
      })
    },
    titleDeletionHidden () {
      this.SET_DELETE_TITLE_MODAL(null)
    },
    async cancelStepConfirm () {
      const { ids } = this.cancelStepModal
      for (const id of ids) {
        this.ADD_REFRESH_STEP_PENDING(id)
      }
      try {
        if (ids.length > 1) {
          await this.CANCEL_STEP_MULTIPLE({ mnemo: this.mnemo, stepsIds: ids })
        } else {
          await this.CANCEL_STEP({ mnemo: this.mnemo, stepId: ids[0] })
        }
      } finally {
        this.onFinishStepPatch(ids)
      }
    },
    cancelStepHidden () {
      this.SET_CANCEL_STEP_MODAL(null)
    },
    async voidedToDraftStepConfirm () {
      const { steps, ids } = this.voidedToDraftStepModal
      const stepIds = steps.map((s) => s.id)
      for (const id of stepIds) {
        this.ADD_REFRESH_STEP_PENDING(id)
      }
      try {
        if (ids.length > 1) {
          await this.RESET_STEP_MULTIPLE({ mnemo: this.mnemo, ids })
        } else {
          const step = steps[0]
          await this.RESET_STEP({ mnemo: this.mnemo, envId: step.envelope.id })
          const computedParent = parent || findStepParent(step, this.signingChecklist)
          this.REFRESH_STEP({
            mnemo: this.mnemo,
            stepId: step.id,
            parent: computedParent,
          })
        }
      } finally {
        this.onFinishStepPatch(stepIds)
      }
    },
    voidedToDraftStepHidden () {
      this.SET_VOIDED_TO_DRAFT_STEP_MODAL(null)
    },
    async sendSignStepConfirm () {
      const { steps, ids } = this.sendSignStepModal
      for (const id of ids) {
        this.ADD_REFRESH_STEP_PENDING(id)
      }
      try {
        if (ids.length > 1) {
          await this.SEND_SIGN_NOW_MULTIPLE({ mnemo: this.mnemo, stepsIds: ids })
        } else {
          await this.SEND_SIGN_NOW({ mnemo: this.mnemo, step: steps[0] })
        }
      } finally {
        this.onFinishStepPatch(ids)
      }
    },
    sendSignStepHidden () {
      this.SET_SEND_SIGN_STEP_MODAL(null)
    },
    onFinishStepPatch (stepIds) {
      if (stepIds.some((id) => this.refreshStepPendingIds.includes(id))) {
        this.SET_REFRESH_STEP_PENDING_IDS(
          this.refreshStepPendingIds.filter((id) => !stepIds.includes(id)),
        )
      }
      // If there are patched steps included in the multi select
      // we should remove them from the selection
      if (this.selectedSteps.length !== 0) {
        this.SET_SELECTED_STEPS(
          this.selectedSteps.filter((selectedStep) => !stepIds.includes(selectedStep.id)),
        )
      }
    },
    async onSwapFileConfirm (data) {
      const { ids } = this.swapFiles

      for (const id of ids) {
        if (data.find(step => step.envelopeId === id)) { this.ADD_REFRESH_STEP_PENDING(id) }
      }

      try {
        await this.SWAP_STEP_FILES({
          mnemo: this.mnemo,
          data: data,
        })

        await this.REFRESH_SIGNING_CHECKLIST({
          mnemo: this.mnemo,
        })

        track('swap_success')
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.signing-checklist.list.SigningChecklistTable.message.swapFileSuccess'))
      } catch {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.list.SigningChecklistTable.message.swapFileError'))
      } finally {
        this.onFinishStepPatch(ids)
      }
    },
    resetSwapFiles () {
      this.SET_SWAP_FILES(null)
    },
  },
}
</script>

<style scoped lang="scss">
/*Toggle class to fit the top with the multiple selection menu
* Need to be updated if the layout change
*/
  .signing-checklist-tab-header{
    position: sticky;
    background-color: #fff;
    z-index: 2;
  }
  .signing-checklist-tab-header-without-multiple {
    top: 128px;
  }
  .signing-checklist-tab-header-with-multiple {
    top: 210px;
  }
  .signing-checklist-tab-header-guest {
    top: 63px;
  }
@media #{map-get($display-breakpoints, 'md-only')}{
  .signing-checklist-tab-header-without-multiple {
    top: 120px;
  }
  .signing-checklist-tab-header-with-multiple {
    top: 233px;
  }
  .signing-checklist-tab-header-guest {
    top: 55px;
  }
}
  @media #{map-get($display-breakpoints, 'sm-and-down')}{
    .signing-checklist-tab-header-without-multiple {
      top: 153px;
    }
    .signing-checklist-tab-header-with-multiple {
      top: 230px;
    }
    .signing-checklist-tab-header-guest {
      top: 55px;
    }
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')}{
    .signing-checklist-tab-header-without-multiple {
      top: 116px;
    }
    .signing-checklist-tab-header-with-multiple {
      top: 201px;
    }
    .signing-checklist-tab-header-guest {
      top: 64px;
    }
  }

  .SigningChecklistTable-header-item {
    text-transform: uppercase;
  }

  .SigningChecklistTable-refreshSigningChecklistLoader {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(#fff, .8);

    & .SigningChecklistTable-refreshSigningChecklistLoader-loader {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
.SigningChecklistTable-header-select {
  text-transform: uppercase;
}

::v-deep .v-input--checkbox i {
  font-size: 16px;
}
</style>
