import { actions } from './actions'
import * as getters from './getters'
import { mutations } from './mutations'
import { state } from './state'

export const login = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
