<template>
  <div>
    <v-row>
      <v-col cols="12">
        <span class="text-h3">{{$t('project.documents.pane.DocumentsShareByEmailPane.selectFormat')}}</span>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12">
        <v-radio-group v-model="shareFormat" class="mt-0" :column="false">
          <v-radio :value="shareFormatOptions.EXCEL" class="mr-12">
            <template #label>
              <span>{{ $t('project.documents.pane.DocumentsShareByEmailPane.formatExcel') }}</span>
            </template>
          </v-radio>
          <v-radio :value="shareFormatOptions.PDF">
            <template #label>
              <span>{{ $t('project.documents.pane.DocumentsShareByEmailPane.formatPdf') }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-divider class="mt-2 mb-4" />

    <v-row class="mt-0">
      <v-col cols="12">
        <span class="text-h3">{{$t('project.documents.pane.DocumentsShareByEmailPane.selectRecipients')}}</span>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12">
        <DocumentSharePicker :forcePmSelection="false" users-only />
      </v-col>
    </v-row>

    <v-divider class="my-4" />

    <v-row class="mt-0">
      <v-col cols="12">
        <span class="text-h3">{{$t('project.documents.pane.DocumentsShareByEmailPane.addMessage')}}</span>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12">
        <VisualTextEditor v-model="notifyMessage"
                          counter="footer"
                          :error-messages="notifyMessageErrors"
                          :max-length="NOTIFY_MESSAGE_MAX_LENGTH"
                          :placeholder="$t('project.documents.pane.DocumentsShareByEmailPane.optionalMessage')"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <AppButton block
                   color="primary"
                   :disabled="shareDocumentsIndexByEmailPending"
                   outlined
                   @click="close"
        >
          {{$t('common.cancel')}}
        </AppButton>
      </v-col>
      <v-col cols="6">
        <AppButton block
                   color="primary"
                   :disabled="shareDisabled"
                   :loading="shareDocumentsIndexByEmailPending"
                   @click="shareByEmail"
        >
          {{$t('common.share')}}
        </AppButton>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppButton from '../../../common/buttons/AppButton'
import DocumentSharePicker from '../../../common/document-share/DocumentSharePicker'
import VisualTextEditor from '../../../common/visual-text-editor/VisualTextEditor'
import { CLOSE_DOCUMENTS_PANE, SHARE_DOCUMENTS_INDEX_BY_EMAIL } from '../../../store/modules/documents/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

const NOTIFY_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'DocumentsShareByEmailPane',
  components: { AppButton, DocumentSharePicker, VisualTextEditor },
  data () {
    return {
      notifyMessage: '',
      shareFormat: '',
      shareFormatOptions: Object.freeze({
        EXCEL: 'documents-index-excel',
        PDF: 'documents-index-pdf',
      }),
      NOTIFY_MESSAGE_MAX_LENGTH,
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    ...mapState('documents', ['shareDocumentsIndexByEmailPending']),
    ...mapState('documentSharePicker', ['selectedGroups', 'selectedMembers']),
    noRecipientsSelected () {
      return this.selectedGroups.length === 0 && this.selectedMembers.length === 0
    },
    notifyMessageLength () {
      return this.notifyMessage.replace(/(<([^>]+)>)/gi, '').length
    },
    notifyMessageIsTooLong () {
      return this.notifyMessageLength > this.NOTIFY_MESSAGE_MAX_LENGTH
    },
    notifyMessageErrors () {
      const errors = []

      if (this.notifyMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    shareDisabled () {
      return this.notifyMessageIsTooLong || !this.shareFormat || this.noRecipientsSelected
    },
  },
  methods: {
    ...mapActions('documents', [CLOSE_DOCUMENTS_PANE, SHARE_DOCUMENTS_INDEX_BY_EMAIL]),
    close () {
      this.CLOSE_DOCUMENTS_PANE()
    },
    async shareByEmail () {
      const shareData = {
        type: this.shareFormat,
        withUsers: this.selectedMembers.map(i => i.id),
        withGroups: this.selectedGroups.map(i => i.id),
      }

      if (this.notifyMessage) {
        shareData.message = this.notifyMessage
      }

      try {
        await this.SHARE_DOCUMENTS_INDEX_BY_EMAIL({
          mnemo: this.roomMnemo,
          data: shareData,
        })

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.documents.pane.DocumentsShareByEmailPane.shareByEmailSuccess'),
        })

        this.CLOSE_DOCUMENTS_PANE()
      } catch (error) {
        console.error(error)

        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.pane.DocumentsShareByEmailPane.shareByEmailError'),
        })
      }
    },
  },
}
</script>
