<template>
  <!--
    Triggered on click. Use this event to react on button click.
    @event click
  -->
  <v-btn class="AppMultipleSelectionBarButton"
         v-bind="$attrs"
         elevation="0"
         color="transparent"
         @click="$emit('click')"
  >
    <div class="AppMultipleSelectionBarButton-icon-container">
      <font-awesome-icon class="AppMultipleSelectionBarButton-icon" :icon="['fas', icon]"/>
    </div>
    {{ $t(`common.${type}`) }}
  </v-btn>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { AppMultipleSelectionBarButtonIcon } from './enums'
import { AppMultipleSelectionBarButtonType } from './types'
export default defineComponent({
  name: 'AppMultipleSelectionBarButton',
  props: {
    type: {
      type: String as PropType<AppMultipleSelectionBarButtonType>,
      required: true,
    },
  },
  computed: {
    icon (): string {
      return AppMultipleSelectionBarButtonIcon[this.type].valueOf()
    },
  },
})
</script>

<style scoped lang="scss">
.AppMultipleSelectionBarButton {
  &::before {
    background-color: var(--v-primary-base);
  }
  &-icon {
    color: var(--v-primary-base);
    &-container {
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
      border-radius: 4px;
      background-color: var(--v-primary-lighten5);
    }
  }
}

</style>
