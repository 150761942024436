<template>
  <div class="d-flex item-container" :class="{'item-container--warning' : hasErrors}">
    <ClosdFildersIcon :document="doc.file" />
    <app-text variant="small-regular" as="span" class="item-name">
      {{ displayedDocumentName }}
    </app-text>
    <div class="item-status-container">
      <v-chip :color="envelopeStatusColor">
        <span class="item-status">
          {{ $t(`common.envelopeStatus.${doc.status}`) }}</span>
      </v-chip>
    </div>
  </div>
</template>

<script>
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon.vue'
import { ENVELOPE_TYPES } from '@/project/signing-checklist/constants'

export default {
  name: 'SigningChecklistSummaryListItem',
  components: { ClosdFildersIcon },
  props: {
    doc: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      ENVELOPE_TYPES,
    }
  },
  computed: {
    awaitingValidation () {
      return !this.doc.hasAllValidations
    },
    displayedDocumentName () {
      if (!this.multiEnvelopeSignatory) return this.doc.name
      return this.doc.name + ' - ' + this.multiEnvelopeSignatory.fullName
    },
    envelopeStatusColor () {
      switch (this.doc.status) {
        case 'draft':
        case 'temp':
          return '#717B85'
        case 'sent':
          return 'warning'
        case 'signed':
          return 'success'
        case 'voided':
          return 'error'
        case 'unvalidated':
          return 'warning'
      }
      return null
    },
    hasErrors () {
      return this.doc.missingToSend || this.doc.missingToSign
    },
    multiEnvelopeSignatory () {
      return this.doc.signatories.flat().find(signatory => signatory.multiSignatureId !== null)
    },
  },
}
</script>

<style lang="scss" scoped>
  .item-container {
    background-color: var(--v-originalGrey-lighten5);
    border-radius: 4px;
    margin-bottom: 16px;
    align-items: center;
    overflow: hidden;

    &--warning {
      background-color: #fffaf2;
    }
  }
  .item-name {
    overflow: hidden;
    padding-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .item-status-container {
    background-color:#D4D7DA;
    display: flex;
    justify-content: center;
    margin-left : auto;
    min-width: 150px;
    padding: 8px;

    .v-chip.v-size--default {
      height: auto;
      padding: 1px 12px;
    }
  }
  .item-status {
    color: white;
    font-family: 'roboto';
    font-size: 10px;
  }
</style>
