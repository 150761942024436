<template>
  <div class="w-100">
    <div class="color-shadow" :style="{ background: shadowColor }" />

    <v-img :src="illustration"
           contain
           position="center right"
           class="illustration"
    />

    <div class="title">
      <slot name="title"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectTypeFeatureHeader',
  props: {
    shadowColor: {
      type: String,
      required: true,
    },
    illustration: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.illustration {
  max-height: 235px;
}

.color-shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  clip-path: polygon(55% 0, 100% 0, 100% 82%, 55% 0);
}

.title {
  position: absolute;
  bottom: 40px;
  left: 65px;
}

@media (max-width: 768px) {
  .title {
    left: 40px;
  }

  .illustration {
    max-height: 180px;
  }
}

@media (max-width: 660px) {
  .title {
    bottom: 26px;

    h1 {
      font-size: 20px !important;
    }
    h2 {
      font-size: 16px !important;
    }
  }

  .illustration {
    max-height: 140px;
  }
}

@media (max-width: 570px) {
  .illustration {
    max-height: 100px;
  }
}
</style>
