export const mutations = {
  SET_IS_OPEN: (state, value) => {
    state.isOpen = value
  },
  SET_LOADING: (state, value) => {
    state.loading = value
  },
  SET_DOCUMENT_TO_RENAME: (state, documentToRename) => {
    state.documentToRename = documentToRename
  },
}
