<template>
  <AppTooltip v-if="signatoriesLists.length !== 0" top>
    <template v-slot:activator="{ on, attrs }">
      <ul class="list-raw" v-bind="attrs" v-on="on">
        <li v-for="list in signatoriesLists" :key="list.typeLabel">
          {{ $tc(`project.signing-checklist.list.SigningChecklistMultiEnvelopeSignatoriesList.signatories${list.typeLabel}Total`, list.items.length) }}
        </li>
      </ul>
    </template>
    <div v-for="list in signatoriesLists" :key="list.typeLabel">
      <span class="font-weight-medium">
        {{ $t(`project.signing-checklist.list.SigningChecklistMultiEnvelopeSignatoriesList.signatories${list.typeLabel}Title`) }}
      </span>
      <ul>
        <li v-for="signer in list.items" :key="`signer-${signer.id}`">
          {{ signer.fullName }}
        </li>
      </ul>
    </div>
  </AppTooltip>
</template>

<script>
import AppTooltip from '@/common/AppTooltip'
import { flatten } from '@/common/utils/flatArray'

export default {
  name: 'SigningChecklistMultiEnvelopeSignatoriesList',
  components: { AppTooltip },
  props: {
    signatories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    flatSignatories () {
      return flatten([], this.signatories)
    },
    signatoriesCommonList () {
      return this.flatSignatories.filter(signature => signature.signatureType === 'single')
    },
    signatoriesLists () {
      const lists = []

      if (this.signatoriesCommonList.length !== 0) { lists.push({ typeLabel: 'Common', items: this.signatoriesCommonList }) }
      if (this.signatoriesUniqueList.length !== 0) { lists.push({ typeLabel: 'Unique', items: this.signatoriesUniqueList }) }

      return lists
    },
    signatoriesUniqueList () {
      const filteredSignatories = this.flatSignatories.find(signature => signature.signatureType === 'multiple')
      return filteredSignatories?.signers || []
    },
  },
}
</script>
