export const state = {
  autoTags: null,
  autoTagsError: null,
  autoTagsPending: false,
  changeSignerError: null,
  changeSignerPending: false,
  deleteSignerError: null,
  deleteSignerPendingIds: [],
  deleteTagError: null,
  deleteTagPending: false,
  dragTagActive: {
    value: false,
    tag: null,
  },
  dragTagActiveError: null,
  dragTagActivePending: false,
  duplicateCheckboxPending: false,
  duplicateTagError: null,
  duplicateTagPending: false,
  envelopeDisplayInformation: null,
  envelopeDisplayInformationError: null,
  envelopeDisplayInformationPending: false,
  lastSave: null,
  pageContents: {},
  pageThumbs: {},
  patchSignersOrderPending: false,
  patchTagError: null,
  patchTagPending: false,
  postAutoTagsError: null,
  postAutoTagsPending: false,
  postSignersError: null,
  postSignersPending: false,
  signatureOrderError: null,
  tagError: null,
  tagPending: false,
  taggingInformation: null,
  taggingInformationError: null,
  taggingInformationPending: false,
  tagsOverlaps: [],
}
