<template>
  <div>
    <template v-if="savedChecklistsPending">
      <v-skeleton-loader type="table-thead, table-tbody"/>
    </template>
    <template v-else>
      <v-row v-if="savedChecklists.length" class="mb-2">
        <v-col>
          <SearchTextField v-model="search" class="mb-6" :tooltipText="$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.search')" />

          <v-row>
            <v-col md="7" sm="12">
              <v-data-table :headers="headers"
                            :items="tableItems"
                            :items-per-page="-1"
                            disable-pagination
                            hide-default-footer
                            style="border: 1px solid #EEEEEE;"
              >
                <template #body="{items, headers}">
                  <SavedChecklistTableBody :columns-count="headers.length"
                                           :items="items"
                  />
                </template>
              </v-data-table>
            </v-col>
            <v-col md="5" sm="12">
              <SavedChecklistPreview/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-else class="d-flex flex-column justify-center align-center" style="height: 500px;">
        <template v-if="!savedChecklistsError">
          <font-awesome-icon :icon="['fas', 'tasks']" size="xl"></font-awesome-icon>
          <div class="mt-8">
            {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.savedChecklists.noData') }}
          </div>
        </template>
        <template v-else>
          {{ $t('project.checklist.dialogs.ImportChecklistDialog.getSavedChecklistsError') }}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash-es'
import { mapState } from 'vuex'

import SearchTextField from '@/common/SearchTextField.vue'
import { ISOToShortenedDate } from '@/common/utils/dates'
import { SET_SELECTED_SAVED_CHECKLIST } from '@/store/modules/checklist/mutation_types'

import SavedChecklistPreview from './SavedChecklistPreview.vue'
import SavedChecklistTableBody from './SavedChecklistTableBody.vue'

export default {
  name: 'SavedChecklistTableTemplate',
  components: {
    SearchTextField,
    SavedChecklistPreview,
    SavedChecklistTableBody,
  },
  data () {
    return {
      searchQuery: '',
    }
  },
  computed: {
    ...mapState('checklist', ['savedChecklists', 'selectedSavedChecklist', 'savedChecklistsPending', 'savedChecklistsError']),
    search: {
      get () {
        return this.searchQuery
      },
      set: debounce(function (val) {
        this.searchQuery = val
      }, 1000),
    },
    headers () {
      return [
        {
          value: 'name',
          text: this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.savedChecklists.name'),
          class: ['caption', 'font-weight-semi-bold'],
        },
        {
          value: 'share',
          sortable: false,
          text: this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.savedChecklists.share'),
          class: ['caption', 'font-weight-semi-bold'],
        },
        {
          value: 'createdAt',
          text: this.$t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.savedChecklists.createdOn'),
          class: ['caption', 'font-weight-semi-bold'],
          width: 150,
        },
        {
          sortable: false,
        },
      ]
    },
    tableItems () {
      return this.searchQuery ? this.filteredSearchResult : this.savedChecklists
    },
    filteredSearchResult () {
      if (!this.searchQuery) return []
      let filteredSavedChecklists = cloneDeep(this.savedChecklists)
      const query = this.searchQuery.toLowerCase()
      let isSavedChecklistIncluded = false

      filteredSavedChecklists = filteredSavedChecklists.filter(savedChecklist => {
        const matchesSearch = savedChecklist.name.toLowerCase().includes(query) ||
               ISOToShortenedDate(savedChecklist.createdAt).toLowerCase().includes(query)

        if (matchesSearch && savedChecklist?.name === this.selectedSavedChecklist?.name) {
          isSavedChecklistIncluded = true
        }

        return matchesSearch
      })

      if (!isSavedChecklistIncluded) {
        this.$store.commit(`checklist/${SET_SELECTED_SAVED_CHECKLIST}`, null)
      }

      return filteredSavedChecklists
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-data-table-header {
  background:#EEEEEE;
}
</style>
