<template>
  <v-row class="WatermarkedDocumentsPage"
  >
    <v-col class="WatermarkedDocumentsPage__container text-center position-relative"
    >
      <template v-if="pageExist">
        <div class="WatermarkedDocumentsPage__imageContainer mx-auto position-relative"
             :style="pageSize">
          <img ref="image"
               :src="pageSrc"
               class="WatermarkedDocumentsPage__image w-100"
               :alt="`page ${page}`"
          >
          <v-overlay :absolute="true"
                     color="transparent"
          />
        </div>
      </template>
      <template v-else-if="!isLoading">
        <div v-intersect.quiet="{
              handler: onIntersect,
              options: {
                threshold: 0.2,
              }}"
             class="WatermarkedDocumentsPage__placeholder mx-auto"
             :style="pageSize"
        ></div>
      </template>
      <template v-else>
        <div class="WatermarkedDocumentsPage__placeholder mx-auto d-flex align-center justify-center"
             :style="pageSize"
        >
          <AppLoader/>
        </div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

import AppLoader from '../../../common/AppLoader'
export default {
  name: 'WatermarkedDocumentsPage',
  components: { AppLoader },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pagesContainerWidth: {
      type: Number,
      required: true,
    },
    maxContainerWidth: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      // Size of an A4 page in DB
      defaultPageSize: {
        width: 819,
        height: 1058,
      },
    }
  },
  computed: {
    ...mapState('files', ['watermarkedFile', 'watermarkedFilePendingPages']),
    pageExist () {
      return this.watermarkedFile.pages.find(p => p.page === this.page)
    },
    pageSrc () {
      if (this.pageExist) {
        return `data:image/png;base64, ${this.pageExist.pageB64}`
      }
      return null
    },
    pageSize () {
      const pageWidth = this.pagesContainerWidth > this.maxContainerWidth ? this.maxContainerWidth : this.pagesContainerWidth
      const size = {
        width: `${pageWidth}px`,
      }
      if (this.pageSrc) {
        this.$nextTick(() => {
          size.height = 'auto'
        })
      } else {
        size.height = `${pageWidth * this.defaultPageSize.height / this.defaultPageSize.width}px`
      }
      return size
    },
    isLoading () {
      return this.watermarkedFilePendingPages.some(p => p.page === this.page)
    },
  },
  methods: {
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting) {
        const pagesToRequest = {
          startPage: this.page,
          endPage: this.page + 2 > this.watermarkedFile.totalNbPages ? this.watermarkedFile.totalNbPages : this.page + 2,
        }
        this.$emit('getWatermarkedPages', {
          pagesToRequest,
          fileId: this.watermarkedFile.fileId,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.WatermarkedDocumentsPage {
  &__image,
  &__placeholder {
    max-width: 1000px;
  }
  &__placeholder {
    background-color: #fff;
  }
}
</style>
