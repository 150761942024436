<template>
    <div>
      <div class="d-flex align-center">
        <AppButton icon class="mr-2" @click="expandMembers = !expandMembers">
          <font-awesome-icon v-if="!expandMembers" :icon="['fas', 'caret-right']" size="lg"></font-awesome-icon>
          <font-awesome-icon v-if="expandMembers" :icon="['fas', 'caret-down']" size="lg"></font-awesome-icon>
        </AppButton>
        <v-checkbox v-model="isSelected"
                    :label="group.name"
                    hide-details
                    class="DocumentSharePickerGroup-label mt-0 pt-0"
                    :disabled="(forcePmSelection && group.isPm) ||
                      (forceCurrentUserSelection && group.id === currentUserGroupId) ||
                      !multiSelect"
                    :indeterminate="groupIsPartiallySelected"
        />
      </div>
      <DocumentSharePickerMember v-for="member in group.members"
                                 v-show="expandMembers"
                                 :key="`member-${member.id}`"
                                 :group-selected="isSelected"
                                 :multi-select="multiSelect"
                                 style="padding-left: 80px"
                                 :member="member"
                                 :disabled="(forcePmSelection && group.isPm) ||
                                  (forceCurrentUserSelection && member.id === currentUserId) ||
                                  disabledMembers.some(o => o.id === member.id)"
                                 :group-is-selected="isSelected"
                                 :users-only="usersOnly"
                                 @removeMemberFromSelectedGroup="removeMemberFromSelectedGroup"
      />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { SELECT_GROUP, SELECT_MEMBER, UNSELECT_GROUP } from '@/store/modules/document-share-picker/action_types'

import DocumentSharePickerMember from './DocumentSharePickerMember'
import AppButton from '../buttons/AppButton'

export default {
  name: 'DocumentSharePickerGroup',
  components: { AppButton, DocumentSharePickerMember },
  props: {
    group: {
      type: Object,
      required: true,
    },
    forcePmSelection: {
      type: Boolean,
      required: true,
    },
    forceCurrentUserSelection: {
      type: Boolean,
      required: true,
    },
    usersOnly: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      expandMembers: false,
    }
  },
  computed: {
    ...mapState('documentSharePicker', [
      'selectedGroups',
      'selectedMembers',
      'disabledMembers',
      'highlightedMember',
    ]),
    ...mapGetters('user', ['currentUserId']),
    ...mapGetters('groups', ['currentUserGroupId']),
    isSelected: {
      get () {
        if (this.usersOnly) {
          return this.group.members.length > 0
            ? this.group.members.every(member => {
              return this.selectedMembers.some(selectedMember => selectedMember.id === member.id)
            })
            : false
        } else if (this.forcePmSelection && this.group.isPm) {
          return true
        } else {
          return this.selectedGroups.some(group => group.id === this.group.id)
        }
      },
      set (groupIsSelected) {
        if (groupIsSelected) {
          this.SELECT_GROUP({
            group: this.group,
            userOnly: this.usersOnly,
          })
        } else {
          this.UNSELECT_GROUP({
            group: this.group,
            userOnly: this.usersOnly,
          })
        }
      },
    },
    atLeastOneMemberIsSelected () {
      if (this.group.members.length > 0) {
        return this.group.members.some(member => {
          return this.selectedMembers.find(selectedMember => selectedMember.id === member.id)
        })
      }
      return false
    },
    groupIsPartiallySelected () {
      return this.atLeastOneMemberIsSelected && !this.isSelected
    },
  },
  watch: {
    highlightedMember (value) {
      if (!value) {
        return
      }

      const highlightedMemberIsInGroup = this.group.members.some(
        member => member.id === value.id,
      )

      if (highlightedMemberIsInGroup) {
        this.expandMembers = true
      }
    },
  },
  methods: {
    ...mapActions('documentSharePicker', [SELECT_GROUP, UNSELECT_GROUP, SELECT_MEMBER]),
    removeMemberFromSelectedGroup (memberIdToRemove) {
      /*
      * When we unselect a member from a selected group we have to remove the group from the selectedGroups state in case the group toggle was selected beforehand.
      * A selected group doesn't have its members in the selectedMembers state since it directly maps into selectedGroups.
      * So after removing the group, we need to add all its members except the unselected one in the selectedMembers state to workaround groups and members being separate.
      */
      this.UNSELECT_GROUP({
        group: this.group,
        userOnly: this.usersOnly,
      })
      this.group.members.forEach(member => {
        if (member.id !== memberIdToRemove) {
          this.SELECT_MEMBER({
            member,
            multiselect: this.multiSelect,
          })
        }
      })
    },
  },
}
</script>
