<template>
  <div class="qa-item-thread-add-answer">
    <div class="qa-item-thread-add-answer__title">
      {{$t("project.question-answer.QuestionAnswerItemThreadAnswerAdd.title")}}
    </div>

    <VisualTextEditor ref="answerRichTextField"
                      v-model="newAnswer"
                      qa-variant
    />
    <div class="qa-item-thread-add-answer__actions">
      <AppButton color="primary"
                 :disabled="newAnswerLength === 0"
                 :loading="postAnswerPending"
                 @click="preparePostAnswer"
      >
        {{$t('project.question-answer.QuestionAnswerItemThreadAnswerAdd.submit')}}
      </AppButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import service from '@/services/questionAnswer.service'
import { QuestionAnswerActionTypes } from '@/store/modules/question-answer/action_types'
import { QuestionAnswerMutationTypes } from '@/store/modules/question-answer/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'QuestionAnswerItemThreadAnswerAdd',
  components: { AppButton, VisualTextEditor },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      newAnswer: '',
    }
  },
  computed: {
    ...mapState('questionAnswer', ['question', 'postAnswerPending']),
    newAnswerLength () {
      return this.newAnswer.replace(/(<([^>]+)>)/gi, '').length
    },
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.POST_ANSWER]),
    ...mapMutations('questionAnswer', [QuestionAnswerMutationTypes.SET_QUESTION, QuestionAnswerMutationTypes.ADD_ANSWER_TO_QUESTION]),
    async preparePostAnswer () {
      try {
        const response = await this.POST_ANSWER({
          mnemo: this.mnemo,
          question: this.question,
          data: {
            description: this.newAnswer,
          },
        })

        if (this.question.answers.length === 0) {
          // We don't use GET_QUESTION because we want the update to be hidden from the user
          // TODO Special error handling ?
          const responseQuestion = await service.getQuestion(this.mnemo, this.question.id)
          this.SET_QUESTION(responseQuestion.data.question)
        } else {
          this.ADD_ANSWER_TO_QUESTION({
            questionId: this.question.id,
            answer: response.data.answer,
          })
        }

        this.newAnswer = ''
        this.$refs.answerRichTextField.editor.commands.clearContent()
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.question-answer.QuestionAnswerItemThreadAnswerAdd.postAnswerSuccess'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.question-answer.QuestionAnswerItemThreadAnswerAdd.postAnswerError'),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.qa-item-thread-add-answer {
  &__title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 18px;
  }

  &__actions {
    text-align: right;
    margin-top: 25px;

    button:not(:last-child) {
      margin-right: 16px;
    }
  }
}
</style>
