const SIGNED_STATUS = 'signed'

function getSignersFromSignedFile (signedFile) {
  const signersNames = []
  // Can be falsy if there is no signature
  if (signedFile.mySignature) {
    signersNames.push(signedFile.mySignature.fullName)
  }

  signedFile.otherSignatures.forEach((signature) => {
    signersNames.push(signature.fullName)
  })

  return signersNames
}

/**
* Return an object representing a file ready to be archived from a file and an archiving config id
* @param {object} file - A file which can be an instance of File from the user's device or a file object representing a file already on Closd.
* @param {number} configId - The archiving config id
* @returns {object} A standard representation of a file that is ready to be archived
*/
function formatImportedFile (file, configId) {
  const fileObject = {
    objectToPost: {
      archivingConfigId: configId,
      fileRights: [],
      fileRef: '',
      fileName: file.name,
      notify: false,
      notifyMessage: '',
      fileSigners: [],
      fileSigningDate: '',
      fileIsSigned: false,
    },
    extension: file.extension,
    size: file.size,
    originalFile: file,
    name: file.name,
    signedOnClosd: file.status === SIGNED_STATUS,
    status: 'pending',
  }
  if (file.hasOwnProperty('id')) {
    fileObject.objectToPost.fileRef = file.fileRef
    if (file.status !== SIGNED_STATUS) {
      fileObject.objectToPost.fileId = file.id
      fileObject.objectToPost.fileName = file.basename
      fileObject.extension = file.ext ?? file.extension
    } else {
      fileObject.objectToPost.envId = file.id
      fileObject.objectToPost.fileName = file.name
      fileObject.objectToPost.fileSigningDate = file.time
      fileObject.objectToPost.fileIsSigned = true
      fileObject.size = file.docSize
      fileObject.extension = 'pdf'
      fileObject.objectToPost.fileSigners = getSignersFromSignedFile(file)
    }
  } else if (file instanceof File) {
    fileObject.objectToPost.fileRaw = file
    fileObject.objectToPost.fileName = file.name
    fileObject.extension = file.name.split('.').pop()
    fileObject.status = 'queued'
    fileObject.progress = 0
  }

  return fileObject
}

export {
  formatImportedFile,
}
