<template>
  <AppDialog :is-open="isOpen"
             size="xl"
             :ok-text="$t('project.documents.dialogs.DocumentsExistingTreeDialog.import')"
             :ok-disabled="!selectedFile"
             :ok-loading="postMassFoldersPending"
             :cancel-disabled="postMassFoldersPending"
             @ok="onOk"
             @cancel="onCancel"
  >
    <template #title>{{ $t('project.documents.dialogs.DocumentsExistingTreeDialog.title') }}</template>
    <template #body>
      <v-row>
        <v-col class="text--primary"
v-html="$t('project.documents.dialogs.DocumentsExistingTreeDialog.textInfo')"

></v-col>
      </v-row>
      <v-row>
        <v-col>
          <AppDownloadFile :link="templateLink"
                           class="w-100 h-100"
          >
            <template #default="{submitForm}">
              <div class="ChoiceCard"
                   @click="submitForm"
              >
                <div class="d-inline-flex align-center ml-n3">
                  <div class="mr-3 step-chip">1</div>
                  <div class="text--primary body-1 font-weight-medium">{{ $t('project.documents.dialogs.DocumentsExistingTreeDialog.downloadTemplate') }}</div>
                </div>
                <div class="mt-5">
                  <v-img src="/img/icons/excel_import.svg"
                         class="mx-auto"
                         width="45"
                  />
                </div>
              </div>
            </template>
          </AppDownloadFile>
        </v-col>
        <v-col>
          <div class="ChoiceCard"
               @click="onFileInputClick"
          >
            <div class="d-inline-flex align-center ml-n3">
              <div class="mr-3 step-chip">2</div>
              <div class="text--primary body-1 font-weight-medium">{{ $t('project.documents.dialogs.DocumentsExistingTreeDialog.uploadFile') }}</div>
            </div>
            <div class="mt-5">
              <v-img src="/img/icons/excel_closd.svg"
                     class="mx-auto"
                     width="45"
              />
            </div>
            <div v-if="!selectedFile" class="mt-5 caption text--primary">{{ $t('project.documents.dialogs.DocumentsExistingTreeDialog.uploadedFileInfo') }}</div>
            <div v-else class="mt-5 text--primary">{{selectedFile.name}}</div>
            <input id="fileInput"
                   ref="fileInput"
                   type="file"
                   class="d-none"
                   name="fileInput"
                   accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                   @input="selectFile"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="postFileResponse && postFileResponse.errors">
        <v-col>
          <div class="body-1 text--primary mb-3">{{ $t('project.documents.dialogs.DocumentsExistingTreeDialog.errorsInFile') }}</div>
          <ul style="list-style-position: inside;" class="text-left error--text">
            <li v-for="(error, i) in postFileResponse.errors" :key="`errorLine-${i}`" class="py-1">
              {{ $t('project.documents.dialogs.DocumentsExistingTreeDialog.error.atLine')}} {{Number(i) }}
              <ul style="list-style-position: inside;" class="ml-5">
                <li v-for="(errorDetail, j) in error" :key="`error-${j}`">
                  {{ $t("project.documents.dialogs.DocumentsExistingTreeDialog.error." + errorDetail) }}
                </li>
              </ul>
            </li>
          </ul>
        </v-col>
      </v-row>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'

import AppDialog from '../../../common/dialogs/AppDialog'
import { LOAD_FOLDERS_TREE, GET_DOCUMENTS_BY_ID, GET_NUMBERING, POST_MASS_FOLDERS } from '../../../store/modules/documents/action_types'

export default {
  name: 'DocumentsExistingTreeDialog',
  components: { AppDialog, AppDownloadFile },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      selectedFile: null,
      postFileResponse: null,
    }
  },
  computed: {
    ...mapState('documents', ['postMassFoldersPending', 'numberingEnabled']),
    templateLink () {
      return `${process.env.VUE_APP_API_URL}/resources/massFoldersImportTemplate`
    },
  },
  methods: {
    ...mapActions('documents', [LOAD_FOLDERS_TREE, POST_MASS_FOLDERS, GET_DOCUMENTS_BY_ID, GET_NUMBERING]),
    async onOk () {
      try {
        const formData = new FormData()
        formData.append('file', this.selectedFile, this.selectedFile.name)
        await this.POST_MASS_FOLDERS({
          mnemo: this.mnemo,
          data: formData,
        })
        this.GET_DOCUMENTS_BY_ID({
          mnemo: this.mnemo,
          id: 0,
          queryObject: { markRecent: true },
        })
        if (!this.numberingEnabled) {
          this.GET_NUMBERING(this.mnemo)
        }
        this.LOAD_FOLDERS_TREE()
        this.onCancel()
      } catch (error) {
        console.error(error.response)
        this.postFileResponse = error.response.data
      }
    },
    onCancel () {
      this.selectedFile = null
      this.postFileResponse = null
      this.$refs.fileInput.value = null
      this.$emit('update:isOpen', false)
    },
    onFileInputClick () {
      this.$refs.fileInput.click()
    },
    selectFile (e) {
      this.selectedFile = e.target.files[0]
    },
  },
}
</script>

<style scoped lang="scss">
.ChoiceCard {
  height: 100%;
  text-align: center;
  padding: 20px;
  border: 2px solid #eee;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
    border-color: #4C75B5;
  }
}

.step-chip {
  background: var(--v-tertiary-base);
  color: white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
