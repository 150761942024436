<template>
  <div>
    <div v-if="loading" class="text-center mb-4">
      <v-progress-circular
        style="width: 64px; height: 64px"
        size="64"
        color="primary"
      />
    </div>
    <v-row v-if="!validator.importedFiles.required &&
      validator.importedFiles.$dirty"
    >
      <v-col cols="12">
        <Alert
            type="error"
            :dismissible="false"
            icon
          >
          {{ $t("archives.steps.ArchivesAddStep1.lblDocumentRequired") }}
        </Alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <div class="ArchivesAddStep1-card h-100">
          <h3 class="text-center mt-0 mb-8 ArchivesAddStep1-card-title">
            {{ $t("archives.steps.ArchivesAddStep1.lblClosdFiles") }}
          </h3>
          <img
            class="d-block mx-auto my-4"
            src="/img/folder_file.svg"
            width="95"
          />
          <AppButton
            color="tertiary"
            dark
            small
            :disabled="loading"
            @click="openClosdFileBrowserModal"
          >
            <i class="fa fa-plus mr-2"></i>
            {{ $t("archives.steps.ArchivesAddStep1.btnClosdFiles") }}
          </AppButton>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="ArchivesAddStep1-card h-100"
          @dragover.prevent
          @dragenter.prevent="dragInProgress = true"
          @dragleave.prevent="dragInProgress = false"
          @drop.prevent="onDrop"
        >
          <h3 class="text-center mt-0 mb-8 ArchivesAddStep1-card-title">
            {{ $t("archives.steps.ArchivesAddStep1.lblExternalFiles") }}
          </h3>
          <img src="/img/uploaderIllustration.svg" width="145" />
          <p class="mt-2 mb-0 px-4 font-weight-medium">
            {{ $t("archives.steps.ArchivesAddStep1.lblExternalFilesDrop") }}
          </p>
          <p class="my-2 px-4 font-weight-semi-bold text-uppercase">
            {{ $t("archives.steps.ArchivesAddStep1.lblExternalFilesDropOr") }}
          </p>
            <input
              id="archive-file-loader"
              ref="archiveFileLoader"
              type="file"
              multiple
              class="d-none"
              @change="importFiles($event)"
            />
            <input
              id="archive-folder-loader"
              ref="archiveFolderLoader"
              type="file"
              webkitdirectory
              multiple
              style="display: none"
              @change="importFolderContent($event)"
            />
          <div class="mt-2" >
            <div class="py-0">
              <AppButton
                color="tertiary"
                dark
                small
                class="my-1"
                :disabled="loading"
                @click="
                  () => {
                    this.$refs.archiveFileLoader.click();
                  }
                "
              >
                <i class="fa fa-plus mr-2"></i>
                {{ $t("archives.steps.ArchivesAddStep1.btnExternalFiles") }}
              </AppButton>
            </div>
            <div class="py-0">
              <AppButton
                color="tertiary"
                dark
                small
                class="my-1"
                :disabled="loading"
                @click="
                  () => {
                    this.$refs.archiveFolderLoader.click();
                  }
                "
              >
                <i class="fa fa-plus mr-2"></i>
                {{ $t("archives.steps.ArchivesAddStep1.btnExternalFolder") }}
              </AppButton>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12">
        <AppSelect
          id="archivingConfig"
          outlined
          color="tertiary"
          :value="archivingConfig"
          :label="$t('archives.steps.ArchivesAddStep1.lblArchiveDuration')"
          :error-messages="archivingConfigErrorMessage"
          :hint="$t('archives.steps.ArchivesAddStep1.lblArchiveDurationDescription')"
          persistent-hint
          :items="archivingConfigOptionsItems"
          @change="$emit('archivingConfig', $event)"
        >
        </AppSelect>
      </v-col>
    </v-row>
    <template v-if="importedFiles.length > 0">
      <v-row>
        <v-col cols="12">
          <ArchivesUploadedFileListItem
            v-for="(file, i) in importedFiles"
            :key="`file-${i}`"
            :file="file"
            @deleteFile="deleteFile"
          />
        </v-col>
      </v-row>
    </template>
    <ArchivesClosdFileBrowserDialog
      v-if="closdFileBrowserDialogIsOpened"
      @selectedFiles="importClosdFiles"
      @close="closdFileBrowserDialogIsOpened = false"
    />
  </div>
</template>

<script>
import { getFilesFromDataTransferItems } from 'datatransfer-files-promise'
import { createNamespacedHelpers } from 'vuex'

import { formatImportedFile } from '@/common/utils/archives'

import ArchivesClosdFileBrowserDialog from './ArchivesClosdFileBrowserDialog'
import ArchivesUploadedFileListItem from './ArchivesUploadedFileListItem'
import Alert from '../../common/alerts/Alert.vue'
import AppSelect from '../../common/AppSelect'
import AppButton from '../../common/buttons/AppButton'

const { mapGetters } = createNamespacedHelpers('archives')

export default {
  name: 'ArchivesAddStep1',
  components: {
    ArchivesClosdFileBrowserDialog,
    ArchivesUploadedFileListItem,
    Alert,
    AppButton,
    AppSelect,
  },
  props: {
    validator: {
      type: Object,
      required: true,
    },
    importedFiles: {
      type: Array,
      required: true,
    },
    archivingConfig: {
      required: true,
      validator: (value) => {
        return value === null || typeof value === 'object'
      },
    },
  },
  data () {
    return {
      dragInProgress: false,
      loading: false,
      closdFileBrowserDialogIsOpened: false,
    }
  },
  computed: {
    ...mapGetters(['sections']),
    archivingConfigState () {
      if (!this.validator.archivingConfig.$anyDirty) {
        return true
      } else if (this.validator.archivingConfig.$anyError) {
        return false
      }
      return true
    },
    archivingConfigErrorMessage () {
      return this.archivingConfigState
        ? []
        : [this.$t('archives.steps.ArchivesAddStep1.lblArchiveDurationRequired')]
    },
    archivingConfigOptionsItems () {
      return this.sections && this.sections.map((config) => ({
        value: config,
        text:
          `${config.duration} ${this.$tc(
            'archives.steps.ArchivesAddStep1.lblYears',
            config.duration,
          )}`,
      }))
    },
  },
  methods: {
    importFiles (e) {
      this.loading = true
      const queue = [...this.importedFiles]
      for (const f of this.$refs.archiveFileLoader.files) {
        queue.push(formatImportedFile(f, this.archivingConfig.id))
      }
      this.$emit('updateUploadedFiles', queue)
      this.loading = false
      this.$refs.archiveFileLoader.value = ''
    },
    importFolderContent (e) {
      this.loading = true
      for (const f of this.$refs.archiveFolderLoader.files) {
        this.importedFiles.push(formatImportedFile(f, this.archivingConfig.id))
      }
      this.loading = false
      this.$refs.archiveFolderLoader.value = ''
    },
    importClosdFiles (e) {
      this.loading = true
      for (const f of e) {
        this.importedFiles.push((formatImportedFile(f, this.archivingConfig.id)))
      }
      this.loading = false
    },
    async onDrop (e) {
      let files = e.dataTransfer ? e.dataTransfer.items : 'null'
      if (files) {
        this.loading = true
        const droppedFiles = await getFilesFromDataTransferItems(files)
        files = [...this.importedFiles, ...droppedFiles.map((file) => formatImportedFile(file, this.archivingConfig.id))]
        this.$emit('updateUploadedFiles', files)
        this.loading = false
      }
    },
    deleteFile (e) {
      try {
        const fileToDelete = this.importedFiles.findIndex(f => f === e)
        this.importedFiles.splice(fileToDelete, 1)
      } catch (e) {
        console.error(e)
      }
    },
    openClosdFileBrowserModal () {
      this.closdFileBrowserDialogIsOpened = true
    },
  },
}
</script>

<style scoped lang="scss">
.ArchivesAddStep1-card {
  border: 2px dashed #d8d8d8;
  border-radius: 0.35rem;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.ArchivesAddStep1-card-title {
  font-size: 17px;
  font-weight: normal;
}
</style>
