export const REMOVE_PATCH_SUB_ADMIN_BILLING_PENDING = 'REMOVE_PATCH_SUB_ADMIN_BILLING_PENDING'
export const REMOVE_PATCH_SUB_ADMIN_PENDING = 'REMOVE_PATCH_SUB_ADMIN_PENDING'
export const REMOVE_PATCH_SUB_ADMIN_USER_PENDING = 'REMOVE_PATCH_SUB_ADMIN_USER_PENDING'
export const SET_ACTIVE_ROOMS = 'SET_ACTIVE_ROOMS'
export const SET_ACTIVE_ROOMS_ERROR = 'SET_ACTIVE_ROOMS_ERROR'
export const SET_ACTIVE_ROOMS_PENDING = 'SET_ACTIVE_ROOMS_PENDING'
export const SET_CLOSED_ROOMS = 'SET_CLOSED_ROOMS'
export const SET_CLOSED_ROOMS_ERROR = 'SET_CLOSED_ROOMS_ERROR'
export const SET_CLOSED_ROOMS_PENDING = 'SET_CLOSED_ROOMS_PENDING'
export const SET_DELETE_USER_ERROR = 'SET_DELETE_USER_ERROR'
export const SET_DELETE_USER_PENDING = 'SET_DELETE_USER_PENDING'
export const SET_DOWNLOAD_INVOICES_ERROR = 'SET_DOWNLOAD_INVOICES_ERROR'
export const SET_DOWNLOAD_INVOICES_PENDING = 'SET_DOWNLOAD_INVOICES_PENDING'
export const SET_PATCH_SUB_ADMIN_BILLING_ERROR = 'SET_PATCH_SUB_ADMIN_BILLING_ERROR'
export const SET_PATCH_SUB_ADMIN_BILLING_PENDING = 'SET_PATCH_SUB_ADMIN_BILLING_PENDING'
export const SET_PATCH_SUB_ADMIN_ERROR = 'SET_PATCH_SUB_ADMIN_ERROR'
export const SET_PATCH_SUB_ADMIN_PENDING = 'SET_PATCH_SUB_ADMIN_PENDING'
export const SET_PATCH_SUB_ADMIN_USER_ERROR = 'SET_PATCH_SUB_ADMIN_USER_ERROR'
export const SET_PATCH_SUB_ADMIN_USER_PENDING = 'SET_PATCH_SUB_ADMIN_USER_PENDING'
export const SET_POST_NEW_SUBSCRIBER_ERROR = 'SET_POST_NEW_SUBSCRIBER_ERROR'
export const SET_POST_NEW_SUBSCRIBER_PENDING = 'SET_POST_NEW_SUBSCRIBER_PENDING'
export const SET_POST_SUBSCRIPTION_TEAM_PENDING = 'SET_POST_SUBSCRIPTION_TEAM_PENDING'
export const SET_PATCH_SUBSCRIPTION_TEAM_PENDING = 'SET_PATCH_SUBSCRIPTION_TEAM_PENDING'
export const SET_SELECTED_SUBSCRIPTION_TEAM = 'SET_SELECTED_SUBSCRIPTION_TEAM'
export const SET_SUBSCRIPTION_TEAMS_PENDING = 'SET_SUBSCRIPTION_TEAMS_PENDING'
export const SET_SUBSCRIPTION_TEAMS = 'SET_SUBSCRIPTION_TEAMS'
export const SET_SUBSCRIPTION_TEAMS_ERROR = 'SET_SUBSCRIPTION_TEAMS_ERROR'
export const SET_QUOTE_REQUEST_PENDING = 'SET_QUOTE_REQUEST_PENDING'
export const SET_RESTORE_ROOM_ERROR = 'SET_RESTORE_ROOM_ERROR'
export const SET_RESTORE_ROOM_PENDING = 'SET_RESTORE_ROOM_PENDING'
export const SET_SEATS = 'SET_SEATS'
export const SET_SEATS_ERROR = 'SET_SEATS_ERROR'
export const SET_RESTORE_ROOM_DIALOG = 'SET_RESTORE_ROOM_DIALOG'
export const SET_SEATS_PENDING = 'SET_SEATS_PENDING'
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
export const SET_SUBSCRIPTION_BRANDING = 'SET_SUBSCRIPTION_BRANDING'
export const SET_SUBSCRIPTION_ERROR = 'SET_SUBSCRIPTION_ERROR'
export const SET_SUBSCRIPTION_INVOICES = 'SET_SUBSCRIPTION_INVOICES'
export const SET_SUBSCRIPTION_INVOICES_ERROR = 'SET_SUBSCRIPTION_INVOICES_ERROR'
export const SET_SUBSCRIPTION_INVOICES_PENDING = 'SET_SUBSCRIPTION_INVOICES_PENDING'
export const SET_SUBSCRIPTION_PENDING = 'SET_SUBSCRIPTION_PENDING'
export const SET_SUBSCRIPTION_BRANDING_PENDING = 'SET_SUBSCRIPTION_BRANDING_PENDING'
export const SET_SUBSCRIPTION_USERS = 'SET_SUBSCRIPTION_USERS'
export const SET_SUBSCRIPTION_USERS_ERROR = 'SET_SUBSCRIPTION_USERS_ERROR'
export const SET_SUBSCRIPTION_USERS_PENDING = 'SET_SUBSCRIPTION_USERS_PENDING'
export const SET_USER_SUB_ADMIN = 'SET_USER_SUB_ADMIN'
export const SET_USER_SUB_ADMIN_BILLING = 'SET_USER_SUB_ADMIN_BILLING'
export const SET_USER_SUB_ADMIN_USER = 'SET_USER_SUB_ADMIN_USER'
export const SET_CLOSED_ROOMS_FILTERS = 'SET_CLOSED_ROOMS_FILTERS'
export const REMOVE_CLOSED_ROOMS_FILTER = 'REMOVE_CLOSED_ROOMS_FILTER'
export const SET_CLOSED_ROOMS_FILTERS_PANE_IS_OPEN = 'SET_CLOSED_ROOMS_FILTERS_PANE_IS_OPEN'
export const SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN = 'SET_SUBSCRIPTION_TEAMS_PANE_IS_OPEN'
export const SET_ACTIVE_ROOMS_FILTERS = 'SET_ACTIVE_ROOMS_FILTERS'
export const REMOVE_ACTIVE_ROOMS_FILTER = 'REMOVE_ACTIVE_ROOMS_FILTER'
export const SET_ACTIVE_ROOMS_FILTERS_PANE_IS_OPEN = 'SET_ACTIVE_ROOMS_FILTERS_PANE_IS_OPEN'
export const SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG = 'SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG'
export const SET_DELETE_SUBSCRIPTION_TEAM_PENDING = 'SET_DELETE_SUBSCRIPTION_TEAM_PENDING'
export const SET_DELETE_TEAM_DIALOG = 'SET_DELETE_TEAM_DIALOG'
export const REMOVE_SUBSCRIPTION_TEAM = 'REMOVE_SUBSCRIPTION_TEAM'
