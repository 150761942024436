<template>
  <v-alert class="AppBanner pa-4"
           close-icon="far fa-xmark"
           :color="backgroundColor"
           dismissible
  >
    <div class="d-flex align-center">
      <div>
        <app-icon :icon-name="computedIcon"
                  icon-weight="fas"
                  :style="{color: color}"
                  class="mr-4"
        />
      </div>
      <div>
        <div class="AppAlert-title font-weight-semi-bold"
             :style="{color: color}"
        >
          <!-- @slot Use this slot for title -->
          <slot name="title"></slot>
        </div>
        <div class="AppAlert-text">
          <!-- @slot Use this slot for banner text -->
          <slot></slot>
        </div>
      </div>
    </div>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { AppAlertBackgroundColor, AppAlertColor, AppAlertIcon } from '@/design-system/alert/enums'
import { AppAlertVariant } from '@/design-system/alert/types'

/**
 * @displayName Banner
 */
export default defineComponent({
  name: 'AppBanner',
  props: {
    /**
     * Variant of the Banner.
     * @values info, success, error or warning
     */
    variant: {
      type: String as PropType<AppAlertVariant>,
      default: 'info',
    },
  },
  computed: {
    computedIcon (): string {
      return AppAlertIcon[this.variant].valueOf()
    },
    backgroundColor (): string {
      return AppAlertBackgroundColor[this.variant].valueOf()
    },
    color (): string {
      return AppAlertColor[this.variant].valueOf()
    },
  },
})
</script>

<style scoped lang="scss">
.AppBanner {
  border-radius: 0;

  ::v-deep .v-alert__dismissible i.v-icon {
    font-size: 14px;
    width: 14px;
    height: 14px;
  }
}
</style>

<docs>
```vue
<template>
  <v-container fluid>
    <v-row>
      <v-col class="text-h1">
        Banner
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h2">
        Usage
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>System or product level notifications that are not specific to a task.</p>
        <p>
          Use banners at the top of the screen to display critical messaging about the loss of data, functionality, or important site-wide information that affects the user's ability to use the product.
          Banners should appear one at a time, are not dismissible, and only disappear when no longer required. Banners animate into a screen by pushing the entire content below down.
        </p>
        <p>
          <b>Interaction :</b>
          <ul>
            <li>Persist until the message is resolved</li>
          </ul>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="mb-2">Info</p>
        <app-banner variant="info" class="mb-4">
          <template #title>I’m an Alert Usage Example!</template>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.
        </app-banner>
        <p class="mb-2">Success</p>
        <app-banner variant="success" class="mb-4">
          <template #title>I’m an Alert Usage Example!</template>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.
        </app-banner>
        <p class="mb-2">Error</p>
        <app-banner variant="error" class="mb-4">
          <template #title>I’m an Alert Usage Example!</template>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.
        </app-banner>
        <p class="mb-2">Warning</p>
        <app-banner variant="warning" class="mb-4">
          <template #title>I’m an Alert Usage Example!</template>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.
        </app-banner>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
  }
</script>
```
</docs>
