/**
     * Represents a login.
     * @constructor
     * @param {string} email - User email.
     * @param {string} password - User password
     * @param {boolean} rememberMe - store user
     * @param {boolean} acceptPrivacy - accept privacy doc
     * @param {boolean} acceptTOS - accept terms of services
     * @param {string} passwordConfirmation - password confirmation
     * @param {string} token - token get by url
     */
export const Login = function (
  email = '',
  password = '',
  rememberMe = '',
  acceptprivacy = '',
  accepttos = '',
  passwordconfirmation = '',
  token = '',
) {
  this.email = email
  this.password = password
  this.passwordConfirmation = passwordconfirmation
  this.token = token
  this.rememberMe = rememberMe
  this.acceptPrivacy = acceptprivacy
  this.acceptTOS = accepttos
}
