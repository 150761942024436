<template>
  <div>
    <div class="d-flex align-center">
      <span v-if="item.signInOrder" class="mr-1">{{ signatory.signingOrder }}.</span>
      <app-icon v-if="signatory.status !== 'signed'" icon-name="clock" icon-weight="far" size="small" class="tertiary--text mr-1"/>
      <AppTooltip v-if="signatory.status === 'signed'" top>
        <template #activator="{attrs, on}">
          <app-icon icon-name="circle-check" icon-weight="fas" class="success--text" size="small mr-1" v-bind="attrs" v-on="on"/>
        </template>
        <span>{{ $t('project.project-settings.signatures.ProjectSettingsSignaturesSignatoriesList.userSignedTooltip', { date: getSignatureDate(signatory) }) }}</span>
      </AppTooltip>
      <span>{{ signatory.fullName }}</span>
      <app-button v-if="signatory.status === 'sent'"
                  :disabled="signatoryChaseButtonIsDisabled(signatory.signatureId)"
                  :loading="signatoryChaseLoading && signatoryChaseLoading === signatory.signatureId"
                  class="ml-1 mb-1"
                  size="small"
                  type="tonal"
                  variant="brand"
                  @click.stop="prepareChaseSignatory(item, signatory)"
      >
        {{ $t('common.chase') }}
      </app-button>
    </div>
    <div v-for="witness in witnessesList"
         :key="`witness-${witness.id}`"
         class="d-flex align-center ml-6"
    >
      <span v-if="item.signInOrder" class="mr-1">{{ witness.signingOrder }}.</span>
      <app-icon v-if="witness.status !== 'signed'" icon-name="clock" icon-weight="far" size="small" class="tertiary--text mr-1"/>
      <AppTooltip v-if="witness.status === 'signed'" top>
        <template #activator="{attrs, on}">
          <app-icon icon-name="circle-check" icon-weight="fas" class="success--text" size="small mr-1" v-bind="attrs" v-on="on"/>
        </template>
        <span>{{ $t('project.project-settings.signatures.ProjectSettingsSignaturesSignatoriesList.userSignedTooltip', { date: getSignatureDate(witness) }) }}</span>
      </AppTooltip>
      <span>{{ witness.fullName }}</span>
      <app-button v-if="signatory.status === 'signed' && witness.status === 'sent'"
                  :disabled="signatoryChaseButtonIsDisabled(witness.signatureId)"
                  :loading="signatoryChaseLoading && signatoryChaseLoading === witness.signatureId"
                  class="ml-1 mb-1"
                  size="small"
                  type="tonal"
                  variant="brand"
                  @click.stop="prepareChaseSignatory(item, witness)"
      >
        {{ $t('common.chase') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import { ISOToShortDate } from '@/common/utils/dates'
import { CHASE_USERS } from '@/store/modules/room/action_types'
import { ADD_CHASE_TIMER_SIGNATURE } from '@/store/modules/room/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'ProjectSettingsSignaturesSignatory',
  props: {
    signatory: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      signatoryChaseLoading: null,
    }
  },
  components: {
    AppTooltip,
  },
  computed: {
    ...mapState('room', ['chaseTimers']),
    ...mapGetters('room', ['roomMnemo']),
    witnessesList () {
      return this.item.signatories.filter(s => s.signatureType === 'witness' && s.witnessOfSignerId === this.signatory.id)
    },
  },
  methods: {
    ...mapActions('room', [CHASE_USERS]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    getSignatureDate (signatory) {
      return ISOToShortDate(signatory.timestamp)
    },
    async prepareChaseSignatory (envelope, signatory) {
      // TODO : Handle pendingIDS with vuex-action
      this.signatoryChaseLoading = signatory.signatureId
      try {
        await this.CHASE_USERS({
          mnemo: this.roomMnemo,
          envelopeId: envelope.id,
          data: {
            usersIds: [signatory.id],
          },
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('common.chaseSuccess'))
        this.$store.commit(`room/${ADD_CHASE_TIMER_SIGNATURE}`, signatory.signatureId)
      } catch (error) {
        console.error(error)
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.chaseError'))
      } finally {
        this.signatoryChaseLoading = null
      }
    },
    signatoryChaseButtonIsDisabled (signatureId) {
      const signatureChaseTimer = this.chaseTimers.signature?.[signatureId]
      return signatureChaseTimer && dayjs().isBefore(signatureChaseTimer)
    },
  },
}
</script>

<style lang="scss" scoped>
.ProjectSettingsSignatures-listDot {
  background-color: var(--v-accent-base);
  border-radius: 50%;
  display: inline-block;
  height: 5px;
  margin-right: 5px;
  vertical-align: middle;
  width: 5px;
}
</style>
