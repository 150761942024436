export enum QuestionAnswerMutationTypes {
    ADD_ANSWER_TO_QUESTION = 'ADD_ANSWER_TO_QUESTION',
    REMOVE_FILTER = 'REMOVE_FILTER',
    REMOVE_QUESTION_FROM_LIST = 'REMOVE_QUESTION_FROM_LIST',
    SET_CATEGORIES = 'SET_CATEGORIES',
    SET_CATEGORIES_ERROR = 'SET_CATEGORIES_ERROR',
    SET_CATEGORIES_PENDING = 'SET_CATEGORIES_PENDING',
    SET_DELETE_QUESTION_ERROR = 'SET_DELETE_QUESTION_ERROR',
    SET_DELETE_QUESTION_PENDING = 'SET_DELETE_QUESTION_PENDING',
    SET_FILTERS = 'SET_FILTERS',
    SET_FILTERS_PANE_IS_OPEN = 'SET_FILTERS_PANE_IS_OPEN',
    SET_IMPORT_QUESTIONS_ERROR = 'SET_IMPORT_QUESTIONS_ERROR',
    SET_IMPORT_QUESTIONS_PENDING = 'SET_IMPORT_QUESTIONS_PENDING',
    SET_MARK_QUESTION_AS_ANSWERED_PENDING = 'SET_MARK_QUESTION_AS_ANSWERED_PENDING',
    SET_PATCH_QUESTION_ERROR = 'SET_PATCH_QUESTION_ERROR',
    SET_PATCH_QUESTION_PENDING = 'SET_PATCH_QUESTION_PENDING',
    SET_PATCH_QUESTION_RIGHTS_ERROR = 'SET_PATCH_QUESTION_RIGHTS_ERROR',
    SET_PATCH_QUESTION_RIGHTS_PENDING = 'SET_PATCH_QUESTION_RIGHTS_PENDING',
    SET_QUESTIONS_BULK_RIGHTS_PENDING = 'SET_QUESTIONS_BULK_RIGHTS_PENDING',
    SET_POST_ANSWER_ERROR = 'SET_POST_ANSWER_ERROR',
    SET_POST_ANSWER_PENDING = 'SET_POST_ANSWER_PENDING',
    SET_POST_QUESTION_ERROR = 'SET_POST_QUESTION_ERROR',
    SET_POST_QUESTION_PENDING = 'SET_POST_QUESTION_PENDING',
    SET_QUESTION = 'SET_QUESTION',
    SET_QUESTIONS = 'SET_QUESTIONS',
    SET_QUESTIONS_ERROR = 'SET_QUESTIONS_ERROR',
    SET_QUESTIONS_PENDING = 'SET_QUESTIONS_PENDING',
    SET_QUESTION_ERROR = 'SET_QUESTION_ERROR',
    SET_QUESTION_PENDING = 'SET_QUESTION_PENDING',
    SET_UPDATE_QUESTION_IN_LIST = 'SET_UPDATE_QUESTION_IN_LIST',
    SET_UPDATE_QUESTION_IN_LIST_ERROR = 'SET_UPDATE_QUESTION_IN_LIST_ERROR',
    SET_UPDATE_QUESTION_IN_LIST_PENDING = 'SET_UPDATE_QUESTION_IN_LIST_PENDING',
}
