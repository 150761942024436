<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-radio-group
          :value="envelopeType"
          required
          :disabled="isEditingLimited"
          class="envelopeType__container"
          row
          active-class="envelopeType__card--active"
          :error-messages="envelopeTypeErrorMessages"
          @change="envelopeTypeChange"
        >
          <div v-for="option in envelopeTypeOptions"
               :key="`option-${option.value}`"
               class="d-flex flex-column"
          >
            <AppTooltip
              :key="option.value"
              :disabled="!option.disabled"
              top
            >
              <template #activator="{attrs, on}">
                <div v-bind="attrs" v-on="on">
                  <v-radio
                    :value="option.value"
                    class="envelopeType__card"
                    :class="{'envelopeType__card--disabled': option.disabled}"
                    :disabled="option.disabled"
                  >
                    <template v-slot:label>
                      <div class="w-100 d-flex align-center justify-space-between">
                        <h4>{{ $t(`project.signing-checklist.add.step2.Section3.envelopeTypeOptions.${option.value}`) }}</h4>
                        <span v-if="option.value === ENVELOPE_TYPES.SINGLE && isMultiEnvelopeChild">
                          ({{ $t('common.envelopeType.fromBulk') }})
                        </span>
                      </div>
                    </template>
                  </v-radio>
                </div>
              </template>
             <span>{{ $t('project.signing-checklist.add.step2.Section3.envelopeMultipleDSQOptionDisabledTooltip') }}</span>
            </AppTooltip>
            <span class="mt-2 originalGrey--text text--darken-2">{{ $t(`project.signing-checklist.add.step2.Section3.envelopeTypeOptions.${option.value}Info`) }}</span>
          </div>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { createNamespacedHelpers } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import { ENVELOPE_TYPES, SIGNATURE_TYPE_DOCUSIGN_QUALIFIED } from '@/project/signing-checklist/constants'
import { SET_IMPORTED_FILE_ENVELOPE_TYPE } from '@/store/modules/signing-checklist/mutation_types'

type EnvelopeTypeOptions = {
  value: string
  disabled: boolean
}

type DataType = {
  ENVELOPE_TYPES
  envelopeTypeOptions: EnvelopeTypeOptions[]
}

const { mapMutations: mapSigningChecklistMutations } = createNamespacedHelpers('signingChecklist')

export default Vue.extend({
  name: 'Section3',
  components: { AppTooltip },
  props: {
    fileId: {
      type: Number,
      required: true,
    },
    envelopeType: {
      type: String,
      required: true,
    },
    isEditingLimited: {
      type: Boolean,
      required: true,
    },
    isMultiEnvelopeChild: {
      type: Boolean,
      required: true,
    },
    envelopeTypeValidator: {
      type: Object,
      required: true,
    },
    signatureType: {
      type: String,
      required: true,
    },
  },
  data (): DataType {
    return {
      ENVELOPE_TYPES,
      envelopeTypeOptions: [],
    }
  },
  computed: {
    envelopeTypeErrorMessages (): TranslateResult[] {
      if (this.envelopeTypeValidator.$dirty &&
        !this.envelopeTypeValidator.required) {
        return [this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.signing-checklist.add.step2.Section3.envelopeTypeLabel') })]
      }
      return []
    },
  },
  methods: {
    ...mapSigningChecklistMutations([SET_IMPORTED_FILE_ENVELOPE_TYPE]),
    envelopeTypeChange (value) {
      this.SET_IMPORTED_FILE_ENVELOPE_TYPE({
        fileId: this.fileId,
        value,
      })
    },
    setEnvelopeTypeOptions (): void {
      for (const value in ENVELOPE_TYPES) {
        const isDisabled = value === ENVELOPE_TYPES.MULTIPLE.toUpperCase() && this.signatureType === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED
        this.envelopeTypeOptions.push({ value: ENVELOPE_TYPES[value], disabled: isDisabled })
      }
    },
  },
  mounted () {
    this.setEnvelopeTypeOptions()
  },
  watch: {
    signatureType (newValue, old) {
      this.envelopeTypeOptions = this.envelopeTypeOptions.map(env =>
        env.value === ENVELOPE_TYPES.MULTIPLE ? { ...env, disabled: newValue === SIGNATURE_TYPE_DOCUSIGN_QUALIFIED } : env,
      )
    },
  },
})
</script>

<style scoped lang="scss">
.envelopeType__container {

  ::v-deep .v-input--radio-group__input {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }

  .envelopeType__card {
    justify-self: stretch;
    min-height: 72px;
    padding: 16px 24px;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    margin: 0;

    &--active {
      outline: 2px solid var(--v-primary-base);
      outline-offset: -2px;
      background-color: var(--v-primary-lighten4);
    }

    &--disabled {
      ::v-deep * {
        color: #D4D7DA;
      }
    }

    .caption {
      color: #717B85;
      font-size: 12px;
    }
  }
}
</style>
