<template>
  <Pane v-model="tableTaskPane.isOpen"
        temporary
        class="ChecklistTableTaskPane"
        :tabs="tabs"
        :current-tab.sync="tab"
        eager
        @closeButtonClicked="closePane"
  >
    <template #content-share>
      <ChecklistTableTaskPaneShare :mnemo="mnemo"/>
    </template>
    <template #content-move>
      <ChecklistTableTaskPaneMove :mnemo="mnemo"/>
    </template>
    <template #content-duplicate>
      <ChecklistTableTaskPaneDuplicate :mnemo="mnemo"/>
    </template>
  </Pane>
</template>

<script>
import { mapState } from 'vuex'

import ChecklistTableTaskPaneDuplicate from './ChecklistTableTaskPaneDuplicate'
import ChecklistTableTaskPaneMove from './ChecklistTableTaskPaneMove'
import ChecklistTableTaskPaneShare from './ChecklistTableTaskPaneShare'
import Pane from '../../../common/Pane'
import { SET_TABLE_TASK_PANE, SET_TABLE_TASK_PANE_TAB } from '../../../store/modules/checklist/mutation_types'
export default {
  name: 'ChecklistTableTaskPane',
  components: { ChecklistTableTaskPaneMove, ChecklistTableTaskPaneDuplicate, ChecklistTableTaskPaneShare, Pane },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('checklist', ['tableTaskPane']),
    tab: {
      get () {
        return this.tableTaskPane.tab
      },
      set (val) {
        this.$store.commit(`checklist/${SET_TABLE_TASK_PANE_TAB}`, val)
      },
    },
    tabs () {
      const tabs = [
        {
          title: this.$t('common.share'),
          id: 'share',
        },
      ]
      if (this.tableTaskPane.documents.every(item => item.type === 'file')) {
        tabs.push({
          title: this.$t('project.checklist.pane.ChecklistTableTaskPane.move'),
          id: 'move',
        })
      }
      if (this.tableTaskPane.documents.length === 1 && this.tableTaskPane.documents[0].type === 'file') {
        tabs.push({
          title: this.$t('project.checklist.pane.ChecklistTableTaskPane.duplicate'),
          id: 'duplicate',
        })
      }
      return tabs
    },
  },
  methods: {
    closePane () {
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: false,
        tab: '',
        documents: [],
        parent: null,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableTaskPane {
  z-index: 210 !important;
}
</style>
