<template>
  <AppButton text
             v-bind="$attrs"
             v-on="$listeners"
  >
    <!-- @slot for button text -->
    <slot></slot>
  </AppButton>
</template>

<script>
import AppButton from '@/common/buttons/AppButton.vue'

export default {
  name: 'SnackBarButton',
  components: { AppButton },
}
</script>

<style scoped lang="scss">
  ::v-deep.v-btn {
    text-transform: uppercase;
  }
</style>
