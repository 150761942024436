<template>
  <AppDialog :is-open="isDialogOpened"
             :ok-loading="duplicateEnvelopeTemplatePending"
             :ok-text="$t('project.signing-checklist.envelope-templates.dialogs.DuplicateDialog.duplicate')"
             :cancel-disabled="duplicateEnvelopeTemplatePending"
             @ok="prepareDuplicateEnvelopeTemplate"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('project.signing-checklist.envelope-templates.dialogs.DuplicateDialog.title') }}</template>
    <template #body>
      <AppTextField v-model="$v.copyName.$model"
                    :label="$t('project.signing-checklist.envelope-templates.dialogs.DuplicateDialog.labelName')"
                    :counter="MAX_NAME_LENGTH"
                    maxLength="100"
                    :error-messages="copyNameErrors"
      />
    </template>
  </AppDialog>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapMutations, mapState } from 'vuex'

import AppTextField from '@/common/AppTextField.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

const MAX_NAME_LENGTH = 100

export default {
  name: 'DuplicateDialog',
  components: { AppTextField, AppDialog },
  mixins: [dialogMixin, validationMixin],
  validations: {
    copyName: { required, maxLength: maxLength(MAX_NAME_LENGTH) },
  },
  props: {
    envelopeTemplate: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      MAX_NAME_LENGTH,
      copyName: this.envelopeTemplate.title,
    }
  },
  computed: {
    ...mapState('envelopeTemplates', ['duplicateEnvelopeTemplatePending']),
    copyNameErrors () {
      const errors = []
      if (!this.$v.copyName.$dirty) return errors
      !this.$v.copyName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.signing-checklist.envelope-templates.dialogs.DuplicateDialog.labelName') }))
      !this.$v.copyName.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.signing-checklist.envelope-templates.dialogs.DuplicateDialog.labelName'), maxLength: MAX_NAME_LENGTH }))
      return errors
    },
  },
  methods: {
    ...mapActions('envelopeTemplates', ['DUPLICATE_ENVELOPE_TEMPLATE']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async prepareDuplicateEnvelopeTemplate () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        try {
          await this.DUPLICATE_ENVELOPE_TEMPLATE({
            envelopeTemplateId: this.envelopeTemplate.id,
            data: {
              title: this.copyName,
            },
          })

          this.closeDialog()
        } catch (error) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.signing-checklist.envelope-templates.dialogs.DuplicateDialog.duplicateEnvelopeTemplateError'))
        }
      }
    },
  },
}
</script>
