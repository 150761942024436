<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             @cancel="closeDialog"
             @ok="confirmClick"
  >
    <template #title>{{ $t('project.signing-checklist.add.step2.SigningChecklistAddStep2SignatoryWarningDialog.title') }}</template>

    <template #body>
      <p>{{ $t('project.signing-checklist.add.step2.SigningChecklistAddStep2SignatoryWarningDialog.commonText') }}</p>
      <p v-if="hasMultipleSignersError">{{ $t('project.signing-checklist.add.step2.SigningChecklistAddStep2SignatoryWarningDialog.hasMultipleSignersError') }}</p>
      <p class="mb-0">{{ $t('project.signing-checklist.add.step2.SigningChecklistAddStep2SignatoryWarningDialog.confirmText') }}</p>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import { ENVELOPE_TYPES } from '@/project/signing-checklist/constants'

export default {
  name: 'SigningChecklistAddStep2SignatoryWarningDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasMultipleSignersError () {
      return this.files.some((file) => file.objectToPost.envelopeType === ENVELOPE_TYPES.MULTIPLE)
    },
  },
  methods: {
    confirmClick () {
      this.$emit('confirm')
      this.closeDialog()
    },
  },
}
</script>
