<template>
  <div>
    <template v-if="isPending">
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            hide-on-leave
            class="mx-auto mb-2"
            type="image"
            height="40"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </template>
    <v-row v-else-if="folders.length">
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="12">
            <div class="ProjectsFolders-title">{{ $t('projects.ProjectsHeader.folders') }}</div>
          </v-col>
        </v-row>
          <v-container fluid class="py-4 px-0">
            <ProjectsFoldersTemplate :folders="folders"></ProjectsFoldersTemplate>
          </v-container>
      </v-col>
    </v-row>

    <!-- DIALOGS -->
    <DocumentsDeleteDialog
      v-if="projectsFolderDeleteDialogIsOpen"
      @close="closeProjectsFolderDeleteDialog"
      @validate-deletion="onDeletionValidation"
    />
    <DocumentsRenameDialog v-if="projectsFolderRenameDialogIsOpen"/>
    <ProjectsMoveRoomDialog v-if="projectsFolderMoveDialogIsOpen"/>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DocumentsDeleteDialog from '@/project/documents/dialogs/DocumentsDeleteDialog.vue'
import DocumentsRenameDialog from '@/project/documents/dialogs/DocumentsRenameDialog.vue'
import ProjectsMoveRoomDialog from '@/projects/dialogs/ProjectsMoveRoomDialog.vue'
import { GET_PROJECTS_FOLDER_BY_ID, LOAD_PROJECTS_FOLDERS_TREE } from '@/store/modules/projects-folders/action_types'
import { DELETE_PROJECTS_FOLDER } from '@/store/modules/projects-folders-delete/action_types'
import {
  SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN,
  SET_PROJECTS_FOLDER_TO_DELETE,
} from '@/store/modules/projects-folders-delete/mutation_types'
import { GET_ROOMS_PROGRESSIVELY } from '@/store/modules/rooms/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

import ProjectsFoldersTemplate from './ProjectsFoldersTemplate.vue'

export default {
  name: 'ProjectsFolders',
  components: {
    ProjectsFoldersTemplate,
    DocumentsDeleteDialog,
    DocumentsRenameDialog,
    ProjectsMoveRoomDialog,
  },
  computed: {
    ...mapState('projectsFolders', ['projectsFoldersTree', 'getProjectsFoldersTreePending', 'currentProjectsFolder', 'projectsFolderByIdPending']),
    ...mapState('projectsFoldersRename', ['projectsFolderRenameDialogIsOpen']),
    ...mapState('projectsFoldersDelete', ['projectsFolderDeleteDialogIsOpen', 'projectsFolderToDelete']),
    ...mapState('projectsFoldersMove', ['projectsFolderMoveDialogIsOpen']),
    isPending () {
      return this.projectsFolderByIdPending || this.getProjectsFoldersTreePending
    },
    folders () {
      return this.currentProjectsFolder.children || this.projectsFoldersTree
    },
  },
  methods: {
    ...mapActions('projectsFolders', [LOAD_PROJECTS_FOLDERS_TREE, GET_PROJECTS_FOLDER_BY_ID]),
    ...mapActions('projectsFoldersDelete', [DELETE_PROJECTS_FOLDER]),
    ...mapActions('rooms', [GET_ROOMS_PROGRESSIVELY]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    ...mapMutations('projectsFoldersDelete', [SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN, SET_PROJECTS_FOLDER_TO_DELETE]),
    closeProjectsFolderDeleteDialog () {
      this.SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN(false)
      this.SET_PROJECTS_FOLDER_TO_DELETE({})
    },
    async onDeletionValidation () {
      try {
        await this.DELETE_PROJECTS_FOLDER(this.projectsFolderToDelete.id)

        if (this.currentProjectsFolder.id) {
          try {
            this.GET_PROJECTS_FOLDER_BY_ID({
              folderId: this.currentProjectsFolder.id,
            })
          } catch (error) {
            this.ENQUEUE_ERROR_SNACKBAR(
              this.$t('projects.ProjectsFolders.getFolderError',
              { name: this.currentProjectsFolder.name }),
            )
          }
        } else {
          try {
            this.LOAD_PROJECTS_FOLDERS_TREE()
          } catch (error) {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.ProjectsFolders.getProjectsFoldersTreeError'))
          }
          this.GET_ROOMS_PROGRESSIVELY()
        }
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.ProjectsFolders.deleteFolderError'))
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ProjectsFolders-title {
  font-size: 21px;
  font-weight: 600;
}
</style>
