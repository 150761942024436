<template>
  <div style="display:flex; justify-content: center;">
    <div class="lawyer-card">
      <img :src="lawyer.avatar"  height="137"  class="mb-4"/>
      <h3 class="grey-darken4">{{ lawyer.name }}</h3>
      <p class="mb-0 mt-1 grey-darken2">{{ lawyer?.profession }}</p>
      <p class="grey-darken2">{{ lawyer.office }}</p>
      <img src="/img/landing-page/quote_icon.svg" class="my-3"/>
      <p class="quote">{{ lawyer.quote }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'LawyerCardItem',
    props: {
        lawyer: {
        type: Object,
        required: true,
        },
    },
  }
</script>

<style lang="scss" scoped>
.lawyer-card {
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 20px;
  border-radius: 19px;
  background: white;
  box-shadow: 0px 2px 28px 0px #444A5014;
  text-align: center;
  margin: 40px;
  margin-bottom: 20px;
  @media #{map-get($display-breakpoints, 'xs-only')}{
    margin: 40px 20px;
  }
}
.quote {
  font-weight: 400;
  font-size: 16px;
  color: var(--v-grey-darken3);
}
.grey-darken2 {
  color: var(--v-grey-darken2);
}
.grey-darken4 {
  color: var(--v-grey-darken4);
}
</style>
