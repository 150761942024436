<template>
  <div>
    <v-row>
      <v-col>
        <v-card ref="content" style="height: 75vh" class="overflow-y-scroll" elevation="1" >
          <v-stepper
            v-model="stepActive"
            alt-labels
            class="mx-auto"
          >
            <v-stepper-header style="max-width: 1280px" class="mx-auto">
              <template v-for="i in 4">
                <v-stepper-step :key="'step' + i" :step="i">
                  {{ $t(`archives.views.ArchivesAdd.step${i}Title`) }}
                </v-stepper-step>
                <v-divider v-if="i !== 4" :key="i"></v-divider>
              </template>
            </v-stepper-header>
            <div v-show="creationLoading">
              <div class="d-flex justify-center">
                <v-progress-circular
                  style="width: 64px; height: 64px"
                  color="primary"
                  indeterminate
                  size="64"
                />
              </div>
            </div>
            <v-stepper-items>
              <v-stepper-content step="1">
                <ArchivesAddStep1
                  v-if="stepActive === 1 && !creationLoading"
                  ref="step1"
                  :importedFiles="importedFiles"
                  :archivingConfig="archivingConfig"
                  :validator="$v"
                  @archivingConfig="onUpdatedArchivingConfig"
                  @updateUploadedFiles="importedFiles = $event"
                />
              </v-stepper-content>
              <v-stepper-content step="2">
                <ArchivesAddStep2
                  v-if="stepActive === 2"
                  :importedFiles="importedFiles"
                />
              </v-stepper-content>
              <v-stepper-content step="3">
                <ArchivesAddStep3
                  v-if="stepActive === 3"
                  v-model="importedFiles"
                />
              </v-stepper-content>
              <v-stepper-content step="4">
                <ArchivesAddStep4
                  v-if="stepActive === 4"
                  ref="step4"
                  :importedFiles="importedFiles"
                  @uploadStarted="uploadStarted = true"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
        <div
          class="d-flex mt-2"
          :class="{
            'justify-space-between': stepActive > 1,
            'justify-end': stepActive === 1,
          }"
        >
          <AppButton
            v-if="stepActive > 1"
            :disabled="uploadStarted"
            color="white"
            @click="stepActive--"
          >
            <i
              v-if="$vuetify.breakpoint.mdAndUp"
              class="fal mr-2 fa-arrow-left-long"
            ></i>
            {{ $t(`common.previous`) }}
          </AppButton>
          <AppButton
            color="primary"
            :disabled="uploadStarted"
            @click="checkStepValidation"
          >
            {{ stepActive === 4 ? $t(`archives.views.ArchivesAdd.buttonLastStep`) : $t(`common.next`) }}
            <i
              v-if="stepActive < 4 && $vuetify.breakpoint.mdAndUp"
              class="ml-2 fal fa-arrow-right-long"
            ></i>
          </AppButton>
        </div>
      </v-col>
    </v-row>
    <ArchivesAddWarningDialog
      v-if="showWarningDialog"
      @confirm="$refs.step4.startUpload"
      @close="showWarningDialog = false"
    />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { createNamespacedHelpers, mapActions, mapState } from 'vuex'

import { formatImportedFile } from '@/common/utils/archives'
import { GET_BIBLE } from '@/store/modules/bibles/action_types'
import { GET_SIGNED_DOCUMENTS } from '@/store/modules/signed-documents/action_types'

import AppButton from '../../common/buttons/AppButton.vue'
import { GET_FILE } from '../../store/modules/files/action_types'
import ArchivesAddStep1 from '../steps/ArchivesAddStep1'
import ArchivesAddStep2 from '../steps/ArchivesAddStep2'
import ArchivesAddStep3 from '../steps/ArchivesAddStep3'
import ArchivesAddStep4 from '../steps/ArchivesAddStep4'
import ArchivesAddWarningDialog from '../steps/ArchivesAddWarningDialog'

const { mapGetters } = createNamespacedHelpers('archives')
const { mapActions: mapFilesActions } = createNamespacedHelpers('files')
const { mapActions: mapBiblesActions } = createNamespacedHelpers('bibles')
const { mapActions: mapSignedDocumentsActions } = createNamespacedHelpers('signedDocuments')
export default {
  name: 'ArchivesAdd',
  components: {
    ArchivesAddStep1,
    ArchivesAddStep2,
    ArchivesAddStep3,
    ArchivesAddStep4,
    ArchivesAddWarningDialog,
    AppButton,
  },
  mixins: [validationMixin],
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    bibles: {
      type: Array,
      default: () => [],
    },
    signedDocuments: {
      type: Array,
      default: () => [],
    },
  },
  validations: {
    archivingConfig: { required },
    importedFiles: { required },
  },
  data () {
    return {
      stepActive: 1,
      importedFiles: [],
      archivingConfig: {},
      uploadStarted: false,
      showWarningDialog: false,
      creationLoading: false,
    }
  },
  computed: {
    ...mapGetters(['sections']),
    ...mapState('room', ['currentRoom']),
  },
  watch: {
    stepActive () {
      this.$refs.content.$el.scrollTop = 0
    },
  },
  async created () {
    if (this.sections && this.sections.length === 1) {
      this.archivingConfig = this.sections[0]
    }
    const { query } = this.$route
    const filePromises = []
    const biblePromises = []
    let newFiles = this.files
    let newBibles = this.bibles
    let newSignedDocuments = this.signedDocuments
    try {
      // If we navigated here through the application's runtime,
      // the files are passed with their information already through the router
      // Otherwise, we only have the file ids in the URL left and have to fetch
      // their information.
      // TODO: We could use a route for files and bibles would take ids as params to
      // get in only one roundtrip all the elements needed
      // This approach currently assumes we don't have a lot of elements and
      // avoids any ids that doesn't exist / errors
      if (query.fromMnemo) {
        await this.getRoomByMnemo({ mnemo: query.fromMnemo, params: null })
      }
      if (this.files.length === 0 && query.fileIds) {
        const mnemo = query.fromMnemo
        const fileIdsArray = query.fileIds.split(',')
        for (const fileId of fileIdsArray) {
          filePromises.push(this.GET_FILE({
            mnemo,
            id: fileId,
          }))
        }
        const promisedFiles = await Promise.allSettled(filePromises)
        newFiles = []
        promisedFiles.forEach((promisedFile) => {
          if (promisedFile.status === 'fulfilled') {
            newFiles.push(promisedFile.value)
          }
        })
      }
      if (this.bibles.length === 0 && query.bibleIds) {
        const mnemo = query.fromMnemo
        const bibleIdsArray = query.bibleIds.split(',')
        for (const bibleId of bibleIdsArray) {
          biblePromises.push(this.GET_BIBLE({
            mnemo,
            bibleId,
          }))
        }
        const promisedBibles = await Promise.allSettled(biblePromises)
        newBibles = []
        promisedBibles.forEach((promisedBible) => {
          if (promisedBible.status === 'fulfilled') {
            newBibles.push(promisedBible.value.data)
          }
        })
      }

      if (this.signedDocuments.length === 0 && query.signedDocumentIds) {
        const mnemo = query.fromMnemo
        const signedDocumentIdsQuery = query.signedDocumentIds
        newSignedDocuments = await this.GET_SIGNED_DOCUMENTS({
          mnemo,
          params: {
            onlyIds: signedDocumentIdsQuery,
          },
        })
      }
      newFiles =
        newFiles.map((file) => formatImportedFile(file, this.archivingConfig.id))
      newBibles =
        newBibles.map((bible) => this.formatBibleToImportedFile(bible))
      // Signed documents are already handled since it's a possible input from the picker
      newSignedDocuments =
        newSignedDocuments.map((signedDocument) => formatImportedFile(signedDocument, this.archivingConfig.id))
      this.importedFiles = newFiles.concat(newBibles, newSignedDocuments)
      if (this.currentRoom?.cmRef) {
        this.importedFiles = this.importedFiles.map(obj => ({
           ...obj,
           objectToPost: {
          ...obj.objectToPost,
          fileRef: this.currentRoom.cmRef,
         },
        }))
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.creationLoading = false
    }
  },
  methods: {
    ...mapActions('room', ['getRoomByMnemo']),
    ...mapFilesActions([GET_FILE]),
    ...mapBiblesActions([GET_BIBLE]),
    ...mapSignedDocumentsActions([GET_SIGNED_DOCUMENTS]),
    formatBibleToImportedFile (bible) {
      return {
        objectToPost: {
          bibleId: bible.id,
          archivingConfigId: this.archivingConfig.id,
          fileRights: [],
          fileRef: '',
          fileName: bible.name,
          notify: false,
          notifyMessage: '',
          fileSigners: [],
          fileSigningDate: '',
          fileIsSigned: false,
        },
        extension: 'zip',
        size: bible.size,
        originalFile: bible,
        name: bible.name,
        signedOnClosd: false,
        status: 'pending',
      }
    },
    onUpdatedArchivingConfig (event) {
      this.$v.archivingConfig.$model = event
      this.importedFiles.forEach((file) => {
        file.objectToPost.archivingConfigId = event.id
      })
    },
    checkStepValidation () {
      if (this.stepActive === 1) {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.stepActive++
        }
      } else if (this.stepActive === 2) {
        this.stepActive++
      } else if (this.stepActive === 3) {
        this.stepActive++
      } else if (this.stepActive === 4) {
        if (!this.$store.state.archives.muteArchivingWarning) {
          this.showWarningDialog = true
        } else {
          this.$refs.step4.startUpload()
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ArchiveAdd-title {
  font-size: 1.3rem;
  font-weight: bold;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
</style>
