<template>
  <AppDialog :is-open="isDialogOpened"
             :cancel-text="$t('common.close')"
             cancel-only
             @cancel="closeDialog"
  >
    <template #title>{{ $t('project.signing-checklist.add.step2.SigningChecklistAddStep2MissingRecipientWarningDialog.title') }}</template>

    <template #body>
      <p>{{ $t('project.signing-checklist.add.step2.SigningChecklistAddStep2MissingRecipientWarningDialog.warning') }}</p>
      <ul>
        <li v-for="(file, i) in filesWithoutRecipients"
            :key="`file-${i}`"
            class="my-1"
        >
          {{ file.name }}
        </li>
      </ul>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from '../../../../common/dialogs/AppDialog.vue'
import { dialogMixin } from '../../../../mixins/dialogMixin'

export default {
  name: 'SigningChecklistAddStep2MissingRecipientWarningDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    importedFiles: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filesWithoutRecipients () {
      return this.importedFiles.filter(f => f.objectToPost?.recipients?.length === 0)
    },
  },
}
</script>
