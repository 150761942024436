<template>
  <a :href="link" target="_blank" class="copyright-link">&copy; {{ $t('common.CopyrightLink.imageBy', { author }) }}</a>
</template>

<script>
export default {
  name: 'CopyrightLink',
  props: {
    author: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.copyright-link {
  color: var(--v-grey-base);
  text-decoration: none;
  font-size: 10px;
}
</style>
