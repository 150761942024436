<template>
  <div>
    <template v-if="signersToDisplay.length > 0">
      <template v-if="item.objectToPost.fileIsSigned && !item.signedOnClosd">
        <div class="Closd-hoverArea" @click="addSignersDialogIsOpen = true">
          <span>{{ signersToDisplay }}</span>
          <span v-if="noDisplayedSigners">,
            {{
              $tc("archives.steps.ArchivesAddStep2TableCellSigners.lblNoDisplayedSigners", noDisplayedSigners, {
                nb: noDisplayedSigners,
              })
            }}</span
          >
        </div>
      </template>
      <template v-else>
        <span>{{ signersToDisplay }}</span>
        <span v-if="noDisplayedSigners">,
          {{
            $tc("archives.steps.ArchivesAddStep2TableCellSigners.lblNoDisplayedSigners", noDisplayedSigners, {
              nb: noDisplayedSigners,
            })
          }}</span
        >
      </template>
    </template>
    <template v-else-if="item.objectToPost.fileIsSigned && !item.signedOnClosd">
      <div class="Closd-hoverArea" @click="addSignersDialogIsOpen = true">
        <span class="font-italic text--secondary">{{
          $t("archives.steps.ArchivesAddStep2TableCellSigners.lblClickToAddSigners")
        }}</span>
      </div>
    </template>
    <ArchivesAddStep2AddSignersDialog
      v-if="addSignersDialogIsOpen"
      :signers="item.objectToPost.fileSigners"
      @newSignersArray="item.objectToPost.fileSigners = $event"
      @close="addSignersDialogIsOpen = false"
    />
  </div>
</template>

<script>
import ArchivesAddStep2AddSignersDialog from '../dialogs/ArchivesAddStep2AddSignersDialog'

export default {
  name: 'ArchivesAddStep2TableCellSigners',
  components: { ArchivesAddStep2AddSignersDialog },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      addSignersDialogIsOpen: false,
    }
  },
  computed: {
    signersToDisplay () {
      return this.item.objectToPost.fileSigners.length > 3
        ? this.item.objectToPost.fileSigners.slice(0, 3).join(', ')
        : this.item.objectToPost.fileSigners.slice(0, this.item.objectToPost.fileSigners.length).join(', ')
    },
    noDisplayedSigners () {
      return this.item.objectToPost.fileSigners.length > 3
        ? this.item.objectToPost.fileSigners.length - 3
        : null
    },
  },
}
</script>
