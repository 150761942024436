var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"app-badge",class:{
           'app-badge--small': _vm.size === 'small',
           'app-badge--medium': _vm.size === 'medium',
           'app-badge--neutral': _vm.variant === 'neutral',
           'app-badge--danger': _vm.variant === 'danger',
           'app-badge--info': _vm.variant === 'info',
        }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }