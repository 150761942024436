import axios from 'axios'

export default {
  getSubscription (subId) {
    return axios.get(`/subscription/${subId}?billing`)
  },
  getSubscriptionBranding (subdomain) {
    return axios.get(`/subscription/branding/${subdomain}`)
  },
  getSubscriptionUsers () {
    return axios.get('/subscription/users')
  },
  patchSubAdmin (userId, data) {
    return axios.patch(`/subscription/user/${userId}/admin`, data)
  },
  patchSubAdminBilling (userId, data) {
    return axios.patch(`/subscription/user/${userId}/billingAdmin`, data)
  },
  patchSubAdminUser (userId, data) {
    return axios.patch(`/subscription/user/${userId}/user`, data)
  },
  deleteUser (userId) {
    return axios.delete(`/subscription/user/${userId}`)
  },
  postNewSubscriber (data) {
    return axios.post('/subscription/self/user', data)
  },
  getActiveRooms () {
    return axios.get('/subscription/rooms')
  },
  getClosedRooms () {
    return axios.get('/subscription/rooms/closed')
  },
  postSubscriptionTeam (data) {
    return axios.post('/subscription/team', data)
  },
  patchSubscriptionTeam (teamId, data) {
    return axios.patch(`/subscription/team/${teamId}`, data)
  },
  deleteSubscriptionTeam (teamId) {
    return axios.delete(`/subscription/team/${teamId}`)
  },
  getSubscriptionTeams () {
    return axios.get('/subscription/teams')
  },
  getSeats () {
    return axios.get('/subscription/seats')
  },
  getSubscriptionInvoices () {
    return axios.get('/subscription/invoices')
  },
  downloadInvoices (data) {
    return axios.get('/subscription/download/invoices', { params: data })
  },
  restoreRoom (roomId, data) {
    return axios.patch(`/subscription/room/${roomId}/restore`, data)
  },
  requestFeatureQuote (data) {
    return axios.post('/contact/form', data)
  },
}
