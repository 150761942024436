<template>
  <div class="documents-selection">
    <app-text as="span" variant="large-bold">{{$tc(`${TRANSLATION_KEY_PREFIX}.title`, selectedDocuments.length)}}</app-text>
    <DocumentSelectionList :selected-documents="selectedDocuments"
                           :removable="removable"
                           @remove="onRemove"
    >
      <slot name="item"></slot>
    </DocumentSelectionList>
  </div>
</template>

<script>
import DocumentSelectionList from '@/common/document-selection/DocumentSelectionList'

const TRANSLATION_KEY_PREFIX = 'common.document-selection.DocumentSelection'

export default {
  name: 'DocumentSelection',
  components: {
    DocumentSelectionList,
  },
  props: {
    removable: {
      type: Boolean,
    },
    selectedDocuments: {
      type: Array,
      required: true,
    },
  },
  data () {
  return {
      TRANSLATION_KEY_PREFIX,
    }
  },
  methods: {
    onRemove (documentId) {
      this.$emit('remove', documentId)
    },
  },
}
</script>

<style lang="scss" scoped>
.documents-selection {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
</style>
