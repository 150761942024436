import {
  SET_DOCUMENTS,
  SET_IS_OPEN,
  SET_LOADING,
} from './mutation_types'

export const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_IS_OPEN] (state, value) {
    state.isOpen = value
  },
  [SET_DOCUMENTS] (state, documents) {
    state.documents = documents
  },
}
