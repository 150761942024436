import {
  ADD_BIBLE,
  REMOVE_BIBLE_PENDING,
  SET_BIBLES,
  SET_BIBLES_ERROR,
  SET_BIBLES_PENDING,
  SET_BIBLE_ERROR,
  SET_BIBLE_PENDING,
  SET_EXPORT_BIBLE_TO_IMANAGE_ERROR,
  SET_EXPORT_BIBLE_TO_IMANAGE_PENDING,
  SET_POST_BIBLE_PENDING,
} from './mutation_types'

export const mutations = {
  [ADD_BIBLE] (state, value) {
    state.bibles.push(value)
  },
  [REMOVE_BIBLE_PENDING] (state, index) {
    state.biblePendingIds.splice(index, 1)
  },
  [SET_BIBLE_ERROR] (state, value) {
    state.bibleError = value
  },
  [SET_BIBLE_PENDING] (state, id) {
    state.biblePendingIds.push(id)
  },
  [SET_BIBLES] (state, value) {
    state.bibles = value
  },
  [SET_BIBLES_ERROR] (state, value) {
    state.biblesError = value
  },
  [SET_BIBLES_PENDING] (state, value) {
    state.biblesPending = value
  },
  [SET_EXPORT_BIBLE_TO_IMANAGE_ERROR] (state, value) {
    state.exportBibleToImanageError = value
  },
  [SET_EXPORT_BIBLE_TO_IMANAGE_PENDING] (state, value) {
    state.exportBibleToImanagePending = value
  },
  [SET_POST_BIBLE_PENDING] (state, value) {
    state.postBiblePending = value
  },
}
