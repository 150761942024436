export const SET_FORGOT_PASSWORD_PENDING = 'SET_FORGOT_PASSWORD_PENDING'
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD'
export const SET_FORGOT_PASSWORD_ERROR = 'SET_FORGOT_PASSWORD_ERROR'
export const SET_ACTIVATE_ACCOUNT_PENDING = 'SET_ACTIVATE_ACCOUNT_PENDING'
export const SET_ACTIVATE_ACCOUNT = 'SET_ACTIVATE_ACCOUNT'
export const SET_ACTIVATE_ACCOUNT_ERROR = 'SET_ACTIVATE_ACCOUNT_ERROR'
export const SET_RESET_PASSWORD_PENDING = 'SET_RESET_PASSWORD_PENDING'
export const SET_RESET_PASSWORD = 'SET_RESET_PASSWORD'
export const SET_RESET_PASSWORD_ERROR = 'SET_RESET_PASSWORD_ERROR'
export const SET_SIGNIN_PENDING = 'SET_SIGNIN_PENDING'
export const SET_SIGNIN = 'SET_SIGNIN'
export const SET_SIGNIN_ERROR = 'SET_SIGNIN_ERROR'
export const SET_LOADING_PAGE = 'SET_LOADING_PAGE'
export const SET_TWO_FACTOR_ERROR = 'SET_TWO_FACTOR_ERROR'
export const SET_AUTH_FROM_TOKEN_PENDING = 'SET_AUTH_FROM_TOKEN_PENDING'
export const SET_AUTH_FROM_TOKEN_ERROR = 'SET_AUTH_FROM_TOKEN_ERROR'
export const SET_USER_TOKEN_SCOPE = 'SET_USER_TOKEN_SCOPE'
export const SET_STEP = 'SET_STEP'
