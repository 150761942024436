<template>
  <component :is="headlineTag" :class="computedClass">
    <!-- @slot for headline text -->
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

type HeadlineLevel = 1 | 2 | 3

/**
 * @displayName Headline
 */
export default defineComponent({
  name: 'AppHeadline',
  props: {
    /**
     * Level of the headline tag
     * Possible values are : 1 (h1), 2 (h2) and 6 (h6)
     */
    level: {
      type: Number as PropType<HeadlineLevel>,
      default: 1,
    },
  },
  computed: {
    headlineTag (): string {
      switch (this.level) {
        case 2:
          return 'h2'
        case 3:
          return 'h3'
        default:
          return 'h1'
      }
    },
    computedClass (): string {
      return `headline-${this.level}`
    },
  },
})
</script>

<style lang="scss" scoped>
.headline {
  &-1 {
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.25px;
  }

  &-2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  &-3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }
}
</style>

<docs>
```vue
<template>
  <div>
    <div v-for="headline in headlines" class="typo-presenter">
      <AppHeadline class="typo-presenter__headline" :level="headline.level">
        {{ headline.text }}
      </AppHeadline>

      <div class="typo-presenter__characteristics">
        <div class="typo-presenter__characteristic">
          <span class="typo-presenter__characteristic-name">Font</span>
          <span class="typo-presenter__characteristic-value">{{ headline.font }}</span>
        </div>

        <div class="typo-presenter__characteristic">
          <span class="typo-presenter__characteristic-name">Weight</span>
          <span class="typo-presenter__characteristic-value">{{ headline.weight }}</span>
        </div>

        <div class="typo-presenter__characteristic">
          <span class="typo-presenter__characteristic-name">Size</span>
          <span class="typo-presenter__characteristic-value">{{ headline.size }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AppHeadline from './AppHeadline'

  export default {
    components: { AppHeadline },
    data () {
      return {
        headlines: [
          {
            level: 1,
            text: 'Headline 1',
            font: 'Roboto',
            weight: 'Regular 400',
            size: '34px (2,125rem)'
          },
          {
            level: 2,
            text: 'Headline 2',
            font: 'Roboto',
            weight: 'Medium 500',
            size: '24px (1,5rem)'
          },
          {
            level: 3,
            text: 'Headline 3',
            font: 'Roboto',
            weight: 'Medium 500',
            size: '18px (1,125rem)'
          }
        ],
      }
    },
  }
</script>

<style lang="scss">
  .typo-presenter {
    width: 450px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .typo-presenter__headline {
    border-bottom: 1px solid #000;
    margin-bottom: 8px;
  }

  .typo-presenter__characteristics {
    display: flex;
  }

  .typo-presenter__characteristic {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-name {
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      color: var(--v-grey-lighten1);
    }
  }

  .typo-presenter__characteristic-name {
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    color: var(--v-grey-lighten1);
  }

  .typo-presenter__characteristic-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: var(--v-grey-base);
  }
</style>
```
</docs>
