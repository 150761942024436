<template>
  <span class="app-badge"
        :class="{
             'app-badge--small': size === 'small',
             'app-badge--medium': size === 'medium',
             'app-badge--neutral': variant === 'neutral',
             'app-badge--danger': variant === 'danger',
             'app-badge--info': variant === 'info',
          }"
  >
    <slot></slot>
  </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

export type AppBadgeSize = 'small' | 'medium'
export type AppBadgeVariant = 'danger' | 'info' | 'neutral'

/**
 * @displayName Principal Button
 */
export default Vue.extend({
  name: 'AppBadge',
  inheritAttrs: false,
  props: {
    /**
     * Size of the badge
     * Possible values are : small, medium
     */
    size: {
      type: String as PropType<AppBadgeSize>,
      default: 'medium',
    },
    /**
     * Color variant
     * Possible values are : danger, info, neutral
     */
    variant: {
      type: String as PropType<AppBadgeVariant>,
      default: 'neutral',
    },
  },
})
</script>

<style scoped lang="scss">
.app-badge {
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  line-height: 1.5;
  padding: 0 4px;
  text-transform: uppercase;
  user-select: none;

  &.app-badge--small {
    font-size: 12px;
    height: 16px;
  }

  &.app-badge--medium {
    font-size: 14px;
    height: 20px;
  }

  &.app-badge--neutral {
    background-color: var(--v-grey-lighten5);
    color: var(--v-grey-darken2);
  }

  &.app-badge--danger {
    background-color: var(--v-error-base);
    color: var(--v-white-base);
  }

  &.app-badge--info {
    background-color: var(--v-info-darken1);
    color: var(--v-white-base);
  }
}
</style>
