<template>
  <div class="save-checklist-dialog-share-type">
    <v-radio-group
      v-model="shareType"
      hide-details
      active-class="share-type-card--active"
      class="share-type-container"
      :class="containerClasses"
      required
      row
    >
      <div v-for="option in shareTypeOptionsValues"
            :key="`option-${option}`"
            class="d-flex flex-column"
      >
        <v-radio :value="option"
                  class="share-type-card mb-2"
        >
          <template v-slot:label>
            <h4>
              <template v-if="withoutPrivateOption">
                {{ $t(`project.checklist.dialogs.ImportChecklistDialog.accessRights.shareTypeOptions.${option}`) }}
              </template>
              <template v-else>
                {{ $t(`project.checklist.dialogs.SaveChecklistDialog.sharingOptions.shareTypeOptions.${option}`) }}
              </template>
            </h4>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
  import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes.ts'

  export default {
    name: 'SaveChecklistDialogShareType',
    props: {
      defaultShareType: {
        type: String,
        required: true,
      },
      withoutPrivateOption: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        shareType: this.defaultShareType,
      }
    },
    computed: {
      shareTypeOptionsValues () {
        const values = Object.values(SHARE_TYPE_OPTIONS)
        if (this.withoutPrivateOption) {
          return values.filter(item => item !== SHARE_TYPE_OPTIONS.PRIVATE)
        }
        return values
      },
      containerClasses () {
        return {
          'share-type-container-col-2': this.withoutPrivateOption,
          'share-type-container-col-3': !this.withoutPrivateOption,
        }
      },
    },
    watch: {
      shareType (value) {
        this.$emit('change-share-type', value)
      },
    },
  }
</script>

<style lang="scss">
  .save-checklist-dialog-share-type {
    padding: 0;
    .share-type-card {
      padding: $spacer * 4 $spacer * 2;
    }

    .share-type-container {
      margin-top: $spacer * 4;
      margin-bottom: 0;

      .v-input--radio-group__input {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(1, 1fr);
      }

      .share-type-card {
        border: 1px solid #DEE2E6;
        border-radius: 4px;
        margin: 0;

        &--active {
          outline: 2px solid var(--v-primary-base);
          outline-offset: -2px;
          background-color: var(--v-primary-lighten4);
        }

        .caption {
          color: #717B85;
          font-size: 12px;
        }
      }
    }

    .share-type-container-col-2 {
      @media screen and (min-width: 1024px) {
        .v-input--radio-group__input {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .share-type-container-col-3 {
      @media screen and (max-width: 1023px) and (min-width: 768px) {
        .v-input--radio-group__input {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      @media screen and (min-width: 1024px) {
        .v-input--radio-group__input {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
</style>
