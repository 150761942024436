<template>
  <div>
    <AppButton color="primary"
               class="mr-4"
               @click="dialogIsOpen = true"
    >
      <font-awesome-icon class="mr-2" :icon="['far', 'plus']"></font-awesome-icon>
      {{ $t('subscriptions.AddSubscriberDialog.newUser') }}
    </AppButton>
    <AppDialog :is-open="dialogIsOpen"
               size="xl"
               :ok-disabled="postNewSubscriberPending"
               :ok-loading="postNewSubscriberPending"
               :cancel-disabled="postNewSubscriberPending"
               @ok="preparePostSSubscriber"
               @cancel="resetAndClose"
    >
      <template #title>{{ $t('subscriptions.AddSubscriberDialog.title') }}</template>
      <template #body>
        <div v-if="seatsPending && !confirmationDialogOpen" class="text-center">
          <v-progress-circular indeterminate color="primary" size="48" />
        </div>
        <template v-else>
          <v-row>
            <v-col>
              <div v-html="$t('common.requiredFieldsInfo')"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <form novalidate @submit.stop.prevent="preparePostSSubscriber"
              >
                <v-row>
                  <v-col>
                    <AppTextField v-model.trim="newSubscriber.firstName"
                                  :label="$t('common.form.firstName')"
                                  class="required"
                                  :error-messages="newSubscriberFirstNameErrors"
                                  maxlength="50"
                                  @blur="$v.newSubscriber.firstName.$touch"
                    ></AppTextField>
                  </v-col>
                  <v-col>
                    <AppTextField v-model.trim="newSubscriber.lastName"
                                  :label="$t('common.form.lastName')"
                                  class="required"
                                  :error-messages="newSubscriberLastNameErrors"
                                  maxlength="50"
                                  @blur="$v.newSubscriber.lastName.$touch"
                    ></AppTextField>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <AppTextField v-model.trim="newSubscriber.company"
                                  :label="$t('common.form.company')"
                                  maxlength="100"
                    ></AppTextField>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <AppTextField v-model.trim="newSubscriber.email"
                                  :label="$t('common.form.email')"
                                  class="required"
                                  type="email"
                                  :error-messages="newSubscriberEmailErrors"
                                  maxlength="255"
                                  @blur="$v.newSubscriber.email.$touch"
                    ></AppTextField>
                  </v-col>
                  <v-col>
                    <AppSelect v-model="newSubscriber.locale"
                              :items="localeOptions"
                              class="required"
                              :label="$t('common.form.locale')"
                              :error-messages="newSubscriberLocaleErrors"
                              @blur="$v.newSubscriber.locale.$touch"
                    >
                      <template #selection="{item}">
                        <div class="d-flex align-center">
                          <v-img :src="item.icon" width="25" class="mr-2"></v-img>
                          <span>{{ item.text }}</span>
                        </div>
                      </template>
                      <template #item="{item}">
                        <div class="d-flex align-center">
                          <v-img :src="item.icon" width="25" class="mr-2"></v-img>
                          <span>{{ item.text }}</span>
                        </div>
                      </template>
                    </AppSelect>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="newSubscriber.isUser"
                                class="mt-0 pt-0"
                                :loading="seatsPending"
                                hide-details
                    >
                      <template #label>
                        <span>{{$t('subscriptions.AddSubscriberDialog.subscriptionUser')}}</span>
                        <AppHelpTooltip
                          :text="$t('subscriptions.AddSubscriberDialog.subscriptionUserTooltip', {name: subscription.name})"
                        >
                        </AppHelpTooltip>
                        <span v-if="nextPrice" class="">{{$t('subscriptions.AddSubscriberDialog.licensePriceAbbr', { price: nextPrice })}}</span>
                      </template>
                    </v-checkbox>
                    <v-checkbox v-model="newSubscriber.isAdmin"
                                class="mt-2 pt-0"
                                hide-details
                    >
                      <template #label>
                        <span>{{$t('subscriptions.AddSubscriberDialog.subscriptionAdmin')}}</span>
                        <AppHelpTooltip :text="$t('subscriptions.AddSubscriberDialog.subscriptionAdminTooltip')"></AppHelpTooltip>
                      </template>
                    </v-checkbox>
                    <v-checkbox v-model="newSubscriber.isBillingAdmin"
                                class="mt-2 pt-0"
                                hide-details
                    >
                      <template #label>
                        <span>{{$t('subscriptions.AddSubscriberDialog.subscriptionBillingAdmin')}}</span>
                        <AppHelpTooltip :text="$t('subscriptions.AddSubscriberDialog.subscriptionBillingAdminTooltip')"></AppHelpTooltip>
                      </template>
                    </v-checkbox>
                    <div v-if="roleError" class="mt-2 error--text">{{$t('subscriptions.AddSubscriberDialog.roleError')}}</div>
                  </v-col>
                </v-row>
              </form>
            </v-col>
          </v-row>
        </template>
      </template>
    </AppDialog>
    <AppDialog :is-open="confirmationDialogOpen"
               size="m"
               :ok-disabled="seatsPending || seatsError"
               :cancel-disabled="seatsPending"
               @ok="confirmPostSubscriber"
               @cancel="confirmationDialogOpen = false"
    >
      <template #title>{{ $t('subscriptions.AddSubscriberDialog.confirmationDialogTitle') }}</template>
      <template #body>
        <div v-if="seatsPending" class="text-center">
          <v-progress-circular indeterminate color="primary" size="48" />
        </div>
        <p v-else>{{confirmationDialogBody}}</p>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import AppHelpTooltip from '@/common/AppHelpTooltip'
import AppSelect from '@/common/AppSelect'
import AppTextField from '@/common/AppTextField'
import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import { POST_NEW_SUBSCRIBER, GET_SEATS } from '@/store/modules/subscription/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'
export default {
  name: 'AddSubscriberDialog',
  components: { AppHelpTooltip, AppDialog, AppButton, AppTextField, AppSelect },
  mixins: [validationMixin],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  validations: {
    newSubscriber: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      locale: { required },
    },
  },
  data () {
    return {
      dialogIsOpen: false,
      confirmationDialogOpen: false,
      nextPrice: 0,
      licenseIncluded: false,
      skipCheckSeats: false,
      newSubscriber: {
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        locale: null,
        isAdmin: false,
        isBillingAdmin: false,
        isUser: true,
        confirmMonthly: false,
        confirmYearly: false,
      },
      localeOptions: [
        {
          text: this.$t('subscriptions.AddSubscriberDialog.locale.french'),
          value: 'fr',
          icon: '/img/icons/fr.svg',
        },
        {
          text: this.$t('subscriptions.AddSubscriberDialog.locale.english'),
          value: 'en',
          icon: '/img/icons/en.svg',
        },
        {
          text: this.$t('subscriptions.AddSubscriberDialog.locale.german'),
          value: 'de',
          icon: '/img/icons/de.svg',
        },
      ],
      roleError: false,
    }
  },
  computed: {
    ...mapState('subscription', ['postNewSubscriberPending', 'seatsPending', 'seats', 'seatsError']),
    newSubscriberFirstNameErrors () {
      const errors = []
      if (!this.$v.newSubscriber.firstName.$dirty) return errors
      !this.$v.newSubscriber.firstName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.form.firstName') }))
      return errors
    },
    newSubscriberLastNameErrors () {
      const errors = []
      if (!this.$v.newSubscriber.lastName.$dirty) return errors
      !this.$v.newSubscriber.lastName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.form.lastName') }))
      return errors
    },
    newSubscriberEmailErrors () {
      const errors = []
      if (!this.$v.newSubscriber.email.$dirty) return errors
      !this.$v.newSubscriber.email.email && errors.push(this.$t('common.validations.email'))
      !this.$v.newSubscriber.email.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.form.email') }))
      return errors
    },
    newSubscriberLocaleErrors () {
      const errors = []
      if (!this.$v.newSubscriber.locale.$dirty) return errors
      !this.$v.newSubscriber.locale.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.form.locale') }))
      return errors
    },
    hasOneRoleChecked () {
      return this.newSubscriber.isAdmin || this.newSubscriber.isUser || this.newSubscriber.isBillingAdmin
    },
    confirmationDialogBody () {
      if (this.skipCheckSeats || this.licenseIncluded) {
        return this.$t('subscriptions.AddSubscriberDialog.licenseIncluded')
      } else if (this.nextPrice) {
        return this.$t('subscriptions.AddSubscriberDialog.licensePrice', { price: this.nextPrice })
      } else {
        return this.$t('subscriptions.AddSubscriberDialog.getSeatsError')
      }
    },
  },
  watch: {
    async dialogIsOpen (val) {
      if (val === true) {
        await this.GET_SEATS()
        this.initSeatsData(this.seats)
        this.newSubscriber.company = this.subscription.company
      }
    },
    hasOneRoleChecked (val) {
      if (val) {
        this.roleError = false
      }
    },
  },
  methods: {
    ...mapActions('subscription', [POST_NEW_SUBSCRIBER, GET_SEATS]),
    resetAndClose () {
      this.dialogIsOpen = false
      this.nextPrice = 0
      this.licenseIncluded = false
      this.confirmationDialogOpen = false
      this.newSubscriber = {
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        locale: null,
        isAdmin: false,
        isBillingAdmin: false,
        isUser: true,
        confirmMonthly: false,
        confirmYearly: false,
      }
      this.$v.$reset()
    },
    initSeatsData (seats) {
      if (seats.billingSeatsAvailable <= 0 && (seats.nextYearlyPrice > 0 || seats.nextMonthlyPrice > 0)) {
        if (seats.nextYearlyPrice) {
          this.nextPrice = seats.nextYearlyPrice
          this.newSubscriber.confirmYearly = true
          this.newSubscriber.confirmMonthly = false
        } else {
          this.nextPrice = seats.nextMonthlyPrice
          this.newSubscriber.confirmMonthly = true
          this.newSubscriber.confirmYearly = false
        }
      } else {
        this.nextPrice = 0
        this.licenseIncluded = true
      }
    },
    preparePostSSubscriber () {
      this.$v.$touch()
      this.roleError = false
      if (!this.hasOneRoleChecked) {
        this.roleError = true
      } else if (!this.$v.$invalid) {
        if (!this.newSubscriber.isUser) {
          this.skipCheckSeats = true
        } else {
          this.skipCheckSeats = false
          this.GET_SEATS().then(() => this.initSeatsData(this.seats))
        }
        this.confirmationDialogOpen = true
      }
    },
    async confirmPostSubscriber () {
      this.confirmationDialogOpen = false
      try {
        await this.POST_NEW_SUBSCRIBER(this.newSubscriber)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('subscriptions.AddSubscriberDialog.postSubscriberSuccess'),
        })
        this.$emit('postNewSubscriberSuccess')
        this.resetAndClose()
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('subscriptions.AddSubscriberDialog.postSubscriberError'),
        })
      }
    },
  },
}
</script>
