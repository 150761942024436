<template>
  <AppDialog
    :is-open="isDialogOpened"
    :ok-loading="deleteSubscriptionTeamPending"
    :cancel-disabled="deleteSubscriptionTeamPending"
    :ok-text="$t('subscriptions.views.SubscriptionTeams.deleteTeam')"
    @ok="deleteSubscriptionTeam"
    @cancel="closeDialog"
  >
    <template #title>
      {{ $t('subscriptions.views.SubscriptionTeams.deleteTeamDialogTitle', { teamName: subscriptionTeam.name }) }}
    </template>
    <template #body>
      <p>{{ $t('subscriptions.views.SubscriptionTeams.deleteTeamDialogText') }}</p>
    </template>
  </AppDialog>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import { DELETE_SUBSCRIPTION_TEAM } from '@/store/modules/subscription/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'SubscriptionTeamsDeleteDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    subscriptionTeam: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('subscription', ['deleteSubscriptionTeamPending']),
  },
  methods: {
    ...mapActions('subscription', [DELETE_SUBSCRIPTION_TEAM]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async deleteSubscriptionTeam () {
      try {
        await this.DELETE_SUBSCRIPTION_TEAM(this.subscriptionTeam.id)
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('subscriptions.views.SubscriptionTeams.deleteTeamSuccess', { teamName: this.subscriptionTeam.name }))
        this.closeDialog()
        this.$emit('delete-success', this.subscriptionTeam)
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.msgFailErrorOccurred'))
      }
    },
  },
}
</script>
