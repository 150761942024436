<template>
    <v-chip
            :color="`${label.color}`"
            small
            class="pa-2 px-4"
    >
      <span class="caption white--text">{{labelName}}</span>
    </v-chip>
</template>

<script>
/**
 * Default Project label
 * @displayName AppProjectLabel
 */
export default {
  name: 'AppProjectLabel',
  props: {
    /**
     * Label Object
     *{
     *  color: String (Vuetify color or hex #000000),
     *  id: Number,
     *  label: {
     *    fr: String,
     *    en: String,
     *    ...,
     *  },
     *  order: Number,
     *  subscriptionId: Number,
     * }
     */
    label: {
      type: Object,
      required: true,
    },
  },
  computed: {
    labelName () {
      return this.label.label[this.$i18n.locale]
    },
  },
}
</script>

<docs>
```vue
<template>
  <div>
    <AppProjectLabel :label="label"/>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        label: {
          color:"#00ff00",
          id:2,
          label: {
            en:"Started",
            fr:"Démarré",
          },
          order:0,
          subscriptionId:1,
        }
      }
    },
  }
</script>
```
</docs>
