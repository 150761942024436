<template>
  <v-overlay app
             :opacity="1"
             :z-index="999"
  >
    <div>
      <div class="Unauthorized-title mb-6">{{ $t('common.UnauthorizedOverlay.unauthorized') }}</div>
      <AppButton color="primary" @click="logout">
        {{ $t('common.UnauthorizedOverlay.logout') }}
      </AppButton>
    </div>
  </v-overlay>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import AppButton from '@/common/buttons/AppButton'

import { LOGOUT_ROUTE_NAME } from '../router'
const { mapMutations } = createNamespacedHelpers('user')
export default {
  name: 'UnauthorizedOverlay',
  components: {
    AppButton,
  },
  methods: {
    logout () {
      this.$router.replace({ name: LOGOUT_ROUTE_NAME })
    },
  },
}
</script>

<style scoped lang="scss">
.Unauthorized-title {
  text-align: center;
  font-size: 30px;
}
</style>
