export const state = {
  signedDocuments: [],
  loading: false,
  searchQuery: '',
  signDateModel: [],
  filters: {
    signatories: [],
    startDate: null,
    endDate: null,
  },
  exportSignedDocumentToImanagePending: false,
  exportSignedDocumentToImanageError: null,
  signedDocumentPendingIds: [],
  signedDocumentError: null,
}
