<template>
  <AppDialog :is-open="isDialogOpened"
             size="l"
             @cancel="closeDialog"
  >
    <template #title>
      <slot name="header-text">
        {{ $t('common.dialogs.AppUnsavedChangesDialog.title') }}
      </slot>
    </template>

    <template #body>
      <slot name="body-text">
        <p>{{ $t("common.dialogs.AppUnsavedChangesDialog.body") }}</p>
      </slot>
    </template>

    <template #footer>
      <v-spacer />

      <AppButton color="white" @click="$emit('discard')">
        {{ $t('common.dialogs.AppUnsavedChangesDialog.noSave') }}
      </AppButton>

      <AppButton color="primary" @click="$emit('save')">
        {{ $t('common.dialogs.AppUnsavedChangesDialog.save') }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import AppButton from '@/common/buttons/AppButton.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'

export default {
  name: 'AppUnsavedChangesDialog',
  emits: ['save', 'discard'],
  components: { AppButton, AppDialog },
  mixins: [dialogMixin],
}
</script>
