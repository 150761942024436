<template>
<AppDialog :is-open="isDialogOpened"
           :ok-loading="cancelScheduledClosingPending"
           :cancel-disabled="cancelScheduledClosingPending"
           @ok="prepareCancelScheduledClosing"
           @cancel="closeDialog"
>
  <template #title>{{ $t('project.signing-checklist.list.CancelScheduledSigningDialog.title') }}</template>
  <template #body>
    <v-row>
      <v-col>
        {{ $t('project.signing-checklist.list.CancelScheduledSigningDialog.text') }}
      </v-col>
    </v-row>
  </template>
</AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import { dialogMixin } from '@/mixins/dialogMixin'
import { CANCEL_SCHEDULED_CLOSING } from '@/store/modules/signing-checklist/action_types'
export default {
  name: 'CancelScheduledSigningDialog',
  components: { AppDialog },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('signingChecklist', ['cancelScheduledClosingPending']),
  },
  methods: {
    ...mapActions('signingChecklist', [CANCEL_SCHEDULED_CLOSING]),
    async prepareCancelScheduledClosing () {
      await this.CANCEL_SCHEDULED_CLOSING(this.mnemo)
    },
  },
}
</script>
