<template>
 <div>
    <v-select v-model="$i18n.locale"
              item-value="code"
              color="blue darken-2"
              :items="langs"
              solo
              @change="routerUpdate"
    >
      <template v-slot:selection="{ item }">
          <img :src="item.urlSvg" class="flag">
      </template>
      <template v-slot:item="{ item }">
          <img :src="item.urlSvg" class="flag">
      </template>
    </v-select>
  </div>
</template>

<script>
/**
 * Default lang selector
 * @displayName ClosdLangSelector
 */
/**
 * Display a select with lang choice
 */
export default {
  name: 'ClosdLangSelector',
  data () {
    return {
      langs: [
        { code: 'fr', urlSvg: '/img/icons/fr.svg' },
        { code: 'en', urlSvg: '/img/icons/en.svg' },
        { code: 'de', urlSvg: '/img/icons/de.svg' },
      ],
    }
  },
  methods: {
    /**
     * Gets called when the user change select value
     *
     * @param {String} value The new lang selected
     */
    routerUpdate (value) {
      this.$router.replace({ ...this.$route, params: { locale: value } })
    },
  },
}
</script>

<style lang="scss" scoped>
.flag{
  width: 25px;
  margin-left: auto;
  margin-right: auto;
}
.v-select {
  width: 75px;
}
</style>

<docs>
```vue
<template>
  <ClosdLangSelector/>
</template>
```
</docs>
