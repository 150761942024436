<template>
  <AppTooltip top :disabled="!selectionTooLarge">
    <template #activator="{ on, attrs }">
      <!-- We use a wrapper div to retain the disable styling _and_ the tooltip hover event -->
      <div v-bind="attrs" v-on="on">
        <MultiSelectBarButton
          :disabled="selectionTooLarge"
          @click="handleClick"
        >
          <PolyOfficeIcon/>
          {{ $t('common.polyOffice.PolyOfficeMultiSelectBarButtonExport.exportFiles') }}
        </MultiSelectBarButton>
      </div>
    </template>
    <span>{{ $tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', selectionCount) }}</span>
  </AppTooltip>
</template>

<script>
import MultiSelectBarButton from '@/common/buttons/MultiSelectBarButton'
import PolyOfficeIcon from '@/common/polyOffice/PolyOfficeIcon'

import { POLYOFFICE_MAX_EXPORT_SIZE } from './constants'
import AppTooltip from '../AppTooltip'

export default {
  name: 'PolyOfficeMultiSelectBarButtonExport',
  components: {
    PolyOfficeIcon,
    MultiSelectBarButton,
    AppTooltip,
  },
  props: {
    selectionSize: {
      type: Number,
      required: true,
    },
    selectionCount: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    selectionTooLarge () {
      return POLYOFFICE_MAX_EXPORT_SIZE <= this.selectionSize
    },
  },
  methods: {
    handleClick () {
      this.$emit('polyOfficeOpenDialog')
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep.v-btn:hover {
  svg path {
    fill: #FFF;
  }
}
</style>
