<template>
 <AppDialog :is-open="isDialogOpened"
            :ok-text="$t('project.documents.dialogs.DocumentsRenameDialog.rename')"
            :ok-loading="renameSavedChecklistPending"
            :ok-disabled="nameErrors.length > 0 || renameSavedChecklistPending"
            :cancel-disabled="renameSavedChecklistPending"
            @cancel="prepareCloseRenameSavedChecklist"
            @ok="prepareRenameSavedChecklist"
 >
   <template #title>
      {{ $t('project.checklist.dialogs.SavedChecklistRenameDialog.title') }}
   </template>
   <template #body>
     <form @submit.prevent="prepareRenameSavedChecklist"
     >
       <AppTextField v-model.trim="$v.newName.$model"
                     class="required"
                     :label="$t('project.documents.dialogs.DocumentsRenameDialog.lblRenameDocument')"
                     :counter="nameMaxLength"
                     :maxlength="nameMaxLength"
                     :error-messages="nameErrors"
                     autofocus
       />
     </form>
   </template>
 </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import { dialogMixin } from '@/mixins/dialogMixin'

import AppTextField from '../../../common/AppTextField'
import AppDialog from '../../../common/dialogs/AppDialog'
import { RENAME_SAVED_CHECKLIST } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

const NAME_MAX_LENGTH = 100

export default {
  name: 'SavedChecklistRenameDialog',
  components: {
    AppDialog,
    AppTextField,
  },
  mixins: [validationMixin, dialogMixin],
  validations: {
    newName: {
      required,
      maxLength: (value, vm) => value.length <= vm.nameMaxLength,
    },
  },
  props: {
    checklist: {
      required: true,
      validator: (value) => {
        return value === null || typeof value === 'object'
      },
    },
  },
  data () {
    return {
      newName: '',
    }
  },
  computed: {
    ...mapState('checklist', ['renameSavedChecklistPending']),
    nameMaxLength () {
      return NAME_MAX_LENGTH
    },
    nameErrors () {
      const errors = []
      if (!this.$v.newName.$dirty) return errors
      !this.$v.newName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('project.checklist.dialogs.SaveChecklistDialog.saveName') }))
      !this.$v.newName.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('project.checklist.dialogs.SaveChecklistDialog.saveName') }))
      return errors
    },
  },
  created () {
    this.newName = this.checklist.name
  },
  methods: {
    ...mapActions('checklist', [RENAME_SAVED_CHECKLIST]),
    prepareCloseRenameSavedChecklist () {
      this.closeDialog()
    },
    async prepareRenameSavedChecklist () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.RENAME_SAVED_CHECKLIST({
            todoListId: this.checklist.id,
            name: this.newName,
          })
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('project.checklist.dialogs.SavedChecklistRenameDialog.renameSavedChecklistSuccess'),
          })
          this.checklist.name = this.newName
          this.newName = ''
        } catch (e) {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.dialogs.SavedChecklistRenameDialog.renameSavedChecklistError'),
          })
        } finally {
          this.closeDialog()
        }
      }
    },
  },
}
</script>
