<template>
  <div class="white pa-5 rounded">
    <h3 class="mb-4">{{ $t("archives.ArchivesSearchArea.title") }}</h3>
    <v-row>
      <v-col lg="4" cols="12">
        <div class="d-flex align-center">
          <AppTextField
            id="document-name"
            v-model.trim="search.docName"
            :placeholder="$t('archives.ArchivesSearchArea.inputDocumentNamePlaceholder')"
            :label="$t('archives.ArchivesSearchArea.inputDocumentNameLabel')"
          />
          <AppTooltip top>
            <template #activator="{ on, attrs }">
              <i
                class="fas fa-circle-question ml-2"
                v-bind="attrs"
                v-on="on"
              ></i>
            </template>
            {{ $t("archives.ArchivesSearchArea.inputDocumentNameHint") }}
          </AppTooltip>
        </div>
      </v-col>
      <v-col lg="4" cols="12">
        <div class="d-flex align-center">
          <AppTextField
            id="folder-ref"
            v-model.trim="search.folderRef"
            :placeholder="$t('archives.ArchivesSearchArea.inputFolderRefPlaceholder')"
            :label="$t('archives.ArchivesSearchArea.inputFolderRefLabel')"
          />
          <AppTooltip top>
            <template #activator="{ on, attrs }">
              <i
                class="fas fa-circle-question ml-2"
                v-bind="attrs"
                v-on="on"
              ></i>
            </template>
            {{ $t("archives.ArchivesSearchArea.inputFolderRefHint") }}
          </AppTooltip>
        </div>
      </v-col>
      <v-col lg="4" cols="12">
        <div class="d-flex align-center">
          <AppTextField
            id="archiver-name"
            v-model.trim="search.archiverName"
            :placeholder="$t('archives.ArchivesSearchArea.inputArchiverNamePlaceholder')"
            :label="$t('archives.ArchivesSearchArea.inputArchiverNameLabel')"
          />
          <AppTooltip top>
            <template #activator="{ on, attrs }">
              <i
                class="fas fa-circle-question ml-2"
                v-bind="attrs"
                v-on="on"
              ></i>
            </template>
            {{ $t("archives.ArchivesSearchArea.inputArchiverNameHint") }}
          </AppTooltip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" cols="12">
        <AppDatePicker
          range
          :label="$t('archives.ArchivesSearchArea.inputArchiveDateLabel')"
          :value="archiveDateModel"
          @input="(value) => handleDateRangeChange(value, 'archiveDate')"
        />
      </v-col>
      <v-col v-for="field in additionalFields" :key="field.field" lg="4" cols="12">
        <div class="d-flex align-center">
          <AppTextField
            :id="field.field"
            v-model.trim="search.additionalFields[field.field]"
            :label="field[$i18n.locale]"
          />
        </div>
      </v-col>
      <template v-if="search.signedDocuments">
        <v-col lg="4" cols="12">
          <div class="d-flex align-center">
            <AppTextField
              id="signers-name"
              v-model.trim="search.signerName"
              :placeholder="$t('archives.ArchivesSearchArea.inputSignersNamesPlaceholder')"
              :label="$t('archives.ArchivesSearchArea.inputSignersNamesLabel')"
            />
            <AppTooltip top>
              <template #activator="{ on, attrs }">
                <i
                  class="fas fa-circle-question ml-2"
                  v-bind="attrs"
                  v-on="on"
                ></i>
              </template>
              {{ $t("archives.ArchivesSearchArea.inputSignersNamesHint") }}
            </AppTooltip>
          </div>
        </v-col>
        <v-col lg="4" cols="12">
          <AppDatePicker
            range
            :label="$t('archives.ArchivesSearchArea.inputSignDateLabel')"
            :value="signDateModel"
            @input="(value) => handleDateRangeChange(value, 'signDate')"
          />
        </v-col>
      </template>
    </v-row>
    <div class="my-2">
      <v-switch
        :value="search.signedDocuments"
        :label="$t('archives.ArchivesSearchArea.inputSearchSignedDocumentsLabel')"
        @change="handleSignedDocumentsChange"
      />
    </div>
    <div class="text-center">
      <AppButton
        class="btn-search"
        color="secondary"
        :disabled="loading"
        @click="submit"
      >
        <span class="mr-2">
          <i class="fa fa-search"></i>
        </span>
        {{ $t("archives.ArchivesSearchArea.btnSearch") }}
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppDatePicker from '../common/AppDatePicker.vue'
import AppTextField from '../common/AppTextField.vue'
import AppTooltip from '../common/AppTooltip'
import AppButton from '../common/buttons/AppButton.vue'
import { getRelativePastDateRange } from '../common/utils/dates'

export default {
  name: 'ArchivesSearchArea',
  components: {
    AppDatePicker,
    AppTextField,
    AppButton,
    AppTooltip,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    additionalFields: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      search: {
        docName: '',
        folderRef: '',
        archiverName: '',
        archiveDate: {
          begin: '',
          end: '',
        },
        signerName: '',
        signDate: {
          begin: '',
          end: '',
        },
        signedDocuments: false,
        additionalFields: {},
      },
      archiveDateModel: [],
      signDateModel: [],
    }
  },
  mounted () {
    this.search.archiveDate = getRelativePastDateRange(3, 'month')
    this.archiveDateModel = [this.search.archiveDate.begin, this.search.archiveDate.end]
    this.submit(this.search)
  },
  methods: {
    submit () {
      this.$emit('submit', this.search)
    },
    handleSignedDocumentsChange (value) {
      if (!value) {
        this.search.signDate = {
          begin: '',
          end: '',
        }
        this.signDateModel = []
        this.search.signerName = ''
      }
      this.search.signedDocuments = value
    },
    handleDateRangeChange (value, field) {
      this[field + 'Model'] = value
      this.search[field].begin = value[0]
      if (value.length === 2) {
        this.search[field].end = value[1]
      }
    },
  },
}
</script>
