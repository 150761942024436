<template>
  <div class="message-wrapper">
    <div class="message-author">
      <slot name="message-author" />
    </div>

    <div class="message-bubble">
      <app-text variant="caption" as="span" class="mb-2">
        {{ formattedMessageDate }}
      </app-text>
      <app-text variant="small-regular">
        <slot />
      </app-text>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { defineComponent } from 'vue'

import { ISOToShortDate } from '@/common/utils/dates'

export default defineComponent({
  name: 'AppChatMessage',
  props: {
    messageDate: {
      type: String,
      default: () => dayjs().toString(),
    },
  },
  computed: {
    formattedMessageDate () {
      return `${this.$t('common.dateUtils.article')} ${ISOToShortDate(this.messageDate)}`
    },
  },
})
</script>

<style scoped>
.message-wrapper {
  display: flex;
  column-gap: 16px;
}

.message-author {
  width: 28px;
}

.message-bubble {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 392px;
  background-color: var(--v-grey-lighten6);
  border-radius: 12px;
  padding: 20px;

  p {
    margin: 0;
    white-space: preserve;
    word-break: break-word;
  }
}
</style>
