<template>
  <AppDialog :is-open="isDialogOpened"
              stepper-dialog
              :stepper-model="currentStep"
              size="xl"
              @cancel="resetAndClose"
  >
    <template #stepper-header>
      <v-stepper-step :step="1">
        <template v-if="isFromTemplateDialog">
          {{ $t('project.checklist.dialogs.ImportChecklistDialog.stepperHeader.accessRights') }}
        </template>
        <template v-else>
          {{ $t('project.checklist.dialogs.ImportChecklistDialog.stepperHeader.file') }}
        </template>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step v-if="!isFromTemplateDialog" :step="2">
        {{ $t('project.checklist.dialogs.ImportChecklistDialog.stepperHeader.accessRights') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step v-if="!emptyChecklist" :step="stepsNumber">
        {{ $t('project.checklist.dialogs.ImportChecklistDialog.stepperHeader.importType') }}
      </v-stepper-step>
    </template>
    <template #stepper-items>
      <v-stepper-items>
        <template v-if="currentStep === 1">
          <template v-if="isFromTemplateDialog">
            <!-- ACCESS RIGHTS -->
            <ImportChecklistDialogAccessRights
              :shareType="shareType"
              :sendNotif="sendNotif"
              :notifyMessage="notifyMessage"
              @change-share-type="shareType = $event"
              @set-send-notif="sendNotif = $event"
              @set-notif-message="notifyMessage = $event"
            />
          </template>
          <template v-else>
            <!-- EXCEL FILE -->
            <app-text variant="medium-bold" class="mb-2">
              {{ $t('project.checklist.dialogs.ImportChecklistDialog.importaChecklist') }}
            </app-text>
            <app-text variant="small-regular" class="mb-6">
              {{ $t('project.checklist.dialogs.ImportChecklistDialog.importaChecklistSubtitle') }}
            </app-text>
            <v-row v-if="!isFromTemplateDialog && importErrors">
              <v-col>
                <app-alert-in-page variant="error" :dismissible="false" class="mb-6">
                  <template v-slot:title>
                    <!-- If there's data, there's a detail of each errors. Otherwise it's unknown. -->
                    <template v-if="importErrors.data">
                      {{ $t('project.checklist.dialogs.ImportChecklistDialog.importChecklistError') }}
                    </template>
                    <template v-else>
                      {{ $t('project.checklist.dialogs.ImportChecklistDialog.importChecklistGenericError') }}
                    </template>
                  </template>
                  <template v-if="importErrors.data">
                    <AppFlatExpansionPanel>
                      <template v-slot:title>
                        <span>{{ $t('project.checklist.dialogs.ImportChecklistDialog.importChecklistErrorDetailBtn') }}</span>
                      </template>
                      <template v-slot:content>
                        <ul style="list-style: none;" class="pl-0 text-left">
                          <li v-for="(error, i) in importErrors.data" :key="`errorLine-${i}`" class="py-1">
                            <span class="font-weight-bold">{{ $t('project.checklist.dialogs.ImportChecklistDialog.importErrors.atLine')}} {{ i }}</span>
                            <ul style="list-style-position: inside;" class="pl-2">
                              <li v-for="(errorDetail, j) in error" :key="`error-${j}`">
                                {{ $t("project.checklist.dialogs.ImportChecklistDialog.importErrors." + errorDetail) }}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </template>
                    </AppFlatExpansionPanel>
                  </template>
                </app-alert-in-page>
              </v-col>
            </v-row>
            <ImportChecklistDialogExcelFile :templateLink="templateLink"
                                            :hasImportErrors="!!importErrors"
                                            :existingFile="file"
                                            @update-file="file = $event"
                                            @reset-import-errors="importErrors = null"
            >
            </ImportChecklistDialogExcelFile>
          </template>
        </template>
        <template v-if="!isFromTemplateDialog && currentStep === 2">
          <!-- ACCESS RIGHTS -->
          <ImportChecklistDialogAccessRights
            :shareType="shareType"
            :sendNotif="sendNotif"
            :notifyMessage="notifyMessage"
            @change-share-type="shareType = $event"
            @set-send-notif="sendNotif = $event"
            @set-notif-message="notifyMessage = $event"
          />
        </template>
        <template v-if="!emptyChecklist && currentStep === stepsNumber">
          <!-- IMPORT TYPE -->
          <app-text variant="medium-bold" class="mb-4">
            {{ $t('project.checklist.dialogs.ImportChecklistDialog.importMethodChecklistTemplate') }}
          </app-text>
          <div class="import-method-card-container">
            <div class="import-method-card"
                  :class="{'import-method-card--active': isReplaceChecklistSelected}"
                  @click="isReplaceChecklistSelected = true"
            >
              <app-icon v-show="isReplaceChecklistSelected" icon-name="check-circle" icon-weight="fas" class="import-method-card-activeIcon brand--text"/>
              <v-img src="/img/checklist/replace-checklist-illustration.svg" class="mb-6"/>
              <app-text variant="medium-bold" class="mb-2">
                  {{ $t('project.checklist.dialogs.ImportChecklistDialog.replaceChecklist') }}
              </app-text>
              <app-text variant="small-regular" class="mb-2 grey--text">
                {{ $t('project.checklist.dialogs.ImportChecklistDialog.replaceChecklistTemplateText') }}
              </app-text>
            </div>
            <div class="import-method-card"
                  :class="{'import-method-card--active': !isReplaceChecklistSelected}"
                  @click="isReplaceChecklistSelected = false"
            >
              <app-icon v-show="!isReplaceChecklistSelected" icon-name="check-circle" icon-weight="fas" class="import-method-card-activeIcon brand--text"/>
              <v-img src="/img/checklist/merge-checklist-illustration.svg" class="mb-6"/>
              <app-text variant="medium-bold" class="mb-2">
                {{ $t('project.checklist.dialogs.ImportChecklistDialog.addChecklistTemplate') }}
              </app-text>
              <app-text variant="small-regular" class="mb-2 grey--text">
                {{ $t('project.checklist.dialogs.ImportChecklistDialog.addChecklistTemplateText') }}
              </app-text>
            </div>
          </div>
        </template>
      </v-stepper-items>
    </template>
    <template #footer>
      <div class="d-flex justify-space-between align-center w-100">
        <div>
          <app-button v-if="currentStep > 1 || isFromTemplateDialog"
                      type="outlined"
                      variant="neutral"
                      :loading="importChecklistFromExcelPending || importChecklistFromClosdPending"
                      @click="currentStep > 1 ? currentStep-- : openImportChecklistFromTemplate()"
          >
            <template #left-icon>
              <font-awesome-icon :icon="['far', 'arrow-left']" />
            </template>
            {{ $t('common.previous') }}
          </app-button>
        </div>
        <div class="d-flex align-center">
          <app-button type="outlined"
                      variant="neutral"
                      :loading="importChecklistFromExcelPending || importChecklistFromClosdPending"
                      @click="resetAndClose"
          >
            {{ $t('common.cancel') }}
          </app-button>
          <app-button v-if="currentStep < stepsNumber"
                    class="ml-4"
                    :disabled="okDisabled"
                    @click="currentStep++"
          >
            {{ $t('common.next') }}
          </app-button>
          <app-button v-if="currentStep === stepsNumber"
                      class="ml-4"
                      :loading="importChecklistFromExcelPending || importChecklistFromClosdPending"
                      :disabled="okDisabled"
                      @click="isFromTemplateDialog ? prepareImportChecklistFromClosd() : prepareImportChecklistFromExcel()"
          >
            {{ okButtonText }}
          </app-button>
        </div>
      </div>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppFlatExpansionPanel from '@/common/AppFlatExpansionPanel.vue'
import { DARK_BLUE_500 } from '@/common/utils/colors'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes.ts'
import { dialogMixin } from '@/mixins/dialogMixin'
import { IMPORT_CHECKLIST_FROM_EXCEL } from '@/store/modules/checklist/action_types'
import {
  SET_SELECTED_SAVED_CHECKLIST,
  SET_SELECTED_LEXISNEXIS_CHECKLIST,
  SET_CHECKLISTS_TEMPLATES_SELECTED_TAB,
  SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN,
} from '@/store/modules/checklist/mutation_types'
import {
  INIT_SELECTED_GROUPS,
  RESET_DISABLED_MEMBERS,
  RESET_SELECTED_GROUPS_AND_MEMBERS,
} from '@/store/modules/document-share-picker/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

import { TAB_INDEX } from './enums.ts'
import ImportChecklistDialogAccessRights from './ImportChecklistDialogAccessRights.vue'
import ImportChecklistDialogExcelFile from './ImportChecklistDialogExcelFile.vue'
import AppDialog from '../../../common/dialogs/AppDialog'
import { IMPORT_CHECKLIST_FROM_CLOSD, GET_CHECKLIST } from '../../../store/modules/checklist/action_types'

const STEPS = Object.freeze({
  FROM_EXCEL_DIALOG: 3,
  FROM_TEMPLATE_DIALOG: 2,
})

export default {
  name: 'ImportChecklistDialog',
  mixins: [dialogMixin],
  components: {
    AppDialog,
    ImportChecklistDialogExcelFile,
    ImportChecklistDialogAccessRights,
    AppFlatExpansionPanel,
  },
  props: {
    isFromTemplateDialog: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      currentStep: 1,
      isReplaceChecklistSelected: true,
      file: null,
      importErrors: null,
      TAB_INDEX,
      shareType: SHARE_TYPE_OPTIONS.CUSTOM,
      sendNotif: false,
      notifyMessage: '',
      DARK_BLUE_500,
    }
  },
  computed: {
    ...mapState('checklist', [
      'importChecklistFromExcelPending',
      'importChecklistFromClosdPending',
      'selectedSavedChecklist',
      'selectedLexisNexisChecklist',
      'checklistTemplatesSelectedTab',
      'checklist',
    ]),
    ...mapState('groups', ['groups']),
    ...mapState('documentSharePicker', ['selectedGroups', 'selectedMembers']),
    ...mapGetters('room', ['roomMnemo']),
    emptyChecklist () {
      return !this.checklist || this.checklist.length === 0
    },
    stepsNumber () {
      let stepCount = this.isFromTemplateDialog
        ? STEPS.FROM_TEMPLATE_DIALOG
        : STEPS.FROM_EXCEL_DIALOG
      if (this.emptyChecklist) {
        stepCount--
      }
      return stepCount
    },
    isLexisNexisChecklistTab () {
      return this.checklistTemplatesSelectedTab === TAB_INDEX.LEXIS_NEXIS_CHECKLIST
    },
    selectedChecklist () {
      return this.isLexisNexisChecklistTab
      ? this.selectedLexisNexisChecklist
      : this.selectedSavedChecklist
    },
    okDisabled () {
      return this.isFromTemplateDialog ? !this.selectedChecklist : !this.file
    },
    templateLink () {
      return `${process.env.VUE_APP_API_URL}/resources/massTodoImportTemplate`
    },
    okButtonText () {
      return !this.isFromTemplateDialog ? this.$t('common.validate') : this.$t('project.checklist.dialogs.ImportChecklistDialog.importChecklist')
    },
  },
  created () {
    // PmGroup is init here and not in the child component because
    // the picker forgets everything when you do previous and asks for the groups again
    this.initPmGroup()
  },
  methods: {
    ...mapActions('checklist', [
      IMPORT_CHECKLIST_FROM_CLOSD,
      IMPORT_CHECKLIST_FROM_EXCEL,
      GET_CHECKLIST,
    ]),
    ...mapActions('groups', ['GET_GROUPS']),
    ...mapActions('documentSharePicker', [
      INIT_SELECTED_GROUPS,
      RESET_DISABLED_MEMBERS,
      RESET_SELECTED_GROUPS_AND_MEMBERS,
    ]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async initPmGroup () {
      await this.GET_GROUPS(this.roomMnemo)
      const pmGroup = this.groups.find(g => g.isPm)
      if (pmGroup) {
        this.INIT_SELECTED_GROUPS([pmGroup])
      }
    },
    async prepareImportChecklistFromClosd () {
      if (this.selectedChecklist) {
        try {
          if (this.shareType === SHARE_TYPE_OPTIONS.PUBLIC) {
            this.RESET_SELECTED_GROUPS_AND_MEMBERS()
          }
          await this.IMPORT_CHECKLIST_FROM_CLOSD({
            mnemo: this.roomMnemo,
            data: {
              todoListId: this.selectedChecklist.id,
              shareType: this.shareType,
              withUsers: this.selectedMembers.map(user => user.id),
              withGroups: this.selectedGroups.map(group => group.id),
              sendNotif: this.sendNotif,
              notifyMessage: this.notifyMessage,
              lexis: this.isLexisNexisChecklistTab,
              reset: this.isReplaceChecklistSelected,
            },
          })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.checklist.dialogs.ImportChecklistDialog.importChecklistSuccess'))
          this.GET_CHECKLIST(this.roomMnemo)
          this.resetAndClose()
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.checklist.dialogs.ImportChecklistDialog.importChecklistFromClosdError'))
        }
      }
    },
    async prepareImportChecklistFromExcel () {
      if (this.file) {
        try {
          if (this.shareType === SHARE_TYPE_OPTIONS.PUBLIC) {
            this.RESET_SELECTED_GROUPS_AND_MEMBERS()
          }
          this.importErrors = null
          const formData = new FormData()
          formData.append('file', this.file, this.file.name)
          formData.append('shareType', this.shareType)
          this.selectedMembers.forEach(user => {
            formData.append('withUsers[]', user.id)
          })
          this.selectedGroups.forEach(group => {
            formData.append('withGroups[]', group.id)
          })
          formData.append('sendNotif', this.sendNotif)
          formData.append('notifyMessage', this.notifyMessage)
          formData.append('lexis', this.isLexisNexisChecklistTab)
          formData.append('reset', this.isReplaceChecklistSelected)

          await this.IMPORT_CHECKLIST_FROM_EXCEL({
            mnemo: this.roomMnemo,
            data: formData,
          })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.checklist.dialogs.ImportChecklistDialog.importChecklistFromExcelSuccess'))
          this.resetAndClose()
        } catch (error) {
          if (error.response?.data) {
            const { errors: errorData, errorCode } = error.response.data
            // Currently, errorCode is added onto the object to make it non-null and possibly react to it
            // `errors` from the back-end can be undefined if there's no details but errorCode is always there
            this.importErrors = { data: errorData, errorCode }
            this.currentStep = 1
          } else {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.checklist.dialogs.ImportChecklistDialog.importChecklistError'))
          }
        }
      }
    },
    openImportChecklistFromTemplate () {
      this.resetAndClose()
      this.$store.commit(`checklist/${SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN}`, true)
    },
    resetAndClose () {
      if (this.isFromTemplateDialog) {
        this.$store.commit(`checklist/${SET_SELECTED_SAVED_CHECKLIST}`, null)
        this.$store.commit(`checklist/${SET_SELECTED_LEXISNEXIS_CHECKLIST}`, null)
        this.$store.commit(`checklist/${SET_CHECKLISTS_TEMPLATES_SELECTED_TAB}`, TAB_INDEX.UNSET)
      }

      // This is done here because we need the list of members and groups.
      // Otherwise, if it's done in the child component, we won't get these data in the last stepper of the modal
      this.RESET_DISABLED_MEMBERS()
      this.RESET_SELECTED_GROUPS_AND_MEMBERS()

      this.closeDialog()
    },
  },
}
</script>

<style scoped lang="scss">
.import-method-card-container {
  display: flex;
  gap: $spacer * 6;
}
.import-method-card {
  position: relative;
  width: calc(50% - #{$spacer * 3});
  padding: 40px 16px 32px;
  border: 2px solid var(--v-grey-lighten3);
  border-radius: 12px;
  cursor: pointer;
  &--active {
    border-color: var(--v-brand-base);
    background: var(--v-brand-lighten5);
  }
  &-activeIcon {
    position: absolute;
    top: 11px;
    right: 11px;
  }
}
</style>
