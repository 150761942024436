import { mapActions, mapMutations, mapState } from 'vuex'

export function typedMapActions<T extends string> (namespace: string, actions: Array<T>) {
  return mapActions(namespace, actions)
}

export function typedMapMutations<T extends string> (namespace: string, mutations: Array<T>) {
  return mapMutations(namespace, mutations)
}

export function typedMapState<T extends string> (namespace: string, states: Array<T>) {
  return mapState(namespace, states)
}
