<template>
  <AppDialog
    :is-open="isDialogOpened"
    :ok-loading="deleteAliasPending"
    :cancel-disabled="deleteAliasPending"
    :ok-text="$t('common.delete')"
    ok-color="error"
    @ok="deleteUserAlias"
    @cancel="closeDialog"
  >
    <template #title>
      {{ $t('profile.views.EmailAlias.deleteAlias', { email: alias.email }) }}
    </template>
    <template #body>
      <p>{{ $t('profile.views.EmailAlias.deleteAliasDialogText') }}</p>
    </template>
  </AppDialog>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'

  import AppDialog from '@/common/dialogs/AppDialog.vue'
  import { dialogMixin } from '@/mixins/dialogMixin'
  import { DELETE_USER_ALIAS } from '@/store/modules/user/action_types'
  import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

  export default {
    name: 'EmailAliasDeleteDialog',
    components: { AppDialog },
    mixins: [dialogMixin],
    props: {
      alias: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapState('user', ['aliases', 'deleteAliasPending']),
    },
    methods: {
      ...mapActions('user', [DELETE_USER_ALIAS]),
      ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
      async deleteUserAlias () {
        try {
          await this.DELETE_USER_ALIAS({
            aliasId: this.alias.id,
          })
          const index = this.aliases.findIndex(item => item.id === this.alias.id)
          if (index > -1) {
            this.aliases.splice(index, 1)
          }
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('profile.views.EmailAlias.deleteAliasSuccess', { email: this.alias.email }))
          this.closeDialog()
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('profile.views.EmailAlias.deleteAliasError'))
        }
      },
    },
  }
</script>
