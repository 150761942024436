<template>
  <div>
    <div class="PrepareSignatureSidebarSignerTag"
         :style="computedTagStyles"
         draggable="true"
         @dragstart="dragStart"
         @dragend="dragEnd"
    >
      <app-text as="span" variant="x-small-bold">{{ $t(`project.signing-checklist.prepare-signature.PrepareSignatureSidebarSignerTag.${tag.type}`) }}</app-text>
      <AppNewLabel v-if="tag.hasNewLabel" class="ml-auto mr-2">{{ $t('common.new') }}</AppNewLabel>
      <div class="PrepareSignatureSidebarSignerTagIcon">
        <font-awesome-icon :icon="[tag.iconWeight, tag.icon]"></font-awesome-icon>
      </div>
    </div>
    <div ref="ghost"
         class="PrepareSignatureSidebarSignerTagGhost"
         :class="`PrepareSignatureSidebarSignerTagGhost--${tag.type}`"
         :style="computedGhostStyles"
    >
      <template v-if="tag.type === tagsTypes.SIGN">
        {{ signerName }}
        <font-awesome-icon class="PrepareSignatureSidebarSignerTagGhost-icon mx-1" :icon="['far', 'signature']"></font-awesome-icon>
      </template>
      <template v-if="tag.type === tagsTypes.INITIAL">
        {{ signerInitials }}
      </template>
      <template v-if="tag.type === tagsTypes.DATE">
        {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSignerTag.signatureDate') }}
      </template>
      <template v-if="tag.type === tagsTypes.CERTIFIED">
        {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSignerTag.certified', { signer: signerName }) }}
      </template>
      <template v-if="tag.type === tagsTypes.NAME">
        {{ signerName }}
      </template>
      <template v-if="tag.type === tagsTypes.TEXT">
        {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSignerTag.freeTextPlaceholder') }}
      </template>
      <template v-if="tag.type === tagsTypes.TEXT_READ_ONLY">
        {{ $t('project.signing-checklist.prepare-signature.PrepareSignatureSidebarSignerTag.textFieldReadOnlyPlaceholder') }}
      </template>
      <template v-if="tag.type === tagsTypes.CHECKBOX">
        <font-awesome-layers class="icon">
          <font-awesome-icon :icon="['far', 'square']"></font-awesome-icon>
          <font-awesome-icon :icon="['fas', 'asterisk']"
                             transform="up-8 right-8"
                             style="font-size: 10px; color: #e02020"
          ></font-awesome-icon>
        </font-awesome-layers>
      </template>
      <template v-if="tag.type === tagsTypes.OPTIONAL_CHECKBOX">
        <font-awesome-icon :icon="['far', 'square']"
                           class="text--primary icon"
        ></font-awesome-icon>
      </template>
    </div>
  </div>
</template>

<script>
import AppNewLabel from '@/common/AppNewLabel.vue'
import { tagsTypes } from '@/common/prepare-signature/tagsTypes'
import { hexToRGB } from '@/common/utils/colors'

export default {
  name: 'PrepareSignatureSidebarSignerTag',
  components: { AppNewLabel },
  props: {
    signer: {
      type: Object,
      required: true,
    },
    signerColor: {
      type: String,
      required: true,
    },
    signerName: {
      type: String,
      required: true,
    },
    signerInitials: {
      type: String,
      required: true,
    },
    tag: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      tagsTypes,
    }
  },
  computed: {
    computedTagStyles () {
      const rgb = hexToRGB(this.signerColor)
      return {
        backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, .13)`,
      }
    },
    computedGhostStyles () {
      const rgb = hexToRGB(this.signerColor)
      return {
        backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, .13)`,
        borderColor: this.signerColor,
      }
    },
  },
  methods: {
    dragStart (event) {
      this.$emit('drag-tag-active', { value: true, tag: null })

      const ghost = this.$refs.ghost.cloneNode(true)
      ghost.classList.add('ghost-tmp')
      ghost.style.display = 'inline-flex'
      document.body.appendChild(ghost)
      event.dataTransfer.setDragImage(ghost, 0, 0)
      event.dataTransfer.setData('action', 'post')
      event.dataTransfer.setData('signatureId', this.signer.signatureId)
      event.dataTransfer.setData('tagType', this.tag.type)
      this.$nextTick(() => {
        // If the ghost is removed, we have a reference but everything is zeroed
        if (ghost.getBoundingClientRect().width !== 0) {
          event.dataTransfer.setData('tagWidth', ghost.getBoundingClientRect().width)
          event.dataTransfer.setData('tagHeight', ghost.getBoundingClientRect().height)
        }
      })
    },
    dragEnd () {
      const ghostToRemove = document.querySelector('.ghost-tmp')
      ghostToRemove.remove()
    },
  },
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.PrepareSignatureSidebarSignerTag {
  align-items: center;
  background-color: var(--v-grey-lighten4);
  border-radius: 4px;
  color: var(--v-grey-base);
  cursor: grab;
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  text-transform: uppercase;
}
.PrepareSignatureSidebarSignerTagGhost {
  background-color: rgba(150, 150, 150, .3);
  border: 2px solid;
  border-radius: 3px;
  display: none;
  position: fixed;
  font-family: 'Roboto';
  // We put it off-screen so that it doesn't force any overflow
  // And it's not visible
  // Related to V2-720
  top: 0;
  left: 0;
  // 200% is done specifically so that any big containers aren't ever visible no matter their sizes
  transform: translate(-200%, -200%);
  &--signHere {
    width: 125px;
    height: 49px;
    font-size: 11px;
    line-height: 13.2px;
    padding: 4px;
  }
  &--initialHere {
    width: 60px;
    height: 49px;
    font-size: 11px;
    justify-content: center;
    align-items: center;
  }
  &--dateSigned {
    font-size: 11px;
    padding: 5px;
  }
  &--certifiedField {
    padding: 3px;
    font-style: italic;
    font-size: 11pt;
    line-height: 18px;
  }
  &--fullName {
    padding: 0 3px;
    text-align: center;
    font-size: 12pt;
  }
  &--textField, &--textFieldReadOnly {
    font-size: 11.5pt;
    font-style: italic;
    width: 200px;
    height: 60px;
  }
  &--checkbox,
  &--optionalCheckbox {
    width: 24px;
    height: 24px;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}
.PrepareSignatureSidebarSignerTagIcon {
  width: 20px;
  text-align: center;
}
.v-chip {
  text-transform: none;
  margin-left: auto;
  margin-right: 12px;
}
</style>
