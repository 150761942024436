/**
 * Available filter types
 * @readonly
 * @enum {string}
 */
export enum FilterTypes {
  'CHECKBOX' = 'checkbox',
  'DATE' = 'date',
}

type ValueContainer = {
  key: Array<unknown>,
  label: string,
}

type FilterOptions = {
  label: string,
  value: unknown,
}

export type FilterType = {
  category: ValueContainer,
  value: ValueContainer,
  type: FilterTypes,
  options: Array<FilterOptions> | undefined
}

export const Filter = function (this: FilterType,
  categoryKey,
  categoryLabel,
  valueKey,
  valueLabel,
  type,
  options = [],
) {
  this.category = {
    key: categoryKey,
    label: categoryLabel,
  }
  this.value = {
    key: valueKey,
    label: valueLabel,
  }
  this.type = type
  this.options = options
}
