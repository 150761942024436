import { uploadStatus } from '@/common/app-file-upload/uploadStatus'
import { SET_UPLOAD_HINT } from '@/store/modules/app-upload-handler/mutation_types'

export const mutations = {
  ADD_FILE_TO_UPLOAD: function (state, file) {
    state.filesToUpload.push(file)
  },
  ADD_TO_QUEUE: function (state, file) {
    state.uploadQueue.push(file)
  },
  INCREMENT_UPLOAD_IN_PROGRESS: function (state) {
    state.uploadInProgress++
  },
  DECREMENT_UPLOAD_IN_PROGRESS: function (state) {
    state.uploadInProgress--
  },
  SET_FILE_STATUS_TO_PENDING: (state, fileKey) => {
    state.uploadQueue.find(file => file.key === fileKey).status = uploadStatus.PENDING
  },
  REDO_ALL_ERRORED_DOCUMENTS (state) {
    state.uploadQueue.forEach(file => {
      if (file.status === uploadStatus.ERROR) {
        file.status = uploadStatus.PENDING
      }
    })
  },
  RESET: (state) => {
    state.filesToUpload = []
    state.uploadInProgress = 0
    state.uploadQueue = []
  },
  [SET_UPLOAD_HINT] (state, { visible = false, destinationFolder = null, messageType = null }) {
    state.uploadHint = {
      visible,
      destinationFolder,
      messageType,
    }
  },
}
