import projectsFoldersService from '@/services/projectsFolders.service'

import {
  MOVE_PROJECTS_FOLDER,
} from './action_types'
import {
  SET_PROJECTS_FOLDER_MOVE_PENDING,
  SET_PROJECTS_FOLDER_TO_MOVE,
} from './mutation_types'

export const actions = {
  async [MOVE_PROJECTS_FOLDER] ({ commit }, { projectsFolderToMoveId, toFolderId }) {
    commit(SET_PROJECTS_FOLDER_MOVE_PENDING, true)
    try {
      await projectsFoldersService.moveProjectsFolderToFolder(projectsFolderToMoveId, toFolderId)
      commit(SET_PROJECTS_FOLDER_TO_MOVE, {})
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_PROJECTS_FOLDER_MOVE_PENDING, false)
    }
  },
}
