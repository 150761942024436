import axios from 'axios'
import { cloneDeep } from 'lodash-es'

/**
 * Message event type
 */
export const CANCELED = 'poly-canceled'
export const COMPLETED = 'poly-completed'

export default {

  getInfo (actionToken) {
    return axios.get('hooks/polyoffice/info', {
      params: {
        act: actionToken,
      },
    })
  },
  /**
   * @param {DocumentsImport, SigningChecklistImport} payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  postImport (payload) {
    const data = cloneDeep(payload)
    delete data.mnemo
    return axios.post(`/room/${payload.mnemo}/polyoffice/receive`, data)
  },

  /**
   * @param {DocumentsExport} payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  postExport (payload) {
    const data = cloneDeep(payload)
    delete data.mnemo
    return axios.post(`/room/${payload.mnemo}/polyoffice/send`, data)
  },
}
